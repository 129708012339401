<template>
  <div class="h-100p">
    <basic-container v-show="!flowBox" class="h-100p">
      <avue-crud
        :option="option"
        :table-loading="loading"
        :data="data"
        ref="crud"
        v-model="form"
        :page.sync="page"
        @search-change="searchChange"
        @search-reset="searchReset"
        @selection-change="selectionChange"
        @current-change="currentChange"
        @size-change="sizeChange"
        @refresh-change="refreshChange"
        @on-load="onLoad"
      >
        <template slot="menuLeft">
          <el-button
            type="primary"
            size="small"
            icon="el-icon-circle-plus"
            v-if="permission.flow_model_create"
            @click="handleCreate"
            >创 建
          </el-button>
          <el-button
            type="danger"
            size="small"
            icon="el-icon-delete"
            v-if="permission.flow_model_delete"
            plain
            @click="handleDelete"
            >删 除
          </el-button>
        </template>
        <template slot-scope="scope" slot="menu">
          <el-button
            type="text"
            size="small"
            v-if="permission.flow_model_update"
            plain
            class="none-border"
            @click.stop="handleUpdate(scope.row, scope.index)"
            >设计
          </el-button>
          <el-button
            type="text"
            size="small"
            v-if="permission.flow_model_deploy"
            plain
            class="none-border"
            @click.stop="handleDeploy(scope.row, scope.index)"
            >部署
          </el-button>
          <el-button
            type="text"
            size="small"
            v-if="permission.flow_model_download"
            plain
            class="none-border"
            @click.stop="handleDownload(scope.row, scope.index)"
            >下载
          </el-button>
          <el-button
            type="text"
            size="small"
            v-if="permission.flow_model_delete"
            plain
            class="none-border"
            @click.stop="handleSlotDelete(scope.row, scope.index)"
            >删除
          </el-button>
        </template>
        <template slot-scope="{ row }" slot="version">
          <el-tag>v{{ row.version }}</el-tag>
        </template>
      </avue-crud>
      <!-- <el-dialog
        title="流程配置"
        append-to-body
        :visible.sync="flowBox"
        :fullscreen="true"
        :body-style="{'height': '100%'}"
      >
        <div style="height: calc(100vh - 80px)">
          <basic-bpmn></basic-bpmn>
        </div> -->
      <!-- <span slot="footer" class="dialog-footer">
          <el-button @click="flowBox = false">取 消</el-button>
          <el-button type="primary" @click="handleRefresh">确 定</el-button>
        </span> -->
      <!-- </el-dialog> -->
      <el-dialog
        title="流程部署"
        append-to-body
        :visible.sync="deployBox"
        width="30%"
      >
        <avue-form
          ref="form"
          :option="optionDeploy"
          v-model="form"
          @submit="handleSubmit"
        />
        <span slot="footer" class="dialog-footer">
          <el-button @click="deployBox = false">取 消</el-button>
          <el-button
            type="primary"
            @click="handleDoDeploy"
            :loading="deployLoading"
            >确 定</el-button
          >
        </span>
      </el-dialog>

      <el-dialog
        title="创建模型"
        append-to-body
        :visible.sync="createBox"
        width="40%"
      >
        <avue-form
          ref="form"
          :option="optionCreate"
          size="small"
          v-model="createForm"
          @submit="handleCreateSubmit"
        />
      </el-dialog>

      <el-dialog
        title="保存模型"
        append-to-body
        :visible.sync="editSaveBox"
        width="40%"
      >
        <avue-form
          ref="form"
          :option="optionEdit"
          size="small"
          v-model="editSaveForm"
          @submit="handleEditSubmit"
        >
          <template slot-scope="{}" slot="newversionLabel"
            ><span></span
          ></template>
          <template slot-scope="{ size }" slot="newversion">
            <el-checkbox v-model="editSaveForm.newversion"
              >将此保存为新版本？这意味着你可以回到以前的版本。</el-checkbox
            >
          </template>
        </avue-form>
      </el-dialog>
    </basic-container>
    <div class="h-100p" style="background: #fff" v-if="flowBox">
      <basic-bpmn
        class="h-100p"
        @back="flowBox = false"
        @save="saveModel"
        :value="flowModel"
        :users="users"
        :roles="roles"
      ></basic-bpmn>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  modelList,
  removeModel,
  deployModel,
  FlowApiUrls,
  exportBpmn20,
  createModel,
  saveModel,
} from "@/api/flow/flow";
import { getDeptUserTree } from "@/api/system/dept";
import { getList as getRoleList } from "@/api/system/role";
import { getList as getTenantList } from '@/api/system/tenant'
import { flowCategory } from "@/util/flow";

import { downloadFile } from "@/api/download";

export default {
  data() {
    return {
      createBox: false,
      createForm: {},
      optionCreate: {
        border: true,
        column: [
          {
            label: "模型名称",
            prop: "name",
            type: "input",
            span: 24,
            rules: [
              { required: true, message: "请输入模型名称", trigger: "blur" },
            ],
          },
          {
            label: "模型Key",
            prop: "key",
            type: "input",
            span: 24,
            rules: [
              { required: true, message: "请输入模型Key", trigger: "blur" },
            ],
          },
          {
            label: "模型类型",
            prop: "modelType",
            type: "select",
            value: 0,
            dicData: [
              { label: "主流程", value: 0 },
              { label: "子流程", value: 1 },
            ],
            span: 24,
            rules: [
              { required: true, message: "请选择模型类型", trigger: "blur" },
            ],
            display: false,
          },
          {
            label: "模型描述",
            prop: "description",
            type: "textarea",
            span: 24,
          },
        ],
      },

      editSaveBox: false,
      editSaveForm: {},
      optionEdit: {
        column: [
          {
            label: "模型名称",
            prop: "name",
            type: "input",
            span: 24,
            rules: [
              { required: true, message: "请输入模型名称", trigger: "blur" },
            ],
          },
          {
            label: "模型Key",
            prop: "key",
            type: "input",
            span: 24,
            rules: [
              { required: true, message: "请输入模型Key", trigger: "blur" },
            ],
          },
          {
            label: "模型描述",
            prop: "description",
            type: "textarea",
            span: 24,
          },
          {
            label: "新版本",
            prop: "newversion",
            span: 24,
            value: false,
          },
        ],
      },
      users: [],
      roles: [],
      form: {},
      optionDeploy: {
        menuBtn: false,
        column: [
          {
            label: "流程类型",
            type: "select",
            dicUrl: "/api/jida-system/dict/dictionary?code=flow",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            slot: true,
            prop: "categoryValue",
            search: true,
            span: 24,
            rules: [
              {
                required: true,
                message: "请选择流程类型",
                trigger: "blur",
              },
            ],
          },
          {
            label: "流程模式",
            prop: "flowMode",
            type: "radio",
            dicData: [
              {
                label: "通用流程",
                value: 1,
              },
              {
                label: "定制流程",
                value: 2,
              },
            ],
            value: 1,
            span: 24,
            rules: [
              {
                required: true,
                message: "请选择流程模式",
                trigger: "blur",
              },
            ],
          },
          {
            label: "所属租户",
            prop: "tenantId",
            type: "tree",
            multiple: true,
            dicUrl: "/api/jida-system/tenant/select",
            props: {
              label: "tenantName",
              value: "tenantId",
            },
            display: false,
            span: 24,
            rules: [
              {
                required: true,
                message: "请选择所属租户",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      selectionId: "",
      selectionList: [],
      query: {},
      loading: true,
      deployLoading: false,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      deployBox: false,
      flowBox: false,
      flowUrl: "",
      flowModel: null,
      option: {
        height: "auto",
        calcHeight: 30,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        // index: true,
        selection: true,
        selectionWidth: 60,
        editBtn: false,
        addBtn: false,
        viewBtn: false,
        delBtn: false,
        dialogWidth: 900,
        // menuWidth: 150,
        dialogClickModal: false,
        currentModel: null,
        column: [
          {
            label: "模型标识",
            prop: "modelKey",
            search: true,
            width: 150,
          },
          {
            label: "模型名称",
            prop: "name",
            search: true,
          },
          {
            label: "流程版本",
            prop: "version",
            slot: true,
            width: 80,
          },
          {
            label: "创建时间",
            prop: "created",
            width: 150,
          },
          {
            label: "更新时间",
            prop: "lastUpdated",
            width: 150,
          },
        ],
      },
      data: [],
      tenant: {}
    };
  },
  watch: {
    "form.flowMode"() {
      this.$refs.form.option.column.filter((item) => {
        if (item.prop === "tenantId") {
          item.display = this.form.flowMode === 2;
        }
      });
    },
  },
  computed: {
    ...mapGetters(["permission"]),
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  mounted() {
    getDeptUserTree().then((res) => {
      this.users = this.resetData(res.data.data || [], (item) => {
        item.label = item.title;
        item.id = item.value;
        if (item.type === "U") {
          item.isLeaf = true;
        }
      });
      console.log(this.users);
    });
    getTenantList(1 , 500).then(
      res => {
        this.tenant = {};
        let roles = {};
        (res.data.data.records || []).forEach(
          item => {
            this.tenant[item.tenantId] = {
              ...item
            };
            roles[item.tenantId] = {
              label: item.tenantName,
              id: item.tenantId,
              disabled: true,
              children: []
            }
          }
        )
        getRoleList().then((res) => {
          let handle = (data) => {
            let childrenArr = [];
            data.forEach(
              item => {
                let newItem = {
                  ...item,
                  label: item.roleName,
                  id: item.id
                }
                if(item.children && item.children.length){
                  newItem.children = handle(item.children);
                }else{
                  newItem.isLeaf = true;
                }
                if(item.parentId === '0'){
                  if(roles[item.tenantId]){
                    roles[item.tenantId].children.push(newItem)
                  }else{
                    roles[item.id] = newItem;
                  }
                }else{
                  childrenArr.push(newItem)
                }
              }
            );
            return childrenArr;
          }
          handle(res.data.data || []);
          // console.log(roles);
          this.roles = Object.values(roles);
          // this.roles = this.resetData(res.data.data || [], (item) => {
          //   item.label = `${this.tenant[item.tenantId] ? (this.tenant[item.tenantId].tenantName + '_') : ''}${item.roleName}`;
          //   item.id = item.id;
          //   if (!item.children || !item.children.length) {
          //     item.isLeaf = true;
          //   }
          // });
        });
      }
    )
  },
  methods: {
    resetData(data, setFn) {
      data.forEach((item) => {
        setFn(item);
        if (item.isLeaf === true) {
          delete item.children;
        } else if (item.children && item.children.length) {
          item.children = this.resetData(item.children, setFn);
        }
      });
      return data;
    },
    handleSubmit(form, done) {
      this.deployLoading = true;
      deployModel({
        modelId: this.selectionId,
        category: flowCategory(form.categoryValue),
        tenantIds: form.tenantId,
      }).then((res) => {
        const data = res.data;
        if (data.success) {
          this.$message({
            type: "success",
            message: data.msg,
          });
          done();
          this.$refs.form.resetForm();
          this.deployBox = false;
          this.deployLoading = false;
        } else {
          done();
          this.deployLoading = false;
          this.$message({
            type: "warn",
            message: data.msg,
          });
        }
      });
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return removeModel(this.ids);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
          this.onLoad(this.page);
        });
    },
    handleCreateSubmit(form, done) {
      createModel(form)
        .then((res) => {
          this.createBox = false;
          this.handleUpdate(res.data);
          done();
        })
        .catch((e) => {
          done();
        });
    },
    handleCreate() {
      // this.flowUrl = `${website.flowDesignUrl}/index.html`;
      // this.flowBox = true;
      this.createBox = true;
    },
    handleEditSubmit(form, done) {
      saveModel(this.currentModel.id, form)
        .then((res) => {
          this.editSaveBox = false;
          this.$message.success("保存成功");
          this.flowBox = false;
          this.refreshChange();
          done();
        })
        .catch((e) => {
          done();
        });
    },
    saveModel(xml) {
      // console.log(xml , this.currentModel);
      this.editSaveForm = {
        json_xml: xml.replace(/\n/g, ""),
        modeltype: "model",
        name: this.currentModel.name,
        key: this.currentModel.key,
        description: this.currentModel.description,
        newversion: false,
        lastUpdated: this.currentModel.lastUpdated,
      };
      this.editSaveBox = true;
    },
    handleUpdate(row) {
      // this.flowUrl = `${website.flowDesignUrl}/index.html#/editor/${row.id}`;
      exportBpmn20(row.id, {
        version: Date.now(),
      }).then((res) => {
        if (res.data) {
          this.currentModel = res.data.data;
          this.flowModel = res.data.data.modelEditorJson;
          this.flowBox = true;
        }
      });
    },
    handleDeploy(row) {
      this.deployBox = true;
      this.selectionId = row.id;
    },
    handleDoDeploy() {
      this.$refs.form.submit();
    },
    handleDownload(row) {
      downloadFile(FlowApiUrls.export(row.id), {
        version: Date.now(),
      });
    },
    handleSlotDelete(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return removeModel(row.id);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
          this.onLoad(this.page);
        });
    },
    handleRefresh() {
      this.flowBox = false;
      this.onLoad(this.page);
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      modelList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
      });
    },
  },
};
</script>

<style>
.none-border {
  border: 0;
  background-color: transparent !important;
}
</style>

export default [
  {
    'label':'都不发',
    'value':'NONE'
  },
  {
    'label':'成功发',
    'value':'SUCCESS'
  },
  {
    'label':'失败发',
    'value':'FAILURE'
  },
  {
    'label':'成功或失败都发',
    'value':'ALL'
  }
]

<template>
  <basic-container>
    <avue-crud
      ref="crud"
      :table-loading="tableLoading"
      :data="tableData"
      :option="option"
      :page.sync="page"
      :before-open="beforeOpen"
      v-model="form"
      @on-load="onLoad"
      @refresh-change="onLoad"
      @row-save="savaData"
      @row-del="deleteRow"
      @row-update="(row , index , done , loading) => savaData(row , done , loading)"
    >
      <!-- 搜索 -->
      <template slot='menuLeft'>
        <el-select
          v-model="search.tenant_id"
          size="small"
          placeholder="请选择租户"
          style="width:180px!important;"
          @change='searchChange'
          clearable
        >
          <el-option v-for='item in tenantList' :key='item.tenantId' :value='item.tenantId' :label='item.tenantName'></el-option>
        </el-select>
      </template>
    </avue-crud>
  </basic-container>
</template>
<script>

import taskQuoraService from '@/api/dataAssets/task-quota';
import * as tenantService from '@/api/system/tenant'

export default {
  data(){
    return {
      form: {},
      tableData: [],
      tenantList: [],
      tableLoading: false,
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      option: {
        labelWidth: 130,
        column: [
          {
            label: "租户",
            prop: "tenant_id",
            dicData: [],
            props: {
              label: 'tenantName',
              value: 'tenantId'
            },
            minWidth: 100,
            type: 'select',
            row: true,
            rules: [
              { required: true, message: "请选择租户", trigger: "blur"}
            ],
            // search: true,
            // searchSpan: 6,
          },
          {
            label: "采集任务配额",
            prop: "task_cj_quota",
            width: 100,
            type: 'number',
            minRows: 0,
            span: 12,
            rules: [
              { required: true, message: "请输入采集任务配额", trigger: "blur"}
            ]
          },
          {
            label: "采集任务上线数",
            prop: "task_cj_max",
            width: 100,
            type: 'number',
            minRows: 0,
            span: 12,
            rules: [
              { required: true, message: "请输入采集任务上线数", trigger: "blur"}
            ]
          },
          {
            label: "治理任务配额",
            prop: "task_zl_quota",
            width: 100,
            minRows: 0,
            type: 'number',
            rules: [
              { required: true, message: "请输入治理任务配额", trigger: "blur"}
            ]
          },
          {
            label: "治理任务上线数",
            prop: "task_zl_max",
            minRows: 0,
            width: 100,
            type: 'number',
            rules: [
              { required: true, message: "请输入治理任务上线数", trigger: "blur"}
            ]
          },
          {
            label: "宽表任务配额",
            prop: "task_kb_quota",
            minRows: 0,
            width: 100,
            type: 'number',
            rules: [
              { required: true, message: "请输入宽表任务配额", trigger: "blur"}
            ]
          },
          {
            label: "宽表任务上线数",
            prop: "task_kb_max",
            minRows: 0,
            width: 100,
            type: 'number',
            rules: [
              { required: true, message: "请输入宽表任务上线数", trigger: "blur"}
            ]
          },
          {
            label: "创建人",
            prop: "create_user_name",
            display: false
          },
          {
            label: "创建时间",
            prop: "create_time",
            width: 150,
            display: false
          },
        ]
      },
      search: {}
    }
  },
  mounted(){
    this.getTenants();
  },
  methods: {
    beforeOpen(done , type){
      if(['edit'].includes(type)){
        taskQuoraService.getDetail({id: this.form.id}).then(
          res => {
            this.form = res.data.data;
          }
        )
      }
      done();
    },
    onLoad(){
      this.tableLoading = true;
      let params = {
        pageNo: this.page.currentPage,
        pageSize: this.page.pageSize
      }
      if(this.search.tenant_id){
        params['tenantId'] = this.search.tenant_id;
      }
      taskQuoraService.getList(params).then(
        res => {
          if(res.data && res.data.data){
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
          }
          this.tableLoading = false;
        }
      ).catch(e => {
        this.tableLoading = false;
      })
    },
    searchChange(){
      this.page.currentPage = 1;
      this.onLoad();
    },
    getTenants(){
      this.tenantList=[]
      tenantService.getList(1 , 10000).then(
        res => {
          if(res.data && res.data.data){
            let data = res.data.data.records||[];
            this.tenantList=data
            this.option.column.forEach(
              item => {
                if(item.prop === 'tenant_id'){
                  item.dicData = data;
                }
              }
            )
          }
        }
      )
    },
    responeHandle(res , done , loading){
      if(res.data && res.data.code === 0){
        done && done();
        this.onLoad();
        this.$message.success(res.data.msg);
      }else{
        loading && loading();
        this.$message.error(res.data.msg);
      }
    },
    savaData(row , done , loading){
      let tenantId = row.tenant_id , tenantName = row.$tenant_id;
      let json = {};
      Object.keys(row).forEach(
        key => {
          if(key.indexOf('task_') > -1){
            json[key] = row[key];
          }
        }
      );
      let data = {
        tenantId: tenantId,
        tenantName: tenantName,
        json: JSON.stringify(json),
      };
      if(row.id){
        data['id'] = row.id;
      }
      (data['id'] ? taskQuoraService.edit : taskQuoraService.save)(data).then(
        res => {
          this.responeHandle(res , done , loading);
        }
      ).catch(() => {
        loading();
      })
    },
    deleteRow(row){
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        taskQuoraService.delete(row.id).then(res => {
          this.responeHandle(res)
        })
      })
    }
  }
}
</script>

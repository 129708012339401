<template>
    <div :class="{ houseingRes: houseingRes != 'houseingRes' }">
        <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" @row-click="handleRowClick" @on-load="onLoad" @selection-change="selectionChange" @cell-click="handleRowClick">
            <template slot="menuLeft" slot-scope="scope" v-if="houseingRes == 'houseingRes'">
                <el-input v-model="searchText" @keyup.native.enter="refreshChange" size="small" placeholder="请输入楼宇名称" type="text" style="width: 250px">
                    <el-button class="mar-0 pad-0" size="small" @click="refreshChange" slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </template>
            <template slot-scope="{ row }" slot="ssly">
                <a class="cur-p">{{ row.ssly }}</a>
            </template>
        </avue-crud>
    </div>
</template>

<script>
import { getMetaCommonList } from '@/api/busiMode/metaCommon';
const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
export default {
    components: {},
    data() {
        return {
            option: {
                height: viewPortHeight - 260,
                calcHeight: 30,
                dialogWidth: 900,
                tip: false,
                searchShow: false,
                searchMenuSpan: 6,
                border: true,
                index: true,
                refreshBtn: false,
                columnBtn: false,
                searchBtn: false,
                labelWidth: 120,
                menuWidth: 250,
                viewBtn: false,
                dialogClickModal: false,
                delBtn: false,
                editBtn: false,
                menu: false,
                addBtn: false,
                cancelBtn: false,
                page: false,
                column: [
                    {
                        width: 200,
                        overHidden: true,
                        align: 'left',
                        label: '楼宇名称',
                        prop: 'ssly',
                        hide: false,
                        slot: true,
                        fixed: true,
                    },

                    {
                        width: 180,
                        overHidden: true,
                        label: '建筑面积(m²)',
                        prop: 'room_area_sum',
                        hide: false,
                        align: 'right',
                    },

                    // {
                    //   width: 120,
                    //   overHidden: true,
                    //   label: "出租面积(m²)",
                    //   prop: "sum_checkin_area",
                    //   hide: false,
                    //   align: "right",
                    // },
                    {
                        width: 180,
                        overHidden: true,
                        label: '空置面积(m²)',
                        prop: 'room_area_wz',
                        hide: false,
                        align: 'right',
                    },
                    {
                        width: 180,
                        overHidden: true,
                        label: '出租面积(m²)',
                        prop: 'room_area_yz',
                        hide: false,
                        align: 'right',
                    },
                    {
                        width: 180,
                        overHidden: true,
                        label: '已售面积(m²)',
                        prop: 'room_area_ys',
                        hide: false,
                        align: 'right',
                    },
                    {
                        width: 120,
                        overHidden: true,
                        label: '自用面积(m²)',
                        prop: 'room_area_zy',
                        hide: false,
                        align: 'right',
                    },

                    {
                        overHidden: true,
                        width: 150,
                        label: '入驻企业数',
                        prop: 'sum_checkin_num',
                        hide: false,
                        align: 'right',
                    },
                    {
                        overHidden: true,
                        width: 150,
                        label: '本年应收房租(元)',
                        prop: 'ys_fz_sum',
                        hide: false,
                        align: 'right',
                    },
                    {
                        overHidden: true,
                        width: 150,
                        label: '本年已收房租(元)',
                        prop: 'yj_fz_sum',
                        hide: false,
                        align: 'right',
                    },
                    {
                        overHidden: true,
                        width: 150,
                        label: '本年政策性未收房租(元)',
                        prop: 'ye_zc_fz_sum',
                        hide: false,
                        align: 'right',
                    },
                    {
                        overHidden: true,
                        width: 150,
                        label: '拖欠(元)',
                        prop: 'ye_qy_fz_sum',
                        hide: false,
                        align: 'right',
                    },
                    // {
                    //     width: 180,
                    //     overHidden: true,
                    //     label: '租金收入(万元)',
                    //     prop: 'fee_type_1_amounts',
                    //     hide: false,
                    //     align: 'right',
                    // },

                    // {
                    //     width: 120,
                    //     overHidden: true,
                    //     label: '物业费收入(万元)',
                    //     prop: 'fee_type_2_amounts',
                    //     hide: false,
                    //     align: 'right',
                    // },
                    // {
                    //     overHidden: true,
                    //     label: '其他收入(万元)',
                    //     prop: 'fee_type_4_amounts',
                    //     hide: false,
                    //     align: 'right',
                    // },
                    // {
                    //   width: 120,
                    //   overHidden: true,
                    //   label: "用电量(kW·h)",
                    //   prop: "sum_power_consumption",
                    //   hide: false,
                    //   align: "right",
                    // },
                    // {
                    //   width: 120,
                    //   overHidden: true,
                    //   label: "用水量(吨)",
                    //   prop: "sum_water_consumption",
                    //   hide: false,
                    //   align: "right",
                    // },
                    // {
                    //   width: 120,
                    //   overHidden: true,
                    //   label: "节能减排",
                    //   prop: "sum_energy_conservation",
                    //   hide: false,
                    //   align: "right",
                    // },
                ],
            },
            loading: true,
            model: 'wisdom_park.v_rpt_building_stat_curyear',
            data: [],
            buildObj: {},
            searchText: '',
        };
    },
    props: {
        houseingRes: {
            type: String,
            default: function () {
                return '';
            },
        },
    },
    computed: {},
    mounted() {
        this.onLoad();
        if (this.houseingRes == 'houseingRes') {
            this.option.height = viewPortHeight * 0.6;
            this.option.selection = true;
        } else {
            this.option.height = viewPortHeight - 260;
            this.option.selection = false;
        }
        this.option = JSON.parse(JSON.stringify(this.option));
    },
    methods: {
        refreshChange() {
            this.onLoad();
        },
        onLoad() {
            this.loading = true;
            let body = {
                current: 1,
                size: 50,
                // orders: [
                //   {
                //     field: "sort",
                //     sort: "ASC",
                //   },
                // ],
            };
            if (this.searchText) {
                body['wheres'] = [{ field: 'name', op: 'LIKE', value: this.searchText }];
            }
            getMetaCommonList(this.model, body).then(res => {
                const data = res.data.data;
                data.records.forEach(element => {
                    element.occupancy_rate = this.getRZL(element);
                });
                this.data = data.records;
                this.loading = false;
            });
        },
        getRZL(graden) {
            if (graden.sum_room_area_yz && graden.floorage) {
                let total =
                    Number(graden.sum_room_area_yz) + Number(graden.sum_room_area_ys) + Number(graden.sum_room_area_zy);
                return ((total / Number(graden.floorage)) * 100).toFixed(2) + '%';
            } else {
                return '-';
            }
        },
        handleRowClick(e) {
            this.$emit('oneBuild', e);
        },
        selectionChange(selection) {
            this.$emit('oneBuild', selection);
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.houseingRes /deep/ .avue-crud__menu {
    display: none;
}
.cell-color {
    color: #409eff !important;
    cursor: pointer;
}
/deep/ .el-table th.is-leaf {
    padding-left: 15px;
    padding-right: 15px;
}
</style>

<template>
  <div>
    <div class="avue-empty" style="margin-top: 50px;" v-if="noData">
      <no-data></no-data>
      <!-- <div class="avue-empty__image" style="height: 50px;">
        <img src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNDEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgPGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMCAxKSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4KICAgIDxlbGxpcHNlIGZpbGw9IiNGNUY1RjUiIGN4PSIzMiIgY3k9IjMzIiByeD0iMzIiIHJ5PSI3Ii8+CiAgICA8ZyBmaWxsLXJ1bGU9Im5vbnplcm8iIHN0cm9rZT0iI0Q5RDlEOSI+CiAgICAgIDxwYXRoIGQ9Ik01NSAxMi43Nkw0NC44NTQgMS4yNThDNDQuMzY3LjQ3NCA0My42NTYgMCA0Mi45MDcgMEgyMS4wOTNjLS43NDkgMC0xLjQ2LjQ3NC0xLjk0NyAxLjI1N0w5IDEyLjc2MVYyMmg0NnYtOS4yNHoiLz4KICAgICAgPHBhdGggZD0iTTQxLjYxMyAxNS45MzFjMC0xLjYwNS45OTQtMi45MyAyLjIyNy0yLjkzMUg1NXYxOC4xMzdDNTUgMzMuMjYgNTMuNjggMzUgNTIuMDUgMzVoLTQwLjFDMTAuMzIgMzUgOSAzMy4yNTkgOSAzMS4xMzdWMTNoMTEuMTZjMS4yMzMgMCAyLjIyNyAxLjMyMyAyLjIyNyAyLjkyOHYuMDIyYzAgMS42MDUgMS4wMDUgMi45MDEgMi4yMzcgMi45MDFoMTQuNzUyYzEuMjMyIDAgMi4yMzctMS4zMDggMi4yMzctMi45MTN2LS4wMDd6IiBmaWxsPSIjRkFGQUZBIi8+CiAgICA8L2c+CiAgPC9nPgo8L3N2Zz4K" alt="">
      </div>
      <p class="avue-empty__desc">暂无数据</p> -->
    </div>
    <div v-if="!noData">
      <span style="display: inline-block;width: 16px;height: 16px;border:solid 2px #fb4200;border-radius: 8px;float: left"></span>
      <span style="margin-left: 2px;float: left">当前</span>
      <i style="color: #E6A23C;font-size: 20px;margin-left: 10px;float: left" class="el-icon-right"></i>
      <span style="margin-left: 2px;float: left">血缘</span>
      <i style="color: #F56C6C;font-size: 20px;margin-left: 10px;float: left" class="el-icon-right"></i>
      <span style="margin-left: 2px;float: left">影响</span>
    </div>
    <div id="mountNode" style="width: 100%;height: 100%;"></div>
  </div>
</template>
<script>
import G6 from '@antv/g6';
import { getGraphData } from '@/api/metadata/metadata';
export default {
  props:{
    guid:String
  },
  data() {
    return {
      noData:false,
      data:{},
      graph:{},
      baseEntityGuid:'',
      inputEdgesIds:[]
    }
  },
  mounted() {
    this.getData();
  },
  watch: {
    'guid'(){
      this.getData();
    }
  },
  methods: {
    getData() {
      getGraphData(this.guid).then(res => {
        this.baseEntityGuid = res['data']['baseEntityGuid'];
        let nodes = Object.values(res['data']['guidEntityMap']);
        nodes.forEach(item => {
          item['id'] = item['guid'];
          item['label'] = item['displayText'];
        });
        let edges = res['data']['relations'];
        this.inputEdgesIds = [];
        edges.forEach( item => {
          item['source'] = item['fromEntityId'];
          item['target'] = item['toEntityId'];
        });
        this.getInputEdges(edges,[this.baseEntityGuid]);
        let data = {
          nodes:nodes,
          edges:edges
        };
        this.noData = nodes.length === 0;
        // this.data = res['data'];
         this.initGraph(data);
      });
    },
    getInputEdges(edges,ids) {
      let temp = [];
      for(let j = 0,jlen = ids.length; j < jlen; j ++ ) {
        let target = ids[j];
        for(let i = 0,len = edges.length; i < len; i ++){
          let edge = edges[i];
          if(edge.target === target){
            temp.push(edge.source);
            this.inputEdgesIds.push(edge.target);
          }
        }
      }
      if(temp.length > 0){
        this.getInputEdges(edges,temp)
      }
    },
    initGraph(data) {
      let _t = this;
      document.getElementById('mountNode').innerHTML = '';
      this.$nextTick(() => {
        const container = document.getElementById('mountNode');
        const metaInfoComtainer = document.getElementById('metaInfo');
        const width = container.scrollWidth || metaInfoComtainer.scrollWidth;
        const height = container.scrollHeight || 500;
        this.graph = new G6.Graph({
          container: 'mountNode',
          width,
          height,
          fitCenter:true,
          //fitView: true,
          modes: {
            default: [
              'drag-canvas',
              'drag-node',
              'zoom-canvas',
              {
                type: 'tooltip',
                formatText(model) {
                  let str = '';
                  if(!!model.attributes){
                    let keys = Object.keys(model.attributes);
                    for(let i = 0,len = keys.length; i < len; i ++ ){
                      str += `<div style="margin-bottom: 5px;">
                                <span>${keys[i]}:</span>
                                <span>${model.attributes[keys[i]]}</span>
                              </div>`
                    }
                  } else {
                    str = model.name;
                  }
                  return str;
                },
                offset: 10,
              }
            ],
          },
          layout: {
            type: 'dagre',
            rankdir: 'LR',
            ranksep: 100,
            align: 'DL',
            controlPoints: true,
            nodesepFunc: () => 20,
            ranksepFunc: () => 10,
          },
          defaultNode: {
            size: 26,
            anchorPoints: [
              [0, 0.5],
              [1, 0.5],
            ]
          },
          defaultEdge: {
            type: 'cubic-horizontal'
          },
        });
        this.graph.node(function (node) {
          let obj = {
            label: node.name,
            labelCfg: {
              offset: 10,
              position: 'top'
            },
          };
          if(node.id === _t.baseEntityGuid){
            return {
              ...obj,
              style:{
                stroke: '#fb4200',
                lineWidth: 3
              },
              size: 36
            }
          }
          else {
            return {
              ...obj
            }
          }
        });
        this.graph.edge(function (edge) {
          if( _t.inputEdgesIds.indexOf(edge.target) > -1) {
            return {
              style: {
                endArrow: {
                  path: 'M 0,0 L 8,4 L 8,-4 Z',
                  fill: '#E6A23C',
                },
                stroke: '#E6A23C',
                radius: 20
              }
            }
          } else {
            return {
              style: {
                endArrow: {
                  path: 'M 0,0 L 8,4 L 8,-4 Z',
                  fill: '#F56C6C',
                },
                stroke: '#F56C6C',
                radius: 20
              }
            }
          }

        });
        this.graph.data(data);
        this.graph.render();
        if (typeof window !== 'undefined')
          window.onresize = () => {
            if (!this.graph || this.graph.get('destroyed')) return;
            if (!container || !container.scrollWidth || !container.scrollHeight) return;
            this.graph.changeSize(container.scrollWidth, container.scrollHeight);
          };
      })

    }
  }
}
</script>
<style lang="scss">
  .g6-tooltip {
    padding: 10px 6px;
    color: #444;
    background-color: rgba(255, 255, 255, 0.9);
    border: 1px solid #e2e2e2;
    border-radius: 4px;
  }
</style>

<template>
  <el-container class="h-100p">
    <el-aside width="300px">
      <basic-container isCard class="h-100p ov-a">
        <el-input class="mar-b-15" size="small" placeholder="输入关键字进行过滤" clearable v-model="treeFilterText"></el-input>
        <el-tree
          ref="tree"
          node-key="id"
          :data="catalogData"
          :props="treeProps"
          :expand-on-click-node="false"
          @node-click="treeNodeClick"
          :filter-node-method="filterNode"
        >
        </el-tree>
      </basic-container>
    </el-aside>
    <el-main class="pad-l-15">
      <basic-container>
        <el-card class="mar-b-15">
          <el-form ref="searchForm" :model="seachForm" size="small" label-width="80px">
            <el-row>
              <el-col :span="6">
                <el-form-item label="公司" prop="companyId">
                  <model-search-select
                    v-model="seachForm.companyId"
                    model="wp_company"
                    class="dis-b w-100p"
                  >
                  </model-search-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="政策" prop="reductionPolicyId">
                  <model-search-select
                    v-model="seachForm.reductionPolicyId"
                    model="wp_reduction_policy"
                    class="dis-b w-100p"
                    labelKey="reduction_policy_title"
                  >
                  </model-search-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="政策类型" prop="reductionPolicyType">
                  <dict-select v-model="seachForm.reductionPolicyType" code="ReductionPolicyType"></dict-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item class="text-right" label-width="0px">
                  <el-button icon="el-icon-delete" @click="$refs.searchForm.resetFields()">清 空</el-button>
                  <el-button icon="el-icon-search" type="primary" @click="searchTable">搜 索</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-card>
        <PreferentialTable :queryParams="tableQueryParams"></PreferentialTable>
      </basic-container>
    </el-main>
  </el-container>
</template>
<script>
import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  delMetaCommon,
  getMetaCommonDetail,
  getMetaCommonListTree
} from "@/api/busiMode/metaCommon";

import PreferentialTable from './preferential-table.vue';
import { getDictionary } from '@/api/system/dictbiz.js'

export default {
  components: { PreferentialTable },
  data(){
    return {
      seachForm: {
        companyId: '',
        reductionPolicyId: '',
        reductionPolicyType: ''
      },
      catalogModel: 'wisdom_park.wp_expense_account',
      model: 'wisdom_park.wp_receipt',
      catalogData: [],
      treeProps: {
        label: 'expense_account_name'
      },
      treeFilterText: '',
      tableQueryParams: {

      },
      dictData: {

      }
    }
  },
  watch: {
    treeFilterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  mounted(){
    this.getMetaCommonListTree();
    this.getDictionary();
  },
  methods: {
    getDictionary(){
      [
        {prop: 'receiptSubjectType' , model: 'ReceiptSubjectType'}
      ].forEach(
        item => {
          getDictionary({
            code: item.model
          }).then(
            res => {
              this.dictData[item.prop] = res.data.data || [];
            }
          )
        }
      )
    },
    getMetaCommonListTree(){
      getMetaCommonListTree(this.catalogModel , {
        labelField: 'expense_account_name',
        valueField: 'id',
        releaseField: 'parent_id',
        isLazy: false
      }).then(
        res => {
          this.catalogData = [
            {
              expense_account_name: '全部',
              id: 'all'
            }
          ].concat(res.data.data);
          this.$nextTick(() => {
            this.$refs.tree.setCurrentNode(this.catalogData[0])
          })
        }
      )
    },
    treeNodeClick(ev){
      if(ev){
        let tableQueryParams = {
          ...this.getSearchForm()
        }
        if(ev.id !== 'all'){
          tableQueryParams = {
            ...tableQueryParams,
            expenseAccountCode: ev['expense_account_code']
          }
        }
        this.tableQueryParams = tableQueryParams;
      }
    },
    getSearchForm(){
      let seachForm = {};
      Object.keys(this.seachForm).forEach(
        key => {
          if(this.tableQueryParams[key] || this.seachForm[key]){
            seachForm[key] = this.seachForm[key];
          }
        }
      )
      return seachForm;
    },
    searchTable(){
      this.tableQueryParams = {
        ...this.tableQueryParams,
        ...this.getSearchForm()
      }
    },
    filterNode(value, data){
      if (!value) return true;
      return data.expense_account_name.indexOf(value) !== -1;
    }
  }
}
</script>

<template>
    <div>
        <el-card class="pad-10">
            <el-col :span="3" class="pad-16 fts-18 text-center">{{ floor.code }}层
                <i class="el-icon-picture-outline cur-p fts-12" @click="openFloorPicDialogVisible(floor)"></i>
            </el-col>
            <el-col :span="11" class="text-center title b-l o-a">
                <div class="lygk">

                    <div class="bnd">楼层概况</div>
                    <el-col :span="3">
                        <div class="thsj-tj">{{ graden.room_num || 0 }}</div>
                        <span class="text-title">总房间数</span>
                    </el-col>
                    <el-col :span="4">
                        <div class="thsj-tj">{{ graden.floor_area || 0 }}</div>
                        <span class="text-title">建筑面积(m²)</span>
                    </el-col>

                    <el-col :span="3">
                        <div class="thsj-tj">{{ graden.sum_room_area_yz || 0 }}</div>
                        <span class="text-title">出租面积(m²)</span>
                    </el-col>

                    <el-col :span="3">
                        <div class="thsj-tj">{{ graden.sum_room_area_wz || 0 }}</div>
                        <span class="text-title">空置面积(m²)</span>
                    </el-col>
                    <el-col :span="4">
                        <div class="thsj-tj">{{ graden.sum_room_area_ys }}</div>
                        <span class="text-title">已售面积(m²)</span>
                    </el-col>
                    <el-col :span="4">
                        <div class="thsj-tj">{{ graden.sum_room_area_zy }}</div>
                        <span class="text-title">自用面积(m²)</span>
                    </el-col>
                    <el-col :span="3">
                        <div class="thsj-tj">{{ graden.sum_checkin_num || 0 }}</div>
                        <span class="text-title">入驻企业数</span>
                    </el-col>
                </div>
            </el-col>
            <el-col :span="5" class="text-center thsj title b-l">
                <div class="bnd">本年累计</div>
                <el-col :span="8">
                    <div class="thsj-tj">{{ graden.fee_type_1_amounts || 0 }}</div>
                    <span class="text-title">租金收入</span>
                </el-col>
                <el-col :span="8">
                    <div class="thsj-tj">{{ graden.fee_type_2_amounts || 0 }}</div>
                    <span class="text-title">物业费收入</span>
                </el-col>
                <el-col :span="8">
                    <div class="thsj-tj">{{ graden.fee_type_4_amounts || 0 }}</div>
                    <span class="text-title">其他收入</span>
                </el-col>
            </el-col>
            <el-col :span="5" class="text-center thsj pad-10 b-l">
                <div class="bnd">能源使用</div>
                <el-col :span="8">
                    <div class="thsj-tj">{{ graden.sum_power_consumption || "0" }}</div>
                    <span class="text-title">用电量(kW·h)</span>
                </el-col>
                <el-col :span="8">
                    <div class="thsj-tj">{{ graden.sum_water_consumption || "0" }}</div>
                    <span class="text-title">用水量(吨)</span>
                </el-col>
                <el-col :span="8">
                    <div class="thsj-tj">{{ graden.sum_energy_conservation || "0" }}</div>
                    <span class="text-title">空调(台)</span>
                </el-col>
            </el-col>
            <!-- <el-col :span="6" class="text-center thsj pad-t-15 b-l">
        <div class="bnd">本年累计</div>
        <el-col :span="8">
          <div class="thsj-tj">{{ graden.sum_power_consumption || "-" }}</div>
          <span class="text-title">用电量(kW·h)</span></el-col
        >
        <el-col :span="8">
          <div class="thsj-tj">{{ graden.sum_water_consumption || "-" }}</div>
          <span class="text-title">用水量(吨)</span></el-col
        >
        <el-col :span="8">
          <div class="thsj-tj">{{ graden.sum_energy_conservation || "-" }}</div>
          <span class="text-title">节能减排</span></el-col
        >
      </el-col> -->
        </el-card>
        <el-dialog title="楼层图片" :visible.sync="floorPicDialogVisible" :close-on-click-modal="false" destroy-on-close :top="'8vh'" width="60%">
            <avue-form :option="floorPicOption" v-model="imgform"></avue-form>

        </el-dialog>
    </div>
</template>

<script>
import { getMetaCommonList, getPicListByIds } from '@/api/busiMode/metaCommon';

export default {
    components: {},
    data() {
        return {
            graden: {
                name: '国际创新园',
                area: 98000,
                ds: 9,
                czmj: 56900,
                rooms: 1908,
                ruzys: 80,
                ydl: 2000,
                ysl: 12900,
                jnjp: 100,
            },
            model: 'wisdom_park.v_wp_floor_stat',
            floorPicDialogVisible: false,
            floorPicOption: {
                labelWidth: 0,
                submitBtn: false,
                emptyBtn: false,
                disabled: true,
                column: [
                    {
                        label: '',
                        prop: 'imgUrl',
                        type: 'upload',
                        listType: 'picture-card',
                        loadText: '附件上传中，请稍等',
                        span: 24,
                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '只能上传jpg/png文件，且不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },
                ],
            },
            imgform: {
                imgUrl: [],
            },
            currentFloor: {},
        };
    },
    props: {
        floor: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },

    watch: {
        floor: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.onLoad();
                }
            },
        },
    },
    computed: {},
    mounted() {
        this.onLoad();
    },
    methods: {
        openFloorPicDialogVisible(item) {
            this.currentFloor = item;
            this.imgform = {};
            this.floorPicDialogVisible = true;
            if (item.floor_pics) {
                this.getpicList(item.floor_pics);
            }
        },
        closeFloorPicDia() {
            this.floorPicDialogVisible = false;
        },
        getpicList(ids) {
            getPicListByIds(ids).then(res => {
                if (res.data.code == 200) {
                    let arr = [];
                    res.data.data.forEach(pic => {
                        arr.push({
                            label: pic.id,
                            value: pic.link,
                        });
                    });
                    this.imgform.imgUrl = arr;
                }
            });
        },
        onLoad() {
            let params = {
                wheres: [
                    {
                        field: 'floor_id',
                        value: this.floor.id,
                    },
                ],
            };
            getMetaCommonList(this.model, {
                current: 1,
                size: 500,
                ...params,
            }).then(res => {
                const data = res.data.data;
                this.graden = data.records[0];
                if (this.graden.sum_free_area) {
                    this.graden.sum_free_area = this.graden.sum_free_area.toFixed(2);
                }
                if (this.graden.sum_checkin_area) {
                    this.graden.sum_checkin_area = this.graden.sum_checkin_area.toFixed(2);
                }
                if (this.graden.sum_floor_area) {
                    this.graden.sum_floor_area = this.graden.sum_floor_area.toFixed(2);
                }
            });
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.thsj-tj {
    font-size: 18px;
}
.text-title {
    margin-top: 6px;
    color: rgba(0, 0, 0, 0.5);
    display: block;
}
.title {
    padding: 15px 10px 0px 10px;
}
.bnd {
    position: absolute;
    top: 10px;
    color: rgba(0, 0, 0, 0.5);
}
.b-l {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.lygk {
    width: 100%;
    overflow: auto;
    width: 800px;
    display: flex;
}
.o-a {
    overflow: auto;
}
</style>

<template>
  <div ref="chartContainer" class="w-100p h-100p"></div>
</template>
<script>

import * as echarts from 'echarts';
import WidgetMixins from '../widget-mixins'

import { assign } from '../../util';

export default {
  mixins: [ WidgetMixins ],
  name: 'BuiltInWidgetByEcharts',
  data(){
    return {
      chartOption: null,
      chartInstance: null
    }
  },
  mounted(){
    if(!this.chartInstance){
      this.chartInstance = echarts.init(this.$refs.chartContainer);
    }
    this.setChartOption();
  },
  methods: {
    setChartOption(clear){
      if(this.chartInstance){
        if(clear){
          this.chartInstance.clear();
        }
        console.log(this.chartOption);
        this.chartInstance.setOption(this.chartOption);
      }
    },
    refresh(type){
      if(this.widget){
        if(['widget.private' , 'widget.dataOption' , 'data'].includes(type)){
          if(this.chartOption && this.widget && this.widget.private && this.widget.private.chartType !== this.chartOption.chartType){
            this.setChartOption(true);
          }
          this.setOption();
        }
      }
    },
    setOption(){
      let option = JSON.parse(JSON.stringify(this.widget.private));
      option['color'] = this.widget.private.color ? this.widget.private.color['color'] : null;
      if(!option['color'] || !option['color'].length){
        delete option['color'];
      }
      this.setSeries(option);
      this.setAxis(option);

      this.chartOption = option;
      this.setChartOption();
    },
    setAxis(option){

      const dataOption = this.widget.dataOption || null , data = Array.isArray(this.data) ? this.data : [];
      let category = [] , categoryAxis = '';

      ['xAxis' , 'yAxis'].forEach(
        item => {
          if(this.widget.private[item] && this.widget.private[item].type === 'category'){
            categoryAxis = item;
          }
        }
      )
      if(dataOption && dataOption['category']){
          data.forEach(
            item => {
              category.push(item[dataOption['category']]);
            }
          )
      }

      if(categoryAxis){
        option[categoryAxis] = option[categoryAxis] || {
          type: 'category',
          data: []
        }
        option[categoryAxis]['data'] = category;
      }

    },
    getSeriesMoreOption(option){
      if(option['_series']){
        return option['_series'];
      }
    },
    setSeries(option){
      const dataOption = this.widget.dataOption || null , data = Array.isArray(this.data) ? this.data : [];
      const series = [] , chartType = option['chartType'];
      const moreOption = this.getSeriesMoreOption(option);
      if(dataOption && dataOption['value']){
        if(Array.isArray(dataOption['value'])){
          dataOption['value'].forEach(
            item => {
              let seriesItem = {
                type: chartType,
                name: item['name'],
                data: [],
                ...moreOption
              }
              data.forEach(
                dataItem => {
                  seriesItem.data.push(dataItem[item['value']] || null);
                }
              )
              series.push(seriesItem);
            }
          )
        }
      }else if(dataOption && dataOption['multiValue']){
        let seriesItem = {
          type: chartType,
          // name: item['name'],
          data: [],
          ...moreOption
        }
        let itemData = Array.isArray(data) ? (data.length ? data[0] : null) : data;
        if(Array.isArray(dataOption['multiValue'])){
          dataOption['multiValue'].forEach(
            item => {
              if(itemData){
                seriesItem.data.push({
                  name: item['name'],
                  value: itemData[item['value']]
                })
              }
            }
          )
        }
        series.push(seriesItem);
      }

      option['series'] = series;
    }
  }
}
</script>
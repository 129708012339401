<template>
  <div>
    <basic-container class="cards">
      <el-form :inline="true" :model="formInline" size="small" class="demo-form-inline">
        <el-form-item label="时间">
          <el-date-picker class="w-150" @change="changeData" :picker-options="startTime" v-model="formInline.startDate" placeholder="开始日期">
          </el-date-picker>
        </el-form-item>
        <el-form-item label="-" width="300px">
          <el-date-picker class="w-150" @change="changeData" :picker-options="endtTime" v-model="formInline.endDate" placeholder="结束日期">
          </el-date-picker>
        </el-form-item>
        <!-- <el-form-item label="时间">
          <el-date-picker
            class="w-150"
            @change="changeData"
            v-model="formInline.startDate"
            placeholder="开始日期"
          >
          </el-date-picker>
        </el-form-item> -->
        <el-form-item label="所属区域" prop="name">
          <el-select v-model="formInline.subPartId" size="small" filterable clearable @change="getBuildList" placeholder="请选择区域">
            <template v-for="(item, index) in partList">
              <el-option v-if="item.parent_id != 0" :key="index" :label="item.name" :value="item.id"> </el-option>
            </template>
          </el-select>
        </el-form-item>
        <el-form-item label="楼宇">
          <el-select
            filterable
            v-model="formInline.buildingId"
            clearable
            class="w-200"
            size="small"
            @change="getCompanyList"
            placeholder="请选择所属楼宇"
          >
            <el-option v-for="(item, index) in buildList" :key="index" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业">
          <el-select
            v-model="formInline.companyId"
            clearable
            class="w-200"
            filterable
            size="small"
            @change="getContractList"
            placeholder="请选择企业"
            @clear="clearCom"
          >
            <el-option v-for="(item, index) in companyList" :key="index" :label="item.name" :value="item.company_id"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="合同">
          <el-select
            class="w-200"
            v-model="formInline.contractId"
            @change.native="selectBlur"
            @blur.native="selectBlur"
            clearable
            filterable
            size="small"
            placeholder="请选择合同"
          >
            <el-option v-for="(item, index) in contractList" :key="index" :label="item.contract_code" :value="item.contract_id"> </el-option>
          </el-select>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="search">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button class="pull-right" type="primary" plain @click="exportTableClick" icon="el-icon-download" size="small">导出</el-button>
        </el-form-item>
      </el-form>
    </basic-container>
    <basic-container isCard>
      <div class="fts-16 text-center mar-b-10">{{ formInline.startDate }} 至 {{ formInline.endDate }}{{ partName }}房租收费</div>
      <el-table
        class="fixed-table"
        id="excelTableDiv"
        :data="tableData"
        style="width: 100%"
        v-loading="isLoading"
        element-loading-text="加载中，请稍后..."
        :height="height"
        border
      >
        <!-- <el-table-column header-align="center" :label="`${formInline.startDate}年${partName}房租收费`"> -->
        <el-table-column fixed type="index" header-align="center" width="50"> </el-table-column>
        <el-table-column fixed prop="contract_code" label="合同号" align="left" header-align="center" width="220"> </el-table-column>

        <el-table-column fixed prop="renters_name" label="企业名称" align="left" header-align="center" width="260"> </el-table-column>
        <el-table-column fixed prop="room_name" label="租赁位置" align="left" header-align="center" width="140"> </el-table-column>

        <el-table-column prop="room_area" align="right" header-align="center" label="租赁面积(m²)" width="100">
          <template slot-scope="{ row }">
            {{ toqfw(row.room_area) }}
          </template>
        </el-table-column>
        <el-table-column prop="sd_date" align="center" label="合同起止时间" width="200"> </el-table-column>
        <el-table-column prop="" align="center" label="账单缴费时间">
          <el-table-column prop="receivable_date_y" align="center" label="年" width="80"> </el-table-column>
          <el-table-column prop="receivable_date_m" align="center" label="月" width="80"> </el-table-column>
          <el-table-column prop="receivable_date_d" align="center" label="日" width="80"> </el-table-column>
        </el-table-column>
        <el-table-column prop="sd_time" align="left" header-align="center" label="账单周期" width="200"> </el-table-column>
        <el-table-column prop="is_enjoy_policy" align="center" header-align="center" label="是否享受政策" width="50">
          <template slot-scope="{ row }">
            <template v-if="row.is_enjoy_policy == 1"> 是 </template>
            <template v-if="row.is_enjoy_policy == 0"> 否 </template>
          </template>
        </el-table-column>
        <el-table-column prop="" align="center" header-align="center" label="本期应收账单应收金额" width="">
          <el-table-column prop="zc_ys" align="right" header-align="center" label="政策金额(元)" width="150">
            <template slot-scope="{ row }">
              {{ toqfw(row.zc_ys) }}
            </template>
          </el-table-column>
          <el-table-column prop="qy_ys" align="right" header-align="center" label="正常缴费(元)" width="150">
            <template slot-scope="{ row }">
              {{ toqfw(row.qy_ys) }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="" align="" header-align="center" label="本期账单实收金额" width="">
          <el-table-column prop="" align="" header-align="center" label="房租减免金额(疫情等)" width="160"> </el-table-column>
          <el-table-column prop="zc_ss" align="right" header-align="center" label="政策金额(元)" width="150">
            <template slot-scope="{ row }">
              {{ toqfw(row.zc_ss) }}
            </template>
          </el-table-column>
          <el-table-column prop="qy_ss" align="right" header-align="center" label="正常缴费(元)" width="150">
            <template slot-scope="{ row }">
              {{ toqfw(row.qy_ss) }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="" align="" header-align="center" label="本期账单欠费" width="">
          <el-table-column prop="zc_bq_qf" align="right" header-align="center" label="政策金额(元)" width="150">
            <template slot-scope="{ row }">
              {{ toqfw(row.zc_bq_qf) }}
            </template>
          </el-table-column>
          <el-table-column prop="qy_bq_qf" align="right" header-align="center" label="正常缴费(元)" width="150">
            <template slot-scope="{ row }">
              {{ toqfw(row.qy_bq_qf) }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="" align="" header-align="center" label="往期欠费" width="">
          <el-table-column prop="zc_wq_qf" align="right" header-align="center" label="政策金额(元)" width="150">
            <template slot-scope="{ row }">
              {{ toqfw(row.zc_wq_qf) }}
            </template>
          </el-table-column>
          <el-table-column prop="qy_wq_qf" align="right" header-align="center" label="正常缴费(元)" width="150">
            <template slot-scope="{ row }">
              {{ toqfw(row.qy_wq_qf) }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="" align="center" label="到款时间">
          <el-table-column align="center" label="年" width="80">
            <template slot-scope="{ row }">
              <template>{{ row.received_time ? row.received_time.split("-")[0] : "" }}</template>
            </template>
          </el-table-column>
          <el-table-column align="center" label="月" width="80">
            <template slot-scope="{ row }">
              <template>{{ row.received_time ? row.received_time.split("-")[1] : "" }}</template>
            </template>
          </el-table-column>
          <el-table-column align="center" label="日" width="80">
            <template slot-scope="{ row }">
              <template>{{ row.received_time ? row.received_time.split("-")[2].substring(0, 2) : "" }}</template>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="" align="center" label="开票时间">
          <el-table-column align="center" label="年" width="80">
            <template slot-scope="{ row }">
              <template>{{ row.invoice_date ? row.invoice_date.split("-")[0] : "" }}</template>
            </template>
          </el-table-column>
          <el-table-column align="center" label="月" width="80">
            <template slot-scope="{ row }">
              <template>{{ row.invoice_date ? row.invoice_date.split("-")[1] : "" }}</template>
            </template>
          </el-table-column>
          <el-table-column align="center" label="日" width="80">
            <template slot-scope="{ row }">
              <template>{{ row.invoice_date ? row.invoice_date.split("-")[2] : "" }}</template>
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="" align="" header-align="center" label="累计欠费" width="">
          <el-table-column prop="zc_lj_qf" align="right" header-align="center" label="政策金额(元)" width="150">
            <template slot-scope="{ row }">
              {{ toqfw(row.zc_lj_qf) }}
            </template>
          </el-table-column>
          <el-table-column prop="qy_lj_qf" align="right" header-align="center" label="正常缴费(元)" width="150">
            <template slot-scope="{ row }">
              {{ toqfw(row.qy_lj_qf) }}
            </template>
          </el-table-column>
        </el-table-column>
        <el-table-column prop="bz" width="150" label="备注"> </el-table-column>
        <!-- </el-table-column> -->
      </el-table>
    </basic-container>
  </div>
</template>

<script>
import { getMetaCommonList } from "@/api/busiMode/metaCommon"
import Vue from "vue"
import XLSXS from "xlsx-style"
import FileSaver from "file-saver"
import dayjs from "dayjs"
import { getLeaseDetails } from "@/api/reportForms/reportForms"
const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight
export default {
  data() {
    return {
      height: viewPortHeight - 320,
      formInline: {
        startDate: "2023-01-01",
        endDate: "2023-12-30",
      },
      value1: "2021",
      tableData: [],
      buildList: [],
      companyList: [],
      isLoading: false,
      partList: [],
      contractList: [],
      partName: "",
      jdTitle: "全年",
      startTime: {
        disabledDate: (time) => {
          if (this.formInline.endDate) {
            return time.getTime() > dayjs(this.formInline.endDate)
          }
        },
      },
      endtTime: {
        disabledDate: (time) => {
          if (this.formInline.startDate) {
            //return dayjs(this.formInline.startDate).format('YYYY-MM-DD');
            return time.getTime() < dayjs(this.formInline.startDate)
          }
        },
      },
    }
  },
  watch: {
    value1: {
      handler: function (newValue, oldName) {
        if (newValue) {
          this.value1 = dayjs(newValue).format("YYYY")
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.formInline.startDate = dayjs().format("YYYY") + "-01-01"
    this.formInline.endDate = dayjs().format("YYYY-MM-DD")
    this.getPart()
    this.search()
  },

  methods: {
    toqfw(num) {
      if (Number(num)) {
        let n = num
        n = this.addDou(Number(num).toFixed(2))
        return n
      } else {
        return num
      }
    },

    addDou(value) {
      var isNegative = value < 0
      value = Math.abs(value).toString()
      var decimalIndex = value.indexOf(".")
      if (decimalIndex === -1) {
        decimalIndex = value.length
      }
      for (var i = decimalIndex - 3; i > 0; i -= 3) {
        value = value.slice(0, i) + "," + value.slice(i)
      }
      if (isNegative) {
        value = "-" + value
      }
      return value
    },
    selectBlur(e) {
      console.log(e)
      Vue.set(this.formInline, "contractId", e.target.value)
    },
    clearCom() {
      delete this.formInline.company_id
      delete this.formInline.contractId
      this.contractList = []
      this.$forceUpdate()
    },
    quarterSort() {
      this.formInline.quarter = this.formInline.quarter.sort()
    },
    changeData() {
      if (this.formInline.startDate) {
        this.formInline.startDate = this.formatDate(this.formInline.startDate)
      } else {
        this.formInline.startDate = ""
      }
      if (this.formInline.endDate) {
        this.formInline.endDate = this.formatDate(this.formInline.endDate)
      } else {
        this.formInline.endDate = ""
      }
    },
    formatDate(createTime) {
      let obj = ""
      if (createTime) {
        if (createTime) {
          obj = dayjs(createTime).format("YYYY-MM-DD")
        }
      }
      return obj
    },
    changejd() {
      console.log(this.formInline.jd)
    },
    search() {
      if (this.formInline["startDate"]) {
        this.formInline["startDate"] = dayjs(this.formInline["startDate"]).format("YYYY-MM-DD")
      }
      this.getStatFinanceCalculateArea(this.formInline)
    },
    getStatFinanceCalculateArea(data) {
      let o = {}
      o = JSON.parse(JSON.stringify(data))
      if (data) {
        if (this.formInline.startDate) {
          this.formInline.startDate = dayjs(this.formInline.startDate).format("YYYY-MM-DD")
        } else {
          this.formInline.startDate = ""
        }
        if (this.formInline.endDate) {
          this.formInline.endDate = dayjs(this.formInline.endDate).format("YYYY-MM-DD")
        } else {
          this.formInline.endDate = ""
        }
      }
      this.isLoading = true
      getLeaseDetails(o)
        .then((res) => {
          this.tableData = []
          this.tdList = []

          const data = res.data
          let tableData = data.data.resultDetail
          if (data.data.resultDetail.length != 0) {
            let totalArea = {
              contract_code: "汇总",
            }
            for (let key in data.data.resultTotal) {
              if (Number.isFinite(data.data.resultTotal[key])) {
                totalArea[key] = data.data.resultTotal[key].toFixed(2)
              } else {
                totalArea[key] = data.data.resultTotal[key]
              }
            }
            tableData.push(totalArea)
            this.tableData = tableData
            console.log(this.tableData)
            this.tdList = data.data.month
          }
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },

    getPart() {
      let body = {
        current: 1,
        size: 100,
        orders: [{ field: "sort", sort: "ASC" }],
      }
      getMetaCommonList("wisdom_park.wp_part", body).then((res) => {
        const data = res.data.data
        this.partList = data.records
      })
    },
    getBuildList(e) {
      this.formInline.buildingId = ""
      this.partList.forEach((e) => {
        if (e.id == this.formInline.subPartId) {
          this.partName = e.name
        }
      })
      getMetaCommonList("wisdom_park.wp_building", {
        size: 100,
        current: 1,
        wheres: [{ field: "sub_part_id", value: this.formInline.subPartId }],
      }).then((res) => {
        const data = res.data.data
        this.buildList = data.records
      })
    },
    getCompanyList() {
      getMetaCommonList("wisdom_park_statistics.v_wp_company_building_stat", {
        size: 100,
        current: 1,
        wheres: [{ field: "building_id", value: this.formInline.buildingId }],
      }).then((res) => {
        const data = res.data.data
        this.companyList = data.records
      })
    },
    getContractList() {
      if (this.formInline.companyId) {
        getMetaCommonList("wisdom_park_statistics.v_company_contract_stat", {
          size: 100,
          current: 1,
          wheres: [{ field: "company_id", value: this.formInline.companyId }],
        }).then((res) => {
          const data = res.data.data
          this.contractList = data.records
        })
      }
    },

    mergeTableRow(data, merge) {
      if (!merge || merge.length === 0) {
        return data
      }
      merge.forEach((m) => {
        const mList = {}
        data = data.map((v, index) => {
          const rowVal = v[m]
          if (mList[rowVal] && mList[rowVal].newIndex === index) {
            mList[rowVal]["num"]++
            mList[rowVal]["newIndex"]++
            data[mList[rowVal]["index"]][m + "-span"].rowspan++
            v[m + "-span"] = {
              rowspan: 0,
              colspan: 0,
            }
          } else {
            mList[rowVal] = { num: 1, index: index, newIndex: index + 1 }
            v[m + "-span"] = {
              rowspan: 1,
              colspan: 1,
            }
          }
          return v
        })
      })
      return data
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      const span = column["property"] + "-span"
      if (row[span]) {
        return row[span]
      }
    },
    // 点击事件触发的方法
    exportTableClick() {
      // 首先加载  <table id='excelTable'> 标签 为 workbook对象
      var fix = document.querySelector(".el-table__fixed")
      var workbook
      // 判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
      if (fix) {
        /* 从表生成工作簿对象 */
        workbook = this.$XLSX.utils.table_to_book(document.querySelector("#excelTableDiv").removeChild(fix))
        document.querySelector("#excelTableDiv").appendChild(fix)
      } else {
        workbook = this.$XLSX.utils.table_to_book(document.querySelector("#excelTableDiv"))
      }
      /* 获取二进制字符串作为输出 */
      // var wbout = XLSX.write(wb, {
      //     bookType: 'xlsx',
      //     bookSST: true,
      //     type: 'array',
      // });

      //let workbook = this.$XLSX.utils.table_to_book(document.getElementById('excelTableDiv'), { raw: true });
      // 先定义列宽 ， 我这里文件一共有7 列 ，所以设置7列宽度相等都为 20 ，如果你有很多列建议直接 map()
      let wscols = [
        { wch: 5 },
        { wch: 20 },
        { wch: 20 },
        { wch: 25 },
        { wch: 25 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
        { wch: 15 },
      ]

      // 获取 需要设置样式的 sheet ，我这里只有 一个 sheet 所以索引默认加载了第一个
      const sheet = workbook.Sheets[workbook.SheetNames[0]]
      console.log(sheet)
      // 设置列宽
      sheet["!cols"] = wscols
      // 定义框线样式
      const borderAll = {
        color: { auto: 1 },
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
        right: { style: "thin" },
      }

      // 这里的意思为 先默认代表表格的 7 个列  的 列号
      // 比如 A2 意思是 第一列 第2行
      const _letterList = ["A", "B", "C", "D", "E", "F", "G"]
      // 定义一个 箭头函数，接受三个参数，分别为 当前Sheet , 行列号（例如：‘A2’）, 是否是新增样式
      const _mapCellStyle = (_sheet, _key, _type) => {
        const _cellStyle = {
          border: borderAll,
          alignment: {
            wrapText: true,
            horizontal: "center",
            vertical: "center",
          },
          font: {
            name: "微软雅黑",
            sz: 10,
            height: 50,
          },
          height: 50,
          bold: true,
          numFmt: "0",
        }

        const _title = {
          // border: borderAll,
          alignment: {
            wrapText: true,
            horizontal: "center",
            vertical: "center",
          },
          font: {
            name: "微软雅黑",
            sz: 16,
          },
          bold: false,
          numFmt: "0",
        }
        if (_key == "A1") {
          if (_type === "append") {
            // 需要新增样式，说明当前加载的sheet中并不存在该单元格，可能当前单元格被前边合并，这种被合并的单元格也得设置样式，
            // 否则就会出现 合并单元格只有第一格带框线，后边没框线的情况出现，所以这里需要将后边的边框样式也加上。
            _sheet[_key] = {
              s: _title,
            }
          } else {
            // 若不是新增样式 则代表sheet中已存在该表格直接修改其 s 属性即可使属性生效
            if (typeof _sheet[_key] === "object") {
              _sheet[_key].s = _title
            }
          }
        } else {
          if (_type === "append") {
            // 需要新增样式，说明当前加载的sheet中并不存在该单元格，可能当前单元格被前边合并，这种被合并的单元格也得设置样式，
            // 否则就会出现 合并单元格只有第一格带框线，后边没框线的情况出现，所以这里需要将后边的边框样式也加上。
            _sheet[_key] = {
              s: _cellStyle,
            }
          } else {
            // 若不是新增样式 则代表sheet中已存在该表格直接修改其 s 属性即可使属性生效
            if (typeof _sheet[_key] === "object") {
              _sheet[_key].s = _cellStyle
            }
          }
        }
      }

      // sheet 不懂得可以单独打印一下，它其实就是一个对象，键代表行列号（‘A2’），值为该单元格的值，样式等，
      // 我们需要做的就是修改其值中的样式
      Object.keys(sheet).forEach((i, _) => {
        // 无用属性直接过滤
        if (i !== "!ref" || i !== "!cols" || i !== "!merges" || i !== "Am") {
          // 首先设置遍历到的 当前 key
          let _nowKey = i
          // 然后调用 _mapCellStyle  渲染当前单元格样式
          _mapCellStyle(sheet, _nowKey)
          // 我们这里先获取下一个行列号  例如当前_nowKey 是 A1  这里就是获取到 B1 ,及 当前行的 下一列数据
          let _nextKey = _letterList[_letterList.indexOf(_nowKey[0]) + 1] + i.slice(1)

          // 判断 B1 是否在 Sheet的key中,如果不存在，只可能是因为B1所在单元格已经被A1所合并，所以我们需要将B1也调用一下  _mapCellStyle
          // 渲染 B1 的样式，不然就会出现 A1 B1 这两格是合并的状态，只有A1有框线，而B1 没有框线的情况。
          // 这里用 while 就是 要把需要合并的单元格全部 渲染上样式，直到可能 B4 在 Sheet中 停止
          while (_nowKey[0] !== "G" && !Object.keys(sheet).includes(_nextKey)) {
            _mapCellStyle(sheet, _nextKey, "append")
            // 这里就是简单的交换索引
            _nowKey = _nextKey
            _nextKey = _letterList[_letterList.indexOf(_nowKey[0]) + 1] + _nowKey.slice(1)
          }
        }
      })

      let wopts = {
        bookType: "xlsx",
        bookSST: false,
        type: "buffer",
      }
      let _blob = XLSXS.write(workbook, wopts)

      FileSaver.saveAs(
        new Blob([_blob], {
          type: "application/octet-stream",
        }),
        this.formInline.startDate + "至" + this.formInline.endDate + "房租收费表" + new Date().getTime() + ".xlsx"
      )
    },
  },
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
::v-deep .el-table__fixed {
  // 左固定列
  height: auto !important;
  bottom: 12px; // 改为自动高度后，设置与父容器的底部距离，高度会动态改变，值可以设置比滚动条的高度稍微大一些,这个根据实际情况改
}
::v-deep .el-table__fixed-right {
  // 右固定列
  height: auto !important;
  bottom: 12px;
}
/deep/.el-table thead.is-group th {
  background: #fafafa;
}
/deep/ .el-form-item--small.el-form-item {
  margin-bottom: 18px !important;
}
.bg-white {
  background-color: #fff !important;
}

.cards {
  background: #fff;
  padding: 15px 10px 0px 10px !important;
  padding-right: 0px !important;
  margin-bottom: 15px;
}
.ft500 {
  font-weight: 700;
}
.fixed-table {
  width: 100%;
  border-collapse: collapse;
}

.fixed-table th,
.fixed-table td {
  text-align: left;
  padding: 8px;
  border: 1px solid #ddd;
}
.fixed-column {
  position: sticky;
  left: 0;
  background-color: white;
  z-index: 1;
}
</style>

<template>
  <div class="mar-t-5f">
    <buildList @oneBuild="oneBuild" :houseingRes="houseingRes"></buildList>
  </div>
</template>

<script>
import { saveMetaCommon } from "@/api/busiMode/metaCommon";
import standardService from "@/api/metadata/standard";
import { mapGetters } from "vuex";
import buildList from "./buildList1.vue";

export default {
  components: { buildList },

  data() {
    return {
      houseingRes: "houseingRes",
      selections: [],
      houseModel: "wisdom_park.wp_housing_resources",
    };
  },
  computed: {},
  mounted() {},
  methods: {
    oneBuild(e) {
      this.selections = e;
    },
    save() {
      this.saveMetaCommon(this.selections);
    },
    async saveMetaCommon(data) {
      var ary = [];
      data.forEach((v, index) => {
        let obj = {
          name: v.name,
          area: v.floorage,
          address: v.address,
          renovation: v.renovation,
          attachment: v.attachment_id,
          property_management_fee: v.property_management_fee,
          payment_type: v.payment_type,
          subject_type: 2,
          subject_id: v.id,
          building_name: v.name,
          building_id: v.id,
        };
        const res = saveMetaCommon(this.houseModel, {
          values: obj,
        }).then((_res) => {
          if (_res.code == 200) {
            return {
              id: index + 1,
              imgs: _res.data,
            };
          }
          return "";
        });
        ary.push(res);
      });
      await Promise.all(ary).then((result) => {
        this.$emit("close");
      });
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped></style>

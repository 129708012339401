<template>
  <div>
    <!-- 饼形图展示 -->
    <div class="cockpit-right-half flex">
      <div class="right-half-div" style="margin-right: 10px">
        <div class="cockpit-title">
          园区报修工单分析
          <img src="../../../public/assets/assets/img/more.png" class="more" />
        </div>
        <div class="pie-content">
          <p class="f14">报修工单类型占比分析</p>
          <div id="left-pie" class="pie"></div>
        </div>
      </div>
      <div class="right-half-div">
        <div class="cockpit-title">
          园区问题反馈分类统计
          <img src="../../../public/assets/assets/img/more.png" class="more" />
        </div>
        <div class="pie-content">
          <p class="f14">园区问题反馈分类统计</p>
          <div id="right-pie" class="pie"></div>
        </div>
      </div>
    </div>

    <!-- 人流量分析 -->
    <div class="cockpit-right-middle">
      <div class="cockpit-title mt">
        人流量分析
        <img src="../../../public/assets/assets/img/more.png" class="more" />
      </div>
      <div class="cockpit-right-middle-content">
        <p class="desc f14">
          近七日人流量总数<span>{{ personTotle }}</span
          >人次
        </p>
        <div class="flex mt20">
          <div class="middle-half">
            <div class="flex f14">
              <i class="cockpit-icon icon_2"></i>
              近七日人流量时间分布
            </div>
            <div id="lineChart" class="line-chart"></div>
          </div>
          <div class="middle-half bar-right">
            <div class="flex f14">
              <i class="cockpit-icon icon_2"></i>
              近七日人流量楼宇分布
            </div>
            <div id="barChart" class="line-chart"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- 车流量分析 -->
    <div class="cockpit-right-middle">
      <div class="cockpit-title mt">
        车流量分析
        <img src="../../../public/assets/assets/img/more.png" class="more" />
      </div>
      <div class="cockpit-right-middle-content">
        <p class="desc f14">
          近七日停车场进出车辆<span>{{ carTotle }}</span
          >辆次
        </p>
        <div class="mt20">
          <div class="middle-all">
            <div class="flex f14">
              <i class="cockpit-icon icon_2"></i>
              近七日进出车辆数时间分布
            </div>
            <div id="lineChartBtm" class="line-chart"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from "echarts/core";
import fullscreen from "vue-fullscreen";
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, PieChart, LineChart } from "echarts/charts";
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  LegendComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
} from "echarts/components";
// 标签自动布局、全局过渡动画等特性
import { LabelLayout, UniversalTransition } from "echarts/features";
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from "echarts/renderers";
import { getMetaCommonList } from "@/api/busiMode/metaCommon";

// 注册必须的组件
echarts.use([
  TitleComponent,
  LegendComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  PieChart,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
]);

export default {
  data() {
    return {
      carTotle: 0,
      personTotle: 0,
      /** echarts 数据 **/
      leftPie: "" /** 右侧左上饼形图 **/,
      rightPie: "" /** 右侧右上饼形图 **/,
      lineChart: "" /** 右侧中间折线图 **/,
      barChart: "" /** 右侧中间柱状图 **/,
      lineChartBtm: "" /** 右侧底部折线图 **/,
      color: ["#00FFAC", "#00D5FF", "#40B3FF", "#F382FF", "#FF3994", "#FFF7C1"],
      /** 横坐标 白色显示 **/
      axisLabel: {
        show: true,
        color: "#FFF",
        interval: 0,
      },
      /** 纵坐标分割提升线样式 **/
      splitLine: {
        show: true,
        lineStyle: {
          color: "#213456",
          type: "dashed",
          width: "1",
        },
      },

      /** 折线/折点/阴影样式(渐变色) **/
      itemStyle: {
        color: "rgba(2, 25, 68, 1)",
        borderWidth: "1",
        borderType: "solid",
        shadowColor: "#0098DB",
        shadowBlur: 2,
      },
    };
  },

  mounted() {
    /** 初始化 Echarts 的饼状图 柱状图 折线图 **/
    /** 右侧 左上 报修工单类型占比分析饼图 **/
    this.leftPie = echarts.init(document.getElementById("left-pie"));
    this.leftPie.setOption({
      title: this.initPieTitle("报修总数", "20"),
      legend: {
        // 配置图例
        type: "plain",
        show: true,
        left: "45%",
        top: "10%",
        width: "50%",
        orient: "vertical", // 展示方向
        itemGap: 13,
        itemWidth: 20,
        /** 配置 文本样式 **/
        textStyle: {
          color: "#fff",
          fontSize: "11",
        },
        /** 格式化文本显示内容 **/
        formatter: (name) => {
          return this.pieFormatter(name, this.leftPie.getOption().series[0]);
        },
      },
      color: this.color,
      series: [
        {
          name: "leftPie",
          type: "pie",
          radius: ["50%", "70%"],
          label: {
            show: false, // 禁止标签显示
          },
          center: ["18%", "50%"], // 设置饼状图在画布中的位置
          data: [],
        },
      ],
    });
    this.getMetaCommonList("v_jsc_wp_repair_stat", "leftPie");

    /** 右侧 左上 报修工单类型占比分析饼图 **/
    this.rightPie = echarts.init(document.getElementById("right-pie"));
    this.rightPie.setOption({
      title: this.initPieTitle("反馈问题总数", "30"),
      legend: {
        // 配置图例
        type: "plain",
        show: true,
        left: "45%",
        top: "10%",
        width: "50%",
        orient: "vertical", // 展示方向
        itemGap: 13,
        itemWidth: 20,
        /** 配置 文本样式 **/
        textStyle: {
          color: "#fff",
          fontSize: "11",
        },
        /** 格式化文本显示内容 **/
        formatter: (name) => {
          return this.pieFormatter(name, this.rightPie.getOption().series[0]);
        },
      },
      color: this.color,
      series: [
        {
          name: "rightPie",
          type: "pie",
          radius: ["50%", "70%"],
          label: { show: false }, // 禁止标签显示
          center: ["18%", "50%"], // 设置饼状图在画布中的位置
          data: [],
        },
      ],
    });
    this.getMetaCommonList("v_jsc_wp_problem_stat", "rightPie");

    /** 右侧中间 折线图 **/
    this.lineChart = echarts.init(document.getElementById("lineChart"));
    this.lineChart.setOption({
      xAxis: {
        type: "category",
        // boundaryGap: false,
        data: [],
        axisLabel: {
          // ...this.axisLabel,
          // rotate: 38,
          color: "#333",
          // interval: 0,
          formatter: function (value) {
            let obj = value.split("-");
            return obj[1] + "-" + obj[2];
          },
        },
      },
      yAxis: {
        type: "value",
        splitLine: this.splitLine,
      },
      series: [
        {
          data: [],
          type: "line",
          center: ["10%", "20%"],
          /** 折线/折点/阴影样式(渐变色) **/
          itemStyle: this.itemStyle,
          lineStyle: {
            color: "rgba(255,255,255,0.5)",
            width: "1",
          },
          areaStyle: {
            color: this.gradientColor(
              "linear",
              "rgba(0,238,255,0.7)",
              "rgba(0,238,255,0)"
            ),
          },
          toolTips: {},
          label: {
            show: true,
            fontSize: "10",
            color: "#fff",
          },
        },
      ],
    });
    this.getMetaCommonList("v_stat_summary_stat", "lineChart");
    this.barChart = echarts.init(document.getElementById("barChart"));
    this.barChart.setOption({
      tooltip: {},
      // dataZoom : [
      //   {
      //       type: 'slider',
      //       show: true,
      //       startValue: 0,
      //       endValue: 15,
      //   },
      //   {
      //       type: 'inside',
      //       start: 94,
      //       end: 100
      //   },
      //   {
      //       type: 'slider',
      //       show: true,
      //       yAxisIndex: 0,
      //       filterMode: 'empty',
      //       width: 12,
      //       height: '70%',
      //       handleSize: 8,
      //       showDataShadow: false,
      //       left: '93%'
      //   }
      // ],
      xAxis: {
        data: [],
        axisLabel: {
          // ...this.axisLabel,
          rotate: 40,
          color: "#333",
          //  让x轴文字方向为竖向
          interval: 0,
          // formatter: function (value) {
          //   return value.split("").join("\n");
          // },
        },
        axisTick: {
          show: true,
          alignWithLabel: true,
          interval: 0,
          length: 10,
        },
      },
      yAxis: {
        type: "value",
        splitLine: this.splitLine,
      },
      series: [
        {
          name: "流量",
          type: "bar",
          data: [],
          showBackground: true,
          barWidth: "15",
          backgroundStyle: {
            color: "RGBA(26, 44, 77, 1)",
          },
          // 配置柱状图背景色
          color: this.gradientColor(
            "linear",
            "rgba(0, 238, 255, 1)",
            "rgba(0, 238, 255, 0.1)"
          ),
          fontSize: "16",
        },
      ],
    });
    this.getMetaCommonList("v_jsc_wp_summary_building_stat", "barChart");

    /** 右侧底部折线图 **/
    this.lineChartBtm = echarts.init(document.getElementById("lineChartBtm"));
    this.lineChartBtm.setOption({
      xAxis: {
        type: "category",
        // boundaryGap: false,
        data: [],
        axisLabel: this.axisLabel,
      },
      yAxis: {
        type: "value",
        splitLine: this.splitLine,
      },
      series: [
        {
          data: [],
          type: "line",
          center: ["10%", "20%"],
          /** 折线/折点/阴影样式(渐变色) **/
          itemStyle: this.itemStyle,
          lineStyle: {
            color: "rgba(255,255,255,0.5)",
            width: "1",
          },
          areaStyle: {
            color: this.gradientColor(
              "linear",
              "rgba(0,238,255,0.7)",
              "rgba(0,238,255,0)"
            ),
          },
          toolTips: {},
          label: {
            show: true,
            fontSize: "10",
            color: "#fff",
          },
        },
      ],
    });
    this.getMetaCommonList("v_stat_seven_car_records", "lineChartBtm");
  },

  methods: {
    getMetaCommonList(view, name) {
      let b = {
        size: 100,
        current: 1,
      };
      getMetaCommonList("wisdom_park." + view, b).then((res) => {
        const data = res.data.data;
        if (name == "leftPie") {
          let totleNum = 0;
          let pieData = [];

          data.records.forEach((e) => {
            totleNum = totleNum + e.num;
            pieData.push({
              value: Number(e.num / totleNum).toFixed(2),
              name: e.repair_type_name,
            });
          });
          this.leftPie.setOption({
            title: this.initPieTitle("报修总数", totleNum),
          });
          this.leftPie.setOption({
            series: [
              {
                name: "leftPie",
                type: "pie",
                radius: ["50%", "70%"],
                label: {
                  show: false, // 禁止标签显示
                },
                center: ["18%", "50%"], // 设置饼状图在画布中的位置
                data: pieData,
              },
            ],
          });
        }
        if (name == "rightPie") {
          let totleNum = 0;
          let pieData = [];

          data.records.forEach((e) => {
            totleNum = totleNum + e.num;
            pieData.push({
              value: Number(e.num / totleNum).toFixed(2),
              name: e.problem_name,
            });
          });
          this.rightPie.setOption({
            title: this.initPieTitle("反馈问题总数", totleNum),
          });
          this.rightPie.setOption({
            series: [
              {
                name: "leftPie",
                type: "pie",
                radius: ["50%", "70%"],
                label: {
                  show: false, // 禁止标签显示
                },
                center: ["18%", "50%"], // 设置饼状图在画布中的位置
                data: pieData,
              },
            ],
          });
        }
        if (name == "lineChart") {
          let time = [];
          let lineData = [];
          data.records.forEach((e) => {
            time.push(e.days);
            lineData.push(e["in_count"]);
            this.personTotle = this.personTotle + Number(e["in_count"]);
          });
          this.lineChart.setOption({
            xAxis: {
              type: "category",
              data: time,
              axisLabel: this.axisLabel,
            },
            series: [
              {
                data: lineData,
                type: "line",
                center: ["10%", "20%"],
                itemStyle: this.itemStyle,
                lineStyle: {
                  color: "rgba(255,255,255,0.5)",
                  width: "1",
                },
                areaStyle: {
                  color: this.gradientColor(
                    "linear",
                    "rgba(0,238,255,0.7)",
                    "rgba(0,238,255,0)"
                  ),
                },
                toolTips: {},
                label: {
                  show: true,
                  fontSize: "10",
                  color: "#fff",
                },
              },
            ],
          });
        }
        if (name == "barChart") {
          let time = [];
          let lineData = [];
          data.records.forEach((e) => {
            time.push(e.building_name);
            lineData.push(e["in_count"]);
          });
          this.barChart.setOption({
            xAxis: {
              data: time,
              axisLabel: this.axisLabel,
            },
            yAxis: {
              type: "value",
              splitLine: this.splitLine,
            },
            series: [
              {
                name: "流量",
                type: "bar",
                data: lineData,
                showBackground: true,
                barWidth: "15",
                backgroundStyle: {
                  color: "RGBA(26, 44, 77, 1)",
                },
                // 配置柱状图背景色
                color: this.gradientColor(
                  "linear",
                  "rgba(0, 238, 255, 1)",
                  "rgba(0, 238, 255, 0.1)"
                ),
                fontSize: "16",
              },
            ],
          });
        }
        if (name == "lineChartBtm") {
          let time = [];
          let lineData = [];
          data.records.forEach((e) => {
            time.push(e.days);
            lineData.push(e["in_count"]);
            this.carTotle = this.carTotle + Number(e["in_count"]);
          });
          this.lineChartBtm.setOption({
            xAxis: {
              type: "category",
              data: time,
              axisLabel: this.axisLabel,
            },
            series: [
              {
                data: lineData,
                type: "line",
                center: ["10%", "20%"],
                itemStyle: this.itemStyle,
                lineStyle: {
                  color: "rgba(255,255,255,0.5)",
                  width: "1",
                },
                areaStyle: {
                  color: this.gradientColor(
                    "linear",
                    "rgba(0,238,255,0.7)",
                    "rgba(0,238,255,0)"
                  ),
                },
                toolTips: {},
                label: {
                  show: true,
                  fontSize: "10",
                  color: "#fff",
                },
              },
            ],
          });
        }
      });
    },

    // 初始化饼形图图列
    initPieTitle(text, subtext) {
      return {
        text,
        x: "16.5%",
        y: "40%",
        textAlign: "center",
        textStyle: {
          color: "#fff",
          fontSize: 12,
          fontWeight: "normal",
          align: "left",
          width: "140",
        },
        subtext,
        subtextStyle: {
          color: "#fff",
          fontSize: 28,
          fontWeight: "bolder",
          align: "center",
        },
      };
    },
    /** 格式化图例文本文字 **/
    pieFormatter(name, { data }) {
      for (let i = 0; i < data.length; i++) {
        if (name == data[i].name) {
          if (i == data.length - 1)
            return `      ${name}              ${data[i].value}%`;
          else return `      ${name}       ${data[i].value}%`;
        }
      }
    },
    /** 渐变色 **/
    gradientColor(type, startColor, endColor) {
      return {
        type,
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [
          {
            offset: 0,
            color: startColor, // 0% 处的颜色
          },
          {
            offset: 1,
            color: endColor, // 100% 处的颜色
          },
        ],
        global: false,
      };
    },
  },
};
</script>

<style scoped lang='scss'>
/** 标题渐变色样式 **/
.cockpit-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  color: #fff;
  font-weight: bold;
  font-size: 18px;
  background: linear-gradient(
    90deg,
    rgba(0, 238, 255, 0.7) 0%,
    rgba(60, 165, 255, 0) 100%
  );
  img {
    width: 40px;
  }
}
.cockpit-right-half {
  width: 100%;
  justify-content: space-between;
  display: flex;
}
.flex {
  display: flex;
}
.right-half-div {
  height: 285px;
  width: 446px;
  background: url(../../../public/assets/assets/img/bg004.svg) 100%/100%;
}

/** 人流量分析 **/
.cockpit-right-middle {
  width: 100%;
  padding-top: 5px;
  height: 321px;
  background-repeat: no-repeat !important;
  margin-top: 12.5px;
  background: url(../../../public/assets/assets/img/bg005.svg);
}

.cockpit-right-middle .desc span {
  display: inline-block;
  color: #00d5ff;
  margin: 0 10px;
  font-size: 20px;
}

.cockpit-right-middle-content {
  padding: 0px 20px;
  padding-bottom: 0;
}

.middle-half {
  flex: 1;
  width: 430px;
}

.middle-half.bar-right {
  flex-basis: 100px;
}

/** Echarts css样式 **/
/** 饼图 **/
.pie {
  margin-top: 5px;
  height: 200px;
  width: 100%;
}

.pie-content {
  height: 225px;
  margin-top: 0px;
  padding-left: 20px;
}
.pie-content p {
  margin-bottom: 0px !important;
}
.f14 {
  font-size: 14px;
  font-weight: bold;
  margin: 0px;
}

/** 折线图 **/
.line-chart {
  height: 250px;
  transform: translateY(-20px);
}

/** 各种类型的单独样式 **/
.fw {
  background: url(../../../public/assets/assets/img/bg_icon_1.svg) 100% 100%;
}

.fw p.num {
  color: #00ffac;
}

.qy {
  background: url(../../../public/assets/assets/img/bg_icon_2.svg) 100% 100%;
}

.qy p.num {
  color: #00eeff;
}

.hn {
  background: url(../../../public/assets/assets/img/bg_icon_3.svg) 100% 100%
    no-repeat;
}

.hn p.num {
  color: #40b3ff;
}

p.num span.unit {
  font-size: 10px;
  margin-left: 3px;
}

.hn.data-item {
  width: 96px;
}

.fwsr {
  background: url(../../../public/assets/assets/img/bg_icon_4.svg) 100% 100%
    no-repeat;
}

.fwsr p.num {
  color: #f382ff;
}

.tcf {
  background: url(../../../public/assets/assets/img/bg_icon_5.svg) 100% 100%
    no-repeat;
}

.tcf p.num {
  color: #ffed7a;
}

/** 小图标 **/
.cockpit-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.icon_1 {
  background: url(../../../public/assets/assets/img/icon_1.svg) 100%/100%;
}

.icon_2 {
  background: url(../../../public/assets/assets/img/icon_2.svg) 100%/100%;
}

.icon_3 {
  background: url(../../../public/assets/assets/img/icon_3.svg) 100%/100%;
}

.icon_4 {
  background: url(../../../public/assets/assets/img/icon_4.svg) 100%/100%;
}

.icon_5 {
  background: url(../../../public/assets/assets/img/icon_5.svg) 100%/100%;
}

.icon_6 {
  background: url(../../../public/assets/assets/img/icon_6.svg) 100%/100%;
}

.count {
  margin-top: 28px;
}

.mt20 {
  margin-top: 20px;
}
.cockpit-title.noback {
  background: none;
}
</style>

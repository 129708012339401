<template>
  <basic-container>
    <avue-crud :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               :before-open="beforeOpen"
               v-model="form"
               ref="crud"
               @row-update="rowUpdate"
               @row-save="rowSave"
               @row-del="rowDel"
               @search-change="searchChange"
               @search-reset="searchReset"
               @current-change="currentChange"
               @size-change="sizeChange"
               @refresh-change="refreshChange"
               @on-load="onLoad">
      <template slot-scope="scope" slot="menu">
        <template v-if="scope.row.status == 1">
          <el-button
              type="text"
              size="small"
              icon="el-icon-delete"
              class="none-border"
              @click="confirmToggle(scope.row)"
              >删除
            </el-button>
        </template>
        <template v-if="scope.row.status == 0">
          <el-button
              type="text"
              size="small"
              icon="el-icon-recover"
              class="none-setting"
              @click="confirmToggle(scope.row)"
              >恢复
            </el-button>
        </template>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
  import {getList, getDetail, add, update, remove, getRegionDetail, toogleStatus} from "@/api/spcod/xpscontact";
  import {mapGetters} from "vuex";
 let baseUrl = 'https://cli.avuejs.com/api/area'
  export default {
    data() {
        // 正则表达式 验证手机号
        const validateMobile = (rule, value, callback) => {
          if (!/^[1]([3-9])[0-9]{9}$/.test(value)) {
            callback(new Error("请输入正确的手机号格式"));
          } else {
            callback();
          }
        };
      return {
        form: {
          province: '',
          city: '',
          area: ''
        },
        query: {},
        loading: true,
        page: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        selectionList: [],
        option: {
          height:'auto',
          calcHeight: 30,
          tip: false,
          searchShow: true,
          searchMenuSpan: 6,
          border: true,
          index: true,
          addBtn:false,
          editBtn:false,
          delBtn:false,
          viewBtn: true,
          dialogClickModal: false,
          column: [
          {
              label: "联系人",
              prop: "ctxName",
              search:true,
              searchSpan: 6,
              rules: [{
                required: true,
                message: "请输入联系人名字",
                trigger: "blur"
              }]
            },
            {
              label: "联系电话",
              prop: "ctxPhone",
              search:true,
              searchSpan: 6,
              rules: [{
                required: true,
                message: "请输入联系人电话",
                trigger: "blur"
              },
              {
                trigger: "blur",
                validator: validateMobile
              }]
            },
          {
              label: "所属用户",
              prop: "userName",
              addDisplay:false,
              editDiplay:false,
              rules: [{
                required: false,
                message: "请输入所属用户",
                trigger: "blur"
              }]
            },
            {
              label: "用户关系",
              prop: "userRel",
              type: 'select',
                dicData:[{
                  label:'寄件人',
                  value:1
                },{
                  label:'收件人',
                  value:2
                }],
              rules: [{
                required: true,
                message: "请输入用户关系,1寄件人/2收件人",
                trigger: "blur"
              }]
            },
            {
              label: "默认关系",
              prop: "defaultRel",
              type: 'select',
                dicData:[{
                  label:'是',
                  value:1
                },{
                  label:'否',
                  value:0
                }],
              rules: [{
                required: true,
                message: "请输入是否默认关系，1是/0否",
                trigger: "blur"
              }]
            },
            {
              label: "省份",
              prop: "ctxProvince",
              type: "select",
              search:false,
              searchSpan: 5,
              props: {
                label: "name",
                value: "code",
              },
              // cascaderItem: ["ctxCity", "ctxDisctrict"],
              cascader: ['ctxCity'],
              dicUrl: "/api/jida-system/region/select",
              // dicUrl: `${baseUrl}/getCtxProvince`,
            },
            {
              label: "地市",
              prop: "ctxCityName",
              addDisplay:false,
              editDisplay:false
            },
            {
              label: "地市",
              prop: "ctxCity",
              type: "select",
              cell: true,
              hide:true,
              viewDisplay:false,
              props: {
                label: "name",
                value: "code",
              },
              cascader: ['ctxDisctrict'],
              dicUrl: "/api/jida-system/region/select?code={{key}}",
               rules: [
                {
                  required: false,
                  message: '请选择地市',
                  trigger: 'blur'
                }
              ]
            },
            {
              label: "区县",
              prop: "ctxDisctrictName",
              addDisplay:false,
              editDisplay:false
            },
            {
              label: "区县",
              prop: "ctxDisctrict",
              type: "select",
              hide:true,
              cell: true,
              props: {
                label: "name",
                value: "code",
              },
              dicFlag: false,
              dicUrl: "/api/jida-system/region/select?code={{key}}",
              rules: [
                {
                  required: false,
                  message: '请选择区县',
                  trigger: 'blur'
                }
              ]
            },
            {
              label: "地址",
              prop: "ctxAddr",
              span: 24,
              minRows:6,
              type:"textarea",
              rules: [{
                required: true,
                message: "请输入地址",
                trigger: "blur"
              }]
            },
            {
              label: "公司名字",
              prop: "ctxCompany",
              rules: [{
                required: false,
                message: "请输入公司名字，可选",
                trigger: "blur"
              }]
            },
            {
            label: "状态",
            prop: "status",
            addDisplay: false,
            editDisplay: false,
            search: true,
            searchSpan: 4,
            type: "select",
            dicData: [
              {
                label: "正常",
                value: 1,
              },
              {
                label: "删除",
                value: 0,
              },
            ],
            rules: [
              {
                required: false,
                message: "请输入状态",
                trigger: "blur",
              },
            ],
          },
          ]
        },
        data: []
      };
    },
    computed: {
      ...mapGetters(["permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.xpscontact_add, false),
          viewBtn: this.vaildData(this.permission.xpscontact_view, false),
          delBtn: this.vaildData(this.permission.xpscontact_delete, false),
          editBtn: this.vaildData(this.permission.xpscontact_edit, false)
        };
      },
      ids() {
        let ids = [];
        this.selectionList.forEach(ele => {
          ids.push(ele.id);
        });
        return ids.join(",");
      }
    },
    mounted(){
      //放在数据加载完后执行
      this.$refs.crud.dicInit('cascader');
    },
    methods: {
      confirmToggle(row){
        let _this = this;
        this.$confirm("确认修改状态", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            //修改状态
            toogleStatus(row.id).then(res=>{
              if(row.status == 1)
                row.status = 0;
              else
                row.status = 1;
              _this.$forceUpdate();
              _this.$message({
                type: "success",
                message: "操作成功!"
              });
            });
          });
      },
       rowSave(row, done, loading) {
        this.doSave(row, done, loading);
      },
      async doSave(row, done, loading){
        if(row.ctxProvince != ''){
          await getRegionDetail(row.ctxProvince).then(res=>{
            console.log(res);
            row.ctxProvinceName = res.data.data.name;
          });
        }
        if(row.ctxCity){
          await getRegionDetail(row.ctxCity).then(res=>{
            row.ctxCityName = res.data.data.name;
          });
        }
        if(row.ctxDisctrict){
          await getRegionDetail(row.ctxDisctrict).then(res=>{
            console.log(res);
            row.ctxDisctrictName = res.data.data.name;
          });
        }
        await add(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          window.console.log(error);
        });
      },
      rowUpdate(row, index, done, loading) {
        this.doUpdate(row, index, done, loading);
      },
      async doUpdate(row, index, done, loading){
        if(row.ctxProvince){
          await getRegionDetail(row.ctxProvince).then(res=>{
            row.ctxProvinceName = res.data.data.name;
          });
        }else{
          row.ctxProvinceName = '';
        }
        if(row.ctxCity){
          await getRegionDetail(row.ctxCity).then(res=>{
            row.ctxCityName = res.data.data.name;
          });
        }else{
          row.ctxCityName = '';
        }
        if(row.ctxDisctrict){
          await getRegionDetail(row.ctxDisctrict).then(res=>{
            row.ctxDisctrictName = res.data.data.name;
          });
        }else{
          row.ctxDisctrictName = '';
        }

        update(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          console.log(error);
        });
      },
      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(row.id);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
          });
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
            this.$refs.crud.toggleSelection();
          });
      },
      beforeOpen(done, type) {
        if (["edit", "view"].includes(type)) {
          getDetail(this.form.id).then(res => {
            this.form = res.data.data;
          });
        }
        done();
      },
      searchReset() {
        this.query = {};
        this.onLoad(this.page);
      },
      searchChange(params, done) {
        this.query = params;
        this.page.currentPage = 1;
        this.onLoad(this.page, params);
        done();
      },
      selectionClear() {
        this.selectionList = [];
        this.$refs.crud.toggleSelection();
      },
      currentChange(currentPage){
        this.page.currentPage = currentPage;
      },
      sizeChange(pageSize){
        this.page.pageSize = pageSize;
      },
      refreshChange() {
        this.onLoad(this.page, this.query);
      },
      onLoad(page, params = {}) {
        this.loading = true;
        getList(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
          const data = res.data.data;
          this.page.total = data.total;
          this.data = data.records;
          this.loading = false;
          this.selectionClear();
        });
      }
    }
  };
</script>

<style>
</style>

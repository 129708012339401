<template>
  <basic-container class="datalayer-container">
    <div v-if="showLayerList">
      <avue-crud
        ref="crud"
        v-model="catalogForm"
        :data="tableData"
        :table-loading="layerLoading"
        :page.sync="page"
        :option="option"
        :before-close="beforeClose"
        @on-load="onLoad"
        @refresh-change="onLoad"
        @row-save="handleRowSave"
        @row-update="rowUpdate"
      >
        <template slot="menuLeft" slot-scope="{type,size,row}">
          <el-button
            @click="showPublishModel"
            type="primary"
            icon="el-icon-share"
            size="small">全量备份
          </el-button>
          <el-button
            @click="showPublishModel"
            type="primary"
            icon="el-icon-share"
            size="small">增量备份
          </el-button>
          <el-button
            @click="showPublishModel"
            type="primary"
            icon="el-icon-share"
            size="small">累积增量备份
          </el-button>
        </template>
        <template slot-scope="{type,size,row}" slot="menu">
          <el-button @click.stop="handleEdit(row)" icon="el-icon-edit" :size="size" :type="type">编辑</el-button>
          <el-button @click.stop="rowDel(row)" icon="el-icon-delete" :size="size" :type="type">删除</el-button>
        </template>
        <template slot="dhName" slot-scope="scope">
          <a @click="showLayerDetail(scope.row)">{{scope.row.dhName}}</a>
        </template>
        <template slot="tableGrabbingRules" slot-scope="scope">
          <div v-for="(item,index) in scope.row.ruleList" :key="index">
            <div>
              <div>
                <span>库:</span><span>{{item.databaseName}}</span>
              </div>
              <div>
                <span>命名规则:</span><span>{{item.hitRule}}</span>
              </div>
              <div>
                <span>剔除规则:</span><span>{{item.eliminationRule}}</span>
              </div>
            </div>
          </div>
        </template>
        <template slot-scope="{row}" slot="ruleForm">
          <div>
            <el-table
              v-if="ruleTableData.length > 0"
              :data="ruleTableData"
              style="width: 100%;">
              <el-table-column
                label="库类型">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.databaseType" placeholder="请选择库类型" @change="datasourceTypeChange(scope.row)">
                    <el-option
                      v-for="item in datasourceTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                label="库">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.databaseId" placeholder="请选择库" @focus="queryDataBase(scope.row)">
                    <el-option
                      v-for="item in scope.row.databases"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                label=""
                width="30px"
              >
                <template slot-scope="scope">
                  <span style="cursor: pointer;" @click="closeRule(scope)"><i class="el-icon-delete"></i></span>
                </template>
              </el-table-column>
            </el-table>
            <div style="color: #1976d3;">
              <i class="el-icon-plus"></i>
              <span @click="addRule" style="margin-left: 5px;cursor: pointer">添加</span>
            </div>
          </div>
        </template>
      </avue-crud>
    </div>
    <div v-if="showDBTable" class="table-height">
      <span class="back" @click="showDBTable = false;showLayerList = true;">
          <i class="el-icon-back"></i>
        </span>
      <avue-crud
        ref="dbtable"
        :data="dbTables"
        :option="dbTablesOption"
        @refresh-change="onLoad"
      >
        <template slot="name" slot-scope="scope">
          <a @click="toShowTables(scope.row)">{{scope.row.name}}</a>
        </template>
      </avue-crud>
    </div>
    <div v-if="showTables" class="table-height">
      <span class="back" @click="showTables = false;showDBTable = true;">
          <i class="el-icon-back"></i>
        </span>
      <avue-crud
        ref="table"
        :data="tables"
        :option="tablesOption"
        @refresh-change="onLoad"
      >
        <template slot="tableName" slot-scope="scope">
          <a @click="showMetaDetail(scope.row)">{{scope.row.tableName}}</a>
        </template>
      </avue-crud>
    </div>
    <div v-if="showMetaInfo" class="table-height">
        <span class="back" @click="showMetaInfo = false;showTables = true;">
          <i class="el-icon-back"></i>
        </span>
      <meta-info :guid="guid"></meta-info>
    </div>
    <el-dialog class="avue-dialog avue-crud__dialog" custom-class="dialog-width" @close="closeCateForm"  append-to-body :title="'备份'" :visible.sync="addCategoryVisible">
      <avue-form ref="categoryForm" v-model="categoryForm" :option="cateOption">
        <avue-input-tree default-expand-all v-model="form" placeholder="请选择备份范围" type="tree" :dic="dic"></avue-input-tree>
        <template slot-scope="scope" slot="menuForm">
          <basic-form-button @submit="addCategoryVisible = false" @cancel="addCategoryVisible = false"></basic-form-button>
        </template>
      </avue-form>

    </el-dialog>
  </basic-container>
</template>
<script>
  import Vue from 'vue'
  import {
    getDataLayerList,
    saveDataLayer,
    delDataLayer,
    updateEnableStatus,
    updateShowStatus,
    updateDataLayer,
    queryTablesByDataBaseIdAndDhId,
    queryDataBaseByDataHierarchyId
  } from '@/api/dws/datalayer';
  import datasourceTypeList from '@/config/datasourceTypeList'
  import { getDataBase } from "@/api/metadata/task";
  import { getDMList } from "@/api/dws/backup";
  import metaInfo from './metainfo';
  export default {
    components:{ metaInfo },
    data() {
      return {
        categoryForm:{},
        cateOption:{
          submitBtn:false,
          emptyBtn:false,
          column: [
            {
              label:'分层',
              prop:'labels',
              type:'tree',
              multiple:true,
              dicData: [
                {
                label:'应用数据层',
                value:0
                },
                {
                  label:'融合数据层',
                  value:1
                },
                {
                  label:'操作数据层',
                  value:2
                },
                {
                  label:'原始数据层',
                  value:3
                }
              ]
            },
          ]
        },
        addCategoryVisible:false,
        form:'',
        dic:[{
          label:'选项1',
          value:0,
          children:[{
            label:'选项3',
            value:2
          },{
            label:'选项4',
            value:3
          }]
        },{
          label:'选项2',
          value:1
        }],
        datasourceTypeList:datasourceTypeList,
        guid:'',
        layerLoading:false,
        catalogForm:{
          database:''
        },
        tableData:[],
        ruleTableData:[
          {
            'hitRule':'',
            'eliminationRule':'',
            'databaseId':'',
            'databaseType':'',
            'databases':[]
          }
        ],
        databases:[],
        databaseList:[
          {
            'database':''
          }
        ],
        page: {
          pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        option:{
          menu:false,
          addBtn:false,
          labelWidth:110,
          editBtn:false,
          delBtn:false,
          column:[
            {
              label:'备份开始时间',
              prop:'start',
              rules: [{
                required: true,
                message: "请输入模型名称",
                trigger: "blur"
              }],
            },
            {
              label:'备份结束时间',
              prop:'end',
              rules: [{
                required: true,
                message: "请输入英文标识",
                trigger: "blur"
              }],
            },
            {
              label:'备份类型',
              prop:'type'
            },
            {
              label:'分层',
              prop:'layer'
            },
            // {
            //   label:'备份时间',
            //   prop:'submitTime',
            //   search:true,
            //   searchRange:true,
            //   searchSpan:9,
            //   type: "date",
            //   format: "yyyy-MM-dd hh:mm:ss",
            //   valueFormat: "yyyy-MM-dd hh:mm:ss",
            //   display:false
            // },

            {
              label:'备份内容',
              prop:'content'
            },
            {
              label:'备份数据量(条)',
              prop:'datacount'
            },
            {
              label:'备份人',
              prop:'user'
            }
          ],
          group: [
            {
              column: [
                {
                  label: "库",
                  prop: "rule",
                  span:24
                }
              ]
            }
          ]
        },
        showLayerList:true,
        showDBTable:false,
        showTables:false,
        showMetaInfo:false,
        dbTables:[],
        dbTablesOption:{
          menu:false,
          addBtn:false,
          editBtn:false,
          delBtn:false,
          column:[
            {
              label:'库名称',
              prop:'name'
            },
            {
              label:'库类型',
              prop:'modeltype'
            },
            {
              label:'模型名称',
              prop:'dhName'
            },
            {
              label:'模型标识',
              prop:'dhCode'
            },
          ],
        },
        tables:[],
        tablesOption:{
          menu:false,
          addBtn:false,
          editBtn:false,
          delBtn:false,
          column:[
            {
              label:'表名称',
              prop:'tableName'
            },
            {
              label:'表类型',
              prop:'typeName'
            },
            {
              label:'模型名称',
              prop:'dhName'
            },
            {
              label:'模型标识',
              prop:'dhCode'
            },
          ],
        }
      }
    },
    mounted() {
      //this.getDataBase();
    },
    methods:{
      showPublishModel() {
        this.addCategoryVisible = true;
      },
      onLoad() {
        this.layerLoading = true;
        let obj = {
          dhName:'',
          pageNo:this.page.currentPage,
          pageSize:this.page.pageSize
        };
        getDMList().then( res => {
          res['data']['data']['lists'].forEach(item => {
            item['ruleList'] = JSON.parse(item['tableGrabbingRules']);
          });
          this.tableData = res['data']['data']['lists'];
          this.page.total = res['data']['data']['totalCount'];
          this.layerLoading = false;
        });
      },
      beforeClose(done) {
        done();
        this.ruleTableData = [
          {
            'hitRule':'',
            'eliminationRule':'',
            'databaseId':'',
            'databaseType':'',
            'databases':[]
          }
        ];
        this.databaseList = [];

      },
      handleRowSave(row,done) {
        done();
        return;
        // let temp = [];
        // // 按表规则
        // this.ruleTableData.forEach( item => {
        //   let dataBaseMap = {};
        //   item.databases.forEach( item => {
        //     dataBaseMap[item['value']] = item['label'];
        //   });
        //   temp.push({
        //     'hitRule':item['hitRule'],
        //     'eliminationRule':item['eliminationRule'],
        //     'databaseId':item['databaseId'],
        //     'databaseName':dataBaseMap[item['databaseId']],
        //     'databaseType':item['databaseType']
        //   })
        // });
        // let obj = {
        //   'dhCode':row['dhCode'],
        //   'dhName':row['dhName'],
        //   'dhDesc':row['dhDesc'],
        //   'entableStatus':'1',
        //   'showStatus':'1',
        //   'tableGrabbingRules':JSON.stringify(temp),
        //   'libraryAllowingTableCreation':row['libraryAllowingTableCreation'],
        //   'databaseIds':''
        // };
        // saveDataLayer(obj).then(res => {
        //   done();
        //   this.onLoad();
        // });
      },
      updateEnableStatus(row,status) {
        let obj = {
          'id':row['id'],
          'enableStatus':status
        };
        updateEnableStatus(obj).then( res => {
          this.onLoad();
        });
      },
      updateShowStatus(row,status) {
        let obj = {
          'id':row['id'],
          'showStatus':status
        };
        updateShowStatus(obj).then( res => {
          this.onLoad();
        });
      },
      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            // delDataLayer({
            //   id: row.id
            // }).then(res => {
            //   this.$message.success('操作成功');
            //   this.onLoad();
            // }).catch(e => {
            //   // this.$message.error(e.msg || '')
            // })
          })
      },
      handleEdit(row) {
        this.$refs.crud.rowEdit(row);
        //this.ruleTableData = row.ruleList.concat([]);
        setTimeout(()=>{
        },0)
      },
      rowUpdate(row,index,done) {
        done();
        return;
        // let temp = [];
        // this.ruleTableData.forEach( item => {
        //   let dataBaseMap = {};
        //   item.databases.forEach( item => {
        //     dataBaseMap[item['value']] = item['label'];
        //   });
        //   temp.push({
        //     'hitRule':item['hitRule'],
        //     'eliminationRule':item['eliminationRule'],
        //     'databaseId':item['databaseId'],
        //     'databaseName':dataBaseMap[item['databaseId']],
        //     'databaseType':item['databaseType']
        //   })
        // });
        // let obj = {
        //   'id':row['id'],
        //   'dhCode':row['dhCode'],
        //   'dhName':row['dhName'],
        //   'dhDesc':row['dhDesc'],
        //   'entableStatus':'1',
        //   'showStatus':'1',
        //   'tableGrabbingRules':JSON.stringify(temp),
        //   'libraryAllowingTableCreation':row['libraryAllowingTableCreation'],
        //   'databaseIds':''
        // };
        // updateDataLayer(obj).then(res => {
        //   done();
        //   this.onLoad();
        // });
      },
      addRule() {
        let len = this.ruleTableData.length;
        Vue.set(this.ruleTableData,len,{
          'hitRule':'',
          'eliminationRule':'',
          'databaseId':'',
          'databaseType':'',
          'databases':[]
        });
      },
      addDataBase() {
        this.databaseList.push({
          'database':''
        })
      },
      closeDataBase(index) {
        if(this.databaseList.length === 1) return;
        this.databaseList.splice(index,1);
      },
      closeRule(scope) {
        if(this.ruleTableData.length === 1) {
          return;
        }
        this.ruleTableData.splice(scope.$index,1);
      },
      changeGrabRule() {
        console.log(this.grabType)
      },
      getDataBase() {
        let obj = {
          type: 'HIVE'
        };
        getDataBase(obj).then( res => {
          if(res['status'] === 200) {
            res['data']['data'].forEach(item => {
              let obj ={
                'value':item['id'],
                'label':item['name']
              };
              this.databases.push(obj);
            });
          }
        })
      },
      showLayerDetail(row) {
        let obj = {
          id:row['id']
        };
        queryDataBaseByDataHierarchyId(obj).then( res => {
          this.dbTables = res['data']['data'];
          this.showDBTable = true;
          this.showLayerList = false;
        });
      },
      toShowTables(row) {
        if(!row['guid']) {
          //this.$message.error('未绑定数据');
          return;
        }
        let obj = {
          id:row['dhId'],
          guid:row['guid'],
          databaseId:row['dataBaseId']
        };
        queryTablesByDataBaseIdAndDhId(obj).then( res => {
          this.tables = res['data']['data'];
          this.showDBTable = false;
          this.showTables = true;
        });
      },
      showMetaDetail(row) {
        this.guid = row['guid'];
        this.showTables = false;
        this.showMetaInfo = true;
      },
      datasourceTypeChange(row) {
        row.databaseId = '';
        // let _t = this;
        // row.databases = [];
        // let obj = {
        //   type: row.databaseType
        // };
        // getDataBase(obj).then( res => {
        //   if(res['status'] === 200) {
        //     res['data']['data'].forEach(item => {
        //       let obj ={
        //         'value':item['name'],
        //         'label':item['name']
        //       };
        //       row.databases.push(obj);
        //     });
        //   }
        // })
      },
      queryDataBase(row) {
        if(!row.databaseType) return;
        let _t = this;
        row.databases = [];
        let obj = {
          type: row.databaseType
        };
        getDataBase(obj).then( res => {
          if(res['status'] === 200) {
            res['data']['data'].forEach(item => {
              let obj ={
                'value':item['id'],
                'label':item['name']
              };
              row.databases.push(obj);
            });
          }
        })
      }
    }
  }
</script>
<style lang="scss">
  .datalayer-container{
    a {
      cursor: pointer;
      color: #337ab7;
    }
    .table-height{
      height: calc(100vh - 170px)!important;
      overflow: auto;
      position: relative;
    }
    .back {
      position: absolute;
      cursor: pointer;
      z-index: 1;
    }
  }
  .rule-close {
    position: absolute;
    right: -25px;
    z-index: 1;
    font-size: 18px;
    cursor: pointer;
  }
</style>

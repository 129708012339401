import PaletteProvider from "bpmn-js/lib/features/palette/PaletteProvider";
import { assign } from "min-dash";

import customNodes from './node/index';

class CustomPalette extends PaletteProvider{

  constructor(
    palette, create, elementFactory,
    spaceTool, lassoTool, handTool,
    globalConnect, translate
  ){
    super(
      palette, create, elementFactory,
      spaceTool, lassoTool, handTool,
      globalConnect, translate , 2000
    )
  }

  getPaletteEntries(){
    let actions = {},
    create = this._create,
    translate = this._translate,
    elementFactory = this._elementFactory;

    function createAction(type, group, className, title, options) {
      function createListener(event) {
        var shape = elementFactory.createShape(assign({ type: type }, options));

        if (options) {
          shape.businessObject.di.isExpanded = options.isExpanded;
        }

        create.start(event, shape);
      }

      // var shortType = type.replace(/^busi:/, "");

      return {
        group: group,
        className: className,
        title: title || translate("Create {type}", { type: shortType }),
        action: {
          dragstart: createListener,
          click: createListener
        },
        type: type
      };
    }

    let _actions = {};

    Object.keys(customNodes).forEach(
      keyItem => {
        let item = customNodes[keyItem];
        let info = item.info;
        if(info){
          _actions[`create.${info.name}`] = {
            ...createAction(keyItem , info.group , info.className , translate(info.name)),
            info: info
          };
        }
      }
    )

    assign(actions , {
      ..._actions
    })
    return actions;
  }

}


export default {
  __init__: ['customPalette'],
  customPalette: ['type' , CustomPalette]
};
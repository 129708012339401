<template>
  <basic-container isCard>
    <el-row>
      <el-button
        v-if="show"
        @click="show = !show"
        class="pull-right"
        size="mini"
        type="primary"
        >编辑</el-button
      >

      <el-button
        v-if="!show"
        @click="show = !show"
        class="pull-right mar-l-10"
        size="mini"
        type=""
        >取消</el-button
      >
      <el-button
        v-if="!show"
        @click="saveEnt"
        class="pull-right"
        size="mini"
        type="primary"
        >保存</el-button
      >
    </el-row>

    <el-descriptions
      title="企业信息"
      :column="4"
      direction="vertical"
      :colon="false"
      :labelStyle="labelStyle"
      v-if="show"
    >
      <el-descriptions-item label="企业名称">{{
        ruleForm.name
      }}</el-descriptions-item>
      <el-descriptions-item label="统一社会信用代码">{{
        ruleForm.uniform_social_credit_code
      }}</el-descriptions-item>
      <el-descriptions-item label="企业法人">{{
        ruleForm.legal_person
      }}</el-descriptions-item>
      <el-descriptions-item label="企业法人联系方式">
        {{ ruleForm.legal_contact }}
      </el-descriptions-item>
      <el-descriptions-item label="企业规模">{{
        getNameByDict(firmSizeList, ruleForm.firm_size) || "-"
      }}</el-descriptions-item>
      <el-descriptions-item label="注册资金">{{
        ruleForm.registered_capital
      }}</el-descriptions-item>
      <el-descriptions-item label="企业类型">
        <el-tag size="small">{{
          getNameByDict(enterpriseTypes, ruleForm.type) || "-"
        }}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="企业资质">
        <el-tag size="small">{{
          getNameByDict(qualifications, ruleForm.qualification) || "-"
        }}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item label="负责人姓名">{{
        ruleForm.principal
      }}</el-descriptions-item>
      <el-descriptions-item label="负责人联系电话">{{
        ruleForm.phone_num
      }}</el-descriptions-item>
      <el-descriptions-item label="注册地址">{{
        ruleForm.registered_address
      }}</el-descriptions-item>
    </el-descriptions>
    <el-form
      v-if="!show"
      class="pad-10"
      :model="ruleForm"
      ref="ruleForm"
      :disabled="doType == 'detail'"
      label-width="100px"
      label-position="top"
      size="small"
      :rules="ruleModel"
    >
      <el-row>
        <el-col :span="6">
          <el-form-item label="企业名称" prop="name">
            <el-input
              v-model="ruleForm.name"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="统一社会信用代码"
            prop="uniform_social_credit_code"
          >
            <el-input
              placeholder="请输入统一社会信用代码"
              v-model="ruleForm.uniform_social_credit_code"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="企业法人" prop="legal_person">
            <el-input
              placeholder="请输入企业法人"
              v-model="ruleForm.legal_person"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="企业法人联系方式" prop="legal_contact">
            <el-input
              placeholder="请输入企业法人联系方式"
              v-model="ruleForm.legal_contact"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="企业规模" prop="firm_size">
            <el-select
              v-model="ruleForm.firm_size"
              size="small"
              placeholder="请选择企业规模"
            >
              <el-option
                v-for="item in firmSizeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="注册资金(万元)" prop="registered_capital">
            <el-input
              placeholder="请输入注册资金"
              v-model="ruleForm.registered_capital"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="6">
          <el-form-item label="所属行业" prop="type">
            <el-select
              v-model="ruleForm.industry"
              size="small"
              placeholder="请选择行业"
            >
              <el-option
                v-for="item in industrys"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="企业类型" prop="type">
            <el-select
              v-model="ruleForm.type"
              size="small"
              placeholder="请选择企业类型"
            >
              <el-option
                v-for="item in enterpriseTypes"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="企业资质" prop="qualification">
            <el-select
              v-model="ruleForm.qualification"
              size="small"
              placeholder="请输入企业资质"
            >
              <el-option
                v-for="item in qualifications"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="负责人姓名" prop="principal">
            <el-input
              placeholder="请输入负责人姓名"
              v-model="ruleForm.principal"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="负责人联系电话" prop="phone_num">
            <el-input
              placeholder="请输入负责人联系电话"
              v-model="ruleForm.phone_num"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="注册地址" prop="registered_address">
            <el-input
              type="textarea"
              placeholder="请输入注册地址"
              v-model="ruleForm.registered_address"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-divider content-position="left">营业执照</el-divider>
    <div class="pad-10" v-if="imgform.imgUrl.length == 0">暂无照片</div>
    <avue-form :option="option1" v-model="imgform"></avue-form>
    <el-divider content-position="left">企业LOGO</el-divider>
    <div class="pad-10" v-if="imgform1.imgUrl.length == 0">暂无照片</div>
    <avue-form :option="option2" v-model="imgform1"></avue-form>
  </basic-container>
</template>

<script>
import * as dictbizService from "@/api/system/dictbiz";
import standardService from "@/api/metadata/standard";
import {
  getMetaCommonInfo,
  getPicListByIds,
  updateMetaCommon,
  saveMetaCommon,
} from "@/api/busiMode/metaCommon";
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      ruleModel: {},
      show: true,
      labelStyle: {
        color: "#999",
        fontSize: "12px",
      },
      option1: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,

        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,

            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },

      option2: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,

            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      option: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,

        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,

            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      imgform: {
        imgUrl: [],
      },
      imgform1: {
        imgUrl: [],
      },
      dialogImageUrl: "",
      dialogVisible: false,
      ruleForm: {},
      params: {
        zoom: 4,
        zoomEnable: false,
        dragEnable: true,
      },
      firmSizeList: [],
      enterpriseTypes: [],
      qualifications: [],
      industrys: [],

      entModel: "wisdom_park.wp_company",
    };
  },
  watch: {
    currentRow: {
      immediate: true,
      handler(val) {
        if (val.id) {
          this.getMetaCommonInfo();
        }
      },
    },
    show: {
      immediate: true,
      handler(val) {
        if (val) {
          this.option1.disabled = true;
          this.option2.disabled = true;
          this.option1 = JSON.parse(JSON.stringify(this.option1));
          this.option2 = JSON.parse(JSON.stringify(this.option2));
        } else {
          this.option1.disabled = false;
          this.option2.disabled = false;
          this.option1 = JSON.parse(JSON.stringify(this.option1));
          this.option2 = JSON.parse(JSON.stringify(this.option2));
        }
        if (val == "add") {
          this.ruleForm = {};
        }
      },
    },
  },
  mounted() {
    this.getDicList();
    this.queryByModelCode();
    if (this.currentRow.id) {
      this.getMetaCommonInfo();
    }
    if (this.userInfo.companyId && this.userInfo.tenant_id == "000002") {
      this.currentRow.id = this.userInfo.companyId;
      this.getMetaCommonInfo();
    }
  },
  props: {
    doType: {
      type: String,
      default() {
        return "";
      },
    },
    currentRow: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  methods: {
    getNameByDict(list, data) {
      let name = "";
      list.forEach((e) => {
        if (e.value == data) {
          name = e.name;
        }
      });
      return name;
    },
    queryByModelCode() {
      standardService.getModelRule(this.model).then((res) => {
        this.ruleModel = res;
      });
    },
    getDicList() {
      dictbizService
        .getDictionary({
          code: "firmSize",
        })
        .then((res) => {
          this.firmSizeList = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
      dictbizService
        .getDictionary({
          code: "enterpriseType",
        })
        .then((res) => {
          this.enterpriseTypes = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
      dictbizService
        .getDictionary({
          code: "qualification",
        })
        .then((res) => {
          this.qualifications = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
      dictbizService
        .getDictionary({
          code: "industry",
        })
        .then((res) => {
          this.industrys = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    getMetaCommonInfo() {
      let obj = {
        pk: "id",
        id: this.currentRow.id,
      };
      getMetaCommonInfo(this.entModel, obj).then((res) => {
        if (res.data.code == 200) {
          this.ruleForm = res.data.data;
          this.height = res.data.data.floor_height;
          if (res.data.data["business_license"]) {
            getPicListByIds(res.data.data["business_license"]).then((res) => {
              if (res.data.code == 200) {
                let arr = [];
                res.data.data.forEach((pic) => {
                  arr.push({
                    label: pic.id,
                    value: pic.link,
                  });
                });
                this.imgform.imgUrl = arr;
              }
            });
          }
          if (res.data.data["company_logo"]) {
            getPicListByIds(res.data.data["company_logo"]).then((res) => {
              if (res.data.code == 200) {
                let arr = [];
                res.data.data.forEach((pic) => {
                  arr.push({
                    label: pic.id,
                    value: pic.link,
                  });
                });
                this.imgform1.imgUrl = arr;
              }
            });
          }
        }
      });
    },
    submit() {
      return new Promise((resolve) => {
        this.$refs["ruleForm"].validate((valid) => {
          console.log(this.$refs["ruleForm"]);
          if (valid) {
            resolve({
              valid: true,
              form: this.ruleForm,
            });
          } else {
            let fields = this.$refs["ruleForm"].fields,
              firstError = {
                field: "",
                message: "",
                name: "",
              };
            for (let i = 0; i < fields.length; i++) {
              if (fields[i].validateState === "error") {
                firstError.message = fields[i].validateMessage;
                firstError.field = fields[i].prop;
                firstError.name = fields[i].label;
                break;
              }
            }
            resolve({
              valid: false,
              form: this.ruleForm,
              error: firstError,
            });
          }
        });
      });
    },
    saveEnt() {
      Promise.all([this.submit()]).then((forms) => {
        let valid = true,
          errorObj = null;
        forms.forEach((itemForm) => {
          if (itemForm.valid !== true) {
            valid = false;
            if (!errorObj) {
              errorObj = itemForm.error;
            }
          }
        });
        if (valid) {
          let baseInfo = forms[0].form;
          //营业执照图片
          if (this.imgform) {
            let imgList = [];
            this.imgform.imgUrl.forEach((e) => {
              if (e.label) {
                imgList.push(e.label);
              }
            });
            baseInfo.business_license = imgList.toString();
          }
          //企业logo
          if (this.imgform1) {
            let imgList = [];
            this.imgform1.imgUrl.forEach((e) => {
              if (e.label) {
                imgList.push(e.label);
              }
            });
            baseInfo.company_logo = imgList.toString();
          }
          if (baseInfo.id) {
            let o = {
              update: baseInfo,
              primaryKeyValue: baseInfo["id"],
              primaryKey: "id",
            };
            updateMetaCommon(this.entModel, o).then((res) => {
              if (res.data.code == 200) {
                this.show = !this.show;
                this.getMetaCommonInfo();
              }
            });
          } else {
            saveMetaCommon(this.entModel, {
              values: baseInfo,
            }).then((res) => {
              if (res.data.code == 200) {
                this.show = !this.show;
                this.getMetaCommonInfo();
              }
            });
          }
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
  margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
  left: 0px;
}
/deep/ .el-divider__text {
  padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
  width: 68px;
  height: 68px;
  line-height: 72px;
  vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
}
</style>

<template>
  <data-read-list></data-read-list>
</template>
<script>
import dataReadList from './dataRead/data-read-list';
export default {
  components: {
    dataReadList
  }
}
</script>

<template>
  <basic-container>
    <avue-form
      ref="form"
      v-model="obj"
      :option="option"
      @submit="submit"
      :append-to-body="true"
    >
    </avue-form>
  </basic-container>
</template>
<script>
import iconList from "@/config/iconList";
import { getMenuTree } from "@/api/system/menu";
import { getLazyList, remove, update, add, getMenu } from "@/api/system/menu";

export default {
  data() {
    return {
      obj: {
        $category: "菜单",
        $isOpen: "否",
        name: "",
        path: "",
        parentId: "1123598815738675217",
        source: "iconfont iconicon_task_done",
        code: "",
        category: 1,
        alias: "自动生成",
        isOpen: 1,
        sort: 1,
        remark: "自动生成的",
        $parentId: "低代码",
      },
      parentId: 0,
      sizeValue: "",
    };
  },
  computed: {
    option() {
      return {
        size: this.sizeValue,
        mock: true,
        submitText: "完成",
        column: [
          {
            label: "菜单名称",
            prop: "name",
            span: 24,
            rules: [
              {
                required: true,
                message: "请输入菜单名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "上级菜单",
            prop: "parentId",
            type: "tree",
            span: 24,
            hide: true,
            dicUrl: "/api/jida-system/menu/tree", // 动态字典 接口
            props: {
              label: "title",
            },
            addDisabled: false,
            rules: [
              {
                required: false,
                message: "请选择上级菜单",
                trigger: "click",
              },
            ],
          },
          {
            label: "菜单图标",
            prop: "source",
            type: "icon",
            slot: true,
            span: 24,
            iconList: iconList,
          },
        ],
      };
    },
  },

  watch: {
    option: {
      handler(newName, oldName) {
        //this.getCommList();
      },
      immediate: true,
      deep: true,
    },
  },
  props: {
    model: {
      type: Object,
      default: function () {
        return {};
      },
    },
    currentRow: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  mounted() {
    this.initData();
    this.obj.username = "smallwei";
    this.obj.switch = 0;
    this.obj.phone = "17547400800";
  },
  methods: {
    submit(form, done) {
      console.log(this.mode);
      this.obj.path =
        "/data/table/tableCommon" + "/?modleId" + this.currentRow.id;
      this.obj.code =
        "/data/table/tableCommon" + "/?modleId" + this.currentRow.id;
      this.save(form, done);
    },
    save(form, done) {
      add(this.obj).then(
        (res) => {
          if (res.data.code == 200) {
            this.$emit("saveNewPage");
          } else {
            done();
            this.$refs.form.resetForm();
          }
        },
        (error) => {
          done();
          this.$refs.form.resetForm();
        }
      );
    },
    initData() {
      getMenuTree().then((res) => {
        if (res.data) {
          const column = this.findObject(this.option.column, "parentId");
          column.dicData = res.data.data;
        }
      });
    },
  },
};
</script>

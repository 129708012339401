<template>
  <div>
    <design v-if="viewEdit"></design>
    <template v-if="!viewEdit">
      <preview></preview>
    </template>
  </div>
</template>
<script>
import design from "./design/design";
import preview from "./preview/preview";
import newPage from "./design/newPage";
import { mapGetters, mapState } from "vuex";
export default {
  name: "tableDesign",
  components: { preview, design, newPage },

  data() {
    return {
      showNewPage: false,
      app: {
        appId: "",
        id: "",
      },
    };
  },
  watch: {
    guid(val) {},
  },
  created() {
    this.showNewPage = this.$store.getters.showNewPage;
    this.app = {
      appId: this.$route.query.appId,
      id: this.$route.query.id,
    };
    console.log(this.$store);
  },
  mounted() {
    //this.newPage = this.$route.query.newPage;
  },
  computed: {
    guid() {
      return this.$store.getters.guid;
    },
    ...mapState({
      viewEdit: (state) => state.common.viewEdit,
    }),
  },
  methods: {},
};
</script>

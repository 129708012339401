<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" :page.sync="page" :before-open="beforeOpen" v-model="form" ref="crud" @row-update="rowUpdate" @row-save="rowSave"
                   @row-del="rowDel" @search-change="searchChange" @search-reset="searchReset" @current-change="currentChange" @size-change="sizeChange" @refresh-change="refreshChange"
                   @on-load="onLoad">
            <template slot="menuLeft">
                <el-button type="danger" size="small" icon="el-icon-delete" plain v-if="permission.sportprice_delete" @click="handleDelete">删 除
                </el-button>
            </template>
        </avue-crud>
    </basic-container>
</template>

<script>
import {
    getPage as getPageList,
    getList,
    getDetail,
    add,
    update,
    removeOne,
    getSportGroundInfo,
} from '@/api/spcod/sportprice';
import { getPage } from '@/api/spcod/sportground';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            form: {},
            query: {},
            loading: true,
            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            selectionList: [],
            datalist: [],
            groupList: [],
            option: {
                height: 'auto',
                calcHeight: 30,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: true,
                viewBtn: true,
                dialogClickModal: false,
                labelWidth: 120,
                column: [
                    {
                        label: '价格名称',
                        prop: 'name',
                        width: 200,
                        rules: [
                            {
                                required: true,
                                message: '请输入价格名称,例子：羽毛球馆工作日价格',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '场地',
                        prop: 'groundId',
                        type: 'select',
                        search: true,
                        searchSpan: 5,
                        dicData: [],
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                        rules: [
                            {
                                required: true,
                                message: '请选择场地',
                                trigger: 'blur',
                            },
                        ],
                        change: ({ value, column }) => {
                            console.log(value, column);
                            this.getSportGroundInfo(value);
                        },
                    },
                    {
                        label: '场地类型',
                        prop: 'typeId',
                        disabled: true,
                        type: 'select',
                        readOnly: true,
                        search: true,
                        searchSpan: 5,
                        dicUrl: '/api/park-spcod/sporttype/list?current=1&size=10000000',
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                        dicFormatter: data => {
                            let datalist = data.data.records;
                            datalist.forEach(ele => {
                                ele.id = ele.id + '';
                            });
                            this.datalist = datalist;
                            return datalist;
                        },
                        rules: [
                            {
                                required: true,
                                message: '请输入场馆ID',
                                trigger: 'blur',
                            },
                        ],
                    },

                    {
                        label: '计价方式',
                        prop: 'priceType',
                        disabled: true,
                        search: true,
                        searchSpan: 4,
                        span: 12,
                        type: 'select',
                        readonly: true,
                        dicData: [
                            {
                                label: '计时',
                                value: 1,
                            },
                            {
                                label: '计次',
                                value: 2,
                            },
                        ],
                        rules: [
                            {
                                required: true,
                                message: '请选择价格类型',
                                trigger: 'blur',
                            },
                        ],
                        change: ({ value, column }) => {
                            let _this = this;
                            if (value == undefined || value == '') return;
                            if (value == 2) {
                                let ci = 0;
                                _this.option.column.forEach(c => {
                                    if (c.prop == 'timeUnit' || c.prop == 'unitPrice') {
                                        _this.option.column[ci].addDisplay = false;
                                        _this.option.column[ci].editDisplay = false;
                                    }
                                    ci += 1;
                                });
                                _this.$forceUpdate();
                            } else {
                                let ci = 0;
                                _this.option.column.forEach(c => {
                                    if (c.prop == 'timeUnit' || c.prop == 'unitPrice') {
                                        _this.option.column[ci].addDisplay = true;
                                        _this.option.column[ci].editDisplay = true;
                                    }
                                    ci += 1;
                                });
                                _this.$forceUpdate();
                            }
                        },
                    },
                    {
                        label: '日期类型',
                        prop: 'dayType',
                        search: true,
                        searchSpan: 4,
                        span: 12,
                        type: 'select',
                        dicData: [
                            {
                                label: '工作日',
                                value: 'W',
                            },
                            {
                                label: '假日',
                                value: 'H',
                            },
                        ],
                        rules: [
                            {
                                required: true,
                                message: '请选择日期类型',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '计价单位',
                        prop: 'timeUnit',
                        type: 'select',
                        disabled: true,
                        addDisplay: false,
                        editDisplay: false,
                        dicData: [
                            {
                                label: '半小时',
                                value: 30,
                            },
                            {
                                label: '一小时',
                                value: 60,
                            },
                        ],
                    },
                    {
                        label: '单位价格(元)',
                        prop: 'unitPrice',
                        type: 'number',
                        min: '0',
                        addDisplay: false,
                        editDisplay: false,
                    },
                    {
                        label: '开始时段',
                        prop: 'beginSeg',
                        type: 'select',
                        dicUrl: '/api/park-spcod/sportstadium/getimeseg?startHour=8&endHour=23&timeSeg=60',
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                        dicFormatter: data => {
                            let datalist = [];
                            data.data.forEach(ele => {
                                datalist.push({
                                    name: ele,
                                    id: ele,
                                });
                            });
                            return datalist;
                        },
                        rules: [
                            {
                                required: false,
                                message: '请输入开放开始，09:00',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '结束时段  ',
                        prop: 'endSeg',
                        type: 'select',
                        dicUrl: '/api/park-spcod/sportstadium/getimeseg?startHour=8&endHour=23&timeSeg=60',
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                        dicFormatter: data => {
                            let datalist = [];
                            data.data.forEach(ele => {
                                datalist.push({
                                    name: ele,
                                    id: ele,
                                });
                            });
                            return datalist;
                        },
                        rules: [
                            {
                                required: false,
                                message: '请输入开放结束,20:00',
                                trigger: 'blur',
                            },
                        ],
                    },
                ],
            },
            data: [],
        };
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.sportprice_add, false),
                viewBtn: this.vaildData(this.permission.sportprice_view, false),
                delBtn: this.vaildData(this.permission.sportprice_delete, false),
                editBtn: this.vaildData(this.permission.sportprice_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    mounted() {
        this.getPage();
    },
    methods: {
        getSportGroundInfo(id) {
            this.groupList.forEach(e => {
                if (id == e.id) {
                    let ci = 0;
                    this.form.typeId = e.typeId;
                    this.form.priceType = e.priceType;
                    this.form.timeUnit = e.timeUnit;

                    // this.option.column.forEach((c) => {
                    //   console.log(c.prop);
                    //   if (c.prop == "typeId") {
                    //     this.option.column[ci].value = e.typeId;
                    //     console.log(this.option.column[ci]);
                    //   }
                    //   if (c.prop == "priceType") {
                    //     this.option.column[ci].value = e.priceType;
                    //     console.log(this.option.column[ci]);
                    //   }
                    //   if (c.prop == "timeUnit") {
                    //     this.option.column[ci].value = e.timeUnit;
                    //     console.log(this.option.column[ci]);
                    //   }
                    //   ci += 1;
                    // });
                    this.$forceUpdate();
                }
            });
        },
        getPage() {
            getPage(1, 1000).then(res => {
                this.groupList = res.data.data.records;
                this.option.column[1].dicData = res.data.data.records;
                this.$forceUpdate();
            });
        },
        rowSave(row, done, loading) {
            if (row.unitPrice < 0) {
                this.$message({
                    type: 'error',
                    message: '价格不能小于零!',
                });
                loading();
                return;
            }
            console.log(row);

            if (row.timeUnit == -1) {
                row.timeUnit = '';
            }
            add(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    window.console.log(error);
                },
            );
        },
        rowUpdate(row, index, done, loading) {
            update(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    console.log(error);
                },
            );
        },
        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return removeOne(row.id);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning('请选择至少一条数据');
                return;
            }
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(this.ids);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    this.$refs.crud.toggleSelection();
                });
        },
        beforeOpen(done, type) {
            if (['edit', 'view'].includes(type)) {
                getDetail(this.form.id).then(res => {
                    this.form = res.data.data;
                    this.form.typeId = this.form.typeId + '';
                });
            }
            done();
        },
        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },
        searchChange(params, done) {
            this.query = params;
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
            done();
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        currentChange(currentPage) {
            this.page.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        onLoad(page, params = {}) {
            this.loading = true;
            let o = Object.assign(params, this.query);
            this.datalist.forEach(element => {
                if (o.typeId == element.id) {
                    o.typeName = element.name;
                }
            });
            getPageList(page.currentPage, page.pageSize, o).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                data.records.forEach(r => {
                    r.typeId = r.typeId + '';
                    if (r.timeUnit == -1) {
                        r.timeUnit = '';
                    }
                });
                this.data = data.records;
                this.loading = false;
                this.selectionClear();
            });
        },
    },
};
</script>

<style></style>

<template>
    <basic-container isCard>
        <a v-if="onlyRead" class="pull-right cur-p" @click="openDia">编辑</a>
        <el-descriptions title="基本信息" :column="4" direction="vertical" :colon="false" :labelStyle="labelStyle">
            <el-descriptions-item label="物业费" :span="1">
                {{ buildObj.property_management_fee }}
                元/m²/月</el-descriptions-item>
            <el-descriptions-item label="层数" :span="1">
                {{ buildObj.floor_num }}层</el-descriptions-item>
            <el-descriptions-item label="建筑面积" :span="1">{{ buildObj.floorage }} m²</el-descriptions-item>
            <el-descriptions-item label="在管面积" :span="3">{{ buildObj.manger_area || 0 }} m²</el-descriptions-item>
            <el-descriptions-item label="楼宇位置" :span="4">
                {{ buildObj.address }}
            </el-descriptions-item>
            <el-descriptions-item label="所属区域" :span="4">
                <!-- {{ buildObj }}
        template -->

                <template v-for="(item, index) in partList">
                    <span :key="index" v-if="item.id == buildObj.part_id">
                        {{ item.name }}
                    </span>
                    <span :key="index" v-if="item.id == buildObj.sub_part_id">
                        {{ item.name }}
                    </span>
                </template>
            </el-descriptions-item>
            <el-descriptions-item label="楼宇图片" :span="4">
                <avue-form v-if="imgform.imgUrl.length > 0" :option="option" v-model="imgform"></avue-form>
                <div v-if="imgform.imgUrl.length == 0">暂无图片</div>
            </el-descriptions-item>
        </el-descriptions>
        <el-divider></el-divider>
        <div class="bt">
            <el-descriptions title="楼层配置" :column="4" direction="vertical" :colon="false">
            </el-descriptions>

            <a v-if="onlyRead" @click="onlyRead = false">编辑</a>
            <a v-if="!onlyRead">
                <span @click="saveRoom" class="mar-r-5">保存</span>
                <span @click="cancle">取消</span>
            </a>
        </div>
        <buildConfig :onlyRead="onlyRead" :buildObj="buildObj" @update="updateHight" ref="buildConfig"></buildConfig>
        <el-dialog size="50%" :modal="false" :close-on-click-modal="false" :visible.sync="detailDialogVisible" destroy-on-close :with-header="false">
            <div slot="title" class="header-title">
                <span class="fts-16">楼宇配置</span>
            </div>
            <div>
                <buildInfo ref="buildInfo" :showFloorNum="true"></buildInfo>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="detailDialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="update()">确 定</el-button>
            </span>
        </el-dialog>
    </basic-container>
</template>

<script>
import buildConfig from './buildConfig';
import { getMetaCommonInfo, updateMetaCommon, getPicListByIds, getMetaCommonList } from '@/api/busiMode/metaCommon';
import buildInfo from '../addBuild/buildInfo';

export default {
    components: { buildConfig, buildInfo },

    data() {
        return {
            labelStyle: {
                color: '#999',
                fontSize: '12px',
            },
            buildObj: {
                property_management_fee: 1123,
                floorage: 1000,
                manger_area: 100,
            },
            imgform: {
                imgUrl: [],
            },
            option: {
                labelWidth: 0,
                submitBtn: false,
                emptyBtn: false,
                disabled: true,
                column: [
                    {
                        label: '',
                        prop: 'imgUrl',
                        type: 'upload',
                        listType: 'picture-card',
                        loadText: '附件上传中，请稍等',
                        span: 24,

                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                    },
                ],
            },
            onlyRead: true,
            model: 'wisdom_park.wp_building',
            partList: [],
            partModel: 'wisdom_park.wp_part',
            detailDialogVisible: false,
            height: '',
        };
    },
    props: {
        currentRow: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    watch: {
        currentRow: {
            handler: function (newValue, oldName) {
                if (newValue && newValue.id) {
                    this.getMetaCommonInfo();
                }
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {},
    mounted() {
        this.getPart();
    },
    methods: {
        getPart() {
            let body = {
                current: 1,
                size: 10,
                orders: [{ field: 'sort', sort: 'ASC' }],
            };

            getMetaCommonList(this.partModel, body).then(res => {
                const data = res.data.data;
                this.partList = data.records;
            });
        },
        cancle() {
            this.onlyRead = true;
            //this.buildObj = {};
            let b = this.buildObj;
            this.buildObj = {};
            this.buildObj = JSON.parse(JSON.stringify(b));
        },
        saveRoom() {
            let configtable = {
                aboveFloorList: this.$refs.buildConfig.$refs.configTable.aboveFloorList,
                underFloorList: this.$refs.buildConfig.$refs.configTable.underFloorList,
            };
            if (configtable) {
                if (configtable.aboveFloorList.length + configtable.underFloorList.length > this.buildObj.floor_num) {
                    this.$message.error('地上和地下的层数不能大于整栋楼宇的层数');
                    return;
                }

                let floorage = 0;
                configtable.aboveFloorList.forEach(e => {
                    if (e.floor_area) {
                        console.log(e.floor_area, this.floorage);
                        // floorage = floorage + Number(e.floor_area);

                        floorage = floorage + Number(e.floor_area * 1000) / 1000;
                        console.log(floorage);
                    }
                });
                configtable.underFloorList.forEach(e => {
                    if (e.floor_area) {
                        console.log(e.floor_area);
                        floorage = floorage + Number(e.floor_area * 1000) / 1000;
                        console.log(floorage);
                    }
                });

                if (this.buildObj.floorage) {
                    console.log(this.buildObj.floorage);
                    console.log(Number(this.buildObj.floorage), Number(floorage.toFixed(2)));
                    if (Number(this.buildObj.floorage).toFixed(2) < Number(floorage.toFixed(2))) {
                        console.log(Number(floorage));
                        this.$message.error('地上和地下的面积不能大于整栋楼宇的面积');
                        return;
                    }
                }
            }
            this.$refs.buildConfig.$refs.configTable.save();
            this.onlyRead = true;

            if (this.height != this.buildObj['floor_height']) {
                this.updateMetaCommon(this.buildObj);
            }
        },
        updateHight(e) {
            this.buildObj['floor_height'] = e;
        },
        update() {
            let buildInfo = this.$refs.buildInfo.ruleForm;
            this.updateMetaCommon(buildInfo);
        },

        updateMetaCommon(buildInfo) {
            //判断当前面积
            let configtable = {
                aboveFloorList: this.$refs.buildConfig.$refs.configTable.aboveFloorList,
                underFloorList: this.$refs.buildConfig.$refs.configTable.underFloorList,
            };
            if (configtable) {
                if (configtable.aboveFloorList.length + configtable.underFloorList.length > this.buildObj.floor_num) {
                    this.$message.error('地上和地下的层数不能大于整栋楼宇的层数');
                    return;
                }

                let floorage = 0;
                configtable.aboveFloorList.forEach(e => {
                    if (e.floor_area) {
                        console.log(e.floor_area, this.floorage);
                        // floorage = floorage + Number(e.floor_area);

                        floorage = floorage + Number(e.floor_area * 1000) / 1000;
                        console.log(floorage);
                    }
                });
                configtable.underFloorList.forEach(e => {
                    if (e.floor_area) {
                        console.log(e.floor_area);
                        floorage = floorage + Number(e.floor_area * 1000) / 1000;
                        console.log(floorage);
                    }
                });

                if (this.buildObj.floorage) {
                    if (Number(this.buildObj.floorage).toFixed(2) < Number(floorage.toFixed(2))) {
                        console.log(Number(floorage));
                        this.$message.error('地上和地下的面积不能大于整栋楼宇的面积');
                        return;
                    }
                    // if (Number(this.buildObj.floorage) < Number(floorage)) {
                    //     console.log(Number(floorage));
                    //     this.$message.error('地上和地下的面积不能大于整栋楼宇的面积');
                    //     return;
                    // }
                }
            }
            //照片
            if (this.$refs.buildInfo.imgform) {
                let imgList = [];
                this.$refs.buildInfo.imgform.imgUrl.forEach(e => {
                    if (e.label) {
                        imgList.push(e.label);
                    }
                });
                buildInfo.attachment_id = imgList.toString();
            }
            let o = {
                update: buildInfo,
                primaryKeyValue: this.buildObj['id'],
                primaryKey: 'id',
            };
            updateMetaCommon(this.model, o).then(res => {
                if (res.data.code == 200) {
                    this.detailDialogVisible = false;
                    this.$nextTick(() => {
                        this.buildObj = buildInfo;
                    });
                    this.$emit('changName', buildInfo);
                }
            });
        },
        openDia() {
            this.detailDialogVisible = true;
            this.$nextTick(() => {
                console.log(this.buildObj);
                this.$refs.buildInfo.ruleForm = this.buildObj;
                if (this.buildObj.attachment_id) {
                    this.$refs.buildInfo.imgform = this.imgform;
                }
            });
        },
        getMetaCommonInfo() {
            let obj = {
                pk: 'id',
                id: this.currentRow.id,
            };
            getMetaCommonInfo(this.model, obj).then(res => {
                if (res.data.code == 200) {
                    this.buildObj = res.data.data;
                    this.height = res.data.data.floor_height;
                    if (res.data.data['attachment_id']) {
                        getPicListByIds(res.data.data['attachment_id']).then(res => {
                            if (res.data.code == 200) {
                                let arr = [];
                                res.data.data.forEach(pic => {
                                    arr.push({
                                        label: pic.id,
                                        value: pic.link,
                                    });
                                });
                                this.imgform.imgUrl = arr;
                            }
                        });
                    }
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.buildImg {
    width: 100px;
    height: 100px;
    margin-right: 10px;
}
/deep/ .el-descriptions-item__content {
    font-size: 1.01em;
}
.bt {
    font-size: 16px;
    color: #606266;
    margin-top: 20px;
}
.bt a {
    right: 20px;
    text-align: right;
    margin-top: -20px;
    font-size: 12px;
    cursor: pointer;
    display: block;
}
/deep/ .el-dialog__body {
    overflow: auto !important;
    height: 55vh;
}
.cur-a {
    cursor: pointer;
}
</style>

export default [
  {
    'label':'字符型',
    'value':'string'
  },
  {
    'label':'浮点型',
    'value':'float'
  },
  {
    'label':'整型',
    'value':'int'
  },
  {
    'label':'逻辑型',
    'value':'boolean'
  },
  {
    'label':'日期时间类型',
    'value':'date'
  }
]

<template>
  <basic-container
    isCard
    :body-style="{ padding: '0', height: '100%' }"
    class="h-100p"
  >
    <el-container class="h-100p">
      <el-header height="50px" class="lh-50 bor-b">
        <basic-page-header
          @back="back"
          class="lh-50"
          :title="'设备详情'"
        ></basic-page-header>
      </el-header>
      <el-container style="height: calc(100% - 50px)" class="pad-20">
        <el-main class="ov-a">
          <el-descriptions
            title=""
            :column="4"
            :colon="false"
            direction="vertical"
            :labelStyle="labelStyle"
          >
            <el-descriptions-item label="设备名称">{{
              ruleForm.name
            }}</el-descriptions-item>
            <el-descriptions-item label="终端类型">{{
              ruleForm.type
            }}</el-descriptions-item>
            <el-descriptions-item label="电子类型">
              {{ ruleForm.sub_type }}</el-descriptions-item
            >
            <el-descriptions-item label="所在场所">{{
              ruleForm.place_name
            }}</el-descriptions-item>
            <el-descriptions-item label="场所类型">
              <span v-if="place_type == 0">园区</span>
              <span v-if="place_type == 1">楼宇</span>
              <span v-if="place_type == 2">楼层</span>
              <span v-if="place_type == 3">房间</span>
              <span v-if="place_type == 4">会议室</span>
            </el-descriptions-item>
            <el-descriptions-item label="联系人">{{
              ruleForm.contacts
            }}</el-descriptions-item>

            <el-descriptions-item label="联系电话">
              {{ ruleForm.contacts_phone }}</el-descriptions-item
            >

            <el-descriptions-item label="离开时间">{{
              ruleForm.departureTime
            }}</el-descriptions-item>
            <el-descriptions-item label="详细地址描述">{{
              ruleForm.address
            }}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions
            :column="4"
            :colon="false"
            direction="vertical"
            :labelStyle="labelStyle"
          >
            <el-descriptions-item label="在线状态">
              <span v-if="equipmentDetail.is_online == 'online'">在线</span>
              <span v-if="equipmentDetail.is_online == 'offline'">离线</span>
              <span v-if="equipmentDetail.is_online == 'unknown'">未知</span>
            </el-descriptions-item>
            <el-descriptions-item label="	终端设备明细">{{
              equipmentDetail.terminal_detail
            }}</el-descriptions-item>
          </el-descriptions>
        </el-main>
      </el-container>
    </el-container>
  </basic-container>
</template>

<script>
import standardService from "@/api/metadata/standard";
import { submit } from "@/api/visitor/visitor";
import { mapGetters, mapState } from "vuex";
import * as dictbizService from "@/api/system/dictbiz";

import {
  getMetaCommonList,
  getMetaCommonInfo,
  getPicListByIds,
} from "@/api/busiMode/metaCommon";

export default {
  data() {
    return {
      ruleModel: {},
      labelStyle: {
        color: "#999",
        fontSize: "12px",
      },
      entModel: "wisdom_park.wp_publish_device_detail",
      model: "wisdom_park.wp_publish_device",
      ruleForm: {},
      equipmentDetail: {},
    };
  },
  computed: {},
  mounted() {
    if (this.$router.currentRoute.query.equipment) {
      this.id = this.$router.currentRoute.query.equipment;
      this.getMetaCommonInfo();
    }
  },
  methods: {
    getMetaCommonInfo() {
      let obj = {
        pk: "id",
        id: this.id,
      };
      getMetaCommonInfo(this.model, obj).then((res) => {
        if (res.data.code == 200) {
          this.ruleForm = res.data.data;
          this.getMetaCommonList(res.data.data.id);
        }
      });
    },

    getMetaCommonList(id) {
      let obj = {
        size: 100,
        current: 1,
        total: 0,
        wheres: [],
      };
      obj["wheres"].push({
        field: "dev_id",
        value: id,
      });

      getMetaCommonList(this.entModel, obj).then((res) => {
        const data = res.data.data;
        if (data.records[0]) {
          this.equipmentDetail = data.records[0];
        }
      });
    },

    back() {
      this.$router.push({
        path: "/information/equipment",
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>

<template>
  <el-row>
    <el-col :span="12" v-if="onlyRead">
      <el-descriptions
        title=""
        :column="4"
        :colon="false"
        :labelStyle="labelStyle"
      >
        <el-descriptions-item label="地上"></el-descriptions-item>
        <el-descriptions-item label="总层数">{{
          aboveFloorList.length
        }}</el-descriptions-item>
        <el-descriptions-item label="总面积"
          >{{ aboveFloorArea }}m²</el-descriptions-item
        >
      </el-descriptions>
    </el-col>
    <el-col :span="12" v-if="onlyRead">
      <el-descriptions
        title=""
        :column="4"
        :colon="false"
        :labelStyle="labelStyle"
      >
        <el-descriptions-item label="地下"></el-descriptions-item>
        <el-descriptions-item label="总层数">{{
          underFloorList.length
        }}</el-descriptions-item>
        <el-descriptions-item label="总面积"
          >{{ underFloorArea }}m²</el-descriptions-item
        >
      </el-descriptions>
    </el-col>
    <el-col :span="12" v-if="!onlyRead">
      <div class="demo-input-suffix mar-t-10 mar-b-10">
        <span class="mar-r-5">地上</span>
        <el-input
          placeholder="层数"
          size="mini"
          class="configInput"
          v-model="floorInfo.up"
        >
        </el-input
        >层
        <span class="pad-l-20">每层面积</span>
        <el-input
          placeholder="面积"
          size="mini"
          class="configInput"
          v-model="floorInfo.uparea"
        >
        </el-input
        >m²
      </div>
    </el-col>
    <el-col :span="12" v-if="!onlyRead">
      <div class="demo-input-suffix mar-t-10 mar-b-10">
        <span class="mar-r-5">地下</span>
        <el-input
          placeholder="层数"
          size="mini"
          class="configInput"
          v-model="floorInfo.down"
        >
        </el-input
        >层
        <span class="pad-l-20">每层面积</span>
        <el-input
          placeholder="面积"
          size="mini"
          class="configInput"
          v-model="floorInfo.down_area"
        >
        </el-input
        >m²
      </div>
    </el-col>

    <el-col :span="12">
      <ul>
        <li v-for="(ground, i) in aboveFloorList" :key="i" :span="12">
          <div class="demo-input-suffix configDetail">
            <span class="wid-20">{{ i + 1 }}</span>
            <span class="text" v-if="ground.above_under == 'above'">地上</span>
            <span class="text" v-if="ground.above_under == 'under'">地下</span>
            <!-- <el-input
              placeholder="别名"
              size="mini"
              class="configInput"
              :disabled="onlyRead == true"
              v-model="ground.name"
            > 
            </el-input>-->
            <el-input
              placeholder="层"
              size="mini"
              class="configInput"
              :disabled="onlyRead == true"
              v-model="ground.code"
            >
            </el-input
            >层
            <el-input
              placeholder="m²"
              size="mini"
              class="configInput"
              :disabled="onlyRead == true"
              v-model="ground.floor_area"
            >
            </el-input
            >m²
            <span class="delete" v-if="onlyRead != true">
              <i class="el-icon-close" @click="del(i, 'above', ground)"></i>
            </span>
          </div>
        </li>
        <li v-if="onlyRead != true">
          <el-button
            icon="el-icon-plus"
            class="addbtn"
            @click="addbtn('above')"
          ></el-button>
        </li>
      </ul>
    </el-col>
    <el-col :span="12">
      <ul>
        <li v-for="(ground, i) in underFloorList" :key="i" :span="12">
          <div class="demo-input-suffix configDetail">
            <span class="wid-20">{{ i + 1 }}</span>
            <span class="text" v-if="ground.above_under == 'above'">地上</span>
            <span class="text" v-if="ground.above_under == 'under'">地下</span>

            <el-input
              placeholder="层"
              size="mini"
              class="configInput"
              :disabled="onlyRead == true"
              v-model="ground.code"
            >
            </el-input
            >层
            <el-input
              placeholder="m²"
              size="mini"
              class="configInput"
              :disabled="onlyRead == true"
              v-model="ground.floor_area"
            >
            </el-input
            >m²
            <!-- <span class="text">
              <i v-if="ground.state == 'lock'" class="el-icon-lock"></i>
            </span> -->
            <span class="delete" v-if="onlyRead != true">
              <i class="el-icon-close" @click="del(i, 'under', ground)"></i>
            </span>
          </div>
        </li>
        <li v-if="onlyRead != true">
          <el-button
            icon="el-icon-plus"
            class="addbtn"
            @click="addbtn('under')"
            above
          ></el-button>
        </li>
      </ul>
    </el-col>
  </el-row>
</template>
<script>
import {
  getMetaCommonList,
  saveMetaCommon,
  delMetaCommon,
  updateMetaCommon,
} from "@/api/busiMode/metaCommon";
export default {
  components: {},
  data() {
    return {
      model: "wisdom_park.wp_floor",
      floorList: [],
      aboveFloorList: [],
      oldaboveFloorList: [],
      aboveFloorArea: 0,
      oldunderFloorList: [],
      underFloorList: [],
      underFloorArea: 0,
      floorInfo: {},
      delIds: [],
      buildObjFloorNum: 0,
      page: {
        pageSize: 50,
        currentPage: 1,
        total: 0,
      },
      labelStyle: {
        color: "#999",
        fontSize: "12px",
      },
    };
  },
  props: {
    onlyRead: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    newFloor: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    buildObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    buildObj: {
      handler: function (newValue, oldName) {
        if (newValue.id) {
          this.getList();
        }
        if (newValue.floor_num) {
          this.buildObjFloorNum = newValue.floor_num;
        }
      },
      immediate: true,
      deep: true,
    },
    underFloorList: {
      handler: function (newValue, oldName) {
        if (newValue) {
          this.getUnderFloorArea();
        }
      },
      immediate: true,
      deep: true,
    },
    aboveFloorList: {
      handler: function (newValue, oldName) {
        if (newValue) {
          this.getAboveFloorArea();
        }
      },
      immediate: true,
      deep: true,
    },
    "floorInfo.uparea": {
      handler: function (newValue, oldValue) {
        if (newValue) {
          if (!this.onlyRead) {
            // console.log(newValue.uparea);
            // console.log(oldValue.uparea);
            // if (newValue.uparea != oldValue.uparea) {
            //   this.getFloorList();
            // }
            // if (!oldValue.uparea) {
            //   this.getFloorList();
            // }
            this.getFloorList();
          }
        }
      },

      deep: true,
    },
  },
  computed: {},
  methods: {
    getFloorList() {
      if (this.floorInfo.down) {
        let downNum = Number(this.floorInfo.down);
        this.underFloorList = [];
        for (let i = 1; i <= downNum; i++) {
          let obj = {
            code: 0 - i,
            above_under: "under",
            floor_area: this.floorInfo.down_area,
          };
          if (this.oldunderFloorList.length > 0) {
            this.oldunderFloorList.forEach((e) => {
              if (e.code == 0 - i) {
                if (e.id) {
                  obj.id = e.id;
                }
              }
            });
          }
          this.underFloorList.push(obj);
        }
      }
      if (this.floorInfo.up) {
        this.aboveFloorList = [];
        let upNum = Number(this.floorInfo.up);
        for (let i = 1; i <= upNum; i++) {
          let obj = {
            code: i,
            above_under: "above",
            floor_area: this.floorInfo.uparea,
          };
          if (this.oldaboveFloorList.length > 0) {
            this.oldaboveFloorList.forEach((e) => {
              if (e.code == i) {
                if (e.id) {
                  obj.id = e.id;
                }
                //  obj.id = this.oldaboveFloorList[i - 1].id;
              }
            });
          }
          this.aboveFloorList.push(obj);
        }
      }
    },
    getUnderFloorArea() {
      this.underFloorArea = 0;
      this.underFloorList.forEach((e) => {
        if (e.floor_area) {
          this.underFloorArea = this.underFloorArea + Number(e.floor_area);
        }
      });
    },
    getAboveFloorArea() {
      this.aboveFloorArea = 0;
      this.aboveFloorList.forEach((e) => {
        if (e.floor_area) {
          // debugger;
          this.aboveFloorArea =
            (Number(this.aboveFloorArea * 1000) + Number(e.floor_area * 1000)) /
            1000;
          console.log(this.aboveFloorArea);
          // console.log(Number(e.floor_area));
        }
      });
    },
    getList() {
      let obj = {
        size: 100,
        current: 1,
        total: 0,
        wheres: [{ field: "building_id", value: this.buildObj.id }],
        orders: [{ field: "code+1000", sort: "DESC" }],
      };

      getMetaCommonList(this.model, obj).then((res) => {
        if (res.data.data.records.length > 0) {
          this.floorList = JSON.parse(JSON.stringify(res.data.data.records));
          this.aboveFloorList = [];
          this.underFloorList = [];
          res.data.data.records.forEach((e) => {
            if (e.above_under == "above") {
              this.aboveFloorList.push(e);
              this.oldaboveFloorList.push(e);
              this.aboveFloorArea = this.aboveFloorArea + e.floor_area;
            } else {
              this.underFloorList.push(e);
              this.oldunderFloorList.push(e);
              this.underFloorArea = this.underFloorArea + e.floor_area;
            }
          });

          let floo = {
            down: this.underFloorList.length,
            down_area:
              this.underFloorList.length > 0
                ? this.underFloorList[0].floor_area
                : 0,
            up: this.aboveFloorList.length,
            uparea: this.aboveFloorList[0].floor_area,
          };
          this.floorInfo = floo;
        }
      });
    },
    addbtn(type) {
      let obj = {
        floor_area: 0,
        above_under: type,
      };
      if (type == "above") {
        obj.code = this.aboveFloorList.length + 1;
        if (this.floorInfo.up) {
          this.floorInfo.up = this.aboveFloorList.length + 1;
        }
        this.aboveFloorList.push(obj);
        console.log(this.aboveFloorList);
      } else {
        if (this.floorInfo.down) {
          this.floorInfo.down = this.underFloorList.length + 1;
        }
        obj.code = 0 - (this.underFloorList.length + 1);
        this.underFloorList.push(obj);
      }
    },
    save(buildId) {
      let adds = [];
      let updates = [];
      let foors = this.aboveFloorList.concat(this.underFloorList);
      foors.forEach((floor) => {
        if (!floor.id) {
          adds.push(floor);
        } else {
          this.floorList.forEach((e) => {
            if (e.id == floor.id) {
              if (
                e.floor_area == floor.floor_area &&
                e.above_under == floor.above_under &&
                e.above_under == floor.above_under
              ) {
                //console.log(e);
              } else {
                updates.push(floor);
              }
            }
          });
        }
      });
      console.log(adds);
      console.log(updates);
      if (buildId) {
        this.buildObj.id = buildId;
      }
      // console.log(updates);
      // this.delMetaCommon(this.delIds);
      this.updateMetaCommon(updates);
      this.saveMetaCommon(adds, updates, buildId);
    },
    async delMetaCommon(data) {
      var ary = [];
      data.forEach((v, index) => {
        const res = delMetaCommon(this.model, { pk: "id", ids: [v] }).then(
          (_res) => {
            if (_res.code == 200) {
              return {
                id: index + 1,
                imgs: _res.data,
              };
            }
            return "";
          }
        );
        ary.push(res);
      });
      await Promise.all(ary);
    },
    async saveMetaCommon(data, update) {
      //先将之前的floor 都删除了
      if (this.floorList.length > 0) {
        let floors = JSON.parse(JSON.stringify(this.floorList));
        let nodels = [];
        this.floorList.forEach((e, i) => {
          data.concat(update).forEach((d) => {
            if (e.code == d.code && e.above_under == d.above_under) {
              console.log("删除432", e.code);
              // floors.splice(i, 1);
              nodels.push(e.code);
            }
          });
        });

        let arr = [];

        this.floorList.forEach((e, i) => {
          this.aboveFloorList.concat(this.underFloorList).forEach((d) => {
            if (
              e.code == d.code &&
              e.floor_area == d.floor_area &&
              e.above_under == d.above_under
            ) {
              nodels.push(e.code);
              console.log("不删除", e.code);
            }
          });
        });
        console.log(nodels);
        floors.forEach((e) => {
          if (!nodels.includes(e.code)) {
            this.delIds.push(e.id);
          }
        });
        console.log(this.delIds);
        if (this.delIds.length > 0) {
          console.log("del", this.delIds);
          delMetaCommon(this.model, {
            pk: "id",
            ids: this.delIds,
          })
            .then((res) => {
              if (res.data.code == 200) {
              }
            })
            .then(() => {
              this.saveReal(data);
            });
        } else {
          this.saveReal(data);
        }
      } else {
        this.saveReal(data);
      }
    },
    async saveReal(data) {
      var ary = [];
      data.forEach((v, index) => {
        v.building_id = this.buildObj.id;
        const res = saveMetaCommon(this.model, { values: v }).then((_res) => {
          if (_res.code == 200) {
            return {
              id: index + 1,
              imgs: _res.data,
            };
          }
          return "";
        });
        ary.push(res);
      });

      await Promise.all(ary).then((result) => {
        this.$emit("close");
      });
    },
    async updateMetaCommon(data) {
      var ary = [];
      data.forEach((v, index) => {
        let o = {
          update: v,
          primaryKeyValue: v.id,
          primaryKey: "id",
        };
        const res = updateMetaCommon(this.model, o).then((_res) => {
          if (_res.code == 200) {
            return {
              id: index + 1,
              imgs: _res.data,
            };
          }
          return "";
        });
        ary.push(res);
      });
      await Promise.all(ary);
    },
    del(i, type, floor) {
      if (type == "above") {
        // this.floorInfo.up = Number(this.floorInfo.up) - 1;
        this.aboveFloorList.splice(i, 1);
      } else {
        //  this.floorInfo.down = Number(this.floorInfo.down) - 1;
        this.underFloorList.splice(i, 1);
      }
      if (floor.id) {
        //this.delIds.push(floor.id);
        // this.floorList.forEach((e, i) => {
        //   if (e.id == floor.id) {
        //     console.log(e);
        //     console.log(floor);
        //     delMetaCommon(this.model, { pk: "id", ids: [floor.id] }).then(
        //       (_res) => {
        //         console.log(_res);
        //         if (_res.data.code == 200) {
        //           this.floorList.splice(i, 1);
        //           console.log(this.floorList);
        //         }
        //       }
        //     );
        //   }
        // });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.configInput {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 80px;
  margin: 0px 5px;
}
ul,
li {
  list-style: none;
  margin-bottom: 5px;
  .delete {
    margin-left: 5px;
    display: none;
    cursor: pointer;
  }
  &:hover {
    .delete {
      display: contents;
    }
  }
}
.configDetail .text {
  padding: 0px 20px;
  color: #606266;
}
ul {
  padding: 0px;
  margin-top: 0px;
}
.configDetail {
  background: #f5f7fa;
  padding: 5px;
}
.addbtn {
  border: 1px dotted;
  padding: 5px;
  width: 100%;
  margin-top: 10px;
}
</style>

<template>
  <el-col :span="24">
    <template v-for="(b, index) in btnList">
      <span
        :key="index"
        v-if="!b.btnEdit"
        style="margin-right: 10px"
        class="opertationSpan"
      >
        <el-button
          :type="b.clazz"
          class="opertationBtn"
          :class="{ opertationBtnActive: b.btnActive == btnActive }"
          size="small"
          @click="
            btnActive = b.btnActive;
            getCurrentBtn(b);
          "
          @dblclick.native="updateTitle(b)"
        >
          <i :class="b.icon"> </i>{{ b.name }}
        </el-button>
        <i class="el-icon-delete deleteicon" @click="deleteOper(index)"></i>
        <el-dropdown class="settingIcon" @command="btbDO">
          <i class="el-icon-setting" title="切换操作类型"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              @click.native="btbDO('business', index)"
              icon="el-icon-plus"
              >业务</el-dropdown-item
            >
            <el-dropdown-item
              @click.native="btbDO('staticFun', index)"
              icon="el-icon-circle-plus"
              >静态</el-dropdown-item
            >
            <el-dropdown-item
              @click.native="btbDO('business', index)"
              icon="el-icon-circle-plus-outline"
              >新增页面</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </span>

      <el-input
        size="small"
        :key="index"
        class="wid-100"
        v-if="b.btnEdit"
        placeholder="双击修改按钮"
        v-model="btnTitle"
      >
        <i
          class="el-icon-check el-input__icon"
          slot="suffix"
          @click="saveTitle(b, index)"
        >
        </i>
      </el-input>
    </template>

    <el-button
      size="small"
      icon="el-icon-plus fts-12"
      class="el-button--warning"
      @click="addTab()"
    >
      新增操作
    </el-button>
    <business v-if="currentBtnObj.type == 'business'"></business>
    <chooseType v-if="currentBtnObj.type == 'any'"></chooseType>
    <staticFun v-if="currentBtnObj.type == 'staticFun'"></staticFun>
    <el-dialog
      title="选择操作类型"
      width="30%"
      :visible.sync="operDialogVisible"
      append-to-body
      destroy-on-close
    >
      <chooseType ref="chooseType"></chooseType>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="operDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="
            operDialogVisible = false;
            sureAdd();
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </el-col>
</template>

<script>
import business from "./business";
import staticFun from "./static";
import chooseType from "./chooseType";

export default {
  components: { business, staticFun, chooseType },
  data() {
    return {
      btnTitle: "",
      operDialogVisible: false,
      currentBtnObj: {},
      newBtnOperType: "business",
      btnList: [
        {
          name: "新增",
          btnActive: 1,
          clazz: "primary",
          btnEdit: false,
          icon: "el-button--warning ",
          type: "business",
        },
        {
          name: "修改",
          btnActive: 2,
          clazz: "primary",
          btnEdit: false,
          icon: "el-icon-edit ",
          type: "business",
        },
        {
          name: "删除",
          btnActive: 3,
          clazz: "primary",
          icon: "el-icon-delete",
          btnEdit: false,
          type: "business",
        },
        {
          name: "轮呼",
          btnActive: 4,
          clazz: "",
          icon: "el-icon-phone",
          btnEdit: false,
          type: "staticFun",
        },
      ],
      btnActive: 1,
    };
  },
  methods: {
    getCurrentBtn(b) {
      this.currentBtnObj = b;
    },
    updateTitle(b) {
      b.btnEdit = !b.btnEdit;
    },
    addTab() {
      this.operDialogVisible = true;
    },
    sureAdd() {
      let obj = {
        title: "New Btn",
        name: this.$refs.chooseType.operName || "new",
        btnEdit: false,
        icon: "",
        btnActive: this.btnList.length + 1,
        type: this.$refs.chooseType.type,
      };
      this.btnActive = this.btnList.length + 1;
      this.btnList.push(obj);
      this.currentBtnObj = obj;
    },
    saveTitle(b, index) {
      b.name = this.btnTitle;
      b.btnEdit = !b.btnEdit;
      this.currentBtnObj.name = this.btnTitle;
      this.btnList[index] = b;
      this.btnTitle = "";
    },
    btbDO(type, index) {
      this.currentBtnObj.type = type;
      this.btnList[index] = this.currentBtnObj;
    },
    deleteOper(index) {
      this.$confirm("此操作删除当前所有操作, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (isNaN(index) || index > this.btnList.length) {
            return false;
          }
          this.btnList.splice(index, 1);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "取消操作",
          });
        });
    },
  },
  created() {
    this.currentBtnObj = this.btnList[0];
  },
};
</script>
<style scoped lang="scss">
.opertationBtnActive {
  border: #f5896c 1px solid;
}
.opertation-edit-icon {
  position: absolute;
  right: 2px;
  top: 10px;
}
.wid-100 {
  width: 100px;
}
.deleteicon {
  position: relative;
  display: none;
  right: 16px;
  top: -7px;
  color: #fff;
  background: #f5896c;
  padding: 2px;
}
.opertationSpan {
  &:hover {
    .settingIcon {
      display: inherit;
    }
    .deleteicon {
      display: inherit;
    }
    margin-right: -25px !important;
  }
}
.opertationBtn {
  &:hover {
    border: #f5896c 1px dashed;
  }
}
.settingIcon {
  display: none;
  position: relative;
  right: 53px;
  top: -6px;
  color: #fff;
  background: #f5896c;
  padding: 0px 2px;
}
</style>

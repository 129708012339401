<template>
    <basic-container class="h-100p">
        <avue-crud ref="table" :data="tableData" :page.sync="page" :option="tableOption" :table-loading="tableLoading" :before-open="beforeOpen" :search.sync="search" v-model="form"
                   @search-change="onLoad" @on-load="onLoad" @refresh-change="onLoad" @row-save="saveTableForm" @row-update="
        (row, index, done, loading) => saveTableForm(row, done, loading)
      ">
            <template slot="supplementary_template_idForm" slot-scope="{ disabled, size }">
                <model-search-select :disabled="disabled" v-model="form.supplementary_template_id" model="wp_contract_template" labelKey="template_name" valueKey="id"
                                     :params="{ template_type: '2' }" />
            </template>
            <template slot="lessee_b_company_idForm" slot-scope="{ disabled, size }">
                <model-search-select :disabled="disabled" v-model="form.lessee_b_company_id" model="wp_company" :fillModel.sync="form" :fills="[{ source: 'name', target: 'lessee_b_name' }]" />
            </template>
            <template slot="lessee_a_company_idForm" slot-scope="{ disabled, size }">
                <model-search-select :disabled="disabled" v-model="form.lessee_a_company_id" model="wp_company" :fillModel.sync="form" :fills="[{ source: 'name', target: 'lessee_a_name' }]" />
            </template>
            <template slot="attachment_idsForm" slot-scope="{ disabled, size }">
                <ManageFormFile :disabled="disabled" v-model="form.attachment_ids"></ManageFormFile>
            </template>
            <template slot-scope="{ size, type }" slot="menuForm">
                <el-button v-if="type === 'add' || type === 'edit'" icon="el-icon-circle-check" type="primary" :size="size" @click="tableSave">暂 存</el-button>
                <el-button icon="el-icon-circle-close" :size="size" @click="$refs.table.closeDialog()">取 消</el-button>
            </template>
            <template slot="menu" slot-scope="scope">
                <el-button v-if="scope.row.status == 1" type="text" size="small" icon="el-icon-edit" @click="$refs.table.rowEdit(scope.row)">编辑
                </el-button>
            </template>
        </avue-crud>
    </basic-container>
</template>
<script>
import { detail as getContractDetail, supplementarySave, supplementaryUpdate } from '@/api/contract/index.js';
import { getMetaCommonList } from '@/api/busiMode/metaCommon';
import * as dictService from '@/api/system/dictbiz';
import ManageFormFile from './manageFormFile.vue';

export default {
    components: { ManageFormFile },
    data() {
        return {
            id: this.$route.params.id || null,
            contractInfo: null,
            model: 'wisdom_park.wp_contract_supplementary',

            form: {},
            tableLoading: false,
            tableData: [],
            search: {},
            page: {
                pageSizes: this.$store.state.common.pageSizes,
                pagerCount: this.$store.state.common.pagerCount,
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            formStatus: 2,
            tableOption: {
                height: 'auto',
                labelPosition: 'top',
                searchMenuPosition: 'right',
                searchLabelPosition: 'right',
                searchMenuSpan: 24,
                border: true,
                dialogWidth: '60%',
                // dialogCloseBtn: false,
                cancelBtn: false,
                editBtn: false,
                delBtn: false,
                viewBtn: true,
                column: [
                    {
                        label: '主合同编号',
                        prop: 'contract_code',
                        span: 12,
                        width: 200,
                        disabled: true,
                    },
                    {
                        label: '补充协议编号',
                        prop: 'supplementary_code',
                        span: 12,
                        width: 200,
                    },
                    {
                        label: '补充',
                        prop: 'supplementary_type',
                        span: 12,
                        width: 150,
                        type: 'select',
                        dicUrl: dictService.getUrl('SupplementaryType'),
                        props: {
                            label: 'dictValue',
                            value: 'dictKey',
                        },
                        dataType: 'number',
                        rules: [
                            {
                                required: true,
                                message: '请选择补充协议类型',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '补充',
                        prop: 'supplementary_template_id',
                        span: 12,
                        hide: true,
                        showColumn: false,
                        rules: [
                            {
                                required: true,
                                message: '请选择补充协议模板',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '约定开始时间',
                        prop: 'start_date',
                        width: 100,
                        span: 12,
                        disabled: true,
                        type: 'datetime',
                        format: 'yyyy-MM-dd',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                    },
                    {
                        label: '约定结束时间',
                        prop: 'end_date',
                        width: 100,
                        span: 12,
                        disabled: true,
                        type: 'datetime',
                        format: 'yyyy-MM-dd',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                    },
                    // {
                    //   label: "甲方签订人",
                    //   prop: "sign_person",
                    //   span: 12,
                    //   width: 200,
                    //   rules: [
                    //     {required: true, message: "请输入甲方签订人", trigger: "blur"}
                    //   ]
                    // },
                    {
                        label: '签订时间',
                        prop: 'sign_date',
                        width: 100,
                        span: 12,
                        type: 'date',
                        format: 'yyyy-MM-dd',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        rules: [{ required: true, message: '请选择签订时间', trigger: 'blur' }],
                    },
                    // {
                    //   label: "乙方公司",
                    //   prop: "lessee_a_name",
                    //   span: 12,
                    //   display: false,
                    //   width: 200
                    // },
                    // {
                    //   label: "乙方公司",
                    //   prop: "lessee_a_company_id",
                    //   span: 12,
                    //   hide: true,
                    //   showColumn: false,
                    //   width: 200,
                    //   disabled: true
                    // },
                    // {
                    //   label: "乙方签订人",
                    //   prop: "lessee_a_sign",
                    //   span: 12,
                    //   width: 200,
                    //   rules: [
                    //     {required: true, message: "请输入乙方签订人", trigger: "blur"}
                    //   ]
                    // },
                    // {
                    //   label: "丙方公司",
                    //   prop: "lessee_b_name",
                    //   display: false,
                    //   span: 12,
                    //   width: 200
                    // },
                    // {
                    //   label: "丙方公司",
                    //   prop: "lessee_b_company_id",
                    //   hide: true,
                    //   showColumn: false,
                    //   span: 12,
                    //   width: 200,
                    //   rules: [
                    //     {required: true, message: "请选择丙方公司", trigger: "blur"}
                    //   ]
                    // },
                    // {
                    //   label: "丙方签订人",
                    //   prop: "lessee_b_sign",
                    //   span: 12,
                    //   width: 200,
                    //   rules: [
                    //     {required: true, message: "请输入丙方签订人", trigger: "blur"}
                    //   ]
                    // },
                    {
                        label: '备注',
                        prop: 'note',
                        width: 100,
                        span: 24,
                        type: 'textarea',
                        minRows: 3,
                    },
                    // {
                    //   label: "附件",
                    //   prop: "attachment_ids",
                    //   width: 100,
                    //   span: 24
                    // },
                ],
            },
        };
    },
    mounted() {
        if (this.id) {
            this.getContractDetail();
            console.log(this.$refs.table);
        }
    },
    methods: {
        tableSave() {
            this.formStatus = 1;
            this.form.id ? this.$refs.table.rowUpdate() : this.$refs.table.rowSave();
        },
        getContractDetail() {
            getContractDetail({
                id: this.id,
            }).then(res => {
                this.contractInfo = res.data.data;
            });
        },
        onLoad() {
            let wheres = [{ field: 'contract_id', op: 'LIKE', value: this.id }];
            // ['template_type' , 'template_name' , 'create_time' , 'template_use'].forEach(
            //   name => {
            //     if(this.search[name]){
            //       if(name === 'template_name'){
            //         wheres.push({field: name , op: 'LIKE' , value: this.search[name]})
            //       }else{
            //         wheres.push({field: name , value: this.search[name]})
            //       }
            //     }
            //   }
            // )
            this.tableData = [];
            this.tableLoading = true;
            getMetaCommonList(this.model, {
                current: this.page.currentPage,
                size: this.page.pageSize,
                wheres: wheres,
            })
                .then(res => {
                    this.tableLoading = false;
                    if (res.data && res.data.code === 200) {
                        this.tableData = res.data.data.records;
                        this.page.total = res.data.data.total;
                    } else {
                        this.tableData = [];
                        this.page.total = 0;
                    }
                })
                .catch(e => {
                    this.tableLoading = false;
                });
        },
        beforeOpen(done, type) {
            this.formStatus = 2;
            if (['edit', 'view'].includes(type)) {
                done();
            } else {
                this.form['lessee_a_company_id'] = this.contractInfo.companyId;
                this.form['lessee_a_name'] = this.contractInfo.rentersName;
                this.form['start_date'] = this.contractInfo.startDate;
                this.form['end_date'] = this.contractInfo.endDate;
                this.form['contract_code'] = this.contractInfo.code;
                done();
            }
        },
        saveTableForm(row, done, loading) {
            let body = {
                ...row,
                contract_id: this.id,
                status: this.formStatus,
            };
            [
                { name: 'start_date', value: '00:00:00' },
                { name: 'end_date', value: '23:59:59' },
                { name: 'sign_date', value: '00:00:00' },
            ].forEach(item => {
                if (!body[item.name].includes(item.value)) {
                    body[item.name] = `${body[item.name]} ${item.value}`;
                }
            });
            (body.id ? supplementaryUpdate : supplementarySave)(body)
                .then(res => {
                    this.responeHandle(res, done, loading);
                })
                .catch(e => {
                    loading();
                });
        },
        responeHandle(res, done, loading) {
            if (res.data && res.data.code === 200) {
                done && done();
                this.onLoad();
                this.$message.success(res.data.msg);
            } else {
                loading && loading();
                this.$message.error(res.data.msg);
            }
        },
    },
};
</script>

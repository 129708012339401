export default [
  {
    value: 'MYSQL',
    label: 'MYSQL'
  },
  {
    value: 'HDFS',
    label: 'HDFS'
  },
  {
    value: 'POSTGRESQL',
    label: 'POSTGRESQL'
  },
  {
    value: 'HIVE',
    label: 'HIVE/IMPALA'
  },
  {
    value: 'SPARK',
    label: 'SPARK'
  },
  {
    value: 'CLICKHOUSE',
    label: 'CLICKHOUSE'
  },
  {
    value: 'ORACLE',
    label: 'ORACLE'
  },
  {
    value: 'SQLSERVER',
    label: 'SQLSERVER'
  },
  {
    value: 'DB2',
    label: 'DB2'
  },
  {
    value: 'PRESTO',
    label: 'PRESTO'
  },
  {
    value: 'FTP',
    label: 'FTP'
  },
  {
    value: 'API',
    label: 'API'
  },
  {
    value: 'KAFKA',
    label: 'KAFKA'
  }
];
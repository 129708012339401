<template>
    <basic-container isCard>
        <el-row>
            <el-col :span="4" class="pad-0">
                <i class="el-icon-back cur-p fts-18 mar-r-5" @click="goBuild"></i>
                <strong class="fts-18 mar-t-30">{{ graden.building_name }}</strong>
                <div class="mar-t-5 mar-l-23">
                    层高:
                    <strong class="mar-r-10">{{ getHei(graden.floor_height) }}</strong>
                    <!-- 层数:<strong>{{
            graden.floor_num
          }}</strong> -->
                </div>
                <div class="mar-t-5 mar-l-23">
                    租赁方式 :<strong>{{ graden.lease_type }}</strong>
                </div>
            </el-col>
            <el-col :span="10" class="text-center title b-l o-a">
                <div class="lygk">
                    <div class="bnd">楼宇概况</div>
                    <div class="w-80">
                        <div class="thsj-tj">{{ graden.floor_num|| 0  }}</div>
                        <span class="text-title">总层数</span>
                    </div>
                    <div class="w-80">
                        <div class="thsj-tj">{{ graden.floorage || 0 }}</div>
                        <span class="text-title">建筑面积(m²)</span>
                    </div>
                    <div class="w-120">
                        <div class="thsj-tj">{{ graden.sum_room_area_yz || 0 }}</div>
                        <span class="text-title">出租面积(m²)</span>
                    </div>
                    <div class="w-120">
                        <div class="thsj-tj">{{ graden.sum_room_area_wz || 0 }}</div>
                        <span class="text-title">空置面积(m²)</span>
                    </div>
                    <div class="w-120">
                        <div class="thsj-tj">{{ graden.sum_room_area_ys || 0 }}</div>
                        <span class="text-title">已售面积(m²)</span>
                    </div>
                    <div class="w-120">
                        <div class="thsj-tj">{{ graden.sum_room_area_zy || 0 }}</div>
                        <span class="text-title">自用面积(m²)</span>
                    </div>
                    <div class="w-80">
                        <div class="thsj-tj">{{ graden.sum_checkin_num }}</div>
                        <span class="text-title">入驻企业数</span>
                    </div>
                    <div class="w-80">
                        <div class="thsj-tj">{{getRZL(graden)}}</div>
                        <span class="text-title">企业入驻率</span>
                    </div>
                </div>

            </el-col>
            <el-col :span="5" class="text-center thsj pad-10 pad-t-15">
                <div class="bnd">本年累计</div>
                <el-col :span="8" class="pad-l-0 pad-r-0">
                    <div class="thsj-tj">{{ graden.fee_type_1_amounts || 0 }}</div>
                    <span class="text-title">租金收入</span>
                </el-col>
                <el-col :span="8" class="pad-l-0 pad-r-0">
                    <div class="thsj-tj">{{ graden.fee_type_2_amounts || 0 }}</div>
                    <span class="text-title">物业费收入</span>
                </el-col>
                <el-col :span="8" class="pad-l-0 pad-r-0">
                    <div class="thsj-tj">{{ graden.fee_type_4_amounts || 0 }}</div>
                    <span class="text-title">其他收入</span>
                </el-col>
            </el-col>
            <el-col :span="5" class="text-center thsj pad-10 b-l ">
                <div class="bnd">能源使用</div>
                <el-col :span="8">
                    <div class="thsj-tj">{{ graden.sum_power_consumption || "0" }}</div>
                    <span class="text-title">用电量(kW·h)</span>
                </el-col>
                <el-col :span="8">
                    <div class="thsj-tj">{{ graden.sum_water_consumption || "0" }}</div>
                    <span class="text-title">用水量(吨)</span>
                </el-col>
                <el-col :span="8">
                    <div class="thsj-tj">{{ graden.sum_energy_conservation || "0" }}</div>
                    <span class="text-title">空调(台)</span>
                </el-col>
            </el-col>
        </el-row>
    </basic-container>
</template>

<script>
import { getMetaCommonList, delMetaCommon } from '@/api/busiMode/metaCommon';

export default {
    components: {},
    data() {
        return {
            graden: {},
            model: 'wisdom_park.v_wp_building_stat',
        };
    },
    props: {
        buildObj: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    watch: {
        buildObj: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.graden.name = val.name;
                    this.graden.floor_height = val.floor_height;
                    this.graden.lease_type = val.lease_type;
                    this.onLoad();
                }
            },
        },
    },
    computed: {},
    mounted() {},
    methods: {
        getRZL(graden) {
            if (graden.sum_room_area_yz && graden.floorage) {
                return ((graden.sum_room_area_yz / graden.floorage) * 100).toFixed(2) + '%';
            } else {
                return '-';
            }
        },
        getHei(h) {
            let hei = '';
            if (h == 1) {
                hei = '1～3米';
            } else if (h == 2) {
                hei = '3～4米';
            } else if (h == 3) {
                hei = '4～5米';
            } else {
                hei = '5米以上';
            }
            return hei;
        },
        goBuild() {
            this.$emit('goBuild');
        },
        onLoad() {
            let params = {
                wheres: [
                    {
                        field: 'building_id',
                        value: this.buildObj.building_id,
                    },
                ],
            };
            getMetaCommonList(this.model, {
                current: 1,
                size: 500,
                ...params,
            }).then(res => {
                const data = res.data.data;
                this.graden = data.records[0];
                if (this.graden.sum_free_area) {
                    this.graden.sum_free_area = this.graden.sum_free_area.toFixed(2);
                }
                if (this.graden.sum_checkin_area) {
                    this.graden.sum_checkin_area = this.graden.sum_checkin_area.toFixed(2);
                }
                // if (this.graden.sum_floor_area) {
                //   this.graden.sum_floor_area = this.graden.sum_floor_area.toFixed(2);
                // }
            });
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.thsj-tj {
    font-size: 18px;
    height: 22px;
    line-height: 22px;
}
.text-title {
    margin-top: 6px;
    color: rgba(0, 0, 0, 0.5);
    display: block;
}
.title {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px 10px 0px 10px;
}
.bnd {
    position: absolute;
    top: -9px;
    color: rgba(0, 0, 0, 0.5);
}
.b-l {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
}
.w-120 {
    width: 120px;
    float: left;
}
.w-80 {
    float: left;
}
.lygk {
    width: 100%;
    width: 800px;
    overflow: auto;
    display: flex;
}
.o-a {
    overflow: auto;
}
</style>

<template>
  <basic-container>
    <avue-crud :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               :before-open="beforeOpen"
               v-model="form"
               ref="crud"
               @row-update="rowUpdate"
               @row-save="rowSave"
               @row-del="rowDel"
               @search-change="searchChange"
               @search-reset="searchReset"
               @current-change="currentChange"
               @size-change="sizeChange"
               @refresh-change="refreshChange"
               @on-load="onLoad">
      <template slot="menuLeft">
        <el-button type="danger"
                   size="small"
                   icon="el-icon-delete"
                   plain
                   v-if="permission.xpscarrier_delete"
                   @click="handleDelete">删 除
        </el-button>
      </template>
      <!-- <avue-form :option="imgOption" v-model="imgForm"
           :upload-preview="uploadPreview"
           :upload-error="uploadError"
           :upload-delete="uploadDelete"
           :upload-before="uploadBefore"
           :upload-after="uploadAfter"
           @submit="handleSubmit" >
   </avue-form> -->
      <template slot-scope="scope" slot="menu">
        <template v-if="scope.row.status == 1">
          <el-button type="text"
              size="small"
              icon="el-icon-download"
              class="none-border" @click="tip(scope.row)">休息</el-button>
        </template>
        <template v-if="scope.row.status == 0">
          <el-button type="text"
              size="small"
              icon="el-icon-upload2"
              class="none-border" @click="tip(scope.row)">营业</el-button>
        </template>
      </template>
      <template slot-scope="{ row }" slot="iconUrl">
      <form-file
        v-if="row.iconUrl"
        :disabled="true"
        :value="row.iconUrl"
      ></form-file>
    </template>
      <template slot="iconUrlForm" slot-scope="{ disabled, size }" >
        <form-file v-model="form.iconUrl" :disabled="disabled" :max="1" accept="image/*"></form-file>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
  import {getCarrierPage, getDetail, add, update, remove,updatestatus} from "@/api/spcod/xpscarrier";
  import {mapGetters} from "vuex";

  export default {
    data() {
      return {
        form: {},
        query: {},
        loading: true,
        page: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        selectionList: [],
        option: {
          height:'auto',
          calcHeight: 30,
          tip: false,
          searchShow: true,
          searchMenuSpan: 6,
          border: true,
          index: true,
          viewBtn: true,
          dialogClickModal: false,
          delBtn:false,
          column: [
            {
              label: "快递公司",
              prop: "name",
              search:true,
              rules: [{
                required: true,
                message: "请输入快递公司名称",
                trigger: "blur"
              }]
            },
            {
              label: "客服电话",
              prop: "contactPhone",
              search:true,
              rules: [{
                required: true,
                message: "请输入客服电话",
                trigger: "blur"
              }]
            },
            {
              label: "头像",
              prop: "iconUrl",
              // type: 'upload',
              viewDisabled: true,
              span: 24,
              component: "form-file",
              params: {
                accept: "image/*",
                multiple: false,
                max: 1,
                placeholder: '',
              },
            },
            // {
            // label: '头像',
            // prop: "iconUrl",
            // type: 'upload',
            // listType: 'picture-card',
            // loadText: "附件上传中，请稍等",
            // span: 24,
            // propsHttp: {
            //   res: "data",
            //   url: "link",
            // },
            // tip: '只能上传jpg/png用户头像，且不超过500kb',
            // action: '/api/jida-resource/oss/endpoint/put-file-attach'
            // },
            {
              label: "状态",
              prop: "status",
              search:true,
              addDisplay: false,
              type: 'select',
              editDisplay: false,
              searchSpan: 4,
              dicData:[{
                label:'有效',
                value:1
              },{
                label:'无效',
                value:0
              }],
              rules: [{
                required: false,
                message: "请输入状态",
                trigger: "blur",
              }]
            },
            {
              label: '备注',
              prop: 'memo',
              hide:false,
              type: 'textarea',
              span:24,
              minRow:2
            }            
          ]
        },
        data: []
      };
    },
    computed: {
      ...mapGetters(["permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.xpscarrier_add, false),
          viewBtn: this.vaildData(this.permission.xpscarrier_view, false),
          delBtn: this.vaildData(this.permission.xpscarrier_delete, false),
          editBtn: this.vaildData(this.permission.xpscarrier_edit, false)
        };
      },
      ids() {
        let ids = [];
        this.selectionList.forEach(ele => {
          ids.push(ele.id);
        });
        return ids.join(",");
      }
    },
    methods: {
      rowSave(row, done, loading) {
        add(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          window.console.log(error);
        });
      },
      rowUpdate(row, index, done, loading) {
        update(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          console.log(error);
        });
      },
      tip(row){
        let _this = this;
        this.$alert('确定修改状态吗？', '确定').then(() => {
          updatestatus(row.id).then(res => {
            if(res.data.success){
              if (row.state === 1) {
                row.state = 0;
              }else {
                row.state = 1;
              }
              _this.onLoad(_this.page);
              _this.$message.success(res.data.msg)
            } else {
              _this.$message.success(res.data.msg)
            }
          }).catch((e) => {
            _this.onLoad(_this.page);
          });
        })
      },
      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(row.id);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
          });
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
            this.$refs.crud.toggleSelection();
          });
      },
      beforeOpen(done, type) {
        if (["edit", "view"].includes(type)) {
          getDetail(this.form.id).then(res => {
            this.form = res.data.data;
          });
        }
        done();
      },
      searchReset() {
        this.query = {};
        this.onLoad(this.page);
      },
      searchChange(params, done) {
        this.query = params;
        this.page.currentPage = 1;
        this.onLoad(this.page, params);
        done();
      },
      selectionClear() {
        this.selectionList = [];
        this.$refs.crud.toggleSelection();
      },
      currentChange(currentPage){
        this.page.currentPage = currentPage;
      },
      sizeChange(pageSize){
        this.page.pageSize = pageSize;
      },
      refreshChange() {
        this.onLoad(this.page, this.query);
      },
      onLoad(page, params = {}) {
        this.loading = true;
        getCarrierPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
          // const data = res.data.data;
          this.page.total = res.data.data.total;
          this.data = res.data.data.records;
          //这里默认数据处理？
          // this.data.forEach((i) => {
          //   if(this.validatenull(i.iconUrl)){
          //     i.iconUrl='http://10.147.17.103:30520/wisdom-park/upload/20221023/08fb9e8f4c838eeac96d90476f79a20f.jpg';
          //   }
          // });
          console.log(this.data); 
          this.loading = false;
          this.selectionClear();
        });
      }
    }
  };
</script>

<style>
</style>

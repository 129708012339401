<template>
  <basic-container>
    <avue-crud
      :data="tableDatas"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      :before-open="beforeOpen"
      v-model="form"
      @row-save="handleSave"
      @row-update="handleUpdate"
      @on-load="onLoad"
      @refresh-change="onLoad"
    >
      <!-- 搜索 -->
      <template slot="menuLeft">
        <el-input
          class="mar-l-5"
          v-model="keyWord"
          @keyup.native.enter="searchChange"
          clearable
          size="small"
          placeholder="请输入关键字搜索"
          type="text"
          style="width: 180px"
        >
          <el-button
            class="mar-0 pad-0"
            size="small"
            @click="searchChange"
            slot="append"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </template>
      <!-- handleDel -->
      <template slot-scope="scope" slot="menu">
        <el-button
          icon="el-icon-view"
          size="mini"
          v-show="scope.row.id != '10000000'"
          @click="handleDel(scope.row)"
          type="text"
          >删 除</el-button
        >
        <el-button
          icon="el-icon-view"
          size="mini"
          @click="viewHandle(scope)"
          type="text"
          >查 看</el-button
        >
      </template>
      <!-- <template slot-scope="scope" slot="appKeyForm">
        <div class='display-flex'>
          <el-input type='textarea' v-model='scope.row.appKey'></el-input>
          <div class='margin-left-20'>
            <el-button size='mini' type='primary'>更新key</el-button>
          </div>
        </div>
      </template> -->
    </avue-crud>
  </basic-container>
</template>
<script>
import {
  getDataAppList,
  saveApp,
  updateApp,
  delApp,
  detailApp,
} from "@/api/dataService/dataApp";
import { dateFormat } from "@/util/date";
export default {
  data() {
    return {
      form: {},
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      keyWord: "",
      tableLoading: false,
      tableDatas: [],
      tableOption: {
        viewBtn: false,
        delBtn: false,
        labelWidth: 120,
        column: [
          {
            label: "应用名称",
            prop: "appName",
            span: 12,
            rules: [
              {
                required: true,
                message: "请输入名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "管理员",
            prop: "adminUser",
            type: "select",
            remote: true,
            dicUrl: `/api/jida-user/user-list?keyword={{key}}`,
            span: 12,
            props: {
              label: "name",
              value: "id",
            },
            formatter(row, value, label, column) {
              if (row.adminUserName == "") {
                return "";
              } else {
                return row.adminUserName;
              }
            },
            rules: [
              {
                required: true,
                message: "请选择管理员",
                trigger: "change",
              },
            ],
          },
          {
            label: "Token",
            prop: "token",
            type: "textarea",
            minRows: 2,
            span: 24,
            hide: true,
            display: false,
          },
          {
            label: "描述",
            prop: "additionalInformation",
            type: "textarea",
            minRows: 2,
            span: 24,
          },
          {
            label: "key有效期",
            prop: "effective_date",
            hide: true,
            type: "datetimerange",
            format: "yyyy-MM-dd HH:mm:ss",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            startPlaceholder: "开始时间",
            endPlaceholder: "结束时间",
            formatter(row, value, label, column) {
              if (
                row.effectiveStartDate == "" ||
                row.effectiveStartDate == null
              ) {
                return null;
              } else {
                return [row.effectiveStartDate, row.effectiveEndDate];
              }
            },
            span: 24,
            rules: [
              {
                required: true,
                trigger: "change",
                message: "请选择key有效期",
              },
            ],
          },
          {
            label: "app key",
            prop: "appKey",
            hide: true,
            span: 24,
            editDisplay: true,
            addDisplay: false,
            type: "textarea",
            disabled: true,
            // formslot:true
          },
          {
            label: "创建人",
            prop: "createUserName",
            display: false,
          },
          {
            label: "创建时间",
            prop: "createTime",
            display: false,
            width: 130,
          },
        ],
      },
    };
  },
  methods: {
    // 查看详情
    viewHandle(row) {
      this.$router.push({
        path: "/dataService/dataApp/dataAppDetail",
        query: {
          id: row.row.id,
        },
      });
    },
    searchChange() {
      this.page.currentPage = 1;
      this.onLoad();
    },
    onLoad() {
      this.tableLoading = true;
      let obj = {
        current: this.page.currentPage,
        size: this.page.pageSize,
        keyWord: this.keyWord,
      };
      getDataAppList(obj)
        .then((res) => {
          this.tableLoading = false;
          this.tableDatas = res.data.data.records;
          this.page.total = res.data.data.total;
        })
        .catch((e) => {
          this.tableLoading = false;
        });
    },
    beforeOpen(done, type) {
      if (type != "add") {
        let params = {
          id: this.form.id,
        };
        detailApp(params).then((res) => {
          this.form = res.data.data;
          if (
            this.form.effectiveStartDate == "" ||
            this.form.effectiveStartDate == null
          ) {
            this.form.effective_date = null;
          } else {
            this.form.effective_date = [
              this.form.effectiveStartDate,
              this.form.effectiveEndDate,
            ];
          }
          if (
            this.form.effectiveStartDate == "" ||
            this.form.effectiveStartDate == null
          ) {
            this.form.effective_date = null;
          } else {
            this.form.effective_date = [
              this.form.effectiveStartDate,
              this.form.effectiveEndDate,
            ];
          }
          this.tableOption.column[2].display = type === "view";
          done();
        });
      } else {
        done();
      }
    },
    handleSave(row, done, loading) {
      let obj = {
        adminUser: row["adminUser"],
        additionalInformation: row["additionalInformation"],
        appName: row["appName"],
        createTime: dateFormat(new Date()),
        effectiveStartDate:
          row["effective_date"] == null ? "" : row["effective_date"][0],
        effectiveEndDate:
          row["effective_date"] == null ? "" : row["effective_date"][1],
      };
      saveApp(obj)
        .then((res) => {
          if (res.data.code == 200) {
            const h = this.$createElement;
            this.$message({
              message: h("p", null, [
                h("span", { style: "color: teal" }, "保存成功"),
                h(
                  "p",
                  { style: "color: teal" },
                  `生成的key为：${res.data.data.appKey}`
                ),
              ]),
              type: "success",
            });
          }
          done();
          loading();
          this.onLoad();
        })
        .catch((e) => {
          loading();
        });
    },
    handleUpdate(row, index, done, loading) {
      let obj = {
        id: row["id"],
        additionalInformation: row["additionalInformation"],
        appName: row["appName"],
        adminUser: row["adminUser"],
        effectiveStartDate:
          row["effective_date"] == null ? "" : row["effective_date"][0],
        effectiveEndDate:
          row["effective_date"] == null ? "" : row["effective_date"][1],
      };
      updateApp(obj).then((res) => {
        done();
        loading();
        this.onLoad();
      });
    },
    handleDel(row) {
      let _t = this;
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delApp({
          ids: row["id"],
        }).then((res) => {
          _t.onLoad();
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.margin-left-20 {
  margin-left: 20px;
}
</style>

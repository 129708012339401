<template>
  <div>
    <basic-container>
      <p>整体资产情况</p>
      <el-row type="flex" :gutter="$store.state.common.gutter">
        <el-col :span="16">
          <el-row type="flex" class="h-100p" :gutter="40" style="align-items:center;justify-content:center;">
            <el-col :span="8" class="text-center">
              128<br>
              <el-divider class="dis-ib w-100"></el-divider><br>
              CPU总核数
            </el-col>
            <el-col :span="8" class="text-center">
              96G<br>
              <el-divider class="dis-ib w-100"></el-divider><br>
              内存总量
            </el-col>
            <el-col :span="8" class="text-center">
              1000G<br>
              <el-divider class="dis-ib w-100"></el-divider><br>
              磁盘总量
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="8">
          <div>
            总CPU使用率
            <el-progress :stroke-width="16" :percentage="68"></el-progress>
          </div>
          <div class="mar-tb-15">
            总内存使用率
            <el-progress :stroke-width="16" :percentage="75"></el-progress>
          </div>
          <div>
            总磁盘使用率
            <el-progress :stroke-width="16" :percentage="84"></el-progress>
          </div>
          <!-- <div>
            总CPU使用率
            <el-progress :stroke-width="20" :percentage="78"></el-progress>
          </div>
          <div>
            总内存使用率
            <el-progress :stroke-width="20" :percentage="69"></el-progress>
          </div>
          <div>
            总磁盘使用率
            <el-progress :stroke-width="20" :percentage="84"></el-progress>
          </div> -->
        </el-col>
      </el-row>
    </basic-container>
    <basic-container class="mat-t-15">
      <el-row>
        <el-col :span="12">
          <div>
            选择节点
            <el-select size="small" v-model="currentNode" placeholder="请选择">
              <el-option
                v-for="item in nodes"
                :key="item.ip"
                :label="item.name"
                :value="item.ip">
              </el-option>
            </el-select>
          </div>
        </el-col>
        <el-col :span="12">
          <div class="text-right">
            选择时间
            <el-select size="small" v-model="currentTime" @change="init()" placeholder="请选择">
              <el-option
                v-for="item in times"
                :key="item.value"
                :label="item.name"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
        </el-col>
      </el-row>
      <el-row class="mar-t-15" :gutter="$store.state.common.gutter">
        <el-col class="h-300" :span="8">
          <basic-echarts :option="chartOption1"></basic-echarts>
        </el-col>
        <el-col class="h-300" :span="8">
          <basic-echarts :option="chartOption2"></basic-echarts>
        </el-col>
        <el-col class="h-300" :span="8">
          <basic-echarts :option="chartOption3"></basic-echarts>
        </el-col>
      </el-row>
      <el-row class="mar-t-15" :gutter="$store.state.common.gutter">
        <el-col class="h-300" :span="8">
          <basic-echarts :option="chartOption4"></basic-echarts>
        </el-col>
        <el-col class="h-300" :span="8">
          <basic-echarts :option="chartOption5"></basic-echarts>
        </el-col>
        <el-col class="h-300" :span="8">
          <basic-echarts :option="chartOption6"></basic-echarts>
        </el-col>
      </el-row>
    </basic-container>
  </div>
</template>
<script>

import BreadcrumbComponent from './BreadcrumbComponent';

export default {
  data(){
    return {
      currentNode: '10.10.100.120',
      nodes: [
        {name: '10.10.100.120' , ip: '10.10.100.120'}
      ],
      currentTime: 5,
      times: [
        {name: '1分钟' , value: 1},
        {name: '5分钟' , value: 5},
        {name: '10分钟' , value: 10}
      ],
      chartOption1: null,
      chartOption2: null,
      chartOption3: null,
      chartOption4: null,
      chartOption5: null,
      chartOption6: null,
    }
  },
  mounted(){
    this.init();
    this.$root.$emit('SetBreadcrumbComponent' , {component: BreadcrumbComponent});
  },
  destroyed(){
    this.$root.$emit('SetBreadcrumbComponent' , null);
  },
  methods: {
    init(){
      this.chartOption1 = this.getOption({
      ...this.createData('CPU使用率' , 100 , [] , 0),
      yAxis: {
        axisLabel: {
          formatter: '{value}%'
        }
      }
    })
    this.chartOption2 = this.getOption({
      ...this.createData('节点负载' , 1 , ['' , '' , ''] , 2)
    })
    this.chartOption3 = this.getOption({
      ...this.createData('内存使用率' , 100 , [] , 0),
      yAxis: {
        axisLabel: {
          formatter: '{value}%'
        }
      }
    })
    this.chartOption4 = this.getOption({
      ...this.createData('磁盘使用率' , 100 , [] , 0),
      yAxis: {
        axisLabel: {
          formatter: '{value}%'
        }
      }
    })
    this.chartOption5 = this.getOption({
      ...this.createData('磁盘I/O' , 100 , ['输入' , '输出'] , 0),
      yAxis: {
        axisLabel: {
          formatter: '{value} kbps'
        }
      }
    })
    this.chartOption6 = this.getOption({
      ...this.createData('网络数据包' , 60 , ['上行' , '下行'] , 0),
      yAxis: {
        axisLabel: {
          formatter: '{value} Pps'
        }
      }
    })
    },
    createData(title , max , legends , toFixed){
      let xAxis = [];
      let series = [];
      let legendData = [];
      let len = Math.floor(Math.random() * 50) + 5;
      if(legends.length === 0){
        legends.push('');
      }
      legends.forEach(
          item => {
            series.push({
              name: item,
              type: 'line',
              step: 'start',
              data: []
            })
            if(item){
              legendData.push(item);
            }
          }
        )
      for(let i = 0 ; i < len; i++){
        let d = new Date(Date.now() - (this.currentTime * i * 1000 * 60));
        let dStr = `${d.getHours()}:${d.getMinutes()} ${d.getMonth() + 1}-${d.getDate()}`;
        // xAxis.push(d.getTime());
        legends.forEach(
          (item , index) => {
            series[index].data.push([d.getTime() , (Math.random() * max).toFixed(toFixed)])
          }
        )
      }
      return {
        title: title,
        series: series,
        xAxis: xAxis,
        legends: legendData
      }
    },
    getOption({title , xAxis , series , yAxis , legends}){
      return {
        title: {
          text: title
        },
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          data: legends
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        toolbox: {
        },
        xAxis: {
          type: 'time',
          data: xAxis
        },
        yAxis: {
          type: 'value',
          ...yAxis
        },
        series: series
      };
    }
  }
}
</script>
<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" :page.sync="page" v-model="form" ref="crud" @search-change="searchChange" @search-reset="searchReset"
                   @current-change="currentChange" @size-change="sizeChange" @refresh-change="refreshChange" @on-load="onLoad">
            <template slot-scope="scope" slot="menu" v-if="scope.row.status == 2">
                <el-button type="text" size="small" icon="el-icon-reading" class="none-border" @click="gotoComment(scope.row)">配送
                </el-button>
            </template>
        </avue-crud>
        <el-dialog title="一键配送" :visible.sync="dialogVisible" v-if="dialogVisible" width="60%" append-to-body destroy-on-close>
            <avue-form :option="formSettleOption" @submit="settleSubmit" v-model="formSettle"></avue-form>
        </el-dialog>
    </basic-container>
</template>

<script>
import { getList, getPage, getDetail } from '@/api/epmall/orderitem';
import { mapGetters } from 'vuex';
import { getMetaCommonList, delMetaCommon, saveMetaCommon, updateMetaCommon } from '@/api/busiMode/metaCommon';

export default {
    data() {
        const validateMobile = (rule, value, callback) => {
            if (!/^[1]([3-9])[0-9]{9}$/.test(value)) {
                callback(new Error('请输入正确的手机号格式'));
            } else {
                callback();
            }
        };
        return {
            form: {},
            query: { status: 2 },
            loading: true,
            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            selectionList: [],

            formSettle: {},
            currentObj: {},
            formSettleOption: {
                column: [
                    {
                        label: '送餐员',
                        prop: 'deliver',
                        search: true,
                        searchSpan: 6,
                        rules: [
                            {
                                required: true,
                                message: '请输入送餐员,最长20位',
                                trigger: 'blur',
                            },
                        ],
                    },

                    {
                        label: '联系电话',
                        prop: 'phone',
                        search: true,
                        searchSpan: 6,
                        minWidth: 120,
                        rules: [
                            {
                                required: true,
                                message: '请输入配送人员联系电话',
                                trigger: 'blur',
                            },
                            {
                                trigger: 'blur',
                                validator: validateMobile,
                            },
                        ],
                    },
                    {
                        label: '配送时间',
                        prop: 'dispense_time',
                        search: true,
                        searchSpan: 6,
                        type: 'datetime',
                        format: 'yyyy-MM-dd HH:mm:ss',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        rules: [
                            {
                                required: true,
                                message: '请选择配送时间',
                                trigger: 'blur',
                            },
                        ],
                    },

                    {
                        label: '备注',
                        prop: 'remarks',
                        type: 'textarea',
                        rules: [
                            {
                                required: false,
                                message: '请输入备注',
                                trigger: 'blur',
                            },
                        ],
                        hide: true,
                        span: 24,
                        row: true,
                    },
                ],
            },
            option: {
                addBtn: false,
                editBtn: false, // 行内编辑按钮
                viewBtn: false,
                delBtn: false,
                searchShow: true,
                searchMenuSpan: 6,
                menuWidth: 100,
                column: [
                    {
                        label: '订单号',
                        prop: 'orderSn',
                        rules: [
                            {
                                required: true,
                                message: '请输入订单id',
                                trigger: 'blur',
                            },
                        ],
                        width: 200,
                    },
                    {
                        label: '店铺',
                        prop: 'storeName',
                        span: 24,
                        rules: [
                            {
                                required: false,
                                message: '请输入店铺',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '菜品',
                        prop: 'title',
                        search: true,
                        span: 24,
                        rules: [
                            {
                                required: true,
                                message: '请输入菜品',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '订单日',
                        prop: 'orderDay',
                        hide: true,
                        addDisplay: false,
                        editDisplay: false,
                        search: true,
                        searchSpan: 5,
                        type: 'datetime',
                        format: 'yyyy-MM-dd',
                        valueFormat: 'yyyy-MM-dd',
                        rules: [
                            {
                                required: true,
                                message: '请输入订单id',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '数量',
                        prop: 'num',
                        rules: [
                            {
                                required: true,
                                message: '请输入菜品购买数量',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '单价',
                        prop: 'price',
                        rules: [
                            {
                                required: true,
                                message: '请输入菜品单价',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '总金额',
                        prop: 'totalFee',
                        rules: [
                            {
                                required: true,
                                message: '请输入菜品总金额',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '订单时间',
                        prop: 'orderTime',
                        rules: [
                            {
                                required: true,
                                message: '请输入菜品总金额',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '状态',
                        prop: 'status',
                        searchSpan: 5,
                        search: false,
                        addDisplay: false,
                        editDisplay: false,
                        viewDisplay: false,
                        hide: false,
                        type: 'select',
                        dicData: [
                            {
                                label: '未支付',
                                value: 0,
                            },
                            {
                                label: '已支付',
                                value: 1,
                            },
                            {
                                label: '未发货',
                                value: 2,
                            },
                            {
                                label: '已发货',
                                value: 3,
                            },
                            {
                                label: '交易成功',
                                value: 4,
                            },
                            {
                                label: '交易关闭',
                                value: 5,
                            },
                            {
                                label: '交易失败',
                                value: 6,
                            },
                            {
                                label: '交易取消',
                                value: 7,
                            },

                            {
                                label: '完成备餐',
                                value: 8,
                            },
                        ],
                        rules: [
                            {
                                required: true,
                                message: '请输入状态,1=正常,0=关闭',
                                trigger: 'blur',
                            },
                        ],
                    },
                ],
            },
            form: {},
            data: [],
            dialogVisible: false,
            model: 'wisdom_park.wp_order_dispense',
        };
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.epmallorderitem_add, false),
                viewBtn: this.vaildData(this.permission.epmallorderitem_view, false),
                delBtn: this.vaildData(this.permission.epmallorderitem_delete, false),
                editBtn: this.vaildData(this.permission.epmallorderitem_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    methods: {
        settleSubmit() {
            let obj = {
                deliver: this.formSettle.deliver,
                phone: this.formSettle.phone,
                type: 1,
                status: 1,
                dispense_time: this.formSettle.dispense_time,
                remarks: this.formSettle.remarks,
                order_id: this.currentObj.id,
            };
            console.log(this.currentObj);
            saveMetaCommon(this.model, { values: obj }).then(
                () => {
                    this.onLoad(this.page);
                    let obj = { status: 4 };
                    updateMetaCommon('wisdom_park.wp_epmall_order', {
                        update: obj,
                        primaryKeyValue: this.currentObj.orderId,
                        primaryKey: 'id',
                    }).then(res => {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        this.onLoad(this.page);
                        this.dialogVisible = false;
                    });
                },
                error => {
                    loading();
                    window.console.log(error);
                },
            );
        },
        gotoComment(row) {
            this.dialogVisible = true;
            this.currentObj = row;
        },

        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },
        searchChange(params, done) {
            this.query = params;
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
            done();
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        currentChange(currentPage) {
            this.page.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        onLoad(page, params = {}) {
            this.loading = true;
            getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                this.loading = false;
                this.selectionClear();
            });
        },
    },
};
</script>

<style>
</style>

<template>
  <el-row class="form-designer-page mar-0">
    <el-col :span="22" class="w-calc-120">
      <widget-form
        ref="widgetForm"
        :data="widgetForm"
        :searchSpan="4"
        :select.sync="widgetFormSelect"
        @change="handleSearchFormChange(widgetForm)"
      ></widget-form>
    </el-col>
    <el-col :span="2" class="text-right fr w-120">
      <el-dropdown style="display: block" :hide-on-click="false">
        <el-button
          class="mar-l-10 designable-btn-dash"
          size="small"
          split-button
          icon="el-icon-setting"
          type="warning"
          plain
          >搜索操作</el-button
        >
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>启用查询</el-dropdown-item>

          <el-transfer
            filterable
            :filter-method="filterMethod"
            filter-placeholder="请输入搜索字段"
            v-model="value"
            :right-default-checked="widgetForm.column"
            class="my-el-transfer"
            :titles="['隐藏', '显示']"
            :props="{
              key: 'prop',
              label: 'label',
            }"
            :data="searchColums"
            @change="change"
          >
          </el-transfer>
          <!-- <template v-for="(search, index) in searchColums">
            <el-dropdown-item :key="index" v-if="search.label != '配置字段'"
              >{{ search.label }}
              <el-switch
                v-model="search.show"
                @change="changSearchShowFiled(search, index)"
                class="mar-l-10"
              ></el-switch
            ></el-dropdown-item>
          </template> -->
        </el-dropdown-menu>
      </el-dropdown>
    </el-col>
  </el-row>
</template>
<script>
import WidgetForm from "../../../../../components/dynamic/form/WidgetForm";
export default {
  name: "tableDesign",
  components: { WidgetForm },
  watch: {
    widgetForm: {
      handler(newValue, oldName) {
        this.formart();
      },
    },
    // searchColums: {
    //   handler(newValue, oldName) {
    //     //this.formart();
    //   },
    // },
  },
  props: {
    widgetForm: {
      type: Object,
      default: function () {
        return {};
      },
    },
    searchColums: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      filterMethod(query, item) {
        return item.label.indexOf(query) > -1;
      },
      value: [],
      widgetFormSelect: {},
    };
  },

  created() {},
  mounted() {},

  methods: {
    handleSearchFormChange(widgetForm) {
      this.$emit("handleSearchFormChange", widgetForm);
    },
    formart() {
      this.searchColums.forEach((element, index) => {
        element.show = false;
        element.span = element.span ? element.span : 4;
        element.key = element.prop;
        this.widgetForm.column.forEach((e) => {
          if (element.prop == e.prop) {
            this.value.push(element.prop);
            element.show = true;
            e.display = true;
            e.label = element.label;
            e.key = e.prop;
          }
        });
      });
    },
    changSearchShowFiled(col, index) {
      if (col.show) {
        this.widgetForm.column.push(col);
      } else {
        this.widgetForm.column.splice(index, 1);
      }
    },
    change() {
      let arr = [];
      this.value.forEach((value) => {
        this.searchColums.forEach((e) => {
          if (e.prop == value) {
            let obj = e;
            obj.show = true;
            obj.display = true;
            obj.span = 4;
            arr.push(obj);
          }
        });
      });
      this.widgetForm.column = arr;
    },
  },
};
</script>
<style lang="scss">
@import "../../../../../components/dynamic/form/styles/index.scss";
.widget-container-header .el-button--medium {
  padding: 0px !important;
}
.form-designer-page .widget-form-list .el-form-item.widget-form-item {
  border: rgba(0, 0, 0, 0) solid 1px;
  &.itemActive {
    border-style: dashed;
    border: 1px dashed #f5896c;
  }
}
.my-el-transfer .el-transfer__buttons {
  display: inline-block;
  vertical-align: middle;
  padding: 0 30px;
  margin-top: 130px;
  button {
    padding: 9px 15px;
    font-size: 12px;
    border-radius: 3px;
  }
}
</style>

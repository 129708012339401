<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" :page.sync="page" :search.sync="search" v-model="form" ref="crud" @search-change="searchChange" @row-save="rowSave"
                   @search-reset="searchReset" @current-change="currentChange" @size-change="sizeChange" @refresh-change="refreshChange" @on-load="onLoad">
            <template slot-scope="scope" slot="menu">
                <el-button type="text" size="small" icon="view" class="none-border" @click="openDislog(scope.row)">查看
                </el-button>
                <el-button v-if="scope.row.refundStatus!=2" type="text" size="small" icon="el-icon-back" class="none-border" @click="retryRefund(scope.row)">重新发起退款
                </el-button>
                <el-button v-if="scope.row.refundStatus!=2" type="text" size="small" icon="el-icon-edit" class="none-border" @click="confirmRefund(scope.row)">手动确认退款
                </el-button>
            </template>
        </avue-crud>
        <el-dialog :visible.sync="detailDia" width="60%" destroy-on-close append-to-body>
            <div slot="title" class="header-title">
                <span class="fts-16">查看</span>
            </div>
            <el-row>
                <el-col :span="24">
                    <el-descriptions border title="退款详情" :column="2" :colon="false" :labelStyle="labelStyle">
                        <el-descriptions-item label="订单号">
                            {{ formDia.orderSn }}
                        </el-descriptions-item>
                        <el-descriptions-item label="支付方式">
                            <span v-if="formDia.payType=='accbal'">会员余额支付</span>
                            <span v-if="formDia.payType=='weixinpay'">微信支付</span>
                            <span v-if="formDia.payType=='wx'">微信支付</span>
                            <span v-if="formDia.payType=='alipay'">支付宝支付</span>
                            <span v-if="formDia.payType=='unionpay'">银联支付</span>
                            <span v-if="formDia.payType=='dragonpay'">龙支付</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="退款金额
">
                            {{ formDia.amount }}
                        </el-descriptions-item>

                        <el-descriptions-item label="退款方式">
                            <span v-if="formDia.refundType==1">系统自动退款</span>
                            <span v-if="formDia.refundType==2">人工确认退款</span>
                        </el-descriptions-item>

                        <el-descriptions-item label="权限回收">
                            <span v-if="formDia.grantRevoke==1">回收成功</span>
                            <span v-if="formDia.grantRevoke==2">回收失败</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="退款状态">
                            <span v-if="formDia.refundStatus==1">退款中</span>
                            <span v-if="formDia.refundStatus==2">已退款</span>
                            <span v-if="formDia.refundStatus==3">退款失败</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="退款信息">
                            {{ formDia.refundMsg }}
                        </el-descriptions-item>

                        <el-descriptions-item label="退款时间">
                            {{ formDia.createTime }}
                        </el-descriptions-item>

                        <el-descriptions-item label="备注">
                            {{ formDia.spcodOrderRefund }}
                        </el-descriptions-item>
                        <el-descriptions-item label="相关凭证" :column="4">
                            <orderFormFile v-model="formDia.attachmentIds"></orderFormFile>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-col>

            </el-row>
        </el-dialog>
    </basic-container>
</template>

<script>
import { getRefundOrderList, confirmRefund, retryRefund } from '@/api/spcod/sporttype';
import { getPicListByIds } from '@/api/busiMode/metaCommon';
import orderFormFile from './orderFormFile';
export default {
    components: { orderFormFile },

    data() {
        return {
            form: {},
            query: { bizType: 'COD' },
            loading: true,
            search: {},
            currentRow: {},
            detailDia: false,
            formDia: {},
            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            labelStyle: {
                color: '#999',
                fontSize: '12px',
            },
            selectionList: [],
            model: 'wisdom_park.v_wp_spcod_order_managemen_cod_stat',
            option: {
                height: 'auto',
                calcHeight: 30,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: true,
                viewBtn: false,
                editBtn: false,
                delBtn: false,
                addBtn: false,
                dialogClickModal: false,
                menu: true,
                column: [
                    {
                        label: '订单号',
                        prop: 'orderSn',
                        search: true,
                        addDisplay: false,
                    },
                    {
                        label: '支付方式',
                        prop: 'payType',
                        type: 'select',
                        addDisplay: false,
                        dicData: [
                            {
                                label: '会员余额支付',
                                value: 'accbal',
                            },
                            {
                                label: '微信支付',
                                value: 'weixinpay',
                            },
                            {
                                label: '微信支付',
                                value: 'wx',
                            },
                            {
                                label: '支付宝支付',
                                value: 'alipay',
                            },
                            {
                                label: '银联支付',
                                value: 'unionpay',
                            },
                            {
                                label: '龙支付',
                                value: 'dragonpay',
                            },
                        ],
                    },
                    {
                        label: '退款金额',
                        prop: 'amount',
                        addDisplay: false,
                    },
                    {
                        label: '退款方式',
                        prop: 'refundType',
                        search: true,
                        type: 'select',
                        addDisplay: false,
                        dicData: [
                            {
                                label: '系统自动退款',
                                value: 1,
                            },
                            {
                                label: '人工确认退款',
                                value: 2,
                            },
                        ],
                    },
                    {
                        label: '退款状态',
                        prop: 'refundStatus',
                        search: true,
                        type: 'select',
                        addDisplay: false,
                        dicData: [
                            {
                                label: '退款中',
                                value: 1,
                            },
                            {
                                label: '已退款',
                                value: 2,
                            },
                            {
                                label: '退款失败',
                                value: 3,
                            },
                        ],
                    },

                    {
                        label: '权限回收',
                        prop: 'grantRevoke',
                        type: 'select',
                        addDisplay: false,
                        dicData: [
                            {
                                label: '回收成功',
                                value: 1,
                            },
                            {
                                label: '回收失败',
                                value: 2,
                            },
                        ],
                    },

                    {
                        label: '退款信息',
                        prop: 'refundMsg',
                        width: 200,
                        addDisplay: false,
                    },
                    {
                        label: '退款时间',
                        width: 200,
                        prop: 'createTime',
                        addDisplay: false,
                    },
                    {
                        label: '上传相关凭证',
                        prop: 'attachmentIds',
                        hide: true,
                        showColumn: false,
                        type: 'upload',
                        span: 24,
                        fileType: 'doc/docx',
                        loadText: '上传中，请稍等',
                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '请上传 .doc,.docx 标准格式文件',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },

                    {
                        label: '备注',
                        prop: 'spcodOrderRefund',
                        type: 'textarea',
                        hide: true,
                    },
                ],
            },
            data: [],
        };
    },
    methods: {
        confirmRefund(row) {
            this.currentRow = row;
            this.$refs.crud.rowAdd();
        },
        retryRefund(row) {
            this.$confirm('确定重新操作?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                retryRefund(row.id).then(res => {
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    this.onLoad(this.page);
                });
            });
        },
        rowSave(row, done, loading) {
            let obj = {
                id: this.currentRow.id,
            };
            if (row.attachmentIds) {
                let attachmentIds = [];
                row.attachmentIds.forEach(element => {
                    attachmentIds.push(element.label);
                });
                obj.attachmentIds = attachmentIds.toString();
            }
            if (row.spcodOrderRefund) {
                obj.spcodOrderRefund = row.spcodOrderRefund;
            }
            confirmRefund(obj).then(res => {
                if (res.data.data.code == 200) {
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                }
                done(row);
                this.onLoad(this.page);
            });
            console.log(row);
        },
        openDislog(row) {
            this.detailDia = true;
            this.formDia = row;
            // getrefundDetail(row.id).then(res => {
            //     this.formDia = res.data.data;
            //     if (this.formDia.attachmentIds) {
            //         this.getPic(this.formDia.attachmentIds);
            //     }
            // });
        },
        getPic(val) {
            getPicListByIds(val).then(res => {
                if (res.data.code == 200) {
                    console.log(res.data.data);
                    let arr = [];
                    res.data.data.forEach(pic => {
                        arr.push({
                            label: pic.id,
                            value: pic.link,
                        });
                    });
                    this.formDia.attachmentIds = arr;
                }
            });
        },
        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },
        searchChange(params, done) {
            this.query = params;
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
            done();
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        currentChange(currentPage) {
            this.page.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        onLoad(page, params = {}) {
            this.loading = true;
            params.bizType = 'COD';
            getRefundOrderList(this.page.currentPage, this.page.pageSize, Object.assign(params, this.query)).then(
                res => {
                    const data = res.data.data;
                    this.page.total = data.total;
                    this.data = data.records;
                    this.loading = false;
                    this.selectionClear();
                },
            );
        },
    },
};
</script>

<style></style>

class WidgetComponentManeger {

  widgets = {};

  constructor(){

  }

  register(widgets){
    if(typeof widgets === 'object'){
      if(!Array.isArray(widgets)){
        widgets = [widgets]
      }
      widgets.forEach(
        item => {
          if(item.name){
            this.widgets[item.name] = item;
          }
        }
      )
    }
  }

  getWidgets(){
    return this.widgets;
  }

  getWidget(name){
    return this.widgets[name] || null;
  }

}

class WidgetTypeManager {

  widgetTypes = {};

  constructor(){

  }

  register(widgetTypes){
    if(typeof widgetTypes === 'object'){
      if(!Array.isArray(widgetTypes)){
        widgetTypes = [widgetTypes]
      }
      widgetTypes.forEach(
        item => {
          if(!this.widgetTypes[item.code]){
            this.widgetTypes[item.code] = item;
          }
        }
      )
    }
  }

  getWidgetTypes(){
    return Object.values(this.widgetTypes);
  }

}

class DataSourceManager {

  datasources = {};

  constructor(){

  }

  register(datasources){
    if(typeof datasources === 'object'){
      if(!Array.isArray(datasources)){
        datasources = [datasources]
      }
      datasources.forEach(
        item => {
          this.datasources[item.id] = item;
          // this.datasources.push(item);
        }
      )
    }
  }

  getDatasources(){
    return Object.values(this.datasources);
  }

}

class RequestServiceManager {

  services = {};

  constructor(){

  }

  register(services){
    if(typeof services === 'object'){
      if(!Array.isArray(services)){
        services = [services]
      }
      services.forEach(
        item => {
          this.services[item.type] = item;
        }
      )
    }
  }

  getServices(){
    return this.services;
  }

  getData(type , widget , dash){
    return new Promise((a , b) => {
      if(this.services[type]){
        this.services[type].getData(widget , dash).then(
          res => {
            a(res);
          }
        )
      }else{
        b()
      }
    })
  }

}

class DashManager {
  dash = null;

  set(val){
    this.dash = val;
  }

  get(){
    return this.dash;
  }

  getVariables(){

  }
}

class Connector {

  Widgets = new WidgetComponentManeger;
  WidgetType = new WidgetTypeManager;
  Datasource = new DataSourceManager;
  RequestService = new RequestServiceManager;
  Dash = new DashManager;

  constructor(option){

  }

}

const ConnectorCache = {
  default: new Connector()
}

export const DataViewConnector = {
  init(namespaces , option){
    namespaces = namespaces || 'default';
    ConnectorCache[namespaces] = new Connector(option);
    console.log(DataViewConnector)
    this.Widgets = ConnectorCache[namespaces]['Widgets'];
    this.WidgetType = ConnectorCache[namespaces]['WidgetType'];
    this.Datasource = ConnectorCache[namespaces]['Datasource'];
    this.RequestService = ConnectorCache[namespaces]['RequestService'];
    this.Dash = ConnectorCache[namespaces]['Dash'];
    console.log(DataViewConnector , 123)
    return ConnectorCache[namespaces];
  },
  execute(namespaces , callback){
    if(callback && typeof callback === 'function'){
      callback(ConnectorCache[namespaces]);
    }
  },
  clear(){
    this.RequestService = null;
    this.Datasource = null;
    this.WidgetType = null;
    this.Widgets = null;
    this.Dash = null;
  }

}
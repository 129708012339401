<template>
  <div style="background: #fff" :style="style" class="widget-container h-100p w-100p">
    <div :style="getStyleByObject(widget.public.title.style)" v-if="widget.public.title.show" :class="{'widget-title-immersive': widget.public.title.immersive}" class="widget-title"><span>{{transformVar(widget.public.title.text)}}</span></div>
    <div :style="getContentStyle()">
      <component :is="component" :key="componentKey" :data="data" :widget="widget"></component>
    </div>
  </div>
</template>
<script>

import { getStyleByObject , transformVar } from '../util';

import { DataViewConnector } from '../index.js';


const debounce = (() => {
  let timer = null
  return (callback, wait) => {
    clearTimeout(timer)
    timer = setTimeout(callback, wait)
  }
})();

export default {
  inject: [ 'customWidgets' ],
  name: 'DataViewWidgetContainer',
  data(){
    return {
      style: null,
      component: null,
      componentKey: null,
      data: null,
      titleStyle: null,
      widgetTypes: DataViewConnector.WidgetType.getWidgetTypes(),
    }
  },
  watch: {
    // 'widget.public.dataConfig.datasource': {
    //   deep: true,
    //   immediate: true,
    //   handler(){
    //     this.setData()
    //   }
    // },
    'widget.w': {
      handler(){
        this.setComponentKey();
      }
    },
    'widget.h': {
      handler(){
        this.setComponentKey();
      }
    },
    'widget.public.style': {
      deep: true,
      immediate: true,
      handler(){
        this.style = getStyleByObject(this.widget.public.style);
      }
    },
    'widget.selector': {
      handler(){
        console.log(this.widget.selector);
        this.setComponent();
      }
    },
    'widget.public.title.show': {
      handler(){
        this.setComponentKey();
      }
    }
  },
  props: {
    widget: {
      type: Object,
      default(){
        return null;
      }
    },
    dash: {
      type: Object,
      default(){
        return null;
      }
    }
  },
  mounted(){
    this.init();
  },
  created(){
    window.addEventListener('resize' , this.windowResize);
  },
  destroyed(){
    window.removeEventListener('resize' , this.windowResize)
  },
  methods: {
    transformVar(text){
      return transformVar(text , this.dash , this.widget , this.data)
    },
    init(){
      if(this.widget){
        this.setComponent();
        this.setData();
      }
    },
    setData(){
      return new Promise((resolve , reject) => {
        if(this.widget && this.widget.public.dataConfig.datasource.type){
          return DataViewConnector.RequestService.getData(this.widget.public.dataConfig.datasource.type , this.widget , this.dash).then(
            res => {
              this.data = res.data || null;
              resolve(res);
              return res;
            }
          );
        }else{
          reject();
        }
      })
    },
    windowResize(ev){
      this.setComponentKey();
    },
    setComponentKey(){
      debounce(() => {
        if(this.component && this.widget){
          this.componentKey = this.widget.selector + Date.now();
        }else{
          this.componentKey = null;
        }
      } , 500);
    },
    setComponent(){
      if(this.widget && this.widget.selector){
        this.widgetTypes.forEach(
          item => {
            if(item.code === this.widget.selector){
              this.component = DataViewConnector.Widgets.getWidget(item.component);
            }
          }
        )
      }else{
        this.component = null;
      }
      this.setComponentKey();
    },
    getStyleByObject(obj){
      return getStyleByObject(obj);
    },
    loadData(){
      this.setData();
      return new Promise((a) => {
        this.setData().then(
          res => {
            a(res);
          }
        );
      })
    },
    getContentStyle(){
      let styles = {};
      styles['height'] = this.widget.public.title.show && !this.widget.public.title.immersive ? 'calc(100% - 40px)' : '100%';
      styles['overflow'] = this.widget.public.moreConfig ? (this.widget.public.moreConfig.overflow || 'hidden') : 'hidden';
      return styles;
    }
  }
}
</script>
<style lang="scss" scoped>
.widget-container{
  position: relative;
}
.widget-title{
  height: 40px;
  line-height: 40px;
}
.widget-title-immersive{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
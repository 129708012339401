<template>
  <div>
    <el-divider content-position="left">入驻信息</el-divider>
    <el-row>
      <el-col class="mar-t-10" :span="24">
        <el-table :data="selectRoomList" size="small" border>
          <el-table-column type="index" width="50">
            <template slot="header">
              <el-button
                size="mini"
                @click="
                  selectRoomList.push({
                    building: '',
                    buildingName: '',
                    floor: '',
                    floorName: '',
                    room: [],
                    roomSelected: [],
                    floorData: [],
                    roomData: [],
                  })
                "
                type="primary"
                icon="el-icon-plus"
                circle
              ></el-button>
            </template>
            <template slot-scope="scope">
              <el-button
                size="mini"
                @click="
                  selectRoomList.splice(scope.$index, 1);
                  calculateRoom();
                "
                type="danger"
                icon="el-icon-minus"
                circle
              ></el-button>
            </template>
          </el-table-column>
          <el-table-column label="楼宇" width="150px">
            <template slot-scope="scope">
              <el-select
                class="dis-b"
                size="small"
                v-model="scope.row.building"
                @change="
                  roomSelectChange(scope.row, 'building');
                  getFloor(scope.$index);
                "
              >
                <el-option
                  v-for="item of dictData.building"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="楼层" width="150px">
            <template slot-scope="scope">
              <el-select
                class="dis-b"
                size="small"
                v-model="scope.row.floor"
                @change="
                  roomSelectChange(scope.row, 'floor');
                  getRoom(scope.$index);
                "
              >
                <el-option
                  v-for="item of scope.row.floorData"
                  :key="item.id"
                  :value="item.id"
                  :label="item.name"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="房间">
            <template slot-scope="scope">
              <el-checkbox-group
                v-model="scope.row.room"
                @change="roomSelectChange(scope.row, 'room')"
              >
                <el-checkbox
                  v-for="item of scope.row.roomData"
                  :label="item.id"
                  :key="item.id"
                  >{{ item.name }}</el-checkbox
                >
              </el-checkbox-group>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import {
  getMetaCommonDetail,
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  getMetaCommonTree,
  delMetaCommon,
  getMetaCommonInfo,
  getMetaCommonListByDict,
  getPicListByIds,
} from "@/api/busiMode/metaCommon";
export default {
  props: {
    ruleModel: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selectRoomList: [],
      dictData: {
        contract_attribute: [],
        offer_type: [],
        payment_type: [],
        building: [],
        staff: [],
        enterprise: [],
      },
    };
  },
  mounted() {
    this.getBuildList();
  },
  computed: {},
  methods: {
    getMetaCommonList(code, prop, params) {
      return getMetaCommonList(`wisdom_park.${code}`, {
        current: 1,
        size: 500,
        ...params,
      }).then((res) => {
        if (res.data && res.data.data && prop) {
          this.dictData[prop] = res.data.data.records;
        }
        return res;
      });
    },
    getBuildList() {
      getMetaCommonList(`wisdom_park.wp_building`, {
        current: 1,
        size: 500,
      }).then((res) => {
        if (res.data && res.data.data) {
          this.dictData["building"] = res.data.data.records;
        }
      });
    },
    getFloor(index) {
      let building = this.selectRoomList[index].building;
      this.selectRoomList[index].floor = "";
      this.selectRoomList[index].floorData = [];
      if (building) {
        this.getMetaCommonList("wp_floor", null, {
          wheres: [{ field: "building_id", value: building }],
          orders: [
            { field: "above_under", sort: "desc" },
            { field: "code", sort: "asc" },
          ],
        }).then((res) => {
          if (res.data && res.data.data) {
            let floorData = res.data.data.records.sort((curr, next) => {
              if (curr["above_under"] === "under") {
                return parseInt(next.code) - parseInt(curr.code);
              } else {
                return 0;
              }
            });
            floorData = floorData.sort((curr, next) => {
              if (curr["above_under"] === "above") {
                return parseInt(curr.code) - parseInt(next.code);
              } else {
                return 0;
              }
            });
            this.selectRoomList[index].floorData = floorData.map((item) => {
              return {
                ...item,
                name: `${item["above_under"] === "under" ? "负" : ""}${parseInt(
                  item.code
                )}层`,
              };
            });
          }
        });
      }
    },
    getRoom(index) {
      let building = this.selectRoomList[index].building,
        floor = this.selectRoomList[index].floor;
      this.selectRoomList[index].roomData = [];
      this.selectRoomList[index].room = [];
      if (building && floor) {
        this.getMetaCommonList("wp_room", null, {
          wheres: [
            { field: "building_id", value: building },
            { field: "floor_id", value: floor },
          ],
        }).then((res) => {
          if (res.data && res.data.data) {
            this.selectRoomList[index].roomData = res.data.data.records;
          }
        });
      }
    },
    roomSelectChange(row, name) {
      let data = [];
      if (name === "building") {
        data = this.dictData.building;
      } else {
        data = row[`${name}Data`] || [];
      }
      if (data) {
        if (name !== "room") {
          data.forEach((item) => {
            if (item.id === row[name]) {
              row[`${name}Name`] = item.name;
            }
          });
        } else {
          this.calculateRoom();
        }
      }
    },
    calculateRoom() {
      let roomSelected = [];
      this.selectRoomList.forEach((itemRoom) => {
        itemRoom.room.forEach((itemRoomId) => {
          let currentRoom = itemRoom.roomData.find(
            (item) => item.id === itemRoomId
          );
          let currentBuilding = this.dictData.building.find(
            (item) => item.id === itemRoom.building
          );
          let oldRoomItem =
            this.roomSelected.find((item) => item.room_id === itemRoomId) || {};
          if (currentRoom && currentBuilding) {
            let roomObj = {
              building_id: itemRoom.building,
              building_name: itemRoom.buildingName,
              floor_id: itemRoom.floor,
              floor_name: itemRoom.floorName,
              room_id: itemRoomId,
            };
            roomSelected.push(roomObj);
          }
        });
      });
      this.roomSelected = roomSelected;
      this.calculateAmount();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
  margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
  left: 0px;
}
/deep/ .el-divider__text {
  padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
  width: 68px;
  height: 68px;
  line-height: 72px;
  vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
}
</style>

<template>
  <basic-container>
    <!-- <el-page-header class="mar-b-15" @back="$router.back()" content="详情页面"></el-page-header> -->
    <div>
      <el-button @click="$router.back()" icon="el-icon-back" type="text" size="small">返回</el-button>
    </div>
    <meta-info :guid="guid"></meta-info>
  </basic-container>
</template>
<script>
import metaInfo from '../metainfo';
export default {
  components: {
    metaInfo
  },
  data(){
    return {
      guid: this.$router.currentRoute.params.guid
    }
  }
}
</script>
import { render, staticRenderFns } from "./thirdlogin.vue?vue&type=template&id=7ac1e600&scoped=true"
import script from "./thirdlogin.vue?vue&type=script&lang=js"
export * from "./thirdlogin.vue?vue&type=script&lang=js"
import style0 from "./thirdlogin.vue?vue&type=style&index=0&id=7ac1e600&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ac1e600",
  null
  
)

export default component.exports
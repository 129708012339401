<template>
    <el-row class="pad-30">
        <el-form ref="form" :model="form" :rules="rules" label-width="85px" label-suffix=":">
            <el-col :span="24">
                <el-form-item label="标题">
                    <el-input size="small" v-model="form.title"></el-input>
                </el-form-item>
            </el-col>

            <el-col :span="24">
                <el-form-item label="内容">
                    <el-input type="textarea" :rows="5" v-model="form.content"></el-input>
                </el-form-item>
            </el-col>
            <el-col :span="12">
                <el-form-item label="业务类型" prop="bizType">
                    <el-input size="small" v-model="form.bizType"></el-input>
                </el-form-item></el-col>
            <el-col :span="24">
                <el-form-item label="接收类型">
                    <el-checkbox-group v-model="recipientType">
                        <el-checkbox label="接收人"></el-checkbox>
                        <el-checkbox label="接收角色"></el-checkbox>
                        <el-checkbox label="接收部门"></el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
            </el-col>
            <el-col :span="12" v-if="recipientType.indexOf('接收人')>=0">
                <el-form-item label="接收人" prop="recipient">
                    <el-select style="width: 100%" v-model="form.recipient" multiple filterable remote size="small" reserve-keyword placeholder="请输入接收人" :remote-method="remoteMethod"
                               :loading="loading" clearable>
                        <el-option v-for="item in dddd" :key="item.id" :label="item.name" :value="item.id">
                            <span style="float: left">{{item.name}}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px">{{item.deptName}}</span>
                        </el-option>

                        <el-pagination @current-change="handleCurrentChange2" :page-size="page.pageSize" layout="prev, pager, next" :total="page.total">
                        </el-pagination>
                    </el-select>
                </el-form-item>
            </el-col>
            <el-col :span="24" v-if="recipientType.indexOf('接收角色')>=0">
                <avue-form v-model="form" :option="roleOption"></avue-form>
            </el-col>
            <el-col :span="24" v-if="recipientType.indexOf('接收部门')>=0">
                <avue-form v-model="form" :option="deptOption"></avue-form>
            </el-col>

            <el-col :span="24">
                <el-form-item :span="24">
                    <el-button type="primary" v-if="!form.id" size="small" @click="onSubmit('send')">保存&发送</el-button>
                    <el-button type="primary" plain size="small" @click="onSubmit">保存</el-button>
                    <el-button size="small" @click="close">取消</el-button>
                </el-form-item>
            </el-col>
        </el-form>
    </el-row>
</template>

<script>
import { saveMessage, updateMessage, getMessageDetail } from '@/api/desk/notice';
import { mapGetters, mapState } from 'vuex';
import { getDeptTree } from '@/api/system/dept';
import { getRoleList } from '@/api/system/role';
import { getList } from '@/api/system/user.js';

export default {
    components: {},
    data() {
        return {
            form: {},
            rules: {
                bizType: [{ required: true, message: '请输入业务类型', trigger: 'blur' }],
            },
            roleList: [],
            deptList: [],
            recipientType: [],

            loading: false,
            dddd: [],
            list: [],
            queryName: '',
            page: {
                pageSize: 5,
                currentPage: 1,
                total: 0,
            },
            roleOption: {
                labelWidth: 82,
                menuBtn: false,
                colon: 'false',
                column: [
                    {
                        label: '接收角色',
                        display: true,
                        prop: 'receivingRole',
                        multiple: true,
                        type: 'tree',
                        colon: false,
                        dicData: [],
                        props: {
                            label: 'roleName',
                            value: 'id',
                        },
                        width: '400px',
                        checkStrictly: true,
                        slot: true,
                    },
                ],
            },
            deptOption: {
                labelWidth: 82,
                menuBtn: false,
                colon: 'false',
                column: [
                    {
                        label: '接收部门',
                        prop: 'receivingDept',
                        type: 'tree',
                        multiple: true,
                        dicData: [],
                        props: {
                            label: 'title',
                        },
                        checkStrictly: true,
                        slot: true,
                        display: true,
                    },
                ],
            },
        };
    },
    props: {
        currentEditRow: {
            type: Object,
            default: function () {
                return {};
            },
        },
    },
    watch: {
        currentEditRow: {
            handler: function (newValue, oldName) {
                if (newValue.id) {
                    this.getMessageDetail();
                } else {
                    this.form = {};
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    mounted() {
        this.getData();
        this.initData();
    },

    methods: {
        initData() {
            getRoleList(1, 10000).then(res => {
                const column = this.findObject(this.roleOption.column, 'receivingRole');
                column.dicData = res.data.data;
            });
            getDeptTree(this.userInfo.tenant_id).then(res => {
                const column = this.findObject(this.deptOption.column, 'receivingDept');
                column.dicData = res.data.data;
            });
        },
        getMessageDetail() {
            getMessageDetail(this.currentEditRow.id).then(res => {
                console.log('想去');
                this.form = res.data.data;
                if (this.form.recipient != '') {
                    this.recipientType.push('接收人');
                    this.form.recipient = this.form.recipient.split(',');
                    if (this.form.recipient.length > 0) {
                        this.form.recipientName = this.form.recipientName.split(',');
                        this.form.recipient.forEach(element => {
                            this.getListById(element);
                        });
                    }
                }
                if (this.form.receivingRole != '') {
                    this.recipientType.push('接收角色');
                    this.form.receivingRole = this.form.receivingRole.split(',');
                }
                if (this.form.receivingDept != '') {
                    this.recipientType.push('接收部门');
                    this.form.receivingDept = this.form.receivingDept.split(',');
                }
            });
        },
        async getListById(id) {
            await getList(this.page.currentPage, this.page.pageSize, { id: id }).then(res => {
                this.loading = false;
                this.dddd = this.dddd.concat(res.data.data.records);
                console.log(this.ddd);
                this.page.total = this.page.total + res.data.data.total;
            });
        },
        getData() {
            getList(this.page.currentPage, this.page.pageSize, { realName: this.queryName }).then(res => {
                this.loading = false;
                this.dddd = res.data.data.records;
                this.page.total = res.data.data.total;
            });
        },
        onSubmit(IsSend) {
            if (this.form.recipient) {
                if (this.recipientType.indexOf('接收人') >= 0) {
                    this.form.recipient = this.form.recipient.toString();
                } else {
                    this.form.recipient = '';
                }
            }
            if (this.form.receivingDept) {
                if (this.recipientType.indexOf('接收部门') >= 0) {
                    this.form.receivingDept = this.form.receivingDept.toString();
                } else {
                    this.form.receivingDept = '';
                }
            }
            if (this.form.receivingRole) {
                if (this.recipientType.indexOf('接收角色') >= 0) {
                    this.form.receivingRole = this.form.receivingRole.toString();
                } else {
                    this.form.receivingRole = '';
                }
            }
            if (IsSend == 'send') {
                this.form.sendFlag = 1;
            }
            if (this.form.id) {
                updateMessage(this.form).then(res => {
                    this.$emit('close');
                });
            } else {
                saveMessage(this.form).then(res => {
                    this.$emit('close');
                });
            }
        },
        recipientTypeChange() {
            console.log(this.form);
        },
        close() {
            this.$emit('close');
        },

        onLoad(page, params = {}) {
            this.loading = true;
            this.queryName = query;
            getList(page.currentPage, page.pageSize, Object.assign(params, this.query), this.treeDeptId).then(res => {
                const data = res.data.data;
                this.data = data.records;
            });
        },
        remoteMethod(query) {
            this.queryName = query;
            this.loading = true;
            this.getData();
        },

        //分页方法
        handleCurrentChange2(val) {
            this.page.currentPage = val;
            this.getData();
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
// /deep/ .el-select > .el-input {
//     width: 300px;
// }
</style>

<template>
  <div></div>
</template>
<script>
import { getTgtLoginToken } from '@/api/baseService/index.js';
export default {
  data(){
    return {
      baseUrl: 'https://172.16.73.10',
    }
  },
  mounted(){
    getTgtLoginToken().then(
      res => {
        let resData = JSON.parse(res.data.data);
        let openUrl = `${this.baseUrl}/lsm/ssoService/v1/tokenLogin?token=${resData.data.token}&service=https://172.16.73.10/portal/ui/index`;
        // console.log(openUrl);
        window.open(openUrl)
        this.$router.back();
      }
    )
  }
}
</script>
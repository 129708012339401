<template>
  <basic-container>
    <avue-crud
      ref="crud"
      :table-loading="tableLoading"
      :data="tableData"
      :page.sync="page"
      :option="tableOption"
      @row-del="deleteRow"
      @on-load="onLoad"
      @refresh-change="onLoad"
    >
      <template slot="shareUrl" slot-scope="scope">
        <el-tooltip class="item" :content="scope.row.shareUrl" placement="top-start">
          <span>{{scope.row.shareUrl.split('/').shift()}}...{{scope.row.shareUrl.split('/').pop()}}</span>
          <div slot="content"><span class="mar-r-15"><i @click="$copy(scope.row.shareUrl)" class="el-icon-document-copy cur-p"></i></span>{{scope.row.shareUrl}}</div>
        </el-tooltip>
      </template>
      <template slot="menuLeft">
        <!-- 搜索 -->
        <el-row :gutter="$store.state.common.gutter">
          <el-col :span="5">
            <el-button @click="addRow" icon="el-icon-plus" type="primary" size="small"> 新 增</el-button>
          <el-button @click="registerApi" icon="el-icon-plus" size="small"> 注 册</el-button>
          </el-col>
          <el-col :span="4">
            <el-input size="small" v-model='search.name' clearable placeholder="请输入名称"></el-input>
          </el-col>
          <el-col :span="4">
            <el-select size="small" v-model='search.apiCollectionId' clearable placeholder="请选择api集合">
              <el-option v-for='item in collectionDatas' :key='item.id' :value='item.id' :label='item.name'></el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select size="small" v-model='search.status' clearable placeholder="请选择状态">
              <el-option v-for='item in stateData' :key='item.value' :value='item.value' :label='item.label'></el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-button size="small" icon="el-icon-search" type="primary" @click="searchChange">搜索</el-button>
            <el-button size="small" icon="el-icon-delete" @click="emptHandle">清空</el-button>
          </el-col>
        </el-row>
      </template>
      <!-- 调用次数 -->
      <template slot="callCount" slot-scope="scope">
        <span style='color: #0c7dff;cursor:pointer' @click='callCountClick(scope.row)'>{{scope.row.callCount}}</span>
      </template>
      <template slot="menu" slot-scope="scope">
        <el-button @click="editRow(scope.row)" icon="el-icon-edit" type="text" size="small">编辑</el-button>
        <el-button @click="viewRow(scope.row)" icon="el-icon-view" type="text" size="small">查看</el-button>
        <el-button @click="testRow(scope.row)" icon="el-icon-caret-right" type="text" size="small">测试</el-button>
        <el-button @click="_poponline(scope.row)" v-if="[0,-1,''].indexOf(scope.row.status) > -1" icon="el-icon-upload2" size="small" type="text">上架</el-button>
        <el-button @click="_downline(scope.row)" v-if="scope.row.status == '1'" icon="el-icon-download" size="small" type="text">下架</el-button>
      </template>
    </avue-crud>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :width="dialogWidth"
      append-to-body
      @close="dialogCancel"
    >
      <data-share-form @close="dialogCancel" @refresh="onLoad" v-if="dialogVisibleType === 'edit'" :id="currentId" :dataSourceName="dataSourceName" :tableName="tableName" :dataSourceType="dataSourceType"></data-share-form>
      <data-share-view @close="dialogCancel" v-if="dialogVisibleType === 'view'" :id="currentId"></data-share-view>
      <data-share-register @close="dialogCancel" @refresh="onLoad" v-if="['register-add', 'register-edit'].indexOf(dialogVisibleType) > -1" :id="currentId" :type="dialogVisibleType"></data-share-register>
      <data-share-register-view @close="dialogCancel" v-if="dialogVisibleType === 'register-view'" :id="currentId"></data-share-register-view>
    </el-dialog>
    <el-drawer
      title="测试"
      :visible.sync="drawer"
      append-to-body
    >
      <data-share-test @close="dialogCancel" v-if="drawer&&drawerType === 'test'" :id="currentId"></data-share-test>
      <data-share-register-test appId='10000000' @close="dialogCancel" v-if="drawer&&drawerType === 'register-test'" :id="currentId"></data-share-register-test>
    </el-drawer>
  </basic-container>
</template>
<script>
import api from '@/api/dataShare/index';
import * as dictbizService from '@/api/system/dictbiz';
import dataShareForm from './datashareForm.vue';
import dataShareTest from './datashareTest.vue';
import dataShareView from './datashareView.vue';
import dataShareRegister from './datashareRegister';
import dataShareRegisterView from './datashareRegisterView.vue';
import dataShareRegisterTest from './datashareRegisterTest.vue';
import apiStatus from '@/config/apiStatus';
import {apicollectionSelect} from '@/api/dataApiAccredit/dataApiAccredit'

export default {
  components: {
    dataShareForm,
    dataShareTest,
    dataShareView,
    dataShareRegister,
    dataShareRegisterView,
    dataShareRegisterTest
  },
  props: {
    dataSourceType: {
      type: String,
      default: ''
    },
    dataSourceName: {
      type: String,
      default: ''
    },
    tableName: {
      type: String,
      default: ''
    }
  },
  data(){
    return {
      collectionDatas: [],
      stateData: [
        {
          label: '未上架',
          value: 0
        },
        {
          label: '已上架',
          value: 1
        }
      ],
      search: {},
      drawer: false,
      drawerType: '',
      dialogTitle: '',
      dialogVisibleType: '',
      dialogWidth: '70%',
      dialogVisible: false,
      tableLoading: false,
      currentId: null,
      tableData: [],
      page: {
          pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableOption: {
        // height: 'auto',
        // calcHeight: 30,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: false,
        editBtn: false,
        addBtn: false,
        size: 'small',
        // viewBtn: true,
        // delBtn: false,
        // menuWidth: 350,
        dialogClickModal: false,
        menuWidth: 300,
        column: [
          {
            label: '名称',
            prop: 'name',
            span: 24,
          },
          {
            label: 'api集合',
            prop: 'apiCollectionId',
            type: 'select',
            dicUrl: '/api/jida-data-asset/apicollection/select',
            props: {
              label: 'name',
              value: 'id'
            },
            display: false,
          },
          {
            label: '入参',
            prop: '_inputParams'
          },
          {
            label: '返回参数',
            prop: '_outputParams'
          },
          {
            label: '请求地址',
            prop: 'shareUrl',
            width: 220,
          },
          {
            label: '请求方式',
            prop: 'requestMethod',
            dicUrl: dictbizService.getUrl('REQUEST_METHOD'),
            props: {
              label: 'dictValue',
              value: 'dictKey'
            }
          },
          // {
          //   label: '提供时间',
          //   prop: 'supplyTime'
          // },
          {
            label: '调用次数',
            prop: 'callCount',
            slot: true
          },
          {
            label: '调用数据量(条)',
            prop: 'callRecords',
            width: 150
          },
          {
            label: '创建方式',
            prop: 'type',
            display: false,
            formatter(row,value,label,column) {
              if (row.type==0) {
                return '本地生成'
              } else if (row.type==1){
                return '第三方注册'
              } else {
                return ''
              }
            },
          },
          {
            label: '状态',
            prop: 'status',
            type:'select',
            dicData: [
              {
                label: '未上架',
                value: 0
              },
              {
                label: '已上架',
                value: 1
              }
            ],
          },
          {
            label: '创建人',
            prop: 'createUserName',
          },
          {
            label: '创建时间',
            prop: 'createTime',
            width: 140
          }
        ]
      }
    }
  },
  methods: {
    // 调用次数 的点击事件，跳转到调用日志
    callCountClick (row) {
        this.$router.push({
          path: '/dataShare/datashareCallData',
          query: {
            id: row.id
          }
        })
    },
    // 清空搜索
    emptHandle () {
      this.search.name=''
      this.search.status=''
      this.search.apiCollectionId=''
    },
    // 搜索
    searchChange () {
      this.onLoad()
    },
    registerApi(){
      this.dialogVisibleType = 'register-add';
      this.dialogVisible = true;
      this.currentId = null;
      this.dialogTitle = '注册API';
      this.dialogWidth = '70%'
    },
    deleteRow(row){
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
      .then(() => {
        api.remove({
          ids:row['id']
        }).then(res => {
          this.onLoad();
        })
      })
    },
    addRow(){
      this.dialogVisible = true;
      this.dialogVisibleType = 'edit';
      this.dialogTitle = '新增';
      this.currentId = null;
      this.dialogWidth = '70%';
      // this.$breadcrumb.add({
      //   name: '新增'
      // });
      // this.$router.push('/dataShare/create');
    },
    editRow(row){
      this.dialogVisible = true;
      if(row.type === 1){
        this.dialogVisibleType = 'register-edit';
      }else{
        this.dialogVisibleType = 'edit';
      }
      this.dialogTitle = '编辑';
      this.currentId = row.id;
      this.dialogWidth = '70%';

    },
    _poponline(row) {
      api.upAndDown({
        id:row['id'],
        status: 1
      }).then(res => {
        this.onLoad();
      })
    },
    _downline(row) {
      api.upAndDown({
        id:row['id'],
        status: 0
      }).then(res => {
        this.onLoad();
      })
    },
    viewRow(row){
      // this.$breadcrumb.add({
      //   name: '查看'
      // });
      // this.$router.push('/dataShare/detail/' + row.id);
      this.dialogVisible = true;
      this.dialogVisibleType = 'view';
      if(row.type === 1){
        this.dialogVisibleType = 'register-view';
      }else{
        this.dialogVisibleType = 'view';
      }
      this.dialogTitle = '查看';
      this.currentId = row.id;
      this.dialogWidth = '70%';
    },
    testRow(row){
      this.drawer = true;
      if(row.type === 1){
        this.drawerType = 'register-test';
      }else{
        this.drawerType = 'test';
      }
      this.currentId = row.id;
    },
    dialogCancel(data){
      if(data){
        this.dialogTitle = data.title;
        this.dialogVisibleType = data.type;
      }else{
        this.dialogTitle = '';
        this.dialogVisibleType = '';
        this.dialogVisible = false;
      }
    },
    onLoad(){
      this.tableLoading = true;
      this.tableData = [];
      let querys = {
        current: this.page.currentPage,
        size: this.page.pageSize,
        apiCollectionId: this.search.apiCollectionId,
        status: this.search.status,
      }
      console.log(this.dataSourceName , this.tableName)
      if(this.dataSourceName && this.tableName){
        querys['dataSourceDb'] = this.dataSourceName;
        querys['dataSourceTable'] = this.tableName;
      }
      api.page(querys).then(
        res => {
          this.tableLoading = false;
          if(res.data){
            let tableData = [];
            (res.data.data.records || []).forEach(
              item => {
                tableData.push({
                  ...item,
                  _inputParams: item.inputParams ? JSON.parse(item.inputParams).map(v => v.key).join(',') : '',
                  _outputParams: item.outputParams ? JSON.parse(item.outputParams).map(v => v.key).join(',') : ''
                })
              }
            )
            this.tableData = tableData;
            this.page.total = res.data.data.total;
          }
        }
      )
    }
  },
  created(){
    // 获取API集合数据
    apicollectionSelect().then((res) => {
      if(res.data.code==200) {
        this.collectionDatas=res.data.data
      } else {
        this.collectionDatas=[]
      }
    })
  },
  mounted(){

  }
}
</script>

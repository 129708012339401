export default [
  {
    label: '未上架',
    value: -1
  },
  {
    label: '未上架',
    value: 0
  },
  {
    label: '已上架',
    value: 1
  }
]

<template>
  <div>
    <basic-container v-show="!showStandardMap">
      <avue-crud
        ref="table"
        v-model="tableForm"
        :before-open="beforeOpen"
        :data="tableData"
        :page.sync="page"
        :option="tablesOption"
        :table-loading="tableLoading"
        @search-change="searchChange"
        @on-load="tableOnLoad"
        @refresh-change="tableOnLoad"
        @row-del="handleDelTableData"
        @row-save="saveTableForm"
        @row-update="
          (row, index, done, loading) => saveTableForm(row, done, loading)
        "
      >
        <template slot="menu" slot-scope="{ size, row }">
          <el-button
            @click="
              currentRow = row;
              showStandardMap = true;
            "
            type="text"
            :size="size || $store.state.common.size"
            icon="portal-iconfont portal-icon_liuzhuan"
          >
            元数据映射</el-button
          >
          <el-button
            @click="setStatus(row)"
            v-if="row.status === 1"
            type="text"
            :size="size || $store.state.common.size"
            icon="iconfont icon-mima"
          >
            禁用</el-button
          >
          <el-button
            @click="setStatus(row)"
            v-if="row.status === 0"
            type="text"
            :size="size || $store.state.common.size"
            icon="el-icon-unlock"
          >
            启用</el-button
          >
        </template>
      </avue-crud>
    </basic-container>
    <standard-map
      v-if="showStandardMap"
      @back="showStandardMap = false"
      :item="currentRow"
    ></standard-map>
  </div>
</template>
<script>
import service from "@/api/metadata/standard";
import * as gradeService from "@/api/metadata/grade";
import * as dictService from "@/api/system/dictbiz";
import { listLabeltype } from "@/api/metadata/labeltype";

import standardMap from "./standard-map";

export default {
  components: { standardMap },
  data() {
    return {
      currentRow: null,
      showStandardMap: false,
      tableLoading: false,
      tableData: [],

      tablesOption: {
        size: "small",
        searchMenuSpan: 3,
        labelWidth: 110,
        column: [
          {
            label: "标准编号",
            prop: "standardNo",
            display: false,
            search: true,
          },
          {
            label: "标准中文名",
            prop: "standardNameCn",
            display: false,
            search: true,
            labelWidth: 100,
          },
          {
            label: "标准英文名",
            prop: "standardNameEn",
            display: false,
            search: true,
            labelWidth: 100,
          },
          {
            label: "数据类型",
            prop: "dataType",
            display: false,
            dicUrl: dictService.getUrl("MetaDataStandardDataType"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "数据长度",
            prop: "dataLength",
            display: false,
          },
          {
            label: "状态",
            prop: "status",
            display: false,
            dicData: [
              {
                label: "启用",
                value: 1,
              },
              {
                label: "禁用",
                value: 0,
              },
            ],
            type: "select",
            show: true,
            search: true,
          },
          {
            label: "敏感数据",
            prop: "isSensitived",
            dicData: [
              {
                label: "是",
                value: 1,
              },
              {
                label: "否",
                value: 0,
              },
            ],
            display: false,
          },
          {
            label: "创建人",
            prop: "createUserName",
            display: false,
          },
          {
            label: "创建时间",
            prop: "createTime",
            display: false,
            width: 130,
          },
        ],
        group: [
          {
            label: "业务属性",
            size: "small",
            labelWidth: 100,
            column: [
              {
                label: "标准编号",
                prop: "standardNo",
                type: "input",
                rules: [
                  {
                    required: true,
                    message: "请输入标准编号",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "标准中文名",
                prop: "standardNameCn",
                type: "input",
                rules: [
                  {
                    required: true,
                    message: "请输入标准中文名",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "标准英文名",
                prop: "standardNameEn",
                type: "input",
                rules: [
                  {
                    required: true,
                    message: "请输入标准英文名",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "分类",
                prop: "daLabelTypeId",
                type: "tree",
                props: {
                  label: "name",
                  value: "id",
                },
                dicData: [],
                rules: [
                  { required: true, message: "请选择分类", trigger: "blur" },
                ],
              },
              {
                label: "分级",
                prop: "daGradeId",
                type: "tree",
                props: {
                  label: "name",
                  value: "id",
                },
                dicData: [],
                rules: [
                  { required: true, message: "请选择分级", trigger: "blur" },
                ],
              },
              {
                label: "描述",
                prop: "description",
                type: "textarea",
                minRows: 2,
                span: 24,
              },
            ],
          },
          {
            label: "技术属性",
            size: "small",
            labelWidth: 100,
            column: [
              {
                label: "数据类型",
                prop: "dataType",
                dicUrl: dictService.getUrl("MetaDataStandardDataType"),
                type: "select",
                props: {
                  label: "dictValue",
                  value: "dictKey",
                },
                rules: [
                  {
                    required: true,
                    message: "请选择数据类型",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "数据长度",
                prop: "dataLength",
                type: "number",
                rules: [
                  {
                    required: true,
                    message: "请输入数据长度",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "数据精度",
                prop: "dataAccuracy",
                type: "number",
                display: false,
                rules: [
                  {
                    required: true,
                    message: "请输入数据精度",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "文件后缀",
                prop: "fileSuffix",
                type: "input",
                display: false,
                rules: [
                  {
                    required: true,
                    message: "请输入文件后缀",
                    trigger: "blur",
                  },
                ],
                placeholder: "请输入文件后缀，以英文分好分隔",
              },
              {
                label: "字典",
                prop: "dictionaryCode",
                dicUrl: "/api/jida-system/dict-biz/parent-list-all",
                type: "select",
                props: {
                  label: "dictValue",
                  value: "code",
                },
                filterable: true,
                display: false,
                rules: [
                  { required: true, message: "请选择字典", trigger: "blur" },
                ],
              },
              {
                label: "时间类型",
                prop: "dateTimeFormat",
                dicUrl: dictService.getUrl("MetaDataStandard_DateTimeFormat"),
                type: "select",
                props: {
                  label: "dictValue",
                  value: "dictKey",
                },
                filterable: true,
                display: false,
                rules: [
                  {
                    required: true,
                    message: "请选择时间类型",
                    trigger: "blur",
                  },
                ],
              },
            ],
          },
          {
            label: "质量属性",
            size: "small",
            labelWidth: 100,
            column: [
              {
                label: "字符规范",
                prop: "dataSpecification",
                type: "input",
                placeholder: "请输入正则表达式",
                span: 24,
              },
              {
                label: "不允许为空",
                prop: "isNotNull",
                type: "radio",
                dicData: [
                  { label: "是", value: 1 },
                  { label: "否", value: 0 },
                ],
                value: 0,
              },
            ],
          },
          {
            label: "敏感属性",
            size: "small",
            labelWidth: 100,
            column: [
              {
                label: "敏感数据",
                prop: "isSensitived",
                type: "radio",
                dicData: [
                  { label: "是", value: 1 },
                  { label: "否", value: 0 },
                ],
                value: 0,
              },
            ],
          },
        ],
      },
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      tableForm: {
        standardNo: "",
        standardNameCn: "",
        standardNameEn: "",
        daLabelTypeId: null,
        daGradeId: null,
        description: "",
        dataType: null,
        dataLength: 0,
        dataSpecification: "",
        isNotNull: 1,
        isSensitived: 1,
      },
      dyColumns: [
        "dateTimeFormat",
        "dictionaryCode",
        "fileSuffix",
        "dataAccuracy",
      ],
      dyColumnHash: {
        DateTime: "dateTimeFormat",
        dictionary: "dictionaryCode",
        Double: "dataAccuracy",
        coordinate: "dataAccuracy",
        attachFile: "fileSuffix",
      },
    };
  },
  watch: {
    "tableForm.dataType"(val) {
      if (Object.keys(this.dyColumnHash).indexOf(val) > -1) {
        this.setFormColumnDisplay((item) => {
          if (this.dyColumns.indexOf(item.prop) > -1) {
            if (this.dyColumnHash[val] === item.prop) {
              item.display = true;
            } else {
              item.display = false;
            }
          }
        });
      } else {
        this.setFormColumnDisplay((item) => {
          if (this.dyColumns.indexOf(item.prop) > -1) {
            item.display = false;
          }
        });
      }
    },
  },
  mounted() {
    this.initDicData();
  },
  methods: {
    searchChange(params, done) {
      console.log(params);
      done();
      this.tableOnLoad(params);
    },
    setFormColumnDisplay(fn) {
      this.tablesOption.group.forEach((groupItem) => {
        groupItem.column.forEach((columnItem) => {
          fn(columnItem);
        });
      });
    },
    _initTableForm() {
      return {
        standardNo: "",
        standardNameCn: "",
        standardNameEn: "",
        daLabelTypeId: null,
        daGradeId: null,
        description: "",
        dataType: null,
        dataLength: 0,
        dataSpecification: "",
        isNotNull: 1,
        isSensitived: 1,
      };
    },
    _setDicData(groupIndex, colProp, dicData) {
      let group = this.tablesOption.group[groupIndex] || null;
      if (group && group.column) {
        group.column.forEach((item) => {
          if (item.prop === colProp) {
            item.dicData = dicData;
          }
        });
      }
    },
    beforeOpen(done, type) {
      if (["edit"].includes(type)) {
        service.getDetail({ id: this.tableForm.id }).then((res) => {
          this.tableForm = res.data.data;
        });
      }
      done();
    },
    tableOnLoad(form) {
      this.tableLoading = true;
      let page = {
        size: this.page.pageSize,
        current: this.page.currentPage,
      };
      service
        .getList(Object.assign(page, form))
        .then((res) => {
          let data = res.data.data || null;
          if (data) {
            this.page.total = data.total;
            this.tableData = data.records;
          }
          this.tableLoading = false;
        })
        .catch((e) => {
          this.tableLoading = false;
        });
    },
    responeHandle(res, done, loading) {
      if (res.data && res.data.code === 200) {
        done && done();
        this.tableOnLoad();
        this.$message.success(res.data.msg);
      } else {
        loading && loading();
        this.$message.error(res.data.msg);
      }
    },
    saveTableForm(row, done, loading) {
      service
        .saveData(row)
        .then((res) => {
          this.responeHandle(res, done, loading);
        })
        .catch((e) => {
          loading();
        });
    },
    setStatus(row) {
      service
        .setStatus({
          id: row.id,
          status: row.status === 1 ? 0 : 1,
        })
        .then((res) => {
          this.responeHandle(res);
        });
    },
    handleDelTableData(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        service
          .remove({
            ids: row.id,
          })
          .then((res) => {
            this.responeHandle(res);
          });
      });
    },
    initDicData() {
      this.getGrade();
      this.getClassfication();
    },
    getGrade() {
      gradeService.getList().then((res) => {
        this._setDicData(0, "daGradeId", res["data"]["data"]["records"]);
      });
    },
    getClassfication() {
      listLabeltype().then((res) => {
        this._setDicData(0, "daLabelTypeId", res["data"]["data"]["records"]);
      });
    },
  },
};
</script>

<template>
  <el-row>
    <el-col :span="5">
      <div class="box">
        <el-scrollbar>
          <basic-container>
            <avue-tree
              :option="treeOption"
              :data="treeData"
              @node-click="nodeClick"
            />
          </basic-container>
        </el-scrollbar>
      </div>
    </el-col>
    <el-col :span="19">
      <basic-container>
        <avue-crud
          ref="crud"
          :option="option"
          :search.sync="search"
          :data="list"
          :page="page"
          @row-save="rowSave"
          @row-del="rowDel"
          @row-update="rowUpdate"
        >
          <template slot-scope="scope" slot="menuLeft">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="small"
              plain
              @click.stop="chooseModelboxOpen"
              >一键生成</el-button
            >
          </template>
        </avue-crud>
      </basic-container>
    </el-col>
    <el-drawer
      title="一键生成"
      :visible.sync="chooseModelbox"
      size="80%"
      direction="rtl"
      append-to-body="true"
      destroy-on-close="true"
    >
      <div class="pad-15">
        <!-- <tablePreview :option="preOption"></tablePreview> -->
        <tableDesign :importCode="preOption" :planCode="planCode"></tableDesign>
      </div>
    </el-drawer>
  </el-row>
</template>
<script>
import {
  getMetaItemList,
  getMetaList,
  updateMetaItem,
  removeMetaItem,
  addMetaItem,
  automatic,
  getListByPlanId,
} from "@/api/busiMode/meta";
import tablePreview from "../../../components/dynamic/table/preview";
import tableDesign from "../../data/table/table/design/design";

export default {
  name: "tableList",
  components: { tablePreview, tableDesign },
  data() {
    return {
      treeDeptId: "",
      treeData: [],
      preOption: {},
      tableId: "",
      treeOption: {
        nodeKey: "id",

        addBtn: false,
        menu: false,
        size: "small",
        props: {
          labelText: "标题",
          label: "cname",
          value: "datasource",
          children: "children",
        },
      },
      planCode: "",
      chooseModelbox: false,
      option: {
        refreshBtn: true,
        sortable: true,
        menu: true, //操作菜单栏
        addBtn: true, //添加按钮
        columnBtn: false, //列显隐
        // editBtn: false, //行内编辑按钮
        // delBtn: false,
        column: [
          {
            label: "中文名",
            prop: "cname",
          },
          {
            label: "英文名",
            prop: "name",
          },

          {
            label: "数据类型",
            prop: "dateType",
          },
          {
            label: "字段长度",
            prop: "length",
          },
          {
            label: "小数点长度",
            prop: "pointLength",
          },
          {
            label: "是否为空",
            prop: "blank",
          },
        ],
      },
      list: [],
      page: {
        pageSizes: [10, 20, 30, 40], //默认
        currentPage: 1,
        total: 10,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    chooseModelboxOpen() {
      this.loadOption(this.tableId);
    },
    loadOption(id) {
      automatic(id).then((res) => {
        console.log(res.data);
        if (res.data.code == 200) {
          let obj = { planId: res.data.data };
          getListByPlanId(obj).then((res) => {
            console.log(res);
            if (res.data.code == 200) {
              let a = eval("(" + res.data.data[0].content + ")");
              this.planCode = res.data.data[0].code;
              this.preOption = a.option;
              this.chooseModelbox = true;
            }
          });
        }
      });
    },
    initData() {
      getMetaList().then((res) => {
        console.log(res.data.data.records);
        this.treeData = res.data.data.records;
        this.tableId = this.treeData[0]["id"];
        this.getMetaItemList();
      });
    },
    getMetaItemList() {
      getMetaItemList(this.tableId).then((res) => {
        this.list = res.data.data.records;
      });
    },
    nodeClick(data) {
      this.tableId = data.id;
      this.getMetaItemList();
    },
    rowSave(row, done) {
      addMetaItem(row).then(() => {
        this.$message.success("新增成功");
        getMetaItemList().then((res) => {
          this.list = res.data.data.records;
        });
        done();
      });
    },
    rowDel(row, index) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeMetaItem(row.id).then(() => {
            this.list.splice(index, 1, row);
          });
        })
        .catch(() => {});
    },
    rowUpdate(row, index, done) {
      updateMetaItem(row).then(() => {
        //this.$message.success("修改成功");
        this.list.splice(index, 1, row);
        done();
      });
    },
    goDesign() {
      // this.$router.push({
      //   path: "/data/table/design",
      //   query: {
      //     name: "信息快报",
      //   },
      // });
      //console.log(row);
      // console.log(this.$refs.crud);
      // this.$refs.crud.rowAdd();
      // this.list.push(row);
      // done();
    },
    handleEdit(row) {
      // this.$router.push({
      //   path: "/data/table/design",
      //   query: {
      //     name: "信息快报",
      //   },
      // });
    },
  },
};
</script>
<style scoped></style>

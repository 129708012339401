<template>
    <basic-container v-loading="loading">
        <!-- <basic-page-header :title="types[type]" @back="goBack"></basic-page-header> -->
        <el-form  :disabled="type === 'view'" label-width="120px" class="mar-t-40" size="small" ref="form">
            <el-form-item label="类型" required>
                <el-radio-group v-model="form.authorizeType" @change="form.authorizeId = []">
                    <el-radio size="small" :label="1">角色</el-radio>
                    <el-radio size="small" :label="2">用户</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item required v-if="form.authorizeType === 1" label="角色">
                <el-cascader
                class="w-300"
                size="small"
                v-model="form.authorizeId"
                :options="roles"
                :props="{ multiple: true , checkStrictly: true , label: 'roleName' , value: 'id' , emitPath: false}"
                collapse-tags
                clearable></el-cascader>
            </el-form-item>
            <el-form-item required v-if="form.authorizeType === 2" label="用户">
                <el-select class="w-300" v-model="form.authorizeId" multiple collapse-tags filterable placeholder="请选择">
                    <el-option
                    v-for="user in users"
                    :key="user.id"
                    :label="user.name"
                    :value="user.id">
                        <span style="float: left">{{ user.name }}</span>
                        <span style="float: right; color: #8492a6; font-size: 13px;margin-right: 15px">{{ user.roleName }}</span>
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item required label="授权">
                <div class="mar-b-15">
                    <el-button  v-if="type !== 'view'" icon="el-icon-plus" class="pull-right mar-r-20" type="primary" @click="addAuthorize">添加</el-button>
                    <el-input
                        class="dis-ib w-300"
                        placeholder="输入关键字搜索..."
                        prefix-icon="el-icon-search"
                        v-model="search">
                    </el-input>
                </div>
                <el-table :data="form.authorizeList.filter(data => !search ||  data.tableName.toLowerCase().includes(search.toLowerCase()))" border max-height="400">
                    <el-table-column
                    label="类型"
                    width="100"
                    >
                    <template slot-scope="{row}">
                        <span>{{({1: '数据库' , 2: '数据表'}[row.type])}}</span>
                    </template>
                    </el-table-column>
                    <el-table-column
                    label="名称"
                    min-width="150"
                    >
                    <template slot-scope="{row}">
                        <span>{{row.databaseName+(row.type === 1 ? '' : ('.'+row.tableName))}}</span>
                    </template>
                    </el-table-column>
                    <el-table-column
                    prop="dataHierarchyName"
                    label="数据分层"
                    width="150">
                    </el-table-column>
                    <!-- <el-table-column
                    prop="databaseName"
                    label="数据库"
                    width="150">
                    </el-table-column> -->
                    <el-table-column
                    prop=""
                    label="权限"
                    width="350">
                    <template slot-scope="scope">
                        <el-checkbox v-if="scope.row.type === 1" :value="getSwitch(scope.row , 'create')" @change="setSwitch(scope.row , 'create')">创建表</el-checkbox>
                        <el-checkbox v-if="scope.row.type === 1" :value="getSwitch(scope.row , 'drop')" @change="setSwitch(scope.row , 'drop')">删除表</el-checkbox>
                        <el-checkbox :value="getSwitch(scope.row , 'insert')" @change="setSwitch(scope.row , 'insert')">写入</el-checkbox>
                        <el-checkbox :value="getSwitch(scope.row , 'select')" @change="setSwitch(scope.row , 'select')">读取</el-checkbox>
                        <el-popover
                            class="mar-l-5"
                            placement="top-start"
                            title="授权字段"
                            width="430"
                            trigger="click"
                            v-if="getSwitch(scope.row , 'select') && scope.row.type === 2"
                        >
                            <el-table height="200" :data="fields">
                                <el-table-column width="150" prop="value" label="字段"></el-table-column>
                                <el-table-column width="150" prop="type" label="类型"></el-table-column>
                                <el-table-column width="100" label="授权读取">
                                    <template slot-scope="{row}">
                                        <el-switch :value="getFieldSwitch(scope.row  , row , 'select')" @change="setFieldSwitch(scope.row  , row , 'select')"></el-switch>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <span class="text-primary cur-p" slot="reference" @click="remoteMethod(scope.row)">授权字段({{getColumnNum(scope.row , 'select')}})</span>
                        </el-popover>
                    </template>
                    </el-table-column>
                    <!-- <el-table-column
                    prop=""
                    label="写入"
                    width="180">
                    <template slot-scope="scope">
                        <el-switch :value="getSwitch(scope.row , 2)"  class="mar-r-15" @change="setSwitch(scope.row , 2)"></el-switch>
                        <el-popover
                            placement="top-start"
                            title="授权字段"
                            width="430"
                            trigger="click"
                            v-if="getSwitch(scope.row , 1) && scope.row.type === 2"
                        >
                            <el-table height="200" :data="fields">
                                <el-table-column width="150" prop="value" label="字段"></el-table-column>
                                <el-table-column width="150" prop="type" label="类型"></el-table-column>
                                <el-table-column width="100" label="授权写入">
                                    <template slot-scope="{row}">
                                        <el-switch :value="getFieldSwitch(scope.row  , row , 2)" @change="setFieldSwitch(scope.row  , row , 2)"></el-switch>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <span class="text-primary cur-p" slot="reference" @click="remoteMethod(scope.row)">授权字段({{getColumnNum(scope.row , 2)}})</span>
                        </el-popover>
                    </template>
                    </el-table-column> -->
                    <el-table-column  v-if="type !== 'view'" label="操作">
                        <template slot-scope="scope">
                            <el-button
                            icon="el-icon-delete"
                            type="text"
                            @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-form-item>
            <basic-form-button v-if="type !== 'view'" @submit="saveData" @cancel="goBack"></basic-form-button>
        </el-form>
        <el-dialog
        title="添加表"
        :visible.sync="dialogVisible"
        v-if='dialogVisible'
        width="70%"
        append-to-body
        >
            <basic-label-row>
                <basic-label-item label="数据分层">
                    <el-select size="small" v-model="newAuthorizeVo.dataHierarchyId" @change="tables = [];dataHierarchyChange();">
                        <el-option
                        v-for="item in dataHierarchys"
                        :key="item.id"
                        :label="item.dhName"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </basic-label-item>
                <basic-label-item label="数据库">
                    <el-select size="small" v-model="newAuthorizeVo.databaseId" @change="tables = [];databasesChange()">
                        <el-option label="请选择" value=""></el-option>
                        <el-option
                        v-for="item in databases"
                        :key="item.dataBaseId"
                        :label="item.name"
                        :value="item.dataBaseId">
                        </el-option>
                    </el-select>
                </basic-label-item>
                <basic-label-item label="数据表">
                    <avue-crud
                        ref="table"
                        :data="tables"
                        :page.sync="page"
                        :option="tablesOption"
                        :table-loading="tableLoading"
                        @on-load="showTables"
                        @refresh-change="showTables"
                        @select="selectionChange"
                        @select-all="selectionChange"
                        >
                        <template slot="menuLeft">
                            <el-checkbox v-if="newAuthorizeVo.databaseId" @change="allTableChange" class="mar-r-15" v-model="newAuthorizeVo.allTable">选择数据库全部表</el-checkbox>
                            <el-input
                            v-model="searchVal"
                            @keyup.native.enter="showTables"
                            size="small"
                            :placeholder="$t('Please enter keyword')"
                            type="text"
                            style="width:180px;"
                            >
                                <el-button
                                    class="mar-0 pad-0"
                                    size="small"
                                    @click="showTables"
                                    slot="append"
                                    icon="el-icon-search"
                                ></el-button>
                            </el-input>
                            <div v-if="newAuthorizeVo.tables.length > 0" class="dis-ib mar-l-30">
                                共选中{{newAuthorizeVo.tables.length}}条数据 <el-button @click="clearSelection" icon="el-icon-circle-close" type="text">清空</el-button>
                            </div>
                        </template>
                    </avue-crud>
                </basic-label-item>
            </basic-label-row>
            <basic-form-button :isForm="false" @submit="saveAddVo" @cancel="cancelAdd"></basic-form-button>
        </el-dialog>
    </basic-container>
</template>
<script>

import * as roleApi from '@/api/system/role';
import * as userApi from '@/api/system/user';
import service from '@/api/dataAssets/authorize'

import {
  getDataLayerList,
  queryTablesByDataBaseIdAndDhId,
  queryDataBaseByDataHierarchyId
} from "@/api/dws/datalayer";

import { queryDataByDataBaseIdAndTableName } from "@/api/dws/datamodel";

export default {
    data(){
        return {
            loading: false,
            types: {
                'view': '查看授权',
                'edit': '编辑授权',
                'add': '新增授权'
            },
            type: this.$router.currentRoute.params.type,
            id: this.$router.currentRoute.params.id || null,
            search: '',
            form: {
                authorizeType: 1,
                authorizeId: [],
                authorizeList: []
            },
            roles: [],
            users: [],
            dataHierarchys: [],
            databases: [],
            tables: [],
            dialogVisible: false,
            currentDatabase: null,
            newAuthorizeVo: {
                dataHierarchyId: '',
                dataHierarchyName: '',
                databaseId: '',
                databaseName: '',
                allTable: false,
                tables: []
            },
            page: {
              pageSizes: this.$store.state.common.pageSizes,
              pagerCount: this.$store.state.common.pagerCount,
              pageSize: 10,
              currentPage: 1,
              total: 0
            },
            fields: [],
            fieldHash: {},
            searchVal: '',
            tableLoading: false,
            tablesOption: {
                tip: false,
                addBtn: false,
                editBtn: false,
                delBtn: false,
                menu: false,
                selection: true,
                maxHeight: 300,
                border: true,
                column: [
                    {
                        label: "表名称",
                        prop: "tableName",
                        width: 200
                    },
                    {
                        label: "表类型",
                        prop: "typeName"
                    },
                    {
                        label: "字段数",
                        prop: "columnNum"
                    },
                    {
                        label: "数据量（条）",
                        prop: "dataRows"
                    }
                ]
            },
        }
    },
    // destroyed(){
    //     this.$breadcrumb.pop();
    // },
    created(){
        if(this.$breadcrumb.breadcrumbs.length < 3){
            this.$breadcrumb.add({
                name: this.types[this.type]
            })
        }
    },
    mounted(){
        roleApi.getList(1 , 10000).then(
            res => {
                this.roles = res.data ? res.data.data : [];
            }
        )
        userApi.getList(1 , 10000).then(
            res => {
                this.users = res.data ? res.data.data.records : [];
            }
        )
        getDataLayerList({
            dhName: '',
            pageNo: 1,
            pageSize: 10000
        }).then(
            res => {
                this.dataHierarchys = res.data.data.lists || [];
            }
        )
        if(this.type !== 'add' && this.id){
            this.loading = true;
            service.get({
                id: this.id
            }).then(
                res => {
                    this.loading = false;
                    let data = res.data.data;
                    let form = null;
                    if(typeof data['authorizeJson'] === 'string'){
                        form = JSON.parse(data['authorizeJson']);
                    }
                    if(form){
                        form.id = data.id;
                    }
                    console.log(form);
                    this.form = form || {
                        authorizeType: 1,
                        authorizeId: [],
                        authorizeList: []
                    };
                }
            ).catch(e => this.loading = false);
        }
    },
    methods: {
        goBack(){
            this.$breadcrumb.pop();
            this.$router.back();
        },
        getColumnNum(row , type){
            let len = 0;
            if(row.authorizeList){
                row.authorizeList.forEach(
                    item => {
                        if(item.authorizeType === type){
                            console.log(item);
                            if(item.fields === '*'){
                                len = row.columnNum;
                            }else{
                                len = item.fields.length;
                            }
                        }
                    }
                )
            }
            return `${len}/${row.columnNum}`
        },
        saveData(done){
            if(this.form.authorizeType < 1){
                this.$message({
                    type: 'error',
                    message: '请选择类型'
                })
                done();
                return;
            }
            if(this.form.authorizeId.length < 1){
                this.$message({
                    type: 'error',
                    message: '请选择' + (this.form.authorizeType === 1 ? '角色' : '用户')
                })
                done();
                return;
            }
            if(this.form.authorizeList.length < 1){
                this.$message({
                    type: 'error',
                    message: '请添加授权信息'
                })
                done();
                return;
            }
            console.log(this.form)
            console.log(JSON.stringify(this.form , null , 2))
            service.save(this.form).then(
                res => {
                    done();
                    if(res.data.code === 200){
                        this.$message({
                            type: 'success',
                            message: res.data.msg || '操作成功'
                        })
                        this.goBack();
                    }
                }
            ).catch( e => done());
        },
        cancelAdd(done){
            done();
            this.dialogVisible = false;
        },
        saveAddVo(done){
            done();
            this.dialogVisible = false;
            let authorizeList = [];
            this.newAuthorizeVo.tables.forEach(
                item => {
                    if(!this.hasTable(item , this.form.authorizeList)){
                        authorizeList.push({
                            type: item.type,
                            databaseId: item.databaseId,
                            databaseName: item.databaseName,
                            dataHierarchyId: item.dataHierarchyId,
                            dataHierarchyName: item.dataHierarchyName,
                            tableName: item.tableName,
                            columnNum: item.columnNum,
                            authorizeList: item.type === 1 ? [
                                {
                                    authorizeType: 'create',
                                    fields: '*'
                                },
                                {
                                    authorizeType: 'drop',
                                    fields: '*'
                                },
                                {
                                    authorizeType: 'select',
                                    fields: '*'
                                },
                                {
                                    authorizeType: 'insert',
                                    fields: '*'
                                }
                            ] : [
                                {
                                    authorizeType: 'select',
                                    fields: '*'
                                },
                                {
                                    authorizeType: 'insert',
                                    fields: '*'
                                }
                            ]
                        });
                    }
                }
            )
            this.form.authorizeList = authorizeList;
        },
        handleDelete(index , row){
            this.form.authorizeList.splice(index , 1);
        },
        allTableChange(){
            if(this.newAuthorizeVo.allTable){
                this.newAuthorizeVo.tables.push({
                    type: 1,
                    databaseId: this.newAuthorizeVo.databaseId,
                    databaseName: this.newAuthorizeVo.databaseName,
                    dataHierarchyId: this.newAuthorizeVo.dataHierarchyId,
                    dataHierarchyName: this.newAuthorizeVo.dataHierarchyName,
                    tableName: '',
                    columnNum: 0
                })
                let tables = this.newAuthorizeVo.tables;
                for(let index = tables.length - 1; index > 0; index--){
                    let item = this.newAuthorizeVo.tables[index];
                    if(item.databaseId === this.newAuthorizeVo.databaseId && item.dataHierarchyId === this.newAuthorizeVo.dataHierarchyId && item.type === 2){
                        this.newAuthorizeVo.tables.splice(index , 1);
                    }
                }
                // this.newAuthorizeVo.tables.forEach(
                //     (item , index) => {
                //         if(item.databaseId === this.newAuthorizeVo.databaseId && item.dataHierarchyId === this.newAuthorizeVo.dataHierarchyId && item.type === 2){
                //             this.newAuthorizeVo.tables.splice(index , 1);
                //         }
                //     }
                // )
                this.tablesOption = {
                    ...this.tablesOption,
                    selection: false
                }
            }else{
                this.newAuthorizeVo.tables.forEach(
                    (item , index) => {
                        if(item.databaseId === this.newAuthorizeVo.databaseId && item.dataHierarchyId === this.newAuthorizeVo.dataHierarchyId && item.type === 1){
                            this.newAuthorizeVo.tables.splice(index , 1);
                        }
                    }
                )
                this.tablesOption = {
                    ...this.tablesOption,
                    selection: true
                }
            }
        },
        getFieldSwitch(row , field , type){
            if(row.authorizeList){
                let res = false;
                row.authorizeList.forEach(
                    item => {
                        if(item.authorizeType === type){
                            if(item.fields === '*' || item.fields.includes(field.value)){
                                res = true;
                            }
                        }
                    }
                )
                return res;
            }else{
                return false;
            }
        },
        setFieldSwitch(row , field , type){
            if(!row.authorizeList){
                row.authorizeList = [];
            }
            row.authorizeList.forEach(
                item => {
                    if(item.authorizeType === type){
                        let fields = [];
                        if(item.fields === '*'){
                            this.fields.forEach(
                                fieldItem => {
                                    if(fieldItem.value !== field.value){
                                        fields.push(fieldItem.value)
                                    }
                                }
                            )
                            item.fields = fields;
                        }else{
                            let index = item.fields.indexOf(field.value);
                            if(index > -1){
                                item.fields.splice(index , 1);
                            }else{
                                item.fields.push(field.value);
                            }
                            if(item.fields.length === row.columnNum){
                                item.fields = '*';
                            }
                        }
                    }
                }
            )
        },
        getSwitch(row , type){
            let res = false;
            if(row.authorizeList){
                row.authorizeList.forEach(
                    item => {
                        if(item.authorizeType === type){
                            res = true;
                        }
                    }
                )
            }
            return res;
        },
        setSwitch(row , type){
            if(!row.authorizeList){
                row.authorizeList = [];
            }
            let add = true;
            row.authorizeList.forEach(
                (item , index) => {
                    if(item.authorizeType === type){
                        add = false;
                        row.authorizeList.splice(index , 1);
                    }
                }
            )
            if(add){
                row.authorizeList.push({
                    authorizeType: type,
                    fields: '*'
                })
            }
        },
        hasTable(table , tables){
            let has = false;
            tables = tables || this.newAuthorizeVo.tables;
            tables.forEach(
                item => {
                    if(item.type === table.type && item.databaseId === table.dataBaseId && item.dataHierarchyId === table.dhId && item.tableName === table.tableName){
                        has = true;
                    }
                }
            );
            return has;
        },
        clearSelection(){
            this.newAuthorizeVo.tables = [];
            this.$refs.table.toggleSelection();
        },
        selectionChange(selections , row){
            let tables = [] , remove = true;
            selections.forEach(
                selection => {
                    if(!this.hasTable({
                        ...selection,
                        type: 2
                    })){
                        tables.push(selection);
                    }
                    if(row){
                        if(selection.tableName === row.tableName && selection.dataBaseId === row.dataBaseId && selection.dhId === row.dhId){
                            remove = false;
                        }
                    }
                }
            );
            if(remove && row){
                this.newAuthorizeVo.tables.forEach(
                    (item , index) => {
                        if(item.type === 2 && item.tableName === row.tableName && item.databaseId === row.dataBaseId && item.dataHierarchyId === row.dhId){
                            this.newAuthorizeVo.tables.splice(index , 1);
                        }
                    }
                )
            }
            if(row === undefined && selections.length === 0){
                this.tables.forEach(
                    row => {
                        this.newAuthorizeVo.tables.forEach(
                            (item , index) => {
                                if(item.type === row.type && item.tableName === row.tableName && item.databaseId === row.dataBaseId && item.dataHierarchyId === row.dhId){
                                    this.newAuthorizeVo.tables.splice(index , 1);
                                }
                            }
                        )
                    }
                )
            }
            tables.forEach(
                item => {
                    this.newAuthorizeVo.tables.push({
                        type: 2,
                        databaseId: item.dataBaseId,
                        databaseName: item.dataBaseName,
                        dataHierarchyId: item.dhId,
                        dataHierarchyName: item.dhName,
                        tableName: item.tableName,
                        columnNum: item.columnNum,
                    })
                }
            )
        },
        addAuthorize(){
            this.newAuthorizeVo = {
                dataHierarchyId: '',
                dataHierarchyName: '',
                databaseId: '',
                databaseName: '',
                allTable: false,
                tables: this.form.authorizeList.map(item => {
                    return {
                        databaseId: item.databaseId,
                        databaseName: item.databaseName,
                        dataHierarchyId: item.dataHierarchyId,
                        dataHierarchyName: item.dataHierarchyName,
                        tableName: item.tableName,
                        columnNum: item.columnNum,
                        type: item.type
                    }
                })
            }
            this.tables = [];
            this.databases = [];
            this.searchVal=''
            this.dialogVisible = true;
            if (this.currentDatabase==null) {}else {
              this.currentDatabase.dhId=''
              this.currentDatabase.guid=''
              this.currentDatabase.dataBaseId=''
            }
            this.page = {
                pageSize: 10,
                currentPage: 1,
                total: 0
            }
        },
        dataHierarchyChange(){
          this.page.total=0
            this.dataHierarchys.forEach(
                item => {
                    if(item.id === this.newAuthorizeVo.dataHierarchyId){
                        this.newAuthorizeVo.dataHierarchyName = item.dhName;
                    }
                }
            )
            this.newAuthorizeVo.databaseId = '';
            this.newAuthorizeVo.databaseName = '';
            this.databases = [];
            this.tables = [];
            queryDataBaseByDataHierarchyId({
                id: this.newAuthorizeVo.dataHierarchyId
            }).then(
                res => {
                    this.databases = res.data.data || [];
                }
            )
        },
        databasesChange(){
            this.newAuthorizeVo.allTable = false;
            this.tablesOption = {
                ...this.tablesOption,
                selection: true
            }
            this.newAuthorizeVo.tables.forEach(
                item => {
                    if(item.dataHierarchyId === this.newAuthorizeVo.dataHierarchyId && item.databaseId === this.newAuthorizeVo.databaseId && item.type === 1){
                        this.newAuthorizeVo.allTable = true;
                        this.tablesOption = {
                            ...this.tablesOption,
                            selection: false
                        }
                    }
                }
            )
            this.databases.forEach(
                item => {
                    if(item.dataBaseId === this.newAuthorizeVo.databaseId){
                        this.newAuthorizeVo.databaseName = item.database;
                        this.currentDatabase = item;
                    }
                }
            );
            this.page = {
                pageSize: 10,
                currentPage: 1,
                total: 0
            }
            this.showTables();
        },
        showTables() {
            let row = this.currentDatabase;
            if (!row || !row["guid"]) {
                return;
            }
            this.tableLoading = true;
            let obj = {
                id: row["dhId"],
                guid: row["guid"],
                databaseId: row["dataBaseId"],
                pageSize: this.page.pageSize,
                pageNo: this.page.currentPage,
                searchVal: this.searchVal
            };
            this.tables = [];
            queryTablesByDataBaseIdAndDhId(obj)
            .then(res => {
                this.tables = res["data"]["data"] ? res["data"]["data"].map(item => {return {
                    ...item ,
                    dataBaseId: row.dataBaseId ,
                    dataBaseName: row.database
                }}) : [];
                this.page.total = res["data"]["total"];
                this.tableLoading = false;
                this.$nextTick(() => {
                    let selections = [];
                    this.tables.forEach(
                        item => {
                            if(this.hasTable({
                                ...item,
                                type: 2
                            })){
                                selections.push(item);
                            }
                        }
                    )
                    this.$refs.table.toggleSelection(selections);
                })
            })
            .catch(e => {
                this.tableLoading = false;
                this.tables = [];
            });
        },
        remoteMethod(row){
            this.fields = [];
            let key = `${row.databaseId}_${row.tableName}`;
            if(this.fieldHash[key]){
                this.fields = this.fieldHash[key];
            }else{
                queryDataByDataBaseIdAndTableName({
                    databaseId: row.databaseId,
                    tableName: row.tableName,
                    rowSize: 0
                }).then(
                    res => {
                        this.fieldHash[key] = res.data.columns.map(item => {
                            return {
                                name: item.columnName,
                                value: item.columnName,
                                type: item.columnTypeName
                            }
                        });
                        this.fields = this.fieldHash[key];
                    }
                )
            }
        }
    }
}
</script>

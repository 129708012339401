<template>
  <avue-crud
    ref="table"
    :data="tableData"
    :page.sync="page"
    :option="tableOption"
    :table-loading="tableLoading"
    :search.sync="search"
    @search-change="searchChange"
    @on-load="onLoad"
    @refresh-change="onLoad"
  >
    <template slot-scope="{row}" slot="parkingFee">
      {{row.parkingFee}}元
    </template>
    <template slot-scope="{row}" slot="realFee">
      {{row.realFee}}元
    </template>
  </avue-crud>
</template>
<script>
import * as dictService from "@/api/system/dictbiz";
import {
  getMetaCommonList,
} from "@/api/busiMode/metaCommon";

export default {
  data(){
    return {
      model: 'wisdom_park.wp_third_qqb_long_rent_records',
      tableLoading: false,
      tableData: [],
      search: {},
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableOption: {
        height: 'auto',
        // calcHeight: 10,
        // labelPosition: 'top',
        searchMenuPosition: 'center',
        searchMenuSpan: 24,
        searchSpan: 6,
        border: true,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        menu:false,
        header: true,
        column: [
          {
            label: "车牌号码",
            prop: "plateNumber",
            width: 120,
            search: true,
            searchLike: true
          },
          {
            label: "开始时间",
            prop: "startDate",
            width: 100,
            valueFormat: 'YYYY-MM-DD HH:mm:ss',
            format: 'YYYY-MM-DD',
            type: 'datetime'
          },
          {
            label: "结束时间",
            prop: "endDate",
            width: 100,
            valueFormat: 'YYYY-MM-DD HH:mm:ss',
            format: 'YYYY-MM-DD',
            type: 'datetime'
          },
          // {
          //   label: "计费时长",
          //   prop: "spaceNum",
          //   width: 150
          // },
          // {
          //   label: "区域标识",
          //   prop: "mgrType",
          //   width: 150
          // },
          {
            label: "车辆类型",
            prop: "carType",
            width: 100,
            dicUrl: dictService.getUrl("CarType"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: 'number',
            type: 'select',
            search: true
          },
          {
            label: "应收",
            prop: "parkingFee",
            width: 150
          },
          {
            label: "折扣",
            prop: "discountFee"
          },
          {
            label: "实付",
            prop: "realFee"
          },
          {
            label: "支付渠道",
            prop: "payChannel",
            dicUrl: dictService.getUrl("PayChannel"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            search: true,
            type: 'select'
          },
          {
            label: "停车场",
            prop: "parkId",
            props: {
              label: "parkName",
              value: "parkId",
            },
            width: 250,
            dicData: [],
            search: true,
            type: 'select'
          },
          {
            label: "操作人员",
            prop: "operatorName",
            width: 200
          },
          {
            label: "操作时间",
            prop: "operateTime",
            width: 150
          },
        ]
      },
    }
  },
  props: {
    receiptNo: {
      type: String,
      default: ''
    }
  },
  mounted(){
    getMetaCommonList('wisdom_park.wp_third_qqb_park' , {
      size: 10000,
      current: 1
    }).then(
      res => {
        console.log(res);
        const column = this.findObject(this.tableOption.column, 'parkId');
        column.dicData = res.data.data.records || [];
      }
    )
  },
  methods: {
    searchChange(params, done) {
      this.onLoad().then(
        res => {
          done();
        }
      );
    },
    getQueryParams(){
      let wheres = [];
      this.tableOption.column.forEach(
        item => {
          if(item.search){
            if(item.type === 'daterange' || item.type === 'datetimerange'){
              if(this.search[item.prop] && this.search[item.prop].length === 2){
                let sName = item.searchProps && item.searchProps.start ? item.searchProps.start : item.prop;
                let eName = item.searchProps && item.searchProps.end ? item.searchProps.end : item.prop;
                let isDatetime = item.searchProps && item.searchProps.datetime ? true : false;
                wheres.push({ field: sName , value: `${this.search[item.prop][0]}${isDatetime ? ' 00:00:00' : ''}`, op: 'GE' })
                wheres.push({ field: eName , value: `${this.search[item.prop][1]}${isDatetime ? ' 23:59:59' : ''}`, op: 'LE' })
              }
            }else{
              if((this.search[item.prop] ?? '') !== ''){
                let itemWhere = { field: item.prop, value: this.search[item.prop] };
                if (item.searchLike) {
                  itemWhere["op"] = "LIKE";
                }
                wheres.push(itemWhere);
              }
            }
          }
        }
      );
      return wheres;
    },
    onLoad(){
      this.tableLoading = true;
      this.tableData = [];
      let wheres = this.getQueryParams();
      return getMetaCommonList(this.model , {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres,
        orders: [
          {field: 'operateTime' , sort: 'DESC'}
        ],
      }).then(
        res => {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          return res;
        }
      ).catch(e => {
        this.tableLoading = false;
        return e;
      })
    }
  }
}
</script>
<template>
    <div class="pad-10">
        <el-form :inline="true" :model="formSearch" size="small" class="demo-form-inline">
            <el-form-item label="门禁类型">
                <el-select v-model="formSearch.doorType" @change="resetform()" placeholder="请选择门禁类型">
                    <!-- <el-option label="人员通道门禁" value="0"></el-option>
          <el-option label="会议室门禁" value="1"></el-option> -->
                    <el-option v-for="item in doorTypes" :key="item.value" :label="item.name" :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="所属楼宇">
                <el-select v-model="formSearch.buildingId" filterable @change="floorListLoad" placeholder="请选择所属楼宇">
                    <template v-if="formSearch.doorType">
                        <el-option v-for="(item, i) in doorList" :key="i" v-if="item.parent_id == formSearch.doorType" :label="item.name" :value="item.id">
                        </el-option>
                    </template>
                    <!-- <template v-if="!formSearch.doorType">
            <el-option
              v-for="(item, i) in doorList"
              :key="i"
              v-if="item.parent_id == 0 || item.parent_id == 1"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </template> -->
                </el-select>
            </el-form-item>
            <el-form-item label="所属楼层">
                <el-select v-model="formSearch.floorId" filterable placeholder="请选择所属楼层">
                    <el-option v-for="(item, i) in floorList" :key="i" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit">查询</el-button>
                <el-button type="" @click="
            formSearch = {};
            onLoad();
          ">清空</el-button>
            </el-form-item>
        </el-form>
        <el-col :span="8"> </el-col>
        <el-row v-loading="loading" style="min-height: 400px">
            <el-col :span="8" class="mar-b-15 h-155" v-for="item in data">
                <el-card class="box-card pad-0" :body-style="{ padding: '0px' }">
                    <el-row class="pad-t-15 pad-l-20 pad-r-20">
                        <div class="mar-b-15">
                            <span class="fts-16">{{ item.name }}</span>
                            <span class="status2 fts-14 mar-l-10 pull-right" v-if="item.status == 0">
                                离线</span>
                            <span class="status1 fts-14 mar-l-10 pull-right" v-if="item.status == 1">
                                在线</span>
                        </div>
                        <div class="fts-14 col-999">
                            类型：
                            <span v-if="item.doorType == 0">人员通道门禁</span>
                            <span v-if="item.doorType == 1">会议室门禁</span>
                        </div>
                        <div class="fts-14 col-999 mar-t-10 overflow">
                            位置：
                            <span v-if="item.doorType == 1">
                                <span v-if="item.locationBuildName">
                                    {{ item.locationBuildName }}
                                </span>
                                <span v-if="item.locationCodRoomName">
                                    / {{ item.locationCodRoomName }}
                                </span>
                            </span>
                            <span v-if="item.doorType == 0">
                                <span v-if="item.locationBuildName">
                                    {{ item.locationBuildName }}
                                </span>
                                <span v-if="item.locationFloorName">
                                    / {{ item.locationFloorName }}层
                                </span>
                                <span v-if="item.locationName">
                                    / {{ item.locationName }}
                                </span>
                            </span>
                        </div>
                    </el-row>

                    <el-col :span="24" class="pad-b-10 mar-t-10">
                        <!-- <el-button size="mini" type="primary" plain
              >闸机操作记录</el-button
            >
            <el-button type="primary" size="mini" plain @click="txjl(item)"
              >闸机通行记录</el-button
            > -->
                        <el-link type="primary" @click="czjl(item)" class="mar-t-10 pull-right">闸机操作记录</el-link>
                        <el-link type="primary" @click="txjl(item)" class="pull-right mar-t-10 mar-r-10">闸机通行记录</el-link>
                        <el-button type="primary" size="mini" @click="doorevents(item, 2)">一键开闸</el-button>
                        <el-button type="primary" size="mini" class="mar-r-10" @click="doorevents(item, 1)">一键关闸</el-button>
                    </el-col>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog :visible.sync="detailDia" width="40%" class="enterDetail" destroy-on-close append-to-body>
            <div slot="title" class="header-title">
                <span class="fts-16" v-if="type == 'detail'">门禁详情</span>
            </div>

            <addDoor @close="closeDia" :currentRow="currentRow" :type="type"></addDoor>
        </el-dialog>
    </div>
</template>

<script>
import { getMetaCommonList, delMetaCommon } from '@/api/busiMode/metaCommon';
import standardService from '@/api/metadata/standard';
import { mapGetters } from 'vuex';
import addDoor from './addDoor';
import * as dictbizService from '@/api/system/dictbiz';

import { doorevents, doDoorControl, syncReSourcesDoors } from '@/api/visitor/visitor';

export default {
    components: { addDoor },
    data() {
        return {
            searchText: '',
            form: {},
            formSearch: {
                doorType: '0',
            },
            selectionList: [],
            loading: true,
            dialogVisible: false,
            detailDia: false,
            query: {},
            dialogType: '',
            type: '',
            size: '60%',
            currentRow: {},
            title: '',
            labelStyle: {
                color: '#999',
                fontSize: '12px',
            },
            page: {
                size: 10,
                current: 1,
                total: 0,
            },
            doorModel: 'wisdom_park.v_wp_door_tree_stat',
            model: 'wisdom_park.wp_third_hk_resources_doors',
            data: [],
            doorList: [],
            floorList: [],
            doorTypes: [],
            ruleModel: {},
        };
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.code_add, false),
                viewBtn: this.vaildData(this.permission.code_view, false),
                delBtn: this.vaildData(this.permission.code_delete, false),
                editBtn: this.vaildData(this.permission.code_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    mounted() {
        this.refreshData();
        this.queryByModelCode();
        this.getDicList();
        this.dorrLoad();
    },
    props: {},
    watch: {},
    methods: {
        getDicList() {
            dictbizService
                .getDictionary({
                    code: 'DoorType',
                })
                .then(res => {
                    this.doorTypes = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
        },
        resetform() {
            this.formSearch.buildingId = '';
            this.formSearch.floorId = '';
            this.dorrLoad();
        },
        add() {
            this.type = 'add';
            this.detailDia = true;
        },
        openDetailDia(type, row) {
            this.type = type;
            this.detailDia = true;
            setTimeout(() => {
                this.currentRow = row;
            }, 0);
        },
        back() {
            this.detailDia = false;
            this.searchReset();
        },
        queryByModelCode() {
            standardService.getModelRule(this.model).then(res => {
                this.ruleModel = res;
            });
        },
        txjl(row) {
            this.$router.push({
                path: '/door/doorEvent',
                query: { indexCode: row.indexCode },
            });
        },
        czjl(row) {
            this.$router.push({
                path: '/door/doList',
                query: { indexCode: row.indexCode },
            });
        },
        changName(row) {
            this.title = row.name;
        },

        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },

        selectionChange(list) {
            this.selectionList = list;
        },
        selectionClear() {
            this.selectionList = [];
        },

        refreshChange() {
            this.onLoad(this.page);
        },
        closeDia() {
            this.detailDia = false;
            this.refreshChange();
        },
        onSubmit() {
            this.onLoad();
        },
        refreshData() {
            syncReSourcesDoors().then(res => {
                this.onLoad();
            });
        },
        onLoad() {
            this.loading = true;
            let body = {
                current: 1,
                size: 100,
                orders: [{ field: 'name', sort: 'ASC' }],
                wheres: [],
            };
            if (this.formSearch.doorType) {
                body['wheres'].push({
                    field: 'doorType',
                    value: this.formSearch.doorType,
                });
            }
            if (this.formSearch.buildingId) {
                body['wheres'].push({
                    field: 'locationBuildId',
                    value: this.formSearch.buildingId,
                });
            }
            if (this.formSearch.floorId) {
                body['wheres'].push({
                    field: 'locationFloorId',
                    value: this.formSearch.floorId,
                });
            }
            getMetaCommonList(this.model, body).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                this.loading = false;
                this.selectionClear();
            });
        },
        dorrLoad() {
            let body = {
                current: 1,
                size: 1000,
                wheres: [{ field: 'doorType', value: this.formSearch.doorType }],
            };
            getMetaCommonList(this.doorModel, body).then(res => {
                const data = res.data.data;
                this.doorList = data.records;
            });
        },
        floorListLoad() {
            let body = {
                current: 1,
                size: 1000,
                wheres: [],
            };
            body['wheres'].push({
                field: 'parent_id',
                value: this.formSearch.buildingId,
            });
            getMetaCommonList(this.doorModel, body).then(res => {
                const data = res.data.data;
                this.floorList = data.records;
            });
        },

        doorevents(row, type) {
            //1 关闸 2 开闸 是否确认开闸
            let tip = type == 1 ? '关闸' : '开闸';
            let message = type == 1 ? '门关' : '门开';
            this.$confirm('确定' + tip, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let obj = {
                        doorIndexCodes: row.indexCode,
                        doorIndexNames: row.name,
                        controlType: type,
                        controlTypeName: message,
                    };
                    doDoorControl(obj).then(res => {
                        if (res.data.code == 200) {
                            this.$message.success('当前门禁已' + message);
                        }
                    });
                })
                .catch(() => {});
        },
        rowDel(row, index) {
            this.$confirm('是否删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let pk = 'id';
                    delMetaCommon(this.model, { pk: pk, ids: [row[pk]] }).then(res => {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        this.onLoad(this.page);
                    });
                })
                .catch(() => {});
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.status1 {
    color: #67c23a;
}
.status2 {
    color: #f56c6c;
}
.thsj-tj {
    font-size: 16px;
}
.text-title {
    margin-top: 6px;
    color: rgba(0, 0, 0, 0.5);
    display: block;
}
.title {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px 10px 0px 10px;
}
.bnd {
    position: absolute;
    top: -9px;
    color: rgba(0, 0, 0, 0.5);
}
.col-999 {
    color: #999;
}
.overflow {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
</style>

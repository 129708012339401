import { uuid , assign } from '../util';

export class DataViewWidget{

  id = null;
  selector = '';
  public = {
    title: {
      show: true,
      immersive: false,
      text: '测试数据',
      subtext: '',
      style: {
        color: '#000',
        background: '#fff',
        paddingLeft: 10,
        paddingRight: 10,
        unit: {
          paddingLeft: 'px',
          paddingRight: 'px'
        }
      },
      styleList: [],
      overflow: 'hidden'
    },
    style: {
      background: '#fff',
      paddingTop: 10,
      paddingLeft: 10,
      paddingRight: 10,
      paddingBottom: 10,
      unit: {
        paddingTop: 'px',
        paddingLeft: 'px',
        paddingRight: 'px',
        paddingBottom: 'px'
      }
    },
    dataConfig: {
      datasource: {
        id: null,
        type: ''
      },
      option: {}
    }
  }
  private = {};
  variables = [];
  dataOption = null

  x = 0;
  y = 0;
  i = null;
  w = 25;
  h = 10;


  constructor(widgetJson){
    widgetJson = widgetJson || {};
    this.id = widgetJson.id || `widget_${uuid()}`;
    this.i = this.id;
    this.selector = widgetJson.selector || null;
    this.dataOption = widgetJson.dataOption || null;
    this.variables = widgetJson.variables || [];
    this.x = widgetJson.x || 0;
    this.y = widgetJson.y || 0;
    this.w = widgetJson.w || 25;
    this.h = widgetJson.h || 10;
    this.public = widgetJson.public || this.public;
    this.private = widgetJson.private || this.private;

  }

}
<template>
  <basic-container>
    <basic-page-header @back="goBack"></basic-page-header>
    <avue-crud
      ref="table"
      :data="tableData"
      :page.sync="page"
      :option="tablesOption"
      :table-loading="tableLoading"
      @on-load="tableOnLoad"
      @refresh-change="tableOnLoad"
      @row-save="saveData"
      @row-update="(row , index , done , loading) => saveData(row , done , loading)"
      @row-del="handleDelTableData"
    >
      <template slot="mapName" slot-scope="{row}">
        {{row.databaseName}}/{{row.tableName}}/{{row.columnName}}
      </template>
      <template slot="menuLeft">
        <el-button @click="addRow" type="primary" icon="el-icon-plus" :size="$store.state.common.size">新增</el-button>
      </template>
      <template slot="mapNameForm" slot-scope="{ row , size }">
        <el-popover
          width="400"
          trigger="click">
          <div>
            <el-form ref="addForm" :rules="rules" :model="row" label-width="80px">
              <el-form-item label="数据库" prop="databaseQualifiedName">
                <el-select class="dis-b" :size="size" @change="databaseChange(false , row)" v-model="row.databaseQualifiedName" placeholder="请选择数据库">
                  <el-option v-for="item of databases" :key="item.qualifiedName" :label="item.displayText" :value="item.qualifiedName"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="表" prop="tableQualifiedName">
                <el-select filterable class="dis-b" :loading="tableSelectLoading" :size="size" @change="tableChange(false , row)" v-model="row.tableQualifiedName" placeholder="请选择数据库">
                  <el-option v-for="item of tables" :key="item.qualifiedName" :label="item.displayText" :value="item.qualifiedName"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="字段" prop="columnQualifiedName">
                <el-select filterable class="dis-b" :loading="columnSelectLoading" :size="size" @change="columnChange(row)" v-model="row.columnQualifiedName" placeholder="请选择数据库">
                  <el-option v-for="item of columns" :key="item.qualifiedName" :label="item.displayText" :value="item.qualifiedName"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
          <el-input class="dis-b w-280" placeholder="请选择 库/表/字段" :value="getEditViewName(row)" :size="size" slot="reference" readonly></el-input>
        </el-popover>
      </template>
    </avue-crud>
  </basic-container>
</template>
<script>

import service from '@/api/metadata/standard';

export default {
  data(){
    return {
      tableLoading: false,
      tableData: [],
      tablesOption: {
        size: 'small',
        addBtn: false,
        editBtn: false,
        // delBtn: false,
        cellBtn: true,
        cancelBtn: true,
        column: [
          {
            label: "标准编号",
            prop: "standardNo",
            display:false
          },
          {
            label: "标准中文名",
            prop: "standardNameCn",
            display:false
          },
          {
            label: "标准英文名",
            prop: "standardNameEn",
            display:false
          },
          {
            label: "数据类型",
            prop: "dataTypeName",
            display:false,
          },
          {
            label: "落地元数据名称",
            prop: "mapName",
            display:false,
            width: 300,
            cell: true
          },
          {
            label: "落标情况",
            prop: "createType",
            display:false,
            dicData: [
              {label: '自动匹配' , value: '0'},
              {label: '手动匹配' , value: '1'}
            ]
          },
        ]
      },
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },

      databases: [],
      tables: [],
      columns: [],
      formModel: {
        // databaseGuid: null,
        databaseName: '',
        // tableGuid: null,
        tableName: '',
        // columnGuid: null,
        columnName: '',
        databaseQualifiedName: '',
        databaseAtlasType: '',
        tableQualifiedName: '',
        tableAtlasType: '',
        columnQualifiedName: '',
        columnAtlasType: ''
      },
      tableSelectLoading: false,
      columnSelectLoading: false,
      rules: {
        databaseQualifiedName: [{ required: true, message: '请选择数据库', trigger: 'blur' }],
        tableQualifiedName: [{ required: true, message: '请选择表', trigger: 'blur' }],
        columnQualifiedName: [{ required: true, message: '请选择字段', trigger: 'blur' }],
      }
    }
  },
  props: {
    item: {
      typeof: Object,
      default: () => {
        return null;
      }
    }
  },
  mounted(){
    this.getAtlasDataBaseList();
  },
  methods: {
    goBack(){
      this.$emit('back');
    },
    tableOnLoad(){
      if(!this.item.id){
        return;
      }
      this.tableLoading = true;
      service.getMapList({
        current: this.page.currentPage,
        size: this.page.pageSize,
        metaDataStandardId: this.item.id
      }).then(
        res => {
          let data = res.data.data || null;
          if(data){
            this.page.total = data.total;
            this.tableData = data.records.map(
              item => {
                let metaDataStandard = item.metaDataStandard || {};
                return {
                  ...item,
                  dataTypeName: metaDataStandard.dataTypeName || '',
                  standardNameCn: metaDataStandard.standardNameCn || '',
                  standardNameEn: metaDataStandard.standardNameEn || '',
                  standardNo: metaDataStandard.standardNo || ''
                }
              }
            );
          }
          this.tableLoading = false;
        }
      ).catch(
        e => {
          this.tableLoading = false;
        }
      )
    },
    getAtlasDataBaseList(){
      service.getAtlasDataBaseList().then(
        res => {
          if(res.data && res.data.code === 200){
            this.databases = res.data.data || [];
          }
        }
      )
    },
    databaseChange(noClear , row){
      if(!noClear){
        row.tableName = '';
        row.tableQualifiedName = null;
        row.databaseAtlasType = '';
        row.columnName = '';
        row.columnQualifiedName = null;
        row.columnAtlasType = '';
        // databaseQualifiedName: '',
        // databaseAtlasType: '',
        // tableQualifiedName: '',
        // tableAtlasType: '',
        // columnQualifiedName: '',
        // columnAtlasType: ''
      }
      let dataBaseGuid = null;
      this.databases.forEach(
        item => {
          if(item.qualifiedName === row.databaseQualifiedName){
            row.databaseName = item.displayText;
            row.databaseAtlasType = item.atlasType;
            dataBaseGuid = item.guid;
          }
        }
      );
      this.tableSelectLoading = true;
      service.getAtlasDataTableList({
        dataBaseGuid: dataBaseGuid,
        qualifiedName: row.databaseQualifiedName,
        atlasType: row.databaseAtlasType
      }).then(
        res => {
          if(res.data && res.data.code === 200){
            this.tables = res.data.data || [];
          }
          this.tableSelectLoading = false;
        }
      )
    },
    tableChange(noClear , row){
      if(!noClear){
        row.columnName = '';
        row.columnQualifiedName = null;
        row.columnAtlasType = '';
      }
      let tableGuid = null;
      this.tables.forEach(
        item => {
          if(item.qualifiedName === row.tableQualifiedName){
            row.tableName = item.displayText;
            row.tableAtlasType = item.atlasType;
            tableGuid = item.guid;
          }
        }
      );
      this.columnSelectLoading = true;
      service.getAtlasColumnList({
        tableGuid: tableGuid,
        qualifiedName: row.tableAtlasType,
        atlasType: row.tableAtlasType
      }).then(
        res => {
          if(res.data && res.data.code === 200){
            this.columns = res.data.data || [];
          }
          this.columnSelectLoading = false;
        }
      )
    },
    columnChange(row){
      this.columns.forEach(
        item => {
          if(item.qualifiedName === row.columnQualifiedName){
            row.columnName = item.displayText;
            row.columnAtlasType = item.atlasType;
          }
        }
      );
    },
    
    getEditViewName(row){
      let names = [];
      if(row.databaseName){
        names.push(row.databaseName);
        if(row.tableName){
          names.push(row.tableName);
          if(row.columnName){
            names.push(row.columnName);
          }
        }
      }
      return names.join('/');
    },
    addRow(){
      let metaDataStandard = this.item;
      this.$refs.table.rowCellAdd({
        $cellEdit: true,
        // databaseGuid: null,
        databaseName: '',
        // tableGuid: null,
        tableName: '',
        // columnGuid: null,
        columnName: '',
        createType: '1',
        dataTypeName: metaDataStandard.dataTypeName || '',
        standardNameCn: metaDataStandard.standardNameCn || '',
        standardNameEn: metaDataStandard.standardNameEn || '',
        standardNo: metaDataStandard.standardNo || '',
        databaseQualifiedName: '',
        databaseAtlasType: '',
        tableQualifiedName: '',
        tableAtlasType: '',
        columnQualifiedName: '',
        columnAtlasType: ''
      })
    },
    responeHandle(res , done , loading){
      if(res.data && res.data.code === 200){
        done && done();
        this.tableOnLoad();
        this.$message.success(res.data.msg);
      }else{
        loading && loading();  
        this.$message.error(res.data.msg);
      }
    },
    handleDelTableData(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        service.removeMap({
          ids: row.id
        }).then(res => {
          this.responeHandle(res)
        })
      })
    },
    saveData(row , done , loading){
      let form = {
        ...row,
        metaDataStandardId: this.item.id
      }
      this.$refs.addForm.validate(valid => {
        if(valid){
          service.saveMap(form).then(
            res => {
              this.responeHandle(res , done , loading);
            }
          ).catch(e => {
            loading();
          })
        }else{
          this.$message.error('数据库、表和字段为必填项');
          loading();
          return false;
        }
      })
    }
  }
}
</script>
<template>
    <div v-loading="loading" class="pad-15">
        <el-descriptions title="基本信息" :column="column" direction="vertical" :colon="false" :labelStyle="labelStyle">
            <el-descriptions-item label="合同编号">{{
        info.contract_no || '-'
      }}</el-descriptions-item>
            <el-descriptions-item label="租客名称">{{
        info.renters_name || '-'
      }}</el-descriptions-item>
            <el-descriptions-item label="企业法人">{{
        info.corporation_personality || '-'
      }}</el-descriptions-item>
            <el-descriptions-item label="法人联系方式">{{
        info.corporation_personality_contact || '-'
      }}</el-descriptions-item>
            <el-descriptions-item label="签订人">{{
        info.renters_signed || '-'
      }}</el-descriptions-item>
            <el-descriptions-item label="签订人联系方式">{{
        info.renters_signed_contact || '-'
      }}</el-descriptions-item>
            <el-descriptions-item label="联系地址">{{
        info.renters_address || '-'
      }}</el-descriptions-item>
            <el-divider></el-divider>
            <el-descriptions title="" :column="column" direction="vertical" :colon="false" :labelStyle="labelStyle">
                <el-descriptions-item v-if="info.rentDateType == 1" label="租赁时间">{{ info.rentPeriod }}个月</el-descriptions-item>
                <el-descriptions-item label="开始日期">{{
        info.start_time | formatDate("YYYY-MM-DD")
      }}</el-descriptions-item>
                <el-descriptions-item label="结束日期">{{
        info.end_time | formatDate("YYYY-MM-DD")
      }}</el-descriptions-item>
                <el-descriptions-item label="签订日期">{{
        info.signedDate
      }}</el-descriptions-item>
            </el-descriptions>

        </el-descriptions>
        <el-descriptions class="mar-t-20" title="房源信息"></el-descriptions>
        <el-table :data="contractSubjects" border class="mar-b-20" size="small">
            <el-table-column label="楼宇" prop="building_name"></el-table-column>
            <el-table-column label="楼层" prop="floor_name"></el-table-column>
            <el-table-column label="房间名称" prop="room_name"></el-table-column>

        </el-table>
        <template v-if="!isBusiness">
            <el-descriptions title="金额条款" :column="column" direction="vertical" :colon="false" :labelStyle="labelStyle">
                <el-descriptions-item label="租金总额">￥{{ info.amount || "-" }}元</el-descriptions-item>
                <el-descriptions-item label="租赁面积(㎡)">{{ info.rent_area || "-" }}</el-descriptions-item>
            </el-descriptions>
        </template>
    </div>
</template>
<script>
import { detail, updateAttachments } from '@/api/contract/index.js';
import { getMetaCommonDetail, getPicListByIds, getMetaCommonList } from '@/api/busiMode/metaCommon';
import { getDictionary } from '@/api/system/dictbiz.js';
// import ManageFormFile from './manageFormFile.vue';

export default {
    components: {},
    data() {
        return {
            loading: false,
            info: {},
            labelStyle: {
                color: '#999',
                fontSize: '12px',
            },
            depositTypes: {
                0: '默认',
                1: '自定义',
            },
            relations: [
                {
                    code: 'contractTemplateId',
                    model: 'wp_contract_template',
                    props: [{ code: 'contractTemplateName', name: 'template_name' }],
                    type: 1,
                },
                {
                    code: 'lessorSigned',
                    model: 'wp_employee',
                    props: [{ code: 'lessorSignedName', name: 'name' }],
                    type: 1,
                },
                {
                    code: 'companyId',
                    model: 'wp_company',
                    props: [
                        { code: 'companyName', name: 'name' },
                        { code: 'corporationPersonality', name: 'legal_person' },
                        { code: 'corporationPersonalityContact', name: 'legal_contact' },
                    ],
                    type: 1,
                },
                {
                    code: 'collectionAccountId',
                    model: 'wp_collection_account',
                    props: [
                        { code: 'collectionAccountName', name: 'collection_account' },
                        { code: 'accountName', name: 'account_name' },
                        { code: 'accountBank', name: 'deposit_bank' },
                    ],
                    type: 1,
                },
                {
                    code: 'contractAttribute',
                    model: 'ContractAttribute',
                    props: [{ code: 'contractAttributeName', name: 'dictValue' }],
                    type: 2,
                },
                {
                    code: 'contractType',
                    model: 'ContractType',
                    props: [{ code: 'contractTypeName', name: 'dictValue' }],
                    type: 2,
                },
                {
                    code: 'receiptStrategy',
                    model: 'ReceiptStrategy',
                    props: [{ code: 'receiptStrategyName', name: 'dictValue' }],
                    type: 2,
                },
                {
                    code: 'incrementalStrategy',
                    model: 'ReceiptStrategy',
                    props: [{ code: 'incrementalStrategyName', name: 'dictValue' }],
                    type: 2,
                },
                {
                    code: 'depositPayStatus',
                    model: 'DepositPayStatus',
                    props: [{ code: 'depositPayStatusName', name: 'dictValue' }],
                    type: 2,
                },
                {
                    code: 'propertyFeeWay',
                    model: 'PropertyFeeWay',
                    props: [{ code: 'propertyFeeWayName', name: 'dictValue' }],
                    type: 2,
                },
                {
                    code: 'beforePaymentType',
                    model: 'BeforePaymentType',
                    props: [{ code: 'beforePaymentTypeName', name: 'dictValue' }],
                    type: 2,
                },
                {
                    code: 'offerType',
                    model: 'OfferType',
                    props: [{ code: 'offerTypeName', name: 'dictValue' }],
                    type: 2,
                },
                {
                    code: 'fixRentFree',
                    model: 'FixRentFree',
                    props: [{ code: 'fixRentFreeName', name: 'dictValue' }],
                    type: 2,
                },
                {
                    code: 'frequencyUnit',
                    model: 'FREQUENCY_UNIT',
                    props: [{ code: 'frequencyUnitName', name: 'dictValue' }],
                    type: 2,
                },
                {
                    code: 'preferentialWay',
                    model: 'PREFERENTIAL_WAY',
                    props: [{ code: 'preferentialWayName', name: 'dictValue' }],
                    type: 2,
                },
            ],
            contractSubjects: [],
            rawdata: {},
        };
    },
    props: {
        id: {
            type: String,
            default: '',
        },
        isTodo: {
            type: Boolean,
            default: false,
        },
        column: {
            type: Number,
            default: 4,
        },
    },
    computed: {
        isBusiness() {
            return Number(this.info.contractType) === 2 ? true : false;
        },
    },
    mounted() {
        this.getDetail();
        this.getRoomList();
    },
    methods: {
        getRoomList() {
            let obj = {
                current: 1,
                size: 100,
                wheres: [{ field: 'sublessor_contract_id', value: this.id }],
            };
            getMetaCommonList('wisdom_park.wp_sublessor_contract_subject', obj).then(res => {
                const data = res.data.data;
                this.contractSubjects = data.records;
                console.log('this.info.contractSubjects', this.info.contractSubjects);
            });
        },
        getFrequency() {
            if (['1', '2'].includes(`${this.info.frequencyUnit}`)) {
                return `${this.info.frequency}${this.info.frequencyUnitName}`;
            }
        },
        saveAttachmentIds(e) {
            updateAttachments({
                id: this.id,
                attachmentIds: e,
            });
        },
        viewContract() {
            getPicListByIds([this.info.pdfAttachmentId]).then(res => {
                if (res.data.data && res.data.data[0]) {
                    let file = res.data.data[0];
                    window.open(`/pdfjs/web/viewer.html?file=${encodeURIComponent(file.link)}`);
                }
            });
        },
        getDetail() {
            if (this.id) {
                this.loading = true;
                this.loading = true;
                getMetaCommonDetail('wisdom_park.wp_sublessor_contract', {
                    pk: 'id',
                    id: this.id,
                }).then(res => {
                    this.loading = false;
                    let form = res.data.data;
                    Object.keys(form).forEach(keyName => {
                        if (typeof form[keyName] === 'number') {
                            form[keyName] = `${form[keyName]}`;
                        }
                    });
                    this.info = form;
                });
            }
        },
        getRelationData(info) {
            let len = 0,
                count = 0;
            let resHandle = res => {
                len++;
                info = {
                    ...info,
                    ...res,
                };
                if (len >= count) {
                    this.info = info;
                    this.loading = false;
                }
            };
            if (this.relations.length) {
                this.relations.forEach(item => {
                    if (info[item.code] !== '' && info[item.code] !== null && info[item.code] !== undefined) {
                        count++;
                        if (item.type === 1) {
                            this.getMetaCommonDetail(item, info).then(resHandle);
                        } else {
                            this.getDictionary(item, info).then(resHandle);
                        }
                    } else {
                        item.props.forEach(prop => {
                            info[prop.code] = '-';
                        });
                    }
                });
            } else {
                this.info = info;
                this.loading = false;
            }
        },
        getMetaCommonDetail(item, form) {
            return getMetaCommonDetail(`wisdom_park.${item.model}`, {
                pk: 'id',
                id: form[item.code],
            }).then(res => {
                let info = {};
                if (res.data.data) {
                    let data = res.data.data;
                    item.props.forEach(prop => {
                        info[prop.code] = data[prop.name];
                    });
                }
                return info;
            });
        },
        getDictionary(item, form) {
            return getDictionary({
                code: item.model,
            }).then(res => {
                let info = {};
                if (res.data && res.data.data) {
                    let data = res.data.data.find(d => {
                        if (d['dictKey'] === `${form[item.code]}`) {
                            return d;
                        }
                    });
                    if (data) {
                        item.props.forEach(prop => {
                            info[prop.code] = data[prop.name];
                        });
                    }
                }
                return info;
            });
        },
    },
};
</script>

<template>
  <div>
    <div class="block">
      <span class="nstration">单层高度</span>
      <el-select
        v-model="buildObj.floor_height"
        size="small"
        :disabled="onlyRead == true"
        placeholder="请选择"
      >
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        >
        </el-option>
      </el-select>
    </div>
    <div class="block mar-t-20">
      <span class="nstration">楼层配置</span>
      <div
        style="height: calc(100vh - 500px); overflow-x: hidden !important"
        class="ov-a"
      >
        <configTable
          ref="configTable"
          :onlyRead="onlyRead"
          :buildObj="buildObj"
        ></configTable>
      </div>
    </div>
  </div>
</template>

<script>
import configTable from "./configTable";

export default {
  components: { configTable },
  data() {
    return {
      input1: 10,
      options: [
        {
          value: "1",
          label: "1～3米",
        },
        {
          value: "2",
          label: "3～4米",
        },
        {
          value: "3",
          label: "4～5米",
        },
        {
          value: "4",
          label: "5米以上",
        },
      ],

      value: "3",
    };
  },
  props: {
    onlyRead: {
      type: Boolean,
      default: function () {
        return true;
      },
    },
    buildObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },

  watch: {
    "buildObj.floor_height": {
      handler: function (newValue, oldName) {
        if (newValue) {
          console.log(newValue);
          this.$emit("update", newValue);
        }
      },
      deep: true,
    },
  },
  computed: {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.nstration {
  display: block;
  color: rgb(153, 153, 153);
  font-size: 12px;
  margin-bottom: 10px;
}
.configInput {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 80px;
  margin: 0px 5px;
}
</style>

<template>
  <div>
    <div>
      <el-input
        style="margin-bottom: 15px; width: calc(100% - 34px)"
        size="small"
        placeholder="请输入内容"
        suffix-icon="el-icon-search"
        v-model="filterText"
      >
      </el-input>
      <el-button
        style="margin-left: 5px"
        type="primary"
        size="mini"
        icon="el-icon-refresh"
        circle
        @click="refreshTable"
      ></el-button>
    </div>
    <template v-if="!treeRefresh">
      <el-scrollbar class="h-previewtree" v-loading="treeOptionRefresh">
        <el-tree
          v-if="treeOption.dataOption.type == 'list'"
          ref="tree"
          :data="treeData"
          element-loading-text="加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.2)"
          @node-click="handleNodeClick"
          :filter-node-method="filterNode"
        >
        </el-tree>
        <el-tree
          ref="tree"
          lazy
          v-if="treeOption.dataOption.type == 'tree'"
          :load="loadNode"
          element-loading-text="加载中"
          element-loading-spinner="el-icon-loading"
          element-loading-background="rgba(0, 0, 0, 0.2)"
          @node-click="handleNodeClick"
          :empty-text="emptyText"
          :filter-node-method="filterNode"
        >
        </el-tree>
      </el-scrollbar>
    </template>
  </div>
</template>
<script>
import {
  getMetaCommonList,
  getMetaCommonTree,
} from "@/api/busiMode/metaCommon";

export default {
  name: "previewTree",
  computed: {},
  components: {},
  data() {
    return {
      treeData: [],
      filterText: "",
      treeOptionRefresh: false,
      treeRefresh: false,
      resolve: {},
      emptyText: "",
      item: {},
    };
  },
  props: {
    treeOption: {
      type: Object,
      default: function () {
        return {};
      },
    },
    guid: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  created() {},
  mounted() {},
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
    treeOption: {
      handler(newValue, oldName) {
        if (this.treeOption.dataOption.model) {
          this.refreshTable();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    refreshTable() {
      if (this.treeOption.dataOption.type) {
        if (this.treeOption.dataOption.type == "list") {
          this.emptyText = "";
          this.getMetaCommonList();
        } else {
          this.emptyText = "";
          this.treeRefresh = true;
          this.handleNodeClick({});
        }
      }
    },
    loadNode(item, resolve) {
      this.item = item;
      console.log(item);
      this.resolve = resolve;
      this.treeOptionRefresh = true;
      let obj = {};
      let arr = [];

      if (item["data"]) {
        obj = {
          labelField: this.treeOption.dataOption.labelField, //标签显示字段
          valueField: this.treeOption.dataOption.valueField, //取值的字段 key
          releaseField: this.treeOption.dataOption.releaseField, //关联字段
          rootValue: item["data"].value,
        };
      } else {
        obj = {
          labelField: this.treeOption.dataOption.labelField, //标签显示字段
          valueField: this.treeOption.dataOption.valueField, //取值的字段 key
          releaseField: this.treeOption.dataOption.releaseField, //关联字段
        };
      }
      if (!obj.rootValue) {
        let a = {
          children: [],
          label: "全部",
          value: -99,
        };
        arr.push(a);
      }
      if (this.guid) {
        let searchTreeObj =
          this.guid.split(".")[0] + "." + this.treeOption.dataOption.model;
        console.log("开始加载了欧欧～");
        getMetaCommonTree(searchTreeObj, obj).then((res) => {
          if (res.data.code == 200) {
            arr = arr.concat(res["data"]["data"]);
            console.log(resolve);
            if (arr.length > 0) {
              this.emptyText = "暂无数据";
            }
            this.treeOptionRefresh = false;
            resolve(arr);
          }
        });
      }

      //  }
    },
    filterNode(value, data) {
      return data.label.indexOf(value) !== -1;
    },
    getMetaCommonList() {
      this.treeOptionRefresh = true;
      this.treeData = [
        {
          children: [],
          label: "全部",
          value: -99,
        },
      ];
      let searchTreeObj =
        this.guid.split(".")[0] + "." + this.treeOption.dataOption.model;
      getMetaCommonList(searchTreeObj, {}).then((res) => {
        if (res.data.code == 200) {
          let model = this.treeOption.dataOption;
          if (this.treeData.length > 0) {
            res.data.data.records.forEach((element) => {
              this.treeData.push({
                label: element[model.labelField],
                value: element[model.valueField],
                children: [],
              });
            });
          } else {
            this.emptyText = "暂无数据";
          }
        }
        this.treeOptionRefresh = false;
      });
    },
    handleNodeClick(search) {
      this.treeSearch = search;
      let objs = {
        field: this.treeOption.dataOption.label,
        value: search.value,
        modelObj: this.treeOption.dataOption,
      };
      if (search.value == "-99") {
        objs = {};
      }
      let t = this;
      setTimeout(() => {
        t.treeRefresh = false;
        t.$emit("treeNodeClick", objs);
      }, 10);
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss"></style>

<template>
    <div>
        <basic-container>
            <avue-crud
                :option="option"
                :data="data"
                :page.sync="page"
                :table-loading="tableLoading"
                @on-load="onLoad"
                @refresh-change="onLoad"
            >
                <template slot="menuLeft" slot-scope="scope">
                    <el-button type="primary" :size="size" icon="el-icon-plus" @click="add">新增</el-button>
                </template>
                <template slot-scope="{type , row}" slot="menu">
                    <el-button :size="size" :type="type" @click="view(row)" icon="el-icon-view">查看</el-button>
                    <el-button :size="size" :type="type" @click="edit(row)" icon="el-icon-edit">编辑</el-button>
                    <el-button :size="size" :type="type" icon="el-icon-delete" @click="deleteRow(row)">删除</el-button>
                </template>
            </avue-crud>
        </basic-container>
    </div>
</template>
<script>

import service from '@/api/dataAssets/authorize'

export default {
    data(){
        return {
            tableLoading: false,
            data: [],
            size: this.$store.state.common.size,
            option: {
                addBtn: false,
                delBtn: false,
                editBtn: false,
                // viewBbtn: false,
                column: [
                    {
                        label: "授权类型",
                        prop: "authorizeType",
                        width: 200,
                        dicData: [
                            {
                                label: "角色",
                                value: 1
                            },
                            {
                                label: "用户",
                                value: 2
                            }
                        ]
                    },
                    {
                      label: "授权角色/用户",
                      prop: "authorizeName",
                      width: 200,
                      formatter(row) {
                        return (row.authorizeName==''||row.authorizeName==null)?" ":JSON.parse(row.authorizeName).toString()
                      }
                    },
                    {
                        label: "创建时间",
                        prop: "createTime"
                    },
                    {
                        label: "修改时间",
                        prop: "updateTime"
                    }
                ]
            },
            page: {
                pageSizes: this.$store.state.common.pageSizes,
                pagerCount: this.$store.state.common.pagerCount,
                pageSize: 10,
                currentPage: 1,
                total: 0
            }
        }
    },
    mounted(){

    },
    methods: {
        onLoad(){
            this.tableLoading = true;
            service.getList({
                current: this.page.currentPage,
                size: this.page.pageSize
            }).then(
                res => {
                    this.data = res.data.data.records.map(item => {
                        return {
                            ...item,
                            authorizeType: +item['authorizeType']
                        }
                    });
                    this.tableLoading = false;
                }
            )
        },
        add(){
            this.$router.push('/data-assets/authorize/add')
        },
        edit(row){
            this.$router.push('/data-assets/authorize/edit/' + row.id)
        },
        view(row){
            this.$router.push('/data-assets/authorize/view/' + row.id)
        },
        deleteRow(row){
            this.$confirm("确定将选择数据删除?", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        service.remove({
                            ids: row['id']
                        }).then(res => {
                            instance.confirmButtonLoading = false;
                            this.onLoad();
                            done();
                        }).catch(e => { done(); instance.confirmButtonLoading = false;})
                    } else {
                        done();
                    }
                }
            })
        }
    }
}
</script>

<template>
  <el-form ref="form" :model="widget" size="small" label-width="80px">
    <el-form-item class="mar-t-8" label="显示标题">
      <el-switch v-model="widget.option.simple" :active-value="false" :inactive-value="true"></el-switch>
    </el-form-item>
    <el-form-item class="mar-t-8" label="标题">
      <el-input v-model="widget.title" placeholder="请输入内容"></el-input>
    </el-form-item>
    <el-form-item class="mar-t-8" label="子标题">
      <el-input v-model="widget.subTitle" placeholder="请输入内容"></el-input>
    </el-form-item>
  </el-form>
</template>
<script>
export default {
  name: 'WidgetConfigByHeader',
  tabName: '标题',
  data(){
    return {

    }
  },
  props: {
    widget: {
      type: Object,
      default: () => {
        return {}
      }
    },
    option: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
}
</script>
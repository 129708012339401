<template>
  <basic-container>
    <avue-crud :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               :before-open="beforeOpen"
               v-model="form"
               ref="crud"
               @row-update="rowUpdate"
               @row-save="rowSave"
               @row-del="rowDel"
               @search-change="searchChange"
               @search-reset="searchReset"
               @current-change="currentChange"
               @size-change="sizeChange"
               @refresh-change="refreshChange"
               @on-load="onLoad">
      <template slot="menuLeft">
        <el-button type="danger"
                   size="small"
                   icon="el-icon-delete"
                   plain
                   v-if="permission.epmallstore_delete"
                   @click="handleDelete">删 除
        </el-button>
      </template>

      <template slot-scope="{row}" slot="menu">
        <template v-if="(row.status != 0)">
          <el-button @click.stop="settleList(row)" icon="el-icon-s-order" size="mini" type="text">明细</el-button>
        </template>
        <template v-if="(row.status == 1)">
          <el-button @click.stop="gotoSettle(row)" icon="el-icon-c-scale-to-original" size="mini" type="text">挑单</el-button>
          <el-button type="text" size="mini" icon="el-icon-folder-delete" @click.stop="delSettle(row)">删除</el-button>
          <el-button type="text" size="mini" icon="el-icon-folder-delete" @click.stop="doSettle(row)">结算</el-button>
        </template>
      </template>
    </avue-crud>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      width="70%"
      append-to-body
      @close="dialogCancel"
    >
      <avue-form
        :option="formSettleOption"
        @submit="settleSubmit"
        v-model="formSettle"
      ></avue-form>
    </el-dialog>
  </basic-container>
</template>

<script>
  import {getPage,getDetail, add, update, remove, pickorders,settle,delSheet,doSettle} from "@/api/epmall/settlesheet";
  import {mapGetters} from "vuex";

  export default {
    data() {
      return {
        curRow:{},//当前行
        dialogTitle: "结算",
        dialogVisible: false,
        formSettle: {},
        formSettleOption: {
            column: [
              {
                label: "银行",
                prop: "bankId",
                type: "select",
                dicData: [
                  {
                    value: 1,
                    label: '中国建设银行'
                  },
                  {
                    value: 2,
                    label: '青岛银行'
                  },
                  {
                    value: 3,
                    label: '中国招商银行'
                  }
                ],
                rules: [
                  {
                    required: true,
                    message: "",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "银行流水号",
                prop: "bankSheetNo",
                rules: [
                  {
                    required: true,
                    message: "请输入银行流水号",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "备注",
                prop: "memo",
                span: 24,
                minRows: 4,
                type: "textarea",
                rules: [
                  {
                    required: false,
                    message: "请输入订购注意事项",
                    trigger: "blur",
                  },
                ],
              },
            ],
          },
        form: {
        },
        query: {},
        loading: true,
        page: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        selectionList: [],
        option: {
          height:'auto',
          calcHeight: 30,
          tip: false,
          searchShow: true,
          searchMenuSpan: 6,
          border: true,
          index: true,
          viewBtn: true,
          delBtn: false,
          editBtn:false,
          dialogClickModal: false,
          labelWidth: 100,
          column: [
            {
              addDisplay:false,
              label: "编码",
              prop: "code",
              width:150,
              searchSpan:4,
              rules: [{
                required: true,
                message: "请输入店铺名称",
                trigger: "blur"
              }]
            },
            {
              label: "名称",
              prop: "name",
              width:150,
              rules: [{
                required: true,
                message: "请输入结算单名称",
                trigger: "blur"
              }]
            },
            {
              addDisplay:false,
              label: "总金额",
              prop: "totalValue",
               rules: [{
                required: true,
                message: "请输入总金额",
                trigger: "blur"
              }]
            },
            {
              label: "店铺",
              prop: "storeId",
              type:"select",
              hide:true,
              search:true,
              searchSpan:4,
              with: 40,
              dicUrl:
              "/api/park-epmall/store/list?current=1&size=10000000",
              props: {
                label: "name",
                value: "id",
              },
              dicFormatter: (res) => {
                let ds =  res.data.records;
                ds.forEach(e=>{
                  e.id = Number(e.id);
                });
                return ds;
              },
              rules: [
                {
                  required: true,
                  message: "请输入店铺Id",
                  trigger: "blur",
                },
              ],
            },
          {
              label: "创建时间",
              prop: "createTime",
              addDisplay: false,
              editDisplay:false,
              hide: false,
              type: "datetime",
              format: "yyyy-MM-dd",
              valueFormat: "yyyy-MM-dd",
              rules: [{
                required: true,
                message: "请输入创建时间",
                trigger: "blur"
              }]
            },
            {
            addDisplay:false,
            label: "制单人",
            prop: "creatorName",
            rules: [
              {
                required: true,
                message: "请输入地址",
                trigger: "blur",
              },
            ],
            },
            {
            addDisplay:false,
            label: "银行流水号",
            prop: "bankSheetNo",
            rules: [
              {
                required: true,
                message: "请输入地址",
                trigger: "blur",
              },
            ],
            },
            {
            addDisplay:false,
            label: "银行",
            prop: "bankId",
            type:"select",
            dicData: [
                {
                  value: 1,
                  label: '中国建设银行'
                },
                {
                  value: 2,
                  label: '青岛银行'
                },
                {
                  value: 3,
                  label: '中国招商银行'
                }
              ],
            rules: [
              {
                required: true,
                message: "请输入地址",
                trigger: "blur",
              },
            ],
            },
            {
              label: "结算时间",
              prop: "settleTime",
              addDisplay: false,
              editDisplay:false,
              hide: false,
              type: "datetime",
              format: "yyyy-MM-dd",
              valueFormat: "yyyy-MM-dd",
              rules: [{
                required: true,
                message: "请输入结算时间",
                trigger: "blur"
              }]
            },
            {
            label: "结算人",
            addDisplay:false,
            prop: "settleName",
            rules: [
              {
                required: true,
                message: "请输入地址",
                trigger: "blur",
              },
            ],
            },
            {
              label: "状态",
              prop: "status",
              search: true,
              searchSpan: 4,
              addDisplay:false,
              editDisplay:false,
              type: 'select',
              dicData: [
                {
                  value: 0,
                  label: '作废'
                },
                {
                  value: 1,
                  label: '新单'
                },
                {
                  value: 2,
                  label: '已结算'
                }
              ],
              rules: [{
                required: false,
                message: "状态",
                trigger: "blur"
              }]
            },
            {
            label: "备注",
            prop: "memo",
            hide:true,
            span: 24,
            minRows:2,
            type:"textarea",
            rules: [
              {
                required: false,
                message: "请输入地址",
                trigger: "blur",
              },
            ],
            },
          ]
        },
        data: []
      };
    },
    computed: {
      ...mapGetters(["permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.epmallstore_add, false),
          viewBtn: this.vaildData(this.permission.epmallstore_view, false),
          delBtn: this.vaildData(this.permission.epmallstore_delete, false),
          editBtn: this.vaildData(this.permission.epmallstore_edit, false)
        };
      },
      ids() {
        let ids = [];
        this.selectionList.forEach(ele => {
          ids.push(ele.id);
        });
        return ids.join(",");
      }
    },
    methods: {
      doSettle(row){
        this.curRow = row;
        this.dialogVisible = true;
      },
      dialogCancel() {
        this.dialogVisible = false;
      },
      settleSubmit() {
        let _this = this;
        _this.formSettle.id = _this.curRow.id;
        doSettle(_this.formSettle).then(res=>{
          this.onLoad(this.page);
          this.$message({
            type: "success",
             message: res.data.data.msg
          });
          _this.dialogVisible = false;
        })
      },
      settleList(row){
        this.$router.push({
            path: "/settlesheet/orderlist",
            query: {
              storeId:row.storeId,
              settleId:row.id,
              mode:'list'
            },
          });
      },
      delSettle(row){
        this.$confirm("确定删除此结算单?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return delSheet(row.id);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
          });
      },
      gotoSettle(row){
        this.$router.push({
            path: "/settlesheet/orderlist",
            query: {
              storeId:row.storeId,
              settleId:row.id,
              mode:'pick'
            },
          });
      },
      rowSave(row, done, loading) {
           let op =parseInt(row.openTime);
        let en=parseInt(row.openEnd);
        if(en<=op){
           this.$message({
            type: "error",
            message: "结业时间不能大于开业时间"
          });
          return;
        }
        if(row.files && row.files.length>0){
          row.logoUrl = row.files[0].value;
        }
        add(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          window.console.log(error);
        });
      },
      rowUpdate(row, index, done, loading) {
           let op =parseInt(row.openTime);
        let en=parseInt(row.openEnd);
        if(en<=op){
           this.$message({
            type: "error",
            message: "结业时间不能大于开业时间"
          });
          return;
        }

        if(row.files && row.files.length>0){
          row.logoUrl = row.files[0].value;
        }
        update(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          console.log(error);
        });
      },
      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(row.id);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
          });
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
            this.$refs.crud.toggleSelection();
          });
      },
      beforeOpen(done, type) {
        let _this = this;
        if (["edit", "view"].includes(type)) {
          _this.form.files = [];
          getDetail(this.form.id).then(res => {
            this.form = res.data.data;
            _this.form = res.data.data;
            let files = [];
            files.push({"label":"noValue","value":this.form.logoUrl});
            _this.form.files = files;
          });
        }
        done();
      },
      searchReset() {
        this.query = {};
        this.onLoad(this.page);
      },
      searchChange(params, done) {
        this.query = params;
        this.page.currentPage = 1;
        this.onLoad(this.page, params);
        done();
      },
      selectionClear() {
        this.selectionList = [];
        this.$refs.crud.toggleSelection();
      },
      currentChange(currentPage){
        this.page.currentPage = currentPage;
      },
      sizeChange(pageSize){
        this.page.pageSize = pageSize;
      },
      refreshChange() {
        this.onLoad(this.page, this.query);
      },
      onLoad(page, params = {}) {
        this.loading = true;
        getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
          const data = res.data.data;
          this.page.total = data.total;
          this.data = data.records;
          this.loading = false;
          this.selectionClear();
        });
      },
    //   updateStoreState(row){
    //     this.$alert('确定修改状态吗？', '确定').then(() => {
    //       updateStoreStates(row.id).then(res => {
    //         if(res.data.success){
    //           if (row.state === 1) {
    //             row.state = 0;
    //             this.$forceUpdate();
    //           }else {
    //             row.state = 1;
    //             this.$forceUpdate()
    //           }
    //           this.$message.success(res.data.msg)
    //         } else {
    //           this.$message.success(res.data.msg)
    //         }
    //       });
    //     })
    //   }
    }
  };
</script>

<style>
</style>

import { getStyleName } from './const-style-name';

export const getStyle = (optionList , option , nameHash) => {
  let styles = {};
  if(optionList){
    (optionList || []).forEach(
      item => {
        let name = getStyleName(nameHash && nameHash[item.code] ? nameHash[item.code] : item.code);
        if(name){
          styles[name] = item.getValue(option ? option[item.code] : (item.defaultValue || item.value));
        }
      }
    )
  };
  return styles;
}
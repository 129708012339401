<template>
  <el-collapse v-model="activeTab">
    <el-collapse-item name="base">
      <div slot="title" class="panel-tab__title">
        <i class="el-icon-info"></i>基本信息
      </div>
      <taskInfo v-if="type === 'Process'" :businessObject="elementBusinessObject"></taskInfo>
      <baseInfo v-else ref="baseInfo" @setTaskInfo="setTaskInfo" :businessObject="elementBusinessObject" :bpmnElement="bpmnElement"/>
    </el-collapse-item>
    <template v-if="type !== 'Process'">
      <el-collapse-item name="base">
        <div slot="title" class="panel-tab__title">
          <i class="el-icon-info"></i> 限制
        </div>
        <condition
            ref="condition"
            :dataSoure="dataSoure"
            :businessObject="elementBusinessObject"
            :bpmnElement="bpmnElement"
            @setOperationList="setOperationList"
          />
      </el-collapse-item>

      <el-collapse-item name="base">
        <div slot="title" class="panel-tab__title">
          <i class="el-icon-info"></i> 操作
        </div>
        <taskOperation
          ref="taskOperation"
          :dataSoure="dataSoure"
          @setTaskOperation="setTaskOperation"
        />
      </el-collapse-item>
    </template>
  </el-collapse>

  <!-- <div class="panel-tab__content" style="padding: 0px">
    <el-form size="mini" label-width="10px" @submit.native.prevent>
      <div class="mar-l-10">基本信息</div>
      <el-form-item size="small">
        <baseInfo ref="baseInfo" @setTaskInfo="setTaskInfo" />
      </el-form-item>
      <div class="mar-l-10">限制</div>
      <el-form-item size="small">
        <condition
          ref="condition"
          :dataSoure="dataSoure"
          @setOperationList="setOperationList"
        />
      </el-form-item>
      <div class="mar-l-10">操作</div>
      <el-form-item size="small">
        <taskOperation
          ref="taskOperation"
          :dataSoure="dataSoure"
          @setTaskOperation="setTaskOperation"
        />
      </el-form-item>
    </el-form>
  </div> -->
</template>

<script>
import condition from "./task-components/condition";
import taskOperation from "./task-components/taskOperation";
import baseInfo from "./task-components/baseInfo";
import taskInfo from './task-components/taskInfo.vue';

export default {
  name: "BusinessTask",
  components: { condition, taskOperation, baseInfo , taskInfo},
  props: {
    id: String,
    type: String,
    bpmnModeler: {
      type: Object,
      default(){
        return null;
      }
    },
    bpmnElement: {
      type: Object,
      default(){
        return null;
      }
    },
    dataSoure: {
      type: Object,
      default() {
        return {};
      },
      immediate: true,
    },
    elementBusinessObject: {
      type: Object,
      default() {
        return {};
      },
      immediate: true,
    },
  },
  data() {
    return {
      activeTab: 'base',
      titleV: ["baseset", "condition", "taskOperation"],
      taskConfigForm: {
        asyncAfter: false,
        asyncBefore: false,
        exclusive: false,
      },
      taskType: "new",
      witchTaskComponent: "",
      installedComponent: {
        // 手工任务与普通任务一致，不需要其他配置
        // 接收消息任务，需要在全局下插入新的消息实例，并在该节点下的 messageRef 属性绑定该实例
        // 发送任务、服务任务、业务规则任务共用一个相同配置
        UserTask: "UserTask", // 用户任务配置
        ScriptTask: "ScriptTask", // 脚本任务配置
        ReceiveTask: "ReceiveTask", // 消息接收任务
      },
      currentTask: {
        taskId: "",
        taskKey: "",
        taskName: "",
        taskType: "RDBMS", // orm kafaka mysql
        condition: {},
        process: {},
      },
    };
  },
  watch: {
    id: {
      immediate: true,
      handler() {
        if (window.bpmnInstances) {
          // this.bpmnElement = window.bpmnInstances.bpmnElement;
          this.taskConfigForm.asyncBefore =
            this.bpmnElement?.businessObject?.asyncBefore;
          this.taskConfigForm.asyncAfter =
            this.bpmnElement?.businessObject?.asyncAfter;
          this.taskConfigForm.exclusive =
            this.bpmnElement?.businessObject?.exclusive;
        }
      },
    },
    type: {
      immediate: true,
      handler() {
        this.witchTaskComponent = this.installedComponent[this.type];
      },
    },
    elementBusinessObject: {
      immediate: true,
      deep: true,
      handler(val) {
        this.changeOptions();
      },
    },
  },
  created(){

  },
  mounted(){
    console.log(this);
  },
  methods: {
    changeOptions() {
      let taskList = JSON.parse(localStorage.getItem("taskListForPros"));
      if (taskList) {
        this.currentTask = {
          expression: "",
          taskId: "",
          taskKey: "",
          taskName: "",
          taskType: "RDBMS", // orm kafaka mysql
          condition: {},
          process: {},
        };
        taskList.forEach((task, index) => {
          if (task.taskId == this.elementBusinessObject.id) {
            this.currentTask = taskList[index];
            let process = JSON.stringify(taskList[index].process);
            let t = this;
            setTimeout(() => {
              // this.$nextTick(() => {
              if (t.$refs.taskOperation) {
                t.$refs.taskOperation.modelForm.filterCriteria =
                  JSON.parse(process).filterCriteria;
                t.$refs.taskOperation.modelForm.fields =
                  JSON.parse(process).fields;
                t.$refs.taskOperation.modelForm.model =
                  JSON.parse(process).model;
                //  t.$refs.taskOperation.modelForm.process = t.currentTask.process;
              }
              if (t.$refs.condition) {
                t.$refs.condition.modelForm.operationList =
                  t.currentTask.condition.operationList;

                t.$refs.condition.modelForm.expression =
                  t.currentTask.condition.expression;
              }
              if (t.$refs.baseInfo) {
                t.$refs.baseInfo.infoForm.taskName =
                  t.elementBusinessObject.name;
              }
              // });
            }, 500);
          }
        });
      } else {
        let t = this;
        setTimeout(() => {
          if (t.$refs.baseInfo) {
            t.$refs.baseInfo.infoForm.taskName = t.elementBusinessObject.name;
          }
        }, 1000);
      }
    },
    setTaskInfo(obj) {
      this.currentTask.taskName = obj.taskName;
      this.currentTask.taskKey = obj.taskKey;
      //同步更改当前节点的lable信息
      if (obj.taskName != "") {
        const elementRegistry = this.BpmnMain.modeler.get("elementRegistry");
        const modeling = this.BpmnMain.modeler.get("modeling");
        let ele = elementRegistry.get(this.elementBusinessObject.id);
        modeling.updateLabel(ele, obj.taskName);
      }

      this.getCurrentData();
    },
    setOperationList(list) {
      this.currentTask.condition = list;
      this.getCurrentData();
    },
    setTaskOperation(tasks) {
      this.currentTask.process = tasks;
      this.getCurrentData();
    },
    getCurrentData() {
      let taskList = JSON.parse(localStorage.getItem("taskListForPros")) || [];
      if (taskList) {
        this.currentTask.taskId = this.elementBusinessObject.id;
        if (taskList.length == 0) {
          taskList.push(this.currentTask);
        } else {
          let isPush = false;
          taskList.forEach((task, index) => {
            if (task.taskId == this.elementBusinessObject.id) {
              taskList[index] = Object.assign(
                taskList[index],
                this.currentTask
              );
              isPush = true;
            }
          });
          if (!isPush) {
            taskList.push(this.currentTask);
          }
        }
        localStorage.setItem("taskListForPros", JSON.stringify(taskList));
        // this.changeOptions();
      }
    },
    changeTaskAsync() {
      if (!this.taskConfigForm.asyncBefore && !this.taskConfigForm.asyncAfter) {
        this.taskConfigForm.exclusive = false;
      }
      window.bpmnInstances.modeling.updateProperties(
        window.bpmnInstances.bpmnElement,
        {
          ...this.taskConfigForm,
        }
      );
    },
  },
};
</script>

<template>
  <basic-container
    isCard
    :body-style="{ padding: '0', height: '100%' }"
    class="h-100p"
  >
    <el-container class="h-100p">
      <el-header height="50px" class="lh-50 bor-b" v-if="!isTodo">
        <basic-page-header
          @back="back"
          class="lh-50"
          :title="'会员卡申请'"
        ></basic-page-header>
      </el-header>
      <el-container style="height: calc(100% - 50px)" class="pad-20">
        <el-main class="ov-a">
          <el-descriptions
            title="会员卡申请"
            :column="4"
            :colon="false"
            direction="vertical"
            :labelStyle="labelStyle"
          >
            <el-descriptions-item label="会员称呼">{{
              cardForm.vipName
            }}</el-descriptions-item>
            <el-descriptions-item label="会员卡号">
              {{ cardForm.cardNo }}
            </el-descriptions-item>
            <el-descriptions-item label="会员卡名称">
              {{ cardForm.cardName }}
            </el-descriptions-item>
            <el-descriptions-item
              label="业务类型"
              v-if="cardForm.bizType == 'COD'"
            >
              会议
            </el-descriptions-item>
            <el-descriptions-item
              label="业务类型"
              v-if="cardForm.bizType == 'SPT'"
            >
              文体
            </el-descriptions-item>
            <el-descriptions-item
              label="业务类型"
              v-if="cardForm.bizType == 'FOD'"
            >
              餐食
            </el-descriptions-item>
            <el-descriptions-item label="卡类型" v-if="cardForm.timeType == 0">
              普通卡
            </el-descriptions-item>
            <el-descriptions-item label="卡类型" v-if="cardForm.timeType == 1">
              年卡
            </el-descriptions-item>
            <el-descriptions-item label="卡类型" v-if="cardForm.timeType == 2">
              半年卡
            </el-descriptions-item>
            <el-descriptions-item label="卡类型" v-if="cardForm.timeType == 3">
              季卡
            </el-descriptions-item>
            <el-descriptions-item label="卡类型" v-if="cardForm.timeType == 4">
              月卡
            </el-descriptions-item>
            <el-descriptions-item label="场地类型" v-if="cardForm.typeId == 0">
              通卡
            </el-descriptions-item>
            <el-descriptions-item label="场地类型" v-if="cardForm.typeId != 0">
              {{ cardForm.typeName }}
            </el-descriptions-item>
            <el-descriptions-item
              label="会员卡类型"
              v-if="cardForm.cardType == 1"
            >
              计时
            </el-descriptions-item>
            <el-descriptions-item
              label="会员卡类型"
              v-if="cardForm.cardType == 2"
            >
              计次
            </el-descriptions-item>
            <el-descriptions-item label="卡面额/次数">
              {{ cardForm.faceValue }}
            </el-descriptions-item>
            <el-descriptions-item label="赠送面额/次数">
              {{ cardForm.giftValue }}
            </el-descriptions-item>
            <el-descriptions-item label="总面额/次数">
              {{ cardForm.balanceValue }}
            </el-descriptions-item>
            <el-descriptions-item
              label="会员卡状态"
              v-if="cardForm.status == 0"
            >
              已退卡
            </el-descriptions-item>
            <el-descriptions-item
              label="会员卡状态"
              v-if="cardForm.status == 1"
            >
              有效卡
            </el-descriptions-item>
            <el-descriptions-item
              label="会员卡状态"
              v-if="cardForm.status == 2"
            >
              制卡申请
            </el-descriptions-item>
            <el-descriptions-item
              label="会员卡状态"
              v-if="cardForm.status == 3"
            >
              退卡申请
            </el-descriptions-item>
            <el-descriptions-item label="申请时间" :span="2">
              {{ cardForm.createTime }}
            </el-descriptions-item>
            <el-descriptions-item label="备注" :span="6">
              {{ cardForm.memo }}
            </el-descriptions-item>
          </el-descriptions>
        </el-main>
      </el-container>
    </el-container>
    <RichText style="display: none"></RichText>
  </basic-container>
</template>

<script>
import standardService from "@/api/metadata/standard";
import { submit } from "@/api/visitor/visitor";
import { mapGetters, mapState } from "vuex";
import * as dictbizService from "@/api/system/dictbiz";
import { getDetailById } from "@/api/spcod/spcodaccountcard";
import { getTaskInfo } from "@/api/flow/flow.js";

export default {
  data() {
    return {
      ruleModel: {},
      labelStyle: {
        color: "#999",
        fontSize: "12px",
      },
      cardTypeName: "",
      option1: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,
        disabled: true,
        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,
            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      option: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,
            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      imgform: {
        imgUrl: [],
      },
      cardForm: {},
      params: {
        zoom: 4,
        zoomEnable: false,
        dragEnable: true,
      },
      ruleModel: {},
      companyList: [],
      model: "wisdom_park.wp_publish_schedule",
      programModel: "wisdom_park.wp_publish_program",
      cardTypeList: [],
    };
  },
  computed: {},
  mounted() {
    console.log(this.id);
    if (this.$router.currentRoute.query.id) {
      this.getCardInfo();
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    isTodo: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        console.log("watch:", val);
        //this.getCardInfo();
      },
    },
  },
  methods: {
    toHump(name) {
      return name.replace(/\_(\w)/g, function (all, letter) {
        return letter.toUpperCase();
      });
    },
    getCardInfo() {
      let _this = this;
      getDetailById(this.id).then((res) => {
        if (res.data.code == 200) {
          _this.cardForm = res.data.data;
        }
      });
    },
    submit() {
      return new Promise((resolve) => {
        this.$refs["cardForm"].validate((valid) => {
          if (valid) {
            resolve({
              valid: true,
              form: this.cardForm,
            });
          } else {
            let fields = this.$refs["cardForm"].fields,
              firstError = {
                field: "",
                message: "",
                name: "",
              };
            for (let i = 0; i < fields.length; i++) {
              if (fields[i].validateState === "error") {
                firstError.message = fields[i].validateMessage;
                firstError.field = fields[i].prop;
                firstError.name = fields[i].label;
                break;
              }
            }
            resolve({
              valid: false,
              form: this.cardForm,
              error: firstError,
            });
          }
        });
      });
    },
    back() {
      this.$router.push({
        path: "/information/publish",
      });
    },
    save() {
      Promise.all([this.submit()]).then((forms) => {
        //照片
        if (this.imgform) {
          let imgList = [];
          this.imgform.imgUrl.forEach((e) => {
            if (e.label) {
              imgList.push(e.label);
            }
          });
          this.cardForm.attachment_id = imgList.toString();
        }
        if (this.cardForm.id) {
          let o = {
            update: this.cardForm,
            primaryKeyValue: this.cardForm["id"],
            primaryKey: "id",
          };
          submit(this.cardForm).then((res) => {
            if (res.data.code == 200) {
              this.$router.push({
                path: "/visitor/index",
              });
            }
          });
        } else {
          submit(this.cardForm).then((res) => {
            if (res.data.code == 200) {
              this.$router.push({
                path: "/visitor/index",
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.contenCon {
  display: none;
  width: 0 !important;
  height: 0 !important;
}

.contenCon .el-form-item__content {
  display: none;
  width: 0 !important;
  height: 0 !important;
}

/deep/ .el-divider--horizontal {
  margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
  left: 0px;
}
/deep/ .el-divider__text {
  padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
  width: 68px;
  height: 68px;
  line-height: 72px;
  vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
}
.btnClass {
  display: flow-root;
  height: 50px;
  line-height: 50px;
}
/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
/deep/ .el-select {
  width: 100%;
}
</style>

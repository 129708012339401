<template>
  <el-row class="mar-t-15f">
    <el-col :span="24">
      <el-table :data="selectRoomList" size="small" class="searchTable">
        <el-table-column label="" width="200px">
          <template slot-scope="scope">
            <el-select
              class="dis-b"
              size="small"
              clearable
              @clear="resetBuild"
              placeholder="选择楼宇"
              v-model="scope.row.building"
              @change="
                roomSelectChange(scope.row, 'building');
                getFloor(scope.$index);
              "
            >
              <el-option
                v-for="item of dictData.building"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column label="楼层" width="200px">
          <template slot-scope="scope">
            <el-select
              class="dis-b"
              size="small"
              clearable
              @clear="resetFloor"
              placeholder="选择楼层"
              v-model="scope.row.floor"
              @change="getRoom(scope.$index)"
            >
              <el-option
                v-for="item of scope.row.floorData"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
  </el-row>
</template>

<script>
import { getMetaCommonList } from "@/api/busiMode/metaCommon";
export default {
  props: {
    ruleModel: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      selectRoomList: [
        {
          building: "",
          buildingName: "",
          floor: "",
          floorName: "",
          room: [],
          roomSelected: [],
          floorData: [],
          roomData: [],
        },
      ],
      dictData: {
        contract_attribute: [],
        offer_type: [],
        payment_type: [],
        building: [],
        staff: [],
        enterprise: [],
      },
    };
  },
  mounted() {
    this.getBuildList();
  },
  computed: {},
  methods: {
    getMetaCommonList(code, prop, params) {
      return getMetaCommonList(`wisdom_park.${code}`, {
        current: 1,
        size: 500,
        ...params,
      }).then((res) => {
        if (res.data && res.data.data && prop) {
          this.dictData[prop] = res.data.data.records;
        }
        return res;
      });
    },
    getBuildList() {
      getMetaCommonList(`wisdom_park.wp_building`, {
        current: 1,
        size: 500,
      }).then((res) => {
        if (res.data && res.data.data) {
          this.dictData["building"] = res.data.data.records;
        }
      });
    },
    getFloor(index) {
      let building = this.selectRoomList[index].building;
      this.selectRoomList[index].floor = "";
      this.selectRoomList[index].floorData = [];
      if (building) {
        this.getMetaCommonList("wp_floor", null, {
          wheres: [{ field: "building_id", value: building }],
          orders: [
            { field: "above_under", sort: "desc" },
            { field: "code", sort: "asc" },
          ],
        }).then((res) => {
          if (res.data && res.data.data) {
            let floorData = res.data.data.records.sort((curr, next) => {
              if (curr["above_under"] === "under") {
                return parseInt(next.code) - parseInt(curr.code);
              } else {
                return 0;
              }
            });
            floorData = floorData.sort((curr, next) => {
              if (curr["above_under"] === "above") {
                return parseInt(curr.code) - parseInt(next.code);
              } else {
                return 0;
              }
            });
            this.selectRoomList[index].floorData = floorData.map((item) => {
              return {
                ...item,
                name: `${item["above_under"] === "under" ? "负" : ""}${parseInt(
                  item.code
                )}层`,
              };
            });
          }
          this.$emit("selectRoomList", this.selectRoomList[0]);
        });
      }
    },
    getRoom(index) {
      // let building = this.selectRoomList[index].building,
      //   floor = this.selectRoomList[index].floor;
      // this.selectRoomList[index].roomData = [];
      // this.selectRoomList[index].room = [];
      // if (building && floor) {
      //   this.getMetaCommonList("wp_room", null, {
      //     wheres: [
      //       { field: "building_id", value: building },
      //       { field: "floor_id", value: floor },
      //     ],
      //   }).then((res) => {
      //     if (res.data && res.data.data) {
      //       this.selectRoomList[index].roomData = res.data.data.records;
      //     }
      //   });
      // }
      this.$emit("selectRoomList", this.selectRoomList[0]);
    },
    resetBuild() {
      this.selectRoomList[0].building == "";
      this.selectRoomList[0].floor == "";
      this.$emit("selectRoomList", this.selectRoomList[0]);
    },
    resetFloor() {
      this.selectRoomList[0].floor == "";
      this.$emit("selectRoomList", this.selectRoomList[0]);
    },
    roomSelectChange(row, name) {
      let data = [];
      if (name === "building") {
        data = this.dictData.building;
      } else {
        data = row[`${name}Data`] || [];
      }
      if (data) {
        if (name !== "room") {
          data.forEach((item) => {
            if (item.id === row[name]) {
              row[`${name}Name`] = item.name;
            }
          });
        } else {
          this.calculateRoom();
        }
      }
    },
    calculateRoom() {
      let roomSelected = [];
      this.selectRoomList.forEach((itemRoom) => {
        itemRoom.room.forEach((itemRoomId) => {
          let currentRoom = itemRoom.roomData.find(
            (item) => item.id === itemRoomId
          );
          let currentBuilding = this.dictData.building.find(
            (item) => item.id === itemRoom.building
          );
          let oldRoomItem =
            this.roomSelected.find((item) => item.room_id === itemRoomId) || {};
          if (currentRoom && currentBuilding) {
            let roomObj = {
              building_id: itemRoom.building,
              building_name: itemRoom.buildingName,
              floor_id: itemRoom.floor,
              floor_name: itemRoom.floorName,
              room_id: itemRoomId,
            };
            roomSelected.push(roomObj);
          }
        });
      });
      this.roomSelected = roomSelected;
      this.calculateAmount();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
  margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
  left: 0px;
}
/deep/ .el-divider__text {
  padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
  width: 68px;
  height: 68px;
  line-height: 72px;
  vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
}

.searchTable /deep/ .el-table__header-wrapper {
  display: none;
}
.searchTable /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
  background-color: #fff;
}
</style>

import { uuid , assign } from '../util';

export class DataViewDashboard{

  widgets = [];
  id = null;
  
  props = {
    colNum: 100,
    rowHeight: 10,
    maxRows: Infinity,
    margin: [10, 10],
    isDraggable: true,
    isResizable: true,
    isMirrored: false,
    autoSize: true,
    verticalCompact: true,
    preventCollision: false,
    useCssTransforms: true,
    responsive: false,
    useStyleCursor: true
  };

  variables = [];

  constructor(dashboardJson){
    dashboardJson = dashboardJson || {};
    this.id = dashboardJson.id;
    this.widgets = dashboardJson.widgets || [];
    this.variables = dashboardJson.variables || [];
    assign(this.props , dashboardJson.props || {} , true);
  }



}
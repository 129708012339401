<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="6" :style="{ height: viewPortHeight - 115 + 'px' }">
        <basic-container isCard class="h-100p ov-a">
          <!-- 新增节点和修改节点 -->
          <div class="btnBottom">
            <el-button
              circle
              :type="btnType"
              size="small"
              icon="el-icon-plus"
              @click="openTreeList('add')"
            ></el-button>
            <el-button
              circle
              :type="btnType"
              size="small"
              icon="el-icon-edit"
              @click="openTreeList('upd')"
            ></el-button>
            <el-button
              circle
              :type="delBtnType"
              size="small"
              icon="el-icon-delete"
              @click="delTreeNode()"
            ></el-button>
          </div>
          <div class="grid-content bg-purple">
            <!-- <div class="filter-con">
                <el-input v-model="treeFilterText" clearabled></el-input>
                <el-button type="primary" @click="refreshTree" size="mini" class="is-circle">
                  <i class="el-icon-refresh"></i>
                </el-button>
              </div> -->
            <el-tree
              :data="treeData"
              :props="defaultProps"
              node-key="id"
              default-expand-all
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              @node-click="getTreeDetail"
              ref="tree"
            >
            </el-tree>
          </div>
        </basic-container>
      </el-col>
      <el-col :span="18" :style="{ height: viewPortHeight - 115 + 'px' }">
        <el-card class="h-100p">
          <el-form
            :model="ruleForm"
            status-icon
            ref="ruleForm"
            label-width="120px"
            class="demo-ruleForm"
            v-show="ruleForm"
          >
            <el-form-item label="分类编码：">
              <el-input
                type="text"
                v-model="ruleForm.code"
                autocomplete="off"
                style="width: 85%"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="分类名称：">
              <el-input
                type="text"
                v-model="ruleForm.name"
                autocomplete="off"
                style="width: 85%"
                disabled
              ></el-input>
            </el-form-item>
            <el-form-item label="分类图标：">
              <el-image
                style="width: 100px; height: 100px"
                :src="ruleForm.iconUrl"
                fit="contain"
              ></el-image>
            </el-form-item>
            <!-- <el-form-item label="是否叶子：" >
              <el-checkbox  name="type" v-model="ruleForm.isLeaf" disabled></el-checkbox>
              </el-form-item> -->
            <el-form-item label="分类备注：">
              <el-input
                type="textarea"
                v-model="ruleForm.memo"
                autocomplete="off"
                style="width: 85%"
                disabled
              ></el-input>
            </el-form-item>
          </el-form>
        </el-card>
      </el-col>
    </el-row>
    <!-- 修改节点方法 -->
    <el-dialog
      title="修改"
      :visible.sync="upDialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <el-form
        :model="addForm"
        status-icon
        ref="treeForm"
        size="20px"
        label-width="120px"
        class="demo-ruleForm"
        v-show="ruleForm"
        :rules="treeRules"
      >
        <el-form-item label="分类编码：" prop="code">
          <el-input
            type="text"
            v-model="addForm.code"
            autocomplete="off"
            style="width: 40%"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类名称：" prop="name">
          <el-input
            type="text"
            v-model="addForm.name"
            autocomplete="off"
            style="width: 40%"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类图标：" size="10px">
          <avue-form :option="option" v-model="addForm"></avue-form>
        </el-form-item>
        <!-- <el-form-item label="分类轮播图：" size="10px" >
              <el-upload action="" :http-request="uploadFileHandle" list-type="picture-card" :file-list="fileList"></el-upload>
            </el-form-item> -->
        <el-form-item label="分类备注：" prop="memo">
          <el-input
            type="textarea"
            v-model="addForm.memo"
            autocomplete="off"
            style="width: 85%"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="addTreeList">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 修改节点方法 -->
    <el-dialog
      title="新增"
      :visible.sync="addDialogVisible"
      width="70%"
      :before-close="handleClose"
    >
      <el-form
        :model="addForm"
        status-icon
        ref="treeForm"
        size="20px"
        label-width="120px"
        class="demo-ruleForm"
        v-show="ruleForm"
        :rules="treeRules"
      >
        <el-form-item label="分类编码：" prop="code">
          <el-input
            type="text"
            v-model="addForm.code"
            autocomplete="off"
            style="width: 40%"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类名称：" prop="name">
          <el-input
            type="text"
            v-model="addForm.name"
            autocomplete="off"
            style="width: 40%"
          ></el-input>
        </el-form-item>
        <el-form-item label="分类图标：" size="10px">
          <avue-form :option="option" v-model="addForm"></avue-form>
        </el-form-item>
        <!--<el-form-item label="分类轮播图：" size="10px" >
              <el-upload action="" :http-request="uploadFileHandle" list-type="picture-card" :file-list="fileList"></el-upload>
            </el-form-item> -->
        <el-form-item label="分类备注：" prop="memo">
          <el-input
            type="textarea"
            v-model="addForm.memo"
            autocomplete="off"
            style="width: 85%"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="addTreeList">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
// import { uploadFile } from '@/api/resource/file.js'
import {
  getTreeListAPI,
  addTreeListAPI,
  updTreeListAPI,
  delCatgNode,
} from "@/api/spcod/prncatg.js";
// import totree from '@/util/transformToTree.js'

const viewPortHeight =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

export default {
  data() {
    return {
      viewPortHeight: viewPortHeight,
      treeFilterText: "",
      //树结构数据
      treeData: [],
      defaultProps: {
        children: "subs",
        label: "name",
      },
      // 右侧表单对象
      ruleForm: {
        code: "",
        iconUrl: "",
        memo: "",
        name: "",
        isLeaf: false,
      },
      // 按钮颜色
      btnType: "info",
      delBtnType: "info",
      // 弹出框标志位
      addDialogVisible: false,
      upDialogVisible: false,
      // 新增节点表单对象
      addForm: {
        code: "",
        files: "",
        memo: "",
        name: "",
      },
      // 表单校验对象
      treeRules: {
        // code:[{required:false,message:'分类编码不能为空',trigger:'blur'}],
        name: [
          { required: true, message: "分类名称不能为空", trigger: "blur" },
        ],
        memo: [
          { required: false, message: "分类备注不能为空", trigger: "blur" },
        ],
      },
      option: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "",
            prop: "files",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,
            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file",
          },
        ],
        // 点击按钮的类型
        type: "add",
      },
      fileList: [],
    };
  },
  watch: {
    treeFilterText(value) {
      this.$refs.tree.filter(value);
    },
  },
  methods: {
    refreshTree() {
      this.treeFilterText = "";
    },
    uploadFileHandle() {},
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getTreeDetail(data) {
      this.ruleForm = { ...data, isLeaf: data.isLeaf == "Y" ? true : false };
      localStorage.setItem("treeData", JSON.stringify(data));
      this.btnType = "primary";
      if (data.isLeaf == "Y") this.delBtnType = "primary";
      else this.delBtnType = "info";
    },
    // 获取树形结构数据
    async getTreeList() {
      const res = await getTreeListAPI();
      this.treeData = res.data.data;
    },
    delTreeNode() {
      if (this.delBtnType == "info") return;
      let _this = this;
      const res = JSON.parse(localStorage.getItem("treeData"));
      if (res.isLeaf != "Y") return;
      this.$confirm("确定删除分类节点？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then((res2) => {
        //修改状态
        delCatgNode(res.id).then((delres) => {
          if (delres.data.success) {
            this.$message({
              type: "success",
              message: delres.data.msg,
            });
          } else {
            this.$message({
              type: "error",
              message: delres.data.msg,
            });
          }
          _this.getTreeList();
        });
      });
    },
    openTreeList(data) {
      if (this.btnType == "info") return;
      this.addForm.iconUrl = "";
      this.addForm = {};
      this.type = data;
      if (data === "upd") {
        this.upDialogVisible = true;
        const res = JSON.parse(localStorage.getItem("treeData"));
        let files = [];
        files.push({ label: "noLabel", value: res.iconUrl });
        this.addForm = {
          code: res.code,
          files: files,
          memo: res.memo,
          name: res.name,
        };
      } else {
        this.addForm = {
          code: "",
          files: [],
          memo: "",
          name: "",
        };
        this.addDialogVisible = true;
      }
    },
    async addTreeList() {
      if (this.type === "add") {
        let res = JSON.parse(localStorage.getItem("treeData"));
        this.addForm = { ...this.addForm, pid: res.id };
        if (this.addForm.files && this.addForm.files.length > 0) {
          this.addForm.iconUrl = this.addForm.files[0].value;
        }
        this.$refs.treeForm.validate(async (valid) => {
          if (!valid) return;
          const ret = await addTreeListAPI(this.addForm);
          if (ret.status === 200) {
            this.getTreeList();
            this.addDialogVisible = false;
            this.upDialogVisible = false;
            this.$refs.treeForm.resetFields();
            this.$refs.treeForm.clearValidate();
            //this.addForm.iconUrl = '';
          } else {
            this.$message.error("新增节点失败！");
          }
        });
      } else {
        let td = JSON.parse(localStorage.getItem("treeData"));
        this.addForm = { ...this.addForm, id: td.id };
        if (this.addForm.files && this.addForm.files.length > 0) {
          this.addForm.iconUrl = this.addForm.files[0].value;
        }
        this.$refs.treeForm.validate(async (valid) => {
          if (!valid) return;
          const ret = await updTreeListAPI(this.addForm);
          if (ret.status === 200) {
            this.getTreeList();
            this.addDialogVisible = false;
            this.upDialogVisible = false;
            this.$refs.treeForm.resetFields();
            this.$refs.treeForm.clearValidate();
            this.addForm.iconUrl = "";
          } else {
            this.$message.error("修改节点失败！");
          }
        });
      }
    },
    handleClose() {
      this.$refs.treeForm.resetFields();
      this.$refs.treeForm.clearValidate();
      // localStorage.removeItem('updData')
      // localStorage.removeItem('treeData')
      this.addDialogVisible = false;
      this.upDialogVisible = false;
    },
    handleAvatarSuccess(res) {
      this.ruleForm.logoUrl = res.data.url;
      this.$message({
        message: "图片上传成功",
        type: "success",
      });
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isLt20M = file.size / 1024 / 1024 < 20;

      if (!isJPG) {
        this.$message.error("上传头像图片只能是 JPG 格式!");
      }
      if (!isLt20M) {
        this.$message.error("上传头像图片大小不能超过 20MB!");
      }
      return isJPG && isLt20M;
    },
    handleUploadError() {
      this.$message({
        type: "error",
        message: "上传失败",
      });
    },
  },

  mounted() {
    this.getTreeList();
  },
};
</script>
<style lang="scss" scoped>
/deep/.el-tree-node.is-current > .el-tree-node__content {
  color: #409eff;
  background-color: #f5f7fa;
}
/deep/ .el-tree-node__content:hover {
  background-color: transparent;
}
.el-row {
  margin-bottom: 20px;
}
.el-col {
  border-radius: 4px;
}
.bg-purple-dark {
  background: #fff;
}
.bg-purple {
  background: #fff;
}
.bg-purple-light {
  background: #e5e9f2;
}
.grid-content {
  border-radius: 4px;
  min-height: 36px;
}
.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.treeborder {
  border: 1px solid #eee;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  line-height: 150px;
  text-align: center;
}
.avatar {
  width: 150px;
  height: 150px;
  display: block;
}
.btnBottom {
  margin-bottom: 10px;
}
.filter-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  .is-circle {
    margin-left: 5px;
  }
}
</style>

import { transformVar } from '../../data-view/util/index';
export const StaticDataService = {
  type: 'staticData',
  getData(widget , dash){
    return new Promise((resolve , reject) => {
      if(widget){
        let { staticData , staticDataType } = widget.public.dataConfig.option; 
        let data = null , columns = [];
        if(staticDataType){
          if(staticDataType.toLocaleLowerCase() === 'json'){
            try {
              data = JSON.parse(transformVar(staticData , widget , dash , null));
            } catch (error) {
              data = null;
            }
          }else{
            data = staticData;
          }
        }
        if(typeof data === 'object'){
          let tempData = null;
          if(Array.isArray(data)){
            tempData = data.length ? data[0] : null
          }else{
            tempData = data;
          }
          columns = Object.keys(tempData).map(item => {
            return {
              name: item,
              value: item
            }
          });
        }
        return resolve({
          columns: columns,
          data: data,
          isError: false
        });
      }
    })
  }
}

export const RedashService = (service) =>{
  const getJoB = (id , successFn , errorFn) => {
    service.jobs(id).then(
      res => {
        if(res.data){
          if(res.data.job.error){
            errorFn(res.data.job)
          }else if(res.data.job.status === 3){
            successFn(res.data.job);
          }else if(res.data.job.status < 3){
            getJoB(id , successFn , errorFn)
          }
        }
      }
    )
  }
  return {
    type: 'RedashDatasource',
    getData(widget , dash){
      return new Promise((resolve , reject) => {
        let option = widget.public.dataConfig.option || null;
        if(option && option.id && option.queryString){
          service.query_results({
            data_source_id: option.id,
            query: transformVar(option.queryString , widget , dash , null),
            max_age: 0,
            parameters: {}
          }).then(
            res => {
              let job = res.data.job || null;
              if(job){
                getJoB(job.id , (job) => {
                  service.results(job.query_result_id).then(
                    res => {
                      let query_result = res.data.query_result || null;
                      let results = {
                        columns: [],
                        data: [],
                        isError: false
                      }
                      if(query_result){
                        query_result.data.columns.forEach(
                          item => {
                            results.columns.push({
                              name: item.friendly_name,
                              value: item.name,
                              desc: item.type
                            })
                          }
                        )
                        results.data = query_result.data.rows;
                      }
                      resolve(results);
                    }
                  )
                } , (job) => {
                  resolve({
                    isError: true,
                    columns: [],
                    data: [],
                    error: job.error || ''
                  })
                })
              }
            }
          )
        }
      })
    }
  }
}
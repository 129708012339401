<template>
  <basic-container>
    <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" :page.sync="page" @on-load="onLoad" @search-change="searchChange"> </avue-crud>
  </basic-container>
</template>

<script>
import { option } from "./optionSub.js"
import { getMetaCommonList } from "@/api/busiMode/metaCommon"
import { mapState } from "vuex"

export default {
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.code_add, false),
        viewBtn: this.vaildData(this.permission.code_view, false),
        delBtn: this.vaildData(this.permission.code_delete, false),
        editBtn: this.vaildData(this.permission.code_edit, false),
      }
    },
  },
  data() {
    return {
      query: {},
      option: {
        ...option,
        cancelBtn: false,
        searchShowBtn: false,
        refreshBtn: false,
        menu: false,
        index: true,
        searchMenuPosition: "right",
        column: [
          {
            overHidden: true,
            label: "企业名称",
            align: "left",
            prop: "renter_company_name",
            hide: false,
            search: true,
          },
          {
            label: "入驻房间",
            aligin: "left",
            prop: "renter_rooms",
            search: false,
            search: true,
          },
        ],
      },
      loading: true,
      data: [],
      vrDrawer: false,
      page: {
        currentPage: 1,
        total: 10,
        pageSize: 10,
      },
      searchText: "",
      detailDia: false,
      title: "",
      drawer: false,
    }
  },

  methods: {
    /** 加载数据 */
    onLoad(page, params = {}) {
      console.log(this.userInfo)
      let b = {
        current: page.currentPage,
        size: page.pageSize,
        wheres: [{ field: "sublessor_company_id", value: this.userInfo.companyId }],
      }
      Object.keys(params).forEach((item) => {
        b.wheres.push({ field: item, op: "LIKE", value: params[item] })
      })

      getMetaCommonList("wisdom_park.v_wp_sublessor_renter_company", b).then(({ data }) => {
        if (data.success) {
          this.data = data.data.records
          this.page.total = data.data.total
          this.loading = false
        }
      })
    },

    /**搜索事件 */
    searchChange(params, done) {
      this.page.currentPage = 1
      this.onLoad(this.page, params)
      done()
    },

    /** 清空事件 */
    searchReset() {},
  },
}
</script>
<style lang="scss" scoped>
/deep/ .avue-crud__pagination .el-pagination {
  display: inline-block !important;
}
</style>

<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="5" :style="{ height: viewPortHeight - 115 + 'px' }">
        <!-- <el-card> -->
        <basic-container isCard class="h-100p">
          <div class="grid-content bg-purple">
            <!-- <div class="filter-con">
              <el-input v-model="treeFilterText" clearabled></el-input>
              <el-button type="primary" @click="treeFilterText=''" size="mini" class="is-circle">
                <i class="el-icon-refresh"></i>
              </el-button>
            </div> -->
            <el-tree
              :data="treeData"
              :props="defaultProps"
              node-key="id"
              default-expand-all
              :expand-on-click-node="false"
              :filter-node-method="filterNode"
              class="treeborder"
              @node-click="getTreeDetail"
              ref="tree"
            >
            </el-tree>
          </div>

          <el-dialog
            :title="dialogTitle"
            :visible.sync="dialogVisible"
            v-if="dialogVisible"
            width="50%"
            append-to-body
          >
            <avue-form
              :option="formAuditOption"
              @submit="aduitSubmit"
              v-model="formAudit"
            ></avue-form>
          </el-dialog>
          <RichText style="display: none"></RichText>
        </basic-container>
      </el-col>
      <el-col :span="19">
        <avue-crud
          :option="option"
          :table-loading="loading"
          :data="data"
          :page.sync="page"
          :before-open="beforeOpen"
          :upload-before="uploadBefore"
          :upload-after="uploadAfter"
          v-model="form"
          ref="crud"
          @row-update="rowUpdate"
          @row-save="rowSave"
          @row-del="rowDel"
          @search-change="searchChange"
          @search-reset="searchReset"
          @current-change="currentChange"
          @size-change="sizeChange"
          @refresh-change="refreshChange"
          @on-load="onLoad"
        >
          <template slot="menuLeft">
            <el-button
              type="primary"
              size="small"
              icon="el-icon-add"
              plain
              @click="addHandle"
              >新 增
            </el-button>
          </template>

          <template slot-scope="scope" slot="menu">
            <template v-if="scope.row.status == 1">
              <el-button
                type="text"
                size="small"
                icon="el-icon-delete-solid"
                class="none-border"
                @click="doCheckRemove(scope.row.id)"
                >删除
              </el-button>
              <el-button
                type="text"
                size="small"
                icon="el-icon-edit"
                class="none-border"
                @click="operEdit(scope.row.id)"
                >编辑
              </el-button>
              <!--
            <el-button
              type="text"
              size="small"
              icon="el-icon-share"
              class="none-border"
              @click="openAddListDetailValue(scope.row.id)"
              >通知企业
            </el-button>-->
              <el-button
                type="text"
                size="small"
                icon="el-icon-top-right"
                class="none-border"
                @click="getoAudit(scope.row)"
                >提交审核
              </el-button>
            </template>

            <template v-if="scope.row.status == 200">
              <el-button
                type="text"
                size="small"
                icon="el-icon-edit"
                class="none-border"
                @click="operPolicyStatus('确定审核通过?', scope.row, 3)"
                >审核通过
              </el-button>
              <el-button
                type="text"
                size="small"
                icon="el-icon-delete"
                class="none-border"
                @click="operPolicyStatus('确定审核退回?', scope.row, 4)"
                >审核退回
              </el-button>
            </template>

            <template v-if="scope.row.status == 3">
              <el-button
                type="text"
                size="small"
                icon="el-icon-download"
                class="none-border"
                @click="operPolicyOffshoreDialog(scope.row)"
                >下架
              </el-button>
            </template>
            <template v-if="scope.row.status == 4">
              <el-button
                type="text"
                size="small"
                icon="el-icon-edit"
                class="none-border"
                @click="operEdit(scope.row.id)"
                >重新编辑
              </el-button>
            </template>
            <template v-if="scope.row.status == 5">
              <el-button
                type="text"
                size="small"
                icon="el-icon-delete-solid"
                class="none-border"
                @click="doCheckRemove(scope.row.id)"
                >删除
              </el-button>
            </template>
            <!--<el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              class="none-border"
              @click="getoAudit(scope.row)"
              >审核
            </el-button>-->
          </template>

          <template slot="detailsForm">
            <div class="title">{{ form.title }}</div>
            <div class="ttime">{{ form.releaseTime }}</div>
            <div class="ov-a" v-if="showEdt" v-html="form.content1"></div>
            <!-- <div  class="content ql-editor" v-html="editor(form.content)"></div> -->
          </template>
        </avue-crud>
      </el-col>
      <el-drawer
        title="添加企业通知"
        :visible.sync="drawer"
        append-to-body
        size="1000px"
        :withHeader="false"
        class="enterpriseForm"
      >
        <el-container class="h-100p">
          <el-header class="bor-b" height="50px">
            <basic-page-header
              class="lh-50"
              @back="drawer = false"
              title="添加企业通知"
            ></basic-page-header>
          </el-header>
          <el-main class="ov-a">
            <div class="pad-15 search-table">
              <avue-crud
                :search.sync="searchForm"
                :data="searchTableData"
                :option="searchAssetsOption"
                ref="crud"
                :table-loading="searchTableLoading"
                @search-change="handleSearchSubmit"
                @selection-change="searchTableSelection"
              ></avue-crud>
              <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="closeAddListDetailValue()"
                  >关 闭</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  @click="addEnterpriseNotify()"
                  >添 加</el-button
                >
              </span>
            </div>
          </el-main>
        </el-container>
      </el-drawer>
    </el-row>
  </div>
</template>

<script>
import {
  getList,
  getPage,
  getDetail,
  add,
  update,
  remove,
  aduitPolicy,
  addCorpNofity,
  getCorpNofityPage,
  doCheckRemove,
} from "@/api/spcod/prnpolicy";
import { getTreeListAPI } from "@/api/spcod/prncatg.js";
import * as dictService from "@/api/system/dictbiz";
import {
  getMetaCommonList,
  delMetaCommon,
  updateMetaCommon,
} from "@/api/busiMode/metaCommon";
import { startFlow } from "@/api/flow/flow.js";

import { mapGetters } from "vuex";
import "@wangeditor/editor-for-vue";
import editor from "@wangeditor/editor";

import { htmlDecode } from "@/util/util";

const viewPortHeight =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;

export default {
  data() {
    return {
      curRow: {},
      dialogTitle: "确定下架该内容么？",
      dialogVisible: false,
      formAudit: {},
      formAuditOption: {
        column: [
          {
            label: "下架原因",
            prop: "memo",
            span: 24,
            minRows: 4,
            type: "textarea",
            rules: [
              {
                required: false,
                message: "请输入下架原因",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      viewPortHeight: viewPortHeight,
      showEdt: false,
      editor: null,
      treeFilterText: "",
      treeData: [],
      curCatgId: 0,
      curCatgCode: "",
      curCatgName: "",
      defaultProps: {
        children: "subs",
        label: "name",
      },
      policyId: 0,
      corpIds: [],
      corpMap: {},
      enterpriseTypes: [],
      firmSizeList: [],
      qualifications: [],
      drawer: false,
      searchForm: {
        asset_category_code: "",
        location_build_id: "",
        location_floor_id: "",
        location_room_id: "",
      },
      searchTableData: [],
      searchTableLoading: false,
      searchAssetsOption: {
        submitText: "搜索",
        border: true,
        searchMenuPosition: "right",
        addBtn: false,
        menu: false,
        selection: true,
        header: true,
        searchShowBtn: false,
        refreshBtn: false,
        columnBtn: false,
        column: [
          {
            width: "auto",
            overHidden: true,
            align: "right",
            label: "主键id",
            prop: "id",
            hide: true,
          },
          {
            width: "200",
            overHidden: true,
            align: "left",
            label: "企业名称",
            fixed: true,
            prop: "name",
            search: true,
            hide: false,
            searchSpan: 6,
          },
          {
            width: "200",
            overHidden: true,
            align: "left",
            label: "统一社会信用代码",
            prop: "uniform_social_credit_code",
            search: false,
            hide: false,
            searchSpan: 6,
          },
          {
            label: "所属行业",
            width: "200",
            prop: "industry",
            dicUrl: dictService.getUrl("industry"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            search: true,
            searchSpan: 6,
            type: "select",
          },
          {
            width: "auto",
            overHidden: true,
            align: "left",
            label: "企业法人",
            prop: "legal_person",
            hide: false,
          },
          {
            width: "auto",
            overHidden: true,
            align: "left",
            label: "负责人姓名",
            prop: "principal",
            hide: false,
          },
          {
            width: "200",
            overHidden: true,
            align: "left",
            label: "联系电话",
            prop: "phone_num",
            hide: false,
          },
          {
            width: "auto",
            overHidden: true,
            align: "left",
            label: "联系方式",
            prop: "contact_details",
            hide: true,
          },
          {
            width: "100",
            overHidden: true,
            align: "left",
            label: "企业规模",
            prop: "firm_size",
          },
          {
            width: "100",
            overHidden: true,
            align: "right",
            label: "注册资金",
            prop: "registered_capital",
          },
          {
            width: "100",
            overHidden: true,
            align: "left",
            label: "企业类型",
            prop: "type",
          },
          {
            width: "100",
            overHidden: true,
            align: "left",
            label: "企业资质",
            prop: "qualification",
          },
          {
            width: "auto",
            overHidden: true,
            align: "right",
            label: "排序",
            prop: "sort",
            hide: true,
          },
          {
            width: "auto",
            overHidden: true,
            align: "right",
            label: "创建人id",
            prop: "create_user_id",
            hide: true,
          },
          {
            width: "auto",
            overHidden: true,
            align: "right",
            label: "修改人id",
            prop: "update_user_id",
            hide: true,
          },
          {
            width: "auto",
            overHidden: true,
            align: "left",
            label: "附件集合id",
            prop: "attachment_id",
            hide: true,
          },
        ],
      },
      form: {
        // fileUrl:[
        //   { "label": "avue@226d5c1a_image.png", "value": "/images/logo-bg.jpg" }
        // ]
      },
      query: {},
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      option: {
        height: "auto",
        calcHeight: 30,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        addBtn: false,
        border: true,
        index: true,
        viewBtn: true,
        delBtn: false,
        editBtn: false,
        dialogClickModal: false,
        dialogDirection: "rtl",
        dialogType: "drawer",
        column: [
          {
            label: "所属分类",
            prop: "catgId",
            type: "select",
            dicUrl: "/api/park-spcod/policycatg/list?current=1&size=1000",
            viewDisplay: false,
            hide: true,
            props: {
              label: "name",
              value: "id",
            },
            dicFormatter: (res) => {
              let catgs = res.data.records;
              console.log(catgs);
              catgs.forEach((c) => {
                c.id = parseInt(c.id);
              });
              return catgs;
            },
            rules: [
              {
                required: true,
                message: "请输入所属分类",
                trigger: "blur",
              },
            ],
          },
          {
            label: "标题",
            prop: "title",
            viewDisplay: false,
            width: 240,
            span: 24,
            rules: [
              {
                required: false,
                message: "请输入标题",
                trigger: "blur",
              },
            ],
          },
          {
            label: "状态",
            prop: "status",
            addDisplay: false,
            viewDisplay: false,
            editDisplay: false,
            search: true,
            searchSpan: 6,
            type: "select",
            dicData: [
              {
                label: "删除",
                value: 0,
              },
              {
                label: "待审核",
                value: 1,
              },
              {
                label: "提交审核",
                value: 2,
              },
              {
                label: "审核通过",
                value: 3,
              },
              {
                label: "审核退回",
                value: 4,
              },
              {
                label: "下架",
                value: 5,
              },
            ],
            rules: [
              {
                required: true,
                message: "请状态",
                trigger: "blur",
              },
            ],
          },
          // {
          //   label: "政策发布时间",
          //   prop: "title",
          //   addDisplay: false,
          //   rules: [{
          //     required: true,
          //     message: "请输入政策发布时间",
          //     trigger: "blur"
          //   }]
          // },
          {
            label: "内容",
            prop: "content",
            className: "contenCon",
            hide: true,
            component: "RichText",
            viewDisplay: false,
            span: 0,
            width: 0,
            labelWidth: 0,
            minRows: 0,
            rules: [
              {
                required: true,
                message: "请输入内容，图文混编",
                trigger: "blur",
              },
            ],
          },
          // {
          // label: '头图',
          // prop: "iconUrl",
          // type: 'upload',
          // listType: 'picture-img',
          // span: 24,
          // propsHttp: {
          //   res: "data",
          //   url: "link",
          //   name: "attachId",
          // },
          // tip: '只能上传jpg/png用户头像，且不超过500kb',
          // action: '/api/jida-resource/oss/endpoint/put-file-attach'
          // },
          {
            label: "链接",
            prop: "policyLink",
            hide: true,
            viewDisplay: true,
            span: 24,
            rules: [
              {
                required: false,
                message: "请输入链接",
                trigger: "blur",
              },
            ],
          },
          {
            label: "接收人",
            prop: "visualName",
            searchSpan: 12,
          },
          {
            label: "企业",
            prop: "deptIds",
            searchSpan: 12,
            width: 200,
            type: "select",
            filterable: true,
            multiple: true,
            dicData: [],
            rules: [
              {
                required: true,
                message: "请选择新闻可见的企业",
                trigger: "blur",
              },
            ],
          },
          {
            label: "申请开始",
            prop: "applyBday",
            type: "datetime",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            viewDisplay: false,
            hide: true,
            rules: [
              {
                required: false,
                message: "请输入申请开始时间",
                trigger: "blur",
              },
            ],
          },
          {
            label: "申请结束",
            prop: "applyEday",
            type: "datetime",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            viewDisplay: false,
            hide: true,
            rules: [
              {
                required: false,
                message: "请输入申请结束时间",
                trigger: "blur",
              },
            ],
          },
          {
            label: "附件数量",
            prop: "attchFileNum",
            hide: true,
            addDisplay: false,
            viewDisplay: false,
            editDisplay: false,
            rules: [
              {
                required: true,
                message: "请输入附件数量",
                trigger: "blur",
              },
            ],
          },
          {
            label: "查看次数",
            prop: "viewNum",
            addDisplay: false,
            viewDisplay: false,
            editDisplay: false,
            rules: [
              {
                required: true,
                message: "请输入查看次数",
                trigger: "blur",
              },
            ],
          },
          {
            label: "创建人",
            prop: "createUserName",
            hide: true,
            addDisplay: false,
            viewDisplay: false,
            editDisplay: false,
            rules: [
              {
                required: true,
                message: "请输入创建人",
                trigger: "blur",
              },
            ],
          },
          // {
          //   label: "审核人",
          //   prop: "auditorUserName",
          //   addDisplay: false,
          //   viewDisplay: false,
          //   editDisplay: false,
          //   rules: [{
          //     required: true,
          //     message: "请输入审核人",
          //     trigger: "blur"
          //   }]
          // },
          {
            label: "发布时间",
            prop: "releaseTime",
            width: 80,
            hide: true,
            viewDisplay: false,
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            formatter(row, value, label, column) {
              if (row.releaseTime && row.releaseTime != "")
                return row.releaseTime.substring(0, 10);
              else return "";
            },
            rules: [
              {
                required: false,
                message: "请输入发布时间",
                trigger: "blur",
              },
            ],
          },
          {
            label: "审核时间",
            prop: "auditorTime",
            addDisplay: false,
            viewDisplay: false,
            width: 150,
            rules: [
              {
                required: true,
                message: "请输入审核时间",
                trigger: "blur",
              },
            ],
          },
          {
            label: "xiangqing",
            prop: "details",
            labelWidth: 0,
            span: 24,
            hide: true,
            addDisplay: false,
            editDisplay: false,
            formslot: true,
          },
        ],
      },
      data: [],
    };
  },
  mounted() {
    this.getTreeList();
    let curCid = this.$store.getters.SET_PrnCatgId;
    this.getMetaCommonListcheckin();
    console.log("curCid", curCid);
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.prnpolicy_add, false),
        viewBtn: this.vaildData(this.permission.prnpolicy_view, false),
        delBtn: this.vaildData(this.permission.prnpolicy_delete, false),
        editBtn: this.vaildData(this.permission.prnpolicy_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  watch: {
    treeFilterText(value) {
      this.$refs.tree.filter(value);
    },
  },
  methods: {
    uploadBefore(file, done) {
      //this.$message.success("部署开始");
      //this.file = file;
      done();
    },
    getMetaCommonListcheckin() {
      console.log(12312);
      let body = {
        current: 1,
        size: 1000,
        wheres: [{ field: "status", value: 1 }],
      };
      getMetaCommonList("wisdom_park.wp_company", body).then((res) => {
        const data = res.data.data;
        console.log(data);
        if (data.records) {
          let arr = [];
          data.records.forEach((e) => {
            arr.push({
              label: e.dept_name,
              value: e.dept_id,
            });
          });
          console.log(this.option.column[5]);
          this.option.column[6].dicData = arr;
        }
        this.$forceUpdate();
      });
    },
    uploadAfter(file, done) {},
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getTreeDetail(treeData) {
      //对右侧table解析筛选
      let _this = this;
      if (treeData.subs.length > 0) {
        _this.curCatgId = 0;
        return;
      }
      _this.loading = true;
      _this.curCatgId = treeData.id;
      _this.curCatgCode = treeData.code;
      this.curCatgName = treeData.name;
      console.log(treeData);
      getPage(this.page.currentPage, this.page.pageSize, {
        catgId: treeData.id,
      }).then((res) => {
        const data = res.data.data;
        _this.page.total = data.total;
        _this.data = data.records;
        _this.loading = false;
        this.selectionClear();
      });
    },
    // 获取树形结构数据
    async getTreeList() {
      const res = await getTreeListAPI();
      this.treeData = res.data.data;
    },
    addHandle() {
      let _this = this;
      if (_this.curCatgId == 0) {
        this.$message({
          type: "error",
          message: "请选择末级节点!",
        });
        return;
      }
      this.$router.push({
        path:
          "/prncatg/prnpolicy-add?catgId=" +
          _this.curCatgId +
          "&catgCode=" +
          _this.curCatgCode +
          "&curCatgName=" +
          _this.curCatgName,
      });
    },
    rowSave(row, done, loading) {
      add(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          loading();
          window.console.log(error);
        }
      );
    },
    rowUpdate(row, index, done, loading) {
      update(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          loading();
          console.log(error);
        }
      );
    },
    getoAudit(row) {
      let _this = this;
      this.$confirm("确定将提交审核?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        aduitPolicy(row.id, 2).then((res) => {
          startFlow(row.id, "BIZ_POLICY_AUDIT", "SUBMIT", {
            title: `${row.catgName}-${row.title}`,
          }).then((res) => {
            _this.$message({
              type: "success",
              message: "提交审核成功!",
            });
            _this.onLoad(this.page);
          });
        });
      });
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    operEdit(policyId) {
      this.$router.push({
        path: "/prncatg/prnpolicy-edit",
        query: {
          id: policyId,
        },
      });
    },
    aduitSubmit() {
      let _this = this;
      //(prnpolicyId,status,memo)
      console.log(_this.curRow.id, 5, _this.formAudit.memo);

      aduitPolicy(_this.curRow.id, 5, "").then((res) => {
        _this.$message({
          type: "success",
          message: "操作成功!",
        });
        _this.dialogVisible = false;
        _this.$forceUpdate();
        _this.query.catgId = _this.curCatgId;
        _this.onLoad(this.page);
      });
    },
    operPolicyOffshoreDialog(row) {
      this.curRow = row;

      //this.dialogVisible = true;
      this.$confirm("确定下架该内容吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.aduitSubmit();
      });
    },
    doCheckRemove(id) {
      let _this = this;
      _this
        .$confirm("确定删除吗？", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          //修改状态
          doCheckRemove(id, "").then(
            (res) => {
              _this.$forceUpdate();
              _this.query.catgId = _this.curCatgId;
              _this.onLoad(this.page);
              _this.$message({
                type: "success",
                message: "删除操作成功!",
              });
            },
            (error) => {
              // _this
              //   .$confirm("确定强制删除吗？", {
              //     confirmButtonText: "确定",
              //     cancelButtonText: "取消",
              //     type: "warning",
              //   })
              //   .then(() => {
              //     doCheckRemove(id, "yes").then((res) => {
              //       _this.$forceUpdate();
              //       _this.query.catgId = _this.curCatgId;
              //       _this.onLoad(this.page);
              //       _this.$message({
              //         type: "success",
              //         message: "强制删除操作成功!",
              //       });
              //     });
              //   });
            }
          );
        });
    },
    operPolicyStatus(msg, row, nextStatus) {
      let _this = this;
      //状态，0删除/1待审核/2提交审核/3审核通过/4审核退回/5下架
      this.$confirm(msg, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //修改状态
          aduitPolicy(row.id, nextStatus);
        })
        .then(() => {
          row.status = nextStatus;
          _this.$forceUpdate();
          //this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
        });
    },
    handleDelete(prnpolicyId) {
      this.$confirm("确定将选择政策删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //修改状态
          aduitPolicy(prnpolicyId, 0);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
        });
    },
    beforeOpen(done, type) {
      this.showEdt = false;
      if (["view"].includes(type)) {
        getDetail(this.form.id).then((res) => {
          this.form = res.data.data;
          done();
          this.$nextTick(() => {
            setTimeout(() => {
              this.editor = window.editor;
              this.showEdt = true;
              this.form.content1 = htmlDecode(this.form.content, false);
              // console.log(this.form.content1);
              this.$forceUpdate();
            }, 10);
          });
          // this.form.content = window.editor.getHtml(this.form.content)
        });
      }
      if (["edit"].includes(type)) {
        this.$router.push({
          path: "/prncatg/prnpolicy-edit",
          query: {
            id: this.form.id,
          },
        });
      }
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      let _this = this;
      _this.query.catgId = _this.curCatgId;
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      getPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
      });
    },
    uploadAfter(res, done, loading, column) {
      done();
      this.$message.success("上传成功");
    },
    openAddListDetailValue(policyId) {
      this.policyId = policyId;
      this.drawer = true;
    },
    closeAddListDetailValue() {
      this.drawer = false;
    },
    searchTableSelection(ev) {
      let _this = this;
      ev.forEach((e) => {
        if (!_this.corpIds.includes(e.id + "")) _this.corpIds.push(e.id + "");
      });
    },
    handleSearchSubmit(form, done) {
      let body = {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: [],
      };
      getMetaCommonList("wisdom_park.wp_company", body).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        let _this = this;
        data.records.forEach((e) => {
          //console.log(e);
          if (e.type) {
            this.enterpriseTypes.forEach((type) => {
              if (e.type == type.value) {
                e.type = type.name;
              }
            });
          }
          if (e.qualification) {
            this.qualifications.forEach((q) => {
              if (e.qualification == q.value) {
                e.qualification = q.name;
              }
            });
          }
          if (e.firm_size) {
            this.firmSizeList.forEach((q) => {
              if (e.firm_size == q.value) {
                e.firm_size = q.name;
              }
            });
          }
        });
        _this.searchTableData = data.records;
        _this.$nextTick(() => {
          let notifyMap = {};
          getCorpNofityPage(1, 1000, { policyId: this.policyId }).then(
            (ntyRes) => {
              ntyRes.data.data.records.forEach((n) => {
                notifyMap[n.corpId] = "Y";
              });
              for (var i = 0; i < _this.searchTableData.length; i++) {
                if (_this.searchTableData[i].id + "" in notifyMap)
                  _this.$refs.crud.toggleSelection([data.records[i]]);
              }
            }
          );
          //_this.$refs.crud.toggleSelection([data.records[1]]);
          //_this.$refs.crud.toggleSelection([data.records[2]]);
        });
        //this.loading = false;
        done();
      });
    },
    addEnterpriseNotify() {
      let _this = this;
      let notifyDTO = {};
      notifyDTO.policyId = this.policyId;
      notifyDTO.corpIds = this.corpIds;
      addCorpNofity(notifyDTO).then((res) => {
        _this.$message.success("添加公司通知成功！");
        _this.drawer = false;
      });
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
/deep/.el-tree-node.is-current > .el-tree-node__content {
  color: #409eff;
  background-color: #f5f7fa;
}
/deep/ .el-tree-node__content:hover {
  background-color: transparent;
}
.filter-con {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 15px;
  .is-circle {
    margin-left: 5px;
  }
}
.title {
  font-size: 24px;
  font-weight: 500;
  text-align: center;
}
.ttime {
  text-align: center;
}
</style>
<style lang="scss" scoped>
.contenCon {
  display: none;
  width: 0 !important;
  height: 0 !important;
}

.contenCon .el-form-item__content {
  display: none;
  width: 0 !important;
  height: 0 !important;
}

.enterpriseForm {
  /deep/.avue-crud__menu {
    display: none;
  }

  /deep/.el-drawer__header {
    margin-bottom: 0;
  }

  /deep/.el-drawer__body {
    padding-top: 0;
  }

  /deep/.avue-crud__search .avue-form {
    padding: 0;
  }
}
</style>

<template>
    <basic-container>
        <avue-crud ref="table" :data="tableData" :page.sync="page" :option="tableOption" :table-loading="tableLoading" :search.sync="search" v-model="form" @search-change="searchChange"
                   @on-load="onLoad" @refresh-change="onLoad">
            <template slot-scope="{size}" slot="menuLeft">
                <el-button icon="el-icon-plus" :size="size || 'small'" type="primary" @click="gotoForm()">新 增</el-button>
            </template>
            <template slot-scope="{size , row}" slot="menu">
                <el-button icon="el-icon-view" :size="size || 'small'" type="text" @click="gotoForm(row , 'view')">查看</el-button>
                <el-button icon="el-icon-edit" :size="size || 'small'" type="text" @click="gotoForm(row , 'edit')">编辑</el-button>
                <el-button icon="el-icon-link" :size="size || 'small'" type="text" @click="relation(row)">关联企业</el-button>
                <el-button icon="el-icon-delete" :size="size || 'small'" type="text" @click="rowDel(row)">删除</el-button>
            </template>
        </avue-crud>
    </basic-container>
</template>
<script>
import { saveMetaCommon, updateMetaCommon, getMetaCommonList, delMetaCommon } from '@/api/busiMode/metaCommon';
import * as dictService from '@/api/system/dictbiz';
export default {
    data() {
        return {
            form: {
                supplementary_terms: '',
            },
            model: 'wisdom_park.wp_reduction_policy',
            tableLoading: false,
            tableData: [],
            search: {},
            page: {
                pageSizes: this.$store.state.common.pageSizes,
                pagerCount: this.$store.state.common.pagerCount,
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            tableOption: {
                height: 'auto',
                searchMenuSpan: 6,
                border: true,
                addBtn: false,
                editBtn: false,
                delBtn: false,
                searchLabelWidth: 100,
                menuWidth: 250,
                column: [
                    {
                        label: '减免政策标题',
                        prop: 'reduction_policy_title',
                        span: 6,
                        search: true,
                        searchSpan: 6,
                        width: 150,
                        overHidden: true,
                    },
                    // {
                    //   label: "减免方式",
                    //   prop: "reduction_type",
                    //   span: 6,
                    //   type: "select",
                    //   dicUrl: dictService.getUrl("ReductionType"),
                    //   props: {
                    //     label: "dictValue",
                    //     value: "dictKey",
                    //   },
                    // },
                    {
                        label: '政策类型',
                        prop: 'reduction_policy_type',
                        span: 6,
                        type: 'select',
                        dicUrl: dictService.getUrl('ReductionPolicyType'),
                        props: {
                            label: 'dictValue',
                            value: 'dictKey',
                        },
                        search: true,
                        searchSpan: 6,
                        dataType: 'number',
                        width: 150,
                        overHidden: true,
                    },
                    {
                        label: '企业减免限额',
                        prop: 'company_limit',
                        type: 'number',
                        width: 100,
                    },
                    {
                        label: '政策减免限额',
                        prop: 'reduction_limit',
                        type: 'number',
                        width: 100,
                        overHidden: true,
                    },
                    {
                        label: '政策开始时间',
                        prop: 'start_time',
                        type: 'date',
                        width: 150,
                    },
                    {
                        label: '政策结束时间',
                        prop: 'end_time',
                        type: 'date',
                        width: 150,
                    },
                    {
                        label: '政策说明',
                        prop: 'note',
                        type: 'textarea',
                        overHidden: true,
                        width: 150,
                    },
                    {
                        label: '更新时间',
                        prop: 'update_time',
                        display: false,
                        width: 150,
                    },
                ],
            },
        };
    },
    mounted() {},
    methods: {
        relation(row) {
            this.$router.push(`/reduction-policy/relation?id=${row.id}`);
            this.$breadcrumb.add({
                name: '关联企业',
                path: '',
            });
        },
        gotoForm(row, type) {
            type = type || 'add';
            let uri = `/reduction-policy/action/${type}`;
            if (type !== 'add') {
                uri += '?id=' + row.id;
            }
            this.$router.push(uri);
            let types = {
                add: '新增',
                view: '查看',
                edit: '编辑',
            };
            this.$breadcrumb.add({
                name: types[type] + '减免政策',
                path: '',
            });
        },
        searchChange(form, done) {
            this.onLoad();
            done();
        },
        onLoad() {
            let wheres = [];
            ['reduction_policy_title', 'reduction_policy_type'].forEach(name => {
                if (this.search[name]) {
                    if (name === 'reduction_policy_title') {
                        wheres.push({ field: name, op: 'LIKE', value: this.search[name] });
                    } else {
                        wheres.push({ field: name, value: this.search[name] });
                    }
                }
            });
            this.tableData = [];
            this.tableLoading = true;
            getMetaCommonList(this.model, {
                current: this.page.currentPage,
                size: this.page.pageSize,
                wheres: wheres,
            })
                .then(res => {
                    this.tableLoading = false;
                    if (res.data && res.data.code === 200) {
                        this.tableData = res.data.data.records;
                        this.page.total = res.data.data.total;
                    } else {
                        this.tableData = [];
                        this.page.total = 0;
                    }
                })
                .catch(e => {
                    this.tableLoading = false;
                });
        },
        responeHandle(res, done, loading) {
            if (res.data && res.data.code === 200) {
                done && done();
                this.onLoad();
                this.$message.success(res.data.msg);
            } else {
                loading && loading();
                this.$message.error(res.data.msg);
            }
        },
        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return delMetaCommon(this.model, { pk: 'id', ids: [row.id] });
                })
                .then(() => {
                    this.onLoad();
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
    },
};
</script>
<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" :page.sync="page" :before-open="beforeOpen" v-model="form" ref="crud" @row-update="rowUpdate" @row-save="rowSave"
                   @row-del="rowDel" @search-change="searchChange" @search-reset="searchReset" @selection-change="selectionChange" @current-change="currentChange" @size-change="sizeChange"
                   @refresh-change="refreshChange" @on-load="onLoad">
            <template slot="menuLeft">
                <el-button type="danger" size="small" icon="el-icon-delete" plain v-if="permission.epmallordercomment_delete" @click="handleDelete">删 除
                </el-button>
                <el-button v-if="this.orderId" type="primary" size="small" icon="el-icon-back" plain @click="handleBack">返 回
                </el-button>
            </template>
            <template slot-scope="scope" slot="menu">
                <el-button type="text" size="small" icon="el-icon-refresh-left" class="none-border" @click="toogleStatus(scope.row)">修改状态
                </el-button>
            </template>
        </avue-crud>
    </basic-container>
</template>

<script>
import { getList, getPage, getDetail, toogleStatus, add } from '@/api/epmall/ordercomment';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            orderId: '',
            form: {},
            query: {},
            loading: true,
            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            selectionList: [],
            option: {
                height: 'auto',
                calcHeight: 30,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: true,
                addBtn: false,
                viewBtn: true,
                editBtn: false, // 行内编辑按钮
                delBtn: false,
                dialogClickModal: false,
                column: [
                    {
                        label: '订单号',
                        prop: 'orderId',
                        rules: [
                            {
                                required: true,
                                message: '请输入订单id',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '菜品',
                        prop: 'itemTitle',
                        rules: [
                            {
                                required: true,
                                message: '请输入菜品',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '评分等级',
                        prop: 'score',
                        rules: [
                            {
                                required: true,
                                message: '请输入评分等级',
                                trigger: 'blur',
                            },
                        ],
                    },
                    // {
                    //   label: "评论",
                    //   prop: "cmtContent",
                    //   rules: [{
                    //     required: true,
                    //     message: "请输入评论",
                    //     trigger: "blur"
                    //   }]
                    // },

                    {
                        label: '类型',
                        prop: 'cmtType',
                        type: 'select',
                        dicData: [
                            {
                                label: '评论',
                                value: 1,
                            },
                            {
                                label: '附加评论',
                                value: 2,
                            },
                        ],
                        rules: [
                            {
                                required: true,
                                message: '请输入类型：1=评论，2=附加评论',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '状态',
                        prop: 'status',
                        type: 'select',
                        dicData: [
                            {
                                label: '正常',
                                value: 1,
                            },
                            {
                                label: '屏蔽',
                                value: 0,
                            },
                        ],
                        rules: [
                            {
                                required: true,
                                message: '请输入类型',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '评论人',
                        prop: 'userName',
                    },
                    // {
                    //   label: "时间",
                    //   prop: "created",
                    //   rules: [{
                    //     required: true,
                    //     message: "请输入时间",
                    //     trigger: "blur"
                    //   }]
                    // },
                    {
                        label: '时间',
                        prop: 'created',
                        type: 'datetime',
                        format: 'yyyy-MM-dd',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        rules: [
                            {
                                required: true,
                                message: '请输入时间',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '评论',
                        prop: 'cmtContent',
                        span: 24,
                        minRows: 3,
                        type: 'textarea',
                        rules: [
                            {
                                required: false,
                                message: '请输入评论',
                                trigger: 'blur',
                            },
                        ],
                    },
                ],
            },
            data: [],
        };
    },
    mounted() {
        let _this = this;
        _this.orderId = this.$router.currentRoute.query.orderId;
        console.log('orderId:' + _this.orderId);
        _this.onLoad(_this.page);
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.epmallordercomment_add, false),
                viewBtn: this.vaildData(this.permission.epmallordercomment_view, false),
                delBtn: this.vaildData(this.permission.epmallordercomment_delete, false),
                editBtn: this.vaildData(this.permission.epmallordercomment_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    methods: {
        handleBack() {
            this.$router.go(-1);
        },
        toogleStatus(row) {
            this.$confirm('确定修改状态吗?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return toogleStatus(row.id);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
        rowSave(row, done, loading) {
            console.log(row);
            add(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    window.console.log(error);
                },
            );
        },
        rowUpdate(row, index, done, loading) {
            update(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    console.log(error);
                },
            );
        },
        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(row.id);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning('请选择至少一条数据');
                return;
            }
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(this.ids);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    this.$refs.crud.toggleSelection();
                });
        },
        beforeOpen(done, type) {
            if (['edit', 'view'].includes(type)) {
                getDetail(this.form.id).then(res => {
                    this.form = res.data.data;
                });
            }
            done();
        },
        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },
        searchChange(params, done) {
            this.query = params;
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
            done();
        },
        selectionChange(list) {
            this.selectionList = list;
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        currentChange(currentPage) {
            this.page.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        onLoad(page, params = {}) {
            let _this = this;
            if (_this.orderId == '') return;
            this.loading = true;
            this.query.orderId = this.orderId;
            getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                this.loading = false;
                this.selectionClear();
            });
        },
    },
};
</script>

<style></style>

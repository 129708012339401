import { render, staticRenderFns } from "./purchaseHistory.vue?vue&type=template&id=9c4dea9e&scoped=true"
import script from "./purchaseHistory.vue?vue&type=script&lang=js"
export * from "./purchaseHistory.vue?vue&type=script&lang=js"
import style0 from "./purchaseHistory.vue?vue&type=style&index=0&id=9c4dea9e&prod&scoped=true&lang=scss&rel=stylesheet%2Fscss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9c4dea9e",
  null
  
)

export default component.exports
<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" :page.sync="page" :before-open="beforeOpen" v-model="form" ref="crud" @row-update="rowUpdate" @row-save="rowSave"
                   @row-del="rowDel" @search-change="searchChange" @search-reset="searchReset" @current-change="currentChange" @size-change="sizeChange" @refresh-change="refreshChange"
                   @on-load="onLoad">
            <template slot-scope="scope" slot="menu">
                <el-button type="text" size="small" icon="el-icon-reading" class="none-border" @click="gotoComment(scope.row)">查看评论
                </el-button>
            </template>
        </avue-crud>
    </basic-container>
</template>

<script>
import { getList, getPage, getDetail } from '@/api/epmall/orderitem';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            form: {},
            query: {},
            loading: true,
            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            selectionList: [],
            option: {
                height: 'auto',
                calcHeight: 30,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: true,
                addBtn: false,
                editBtn: false, // 行内编辑按钮
                viewBtn: true,
                delBtn: false,
                dialogClickModal: false,
                column: [
                    // {
                    //   label: "商品id",
                    //   prop: "itemId",
                    //   rules: [{
                    //     required: true,
                    //     message: "请输入商品id",
                    //     trigger: "blur"
                    //   }]
                    // },
                    {
                        label: '订单号',
                        prop: 'orderSn',
                        rules: [
                            {
                                required: true,
                                message: '请输入订单id',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '店铺',
                        prop: 'storeName',
                        span: 24,
                        rules: [
                            {
                                required: false,
                                message: '请输入店铺',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '菜品',
                        prop: 'title',
                        search: true,
                        span: 24,
                        rules: [
                            {
                                required: true,
                                message: '请输入菜品',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '订单日',
                        prop: 'orderDay',
                        hide: true,
                        addDisplay: false,
                        editDisplay: false,
                        search: true,
                        searchSpan: 5,
                        type: 'datetime',
                        format: 'yyyy-MM-dd',
                        valueFormat: 'yyyy-MM-dd',
                        rules: [
                            {
                                required: true,
                                message: '请输入订单id',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '数量',
                        prop: 'num',
                        rules: [
                            {
                                required: true,
                                message: '请输入菜品购买数量',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '单价',
                        prop: 'price',
                        rules: [
                            {
                                required: true,
                                message: '请输入菜品单价',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '总金额',
                        prop: 'totalFee',
                        rules: [
                            {
                                required: true,
                                message: '请输入菜品总金额',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '订单时间',
                        prop: 'orderTime',
                        rules: [
                            {
                                required: true,
                                message: '请输入菜品总金额',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '状态',
                        prop: 'status',
                        searchSpan: 5,
                        search: true,
                        addDisplay: false,
                        editDisplay: false,
                        viewDisplay: false,
                        hide: false,
                        type: 'select',
                        dicData: [
                            {
                                label: '未支付',
                                value: 0,
                            },
                            {
                                label: '已支付',
                                value: 1,
                            },
                            {
                                label: '未发货',
                                value: 2,
                            },
                            {
                                label: '已发货',
                                value: 3,
                            },
                            {
                                label: '交易成功',
                                value: 4,
                            },
                            {
                                label: '交易关闭',
                                value: 5,
                            },
                            {
                                label: '交易失败',
                                value: 6,
                            },
                            {
                                label: '交易取消',
                                value: 7,
                            },

                            {
                                label: '完成备餐',
                                value: 8,
                            },
                        ],
                        rules: [
                            {
                                required: true,
                                message: '请输入状态,1=正常,0=关闭',
                                trigger: 'blur',
                            },
                        ],
                    },
                ],
            },
            data: [],
        };
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.epmallorderitem_add, false),
                viewBtn: this.vaildData(this.permission.epmallorderitem_view, false),
                delBtn: this.vaildData(this.permission.epmallorderitem_delete, false),
                editBtn: this.vaildData(this.permission.epmallorderitem_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    methods: {
        gotoComment(row) {
            this.$router.push({
                path: '/epmall/epmallordercomment',
                query: {
                    orderId: row.orderId,
                },
            });
        },
        rowSave(row, done, loading) {
            add(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    window.console.log(error);
                },
            );
        },
        rowUpdate(row, index, done, loading) {
            update(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    console.log(error);
                },
            );
        },
        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(row.id);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning('请选择至少一条数据');
                return;
            }
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(this.ids);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    this.$refs.crud.toggleSelection();
                });
        },
        beforeOpen(done, type) {
            if (['edit', 'view'].includes(type)) {
                getDetail(this.form.id).then(res => {
                    this.form = res.data.data;
                });
            }
            done();
        },
        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },
        searchChange(params, done) {
            this.query = params;
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
            done();
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        currentChange(currentPage) {
            this.page.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        onLoad(page, params = {}) {
            this.loading = true;
            getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                this.loading = false;
                this.selectionClear();
            });
        },
    },
};
</script>

<style>
</style>

import Component from './main.vue';

export default [
  {
    widgetName: '数字卡片',
    type: '基础部件',
    code: 'BuiltInWidgetByNumCard',
    component: Component,
    icon: 'portal-iconfont portal-icon_fsux_tubiao_zhibiaoka',
    dataOption: [
      { name: '标题类型' , code: 'nameType' , type: 'select' ,
        selectList: [
          {name: '字段' , value: 'field'},
          {name: '文本' , value: 'text'}
        ],
        value: 'text'
      },
      {name: '标题' , code: 'name' , type: 'field' , displayConditions: [{prop: 'nameType' , value: 'field' , operator: 'equal'}]},
      {name: '标题' , code: 'name' , type: 'text' , displayConditions: [{prop: 'nameType' , value: 'text' , operator: 'equal'}]},
      {name: '值' , code: 'value' , type: 'field'}
    ],
    optionList: [
      {
        name: '位置' , code: 'position' , value: 1, type: 'radio', 
        selectList: [
          {name: '数字在上' , value: 1},
          {name: '标题在上' , value: 2}
        ]
      },
      {
        name: '数字样式',
        code: 'numStyle',
        optionList: [
          {name: '字体大小' , code: 'fontSize' , value: 36 , type: 'number' , units: [{name: 'PX' , desc: '像素' , value: 'px'}] , unitValue: 'px'},
          {name: '行高' , code: 'lineHeight' , value: 36 , type: 'number' , units: [{name: 'PX' , desc: '像素' , value: 'px'}] , unitValue: 'px'},
          {name: '字体颜色' , code: 'color' , value: '#000' , type: 'color'},
          {name: '对齐' , code: 'textAlign' , value: '#000' , type: 'radio' , value: 'center', selectList: [
            {name: '居左' , value: 'left'},
            {name: '居中' , value: 'center'},
            {name: '居右' , value: 'right'}
          ]}
        ]
      },
      {
        name: '标题样式',
        code: 'titleStyle',
        optionList: [
          {name: '字体大小' , code: 'fontSize' , value: 14 , type: 'number' , units: [{name: 'PX' , desc: '像素' , value: 'px'}] , unitValue: 'px'},
          {name: '行高' , code: 'lineHeight' , value: 36 , type: 'number' , units: [{name: 'PX' , desc: '像素' , value: 'px'}] , unitValue: 'px'},
          {name: '字体颜色' , code: 'color' , value: '#666' , type: 'color'},
          {name: '对齐' , code: 'textAlign' , value: '#000' , type: 'radio' , value: 'center', selectList: [
            {name: '居左' , value: 'left'},
            {name: '居中' , value: 'center'},
            {name: '居右' , value: 'right'}
          ]}
        ]
      }
    ]
  }
]
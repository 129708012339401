<template>
  <div></div>
</template>
<script>
import md5 from "js-md5";
export default {
  data() {
    return {
      baseUrl: "http://192.168.202.2:8780/adc/login_web_url",
      //baseUrl: "http://16wi692823.imwork.net:9083/adc/login_web_url",
      //baseUrl: "http://www.mtstar.net:80/adc/login_web_url",
    };
  },
  mounted() {
    // let access_token = "1027d408-cb99-11e8-aaa2-00163e046949";
    // let access_secret = "c430054";
    var access_token = "1027d408-cb99-11e8-aaa2-00163e046949";
    var access_secret = "c371144";
    let timestamp = this.getTimeStamp();
    let sign = md5(access_token + md5(access_secret) + timestamp);
    let openUrl = `${this.baseUrl}?access_token=${access_token}&timestamp=${timestamp}&sign=${sign}`;
    window.open(openUrl);
    this.$router.back();
  },
  methods: {
    getTimeStamp() {
      var timestamp = parseInt(new Date().valueOf() / 1000);
      return timestamp;
    },
  },
};
</script>

export function uuid(length = 8, chars) {
  let result = "";
  let charsString = chars || "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
  for (let i = length; i > 0; --i) {
    result += charsString[Math.floor(Math.random() * charsString.length)];
  }
  return result;
}
export const initWidget = (widget) => {

  const position = widget.position || {};
  const publicOption = widget.public || {};
  const datasourceConf = widget.datasourceConf || {};
  const option = {};

  (widget.optionList || []).forEach(
    item => {
      option[item.code] = item['defaultValue'] || null;
    }
  )

  let newWidget = {
    ...widget,
    i: 'widget-' + uuid(16 , '0123456789'),
    x: position.x || 0,
    y: position.y || 0,
    h: position.height || 1,
    w: position.width || 1,
    style: {
      background: '#ffffff'
    },
    title: '',
    subTitle: '',
    public: {
      shadow: 'hover',
      simple: false,
      loading: false,
      fullscreen: false,
      collapse: false,
      refresh: false,
      fixedHeight: false,
      padding: [12 , 20],
      ...publicOption
    },
    option: option,
    datasourceConf: {
      dataModel: datasourceConf.dataModel || 'staticData',
      dataOption: datasourceConf.dataOption || [],
      dataType: datasourceConf.dataType || 'text',
      staticValue: datasourceConf.staticValue !== undefined ? datasourceConf.staticValue : (typeof widget.testData === 'object' ? JSON.stringify(widget.testData , null , 2) : widget.testData),
      option: {}
    },
  }

  return newWidget;

}
<template>
  <el-row>
    <el-col :span="24">
      <basic-container>
        <avue-crud
          ref="crud"
          :option="option"
          :search.sync="search"
          :data="list"
          :page="page"
          @row-save="rowSave"
          @row-del="rowDel"
          @row-update="rowUpdate"
        >
          <template slot-scope="{ type, size, row }" slot="menu">
            <el-button
              @click.stop="chooseModelboxOpen(row)"
              icon="el-icon-edit"
              :size="size"
              :type="type"
              >模版配置</el-button
            >
          </template>
        </avue-crud>
      </basic-container>
    </el-col>
    <el-drawer
      title="模版配置"
      :visible.sync="chooseModelbox"
      size="80%"
      direction="rtl"
      append-to-body="true"
      destroy-on-close="true"
    >
      <div class="pad-15"><TemList :temId="temId"></TemList></div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="chooseModelbox = false"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="goDesign()"
          >去创建</el-button
        >
      </span>
    </el-drawer>
  </el-row>
</template>
<script>
import {
  getTempList,
  updateTempItem,
  removeTempItem,
  addTempItem,
} from "@/api/busiMode/meta";
import TemList from "../template/list";

export default {
  name: "tableList",
  components: { TemList },
  data() {
    return {
      temId: "",
      chooseModelbox: false,
      option: {
        refreshBtn: true,
        sortable: true,
        menu: true, //操作菜单栏

        columnBtn: false, //列显隐
        column: [
          {
            label: "模版名称",
            prop: "name",
          },
          {
            label: "模板编码",
            prop: "code",
          },

          {
            label: "是否默认模版",
            prop: "isDefault",
            formatter: function (row, value, label, column) {
              let isDefault = "否";
              if (row.isDefault == 1) {
                isDefault == "是";
              }
              return isDefault;
            },
          },
        ],
      },
      list: [],
      page: {
        pageSizes: [10, 20, 30, 40], //默认
        currentPage: 1,
        total: 10,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    chooseModelboxOpen(row) {
      console.log(row);
      this.temId = row.id;
      this.chooseModelbox = true;
    },
    initData() {
      getTempList().then((res) => {
        this.list = res.data.data.records;
      });
    },
    rowSave(row, done) {
      addTempItem(row).then(() => {
        this.$message.success("新增成功");
        this.initData();
        done();
      });
    },
    rowDel(row, index) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeTempItem(row.id).then(() => {
            this.list.splice(index, 1, row);
          });
        })
        .catch(() => {});
    },
    rowUpdate(row, index, done) {
      updateTempItem(row).then(() => {
        this.list.splice(index, 1, row);
        done();
      });
    },
    mbpz(row) {
      console.log(row);
    },
  },
};
</script>
<style scoped></style>

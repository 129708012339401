<template>
  <basic-container v-loading="loading">
    <el-form
      style="width: 50%; margin: 0 auto"
      size="small"
      ref="form"
      :model="form"
      label-width="100px"
    >
      <el-form-item label="项目名称">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="产品Logo">
        <el-input v-model="form.logo"></el-input>
      </el-form-item>
      <el-form-item label="页脚">
        <el-switch v-model="form.footer"></el-switch>
      </el-form-item>
      <el-form-item label="页脚内容" v-if="form.footer">
        <el-input v-model="form.footerContent"></el-input>
      </el-form-item>
      <el-form-item label="布局">
        <div class="set-layout-item">
          <el-container>
            <el-header height="20px">导航</el-header>
            <el-main height="60px">内容</el-main>
            <el-footer height="20px">页脚</el-footer>
          </el-container>
        </div>
        <!-- <el-switch v-model="form.pageStructure"></el-switch> -->
      </el-form-item>
      <el-form-item label="主题">
        <el-radio-group v-model="form.theme.code" @change="themeChange">
          <el-radio
            v-for="item of themes"
            :label="item.code"
            :key="item.code"
            >{{ item.name }}</el-radio
          >
          <!-- <el-radio label="1">红色</el-radio> -->
        </el-radio-group>
      </el-form-item>
      <!-- <el-form-item label="主题按钮">
        <el-radio-group v-model="form.theme.buttonColor.type">
          <el-radio v-if="form.theme.code !== 'custom'" label="default">默认</el-radio>
          <el-radio label="1">红色</el-radio>
          <el-radio label="2">橙色</el-radio>
          <el-radio label="3">绿色</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="页面边距">
        <el-radio-group v-model="form.theme.margin.type">
          <el-radio v-if="form.theme.code !== 'custom'" label="default">默认</el-radio>
          <template v-if="form.theme.code === 'custom'" >
            <el-radio v-for="item of sizeType" :key="item.code" :label="item.code">{{item.name}}</el-radio>
          </template>
          <el-radio label="custom">自定义</el-radio>
        </el-radio-group>
      </el-form-i patem>
      <el-form-item label="自定义边距" v-if="form.theme.margin.type == 'custom'">
        <el-input v-model="form.theme.margin.value"></el-input>
      </el-form-item>
      <el-form-item label="字体大小">
        <el-radio-group v-model="form.theme.fontSize.type">
          <el-radio v-if="form.theme.code !== 'custom'" label="default">默认</el-radio>
          <template v-if="form.theme.code === 'custom'" >
            <el-radio v-for="item of sizeType" :key="item.code" :label="item.code">{{item.name}}</el-radio>
          </template>
          <el-radio label="custom">自定义</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="自定义字体" v-if="form.theme.fontSize.type == 'custom'">
        <el-input v-model="form.theme.fontSize.value"></el-input>
      </el-form-item> -->
      <el-form-item label-width="0px" class="text-center">
        <el-button size="small" type="primary" @click="submit"
          >保存并应用</el-button
        >
        <el-button size="small" @click="$route.back()">取消</el-button>
      </el-form-item>
    </el-form>
  </basic-container>
</template>
<script>
import { setTheme } from "@/util/util";
import { mapGetters } from "vuex";
import { setStore, getStore, removeStore } from "@/util/store";
import { getList, add, update } from "@/api/system/param.js";
import themes from "@/config/theme";

export default {
  data() {
    return {
      form: {
        theme: {
          code: "",
          margin: {
            type: "default",
            value: "",
          },
          buttonColor: {
            type: "default",
            value: "",
          },
          fontSize: {
            type: "default",
            value: "",
          },
        },
        pageStructure: false,
        logo: "",
        name: "",
        footer: false,
        footerContent: "",
      },

      old: {
        theme: "",
        PageStructure: "",
      },
      isSave: false,
      loading: false,

      code: "page.config",
      paramInfo: null,

      themes: themes,
      sizeType: [
        { name: "大", code: "max", fontSize: 16, margin: 20 },
        { name: "中", code: "medium", fontSize: 14, margin: 16 },
        { name: "小", code: "small", fontSize: 12, margin: 8 },
      ],
    };
  },
  computed: {
    ...mapGetters(["menuMode"]),
  },
  watch: {
    "form.pageStructure"(val) {
      if (val) {
        this.$store.commit("SET_MENU_MODEL", "");
      } else {
        this.$store.commit("SET_MENU_MODEL", "top");
      }
    },
    cusfontsize(val) {
      window.document
        .getElementsByTagName("body")[0]
        .style.setProperty("--fontSize", val + "px");
    },
    // "obj.fontSize"(val) {
    //   window.document
    //     .getElementsByTagName("body")[0]
    //     .style.setProperty("--fontSize", val + "px");
    // },
    // "obj.pageMargin"(val) {
    //   this.setPageMargin(val);
    // },
    // "obj.margin"(val) {
    //   this.setPageMargin(val);
    // },
  },
  created() {
    this.old.theme = getStore({ name: "themeName" });
    this.old.pageStructure = this.menuMode;
    this.form.pageStructure = this.menuMode == "top" ? false : true;
    this.form.theme.code = this.old.theme;
  },
  mounted() {
    this.loading = true;
    getList(1, 10, {
      paramKey: this.code,
    }).then((res) => {
      if (res.data && res.data.data.records.length) {
        this.paramInfo = res.data.data.records[0];
        let paramValue = {};
        try {
          paramValue = JSON.parse(this.paramInfo.paramValue);
        } catch (error) {
          paramValue = {};
        }
        this.form = Object.assign(this.form, paramValue);
      } else {
        this.paramInfo = null;
      }
      this.loading = false;
    });
  },
  destroyed() {
    if (!this.isSave) {
      this.$store.commit("SET_MENU_MODEL", this.old.pageStructure);
      this.setTheme(this.old.theme);
    }
  },
  methods: {
    themeChange() {
      if (this.form.theme.code !== "custom") {
        this.setTheme(this.form.theme.code);
      }
    },
    setPageMargin(value) {
      window.document
        .getElementsByTagName("body")[0]
        .style.setProperty("--marginBox", value + "px");
      window.document
        .getElementsByTagName("body")[0]
        .style.setProperty("--marginBoxRow", 0 - value / 2 + "px");
      window.document
        .getElementsByTagName("body")[0]
        .style.setProperty("--marginBoxCol", value / 2 + "px");
      // }
    },
    setTheme(val) {
      this.$store.commit("SET_THEME_NAME", val);
      if (this.menuMode == "top") {
        setTheme(val + " menuTopDark");
      } else {
        setTheme(val);
      }
    },
    emptytChange() {
      // this.$message.success("清空方法回调");
    },
    submit() {
      let paramInfo = this.paramInfo;
      let body = {
        ...paramInfo,
        paramName: "页面配置项",
        paramKey: this.code,
        paramValue: JSON.stringify(this.form),
      };
      this.loading = true;
      (this.paramInfo ? add : update)(body).then((res) => {
        this.loading = false;
        this.isSave = true;
      });
    },
    tip() {
      this.$message.success("自定义按钮");
    },
  },
};
</script>

<style>
.set-layout-item {
  width: 100px;
  height: 100px;
  display: inline-block;
  margin-right: 10px;
}
</style>

<template>
  <data-model-list></data-model-list>
</template>
<script>
import dataModelList from './dataModel/data-model-list';
export default {
  components: {
    dataModelList
  }
}
</script>
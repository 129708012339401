<template>
  <div>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      ref="crud"
      v-model="form"
      :page.sync="page"
      @refresh-change="refreshChange"
      @on-load="onLoad"
      @search-change="searchChange"
      :before-close="handleBeforeClose"
    >
      <!-- <template slot="menuLeft" slot-scope="scope">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="add()">新增
                </el-button>
            </template> -->
    </avue-crud>
  </div>
</template>

<script>
import { getUserMessageList } from "@/api/desk/notice"
import { mapGetters, mapState } from "vuex"
import { getDeptTree } from "@/api/system/dept"
import { getRoleTree } from "@/api/system/role"
import { readMessage } from "@/api/desk/notice"

export default {
  data() {
    return {
      searchText: "",
      regionName: "",
      form: {},
      selectionList: [],
      loading: true,
      dialogVisible: false,
      detailDia: false,
      query: {},
      dialogType: "",
      type: "",
      size: "60%",
      currentRow: {},
      currentEditRow: {},
      title: "新增消息",
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      option: {
        dateBtn: false,
        border: true,
        height: "auto",
        tip: true,
        searchShow: true,
        searchMenuSpan: 12,
        addBtn: false,
        refreshBtn: true,
        addRowBtn: false,
        columnBtn: true,
        delBtn: false,
        editBtn: false,
        viewBtn: true,
        cancelBtn: true,
        rowKey: "",
        showPage: true,
        index: true,
        indexLabel: "序号",
        selection: false,
        showHeader: true,
        expand: false,
        highlightCurrentRow: true,
        stripe: false,
        menuWidth: 150,
        submitText: "保存并发表",
        reserveSelection: true,
        formslot: true,
        column: [
          {
            align: "left",
            label: "发送人",
            prop: "sender",
            show: true,
            // search: true,
            display: true,
          },

          {
            overHidden: true,
            align: "center",
            label: "业务类型",
            prop: "bizType",
            hide: false,
            display: true,
          },
          {
            overHidden: true,
            align: "center",
            label: "状态",
            prop: "status",
            hide: false,
            type: "select",
            //  search: true,
            dicData: [
              {
                label: "未读",
                value: 1,
              },
              {
                label: "已读",
                value: 2,
              },
            ],
            display: true,
          },

          {
            label: "发送日期",
            align: "center",
            prop: "createTime",
            width: 150,
            search: true,
            editDisplay: false,
            addDisplay: false,
            viewDisplay: true,
            type: "date",
            format: "yyyy年MM月dd日",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
          },
          {
            overHidden: true,
            align: "center",
            label: "标题",
            prop: "title",
            hide: false,
            show: true,
            display: true,
          },
          {
            overHidden: true,
            label: "内容",
            prop: "content",
            width: 250,
            hide: false,
            show: true,
            overHidden: true,
            type: "textarea",
            display: true,
            span: 12,
          },
        ],
      },
      data: [],
      ruleModel: {},
    }
  },

  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.code_add, false),
        viewBtn: this.vaildData(this.permission.code_view, false),
        delBtn: this.vaildData(this.permission.code_delete, false),
        editBtn: this.vaildData(this.permission.code_edit, false),
      }
    },
    ids() {
      let ids = []
      this.selectionList.forEach((ele) => {
        ids.push(ele.id)
      })
      return ids.join(",")
    },
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted() {},
  props: {},
  watch: {},
  methods: {
    handleBeforeClose(done, type) {
      console.log(this.form)
      readMessage(this.form.id).then((res) => {
        // this.getCount();
        // this.getInstantUserMessageList();
        this.$store.commit("SET_NOTICE_REFRESH", !this.$store.state.user.noticeRefresh)
        done()
        this.onLoad()
      })
    },
    initData() {
      getRoleTree(this.userInfo.tenant_id).then((res) => {
        const column = this.findObject(this.option.column, "receivingRole")
        column.dicData = res.data.data
      })
      getDeptTree(this.userInfo.tenant_id).then((res) => {
        const column = this.findObject(this.option.column, "receivingDept")
        column.dicData = res.data.data
      })
    },

    searchChange(params, done) {
      console.log(params)
      this.query = params
      this.page.currentPage = 1
      this.onLoad(params, done)
    },
    refreshChange() {
      this.onLoad(this.page)
    },

    onLoad(params, done) {
      this.loading = true
      getUserMessageList(this.page.currentPage, this.page.pageSize).then((res) => {
        const data = res.data.data
        this.page.total = data.total
        this.data = data.records
        this.loading = false
        done && done()
      })
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(row.id)
          return removeMessage(row.id)
        })
        .then(() => {
          this.onLoad()
          this.$message({
            type: "success",
            message: "操作成功!",
          })
        })
    },
  },
}
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.deleteIcon {
  font-size: 18px;
  position: absolute;
  left: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 1;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
}
/deep/ .el-drawer__header {
  border-bottom: 1px solid #ebebeb;
  padding: 10px 20px;
  margin-bottom: 0px;
}
/deep/ .el-drawer__body {
  padding: 0px;
}
/deep/ .el-dialog__body {
  padding: 0px;
}
.buildDetail /deep/ .el-dialog__body {
  padding: 0px;
}
</style>

<template>
  <div class="apiMarket">
    <div class='display-flex'>
      <div class='w-300' style="margin-bottom: 0;">
        <el-card shadow="never" :body-style="{ padding: '20px 20px 0 20px' }" class="meta-height w-300">
          <el-input
            style="margin-bottom: 15px;"
            size="small"
            placeholder="请输入集合名称"
            suffix-icon="el-icon-search"
            v-model="apiKey"
            @change="filterTree"
          >
          </el-input>
          <el-tree
            ref="tree"
            :data="categorys"
            node-key="id"
            :expand-on-click-node="false"
            :props="defaultProps"
            @node-click="nodeClick">
          </el-tree>
        </el-card>
      </div>
      <div class="meta-container" style="margin-bottom: 0;flex: 1">
        <el-card shadow="never" :body-style="{ padding: '20px 20px 0 20px' }" class="meta-height">
          <avue-crud
            :data="tableData"
            :page.sync="page"
            :option="tableOption"
            :table-loading="tableLoading"
            @on-load="onLoad"
            @refresh-change="onLoad"
          >
          <!-- 搜索 -->
            <template slot="menuLeft">
              <el-input class="mar-l-5" v-model="search.name"
                  @keyup.native.enter="searchChange"
                  clearable
                  size="small"
                  placeholder="请输入API名称"
                  type="text"
                  style="width:210px;">
                <el-button class="mar-0 pad-0" size="small" @click="searchChange" slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </template>
            <template slot="apiCollectionId" slot-scope="{row}">
              {{apiCollectionMap[row.apiCollectionId] || row.apiCollectionName}}
            </template>
            <template slot="menu" slot-scope="scope">
              <el-button @click="viewRow(scope.row)" icon="el-icon-view" type="text" size="small">查看</el-button>
            </template>
          </avue-crud>
        </el-card>
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      width="70%"
      append-to-body
      @close="dialogCancel"
    >
      <data-share-view @close="dialogCancel" :id="currentId"></data-share-view>
    </el-dialog>
  </div>
</template>
<script>
import dataShareView from '@/views/dataShare/datashareView.vue';
import {
  getDataAPICollectionSelect
} from '@/api/dataService/dataAPICollection'
import api from '@/api/dataShare/index';
import apiStatus from '@/config/apiStatus';
  export default {
    components: {
      dataShareView
    },
    data(){
      return {
        search: {},
        depts: {},
        currentId:'',
        dialogTitle: '',
        dialogVisible: false,
        apiKey: '',
        selectId: '',
        categorys: [
          {
            id:'all',
            name:'全部集合'
          }
        ],
        defaultProps: {
          children: 'children',
          label: 'name'
        },
        page: {
          pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
          pageSize: 5,
          currentPage: 1,
          total: 0
        },
        tableLoading: false,
        tableData: [],
        tableOption: {
          addBtn: false,
          editBtn: false,
          delBtn: false,
          menuWidth: 80,
          column:[
            {
              label:'API名称',
              prop:'name',
              span: 24,
              rules: [{
                required: true,
                message: "请输入名称",
                trigger: "blur"
              }]
            },
            {
              label:'集合名称',
              prop:'apiCollectionId',
              span: 24,
              rules: [{
                required: true,
                message: "请输入名称",
                trigger: "blur"
              }]
            },
            // {
            //   label:'API标签',
            //   prop:'apilabel',
            // },
            // {
            //   label:'数据源类型',
            //   prop:'datasource',
            // },
            // {
            //   label:'状态',
            //   prop:'status',
            //   type:'select',
            //   dicData: apiStatus
            // },
            // {
            //   label:'测试状态',
            //   prop:'testStatus',
            // },
            // {
            //   label:'生成方式',
            //   prop:'produceType',
            // },
            {
              label:'描述',
              prop:'description',
              type: "textarea",
              minRows: 2,
              span: 24,
            },
            // {
            //   label:'创建人',
            //   prop:'createUserName',
            //   display: false
            // },
            // {
            //   label:'创建时间',
            //   prop:'createTime',
            //   display: false
            // }
          ]
        },
        apiCollectionMap: {},
      }
    },
    created(){
      api.getDeptList().then(
        res => {

          let handel = (data) => {
            data.forEach(item => {
              this.depts[item['id']] = item['deptName'];
              if(item['children'] && item['children'].length){
                handel(item['children'])
              }
            })
          }
          handel(res.data.data || []);
        }
      )
    },
    mounted() {
      this.getApiCollection();
    },
    methods: {
      // 清空搜索
    searchReset () {

    },
    // 搜索
    searchChange (params,done) {
      this.onLoad()
      done()
    },
      viewRow(row){
        this.dialogVisible = true;
        this.dialogTitle = '查看';
        this.currentId = row.id;
      },
      dialogCancel(data){
        this.dialogTitle = '';
        this.dialogVisible = false;
      },
      filterTree() {
        this.getApiCollection();
      },
      nodeClick(item) {
        this.selectId = item['id'];
        this.onLoad();
      },
      getApiCollection() {
        let obj = {
          name:this.apiKey
        };
        if(!this.apiKey) delete obj.name;
        getDataAPICollectionSelect(obj).then(
          res => {
            if(this.apiKey) {
              this.categorys = [];
            } else {
              this.categorys = [
                {
                  id:'all',
                  name:'全部集合'
                }
              ]
            }
            this.categorys = this.categorys.concat(res.data.data || []);
            this.selectId = this.categorys[0]['id'];
            this.categorys.forEach(
              item => {
                this.apiCollectionMap[item.id] = item['name'];
              }
            )
            this.onLoad();
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(this.selectId);//获取已经设置的资源后渲染
            });
          }
        )
      },
      onLoad() {
        if(!this.selectId){
          return '';
        }
        this.tableLoading = true;
        this.tableData = [];
        let obj = {
          name: this.search.name,
          current: this.page.currentPage,
          size: this.page.pageSize,
          status: 1
        };
        if(this.selectId !== 'all' && this.selectId !== ''){
          obj['apiCollectionId'] = this.selectId
        }
        api.page(obj).then(
          res => {
            this.tableLoading = false;
            if (res.data) {
              let tableData = [];
              (res.data.data.records || []).forEach(
                item => {
                  tableData.push({
                    ...item,
                    _inputParams: JSON.parse(item.inputParams).map(v => v.key).join(','),
                    _outputParams: JSON.parse(item.outputParams).map(v => v.key).join(',')
                  })
                }
              )
              this.tableData = tableData;
              this.page.total = res.data.data.total;
            }
          }
        )
      }
    }
  }
</script>
<style lang="scss" scoped>
  .apiMarket {
    .meta-height{
      height: calc(100vh - 124px)!important;
      overflow: auto;
    }
    .meta-container {
      width: calc(79.16667% - 8px);
      margin-left: 8px;
    }
  }
</style>

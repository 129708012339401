<template>
    <el-container style="height: calc(100% - 50px)" v-loading="pageLoading">
        <el-main class="pad-20 ov-a">
            <el-form class="inventoryForm mar-t-20" :model="form" :rules="rules" ref="form" :size="formSize" label-width="100px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="盘点单据号" prop="code">
                            <el-input v-model="form.code" disabled placeholder="系统自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="资产类型" prop="asset_category">
                            <el-select class="dis-b" :disabled="disabled" v-model="form.asset_category">
                                <el-option v-for="item of AssetCategory" :label="item.dictValue" :value="item.dictKey" :key="item.dictKey"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="盘点名称" prop="name">
                            <el-input v-model="form.name" :disabled="disabled" placeholder="请输入盘点名称"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="负责人" prop="owner_code">

                            <model-search-select :disabled="disabled" v-model="form.owner_code" format="{name}_{postName}" :params="{ tenantId: '000000' }" :fillModel.sync="form"
                                                 :fills="[{ source: 'name', target: 'owner_code_name' }]">
                                <template slot-scope="{ item }">
                                    <span style="float: left">{{ item.name
                    }}{{ item.postName ? `(${item.postName})` : "" }}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{
                    item.deptName
                  }}</span>
                                </template>
                            </model-search-select>
                            <!-- <basic-user-select v-model="form.owner_code" @change="(ev) => userChange(ev , [
              {prop: 'owner_code_name', code: 'name'}  
            ])"></basic-user-select> -->
                            <!-- <el-select
              v-model="form.owner_code"
              filterable
              remote
              clearable
              placeholder="输入关键词搜索"
              :remote-method="searchUser"
              :loading="loading.userLoading"
              :disabled="disabled"
              @change="userChange"
              class="dis-b"
            >
              <el-option v-for="item of dictData.userList" :key="item.id" :label="item.deptName +'_'+ item.postName + '_' + item.name" :value="item.id">
                <span style="float: left">{{ item.name }}{{item.postName ? `(${item.postName})` : ''}}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deptName }}</span>
              </el-option>
            </el-select> -->
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="计划开始时间" prop="plan_start_time">
                            <el-date-picker :disabled="disabled" class="dis-b w-100p" v-model="form.plan_start_time" type="datetime" placeholder="选择日期" format="yyyy-MM-dd HH:mm"
                                            value-format="yyyy-MM-dd HH:mm:00"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="计划结束时间" prop="plan_finish_time">
                            <el-date-picker :disabled="disabled" class="dis-b w-100p" v-model="form.plan_finish_time" type="datetime" placeholder="选择日期" format="yyyy-MM-dd HH:mm"
                                            value-format="yyyy-MM-dd HH:mm:00"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="附件" prop="attachment_id">
                            <form-file :disabled="disabled" v-model="form.attachment_id"></form-file>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="盘点说明" prop="remarks">
                            <el-input type="textarea" :rows="5" :disabled="disabled" placeholder="请输入内容" v-model="form.remarks">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="pad-10" style="position: sticky;background: #fff;top: -20px;z-index: 10;">
                    <el-divider content-position="left">盘点资产(共{{listDetailValues.length}}条)</el-divider>
                    <div v-if="disabled" class="mar-t-20" style="display: flex;">
                        <div style="flex: 1;">
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="实际盘点人">
                                        <model-search-select v-model="tableSearch.actual_inventory_user_id" format="{name}_{postName}" :params="{ tenantId: '000000' }">
                                            <template slot-scope="{ item }">
                                                <span style="float: left">{{ item.name
                          }}{{ item.postName ? `(${item.postName})` : "" }}</span>
                                                <span style="float: right; color: #8492a6; font-size: 13px">{{
                          item.deptName
                        }}</span>
                                            </template>
                                        </model-search-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="资产编码">
                                        <el-input v-model="tableSearch.system_asset_code"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="盘点状态">
                                        <el-select clearable v-model="tableSearch.inventory_status">
                                            <el-option label="未盘点" value="1"></el-option>
                                            <el-option label="已盘点" value="2"></el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </div>
                        <div>
                            <el-button icon="el-icon-printer" :size="formSize || 'small'" @click="printer">打印标签</el-button>
                        </div>
                    </div>
                    <div v-if="tableSelections.length">
                        <el-alert :title="'共选中了 ' + tableSelections.length + ' 条数据'" type="success" close-text="清空" @close="clearTableSelections()">
                        </el-alert>
                    </div>
                    <!-- <b>盘点资产</b>
          
        <el-button @click="openAddAssetDrawer(-1)" class="pull-right" :size="formSize"  type="primary" icon="el-icon-plus">添加盘点资产</el-button> -->
                </div>
                <div class="search-table">
                    <el-form-item class="mar-b-20" label-width="0px">
                        <!-- <div>
              <span>共{{listDetailValues.length}}条数据</span>
            </div> -->
                        <el-table ref="detailTable" :data="tableData" :size="formSize" @selection-change="handleSelectionChange" border row-key="asset_id">
                            <el-table-column v-if="disabled" header-align="center" type="selection" width="60">
                            </el-table-column>
                            <el-table-column header-align="center" v-if="!disabled && (form.inventory_status != 3)" width="70px">
                                <template slot="header">
                                    <el-button size="mini" @click="openAddAssetDrawer()" type="primary" icon="el-icon-plus" circle></el-button>
                                </template>
                                <template slot-scope="scope">
                                    <el-button size="mini" @click="listDetailValues.splice(scope.$index, 1);tablePage.total = listDetailValues.length" type="danger" icon="el-icon-minus"
                                               circle></el-button>
                                </template>
                            </el-table-column>
                            <el-table-column label="序号" align="center" width="60px">
                                <template slot-scope="scope">
                                    {{(tablePage.minIndex + scope.$index + 1)}}
                                </template>
                            </el-table-column>
                            <el-table-column label="资产编码" width="160px" prop="system_asset_code"></el-table-column>
                            <el-table-column label="资产类别" width="160px" prop="asset_category_code">
                                <template slot-scope="scope">
                                    <span v-if="catalogData[scope.row.asset_category_code]">
                                        {{catalogData[scope.row.asset_category_code].name}}
                                    </span>
                                    <span v-else>-</span>
                                    <!-- {{catalogData[scope.row.asset_category_code] || '-'}} -->
                                </template>
                            </el-table-column>
                            <el-table-column width="160px" label="资产名称" prop="asset_name"></el-table-column>
                            <el-table-column label="使用日期" width="160px" prop="cw_start_date"></el-table-column>
                            <el-table-column label="使用部门" width="160px" prop="collection_dept_name"></el-table-column>
                            <el-table-column label="使用人" width="160px" prop="collection_user_name"></el-table-column>
                            <el-table-column label="盘点人员" :width="disabled ? '300px' : '300px'" prop="inventory_user_name">
                                <!-- { "system_asset_code": "ZCLB01000101-1", "asset_name": "倒蹬机", "remarks": "Excel解析入库更新", "location_floor_id": null, "location_build_id": "1619165715667927041", "location_room_id": null, "inventory_user_id": [ "1123598821738675201" ], "inventory_user_name": "", "inventory_date": null, "inventory_status": null, "collection_dept_name": null, "collection_user_id": null, "collection_user_name": null, "collection_dept_id": null, "location_name": "青岛国际创新园A座", "asset_category_code": "1601496334154645505", "inventory_use_person": 2, "asset_id": "1648989904919654401" } -->
                                <template slot-scope="scope">
                                    <div v-if="scope.row.inventory_use_person !== 1" style="display: flex;gap: 8px;">
                                        <div style="flex: 1">
                                            <model-search-select v-if="!disabled && form.inventory_status != 3 && scope.row.isEdit" :multiple="true" valueType="string" :disabled="disabled"
                                                                 v-model="scope.row.inventory_user_id" format="{name}" :params="{ tenantId: '000000' }" :fillModel.sync="scope.row" :fills="[
                          { source: 'name', target: 'inventory_user_name' },
                        ]">
                                                <template slot-scope="{ item }">
                                                    <span style="float: left">{{ item.name
                            }}{{
                              item.postName ? `(${item.postName})` : ""
                            }}</span>
                                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deptName }}</span>
                                                </template>
                                            </model-search-select>
                                            <div v-else>{{ scope.row.inventory_user_name }}</div>
                                        </div>
                                        <el-button v-if="!disabled && !scope.row.isEdit" type="text" size="small" icon="el-icon-edit" @click="scope.row.isEdit = true;">修改</el-button>
                                        <el-button v-if="!disabled && scope.row.isEdit" type="text" size="small" icon="el-icon-check" @click="scope.row.isEdit = false;">完成</el-button>
                                    </div>
                                    <template v-else>
                                        <span>默认资产使用人盘点</span>
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column width="220px" label="资产位置" prop="location_name"></el-table-column>
                            <el-table-column v-if="isView" width="220px" label="原资产位置" prop="location_name1">
                                <template slot-scope="{ row }">
                                    <span class="text-red" v-if="
                      oldChange &&
                      oldChange[row.id] &&
                      oldChange[row.id].location_name
                    ">{{ oldChange[row.id].location_name.oldVal || "" }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="盘点状态" prop="inventory_status">
                                <template slot-scope="{ row }">
                                    <span v-if="row.inventory_status == 1">未盘点</span>
                                    <span v-if="row.inventory_status == 2">已盘点</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="资产状态" prop="asset_status">
                                <template slot-scope="{ row }">
                                    <span v-if="row.asset_status == 1">正常</span>
                                    <span v-if="row.asset_status == 2">异常</span>
                                </template>
                            </el-table-column>
                            <el-table-column v-if="isView" label="原资产状态" prop="inventory_status1">
                                <template slot-scope="{ row }">
                                    <template v-if="
                      oldChange &&
                      oldChange[row.id] &&
                      oldChange[row.id].asset_status
                    ">
                                        <div class="text-red">
                                            <span v-if="oldChange[row.id].asset_status.oldVal == 1">正常</span>
                                            <span v-if="oldChange[row.id].asset_status.oldVal == 2">异常</span>
                                        </div>
                                    </template>
                                </template>
                            </el-table-column>
                            <el-table-column width="250px" label="异常原因" prop="remarks">
                                <template slot-scope="{ row }">
                                    <span v-if="row.asset_status == 2">{{ row.remarks }}</span>
                                    <span v-else>-</span>
                                </template>
                            </el-table-column>
                            <el-table-column v-if="isView" width="120px" label="实际盘点人员" prop="actual_inventory_user_name"></el-table-column>
                            <el-table-column v-if="isView" width="150px" label="盘点时间" prop="actual_inventory_date"></el-table-column>
                            <el-table-column v-if="isView" min-width="400px" label="图片" prop="attachment_id">
                                <template slot-scope="{ row }">
                                    <form-file v-if="row.attachment_id" :disabled="true" :value="row.attachment_id"></form-file>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination class="pad-tb-10" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="tablePage.currentPage" :page-sizes="tablePage.pageSizes"
                                       :page-size="tablePage.pageSize" layout="->, total, sizes, prev, pager, next, jumper" :total="tablePage.total">
                        </el-pagination>
                    </el-form-item>
                </div>
            </el-form>
        </el-main>
        <el-footer v-if="!isView" height="50px" class="lh-50">
            <div class="text-center">
                <el-button :size="formSize" :loading="btnLoading" icon="el-icon-close" @click="back">取 消</el-button>
                <el-button :size="formSize" :loading="btnLoading" icon="el-icon-check" type="primary" @click="saveForm(false)">保 存</el-button>
            </div>
        </el-footer>
        <el-drawer title="添加资产" :visible.sync="drawer" append-to-body size="800px" :withHeader="false" class="inventoryForm">
            <el-container v-if="drawer" class="h-100p">
                <el-header class="bor-b" height="50px">
                    <basic-page-header class="lh-50" @back="drawer = false" title="添加资产"></basic-page-header>
                </el-header>
                <el-main class="ov-h">
                    <div class="pad-15 search-table">
                        <avue-crud ref="searchTable" :page.sync="searchTablePage" :search.sync="searchForm" :data="searchTableData" :option="searchAssetsOption" :table-loading="searchTableLoading"
                                   @on-load="searchTableOnLoad()" @refresh-change="searchTableOnLoad()" @search-change="handleSearchSubmit" @selection-change="searchTableSelection"
                                   @sort-change="sortChange"></avue-crud>
                    </div>
                </el-main>
                <el-footer class="text-center pad-tb-15" height="113px">
                    <div class="lh-34">
                        <el-row>
                            <el-col :span="4" style="width: 100px" class="is-required-text">
                                盘点人员
                            </el-col>
                            <el-col :span="6">
                                <el-select size="small" class="dis-b" @change="addListFormUserTypeChange()" v-model.number="addListForm.userType">
                                    <el-option label="自定义" :value="1"></el-option>
                                    <el-option label="资产使用人盘点" :value="2"></el-option>
                                </el-select>
                            </el-col>
                            <el-col :span="12" v-if="addListForm.userType === 1">
                                <model-search-select class="dis-b w-100p" :size="formSize" :disabled="disabled" :multiple="true" :valueType="'string'" v-model="addListForm.userId"
                                                     format="{name}_{postName}" :params="{ tenantId: '000000' }" :fillModel.sync="addListForm" :fills="[{ source: 'name', target: 'userName' }]">
                                    <template slot-scope="{ item }">
                                        <span style="float: left">{{ item.name
                      }}{{ item.postName ? `(${item.postName})` : "" }}</span>
                                        <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deptName }}</span>
                                    </template>
                                </model-search-select>
                            </el-col>
                        </el-row>
                    </div>
                    <div class="lh-34 mar-t-15">
                        <el-button :size="formSize" :loading="btnLoading" icon="el-icon-close" @click="drawer = false">取 消</el-button>
                        <el-button :size="formSize" :disabled="searchTableSelections.length === 0" :loading="btnLoading" icon="el-icon-check" type="primary" @click="addList()">添 加</el-button>
                    </div>
                </el-footer>
            </el-container>
        </el-drawer>
    </el-container>
</template>
<script>
import dayjs from 'dayjs';

import {
    saveMetaCommon,
    updateMetaCommon,
    getMetaCommonList,
    delMetaCommon,
    getMetaCommonListTree,
    getMetaCommonDetail,
    getMetaListUrl,
} from '@/api/busiMode/metaCommon';

import * as dictService from '@/api/system/dictbiz';

import { searchList } from '@/api/system/user';
import { uuid } from '@/util/util';

import { startFlow } from '@/api/flow/flow.js';
import { j2printer, getList } from '@/api/asset/index.js';

import i18n from '../../../lang';

const modelPrefix = 'wisdom_park.';

export default {
    data() {
        return {
            pageLoading: false,
            drawer: false,
            btnLoading: false,
            editableTabsValue: '',
            loading: {
                userLoading: false,
            },
            wheres: [],
            form: {
                code: `PD-${dayjs().format('YYYYMMDD')}${uuid(6, '1234567890')}`,
                name: '',
                owner_code: '',
                owner_code_name: '',
                remarks: '',
                plan_start_time: '',
                plan_finish_time: '',
                attachment_id: '',
                asset_category: '',
            },
            tableSearch: {
                actual_inventory_user_id: null,
                system_asset_code: '',
                inventory_status: '',
            },
            tableSelections: [],
            listDetailValues: [],
            tableData: [],
            dictData: {
                userList: [],
                categorys: [],
            },
            catalogModel: `${modelPrefix}wp_asset_category`,
            modifyModel: `${modelPrefix}wp_modify_tips`,
            model: `${modelPrefix}wp_asset_inventory`,
            detailTable: 'wp_asset_inventory_detail',
            masterIdColumnName: 'inventory_id',
            floorModel: `${modelPrefix}wp_floor`,
            roomModel: `${modelPrefix}wp_room`,
            assetModel: `${modelPrefix}wp_asset_detail`,
            catalogData: {},
            listValue: {
                field: [
                    'asset_id',
                    'system_asset_code',
                    'asset_name',
                    'remarks',
                    'location_floor_id',
                    'location_build_id',
                    'location_room_id',
                    'inventory_user_id',
                    'inventory_user_name',
                    'inventory_date',
                    { code: 'inventory_status', type: 'string', default: '1' },
                    'collection_dept_name',
                    'collection_user_id',
                    'collection_user_name',
                    'collection_dept_id',
                    'location_name',
                    'asset_category_code',
                    'inventory_use_person',
                    'cw_start_date',
                    'asset_model',
                ],
                relate: 'inventory_id',
                master: 'asset_id',
            },
            searchTabels: [],
            searchTabelIndex: 0,
            searchForm: {
                asset_category_code: '',
                location_build_id: '',
                location_floor_id: '',
                location_room_id: '',
                user_id: '',
            },
            addListForm: {
                userId: '',
                userName: '',
                userType: 1,
            },
            searchTableData: [],
            searchTableLoading: false,
            searchTableSelections: [],
            searchTablePage: {
                pageSizes: this.$store.state.common.pageSizes,
                pagerCount: this.$store.state.common.pagerCount,
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            searchAssetsOption: {
                height: 'auto',
                rowKey: 'id',
                submitText: i18n.t('crud.searchBtn'),
                border: true,
                // searchMenuPosition: "top",
                labelPosition: 'top',
                addBtn: false,
                menu: false,
                selection: true,
                reserveSelection: true,
                header: true,
                searchShowBtn: false,
                refreshBtn: false,
                columnBtn: false,
                // searchBtn: false,
                // emptyBtn: false,
                tip: false,
                searchMenuSpan: 24,
                searchMenuPosition: 'center',
                selectable: row => {
                    return !!!this.listDetailValues.find(a => a[this.listValue.master] === row.id);
                },
                column: [
                    {
                        label: '资产名称',
                        prop: 'asset_name',
                    },
                    {
                        label: '资产类型',
                        prop: 'asset_category_code',
                        type: 'tree',
                        props: {
                            label: 'asset_category_name',
                            value: 'id',
                        },
                        dicData: [],
                        search: true,
                        searchSpan: 6,
                    },
                    {
                        label: '所属楼宇',
                        prop: 'location_build_id',
                        hide: true,
                        showColumn: false,
                        dicUrl: getMetaListUrl(modelPrefix + 'wp_building'),
                        props: {
                            label: 'name',
                            value: 'id',
                            res: 'data.records',
                        },
                        dicQuery: {
                            current: 1,
                            size: 500,
                        },
                        dicMethod: 'post',
                        search: true,
                        searchSpan: 6,
                        type: 'select',
                    },
                    {
                        label: '所属楼层',
                        prop: 'location_floor_id',
                        hide: true,
                        showColumn: false,
                        search: true,
                        searchSpan: 6,
                        type: 'select',
                        dicData: [],
                        props: {
                            label: 'name',
                            value: 'id',
                            res: 'data.records',
                        },
                    },
                    {
                        label: '所属房间',
                        prop: 'location_room_id',
                        hide: true,
                        showColumn: false,
                        search: true,
                        searchSpan: 6,
                        type: 'select',
                        dicData: [],
                        props: {
                            label: 'name',
                            value: 'id',
                            res: 'data.records',
                        },
                    },
                    {
                        label: '资产位置',
                        prop: 'location_name',
                        search: true,
                        searchSpan: 24,
                    },
                    {
                        label: '资产使用人',
                        prop: 'collection_user_name',
                    },
                    {
                        label: '使用日期',
                        prop: 'cw_start_date',
                        sortable: 'custom',
                    },
                ],
            },
            oldChange: null,
            AssetCategory: [],
            orders: [{ sort: 'DESC' }],
            tablePage: {
                pageSizes: this.$store.state.common.pageSizes,
                pagerCount: this.$store.state.common.pagerCount,
                pageSize: 10,
                currentPage: 1,
                total: 0,
                minIndex: 0,
                maxIndex: 10,
            },
        };
    },
    computed: {
        disabled() {
            return this.isTodo || this.isView || false;
        },
        isView() {
            return this.isTodo ? this.isTodo : this.view;
        },
    },
    props: {
        id: {
            type: String,
            default: '',
        },
        formSize: {
            type: String,
            default: 'small',
        },
        isAdd: {
            type: Boolean,
            default: true,
        },
        view: {
            type: Boolean,
            default: false,
        },
        isTodo: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    watch: {
        'searchForm.location_build_id': {
            handler(val) {
                this.setSeachData('floor');
            },
        },
        'searchForm.location_floor_id': {
            handler(val) {
                this.setSeachData('room');
            },
        },
        'tableSearch.actual_inventory_user_id': {
            handler() {
                this.clearTableSelections();
            },
        },
        'tableSearch.system_asset_code': {
            handler() {
                this.clearTableSelections();
            },
        },
        'tableSearch.inventory_status': {
            handler() {
                this.clearTableSelections();
            },
        },
    },
    mounted() {
        dictService
            .getDictionary({
                code: 'AssetCategory',
            })
            .then(res => {
                this.AssetCategory = res.data.data || [];
            });
        this.initData();
        if (this.id) {
            this.form.code = '';
            this.getMetaCommonDetail();
            this.getChangeLog();
        }
        // this.rules = {
        //   name: [{ required: true, message: "请输入盘点名称", trigger: "blur" }],
        // };
    },
    methods: {
        printer() {
            if (this.tableSelections.length < 1) {
                this.$message.warning('请至少选中一条数据');
                return;
            }
            this.$confirm(`确定打印选中的${this.tableSelections.length}条资产标签?`, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                let data = [];
                this.tableSelections.forEach(item => {
                    data.push({
                        asset_name: item.asset_name || '',
                        system_asset_code: item.system_asset_code || '',
                        asset_model: item.asset_model || '',
                        collection_dept_name: item.collection_dept_name || '',
                        location_name: item.location_name || '',
                        address_detail: item.address_detail || '',
                        cw_start_date: item.cw_start_date || '',
                        id: `${item.asset_id}`,
                    });
                });
                j2printer(data)
                    .then(res => {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                    })
                    .catch(e => {
                        this.$message({
                            type: 'error',
                            message: '打印失败，未连接到打印适配程序，请联系管理员!',
                        });
                    });
            });
        },
        handleSelectionChange(selections) {
            this.tableSelections = selections;
        },
        clearTableSelections() {
            this.tableSelections = [];
            this.$refs.detailTable.clearSelection();
            this.$nextTick(() => {
                this.listDetailValuesFilter();
            });
        },
        listDetailValuesFilter() {
            let data = [];
            if (this.tableSearch.actual_inventory_user_id) {
                data = this.listDetailValues.filter((item, index) => {
                    if (item.actual_inventory_user_id === this.tableSearch.actual_inventory_user_id) {
                        return item;
                    }
                });
            } else {
                data = this.listDetailValues;
            }
            if (this.tableSearch.system_asset_code) {
                data = data.filter(item => {
                    if (item.system_asset_code && item.system_asset_code.includes(this.tableSearch.system_asset_code)) {
                        return item;
                    }
                });
            }
            if (this.tableSearch.inventory_status) {
                data = data.filter(item => {
                    if (item.inventory_status == this.tableSearch.inventory_status) {
                        return item;
                    }
                });
            }
            this.tableData = data.filter((item, index) => {
                if (index >= this.tablePage.minIndex && index < this.tablePage.maxIndex) {
                    return item;
                }
            });
            this.tablePage.total = data.length;
            // this.tableData = data;
        },
        handleSizeChange(size) {
            this.tablePage.pageSize = size;
            this.tablePageChange();
        },
        handleCurrentChange(val) {
            this.tablePage.currentPage = val;
            this.tablePageChange();
        },
        tablePageChange() {
            if (this.tablePage.pageSize * (this.tablePage.currentPage - 1) > this.listDetailValues.length) {
                this.tablePage.currentPage = 1;
            }
            this.tablePage.minIndex = this.tablePage.pageSize * (this.tablePage.currentPage - 1);
            this.tablePage.maxIndex = this.tablePage.pageSize * this.tablePage.currentPage;
            this.listDetailValuesFilter();
        },
        sortChange({ column, prop, order }) {
            let has = false;
            console.log(order);
            this.orders = [{ sort: order === 'descending' ? 'DESC' : 'ASC' }];
            // this.orders.forEach((item, index) => {
            //     if (item.field === prop) {
            //       detailedLeaseStatement  has = true;
            //         if (!order) {
            //             this.orders.splice(index, 1);
            //         } else {
            //             item.sort = order === 'descending' ? 'DESC' : 'ASC';
            //         }
            //     }
            // });
            // if (order && has === false) {
            //     // this.orders.push({
            //     //     field: prop,
            //     //     sort: order === 'descending' ? 'DESC' : 'ASC',
            //     // });
            //     this.orders.push({
            //         sort: order === 'descending' ? 'DESC' : 'ASC',
            //     });
            // }
            this.searchTableOnLoad();
        },
        getChangeLog() {
            getMetaCommonDetail(this.modifyModel, {
                pk: 'business_id',
                id: this.id,
            }).then(res => {
                if (res.data.data && res.data.data.id) {
                    let oldChange = res.data.data;
                    if (oldChange.latest_json) {
                        oldChange.latest_json = JSON.parse(oldChange.latest_json);
                    }
                    this.oldChange = oldChange.latest_json;
                }
                console.log(this.oldChange);
            });
        },
        addListFormUserTypeChange() {
            if (this.addListForm.userType == 2) {
                let ok = true;
                this.searchTableSelections.forEach(item => {
                    let inList = this.listDetailValues.find(a => a[this.listValue.master] === item.id);
                    if (!inList && !item.collection_user_id) {
                        ok = false;
                    }
                });
                if (ok === false) {
                    this.$message.warning('资产没有指定使用人，请自定义盘点人员。');
                    this.addListForm.userType = 1;
                }
            }
        },
        userChange(ev, props) {
            (props || []).forEach(item => {
                if (ev && ev.item) {
                    this.form[item.prop] = ev.item[item.code];
                } else {
                    this.form[item.prop] = '';
                }
            });
        },
        openAddAssetDrawer() {
            this.searchTableSelections = [];
            this.searchTableData = [];
            this.searchForm = {
                asset_category_code: '',
                location_build_id: '',
                location_floor_id: '',
                location_room_id: '',
                user_id: '',
            };
            this.addListForm = {
                userId: '',
                userName: '',
                userType: 1,
            };
            this.drawer = true;
        },
        addList() {
            if (this.addListForm.userType === 1 && !this.addListForm.userId) {
                this.$message.error('请选择盘点人员');
                return;
            }
            let list = [];
            // let userInfo = this.dictData.searchUserList.find(item => item.id === this.searchForm.user_id) || {
            //   deptId: '',
            //   deptName: '',
            //   name: '',
            //   id: ''
            // }
            let ids = '|' + this.listDetailValues.map(item => item[this.listValue.master]).join('|') + '|';
            this.searchTableSelections.forEach(item => {
                let has = ids.includes(`|${item.id}|`);
                if (!has) {
                    let newItem = {};
                    this.listValue.field.forEach(field => {
                        if (typeof field === 'string') {
                            field = {
                                type: 'string',
                                code: field,
                            };
                        }
                        if (field.code !== this.listValue.master) {
                            newItem[field.code] = item[field.code] || null;
                        }
                    });
                    newItem[this.listValue.master] = item['id'];
                    newItem['inventory_user_id'] = JSON.parse(JSON.stringify(this.addListForm)).userId;
                    newItem['inventory_user_name'] = this.addListForm.userName || '';
                    newItem['inventory_use_person'] = this.addListForm.userType === 2 ? 1 : 2;
                    newItem['isEdit'] = false;
                    list.push(newItem);
                }
            });
            this.drawer = false;
            this.$nextTick(() => {
                this.listDetailValues = this.listDetailValues.concat(list);
                this.listDetailValuesFilter();
                this.tablePage.total = this.listDetailValues.length;
            });
        },
        searchTableSelection(ev) {
            this.searchTableSelections = ev;
            this.addListFormUserTypeChange();
        },
        handleSearchSubmit(form, done) {
            let wheres = {};

            [
                'asset_category_code',
                'location_build_id',
                'location_floor_id',
                'location_room_id',
                'location_name',
            ].forEach(item => {
                if (form[item]) {
                    wheres[item] = form[item];
                }
            });
            done && done();
            this.wheres = wheres;
            this.searchTableOnLoad(wheres);
        },
        searchTableOnLoad(wheres) {
            wheres = wheres || this.wheres || {};
            // if (wheres.length == 0) {
            //     wheres = this.wheres || {};
            // }
            console.log(this.orders);
            if (this.orders.length >= 1) {
                // wheres.orders = this.orders;
            }
            this.searchTableLoading = true;
            this.searchTableData = [];
            getList({
                current: this.searchTablePage.currentPage,
                size: this.searchTablePage.pageSize,
                ...wheres,
                // wheres: [
                //     ...wheres,
                //     {
                //         field: 'asset_current_status',
                //         value: [10001, 10002, 10003, 10004],
                //         op: 'IN',
                //     },
                // ],
                sort: this.orders[0].sort,
            }).then(res => {
                if (res?.data?.data) {
                    this.searchTableData = res?.data?.data.records;
                    // this.$nextTick(() => {
                    //   let selections = [];
                    //   let ids = this.listDetailValues.map((a) => a[this.listValue.master]);
                    //   this.searchTableData.forEach((item) => {
                    //     if (ids.includes(item.id)) {
                    //       selections.push(item);
                    //     }
                    //   });
                    //   this.$refs.searchTable.toggleSelection(selections);
                    // });
                    this.searchTablePage.total = res.data.data.total;
                    this.searchTableLoading = false;
                }
            });
        },
        setSeachData(type) {
            let propName = type === 'floor' ? 'location_floor_id' : 'location_room_id';
            let checkProps = ['location_build_id'];
            if (type === 'room') {
                checkProps.push('location_floor_id');
            }
            const column = this.findObject(this.searchAssetsOption.column, propName);
            let search = true,
                searchForm = this.searchForm;
            checkProps.forEach(item => {
                if (!searchForm[item]) {
                    search = false;
                }
            });
            if (search) {
                let body = {};
                if (type === 'floor') {
                    body = {
                        wheres: [{ field: 'building_id', value: searchForm.location_build_id }],
                        orders: [{ field: 'code+1000', sort: 'DESC' }],
                    };
                } else {
                    body = {
                        wheres: [
                            { field: 'building_id', value: searchForm.location_build_id },
                            { field: 'floor_id', value: searchForm.location_floor_id },
                        ],
                    };
                }
                getMetaCommonList(type === 'floor' ? this.floorModel : this.roomModel, body).then(res => {
                    column.dicData = (res.data.data.records || []).map(item => {
                        return {
                            ...item,
                            name: item.name ? item.name : `${item.code}层`,
                        };
                    });
                });
            } else {
                column.dicData = [];
            }
        },
        initData() {
            let catalogData = {};
            let handle = (data, parent) => {
                (data || []).forEach(item => {
                    if (!catalogData[item.id]) {
                        parent = parent || [];
                        parent.push(item.asset_category_name);
                        catalogData[item.id] = {
                            name: item.asset_category_name,
                            path: parent.join('/'),
                        };
                        if (item.children) {
                            handle(item.children, parent);
                        }
                    }
                });
            };
            getMetaCommonListTree(this.catalogModel, {
                labelField: 'asset_category_name',
                valueField: 'id',
                releaseField: 'parent_id',
                isLazy: false,
            }).then(res => {
                const column = this.findObject(this.searchAssetsOption.column, 'asset_category_code');
                column.dicData = res.data.data;
                handle(res.data.data || []);
                this.catalogData = catalogData;
                // (res.data.data || []).forEach
            });
        },
        openAddListDetailValue() {
            this.drawer = true;
        },
        getMetaCommonDetail() {
            this.pageLoading = true;
            getMetaCommonDetail(this.model, {
                pk: 'id',
                id: this.id,
                detailTable: this.detailTable,
                masterIdColumnName: this.masterIdColumnName,
            })
                .then(res => {
                    let data = res.data.data;
                    let listDetailValues = (data['listDetailValues'] || []).map(item => {
                        return {
                            ...item,
                            isEdit: false,
                        };
                    });

                    delete data['listDetailValues'];

                    this.form = data;
                    // this.searchUser(this.form.return_user_name);
                    // listDetailValues.forEach((item) => {
                    //   this.searchUser(item.inventory_user_name, "userList", item);
                    // });
                    this.listDetailValues = listDetailValues;
                    this.listDetailValuesFilter();
                    this.tablePage.total = this.listDetailValues.length;
                    this.pageLoading = false;
                })
                .catch(e => {
                    this.pageLoading = false;
                });
        },
        back() {
            this.$emit('back');
        },
        getMetaCommonList(code, prop, params, index, row) {
            return getMetaCommonList(`wisdom_park.${code}`, {
                current: 1,
                size: 500,
                ...params,
            }).then(res => {
                if (res.data && res.data.data && prop) {
                    if (!this.dictData[prop]) {
                        this.dictData[prop] = [];
                    }
                    if (index !== undefined) {
                        if (!this.dictData[prop][index]) {
                            this.dictData[prop][index] = res.data.data.records;
                        }
                        this.dictData[prop] = res.data.data.records;
                    } else {
                        this.dictData[prop] = res.data.data.records;
                    }
                }
                return res;
            });
        },
        searchModel(queryStr, modelCode, row, inputParams) {
            let params = {};
            if (queryStr) {
                params = {
                    wheres: [{ field: 'asset_name', op: 'LIKE', value: queryStr }],
                };
            }
            if (inputParams) {
                params['wheres'] = params.wheres || [];
                Object.keys(inputParams).forEach(key => {
                    params.wheres.push({
                        field: key,
                        value: inputParams[key],
                    });
                });
            }
            row.loading = true;
            return this.getMetaCommonList(modelCode, '', params)
                .then(res => {
                    row.assets.list = res.data.data.records || [];
                    row.loading = false;
                    return res;
                })
                .catch(e => {
                    row.assets.list = [];
                    row.loading = false;
                    return e;
                });
        },
        searchUser(query, prop, data) {
            prop = prop || 'userList';
            data = data || this.dictData;
            if (query) {
                this.loading.userLoading = true;
                searchList({
                    name: query,
                    tenantId: '000000',
                    size: 1,
                    page: 100,
                })
                    .then(res => {
                        data[prop] = res.data.data.records || [];
                        this.loading.userLoading = false;
                    })
                    .catch(e => {
                        this.loading.userLoading = false;
                    });
            } else {
                data[prop] = [];
            }
        },
        // userChange(){
        //   this.dictData.userList.forEach(
        //     item => {
        //       if(item.id === this.form.userChange){
        //         this.form.owner_code_name = item['name'];
        //       }
        //     }
        //   )
        // },
        addListDetailValue() {
            let itemObj = {
                assets: {
                    list: [],
                    loading: false,
                },
            };
            this.listValue.field.forEach(item => {
                if (typeof item === 'string') {
                    item = {
                        type: 'string',
                        code: item,
                    };
                }
                switch (item.type) {
                    case 'string':
                        itemObj[item.code] = '';
                        break;
                    case 'number':
                        itemObj[item.code] = null;
                        break;
                    case 'arrar':
                        itemObj[item.code] = [];
                        break;
                }
            });
            this.listDetailValues.push(itemObj);
        },
        getListDetailValues() {
            let listDetailValues = [];
            this.listDetailValues.forEach(item => {
                let master = [];
                if (typeof this.listValue.master === 'string') {
                    master = [this.listValue.master];
                } else {
                    master = this.listValue.master;
                }
                let add = true;
                master.forEach(name => {
                    if (item[name] === '' || item[name] === null || item[name] === undefined) {
                        add = false;
                    }
                });
                if (add) {
                    let newItem = {};
                    this.listValue.field.forEach(fieldItem => {
                        if (typeof fieldItem === 'string') {
                            fieldItem = {
                                type: 'string',
                                code: fieldItem,
                            };
                        }
                        let value =
                            (item[fieldItem.code] ?? '') !== ''
                                ? item[fieldItem.code]
                                : (fieldItem['default'] ?? '') !== ''
                                ? fieldItem['default']
                                : null;
                        switch (fieldItem.type) {
                            case 'string':
                                newItem[fieldItem.code] = value;
                                break;
                            case 'number':
                                newItem[fieldItem.code] = Number(value);
                                break;
                            case 'arrar':
                                newItem[fieldItem.code] = value.join(fieldItem.separate || '-');
                                break;
                        }
                    });
                    if (item.id) {
                        newItem['id'] = item.id;
                    }
                    if (item[this.listValue.relate]) {
                        newItem[this.listValue.relate] = item[this.listValue.relate];
                    }
                    listDetailValues.push(newItem);
                }
            });
            return listDetailValues;
        },
        startFlow(row) {
            return startFlow(row.id, this.$PCode.BIZ.ASSET_RETURN, this.$PCode.ACTIONS.SUBMIT);
        },
        saveForm(isStartFlow) {
            let listDetailValues = this.getListDetailValues();
            if (!listDetailValues.length) {
                this.$message({
                    type: 'error',
                    message: '请完善盘点资产',
                });
                return false;
            }

            this.$refs.form.validate(valid => {
                if (valid) {
                    let body = {
                        ...this.form,
                    };
                    if (body['location_name']) {
                        body['location_name'] = body['location_name'].join('-');
                    }
                    if (this.form.id) {
                        delete body.id;
                        body = {
                            update: body,
                            primaryKeyValue: this.form.id,
                            primaryKey: 'id',
                            listDetailValues: listDetailValues,
                        };
                    } else {
                        body = {
                            values: body,
                            listDetailValues: listDetailValues,
                        };
                    }
                    this.btnLoading = true;
                    (this.form.id ? updateMetaCommon : saveMetaCommon)(this.model, {
                        ...body,
                        detailTable: this.detailTable,
                        masterIdColumnName: this.masterIdColumnName,
                    })
                        .then(res => {
                            if (isStartFlow) {
                                return this.startFlow(this.form.id ? this.form : res.data.data).then(res1 => res1);
                            } else {
                                return res;
                            }
                        })
                        .then(res => {
                            this.btnLoading = false;
                            this.$message.success(res.data.msg);
                            this.back();
                        })
                        .catch(e => {
                            this.btnLoading = false;
                        });
                } else {
                    return false;
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.inventoryForm {
    /deep/.avue-crud__menu {
        display: none;
    }
    /deep/.el-drawer__header {
        margin-bottom: 0;
    }
    /deep/.el-drawer__body {
        padding-top: 0;
    }
    /deep/.avue-crud__search .avue-form {
        padding: 0;
    }
    /deep/.el-select {
        display: inline-block;
        position: relative;
        width: 100%;
    }
}
</style>

<template>
  <div>
    <div class="w-100p assets-tag-table mar-b-20">
      <table class="w-100p bor">
        <tr class="bor">
          <th colspan="3" class="lh-40 text-center"><b>企业信息</b></th>
        </tr>
        <tr class="bor">
          <td class="bor">企业名称</td>
          <td class="bor">{{ item.name }}</td>
          <td
            rowspan="5"
            class="pad-30 w-128"
            v-html="getAraleQrcode(item)"
          ></td>
        </tr>
        <tr class="bor">
          <td class="bor">统一社会信用代码</td>
          <td class="bor">{{ item.uniform_social_credit_code || "-" }}</td>
        </tr>
        <tr class="bor">
          <td class="bor">企业法人</td>
          <td class="bor">{{ item.legal_person || "-" }}</td>
        </tr>
        <tr class="bor">
          <td class="bor">所属行业</td>
          <td class="bor">{{ item.industry || "-" }}</td>
        </tr>
        <!-- <tr class="bor">
          <td class="bor">使用部门</td>
          <td class="bor">{{ item.using_unit }}</td>
        </tr>
        <tr class="bor">
          <td class="bor">存放位置</td>
          <td class="bor">{{ item.storage_location }}</td>
        </tr> -->
      </table>
      <div class='desicribe'>
        <span>请将该二维码下发给新入职员工，并在公众号“服务-员工入驻”页面，点击“已有企业签名”标签进行扫描认证</span>
        <span>注意：该二维码生成后30分钟有效！</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { createSign } from "@/api/visitor/visitor";
import {
  getMetaCommonList,
  getPicListByIds,
  getMetaCommonInfo,
  updateMetaCommon,
  saveMetaCommon,
} from "@/api/busiMode/metaCommon";
export default {
  data() {
    return {
      model: "wisdom_park.wp_company",
      item: {},
      serData: "",
    };
  },
  props: {},
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  mounted() {
    if (this.userInfo.companyId && this.userInfo.tenant_id == "000002") {
      //this.ruleForm.companyId = this.userInfo.companyId;
      this.getMetaCommonInfo();
      this.createSign();
    }
  },
  methods: {
    createSign() {
      createSign({ id: this.userInfo.companyId, isAgainCreate: false }).then(
        (res) => {
          if (res.data.code == 200) {
            this.serData = res.data.data;
          }
        }
      );
    },
    getAraleQrcode() {
      let svg = new AraleQRCode({
        render: "svg",
        text: this.serData,
        size: 128,
      });
      console.log(svg.outerHTML);
      return svg.outerHTML;
    },
    getMetaCommonInfo() {
      let obj = {
        pk: "id",
        id: this.userInfo.companyId,
      };
      getMetaCommonInfo(this.model, obj).then((res) => {
        if (res.data.code == 200) {
          this.item = res.data.data;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.assets-tag-table {
  td {
    padding: 0 15px;
  }
}
.desicribe {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  line-height: 22px;
}

</style>
<style lang='scss'>
.join-dialog {
  margin-bottom: 0px !important;
}
</style>

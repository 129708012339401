<template>
<div v-loading="loading">
  <el-form v-if="!loading" ref="form" :disabled="disabled" :model="form" :rules="rules" size="small" label-width="100px" label-position="top">
    <el-row>
      <el-col :span="6">
        <el-form-item label="合同编号" prop="code">
          <el-input placeholder="请填写合同编号" v-model="form.code"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="合同名称" prop="name">
          <el-input placeholder="请填写合同名称" v-model="form.name"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="开始日期" prop="startDate">
          <el-date-picker class="w-100p" value-format="yyyy-MM-dd" v-model="form.startDate" type="date" placeholder="年 / 月 / 日"></el-date-picker>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="结束日期" prop="endDate">
          <el-date-picker :picker-options="pickerOptions" class="w-100p" value-format="yyyy-MM-dd" v-model="form.endDate" type="date" placeholder="年 / 月 / 日"></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="mar-b-10">
        <el-divider content-position="left"><span class="is-required-text">租赁位置</span></el-divider>
      </el-col>
      <el-col>
        <el-table
          :data="subjects"
          size="small"
          border
        >
          <el-table-column
            type="index"
            width="65px"
            v-if="!disabled"
          >
            <template slot="header">
              <el-button size="mini" @click="addSelectRoomList()" type="primary" icon="el-icon-plus" circle></el-button>
            </template>
            <template slot-scope="scope">
              <el-button size="mini" @click="subjects.splice(scope.$index , 1);" type="danger" icon="el-icon-minus" circle></el-button>
            </template>
          </el-table-column>
          <el-table-column prop="values" label="房间">
            <span slot="header" class="is-required-text">房间</span>
            <template slot-scope="{row , $index}">
              <el-cascader
                :disabled="disabled"
                class="dis-b"
                size="small"
                clearable
                filterable
                v-model="row.values"
                :options="subjectData"
                :props="{
                  value: 'id',
                  label: 'name'
                }"
                @change="(ev) => handleChange(ev , $index)"
              ></el-cascader>
            </template>
          </el-table-column>
          <el-table-column prop="roomArea" label="租赁面积" width="180">
            <template slot-scope="{row , $index}">
              <el-input :disabled="disabled" size="small" type="number" :max="row.roomId && rooms[row.roomId] && rooms[row.roomId].roomArea ? rooms[row.roomId].roomArea : 1000000" :min="0" placeholder="请输入内容" v-model="row.roomArea">
                <template slot="append">{{row.roomId && rooms[row.roomId] && rooms[row.roomId].roomArea ? '/' + rooms[row.roomId].roomArea : ''}}m²</template>
              </el-input>
            </template>
          </el-table-column>
          <el-table-column  width="160px" label="租赁开始时间">
            <span slot="header" class="is-required-text">租赁开始时间</span>
            <template slot-scope="scope">
              <el-date-picker :disabled="disabled" :picker-options="{disabledDate: (time) => {
                return roomDatePickerPropsDisabledDate('start' , scope.row , time);
              }}" size="small" class="w-100p" value-format="yyyy-MM-dd" v-model="scope.row.startDate" type="date" placeholder="年 / 月 / 日"></el-date-picker>
            </template>
          </el-table-column>
          <el-table-column  width="160px" label="租赁结束时间">
            <span slot="header" class="is-required-text">租赁结束时间</span>
            <template slot-scope="scope">
              <el-date-picker :disabled="disabled" :picker-options="{disabledDate: (time) => {
                return roomDatePickerPropsDisabledDate('end' , scope.row , time);
              }}" size="small" class="w-100p" value-format="yyyy-MM-dd" v-model="scope.row.endDate" type="date" placeholder="年 / 月 / 日"></el-date-picker>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="selectRoomListError" style="position: static" class="el-form-item__error">请完善租赁位置信息</div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="mar-b-10">
        <el-divider content-position="left">出租方信息</el-divider>
      </el-col>
      <el-col :span="6">
        <el-form-item label="签订人" prop="lessorSigned">
          <el-input placeholder="请填写签订人" v-model="form.lessorSigned"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="签订人联系方式" prop="lessorSignedContact">
          <el-input placeholder="请填写签订人联系方式" v-model="form.lessorSignedContact"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="mar-b-10">
        <el-divider content-position="left">承租方信息</el-divider>
      </el-col>
      <el-col :span="6">
        <el-form-item label="公司" prop="rentersCompanyId">
          <model-search-select
            v-model="form.rentersCompanyId"
            model="wp_company"
            :fillModel.sync="form"
            :fills="[
              {source: 'name' , target: 'rentersName'},
              {source: 'principal' , target: 'corporationPersonality'},
              {source: 'registered_address' , target: 'rentersAddress'}
            ]"
          >
          </model-search-select>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="公司地址" prop="rentersAddress">
          <el-input placeholder="请填写公司地址" v-model="form.rentersAddress"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="公司企业法人" prop="corporationPersonality">
          <el-input placeholder="请填写公司企业法人" v-model="form.corporationPersonality"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="签订人" prop="rentersSigned">
          <el-input placeholder="请填写签订人" v-model="form.rentersSigned"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="签订人联系方式" prop="rentersSignedContact">
          <el-input placeholder="请填写签订人联系方式" v-model="form.rentersSignedContact"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="mar-b-10">
        <el-divider content-position="left">其他信息</el-divider>
      </el-col>
      <el-col :span="6">
        <el-form-item label="租金总金额" prop="amount">
          <el-input placeholder="请填写租金总金额" v-model="form.amount">
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="保证金" prop="deposit">
          <el-input placeholder="请填写租赁面积" v-model="form.deposit"><template slot="append">元</template></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="租赁面积" prop="rentArea">
          <el-input placeholder="请填写租赁面积" v-model="form.rentArea"><template slot="append">㎡</template></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="6">
        <el-form-item label="合同签订时间" prop="signedDate">
          <el-date-picker class="w-100p" value-format="yyyy-MM-dd HH:mm:ss" v-model="form.signedDate" type="datetime" placeholder="年 / 月 / 日"></el-date-picker>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24" class="mar-b-10">
        <el-divider content-position="left">纸质附件</el-divider>
      </el-col>
      <el-col>
        <form-file v-model="form.attachmentIds" :disabled="disabled"></form-file>
      </el-col>
    </el-row>
  </el-form>
</div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { 
  getSubContractDetail , 
  getContractSubject ,
  saveSubContract ,
  updateSubContract
} from '@/api/contract/sub';
import dayjs from 'dayjs';

export default {
  data(){
    return {
      saveLoading: false,
      loading: true,
      form: {
        name: '',
        code: '',
        companyId: this.userInfo ? this.userInfo.companyId : '',
        lessorSigned: '',
        lessorSignedContact: '',
        rentersName: '',
        rentersCompanyId: '',
        rentersAddress: '',
        corporationPersonality: '',
        rentersSigned: '',
        rentersSignedContact: '',
        amount: null,
        rentArea: null,
        deposit: null,
        signedDate: '',
        startDate: '',
        endDate: '',
        attachmentIds: ''
      },
      selectRoomListError: false,
      rules: {
        name: [{ required: true, message: '请输入合同名称', trigger: 'blur' }],
        amount: [{ required: true, message: '请输入租金总金额', trigger: 'blur' }],
        code: [{ required: true, message: '请输入合同编号', trigger: 'blur' }],
        lessorSigned: [{ required: true, message: '请输入出租方签订人', trigger: 'blur' }],
        lessorSignedContact: [{ required: true, message: '请输入出租方签订人联系方式', trigger: 'blur' }],
        rentersCompanyId: [{ required: true, message: '请选择承租方', trigger: 'blur' }],
        rentersSigned: [{ required: true, message: '请输入承租方签订人', trigger: 'blur' }],
        rentersSignedContact: [{ required: true, message: '请输入承租方签订人联系方式', trigger: 'blur' }],
        startDate: [{ required: true, message: '请选择租赁开始时间', trigger: 'blur' }],
        endDate: [{ required: true, message: '请选择租赁结束时间', trigger: 'blur' }],
      },
      subjects: [],
      subjectData: [],
      rooms: {},
      pickerOptions: {
        disabledDate: (time) => {
          let start_time = dayjs(this.form.startDate).valueOf();
          return time.getTime() < start_time;
        }
      },
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    disabled(){
      return this.type === 'view' ? true : false
    }
  },
  props: {
    id: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: 'add'
    }
  },
  mounted(){
    this.getContractSubject();
    if(this.id){
      this.loading = true;
      this.getDetail();
    }
  },
  methods: {
    roomDatePickerPropsDisabledDate(type , row , time){
      if(row.roomId && this.rooms[row.roomId].startDate && this.rooms[row.roomId].endDate){
        let Time = time.getTime();
        let startDate = dayjs(this.rooms[row.roomId].startDate).valueOf();
        let endDate = dayjs(this.rooms[row.roomId].endDate).valueOf();
        if(type === 'end'){
          startDate = dayjs(row.start_date).valueOf();
        }
        return Time < startDate || Time > endDate;
      }else{
        return false
      }
    },
    handleChange(ev , index){
      if(ev && ev.length){
        let building = this.subjectData.find(a => a.id === ev[0]);
        let floor = building ? building.children.find(a => a.id === ev[1]) : null;
        let room = floor ? floor.children.find(a => a.id === ev[2]) : null;
        if(room){
          this.subjects[index].floorId = floor.id;
          this.subjects[index].floorName = floor.name;
          this.subjects[index].buildingId = building.id;
          this.subjects[index].buildingName = building.name;
          this.subjects[index].roomId = room.id;
          this.subjects[index].roomName = room.name;
          this.subjects[index].roomArea = room.area;
        }
      }else{
        ['floor' , 'building' , 'room'].forEach(
          item => {
            this.subjects[index][`${item}Id`] = '';
            this.subjects[index][`${item}Name`] = '';
          }
        );
        this.subjects[index].roomArea = null;
      }
    
    },
    addSelectRoomList(){
      this.subjects.push({
        subContractId: this.id || null,
        roomId: '',
        buildingId: '',
        floorId: '',
        roomName: '',
        floorName: '',
        buildingName: '',
        roomArea: null,
        startDate: '',
        endDate: '',
        values: []
      })
    },
    getDetail(){
      getSubContractDetail({
        id: this.id
      }).then(
        res => {
          this.form = {
            ...this.form,
            ...res.data.data
          }
          this.subjects = this.form.subContractSubjects;
          this.subjects.forEach(
            item => {
              item['values'] = [item.buildingId , item.floorId , item.roomId];
              if(this.type === 'copy'){
                delete item['id'];
                delete item['sub_contract_id']
              }
            }
          );
          if(this.type === 'copy'){
            delete this.form['id'];
          }
          delete this.form['subContractSubjects'];
          this.loading = false;
        }
      )
    },
    getContractSubject(){
      getContractSubject().then(
        res => {
          let data = this.transformSubjectData(res.data.data);
          this.rooms = {};
          res.data.data.forEach(
            item => {
              this.rooms[item.roomId] = {
                ...item
              }
            }
          )
          this.subjectData = data;
        }
      )
    },
    transformSubjectData(res){
      let data = {};
      res.forEach(
        item => {
          if(!data[item.buildingId]){
            data[item.buildingId] = {
              name: item.buildingName,
              id: item.buildingId,
              children: []
            }
          }
          let floor = data[item.buildingId].children.find(a => a.id === item.floorId);
          if(!floor){
            floor = {
              name: item.floorName,
              id: item.floorId,
              children: []
            }
           data[item.buildingId].children.push(floor);
          }
          floor.children.push({
            floorId: item.floorId,
            floorName: item.floorName,
            buildingName: item.buildingName,
            buildingId: item.buildingId,
            name: item.roomName,
            id: item.roomId,
            area: item.roomArea,
            endDate: item.endDate,
            startDate: item.startDate,
          })
        }
      );
      return Object.values(data)
    },
    save(){
      return new Promise((resolve) => {
        if(!this.subjects.length){
          this.selectRoomListError = true;
        }else{
          this.selectRoomListError = false;
        }
        let subContractSubjects = []; 
        this.subjects.forEach(
          item => {
            if(!item.startDate || !item.endDate){
              this.selectRoomListError = true;
            }
            if(!item.roomId || !item.floorId || !item.buildingId){
              this.selectRoomListError = true;
            }
            let newItem = {
              ...item,
              // startDate: dayjs(item.startDate).format('YYYY-MM-DD 00:00:00'),
              // endDate: dayjs(item.endDate).format('YYYY-MM-DD 23:59:59'),
            }
            delete newItem.values;
            subContractSubjects.push(newItem);
          }
        );
        this.$refs.form.validate(valid => {
          if(valid && this.selectRoomListError === false){
            let body = {
              ...this.form,
              subContractSubjects: subContractSubjects,
              companyId: this.form.companyId || this.userInfo.companyId || null,
              startDate: dayjs(this.form.startDate).format('YYYY-MM-DD 00:00:00'),
              endDate: dayjs(this.form.endDate).format('YYYY-MM-DD 23:59:59')
            };
            (this.id && this.type !== 'copy' ? updateSubContract : saveSubContract)(body).then(
              res => {
                resolve(true);
              }
            ).catch(e => {
              resolve(false)
            })
          }else{
            resolve(false)
          }
        })
      })
    }
  }
}
</script>
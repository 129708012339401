<template>
  <div class="interactive h-100p">
    <el-row type="flex" class="h-100p">
      <el-dialog title="新建" :visible.sync="chooseModelbox" modalAppendToBody>
        <el-row>
          <div data-v-3e875892="" class="add-main">
            <div
              data-v-3e875892=""
              class="add-item add-item-form"
              @click="createNew('form')"
            >
              <i data-v-3e875892="" class="add-icon el-icon-document"></i>
              <div data-v-3e875892="" class="add-txt">
                <p data-v-3e875892="" class="add-title">表单</p>
                <p data-v-3e875892="" class="add-desc">纯表单设计</p>
              </div>
            </div>
            <div
              data-v-3e875892=""
              class="add-item add-item-list"
              @click="createNew('table')"
            >
              <i
                data-v-3e875892=""
                class="add-icon icon-ym icon-ym-sysMenu"
              ></i>
              <div data-v-3e875892="" class="add-txt">
                <p data-v-3e875892="" class="add-title">列表</p>
                <p data-v-3e875892="" class="add-desc">表单+列表设计</p>
              </div>
            </div>
            <div data-v-3e875892="" class="add-item" @click="createNew('lc')">
              <i
                data-v-3e875892=""
                class="add-icon icon-ym icon-ym-generator-company"
              ></i>
              <div data-v-3e875892="" class="add-txt">
                <p data-v-3e875892="" class="add-title">流程</p>
                <p data-v-3e875892="" class="add-desc">表单+列表+流程设计</p>
              </div>
            </div>
          </div>
        </el-row>
      </el-dialog>
      <el-drawer
        title="预览"
        size="80%"
        :visible.sync="previewBox"
        modalAppendToBody
        appendToBody
      >
        <el-row>
          <tablePreview></tablePreview>
        </el-row>
      </el-drawer>

      <el-drawer
        title="设置为菜单"
        size="40%"
        :visible.sync="menuBox"
        modalAppendToBody
        appendToBody
      >
        <el-row><createMenu></createMenu></el-row>
      </el-drawer>
      <div v-if="appVisible">
        <el-dialog
          :title="title"
          width="30%"
          append-to-body
          destroy-on-close
          :visible.sync="appVisible"
        >
          <avue-form
            ref="form"
            v-model="appObj"
            :option="appOption"
            @submit="appSubmit"
          >
          </avue-form>
        </el-dialog>
      </div>

      <el-col :span="4" class="w-300">
        <el-card class="h-100p ov-a">
          <el-input
            style="margin-bottom: 15px; width: calc(100% - 68px)"
            size="small"
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
            v-model="filterText"
          >
          </el-input>
          <el-button
            style="margin-left: 5px"
            type="primary"
            @click="getTree"
            size="mini"
            icon="el-icon-refresh"
            circle
          ></el-button>
          <el-button
            style="margin-left: 5px"
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="appModelVis"
            circle
          ></el-button>
          <el-tree
            node-key="id"
            ref="tree"
            element-loading-text="拼命加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.2)"
            :data="treeData"
            @node-click="getModel"
            :props="treeProps"
            :filter-node-method="filterNode"
            @node-contextmenu="contextMenuOperation"
          >
            <template slot-scope="{ node, data }">
              <span slot="reference">
                <i :class="data.source"></i>
                <span :title="data.name" class="mar-l-5">{{ data.name }}</span>
              </span>
            </template>
          </el-tree>
        </el-card>
      </el-col>
      <el-col :span="20" class="w-calc-300">
        <el-card>
          <avue-crud
            ref="crud"
            :option="option"
            :data="tableDatas"
            :page.sync="page"
            v-model="form"
            :table-loading="tableLoading"
            @row-save="rowSave"
            @row-del="rowDel"
            @row-update="rowUpdate"
            @on-load="onLoad"
            @refresh-change="onLoad"
          >
            <template slot-scope="{ row }" slot="menuLeft">
              <el-dropdown>
                <span class="el-dropdown-link">
                  <el-button type="primary" icon="el-icon-plus" size="small">
                    新 增
                  </el-button>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="form">
                    <span @click="newDesi('form')">表单</span></el-dropdown-item
                  >
                  <el-dropdown-item command="table"
                    ><span @click="newDesi('table')"
                      >表格</span
                    ></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>

              <el-button
                type="primary"
                icon="el-icon-upload2"
                size="small"
                plain
                @click.stop="handleDataScope(row)"
              >
                导 入
              </el-button>
            </template>
            <template slot="menuLeft">
              <el-button type="primary" size="small" icon="el-icon-plus"
                >新增
              </el-button>
            </template>
            <template slot="menu" slot-scope="scope">
              <el-button
                type="text"
                size="small"
                icon="el-icon-edit"
                @click="goDesign(scope.row)"
                >设计
              </el-button>
              <el-button
                type="text"
                size="small"
                icon="el-icon-view"
                @click="previewTable(scope.row)"
                >预览
              </el-button>
              <el-button
                type="text"
                size="small"
                icon="el-icon-paperclip"
                @click="menuBox = true"
                >设为菜单
              </el-button>
              <el-button
                type="text"
                size="small"
                icon="el-icon-delete"
                plain
                @click="rowDel"
                >删 除
              </el-button>
            </template>
          </avue-crud>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import tablePreview from "../../data/table/table/preview/preview";
import tableDesign from "../../data/table/table/design/design";
import createMenu from "./createMenu";
import { appOption, appModelOption, tableOption } from "../util/option";
import { dateFormat } from "@/util/date";

import {
  getAppModelDetail,
  saveAppMode,
  updateAppMode,
  delAppMode,
  getTree,
} from "@/api/dataService/appModel";

import {
  getDataAppList,
  saveApp,
  updateApp,
  delApp,
} from "@/api/dataService/dataApp";
// import { appModel } from "../../dataService/appModel";
import { getDynamicFormList } from "@/api/busiMode/meta";
let id = 1000;
export default {
  name: "tableList",
  components: { tableDesign, createMenu, tablePreview },
  data() {
    return {
      treeProps: {
        label: "name",
        children: "children",
      },
      appOption: appOption,
      appVisible: false,
      filterText: "",
      chooseModelbox: false,
      previewBox: false,
      menuBox: false,
      tableLoading: false,
      form: {},
      appObj: {},
      page: {},
      node: {},
      checkedNode: {},
      title: {},
      tableDatas: [
        { a1: "test", model: "用户管理", app: "接处警后管", type: "table" },
        { a1: "test", model: "用户管理", app: "接处警后管", type: "form" },
        { a1: "test", model: "用户管理", app: "接处警后管" },
        { a1: "test", model: "用户管理", app: "接处警后管" },
      ],
      treeData: [],
      options: [
        {
          value: "选项1",
          label: "接处警",
        },
        {
          value: "选项2",
          label: "实战",
        },
      ],
      option: tableOption,
    };
  },
  created() {
    this.getTree();
  },
  watch: {
    filterText(val) {
      console.log(this.$refs.tree);
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    newDesi(type) {
      console.log("this.node", this.checkedNode);
      if (!this.checkedNode.id) {
        this.$message({
          type: "error",
          message: "请选择一个应用或者模块",
        });
        return false;
      }
      if (type == "table") {
        this.createNew("table");
      } else if (type == "form") {
        this.createNew("form");
      }
    },
    append(data) {
      const newChild = { id: id++, label: "testtest", children: [] };
      if (!data.children) {
        this.$set(data, "children", []);
      }
      data.children.push(newChild);
    },
    appSubmit() {
      this.appObj.createTime = dateFormat(new Date());
      Object.keys(this.appObj).forEach((ele) => {
        if (ele.includes("$")) delete this.appObj[ele];
      });
      if (!this.appObj.id) {
        if (this.appObj.type == "A" || this.appObj.type == "M") {
          saveAppMode(this.appObj)
            .then((res) => {
              const newChild = {
                id: "574567",
                name: this.appObj.name,
                children: [],
                type: "M",
              };
              this.$refs.tree.append(newChild, this.node);
              this.appVisible = false;
            })
            .catch((e) => {});
        } else {
          saveApp(this.appObj)
            .then((res) => {
              const newChild = {
                id: "57456723",
                datasourceId: this.appObj.datasourceId,
                name: this.appObj.appName,
                children: [],
                type: "A",
              };
              this.$refs.tree.append(newChild);
              this.appVisible = false;
            })
            .catch((e) => {});
        }
      } else {
        let dataNode = this.$refs.tree.getNode(this.appObj.id);
        if (this.appObj.type == "A") {
          updateApp(this.appObj).then((res) => {
            this.$set(dataNode.data, "name", this.appObj.appName);
            this.appVisible = false;
          });
        } else {
          updateAppMode(this.appObj).then((res) => {
            this.$set(dataNode.data, "name", this.appObj.name);
            this.appVisible = false;
          });
        }
      }
    },
    appModelVis(obj) {
      this.appObj = {};
      console.log(obj);
      if (obj.type == "A" || obj.type == "M") {
        this.appOption = appModelOption;
        this.appObj["appName"] = obj.name;
        this.appObj["parentId"] = obj.id;
        this.appObj["appId"] = obj.appId;

        this.appObj.type = obj.type;
        this.title = "新建模型";
      } else {
        this.title = "新建应用";
        this.appOption = appOption;
      }
      this.appVisible = true;
    },
    updateModelVis(item, obj) {
      this.appObj = obj;
      if (obj.type == "A") {
        this.appObj["appName"] = obj.name;
        this.appOption = appOption;
        this.title = "修改应用";
        this.appVisible = true;
      } else {
        this.appOption = appModelOption;
        getAppModelDetail({ id: obj.id }).then((res) => {
          this.appObj = res.data.data;
          this.title = "修改模型";
          this.appVisible = true;
        });
      }
    },
    contextMenuOperation(ev, data, node) {
      this.node = node;
      let menus = [];
      if (data.type == "A") {
        menus = [
          { name: "新增模块", code: 1, icon: "el-icon-plus" },
          { name: "修改", code: 2, icon: "el-icon-edit-outline" },
          { name: "删除", code: 6, icon: "el-icon-delete" },
        ];
      } else {
        menus = [
          { name: "新增功能", code: 1, icon: "el-icon-plus" },
          { name: "修改", code: 2, icon: "el-icon-edit-outline" },
          { name: "删除", code: 6, icon: "el-icon-delete" },
        ];
      }
      this.$contextmenu({
        menus: menus,
        props: {
          label: "name",
          divider: "isDivider",
          type: "menuType",
        },
        x: ev["x"],
        y: ev["y"],
      }).click((item) => {
        this.excueteCommand(item, data);
      });
    },
    excueteCommand(item, data) {
      if (item.code == 6) {
        //删除
        this.handleDel(data);
      } else if (item.code == 2) {
        //修改
        this.updateModelVis(item, data);
      } else if (item.code == 1) {
        //新增
        this.appModelVis(data);
      }
    },
    handleDel(row) {
      let _t = this;
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        if (row.type == "A") {
          delApp({
            ids: row["id"],
          }).then((res) => {
            _t.$refs.tree.remove(row);
          });
        } else {
          delAppMode({
            ids: row["id"],
          }).then((res) => {
            _t.$refs.tree.remove(row);
          });
        }
      });
    },
    getTree() {
      getTree()
        .then((res) => {
          this.treeData = res.data.data;
        })
        .catch((e) => {});
    },
    getModel(node) {
      console.log(node);
      this.checkedNode = node;
      if (node.type == "A") {
        this.getDynamicFormList(node.id, "appId");
      } else if (node.type == "M") {
        this.getDynamicFormList(node.id, "appModelId");
      }
    },
    getDynamicFormList(id, type) {
      let obj = {
        current: this.page.currentPage,
        size: this.page.pageSize,
      };
      if (id) {
        obj[type] = id;
      }
      getDynamicFormList(obj)
        .then((res) => {
          this.tableLoading = false;
          this.tableDatas = res.data.data.records;
          this.page.total = res.data.data.total;
        })
        .catch((e) => {
          this.tableLoading = false;
        });
    },
    onLoad() {
      this.tableLoading = true;
      this.getDynamicFormList();
    },
    createNew(type) {
      console.log(this.checkedNode);
      if (type === "table") {
        this.$router.push({
          path: "/data/table/tableCommon",
          query: {
            appId: this.checkedNode.appId,
            id: this.checkedNode.id,
          },
        });
      } else {
        this.$router.push({
          path: "/data/form/formCommon",
        });
      }
    },
    previewTable(row) {
      console.log(row);
      this.$breadcrumb.add({
        name: "测试的表单",
        path: this.$router.currentRoute.fullPath,
      });

      this.$router.push({
        path: "/data/table/tableCommon" + "/?modleId" + row.guid,
      });
    },
    goDesign() {
      this.$breadcrumb.add({
        name: "表单设计",
        path: this.$router.currentRoute.fullPath,
      });
      this.$router.push({
        path: "/data/table/design",
        query: { isPre: false },
      });
    },
    rowUpdate(row, index, done) {
      this.tableDatas.splice(index, 1, row);
      done();
    },
    handleAdd() {
      this.$refs.crud.rowAdd();
    },
    rowSave(row, done) {
      //   this.$refs.crud.rowAdd();
      this.tableDatas.push(row);
    },
    rowDel(row, index) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableDatas.splice(index, 1);
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss">
.add-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.add-main .add-item {
  width: 230px;
  height: 136px;
  background: #eff9ff;
  cursor: pointer;
  padding-left: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 15px;
}
.add-main .add-item .add-icon[data-v-3e875892] {
  width: 56px;
  height: 56px;
  margin-right: 10px;
  background: #ceeaff;
  border-radius: 10px;
  color: #46adfe;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 30px;
  line-height: 56px;
  text-align: center;
}
.add-main .add-item.add-item-list[data-v-3e875892] {
  background: #fef3e6;
}
.add-main .add-item.add-item-form[data-v-3e875892] {
  background: #f1f5ff;
}
</style>

<template>
    <div>
        <div style="height: 67vh; overflow-x: hidden !important" class="ov-a mar-t-15 pad-l-20 pad-r-20">
            <el-form :model="ruleForm" ref="ruleForm" label-width="100px" label-position="top" size="small" :disabled="onlyRead" :rules="ruleModel">
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="房源名称" prop="name">
                            <el-input v-model="ruleForm.name" placeholder="请选择房源名称"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="房源面积(m²)" prop="area">
                            <el-input type="number" v-model.number="ruleForm.area" placeholder="请选择房源面积"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="装修情况" prop="name">
                            <el-select v-model="ruleForm.renovation" size="small" placeholder="请选择装修情况">
                                <el-option v-for="item in decorationList" :key="item.value" :label="item.name" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="楼层">
                            <el-radio-group v-model="ruleForm.storey">
                                <el-radio label="1">底层</el-radio>
                                <el-radio label="2">中层</el-radio>
                                <el-radio label="3">高层</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6" v-if="ruleForm.subject_type == 0 || ruleForm.subject_type == 1">
                        <el-form-item label="所属楼宇" prop="building_id">
                            <el-select v-model="ruleForm.building_id" size="small" placeholder="请选择" @change="changeFloor">
                                <el-option v-for="item in buildList" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6" v-if="ruleForm.subject_type == 0">
                        <el-form-item label="所属楼层" prop="floor_id">
                            <el-select v-model="ruleForm.floor_id" size="small" placeholder="请选择">
                                <el-option v-for="item in floors" :key="item.id" :label="item.code" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="房源用途">
                            <el-radio-group v-model="ruleForm.housing_resources_type">
                                <el-radio :label="1">办公</el-radio>
                                <el-radio :label="2">商铺</el-radio>
                                <el-radio :label="3">公寓</el-radio>
                                <el-radio :label="4">其他</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="建成年代" prop="building_year">
                            <div class="block">
                                <el-date-picker v-model="ruleForm.building_year" type="year" placeholder="选择年">
                                </el-date-picker>
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="租售方式" prop="rental_sales_type">
                            <el-input v-model="ruleForm.rental_sales_type" placeholder="请填写租售方式"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="出租单价" prop="num">
                            <el-input type="number" @input.native="setMonthPrice" v-model.number="ruleForm.rental_unit_price_day" placeholder="出租单价">
                                <template slot="append">元/m²/天</template></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="月租金" prop="hight">
                            <el-input type="number" @input.native="setMonthPriceSelf" v-model.number="ruleForm.rental_unit_price_month">
                                <template slot="append">元/月</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="物业费" prop="property_management_fee">
                            <el-input v-model="ruleForm.property_management_fee" placeholder="物业费">
                                >
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="付款方式" prop="payment_type">
                            <el-select v-model="ruleForm.payment_type" size="small" placeholder="请选择付款方式">
                                <el-option v-for="item in paymentTypeList" :key="item.value" :label="item.name" :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="发布人" prop="publish_user">
                            <el-input v-model="ruleForm.publish_user" placeholder="发布人" disabled>
                                >
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="联系人" prop="contact_person">
                            <el-input v-model="ruleForm.contact_person" placeholder="联系人">
                                >
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="联系电话" prop="contact_phone">
                            <el-input v-model="ruleForm.contact_phone" placeholder="联系电话">
                                >
                            </el-input>
                        </el-form-item>
                    </el-col>

                    <el-col :span="6">
                        <el-form-item label="发布时间" prop="publish_time">
                            <el-date-picker value-format="yyyy-MM-dd" v-model="ruleForm.publish_time" type="date" placeholder="年 / 月 / 日"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="6">
                        <el-form-item label="可入驻时间" prop="immigration_time">
                            <el-date-picker value-format="yyyy-MM-dd" v-model="ruleForm.immigration_time" type="date" placeholder="年 / 月 / 日"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="发布状态">
                            <el-radio-group v-model="ruleForm.online_status">
                                <el-radio :label="1">上线</el-radio>
                                <el-radio :label="2">下线</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="出租状态">
                            <el-radio-group v-model="ruleForm.status">
                                <el-radio :label="0">未出租 </el-radio>
                                <el-radio :label="1">已出租</el-radio>
                                <el-radio :label="2">已售 </el-radio>
                                <el-radio :label="3">自用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="18">
                        <el-form-item label="房源地址" prop="address">
                            <el-input v-model="ruleForm.address" placeholder="请选择房源地址" type="textarea"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="房源特色介绍" prop="features">
                            <el-input type="textarea" v-model="ruleForm.features"> </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="房源服务介绍" prop="service_note">
                            <el-input type="textarea" v-model="ruleForm.service_note">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" class="h-100">
                        <el-form-item label="房源标签" prop="label" v-if="!onlyRead">
                            <el-popover placement="top-start" title="添加标签" width="300" trigger="click">
                                <el-button slot="reference" icon="el-icon-plus"></el-button>
                                <div style="height: 74px">
                                    <template v-for="tag in roomTags">
                                        <!-- v-if="ruleForm.label" -->
                                        <template>
                                            <el-tag class="cur-p mar-r-5 mar-b-5" v-if="ruleForm.label.indexOf(tag.id) < 0" :key="tag.id" :disable-transitions="false" @click="addLable(tag)">
                                                {{ tag.name }}
                                            </el-tag>
                                        </template>
                                    </template>
                                </div>
                            </el-popover>

                            <template v-if="ruleForm.label">
                                <template v-for="(tag, index) in ruleForm.label.split(',')">
                                    <el-tag v-if="getTagName(tag) != ''" :key="tag" closable :disable-transitions="false" class="mar-l-5 mar-b-5" @close="delLable(index)">
                                        {{ getTagName(tag) }}
                                    </el-tag>
                                </template>
                            </template>
                        </el-form-item>
                        <el-form-item label="房源标签" prop="label" v-if="onlyRead">
                            <template v-if="ruleForm.label">
                                <el-tag :key="tag" v-for="(tag, index) in ruleForm.label.split(',')" :disable-transitions="false" class="mar-l-5 mar-b-5" @close="ruleForm.label.splice(index, 1)">
                                    {{ getTagName(tag) }}
                                </el-tag>
                                <div class="pad-10" v-if="ruleForm.label.length == 0">
                                    暂无标签
                                </div>
                            </template>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row>
          <el-col :span="12">
            <el-form-item label="3D房源查看地址" prop="online_vr">
              <el-input
                v-model="ruleForm.online_vr"
                placeholder="请输入3D房源查看地址"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row> -->
                <el-divider content-position="left">房源图片</el-divider>
                <avue-form :option="option" v-model="imgform"></avue-form>
                <template v-if="onlyRead">
                    <div class="pad-10 pad-b-20" v-if="imgform.imgUrl.length == 0">
                        暂无照片
                    </div>
                </template>
            </el-form>
        </div>
        <div class="btnClass bor-t">
            <el-button v-if="onlyRead" class="pull-right mar-t-10" size="small" type="" @click="close">关闭</el-button>
            <el-button v-if="!onlyRead" class="pull-right mar-t-10" size="small" type="" @click="close">取消</el-button>
            <el-button v-if="!onlyRead" size="small" class="pull-right mar-r-10 mar-t-10" type="primary" @click="save">保存</el-button>
        </div>
    </div>
</template>

<script>
import {
    getMetaCommonList,
    getPicListByIds,
    getMetaCommonInfo,
    updateMetaCommon,
    saveMetaCommon,
} from '@/api/busiMode/metaCommon';
import * as dictbizService from '@/api/system/dictbiz';
import standardService from '@/api/metadata/standard';
import { mapGetters, mapState } from 'vuex';
import { getUser } from '@/api/system/user';

export default {
    data() {
        return {
            ruleForm: {
                status: 1,
                online_status: 2,
            },
            ruleModel: {},
            roomTags: [],
            dialogVisible: false,
            page: {
                size: 1000,
                current: 1,
                total: 0,
            },
            buildList: [],
            testA: '3',
            floors: [],
            decorationList: [],
            paymentTypeList: [],
            housingResourcesTypes: [],
            houseModel: 'wisdom_park.wp_housing_resources',
            hrType: '',
            option: {
                labelWidth: 0,
                submitBtn: false,
                emptyBtn: false,
                column: [
                    {
                        label: '',
                        prop: 'imgUrl',
                        type: 'upload',
                        listType: 'picture-card',
                        loadText: '附件上传中，请稍等',
                        span: 24,
                        // disabled: true,
                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '只能上传jpg/png文件，且不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },
                ],
            },
            buildModel: 'wisdom_park.wp_building',
            roomModel: 'wisdom_park.wp_room',
            imgform: {
                imgUrl: [],
            },
            userPhone: '',
        };
    },
    props: {
        currentObj: {
            type: Object,
            default: function () {
                return {};
            },
        },
        currentHouse: {
            type: Object,
            default: function () {
                return {};
            },
        },
        houseResourType: {
            type: String,
            default: function () {
                return '';
            },
        },
        onlyRead: {
            type: Boolean,
            default: function () {
                return false;
            },
        },
    },
    watch: {
        ruleForm: {
            handler: function (newValue, oldName) {
                if (newValue.area != '' && newValue.rental_unit_price_day != '') {
                    let m = Number(newValue.area) * Number(newValue.rental_unit_price_day);
                    newValue.rental_unit_price_month = m ? m * 30 : 0;
                } else {
                    newValue.rental_unit_price_month = '';
                }
            },
            deep: true,
        },
        'ruleForm.subject_id': {
            handler: function (newValue, oldName) {
                if (newValue) {
                }
            },
            deep: true,
        },
        'ruleForm.building_id': {
            handler: function (newValue, oldName) {
                if (newValue) {
                    this.getFloors();
                }
            },
            deep: true,
        },
        onlyRead: {
            handler: function (newValue, oldName) {
                this.option.disabled = newValue;
                this.option = JSON.parse(JSON.stringify(this.option));
            },
            deep: true,
        },
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    mounted() {
        this.changeData();
        this.getBuildList();
        if (this.userInfo.user_id) {
            this.getUserPhone(this.userInfo.user_id);
        }
    },

    created() {
        this.getDicList();
        this.queryByModelCode();
    },
    methods: {
        setMonthPrice() {
            /// rental_unit_price_day
            if (this.ruleForm.rental_unit_price_day <= 0) {
                this.ruleForm.rental_unit_price_day = 0;
            }
            if (this.ruleForm.room_area != '' && this.ruleForm.rental_unit_price_day != '') {
                let m = Number(this.ruleForm.room_area) * Number(this.ruleForm.rental_unit_price_day);
                this.ruleForm.rental_unit_price_month = m ? (m * 30).toFixed(2) : 0;
            } else {
                this.ruleForm.rental_unit_price_month = 0;
            }
            // this.set(this.ruleForm, "rental_unit_price_month", val);
            this.ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
        },
        setMonthPriceSelf() {
            if (this.ruleForm.rental_unit_price_month <= 0) {
                this.ruleForm.rental_unit_price_month = 0;
            }
        },
        changeFloor() {
            this.getFloors();
        },
        getUserPhone(id) {
            getUser(id).then(res => {
                console.log(res);
                this.userPhone = res.data.data.phone;
            });
        },
        queryByModelCode() {
            standardService.getModelRule(this.model).then(res => {
                this.ruleModel = res;
            });
        },
        addLable(tag) {
            if (this.ruleForm.label) {
                this.ruleForm.label = this.ruleForm.label + ',' + tag.id;
            } else {
                this.ruleForm.label = this.ruleForm.label + tag.id;
            }
        },

        delLable(index) {
            if (this.ruleForm.label) {
                let arr = this.ruleForm.label.split(',');
                let a = arr.splice(index, 1);
                this.ruleForm.label = arr.toString();
            }
        },
        format(a) {
            let arr = [];
            if (a.includes(',')) {
                arr = a.split(',');
            }
            return a;
        },
        changeData() {
            let obj = {
                pk: 'id',
                id: this.currentHouse['id'],
            };
            if (this.currentHouse['id']) {
                getMetaCommonInfo(`wisdom_park.wp_housing_resources`, obj).then(res => {
                    let data = res.data.data;
                    data.label = data.label ? data.label : '';
                    if (data.status === '') {
                        data.status = 1;
                    }
                    if (!data.online_status) {
                        data.online_status = 2;
                    }
                    if (data.online_status == '上线') {
                        tdata.online_status = 1;
                    }
                    if (data.online_status == '下线') {
                        data.online_status = 2;
                    }
                    if (data.status == '待租') {
                        data.status = 1;
                    }
                    if (data.status == '已租') {
                        data.status = 2;
                    }
                    if (!data.contact_person) {
                        data.contact_person = this.userInfo.nick_name;
                    }
                    if (!data.publish_user) {
                        data.publish_user = this.userInfo.nick_name;
                    }
                    if (!data.contact_phone) {
                        data.contact_phone = this.userPhone;
                    }
                    this.ruleForm = data;
                    if (data.attachment) {
                        this.getpicList(data.attachment);
                    }
                });
            }
        },

        getpicList(ids) {
            getPicListByIds(ids).then(res => {
                if (res.data.code == 200) {
                    let arr = [];
                    res.data.data.forEach(pic => {
                        arr.push({
                            label: pic.id,
                            value: pic.link,
                            url: pic.url,
                        });
                    });
                    this.imgform.imgUrl = arr;
                }
            });
        },
        getTagName(tag) {
            let tagName = '';
            this.roomTags.forEach(item => {
                console.log();
                if (item.id === tag) {
                    tagName = item['name'];
                }
            });
            return tagName;
        },
        getDicList() {
            dictbizService
                .getDictionary({
                    code: 'RoomBrightenedDot',
                })
                .then(res => {
                    this.roomTags = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                            id: item.id,
                        };
                    });
                    console.log(this.roomTags, 'this.roomTags');
                });
            dictbizService
                .getDictionary({
                    code: 'decoration',
                })
                .then(res => {
                    this.decorationList = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
            dictbizService
                .getDictionary({
                    code: 'paymentType',
                })
                .then(res => {
                    this.paymentTypeList = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });

            dictbizService
                .getDictionary({
                    code: 'housingResourcesType',
                })
                .then(res => {
                    this.housingResourcesTypes = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
        },
        close() {
            this.$emit('close');
        },
        getBuildList() {
            let obj = {
                size: 1000,
                current: 1,
            };
            getMetaCommonList('wisdom_park.wp_building', obj).then(res => {
                const data = res.data.data;
                this.buildList = data.records;
            });
        },
        getFloors() {
            let obj = {
                size: 1000,
                current: 1,
            };
            obj.wheres = [{ field: 'building_id', value: this.ruleForm.building_id }];
            getMetaCommonList('wisdom_park.wp_floor', obj).then(res => {
                const data = res.data.data;
                this.floors = data.records;
            });
        },
        submit() {
            return new Promise(resolve => {
                this.$refs['ruleForm'].validate(valid => {
                    if (valid) {
                        resolve({
                            valid: true,
                            form: this.ruleForm,
                        });
                    } else {
                        let fields = this.$refs['ruleForm'].fields,
                            firstError = {
                                field: '',
                                message: '',
                                name: '',
                            };
                        for (let i = 0; i < fields.length; i++) {
                            if (fields[i].validateState === 'error') {
                                firstError.message = fields[i].validateMessage;
                                firstError.field = fields[i].prop;
                                firstError.name = fields[i].label;
                                break;
                            }
                        }
                        resolve({
                            valid: false,
                            form: this.ruleForm,
                            error: firstError,
                        });
                    }
                });
            });
        },
        save() {
            Promise.all([this.submit()]).then(forms => {
                if (this.imgform) {
                    let imgList = [];
                    let urlList = [];
                    this.imgform.imgUrl.forEach(e => {
                        console.log(e);
                        if (e.label) {
                            imgList.push(e.label);
                            urlList.push(e.value);
                        }
                    });
                    this.ruleForm.links = urlList.toString();
                    this.ruleForm.attachment = imgList.toString();
                }
                if (this.ruleForm.building_id) {
                    let name = '';
                    this.buildList.forEach(e => {
                        if (e.id == this.ruleForm.building_id) {
                            name = e.name;
                        }
                    });
                    this.ruleForm.building_name = name;
                }
                if (this.ruleForm.floor_id) {
                    let name = '';
                    this.floors.forEach(e => {
                        if (e.id == this.ruleForm.floor_id) {
                            name = e.name || e.code;
                        }
                    });
                    console.log('name');
                    this.ruleForm.floor_name = name;
                }
                console.log(this.ruleForm.status);
                if (this.ruleForm.status === '') {
                    this.ruleForm.status = 1;
                }
                if (this.ruleForm.id) {
                    let o = {
                        update: this.ruleForm,
                        primaryKeyValue: this.ruleForm['id'],
                        primaryKey: 'id',
                    };
                    updateMetaCommon(this.houseModel, o).then(res => {
                        if (res.data.code == 200) {
                            this.$emit('close', true);
                        }
                    });
                } else {
                    saveMetaCommon(this.houseModel, {
                        values: this.ruleForm,
                    }).then(res => {
                        if (res.data.code == 200) {
                            this.$emit('close', true);
                        }
                    });
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
    margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
    left: 0px;
}
/deep/ .el-divider__text {
    padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
    width: 68px;
    height: 68px;
    line-height: 72px;
    vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 68px;
    height: 68px;
}
.btnClass {
    display: flow-root;
    height: 50px;
    line-height: 50px;
    padding: 0px 15px;
}
/deep/ .el-select {
    width: 100%;
}
</style>

<template>
  <basic-container>
    <avue-crud
      :data="tableDatas"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      @row-save="handleSave"
      @row-update="handleUpdate"
      @row-del="handleDel"
      @on-load="onLoad"
      @refresh-change="onLoad"
    >
    </avue-crud>
  </basic-container>
</template>
<script>
import {
  getDataAPICollection,
  saveAPICollection,
  updateAPICollection,
  delAPICollection
} from '@/api/dataService/dataAPICollection'
  export default {
    data(){
      return {
        page: {
          pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        tableLoading: false,
        tableDatas: [],
        tableOption: {
          column:[
            {
              label:'API名称',
              prop:'name',
              span: 24,
              rules: [{
                required: true,
                message: "请输入名称",
                trigger: "blur"
              }]
            },
            {
              label:'集合名称',
              prop:'path',
              span: 24,
              rules: [{
                required: true,
                message: "请输入名称",
                trigger: "blur"
              }]
            },
            {
              label:'API标签',
              prop:'apilabel',
            },
            {
              label:'数据源类型',
              prop:'datasource',
            },
            {
              label:'状态',
              prop:'status',
            },
            {
              label:'测试状态',
              prop:'testStatus',
            },
            {
              label:'生成方式',
              prop:'produceType',
            },
            {
              label:'描述',
              prop:'description',
              type: "textarea",
              minRows: 2,
              span: 24,
            },
            {
              label:'创建人',
              prop:'createUserName',
              display: false
            },
            {
              label:'创建时间',
              prop:'createTime',
              display: false
            }
          ]
        }
      }
    },
    methods: {
      onLoad() {
        this.tableLoading = true;
        let obj = {
          'current': this.page.currentPage,
          'size': this.page.pageSize
        };
        getDataAPICollection(obj).then( res => {
          this.tableLoading = false;
          this.tableDatas = res.data.data.records;
          this.page.total = res.data.data.total
        }).catch( e => {
          this.tableLoading = false;
        });
      },
      handleSave(row,done,loading) {
        let obj = {
          'description':row['description'],
          'name':row['name'],
          'path':row['path']
        };
        saveAPICollection(obj).then( res => {
          done();
          loading();
          this.onLoad();
        }).catch( e => {
          loading();
        });
      },
      handleUpdate(row, index, done, loading) {
        let obj = {
          'id':row['id'],
          'description':row['description'],
          'name':row['name'],
          'path':row['path']
        };
        updateAPICollection(obj).then( res => {
          done();
          loading();
          this.onLoad();
        });
      },
      handleDel(row) {
        let _t = this;
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            delAPICollection({
              ids:row['id']
            }).then(res => {
              _t.onLoad();
            })
          })
      }
    }
  }
</script>

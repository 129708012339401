import { numConvert } from '@/filters/numConvert.js'

export default {
    methods: {
        _getLabelTask(index){
            let indexTypes = {
                0: {
                    type: '9',
                    name: '治理任务'
                },
                1: {
                    type: '8',
                    name: '宽表任务'
                }
            }
            let str = '' , type = null;
            this.tasks.forEach(
                item => {
                    if(item.type === indexTypes[index].type){
                        str = indexTypes[index].name + ':' + numConvert(item.c);
                        type = item.type;
                    }
                }
            )
            return {
                label: str,
                type: type
            };
        },

        _addDatasources(nodes , edges , combos){
            let firstDH = this.dataHierarchys[0];
            if(!this.datasourceCombo){
                if(this.datasources.length >= 10){
                    combos.push({
                        id: 'datasourceCombo',
                        label: '数据来源',
                        size: [240 , 100],
                        anchorPoints: [[1,0.5]]
                    });
                }
                nodes.push({
                    type: 'circle',
                    label: '-',
                    id: 'datasourceComboCircle',
                    comboId: 'datasourceCombo'
                })
                this.datasources.forEach(
                    item => {
                        let id = item.id + '';
                        nodes.push({
                            label: item.name,
                            id: id,
                            size: [120 , 40],
                            type: 'rect',
                            nodeType: 'datasource',
                            comboId: 'datasourceCombo'
                        })
                        edges.push({
                            source: id,
                            target: 'datasourceComboCircle',
                            label: '采集任务:' + numConvert(item.cjNum),
                            edgeType: 'task',
                            query: {
                                datasourceId: this.getNodeId(id),
                                type: 1,
                                back: true
                            },
                            labelCfg: {
                                position: 'start',
                                refX: 10
                            }
                        })
                    }
                )
                edges.push({
                    source: 'datasourceComboCircle',
                    target: firstDH.id,
                    type: 'line'
                })
            }else{
                let tasks = 0;
                this.datasources.forEach(
                    item => {
                        tasks += item.cjNum;
                    }
                )
                nodes.push({
                    id: 'datasourceBox',
                    title: '数据来源',
                    type: 'dh-node',
                    size: [180 , 60],
                    nodeType: 'box',
                    indicators: [
                        {name: '数据源' , value: this.datasources.length , code: 'datasource'},
                        {name: '采集任务数' , value: tasks , code: 'allCjTask'}
                    ],
                    indicatorClick: (data) => {
                        console.log(data);
                    }
                })
                edges.push({
                    source: 'datasourceBox',
                    target: firstDH.id,
                    type: 'line'
                })
            }
        },
        _addDataHierarchys(nodes , edges , combos){
            this.dataHierarchys.forEach(
                (item , index) => {
                    nodes.push({
                        id: item.id,
                        title: item.label,
                        type: 'dh-node',
                        nodeType: 'dataHierarchy',
                        indicators: item.indicators || [],
                        size: [180 , 60],
                        indicatorClick: (indicator , ev) => {
                            if(indicator){
                                let id = this.getNodeId(indicator.nodeId);
                                if(id){
                                    this.$router.push(`/data-assets/assets?dhId=${id}&back=true`);
                                }
                            }
                        },
                        onClick: (ev , cfg) => {
                            // console.log(ev , cfg);
                            // let model = ev.item.getModel();
                            this._getData_getTables(this.getNodeId(cfg.id)).then(
                                res => {
                                    this.showType = 'chartShow';
                                    this.level = 2;
                                    this.initGraph();
                                    this.clearRender();
                                }
                            );
                        }
                    })
                    let nextDH = null;
                    if(index < this.dataHierarchys.length - 1){
                        nextDH = this.dataHierarchys[index + 1];
                    }
                    if(nextDH){
                        let labelObj = this._getLabelTask(index);
                        edges.push({
                            source: item.id,
                            target: nextDH.id,
                            type: 'line',
                            label: labelObj.label,
                            edgeType: 'task',
                            query: {
                                scene: labelObj.type,
                                back: true
                            }
                        })
                    }
                }
            )
        },
        _addApplications(nodes , edges , combos){
            let lastDH = this.dataHierarchys[this.dataHierarchys.length - 1]
            this.applications.forEach(
                item => {
                    nodes.push({
                        label: item.name,
                        type: 'rect',
                        size: [80 , 40],
                        id: item.id,
                        nodeType: 'app'
                    })
                    edges.push({
                        source: lastDH.id,
                        target: item.id
                    })
                }
            )
        },
        _addTables(nodes , edges , combos){
            this.tables.forEach((item) => {
                nodes.push({
                    id: item.id,
                    type: 'dh-node',
                    title: item.label,
                    data: item,
                    nodeType: 'tableNode',
                    indicators: item.indicators || [],
                    indicatorClick: (indicator , ev) => {
                        if(indicator){
                            console.log(indicator);
                        }
                        // if(this._event_indicatorClick && typeof this._event_indicatorClick === 'function'){
                        //     // this._event_indicatorClick(indicator , ev)
                        // }
                    },
                    onClick: (ev , cfg) => {
                        let data = cfg ? cfg.data : {};
                        if(data.guid){
                            this.dataAssetsDetailProps = {
                                propGuid: data.guid,
                                propTableName: data.tableName,
                                propDatabaseId: data.datasourceId,
                                propDatabaseName: data.databaseName
                            }
                            this.showTableDetail = true;
                        }
                    }
                })
            });
        },
        _setTableData(_tables){
            let tables = _tables || this.tables;
            this.page.total = tables.length;
            this.tableData = [];
            let startIndex = (this.page.currentPage - 1) * this.page.pageSize , 
                endIndex = this.page.currentPage * this.page.pageSize;
            for(let i = startIndex; i <= endIndex; i++){
                if(tables[i]){
                    let item = tables[i];
                    this.tableData.push({
                        id: item.id,
                        dataRows: item.dataRows,
                        dataSize: item.dataSize,
                        tableName: item.tableName,
                        dataSizeIncreaseByDay: item.dataSizeIncreaseByDay,
                        dataRowsIncreaseByDay: item.dataRowsIncreaseByDay,
                        columnNum: item.columnNum,
                        _item: item
                    });
                }
            }

        },

        getRanderData(){
            let nodes = [] , edges = [] , combos = [];
            if(this.level === 1){
                this._addDatasources(nodes , edges , combos);
                this._addDataHierarchys(nodes , edges , combos);
                this._addApplications(nodes , edges , combos)
            }else if(this.level === 2){
                this._addTables(nodes , edges , combos)
            }
            return {
                nodes,
                edges,
                combos
            }
        },
    }
}
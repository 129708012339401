<template>
    <basic-container isCard :body-style="{ padding: '0', height: '100%' }" class="h-100p">
        <div class="h-100p">

            <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" v-model="form" :page.sync="page" @selection-change="selectionChange" @refresh-change="refreshChange"
                       @on-load="getDicList" @search-change="searchChange">
            </avue-crud>

        </div>
    </basic-container>
</template>

<script>
import { getMetaCommonList, getMetaListUrl } from '@/api/busiMode/metaCommon';
import standardService from '@/api/metadata/standard';
import { mapGetters } from 'vuex';
import * as dictbizService from '@/api/system/dictbiz';
const modelPrefix = 'wisdom_park.';
export default {
    data() {
        return {
            searchText: '',
            form: {},
            selectionList: [],
            loading: true,
            dialogVisible: false,
            detailDia: false,
            query: {},
            dialogType: '',
            type: '',
            size: '60%',
            currentRow: {},
            title: '',
            page: {
                size: 10,
                current: 1,
                total: 0,
            },
            option: {
                dateBtn: false,
                border: true,
                height: 'auto',
                tip: true,
                searchShow: true,
                searchMenuSpan: 12,
                addBtn: false,
                refreshBtn: true,
                addRowBtn: false,
                columnBtn: true,
                delBtn: false,
                editBtn: false,
                cancelBtn: false,
                rowKey: '',
                showPage: true,
                index: true,
                indexLabel: '序号',
                selection: false,
                showHeader: true,
                expand: false,
                highlightCurrentRow: true,
                stripe: false,
                menuWidth: 150,
                menu: false,
                reserveSelection: true,
                column: [
                    {
                        overHidden: true,
                        align: 'left',
                        label: '车牌号码',
                        prop: 'plateNumber',
                        span: 4,
                        key: 'id',
                    },
                    {
                        overHidden: true,
                        align: 'left',
                        label: '通行时间',
                        prop: 'time',
                        span: 4,
                        key: 'id',
                    },
                    {
                        overHidden: true,
                        align: 'left',
                        label: '通行方向',
                        type: 'select',
                        prop: 'direction',
                        dicData: [
                            {
                                label: '入场',
                                value: '1',
                            },
                            {
                                label: '出场',
                                value: '2',
                            },
                        ],
                    },
                    {
                        overHidden: true,
                        align: 'left',
                        label: '认证类型',
                        prop: 'authType',
                        type: 'select',
                        dicData: [
                            {
                                label: '白名单',
                                value: '1',
                            },
                            {
                                label: '临时车',
                                value: '2',
                            },
                            {
                                label: '预授权/预约车',
                                value: '3',
                            },
                            {
                                label: '异常放行',
                                value: '4',
                            },
                            {
                                label: '自动放行',
                                value: '5',
                            },
                            {
                                label: '预交费',
                                value: '6',
                            },
                            {
                                label: '共享车位作为临时车',
                                value: '7',
                            },
                            {
                                label: '共享车位作为长租车',
                                value: '8',
                            },
                            {
                                label: '黑名单',
                                value: '9',
                            },
                            {
                                label: '专有车位车',
                                value: '10',
                            },
                        ],
                    },
                    {
                        overHidden: true,
                        align: 'left',
                        label: '通道名称',
                        prop: 'laneName',
                        span: 4,
                        key: 'id',
                    },
                    {
                        label: '停车场',
                        prop: 'parkId',
                        dicUrl: getMetaListUrl(modelPrefix + 'wp_third_qqb_park'),
                        props: {
                            label: 'parkName',
                            value: 'parkId',
                            res: 'data.records',
                        },
                        dicQuery: {
                            current: 1,
                            size: 500,
                        },
                        dicMethod: 'post',
                        addDisplay: true,
                        editDisplay: true,
                        viewDisplay: false,
                        search: true,
                        searchSpan: 8,
                        type: 'select',
                    },
                ],
            },
            model: 'wisdom_park.wp_third_qqb_car_records',
            data: [],
            eventType: [],
            doorEventTypes: [],
            ruleModel: {},
            activeTabName: '1',
            indexCode: this.$route.query.indexCode,
            query: {},
        };
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.code_add, false),
                viewBtn: this.vaildData(this.permission.code_view, false),
                delBtn: this.vaildData(this.permission.code_delete, false),
                editBtn: this.vaildData(this.permission.code_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    created() {
        this.queryByModelCode();
    },
    mounted() {
        this.indexCode = this.$route.query.indexCode;
    },
    props: {
        activeName: {
            type: String,
            default: function () {
                return '1';
            },
        },
        onlyError: {
            type: Boolean,
            default: function () {
                return false;
            },
        },
    },
    watch: {
        activeName: {
            handler: function (newValue, oldName) {
                if (newValue) {
                    this.activeTabName = newValue;
                    this.getDicList();
                }
            },
            immediate: true,
            deep: true,
        },
        onlyError: {
            handler: function (newValue, oldName) {},
            immediate: true,
            deep: true,
        },
    },
    methods: {
        go() {
            this.$router.push({
                path: '/door/main',
            });
        },
        getDicList() {
            if (this.getDicList.length > 0) {
                this.onLoad();
            } else {
                dictbizService
                    .getDictionary({
                        code: 'DoorEventTypes',
                    })
                    .then(res => {
                        this.doorEventTypes = res.data.data;
                        this.onLoad();
                    });
            }
        },
        add() {
            this.type = 'add';
            this.detailDia = true;
        },
        handleClick(val) {
            this.activeTabName = val['name'];
            this.onLoad();
        },
        openDetailDia(type, row) {
            this.type = type;
            this.detailDia = true;
            setTimeout(() => {
                this.currentRow = row;
            }, 0);
        },
        back() {
            this.detailDia = false;
            this.searchReset();
        },
        queryByModelCode() {
            standardService.getModelRule(this.model).then(res => {
                this.ruleModel = res;
            });
        },
        changName(row) {
            this.title = row.name;
        },

        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },

        selectionChange(list) {
            this.selectionList = list;
        },
        selectionClear() {
            this.selectionList = [];
        },

        refreshChange() {
            this.onLoad(this.page);
        },
        closeDia() {
            this.detailDia = false;
            this.refreshChange();
        },
        searchChange(params, done) {
            this.query = params;
            this.onLoad();
            done();
        },
        onLoad() {
            this.loading = true;

            let body = {
                current: this.page.currentPage,
                size: this.page.pageSize,
                orders: [{ field: 'time', sort: 'DESC' }],
            };
            if (this.query.parkId) {
                body.wheres = [{ field: 'parkId', value: this.query.parkId }];
            }
            getMetaCommonList(this.model, body).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                this.loading = false;
            });
        },
        doorevents(row) {
            this.detailDia = true;
            this.currentRow = row;
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.deleteIcon {
    font-size: 18px;
    position: absolute;
    left: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 1;
}
/deep/ .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 15px;
}
/deep/ .el-drawer__header {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 20px;
    margin-bottom: 0px;
}
/deep/ .el-drawer__body {
    padding: 0px;
}
/deep/ .el-dialog__body {
    padding: 0px;
}
.buildDetail /deep/ .el-dialog__body {
    padding: 0px;
}
</style>

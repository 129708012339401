<template>
  <basic-container>
    <avue-crud
      ref="table"
      :data="tableData"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      :before-open="beforeOpen"
      :search.sync="search"
      v-model="form"
      @search-change="searchChange"
      @on-load="onLoad"
      @refresh-change="onLoad"
      @row-save="saveTableForm"
      @row-update="(row, index, done, loading) => saveTableForm(row, done, loading)"
      @row-del="rowDel"
      :upload-preview="uploadPreview"
    >
      <!-- <template slot="attachment_idForm" slot-scope="{disabled,size}">

      </template> -->
    </avue-crud>
  </basic-container>
</template>
<script>
import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  delMetaCommon,
  getPicListByIds
} from "@/api/busiMode/metaCommon";
import * as dictService from "@/api/system/dictbiz";
export default {
  data(){
    return {
      form: {
        supplementary_terms: ''
      },
      model: 'wisdom_park.wp_contract_template',
      tableLoading: false,
      tableData: [],
      search: {},
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableOption: {
        height: 'auto',
        // labelPosition: 'top',
        searchMenuPosition: 'right',
        searchLabelPosition: 'right',
        searchMenuSpan: 24,
        border: true,
        column: [
          {
            label: "模板名称",
            prop: "template_name",
            span: 12,
            search: true,
            searchSpan: 6,
            width: 200,
          },
          {
            label: "编号规则",
            prop: "contract_no_rule",
            span: 12,
            hide: true,
            showColumn: false,
            type: "select",
            dicUrl: dictService.getUrl("ContractNoRule"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: 'number'
          },
          {
            label: "创建日期",
            prop: "create_time",
            display: false,
            viewDisplay: false,
            type: 'date',
            search: true,
            searchSpan: 6,
            width: 120,
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            format: "yyyy年MM月dd日",
          },
          {
            label: "模板类型",
            prop: "template_type",
            span: 8,
            type: "select",
            dicUrl: dictService.getUrl("ContractTemplateType"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            search: true,
            searchSpan: 6,
          },
          {
            label: "模板用途",
            prop: "template_use",
            span: 8,
            type: "select",
            dicUrl: dictService.getUrl("ContractTemplateUse"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            search: true,
            searchSpan: 6,
          },
          {
            label: "租赁类型",
            prop: "rent_type",
            span: 8,
            type: "select",
            dicUrl: dictService.getUrl("RentType"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "计租方式",
            prop: "rent_way",
            span: 8,
            type: "select",
            dicUrl: dictService.getUrl("RentWay"),
            props: {
              label: "dictValue",
              value: "dictKey",
            }
          },
          {
            label: "租金税率",
            prop: "rent_rate",
            span: 8,
            width: 80,
            append: '%',
            value: '0'
          },
          {
            label: "服务费税率",
            prop: "service_rate",
            span: 8,
            width: 80,
            append: '%',
            value: '0'
          },
          {
            label: "模板备注",
            prop: "note",
            span: 24,
            type: 'textarea',
            rows: 2,
            minWidth: 200
          },
          {
            label: "模板文件",
            prop: "attachment_id", 
            span: 18,
            hide: true,
            span: 24,
            type: "upload",
            drag: true,
            limit: 1,
            loadText: "模板上传中，请稍等",
            propsHttp: {
              res: "data",
              url: "attachId",
              name: "originalName",
            },
            fileType: 'doc/docx',
            tip: "请上传 .doc,.docx 标准格式文件",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          }
        ]
      },
      files: {}
    }
  },
  mounted(){
    
  },
  methods: {
    uploadPreview(ev,column,done){
      let file = this.files[ev.url];
      if(file){
        this.downFile(file.link , file.originalName)
      }
    },
    onLoad(){
      let wheres = [];
      ['template_type' , 'template_name' , 'create_time' , 'template_use'].forEach(
        name => {
          if(this.search[name]){
            if(name === 'template_name'){
              wheres.push({field: name , op: 'LIKE' , value: this.search[name]})
            }else{
              wheres.push({field: name , value: this.search[name]})
            }
          }
        }
      )
      this.tableData = [];
      this.tableLoading = true;
      return getMetaCommonList(this.model , {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres
      }).then(
        res => {
          this.tableLoading = false;
          if(res.data && res.data.code === 200){
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
          }else{
            this.tableData = [];
            this.page.total = 0;
          }
        }
      ).catch(e => {
        this.tableLoading = false;
      })
    },
    searchChange(params, done) {
      this.page.currentPage = 1;
      this.onLoad(params)
        .then((res) => {
          done();
        })
        .catch((e) => {
          done();
        });
    },
    beforeOpen(done, type){
      if (["edit", "view"].includes(type)) {
        this.files = {};
        let attachment_ids = this.form.attachment_id ? this.form.attachment_id.split(',') : [];
        if(attachment_ids.length){
          getPicListByIds(attachment_ids).then(
            res => {
              this.form.attachment_id = res.data.data.map(
                item => {
                  this.files[item.id] = item;
                  return {
                    label: item.originalName,
                    value: item.id
                  }
                }
              );
              done();
            }
          )
        }else{
          done();
        }
      }else{
        done();
      }
    },
    saveTableForm(row, done, loading) {
      // console.log(row);
      let body = {
        ...row
      };

      Object.keys(body).forEach(
        key => {
          if(key[0] === '$'){
            delete body[key];
          }
        }
      );
     
      if(body['attachment_id'] && Array.isArray(body['attachment_id'])){
        let attachment_id = body['attachment_id'].map(item => item.value);
        body['attachment_id'] = attachment_id.join(',');
      }
      if(row.id){
        body = {
          update: body,
          primaryKeyValue: row.id,
          primaryKey: "id",
        };
      }else{
        body = {
          values: body
        }
      };
      (row.id ? updateMetaCommon : saveMetaCommon)(this.model , body).then((res) => {
        this.responeHandle(res, done, loading);
      }).catch((e) => {
        loading();
      });
    },
    responeHandle(res, done, loading) {
      if (res.data && res.data.code === 200) {
        done && done();
        this.onLoad();
        this.$message.success(res.data.msg);
      } else {
        loading && loading();
        this.$message.error(res.data.msg);
      }
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        return delMetaCommon(this.model , {pk: 'id' , ids: [row.id]});
      }).then(() => {
        this.onLoad();
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    },
  }
}
</script>
<template>
  <div>
    <basic-container isCard>
      <avue-form
        :option="option"
        v-model="form"
        @tab-click="handleTabClick"
        @submit="handleSubmit"
      >
        <template slot="passwordTip">
          <div>
            <h5>密码规则：</h5>
            <p class="text-info lh-24 fts-12">
              1、密码长度为8-20位<br />
              2、密码需同时包含字母、数字和特殊符号<br />
              3、密码不能输入3个及以上的连续数字和字母(如：<span
                class="text-red"
                >123</span
              >、<span class="text-red">abc</span>) <br />
              4、密码不能输入3个及以上的连续键盘序字符(如：<span
                class="text-red"
                >asd</span
              >、<span class="text-red">!@#</span>、<span class="text-red"
                >123</span
              >)
            </p>
          </div>
        </template>
      </avue-form>
    </basic-container>
  </div>
</template>

<script>
import option from "@/option/user/info";
import { getUserInfo, updateInfo, updatePassword } from "@/api/system/user";
import func from "@/util/func";
import crypto from "@/util/crypto";

import passwordCheck from "@/util/password-check";

const infoOption = {
  column: [
    {
      label: "姓名",
      span: 12,
      row: true,
      prop: "name",
    },
    {
      label: "用户名",
      span: 12,
      row: true,
      prop: "realName",
    },
    {
      label: "手机号",
      span: 12,
      row: true,
      prop: "phone",
    },
    {
      label: "邮箱",
      prop: "email",
      span: 12,
      row: true,
    },
  ],
};

const passwordOption = {
  column: [
    {
      label: "原密码",
      span: 12,
      row: true,
      type: "password",
      prop: "oldPassword",
    },
    {
      label: "新密码",
      span: 12,
      row: true,
      type: "password",
      prop: "newPassword",
      maxlength: 20,
      minlength: 8,
    },
    {
      label: "确认密码",
      span: 12,
      row: true,
      type: "password",
      prop: "newPassword1",
      maxlength: 20,
      minlength: 8,
    },
    {
      label: "",
      span: 12,
      row: true,
      type: "text",
      prop: "passwordTip",
    },
  ],
};

export default {
  data() {
    let type = this.$router.currentRoute.params.type;
    return {
      index: 0,
      option: type === "password" ? passwordOption : infoOption,
      form: {},
    };
  },
  inject: ["indexMain"],
  watch: {
    $route: function () {
      if (this.$router.currentRoute.params.type === "password") {
        this.option.tabsActive = 2;
        this.index = 1;
      } else {
        this.option.tabsActive = 1;
      }
    },
  },
  created() {
    // this.indexMain.showMenus = false;
    if (this.$router.currentRoute.params.type === "password") {
      this.option.tabsActive = 2;
      this.index = 1;
    } else {
      this.option.tabsActive = 1;
    }
    this.handleWitch();
  },
  methods: {
    handleSubmit(form, done) {
      if (this.index === 0) {
        updateInfo(form).then(
          (res) => {
            if (res.data.success) {
              this.$message({
                type: "success",
                message: "修改信息成功!",
              });
            } else {
              this.$message({
                type: "error",
                message: res.data.msg,
              });
            }
            done();
          },
          (error) => {
            done();
          }
        );
      } else {
        if (!form.oldPassword) {
          this.$message({
            type: "error",
            message: "请输入原密码",
          });
          done();
          return;
        }
        let psd = crypto.encrypt(form.newPassword),
          psd1 = crypto.encrypt(form.newPassword1);
        if (psd !== psd1) {
          this.$message({
            type: "error",
            message: "两次输入密码不一致!",
          });
          done();
          return;
        }
        passwordCheck(form.newPassword)
          .then(() => {
            updatePassword(crypto.encrypt(form.oldPassword), psd, psd1).then(
              (res) => {
                if (res.data.success) {
                  this.$message({
                    type: "success",
                    message: "修改密码成功!",
                  });
                } else {
                  this.$message({
                    type: "error",
                    message: res.data.msg,
                  });
                }
                done();
              },
              (error) => {
                done();
              }
            );
          })
          .catch((err) => {
            this.$message(err);
            done();
          });
      }
    },
    handleWitch() {
      if (this.index === 0) {
        getUserInfo().then((res) => {
          const user = res.data.data;
          this.form = {
            id: user.id,
            avatar: user.avatar,
            name: user.name,
            realName: user.realName,
            phone: user.phone,
            email: user.email,
          };
        });
      }
    },
    handleTabClick(tabs) {
      this.index = func.toInt(tabs.index);
      this.handleWitch();
    },
  },
};
</script>

<style></style>

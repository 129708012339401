<template>
  <div>
    <draggable
      class="widget-form-group__body"
      style="display: contents"
      :list="menuLeftList"
      :group="{ name: 'btnList' }"
      ghost-class="ghost"
      :animation="200"
      @add="handleWidgetGroupAdd($event, column)"
      @end="$emit('change')"
    >
      <template v-for="(btn, index) in menuLeftList">
        <el-button
          v-if="btn.show && btn.showPosotion == 'left'"
          :key="index"
          :type="btn.type"
          size="small"
          :icon="btn.icon + ' fts-12'"
          class="menuBtnList active"
          @click="operations(btn)"
        >
          <i class="el-icon-rank dropBtnIcon"></i>

          <el-dropdown @command="btbDO">
            <span class="el-dropdown-link">
              <i class="el-icon-setting"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                icon="el-icon-edit"
                :command="'btnEdit' + btn.value"
                >编辑</el-dropdown-item
              >
              <el-dropdown-item :command="btn.value" icon="el-icon-delete"
                >删除</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          {{ btn.label }}
        </el-button>
      </template>
    </draggable>
    <el-button
      class="mar-l-10 designable-btn-dash"
      size="small"
      split-button
      @click="addOpera"
      icon="el-icon-setting"
      type="warning"
      plain
      >新增操作</el-button
    >
    <el-dialog
      title="操作编辑"
      width="30%"
      append-to-body
      :visible.sync="btnEditVisible"
    >
      <template>
        <el-row>
          <el-col :span="24">
            <el-form size="small" label-position="left" label-width="80px">
              <el-row>
                <el-col :span="18">
                  <el-form-item label="名称">
                    <el-input
                      v-model="btnEditObj.label"
                      autocomplete="off"
                    ></el-input>
                  </el-form-item>
                </el-col>
              </el-row>

              <el-form-item label="图标" class="h-40">
                <el-popover
                  ref="popover"
                  placement="bottom"
                  width="450"
                  trigger="click"
                >
                  <el-tabs v-model="activeName">
                    <el-tab-pane
                      v-for="(item, index) of iconList"
                      :key="index"
                      :label="item.label"
                      :name="'' + index"
                    >
                      <el-row class="h-300 ov-a">
                        <el-col
                          :span="4"
                          v-for="(icon, index) of item.list"
                          :key="index"
                        >
                          <div
                            @click="iconClick(icon)"
                            title="点击切换图标(className)"
                            class="w-100p h-60 icon-item cur-p"
                          >
                            <i class="fts-30" :class="icon"></i>
                          </div>
                        </el-col>
                      </el-row>
                    </el-tab-pane>
                  </el-tabs>
                  <div slot="reference">
                    <el-button
                      style="width: 48px; height: 38px; border-style: dashed"
                      size="small"
                      :icon="btnEditObj.icon"
                    ></el-button>
                  </div>
                </el-popover>
                <i class="el-icon-delete" @click="deleteIcon"></i>
              </el-form-item>
              <el-form-item label="主题">
                <el-button
                  size="small"
                  type="primary"
                  :class="{ btnTypeActive: btnEditObj.type == 'primary' }"
                  @click="btnEditObj.type = 'primary'"
                >
                  <i
                    v-if="btnEditObj.type == 'primary'"
                    class="el-icon-check fts-18"
                  ></i>
                </el-button>

                <el-button
                  size="small"
                  type=""
                  :class="{ btnTypeActive: btnEditObj.type == '' }"
                  @click="btnEditObj.type = ''"
                >
                  <i
                    v-if="btnEditObj.type == ''"
                    class="el-icon-check fts-18"
                  ></i>
                </el-button>
              </el-form-item>
              <el-form-item label="操作">
                <el-row>
                  <el-col :span="16" class="pad-l-0">
                    <el-input
                      placeholder="选择关联的操作"
                      class="selectTree-input"
                      auto-complete="off"
                      v-model="btnEditObj.model"
                      @click.native="changeSelectTree()"
                      @blur="hideParentClick"
                    >
                    </el-input>
                    <el-tree
                      id="floatTree"
                      v-show="isShowSelect"
                      empty-text="暂无数据"
                      :highlight-current="true"
                      :default-expand-all="false"
                      :expand-on-click-node="true"
                      :data="data"
                      :filter-node-method="filterNode"
                      @node-click="selectClassfy"
                      class="width-220 selectTree-input objectTree"
                      ref="selectTree"
                    >
                    </el-tree>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="打开">
                <el-switch
                  v-model="btnEditObj.delivery"
                  active-text="新窗口"
                  inactive-text=""
                >
                </el-switch>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </template>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="btnEditVisible = false"
          >取 消</el-button
        >
        <el-button type="primary" size="small" @click="saveBtn()"
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import iconList from "@/config/iconList";
import Draggable from "vuedraggable";

export default {
  name: "designBtn",
  watch: {},
  components: { Draggable },
  props: {
    menuLeftList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    newOption: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      activeName: 0,
      menuList: [],
      btnEditVisible: false,
      btnEditObj: {},
      curBtnTpye: "",
      iconList: iconList,
      // menuLeftList: [],
      data: [
        {
          label: "接处警后管",
          children: [
            {
              label: "用户管理",
              children: [
                {
                  label: "新增",
                },
                {
                  label: "删除",
                },
              ],
            },
          ],
        },
        {
          label: "移动APP",
        },
      ],
      isShowSelect: false,
    };
  },

  created() {},
  mounted() {},

  methods: {
    addOpera() {
      this.$emit("operDo", "left");
    },
    deleteIcon() {
      this.btnEditObj["icon"] = "";
    },
    operations(btn) {
      this.$emit("btnLeftDo", btn);
    },
    selectClassfy(data) {
      this.btnEditObj.model = data.label;
      this.isShowSelect = false;
    },
    hideParentClick(e) {
      var isOther =
        e.relatedTarget == null ||
        e.relatedTarget.closest("div.el-tree") == null ||
        e.relatedTarget.closest("div.el-tree").id != "floatTree";

      if (isOther) {
        this.isShowSelect = false;
      } else {
        e.target.focus();
      }
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    btbDO(btn) {
      this.$emit("btnLeft", btn);
      this.menuLeftList.forEach((e) => {
        if (btn.includes("btnEdit")) {
          // this.btnEditVisible = true;
          // this.btnEditObj = e;
          // this.curBtnTpye = "edit";
          this.$emit("operDo", "operDo");
        } else if (btn.includes("btnAdd")) {
          this.btnEditObj = {};
          this.curBtnTpye = "add";
          this.btnEditVisible = true;
        } else {
          if (e.value == btn) {
            e.show = false;
          }
        }
      });
      console.log(this.menuBtnList);
      this.menuBtnList = JSON.parse(JSON.stringify(this.menuLeftList));
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.menuBtnList i {
  font-size: 12px;
}
.el-icon-delete {
  // display: none;
  position: absolute;
  top: 15px;
  left: 60px;
  cursor: pointer;
  color: #f56c6c;
}
.btnTypeActive {
  border: 1px dashed #1976d3;
}
.dropBtnIcon {
  position: absolute;
  /* right: -43px; */
  background: #f5896c !important;
  /* top: -20px; */
  padding: 2px;
  color: #fff;
  right: 16px;
  top: -1px;
}
.el-tree {
  padding: 5px 0;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  border: #ccc solid 1px;
  border-top: none;
}

button {
  position: relative;
  .el-icon-rank {
    &.dropBtnIcon {
      position: absolute;
      top: 0;
      right: 0;
      display: none;
    }
  }
  .el-dropdown {
    display: none;
    position: absolute;
    right: 17px;
    top: 0;
    padding: 1px 3px;
    background: #f5896c;
    color: #fff;
    .el-dropdown-link {
      .el-icon-setting {
      }
    }
  }
  &:hover {
    border: dashed 1px #f5896c;
    .el-icon-rank {
      &.dropBtnIcon {
        display: block;
      }
    }
    .el-dropdown {
      display: block;
    }
  }
}
</style>

<template>
  <el-scrollbar class="h-modelstree">
    <el-form ref="form" :model="modelForm" :inline="true">
      <el-form-item label="显示方式">
        <el-radio-group v-model="modelForm.type">
          <el-radio label="list">单级</el-radio>
          <el-radio label="tree">多级</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="模型">
        <el-select
          class="w-240"
          v-model="modelForm.model"
          size="small"
          filterable
          :loading="modelLoading"
          placeholder="请选择模型"
          @change="changeVal"
        >
          <el-option
            v-for="item in modelList"
            :key="item.tableName"
            :label="item.description"
            :value="item.tableName"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="显示字段">
        <el-select
          class="w-240"
          filterable
          v-model="modelForm.labelField"
          size="small"
          placeholder="请选择显示字段"
        >
          <el-option
            v-for="item in tableFifleds"
            :key="item.guid + 'labelField'"
            :label="item.description"
            :value="item.columnName"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <span slot="label"> 字段值 </span>
        <el-select
          class="w-240"
          v-model="modelForm.valueField"
          filterable
          size="small"
          placeholder="请选择字段值"
        >
          <el-option
            v-for="table in tableFifleds"
            :key="table.guid + 'valueField'"
            :label="table.description"
            :value="table.columnName"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item v-if="modelForm.type == 'tree'">
        <span slot="label"> 上级节点 </span>
        <el-select
          class="w-240"
          v-model="modelForm.releaseField"
          filterable
          size="small"
          placeholder="请选择上级节点"
        >
          <el-option
            v-for="item in tableFifleds"
            :key="item.guid + 'releaseField'"
            :label="item.description"
            :value="item.columnName"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <h6>单击</h6>
      <el-form-item label="关联字段">
        <el-select
          class="w-240"
          filterable
          v-model="modelForm.label"
          size="small"
          placeholder="请选择关联字段"
        >
          <el-option
            v-for="item in tableColumns"
            :key="item.prop"
            :label="item.label"
            :value="item.prop"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
  </el-scrollbar>
</template>
<script>
import { findTableByDatasourceid } from "@/api/dataService/appModel";
import { getColumnsByTable } from "@/api/busiMode/meta";
export default {
  name: "modelTree",
  inject: ["dataSoure"],

  data() {
    return {
      modelForm: {
        type: "list",
      },
      fieldLoading: false,
      modelLoading: false,
      modelList: [],
      tableFifleds: [],
      filterList: [
        "create_time",
        "create_date",
        "create_user",
        "create_user_name",
        "update_user",
        "update_user_name",
        "tenant_id",
        "update_time",
        "is_deleted",
      ],
    };
  },
  created() {
    this.findTableByDatasourceid();
  },
  props: {
    tableColumns: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  watch: {
    modelForm: {
      handler: function (newValue, oldName) {
        if (newValue.model) {
          this.getColumnsByTable();
        }
      },
      immediate: true,
      deep: true,
    },
    tableColumns: {
      handler: function (newValue, oldName) {},
      immediate: true,
      deep: true,
    },
  },
  mounted() {},
  methods: {
    changeLabelField() {},
    findJONSFromModel() {},
    findTableByDatasourceid() {
      this.modelLoading = true;
      let o = {
        datasourceType: 0,
        datasourceCode: this.dataSoure.code,
        isSimple: true,
      };
      if (this.dataSoure.code) {
        findTableByDatasourceid(o)
          .then((res) => {
            this.modelList = res["data"]["data"] || [];
            this.modelLoading = false;
          })
          .catch((e) => {
            this.tables = [];
            this.modelLoading = false;
          });
      }
    },
    changeVal() {
      this.fieldLoading = true;
      // this.modelForm.valueField = "";
      // this.modelForm.labelField = "";
      this.getColumnsByTable();
    },
    getColumnsByTable() {
      let o = {
        datasourceCode: this.dataSoure.code,
        tableName: this.modelForm.model,
        datasourceType: 0,
        // datasourceType: this.dataSoureObj.type,
      };
      if (this.dataSoure.code) {
        getColumnsByTable(o)
          .then((res) => {
            if (res.data.code == 200) {
              this.tableFifleds = [];
              res.data.data.columnVOList.forEach((e) => {
                if (!this.filterList.includes(e.columnName)) {
                  this.tableFifleds.push(e);
                }
              });
              this.findPK();
            }
            this.fieldLoading = false;
          })
          .catch((e) => {
            this.fieldLoading = false;
          });
      }
    },
    findPK() {
      this.tableFifleds.forEach((e) => {
        if (e.isPK) {
          this.modelForm.sourceField = e.columnName;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.h-modelstree {
  height: calc(100vh - 263px);
}
</style>

import G6 from '@antv/g6';
import { numConvert } from '@/filters/numConvert.js'

// 自定义层节点
G6.registerNode('dh-node1', {
  drawShape(cfg, group) {
    const strokeColor = cfg.style.stroke;
    const fillColor = cfg.style.fill;
    const r = 2,
      width = cfg.size && cfg.size[0] ? cfg.size[0] : 200,
      titleHeight = 30,
      bodyHeight = Math.ceil(cfg.indicators.length / 2) * 45;
    cfg.size = [width, titleHeight + bodyHeight];
    const shape = group.addShape('rect', {
      attrs: {
        x: 0,
        y: 0,
        width: width,
        height: titleHeight + (bodyHeight < 90 ? 90 : bodyHeight),
        radius: r,
        stroke: strokeColor,
        fill: '#fff'
      },
      name: 'main-box',
      draggable: true,
    });

    group.addShape('rect', {
      attrs: {
        x: 0,
        y: 0,
        width: width,
        height: titleHeight,
        radius: [r, r, 0, 0],
        fill: fillColor,
        stroke: strokeColor
      },
      name: 'title-box',
      draggable: true,
    });

    // title text
    let titleEl = group.addShape('text', {
      attrs: {
        textBaseline: 'top',
        y: (titleHeight - 16) / 2,
        x: 10,
        height: titleHeight,
        lineHeight: titleHeight,
        text: cfg.title,
        fontSize: 16,
        fill: '#fff',
        cursor: 'pointer',
      },
      name: 'title',
    });

    titleEl.on('click', (ev) => {
      if (cfg.onClick) {
        cfg.onClick(ev, cfg)
      }
    })


    // The content list
    cfg.indicators.forEach((item, index) => {
      let i = Math.floor(index / 2);
      // name text
      group.addShape('text', {
        attrs: {
          textBaseline: 'top',
          y: titleHeight + i * 40 + 10,
          x: 20 + (index % 2) * 100,
          lineHeight: 20,
          text: item.name,
          fill: 'rgba(0,0,0, 0.4)',
          fontSize: 11
        },
        name: `index-title-${index}`,
      });

      // value text
      let valEl = group.addShape('text', {
        attrs: {
          textBaseline: 'top',
          y: titleHeight + i * 40 + 25,
          x: 20 + (index % 2) * 100,
          lineHeight: 20,
          text: numConvert(item.value, 2, true),
          fill: '#595959',
          cursor: 'pointer',
          fontSize: 14
        },
        data: item,
        name: `index-value-${index}`,
      });
      valEl.on('click', (ev) => {
        if (cfg.indicatorClick && typeof cfg.indicatorClick === 'function') {
          cfg.indicatorClick({
            ...item,
            nodeId: cfg.id,
            nodeType: cfg.nodeType || null
          }, ev)
        }
      });
      valEl.on('mouseenter', (ev) => {
        ev.target.attr('fill', strokeColor);
      })
      valEl.on('mouseleave', (ev) => {
        ev.target.attr('fill', '#595959');
      })
    });
    return shape;
  },
  getAnchorPoints() {
    return [
      [1, 0.5],
      [0, 0.5]
    ]
  }
},
  'rect',
);

// 自定义ER节点
(function () {
  const isInBBox = (point, bbox) => {
    const {
      x,
      y
    } = point;
    const {
      minX,
      minY,
      maxX,
      maxY
    } = bbox;

    return x < maxX && x > minX && y > minY && y < maxY;
  };

  const itemHeight = 30;

  G6.registerBehavior("dice-er-scroll", {
    getDefaultCfg() {
      return {
        multiple: true,
      };
    },
    getEvents() {
      return {
        itemHeight: 50,
        wheel: "scorll",
        click: "click",
        "node:mousemove": "move",
      };
    },
    scorll(e) {
      e.preventDefault();
      const {
        graph
      } = this;
      const nodes = graph.getNodes().filter((n) => {
        const bbox = n.getBBox();
        return isInBBox(graph.getPointByClient(e.clientX, e.clientY), bbox);
      });
      if (nodes) {
        nodes.forEach((node) => {
          const model = node.getModel();
          if (model.attrs.length < 9) {
            return;
          }
          const idx = model.startIndex || 0;
          let startX = model.startX || 0.5;
          let startIndex = idx + e.deltaY * 0.02;
          startX -= e.deltaX;
          if (startIndex < 0) {
            startIndex = 0;
          }
          if (startX > 0) {
            startX = 0;
          }
          if (startIndex > model.attrs.length - 1) {
            startIndex = model.attrs.length - 1;
          }
          graph.update(node, {
            startIndex,
            startX,
          });
        });
      }


    },
    click(e) {
      const {
        graph
      } = this;
      const {
        y
      } = e;
      const item = e.item;
      const shape = e.shape;
      if (!item) {
        return;
      }
      const model = item.getModel();

      if (shape.get("name") === "collapse") {
        graph.updateItem(item, {
          collapsed: true,
          size: [300, 50],
        });
        setTimeout(() => graph.layout(), 100);
      } else if (shape.get("name") === "expand") {
        graph.updateItem(item, {
          collapsed: false,
          size: [300, 500],
        });
        setTimeout(() => graph.layout(), 100);
      }
    },
    move(e) {
      const name = e.shape.get("name");
      const item = e.item;

      if (name && name.startsWith("item")) {
        this.graph.updateItem(item, {
          selectedIndex: Number(name.split("-")[1]),
        });
      } else {
        this.graph.updateItem(item, {
          selectedIndex: NaN,
        });
      }
    },
  });

  G6.registerEdge("dice-er-edge", {
    draw(cfg, group) {
      const edge = group.cfg.item;
      const sourceNode = edge.getSource().getModel();
      const targetNode = edge.getTarget().getModel();

      const sourceIndex = sourceNode.attrs.findIndex(
        (e) => e.key === cfg.sourceKey
      );

      const sourceStartIndex = sourceNode.startIndex || 0;

      let sourceY = 15;

      if (!sourceNode.collapsed && sourceIndex > sourceStartIndex - 1) {
        sourceY = 30 + (sourceIndex - sourceStartIndex + 0.5) * 30;
        sourceY = Math.min(sourceY, 300);
      }

      const targetIndex = targetNode.attrs.findIndex(
        (e) => e.key === cfg.targetKey
      );

      const targetStartIndex = targetNode.startIndex || 0;

      let targetY = 15;

      if (!targetNode.collapsed && targetIndex > targetStartIndex - 1) {
        targetY = (targetIndex - targetStartIndex + 0.5) * 30 + 30;
        targetY = Math.min(targetY, 300);
      }

      const startPoint = {
        ...cfg.startPoint
      };
      const endPoint = {
        ...cfg.endPoint
      };

      startPoint.y = startPoint.y + sourceY;
      endPoint.y = endPoint.y + targetY;

      let shape;
      if (sourceNode.id !== targetNode.id) {
        shape = group.addShape("path", {
          attrs: {
            stroke: "#5B8FF9",
            path: [
              ["M", startPoint.x, startPoint.y],
              [
                "C",
                endPoint.x / 3 + (2 / 3) * startPoint.x,
                startPoint.y,
                endPoint.x / 3 + (2 / 3) * startPoint.x,
                endPoint.y,
                endPoint.x,
                endPoint.y,
              ],
            ],
            endArrow: true,
          },
          name: "path-shape",
        });
      } else if (!sourceNode.collapsed) {
        let gap = Math.abs((startPoint.y - endPoint.y) / 3);
        if (startPoint["index"] === 1) {
          gap = -gap;
        }
        shape = group.addShape("path", {
          attrs: {
            stroke: "#5B8FF9",
            path: [
              ["M", startPoint.x, startPoint.y],
              [
                "C",
                startPoint.x - gap,
                startPoint.y,
                startPoint.x - gap,
                endPoint.y,
                startPoint.x,
                endPoint.y,
              ],
            ],
            endArrow: true,
          },
          name: "path-shape",
        });
      }

      return shape;
    },
    afterDraw(cfg, group) {
      const labelCfg = cfg.labelCfg || {};
      const edge = group.cfg.item;
      const sourceNode = edge.getSource().getModel();
      const targetNode = edge.getTarget().getModel();
      if (sourceNode.collapsed && targetNode.collapsed) {
        return;
      }
      const path = group.find(
        (element) => element.get("name") === "path-shape"
      );

      const labelStyle = G6.Util.getLabelPosition(path, 0.5, 0, 0, true);
      const label = group.addShape("text", {
        attrs: {
          ...labelStyle,
          text: cfg.label || '',
          fill: "#000",
          textAlign: "center",
          stroke: "#fff",
          lineWidth: 1,
        },
      });
      label.rotateAtStart(labelStyle.rotate);
    },
  });

  G6.registerNode("dice-er-box", {
    draw(cfg, group) {
      const width = 250;
      const height = 316;
      const itemCount = 10;
      const boxStyle = {
        stroke: "#096DD9",
        radius: 4,
      };

      const {
        attrs = [],
        startIndex = 0,
        selectedIndex,
        collapsed,
        icon,
      } = cfg;
      const list = attrs;
      const afterList = list.slice(
        Math.floor(startIndex),
        Math.floor(startIndex + itemCount - 1)
      );
      const offsetY = (0.5 - (startIndex % 1)) * itemHeight + 30;

      group.addShape("rect", {
        attrs: {
          fill: boxStyle.stroke,
          height: 30,
          width,
          radius: [boxStyle.radius, boxStyle.radius, 0, 0],
        },
        draggable: true,
      });

      let fontLeft = 12;

      if (icon && icon.show !== false) {
        group.addShape("image", {
          attrs: {
            x: 8,
            y: 8,
            height: 16,
            width: 16,
            ...icon,
          },
        });
        fontLeft += 18;
      }

      group.addShape("text", {
        attrs: {
          y: 22,
          x: fontLeft,
          fill: "#fff",
          text: cfg.label,
          fontSize: 12,
          fontWeight: 500,
        },
      });

      group.addShape("rect", {
        attrs: {
          x: 0,
          y: collapsed ? 30 : 300,
          height: 15,
          width,
          fill: "#eee",
          radius: [0, 0, boxStyle.radius, boxStyle.radius],
          cursor: "pointer",
        },
        name: collapsed ? "expand" : "collapse",
      });

      group.addShape("text", {
        attrs: {
          x: width / 2 - 6,
          y: (collapsed ? 30 : 300) + 12,
          text: collapsed ? "+" : "-",
          width,
          fill: "#000",
          radius: [0, 0, boxStyle.radius, boxStyle.radius],
          cursor: "pointer",
        },
        name: collapsed ? "expand" : "collapse",
      });

      const keyshape = group.addShape("rect", {
        attrs: {
          x: 0,
          y: 0,
          width,
          height: collapsed ? 45 : height,
          ...boxStyle,
        },
        draggable: true,
      });

      if (collapsed) {
        return keyshape;
      }

      const listContainer = group.addGroup({});
      listContainer.setClip({
        type: "rect",
        attrs: {
          x: -8,
          y: 30,
          width: width + 16,
          height: 300 - 30,
        },
      });
      listContainer.addShape({
        type: "rect",
        attrs: {
          x: 1,
          y: 30,
          width: width - 2,
          height: 300 - 30,
          fill: "#fff",
        },
        draggable: true,
      });

      if (list.length > itemCount) {
        const barStyle = {
          width: 4,
          padding: 0,
          boxStyle: {
            stroke: "#00000022",
          },
          innerStyle: {
            fill: "#00000022",
          },
        };

        listContainer.addShape("rect", {
          attrs: {
            y: 30,
            x: width - barStyle.padding - barStyle.width,
            width: barStyle.width,
            height: height - 30,
            ...barStyle.boxStyle,
          },
        });

        const indexHeight =
          afterList.length > itemCount ?
            (afterList.length / list.length) * height :
            10;

        listContainer.addShape("rect", {
          attrs: {
            y: 30 + barStyle.padding + (startIndex / list.length) * (height - 30),
            x: width - barStyle.padding - barStyle.width,
            width: barStyle.width,
            height: Math.min(height, indexHeight),
            ...barStyle.innerStyle,
          },
        });
      }
      if (afterList) {
        afterList.forEach((e, i) => {
          const isSelected = Math.floor(startIndex) + i === Number(selectedIndex);
          let {
            key = "", type
          } = e;
          if (type) {
            key += " - " + type;
          }
          const label = key.length > 26 ? key.slice(0, 24) + "..." : key;

          listContainer.addShape("rect", {
            attrs: {
              x: 1,
              y: i * itemHeight - itemHeight / 2 + offsetY,
              width: width - 4,
              height: itemHeight,
              radius: 2,
              lineWidth: 1,
              cursor: "pointer",
            },
            name: `item-${Math.floor(startIndex) + i}-content`,
            draggable: true,
          });

          if (!cfg.hideDot) {
            listContainer.addShape("circle", {
              attrs: {
                x: 0,
                y: i * itemHeight + offsetY,
                r: 3,
                stroke: boxStyle.stroke,
                fill: "white",
                radius: 2,
                lineWidth: 1,
                cursor: "pointer",
              },
            });
            listContainer.addShape("circle", {
              attrs: {
                x: width,
                y: i * itemHeight + offsetY,
                r: 3,
                stroke: boxStyle.stroke,
                fill: "white",
                radius: 2,
                lineWidth: 1,
                cursor: "pointer",
              },
            });
          }

          listContainer.addShape("text", {
            attrs: {
              x: 12,
              y: i * itemHeight + offsetY + 6,
              text: label,
              fontSize: 12,
              fill: "#000",
              fontFamily: "Avenir,-apple-system,BlinkMacSystemFont,Segoe UI,PingFang SC,Hiragino Sans GB,Microsoft YaHei,Helvetica Neue,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol",
              full: e,
              fontWeight: isSelected ? 500 : 100,
              cursor: "pointer",
            },
            name: `item-${Math.floor(startIndex) + i}`,
          });
        });
      }
      return keyshape;
    },
    getAnchorPoints() {
      return [
        [0, 0],
        [1, 0],
      ];
    },
  });
})()

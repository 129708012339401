<template>
  <basic-container>
    <avue-crud
      :page.sync="page"
      :data="tableDatas"
      :option="tableOption"
      :table-loading="tableLoading"
      @on-load="onLoad"
      @refresh-change="onLoad"
      @row-save="rowSave"
      @row-update="rowUpdate"
      @row-del="rowDelete"
    >
      <template slot="dataAreaName" slot-scope="scope">
        <el-link type="primary" :href="'#/dws/datawrite/database/' + scope.row.id">{{scope.row.dataAreaName}}</el-link>
      </template>
    </avue-crud>
  </basic-container>
</template>
<script>
import {
  listDataWrite,
  createDataWrite,
  updateDataWrite,
  deleteDataWrite
} from "@/api/dws/datawrite";

export default {
  data(){
    return {
      page: {
          pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableLoading: false,
      tableDatas: [],
      tableOption: {
        labelWidth:110,
        addBtn:false,
        menu:false,
        column:[
          {
            label:'数据分类',
            prop:'dataAreaName',
            rules: [{
              required: true,
              message: "请输入读取类型",
              trigger: "blur"
            }],
          },
          {
            label:'描述',
            prop:'dataAreaDesc',
            type: "textarea",
            minRows: 2,
            span: 24
          },
          {
            label:'状态',
            prop:'entableStatus',
            type: "radio",
            rules: [{
              required: true,
              message: "请选择状态",
              trigger: "blur"
            }],
            dicData: [
              {
                label: '启用',
                value: '1'
              },
              {
                label: '禁用',
                value: '0'
              }
            ]
          },
          {
            label:'创建人',
            prop:'createUser',
            display:false
          },
          {
            label:'创建时间',
            prop:'createTime',
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            valueFormat: "yyyy-MM-dd hh:mm:ss",
            display:false
          }
        ]
      }
    }
  },
  methods: {
    onLoad(){
      this.tableLoading = true;
      let params = {
        modelName:'',
        pageNo:this.page.currentPage,
        pageSize:this.page.pageSize
      };
      listDataWrite(params).then(
        res => {
          this.tableDatas = res['data']['data']['lists'];
          this.page.total = res['data']['data']['totalCount'];
          this.tableLoading = false;
        }
      ).catch(() => {
        this.tableDatas = [];
        this.tableLoading = false;
        this.page.total = 0;
      });
    },
    rowSave(row , done){
      createDataWrite(row).then(
        res => {
          this.onLoad();
          this.$message.success(res.data['msg'] || '成功');
          done();
        }
      ).catch(() => {
        done();
        this.$message.error('失败')
      })
    },
    rowUpdate(row, index , done){
      updateDataWrite(row).then(
        res => {
          this.onLoad();
          this.$message.success(res.data['msg'] || '成功');
          done();
        }
      ).catch(() => {
        done();
        this.$message.error('失败')
      })
    },
    rowDelete(row , index){
      this.$confirm(`确定要删除${row.modelName}？` , `删除模型` , {
        type: 'warning',
        beforeClose: (action , instance , done) => {
          if(action === 'confirm'){
            deleteDataWrite({
              id: row.id
            }).then(
              () => {
                this.$message({
                  type: 'success',
                  message: '操作成功'
                });
                this.onLoad();
                done();
              }
            ).catch(() => {
              done();
            })
          }else{
            done();
          }
        }
      });
    }
  }
}
</script>

<template>
    <div>
        <basic-container class="cards">
            <el-form :inline="true" :model="formInline" size="small" class="demo-form-inline">
                <el-form-item label="时间">
                    <el-date-picker class="w-150" type="year" :clearable="false" @change="changeData" v-model="formInline.startDate" placeholder="开始日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="季度">
                    <el-select class="w-250" v-model="formInline.quarter" :clearable="false" multiple size="small" @change="quarterSort" placeholder="请选择季度">
                        <el-option label="第一季度" :value="1"> </el-option>
                        <el-option label="第二季度" :value="2"> </el-option>
                        <el-option label="第三季度" :value="3"> </el-option>
                        <el-option label="第四季度" :value="4"> </el-option>
                    </el-select>
                </el-form-item>
                <!-- <el-form-item label="-" width="300px">
          <el-date-picker
            class="w-150"
            @change="changeData"
            v-model="formInline.endDate"
            placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item> -->
                <el-form-item label="所属区域" prop="name">
                    <el-select v-model="formInline.subPartId" size="small" filterable clearable @change="getBuildList" placeholder="请选择区域">
                        <template v-for="(item, index) in partList">
                            <el-option v-if="item.parent_id != 0" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="楼宇">
                    <el-select filterable v-model="formInline.buildingId" clearable class="w-200" size="small" @change="getCompanyList" placeholder="请选择所属楼宇">
                        <el-option v-for="(item, index) in buildList" :key="index" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="企业">
                    <el-select v-model="formInline.companyId" clearable class="w-200" filterable size="small" @change="getContractList" placeholder="请选择企业" @clear="clearCom">
                        <el-option v-for="(item, index) in companyList" :key="index" :label="item.name" :value="item.company_id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="合同">
                    <el-select class="w-200" v-model="formInline.contractId" clearable filterable size="small" @change.native="selectBlur" @blur.native="selectBlur" placeholder="请选择合同">
                        <el-option v-for="(item, index) in contractList" :key="index" :label="item.contract_code" :value="item.contract_code">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="search">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button class="pull-right" type="primary" plain @click="exportTable" icon="el-icon-download" size="small">导出</el-button>
                </el-form-item>
            </el-form>
        </basic-container>
        <basic-container isCard>
            <div class="fts-16 text-center mar-b-10">
                {{ formInline.startDate }}政策企业挂账
            </div>
            <el-table :data="tableData" style="width: 100%" id="excelTable" :height="height" border v-loading="isLoading" element-loading-text="加载中，请稍后..." :span-method="objectSpanMethod">
                <!-- <el-table-column header-align="center" :label="`${formInline.startDate} 政策企业挂账`"> -->
                <el-table-column type="index" header-align="center" width="50" fixed>
                </el-table-column>
                <el-table-column fixed prop="policy_title" label="政策" align="left" header-align="center" width="220">
                </el-table-column>
                <el-table-column fixed prop="contract_code" header-align="center" align="center" label="合同编号" width="200">
                </el-table-column>
                <!-- <el-table-column
            prop="renters_name"
            label="企业名称"
            align="left"
            header-align="center"
            width="240"
          >
          </el-table-column> -->

                <el-table-column fixed prop="renters_name" align="left" header-align="center" label="企业名称" width="250">
                    <template slot-scope="{ row }">
                        <span :class="{ ft500: !row.contract_code }">{{
                row.renters_name
              }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="htqx" align="center" label="合同期限" width="200">
                </el-table-column>
                <el-table-column prop="cxhtqx" align="center" :label="`${formInline.startDate} 合同租期`" width="200">
                </el-table-column>

                <el-table-column prop="fjh" header-align="center" align="left" label="房间号" width="220">
                </el-table-column>
                <el-table-column prop="jzmj" header-align="center" align="right" label="建筑面积(m²)" width="100">
                    <template slot-scope="{ row }">
                        {{ toqfw(row.jzmj) }}
                    </template>
                </el-table-column>
                <el-table-column label="项目" header-align="center" prop="xm" width="100">
                </el-table-column>
                <el-table-column label="应收款" value1 header-align="center" width="180">
                    <el-table-column prop="yszc" header-align="center" align="right" label="其中政策(元)" width="150">
                        <template slot-scope="{ row }">
                            {{ toqfw(row.yszc) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="ys_fz" header-align="center" align="right" label="其中减半收取(元)" width="150">
                        <template slot-scope="{ row }">
                            {{ toqfw(row.ys_fz) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="ys_fz_xj" header-align="center" align="right" label="小计(元)" width="150">
                        <template slot-scope="{ row }">
                            {{ toqfw(row.ys_fz_xj) }}
                        </template>
                    </el-table-column>
                </el-table-column>
                <template v-for="(jd, index) of formInline.quarter">
                    <template>
                        <el-table-column :label="`第${jd}季度已挂帐`" header-align="center">
                            <el-table-column :prop="`gz${jd}`" header-align="center" align="right" width="150" :label="`挂帐(政策)(元)`">
                                <template slot-scope="{ row }">
                                    <!-- {{ formInline.quarter.indexOf(jd) }} -->
                                    {{ toqfw(row[`gz${jd}`]) }}
                                </template>
                            </el-table-column>
                            <el-table-column :prop="`gz_fz_qy${jd}`" width="180" header-align="center" align="right" :label="`挂帐(企业缴纳减免)(元)`">
                                <template slot-scope="{ row }">
                                    {{ toqfw(row[`gz_fz_qy${jd}`]) }}
                                </template>
                            </el-table-column>
                            <el-table-column :prop="`gz_fz_xj${jd}`" header-align="center" align="right" width="150" :label="`挂帐(小计)(元)`">
                                <template slot-scope="{ row }">
                                    {{ toqfw(row[`gz_fz_xj${jd}`]) }}
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </template>
                </template>

                <el-table-column prop="qr_fz_qy" header-align="center" align="right" width="150" :label="`已确认减半收入(元)`">
                    <template slot-scope="{ row }">
                        {{ toqfw(row.qr_fz_qy) }}
                    </template>
                </el-table-column>
                <el-table-column :label="`期末余额`" header-align="center" width="200">
                    <el-table-column prop="ye_fz_zc" header-align="center" align="right" width="150" label="政策(元)">
                        <template slot-scope="{ row }">
                            {{ toqfw(row.ye_fz_zc) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="ye_fz_qy" label="企业缴纳(元)" header-align="center" width="150" align="right">
                        <template slot-scope="{ row }">
                            {{ toqfw(row.ye_fz_qy) }}
                        </template>
                    </el-table-column>
                </el-table-column>
                <!-- </el-table-column> -->
                <el-table-column prop="bz" width="150" label="备注"> </el-table-column>
            </el-table>
        </basic-container>
    </div>
</template>

<script>
import { getMetaCommonList } from '@/api/busiMode/metaCommon';
import XLSXS from 'xlsx-style';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import Vue from 'vue';
import { getPlanReceipt } from '@/api/reportForms/reportForms';
var quarterOfYear = require('dayjs/plugin/quarterOfYear');
dayjs.extend(quarterOfYear);
const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
export default {
    data() {
        return {
            height: viewPortHeight - 320,
            formInline: {
                startDate: '2023',
                quarter: [dayjs().quarter()],
            },
            value1: '2021',
            tableData: [],
            buildList: [],
            companyList: [],
            partList: [],
            contractList: [],
            partName: '',
            jdTitle: '全年',
            isLoading: false,
        };
    },
    watch: {
        value1: {
            handler: function (newValue, oldName) {
                if (newValue) {
                    this.value1 = dayjs(newValue).format('YYYY');
                }
            },
            immediate: true,
            deep: true,
        },
    },
    mounted() {
        //this.formInline.quarter = dayjs().quarter();
        this.getPart();
        this.search();
    },

    methods: {
        toqfw(num) {
            if (Number(num)) {
                let n = num;
                n = this.addDou(Number(num).toFixed(2));
                return n;
            } else {
                return num;
            }
        },
        selectBlur(e) {
            console.log(e);
            Vue.set(this.formInline, 'contractId', e.target.value);
        },
        changeJdTitle() {
            if (this.formInline.quarter == '') {
                this.jdTitle = '全年';
            }
            if (this.formInline.quarter == 1) {
                this.jdTitle = '第一季度';
            }
            if (this.formInline.quarter == 2) {
                this.jdTitle = '第二季度';
            }
            if (this.formInline.quarter == 3) {
                this.jdTitle = '第三季度';
            }
            if (this.formInline.quarter == 4) {
                this.jdTitle = '第四季度';
            }
        },
        addDou(num) {
            var numArr = num.split('.');
            num = numArr[0];
            var result = '';
            while (num.length > 3) {
                result = ',' + num.slice(-3) + result;
                num = num.slice(0, num.length - 3);
            }
            if (num) {
                result = num + result;
            }
            result = result + '.' + numArr[1];
            return result;
        },
        clearCom() {
            delete this.formInline.company_id;
            delete this.formInline.contract_code;
            this.contractList = [];
            this.$forceUpdate();
        },
        quarterSort() {
            this.formInline.quarter = this.formInline.quarter.sort();
        },
        changeData() {
            if (this.formInline.startDate) {
                this.formInline.startDate = this.formatDate(this.formInline.startDate);
            } else {
                this.formInline.startDate = '';
            }
            // if (this.formInline.endDate) {
            //   this.formInline.endDate = this.formatDate(this.formInline.endDate);
            // } else {
            //   this.formInline.endDate = "";
            // }
        },
        formatDate(createTime) {
            let obj = '';
            if (createTime) {
                if (createTime) {
                    obj = dayjs(createTime).format('YYYY');
                }
            }
            return obj;
        },
        changejd() {
            console.log(this.formInline.jd);
        },
        search() {
            if (this.formInline.quarter == '') {
                console.log(this.formInline.quarter);
                this.$message({
                    type: 'error',
                    message: '请选择季度!',
                });
                return false;
            }
            if (this.formInline['startDate']) {
                this.formInline['startDate'] = dayjs(this.formInline['startDate']).format('YYYY');
            }
            this.getStatFinanceCalculateArea(this.formInline);
        },
        getStatFinanceCalculateArea(data) {
            let o = {};
            o = JSON.parse(JSON.stringify(data));
            if (data) {
                if (this.formInline.startDate) {
                    this.formInline.startDate = dayjs(this.formInline.startDate).format('YYYY');
                } else {
                    this.formInline.startDate = '';
                }
            }
            if (this.formInline.quarter) {
                this.formInline.startDate = dayjs(this.formInline.startDate).format('YYYY');
            }
            if (o['quarter']) {
                o['quarter'] = Math.max(...o['quarter']) || 4;
            }
            this.isLoading = true;
            getPlanReceipt(o)
                .then(res => {
                    this.tableData = [];
                    this.tdList = [];

                    const data = res.data;
                    let datalist = [];
                    data.data.resultDetail.forEach(e => {
                        datalist.push(
                            Object.assign(e, {
                                policy_title: e.policy_title,
                                contract_code: e.contract_code,
                                renters_name: e.renters_name,
                                htqx: e.htqx,
                                cxhtqx: e.cxhtqx,
                                fjh: e.fjh,
                                jzmj: e.jzmj,
                                xm: '房屋租金',
                                yszc: e.ys_fz_zc,
                                ys_fz: e.ys_fz_jm,
                                ys_fz_xj: e.ys_fz_xj,
                                qyjm: e.yys_fz_jm,
                                gz1: e.gz_fz_zc1,
                                gz2: e.gz_fz_zc2,
                                gz3: e.gz_fz_zc3,
                                gz4: e.gz_fz_zc4,

                                gz_fz_qy1: e.gz_fz_qy1,
                                gz_fz_qy2: e.gz_fz_qy2,
                                gz_fz_qy3: e.gz_fz_qy3,
                                gz_fz_qy4: e.gz_fz_qy4,

                                gz_fz_xj1: e.gz_fz_xj1,
                                gz_fz_xj2: e.gz_fz_xj2,
                                gz_fz_xj3: e.gz_fz_xj3,
                                gz_fz_xj4: e.gz_fz_xj4,

                                qr_fz_qy: e.qr_fz_qy,
                                ye_fz_zc: e.ye_fz_zc,

                                ye_fz_qy1: e.ye_fz_qy1,
                                ye_fz_qy2: e.ye_fz_qy2,
                                ye_fz_qy3: e.ye_fz_qy3,
                                ye_fz_qy4: e.ye_fz_qy4,
                                bz: e.bz,
                            }),
                        );
                        datalist.push({
                            policy_title: e.policy_title,
                            contract_code: e.contract_code,
                            renters_name: e.renters_name,
                            htqx: e.htqx,
                            cxhtqx: e.cxhtqx,
                            fjh: e.fjh,
                            jzmj: e.jzmj,
                            xm: '综合服务费',
                            yszc: e.ys_zhfwf_zc,
                            ys_fz: e.ys_zhfwf_jm,
                            ys_fz_xj: e.ys_zhfwf_xj,
                            qyjm: e.yys_zhfwf_jm,
                            gz1: e.gz_zhfwf_zc1,
                            gz2: e.gz_zhfwf_zc2,
                            gz3: e.gz_zhfwf_zc3,
                            gz4: e.gz_zhfwf_zc4,

                            gz_fz_qy1: e.gz_zhfwf_qy1,
                            gz_fz_qy2: e.gz_zhfwf_qy2,
                            gz_fz_qy3: e.gz_zhfwf_qy3,
                            gz_fz_qy4: e.gz_zhfwf_qy4,

                            gz_fz_xj1: e.gz_zhfwf_xj1,
                            gz_fz_xj2: e.gz_zhfwf_xj2,
                            gz_fz_xj3: e.gz_zhfwf_xj3,

                            gz_fz_xj4: e.gz_zhfwf_xj4,
                            qr_fz_qy: e.qr_zhfwf_qy,
                            ye_fz_zc: e.ye_zhfwf_zc,
                            ye_fz_qy: e.ye_zhfwf_qy,
                            bz: e.bz,
                        });
                        datalist.push({
                            policy_title: '',
                            contract_code: '',
                            renters_name: '小计',
                            htqx: '',
                            cxhtqx: '',
                            fjh: '',
                            jzmj: '',
                            xm: '',
                            yszc: e.ys_zc_sum,
                            ys_fz: e.ys_jm_sum,
                            ys_fz_xj: e.ys_xj_sum,
                            qyjm: e.yys_zhfwf_jm,
                            gz1: e.gz_zc_sum1,
                            gz2: e.gz_zc_sum2,
                            gz3: e.gz_zc_sum3,
                            gz4: e.gz_zc_sum4,

                            gz_fz_qy1: e.gz_qy_sum1,
                            gz_fz_qy2: e.gz_qy_sum2,
                            gz_fz_qy3: e.gz_qy_sum3,
                            gz_fz_qy4: e.gz_qy_sum4,

                            gz_fz_xj1: e.gz_xj_sum1,
                            gz_fz_xj2: e.gz_xj_sum2,
                            gz_fz_xj3: e.gz_xj_sum3,
                            gz_fz_xj4: e.gz_xj_sum4,
                            qr_fz_qy: e.qr_qy_sum,
                            ye_fz_zc: e.ye_zc_sum,
                            ye_fz_qy: e.ye_qy_sum,
                            bz: '',
                        });
                    });

                    datalist.push({
                        policy_title: '',
                        contract_code: '',
                        renters_name: '合计',
                        htqx: '',
                        cxhtqx: '',
                        fjh: '',
                        jzmj: '',
                        xm: '',
                        yszc: data.data.resultTotal.ys_zc_sum,
                        ys_fz: data.data.resultTotal.ys_jm_sum,
                        ys_fz_xj: data.data.resultTotal.ys_xj_sum,
                        qyjm: data.data.resultTotal.ys_jm_sum,
                        gz1: data.data.resultTotal.gz_zc_sum1,
                        gz2: data.data.resultTotal.gz_zc_sum2,
                        gz3: data.data.resultTotal.gz_zc_sum3,
                        gz4: data.data.resultTotal.gz_zc_sum4,

                        gz_fz_qy1: data.data.resultTotal.gz_qy_sum1,
                        gz_fz_qy2: data.data.resultTotal.gz_qy_sum2,
                        gz_fz_qy3: data.data.resultTotal.gz_qy_sum3,
                        gz_fz_qy4: data.data.resultTotal.gz_qy_sum4,

                        gz_fz_xj1: data.data.resultTotal.gz_xj_sum1,
                        gz_fz_xj2: data.data.resultTotal.gz_xj_sum2,
                        gz_fz_xj3: data.data.resultTotal.gz_xj_sum3,
                        gz_fz_xj4: data.data.resultTotal.gz_xj_sum4,
                        qr_fz_qy: data.data.resultTotal.qr_qy_sum,
                        ye_fz_zc: data.data.resultTotal.ye_zc_sum,
                        ye_fz_qy: data.data.resultTotal.ye_qy_sum,
                        bz: '',
                    });
                    this.tableData = datalist;
                    this.mergeTableRow(this.tableData, [
                        'policy_title',
                        'contract_code',
                        'renters_name',
                        'htqx',
                        'cxhtqx',
                        'fjh',
                        'jzmj',
                    ]);
                    this.isLoading = false;
                })
                .catch(() => {
                    this.isLoading = false;
                });
        },

        getPart() {
            let body = {
                current: 1,
                size: 100,
                orders: [{ field: 'sort', sort: 'ASC' }],
            };
            getMetaCommonList('wisdom_park.wp_part', body).then(res => {
                const data = res.data.data;
                this.partList = data.records;
            });
        },
        getBuildList(e) {
            this.formInline.buildingId = '';
            this.partList.forEach(e => {
                if (e.id == this.formInline.subPartId) {
                    this.partName = e.name;
                }
            });
            getMetaCommonList('wisdom_park.wp_building', {
                size: 100,
                current: 1,
                wheres: [{ field: 'sub_part_id', value: this.formInline.subPartId }],
            }).then(res => {
                const data = res.data.data;
                this.buildList = data.records;
            });
        },
        getCompanyList() {
            getMetaCommonList('wisdom_park_statistics.v_wp_company_building_stat', {
                size: 100,
                current: 1,
                wheres: [{ field: 'building_id', value: this.formInline.buildingId }],
            }).then(res => {
                const data = res.data.data;
                this.companyList = data.records;
            });
        },
        getContractList() {
            console.log(this.formInline);
            if (this.formInline.companyId) {
                getMetaCommonList('wisdom_park_statistics.v_company_contract_stat', {
                    size: 100,
                    current: 1,
                    wheres: [{ field: 'company_id', value: this.formInline.companyId }],
                }).then(res => {
                    const data = res.data.data;
                    this.contractList = data.records;
                });
            }
        },

        mergeTableRow(data, merge) {
            if (!merge || merge.length === 0) {
                return data;
            }
            merge.forEach(m => {
                const mList = {};
                data = data.map((v, index) => {
                    const rowVal = v[m];
                    if (mList[rowVal] && mList[rowVal].newIndex === index) {
                        mList[rowVal]['num']++;
                        mList[rowVal]['newIndex']++;
                        data[mList[rowVal]['index']][m + '-span'].rowspan++;
                        v[m + '-span'] = {
                            rowspan: 0,
                            colspan: 0,
                        };
                    } else {
                        mList[rowVal] = { num: 1, index: index, newIndex: index + 1 };
                        v[m + '-span'] = {
                            rowspan: 1,
                            colspan: 1,
                        };
                    }
                    return v;
                });
            });
            return data;
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            const span = column['property'] + '-span';
            if (row[span]) {
                return row[span];
            }
        },
        // 点击事件触发的方法
        exportTable() {
            // 首先加载  <table id='excelTable'> 标签 为 workbook对象
            // let workbook = this.$XLSX.utils.table_to_book(document.getElementById('excelTable'), { raw: true });
            // var fix = document.querySelector('.el-table__fixed');
            // var workbook;
            // // 判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
            // if (fix) {
            //     /* 从表生成工作簿对象 */
            //     workbook = this.$XLSX.utils.table_to_book(document.querySelector('#excelTable').removeChild(fix));
            //     document.querySelector('#excelTable').appendChild(fix);
            // } else {
            //     workbook = this.$XLSX.utils.table_to_book(document.querySelector('#excelTable'));
            // }

            var workbook;
            var fix = document.querySelector('.el-table__fixed');
            var dt = document.querySelector('#excelTable');
            var xlsxParam = { raw: true }; //转换成excel时，使用原始的格式,解决工单号过长导致数字自动转换为科学计数法导致精度缺失
            if (fix) {
                workbook = this.$XLSX.utils.table_to_book(dt.removeChild(fix), xlsxParam);
                dt.appendChild(fix);
            } else {
                workbook = this.$xlsx.utils.table_to_book(dt, xlsxParam);
            }
            // 先定义列宽 ， 我这里文件一共有7 列 ，所以设置7列宽度相等都为 20 ，如果你有很多列建议直接 map()
            let wscols = [
                { wch: 5 },
                { wch: 20 },
                { wch: 20 },
                { wch: 25 },
                { wch: 25 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
            ];

            // 获取 需要设置样式的 sheet ，我这里只有 一个 sheet 所以索引默认加载了第一个
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            // 设置列宽
            sheet['!cols'] = wscols;
            // 定义框线样式
            const borderAll = {
                color: { auto: 1 },
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            };

            // 这里的意思为 先默认代表表格的 7 个列  的 列号
            // 比如 A2 意思是 第一列 第2行
            const _letterList = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
            // 定义一个 箭头函数，接受三个参数，分别为 当前Sheet , 行列号（例如：‘A2’）, 是否是新增样式
            const _mapCellStyle = (_sheet, _key, _type) => {
                const _cellStyle = {
                    border: borderAll,
                    alignment: {
                        wrapText: true,
                        horizontal: 'center',
                        vertical: 'center',
                    },
                    font: {
                        name: '微软雅黑',
                        sz: 10,
                        height: 50,
                    },
                    height: 50,
                    bold: true,
                    numFmt: '0',
                };

                const _title = {
                    // border: borderAll,
                    alignment: {
                        wrapText: true,
                        horizontal: 'center',
                        vertical: 'center',
                    },
                    font: {
                        name: '微软雅黑',
                        sz: 16,
                    },
                    bold: false,
                    numFmt: '0',
                };
                if (_key == 'A1') {
                    if (_type === 'append') {
                        // 需要新增样式，说明当前加载的sheet中并不存在该单元格，可能当前单元格被前边合并，这种被合并的单元格也得设置样式，
                        // 否则就会出现 合并单元格只有第一格带框线，后边没框线的情况出现，所以这里需要将后边的边框样式也加上。
                        _sheet[_key] = {
                            s: _title,
                        };
                    } else {
                        // 若不是新增样式 则代表sheet中已存在该表格直接修改其 s 属性即可使属性生效
                        if (typeof _sheet[_key] === 'object') {
                            _sheet[_key].s = _title;
                        }
                    }
                } else {
                    if (_type === 'append') {
                        // 需要新增样式，说明当前加载的sheet中并不存在该单元格，可能当前单元格被前边合并，这种被合并的单元格也得设置样式，
                        // 否则就会出现 合并单元格只有第一格带框线，后边没框线的情况出现，所以这里需要将后边的边框样式也加上。
                        _sheet[_key] = {
                            s: _cellStyle,
                        };
                    } else {
                        // 若不是新增样式 则代表sheet中已存在该表格直接修改其 s 属性即可使属性生效
                        if (typeof _sheet[_key] === 'object') {
                            _sheet[_key].s = _cellStyle;
                        }
                    }
                }
            };

            // sheet 不懂得可以单独打印一下，它其实就是一个对象，键代表行列号（‘A2’），值为该单元格的值，样式等，
            // 我们需要做的就是修改其值中的样式
            Object.keys(sheet).forEach((i, _) => {
                // 无用属性直接过滤
                if (i !== '!ref' || i !== '!cols' || i !== '!merges' || i !== 'Am') {
                    // 首先设置遍历到的 当前 key
                    let _nowKey = i;
                    // 然后调用 _mapCellStyle  渲染当前单元格样式
                    _mapCellStyle(sheet, _nowKey);
                    // 我们这里先获取下一个行列号  例如当前_nowKey 是 A1  这里就是获取到 B1 ,及 当前行的 下一列数据
                    let _nextKey = _letterList[_letterList.indexOf(_nowKey[0]) + 1] + i.slice(1);

                    // 判断 B1 是否在 Sheet的key中,如果不存在，只可能是因为B1所在单元格已经被A1所合并，所以我们需要将B1也调用一下  _mapCellStyle
                    // 渲染 B1 的样式，不然就会出现 A1 B1 这两格是合并的状态，只有A1有框线，而B1 没有框线的情况。
                    // 这里用 while 就是 要把需要合并的单元格全部 渲染上样式，直到可能 B4 在 Sheet中 停止
                    while (_nowKey[0] !== 'G' && !Object.keys(sheet).includes(_nextKey)) {
                        _mapCellStyle(sheet, _nextKey, 'append');
                        // 这里就是简单的交换索引
                        _nowKey = _nextKey;
                        _nextKey = _letterList[_letterList.indexOf(_nowKey[0]) + 1] + _nowKey.slice(1);
                    }
                }
            });

            let wopts = {
                bookType: 'xlsx',
                bookSST: false,
                type: 'buffer',
            };
            let _blob = XLSXS.write(workbook, wopts);

            FileSaver.saveAs(
                new Blob([_blob], {
                    type: 'application/octet-stream',
                }),
                this.formInline.startDate + '年收入表' + new Date().getTime() + '.xlsx',
            );
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
/deep/.el-table thead.is-group th {
    background: #fafafa;
}
/deep/ .el-form-item--small.el-form-item {
    margin-bottom: 18px !important;
}
.bg-white {
    background-color: #fff !important;
}

.cards {
    background: #fff;
    padding: 15px 10px 0px 10px !important;
    padding-right: 0px !important;
    margin-bottom: 15px;
}
.ft500 {
    font-weight: 700;
}
</style>

<template>
  <el-container style="height: calc(100% - 50px)" v-loading="pageLoading">
    <el-main class="pad-20 ov-a">
      <el-form
        class="mar-t-20"
        :model="form"
        :rules="rules" 
        ref="form"
        :size="formSize"
        label-width="100px"
      >
        <el-row>
          <el-col :span="8">
            <el-form-item label="维保单据号" prop="code">
              <el-input
                v-model="form.code"
                disabled
                placeholder="系统自动生成"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="维保类型" prop="repair_type">
              <el-select
                class="dis-b"
                :disabled="disabled"
                v-model="form.repair_type"
              >
                <el-option
                  v-for="item of dictData.repairType"
                  :key="item.id"
                  :label="item.dictValue"
                  :value="item.dictKey"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请人" prop="report_user">
              <model-search-select
                class="dis-b w-100p"
                :disabled="disabled"
                v-model="form.report_user"
                format="{name}_{deptName}_{postName}"
                :params="{tenantId: '000000'}"
                :fillModel.sync="form"
                :fills="[
                  {source: 'name' , target: 'report_user_name'}
                ]"
              >
                <template slot-scope="{item}">
                  <span style="float: left">{{ item.name }}{{item.postName ? `(${item.postName})` : ''}}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deptName }}</span>
                </template>
              </model-search-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="申请说明" prop="remarks">
              <el-input
                type="textarea"
                rows="5"
                :disabled="disabled"
                v-model="form.remarks"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <div class="pad-tb-10">
          <el-divider content-position="left">维保信息</el-divider>
        </div> -->
        <el-row>
          <el-col :span="8">
            <el-form-item label="维保资产" prop="asset_id">
              <model-search-select
                :disabled="disabled"
                class="dis-b w-100p"
                v-model="form.asset_id"
                model="wp_asset_detail"
                :fillModel.sync="form"
                labelKey="asset_name"
                valueKey="id"
                :fills="[
                  {source: 'asset_name' , target: 'asset_name'},
                  {source: 'system_asset_code' , target: 'system_asset_code'}
                ]"
              >
                <template slot-scope="{item}">
                  <span style="float: left">{{ item.asset_name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.system_asset_code }}</span>
                </template>
              </model-search-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="资产编码" prop="system_asset_code">
              <el-input
                v-model="form.system_asset_code"
                disabled
                placeholder="系统自动获取"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="计划维修日期" prop="plan_finish_date">
              <el-date-picker
                :disabled="disabled"
                class="dis-b w-100p"
                v-model="form.plan_finish_date"
                type="date"
                placeholder="选择日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="计划维修时长" prop="plan_repair_duration">
              <el-input
                type="number"
                v-model.number="form.plan_repair_duration"
                :disabled="disabled"
                placeholder="请输入计划维修时长"
              >
                <template slot="append">天</template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="维修厂家" prop="repair_manufacturer">
              <el-input
                v-model="form.repair_manufacturer"
                :disabled="disabled"
                placeholder="请输入维修厂家"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="故障说明" prop="fault_desc">
              <el-input
                type="textarea"
                rows="5"
                v-model="form.fault_desc"
                :disabled="disabled"
                placeholder="请输入故障说明"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-main>
    <el-footer v-if="!isView" height="50px" class="lh-50">
      <div class="text-center">
        <el-button
          :size="formSize"
          :loading="btnLoading"
          icon="el-icon-close"
          @click="back"
          >取 消</el-button
        >
        <el-button
          :size="formSize"
          :loading="btnLoading"
          icon="el-icon-check"
          type="primary"
          @click="saveForm(false)"
          >保 存</el-button
        >
        <el-button
          :size="formSize"
          :loading="btnLoading"
          icon="el-icon-check"
          type="primary"
          @click="saveForm(true)"
          >保存并提交审批</el-button
        >
      </div>
    </el-footer>
  </el-container>
</template>
<script>
import dayjs from "dayjs";

import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  delMetaCommon,
  getMetaCommonListTree,
  getMetaCommonDetail,
} from "@/api/busiMode/metaCommon";

import { searchList } from "@/api/system/user";
import { uuid } from "@/util/util";
import { getDictionary } from "@/api/system/dictbiz.js";

import { startFlow } from "@/api/flow/flow.js";

export default {
  data() {
    return {
      pageLoading: false,
      form: {
        code: `WB-${dayjs().format("YYYYMMDD")}${uuid(
          6,
          "1234567890"
        )}`,
        asset_id: "",
        asset_name: "",
        report_user: "",
        report_user_name: "",
        remarks: "",
        plan_finish_date: dayjs().format("YYYY-MM-DD HH:mm:ss"),
        plan_repair_duration: null,
        fault_desc: "",
        system_asset_code: "",
        repair_manufacturer: "",
        repair_type: "",
      },
      btnLoading: false,
      dictData: {
        assetsList: [],
        repairType: [],
        userList: [],
      },
      model: "wisdom_park.wp_asset_repair",
    };
  },
  computed: {
    disabled(){
      return this.isTodo || this.isView || false
    },
    isView(){
      return this.isTodo ? this.isTodo : this.view
    },
  },
  props: {
    id: {
      type: String,
      default: "",
    },

    formSize: {
      type: String,
      default: "small",
    },
    isAdd: {
      type: Boolean,
      default: true,
    },
    view: {
      type: Boolean,
      default: false,
    },
    isTodo: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  mounted() {
    this.getDictData();
    if (this.id) {
      this.form.code = "";
      this.getMetaCommonDetail();
    }
  },
  methods: {
    back() {
      this.$emit("back");
    },
    getDictData() {
      [{ code: "RepairType", prop: "repairType" }].forEach((item) => {
        getDictionary({
          code: item.code,
        }).then((res) => {
          if (res.data && res.data.data) {
            this.dictData[item.prop] = res.data.data;
          }
        });
      });
    },
    getMetaCommonDetail() {
      this.pageLoading = true;
      getMetaCommonDetail(this.model, {
        pk: "id",
        id: this.id,
        detailTable: this.detailTable,
        masterIdColumnName: this.masterIdColumnName,
      }).then((res) => {
        let data = res.data.data;
        this.form = data;
        this.pageLoading = false;
      }).catch(e => {
        this.pageLoading = false;
      });
    },
    startFlow(row) {
      return startFlow(
        row.id,
        this.$PCode.BIZ.ASSET_REPARIR,
        this.$PCode.ACTIONS.SUBMIT
      );
    },
    saveForm(isStartFlow) {
      this.$refs.form.validate((valid) => {
        if (valid) {
          let body = {
            ...this.form,
          };
          if (this.form.id) {
            delete body.id;
            body = {
              update: body,
              primaryKeyValue: this.form.id,
              primaryKey: "id",
            };
          } else {
            body = {
              values: body,
            };
          }
          this.btnLoading = true;
          (this.form.id ? updateMetaCommon : saveMetaCommon)(this.model, body)
            .then((res) => {
              if (isStartFlow) {
                return this.startFlow(this.form.id ? this.form : res.data.data).then((res1) => res1);
              } else {
                return res;
              }
            })
            .then((res) => {
              this.btnLoading = false;
              this.$message.success(res.data.msg);
              this.back();
            })
            .catch((e) => {
              this.btnLoading = false;
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

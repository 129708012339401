<template>
  <div>
    <draggable
      class="widget-form-group__body"
      style="display: contents"
      :list="menuBtnList"
      :group="{ name: 'menuBtnList' }"
      ghost-class="ghost"
      :animation="200"
      @add="handleWidgetGroupAdd($event, column)"
      @end="$emit('change')"
    >
      <template v-for="(btn, index) in menuBtnList">
        <el-button
          v-if="btn.show && btn.showPosotion == 'table'"
          :key="index"
          :type="btn.type"
          size="small"
          :icon="btn.icon + ' fts-12'"
          class="menuBtnList active"
          @click="operations(btn)"
        >
          <i class="el-icon-rank dropBtnIcon"></i>

          <el-dropdown @command="btbDO">
            <span class="el-dropdown-link">
              <i class="el-icon-setting"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                icon="el-icon-edit"
                :command="'btnEdit' + btn.value"
                >编辑</el-dropdown-item
              >
              <el-dropdown-item :command="btn.value" icon="el-icon-delete"
                >删除</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          {{ btn.label }}
        </el-button>
      </template>
    </draggable>
    <!-- <el-dropdown @command="btbDO">
      <el-button
        class="mar-l-10 designable-btn-dash"
        size="small"
        split-button
        icon="el-icon-setting"
        type="warning"
        plain
        >新增操作</el-button
      >
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>业务流程</el-dropdown-item>
        <el-dropdown-item :command="'btnAdd'">静态方法</el-dropdown-item>
        <el-dropdown-item>新增操作页</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown> -->
    <el-button
      class="mar-l-10 designable-btn-dash"
      size="small"
      split-button
      @click="addOpera"
      icon="el-icon-setting"
      type="warning"
      plain
      >新增操作</el-button
    >
    <div style="display: contents"></div>
    <el-drawer
      title="操作编辑"
      :visible.sync="btnEditVisible"
      append-to-body
      destroy-on-close
    >
      <menuOperDesign
        :tableOption="tableOption"
        @tableOperBtn="tableOperBtn"
      ></menuOperDesign>
    </el-drawer>
  </div>
</template>

<script>
import {
  menuBtnList,
  menuList,
} from "../../../../../../components/dynamic/table/options";
import iconList from "@/config/iconList";
import Draggable from "vuedraggable";
import menuOperDesign from "./menuOperDesign";

export default {
  name: "designBtn",
  watch: {},
  components: { Draggable, menuOperDesign },
  props: {
    menuBtnList: {
      type: Array,
      default: function () {
        return menuBtnList;
      },
    },
    tableOption: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      activeName: 0,
      menuList: menuList,
      btnEditVisible: false,
      btnEditObj: {},
      curBtnTpye: "",
      iconList: iconList,
      data: [
        {
          label: "接处警后管",
          children: [
            {
              label: "用户管理",
              children: [
                {
                  label: "新增",
                },
                {
                  label: "删除",
                },
              ],
            },
          ],
        },
        {
          label: "移动APP",
        },
      ],

      isShowSelect: false,
    };
  },

  created() {},
  mounted() {},

  methods: {
    addOpera() {
      this.$emit("operDo", "table");
    },
    operations(btn) {
      this.$emit("btnLeftDo", btn);
    },
    handleWidgetGroupAdd(evt, column) {
      let newIndex = evt.newIndex;
      const item = evt.item;

      if (newIndex == 1 && newIndex > column.children.column.length - 1)
        newIndex = 0;
      if (["分组"].includes(item.textContent)) {
        column.children.column.splice(newIndex, 1);
        return;
      }

      const data = this.deepClone(column.children.column[newIndex]);
      if (!data.prop)
        data.prop = "a" + Date.now() + Math.ceil(Math.random() * 99999);
      delete data.icon;
      if (data.type == "dynamic") data.span = 24;
      else data.span = 12;
      this.$set(column.children.column, newIndex, { ...data });
      this.menuBtnList = column.children.column[newIndex];
    },
    saveBtn() {
      if (this.curBtnTpye == "add") {
        this.btnEditObj.show = true;
        this.menuBtnList.push(this.btnEditObj);
      } else {
        this.menuBtnList.forEach((e, index) => {
          if (this.btnEditObj.value == e.value) {
            this.menuBtnList[index] = this.btnEditObj;
          }
        });
      }
      this.menuBtnList = JSON.parse(JSON.stringify(this.menuBtnList));
      this.btnEditVisible = false;
    },
    deleteIcon() {
      this.btnEditObj["icon"] = "";
    },
    iconClick(icon) {
      this.$refs.popover.showPopper = false;
      this.btnEditObj["icon"] = icon;
      this.btnEditObj = JSON.parse(JSON.stringify(this.btnEditObj));
    },
    btbDO(btn) {
      this.menuBtnList.forEach((e) => {
        if (btn.includes("btnEdit")) {
          this.btnEditVisible = true;
          this.btnEditObj = e;
          this.curBtnTpye = "edit";
        } else if (btn.includes("btnAdd")) {
          this.btnEditObj = {};
          this.curBtnTpye = "add";
          this.btnEditVisible = true;
        } else {
          if (e.value == btn) {
            e.show = false;
          }
        }
      });
      this.menuBtnList = JSON.parse(JSON.stringify(this.menuBtnList));
    },
    selectClassfy(data) {
      this.btnEditObj.model = data.label;
      this.isShowSelect = false;
    },
    changeSelectTree() {
      this.isShowSelect = true;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    hideParentClick(e) {
      var isOther =
        e.relatedTarget == null ||
        e.relatedTarget.closest("div.el-tree") == null ||
        e.relatedTarget.closest("div.el-tree").id != "floatTree";

      if (isOther) {
        this.isShowSelect = false;
      } else {
        e.target.focus();
      }
    },
    tableOperBtn(e) {
      this.btnEditVisible = false;
      this.$emit("tableOperBtn", e);
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.menuBtnList i {
  font-size: 12px;
}

.el-icon-delete {
  // display: none;
  position: absolute;
  top: 15px;
  left: 60px;
  cursor: pointer;
  color: #f56c6c;
}
.btnTypeActive {
  border: 1px dashed #1976d3;
}
.dropBtnIcon {
  position: absolute;
  /* right: -43px; */
  background: #f5896c !important;
  /* top: -20px; */
  padding: 2px;
  color: #fff;
  right: 16px;
  top: -1px;
}
.el-tree {
  padding: 5px 0;
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: auto;
  border: #ccc solid 1px;
  border-top: none;
}
</style>

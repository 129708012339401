<template>
  <el-scrollbar class="h-modelstree">
    <el-form
      ref="form"
      :model="modelForm"
      label-width="60px"
      label-position="right"
    >
      <el-col :span="24" class="pad-10">
        <el-form-item label="模型">
          <el-select
            label="模型"
            class="w-240"
            v-model="modelForm.model"
            size="small"
            filterable
            :loading="modelLoading"
            placeholder="请选择模型"
            @change="() => { formChange(); changeVal()}"
          >
            <el-option
              v-for="item in modelList"
              :key="item.tableName"
              :label="item.description"
              :value="item.tableName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="方法">
          <el-select
            v-model="modelForm.method"
            class="w-240"
            size="small"
            placeholder="操作"
            @change="formChange"
          >
            <el-option
              v-for="item of taskList"
              :value="item.value"
              :label="item.name"
              :key="item.value"
            >
              <span style="float: left">{{ item.name }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <avue-dynamic
          v-model="modelForm.fields"
          :children="tableDataOption"
        ></avue-dynamic>
        <template
          v-if="modelForm.method == 'update' || modelForm.method == 'delete'"
          ><p>限制</p>
          <avue-dynamic
            v-model="modelForm.filterCriteria"
            :children="opraOption"
          ></avue-dynamic>
        </template>
      </el-col>
    </el-form>
  </el-scrollbar>
</template>
<script>
import { findTableByDatasourceid } from "@/api/dataService/appModel";
import { getColumnsByTable } from "@/api/busiMode/meta";
export default {
  name: "modelTree",

  data() {
    return {
      modelForm: {
        model: '',
        type: "list",
        method: "add",
        filterCriteria: [
          {
            field: "",
            value: "",
            operationType: "", //lt大于，小于gt 等于equal  like
          },
        ],
        fields: [
          {
            field: "",
            value: "",
          },
        ],
      },
      operList: [],
      tableDataOption: {
        column: [
          {
            type: "select",
            prop: "fieldName",
            label: "字段",
            cell: true,
            dicData: [],
            props: {
              label: "description",
              value: "guid",
            },
          },
          { type: "input", prop: "value", label: "值", cell: true },
        ],
      },
      opraOption: {
        column: [
          {
            type: "select",
            prop: "field",
            label: "字段",
            cell: true,
            dicData: [],
            props: {
              label: "description",
              value: "guid",
            },
          },
          {
            type: "select",
            prop: "operationType",
            label: "操作",
            cell: true,
            dicData: [
              { label: "大于", value: "lt" },
              { label: "等于", value: "equal" },
              { label: "小于", value: "gt" },
            ],
            props: {
              label: "label",
              value: "value",
            },
          },
          { type: "input", prop: "value", label: "值", cell: true },
        ],
      },
      fieldLoading: false,
      modelLoading: false,
      modelList: [],
      taskList: [
        { name: "新增", value: "add" },
        { name: "修改", value: "update" },
        { name: "删除", value: "delete" },
      ],
    };
  },
  mounted() {
    this.resetFormInfo();
    this.modelForm.datasourceCode = this.dataSoure.code;
    this.$nextTick(() => {
      this.findTableByDatasourceid();
    })
  },
  created() {
  },
  props: {
    id: String,
    dataSoure: {
      type: Object,
      default() {
        return {};
      },
    },
    businessObject: Object,
    bpmnElement: {
      type: Object,
      default(){
        return null;
      }
    },
    prefix: String
  },
  watch: {
    id: {
      handler(){
        this.resetFormInfo();
      }
    },
    'modelForm.filterCriteria': {
      handler: function (newValue, oldName) {
        this.formChange();
      },
      deep: true,
    },
    'modelForm.fields': {
      handler: function (newValue, oldName) {
        this.formChange();
      },
      deep: true,
    }
  },
  methods: {
    resetFormInfo(){
      if(this.businessObject){
        let operate = {};
        try {
          operate = JSON.parse(this.businessObject.operate);
        } catch (error) {
          operate = {};
        }

        this.modelForm.model = operate.model || '';
        this.modelForm.type = operate.type || '';
        this.modelForm.method = operate.method || '';
        this.modelForm.filterCriteria = operate.filterCriteria || [];
        this.modelForm.fields = operate.fields || [];
        this.$nextTick(() => {
          this.changeVal();
        })
      }
    },
    findTableByDatasourceid() {
      let o = {
        datasourceType: 0,
        datasourceCode: this.dataSoure.code,
        isSimple: true,
      };
      if (this.dataSoure.code) {
        findTableByDatasourceid(o)
          .then((res) => {
            this.modelList = res["data"]["data"] || [];
            this.modelLoading = false;
          })
          .catch((e) => {
            this.tables = [];
            this.modelLoading = false;
          });
      }
    },
    changeVal() {
      if(this.modelForm.model){
        this.getColumnsByTable();
      }
    },
    getColumnsByTable() {
      let o = {
        datasourceCode: this.dataSoure.code,
        tableName: this.modelForm.model,
        datasourceType: 0,
      };
      if (this.dataSoure.code) {
        getColumnsByTable(o)
          .then((res) => {
            if (res.data.code == 200) {
              this.opraOption.column[0].dicData = res.data.data.columnVOList;
              this.tableDataOption.column.forEach((element) => {
                element.dicData = res.data.data.columnVOList || [];
              });
            }
          })
          .catch((e) => {
            //this.fieldLoading = false;
          });
      }
    },
    formChange(){
      let attrObj = {
        operate: JSON.stringify(this.modelForm)
      }

      if(this.bpmnElement){
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, attrObj)
      }
    }
  },
};
</script>
<style lang="scss" scoped>
/deep/ .avue-crud .el-table .el-form-item {
  margin-bottom: 1px !important;
}
/deep/ .avue-crud__empty {
  padding: 10px 0;
}
/deep/ .avue-crud__pagination {
  position: relative;
  padding: 5px 0 10px 10px;
  text-align: right;
}
/deep/ .avue-crud__empty {
  padding: 10px 0;
}
</style>

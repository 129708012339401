<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      :page.sync="page"
      :before-open="beforeOpen"
      v-model="form"
      ref="crud"
      @row-update="rowUpdate"
      @row-save="rowSave"
      @row-del="rowDel"
      @search-change="searchChange"
      @search-reset="searchReset"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot="menuLeft">
        <el-button
          type="danger"
          size="small"
          icon="el-icon-delete"
          plain
          v-if="permission.epmalladdress_delete"
          @click="handleDelete"
          >删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import {
  getList,
  getDetail,
  add,
  update,
  remove,
  getPage,
} from "@/api/spcod/spcodaccountcard";
import { mapGetters } from "vuex";

export default {
  data() {
    // 正则表达式 验证手机号
    const validateMobile = (rule, value, callback) => {
      if (!/^[1]([3-9])[0-9]{9}$/.test(value)) {
        callback(new Error("请输入正确的手机号格式"));
      } else {
        callback();
      }
    };
    return {
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      option: {
        height: "auto",
        calcHeight: 30,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        viewBtn: true,
        dialogClickModal: false,
        column: [
          {
            label: "会员名称",
            prop: "vipName",
            addDisplay: false,
            editDisplay: false,
            viewDisplay: false,
            rules: [
              {
                required: true,
                message: "请输入",
                trigger: "blur",
              },
            ],
          },
          {
            searchShow: true,
            label: "会员电话",
            prop: "vipPhone",
            search: true,
            rules: [
              {
                required: true,
                message: "请输入联系人",
                trigger: "blur",
              },
            ],
          },
          {
            label: "场地类型",
            prop: "typeName",
            search: false,
            formatter(row, value, label, column) {
              if (row.typeId == 0) return "通用型22";
              else return value + row.typeId;
            },
            rules: [
              {
                required: true,
                message: "请输入电话",
                trigger: "blur",
              },
            ],
          },
          {
            label: "场地类型",
            prop: "typeId",
            hide: true,
            type: "select",
            search: true,
            searchSpan: 4,
            dicUrl: "/api/park-spcod/sporttype/list?current=1&size=10000000",
            props: {
              label: "name",
              value: "id",
            },
            dicFormatter: (data) => {
              let datalist = data.data.records;
              /*datalist.forEach(ele => {
                  ele.id = parseInt(ele.id);
                });*/
              return datalist;
            },
            rules: [
              {
                required: true,
                message: "请输入场馆ID",
                trigger: "blur",
              },
            ],
          },
          {
            label: "会员卡号",
            prop: "cardNo",
            search: false,
            rules: [
              {
                required: true,
                message: "请输入电话",
                trigger: "blur",
              },
            ],
          },
          {
            label: "会员卡类型",
            prop: "cardType",
            addDisplay: false,
            editDisplay: false,
            search: false,
            searchSpan: 4,
            type: "select",
            dicData: [
              {
                label: "全卡",
                value: 1,
              },
              {
                label: "计次",
                value: 2,
              },
              {
                label: "计费",
                value: 3,
              },
            ],
            rules: [
              {
                required: true,
                message: "请输入 0无效 1有效",
                trigger: "blur",
              },
            ],
          },
          {
            label: "卡名称",
            prop: "cardName",
            search: false,
            rules: [
              {
                required: true,
                message: "请输入电话",
                trigger: "blur",
              },
            ],
          },
          {
            label: "卡中金额",
            prop: "cardValue",
            search: false,
            rules: [
              {
                required: true,
                message: "请输入电话",
                trigger: "blur",
              },
            ],
          },
          {
            label: "折扣",
            prop: "disRate",
            search: false,
            rules: [
              {
                required: true,
                message: "请输入折扣",
                trigger: "blur",
              },
            ],
          },
          {
            label: "开始日",
            prop: "effectFrm",
            search: false,
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            formatter(row, value, label, column) {
              if (row.effectFrm && row.effectFrm != "")
                return row.effectFrm.substring(0, 10);
              else return "";
            },
            rules: [
              {
                required: true,
                message: "请输入电话",
                trigger: "blur",
              },
            ],
          },
          {
            label: "结束日",
            prop: "endDay",
            search: false,
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            formatter(row, value, label, column) {
              if (row.endDay && row.endDay != "")
                return row.endDay.substring(0, 10);
              else return "";
            },
            rules: [
              {
                required: true,
                message: "请输入电话",
                trigger: "blur",
              },
            ],
          },
          {
            label: "状态",
            prop: "status",
            search: true,
            searchSpan: 4,
            addDisplay: false,
            editDisplay: false,
            type: "select",
            dicData: [
              {
                value: 1,
                label: "有效",
              },
              {
                value: 2,
                label: "关闭",
              },
            ],
            rules: [
              {
                required: false,
                message: "状态",
                trigger: "blur",
              },
            ],
          },
          {
            label: "备注",
            prop: "memo",
            search: false,
            hide: true,
            rules: [
              {
                required: true,
                message: "请输入电话",
                trigger: "blur",
              },
            ],
          },
          {
            label: "创建时间",
            prop: "createTime",
            search: false,
            hide: true,
            addDisplay: false,
            editDisplay: false,
            rules: [
              {
                required: true,
                message: "请输入电话",
                trigger: "blur",
              },
            ],
          },
          {
            label: "操作人",
            prop: "creatorName",
            search: false,
            hide: true,
            addDisplay: false,
            editDisplay: false,
            rules: [
              {
                required: true,
                message: "请输入电话",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      data: [],
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.epmalladdress_add, false),
        viewBtn: this.vaildData(this.permission.epmalladdress_view, false),
        delBtn: this.vaildData(this.permission.epmalladdress_delete, false),
        editBtn: this.vaildData(this.permission.epmalladdress_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  mounted() {
    //放在数据加载完后执行
    this.$refs.crud.dicInit("cascader");
  },
  methods: {
    rowSave(row, done, loading) {
      add(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          loading();
          window.console.log(error);
        }
      );
    },
    rowUpdate(row, index, done, loading) {
      update(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          loading();
          console.log(error);
        }
      );
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
        });
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getDetail(this.form.id).then((res) => {
          this.form = res.data.data;
        });
      }
      done();
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      getPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        data.forEach((r) => {
          r.typeId = r.typeId + "";
        });
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
      });
    },
  },
};
</script>

<style></style>

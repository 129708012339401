<template>
  <div>
    <el-form :model="ruleForm" ref="ruleForm" :rules="rules" label-width="85px" label-position="right" size="small" :disabled="onlyRead">
      <el-row>
        <el-col :span="12">
          <el-form-item label="所属楼宇" prop="building_id">
            <el-select v-model="ruleForm.building_id" size="small" @change="onBuildChange" :disabled="!buildRead" placeholder="请选择">
              <el-option v-for="(item, index) in buildList" :key="index" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属楼层" prop="floor_id">
            <el-select v-model="ruleForm.floor_id" size="small" :disabled="!buildRead" @change="onFloorChange" placeholder="请选择">
              <el-option v-for="(item, index) in floorList" :key="index" :label="item.code" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="选择房间" prop="room_id">
            <el-select multiple v-model="ruleForm.room_id" size="small" :disabled="!buildRead" placeholder="请选择">
              <el-option v-for="(item, index) in roomList" :key="index" :label="item.name" :value="item.id"> </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="房间状态" prop="status">
            <el-select v-model="ruleForm.status" placeholder="请选择状态">
              <el-option label="已生效" value="1"></el-option>
              <el-option label="已失效" value="2"></el-option>
              <el-option label="待生效" value="3"></el-option>
              <el-option label="永久有效" value="4"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="有效时间">
            <el-col :span="11" class="pad-l-0">
              <el-date-picker type="date" placeholder="开始时间" v-model="ruleForm.valid_start_time" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker>
            </el-col>
            <el-col class="line" :span="1">-</el-col>
            <el-col :span="12" class="pad-r-0">
              <el-date-picker placeholder="结束时间" type="date" v-model="ruleForm.valid_end_time" value-format="yyyy-MM-dd" style="width: 100%"></el-date-picker>
            </el-col>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item label="备注">
            <el-input type="textarea" v-model="ruleForm.note"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { getMetaCommonList, saveMetaCommon, updateMetaCommon } from "@/api/busiMode/metaCommon"
import * as dictbizService from "@/api/system/dictbiz"

export default {
  data() {
    return {
      ruleForm: {
        room_id: [],
        // roomIds: ,
        building_id: "",
        floor_id: "",
        status: "",
      },
      page: {
        size: 1000,
        current: 1,
        total: 0,
      },
      buildList: [],
      decorationList: [],
      roomTypes: [],
      floorList: [],
      roomStatuss: [],

      currentRow: {},

      model: "wisdom_park.wp_room",
      contractList: {},
      companyList: [],
      entModel: "wisdom_park.wp_company",
      view_model: "wisdom_park.v_wp_room_stat",
      /** 房间列表 */
      roomList: [],

      /** 表格校验规则 */
      rules: {
        building_id: [{ required: true, message: "请选择楼宇" }],
        floor_id: [{ required: true, message: "请选择楼层" }],
        room_id: [
          {
            validator: (rule, val, callback) => {
              if (val.length == 0) {
                callback(new Error("请选择房间"))
              } else {
                callback()
              }
            },
          },
        ],
        status: [{ required: true, message: "请选择房间状态" }],
      },
    }
  },
  props: {
    floors: {
      type: Array,
      default: function () {
        return []
      },
    },
    ruleFormObj: {
      type: Object,
      default: function () {
        return {}
      },
    },
    onlyRead: {
      type: Boolean,
      default: function () {
        return false
      },
    },
    buildRead: {
      type: Boolean,
      default: function () {
        return false
      },
    },
  },

  created() {
    /** 操作类型： 编辑或新增 */
    if (this.ruleFormObj.id) {
      /** 编辑操作：不允许修改楼宇楼层房间，不需要请求后端查询数据 */
      this.ruleForm = { ...this.ruleFormObj, room_id: [this.ruleFormObj.room_id] }
      this.buildList.push({
        id: this.ruleForm.building_id,
        name: this.ruleForm.building_name,
      })
      this.floorList.push({
        id: this.ruleForm.floor_id,
        code: this.ruleForm.floor_name,
      })
      this.roomList.push({
        id: this.ruleFormObj.room_id,
        name: this.ruleFormObj.room_name,
      })
    } else {
      /** 获取楼座数据 */
      this.getBuildList()
    }
  },

  methods: {
    /**
     * 新增或修改 事件
     * @param {企业ID} company_id
     */
    saveRooms(company_id) {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          if (this.ruleFormObj.id) {
            let obj = {}
            Object.keys(this.ruleForm).forEach((ele) => {
              if (["id", "status", "valid_start_time", "valid_end_time", "note"].includes(ele)) {
                obj[ele] = this.ruleForm[ele]
              }
            })
            let o = {
              update: obj,
              primaryKeyValue: this.ruleFormObj["id"],
              primaryKey: "id",
            }

            updateMetaCommon("wisdom_park.wp_sublessor_room", o).then(({ data }) => {
              if (data.code == 200) {
                this.$message.success("修改成功")
                this.$emit("roomSucess")
              }
            })
          } else {
            this.ruleForm.building_name = this.buildList.find((item) => item.id === this.ruleForm.building_id).name
            this.ruleForm.floor_name = this.floorList.find((item) => item.id === this.ruleForm.floor_id).code

            this.ruleForm.company_id = company_id
            /** 遍历新增房间事件 */
            let array = []

            this.ruleForm.room_id.forEach((id) => {
              let data = { ...this.ruleForm, company_id: company_id, room_id: id }
              data.room_name = this.roomList.find((item) => item.id === id).name
              array.push(saveMetaCommon("wisdom_park.wp_sublessor_room", { values: data }))
            })
            let list = async () => {
              let res = await Promise.all(array)
              let error = res.filter((item) => item.data.code !== 200)
              if (error.length > 0) {
                this.$message.error(`有${error.length}条数据添加失败`)
              } else {
                this.$message.success("新增成功")
                this.$emit("roomSucess")
              }
            }
            list()
          }
        }
      })
    },

    getMetaCommonList() {
      let body = {
        current: 1,
        size: 1000,
      }
      // 状态（0:无效；1.已入驻；2.入驻中；3.退出中；4.已退出）
      getMetaCommonList(this.entModel, body).then((res) => {
        const data = res.data.data
        this.companyList = data.records
      })
    },

    /**
     * 监听楼宇选择事件，获取对应楼层数据
     * @param {*} val : 选中的楼宇ID
     */
    onBuildChange(val) {
      let obj = {
        size: 1000,
        current: 1,
        orders: [{ field: "code+1000", sort: "DESC" }],
      }
      obj.wheres = [{ field: "building_id", value: val }]
      this.ruleForm.floor_id = ""
      this.ruleForm.room_id = ""
      getMetaCommonList("wisdom_park.wp_floor", obj).then((res) => {
        const data = res.data.data
        this.floorList = data.records
      })
    },

    /**
     * 监听楼层选中事件， 获取对应的房间信息
     * @param {选中的楼层ID} val
     */
    onFloorChange(val) {
      let body = {
        current: 1,
        size: 50,
        wheres: [
          { field: "building_id", value: this.ruleForm.building_id },
          { field: "floor_id", value: val },
          { field: "is_cancel", value: 0 },
        ],
      }
      this.ruleForm.room_id = ""
      getMetaCommonList(this.model, body).then(({ data }) => {
        this.roomList = data.data.records
      })
      this.$forceUpdate()
    },

    // selectChange(val) {
    //   this.roomForm.room_id = val.join(",")
    // },

    getMetaCommonInfo(id) {
      let obj = {
        pk: "id",
        id: id,
      }
      getMetaCommonInfo(this.model, obj).then((res) => {
        if (res.data.code == 200) {
          let newValue = res.data.data
          if (newValue.label) {
            if (typeof newValue.label == "string" && newValue.label.constructor == String && newValue.label != "") {
              newValue.label = newValue.label.split(",")
            }
          }
          if (newValue.housing_resources_type) {
            if (typeof newValue.housing_resources_type == "string" && newValue.housing_resources_type.constructor == String && newValue.housing_resources_type != "") {
              newValue.housing_resources_type = newValue.housing_resources_type.split(",")
            }
          }
          this.ruleForm = res.data.data
        }
      })
    },

    getDicList() {
      dictbizService
        .getDictionary({
          code: "RoomStatus",
        })
        .then((res) => {
          this.roomStatuss = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: Number(item["dictKey"]),
              id: item["id"],
            }
          })
        })

      dictbizService
        .getDictionary({
          code: "RoomBrightenedDot",
        })
        .then((res) => {
          this.roomTags = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
              id: item["id"],
            }
          })
        })

      dictbizService
        .getDictionary({
          code: "decoration",
        })
        .then((res) => {
          this.decorationList = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            }
          })
        })
      dictbizService
        .getDictionary({
          code: "paymentType",
        })
        .then((res) => {
          this.paymentTypeList = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            }
          })
        })
    },

    /**
     * 获取所有楼座数据
     */
    getBuildList() {
      getMetaCommonList("wisdom_park.wp_building", this.page).then((res) => {
        const data = res.data.data
        this.buildList = data.records
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.pararoom {
  font-weight: 600;
  color: #252b50;
  /* font-size: 18px; */
  margin-bottom: 10px;
  margin-top: -10px;
}
/deep/ .el-divider--horizontal {
  margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
  left: 0px;
}
/deep/ .el-divider__text {
  padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
  width: 68px;
  height: 68px;
  line-height: 72px;
  vertical-align: top;
}
/deep/ .el-select {
  width: 100%;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
}
.roomForm {
  .input-with-select {
    /deep/.el-input {
      width: 80px;
    }
  }
}
</style>

<template>
  <div>
    <el-row :gutter="$store.state.common.gutter">
      <el-col :span="8">
        <el-card class="card-count">
          <el-row>
            <el-col :span="12" class="tj-num">
              <div class="tj-title lh-40 pad-l-15">目录数</div>
              <div class="text-center">
                <div class="lh-60 mar-t-7">
                  <el-link :underline="false" type="primary" class="fts-36">{{counts.catalog.totalCount | numConvertToStr(2 , true)}}<span class="fts-14 mar-l-10 color-black">{{counts.catalog.totalCount | numConvertToUnit}}个</span></el-link>
                </div>
              </div>
              <div class="text-right">
                <div class="text-black-2 lh-40 pad-r-15">日增 {{counts.catalog.dailyCount | numConvertToStr(2 , true)}}{{counts.catalog.dailyCount | numConvertToUnit}} <span :class="{'el-icon-top color-green': counts.catalog.dailyCount > 0 , 'el-icon-bottom color-red': counts.catalog.dailyCount < 0}"></span></div>
              </div>
            </el-col>
            <el-col :span="12" class="tj-num">
              <div class="tj-title lh-40 pad-l-15">数据量</div>
              <div class="text-center">
                <div class="lh-60 mar-t-8">
                  <el-link :underline="false" type="primary" class="fts-36">{{counts.dataRow.allDataRows | numConvertToStr(2 , true)}}<span class="fts-14 mar-l-10 color-black">{{counts.dataRow.allDataRows | numConvertToUnit}}条</span></el-link>
                </div>
              </div>
              <div class="text-right">
                <div class="text-black-2 lh-40 pad-r-15">日增 {{counts.dataRow.increaseDataRows | numConvertToStr(2 , true)}}{{counts.dataRow.increaseDataRows | numConvertToUnit}} <span :class="{'el-icon-top color-green': counts.dataRow.increaseDataRows > 0 , 'el-icon-bottom color-red': counts.dataRow.increaseDataRows < 0}"></span></div>
              </div>
            </el-col>
            <el-col :span="12" class="tj-num">
              <div class="tj-title lh-40 pad-l-15">占用存储空间</div>
              <div class="text-center">
                <div class="lh-60 mar-t-7">
                  <el-link :underline="false" type="primary" class="fts-36">{{translateDataSize(counts.dataSize.allDataSize).value | numConvertToStr(2 , true)}}<span class="fts-14 mar-l-10 color-black">{{translateDataSize(counts.dataSize.allDataSize).unit}}</span></el-link>
                </div>
              </div>
              <div class="text-right">
                <div class="text-black-2 lh-40 pad-r-15">日增 {{translateDataSize(counts.dataSize.increaseDataSize).value | numConvertToStr(2 , true)}} {{translateDataSize(counts.dataSize.increaseDataSize).unit}}<span :class="{'el-icon-top color-green': translateDataSize(counts.dataSize.increaseDataSize).value > 0 , 'el-icon-bottom color-red': translateDataSize(counts.dataSize.increaseDataSize).value < 0}"></span></div>
              </div>
            </el-col>
            <el-col :span="12" class="tj-num">
              <div class="tj-title lh-40 pad-l-15">任务数</div>
              <div class="text-center">
                <div class="lh-60 mar-t-8">
                  <el-link :underline="false" type="primary" class="fts-36">{{counts.task.allTaskNums | numConvertToStr(2 , true)}}<span class="fts-14 mar-l-10 color-black">{{counts.task.allTaskNums | numConvertToUnit}}个</span></el-link>
                </div>
              </div>
              <div class="text-right">
                <div class="text-black-2 lh-40 pad-r-15">日增 {{counts.task.increaseTaskNums | numConvertToStr(2 , true)}}{{counts.task.increaseTaskNums | numConvertToUnit}} <span :class="{'el-icon-top color-green': counts.task.increaseTaskNums > 0 , 'el-icon-bottom color-red': counts.task.increaseTaskNums < 0}"></span></div>
              </div>
            </el-col>
          </el-row>
        </el-card>
        <!-- <el-card>
          <div slot="header" class="clearfix">
            <span class="lh-34">日变化统计</span>
          </div>
          <el-row>
            <el-col :span="12" class="tj-num">
              <div class="text-center">
                <div class="fts-36 lh-36 mar-t-30">
                  <el-link :underline="false" type="primary" href="/#/data-assets/catalog">{{totayStats.allCatalogNums}}</el-link><span class="fts-16 mar-l-10">条</span>
                </div>
                <div class="text-black-2 lh-36 mar-b-20">资产目录数</div>
              </div>
            </el-col>
            <el-col :span="12" class="tj-num">
              <div class="text-center">
                <div class="fts-36 lh-36 mar-t-30">
                  <el-link :underline="false" type="primary">{{totayStats.allDataSize.value}}</el-link><span class="fts-16 mar-l-10">{{totayStats.allDataSize.unit}}</span>
                </div>
                <div class="text-black-2 lh-36 mar-b-20">存储空间占用量</div>
              </div>
            </el-col>
            <el-col :span="12" class="tj-num">
              <div class="text-center">
                <div class="fts-36 lh-37 mar-t-30">
                  <el-link :underline="false" type="primary">{{ totayStats.allTableNums }}</el-link><span class="fts-16 mar-l-10">个</span>
                </div>
                <div class="text-black-2 lh-36 mar-b-20">采集表数量</div>
              </div>
            </el-col>
            <el-col :span="12" class="tj-num">
              <div class="text-center">
                <div class="fts-36 lh-37 mar-t-30">
                  <el-link :underline="false" type="primary">{{ totayStats.allDataRows }}</el-link><span class="fts-16 mar-l-10">条</span>
                </div>
                <div class="text-black-2 lh-36 mar-b-20">采集数据量</div>
              </div>
            </el-col>
          </el-row>
        </el-card> -->
      </el-col>

      <el-col :span="16">
        <div class="el-card is-always-shadow">
          <div class="el-card__header">
            <div class="clearfix">
              <el-row>
                <el-col :span="20" class="mar-b-0">
                  <span>近一个月表数据走势图</span>
                </el-col>
                <el-col :span="4" class="mar-b-0">
                  <el-select
                    v-model="chooseBsjType"
                    size="small"
                    @change="changeLineData"
                  >
                    <el-option label="数据量" value="shujuliang"></el-option>
                    <el-option
                      label="存储量"
                      value="kongjian"
                    ></el-option> </el-select
                ></el-col>
              </el-row>
            </div>
          </div>
          <div class="el-card__body">
            <div class="w-100p h-217">
              <basic-echarts :option="line1Option"></basic-echarts>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="$store.state.common.gutter">
      <el-col :span="18">
        <el-col :span="12">
          <el-card header="表活跃度TOP5">
            <div class="w-100p h-300 ov-a">
              <el-table :data="top5TableData.table">
                <el-table-column type="index" label="排名" width="50">
                  <template slot-scope="scope">
                    <div class="text-right mar-r-15">
                      <i
                        v-if="scope.$index < 3"
                        class="portal-icon_huangguan"
                        :class="{
                          'text-sort-1': scope.$index == 0,
                          'text-sort-2': scope.$index == 1,
                          'text-sort-3': scope.$index == 2
                        }"
                      ></i>
                      <span style="margin-left: 10px">{{
                        scope.$index + 1
                      }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column prop="value" label="值" width="50"></el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card header="存储空间TOP5">
            <div class="w-100p h-300 ov-a">
              <el-table :data="top5TableData.store">
                <el-table-column type="index" label="排名" width="50">
                  <template slot-scope="scope">
                    <div class="text-right mar-r-15">
                      <i
                        v-if="scope.$index < 3"
                        class="portal-icon_huangguan"
                        :class="{
                          'text-sort-1': scope.$index == 0,
                          'text-sort-2': scope.$index == 1,
                          'text-sort-3': scope.$index == 2
                        }"
                      ></i>
                      <span style="margin-left: 10px">{{
                        scope.$index + 1
                      }}</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column prop="name" label="名称"></el-table-column>
                <el-table-column prop="value" label="值" width="50"></el-table-column>
              </el-table>
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
        <div class="el-card is-always-shadow">
          <div class="el-card__header">
            <div class="clearfix">
              <el-row>
                <el-col :span="24" class="mar-b-0">
                  <span>近一周数据分层统计</span>
                  <span class="push-right">
                    <el-select
                      v-model="dhChartType"
                      size="small"
                      @change="changeDhData"
                    >
                      <el-option label="数据量" value="dataRows"></el-option>
                      <el-option label="存储量" value="dataSize" ></el-option>
                    </el-select>
                  </span>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="el-card__body">
            <div class="w-100p h-257">
              <basic-echarts :option="lineOption"></basic-echarts>
            </div>
          </div>
        </div>
        </el-col>
        <el-col :span="12">
          <div class="el-card is-always-shadow">
            <div class="el-card__header">
              <div class="clearfix">
                <el-row>
                  <el-col :span="16" class="mar-b-0">
                    <span>数据分层占比分析</span>
                  </el-col>
                  <el-col :span="8" class="mar-b-0">
                    <el-select
                      v-model="chooseType"
                      size="small"
                      @change="changePieData"
                    >
                      <el-option label="数据量" value="shujuliang"></el-option>
                      <el-option
                        label="存储量"
                        value="kongjian"
                      ></el-option> </el-select
                  ></el-col>
                </el-row>
              </div>
            </div>
            <div class="el-card__body">
              <div class="w-100p h-257">
                <basic-echarts v-if="pieOption1 &&  pieOption1.legend && pieOption1.legend.data && pieOption1.legend.data.length" :option="pieOption1"></basic-echarts>
                <no-data v-else></no-data>
              </div>
            </div>
          </div>
        </el-col>
      </el-col>
      <el-col :span="6">
        <el-col :span="24">
          <div class="el-card is-always-shadow">
            <div class="el-card__header">
              <div class="clearfix">
                <el-row>
                  <el-col :span="24" class="mar-b-0">
                    <span>敏感数据日志</span>
                    <span class="push-right">
                      <el-link :underline="false" type="primary" href="/#/data-assets/sensitive">更多</el-link>
                    </span>
                  </el-col>
                </el-row>
              </div>
            </div>
            <div class="el-card__body">
              <div class="w-100p ov-a" style="height: 660px;">
                <el-timeline>
                  <template v-for="item of statisticWithRecentData">
                    <el-timeline-item v-if="item.addSensitiveColumnNum > 0" :key="item.createTime" :timestamp="item.createTime" placement="top">
                      <div>在{{item.tableNum}}张表中发现{{item.addSensitiveColumnNum}}个敏感字段</div>
                    </el-timeline-item>
                  </template>
                </el-timeline>
              </div>
            </div>
          </div>
        </el-col>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {
  formartPieData,
  line1Option,
  lineOption,
  pieOption,
  barOption,
  getDataCollectCountStr,
  getDataSize
} from "./chart-option";

import {
  getAllOverviewData,
  getTodayOverviewData,
  getLatelyNDayData,
  getDataRowProportion,
  getTalbeRowsTopN,
  getTableSizeTopN,
  getDataSizeProportion,
  getDatahierarchyStatisticsForWeek
} from "@/api/dws/datalayer";

import catalogService from '@/api/dataAssets/catalog';
import service from "@/api/overview/index";
import { getColumnSensitiveLog , getColumnSensitiveLogStatisticWithRecent } from '@/api/metadata/metadata';

export default {
  data() {
    return {
      statisticWithRecentData: [],
      columnSensitiveTableData: [],
      lineOption: lineOption(),
      line1Option: line1Option(),
      pieOption1: pieOption([], [], []),
      barOption1: barOption(),
      barOption: barOption({
        unit: 'KB',
        color: ['#1695ec']
      }),
      chooseType: "shujuliang",
      chooseBsjType: "shujuliang",
      dhChartType: 'dataRows',
      dhMgType: 'ods',
      LatelyNDayData: {},
      statNums: {
        allTableNums: 0,
        allDataRows: 0
      },
      totayStats: {
        allTableNums: 0,
        allDataRows: 0,
        allDataSize: 0,
        allCatalogNums: 0
      },
      dhData: null,
      counts: {
        catalog: {
          dailyCount: 0,
          totalCount: 0
        },
        dataSize: {
          allDataSize: '0KB',
          increaseDataSize: '0KB'
        },
        dataRow: {
          allDataRows: 0,
          increaseDataRows: 0
        },
        task: {
          allTaskNums: 0,
          increaseTaskNums: 0
        }
      },
      top5TableData: {
        store: [],
        table: []
      }
    };
  },
  inject: ["indexMain"],
  created() {
    // this.getAllOverviewData();
    this.totalAndIncrease();
    this.dailyAndTotalCount();
    // this.getTodayOverviewData();
    this.getLatelyNDayData();
    this.getDataRowProportion();
    this.getTalbeRowsTopN();
    this.getTableSizeTopN();
    this.getDatahierarchyStatisticsForWeek();
    this.getCatalogCount();
    // this.getColumnSensitiveLog();
    this.getColumnSensitiveLogStatisticWithRecent();
  },
  mounted() {},
  methods: {
    getColumnSensitiveLog(){
      getColumnSensitiveLog({
        size: 5,
        current: 1
      }).then(
        res => {
          if(res.data.data){
            this.columnSensitiveTableData = res.data.data.records;
          }
        }
      )
    },
    getColumnSensitiveLogStatisticWithRecent(){
      getColumnSensitiveLogStatisticWithRecent({
        recentNum: 10
      }).then(
        res => {
          console.log(res);
          if(res.data.data){
            this.statisticWithRecentData = res.data.data || [];
          }
        }
      )
    },
    translateDataSize(str){
      let value = parseFloat(str);
      let unit = str.replace(value, '');
      return {
        value,
        unit
      }
    },
    totalAndIncrease(){
      service.totalAndIncrease({
        projectName: this.$store.state.dag.projectName
      }).then(
        res => {
          if(res.data.data){
            let data = res.data.data;
            this.counts.dataSize.allDataSize = data.allDataSize;
            this.counts.dataSize.increaseDataSize = data.increaseDataSize;
            this.counts.dataRow.allDataRows = data.allDataRows;
            this.counts.dataRow.increaseDataRows = data.increaseDataRows;
            this.counts.task.allTaskNums = data.allTaskNums;
            this.counts.task.increaseTaskNums = data.increaseTaskNums;
          }
        }
      )
    },
    dailyAndTotalCount(){
      service.dailyAndTotalCount().then(
        res => {
          if(res.data.data){
            this.counts.catalog = {
              ...res.data.data
            }
          }
        }
      )
    },
    getCatalogCount(){
      catalogService.getCatalogCount().then(
        res => {
          this.totayStats.allCatalogNums = getDataCollectCountStr(res.data.data || 0 , '');
        }
      )
    },
    getDatahierarchyStatisticsForWeek(){
      getDatahierarchyStatisticsForWeek().then(
        res => {
          this.dhData = res.data.data || null;
          if(this.dhData){
            this.changeDhData();
          }
        }
      )
    },
    changeDhData(){
      if(!this.dhData){
        this.getDatahierarchyStatisticsForWeek();
      }
      let series = [] , xAxis = [];
      Object.keys(this.dhData).forEach(
        key => {
          let serieItem = {
            name: key,
            type: 'line',
            data: []
          };
          (this.dhData[key][this.dhChartType] || []).forEach(
            item => {
              if(!xAxis.includes(item.name)){
                xAxis.push(item.name);
              }
              serieItem.data.push(item.value);
            }
          )
          series.push(serieItem);
        }
      )
      this.lineOption = lineOption(series , xAxis);
    },
    getAllOverviewData() {
      getAllOverviewData().then(res => {
        if (res.status == 200 && res.data.data) {
          this.statNums = {
            allTableNums: getDataCollectCountStr(res.data.data.allTableNums),
            allDataRows: getDataCollectCountStr(res.data.data.allDataRows)
          };
        }
      });
    },
    getTodayOverviewData() {
      getTodayOverviewData().then(res => {
        if (res.status == 200 && res.data.data) {
          this.totayStats = {
            ...this.totayStats,
            allTableNums: getDataCollectCountStr(res.data.data.allTableNums , ''),
            allDataRows: getDataCollectCountStr(res.data.data.allDataRows , ''),
            allDataSize: getDataSize(res.data.data.allDataSize)
          };
        }
      });
    },
    getTalbeRowsTopN() {
      let obj = { num: 5 };
      getTalbeRowsTopN(obj).then(res => {
        if (res.status == 200 && res.data.data) {
          let data = [];
          res.data.data["data"].forEach(
            (item , index) => {
              data.push({
                name: res.data.data["date"][index],
                value: item
              })
            }
          )
          this.top5TableData.table = data;
          // this.barOption1.yAxis.data = res.data.data["date"];
          // this.barOption1.series[0].data = res.data.data["data"];
          // this.barOption1.series[0].barWidth = 20;
        }
      });
    },
    getTableSizeTopN() {
      let obj = { num: 5 };
      getTableSizeTopN(obj).then(res => {
        if (res.status == 200 && res.data.data) {
          let data = [];
          res.data.data["data"].forEach(
            (item , index) => {
              data.push({
                name: res.data.data["date"][index],
                value: item
              })
            }
          )
          this.top5TableData.store = data;
          // this.barOption.yAxis.data = res.data.data["date"];
          // this.barOption.series[0].data = res.data.data["data"];
          // this.barOption.series[0].barWidth = 20;
        }
      });
    },

    changePieData(val) {
      this.changeData = val;
      if (this.changeData == "shujuliang") {
        this.getDataRowProportion();
      } else {
        this.getDataSizeProportion();
      }
    },

    getDataRowProportion() {
      getDataRowProportion().then(res => {
        if (res.status == 200 && res.data.data) {
          let data = formartPieData(res.data);
          this.pieOption1.series[0].data = data.data;
          this.pieOption1.legend.data = data.legend;
          this.pieOption1.legend.formatter = function(name) {
            return `${name}  {value|${data.legendDatas[name]["value"]}条 ,${data.legendDatas[name]["pre"]}%}`;
          };
        }
      });
    },
    getDataSizeProportion() {
      getDataSizeProportion().then(res => {
        if (res.status == 200 && res.data.data) {
          let data = formartPieData(res.data);
          this.pieOption1.series[0].data = data.data;
          this.pieOption1.legend.data = data.legend;
          this.pieOption1.legend.formatter = function(name) {
            return `${name}  {value|${data.legendDatas[name]["value"]}, ${data.legendDatas[name]["pre"]}%}`;
          };
        }
      });
    },
    getLatelyNDayData() {
      let obj = { days: 30 };
      getLatelyNDayData(obj).then(res => {
        if (res.status == 200 && res.data.data) {
          this.LatelyNDayData = res.data.data;
          this.line1Option.xAxis[0].data = this.LatelyNDayData.allDataRows[
            "date"
          ];
          this.changeLineData("shujuliang");
        }
      });
    },
    changeLineData(val) {
      this.changeBjsData = val;
      if (this.changeBjsData == "shujuliang") {
        this.line1Option.series[0].data = this.LatelyNDayData.allDataRows[
          "data"
        ];
        this.line1Option.series[1].data = this.LatelyNDayData.dataRowsIncrease[
          "data"
        ];
        this.line1Option.yAxis[0].name = "总量(条)";
        this.line1Option.yAxis[1].name = "增长量(条)";
      } else {
        this.line1Option.series[0].data = this.LatelyNDayData.allDataSize[
          "data"
        ];
        this.line1Option.series[1].data = this.LatelyNDayData.dataSizeIncrease[
          "data"
        ];
        this.line1Option.yAxis[0].name = "总量(GB)";
        this.line1Option.yAxis[1].name = "增长量(GB)";
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.barTitle {
  position: absolute;
  top: 65px;
  left: 10p;
  margin-left: 10px;
  font-size: 12px;
  color: #666;
}
/deep/.el-card__header{
  padding: 8px 15px;
}
.card-count /deep/ .el-card__body{
  padding: 0;
}
// /deep/.el-card__body{
//   padding: 0;
// }
.tj-num{
  &:nth-child(2){
    background: #f8f8f8;
  }
  &:nth-child(3){
    background: #f8f8f8;
  }
  margin-bottom: 0;
}
.color-black{
  color: #303133;
}
.color-green{
  color: #40c68b;
}
.color-red{
  color: #fe5a60;
}
</style>

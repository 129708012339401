<template>
  <basic-container>
    <!-- <avue-crud v-model='crudForm' :option='tableOption' :data='tableDatas' :page.sync="page" @on-load="dataShareLicensedPageHandel"
      @refresh-change="dataShareLicensedPageHandel">
      <template slot="menu" slot-scope="scope">
        <el-button size='mini' icon='el-icon-caret-right' @click='testHandel(scope.row)' type='text'>测试</el-button>
      </template>
    </avue-crud> -->
    <el-button @click="goBack" icon="el-icon-back" type="text" size="small"
      >返回</el-button
    >
    <avue-form :option="option" ref="crud" v-model="form">
      <template slot="API1">
        <avue-crud
          :option="tableOption"
          :data="tableDatas"
          v-model="crudForm"
          :page.sync="page"
          @on-load="dataShareLicensedPageHandel"
          @refresh-change="dataShareLicensedPageHandel"
        >
          <template slot="menu" slot-scope="scope">
            <el-button
              size="mini"
              icon="el-icon-caret-right"
              @click="testHandel(scope.row)"
              type="text"
              >测试</el-button
            >
          </template>
        </avue-crud>
      </template>
    </avue-form>
    <el-drawer title="测试" :visible.sync="drawerVisible" append-to-body>
      <data-app-test
        :appKey="form.appKey"
        v-if="
          drawerVisible &&
          (testData.apiType == 0 || validatenull(testData.apiType))
        "
        :id="testData.daDataShareId"
      ></data-app-test>
      <dataShareRegisterTest
        :appKey="form.appKey"
        v-if="drawerVisible && testData.apiType == 1"
        :id="testData.daDataShareId"
      ></dataShareRegisterTest>
    </el-drawer>
  </basic-container>
</template>
<script>
import { detailApp } from "@/api/dataService/dataApp";
import { dataShareLicensedPage } from "@/api/dataApiAccredit/dataApiAccredit.js";
import dataAppTest from "./dataAppTest.vue";
import dataShareRegisterTest from "../dataShare/datashareRegisterTest.vue";
import apiStatus from "@/config/apiStatus";
export default {
  components: { dataAppTest, dataShareRegisterTest },
  data() {
    return {
      crudForm: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      testData: {},
      backData: "",
      form: {},
      formTest: {},
      drawer: false,
      direction: "rtl",
      drawerVisible: false,
      tableDatas: [],
      option: {
        labelWidth: 120,
        emptyBtn: false,
        submitBtn: false,
        // detail:true,
        group: [
          {
            label: "基本信息",
            prop: "base",
            icon: "el-icon-edit-outline",
            column: [
              {
                label: "应用名称",
                prop: "appName",
                disabled: true,
              },
              {
                label: "应用ID",
                prop: "id",
                disabled: true,
              },
              {
                label: "管理员",
                prop: "adminUser",
                type: "select",
                dicUrl: `/api/jida-user/user-list?keyword={{key}}`,
                span: 12,
                disabled: true,
                props: {
                  label: "name",
                  value: "id",
                },
              },
              {
                label: "描述",
                prop: "additionalInformation",
                type: "textarea",
                disabled: true,
                minRows: 2,
                maxRows: 4,
              },
              {
                label: "key有效期",
                prop: "effective_date",
                hide: true,
                disabled: true,
                type: "datetimerange",
                format: "yyyy-MM-dd HH:mm:ss",
                valueFormat: "yyyy-MM-dd HH:mm:ss",
                startPlaceholder: "开始时间",
                endPlaceholder: "结束时间",
                // span: 24,
                formatter(row, value, label, column) {
                  if (
                    row.effectiveStartDate == "" ||
                    row.effectiveStartDate == null
                  ) {
                    return null;
                  } else {
                    return [row.effectiveStartDate, row.effectiveEndDate];
                  }
                },
              },
              {
                label: "app key",
                prop: "appKey",
                type: "textarea",
                disabled: true,
                minRows: 2,
                maxRows: 4,
                // span: 24,
              },
            ],
          },
          {
            label: "关联API",
            prop: "API",
            icon: "el-icon-s-order",
            column: [
              {
                label: "",
                prop: "API1",
                span: 24,
                labelWidth: 0,
                formslot: true,
              },
            ],
          },
        ],
      },
      tableOption: {
        addBtn: false,
        viewBtn: false,
        delBtn: false,
        editBtn: false,
        refreshBtn: false,
        columnBtn: false,
        menuWidth: 100,
        simplePage: false,
        column: [
          {
            label: "API名称",
            prop: "apiName",
            span: 24,
          },
          {
            label: "所属集合",
            prop: "apiCollectionName",
          },
          {
            label: "创建方式",
            prop: "apiType",
            display: false,
            formatter(row, value, label, column) {
              if (row.apiType == 0) {
                return "本地生成";
              } else if (row.apiType == 1) {
                return "第三方注册";
              } else {
                return "";
              }
            },
          },
          {
            label: "状态",
            prop: "apiStatus",
            type: "select",
            dicData: apiStatus,
          },
          {
            label: "描述",
            prop: "description",
            type: "textarea",
            minRows: 2,
          },
          {
            label: "创建人",
            prop: "createUserName",
            minRows: 2,
            span: 24,
          },
          // {
          //   label:'授权人',
          //   prop:'createUserName',
          //   display: false
          // },
          {
            label: "授权时间",
            prop: "createTime",
            width: 130,
          },
          {
            label: "授权调用截止时间",
            prop: "endDate",
            width: 130,
          },
          {
            label: "应用调用频次",
            prop: "callCount",
            display: false,
          },
        ],
      },
    };
  },
  created() {
    if (this.$breadcrumb.breadcrumbs.length < 3) {
      this.$breadcrumb.add({
        name: "应用详情",
      });
    }
    this.detailAppHandel();
  },
  methods: {
    // 获取应用详情
    detailAppHandel() {
      let params = {
        id: this.$route.query.id,
      };
      detailApp(params).then((res) => {
        this.form = res.data.data;
        if (
          this.form.effectiveStartDate == "" ||
          this.form.effectiveStartDate == null
        ) {
          this.form.effective_date = null;
        } else {
          this.form.effective_date = [
            this.form.effectiveStartDate,
            this.form.effectiveEndDate,
          ];
        }
      });
    },
    // 获取当前应用下的API
    dataShareLicensedPageHandel() {
      this.tableDatas = [];
      dataShareLicensedPage({
        current: this.page.currentPage,
        size: this.page.pageSize,
        jidaAppId: this.$route.query.id,
        daDataShareId: "",
      }).then((res) => {
        if (res.data) {
          let tableData = [];
          (res.data.data.records || []).forEach((item) => {
            tableData.push({
              ...item,
              _inputParams: item.inputParams
                ? JSON.parse(item.inputParams)
                    .map((v) => v.key)
                    .join(",")
                : "",
              _outputParams: item.outputParams
                ? JSON.parse(item.outputParams)
                    .map((v) => v.key)
                    .join(",")
                : "",
            });
          });
          this.tableDatas = tableData;
          this.page.total = res.data.data.total;
        }
      });
    },
    // 测试方法
    testHandel(msg) {
      this.drawerVisible = true;
      this.testData = msg;
    },
    // 返回
    goBack() {
      this.$breadcrumb.pop();
      this.$router.back();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/.avue-crud__menu {
  display: none;
}
</style>

<template>
  <div>
    <design v-if="viewEdit" :app="app"></design>
    <preview v-if="!viewEdit" :app="app"></preview>
  </div>
</template>
<script>
import design from "./design";
import preview from "./preview";
import { mapGetters, mapState } from "vuex";

export default {
  name: "tableDesign",
  components: { preview, design },
  data() {
    return {
      app: {
        appId: "",
        id: "",
      },
    };
  },
  created() {
    this.app = {
      appId: this.$route.query.appId,
      id: this.$route.query.id,
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      viewEdit: (state) => state.common.viewEdit,
    }),
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.form-designer .widget-form-container .widget-form-list .widget-form-item:hover{
  border-width: 1px;
}
</style>

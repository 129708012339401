import { render, staticRenderFns } from "./prnpolicy.vue?vue&type=template&id=28fce551&scoped=true"
import script from "./prnpolicy.vue?vue&type=script&lang=js"
export * from "./prnpolicy.vue?vue&type=script&lang=js"
import style0 from "@wangeditor/editor/dist/css/style.css?vue&type=style&index=0&prod&lang=css&external"
import style1 from "./prnpolicy.vue?vue&type=style&index=1&id=28fce551&prod&lang=scss&scoped=true"
import style2 from "./prnpolicy.vue?vue&type=style&index=2&id=28fce551&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28fce551",
  null
  
)

export default component.exports
<template>
  <basic-container>
    <avue-crud
      v-model="modelForm"
      :data="tableDatas"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      @row-save="handleSave"
      @row-update="handleUpdate"
      @row-del="handleDel"
      @on-load="onLoad"
      @refresh-change="onLoad"
    >
      <template slot="modetype" slot-scope="scope">
        {{generateThresholdTypeShow(scope.row)}}
      </template>
    </avue-crud>
  </basic-container>
</template>
<script>
  import defaultService from '@/config/defaultService';
  import {
    getFlowRules,
    saveFlowRule,
    updateFlowRule,
    delFlowRule
  } from '@/api/dataService/dataFlow'
  import {
    getServiceList
  } from '@/api/dataService/dataIdentity'
  export default {
    data() {
      return {
        page: {
          pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        tableLoading: false,
        modelForm: {
          grade: 1
        },
        tableDatas: [],
        tableOption: {
          column: [
            {
              label: '资源名',
              prop: 'resource',
              span: 24,
              rules: [{
                required: true,
                message: "请输入名称",
                trigger: "blur"
              }]
            },
            {
              label: '来源应用',
              prop: 'limitApp',
              span: 24,
              placeholder:'调用来源，"default"表示所有应用',
              rules: [{
                required: true,
                message: "请输入名称",
                trigger: "blur"
              }]
            },
            {
              label: '流控模式',
              prop: 'strategy',
              type: "radio",
              dicData: [{
                label: '直接',
                value: 0
              }, {
                label: '关联',
                value: 1
              }, {
                label: '链路',
                value: 2
              }],
              display: false
            },
            {
              label: '阈值类型',
              prop: 'grade',
              span: 24,
              type: "radio",
              value: 1,
              dicData: [{
                label: 'QPS',
                value: 1
              }, {
                label: '并发线程数',
                value: 0
              }],
              rules: [{
                required: true,
                message: "请选择阈值类型",
                trigger: "blur"
              }]
            },
            {
              label: '阈值',
              prop: 'count',
              type: 'number',
              span: 24,
              rules: [{
                required: true,
                message: "请输入阈值",
                trigger: "blur"
              }]
            },
            {
              label: '阈值模式',
              prop: 'modetype',
              display: false
            },
            {
              label: '流控效果',
              prop: 'controlBehavior',
              type: "radio",
              dicData: [{
                label: '快速失败',
                value: 0
              }, {
                label: 'Warm Up',
                value: 1
              }, {
                label: '排队等待',
                value: 2
              }, {
                label: '预热排队',
                value: 3
              }],
              display: false
            }
          ]
        }
      }
    },
    methods: {
      async onLoad() {
        await getServiceList().then( res => {
          let item = res.data.data[0].machines[0];
          defaultService['app'] = item['app'];
          defaultService['ip'] = item['ip'];
          defaultService['port'] = item['port'];
        });
        this.tableLoading = true;
        let obj = {
          ...defaultService
        };
        getFlowRules(obj).then(res => {
          this.tableLoading = false;
          this.page.total = res.data.data.length;
          let start = (this.page.currentPage - 1) * this.page.pageSize;
          let end = start + this.page.pageSize;
          this.tableDatas = res.data.data.slice(start,end);
        }).catch(e => {
          this.tableLoading = false;
        });
      },
      generateThresholdTypeShow(rule) {
        if (!rule.clusterMode) {
          return '单机';
        }
        if (rule.clusterConfig.thresholdType === 0) {
          return '集群均摊';
        } else if (rule.clusterConfig.thresholdType === 1) {
          return '集群总体';
        } else {
          return '集群';
        }
      },
      handleSave(row, done, loading) {
        let obj = {
          "grade": row['grade'],
          "strategy": 0,
          "controlBehavior": 0,
          "limitApp": row['limitApp'],
          "clusterMode": false,
          "clusterConfig": {"thresholdType": 0},
          "resource": row['resource'],
          "count": row['count'],
          ...defaultService
        };
        saveFlowRule(obj).then(res => {
          done();
          loading();
          this.onLoad();
        }).catch(e => {
          loading();
        });
      },
      handleUpdate(row, index, done, loading) {
        let obj = {
          controlBehavior: row['controlBehavior'],
          count: row['count'],
          grade: row['grade'],
          id: row['id'],
          limitApp: row['limitApp'],
          maxQueueingTimeMs: row['maxQueueingTimeMs'],
          resource: row['resource'],
          strategy: row['strategy'],
          warmUpPeriodSec: row['warmUpPeriodSec']
        };
        updateFlowRule(obj).then(res => {
          done();
          loading();
          this.onLoad();
        });
      },
      handleDel(row) {
        let _t = this;
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            delFlowRule({
              app:defaultService.app,
              id: row['id']
            }).then(res => {
              _t.onLoad();
            })
          })
      }
    }
  }
</script>

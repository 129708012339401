<template>
  <div class="count">
    <div class="flex f14" style="padding-left: 20px">
      <i class="cockpit-icon icon_6"></i>
      楼宇租赁分析
    </div>
    <!-- 楼宇租赁分析数据表格 -->
    <!-- 模拟表头数据 -->
    <div class="simulate_thead">
      <p style="width: 5%">#</p>
      <p style="width: 17%">楼宇名称</p>
      <p style="width: 11%">建筑面积（㎡）</p>
      <p style="width: 11%">出租面积（㎡）</p>
      <p style="width: 11%">空置面积（㎡）</p>
      <p style="width: 11%">已售面积（㎡）</p>
      <p style="width: 11%">自用面积（㎡）</p>
      <p style="width: 11%">入驻企业数</p>
      <p style="width: 12%">租金收入（万元）</p>
    </div>
    <div id="mySwiper" class="swiper-container" v-show="disabled">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(page, index) in pages" :key="index">
          <div v-for="(item, index2) in page" :key="index2" class="tr-div">
            <p style="width: 5%">{{ item.index }}</p>
            <p style="width: 17%">{{ item.ssly }}</p>
            <p style="width: 11%">{{ item.room_area_sum }}</p>
            <p style="width: 11%">{{ item.room_area_yz }}</p>
            <p style="width: 11%">{{ item.room_area_wz }}</p>
            <p style="width: 11%">{{ item.room_area_ys }}</p>
            <p style="width: 11%">{{ item.room_area_zy }}</p>
            <p style="width: 11%">{{ item.sum_checkin_num }}</p>
            <p style="width: 12%">{{ getZjsr(item.ys_fz_sum) }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMetaCommonList } from "@/api/busiMode/metaCommon"
/** 引入轮播swiper **/
import Swiper from "swiper"
import "swiper/css/swiper.min.css"
export default {
  data() {
    return {
      /** 轮播数据 **/
      lydata: [],
      pages: [],
      testArr: [],
      disabled: true,
    }
  },

  mounted() {
    this.getMetaCommonList("v_wp_building_stat", "d2")
  },

  methods: {
    getZjsr(diffTime) {
      let data = diffTime ? (diffTime / 10000).toFixed(2) : 0
      return data
    },
    getMetaCommonList(view) {
      let _this = this
      let b = {
        size: 100,
        current: 1,
      }
      getMetaCommonList("wisdom_park.v_rpt_building_stat_curyear", b).then((res) => {
        const data = res.data.data
        this.lydata = data.records
        this.$nextTick(() => {
          _this.lydata.forEach((item, index) => {
            const page = Math.floor(index / 6)
            if (!_this.pages[page]) {
              _this.pages[page] = []
            }
            item.index = index + 1
            _this.pages[page].push(item)
            console.log(_this.pages)
          })
          /** 初始化 轮播图 **/
          this.disabled = false
          this.$nextTick(() => {
            this.disabled = true
            new Swiper(".swiper-container", {
              autoplay: true,
              loop: true,
              speed: 3000,
              direction: "vertical",
              observeParents: false, //注意这里！！
              observer: true, //也要注意这里！！
            })
          })
        })
      })
    },
  },
}
</script>

<style scoped>
/* .cockpit-container .cockpit-left,
.cockpit-container .cockpit-right {
height: 960px;
} */

.cockpit-container .cockpit-left {
  width: 979px;
}

.cockpit-container .cockpit-right {
  width: 916px;
  padding: 0 5px;
}

.cockpit-container .cockpit-left {
  background: url(../../../public/assets/assets/img/bg001.svg) no-repeat 100%/100%;
}

/** 标题渐变色样式 **/
.cockpit-title {
  position: relative;
  display: flex;
  font-size: 18px;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  color: #fff;
  font-weight: bold;
  background: linear-gradient(90deg, rgba(0, 238, 255, 0.7) 0%, rgba(60, 165, 255, 0) 100%);
}

.cockpit-title img {
  width: 40px;
}

.mt {
  margin-top: 5px;
}

.cockpit-mid-title {
  padding: 15px;
}

.flex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

/** 左侧 小框内容 **/
.data-content {
  display: flex;
  display: -webkit-flex;
  margin-top: 15px;
}

.data-item {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 60px;
  margin-right: 8px;
}

.data-item > p.num {
  font-size: 18px;
  font-weight: bold;
}

.data-item > p {
  margin-top: 5px;
  font-size: 11px;
}

/** 楼宇租赁分析表格样式 **/
.simulate_thead {
  display: flex;
  display: -webkit-flex;
  flex-wrap: nowrap;
  margin-top: 15px;
  padding: 0px 20px;
}

.simulate_thead p {
  flex-shrink: 0;
  font-size: 13px;
  line-height: 36px;
  text-align: right;
  background: rgba(0, 238, 255, 0.3);
  margin: 0;
}

.tr-div {
  display: flex;
  display: -webkit-flex;
  flex-wrap: nowrap;
  position: relative;
}

.tr-div > p {
  flex-shrink: 0;
  line-height: 26px;
  font-size: 13px;
  text-align: right;
}

.tr-div::after {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  bottom: 0;
  height: 1px;

  background: linear-gradient(
    90deg,
    rgba(0, 238, 255, 0.1) 0%,
    rgba(0, 238, 255, 0.7) 51%,
    rgba(0, 238, 255, 0.01) 100%
  );
}

.simulate_thead > p:nth-child(2),
.tr-div > p:nth-child(2) {
  text-align: left;
}

.simulate_thead > p:first-child,
.tr-div > p:first-child {
  text-align: center;
}

.tr-div > p:last-child {
  padding-right: 10px;
}

/** 轮播配置**/
#mySwiper {
  height: 308px;
  padding: 0px 20px;
}

/** 各种类型的单独样式 **/
.fw {
  background: url(../../../public/assets/assets/img/bg_icon_1.svg) 100% 100%;
}

.fw p.num {
  color: #00ffac;
}

.qy {
  background: url(../../../public/assets/assets/img/bg_icon_2.svg) 100% 100%;
}

.qy p.num {
  color: #00eeff;
}

.hn {
  background: url(../../../public/assets/assets/img/bg_icon_3.svg) 100% 100% no-repeat;
}

.hn p.num {
  color: #40b3ff;
}

p.num span.unit {
  font-size: 10px;
  margin-left: 3px;
}

.hn.data-item {
  width: 96px;
}

.fwsr {
  background: url(../../../public/assets/assets/img/bg_icon_4.svg) 100% 100% no-repeat;
}

.fwsr p.num {
  color: #f382ff;
}

.tcf {
  background: url(../../../public/assets/assets/img/bg_icon_5.svg) 100% 100% no-repeat;
}

.tcf p.num {
  color: #ffed7a;
}

/** 小图标 **/
.cockpit-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.icon_1 {
  background: url(../../../public/assets/assets/img/icon_1.svg) 100%/100%;
}

.icon_2 {
  background: url(../../../public/assets/assets/img/icon_2.svg) 100%/100%;
}

.icon_3 {
  background: url(../../../public/assets/assets/img/icon_3.svg) 100%/100%;
}

.icon_4 {
  background: url(../../../public/assets/assets/img/icon_4.svg) 100%/100%;
}

.icon_5 {
  background: url(../../../public/assets/assets/img/icon_5.svg) 100%/100%;
}

.icon_6 {
  background: url(../../../public/assets/assets/img/icon_6.svg) 100%/100%;
}

.count {
  margin-top: 28px;
}

.mt20 {
  margin-top: 20px;
}
.cockpit-title.noback {
  background: none;
}
</style>

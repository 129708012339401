<template>
  <div>
    <div class="pad-lr-15" v-loading="loading">
      <BasicLabelRow>
        <BasicLabelItem label="API集合">{{apiCollectionMap[form.apiCollectionId] || '-'}}</BasicLabelItem>
        <BasicLabelItem label="接口名称">{{form.name}}</BasicLabelItem>
        <BasicLabelItem label="请求方式">{{methods[form.requestMethod] || '-'}}</BasicLabelItem>
        <BasicLabelItem label="请求地址">{{form.shareUrl || '-'}}</BasicLabelItem>
        <BasicLabelItem label="接口用途">{{form.description || '-'}}</BasicLabelItem>
      </BasicLabelRow>
      <div class="mar-t-15"><el-divider direction="vertical"></el-divider> 入参</div>
      <el-form v-if="isLoaded" class="mar-t-15" :size="size" ref="form" :model="params" label-width="150px">
        <el-form-item
          v-for="item of inputParams"
          :rules="getRules(item)"
          :key="item.key"
          :label="item.key + '(' + paramTypes[item.dataType] + ')'"
          :prop="item.key"
        >
          <el-input placeholder="请输入" v-model="params[item.key]"></el-input>
        </el-form-item>
        <el-form-item class="mar-t-15">
          <el-button type="primary" :size="size" :loading="submitBtnLoading" @click="query">调用</el-button>
        </el-form-item>
      </el-form>
      <basic-label-row class="mar-t-15">
        <basic-label-item label="返回值">
          <basic-codemirror style="max-height: 250px" :value="code" mode="application/x-json"></basic-codemirror>
        </basic-label-item>
      </basic-label-row>
    </div>
  </div>
</template>
<script>

import api from '@/api/dataShare/index';
import { getDataAPICollectionSelect } from '@/api/dataService/dataAPICollection';
import * as dictbizService from '@/api/system/dictbiz';
import {getAppToken} from '@/api/dataApiAccredit/dataApiAccredit'
export default {
  data(){
    return {
      isLoaded: false,
      submitBtnLoading: false,
      loading: false,
      size: this.$store.state.common.size,
      form: {
        apiCollectionId: null,
        name: '',
        shareUrl: '',
        description: '',
        requestMethod: ''
      },
      inputParams: [],
      outputParams: [],
      apiCollectionMap: {},
      paramTypes: {},
      methods: {},
      params: {},
      code: ''
    }
  },
  props: {
    id: String,
    appId: '',
    appKey: ''
  },
  mounted(){
    if(this.id){
      this.loading = true;
      this.initDicData().then(() => {
        this.getDataAPICollectionSelect().then(
          () => {
            this.get();
          }
        );
      });
    }
  },
  methods: {
    getDataAPICollectionSelect(){
      return getDataAPICollectionSelect().then(
        res => {
          (res.data.data || []).map( api => {
            this.apiCollectionMap[api['id']] = api['name'];
          });
        }
      )
    },
    initDicData(){
      return new Promise((a , b) => {
        dictbizService.getDictionary({
          code: 'REQUEST_METHOD'
        }).then(
          res => {
            (res.data.data || []).map(
              item => {
                this.methods[item.dictKey] = item['dictValue'];
              }
            )
            dictbizService.getDictionary({
              code: 'PARAM_TYPE'
            }).then(
              res => {
                (res.data.data || []).map(
                  item => {
                    this.paramTypes[item.dictKey] = item['dictValue'];
                  }
                )
                a();
              }
            )
          }
        )
      })
    },
    get(){
      this.loading = true;
      return api.get({
        id: this.id
      }).then(
        res => {
          if(res.data){
            this.form = res.data.data;
            this.inputParams = this.form.inputParams ? JSON.parse(this.form.inputParams) : [];
            this.outputParams = this.form.outputParams ? JSON.parse(this.form.outputParams) : [];
            let params = {};
            this.inputParams.forEach(
              item => {
                item['value'] = item['default'] || null;
                params[item.key] = item['value'];
              }
            )
            this.params = params;
          }
          this.loading = false;
          this.isLoaded = true;
        }
      )
    },
    getRules(row){
      let rules = [];
      if(row.required){
        rules.push({ required: true, message: "请输入" + row.key, trigger: "blur"})
      }
      return rules;
    },
    query(){
      this.$refs.form.validate( valid => {
        if(valid){
          let params={}
          if (this.validatenull(this.appId)) {
            params= {
              appKey: this.appKey,
              expireTime: 10000
            }
          } else {
            params= {
              appId: '10000000',
              expireTime: 10000
            }
          }
          getAppToken(params).then((res) => {
            if (res.data.code==200) {
                this.submitBtnLoading = true;
                let inputParams = [];
                this.inputParams.forEach(
                  item => {
                    inputParams.push({
                      key: item.key,
                      value: this.params[item.key],
                      key_type: item.dataType
                    })
                  }
                )
                api.query2({
                  tableName: this.form.dataSourceTable,
                  body: {
                    current: 1,
                    size: 10,
                    data: inputParams
                  },
                  headers: {
                    token: res.data.data.token
                  },
                  url: this.form.shareUrl
                }).then(
                  res => {
                    this.submitBtnLoading = false;
                    if(res.data){
                      this.code = JSON.stringify(res.data , null , 2);
                    }
                  }
                ).catch(() => {
                  this.submitBtnLoading = false;
                })
            }else{
              this.$message.warning('请点击调用重新生成token');
            }
          }).catch((error) => {
            this.$message.warning('请点击调用重新生成token');
          })
          // api.testApi({
          //   id: this.id,
          //   inputParams: JSON.stringify(this.params)
          // }).then(
          //   res => {
          //     this.submitBtnLoading = false;
          //     if(res.data){
          //       this.code = JSON.stringify(res.data , null , 2);
          //     }
          //   }
          // ).catch(e => {
          //   this.submitBtnLoading = false;
          // })
        }else{
          return false;
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.basic__label-row-item-content {
  flex: none!important;
  width: calc(100% - 75px)!important;
}
</style>

<template>
  <el-col :span="24">
    <business v-if="currentBtnObj.type == 'business'"></business>
    <chooseType v-if="currentBtnObj.type == 'any'"></chooseType>
    <staticFun v-if="currentBtnObj.type == 'staticFun'"></staticFun>
    <el-dialog
      title="选择操作类型"
      width="30%"
      :visible.sync="operDialogVisible"
      append-to-body
      destroy-on-close
    >
      <chooseType ref="chooseType"></chooseType>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="operDialogVisible = false"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="
            operDialogVisible = false;
            sureAdd();
          "
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </el-col>
</template>

<script>
import business from "./business";
import staticFun from "./static";
import chooseType from "./chooseType";

export default {
  components: { business, staticFun, chooseType },
  data() {
    return {
      btnTitle: "",
      operDialogVisible: false,
      currentBtnObj: {
        type: "business",
      },
      newBtnOperType: "business",
    };
  },
  methods: {},
};
</script>

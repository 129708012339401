<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" :page.sync="page" :before-open="beforeOpen" :search.sync="search" v-model="form" ref="crud" @search-change="searchChange"
                   @search-reset="searchReset" @current-change="currentChange" @size-change="sizeChange" @refresh-change="refreshChange" @on-load="onLoad">
        </avue-crud>
    </basic-container>
</template>

<script>
import { getList, getDetail, add, update, remove, del } from '@/api/spcod/sporttype';
import { getMetaCommonList } from '@/api/busiMode/metaCommon';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            form: {},
            query: {},
            loading: true,
            search: {},
            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            selectionList: [],
            model: 'wisdom_park.v_wp_spcod_order_managemen_cod_stat',
            option: {
                height: 'auto',
                calcHeight: 30,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: true,
                viewBtn: true,
                editBtn: false,
                delBtn: false,
                dialogClickModal: false,
                menu: false,
                column: [
                    {
                        label: '订单号',
                        prop: 'order_sn',
                    },
                    {
                        label: '会员卡',
                        prop: 'card_name',
                    },
                    {
                        label: '预定人',
                        prop: 'phone_name',
                    },
                    {
                        label: '预留电话',
                        prop: 'contact_phone',
                        search: true,
                        width: 150,
                    },
                    {
                        label: '订单生成日期',
                        prop: 'order_time',
                        width: 150,
                    },
                    {
                        label: '订单状态',
                        prop: 'order_flag',
                        search: true,
                        type: 'select',
                        dicData: [
                            {
                                label: '待支付',
                                value: '待支付',
                            },
                            {
                                label: '已支付',
                                value: '已支付',
                            },
                            {
                                label: '已取消',
                                value: '已取消',
                            },
                            {
                                label: '已删除',
                                value: '已删除',
                            },
                        ],
                    },
                    {
                        label: '订单金额',
                        prop: 'order_value',
                    },
                    {
                        label: '支付类型',
                        prop: 'pay_type',
                        type: 'select',
                        dicData: [
                            {
                                label: '会员卡支付',
                                value: 'accbal',
                            },
                            {
                                label: '微信支付',
                                value: 'weixinpay',
                            },
                            {
                                label: '支付宝支付',
                                value: 'alipay',
                            },
                            {
                                label: '银联支付',
                                value: 'unionpay',
                            },
                            {
                                label: '龙支付',
                                value: 'dragonpay',
                            },
                        ],
                    },
                    {
                        label: '支付流水号',
                        prop: 'pay_sd',
                    },
                    {
                        label: '支付日期',
                        prop: 'pay_time',
                        type: 'date',
                        valueFormat: 'yyyy-MM-dd',
                        search: true,
                        width: 150,
                    },
                    {
                        label: '支付金额',
                        prop: 'pay_value',
                    },
                    {
                        label: '订单备注',
                        prop: 'order_memo',
                        type: 'textarea',
                        minRows: 3,
                    },
                    // {
                    //   label: "订单来源",
                    //   prop: "order_source",
                    //   search: true,
                    // },
                    // {
                    //   label: "对应业务主键",
                    //   prop: "order_bizid",
                    // },
                    {
                        label: '状态',
                        prop: 'status',
                        dicData: [
                            {
                                label: '有效',
                                value: 1,
                            },
                            {
                                label: '作废',
                                value: 0,
                            },
                        ],
                    },

                    // {
                    //   label: "是否是会员支付",
                    //   prop: "",
                    // },
                ],
            },
            data: [],
        };
    },
    methods: {
        beforeOpen(done, type) {
            if (['edit', 'view'].includes(type)) {
                getDetail(this.form.id).then(res => {
                    this.form = res.data.data;
                });
            }
            done();
        },
        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },
        searchChange(params, done) {
            console.log('搜索');
            this.query = params;
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
            done();
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        currentChange(currentPage) {
            console.log(111);
            this.page.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        onLoad(page, params = {}) {
            let wheres = [];
            console.log(params, this.form);
            if (this.query.order_flag) {
                wheres.push({ field: 'order_flag', value: this.query.order_flag });
            }
            if (this.query.contact_phone) {
                wheres.push({ field: 'contact_phone', value: this.query.contact_phone });
            }
            if (this.query.pay_time) {
                wheres.push({ field: 'pay_time', op: 'LIKE', value: this.query.pay_time });
            }

            this.loading = true;
            getMetaCommonList(this.model, {
                current: this.page.currentPage,
                size: this.page.pageSize,
                wheres: wheres,
            })
                .then(res => {
                    this.loading = false;
                    if (res.data && res.data.code === 200) {
                        this.data = res.data.data.records;
                        this.page.total = res.data.data.total;
                    } else {
                        this.data = [];
                        this.page.total = 0;
                    }
                })
                .catch(e => {
                    this.loading = false;
                });
            // getList(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
            //   const data = res.data.data;
            //   this.page.total = data.total;
            //   this.data = data.records;
            //   this.loading = false;
            //   this.selectionClear();
            // });
        },
    },
};
</script>

<style></style>

<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      :page.sync="page"
      :before-open="beforeOpen"
      v-model="form"
      ref="crud"
      @selection-change="selectionChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot="menuLeft">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-delete"
          plain
          @click="addOpera"
          >新 增
        </el-button>
        <el-button
          type="danger"
          size="small"
          icon="el-icon-delete"
          plain
          @click="handleDelete"
          >删 除
        </el-button>
      </template>
      <template slot="menu" slot-scope="scope">
        <el-button
          type="text"
          size="small"
          icon="el-icon-edit"
          @click="previewTable(scope.row, 'des')"
          >设计
        </el-button>

        <el-button
          type="text"
          size="small"
          icon="el-icon-delete"
          plain
          @click="handleDel(scope.row)"
          >删 除
        </el-button>
      </template>
    </avue-crud>

    <div v-if="previewBox" class="serviceDesignDrawer">
      <el-drawer
        size="100%"
        :visible.sync="previewBox"
        modalAppendToBody
        appendToBody
        destroy-on-close
        :with-header="false"
      >
        <!-- <div v-if="previewBox"> -->
          <business
            v-if="previewBox"
            ref="business"
            :dataSoure="dataSource"
            :entityId="dataSource.code + '.' + model.tableName"
            @back="back"
            :onlyBpmn="`true`"
            :currentBtn="currentRow"
          ></business>
        <!-- </div> -->

        <!-- <el-row :gutter="10">
          <el-col :span="4" class="baseinfo">
            <chooseType
              ref="chooseType"
              :operation="currentRow"
              @saveOperationObj="saveOperationObj"
            ></chooseType>
          </el-col>
          <el-col :span="20" style="border-left: 1px">
            <operations
              :dataSoure="dataSource"
              :entityId="dataSource.code + '.' + model.tableName"
              :operationObj="currentRow"
              :currentBtn="currentRow"
              @back="back"
            >
            </operations>
          </el-col>
        </el-row> -->
      </el-drawer>
    </div>
  </basic-container>
</template>

<script>
import { getList, getDetail, add, update, remove } from "@/api/tool/eventModel";
import business from "../data/table/operations/business";
import operations from "../data/table/operations/index";
import chooseType from "../data/table/operations/chooseType";

export default {
  components: { business, operations, chooseType },
  data() {
    return {
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      option: {
        height: "450",
        calcHeight: 30,
        dialogWidth: 900,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: false,
        index: true,
        viewBtn: false,
        selection: true,
        dialogClickModal: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "名称",
            prop: "name",
          },
          {
            label: "版本",
            prop: "version",
          },
          {
            label: "启用方式",
            prop: "startupModel",
          },

          {
            label: "创建人",
            prop: "createUserName",
          },
          {
            label: "创建时间",
            prop: "createTime",
          },
          {
            label: "描述",
            prop: "description",
          },
        ],
      },
      previewBox: false,
      currentRow: {
        type: "business",
      },
      data: [],
      dataSourceObj: {},
    };
  },
  props: {
    dataSource: {
      type: Object,
      default: function () {
        return {};
      },
    },
    model: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  methods: {
    back() {
      this.previewBox = false;
      this.refreshChange();
    },
    saveOperationObj(obj) {
      this.currentRow["label"] = obj.operName;
    },
    addOpera() {
      this.previewBox = true;
      localStorage.setItem(
        "currentDataSource",
        JSON.stringify(this.dataSource)
      );
      this.dataSourceObj = JSON.parse(JSON.stringify(this.dataSource));
      this.currentRow["serviceId"] = "";
      this.currentRow["type"] = "business";
    },
    previewTable(row, type) {
      this.currentRow = row;
      this.currentRow["serviceId"] = row.id;
      this.currentRow["type"] = "business";
      localStorage.setItem(
        "currentDataSource",
        JSON.stringify(this.dataSource)
      );

      this.previewBox = true;
      this.dataSourceObj = JSON.parse(JSON.stringify(this.dataSource));
    },
    rowSave(row, done, loading) {
      add(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          window.console.log(error);
          loading();
        }
      );
    },

    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          //return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    rowDel(row, index) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableDatas.splice(index, 1);
        })
        .catch(() => {});
    },
    handleDel(row) {
      let _t = this;
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        remove({
          ids: row["id"],
        }).then((res) => {
          this.onLoad(this.page);
        });
      });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      let ids = [];
      this.selectionList.forEach((e) => {
        ids.push(e.id);
      });
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove({
            ids: ids.toString(),
          });
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
        });
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getDetail(this.form.id).then((res) => {
          this.form = res.data.data;
        });
      }
      done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      let obj = {
        current: page.currentPage,
        size: page.pageSize,
        modelKey: this.dataSource.code + "." + this.model.tableName,
      };
      getList(obj).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        data.records.forEach((e) => {
          if (e.startupModel == 1) {
            e.startupModel = "事件触发";
          } else if (e.startupModel == 2) {
            e.startupModel = "消息触发和";
          } else if (e.startupModel == 3) {
            e.startupModel = "调用触发";
          } else {
            e.startupModel = "未知";
          }
        });
        this.data = data.records;
        this.loading = false;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.serviceDesignDrawer {
  /deep/.el-drawer__body {
    padding: 0;
  }
}
</style>

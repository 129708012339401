<template>
  <div :class="{ houseingRes: houseingRes != 'houseingRes' }">
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      ref="crud"
      @row-click="handleRowClick"
      @on-load="onLoad"
      @selection-change="selectionChange"
      @cell-click="handleRowClick"
    >
      <template
        slot="menuLeft"
        slot-scope="scope"
        v-if="houseingRes == 'houseingRes'"
      >
        <el-input
          v-model="searchText"
          @keyup.native.enter="refreshChange"
          size="small"
          placeholder="请输入楼宇名称"
          type="text"
          style="width: 250px"
        >
          <el-button
            class="mar-0 pad-0"
            size="small"
            @click="refreshChange"
            slot="append"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </template>
      <template slot-scope="{ row }" slot="building_name">
        <a class="cur-p">{{ row.building_name }}</a>
      </template>
    </avue-crud>
  </div>
</template>

<script>
import { getMetaCommonList } from "@/api/busiMode/metaCommon";
const viewPortHeight =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;
export default {
  components: {},
  data() {
    return {
      option: {
        height: viewPortHeight * 0.5,
        dialogWidth: 900,
        tip: false,
        searchShow: false,
        searchMenuSpan: 6,
        border: true,
        index: false,
        refreshBtn: false,
        columnBtn: false,
        searchBtn: false,
        labelWidth: 120,
        menuWidth: 250,
        viewBtn: false,
        dialogClickModal: false,
        delBtn: false,
        editBtn: false,
        menu: false,
        addBtn: false,
        cancelBtn: false,
        page: false,
        column: [
          {
            width: 200,
            overHidden: true,
            align: "left",
            label: "楼宇名称",
            prop: "name",
            hide: false,
          },
          {
            width: 80,
            overHidden: true,
            align: "left",
            label: "楼宇编号",
            prop: "code",
            hide: false,
          },

          {
            width: 80,
            overHidden: true,
            label: "层高",
            prop: "floor_height",
          },
          {
            width: 80,
            overHidden: true,
            label: "层数",
            prop: "floor_num",
          },

          {
            width: 150,
            overHidden: true,
            label: "物业管理费(m²/月)",
            prop: "property_management_fee",
            hide: false,
          },
          {
            width: 120,
            overHidden: true,
            label: "建筑面积(m²)",
            prop: "floorage",
            hide: false,
          },

          {
            width: 100,
            overHidden: true,
            label: "租赁方式",
            prop: "lease_type",
            hide: false,
          },
          {
            overHidden: true,
            align: "left",
            label: "楼宇地址",
            prop: "address",
          },
        ],
      },
      loading: true,
      model: "wisdom_park.wp_building",
      data: [],
      buildObj: {},
      searchText: "",
    };
  },
  props: {
    houseingRes: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  computed: {},
  mounted() {
    this.onLoad();
    if (this.houseingRes == "houseingRes") {
      this.option.height = viewPortHeight * 0.5;
      this.option.selection = true;
    } else {
      this.option.height = viewPortHeight - 260;
      this.option.selection = false;
    }
    this.option = JSON.parse(JSON.stringify(this.option));
  },
  methods: {
    refreshChange() {
      this.onLoad();
    },
    onLoad() {
      this.loading = true;
      let body = {
        current: 1,
        size: 50,
      };
      if (this.searchText) {
        body["wheres"] = [
          { field: "name", op: "LIKE", value: this.searchText },
        ];
      }
      getMetaCommonList(this.model, body).then((res) => {
        const data = res.data.data;
        this.data = data.records;
        this.loading = false;
      });
    },
    handleRowClick(e) {
      this.$emit("oneBuild", e);
    },
    selectionChange(selection) {
      this.$emit("oneBuild", selection);
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.houseingRes /deep/ .avue-crud__menu {
  display: none;
}
.cell-color {
  color: #409eff !important;
  cursor: pointer;
}
/deep/ .el-table th.is-leaf {
  padding-left: 15px;
  padding-right: 15px;
}
</style>

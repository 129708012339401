<template>
    <div>
        <el-card class="card">
            <el-col :span="24" class="block">
                <div class="demo-input-suffix mar-t-5f">
                    <el-row>
                        <span class="pad-l-20 mar-r-5">所属楼宇</span>
                        <el-select v-model="formInline.building_id" @change="getFloors" clearable size="small" placeholder="请选择">
                            <el-option v-for="(item, index) in buildList" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                        <span class="pad-l-20 mar-r-5">所属楼层</span>
                        <el-select v-model="formInline.floor_id" clearable size="small" placeholder="请选择">
                            <el-option v-for="(item, index) in floorLists" :key="index" :label="item.code" :value="item.id">
                            </el-option>
                        </el-select>
                        <span class="pad-l-20">房间号</span>
                        <el-input size="small" placeholder="请输入要查询的房间号" class="configInput w-200" v-model="formInline.code">
                        </el-input>
                        <span class="pad-l-20 mar-r-5">出租状态</span>
                        <el-select v-model="formInline.status" clearable size="small" placeholder="请选择">
                            <el-option v-for="(item, index) in roomStatuss" :key="index" :label="item.name" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-row>
                    <el-row class="mar-t-10">
                        <span class="pad-l-20 mar-r-5">拥用产权</span>
                        <el-select v-model="formInline.has_ownership" clearable size="small" placeholder="请选择">
                            <el-option label="是" :value="1"> </el-option>
                            <el-option label="否" :value="0"> </el-option>
                        </el-select>

                        <span class="pad-l-20 mar-r-5">虚拟房间</span>
                        <el-select v-model="formInline.is_invented" clearable size="small" placeholder="请选择">
                            <el-option label="是" :value="1"> </el-option>
                            <el-option label="否" :value="0"> </el-option>
                        </el-select>
                        <el-button size="small" class="mar-l-20" :loading="btnLoding" type="primary" @click="search">搜索</el-button>
                        <el-button class="mar-l-5" @click="
                formInline = {};
                onLoad();
              " size="small">重置</el-button>
                        <el-button class="mar-l-5" icon="el-icon-download" :loading="downLoading" @click="getMetaCommonListExport" size="small">导出</el-button>
                    </el-row>
                </div>
            </el-col>
        </el-card>
        <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" :page.sync="page" @refresh-change="refreshChange" @on-load="onLoad">
            <template slot="menuLeft" slot-scope="scope">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="addRoom()">新增房间
                </el-button>
            </template>
            <template slot-scope="scope" slot="menu">
                <el-button type="text" size="small" class="none-border" icon="el-icon-view" @click="getDetail(scope.row, true)">查看
                </el-button>
                <el-button type="text" size="small" class="none-border" icon="el-icon-edit" @click="getDetail(scope.row, false)">修改
                </el-button>
                <el-button type="text" size="small" icon="el-icon-delete" class="none-border" @click="cancle(scope.row)">作废
                </el-button>
            </template>
        </avue-crud>
        <el-dialog :title="roomTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" destroy-on-close :top="'8vh'" width="60%">
            <roomInfo v-if="dialogVisible" ref="roomInfo" :buildRead="buildRead" :onlyRead="onlyRead" :floors="floors" :ruleFormObj="ruleForm"></roomInfo>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" v-if="!onlyRead" @click="saveMetaCommon">确 定</el-button>
                <el-button size="small" type="primary" v-if="onlyRead" @click="editRoom">编 辑</el-button>
                <el-button size="small" @click="closeDias">取 消</el-button>
            </span>
        </el-dialog>
        <el-drawer :visible.sync="detailDia" size="60%" :withHeader="false" destroy-on-close>
            <el-header class="bor-b" height="50px">
                <el-page-header class="lh-50" @back="close" :content="title"></el-page-header>
            </el-header>
            <el-container style="height: calc(100% - 100px)" class="o-a">
                <splitRoom :ruleFormObj="ruleForm" :floors="floors" @close="close" ref="saveSplitRoom"></splitRoom>
            </el-container>
            <el-footer class="bor-t lh-49" height="50px">
                <div class="pull-right">
                    <template>
                        <el-button size="small" type="primary" @click="saveSplitRoom">保存</el-button>
                        <el-button size="small" type="" @click="close">取消</el-button>
                    </template>
                </div>
            </el-footer>
            <!-- <detail :currentRow="currentRow" @changName="changName"></detail> -->
        </el-drawer>
    </div>
</template>

<script>
import roomInfo from './space/roomInfo';
import * as dictbizService from '@/api/system/dictbiz';
import splitRoom from './space/splitRoom';

import {
    getMetaCommonList,
    delMetaCommon,
    saveMetaCommon,
    updateMetaCommon,
    getMetaCommonInfo,
    getMetaCommonListExport,
} from '@/api/busiMode/metaCommon';

export default {
    components: { roomInfo, splitRoom },
    data() {
        return {
            formInline: {},
            detailDia: false,
            dialogVisible: false,
            buildId: '',
            buildName: '',
            title: '',
            btnLoding: false,
            downLoading: false,
            page: {
                size: 10,
                current: 1,
                total: 0,
            },
            tableLoading: false,
            model: 'wisdom_park.wp_room',
            view_model: 'wisdom_park.v_wp_room_stat',
            floorModel: 'wisdom_park.wp_floor',
            room_export: 'wisdom_park.v_wp_room_export_stat',

            spaces: [],
            floors: [],
            ruleForm: { label: [] },
            currentIndex: '',
            onlyRead: false,
            roomTitle: '新增房间',
            decorationList: [],
            sumCheckinArea: '',
            loading: false,
            searchText: '',
            data: [],
            buildRead: false,
            roomStatuss: [],
            option: {
                align: 'center',
                calcHeight: 30,
                dialogWidth: 900,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: true,
                // selection: true,
                labelWidth: 120,
                menuWidth: 200,
                viewBtn: false,
                dialogClickModal: false,
                delBtn: false,
                editBtn: false,
                addBtn: false,
                cancelBtn: false,
                column: [
                    {
                        width: 200,
                        overHidden: true,
                        align: 'left',
                        label: '名称',
                        prop: 'room_name',
                        hide: false,
                    },
                    {
                        width: 150,
                        overHidden: true,
                        align: 'left',
                        label: '楼宇名称',
                        prop: 'building_name',
                        hide: false,
                    },

                    {
                        width: 80,
                        overHidden: true,
                        label: '楼层',
                        prop: 'floor_code',
                    },

                    {
                        width: 110,
                        overHidden: true,
                        label: '房间面积(m²)',
                        align: 'right',
                        prop: 'room_area',
                    },
                    {
                        width: 110,
                        overHidden: true,
                        label: '产权面积(m²)',
                        align: 'right',
                        prop: 'ownership_area',
                    },

                    {
                        width: 120,
                        overHidden: true,
                        align: 'right',
                        label: '出租单价(m²/天)',
                        prop: 'rental_unit_price_day',
                        hide: false,
                    },

                    {
                        width: 80,
                        overHidden: true,
                        label: '出租状态',
                        prop: 'status',
                        dicData: [
                            {
                                value: 0,
                                label: '未出租',
                            },
                            {
                                value: 1,
                                label: '已出租',
                            },
                            {
                                value: 2,
                                label: '已售',
                            },
                            {
                                value: 3,
                                label: '自用',
                            },
                        ],
                    },
                    {
                        width: 80,
                        overHidden: true,
                        label: '虚拟房间',
                        align: 'center',
                        prop: 'is_invented',
                        dicData: [
                            {
                                value: 0,
                                label: '否',
                            },
                            {
                                value: 1,
                                label: '是',
                            },
                        ],
                    },
                    {
                        width: 100,
                        overHidden: true,
                        label: '是否拥有产权',
                        align: 'center',
                        prop: 'has_ownership',
                        dicData: [
                            {
                                value: 0,
                                label: '否',
                            },
                            {
                                value: 1,
                                label: '是',
                            },
                        ],
                    },

                    {
                        width: 250,
                        overHidden: true,
                        label: '入驻企业',
                        prop: 'company_name',
                        hide: false,
                    },
                    {
                        width: 250,
                        overHidden: true,
                        label: '买方企业',
                        prop: 'buy_company_name',
                        hide: false,
                    },
                    {
                        width: 100,
                        align: 'center',
                        overHidden: true,
                        label: '是否已经入驻',
                        prop: 'is_checkin',
                        hide: false,
                        dicData: [
                            {
                                value: 0,
                                label: '未入住',
                            },
                            {
                                value: 1,
                                label: '已入住',
                            },
                        ],
                    },
                ],
            },
            buildList: [],
            floorList: [],
            floorLists: [],
        };
    },
    computed: {},
    mounted() {
        this.buildId = this.$router.currentRoute.query.buildId;
        this.buildName = this.$router.currentRoute.query.buildName;
        this.onLoad();
        this.getDicList();
        this.getBuildList();
    },
    methods: {
        getBuildList() {
            this.loading = true;
            getMetaCommonList('wisdom_park.wp_building', {
                size: 100,
                current: 1,
            }).then(res => {
                this.loading = false;
                const data = res.data.data;
                this.buildList = data.records;
            });
        },
        getFloors() {
            let obj = {
                size: 1000,
                current: 1,
                orders: [{ field: 'code+1000', sort: 'DESC' }],
            };
            obj.wheres = [{ field: 'building_id', value: this.formInline.building_id }];
            getMetaCommonList('wisdom_park.wp_floor', obj).then(res => {
                const data = res.data.data;
                this.floorLists = data.records;
            });
        },
        refreshChange() {
            console.log('herer');
            this.onLoad(this.page);
        },
        close() {
            this.detailDia = false;
            this.onLoad();
        },
        back() {
            history.go(-1);
        },
        getDicList() {
            dictbizService
                .getDictionary({
                    code: 'decoration',
                })
                .then(res => {
                    this.decorationList = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
            dictbizService
                .getDictionary({
                    code: 'RoomStatus',
                })
                .then(res => {
                    this.roomStatuss = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: Number(item['dictKey']),
                            id: item['id'],
                        };
                    });
                });
        },
        saveSplitRoom() {
            this.$refs.saveSplitRoom.saveRooms();
        },
        getRenovation(r) {
            let str = '';
            this.decorationList.forEach(d => {
                if (d.value == r) {
                    str = d.name;
                }
            });
            return str;
        },
        editRoom() {
            this.onlyRead = !this.onlyRead;
            if (!this.onlyRead) {
                this.roomTitle = '房间编辑';
            } else {
                this.roomTitle = '房间详情';
            }
        },
        addRoom() {
            this.ruleForm = { label: [] };
            this.ruleForm.label = [];
            this.ruleForm.has_ownership = 1;
            this.ruleForm.is_invented = 0;
            this.ruleForm.status = 0;
            this.onlyRead = false;
            this.buildRead = true;
            this.roomTitle = '新增房间';
            this.dialogVisible = true;
        },
        btbDO(type, index) {
            if (type == 'rowDel') {
                this.rowDel(index);
            }
            if (type == 'splitRoom') {
                this.splitRoom(index);
            }
        },
        splitRoom(room) {
            this.title = room.name;
            this.detailDia = true;
            this.ruleForm = room;
        },
        cancle(room) {
            this.$confirm('是否作废?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                let r = {
                    is_cancel: 1,
                    id: room.room_id,
                };
                let o = {
                    update: JSON.parse(JSON.stringify(r)),
                    primaryKeyValue: room.room_id,
                    primaryKey: 'id',
                };
                updateMetaCommon(this.model, o).then(res => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        this.onLoad(this.page);
                    }
                });
            });
        },
        rowDel(row) {
            if (row.room_id) {
                this.$confirm('是否删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        let pk = 'id';
                        delMetaCommon(this.model, { pk: pk, ids: [row.room_id] }).then(res => {
                            this.$message({
                                type: 'success',
                                message: '操作成功!',
                            });
                            this.onLoad(this.page);
                        });
                    })
                    .catch(() => {});
            }
        },
        //
        onFloorLoad(id) {
            let params = {
                wheres: [
                    {
                        field: 'floor_id',
                        value: id,
                    },
                ],
            };
            getMetaCommonList('wisdom_park.v_wp_floor_stat', {
                current: 1,
                size: 500,
                ...params,
            }).then(res => {
                const data = res.data.data;
                this.sumCheckinArea = data.records[0].sum_free_area;
            });
        },
        async saveMetaCommon() {
            let room = JSON.parse(JSON.stringify(this.$refs.roomInfo.ruleForm));
            console.log(room);
            if (!this.sumCheckinArea) {
                await this.onFloorLoad();
            }
            // console.log(this.sumCheckinArea);
            // if (this.sumCheckinArea <= room.room_area) {
            //   this.$message.error("房间面积不能大于该楼层的空闲面积");
            //   return false;
            // }
            if (this.$refs.roomInfo.imgform) {
                let imgList = [];
                this.$refs.roomInfo.imgform.imgUrl.forEach(e => {
                    if (e.label) {
                        imgList.push(e.label);
                    }
                });
                room.attachment = imgList.toString();
            }
            if (this.$refs.roomInfo.imgform1) {
                let imgList = [];
                this.$refs.roomInfo.imgform1.imgUrl.forEach(e => {
                    if (e.label) {
                        imgList.push(e.label);
                    }
                });
                room.layout_attachment = imgList.toString();
            }
            if (this.ruleForm.housing_resources_type) {
                this.ruleForm.housing_resources_type = this.ruleForm.housing_resources_type.toString();
            }

            if (!room.id) {
                room.code = room.code != '' ? room.code : room.name;
                let floorModel = {
                    pk: 'id',
                    id: room.floor_id,
                };
                getMetaCommonInfo(this.floorModel, floorModel).then(res => {
                    if (res.data.code == 200) {
                        room.floor_name = res.data.data.name;
                        room.floor_id = res.data.data.id;
                        room.building_name = res.data.data.building_name;
                        room.building_id = res.data.data.building_id;
                        if (room.label && room.label.length > 0) {
                            room.label = room.label.toString();
                        }
                        if (room.label && room.label.length == 0) {
                            room.label = '';
                        }
                        if (room.housing_resources_type && room.housing_resources_type.length > 0) {
                            room.housing_resources_type = room.housing_resources_type.toString();
                        }
                        if (room.housing_resources_type && room.housing_resources_type.length == 0) {
                            room.housing_resources_type = '';
                        }
                        saveMetaCommon(this.model, {
                            values: JSON.parse(JSON.stringify(room)),
                        }).then(res => {
                            if (res.data.code == 200) {
                                this.dialogVisible = false;
                                this.onLoad();
                                if (this.spaces[this.currentIndex]) {
                                    this.spaces[this.currentIndex].rooms.push(res.data.data);
                                }
                            }
                        });
                    }
                });
            } else {
                if (room.label && room.label.length > 0) {
                    room.label = room.label.toString();
                    console.log('转换', JSON.stringify(room.label));
                }
                if (room.label && room.label.length == 0) {
                    room.label = '';
                }
                if (room.housing_resources_type && room.housing_resources_type.length > 0) {
                    room.housing_resources_type = room.housing_resources_type.toString();
                }
                if (room.housing_resources_type && room.housing_resources_type.length == 0) {
                    room.housing_resources_type = '';
                }
                let o = {
                    update: JSON.parse(JSON.stringify(room)),
                    primaryKeyValue: room['id'],
                    primaryKey: 'id',
                };

                updateMetaCommon(this.model, o).then(res => {
                    if (res.data.code == 200) {
                        this.dialogVisible = false;
                        this.onLoad();
                        if (this.spaces[this.currentIndex]) {
                            this.spaces[this.currentIndex].rooms.forEach((r, index) => {
                                if (r.id == room.id) {
                                    this.spaces[this.currentIndex].rooms[index] = room;
                                }
                            });
                        }
                    }
                });
            }
        },
        getDetail(room, onlyRead) {
            this.ruleForm = {};

            this.ruleForm = JSON.parse(JSON.stringify(room));
            // if (this.ruleForm.label == "") {
            //   this.ruleForm.label = [];
            // }
            if (typeof this.ruleForm.label === 'string') {
                this.ruleForm.label = this.ruleForm.label.split(',');
            }
            this.roomTitle = '房间详情';
            if (!onlyRead) {
                this.buildRead = true;
                this.roomTitle = '房间编辑';
            }
            //  JSON.stringify

            this.onlyRead = onlyRead;
            this.onFloorLoad(room.floor_id);
            this.findFloors(room.building_id);
            this.dialogVisible = true;
        },

        closeDias() {
            this.dialogVisible = false;
            this.buildRead = false;
            this.ruleForm = {};
        },
        findFloors(bid) {
            let obj = {
                current: 1,
                size: 1000,
            };
            obj.wheres = [{ field: 'building_id', value: bid }];
            obj.orders = [{ field: 'code+1000', sort: 'DESC' }];
            getMetaCommonList(this.floorModel, obj).then(res => {
                const data = res.data.data;
                console.log(this.floors);
                this.floors = data.records;
            });
        },
        onLoad() {
            let obj = {
                current: this.page.currentPage,
                size: this.page.pageSize,
            };

            let wheres = [
                { field: 'building_id', value: this.buildId },
                { field: 'is_cancel', value: 0 },
            ];
            for (let key in this.formInline) {
                if (key == 'rental_unit_price_day') {
                    wheres.push({ field: key, value: this.formInline[key], op: 'GE' });
                } else if (key == 'rental_unit_price_day1') {
                    wheres.push({
                        field: 'rental_unit_price_day',
                        value: this.formInline[key],
                        op: 'LE',
                    });
                } else if (this.formInline.renovation) {
                    wheres.push({
                        field: 'renovation',
                        value: this.formInline.renovation,
                    });
                } else if (key == 'room_area') {
                    wheres.push({ field: key, value: this.formInline[key], op: 'GE' });
                } else if (key == 'room_area1') {
                    wheres.push({
                        field: 'room_area',
                        value: this.formInline[key],
                        op: 'LE',
                    });
                } else if (key == 'code') {
                    wheres.push({
                        field: 'room_name',
                        value: this.formInline[key],
                        op: 'LIKE',
                    });
                } else if (key == 'building_id') {
                    wheres.push({
                        field: 'building_id',
                        value: this.formInline[key],
                    });
                } else if (key == 'floor_id') {
                    wheres.push({
                        field: 'floor_id',
                        value: this.formInline[key],
                    });
                } else {
                    wheres.push({
                        field: key,
                        value: this.formInline[key],
                    });
                }
            }
            obj.wheres = wheres;
            console.log(wheres);
            this.getMetaCommonList(obj);
        },
        search() {
            let obj = this.getSearch();
            console.log(obj);
            this.getMetaCommonList(obj);
        },
        getMetaCommonList(obj) {
            // obj.orders = [{ field: "room_code+1000", sort: "DESC" }];
            obj.orders = [
                {
                    field: 'building_id',
                    sort: 'ASC',
                },
                {
                    field: 'CAST(v_wp_room_stat.floor_code AS SIGNED)',
                    sort: 'ASC',
                },
                {
                    field: 'room_name',
                    sort: 'ASC',
                },
            ];
            this.btnLoding = true;
            getMetaCommonList(this.view_model, obj).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                this.btnLoding = false;
            });
        },
        getSearch() {
            let wheres = [{ field: 'is_cancel', value: 0 }];
            if (this.buildId) {
                wheres.push({ field: 'building_id', value: this.buildId });
            }
            for (let key in this.formInline) {
                if (key == 'rental_unit_price_day') {
                    wheres.push({ field: key, value: this.formInline[key], op: 'GE' });
                } else if (key == 'rental_unit_price_day1') {
                    wheres.push({
                        field: 'rental_unit_price_day',
                        value: this.formInline[key],
                        op: 'LE',
                    });
                } else if (key == 'room_area') {
                    wheres.push({ field: key, value: this.formInline[key], op: 'GE' });
                } else if (key == 'room_area1') {
                    wheres.push({
                        field: 'room_area',
                        value: this.formInline[key],
                        op: 'LE',
                    });
                } else if (key == 'code') {
                    wheres.push({
                        field: 'room_name',
                        value: this.formInline[key],
                        op: 'LIKE',
                    });
                } else {
                    wheres.push({
                        field: key,
                        value: this.formInline[key],
                    });
                }
            }
            return {
                current: this.page.currentPage,
                size: this.page.pageSize,
                wheres: wheres,
            };
        },

        getMetaCommonListExport() {
            this.downLoading = true;
            let obj = this.getSearch();
            getMetaCommonListExport(this.view_model, obj)
                .then(res => {
                    this.downLoading = false;
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.download = '房屋详情' + '.xls';
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(e => {
                    this.downLoading = false;
                });
        },
        async getroomCommonList(obj) {
            getMetaCommonList(this.view_model, obj).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.titleclazz {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.configInput {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 80px;
    margin: 0px 5px;
}
.card {
    padding: 0px 10px 10px 0px;
}
ul,
li {
    list-style: none;
    margin-bottom: 5px;
}
.spaceCard {
    display: inline-block;
    min-width: 100px;
    height: 105px;
}
.cardBg {
    background: #f5f7fa;
}
.spaceCard {
    &:hover {
        .addbtn {
            display: block;
        }
    }
}
.addbtn {
    border: 1px dotted #1976d3;
    padding: 5px 10px;
    color: #1976d3;
    margin-bottom: 10px;
    border-radius: 0px;
}
.roomClazz {
    white-space: nowrap;
    font-size: 0px;
    overflow-x: auto;
    width: 100%;
}
/deep/ .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 20px;
}
.b-b {
    border-bottom: 1px solid #dcdfe6;
}

.dropclazz {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 3px 5px 3px 0px;
    transform: rotate(-90deg);
}
.p-r {
    position: relative;
}
/deep/ .el-drawer__header {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 20px;
    margin-bottom: 0px;
}
/deep/ .el-drawer__body {
    padding: 0px;
}
/deep/.el-dialog__body {
    height: calc(80vh - 110px);
    overflow: auto;
    padding: 15px;
}

.cz-pos {
    position: absolute;
    top: 10px;
    right: 20px;
    opacity: 0.3;
}
</style>

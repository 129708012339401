<template>
  <el-row class="mar-0">
    <el-col :span="24">
      <avue-dynamic
        style="padding: 0px 10px"
        v-model="operList"
        :children="tableDataOption"
      ></avue-dynamic>
      <small class="cur-p" @click="newROper">
        <i class="el-icon-plus mar-l-10"></i>自定义</small
      >
      <span
        v-if="operFormCustom"
        class="pull-right cur-p mar-l-10"
        @click="operFormCustom = false"
        >取消</span
      >
      <span v-if="operFormCustom" class="pull-right cur-p" @click="closeROper"
        ><i class="el-icon-check"></i>保存</span
      >

      <el-divider v-if="operFormCustom"></el-divider>
      <el-form
        v-if="operFormCustom"
        size="small"
        :v-model="operForm"
        label-position="left"
        label-width="80px"
      >
        <el-form-item label="操作名称">
          <el-input
            style="width: 185px"
            v-model="operForm.operName"
            placeholder="操作名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="操作key">
          <el-input
            style="width: 185px"
            v-model="operForm.phoneHH"
            placeholder="操作Key"
          ></el-input>
        </el-form-item>
        <el-form-item label="适用场景">
          <el-radio-group v-model="operForm.appl">
            <el-radio label="new">新建按钮</el-radio>
            <el-radio label="row">字段操作</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="字段" v-if="operForm.appl == 'row'">
          <el-select v-model="operForm.field" @change="updateFiled()">
            <el-option
              v-for="table in tableOption.column"
              :key="table.prop"
              :label="table.label"
              :value="table.prop"
            />
          </el-select>
        </el-form-item>

        <el-form-item label="执行操作">
          <el-radio-group v-model="operForm.operType">
            <el-radio label="js">js方法</el-radio>
            <el-radio label="dict">关联模块</el-radio>
            <!-- <el-radio label="target">打开新的路由</el-radio> -->
          </el-radio-group>
        </el-form-item>
        <el-form-item label="提示语">
          <el-input
            v-model="operForm.tip"
            placeholder="例如：确定要给改用户拨打电话？"
          ></el-input>
        </el-form-item>
        <div
          class="el-form-item el-form-item--small el-form--label-top"
          v-if="operForm.operType == 'js'"
        >
          <label class="el-form-item__label" style="padding: 0">change：</label>
          <div class="el-form-item__content">
            <monaco-editor
              v-model="operForm.click"
              height="200"
              :options="options"
            ></monaco-editor>
          </div>
        </div>

        <el-tab-pane
          label="远端数据"
          name="remote"
          v-if="operForm.operType == 'dict'"
        >
          网址
          <el-input
            v-model="operForm.dicUrl"
            placeholder="远端请求网址"
          ></el-input>
          远程搜索
          <el-switch v-model="operForm.remote"></el-switch><br />
          请求方法
          <el-select
            v-model="operForm.dicMethod"
            placeholder="请求方法"
            style="width: 100%"
          >
            <el-option label="POST" value="post"></el-option>
            <el-option label="GET" value="get"></el-option>
          </el-select>
          <p v-if="operForm.dicMethod == 'post'">
            请求参数
            <avue-dynamic
              v-model="dicQueryConfig"
              :children="option"
            ></avue-dynamic>
          </p>
        </el-tab-pane>
      </el-form>
    </el-col>
    <el-col :span="24" class="text-center">
      <el-button type="primary" size="small" @click="saveOperBtn()"
        >确 定</el-button
      >
      <el-button size="small" @click="btnEditVisible = false">取 消</el-button>
    </el-col>
  </el-row>
</template>

<script>
import MonacoEditor from "../../../../../../components/dynamic/form/utils/monaco-editor";
import { operBtns } from "../../../../util/option";
export default {
  name: "designBtn",
  watch: {},
  components: { MonacoEditor },
  props: {
    tableOption: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      change: {},
      tableDataOption: {},
      dicQueryConfig: {
        column: [
          { type: "input", prop: "field", label: "key", cell: true },
          {
            type: "input",
            prop: "value",
            label: "value",
            cell: true,
          },
        ],
      },
      options: {
        minimap: {
          enabled: false,
        },
      },
      operForm: {
        appl: "row",
        field: "",
        // click: "({value}) => {\r\n\r\n}",
        click: "\r\n\r\n",
      },
      operFormCustom: false,
      operList: [],
      operBtnList: operBtns,
    };
  },

  created() {},
  mounted() {
    this.tableDataOption = {
      column: [
        {
          type: "select",
          prop: "key",
          label: "操作",
          cell: true,
          dicData: this.operBtnList,
          props: {
            label: "operName",
            value: "key",
          },
        },
        {
          type: "select",
          prop: "appl",
          label: "类型",
          cell: true,
          dicData: [
            { label: "行内", value: "row" },
            { label: "新增按钮", value: "new" },
          ],
          props: {
            label: "label",
            value: "value",
          },
        },
        {
          type: "select",
          prop: "field",
          label: "字段",
          cell: true,
          dicData: this.tableOption.column,
          props: {
            label: "label",
            value: "prop",
          },
        },
      ],
    };
  },

  methods: {
    updateFiled() {},
    newROper() {
      this.operForm = {
        appl: "row",
        field: "",
        click: "({value}) => {\r\n\r\n}",
      };
      this.operBtnList = operBtns;
      this.operFormCustom = true;
    },
    closeROper() {
      this.operList.push(this.operForm);
      this.operBtnList.push(this.operForm);
      this.operFormCustom = false;
    },
    saveOperBtn() {
      this.$emit("tableOperBtn", this.operList);
      //this.operFormCustom = false;
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped></style>

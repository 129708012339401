<template>
  <div class="h-100p">
    <div class="h-40 lh-40">
      <el-menu class="el-menu-demo" mode="horizontal" @select="handleSelect">
        <el-submenu index="2">
          <template slot="title">
            <el-button
              class="el-icon-plus"
              size="small"
              type="primary"
              @click="addWidgetDrawer = true"
            >
              创建</el-button
            ></template
          >
          <el-submenu index="2-1">
            <template slot="title">表格</template>
            <el-menu-item index="2-4-1">表单</el-menu-item>
            <el-menu-item index="2-4-2">模块2</el-menu-item>
          </el-submenu>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>
<script>
// import design from "./design";
// import preview from "./preview";
import { mapGetters, mapState } from "vuex";

export default {
  name: "tableDesign",
  components: {},
  data() {
    return {
      app: {
        appId: "",
        id: "",
      },
    };
  },
  created() {
    this.app = {
      appId: this.$route.query.appId,
      id: this.$route.query.id,
    };
  },
  mounted() {},
  computed: {
    ...mapState({
      viewEdit: (state) => state.common.viewEdit,
    }),
  },
  methods: {},
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
/deep/.el-submenu__title {
  height: auto important !important;
  line-height: 33px !important;
  .el-submenu__icon-arrow {
    display: none;
  }
}
</style>

<template>
  <div>
    <el-card>
      <div>{{ $route.query.day }}: {{ detailData.length }}个预定</div>
      <ul class="dialogUl">
        <li v-for="(item, index) in times" :key="index" class="timeItem">
          <span class="time">{{ item }}</span>
          <div class="contentTime">
            <span
              v-for="(itemtime, ind) in detailData"
              class="contentTimeItem"
              :key="itemtime.id"
            >
              <span v-if="itemtime.times.includes(item)" :class="'item' + ind">
                <!-- <span v-for="indexitem in ind" :key="indexitem">空</span> -->
                <span @click="checkTimeHandle(itemtime)" class="groundName">{{
                  itemtime.groundName
                }}</span>
              </span>

            </span>
          </div>
        </li>
      </ul>
    </el-card>

      <el-dialog title="订单详情" :visible.sync="dialogFormVisible">
        <el-form v-model="form">

          <el-form-item label="groundId" :label-width="formLabelWidth">
            <el-input type="text" v-model="form.groundId" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="场馆" :label-width="formLabelWidth">
            <el-input v-model="form.stadiumName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="场地" :label-width="formLabelWidth">
            <el-input v-model="form.groundName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="类型" :label-width="formLabelWidth">
            <el-input v-model="form.sportType" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="订单时间" :label-width="formLabelWidth">
            <el-input v-model="form.apptTime" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="开始时间" :label-width="formLabelWidth">
            <el-input v-model="form.beginTime" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="结束时间" :label-width="formLabelWidth">
            <el-input v-model="form.endTime" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="订单备注" :label-width="formLabelWidth">
            <el-input v-model="form.extraMemo" autocomplete="off"></el-input>
          </el-form-item>

        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="dialogFormVisible = false">确 定</el-button>
        </div>
      </el-dialog>

  </div>
</template>

  <script>
import {
  getList,
  getDetail,
  add,
  update,
  remove,
  appointStatis,
  appointInfoList
} from "@/api/spcod/sportappoint";
export default {
  filters: {
    getDay(value) {
      const day = value.split("-")[2];
      return day.startsWith("0") ? day.substr(1) : day;
    },
  },
  data() {
    return {
      dialog:false,
      currentDay: null, // 当前时间
      times: [],
      detailData: [],
      type: false,
      dialogFormVisible: false,
      form: {
        groundId: '',
        stadiumName: '',
        groundName: '',
        sportType: '',
        apptTime: '',
        groundId: '',
        beginTime: '',
        endTime: '',
        extraMemo: ''
        },
      formLabelWidth: '120px'
    };
  },
  created() {
    // 获取上个页面传过来的值
    this.currentDay = this.$router.currentRoute.query.day;
    // 钩子函数执行完成之后
    this.times = [
      "08:00",
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
      "22:00",
    ];
    this.getDetail();
  },
  methods: {
    checkTimeHandle(item) {
      console.log("详情数据", item);
      console.log("详情数据", item.id);
      this.dialogFormVisible = true
      this.form = item,
      console.log('this.form',this.form)
      console.log('this.form',this.form.id)
    },
    getDetail() {
      this.detailData = [
         {
           id: 666,
           beginTime: "10:00",
           endTime: "12:00",
           stadiumName: "1号馆",
           groundName: "羽毛球场地",
           sportType: "羽毛球",
           contactName: "李四",
           contactPhone: "8888888888",
           memo: "准备好水1111准备好水2222准备好水3333准备好水4444准备好水5555",
         },
         {
           id: 123,
           beginTime: "14:00",
           endTime: "16:00",
           stadiumName: "1号馆",
           groundName: "乒乓球场地",
           sportType: "乒乓球",
           contactName: "张山",
           contactPhone: "123456788",
           memo: "请清理场地1111请清理场地2222请清理场地3333请清理场地4444请清理场地5555",
         },
         {
           id: 124,
           beginTime: "16:00",
           endTime: "20:00",
           stadiumName: "1号馆",
           groundName: "乒乓球场地1111",
           sportType: "乒乓球",
           contactName: "张山",
           contactPhone: "123456788",
           memo: "请清理场地1111请清理场地2222请清理场地3333请清理场地4444请清理场地5555",
         },
       ];

      appointInfoList(this.currentDay).then(res=>{
        this.detailData = res.data.data;
        console.log(res.data.data);
        this.detailData.forEach((i) => {
          var time = '';
          let begin = i.beginTime.split(' ')[1].split(':')[0];
          let end = i.endTime.split(' ')[1].split(':')[0];
          console.log('begin-end:',begin,end);
          for(var j=begin;j<end;j++) {
            time = time + `,${j}:00`
            i.times = time;
          }
        })
      });
    },
  },
};
</script>

  <style lang="scss" scoped>
div /deep/ .el-calendar-day {
  height: auto;
}
div /deep/ .el-calendar-table__row td,
div /deep/ .el-calendar-table tr td:first-child,
div /deep/ .el-calendar-table__row td.prev {
  border: none;
}
.date-content {
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
}
.date-content .rest {
  color: #fff;
  border-radius: 50%;
  background: rgb(250, 124, 77);
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  font-size: 12px;
  margin-left: 10px;
}
.date-content .work {
  color: #fff;
  border-radius: 50%;
  background: rgb(91, 77, 250);
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  font-size: 12px;
  margin-left: 10px;
}
.date-content .text {
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
}
div /deep/ .el-calendar-table td.is-selected .text {
  background: #409eff;
  color: #fff;
  border-radius: 50%;
}
div /deep/ .el-calendar__header {
  display: none;
}
.dialogUl {
  li {
    list-style: none;
    display: flex;
    align-items: flex-start;
    .time {
      display: inline-block;
      width: 4rem;
      margin-top: -5px;
    }
    .contentTime {
      display: flex;
      align-items: center;
      flex: 1;
      height: 30px;
      border-top: 1px solid #cfcfcf;
      border-left: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      .contentTimeItem {
        flex: 1;
        .groundName {
          display: inline-block;
          height: 30px;
          line-height: 30px;
          width: 100%;
          text-align: center;
          color: #fff;
          background: #409eff;
          border-bottom: 1px solid #fff;
          cursor: pointer;
        }
      }
    }
    &:last-child {
      .contentTime {
        border-bottom: 1px solid #cfcfcf;
      }
    }
  }
}
</style>

<template>
  <div class="process_manage">
    <el-card>
      <el-row :gutter="10">
        <el-col :col="24" class="mar-t-15f lh-50">
          <span class="fts-18 mar-r-10">{{ tableName }}</span>
          <el-button type="text" @click="modelTreeVisible = true"
            >选择模型</el-button
          >
        </el-col>
      </el-row>
      <el-table
        :data="modelData"
        :class="EditOnoff ? 'tb-edit' : 'tb-edit2'"
        size="small"
        v-loading="isLoading"
        height="calc(100vh - 264px)"
        highlight-current-row
        element-loading-text="正在加载中..."
        row-key="guid"
        class="dataTable"
      >
        <el-table-column
          label=""
          width="50"
          align="center"
          :show-overflow-tooltip="true"
          v-if="menuDo != 'showDetail'"
        >
          <div class="text-center move" width="50">
            <i class="el-icon-rank"></i>
          </div>
        </el-table-column>
        <el-table-column
          prop="columnName"
          label="字段名"
          width="150"
          :show-overflow-tooltip="true"
        ></el-table-column>

        <el-table-column label="类型" width="150" :show-overflow-tooltip="true">
          <template scope="scope">
            <span>{{ scope.row.type }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="中文名"
          width="200"
          :show-overflow-tooltip="true"
        >
          <template scope="scope">
            <el-input
              v-if="menuDo != 'showDetail'"
              size="small"
              v-model="scope.row.description"
              placeholder="中文名"
            ></el-input>
            <span v-if="menuDo == 'showDetail'">
              {{ scope.row.description }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="默认值"
          width="160"
          :show-overflow-tooltip="true"
        >
          <template scope="scope">
            <el-select
              v-if="menuDo != 'showDetail'"
              v-model="scope.row.defaultValue"
              size="small"
              filterable
              clearable
              placeholder="请选择默认值"
            >
              <el-option
                v-for="item in globalValues"
                :key="item.code"
                :label="item.desc"
                :value="item.code"
              >
              </el-option>
            </el-select>
            <span v-if="menuDo == 'showDetail'">
              {{ scope.row.description }}
            </span>
          </template>
        </el-table-column>

        <el-table-column label="是否可编辑" width="100" align="center">
          <template scope="scope">
            <el-checkbox v-model="scope.row.isEdit"></el-checkbox>
            <!-- <span v-if="menuDo == 'showDetail'">
              {{ scope.row.isEdit }}
            </span> -->
          </template>
        </el-table-column>
        <el-table-column label="表格显示列" width="100" align="center">
          <template scope="scope">
            <el-checkbox v-model="scope.row.tableShow"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column label="是否查询条件" width="100" align="center">
          <template scope="scope">
            <el-checkbox v-model="scope.row.IsSearch"></el-checkbox>
          </template>
        </el-table-column>
        <el-table-column
          label="关联模型"
          :show-overflow-tooltip="true"
          width="150"
        >
          <template scope="scope">
            <el-select
              v-if="menuDo != 'showDetail'"
              v-model="scope.row.model"
              size="small"
              filterable
              clearable
              @change="changeVal(scope.row, scope.$index)"
              placeholder="请选择模型"
            >
              <el-option
                v-for="item in modelForTableList"
                :key="item.tableName"
                :label="item.description"
                :value="item.tableName"
              >
              </el-option>
            </el-select>
            <span v-if="menuDo == 'showDetail'">
              {{ scope.row.model }}
            </span>
          </template>
        </el-table-column>

        <el-table-column
          label="显示字段"
          :show-overflow-tooltip="true"
          width="150"
        >
          <template scope="scope">
            <el-select
              v-if="menuDo != 'showDetail'"
              v-model="scope.row.showFiled"
              @focus="getFiles(scope.row, scope.$index)"
              size="small"
              filterable
              clearable
              placeholder="请选择字段"
            >
              <el-option
                v-for="item in tableFifleds"
                :key="item.columnName"
                :label="item.description"
                :value="item.columnName"
              >
              </el-option>
            </el-select>
            <span v-if="menuDo == 'showDetail'">
              {{ scope.row.showFiled }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="关联字段"
          :show-overflow-tooltip="true"
          width="150"
        >
          <template scope="scope">
            <el-select
              v-if="menuDo != 'showDetail'"
              v-model="scope.row.fields"
              size="small"
              filterable
              clearable
              placeholder="请选择字段"
            >
              <el-option
                v-for="item in tableFifleds"
                :key="item.columnName"
                :label="item.description"
                :value="item.columnName"
              >
              </el-option>
            </el-select>
            <span v-if="menuDo == 'showDetail'">
              {{ scope.row.fields }}
            </span>
          </template>
        </el-table-column>
        <el-table-column
          label="回填字段"
          :show-overflow-tooltip="true"
          width="150"
        >
          <template scope="scope">
            <el-select
              v-if="menuDo != 'showDetail'"
              v-model="scope.row.backfill"
              size="small"
              filterable
              clearable
              placeholder="请选择回填字段"
            >
              <el-option
                v-for="item in tableFifleds"
                :key="item.description"
                :label="item.description"
                :value="item.columnName"
              >
              </el-option>
            </el-select>
            <span v-if="menuDo == 'showDetail'">
              {{ scope.row.backfill }}
            </span>
          </template>
        </el-table-column>
      </el-table>
    </el-card>

    <el-dialog
      title="选择数据模型"
      width="60%"
      append-to-body
      destroy-on-close
      :close-on-click-modal="false"
      :visible.sync="modelTreeVisible"
    >
      <modelTree @selectedNode="selectedNode" ref="modelTree"></modelTree>
    </el-dialog>
  </div>
</template>

<script>
import {
  getColumnsByTable,
  getGlobalVariable,
  saveModelBuild,
  updateModelBuild,
  getModelDetail,
} from "@/api/busiMode/meta";
import { findTableByDatasourceid } from "@/api/dataService/appModel";
import Draggable from "vuedraggable";
import modelTree from "./modelsTree";
import Sortable from "sortablejs";
export default {
  name: "data_design",
  components: { Draggable, modelTree, Sortable },
  data() {
    return {
      modelTreeVisible: false,
      seach_onoff: false, //搜索显示
      seach_val: "", //搜索类容
      currentPage: 1, //分页中当前第几页
      count: 0, //分页数据总条数
      modelData: [],
      tableName: "",
      //编辑
      EditOnoff: true, //是否可编辑
      EditBefore: {}, //编辑之前的数据
      isFinish: false,
      modelForTableList: [],
      tableFifleds: [],
      globalValues: [],
      isLoading: false,
      modelBuild: {},
      guid: "",
      dataSoureObj: {},
      tableInfo: {},
      filterList: [
        "create_time",
        "create_date",
        "create_user",
        "create_user_name",
        "update_user",
        "update_user_name",
        "tenant_id",
        "update_time",
        "is_deleted",
      ],
    };
  },
  props: {
    entityId: {
      type: String,
      default: function () {
        return "";
      },
    },
    menuDo: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  watch: {
    entityId: {
      handler: function (newValue, oldName) {
        if (newValue) {
          this.guid = this.entityId;
          this.dataSoureObj.code = this.entityId.split(".")[0];
          this.tableInfo.tableName = this.entityId.split(".")[1];
          if (this.dataSoureObj.code) {
            this.findTableByDatasourceid(this.dataSoureObj.code, true);
          }
          this.getColumnsByTable();
        }
      },
      immediate: true,
      deep: true,
    },
    dataSoure: {
      handler: function (newValue, oldName) {
        if (newValue) {
          if (this.dataSoure.code) {
            this.findTableByDatasourceid(this.dataSoure.code, true);
          }
        }
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    //   if (!this.modelId) {
    //     this.modelId = this.$route.fullPath.split(
    //       "/data/table/tableCommon/?modleId"
    //     )[1];
    //   }
    this.getGlobalVariable();
    this.rowDrop();
  },
  mounted() {},
  methods: {
    initDesign() {
      let o = {
        datasourceCode: this.dataSoureObj.code,
        tableName: this.tableInfo.tableName,
        datasourceType: 0,
        // datasourceType: this.dataSoureObj.type,
      };
      if (this.dataSoureObj.code) {
        getColumnsByTable(o).then((res) => {
          if (res.data.code == "200") {
            let modelList = [];
            res.data.data.columnVOList.forEach((e) => {
              if (!this.filterList.includes(e.columnName)) {
                let o = e;
                e.isEdit = true;
                e.defaultValue = "";
                e.tableShow = true;
                e.IsSearch = false;
                e.model = "";
                e.fields = "";
                modelList.push(o);
              }
            });
            this.tableName =
              res.data.data.description +
              " ( " +
              res.data.data.tableName +
              " )";
            this.modelData = modelList;
          }
        });
      }
    },
    rowDrop() {
      const tbody = document.querySelector(".el-table__body-wrapper tbody");
      const _this = this;
      Sortable.create(tbody, {
        handle: ".move",
        animation: 150,
        chosenClass: "cur-m", // 被选中项的css 类名
        dragClass: "cur-m", // 正在被拖拽中的css类名
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.modelData.splice(oldIndex, 1)[0];
          _this.modelData.splice(newIndex, 0, currRow);
        },
      });
    },
    getFiles(row, index) {
      if (this.tableFifleds.length == 0) {
        this.changeVal(row);
      }
    },
    getGlobalVariable() {
      getGlobalVariable().then((res) => {
        if (res.data.code == "200") {
          this.globalValues = res.data.data;
        }
      });
    },

    selectedNode(data) {
      if (data[1]) {
        this.dataSoureObj = data[0];
        this.tableInfo = data[1];
        this.modelForTableList = data[2];
        this.getColumnsByTable();
        this.modelTreeVisible = false;
        this.$emit("modelChoose", data);
      }
    },

    getColumnsByTable() {
      this.isLoading = true;
      let o = {
        datasourceCode: this.dataSoureObj.code,
        tableName: this.tableInfo.tableName,
        datasourceType: 0,
        // datasourceType: this.dataSoureObj.type,
      };
      if (this.dataSoureObj.code) {
        getColumnsByTable(o).then((res) => {
          if (res.data.code == "200") {
            let modelList = [];
            res.data.data.columnVOList.forEach((e) => {
              if (!this.filterList.includes(e.columnName)) {
                let o = e;
                e.isEdit = true;
                e.defaultValue = "";
                e.tableShow = true;
                e.IsSearch = false;
                e.model = "";
                e.fields = "";
                modelList.push(o);
              }
            });
            this.tableName =
              res.data.data.description +
              " ( " +
              res.data.data.tableName +
              " )";
            if (!this.guid) {
              this.guid =
                this.dataSoureObj.code + "." + res.data.data.tableName;
            }
            if (this.guid) {
              this.getModelDetail(modelList);
            } else {
              this.isLoading = false;
              this.modelData = modelList;
            }
          }
        });
      }
    },
    getModelDetail(modelList) {
      let obj = { guid: this.guid };
      getModelDetail(obj)
        .then((res) => {
          if (res.data.code == 200) {
            this.modelData = [];
            this.modelBuild = res.data.data;
            let buildJson = JSON.parse(res.data.data.buildJson);
            modelList.forEach((model) => {
              let m = {};

              buildJson.forEach((build) => {
                if (model.guid == build.guid) {
                  m = Object.assign(
                    JSON.parse(JSON.stringify(model)),
                    JSON.parse(JSON.stringify(build))
                  );
                  m.columnName = JSON.parse(JSON.stringify(model)).columnName;
                  m.type = JSON.parse(JSON.stringify(model)).type;
                  m.dictionaryCode = JSON.parse(
                    JSON.stringify(model)
                  ).dictionaryCode;
                }
              });
              if (JSON.stringify(m) == "{}") {
                m = model;
              }
              this.modelData.push(m);
            });
          }
          this.isLoading = false;
        })
        .catch((err) => {
          this.modelData = modelList;
          this.isLoading = false;
        });
    },
    findTableByDatasourceid(code, isSimple) {
      let o = {
        datasourceType: 0,
        datasourceCode: code,
      };
      if (code) {
        findTableByDatasourceid(o)
          .then((res) => {
            this.modelForTableList = res["data"]["data"] || [];
          })
          .catch((e) => {});
      }
    },
    changeVal(item, index) {
      if (item.model && this.dataSoureObj.code) {
        let o = {
          datasourceCode: this.dataSoureObj.code,
          tableName: item.model,
          datasourceType: 0,
          // datasourceType: this.dataSoureObj.type,
        };
        getColumnsByTable(o).then((res) => {
          let tables = [];

          res.data.data.columnVOList.forEach((e) => {
            if (!this.filterList.includes(e.columnName)) {
              tables.push(e);
            }
          });
          this.tableFifleds = tables;
        });
      }
    },
    saveModelBuild() {
      if (this.modelBuild.id) {
        this.modelBuild.buildJson = JSON.stringify(this.modelData);
        updateModelBuild(this.modelBuild)
          .then((res) => {
            if (res.data.code == 200) {
              this.$message.success("保存成功");
            }
            this.$parent.$parent.$parent.btnLoading = false;
          })
          .catch((err) => {
            this.$parent.$parent.$parent.btnLoading = false;
          });
      } else {
        let obj = {
          buildJson: JSON.stringify(this.modelData),
          guid: this.dataSoureObj.code + "." + this.tableInfo.tableName,
        };
        saveModelBuild(obj)
          .then((res) => {
            if (res.data.code == 200) {
              this.modelBuild = res.data.data;
              this.$message.success("保存成功");
            }
            this.$parent.$parent.$parent.btnLoading = false;
          })
          .catch((err) => {
            this.$parent.$parent.$parent.btnLoading = false;
          });
      }
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
.lz-el-col {
  width: 200px;
  margin-bottom: 10px;
}
.el-table .lz-fontsize-weidth {
  font-weight: bold;
}
.el-table .lz-fontsize {
  font-weight: normal;
  color: #888;
}
//搜索动画显示
.lz-seach {
  max-height: 0px;
  margin-bottom: 0px;
  overflow: hidden;
}
.lz-seach-animation {
  animation: lzmover 0.6s ease-in-out forwards;
  display: block;
  overflow: hidden;
}
@keyframes lzmover {
  0% {
    height: 0px;
    opacity: 0;
    margin-bottom: 0px;
  }
  100% {
    height: 100%;
    opacity: 1;
    margin-bottom: 10px;
  }
}
/deep/ .dataTable {
  &.el-table {
    tbody {
      tr .move {
        cursor: move;
      }
      .el-radio__label {
        font-size: 14px;
        padding-left: 5px;
      }
    }
  }
}
.h-data {
  height: calc(100vh - 274px);
}
/deep/ .el-scrollbar__wrap {
  width: 100%;
}
/deep/ .el-scrollbar__bar.is-vertical {
  display: none;
}
</style>

<template>
  <el-table :data="tableData" v-bind="tableProps">
    <el-table-column v-for="column of columns" :label="column.name" :prop="column.prop" :width="column.width || 'auto'"></el-table-column>
  </el-table>
</template>
<script>

import WidgetMixins from '../widget-mixins'

import { assign , getStyleByObject } from '../../util';

export default {
  mixins: [ WidgetMixins ],
  name: 'BuiltInWidgetByTable',
  data(){
    return {
      columns: [],
      tableData: [],
      tableProps: null
    }
  },
  mounted(){

  },
  methods: {
    refresh(type){
      if(this.widget){
        if(['widget.private' , 'widget.dataOption' , 'data'].includes(type)){
          if(type === 'data'){
            this.setData();
          }else{
            this.setTable();
          }
        }
      }
    },
    setData(){
      this.tableData = this.data || [];
    },
    setTable(){
      if(!this.widget.dataOption || !this.widget.private){
        return;
      }
      const { columns } = this.widget.dataOption;
      this.columns = [];
      this.tableProps = this.widget.private.tableProps || null;
      columns.forEach(
        item => {
          this.columns.push({
            name: item.name,
            prop: item.value
          })
        }
      )
    }
  }
}
</script>
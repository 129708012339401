<template>
  <div class="lh-54 text-right pad-r-30">
    <el-switch
      v-model="value1"
      active-text=""
      inactive-text="存储节点协调">
    </el-switch>
    <el-switch
      v-model="value2"
      active-text=""
      inactive-text="节点状态同步">
    </el-switch>
  </div>
</template>
<script>
  export default {
    data(){
      return {
        value1:true,
        value2:true
      }
    }
  }
</script>

import { render, staticRenderFns } from "./datashareView.vue?vue&type=template&id=ae641456&scoped=true"
import script from "./datashareView.vue?vue&type=script&lang=js"
export * from "./datashareView.vue?vue&type=script&lang=js"
import style0 from "./datashareView.vue?vue&type=style&index=0&id=ae641456&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ae641456",
  null
  
)

export default component.exports
<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" v-model="form" :page.sync="page" :permission="permissionList" @selection-change="selectionChange" @row-del="rowDel"
                   @row-update="rowUpdate" @row-save="rowSave" @refresh-change="refreshChange" @on-load="onLoad">
            <template slot="menuLeft" slot-scope="scope">
                <template v-if="website.permission">
                    <el-button type="primary" size="small" icon="el-icon-plus" v-if="permission.buildList_add" @click="openDislog('add')">新增楼宇
                    </el-button>
                </template>
                <template v-else>
                    <el-button type="primary" size="small" icon="el-icon-plus" @click="openDislog('add')">新增楼宇
                    </el-button>
                </template>
                <el-input v-model="searchText" @keyup.native.enter="refreshChange" size="small" placeholder="请输入楼宇名称" type="text" style="width: 250px">
                    <el-button class="mar-0 pad-0" size="small" @click="refreshChange" slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </template>
            <template slot-scope="scope" slot="menu">
                <template v-if="website.permission">
                    <el-button type="text" size="small" class="none-border" icon="el-icon-view" v-if="permission.buildList_detail" @click="openDislog('detail', scope.row)">楼宇详情
                    </el-button>
                    <el-button type="text" size="small" class="none-border" icon="el-icon-edit" v-if="permission.buildList_space" @click="openDislog('space', scope.row)">空间管理
                    </el-button>
                    <el-button type="text" size="small" icon="el-icon-delete" class="none-border" v-if="permission.buildList_delete" @click="rowDel(scope.row)">删除
                    </el-button>
                    <el-button type="text" size="small" class="none-border" icon="el-icon-document" @click="assetsManage(scope.row)">资产明细
                    </el-button>
                </template>
                <template v-else>
                    <el-button type="text" size="small" class="none-border" icon="el-icon-view" @click="openDislog('detail', scope.row)">楼宇详情
                    </el-button>
                    <el-button type="text" size="small" class="none-border" icon="el-icon-edit" @click="openDislog('space', scope.row)">空间管理
                    </el-button>
                    <el-button type="text" size="small" class="none-border" icon="el-icon-document" @click="assetsManage( scope.row)">资产明细
                    </el-button>

                    <el-button type="text" size="small" icon="el-icon-delete" class="none-border" @click="rowDel(scope.row)">删除
                    </el-button>
                </template>
            </template>
        </avue-crud>
        <el-dialog :size="size" :visible.sync="dialogVisible" destroy-on-close :with-header="false" :close-on-click-modal="false" :top="'8vh'" :body-style="{ padding: '0px' }" width="70%"
                   class="buildDetail">
            <template v-if="dialogVisible">
                <div slot="title" v-if="dialogType != 'space'" class="header-title">
                    <span class="fts-16">新增楼宇</span>
                </div>
                <div>
                    <addBuild v-if="dialogType == 'add'" :ruleModel="ruleModel" @close="closeDia"></addBuild>
                    <space v-if="dialogType == 'space'"></space>
                </div>
            </template>
        </el-dialog>
        <el-drawer :visible.sync="detailDia" size="60%" :withHeader="false" :close-on-click-modal="false">
            <!-- <div slot="title" class="header-title">
        <span class="fts-16">{{ title }}</span>
      </div> -->
            <el-header class="bor-b" height="50px">
                <el-page-header class="lh-50" @back="back" :content="title"></el-page-header>
            </el-header>
            <detail :currentRow="currentRow" @changName="changName"></detail>
        </el-drawer>
    </basic-container>
</template>

<script>
import { getMetaCommonList, delMetaCommon, saveMetaCommon, updateMetaCommon } from '@/api/busiMode/metaCommon';
import standardService from '@/api/metadata/standard';
import { mapGetters } from 'vuex';
import addBuild from './addBuild';
import space from './space';
import detail from './buildDetail';
import website from '@/config/website';

export default {
    components: { addBuild, space, detail },
    data() {
        return {
            searchText: '',
            form: {},
            currentRow: {},
            selectionList: [],
            loading: true,
            dialogVisible: false,
            detailDia: false,
            query: {},
            dialogType: '',
            size: '60%',
            title: '',
            page: {
                size: 10,
                current: 1,
                total: 0,
            },
            option: {
                calcHeight: 30,
                dialogWidth: 900,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: true,
                // selection: true,
                labelWidth: 120,
                menuWidth: 250,
                viewBtn: false,
                dialogClickModal: false,
                delBtn: false,
                editBtn: false,
                addBtn: false,
                cancelBtn: false,
                column: [
                    {
                        width: 200,
                        overHidden: true,
                        align: 'left',
                        label: '楼宇名称',
                        prop: 'name',
                        hide: false,
                    },
                    {
                        width: 120,
                        overHidden: true,
                        align: 'left',
                        label: '楼宇编号',
                        prop: 'code',
                        hide: false,
                    },

                    {
                        width: 80,
                        overHidden: true,
                        label: '层高',
                        prop: 'floor_height',
                        dicData: [
                            {
                                value: '1',
                                label: '1～3米',
                            },
                            {
                                value: '2',
                                label: '3～4米',
                            },
                            {
                                value: '3',
                                label: '4～5米',
                            },
                            {
                                value: '4',
                                label: '5米以上',
                            },
                        ],
                    },
                    {
                        width: 80,
                        overHidden: true,
                        label: '层数',
                        prop: 'floor_num',
                    },

                    {
                        width: 120,
                        overHidden: true,
                        label: '物业管理费(m²/月)',
                        prop: 'property_management_fee',
                        hide: false,
                    },
                    {
                        width: 100,
                        overHidden: true,
                        label: '建筑面积(m²)',
                        prop: 'floorage',
                        hide: false,
                    },

                    {
                        width: 100,
                        overHidden: true,
                        label: '租赁方式',
                        prop: 'lease_type',
                        hide: false,
                    },
                    {
                        overHidden: true,
                        align: 'left',
                        label: '楼宇地址',
                        prop: 'address',
                    },
                ],
            },
            model: 'wisdom_park.wp_building',
            data: [],
            options: [
                {
                    value: '1',
                    label: '1～3米',
                },
                {
                    value: '2',
                    label: '3～4米',
                },
                {
                    value: '3',
                    label: '4～5米',
                },
                {
                    value: '4',
                    label: '5米以上',
                },
            ],
            ruleModel: {},
        };
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.buildList_add, !website.permission),
                viewBtn: this.vaildData(this.permission.buildList_view, !website.permission),
                delBtn: this.vaildData(this.permission.buildList_delete, !website.permission),
                editBtn: this.vaildData(this.permission.buildList_edit, !website.permission),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    mounted() {
        this.queryByModelCode();
    },
    methods: {
        assetsManage(row) {
            this.$router.push({
                path: '/assets/manage',
                query: {
                    location_build_id: row.id,
                },
            });
        },
        back() {
            this.detailDia = false;
            this.searchReset();
        },
        queryByModelCode() {
            standardService.getModelRule(this.model).then(res => {
                this.ruleModel = res;
            });
        },
        openDislog(type, row) {
            this.dialogType = type;
            //this.size = type == "space" ? "100%" : "60%";
            if (type == 'detail') {
                this.currentRow = row;
                this.title = row.name;
                this.detailDia = true;
            } else if (type == 'space') {
                //跳转
                this.$router.push({
                    path: '/build/space',
                    query: {
                        buildId: row.id,
                        buildName: row.name,
                    },
                });
            } else {
                this.dialogVisible = true;
            }
        },
        changName(row) {
            this.title = row.name;
        },
        handleBuild() {},
        rowSave(row, done, loading) {
            add(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    window.console.log(error);
                    loading();
                },
            );
        },
        rowUpdate(row, index, done, loading) {
            update(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    window.console.log(error);
                    loading();
                },
            );
        },
        rowDel(row, index) {
            console.log(row);
            this.$confirm('是否删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let pk = 'id';
                    delMetaCommon(this.model, { pk: pk, ids: [row[pk]] }).then(res => {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        this.onLoad(this.page);
                    });
                })
                .catch(() => {});
        },
        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },

        selectionChange(list) {
            this.selectionList = list;
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning('请选择至少一条数据');
                return;
            }
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let pk = 'id';
                    let ids = [];
                    this.selectionList.forEach(e => {
                        if (e.id) {
                            ids.push(e.id);
                        }
                    });
                    delMetaCommon(this.model, { pk: pk, ids: ids }).then(res => {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        this.data.splice(index, 1);
                    });
                })
                .then(() => {
                    this.onLoad(this.page);

                    this.$refs.crud.toggleSelection();
                });
        },

        refreshChange() {
            this.onLoad(this.page);
        },
        closeDia() {
            this.dialogVisible = false;
            this.refreshChange();
        },
        onLoad() {
            this.loading = true;
            let body = {
                current: this.page.currentPage,
                size: this.page.pageSize,
                orders: [
                    {
                        field: 'sort',
                        sort: 'ASC',
                    },
                ],
            };
            if (this.searchText) {
                body['wheres'] = [{ field: 'name', op: 'LIKE', value: this.searchText }];
            }
            getMetaCommonList(this.model, body).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                data.records.forEach(e => {
                    this.options.forEach(o => {
                        if (e.hight == o.value) {
                            e.hight = o.label;
                        }
                    });
                });
                this.loading = false;
                this.selectionClear();
            });
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.deleteIcon {
    font-size: 18px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 1;
}
/deep/ .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 15px;
}
/deep/ .el-drawer__header {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 20px;
    margin-bottom: 0px;
}
/deep/ .el-drawer__body {
    padding: 0px;
}
.buildDetail /deep/ .el-dialog__body {
    padding: 0px;
}
</style>

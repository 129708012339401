<template>
  <div class="container designer form-designer-page">
    <el-row>
      <el-col :span="24">
        <avue-form-design
          :newOpenType="newOpenType"
          ref="formDesign"
          style="height: calc(100vh - 185px)"
          :options="options"
          @submit="handleSubmit"
          storage
          show-avue-doc
        >
        </avue-form-design>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import draggable from "vuedraggable";

export default {
  name: "newDesigner",
  components: {
    draggable,
  },
  props: {
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
    newOpenType: {
      type: String,
      default: function () {
        return "dialog"; //"target"
      },
    },
  },

  watch: {
    options: {
      handler(newName, oldName) {},
      immediate: true,
      deep: true,
    },
  },
  data() {
    return {
      //newOpenType: "dialog", //"target"
    };
  },
  mounted() {},
  methods: {
    handleSubmit(data) {
      this.options = data;
    },
  },
};
</script>

<style lang="scss" scoped></style>

<template>
  <basic-container>
    <avue-crud
      ref="crud"
      :table-loading="tableLoading"
      :data="tableData"
      :page.sync="page"
      :option="tableOption"
      @on-load="onLoad"
      @refresh-change="onLoad"
    >
      <template slot="menuLeft">
        <el-button type="primary" size="small" @click="() => {dialogVisible = true}">开始执行</el-button>
        <el-button type="primary" size="small">继续</el-button>
      </template>
      <template slot="endTime" slot-scope="{row}">
        <div v-if="row.state === 'running'">
          <el-progress :percentage="row.percentage" :show-text="false"></el-progress>
        </div>
        <div v-else>{{row.endTime}}</div>
      </template>
      <template slot="state" slot-scope="{row}">
        <div :class="states[row.state].class">
          <i :class="states[row.state].icon"></i>{{states[row.state].text}}
        </div>
      </template>
      <template slot="menu" slot-scope="{row}">
        <el-button v-if="row.state === 'faild'" type="text" size="small" icon="el-icon-view">查看失败原因</el-button>
      </template>
    </avue-crud>
    <el-dialog
      title="执行"
      :visible.sync="dialogVisible"
      append-to-body
      >
      <el-form size="small" ref="form" :model="form" label-width="80px">
        <el-form-item label="源设备">
          <el-select size="small" v-model="form.source" @change="() => {form.target = '';}" placeholder="请选择">
            <el-option
              v-for="item in devices"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="目标设备">
          <el-select size="small" v-model="form.target" placeholder="请选择">
            <el-option
              v-for="item in devices"
              :disabled="item === form.source"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </el-form-item>
        <basic-form-button :loading="formLoading" @submit="submit" @cancel="() => {dialogVisible = false}"></basic-form-button>
      </el-form>
    </el-dialog>
  </basic-container>
</template>
<script>

import { formatDate } from '@/filters/index.js'

let tempData = [
  {
    target: '10.10.100.120' ,
    source: '10.10.100.121' ,
    startTime: formatDate(Date.now() - (24 * 3600 * 1000) - 5000) ,
    endTime: formatDate(Date.now() - (24 * 3600 * 1000) - 3000) ,
    state: 'success',
    percentage: 100
  },
  {
    target: '10.10.100.121' ,
    source: '10.10.100.120' ,
    startTime: formatDate(Date.now() - (4 * 20 * 3600 * 1000) - 5000) ,
    endTime: formatDate(Date.now() - (4 * 20 * 3600 * 1000) - 3000) ,
    state: 'faild',
    percentage: 100
  }
];

export default {
  data(){
    return {
      dialogVisible: false,
      formLoading: false,
      tableLoading: false,
      tableData: [],
      page: {
          pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableOption: {
        // height: 'auto',
        // calcHeight: 30,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: false,
        editBtn: false,
        addBtn: false,
        // viewBtn: true,
        delBtn: false,
        // menuWidth: 350,
        dialogClickModal: false,
        menuWidth: 300,
        column: [
          {
            label: '源设备',
            prop: 'source'
          },
          {
            label: '目标设备',
            prop: 'target'
          },
          {
            label:'开始时间',
            prop:'startTime',
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            width: 140
          },
          {
            label:'结束时间',
            prop: 'endTime',
            type: "date",
            format: "yyyy-MM-dd hh:mm:ss",
            width: 140
          },
          {
            label: '状态',
            prop: 'state'
          }
        ]
      },
      form: {
        target: '',
        source: ''
      },
      devices: [
        '10.10.100.120',
        '10.10.100.121',
        '10.10.3.150',
        '10.10.5.10',
        '10.40.60.12'
      ],
      states: {
        'running': {
          text: '运行中',
          icon: 'el-icon-loading',
          class: 'text-primary'
        },
        'success': {
          text: '成功',
          icon: 'el-icon-success',
          class: 'text-success'
        },
        'faild': {
          text: '失败',
          icon: 'el-icon-error',
          class: 'text-danger'
        }
      }
    }
  },
  methods: {
    onLoad(){
      this.tableLoading = true;
      setTimeout(() => {
        this.tableLoading = false;
        this.tableData = tempData;
        this.page.total = tempData.length;
      } , 1500)
    },
    submit(){
      this.formLoading = true;
      setTimeout(() => {
        tempData.push({
          target: this.form.target,
          source: this.form.source,
          startTime: formatDate(Date.now()),
          endTime: null,
          state: 'running',
          percentage: Math.floor(Math.random() * 90)
        })
        this.form = {
          source: '',
          target: ''
        }
        this.formLoading = false;
        this.dialogVisible = false;
        this.$message.success('执行成功');
        this.onLoad();
      } , 2000)
    }
  }
}
</script>
<template>
    <div class="catalog-container h-100p">
        <el-row type="flex" class="h-100p" :gutter="$store.state.common.gutter">
            <el-col style="margin-bottom: 0;width:300px;" class="h-100p">
                <el-card
                    v-loading="treeLoading"
                    class="h-100p ov-a"
                    shadow="never"
                    :body-style="{ padding: '15px' }"
                >
                    <el-tree
                        ref="tree"
                        node-key="id"
                        :props="{ children: 'children', label: 'name', isLeaf: 'isLeaf' }"
                        lazy
                        :default-expanded-keys="defaultExpandedId"
                        :load="loadNode"
                        @node-click="handleNodeClick"
                        >
                    </el-tree>
                    <!-- <el-tree
                        :data="treeData"
                        ref="tree"
                        node-key="id"
                        :props="{ children: 'children', label: 'name', isLeaf: 'isLeaf' }"
                        accordion
                        @node-click="handleNodeClick"
                    >
                    </el-tree> -->
                </el-card>
            </el-col>
            <el-col class="h-100p" :span="1" style="margin-bottom: 0;flex: 1;">
                <el-card>
                    <avue-crud
                    ref="crud"
                    :table-loading="tableLoading"
                    :data="tableData"
                    :option="option"
                    :page.sync="page"
                    :before-open="beforeOpen"
                    :before-close="beforeClose"
                    v-model="catalogForm"
                    @on-load="getCatalogsData"
                    @refresh-change="getCatalogsData"
                    @row-save="rowSave"
                    @row-del="rowDel"
                    @selection-change="selectionChange"
                    @row-update="(row, index, done , loading) => rowUpdate(row, done , loading)"
                    >
                        <template slot-scope="{ row, size, type, index }" slot="menu">
                            <el-button
                            icon="el-icon-edit"
                            :size="size"
                            :type="type"
                            @click="openRowEdit(row, index)"
                            >编辑</el-button>
                            <el-button
                            :size="size"
                            :type="type"
                            @click="() => $router.push(getHref(row))"
                            >元数据</el-button>
                        </template>
                        <template slot="name" slot-scope="scope">
                            <a class="cur-p" @click="openView(scope.row , scope.index)">{{scope.row.name}}</a>
                        </template>
                        <template slot="menuLeft">
                            <el-button icon="el-icon-upload2" :size="$store.state.common.size" @click="importExcel">导入</el-button>
                            <el-input
                                v-model="searchVal"
                                @keyup.native.enter="getCatalogsData"
                                size="small"
                                :placeholder="$t('Please enter keyword')"
                                type="text"
                                style="width:180px;"
                                >
                                <el-button
                                    class="mar-0 pad-0"
                                    size="small"
                                    @click="getCatalogsData"
                                    slot="append"
                                    icon="el-icon-search"
                                ></el-button>
                            </el-input>
                        </template>
                        <template slot="page" slot-scope="{size}">
                            <div class="pull-left">
                                <el-button :size="$store.state.common.size" @click="exportXSL">导出选中</el-button>
                                <el-button :size="$store.state.common.size" @click="exportExcelAll">导出全部</el-button>
                            </div>
                        </template>
                        <template slot-scope="scope" slot="dataSourceTableForm">
                                <!-- <el-select
                                    v-model="catalogForm.dataSourceType"
                                    @change="dataSourceTypeChange()"
                                    placeholder="请选择"
                                    disabled
                                >
                                    <el-option
                                        :key="item.value"
                                        v-for="item of dataSourceTypes"
                                        :label="item.label"
                                        :value="item.value"
                                    ></el-option>
                                </el-select>
                                <el-select
                                    v-model="catalogForm.dataSourceDb"
                                    @change="dataSourceDbChange()"
                                    placeholder="请选择"
                                    disabled
                                >
                                    <el-option
                                        v-for="item in dataSources"
                                        :key="item.name"
                                        :label="item.name"
                                        :value="item.name"
                                    >
                                    </el-option>
                                </el-select> -->
                                <el-select
                                    v-model="catalogForm.dataSourceTable"
                                    @change="dataSourceTableChange()"
                                    filterable
                                    placeholder="请选择"
                                    :disabled="scope.disabled"
                                    :readonly="scope.readonly"
                                >
                                    <el-option
                                        v-for="item in tables"
                                        :key="item.name"
                                        :label="item.name"
                                        :value="item.name"
                                    >
                                    </el-option>
                                </el-select>
                            <div class="clear-f"></div>
                            <el-table
                                stripe
                                border
                                :data="tableData1"
                                style="width: 100%;margin-top: 10px;max-height: 200px;"
                            >
                                <el-table-column prop="columnName" label="字段"></el-table-column>
                                <el-table-column prop="columnType" label="数据类型"></el-table-column>
                                <el-table-column prop="columnComment" label="描述"></el-table-column>
                            </el-table>
                        </template>
                        <template slot-scope="scope" slot="labelListForm">
                            <el-cascader
                                size="small"
                                class="dis-b"
                                :options="labelData"
                                :disabled="scope.disabled"
                                :readonly="scope.readonly"
                                v-model="catalogForm.labelList"
                                :props="{
                                    multiple: true,
                                    label: 'name',
                                    value: 'id',
                                    emitPath: false,
                                    checkStrictly: true
                                }"
                                collapse-tags
                                :show-all-levels="false"
                                clearable
                            ></el-cascader>
                        </template>
                    </avue-crud>
                </el-card>
            </el-col>
        </el-row>
        <el-dialog
            width="400px"
            append-to-body
            title="导入文件"
            :visible.sync="uploadFormVisible"
        >
            <el-upload
                v-if="uploadFormVisible"
                ref="uploadEl"
                drag
                :action="importExcelUrl"
                :data="importExcelData"
                :on-success="getCatalogsData"
                :http-request="uploadFile"
                name="excel"
                accept=".xls,.xlsx"
            >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                <div class="el-upload__tip" slot="tip">只能上传xls/xlsx文件(<a href="/files/资产目录模板.xlsx" class="cur-p">模板下载</a>)</div>
            </el-upload>
        </el-dialog>
    </div>
</template>
<script>
import { mapActions } from "vuex";
import {
  getDataLayerList,
  queryDataBaseByDataHierarchyId
} from "@/api/dws/datalayer";
import labelApi from "@/api/label";
import datasourceTypeList from "@/config/datasourceTypeList";

import catalogService from '@/api/dataAssets/catalog';
import * as dictbizService from '@/api/system/dictbiz';

export default {
    data(){
        return {
            formLabelWidth: "100px",

            searchVal: '',
            treeLoading: false,
            defaultExpandedId: [],
            treeData: [],
            tableData1: [],
            currentNode: null,

            labelData: [],
            labels: {},

            uploadFormVisible: false,
            importExcelUrl: catalogService.apiUrl.importExcel,
            importExcelData: null,

            dataSourceTypes: datasourceTypeList,
            dataSources: [],
            tables: [],
            selections: [],

            tableLoading: false,
            catalogForm: {
                name: "",
                frequency: "",
                dataSourceType: "",
                dataSourceDb: "",
                dataSourceTable: "",
                staticDirType: "",
                labelList: [],
                dataSourceId: null,
                storageMode: null,
                collectFrequency: null,
                generateCycle: null,
                description: ''
            },
            tableData: [],
            page: {
          pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
                pageSize: 10,
                currentPage: 1,
                total: 0
            },
            option: {
                selection: true,
                tip:false,
                editBtn: false,
                labelWidth: 120,
                column: [
                    {
                        label: "名称",
                        prop: "name",
                        rules: [
                            {
                                required: true,
                                message: "请输入名称",
                                trigger: "blur"
                            }
                        ],
                        width: 300,
                    },
                    {
                        label: "存储方式",
                        prop: "storageMode",
                        type: "select",
                        props: {
                            label: "dictValue",
                            value: "dictKey"
                        },
                        dicUrl: dictbizService.getUrl('STORAGE_MODE')
                    },
                    {
                        label: "标签",
                        prop: "labelList",
                        hide: true,
                        showColumn: false,
                        dicUrl: labelApi.apiUrls.tree
                    },
                    {
                        label: "数据量",
                        prop: "dataRecordCount",
                        display: false
                    },
                    {
                        label: "采集频率",
                        prop: "collectFrequency",
                        type: "select",
                        props: {
                            label: "dictValue",
                            value: "dictKey"
                        },
                        dicUrl: dictbizService.getUrl('UPDATE_FREQUENCY')
                    },
                    // {
                    //     label: "更新频率",
                    //     prop: "updateFrequencyDictId",
                    //     type: "select",
                    //     props: {
                    //         label: "dictValue",
                    //         value: "dictKey"
                    //     },
                    //     dicUrl: dictbizService.getUrl('UPDATE_FREQUENCY')
                    // },
                    {
                        label: "生成周期",
                        prop: "generateCycle",
                        type: "select",
                        props: {
                            label: "dictValue",
                            value: "dictKey"
                        },
                        dicUrl: dictbizService.getUrl('UPDATE_FREQUENCY')
                    },
                    {
                        label: "描述",
                        prop: "description",
                        type: "textarea",
                        minRows: 2,
                        span: 24,
                        hide: true
                    },
                    {
                        label: "关联数据集",
                        prop: "dataSourceTable",
                        span: 24,
                        minWidth: 150,
                        rules: [
                            {
                                required: true,
                                message: "请选择数据集",
                                trigger: "blur"
                            }
                        ]
                    },
                    {
                        label: "创建人",
                        prop: "createUserName",
                        display: false
                    },
                    {
                        label: "创建时间",
                        prop: "createTime",
                        type: "date",
                        format: "yyyy-MM-dd HH:mm:ss",
                        valueFormat: "yyyy-MM-dd HH:mm:ss",
                        display: false,
                        width: 150
                    },
                    {
                        label: "最后一次更新时间",
                        prop: "updateTime",
                        type: "date",
                        format: "yyyy-MM-dd HH:mm:ss",
                        valueFormat: "yyyy-MM-dd HH:mm:ss",
                        display: false,
                        width: 150
                    }
                ]
            },
        }
    },
    mounted(){
        labelApi.treeLabel().then(res => {
            this.labelData = this.translateTreeData(res.data.children || []);
        });
        this.getDataLayerList();
        // dictbizService.getDictionary({
        //     code: ''
        // })
    },
    methods: {
        ...mapActions("datasource", [
            "getDatasourcesList",
            "getTablesByDatasourceId",
            "queryDataSourceTableDesc"
        ]),
        getHref(row){
            return row.tableGuid ? `/data-assets/assets-detail/${row.tableGuid}?databaseId=${row.dataSourceId}&tableName=${row.dataSourceTable}&databaseName=${row.dataSourceDb}` : '';
        },
        getDataLayerList(){
            // this.treeLoading = true;
            // this.treeData = [];
            let obj = {
                dhName: "",
                pageNo: 1,
                pageSize: 10000
            };
            return getDataLayerList(obj).then(res => {
                let treeData = [];
                (res.data.data.lists || []).forEach(item => {
                    treeData.push({
                        ...item,
                        name: item["dhName"]
                    });
                });
                return treeData;
            }).catch(e => {
                this.treeLoading = false;
            });
        },
        openView(row , index){
            this.$refs.crud.rowView(row , index);
            this.dataSourceTypeChange(true).then(res => {
                this.dataSourceDbChange(true).then(res => this.dataSourceTableChange());
            });
        },
        handleNodeClick(item){
            if(item.isLeaf){
                this.currentNode = item;
                this.getCatalogsData();
            }else{
                this.$refs.tree.setCurrentNode(this.currentNode);
            }
        },
        translateTreeData(treeData) {
            let newTreeData = [];
            treeData.forEach(item => {
                let el = {
                    ...item.label
                };
                this.labels[el.id] = el.name;
                if (item.children && item.children.length) {
                    el["children"] = this.translateTreeData(item["children"]);
                }
                newTreeData.push(el);
            });
            return newTreeData;
        },

        beforeOpen(done , type){
            if(type === 'add'){
                this.initCataForm();
                this.catalogForm.dataSourceType = this.currentNode.type;
                this.catalogForm.dataSourceDb = this.currentNode.name;
                this.dataSourceTypeChange(true).then(res => {
                    this.dataSourceDbChange(true).then(res => this.dataSourceTableChange());
                });
            }
            if(['edit'].includes(type)){
                catalogService.getDetail({id: this.catalogForm.id}).then(
                    res => {
                        this.catalogForm = res.data.data;
                    }
                )
            }
            done();
        },
        beforeClose(done) {
            this.tableData1 = [];
            this.initCataForm();
            done();
        },
        // 表格数据加载
        getCatalogsData(){
            let params = {
                keyword: this.searchVal,
                current: this.page.currentPage,
                size: this.page.pageSize
            };
            if(this.currentNode){
                if(this.currentNode.dataBaseId){
                    params['dataSourceId'] = this.currentNode.dataBaseId;
                }else{
                    params['hierarchyId'] = this.currentNode.id;
                }
            }else{
                return;
            }
            this.tableLoading = true;
            this.tableData = [];
            catalogService.getList(params).then(
                res => {
                    if(res.data){
                        this.tableData = res.data.data.records.map(
                            item => {
                                return {
                                    ...item,
                                    lastUpdateTime: item.lastUpdateTime || item.createTime
                                }
                            }
                        );
                    }
                    this.tableLoading = false;
                    this.page.total = res.data.data.total;
                }
            )
        },
        initCataForm(){
            this.catalogForm = {
                name: "",
                frequency: "",
                dataSourceType: "",
                dataSourceDb: "",
                dataSourceTable: "",
                staticDirType: "",
                labelList: [],
                dataSourceId: null,
                storageMode: null,
                collectFrequency: null,
                generateCycle: null,
                description: ''
            }
        },
        rowSave(row, done , loading){
            delete row.id;
            catalogService.save({
                ...row,
                hierarchyId: this.currentNode.dhId,
                labelList: this.catalogForm.labelList || []
            }).then(
                res => {
                    done();
                    this.initCataForm();
                    this.getCatalogsData();
                }
            ).catch(e => loading())
        },
        rowUpdate(row, done , loading){
            catalogService.update({
                ...row,
                labelList: this.catalogForm.labelList || []
            }).then(
                res => {
                    done();
                    this.initCataForm();
                    this.getCatalogsData();
                }
            ).catch(e => loading())
        },
        rowDel(row, done){
            this.$confirm("确定将选择数据删除?", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning"
            }).then(() => {
                catalogService.remove({
                    ids: row["id"]
                }).then(res => {
                    this.getCatalogsData();
                });
            });
        },
        selectionChange(list){
            this.selections = list;
            console.log(list);
        },
        exportXSL(){
            if(this.selections.length){
                let ids = this.selections.map(item => item.id);
                catalogService.exportExcel({
                    ids: ids.join(',')
                }).then(
                    res => {
                        console.log(res);
                    }
                )
            }else{
                this.$message.error('请至少选择一条数据导出')
            }
        },
        importExcel(){
            this.uploadFormVisible = true;
            this.importExcelData = {
                hierarchyId: this.currentNode.dhId
            }
        },
        exportExcelAll(){
            let params = {
                keyword: this.searchVal,
            }
            if(this.currentNode.dataBaseId){
                params['dataSourceId'] = this.currentNode.dataBaseId;
            }else{
                params['hierarchyId'] = this.currentNode.id;
            }
            catalogService.exportExcelAll(params).then(
                res => {

                }
            )
        },
        uploadFile(data){
            let formData = new FormData();
            formData.append(data.filename , data.file);
            formData.append('hierarchyId' , this.currentNode.dhId);
            formData.append('dataSourceId' , this.currentNode.dataBaseId);
            catalogService.importExcel(formData).then(
                res => {
                    if(res.data && res.data.code === 200){
                        this.$message.success(res.data.msg);
                        this.uploadFormVisible = false;
                        this.getCatalogsData();
                    }
                }
            )
        },
        uploadError(err){
            this.$message.error('上传失败');
        },

        openRowEdit(row, index) {
            this.catalogForm.labelList = [];
            let tags = [];
            labelApi.listLabelResourceRelation({
                resourceId: row.id
            }).then(res => {
                if (res.data.data.records) {
                    res.data.data.records.forEach(item => {
                        if (tags.indexOf(item["labelId"]) < 0) {
                            tags.push("" + item["labelId"]);
                            // if (!this.labels[item["labelId"]]) {
                            //     this.labels[item["labelId"]] = item["labelName"];
                            // }
                        }
                    });
                }
            });
            row.labelList = tags;
            this.$refs.crud.rowEdit(row, index);
            this.dataSourceTypeChange(true).then(res => {
                this.dataSourceDbChange(true).then(res => this.dataSourceTableChange());
            });
        },
        dataSourceTypeChange(notClear) {
            if (!notClear) {
                this.catalogForm.dataSourceDb = "";
                this.catalogForm.dataSourceTable = "";
                this.tableData1 = [];
            }
            return this.getDatasourcesList({
                type: this.catalogForm.dataSourceType
            }).then(res => {
                this.dataSources = res["data"];
                return res;
            });
        },
        dataSourceDbChange(notClear) {
            if (!notClear) {
                this.catalogForm.dataSourceTable = "";
                this.tableData1 = [];
            }
            let id = "";
            this.dataSources.forEach(item => {
                if (item.name === this.catalogForm.dataSourceDb) {
                    id = item.id;
                    this.catalogForm.dataSourceId = id;
                }
            });
            return this.getTablesByDatasourceId(id).then(res => {
                this.tables = (res.data.data || []).map(item => ({ name: item }));
                return res;
            });
        },
        dataSourceTableChange() {
            this.tableData1 = [];
            let id = "";
            this.dataSources.forEach(item => {
                if (item.name === this.catalogForm.dataSourceDb) {
                id = item.id;
                }
            });
            this.queryDataSourceTableDesc({
                id: id,
                tableName: this.catalogForm.dataSourceTable
            }).then(res => {
                this.tableData1 = res.data.tableDescList;
            });
        },
        queryDataBaseByDataHierarchyId(id) {
            let obj = {
                id: id
            };
            return queryDataBaseByDataHierarchyId(obj).then(res => {
                let data = res.data.data || [];
                data.forEach(item => {
                    item.id = "database_" + item.dataBaseId;
                    item.isLeaf = true;
                });
                return data;
            });
        },
        loadNode(node, resolve) {
            if (node.level === 0) {
                return this.getDataLayerList().then(data => {
                resolve(data);
                if (data.length) {
                    if(this.dhId){
                        data.forEach(item => {
                            if(item.id == this.dhId){
                                this.defaultExpandedId = [item.id];
                            }
                        })
                    }else{
                        this.defaultExpandedId = [data[0].id];
                    }
                }
                });
            } else if (node.level === 1) {
                this.queryDataBaseByDataHierarchyId(node.data.id).then(data => {
                    resolve(data);
                    if (data.length && !this.currentDatabase) {
                        this.$refs.tree.setCurrentNode(data[0]);
                        this.handleNodeClick(data[0]);
                    }
                })
                .catch(e => {
                    resolve([]);
                });
            } else {
                resolve([]);
            }
        },
    }
}
</script>

import palette from "./palette";
import renderer from './renderer';
import contextPad from "./contextPad";

import node from './node';

export default {
  plugin: {
    palette,
    renderer,
    contextPad
  },
  node: node,
  dataSource: {
    getDataSource: '/api/getDataSource'
  },
  
  
}
<template>
  <div>
    <garden v-if="showTpye == 'all'"></garden>
    <div class="mar-t-15" v-if="showTpye == 'all'">
      <buildList @oneBuild="oneBuild" @goBuild="goBuild"></buildList>
    </div>
    <buildDetail
      v-if="showTpye == 'build'"
      @goBuild="goBuild"
      :buildObj="gardenData"
    ></buildDetail>
    <div class="mar-t-15">
      <floors
        v-if="showTpye == 'build'"
        :buildId="gardenData.building_id"
      ></floors>
    </div>
  </div>
</template>

<script>
import { getMetaCommonList, delMetaCommon } from "@/api/busiMode/metaCommon";
import garden from "./garden";
import floors from "./floors";

import builds from "./builds";
import buildList from "./buildList";
import buildDetail from "./buildDetail";
export default {
  components: { garden, builds, floors, buildList, buildDetail },
  data() {
    return {
      showTpye: "all",
    };
  },
  computed: {},
  mounted() {},
  methods: {
    oneBuild(d) {
      this.gardenData = d;
      this.showTpye = "build";
    },
    goBuild() {
      this.showTpye = "all";
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped></style>

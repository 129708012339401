<template>
  <!-- 左侧部分 -->
  <div>
    <div class="cockpit-title">
      园区运营分析
      <img src="../../../public/assets/assets/img/more.png" class="more" />
    </div>
    <div style="padding: 0 20px">
      <!-- 房屋租赁统计 -->
      <div class="count">
        <div class="flex f14">
          <i class="cockpit-icon icon_1"></i>
          房屋租赁统计
        </div>
        <div class="data-content">
          <div class="fw-data flex">
            <div class="fw data-item" v-for="item in d1" :key="item.id">
              <p class="num">{{ item.num }}</p>
              <p>{{ item.desc }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 企业统计 -->
      <div class="count">
        <div class="flex f14">
          <i class="cockpit-icon icon_2"></i>
          企业统计
        </div>
        <div class="data-content">
          <div class="fw-data flex">
            <div class="qy data-item" v-for="item in d2" :key="item.id">
              <p class="num">{{ item.num }}</p>
              <p>{{ item.desc }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- 耗能统计 -->
      <div class="count">
        <div class="flex f14">
          <i class="cockpit-icon icon_3"></i>
          耗能统计
        </div>
        <div class="data-content">
          <div class="fw-data flex">
            <div class="hn data-item" v-for="item in d3" :key="item.id">
              <p class="num">
                {{ item.num }}<span class="unit">{{ item.unit }}</span>
              </p>
              <p>{{ item.desc }}</p>
            </div>
          </div>
        </div>
      </div>

      <div class="flex">
        <!-- 房屋收入统计 -->
        <div class="count">
          <div class="flex f14">
            <i class="cockpit-icon icon_4"></i>
            房屋收入统计
          </div>
          <div class="data-content">
            <div class="fw-data flex">
              <div class="fwsr data-item" v-for="item in d4" :key="item.id">
                <p class="num">
                  {{ item.num | fixed2 }}<span class="unit" v-if="item.unit">{{ item.unit }}</span>
                </p>
                <p>{{ item.desc }}</p>
              </div>
            </div>
          </div>
        </div>

        <!-- 停车费统计 -->
        <div class="count">
          <div class="flex f14">
            <i class="cockpit-icon icon_5"></i>
            停车费统计
          </div>
          <div class="data-content">
            <div class="fw-data flex">
              <div class="tcf data-item" v-for="item in d5" :key="item.id">
                <p class="num">
                  {{ item.num }}<span class="unit" v-if="item.unit">{{ item.unit }}</span>
                </p>
                <p>{{ item.desc }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getMetaCommonList } from "@/api/busiMode/metaCommon"

export default {
  data() {
    return {
      isFullscreen: false,
      /** 房屋租赁统计 **/
      d1: [
        { id: "building_num", num: "0", desc: "楼宇数" },
        { id: "room_num", num: "0", desc: "房间总数" },
        { id: "syl", num: "35%", desc: "使用率" },
        { id: "floorage", num: "0", desc: "建筑面积（㎡）" },
        { id: "sum_room_area_yz", num: "0", desc: "出租面积（㎡）" },
        { id: "sum_room_area_wz", num: "0", desc: "空置面积（㎡）" },
        { id: "sum_room_area_ys", num: "0", desc: "已售面积（㎡）" },
        { id: "sum_room_area_zy", num: "0", desc: "自用面积（㎡）" },
      ],
      /** 企业统计 **/
      d2: [
        { id: "check_in_week", num: "2", desc: "上周入驻数" },
        { id: "check_in_month", num: "10", desc: "本月入驻数" },
        { id: "check_in_year", num: "30", desc: "本年度入驻数" },
        { id: "check_out_week", num: "1", desc: "上周退出数" },
        { id: "check_out_month", num: "3", desc: "本月退出数" },
        { id: "check_out_year", num: "3", desc: "本年度退出数" },
        { id: "sum_checkin_num", num: "7", desc: "当前企业总数" },
        // { id: "d2_7", num: "280", desc: "已售面积（㎡）" },
      ],
      /** 耗能统计 **/
      d3: [
        { id: "water_week", num: "0", desc: "上周用水量", unit: "吨" },
        { id: "water_month", num: "0", desc: "本月用水量", unit: "吨" },
        { id: "water_year", num: "0", desc: "本年度用水量", unit: "吨" },
        { id: "electric_week", num: "0", desc: "上周用电量", unit: "万度" },
        { id: "electric_month", num: "0", desc: "本月用电量", unit: "万度" },
        { id: "electric_year", num: "0", desc: "本年度用电量", unit: "万度" },
        { id: "heating_week", num: "0", desc: "上周用暖量", unit: "吉焦" },
        { id: "heating_mouth", num: "0", desc: "本月用暖量", unit: "吉焦" },
        { id: "heating_year", num: "0", desc: "本年度用暖量", unit: "吉焦" },
      ],
      /** 房屋收入统计 **/
      d4: [
        { id: "ys_fz_week", num: "0", desc: "上周实收", unit: "万" },
        { id: "ys_fz_month", num: "0", desc: "本月实收", unit: "万" },
        { id: "yj_fz_sum", num: "0", desc: "本年度实收", unit: "万" },
        { id: "ys_fz_sum", num: "0", desc: "本年度应收", unit: "万" },
        { id: "wcl", num: "10", desc: "完成率", unit: "" },
      ],
      /** 停车费统计 **/
      d5: [
        { id: "fee_week", num: "0", desc: "上周实收", unit: "万" },
        { id: "fee_month", num: "0", desc: "本月实收", unit: "万" },
        { id: "fee_year", num: "0", desc: "本年度实收", unit: "万" },
      ],
    }
  },

  mounted() {
    this.getMetaCommonList("v_jsc_wp_company_stat", "d2")
    this.getMetaCommonList("v_jsc_wp_park_stat", "d1")
    this.getMetaCommonList("v_jsc_wp_energy_consumption_stat", "d3")
    this.getMetaCommonList("v_jsc_wp_room_income_stat", "d4")
    this.getMetaCommonList("v_jsc_wp_parking_fee_stat", "d5")
  },

  filters: {
    fixed2(num) {
      return (num + "").includes("%") ? num : num == 0 ? "0.00" : Math.round(num * 100) / 100
    },
  },

  methods: {
    getMetaCommonList(view, name) {
      let b = {
        size: 1000,
        current: 1,
      }
      getMetaCommonList("wisdom_park." + view, b).then((res) => {
        const data = res.data.data
        if (name == "d2") {
          this.d2.forEach((e) => {
            for (let key in data.records[0]) {
              if (e.id == key) {
                e.num = data.records[0][key]
              }
            }
          })
        }
        if (name == "d1") {
          this.d1.forEach((e) => {
            for (let key in data.records[0]) {
              if (e.id == key) {
                if (key == "syl") {
                  e.num = data.records[0][key] + " %"
                } else {
                  e.num = data.records[0][key]
                }
              }
            }
          })
        }
        if (name == "d3") {
          this.d3.forEach((e) => {
            for (let key in data.records[0]) {
              if (e.id == key) {
                e.num = data.records[0][key]
              }
            }
          })
        }
        if (name == "d4") {
          this.d4.forEach((e) => {
            for (let key in data.records[0]) {
              if (e.id == key) {
                e.num = data.records[0][key]
                if (key == "wcl") {
                  e.num = e.num + " %"
                }
              }
            }
          })
        }
        if (name == "d5") {
          this.d5.forEach((e) => {
            for (let key in data.records[0]) {
              if (e.id == key) {
                e.num = data.records[0][key]
              }
            }
          })
        }
      })
    },
  },
}
</script>

<style scoped>
.cockpit-container .cockpit-left,
.cockpit-container .cockpit-right {
  height: 960px;
}

.cockpit-container .cockpit-left {
  width: 979px;
}

.cockpit-container .cockpit-right {
  width: 916px;
  padding: 0 5px;
}

.cockpit-container .cockpit-left {
  background: url(../../../public/assets/assets/img/bg001.svg) no-repeat 100%/100%;
}

.flex .f14 {
  margin-bottom: 10px;
}

/** 标题渐变色样式 **/
.cockpit-title {
  position: relative;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  color: #fff;
  font-weight: bold;
  background: linear-gradient(90deg, rgba(0, 238, 255, 0.7) 0%, rgba(60, 165, 255, 0) 100%);
}

.cockpit-title img {
  width: 40px;
}

.mt {
  margin-top: 5px;
}

.cockpit-mid-title {
  padding: 15px;
}

.flex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

/** 左侧 小框内容 **/
.data-content {
  display: flex;
  display: -webkit-flex;
  margin-top: 15px;
}

.data-item {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 60px;
  margin-right: 8px;
}

.data-item > p.num {
  font-size: 18px;
  font-weight: bold;
}

.data-item > p {
  margin: 0px;
  margin-top: 5px;
  font-size: 11px;
}

/** 各种类型的单独样式 **/
.fw {
  background: url(../../../public/assets/assets/img/bg_icon_1.svg) 100% 100%;
}

.fw p.num {
  color: #00ffac;
}

.qy {
  background: url(../../../public/assets/assets/img/bg_icon_2.svg) 100% 100%;
}

.qy p.num {
  color: #00eeff;
}

.hn {
  background: url(../../../public/assets/assets/img/bg_icon_3.svg) 100% 100% no-repeat;
}

.hn p.num {
  color: #40b3ff;
}

p.num span.unit {
  font-size: 10px;
  margin-left: 3px;
}

.hn.data-item {
  width: 96px;
}

.fwsr {
  background: url(../../../public/assets/assets/img/bg_icon_4.svg) 100% 100% no-repeat;
}

.fwsr p.num {
  color: #f382ff;
}

.tcf {
  background: url(../../../public/assets/assets/img/bg_icon_5.svg) 100% 100% no-repeat;
}

.tcf p.num {
  color: #ffed7a;
}

/** 小图标 **/
.cockpit-icon {
  display: inline-block;
  width: 14px;
  height: 14px;
  margin-right: 5px;
}

.icon_1 {
  background: url(../../../public/assets/assets/img/icon_1.svg) 100%/100%;
}

.icon_2 {
  background: url(../../../public/assets/assets/img/icon_2.svg) 100%/100%;
}

.icon_3 {
  background: url(../../../public/assets/assets/img/icon_3.svg) 100%/100%;
}

.icon_4 {
  background: url(../../../public/assets/assets/img/icon_4.svg) 100%/100%;
}

.icon_5 {
  background: url(../../../public/assets/assets/img/icon_5.svg) 100%/100%;
}

.icon_6 {
  background: url(../../../public/assets/assets/img/icon_6.svg) 100%/100%;
}

.count {
  margin-top: 28px;
}

.mt20 {
  margin-top: 20px;
}
.cockpit-title.noback {
  background: none;
}
</style>

<template>
  <basic-container>
    <avue-crud
      ref="crud"
      v-model="catalogForm"
      :data="tableData"
      :table-loading="taskLoading"
      :page.sync="page"
      :option="option"
      :before-close="beforeClose"
      @on-load="onLoad"
      @refresh-change="onLoad"
      @row-save="handleRowSave"
      @row-update="rowUpdate"
    >
      <template slot-scope="{ type, size, row }" slot="menu">
        <el-button
          @click.stop="handleEdit(row)"
          :disabled="row.releaseState === 'ONLINE'"
          icon="el-icon-edit"
          :size="size"
          :type="type"
          >编辑</el-button
        >
        <el-button
          @click.stop="rowDel(row)"
          :disabled="row.releaseState === 'ONLINE'"
          icon="el-icon-delete"
          :size="size"
          :type="type"
          >删除</el-button
        >
        <el-button
          @click.stop="handleTaskCopy(row)"
          icon="el-icon-document-add"
          :size="size"
          :type="type"
          >复制</el-button
        >
        <el-button
          @click="_start(row)"
          :disabled="row.releaseState === 'OFFLINE'"
          icon="el-icon-video-play"
          :size="size"
          :type="type"
          >运行</el-button
        >
        <el-button
          @click="_poponline(row)"
          v-if="row.releaseState === 'OFFLINE'"
          icon="el-icon-upload2"
          :size="size"
          :type="type"
          >上线</el-button
        >
        <el-button
          @click="_downline(row)"
          v-if="row.releaseState === 'ONLINE'"
          icon="el-icon-download"
          :size="size"
          :type="type"
          >下线</el-button
        >
        <el-button
          @click="timeFormAdd(row)"
          :disabled="row.releaseState === 'OFFLINE'"
          icon="el-icon-time"
          :size="size"
          :type="type"
          >定时</el-button
        >
        <el-button
          @click="timeManage(row)"
          :disabled="row.releaseState === 'OFFLINE'"
          icon="el-icon-setting"
          :size="size"
          :type="type"
          >定时管理</el-button
        >
        <!--<el-button @click="copyFormAdd" icon="el-icon-document-copy" :size="size" :type="type">复制</el-button>-->
      </template>
      <template slot-scope="{ row }" slot="databaseTypeForm">
        <el-select
          v-model="catalogForm.databaseType"
          placeholder="请选择"
          @change="datasourceTypeChange('')"
        >
          <el-option
            v-for="item in datasourceTypeList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>
      <template slot-scope="{ row }" slot="databaseForm">
        <el-select
          v-model="catalogForm.database"
          placeholder="请选择"
          @change="datasourceNameChange('')"
        >
          <el-option
            v-for="item in databases"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>
      <template slot-scope="{ row }" slot="versionForm">
        <el-select v-model="catalogForm.version" placeholder="请选择">
          <el-option
            v-for="item in versions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </template>
      <template slot-scope="{}" slot="metastoreUriLabel">
        <span v-if="catalogForm.databaseType === 'HIVE'"
          ><span class="text-red">*</span>Hive Metastore</span
        >
        <span v-else></span>
      </template>
      <template slot-scope="{ row }" slot="metastoreUriForm">
        <el-input
          placeholder="IP:PORT"
          v-if="catalogForm.databaseType === 'HIVE'"
          v-model="catalogForm.metastoreUri"
        ></el-input>
      </template>
      <template slot-scope="{ row }" slot="termGuidsForm">
        <el-cascader
          v-model="catalogForm.termGuids"
          :options="metadataCategorys"
          :props="{ label: 'name', value: 'atlasGuid', emitPath: false }"
        >
        </el-cascader>
      </template>
    </avue-crud>
    <el-dialog
      custom-class="dialog-width"
      append-to-body
      title="启动前请先设置参数"
      :visible.sync="runFormVisible"
    >
      <avue-form ref="runForm" v-model="runObj" :option="runOption">
        <template slot-scope="{ row }" slot="name">
          {{ workFlowName }}
        </template>
        <template slot-scope="scope" slot="menuForm">
          <basic-form-button
            @submit="runSubmit"
            @cancel="runFormVisible = false"
          ></basic-form-button>
        </template>
      </avue-form>
    </el-dialog>
    <el-dialog
      custom-class="dialog-width"
      append-to-body
      title="定时前请先设置参数"
      :visible.sync="timeFormVisible"
    >
      <avue-form
        class="metadata-task"
        ref="timeForm"
        v-model="timeObj"
        :option="timeOption"
      >
        <template slot-scope="{ row }" slot="timer">
          <el-popover placement="bottom-start" trigger="click">
            <template slot="reference">
              <el-input
                style="width: calc(100% - 90px)"
                type="text"
                size="small"
                readonly
                :value="crontab"
              >
              </el-input>
            </template>
            <div class="crontab-box">
              <v-crontab v-model="crontab" :locale="i18n"></v-crontab>
            </div>
          </el-popover>
          <!--<el-popover v-model="cronPopover">-->
          <!--<v-crontab v-model="crontab" :locale="i18n"></v-crontab>-->
          <!--<el-input style="width: calc(100% - 90px)" slot="reference" @click="cronPopover=true" v-model="crontab" placeholder="请输入定时策略"></el-input>-->
          <!--</el-popover>-->

          <el-button class="mar-l-10" type="primary" @click="showPreviewClick"
            >执行时间</el-button
          >
          <div>接下来五次执行时间</div>
          <template v-if="showPreview">
            <div v-for="(item, index) in exectPreview">
              {{ item }}
            </div>
            <div v-if="exectPreview.length === 0">暂无数据</div>
          </template>
        </template>
        <template slot-scope="scope" slot="menuForm">
          <basic-form-button
            @submit="timeSubmit"
            @cancel="timeFormVisible = false"
          ></basic-form-button>
        </template>
      </avue-form>
    </el-dialog>
    <!-- <el-dialog append-to-body
      :title="$t('Set parameters before timing')"
      :visible.sync="timeFormVisible"
      width="auto">
      <m-timing :timingData="currentRow" @onUpdateTiming="onUpdateTiming" @closeTiming="closeTiming"></m-timing>
    </el-dialog> -->
  </basic-container>
</template>

<script>
import { mapActions } from "vuex";
import i18n from "@/lang";
import { getDataBase, getTaskList } from "@/api/metadata/task";
import { getDictionary } from "@/api/system/dict";
import datasourceTypeList from "@/config/datasourceTypeList";
import failureStrategyList from "@/config/failureStrategyList";
import processInstancePriorityList from "@/config/processInstancePriorityList";
import warningTypeList from "@/config/warningTypeList";
import { vCrontab } from "@/view-module/scheduler/common/components/crontab/index";
import {
  getAllMetadataPackages,
  getMetadataCategory,
} from "@/api/metadata/model";
// import mTiming from "@/view-module/scheduler/pages/projects/pages/definition/pages/list/_source/timing.vue";

export default {
  components: { vCrontab },
  data() {
    return {
      taskLoading: false,
      metadataForm: {},
      form: {
        processDefinitionJson: {
          globalParams: [
            {
              prop: "datasourcetype",
              direct: "IN",
              type: "VARCHAR",
              value: "HIVE",
            },
            {
              prop: "datasourcetypekey",
              direct: "IN",
              type: "VARCHAR",
              value: "HIVE",
            },
            {
              prop: "datasourcename",
              direct: "IN",
              type: "VARCHAR",
              value: "HIVE",
            },
            {
              prop: "datasourcenamekey",
              direct: "IN",
              type: "VARCHAR",
              value: "HIVE",
            },
            {
              prop: "version",
              direct: "IN",
              type: "VARCHAR",
              value: "HIVE",
            },
          ],
          tasks: [
            {
              type: "HTTP",
              id: "tasks-" + Math.ceil(Math.random() * 100000),
              name: "httpget",
              params: {
                localParams: [],
                httpParams: [
                  {
                    prop: "datasourceId",
                    httpParametersType: "PARAMETER",
                    value: "",
                  },
                ],
                url: "",
                httpMethod: "GET",
                httpCheckCondition: "STATUS_CODE_DEFAULT",
                condition: "",
              },
              description: "",
              timeout: {
                strategy: "",
                interval: null,
                enable: false,
              },
              runFlag: "NORMAL",
              conditionResult: {
                successNode: [],
                failedNode: [],
              },
              dependence: {},
              maxRetryTimes: "0",
              retryInterval: "1",
              taskInstancePriority: "MEDIUM",
              workerGroup: "default",
              preTasks: [],
            },
          ],
          tenantId: 1,
          timeout: 0,
        },

        type: "3",
        name: "",
        description: "",
        connects: [],
        locations: {},
      },
      showPreview: false,
      cronPopover: false,
      runFormVisible: false,
      timeFormVisible: false,
      copyFormVisible: false,
      tableData: [],
      datasourceTypeList: datasourceTypeList,
      databases: [],
      versions: [],
      workFlowName: "",
      processDefinitionId: "",
      workGroupList: [],
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      catalogForm: {
        name: "",
        databaseType: "",
        database: "",
        version: "",
        description: "",
        metastoreUri: "",
        termGuids: "",
      },
      option: {
        labelWidth: 120,
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: "名称",
            prop: "name",
            display: false,
          },
          {
            label: "状态",
            prop: "releaseState",
            type: "select",
            dicData: [
              {
                label: "上线",
                value: "ONLINE",
              },
              {
                label: "下线",
                value: "OFFLINE",
              },
            ],
            display: false,
          },
          {
            label: "数据来源类型",
            prop: "dsType",
            display: false,
          },
          {
            label: "来源信息",
            prop: "sourceInfo",
            display: false,
          },
          {
            label: "定时状态",
            prop: "scheduleReleaseState",
            dicData: [
              {
                label: "上线",
                value: "ONLINE",
              },
              {
                label: "下线",
                value: "OFFLINE",
              },
            ],
            display: false,
          },
          {
            label: "描述",
            prop: "description",
            display: false,
          },
          {
            label: "创建时间",
            prop: "createTime",
            type: "date",
            format: "yyyy-MM-dd HH:mm:ss",
            width: 140,
            display: false,
          },
        ],
        group: [
          {
            column: [
              {
                label: "名称",
                prop: "name",
                rules: [
                  {
                    required: true,
                    message: "请输入名称",
                    trigger: "blur",
                  },
                ],
                span: 12,
              },
              {
                label: "数据源类型",
                prop: "databaseType",
                labelWidth: 110,
                type: "select",
                rules: [
                  {
                    required: true,
                    message: "请选择版本",
                    trigger: "change",
                  },
                ],
                span: 12,
              },
              {
                label: "数据源",
                prop: "database",
                rules: [
                  {
                    required: true,
                    message: "请选择版本",
                    trigger: "change",
                  },
                ],
                span: 12,
              },
              {
                label: "版本",
                prop: "version",
                rules: [
                  {
                    required: true,
                    message: "请选择版本",
                    trigger: "change",
                  },
                ],
                span: 12,
              },
              {
                label: "分类",
                prop: "termGuids",
                rules: [
                  {
                    required: true,
                    message: "请选择分类",
                    trigger: "change",
                  },
                ],
                span: 12,
              },
              {
                label: "Hive Metastore",
                prop: "metastoreUri",
                placeholder: "IP:PORT",
                // rules: [{
                //   required: true,
                //   message: "请输入Hive Metastore 地址",
                //   trigger: "blur"
                // }],
                span: 12,
                labelslot: true,
                formslot: true,
              },
              // {
              //   label: "元数据分组",
              //   prop: "metadataGroup",
              //   type:'tree',
              //   props: {
              //     label: "name",
              //     value: "id"
              //   },
              //   dicData: [],
              //   span: 12
              // },
              {
                label: "描述",
                prop: "description",
                type: "textarea",
                minRows: 2,
                span: 24,
              },
            ],
          },
        ],
      },
      runObj: {
        failureStrategy: "CONTINUE",
        warningType: "NONE",
        processInstancePriority: "MEDIUM",
        workerGroup: "default",
      },
      runOption: {
        size: "small",
        labelWidth: 120,
        menuPosition: "right",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "任务名称",
            prop: "name",
            span: 24,
          },
          {
            label: "失败策略",
            prop: "failureStrategy",
            type: "radio",
            dicData: failureStrategyList,
          },
          {
            label: "通知策略",
            prop: "warningType",
            type: "select",
            dicData: warningTypeList,
          },
          {
            label: "流程优先级",
            prop: "processInstancePriority",
            type: "select",
            dicData: processInstancePriorityList,
          },
          {
            label: "Worker分组",
            prop: "workerGroup",
            props: {
              label: "name",
              value: "id",
            },
            type: "select",
            dicData: [],
          },
        ],
      },
      timeObj: {
        failureStrategy: "CONTINUE",
        warningType: "NONE",
        processInstancePriority: "MEDIUM",
        workerGroup: "default",
      },
      timeOption: {
        size: "small",
        labelWidth: 120,
        menuPosition: "right",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "起止时间",
            type: "datetimerange",
            prop: "datetimerange",
            span: 24,
          },
          {
            label: "定时",
            prop: "timer",
            span: 24,
          },
          {
            label: "失败策略",
            prop: "failureStrategy",
            type: "radio",
            dicData: failureStrategyList,
          },
          {
            label: "通知策略",
            prop: "warningType",
            type: "select",
            dicData: warningTypeList,
          },
          {
            label: "流程优先级",
            prop: "processInstancePriority",
            type: "select",
            dicData: processInstancePriorityList,
          },
          {
            label: "Worker分组",
            prop: "workerGroup",
            props: {
              label: "name",
              value: "id",
            },
            type: "select",
            dicData: [],
          },
        ],
      },
      crontab: "0 0 * * * ? *",
      i18n: i18n.globalScope.LOCALE,
      exectPreview: [],
      copyObj: {},
      copyOption: {
        size: "small",
        labelWidth: 90,
        menuPosition: "right",
        submitBtn: false,
        emptyBtn: false,
        group: [
          {
            label: "基础信息",
            prop: "baseInfo",
            column: [
              {
                label: "名称",
                prop: "name",
                rules: [
                  {
                    required: true,
                    message: "请输入名称",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "描述",
                prop: "description",
                type: "textarea",
                span: 24,
              },
            ],
          },
          {
            label: "数据来源",
            prop: "dataRelation",
            column: [
              {
                label: "数据源",
                prop: "relation",
                span: 24,
              },
            ],
          },
        ],
      },
      metadataCategorys: [],
      scene: 3,
      currentRow: null,
    };
  },
  mounted() {
    this.getVersions();
    this.setWorkGroup();
    this.getMetadataCategory();
    //this.getAllMetadataPackages();
  },
  methods: {
    ...mapActions("dag", [
      "getProcessListP",
      "saveMetadataTask",
      "updateMetadataTask",
      "editProcessState",
      "getStartCheck",
      "deleteDefinition",
      "processStart",
      "previewSchedule",
      "createSchedule",
      "getProcessDetails",
    ]),
    ...mapActions("security", ["getWorkerGroupsAll"]),
    onUpdateTiming() {
      this.onLoad();
      this.timeFormVisible = false;
    },
    closeTiming() {
      this.timeFormVisible = false;
    },
    getAllMetadataPackages() {
      getAllMetadataPackages().then((res) => {
        this.option.group[0].column[4].dicData = res.data.data;
      });
    },
    getMetadataCategory() {
      getMetadataCategory().then((res) => {
        console.log(res);
        this.metadataCategorys = res.data.data || [];
      });
    },
    transformDate(time) {
      if (!time) return "-";
      let date = new Date(time);
      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDay() < 10 ? "0" + date.getDay() : date.getDay();
      let hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    setWorkGroup() {
      let _t = this;
      this.getWorkerGroupsAll().then((res) => {
        res.forEach((item) => {
          _t.workGroupList.push(item);
        });
        _t.runOption.column[4]["dicData"] = _t.workGroupList;
        _t.timeOption.column[5]["dicData"] = _t.workGroupList;
      });
    },
    /*
     * 重置缓存数据
     */
    beforeClose(done) {
      done();
      this.catalogForm.databaseType = "";
      this.catalogForm.database = "";
      this.catalogForm.version = "";
      this.catalogForm.name = "";
      this.catalogForm.metastoreUri = "";
      this.catalogForm.termGuids = "";
      this.databases = [];
    },
    handleRowSave(row, done, loading) {
      let databaseType = this.datasourceTypeList.filter((item) => {
        return item["value"] === this.catalogForm.databaseType;
      });
      this.form.processDefinitionJson.globalParams[0].value =
        databaseType[0]["label"];
      this.form.processDefinitionJson.globalParams[1].value =
        databaseType[0]["value"]; // datasourtype的key值

      let database = this.databases.filter((item) => {
        return item["value"] === this.catalogForm.database;
      });
      this.form.processDefinitionJson.globalParams[2].value =
        database[0]["label"];
      this.form.processDefinitionJson.globalParams[3].value =
        database[0]["value"]; // datasourcename的key值
      this.form.processDefinitionJson.tasks[0].params.httpParams[0].value =
        this.catalogForm.database;
      this._addParam({
        termGuids: {
          globalParam: "termGuids",
          httpParam: "termGuids",
        },
      });
      if (this.catalogForm.databaseType === "HIVE") {
        if (!this.catalogForm.metastoreUri) {
          this.$message({
            type: "error",
            message: "请填写Hive Metastore",
          });
          loading();
          return;
        }
        this._addParam({
          metastoreUri: {
            globalParam: "metastoreuri",
            httpParam: "metastoreUri",
          },
        });
      } else {
        this.form.processDefinitionJson.tasks[0].params.httpParams.forEach(
          (item, index) => {
            if (item.prop === "metastoreUri") {
              this.form.processDefinitionJson.tasks[0].params.httpParams.splice(
                index,
                1
              );
            }
          }
        );
        this.form.processDefinitionJson.globalParams.forEach((item, index) => {
          if (item.prop === "metastoreuri") {
            this.form.processDefinitionJson.globalParams.splice(index, 1);
          }
        });
      }
      //
      this.form.processDefinitionJson.tasks[0].name = this.catalogForm.name;
      this.form.processDefinitionJson.tasks[0].params.url =
        this.catalogForm.version;
      this.form.processDefinitionJson.globalParams[4].value =
        this.catalogForm.version; //保存version参数编辑时候使用
      let version = this.versions.filter((item) => {
        return item["value"] === this.catalogForm.version;
      });
      this.form.name = row.name;
      this.form.description = row.description;
      this._saveTask(done, loading);
    },
    _saveTask(done, loading) {
      this.saveMetadataTask(this.form)
        .then((res) => {
          loading();
          if (res && res.code === 0) {
            done();
            this.$message({
              type: "success",
              message: "创建成功",
            });
            this.onLoad();
          } else {
            this.$message({
              type: "error",
              message: res["msg"] || "创建失败",
            });
          }
        })
        .catch((e) => {
          loading();
          // this.saving = false;
        });
    },
    _addParam(adds) {
      Object.keys(adds).forEach((key) => {
        let addParams = true,
          names = adds[key];
        this.form.processDefinitionJson.globalParams.forEach((item) => {
          let globalParamKey = names.globalParam;
          if (item.prop === globalParamKey) {
            item.value = this.catalogForm[key];
            addParams = false;
          }
        });
        if (addParams) {
          this.form.processDefinitionJson.globalParams.push({
            prop: names.globalParam,
            direct: "IN",
            type: "VARCHAR",
            value: this.catalogForm[key],
          });
        }
        let add = true;
        this.form.processDefinitionJson.tasks[0].params.httpParams.forEach(
          (item) => {
            let httpParamKey = names.httpParam;
            if (item.prop === httpParamKey) {
              item.value = this.catalogForm[key];
              add = false;
            }
          }
        );
        if (add) {
          this.form.processDefinitionJson.tasks[0].params.httpParams.push({
            prop: names.httpParam,
            httpParametersType: "PARAMETER",
            value: this.catalogForm[key],
          });
        }
      });
    },
    handleTaskCopy(row) {
      let _t = this;
      this.$refs.crud.rowAdd();
      setTimeout(() => {
        _t.catalogForm.databaseType =
          row["globalParamMap"]["datasourcetypekey"];
        _t.datasourceTypeChange(row);
        _t.catalogForm.name = row["name"];
        _t.catalogForm.description = row["description"];
        _t.catalogForm.version = row["globalParamMap"]["version"];
        _t.catalogForm.metastoreUri = "";
        _t.catalogForm.termGuids = "";
        row.globalParamList.forEach((item) => {
          if (item.prop === "metastoreuri") {
            _t.catalogForm.metastoreUri = item.value;
          }
          if (item.prop === "termGuids") {
            _t.catalogForm.termGuids = item.value;
          }
        });
      }, 0);
    },
    handleEdit(_row) {
      let _t = this;
      // this.catalogForm.databaseType = row['globalParamMap']['datasourcetypekey'];
      //  this.datasourceTypeChange(row);
      // this.catalogForm.version = row['globalParamMap']['version'];
      this.getProcessDetails(_row.id).then((res) => {
        let row = res;
        this.$refs.crud.rowEdit(row);
        setTimeout(() => {
          _t.catalogForm.databaseType =
            row["globalParamMap"]["datasourcetypekey"];
          _t.datasourceTypeChange(row);
          _t.catalogForm.name = row["name"];
          _t.catalogForm.description = row["description"];
          _t.catalogForm.version = row["globalParamMap"]["version"];
          _t.catalogForm.metastoreUri = "";
          _t.catalogForm.termGuids = "";
          row.globalParamList.forEach((item) => {
            if (item.prop === "metastoreuri") {
              _t.catalogForm.metastoreUri = item.value;
            }
            if (item.prop === "termGuids") {
              _t.catalogForm.termGuids = item.value;
            }
          });
          // _t.catalogForm.metastoreUri =
        }, 0);
      });
    },
    rowUpdate(row, index, done, loading) {
      let databaseType = this.datasourceTypeList.filter((item) => {
        return item["value"] === this.catalogForm.databaseType;
      });
      this.form.processDefinitionJson.globalParams[0].value =
        databaseType[0]["label"];
      this.form.processDefinitionJson.globalParams[1].value =
        databaseType[0]["value"]; // datasourtype的key值

      let database = this.databases.filter((item) => {
        return item["value"] === this.catalogForm.database;
      });
      this.form.processDefinitionJson.globalParams[2].value =
        database[0]["label"];
      this.form.processDefinitionJson.globalParams[3].value =
        database[0]["value"]; // datasourcename的key值
      this.form.processDefinitionJson.tasks[0].params.httpParams[0].value =
        this.catalogForm.database;
      this._addParam({
        termGuids: {
          globalParam: "termGuids",
          httpParam: "termGuids",
        },
      });
      if (this.catalogForm.databaseType === "HIVE") {
        if (!this.catalogForm.metastoreUri) {
          this.$message({
            type: "error",
            message: "请填写Hive Metastore",
          });
          loading();
          return;
        }
        this._addParam({
          metastoreUri: {
            globalParam: "metastoreuri",
            httpParam: "metastoreUri",
          },
        });
      } else {
        this.form.processDefinitionJson.tasks[0].params.httpParams.forEach(
          (item, index) => {
            if (item.prop === "metastoreUri") {
              this.form.processDefinitionJson.tasks[0].params.httpParams.splice(
                index,
                1
              );
            }
          }
        );
        this.form.processDefinitionJson.globalParams.forEach((item, index) => {
          if (item.prop === "metastoreuri") {
            this.form.processDefinitionJson.globalParams.splice(index, 1);
          }
        });
      }
      //
      this.form.processDefinitionJson.tasks[0].name = this.catalogForm.name;
      this.form.processDefinitionJson.tasks[0].params.url =
        this.catalogForm.version;
      this.form.processDefinitionJson.globalParams[4].value =
        this.catalogForm.version; //保存version参数编辑时候使用
      let version = this.versions.filter((item) => {
        return item["value"] === this.catalogForm.version;
      });
      this.form["id"] = row["id"];
      this.form.name = row.name;
      this.form.processDefinitionJson.tasks[0].name = this.form.name;
      this.form.description = row.description;
      this._updateTask(done);
    },
    _updateTask(done) {
      this.updateMetadataTask(this.form)
        .then((res) => {
          if (res && res.code === 0) {
            this.$message({
              type: "success",
              message: "修改成功",
            });
            done();
            this.onLoad();
          } else {
            this.$message({
              type: "error",
              message: res["msg"] || "修改失败",
            });
          }
        })
        .catch((e) => {
          this.saving = false;
        });
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.deleteDefinition({
            processDefinitionId: row.id,
          })
            .then((res) => {
              this.$message.success(res.msg);
              this.onLoad();
            })
            .catch((e) => {
              // this.$message.error(e.msg || '')
            });
        })
        .then(() => {
          // this.onLoad(this.page);
          // this.$message({
          //   type: "success",
          //   message: "操作成功!"
          // });
        });
    },
    /**
     * Offline
     */
    _downline(item) {
      this._upProcessState({
        processId: item.id,
        releaseState: "OFFLINE",
      });
    },
    _poponline(item) {
      this._upProcessState({
        processId: item.id,
        releaseState: "ONLINE",
      });
    },
    /**
     * Edit state
     */
    _upProcessState(o) {
      this.editProcessState(o)
        .then((res) => {
          this.$message.success(res.msg);
          $("body").find(".tooltip.fade.top.in").remove();
          this.onLoad();
        })
        .catch((e) => {
          // this.$message.error(e.msg || '')
        });
    },
    _start(item) {
      this.getStartCheck({ processDefinitionId: item.id })
        .then((res) => {
          // this.startData = item;
          // this.startDialog = true
          this.workFlowName = item["name"];
          this.processDefinitionId = item["id"];
          this.runFormVisible = true;
        })
        .catch((e) => {
          // this.$message.error(e.msg || '')
        });
    },
    datasourceNameChange() {
      console.log(this.catalogForm.database);
    },
    datasourceTypeChange(row) {
      let _t = this;
      this.databases = [];
      let obj = {
        type: this.catalogForm.databaseType,
      };
      getDataBase(obj).then((res) => {
        if (res["status"] === 200) {
          res["data"]["data"].forEach((item) => {
            let obj = {
              value: item["id"] + "",
              label: item["name"],
            };
            this.databases.push(obj);
          });
          this.$nextTick(() => {
            if (row) {
              _t.catalogForm.database =
                row["globalParamMap"]["datasourcenamekey"] + "";
            }
          });
        }
      });
    },
    getVersions() {
      let obj = {
        code: "metadata_inpect",
      };
      getDictionary(obj).then((res) => {
        if (res["status"] === 200) {
          res["data"]["data"].forEach((item) => {
            item["label"] = item["dictValue"];
            item["value"] = item["dictKey"];
            this.versions.push(item);
          });
          // this.versions = res['data']['data']
        }
      });
    },
    runFormAdd() {
      this.runFormVisible = true;
    },
    runSubmit(done) {
      let obj = {
        processDefinitionId: this.processDefinitionId,
        failureStrategy: this.runObj["failureStrategy"],
        processInstancePriority: this.runObj["processInstancePriority"],
        warningType: this.runObj["warningType"],
        workerGroup: this.runObj["workerGroup"],
        warningGroupId: 0, // 改造的页面没有这个字段，接口还需要传默认传0
      };
      this.processStart(obj)
        .then((res) => {
          done();
          if (res["code"] === 0) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.runFormVisible = false;
          } else {
            // this.$message.error(e.msg || '')
          }
        })
        .catch((e) => done());
    },
    showPreviewClick() {
      let obj = {
        schedule: JSON.stringify({
          startTime: this.transformDate(this.timeObj.datetimerange[0]),
          endTime: this.transformDate(this.timeObj.datetimerange[1]),
          crontab: this.crontab,
        }),
      };
      this.previewSchedule(obj).then((res) => {
        this.exectPreview = res;
        this.showPreview = true;
      });
    },
    timeManage(item) {
      this.$router.push({
        path: `/projects/definition/list/timing/${item["id"]}?scene=${this.scene}`,
      });
    },
    timeFormAdd(item) {
      this.getWorkerGroupsAll();
      this.processDefinitionId = item["id"];
      this.currentRow = {
        item: item,
        type: "timing",
      };
      this.timeFormVisible = true;
    },
    timeSubmit(done) {
      let obj = {
        schedule: JSON.stringify({
          startTime: this.transformDate(this.timeObj.datetimerange[0]),
          endTime: this.transformDate(this.timeObj.datetimerange[1]),
          crontab: this.crontab,
        }),
        processDefinitionId: this.processDefinitionId,
        failureStrategy: this.timeObj["failureStrategy"],
        processInstancePriority: this.timeObj["processInstancePriority"],
        warningType: this.timeObj["warningType"],
        workerGroup: this.timeObj["workerGroup"],
        warningGroupId: 0, // 改造的页面没有这个字段，接口还需要传默认传0
      };
      this.createSchedule(obj)
        .then((res) => {
          done();
          if (res["code"] === 0) {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.timeFormVisible = false;
          } else {
            // this.$message.error(e.msg || '')
          }
        })
        .catch((e) => done());
    },
    copyFormAdd() {
      this.copyFormVisible = true;
    },
    copySubmit() {},
    changeCron(val) {
      this.cron = val;
    },
    onLoad() {
      // if(page){
      //   this.searchParams.pageNo = page.currentPage;
      // } else {
      //   this.searchParams.pageNo = 1;
      // }
      this.taskLoading = true;
      this.getProcessListP({
        pageSize: this.page.pageSize,
        pageNo: this.page.currentPage,
        type: this.scene,
        searchVal: "",
        userId: "",
      }).then((res) => {
        if (res) {
          this.page.total = res["total"];
          res["totalList"].forEach((item) => {
            item["dsType"] = item["globalParamMap"]["datasourcetype"];
            item["sourceInfo"] = item["globalParamMap"]["datasourcename"];
          });
          this.tableData = res["totalList"];
        }
        this.taskLoading = false;
      });
    },
    // getTaskList() {
    //   getTaskList().then( res => {
    //     this.tableData = res['data'];
    //   });
    // }
  },
};
</script>

<style lang="scss">
.task-relation-select {
  display: flex;
  justify-content: space-between;
  .el-select {
    width: 49% !important;
  }
}
</style>

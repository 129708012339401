<template>
    <management :blacklist="true"></management>
</template>
<script>
import { getMetaCommonList, delMetaCommon, getMetaCommonInfo, updateMetaCommon } from '@/api/busiMode/metaCommon';
import management from './management.vue';
export default {
    components: { management },

    data() {
        return {};
    },
    mounted() {},
    created() {},
    methods: {
        jrhmd(row) {
            this.$confirm('确认将当前企业加入黑名单?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    // let obj = {};
                    // Object.keys(row).forEach((ele) => {
                    //   if (!ele.includes("$")) {
                    //     obj[ele] = row[ele];
                    //   }
                    // });
                    // console.log(obj);
                    //obj.status = 0;
                    let r = {
                        pk: 'id',
                        id: row.id,
                    };
                    getMetaCommonInfo(this.model, r).then(res => {
                        if (res.data.code == 200) {
                            let form = res.data.data;
                            form.status = 0;
                            let o = {
                                update: form,
                                primaryKeyValue: form.id,
                                primaryKey: 'id',
                            };
                            updateMetaCommon(this.model, o).then(res => {
                                this.$message({
                                    type: 'success',
                                    message: '操作成功!',
                                });
                                this.onLoad(this.page);
                            });
                        }
                    });
                })
                .catch(() => {});
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__body {
    padding-top: 0px;
}
/deep/ .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 15px;
}
.enterDetail /deep/ .el-dialog__body {
    padding: 0px;
}
/deep/ .el-drawer__body {
    padding-top: 0px;
    overflow-x: hidden !important;
}
/deep/ .el-drawer__header {
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 0px;
    padding-bottom: 15px;
}
/deep/ .avue-crud__menu {
    display: none;
}
</style>

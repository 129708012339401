<template>
    <div>
        <div class="mar-t-10">
            <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" @row-click="handleRowClick" @on-load="onLoad">
                <template slot-scope="{ row }" slot="company_name">
                    <a class="cur-p " style="color: #1976d3;">{{ row.company_name }}</a>
                </template>
            </avue-crud>
            <el-dialog :visible.sync="detailDia" :close-on-click-modal="false" width="60%" class="enterDetail" destroy-on-close append-to-body>
                <div slot="title" class="header-title">
                    <span class="fts-16">企业详情</span>
                </div>
                <archives @close="close" :currentRow="currentRow"></archives>
            </el-dialog>
        </div>
    </div>
</template>

<script>
const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

import { getMetaCommonList, getMetaCommonInfo } from '@/api/busiMode/metaCommon';
import archives from '../../enterprise/archives.vue';

export default {
    components: { archives },

    data() {
        return {
            detailDia: false,
            currentRow: {},
            option: {
                tip: false,
                height: viewPortHeight - 340,
                searchShow: false,
                searchMenuSpan: 6,
                border: true,
                index: false,
                refreshBtn: false,
                columnBtn: false,
                searchBtn: false,
                labelWidth: 120,
                menuWidth: 250,
                viewBtn: false,
                dialogClickModal: false,
                delBtn: false,
                editBtn: false,
                addBtn: false,
                cancelBtn: false,
                menu: false,
                page: false,
                column: [
                    {
                        overHidden: true,
                        label: '房间名称',
                        prop: 'room_name',
                        align: 'left',
                        hide: false,
                        fixed: true,
                        width: 180,
                    },

                    // {
                    //   width: 200,
                    //   overHidden: true,
                    //   label: "房间编号",
                    //   align: "left",
                    //   prop: "room_code",
                    //   hide: false,
                    // },
                    {
                        overHidden: true,
                        label: '入驻企业',
                        align: 'left',
                        slot: true,
                        prop: 'company_name',
                        hide: false,
                        width: 220,
                    },
                    {
                        width: 80,
                        overHidden: true,
                        label: '出租状态',
                        prop: 'status',
                        dicData: [
                            {
                                value: 0,
                                label: '未出租',
                            },
                            {
                                value: 1,
                                label: '已出租',
                            },
                            {
                                value: 2,
                                label: '已售',
                            },
                            {
                                value: 3,
                                label: '自用',
                            },
                        ],
                    },
                    {
                        width: 120,
                        overHidden: true,
                        label: '是否拥有产权',
                        prop: 'has_ownership',
                        dicData: [
                            {
                                value: 0,
                                label: '否',
                            },
                            {
                                value: 1,
                                label: '是',
                            },
                        ],
                    },
                    {
                        width: 120,
                        overHidden: true,
                        label: '产权面积(m²)',
                        prop: 'ownership_area',
                        hide: false,
                        align: 'right',
                    },
                    {
                        width: 100,
                        overHidden: true,
                        label: '租赁期限',
                        prop: 'floorage',
                        hide: true,
                    },
                    {
                        width: 120,
                        overHidden: true,
                        label: '出租单价(元/月)',
                        prop: 'rental_unit_price_month',
                        hide: true,
                        align: 'right',
                    },

                    {
                        width: 120,
                        overHidden: true,
                        label: '租赁开始日期',
                        prop: 'start_date',
                        hide: false,
                        align: 'right',
                    },
                    {
                        width: 120,
                        overHidden: true,
                        label: '租赁结束日期',
                        prop: 'end_date',
                        hide: false,
                        align: 'right',
                    },
                    {
                        width: 140,
                        overHidden: true,
                        label: '出租单价(元/㎡/日)',
                        prop: 'rental_unit_price_day',
                        hide: false,
                        align: 'right',
                    },
                    {
                        width: 120,
                        overHidden: true,
                        label: '租金收入(万元)',
                        prop: 'fee_type_1_amounts',
                        hide: false,
                        align: 'right',
                    },
                    {
                        width: 120,
                        overHidden: true,
                        label: '物业费(万元)',
                        prop: 'fee_type_2_amounts',
                        hide: false,
                        align: 'right',
                    },

                    {
                        width: 120,
                        overHidden: true,
                        label: '其他收入(万元)',
                        prop: 'fee_type_4_amounts',
                        hide: false,
                        align: 'right',
                    },

                    {
                        width: 120,
                        overHidden: true,
                        label: '用电量(kW·h)',
                        prop: 'sum_power_consumption',
                        hide: false,
                    },
                    {
                        width: 120,
                        overHidden: true,
                        label: '用水量(吨)',
                        prop: 'sum_water_consumption, #',
                        hide: false,
                        align: 'right',
                    },
                    {
                        width: 120,
                        overHidden: true,
                        label: '空调(台)',
                        prop: 'sum_energy_conservation #',
                        hide: false,
                        align: 'right',
                    },
                ],
            },
            loading: true,
            model: 'wisdom_park.v_wp_room_stat',
            data: [],
            buildObj: {},
        };
    },
    props: {
        floor: {
            type: Object,
            default: function () {
                return {};
            },
        },
        buildId: {
            type: String,
            default: function () {
                return '';
            },
        },
    },
    watch: {
        floor: {
            immediate: true,
            handler(val) {
                if (val) {
                    this.onLoad();
                }
            },
        },
    },
    computed: {},
    mounted() {
        this.onLoad();
    },
    methods: {
        close() {
            this.detailDia = false;
            this.currentRow = {};
            this.onLoad();
        },
        onLoad() {
            this.loading = true;
            let body = {
                current: 1,
                size: 50,
            };
            body.wheres = [
                { field: 'building_id', value: this.buildId },
                { field: 'floor_id', value: this.floor.id },
                { field: 'is_cancel', value: 0 },
            ];
            getMetaCommonList(this.model, body).then(res => {
                const data = res.data.data;
                this.data = data.records;
                this.loading = false;
            });
        },
        handleRowClick(e) {
            if (e.company_id) {
                console.log(e);
                let r = {
                    pk: 'id',
                    id: e.company_id,
                };
                getMetaCommonInfo('wisdom_park.v_wp_company_building_code_stat', r).then(res => {
                    this.currentRow = res.data.data;
                    this.detailDia = true;
                });
            }
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
/deep/ .avue-crud__menu {
    display: none;
}
/deep/ .el-table th.is-leaf {
    padding-left: 15px;
    padding-right: 15px;
}
</style>

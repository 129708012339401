<template>
  <basic-container>
    <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" :search.sync="search" :page.sync="page" @on-load="onLoad" @search-change="searchChange" @search-reset="searchReset" @row-del="rowDel" @refresh-change="refreshChange">
      <template slot="menuLeft" slot-scope="scope">
        <el-button type="primary" size="small" icon="el-icon-plus" @click="openDislog('add')">添加二房东 </el-button>
      </template>
      <template slot-scope="{ row }" slot="menu">
        <template>
          <el-button type="text" size="small" class="none-border" icon="el-icon-house" @click="openRoomDio(row)">房间管理 </el-button>
          <el-button type="text" size="small" class="none-border" icon="el-icon-office-building" @click="showCompany(row.company_id)">企业管理 </el-button>
        </template>
      </template>
    </avue-crud>
    <el-dialog :visible.sync="detailDia" :close-on-click-modal="false" :with-header="false" :top="'8vh'" :body-style="{ padding: '0px' }" width="50%">
      <div slot="title" class="header-title">
        <span class="fts-16">{{ title }}</span>
      </div>
      <div class="mar-t-10">
        <el-row class="pad-r-20">
          <el-col :span="24" class="pad-l-6 pad-r-8">
            <el-form label-position="right" label-width="100px" class="pad-l-5 pad-r-5">
              <el-form-item
                label="企业名称:"
                :rules="[
                  {
                    required: true,
                    message: '请选择企业名称',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-select v-model="ruleForm.company_id" filterable placeholder="请选择企业名称" size="small" @change="changeCompany" required>
                  <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"> </el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </el-col>
          <el-col :span="24" class="mar-t-10f">
            <avue-form :option="formOption" v-model="roleForm"> </avue-form>
          </el-col>
        </el-row>
      </div>
      <el-row class="dialog-footer pad-10 pad-r-30">
        <el-button class="pull-right" @click="detailDia = false" size="small">取 消</el-button>
        <el-button type="primary" :loading="saveLoading" class="pull-right mar-r-10" @click="saveSublessor" size="small">确 定</el-button>
      </el-row>
    </el-dialog>

    <el-drawer :visible.sync="roomListDia" size="60%" :withHeader="false" :close-on-click-modal="false" :destroy-on-close="true" append-to-body>
      <el-header class="bor-b" height="50px">
        <el-page-header class="lh-50" @back="back" content="房间列表"></el-page-header>
      </el-header>
      <roomsForCompany :companyId="companyId"></roomsForCompany>
    </el-drawer>

    <!-- 显示二房东下的企业列表 -->
    <!-- <el-drawer :visible.sync="showCompanyDialog" size="60%" :withHeader="false" :close-on-click-modal="false" :destroy-on-close="true" append-to-body>
      <el-header class="bor-b" height="50px">
        <el-page-header class="lh-50" @back="back" content="房间列表"></el-page-header>
      </el-header>

      <avue-crud :option="companyOption" :table-loading="companyLoading" :data="companyList" :page.sync="page" @on-load="onLoad"> </avue-crud>
    </el-drawer> -->

    <CompanyList ref="companyCpt" :id="companyId" />
  </basic-container>
</template>

<script>
import { option } from "./option"
import { getMetaCommonList, delMetaCommon, updateMetaCommon, saveMetaCommon } from "@/api/busiMode/metaCommon"
import { getRoleTree } from "@/api/system/role"
import { mapGetters, mapState } from "vuex"
import roomsForCompany from "./roomsForCompany"
import CompanyList from "./CompanyList.vue"

export default {
  components: { roomsForCompany, CompanyList },
  computed: {
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.code_add, false),
        viewBtn: this.vaildData(this.permission.code_view, false),
        delBtn: this.vaildData(this.permission.code_delete, false),
        editBtn: this.vaildData(this.permission.code_edit, false),
      }
    },
  },

  data() {
    return {
      query: {},
      option: option,
      loading: true,
      saveLoading: false,
      model: "wisdom_park.wp_sublessor_company",
      companyList: [],
      data: [],
      vrDrawer: false,
      page: {
        current: 1,
        total: 10,
        pageSize: 10,
      },
      dialogNexttick: true,
      searchText: "",
      detailDia: false,
      currentHouse: {},
      title: "",
      onlyRead: false,
      search: {
        online_status: "",
        name: "",
        status: "",
      },
      drawer: false,
      roleForm: {},
      ruleForm: {
        company_id: "",
        company_name: "",
      },
      formOption: {
        menuBtn: false,
        labelWidth: 100,
        colon: "false",
        column: [
          {
            label: "角色名称",
            prop: "roleId",
            multiple: true,
            type: "tree",
            dicData: [],
            props: {
              label: "title",
            },
            span: 24,
            checkStrictly: true,
            slot: true,
            rules: [
              {
                required: true,
                message: "请选择所属角色名称",
                trigger: "click",
              },
            ],
          },
          {
            label: "备注",
            span: 24,
            prop: "notes",
            type: "textarea",
          },
        ],
      },
      roomListDia: false,

      companyId: "",
    }
  },

  watch: {},
  mounted() {
    this.companyOption = {
      ...option,
      delBtn: false,
      searchShow: false,
      column: [
        {
          overHidden: true,
          label: "企业名称",
          align: "center",
          prop: "company_name",
          hide: false,
          search: false,
        },
      ],
    }
  },
  created() {
    this.getMetaCommonCompanyList()
    this.initData()
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  methods: {
    /**
     * 打开房间管理Model
     */
    openRoomDio(row) {
      this.companyId = row.company_id
      this.roomListDia = true
    },
    saveSublessor() {
      let roles = ""
      if (this.roleForm.roleId) {
        roles = this.roleForm.roleId.toString()
      }
      if (!this.ruleForm.company_id) {
        this.$message.error("请选企业")
        return false
      }
      if (!this.roleForm.roleId) {
        this.$message.error("请选角色")
        return false
      }

      let body = {
        company_id: this.ruleForm.company_id,
        company_name: this.ruleForm.company_name,
        roleIds: roles,
        notes: this.roleForm.notes,
      }
      this.saveLoading = true
      saveMetaCommon("wisdom_park.wp_sublessor_company", {
        values: body,
      }).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("新增成功")
          this.saveLoading = false
          this.detailDia = false
          this.refreshChange()
        }
      })
    },
    changeCompany() {
      let name = ""
      this.companyList.forEach((c) => {
        if (c.id == this.ruleForm.company_id) {
          name = c.name
        }
      })

      this.ruleForm.company_name = name
    },
    initData() {
      getRoleTree(this.userInfo.tenant_id).then((res) => {
        const column = this.findObject(this.formOption.column, "roleId")
        column.dicData = res.data.data
      })
    },
    getMetaCommonCompanyList() {
      let body = {
        current: 1,
        size: 1000,
        wheres: [{ field: "company_name" }],
      }
      // 状态（0:无效；1.已入驻；2.入驻中；3.退出中；4.已退出）

      // body.wheres = [{ field: 'status', op: 'IN', value: [0, 4] }];
      getMetaCommonList("wisdom_park.wp_company", body).then((res) => {
        const data = res.data.data
        this.companyList = data.records
      })
    },
    refreshChange() {
      this.onLoad(this.page)
    },

    searchChange(params, done) {
      this.query = params
      let wheres = []
      for (let key in params) {
        wheres.push({ field: key, op: "LIKE", value: params[key] })
      }
      let body = {
        current: 1,
        size: this.page.pageSize,
        wheres: wheres,
      }
      this.getMetaCommonList(body)
      done()
    },

    onLoad(page, params = {}) {
      this.loading = true
      let body = {
        current: 1,
        size: 10,
      }
      if (page) {
        body = {
          current: page.currentPage,
          size: page.pageSize,
        }
      }
      if (this.searchText) {
        body["wheres"] = [{ field: "name", op: "LIKE", value: this.searchText }]
      }
      this.getMetaCommonList(body)
    },
    searchReset() {
      this.query = {}
      this.onLoad(this.page)
    },
    getMetaCommonList(body) {
      getMetaCommonList(this.model, body).then((res) => {
        const data = res.data.data
        this.page.total = data.total

        this.data = data.records
        this.selectionClear()
        this.loading = false
      })
    },

    selectionClear() {
      this.selectionList = []
      this.$refs.crud.toggleSelection()
    },
    openDislog(type, row) {
      this.detailDia = true
      this.currentHouse = {}
      if (type == "update" || type == "view") {
        if (type == "view") {
          this.onlyRead = true
          this.title = "详情"
        } else {
          this.onlyRead = false
          this.title = "编辑"
        }
        this.$nextTick(() => {
          this.currentHouse = JSON.parse(JSON.stringify(row))
        })
      }
      if (type == "add") {
        this.title = "新增二房东"
        this.dialogNexttick = false
        this.$nextTick(() => {
          this.dialogNexttick = true
          this.currentHouse = {}
        })
      }
    },
    updateOnline(type, row) {
      this.$confirm("确定执行此操作?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          row.online_status = type
          let obj = {}
          Object.keys(row).forEach((ele) => {
            if (!ele.includes("$")) {
              obj[ele] = row[ele]
            }
          })
          if (obj.subject_type == "房间") {
            obj.subject_type = 0
          }
          if (obj.subject_type == "整栋") {
            obj.subject_type = 2
          }
          if (obj.online_status == "上线") {
            obj.online_status = 1
          }
          if (obj.online_status == "下线") {
            obj.online_status = 2
          }
          if (obj.status == "待租") {
            obj.status = 1
          }
          if (obj.status == "已租") {
            obj.status = 2
          }
          let o = {
            update: obj,
            primaryKeyValue: obj["id"],
            primaryKey: "id",
          }
          updateMetaCommon(this.model, o).then((res) => {
            if (res.data.code == 200) {
              this.onLoad()
            }
          })
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功!",
          })
        })
    },
    rowDel(row, index) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let pk = "id"
          delMetaCommon(this.model, { pk: pk, ids: [row[pk]] }).then((res) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            })
            this.query = {}
            this.onLoad(this.page)
          })
        })
        .catch(() => {})
    },
    /**
     * 关闭房间管理弹出层
     */
    back() {
      this.roomListDia = false
    },

    /**
     * 显示二房东下的企业
     * @param {二房东企业ID} id
     */
    showCompany(id) {
      console.log(id)
      this.companyId = id
      this.$refs.companyCpt.toggle()
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/ .avue-crud__pagination .el-pagination {
  display: inline-block !important;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
}
/deep/ .el-dialog__body {
  padding: 0px;
}

/deep/ .el-table td .is-left {
  padding-left: 20px;
}
/deep/ .el-select {
  width: 100%;
}
/deep/ .el-drawer__header {
  border-bottom: 1px solid #ebebeb;
  padding: 10px 20px;
  margin-bottom: 0px;
}
/deep/ .el-drawer__body {
  padding: 0px;
}
</style>

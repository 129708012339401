<template>
  <el-card style="padding: 20px; background: #fff" :title="curCatgName">
    <div slot="header" class="clearfix">
      <span class="fts-16"> {{ curCatgName }}</span>
      <el-button
        class="mar-t-8 fts-12"
        style="float: right; padding: 3px 0"
        type="text"
        @click="back"
      >
        <i class="el-icon-back cur-p fts-12 mar-r-5"></i>返回</el-button
      >
    </div>

    <avue-form
      :option="option"
      :upload-after="uploadAfter"
      v-model="form"
      ref="crud"
      @submit="rowSave"
      @on-load="onLoad"
    >
      <template slot="menuForm">
        <el-button
          type="primary"
          size="small"
          @click="$router.go(-1)"
          icon="el-icon-back"
          >返回</el-button
        >
      </template>
    </avue-form>
  </el-card>
</template>

<script>
import { getList, getDetail, add, update, remove } from "@/api/spcod/prnpolicy";
import { getMetaCommonList } from "@/api/busiMode/metaCommon";
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    let catlogCode = this.$router.currentRoute.query.catgCode || '';
    let includeIconUrl = false;
    ['PARK-NEWS' , 'PARK_ADMIN_MAP' , 'ZCFG' , 'CYFW'].forEach(
      item => {
        if(catlogCode.indexOf(item) > -1){
          includeIconUrl = true;
        }
      }
    );
    return {
      treeFilterText: "",
      curCatgId: 0,
      curCatgCode: "",
      curCatgName: "",
      defaultProps: {
        children: "subs",
        label: "name",
      },
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      option: {
        submitText: "保 存",
        height: "auto",
        calcHeight: 30,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        labelWidth: 120,
        index: true,
        viewBtn: true,
        dialogClickModal: false,
        column: [
          {
            label: "标题",
            prop: "title",
            span: 15,
            rules: [
              {
                required: true,
                message: "请输入标题",
                trigger: "blur",
              },
            ],
          },
          {
            label: "接收人",
            prop: "visualId",
            searchSpan: 12,
            type: "select",
            dicData: [
              {
                label: "所有人",
                value: 1,
              },
              {
                label: "企业管理员",
                value: 2,
              },
              {
                label: "管理员和员工",
                value: 3,
              },
            ],
            rules: [
              {
                required: true,
                message: "请选择新闻可见范围名称",
                trigger: "blur",
              },
            ],
            change: ({ value, column }) => {
              if (value == undefined || value == "") return;

              let ci = 0;
              this.option.column.forEach((c) => {
                if (c.prop == "deptIds") {
                  if (value == 1) {
                    this.option.column[ci].display = false;
                  } else {
                    this.option.column[ci].display = true;
                  }
                }
                ci += 1;
              });
              this.$forceUpdate();
            },
          },
          {
            label: "企业",
            prop: "deptIds",
            searchSpan: 12,
            type: "select",
            filterable: true,
            display: false,
            multiple: true,
            dicData: [],
            rules: [
              {
                required: true,
                message: "请选择新闻可见的企业",
                trigger: "blur",
              },
            ],
          },
          {
            label: "内容",
            prop: "content",
            component: "RichText",
            span: 24,
            minRows: 6,
            rules: [
              {
                required: true,
                message: "请输入内容，图文混编",
                trigger: "change",
              },
            ],
          },
          {
            label: "封面",
            prop: "iconUrl",
            component: "form-file",
            params: {
              accept: "image/*",
              multiple: false,
              max: 1,
              useLink: true
            },
            span: 24,
            rules: [
              {
                required: includeIconUrl,
                message: "请上传封面图片",
                trigger: "blur",
              },
            ],
            // type: "upload",
            // listType: "picture-card",
            // span: 24,
            // propsHttp: {
            //   res: "data",
            //   url: "link",
            //   name: "attachId",
            // },
            // tip: "只能上传jpg/png封面，且不超过500kb",
            // action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
          {
            label: "链接",
            prop: "policyLink",
            span: 24,
            rules: [
              {
                required: false,
                message: "请输入链接",
                trigger: "blur",
              },
            ],
          },
          {
            label: "发布时间",
            prop: "releaseTime",
            type: "date",
            span: 12,
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            pickerOptions: {
              rules: [
                {
                  required: false,
                  message: "请选择发布时间",
                  trigger: "blur",
                },
              ],
            },
          },
          {
            label: "跳转链接",
            span: 24,
            prop: "externalLinks",
            display: false
          },
          {
            label: "文件",
            span: 24,
            prop: "attaches",
            component: "form-file",
            params: {
              downloadView: true,
            },
            // type: "upload",
            // listType: "picture-card",
            // propsHttp: {
            //   url: "link",
            //   name: "originalName",
            //   res: "data",
            // },
            // tip: "只能上传文件,且不超过500kb",
            // action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      data: [],
    };
  },

  mounted() {
    let _this = this;
    _this.curCatgId = this.$router.currentRoute.query.catgId;
    _this.curCatgCode = this.$router.currentRoute.query.catgCode;
    _this.curCatgName = this.$router.currentRoute.query.curCatgName;

    if (_this.curCatgCode == "PARK-ADS") {
      for (var i = 0; i < _this.option.column.length; ) {
        let c = _this.option.column[i];
        if(c.prop === 'externalLinks'){
          c.display = true;
        }
        if (c.prop == "policyLink") {
          c.type = "select";
          c.label = "是否允许点击";
          c.dicData = [
            { value: "CLICK_ALLOWED", label: "允许点击" },
            { value: "CLICK_NOT_ALLOWED", label: "只展示" },
          ];
          c.rules[0].required = true;
          c.span = 12;
          i++;
        } else if (c.prop == "attaches") {
          _this.option.column.splice(i, 1);
        } else {
          i++;
        }
      }
    }
    if (_this.curCatgCode == "PARK-NOTICE") {
      for (var i = 0; i < this.option.column.length; i++) {
        let c = _this.option.column[i];
        if (c.prop == "files") {
          c.display = false;
        }
      }
    }

    this.getMetaCommonListcheckin();
  },
  computed: {
    ...mapGetters(["permission"]),
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.prnpolicy_add, false),
        viewBtn: this.vaildData(this.permission.prnpolicy_view, false),
        delBtn: this.vaildData(this.permission.prnpolicy_delete, false),
        editBtn: this.vaildData(this.permission.prnpolicy_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  watch: {
    treeFilterText(value) {
      this.$refs.tree.filter(value);
    },
  },
  methods: {
    back() {
      this.$router.push({
        path: "/spcod/prnpolicy",
      });
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    getMetaCommonListcheckin() {
      let body = {
        current: 1,
        size: 1000,
        wheres: [{ field: "status", value: 1 }],
      };
      console.log(body);
      getMetaCommonList("wisdom_park.wp_company", body).then((res) => {
        const data = res.data.data;
        if (data.records) {
          let arr = [];
          data.records.forEach((e) => {
            arr.push({
              label: e.dept_name,
              value: e.dept_id,
            });
          });

          this.option.column[2].dicData = arr;
        }
        this.$forceUpdate();
      });
    },
    rowSave(row, done, loading) {
      if (row.files && row.files.length > 0) {
        row.iconUrl = row.files[0].value;
      }
      row.catgId = this.curCatgId;

      if (row.visualId) {
        if (row.visualId == 1) {
          row.visualName = "所有人";
        }
        if (row.visualId == 2) {
          row.visualName = "企业管理员";
        }
        if (row.visualId == 3) {
          row.visualName = "管理员和员工";
        }
      }
      if (row.deptIds) {
        if (row.deptIds.length > 0) {
          row.deptIds = row.deptIds.toString();
        }
      }

      console.log(this.userInfo);
      row.createUserName = this.userInfo.nick_name;
      //createUserName
      console.log(row);
      add(row).then(
        () => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          // this.$router.go(-1);
          this.$router.push({ path: "/spcod/prnpolicy" });
          done();
        },
        (error) => {
          loading();
          window.console.log(error);
        }
      );
    },
    rowUpdate(row, index, done, loading) {
      update(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          loading();
          console.log(error);
        }
      );
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
        });
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getDetail(this.form.id).then((res) => {
          this.form = res.data.data;
        });
      }
      done();
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      getList(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
      });
    },
    uploadAfter(res, done, loading, column) {
      done();
      this.$message.success("上传成功");
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style></style>

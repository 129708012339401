<template>
  <el-tabs v-model="type" stretch>
    <el-form ref="form" :model="tableData" label-width="100px" size="mini">
      <el-form-item label="排序字段"> </el-form-item>
      <avue-dynamic
        style="padding: 0px 30px"
        v-model="tableData.sort"
        :children="tableSortDataOption"
      ></avue-dynamic>
      <el-form-item label="过滤条件"> </el-form-item>
      <avue-dynamic
        style="padding: 0px 30px"
        v-model="tableData.dicQueryConfig"
        :children="tableDataOption"
      ></avue-dynamic>
    </el-form>
  </el-tabs>
</template>
<script>
import { tableSetOption } from "../../../util/option";

export default {
  name: "modelTree",
  data() {
    return {
      tableSetForm: {},
      tableData: {
        dicQueryConfig: [],
        sort: [],
      },
      tableDataOption: {},
      tableSortDataOption: {},
      tableSetOption: tableSetOption,
      type: "dataSet",
    };
  },
  created() {},

  watch: {
    tableData: {
      handler(newValue, oldName) {
        console.log(newValue);
        if (newValue) {
          this.$emit("moreSet", newValue);
        }
      },
      deep: true,
    },
    options: {
      handler(newValue, oldName) {
        console.log(newValue.tableQuery);
        if (newValue.tableQuery || newValue.sort) {
          let dicQueryConfig = newValue.tableQuery.wheres || [];
          let sort = newValue.tableQuery.orders || [];
          let obj = {
            dicQueryConfig: dicQueryConfig,
            sort: sort,
          };
          this.tableData = obj;
        }
      },
      immediate: true,
      deep: true,
    },
  },
  props: {
    sortList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  mounted() {
    this.tableDataOption = {
      column: [
        { type: "input", prop: "field", label: "key", cell: true },
        {
          type: "input",
          prop: "value",
          label: "value",
          cell: true,
        },
      ],
    };
    this.tableSortDataOption = {
      column: [
        {
          type: "select",
          prop: "field",
          label: "key",
          cell: true,
          dicData: this.sortList,
          props: {
            label: "label",
            value: "prop",
          },
        },
        {
          type: "select",
          prop: "sort",
          label: "value",
          cell: true,
          dicData: [
            { label: "升序", value: "ASC" },
            { label: "降序", value: "DESC" },
          ],
          props: {
            label: "label",
            value: "value",
          },
        },
      ],
    };
  },
  methods: {},
};
</script>

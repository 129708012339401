import { render, staticRenderFns } from "./buildInfo.vue?vue&type=template&id=608c1b72&scoped=true"
import script from "./buildInfo.vue?vue&type=script&lang=js"
export * from "./buildInfo.vue?vue&type=script&lang=js"
import style0 from "./buildInfo.vue?vue&type=style&index=0&id=608c1b72&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "608c1b72",
  null
  
)

export default component.exports
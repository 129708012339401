<template>
    <div>
        <basic-container>
            <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" v-model="form" :page.sync="page" @selection-change="selectionChange" @refresh-change="refreshChange"
                       @on-load="onLoad" @search-change="searchChange">
                <template slot="menuLeft" slot-scope="scope">
                    <el-button v-if="!register" type="primary" size="small" icon="el-icon-plus" @click="fkyyy(0)">访客预约
                    </el-button>
                    <el-button v-if="register" type="primary" size="small" icon="el-icon-plus" @click="fkyyy(1)">访客登记
                    </el-button>
                    <!-- <el-input
            v-model="searchText"
            @keyup.native.enter="refreshChange"
            size="small"
            placeholder="请输入访客姓名"
            type="text"
            style="width: 250px"
          >
            <el-button
              class="mar-0 pad-0"
              size="small"
              @click="refreshChange"
              slot="append"
              icon="el-icon-search"
            ></el-button>
          </el-input> -->
                    <el-button class="mar-l-5" icon="el-icon-download" :loading="downLoading" @click="getMetaCommonListExport" size="small">导出</el-button>
                </template>
                <template slot-scope="scope" slot="menu">
                    <el-button type="text" size="small" icon="el-icon-view" class="none-border" @click="edit(scope.row)">查看
                    </el-button>
                    <el-button type="text" size="small" icon="el-icon-delete" class="none-border" @click="rowDel(scope.row)">删除
                    </el-button>
                </template>
            </avue-crud>
        </basic-container>
    </div>
</template>

<script>
import { getMetaCommonList, delMetaCommon, getMetaCommonListExport } from '@/api/busiMode/metaCommon';
import standardService from '@/api/metadata/standard';
import { mapGetters } from 'vuex';

export default {
    components: {},
    data() {
        return {
            searchText: '',
            form: {},
            activeName: '2',
            selectionList: [],
            loading: true,
            dialogVisible: false,
            detailDia: false,
            query: {},
            dialogType: '',
            size: '60%',
            title: '',
            downLoading: false,
            page: {
                size: 10,
                current: 1,
                total: 0,
            },
            option: {
                height: 'auto',
                dialogWidth: 900,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: true,
                menuWidth: 200,
                viewBtn: false,
                dialogClickModal: false,
                delBtn: false,
                editBtn: false,
                addBtn: false,
                cancelBtn: false,
                labelWidth: 120,
                column: [
                    {
                        width: 120,
                        overHidden: true,
                        label: '被访人',
                        prop: 'respondent_name',
                        hide: false,
                    },
                    {
                        width: 220,
                        overHidden: true,
                        label: '被访公司',
                        prop: 'company_name',
                        hide: false,
                    },
                    {
                        width: 150,
                        overHidden: true,
                        align: 'left',
                        label: '来访人姓名',
                        prop: 'visitor_name',
                        search: true,
                        hide: false,
                        searchLabelWidth: 120,
                    },
                    {
                        width: 150,
                        overHidden: true,
                        label: '来访人电话',
                        prop: 'contact',
                        search: true,
                        searchLabelWidth: 120,
                    },
                    {
                        width: 100,
                        overHidden: true,
                        align: 'left',
                        label: '来访人证件类型',
                        prop: 'id_card_type',
                        hide: false,
                        dicData: [
                            { label: '居民身份证', value: 1 },
                            { label: '士官证', value: 2 },
                            { label: '学生证', value: 3 },
                            { label: '驾驶证', value: 4 },
                            { label: '护照', value: 5 },
                            { label: '港澳通行证', value: 6 },
                        ],
                    },
                    {
                        width: 150,
                        overHidden: true,
                        align: 'left',
                        label: '来访人证件号码',
                        prop: 'id_card_no',
                        hide: false,
                    },
                    {
                        width: 150,
                        overHidden: true,
                        align: 'left',
                        label: '来访人单位',
                        prop: 'visitor_company',
                        hide: false,
                    },

                    {
                        width: 180,
                        overHidden: true,
                        label: '来访时间',
                        prop: 'arrival_time',
                        type: 'date',
                        format: 'yyyy年MM月dd日',
                        //valueFormat: "yyyy-MM-dd HH:mm:ss",
                    },
                    {
                        width: 220,
                        overHidden: true,
                        label: '访问事由',
                        prop: 'reason',
                        hide: false,
                    },
                    // {
                    //   width: 120,
                    //   overHidden: true,
                    //   label: "离开时间",
                    //   prop: "departure_time",
                    //   type: "date",
                    //   format: "yyyy年MM月dd日",
                    //   valueFormat: "yyyy-MM-dd HH:mm:ss",
                    // },
                    {
                        width: 120,
                        overHidden: true,
                        label: '通行方式',
                        prop: 'in_out_type',
                        dicData: [
                            { label: '二维码', value: 0 },
                            { label: '卡片', value: 1 },
                            { label: '人脸识别', value: 2 },
                            { label: '一键开闸', value: 3 },
                        ],
                    },

                    {
                        width: 80,
                        overHidden: true,
                        align: 'left',
                        label: '申请人',
                        prop: 'duty_person',
                    },
                    // {
                    //   width: 120,
                    //   overHidden: true,
                    //   label: "审核状态",
                    //   prop: "approval_status",
                    //   dicData: [
                    //     { label: "待提交", value: 1 },
                    //     { label: "审批中", value: 2 },
                    //     { label: "审批通过", value: 3 },
                    //     { label: "审批未通过", value: 4 },
                    //     { label: "已撤回", value: 5 },
                    //   ],
                    // },
                ],
            },
            model: 'wisdom_park.wp_visitor',
            data: [],

            ruleModel: {},
        };
    },
    computed: {
        ...mapGetters(['permission', 'userInfo']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.code_add, false),
                viewBtn: this.vaildData(this.permission.code_view, false),
                delBtn: this.vaildData(this.permission.code_delete, false),
                editBtn: this.vaildData(this.permission.code_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    mounted() {
        this.queryByModelCode();
    },
    props: {
        currentRow: {
            type: Object,
            default() {
                return {};
            },
        },
        register: {
            type: Boolean,
            default() {
                return true;
            },
        },
    },
    watch: {
        currentRow: {
            immediate: true,
            handler(val) {
                if (val.id) {
                    this.onLoad();
                }
            },
        },
    },
    methods: {
        getMetaCommonListExport() {
            this.downLoading = true;
            //let obj = this.getSearch();
            let body = {
                current: this.page.currentPage,
                size: this.page.pageSize,
                wheres: [
                    {
                        field: 'type',
                        value: 1,
                    },
                ],
            };
            if (this.searchText) {
                body['wheres'].push({
                    field: 'visitor_name',
                    op: 'LIKE',
                    value: this.searchText,
                });
            }
            if (this.currentRow.id) {
                body['wheres'].push({ field: 'company_id', value: this.currentRow.id });
            }
            getMetaCommonListExport('wisdom_park.v_stat_visitor_export_stat', body)
                .then(res => {
                    this.downLoading = false;
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.download = '访客' + '.xls';
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(e => {
                    this.downLoading = false;
                });
        },
        fkyyy(type) {
            this.$router.push({
                path: '/visitor/visitorOrder',
                query: {
                    type: type,
                },
            });
        },
        edit(obj) {
            console.log(obj);
            this.$router.push({
                path: '/visitor/visitorInfo',
                query: {
                    visitorId: obj.id,
                },
            });
        },
        update(obj) {
            this.$router.push({
                path: '/visitor/visitorOrder',
                query: {
                    visitorId: obj.id,
                },
            });
        },
        back() {
            this.detailDia = false;
            this.searchReset();
        },
        queryByModelCode() {
            standardService.getModelRule(this.model).then(res => {
                this.ruleModel = res;
            });
        },
        changName(row) {
            this.title = row.name;
        },

        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },

        selectionChange(list) {
            this.selectionList = list;
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        searchChange(params, done) {
            this.form = params;
            this.onLoad(params);
            done();
        },
        closeDia() {
            this.dialogVisible = false;
            this.refreshChange();
        },
        rowDel(row, index) {
            console.log(row);
            this.$confirm('是否删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let pk = 'id';
                    delMetaCommon(this.model, { pk: pk, ids: [row[pk]] }).then(res => {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        this.onLoad(this.page);
                    });
                })
                .catch(() => {});
        },
        getSearch() {
            let body = {
                current: this.page.currentPage,
                size: this.page.pageSize,
                orders: [{ field: 'create_time', sort: 'DESC' }],
                wheres: [
                    {
                        field: 'type',
                        value: 1,
                        wheres: [
                            // {
                            //   field: "create_user_id",
                            //   value: this.userInfo.id,
                            //   or: true,
                            // },
                        ],
                    },
                ],
            };
            if (this.userInfo.role_name.indexOf('administrator') < 0) {
                if (this.userInfo.dept_id) {
                    if (this.userInfo.dept_id.split(',').length > 1) {
                        let deptIds = this.userInfo.dept_id.split(',');
                        deptIds.forEach((e, i) => {
                            console.log(i, deptIds.length - 1);
                            if (i == deptIds.length - 1) {
                                body['wheres'][0]['wheres'].push({
                                    field: 'dept_id',
                                    value: e,
                                    or: true,
                                });
                            } else {
                                body['wheres'][0]['wheres'].push({
                                    field: 'dept_id',
                                    value: e,
                                });
                            }
                        });
                    } else {
                        body['wheres'][0]['wheres'].push({
                            field: 'dept_id',
                            value: this.userInfo.dept_id,
                        });
                    }
                }
            }
            if (this.form.visitor_name) {
                body['wheres'].push({
                    field: 'visitor_name',
                    op: 'LIKE',
                    value: this.form.visitor_name,
                });
            }
            if (this.form.contact) {
                body['wheres'].push({
                    field: 'contact',
                    op: 'LIKE',
                    value: this.form.contact,
                });
            }

            return body;
        },
        refreshChange() {
            this.onLoad(this.page);
        },
        onLoad() {
            this.loading = true;
            let body = this.getSearch();
            getMetaCommonList('wisdom_park.v_stat_visitor_export_stat', body).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                data.records.forEach(e => {
                    // this.options.forEach((o) => {
                    //   if (e.hight == o.value) {
                    //     e.hight = o.label;
                    //   }
                    // });
                });
                this.loading = false;
                this.selectionClear();
            });
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.deleteIcon {
    font-size: 18px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 1;
}
/deep/ .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 15px;
}
/deep/ .el-drawer__header {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 20px;
    margin-bottom: 0px;
}
/deep/ .el-drawer__body {
    padding: 0px;
}
.buildDetail /deep/ .el-dialog__body {
    padding: 0px;
}
</style>

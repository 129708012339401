<template>
  <basic-container>
    <avue-crud
      ref="table"
      :data="tableData"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      :search.sync="search"
      v-model="form"
      @search-change="searchChange"
      @on-load="onLoad"
      @refresh-change="onLoad"
      @row-save="saveTableForm"
      @row-update="(row, index, done, loading) => saveTableForm(row, done, loading)"
      @row-del="rowDel"
    >
      <template slot="user_idForm" slot-scope="{disabled,size}">
        <!-- :params="{ tenantId: '000000' }" -->
        <model-search-select
          class="dis-b w-100p"
          v-model="form.user_id"
          format="{name}_{deptName}_{postName}"
          :fillModel.sync="form"
          :fills="[
            { source: 'name', target: 'delivery_name' },
            { source: 'phone', target: 'delivery_phone' }
          ]"
        >
          <template slot-scope="{ item }">
            <span style="float: left"
              >{{ item.name
              }}{{ item.postName ? `(${item.postName})` : "" }}</span
            >
            <span style="float: right; color: #8492a6; font-size: 13px">{{
              item.deptName
            }}</span>
          </template>
        </model-search-select>
      </template>
    </avue-crud>
  </basic-container>
</template>
<script>
import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  delMetaCommon,
  getPicListByIds
} from "@/api/busiMode/metaCommon";
import * as dictService from "@/api/system/dictbiz";
import dayjs from 'dayjs';
export default {
  data(){
    return {
      form: {

      },
      model: 'wisdom_park.wp_spcod_delivery_man',
      tableLoading: false,
      tableData: [],
      search: {},
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableOption: {
        height: 'auto',
        labelPosition: 'top',
        searchMenuPosition: 'right',
        searchLabelPosition: 'right',
        searchMenuSpan: 24,
        border: true,
        column: [
          {
            label: "配送员",
            prop: "delivery_name",
            span: 12,
            display: false
          },
          {
            label: "配送员",
            prop: "user_id",
            span: 12,
            hide: true,
            showColumn: false
          },
          {
            label: "配送员电话",
            prop: "delivery_phone",
            span: 12,
          },
          // {
          //   label: "创建时间",
          //   prop: "create_time",
          //   span: 12,
          //   display: false
          // },
          // {
          //   label: "更新时间",
          //   prop: "update_time",
          //   span: 12,
          //   display: false
          // },
        ]
      }
    }
  },
  mounted(){
    
  },
  methods: {
    onLoad(){
      let wheres = [];
      Object.keys(this.search).forEach(
        name => {
          if((this.search[name] ?? '') !== '' && name[0] !== '$'){
            if(name === 'name'){
              wheres.push({field: name , op: 'LIKE' , value: this.search[name]})
            }else{
              wheres.push({field: name , value: this.search[name]})
            }
          }
        }
      )
      
      this.tableData = [];
      this.tableLoading = true;
      return getMetaCommonList(this.model , {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres
      }).then(
        res => {
          this.tableLoading = false;
          if(res.data && res.data.code === 200){
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
          }else{
            this.tableData = [];
            this.page.total = 0;
          }
        }
      ).catch(e => {
        this.tableLoading = false;
      })
    },
    searchChange(params, done) {
      this.page.currentPage = 1;
      this.onLoad(params)
        .then((res) => {
          done();
        })
        .catch((e) => {
          done();
        });
    },
    saveTableForm(row, done, loading) {
      // console.log(row);
      let body = {
        ...row
      };

      Object.keys(body).forEach(
        key => {
          if(key[0] === '$'){
            delete body[key];
          }
        }
      );
     
      if(row.id){
        body = {
          update: {
            ...body
          },
          primaryKeyValue: row.id,
          primaryKey: "id",
        };
      }else{
        body = {
          values: {
            ...body,
            create_time: dayjs().format('YYYY-MM-DD HH:mm:ss')
          }
        }
      };
      (row.id ? updateMetaCommon : saveMetaCommon)(this.model , body).then((res) => {
        this.responeHandle(res, done, loading);
      }).catch((e) => {
        loading();
      });
    },
    responeHandle(res, done, loading) {
      if (res.data && res.data.code === 200) {
        done && done();
        this.onLoad();
        this.$message.success(res.data.msg);
      } else {
        loading && loading();
        this.$message.error(res.data.msg);
      }
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        return delMetaCommon(this.model , {pk: 'id' , ids: [row.id]});
      }).then(() => {
        this.onLoad();
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    },
  }
}
</script>
<template>
  <el-col :span="24" class="h-100p">
    <el-col :span="12" v-if="operationObj.type == 'business'">
      <el-form>
        <el-form-item label="服务编排">
          <el-select
            v-model="serviceBusiness"
            size="small"
            @change="changeService"
            placeholder="请选择服务编排"
            clearable
          >
            <el-option
              v-for="item in selectData"
              :key="item.id"
              :value="item.id"
              :label="item.name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="24">
      <business
        ref="business"
        v-if="operationObj.type == 'business'"
        @back="back"
        :entityId="entityId"
      ></business>
    </el-col>

    <toPage v-if="operationObj.type == 'newPage'" @oper="oper"></toPage>
    <staticFun v-if="operationObj.type == 'staticFun'"></staticFun>
  </el-col>
</template>

<script>
import business from "./business";
import staticFun from "./static";
import chooseType from "./chooseType";
import toPage from "./toPage";

import { getList, getDetail, add, update, remove } from "@/api/tool/eventModel";

export default {
  components: { business, staticFun, chooseType, toPage },
  data() {
    return {
      btnTitle: "",
      operDialogVisible: false,
      operNewDialogVisible: false,

      serviceBusiness: "",
      newBtnOperType: "business",
      selectData: [],
      dataSoureObj: "",
    };
  },
  props: {
    dataSoure: {
      type: Object,
      default() {
        return {};
      },
      immediate: true,
    },
    tableInfo: {
      type: Object,
      default() {
        return {};
      },
      immediate: true,
    },
    entityId: {
      type: String,
      default() {
        return "";
      },
      immediate: true,
    },
    currentBtn: {
      type: Object,
      default() {
        return {};
      },
      immediate: true,
    },
    operationObj: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    operationObj: {
      handler(newValue, oldName) {
        console.log(123, this.operationObj);
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    //this.dataSoureObj = this.dataSoure;
    localStorage.setItem("currentDataSource", JSON.stringify(this.dataSoure));
    this.onLoad();
  },
  methods: {
    oper(e) {
      this.$emit("modelOper", e);
    },
    back(serviceId) {
      this.$emit("back", serviceId);
    },
    changeService() {
      this.$refs.business.changeJson(this.serviceBusiness);
      this.currentBtn = { serviceId: this.serviceBusiness };
    },
    onLoad() {
      this.loading = true;
      let obj = {
        current: 1,
        size: 50,
        modelKey: this.entityId,
      };
      console.log(obj);
      getList(obj).then((res) => {
        this.selectData = res.data.data.records;
      });
    },
  },
};
</script>

<template>
  <div>
    <!-- <basic-page-header @back="goBack"></basic-page-header>
    <el-divider></el-divider> -->
    <el-form v-loading="loading" size="small" class="pad-lr-15 w-95p" ref="form" :model="form" :rules="rules" label-width="80px">
      <el-row :gutter="$store.state.common.gutter">
        <el-col :span='12'>
          <el-form-item label="API集合" prop="apiCollectionId">
            <el-select v-model="form.apiCollectionId" placeholder="请选择">
              <el-option
                v-for="item in apiCollections"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span='12'>
          <el-form-item label="接口名称" prop="name">
            <el-input placeholder="" v-model="form.name"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="$store.state.common.gutter">
        <el-col class="mar-b-0">
          <el-form-item label="数据来源" required>
            <el-row type="flex" :gutter="10">
              <el-col :span="8">
                <el-select class="dis-b" :disabled="inModel" v-model="form.dataSourceType" @change="dataSourceTypeChange" placeholder="请选择">
                  <el-option :key="item.value" v-for="item of dataSourceTypes" :label="item.label" :value="item.value"></el-option>
                </el-select>
              </el-col>
              <el-col :span="8">
                <el-select class="dis-b" :disabled="inModel" v-model="form.dataSourceDb" @change="dataSourceDbChange" :loading="dataSourceDbLoading" placeholder="请选择">
                  <el-option
                    v-for="item in dataSources"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="8">
                <el-select class="dis-b" :disabled="inModel" v-model="form.dataSourceTable" @change="dataSourceTableChange" :loading="dataSourceTableLoading"  placeholder="请选择">
                  <el-option
                    v-for="item in tables"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name">
                  </el-option>
                </el-select>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="$store.state.common.gutter">
        <el-col>
          <el-form-item label="请求地址" prop="shareUrl">
            <el-input placeholder="选择数据来源后系统自动生成" :readonly="true" v-model="form.shareUrl"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="$store.state.common.gutter">
        <el-col>
          <el-form-item label="用途" prop="description">
            <el-input  :rows="3" v-model="form.description" type="textarea"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12"><el-divider direction="vertical"></el-divider> <span class="text-red">*</span>入参</el-col>
        <el-col :span="12"><el-divider direction="vertical"></el-divider>  <span class="text-red">*</span>返回参数</el-col>
      </el-row>
      <el-row :gutter="$store.state.common.gutter">
        <el-col :span="12">
          <el-table
            border
            ref="multipleTable1"
            :data="tableData1"
            max-height="250"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange1">
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              label="参数"
              width="120"
              prop="columnName">
            </el-table-column>
            <el-table-column
              prop="columnType"
              label="数据类型"
              width="120">
            </el-table-column>
            <el-table-column
              prop="columnComment"
              label="备注">
            </el-table-column>
          </el-table>
        </el-col>
        <el-col :span="12">
          <el-table
            border
            ref="multipleTable2"
            :data="tableData2"
            max-height="250"
            tooltip-effect="dark"
            style="width: 100%"
            @selection-change="handleSelectionChange2">
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              label="参数"
              width="120"
              prop="columnName">
            </el-table-column>
            <el-table-column
              prop="columnType"
              label="数据类型"
              width="120">
            </el-table-column>
            <el-table-column
              prop="columnComment"
              label="备注">
            </el-table-column>
          </el-table>
        </el-col>
      </el-row>
      <!-- <el-row :gutter="$store.state.common.gutter" v-if="id">
        <el-col>
          <el-form-item label="Token" prop="token">
            <el-input :rows="4" :readonly="true" :value="'Bearer ' + form.token" type="textarea"></el-input>
            <div class="mar-t-15">
              <el-button :loading="tokenBtnLoading" @click="createToken" type="primary" size="small">生成Token</el-button>
            </div>
          </el-form-item>
        </el-col>
      </el-row> -->
      <basic-form-button :loading="buttonLoading" @cancel="goBack" @submit="submitForm"></basic-form-button>
    </el-form>
  </div>
</template>
<script>

import { mapActions } from 'vuex'
import datasourceTypeList from '@/config/datasourceTypeList'
import md5 from 'js-md5';
import api from '@/api/dataShare/index';
import {
  getDataAPICollectionSelect
} from '@/api/dataService/dataAPICollection';
import { findTableByDatasourceid } from "@/api/dataService/appModel";
export default {
  data(){
    return {
      dataSourceDbLoading: false,
      dataSourceTableLoading: false,
      tokenBtnLoading: false,
      buttons: [
        {text: '测试' , code: 'test' , icon: '' , type: 'success'},
        {text: '' , code: 'cancel' , icon: '' , type: ''},
        {text: '' , code: 'submit' , icon: '' , type: 'primary'}
      ],
      baseDataHash: '',
      apiCollectionMap: {},
      form: {
        apiCollectionId: '',
        apiCollectionName: '',
        dataSourceType: '',
        dataSourceDb: '',
        dataSourceTable: '',
        name: '',
        token: '',
        description: '',
        shareUrl: ''
      },
      depts: [],
      apiCollections: [],
      inputParams: [],
      outputParams: [],
      dataSourceTypes: datasourceTypeList,
      dataSources: [],
      tables: [],
      tableData1: [],
      tableData2: [],
      rules: {
        name: [
          {required: true, message: '请输入接口名称', trigger: 'blur'}
        ],
        apiCollectionId: [
          {required: true, message: '请选择API集合', trigger: 'blur'}
        ]
      },
      // id: '',
      loading: false,
      buttonLoading: false,
    }
  },
  computed: {
    origin(){
      if(location.hostname === 'localhost'){
        return 'http://10.40.50.138';
      }else{
        return location.origin;
      }
    },
    inModel(){
      if(this.dataSourceType && this.dataSourceName && this.tableName){
        return true;
      }else{
        return false;
      }
    }
  },
  mounted(){
    console.log(this.inModel , this.dataSourceType , this.dataSourceName , this.tableName)
    if(this.id){
      // this.id = this.$router.currentRoute.params.id;
      this.get().then(
        () => {
          this.baseDataHash = md5(JSON.stringify(this.form))
          this.dataSourceTypeChange(true).then(
            () => {
              this.dataSourceDbChange(true).then(
                () => {
                  this.dataSourceTableChange(true).then(
                    () => {
                      this.setTbaleSelection()
                    }
                  );
                }
              )
            }
          );
        }
      );
    }else{
      if(this.inModel){
        this.form.dataSourceType = this.dataSourceType;
        this.form.dataSourceDb = this.dataSourceName;
        this.form.dataSourceTable = this.tableName;
        this.dataSourceTypeChange(true).then(
          () => {
            this.dataSourceDbChange(true).then(
              () => {
                this.dataSourceTableChange(true).then(
                  () => {
                    this.setTbaleSelection()
                  }
                );
              }
            )
          }
        );
      }
      this.baseDataHash = md5(JSON.stringify(this.form))
    }
    getDataAPICollectionSelect().then(
      res => {
        this.apiCollections = (res.data.data || [])
        this.apiCollections.map( api => {
          this.apiCollectionMap[api['id']] = api['name'];
        });
      }
    )
  },
  props: {
    id: String,
    dataSourceType: {
      type: String,
      default: ''
    },
    dataSourceName: {
      type: String,
      default: ''
    },
    tableName: {
      type: String,
      default: ''
    }
  },
  methods: {
    ...mapActions('datasource', ['getDatasourcesList' , 'getTablesByDatasourceId' , 'queryDataSourceTableDesc']),
    get(){
      this.loading = true;
      return api.get({
        id: this.id
      }).then(
        res => {
          this.loading = false;
          if(res.data){
            this.form = res.data.data;
            this.inputParams = JSON.parse(this.form.inputParams) || [];
            this.outputParams = JSON.parse(this.form.outputParams) || [];
          }
        }
      )
    },
    setTbaleSelection(){
      let handle = (data , tableData , name) => {
        let names = [];
        data.forEach(
          item => {
            names.push(item.key);
          }
        )
        tableData.forEach(
          (item , index) => {
            if(names.includes(item.columnName)){
              this.$refs[name].toggleRowSelection(tableData[index] , true);
            }
          }
        )
      }
      handle(this.inputParams , this.tableData1 , 'multipleTable1');
      handle(this.outputParams , this.tableData2 , 'multipleTable2');
    },
    goBack(saved){
      if(!saved && this.baseDataHash !== md5(JSON.stringify(this.form))){
        this.$confirm(`您所编辑的数据都会丢失，确定要返回？` , `提示` , {
          type: 'warning'
        }).then(() => {
          // this.$breadcrumb.pop();
          // this.$router.back();
          this.$emit('close' , null)
        })
      }else{
        // this.$breadcrumb.pop();
        // this.$router.back();
        this.$emit('close' , null)
      }
    },
    handleSelectionChange1(val){
      let inputParams = [];
      val.forEach(
        item => {
          inputParams.push({
            key: item.columnName,
            name: item.columnComment,
            dataType: item.columnType
          })
        }
      )
      this.inputParams = inputParams;
    },
    handleSelectionChange2(val){
      let outputParams = [];
      val.forEach(
        item => {
          outputParams.push({
            key: item.columnName,
            name: item.columnComment,
            dataType: item.columnType
          })
        }
      )
      this.outputParams = outputParams;
    },
    dataSourceTypeChange(init){
      this.dataSources = [];
      this.tableData1 = [];
      this.tableData2 = [];
      if(init !== true){
        this.form.dataSourceDb = '';
        this.form.dataSourceTable = '';
        this.inputParams = [];
        this.outputParams = [];
      }
      this.dataSourceDbLoading = true;
      return this.getDatasourcesList({
        type: this.form.dataSourceType
      }).then(
        res => {
          this.dataSourceDbLoading = false;
          this.dataSources = res['data'];
        }
      ).catch(() => {this.dataSourceDbLoading = false;})
    },
    dataSourceDbChange(init){
      this.tables = [];
      this.tableData1 = [];
      this.tableData2 = [];
      if(init !== true){
        this.form.dataSourceTable = '';
        this.inputParams = [];
        this.outputParams = [];
      }
      let id = '' , code = '';
      this.dataSources.forEach(
        item => {
          if(item.name === this.form.dataSourceDb){
            id = item.id;
            code = item.code;
          }
        }
      )
      this.dataSourceTableLoading = true;
      return this.getTablesByDatasourceId(id).then(
        res => {
          this.dataSourceTableLoading = false;
          this.tables = Array.from(new Set(res.data.data || [])).map(item => ({name: item}));
        }
      ).catch(() => {
        this.dataSourceTableLoading = false;
      })
    },
    dataSourceTableChange(init){
      this.tableData1 = [];
      this.tableData2 = [];
      if(init !== true){
        this.inputParams = [];
        this.outputParams = [];
      }
      let id = '';
      if(this.form.id){
        if(this.form.shareUrl){
          let shareUrls = this.form.shareUrl.split('/');
          shareUrls.pop();
          shareUrls.push(this.form.dataSourceTable);
          this.form.shareUrl = shareUrls.join('/');
        }else{
          this.form.shareUrl = this.origin + `/api/data-asset/datashare/query2/${this.form.id}/` + this.form.dataSourceTable
        }
      }else{
        this.form.shareUrl = this.origin + '/api/data-asset/datashare/query2/${APIID}/' + this.form.dataSourceTable
      }
      this.dataSources.forEach(
        item => {
          if(item.name === this.form.dataSourceDb){
            id = item.id;
          }
        }
      )
      return this.queryDataSourceTableDesc({
        id: id,
        tableName: this.form.dataSourceTable
      }).then(
        res => {
          this.tableData1 = JSON.parse(JSON.stringify(res.data.tableDescList || []))
          this.tableData2 = JSON.parse(JSON.stringify(res.data.tableDescList || []))
        }
      )
    },
    submitForm(){
      this.$refs.form.validate((valid) => {
        if(valid){
          this.form.apiCollectionName = this.apiCollectionMap[this.form.apiCollectionId];
          let body = {
            ...this.form,
            inputParams: JSON.stringify(this.inputParams),
            outputParams: JSON.stringify(this.outputParams),
          }
          this.buttonLoading = true;
          api.submit(body).then(
            res => {
              this.buttonLoading = false;
              if(res.data){
                this.$message.success(res.data.msg);
                this.$emit('refresh');
                this.$emit('close');
              }
            }
          ).catch(() => {
            this.buttonLoading = false;
          })
        }else{
          return false;
        }
      })
    },
    test(){
      if(!this.form.token){
        if(this.form.id){
          this.$message.warning('请生成Token');
        }else{
          this.$message.warning('请先保存数据');
        }
      }else{
        this.$emit('close' , {
          title: '测试',
          type: 'test'
        });
      }
    },
    createToken(){
      if(this.form.id){
        this.tokenBtnLoading = true;
        api.createToken(this.form).then(
          res => {
            this.tokenBtnLoading = false;
            if(res.data){
              this.form = res.data.data;
            }
          }
        ).catch(() => {
          this.tokenBtnLoading = true;
        })
      }else{
        this.$message.warning('数据错误')
      }
    }
  }
}
</script>

import Component from './main.vue';

export const GaugeChartConfigs = [
  {
    widgetName: '仪表盘',
    type: '图表',
    code: 'BuiltInWidgetByEchartsGauge',
    component: Component,
    icon: 'portal-iconfont portal-icon_fsux_tubiao_yibiaopan',
    dataOption: [
      {
        name: '值' , code: 'multiValue' , type: 'dynamic' ,
        optionList: [],
        meta: [
          {name: '值名' , code: 'name' , type: 'text'},
          {name: '值字段' , code: 'value' , type: 'field'}
        ]
      },
    ],
    optionList: [
      {
        code: 'chartType',
        value: 'gauge',
        hide: true,
        type: 'text',
        name: '图表类型',
      }
    ]
  }
]
<template>
  <div class="container designer form-designer-page">
    <el-row>
      <el-col :span="24">
        <el-menu
          :default-active="activeBtn"
          class="el-menu-demo mar-b-15"
          mode="horizontal"
          @select="handleClick"
        >
          <el-menu-item index="property">属性</el-menu-item>
          <el-menu-item index="api">API管理</el-menu-item>
          <el-menu-item index="service">服务编排</el-menu-item>
          <el-menu-item index="page">界面</el-menu-item>
          <!-- <el-menu-item index="code">代码生成</el-menu-item> -->
        </el-menu>
      </el-col>
    </el-row>
    <el-row v-show="activeBtn == 'property'">
      <el-col :span="24">
        <dataDesign
          ref="dataDesign"
          :menuDo="`showDetail`"
          @modelChoose="modelChoose"
        ></dataDesign>
      </el-col>
    </el-row>
    <el-row v-if="activeBtn == 'api'">
      <el-col :span="24">
        <datashare
          :dataSourceType="dataSource.type"
          :dataSourceName="dataSource.name"
          :tableName="model.tableName"
        ></datashare>
      </el-col>
    </el-row>
    <el-row v-if="activeBtn == 'service'">
      <el-col :span="24">
        <service-design
          :model="model"
          :dataSource="dataSource"
        ></service-design>
      </el-col>
    </el-row>
    <el-row v-if="activeBtn == 'page'">
      <el-col :span="24">
        <tableForModel
          :model="model"
          :dataSource="dataSource"
          :menuDo="`showDetail`"
        ></tableForModel>
      </el-col>
    </el-row>
    <el-row v-if="activeBtn == 'code'">
      <el-col :span="24">
        <modleCode></modleCode>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import modleCode from "./code";
import tableForModel from "./tableForModel";
import serviceDesign from "./serviceDesign";
import dataDesign from "../data/table/dataConfig/dataDesign";
import datashare from "../dataShare/datashare.vue";

export default {
  components: {
    modleCode,
    tableForModel,
    dataDesign,
    serviceDesign,
    datashare,
  },
  data() {
    return {
      activeBtn: "property",
      model: {},
      dataSource: {},
    };
  },
  computed: {},
  mounted() {
    this.dataSource = JSON.parse(this.$router.currentRoute.query.dataSource);
    this.model = JSON.parse(this.$router.currentRoute.query.row);
    let modelFlag = false;
    this.$breadcrumb.breadcrumbs.forEach((e) => {
      if (e.name == this.model.description) {
        modelFlag = true;
      }
    });
    if (!modelFlag) {
      this.$breadcrumb.add({
        name: this.model.description || "",
      });
    }

    this.initpro();
  },
  created() {},
  methods: {
    modelChoose(data) {
      this.model = data[1];
      this.dataSource = data[0];
      this.$breadcrumb.pop();

      this.$breadcrumb.add({
        name: this.model.description || "",
      });
    },
    handleClick(d) {
      this.activeBtn = d;
    },
    initpro() {
      let t = this;
      this.$nextTick(() => {
        t.$refs.dataDesign.dataSoureObj = this.dataSource;
        t.$refs.dataDesign.tableInfo = this.model;
        t.$refs.dataDesign.initDesign();
      });
    },
  },
};
</script>

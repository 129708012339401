<template>
  <div :style="getStyle()">{{data || ''}}</div>
</template>
<script>

import WidgetMixins from '../widget-mixins';

export default {
  name: 'BuiltInWidgetByText',
  mixins: [ WidgetMixins ],
  data(){
    return {

    }
  },
  mounted(){
    console.log(this.data);
  },
  methods: {

  }
}
</script>
const CheckCallback = (name , context) => {
  if(context && context[name] && typeof context[name] === 'function'){
    return true;
  }else{
    return false;
  }
}
export default {

  watch: {
    data: {
      immediate: true,
      handler(){
        if(CheckCallback('dataChange' , this)){
          this.dataChange()
        }else if(this.refresh && typeof this.refresh === 'function'){
          this.refresh('data');
        }
      }
    },
    'widget.public': {
      immediate: true,
      deep: true,
      handler(){
        if(CheckCallback('publicChange' , this)){
          this.publicChange()
        }else if(this.refresh && typeof this.refresh === 'function'){
          this.refresh('widget.public');
        }
      }
    },
    'widget.private': {
      immediate: true,
      deep: true,
      handler(val){
        if(CheckCallback('privateChange' , this)){
          this.privateChange()
        }else if(this.refresh && typeof this.refresh === 'function'){
          this.refresh('widget.private');
        }
      }
    },
    'widget.dataOption': {
      immediate: true,
      deep: true,
      handler(){
        if(CheckCallback('optionChange' , this)){
          this.optionChange()
        }else if(this.refresh && typeof this.refresh === 'function'){
          this.refresh('widget.dataOption');
        }
      }
    }
  },

  props: {
    data: {
      type: [String , Number , Object , Array , Boolean],
      default(){
        return null;
      }
    },
    widget: {
      type: Object,
      default(){
        return null
      }
    }
  },

  methods: {
    getDataType(){
      if(typeof this.data === 'object'){
        if(Array.isArray(this.data)){
          return 'array';
        }else{
          return 'object';
        }
      }else{
        return typeof(this.data);
      }
    },
    getObjData(){
      if(this.getDataType() === 'array'){
        return this.data.length ? this.data[0] : null;
      }else if(this.getDataType() === 'object'){
        return this.data;
      }else{
        return null;
      }
    }
  }

}
<template>
  <div class="ov-h" style="display: flex;">
    <div v-for="(file , index) of fileList" :key="file.id ? file.id : file.name" :title="file.name" class="el-upload el-upload--picture-card custom-el-upload custom-el-upload-view-item">
      <i v-if="file.state === 'uploading'" class="el-icon-loading"></i>
      <template v-if="file.state === 'uploaded'">
        <img v-if="fileTypes[file.type] === 'image'"  :src="file.url" alt="">
        <img v-if="fileTypes[file.type] === 'pdf'" src="/assets/images/icon/file/pdf.png" alt="">
        <img v-if="fileTypes[file.type] === 'txt'" src="/assets/images/icon/file/txt.png" alt="">
        <img v-if="fileTypes[file.type] === 'ppt'" src="/assets/images/icon/file/ppt.png" alt="">
        <img v-if="fileTypes[file.type] === 'excel'" src="/assets/images/icon/file/excel.png" alt="">
        <img v-if="fileTypes[file.type] === 'word'" src="/assets/images/icon/file/word.png" alt="">
        <p>{{file.name}}</p>
        <div class="actionBtn">
          <i v-if="fileTypes[file.type] === 'image'" @click="openImagePreview(file)" class="text-hover cur-p el-icon-zoom-in mar-r-10"></i>
          <i v-if="!isDisabled" class="text-hover cur-p el-icon-delete" @click="fileList.splice(index , 1);setFormAttachmentIds()"></i>
        </div>
      </template>
    </div>
    <div v-if="!isDisabled" @click="() => {this.$refs.file.click()}" class="el-upload el-upload--picture-card custom-el-upload">
      <i class="el-icon-plus"></i>
      <input ref="file" @change="selectFileChange" multiple type="file" name="file" class="el-upload__input">
    </div>
  </div>
</template>
<script>

import { getPicListByIds } from "@/api/busiMode/metaCommon";
import { uploadFile } from '@/api/resource/file.js'

export default {
  data(){
    return {
      fileList: [],
      fileTypes: {
        png: 'image',
        jpg: 'image',
        gif: 'image',
        jpeg: 'image',
        xls: 'excel',
        xlsx: 'excel',
        doc: 'word',
        docx: 'word',
        ppt: 'ppt',
        pdf: 'pdf',
        txt: 'txt'
      },
    }
  },
  props: {
    value: {
      type: String,
      default: ''
    },
    disabled: {
      type: [Boolean , String],
      default: false
    }
  },
  computed: {
    isDisabled(){
      if(this.disabled || this.disabled === 'disabled'){
        return true;
      }else{
        return false;
      }
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(){
        this.getPicListByIds();
      }
    }
  },
  mounted(){
    
  },
  methods: {
    getPicListByIds(){
      let attachment_ids = this.value ? this.value.split(',') : [];
      this.fileList = [];
      if(attachment_ids && attachment_ids.length){
        getPicListByIds(attachment_ids).then(
          res => {
            if(res && res.data && res.data.data){
              res.data.data.forEach(
                item => {
                  let type = item.originalName.split('.').pop().toLocaleLowerCase();
                  let fileItem = {
                    type: type,
                    url: item.link,
                    name: item.originalName,
                    state: 'uploaded',
                    id: item.id,
                    file: item
                  }
                  this.fileList.push(fileItem)
                }
              )
            }
          }
        )
      }
    },
    openImagePreview(file){
      this.$ImagePreview([{url: file.url}], 0 ,{
        closeOnClickModal: true,
      });
    },
    selectFileChange(ev){
      for(let i = 0; i < ev.target.files.length; i++){
        let file = ev.target.files[i];
        let type = file.name.split('.').pop().toLocaleLowerCase();
        let fileItem = {
          type: type,
          url: this.fileTypes[type] === 'image' ? URL.createObjectURL(file) : null,
          name: file.name,
          file: file,
          state: '',
          id: ''
        }
        this.fileList.push(fileItem);
      }
      this.fileList.forEach(
        item => {
          if(!item.id){
            let formData = new FormData();
            formData.append('file' , item.file);
            item.state = 'uploading';
            uploadFile(formData).then(
              res => {
                if(res.data && res.data.data){
                  item.id = res.data.data.attachId;
                  item.state = 'uploaded';
                }
                this.setFormAttachmentIds();
              }
            )
          }
        }
      )
    },
    setFormAttachmentIds(){
      let value = this.fileList.map(itemFile => itemFile.id).join(',');
      this.$emit('input' , value);
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-el-upload{
  width: 100px;
  height: 100px;
  line-height: 100px;
  vertical-align: baseline;
  box-sizing: border-box;
  display: block;
  margin-left: 10px;
}
.custom-el-upload-view-item{
  overflow: hidden;
  position: relative;
  img{
    width: 80px;
    height: 80px;
    display: block;
    margin: 10px;
  }
  p{
    background: rgba(0,0,0,.3);
    line-height: 20px;
    height: 20px;
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #fff;
    margin: 0;
    padding: 0 3px;
  }
  .actionBtn{
    position: absolute;
    top: 0;
    bottom: 20px;
    right: 0;
    left: 0;
    background: rgba(0,0,0,.3);
    line-height: 100px;
    text-align: center;
    display: none;
    i{
      color: #fff;
    }
  }
  &:hover{
    .actionBtn{
      display: block;
    }
  }
}
</style>
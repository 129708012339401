<template>
  <basic-container
    isCard
    :body-style="{ padding: '0', height: '100%' }"
    class="h-100p"
  >
    <el-container v-if="isInit" style="height: 100%">
      <el-header height="50px" class="bor-b">
        <basic-page-header
          @back="back"
          class="lh-50"
          :title="component.title"
        ></basic-page-header>
      </el-header>
      <component
        v-if="component.componentName"
        :is="component.componentName"
        v-bind="props"
        @back="back"
      ></component>
      <el-main v-else>
        <div class="text-center pad-20">没有找到表单...</div>
      </el-main>
    </el-container>
  </basic-container>
</template>
<script>
import collectionForm from "./collectionForm.vue";
import repairForm from "./repairForm.vue";
import returnForm from "./returnForm.vue";
import scrapForm from "./scrapForm.vue";
import storageForm from "./storageForm.vue";
import inventoryForm from "./inventoryForm.vue";
import standardService from "@/api/metadata/standard";

export default {
  components: {
    collectionForm,
    repairForm,
    returnForm,
    scrapForm,
    storageForm,
    inventoryForm,
  },
  data() {
    return {
      componentsName: [
        "collectionForm",
        "repairForm",
        "returnForm",
        "scrapForm",
        "storageForm",
        "inventoryForm",
      ],
      formTitle: {
        collection: "领用单",
        repair: "维保单",
        storage: "入库单",
        return: "退回单",
        scrap: "报废单",
        inventory: "盘点单",
      },
      componentTypes: {
        collection: {
          componentName: "collectionForm",
          title: "领用单",
          model: "wisdom_park.wp_asset_collection",
          rules: {
            collection_date: [
              { required: true, message: '请选择领用时间', trigger: 'blur' }
            ],
            collection_dept_id: [
              { required: true, message: '请选择领用部门', trigger: 'blur' }
            ],
            collection_user_id: [
              { required: true, message: '请选择领用人', trigger: 'blur' }
            ]
          }
        },
        repair: {
          componentName: "repairForm",
          title: "维保单",
          model: "wisdom_park.wp_asset_repair",
        },
        storage: {
          componentName: "storageForm",
          title: "入库单",
          model: "wisdom_park.wp_asset_storage",
        },
        return: {
          componentName: "returnForm",
          title: "退回单",
          model: "wisdom_park.wp_asset_collection_return",
          rules: {
            return_dept_id: [
              { required: true, message: '请选择退回部门', trigger: 'blur' }
            ],
            return_user_id: [
              { required: true, message: '请选择退回人', trigger: 'blur' }
            ]
          }
        },
        scrap: {
          componentName: "scrapForm",
          title: "报废单",
          model: "wisdom_park.wp_asset_scrap",
          rules: {
            scrap_dept: [
              { required: true, message: '请选择报废部门', trigger: 'blur' }
            ],
            scrap_user: [
              { required: true, message: '请选择报废人', trigger: 'blur' }
            ]
          }
        },
        inventory: {
          componentName: "inventoryForm",
          title: "盘点单",
          model: "wisdom_park.wp_asset_inventory",
          rules: {
            asset_category: [
              { required: true, message: '请选择资产类型', trigger: 'blur' }
            ],
            owner_code: [
              { required: true, message: '请选择负责人', trigger: 'blur' }
            ]
          }
        },
      },
      ruleModel: {},
      isInit: false
    };
  },
  computed: {
    formType() {
      return this.$router.currentRoute.params["formType"] || "";
    },
    isView() {
      return this.$router.currentRoute.params["type"] === "view" || false;
    },
    isAdd() {
      return this.$router.currentRoute.params["type"] === "add";
    },
    formSize() {
      return this.$store.state.common.size || "small";
    },
    formId() {
      return this.id ? this.id : this.$router.currentRoute.query["id"];
    },
    component() {
      let component = this.componentTypes[this.formType] || null;
      if (component) {
        return component;
      } else {
        return {};
      }
    },
    props() {
      return {
        id: this.formId,
        isAdd: this.isAdd,
        view: this.isView,
        formSize: this.formSize,
        disabled: this.disabled,
        rules: {
          ...(this.formType && this.componentTypes[this.formType] ? (this.componentTypes[this.formType].rules || {}) : {}),
          ...this.ruleModel,
        },
      };
    },
  },
  mounted() {
    if (!this.isView) {
      this.queryByModelCode();
    }else{
      this.isInit = true;
    }
  },
  destroyed(){
    this.$breadcrumb.pop();
  },
  methods: {
    back() {
      this.$router.back();
    },
    queryByModelCode() {
      standardService.getModelRule(this.component.model).then((res) => {
        this.ruleModel = res;
        this.isInit = true;
      });
    },
  },
};
</script>

import { render, staticRenderFns } from "./room.vue?vue&type=template&id=0c814786&scoped=true"
import script from "./room.vue?vue&type=script&lang=js"
export * from "./room.vue?vue&type=script&lang=js"
import style0 from "./room.vue?vue&type=style&index=0&id=0c814786&prod&lang=scss&rel=stylesheet%2Fscss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c814786",
  null
  
)

export default component.exports
<template>
  <div class="h-100p">
    <div class="h-100p display-flex" :gutter="$store.state.common.gutter">
      <div class="w-300" style='height:100%'>
        <el-card class="w-300" style='height:100%'>
          <el-input
            style="margin-bottom: 15px;width: calc(100% - 33px);"
            size="small"
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
            v-model="filterText">
          </el-input>
          <el-tooltip :content="'新增标签'" placement="top" :enterable="false">
              <span>
                <el-button style="margin-left:5px;" type="primary" size="mini" icon="el-icon-circle-plus-outline" @click="openAddLabelDialog(true)" circle></el-button>
              </span>
          </el-tooltip>

          <el-tree
            ref="tree"
            element-loading-text="正在加载中..."
            v-loading="treeLoading"
            node-key="id"
            :props="{'label': 'name'}"
            :data="labels"
            :filter-node-method="filterNode"
            :default-checked-keys="[1]"
            :expand-on-click-node="false"
            @node-click="handleNodeClick"
            @node-contextmenu="nodeContextmenuClick"
          ></el-tree>
        </el-card>
      </div>
      <div class="meta-container" style="margin-bottom: 0;flex:1;margin-left:8px;height:100%">
        <el-card style='height:100%'>
          <el-tabs v-model="activeTabName">
            <el-tab-pane label="基本信息" name="info">
              <el-form label-width="80px" size="small" v-if="editInfo" :model="editInfo">
                <el-form-item prop="name" label="标签名称">
                  <el-input v-if="edit" v-model="editInfo.name"></el-input>
                  <span v-else>{{info.name}}</span>
                </el-form-item>
                <el-form-item prop="description" label="描述">
                  <el-input v-if="edit" type="textarea" v-model="editInfo.description"></el-input>
                  <span v-else>{{info.description || '-'}}</span>
                </el-form-item>
                <template v-if="!edit">
                  <el-form-item prop="createTime" label="创建时间">
                    <span>{{info.createTime || '-'}}</span>
                  </el-form-item>
                  <el-form-item prop="updateTime" label="更新时间">
                    <span>{{info.updateTime || '-'}}</span>
                  </el-form-item>
                  <el-form-item prop="createUserName" label="创建人">
                    <span>{{info.createUserName || '-'}}</span>
                  </el-form-item>
                </template>
                <basic-form-button v-if="edit" @submit="(done) => submitLabel(done , editInfo)" @cancel="edit = false"></basic-form-button>
              </el-form>
              <div v-if="!edit" class="text-right mar-t-20">
                <el-button size="small" type="primary" class="mar-l-10" icon="el-icon-edit" @click="editLabel">编辑</el-button>
                <el-button size="small" type="danger" icon="el-icon-delete" @click="removeLabel(editInfo)">删除</el-button>
              </div>
            </el-tab-pane>
            <el-tab-pane label="贴标签" name="tag">
              <el-table
                max-height="500"
                :data="tableData"
                style="width: 100%">
                <el-table-column
                  prop="resourceType"
                  label="对象类型"
                  width="180">
                  <template slot-scope="{row}">
                    {{resourceTypeDic[row.resourceType] || row.resourceType}}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="resourceName"
                  label="对象名称"
                  width="180">
                </el-table-column>
                <el-table-column
                  prop="createTime"
                  label="关联时间">
                </el-table-column>
                <el-table-column
                  label="操作"
                  width="80"
                >
                 <template slot-scope="{row}">
                    <el-button size="small" type="text" icon="el-icon-delete" @click="deleteLabelResourceRelation(row)">删除</el-button>
                  </template>
                </el-table-column>
              </el-table>
              <div class="mar-t-15">
                <el-button size="small" type="text" icon="el-icon-circle-plus-outline" @click="() => this.addLabelResourceRelationModal = true">添加</el-button>
              </div>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </div>
    </div>
    <div v-if="nodeContextmenu.show" :style="nodeContextmenu.style" class="node-contextmenu" ref="treeContextmenu">
      <el-card>
        <div @click="openAddLabelDialog(false)" class="lh-34 text-primary cur-p"><i class="el-icon-circle-plus-outline"></i> 新增子节点</div>
        <div @click="removeLabel()" class="lh-34 text-primary cur-p"><i class="el-icon-delete"></i> 删除</div>
      </el-card>
    </div>
    <el-dialog
      title="新增标签"
      :visible.sync="dialogVisible"
      append-to-body
      >
      <el-form v-if="dialogVisible" :model="form" :rules="formRules" label-width="80px" size="small">
        <el-form-item prop="name" label="标签名称">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
        <el-form-item prop="description" label="描述">
          <el-input type="textarea" v-model="form.description"></el-input>
        </el-form-item>
        <basic-form-button @submit="(done) => submitLabel(done)" :loading="formLoading" @cancel="() => this.dialogVisible = false"></basic-form-button>
      </el-form>
    </el-dialog>

    <el-dialog
      title="贴标签"
      :visible.sync="addLabelResourceRelationModal"
      append-to-body
      >
      <el-form ref="addLabelResourceRelationModalForm" v-if="addLabelResourceRelationModal" :model="labelResourceRelationForm" :rules="labelResourceRelationFormRules" label-width="80px" size="small">
        <el-form-item prop="resourceType" label="对象类型">
          <el-select v-model="labelResourceRelationForm.resourceType" placeholder="请选择">
            <el-option
              v-for="item in resourceType"
              :key="item.dictKey"
              :label="item.dictValue"
              :value="item.dictKey">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="resourceId" label="对象">
          <template v-if="labelResourceRelationForm.resourceType === 'database'">
            <el-select v-model="dataSourceType" @change="dataSourceTypeChange" placeholder="请选择">
              <el-option :key="item.value" v-for="item of dataSourceTypes" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <el-select class="mar-l-10" v-model="labelResourceRelationForm.resourceId" placeholder="请选择">
              <el-option :key="item.id" v-for="item of databases" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </template>
          <template v-else-if="labelResourceRelationForm.resourceType === 'catalog'">
            <el-select v-model="labelResourceRelationForm.resourceId" placeholder="请选择">
              <el-option :key="item.id" v-for="item of catalogs" :label="item.name" :value="item.id"></el-option>
            </el-select>
          </template>
          <template v-else>
            <el-select value="" disabled placeholder="请选择">
            </el-select>
          </template>
        </el-form-item>
        <basic-form-button @submit="submitLabelResourceRelation" :loading="formLoading" @cancel="() => this.addLabelResourceRelationModal = false"></basic-form-button>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import _ from 'lodash';
import { getLabelTree , saveLabel , updateLabel , removeLabel , getLabelDetail} from '@/api/metadata/label';
import { listCatalogs } from '@/api/resourceCatalog/resmanage'
import { getDictionary } from '@/api/system/dictbiz';
import datasourceTypeList from '@/config/datasourceTypeList'
import labelApi from '@/api/label';
export default {
  data(){
    return {
      dataSourceTypes: datasourceTypeList,
      labels: [],
      nodeContextmenu: {
        show: false,
        style: {
          top: '0px',
          left: '0px'
        },
        data: null
      },
      tableData: [],
      edit: false,
      treeLoading: false,
      filterText: '',
      formLoading: false,
      form: {
        name: '',
        description: ''
      },
      formRules: {
        name: [
          { required: true, message: '请输入标签名称', trigger: 'blur' }
        ]
      },
      dialogVisible: false,
      info: null,
      editInfo: null,
      activeTabName: 'info',
      resourceType: [],
      resourceTypeDic: {},
      addLabelResourceRelationModal: false,
      labelResourceRelationForm: {
        resourceType: '',
        resourceId: '',
        labelId: ''
      },
      labelResourceRelationFormRules: {
        resourceType: [
          { required: true, message: '请选择对象类型', trigger: 'blur' }
        ],
        resourceId: [
          { required: true, message: '请选择对象', trigger: 'blur' }
        ]
      },
      catalogs: [],
      databases: [],
      dataSourceType: ''
    }
  },
  mounted(){

    this.getLabelTree();
    this.bindDocClick();
    listCatalogs().then(
      res => {
        this.catalogs = res.data.data || [];
      }
    )
    getDictionary({
      code: 'LABEL_RESOURCE_TYPE'
    }).then(
      res => {
        this.resourceType = res.data.data || [];
        this.resourceType.forEach(item => {
          this.resourceTypeDic[item['dictKey']] = item['dictValue'];
        })
      }
    )
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    ...mapActions('datasource', ['getDatasourcesList']),
    editLabel(){
      getLabelDetail({id: this.info.id}).then(
        res => {
          this.info = res.data.data;
          this.edit = true;
        }
      )
    },
    dataSourceTypeChange(){
      this.labelResourceRelationForm.resourceId = '';
      this.getDatasourcesList({
        type: this.dataSourceType
      }).then(
        res => {
          this.databases = res['data'];
        }
      )
    },
    deleteLabelResourceRelation(row){
      this.$confirm("确定要删除该关联？", {
        type: "warning"
      }).then(() => {
        labelApi.removeLabelResourceRelation({
          ids: row.id
        }).then(
          res => {
            this.$message.success(res.data.msg || '删除成功');
            this.listLabelResourceRelation(this.info.id);
            this.info = null;
            this.editInfo = null;
          }
        )
      })
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    listLabelResourceRelation(labelId){
      labelApi.listLabelResourceRelation({
        labelId: labelId
      }).then(
        res => {
          this.tableData = res.data.data.records || [];
        }
      )
    },
    translateTreeData(treeData){
      let newTreeData = [];
      treeData.forEach(
        item => {
          let el = {
            ...item.label
          }
          if(item.children && item.children.length){
            el['children'] = this.translateTreeData(item['children']);
          }
          newTreeData.push(el);
        }
      )
      return newTreeData;
    },
    getLabelTree(){
      this.treeLoading = true;
      getLabelTree().then(
        res => {
          this.treeLoading = false;
          this.labels = this.translateTreeData(res.data.children || []);
          if(this.labels.length && !this.info){
            this.selectLabel(this.labels[0]);
          }
          if(this.info){
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(this.info.id);
            })
          }
        }
      )
    },
    selectLabel(data){
      this.info = _.cloneDeep(data);
      this.editInfo = _.cloneDeep(this.info);
      this.listLabelResourceRelation(data.id);
    },
    handleNodeClick(data){
      this.selectLabel(data);
    },
    openAddLabelDialog(topLabel){
      this.form = {
        name: '',
        description: '',
        parentId: topLabel ? 0 : this.nodeContextmenu.data.id
      }
      this.dialogVisible = true;
    },
    removeLabel(label){
      let mLabel = label || this.nodeContextmenu.data;
      this.$confirm("确定要删除该标签？", {
        type: "warning"
      }).then(() => {
        removeLabel({
          ids: mLabel.id
        }).then(
          res => {
            this.$message.success(res.data.msg || '删除成功');
            this.getLabelTree();
            this.info = null;
            this.editInfo = null;
          }
        )
      })
    },
    bindDocClick(){
      document.removeEventListener('click' , this.outClick);
      this.$nextTick(() => {
        document.addEventListener('click' , this.outClick);
      })
    },
    outClick(e){
      if(this.$refs.treeContextmenu && !this.$refs.treeContextmenu.contains(e.target)){
        this.nodeContextmenu.show = false;
      }
    },
    nodeContextmenuClick(ev , data){
      this.nodeContextmenu.style = {
        top: ev.y + 'px',
        left: ev.x + 'px'
      }
      this.nodeContextmenu.show = true;
      this.nodeContextmenu.data = data;
    },
    submitLabel(done , form){
      if(!form){
        form = this.form;
      }
      if(form.name){
        this.formLoading = true;
        (!form.id ? saveLabel(form) : updateLabel(form)).then(
          res => {
            this.$message.success(res.data.msg);
            this.getLabelTree();
            if(form.id){
              this.info = _.cloneDeep(this.editInfo);
              this.edit = false;
            }else{
              this.dialogVisible = false;
            }
            this.formLoading = false;
            done();
          }
        ).catch(() => {
          this.formLoading = true;
          done();
        })
      }else{
        this.$message.warning('标签名称为必填项。');
        done();
      }
    },
    submitLabelResourceRelation(){
      if(this.labelResourceRelationForm.labelId === ''){
        this.labelResourceRelationForm.labelId = this.info.id;
      }
      this.$refs.addLabelResourceRelationModalForm.validate(valida => {
        if(valida){
          this.formLoading = true;
          labelApi.saveLabelResourceRelation(this.labelResourceRelationForm).then(
            res => {
              if(res.data.success){
                this.$message.success(res.data.msg);
                this.formLoading = false;
                this.addLabelResourceRelationModal = false;
                this.listLabelResourceRelation(this.info.id);
              }
            }
          ).catch(() => {
            this.formLoading = true;
          })
        }
      })
    }
  }
}
</script>
<style scoped>
.node-contextmenu{
  position: fixed;
}
</style>

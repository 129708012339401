<template>
  <basic-container>
    <avue-crud :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               :before-open="beforeOpen"
               v-model="form"
               ref="crud"
               @row-update="rowUpdate"
               @row-save="rowSave"
               @row-del="rowDel"
               @search-change="searchChange"
               @search-reset="searchReset"
               @current-change="currentChange"
               @size-change="sizeChange"
               @refresh-change="refreshChange"
               @on-load="onLoad">
      <template slot="menuLeft">
        <el-button type="danger"
                   size="small"
                   icon="el-icon-delete"
                   plain
                   v-if="permission.epmallbrand_delete"
                   @click="handleDelete">删 除
        </el-button>
      </template>

       <!-- 修改状态 -->
      <template slot-scope="{type,size,row}" slot="menu">
        <el-button icon="el-icon-delete" :size="size" :type="type" @click="tip(row)">修改状态</el-button>
      </template>

    </avue-crud>
  </basic-container>
</template>

<script>
  import {getList,getPage, getDetail, add, update, remove, updateBrandStates} from "@/api/epmall/brand";
  import {mapGetters} from "vuex";

  export default {
    data() {
      return {
        form: {},
        query: {},
        loading: true,
        page: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        selectionList: [],
        option: {
          height:'auto',
          calcHeight: 30,
          tip: false,
          searchShow: true,
          searchMenuSpan: 6,
          border: true,
          index: true,
          viewBtn: true,
          dialogClickModal: false,
          column: [
            {
              label: "品牌编码",
              prop: "code",
              rules: [{
                required: true,
                message: "请输入品牌编码",
                trigger: "blur"
              }]
            },
            {
              label: "品牌名称",
              prop: "name",
              search: true,
              rules: [{
                required: true,
                message: "请输入品牌名称",
                trigger: "blur"
              }]
            },
            {
              label: "图标",
              prop: "iconFile",
              type:'upload',
              listType: 'picture-img',
              span:24,
              propsHttp: {
                res: "data",
                url: "link",
              },
              tip: '只能上传jpg/png用户头像，且不超过500kb',
              action: '/api/jida-resource/oss/endpoint/put-file-attach',
              rules: [{
                required: true,
                message: "请添加图片",
                trigger: "blur"
              }]
            },
            {
              label: "描述",
              prop: "description",
              hide:true,
              span: 24,
              minRows:6,
              type:"textarea",
              rules: [{
                required: false,
                message: "请输入描述",
                trigger: "blur"
              }]
            },
            {
              label: "创建时间",
              prop: "created",
              addDisplay: false,
              // viewDisplay: true,
              viewDisplay: false,
              editDisplay:false,
              rules: [{
                required: true,
                message: "请输入创建时间",
                trigger: "blur"
              }]
            },
            {
              label: "更新时间",
              prop: "updated",
              addDisplay: false,
              viewDisplay: false,
              // viewDisplay: true,
              editDisplay:false,
              rules: [{
                required: true,
                message: "请输入更新时间",
                trigger: "blur"
              }]
            },
            {
              label: "状态",
              prop: "state",
              search: true,
              searchSpan: 4,
              addDisplay: false,
              // viewDisplay: true,
              viewDisplay: false,
              editDisplay:false,
              searchSpan: 4,
              type: 'select',
              dicData:[{
                label:'正常',
                value:1
              },{
                label:'无效',
                value:0
              }],
              rules: [{
                required: true,
                message: "请输入状态，1=正常,0=无效",
                trigger: "blur"
              }]
            },
          ]
        },
        data: []
      };
    },
    computed: {
      ...mapGetters(["permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.epmallbrand_add, false),
          viewBtn: this.vaildData(this.permission.epmallbrand_view, false),
          delBtn: this.vaildData(this.permission.epmallbrand_delete, false),
          editBtn: this.vaildData(this.permission.epmallbrand_edit, false)
        };
      },
      ids() {
        let ids = [];
        this.selectionList.forEach(ele => {
          ids.push(ele.id);
        });
        return ids.join(",");
      }
    },
    methods: {
      rowSave(row, done, loading) {
        add(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          window.console.log(error);
        });
      },
      rowUpdate(row, index, done, loading) {
        update(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          console.log(error);
        });
      },
      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(row.id);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
          });
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
            this.$refs.crud.toggleSelection();
          });
      },
      beforeOpen(done, type) {
        if (["edit", "view"].includes(type)) {
          getDetail(this.form.id).then(res => {
            this.form = res.data.data;
          });
        }
        done();
      },
      searchReset() {
        this.query = {};
        this.onLoad(this.page);
      },
      searchChange(params, done) {
        this.query = params;
        this.page.currentPage = 1;
        this.onLoad(this.page, params);
        done();
      },
      selectionClear() {
        this.selectionList = [];
        this.$refs.crud.toggleSelection();
      },
      currentChange(currentPage){
        this.page.currentPage = currentPage;
      },
      sizeChange(pageSize){
        this.page.pageSize = pageSize;
      },
      refreshChange() {
        this.onLoad(this.page, this.query);
      },
      onLoad(page, params = {}) {
        this.loading = true;
        getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
          const data = res.data.data;
          this.page.total = data.total;
          this.data = data.records;
          this.loading = false;
          this.selectionClear();
        });
      },
      tip(row){
        this.$alert('确定修改状态吗？', '确定').then(() => {

          updateBrandStates(row.id).then(res => {

            if(res.data.success){
              if (row.state === 1) {
                row.state = 0;
                this.$forceUpdate()
              }else {
                row.state = 1;
                this.$forceUpdate()
              }
              this.$message.success(res.data.msg)
            } else {
              this.$message.success(res.data.msg)
            }
          });
        })
      }
    }
  };
</script>

<style>
</style>

<template>
  <basic-container>
    <avue-crud
      :data="tableDatas"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      :before-open="beforeOpen"
      v-model="form"
      @row-save="handleSave"
      @row-update="handleUpdate"
      @row-del="handleDel"
      @on-load="onLoad"
      @refresh-change="onLoad"
    >
    <!-- 搜索 -->
      <template slot="menuLeft">
        <el-input class="mar-l-5" v-model="search.name"
            @keyup.native.enter="searchChange"
            clearable
            size="small"
            placeholder="请输入集合名称"
            type="text"
            style="width:180px;">
          <el-button class="mar-0 pad-0" size="small" @click="searchChange" slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </template>
    </avue-crud>
  </basic-container>
</template>
<script>
import {
  getDataAPICollection,
  saveAPICollection,
  updateAPICollection,
  delAPICollection,
  getDetailAPICollection
} from '@/api/dataService/dataAPICollection'
import { dateFormat } from '@/util/date'
  export default {
    data(){
      return {
        form: {},
        search: {},
        page: {
          pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        tableLoading: false,
        tableDatas: [],
        tableOption: {
          viewBtn: true,
          column:[
            {
              label:'集合名称',
              prop:'name',
              span: 24,
              rules: [{
                required: true,
                message: "请输入名称",
                trigger: "blur"
              }]
            },
            {
              label:'集合Path',
              prop:'path',
              span: 24,
              rules: [{
                required: true,
                message: "请输入名称",
                trigger: "blur"
              }]
            },
            {
              label:'描述',
              prop:'description',
              type: "textarea",
              minRows: 2,
              span: 24,
            },
            {
              label:'创建人',
              prop:'createUserName',
              display: false
            },
            {
              label:'创建时间',
              prop:'createTime',
              display: false
            }
          ]
        }
      }
    },
    methods: {
      beforeOpen (done,type) {
        if (type=='add') {
          done()
        } else {
          let params= {
            id: this.form.id
          }
          getDetailAPICollection(params).then((res) => {
            if (res.data.code==200) {
              this.form=res.data.data
              done()
            }
          })
        }
      },
    // 搜索
    searchChange () {
      this.onLoad()
    },
      onLoad() {
        this.tableLoading = true;
        let obj = {
          name: this.search.name,
          'current': this.page.currentPage,
          'size': this.page.pageSize
        };
        getDataAPICollection(obj).then( res => {
          this.tableLoading = false;
          this.tableDatas = res.data.data.records;
          this.page.total = res.data.data.total
        }).catch( e => {
          this.tableLoading = false;
        });
      },
      // beforeOpen(done , type){
      //   if(['edit'].includes(type)){
      //     getDetail({id: this.form.id}).then(
      //       res => {
      //         this.form = res.data.data;
      //       }
      //     )
      //   }
      //   done();
      // },
      handleSave(row,done,loading) {
        let obj = {
          'description':row['description'],
          'name':row['name'],
          'path':row['path'],
          'createTime':dateFormat(new Date())
        };
        saveAPICollection(obj).then( res => {
          done();
          loading();
          this.onLoad();
        }).catch( e => {
          loading();
        });
      },
      handleUpdate(row, index, done, loading) {
        let obj = {
          'id':row['id'],
          'description':row['description'],
          'name':row['name'],
          'path':row['path']
        };
        updateAPICollection(obj).then( res => {
          done();
          loading();
          this.onLoad();
        });
      },
      handleDel(row) {
        let _t = this;
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            delAPICollection({
              ids:row['id']
            }).then(res => {
              _t.onLoad();
            })
          })
      }
    }
  }
</script>

<template>
  <div>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      ref="crud"
      v-model="form"
      :page.sync="page"
      @selection-change="selectionChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot="menuLeft" slot-scope="scope">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="add()"
          >同步
        </el-button>
        <el-input
          v-model="searchText"
          @keyup.native.enter="refreshChange"
          size="small"
          placeholder="请输入门禁"
          type="text"
          clearable
          class="mar-l-10"
          @clear="onLoad"
          style="width: 250px"
        />
        <el-input
          v-model="regionName"
          @keyup.native.enter="refreshChange"
          size="small"
          placeholder="请输入所在区域"
          type="text"
          class="mar-l-5"
          clearable
          @clear="onLoad"
          style="width: 250px"
        />
        <!-- <el-button
          type="text"
          size="small"
          class="none-border"
          @click="refreshChange"
          icon="el-icon-search"
        ></el-button> -->
        <el-button
          type="primary"
          size="small"
          class="mar-l-5"
          icon="el-icon-search"
          @click="refreshChange()"
          >查询
        </el-button>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button
          type="text"
          size="small"
          class="none-border"
          icon="el-icon-s-tools"
          @click="doorevents(scope.row)"
          >关联设置
        </el-button>
      </template>
    </avue-crud>
    <el-dialog
      :visible.sync="detailDia"
      width="40%"
      class="enterDetail"
      destroy-on-close
      append-to-body
    >
      <div slot="title" class="header-title">
        <span class="fts-16">关联设置</span>
      </div>
      <glsz @close="closeDia" :currentRow="currentRow"></glsz>
    </el-dialog>
  </div>
</template>

<script>
import { getMetaCommonList, delMetaCommon } from "@/api/busiMode/metaCommon";
import standardService from "@/api/metadata/standard";
import { mapGetters } from "vuex";
import glsz from "./glsz";
import { doorevents, syncReSourcesDoors } from "@/api/visitor/visitor";
import * as dictService from "@/api/system/dictbiz";

export default {
  components: { glsz },
  data() {
    return {
      searchText: "",
      regionName: "",
      form: {},
      selectionList: [],
      loading: true,
      dialogVisible: false,
      detailDia: false,
      query: {},
      dialogType: "",
      type: "",
      size: "60%",
      currentRow: {},
      title: "",
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      option: {
        dateBtn: false,
        border: true,
        height: "auto",
        tip: true,
        searchShow: true,
        searchMenuSpan: 12,
        addBtn: false,
        refreshBtn: true,
        addRowBtn: false,
        columnBtn: true,
        delBtn: false,
        editBtn: false,
        cancelBtn: false,
        rowKey: "",
        showPage: true,
        index: true,
        indexLabel: "序号",
        selection: false,
        showHeader: true,
        expand: false,
        highlightCurrentRow: true,
        stripe: false,
        menuWidth: 150,
        reserveSelection: true,
        column: [
          {
            width: 100,
            overHidden: true,
            align: "left",
            label: "主键",
            prop: "id",
            hide: true,
            span: 4,
            key: "id",
          },
          {
            overHidden: true,
            align: "center",
            label: "所在区域",
            prop: "regionName",
            hide: false,
            show: true,
            span: 4,
          },
          {
            overHidden: true,
            align: "left",
            label: "门禁名称",
            prop: "name",
            hide: false,
            show: true,
            span: 4,
            key: "name",
          },
          {
            align: "left",
            label: "门禁类型",
            prop: "doorType",

            dicUrl: dictService.getUrl("DoorType"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            show: true,
            span: 4,
            key: "name",
          },
          {
            overHidden: true,
            align: "left",
            label: "所在楼宇",
            prop: "locationBuildName",
            hide: false,
            show: false,
            span: 4,
            key: "locationBuildName",
          },
          {
            overHidden: true,
            align: "left",
            label: "所在楼层",
            prop: "locationFloorName",
            hide: false,
            show: false,
            span: 4,
            key: "locationFloorName",
          },
          {
            overHidden: true,
            align: "left",
            label: "门禁所在会议室/场馆",
            prop: "locationCodRoomName",
            hide: false,
            show: false,
            span: 4,
            key: "locationCodRoomName",
          },

          {
            overHidden: true,
            align: "left",
            label: "所在方位",
            prop: "locationName",
            hide: false,
            show: false,
            span: 4,
            key: "locationName",
          },

          {
            width: 100,
            overHidden: true,
            align: "left",
            label: "创建时间",
            prop: "createTime",
            hide: true,

            span: 4,
            key: "create_user_id",
          },
        ],
      },
      model: "wisdom_park.wp_third_hk_resources_doors",
      data: [],

      ruleModel: {},
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.code_add, false),
        viewBtn: this.vaildData(this.permission.code_view, false),
        delBtn: this.vaildData(this.permission.code_delete, false),
        editBtn: this.vaildData(this.permission.code_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  mounted() {
    this.queryByModelCode();
    this.getDicList();
  },
  props: {},
  watch: {},
  methods: {
    getDicList() {
      dictService
        .getDictionary({
          code: "DoorType",
        })
        .then((res) => {
          this.doorTypes = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: Number(item["dictKey"]),
            };
          });
        });
    },
    add() {
      this.type = "add";
      syncReSourcesDoors().then((res) => {
        this.$message.success("同步成功");
      });
    },
    openDetailDia(type, row) {
      this.type = type;
      this.detailDia = true;
      setTimeout(() => {
        this.currentRow = row;
      }, 0);
    },
    back() {
      this.detailDia = false;
      this.searchReset();
    },
    queryByModelCode() {
      standardService.getModelRule(this.model).then((res) => {
        this.ruleModel = res;
      });
    },
    changName(row) {
      this.title = row.name;
    },

    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },

    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },

    refreshChange() {
      this.onLoad(this.page);
    },
    closeDia() {
      console.log("关闭");
      this.detailDia = false;
      this.currentRow = {};
      this.refreshChange();
    },
    onLoad() {
      this.loading = true;
      let body = {
        current: this.page.currentPage,
        size: this.page.pageSize,
      };
      if (this.searchText) {
        body["wheres"] = [
          { field: "name", op: "LIKE", value: this.searchText },
        ];
      }
      if (this.regionName) {
        body["wheres"] = [
          { field: "regionName", op: "LIKE", value: this.regionName },
        ];
      }

      getMetaCommonList(this.model, body).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        data.records.forEach((e) => {
          this.doorTypes.forEach((type) => {
            if (e.doorType == type.value) {
              e.doorType = type.name;
            }
          });
        });

        this.data = data.records;
        this.loading = false;
      });
    },
    doorevents(row) {
      this.detailDia = true;
      this.currentRow = row;
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.deleteIcon {
  font-size: 18px;
  position: absolute;
  left: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 1;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
}
/deep/ .el-drawer__header {
  border-bottom: 1px solid #ebebeb;
  padding: 10px 20px;
  margin-bottom: 0px;
}
/deep/ .el-drawer__body {
  padding: 0px;
}
/deep/ .el-dialog__body {
  padding: 0px;
}
.buildDetail /deep/ .el-dialog__body {
  padding: 0px;
}
</style>

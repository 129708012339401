<template>
    <div>
        <basic-container class="cards">
            <el-form :inline="true" :model="formInline" size="small" class="demo-form-inline">
                <el-form-item label="时间">
                    <el-date-picker class="w-150" v-model="formInline.specialDate" placeholder="开始日期">
                    </el-date-picker>
                </el-form-item>

                <el-form-item label="所属区域" prop="name">
                    <el-select @change="getBuildList" v-model="formInline.sub_part_id" size="small" clearable filterable @clear="clearPart" placeholder="请选择区域">
                        <template v-for="(item, index) in partList">
                            <el-option v-if="item.parent_id != 0" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="楼宇">
                    <el-select class="w-200" v-model="formInline.buildingId" clearable filterable size="small" @change="getCompanyList" placeholder="请选择所属楼宇">
                        <el-option v-for="(item, index) in buildList" :key="index" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="企业">
                    <el-select class="w-200" v-model="formInline.companyId" clearable filterable size="small" @change="getContractList" placeholder="请选择企业" @clear="clearCom">
                        <el-option v-for="(item, index) in companyList" :key="index" :label="item.name" :value="item.company_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="合同">
                    <el-select class="w-200" v-model="formInline.contract_code" clearable filterable size="small" @change.native="selectBlur" @blur.native="selectBlur" placeholder="请选择合同">
                        <el-option v-for="(item, index) in contractList" :key="index" :label="item.contract_code" :value="item.contract_id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="search">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button class="pull-right" type="primary" plain @click="exportTable" icon="el-icon-download" size="small">导出</el-button>
                </el-form-item>
            </el-form>
        </basic-container>
        <basic-container isCard>
            <el-table :data="tableData" style="width: 100%" id="exportTable" v-loading="isLoading" element-loading-text="加载中，请稍后..." index border :height="height">
                <el-table-column header-align="center" :label="`${partName}租赁情况汇总`">
                    <el-table-column type="index" header-align="center" width="60">
                    </el-table-column>
                    <el-table-column prop="contract_code" align="center" label="合同编号" width="240">
                    </el-table-column>

                    <el-table-column prop="renters_name" align="left" header-align="center" label="企业名称" width="250">
                        <template slot-scope="{ row }">
                            <span :class="{ ft500: !row.contract_code }">{{
                row.renters_name
              }}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="start_date" align="center" label="租赁期" width="200">
                        <template slot-scope="{ row }">
                            {{ formatDate(row.start_date) }}
                            <span v-if="row.start_date"> 至 </span>
                            {{ formatDate(row.end_date) }}
                        </template>
                    </el-table-column>
                    <el-table-column label="楼层" align="center" width="80" header-align="center" prop="floor_code">
                    </el-table-column>
                    <el-table-column label="房间号" align="left" width="100" header-align="center" prop="room_name">
                    </el-table-column>
                    <el-table-column label="面积(m²)" header-align="center" align="right" prop="room_area" width="150">
                        <template slot-scope="{ row }">
                            {{ toqfw(row.room_area) || "-" }}
                        </template>
                    </el-table-column>

                    <template v-for="td of tdList">
                        <el-table-column :label="`${td}(m²)`" width="150" header-align="center" align="right">
                            <template slot-scope="{ row }">
                                {{ toqfw(row[td]) || "-" }}
                            </template>
                        </el-table-column>
                    </template>
                    <el-table-column prop="remarks" label="备注">
                    </el-table-column>
                </el-table-column>
            </el-table>
        </basic-container>
    </div>
</template>

<script>
import { getMetaCommonList } from '@/api/busiMode/metaCommon';
import XLSXS from 'xlsx-style';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import Vue from 'vue';
import { getAppToken, queryAreaBySpecialDate } from '@/api/reportForms/reportForms';
import numCard from '../../data-view/widgets/num-card';
const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
export default {
    data() {
        return {
            formInline: {
                specialDate: '',
            },
            height: viewPortHeight - 320,
            value1: '',
            partList: [],
            value2: '',
            tableData: [],
            buildList: [],
            companyList: [],
            contractList: [],
            tdList: [],
            partName: '',
            token: '',
            isLoading: false,
        };
    },
    mounted() {
        this.formInline.specialDate = dayjs().format('YYYY-MM-DD');
        this.getPart();
        this.search();
    },
    methods: {
        clearCom() {
            delete this.formInline.company_id;
            delete this.formInline.companyId;
            delete this.formInline.contract_code;
            this.contractList = [];
            this.$forceUpdate();
        },
        toqfw(num) {
            if (Number(num)) {
                let n = num;
                n = this.addDou(Number(num).toFixed(2));
                return n;
            } else {
                return num;
            }
        },
        selectBlur(e) {
            console.log(e);
            Vue.set(this.formInline, 'contract_code', e.target.value);
        },
        addDou(value) {
            var isNegative = value < 0;
            value = Math.abs(value).toString();
            var decimalIndex = value.indexOf('.');
            if (decimalIndex === -1) {
                decimalIndex = value.length;
            }
            for (var i = decimalIndex - 3; i > 0; i -= 3) {
                value = value.slice(0, i) + ',' + value.slice(i);
            }
            if (isNegative) {
                value = '-' + value;
            }
            return value;
        },
        formatDate(createTime) {
            let obj = '';
            if (createTime) {
                obj = dayjs(createTime).format('YYYY-MM-DD');
            }
            return obj;
        },
        query() {
            let params = {
                appKey: '97D0F856DEE24F8ABFF1E876415F44B0',
                expireTime: 50000, //expireTime是token超时时间，你设置成10000 就行。10秒
            };
            getAppToken(params)
                .then(res => {
                    if (res.data.code == 200 && res.data.data.token) {
                        this.token = res.data.data.token;
                        this.search();
                    }
                })
                .catch(error => {});
        },
        search() {
            if (this.formInline['specialDate']) {
                this.formInline['specialDate'] = dayjs(this.formInline['specialDate']).format('YYYY-MM-DD HH:mm:ss');
            }
            this.getPart();
        },

        getStatFinanceCalculateArea(data) {
            let o = {};
            o = JSON.parse(JSON.stringify(data));
            if (data) {
                if (this.formInline.specialDate) {
                    this.formInline.specialDate = dayjs(this.formInline.specialDate).format('YYYY-MM-DD HH:mm:ss');
                } else {
                    this.formInline.start_date = '';
                }

                if (this.formInline['contract_code']) {
                    o.contractCode = this.formInline['contract_code'];
                }
            }
            this.isLoading = true;
            queryAreaBySpecialDate(o)
                .then(res => {
                    this.tableData = [];
                    this.tdList = [];

                    const data = res.data;
                    let tableData = [];
                    if (data.data.resultDetail.length == 0) {
                        this.isLoading = false;
                        return;
                    }
                    data.data.resultDetail.forEach(e => {
                        tableData = tableData.concat(e.detailArea);
                        let obj = {
                            renters_name: e.totalArea.buildingName + ' 出租小计',
                        };
                        for (let key in e.totalArea) {
                            if (key == 'area') {
                                if (Number.isFinite(e.totalArea[key])) {
                                    obj['room_area'] = e.totalArea[key].toFixed(2);
                                } else {
                                    obj['room_area'] = e.totalArea[key];
                                }
                            }
                        }
                        tableData.push(obj);
                    });
                    let totalArea = {
                        renters_name: '总面积出租',
                    };
                    if (Number.isFinite(data.data.resultTotal)) {
                        totalArea['room_area'] = data.data.resultTotal.toFixed(2);
                    } else {
                        totalArea['room_area'] = data.data.resultTotal;
                    }
                    tableData.push(totalArea);
                    this.tableData = tableData;
                    this.tdList = data.data.month;
                    this.isLoading = false;
                })
                .catch(() => {
                    this.isLoading = false;
                });
        },

        getPart() {
            let body = {
                current: 1,
                size: 10,
                orders: [{ field: 'sort', sort: 'ASC' }],
            };
            getMetaCommonList('wisdom_park.wp_part', body).then(res => {
                const data = res.data.data;
                this.partList = data.records;
                if (this.partList.length > 0) {
                    this.getStatFinanceCalculateArea(this.formInline);
                }
                //this.getBuildList();
                //this.formInline.sub_part_id = this.partList[0]["id"];
            });
        },
        clearPart() {
            delete this.formInline.sub_part_id;
        },
        getBuildList() {
            this.formInline.buildingId = '';
            this.partList.forEach(e => {
                if (e.id == this.formInline.sub_part_id) {
                    this.partName = e.name;
                }
            });
            this.formInline.buildingId = '';
            getMetaCommonList('wisdom_park.wp_building', {
                size: 100,
                current: 1,
                wheres: [{ field: 'sub_part_id', value: this.formInline.sub_part_id }],
            }).then(res => {
                const data = res.data.data;
                this.buildList = data.records;
            });
        },
        getCompanyList() {
            getMetaCommonList('wisdom_park_statistics.v_wp_company_building_stat', {
                size: 100,
                current: 1,
                wheres: [{ field: 'building_id', value: this.formInline.buildingId }],
            }).then(res => {
                const data = res.data.data;
                this.companyList = data.records;
            });
        },
        getContractList() {
            if (this.formInline.companyId) {
                getMetaCommonList('wisdom_park_statistics.v_company_contract_stat', {
                    size: 100,
                    current: 1,
                    wheres: [{ field: 'company_id', value: this.formInline.companyId }],
                }).then(res => {
                    const data = res.data.data;
                    this.contractList = data.records;
                });
            }
        },

        // 点击事件触发的方法
        exportTable() {
            // 首先加载  <table id='excelTable'> 标签 为 workbook对象
            let workbook = this.$XLSX.utils.table_to_book(document.getElementById('exportTable'), { raw: true });
            // 先定义列宽 ， 我这里文件一共有7 列 ，所以设置7列宽度相等都为 20 ，如果你有很多列建议直接 map()
            let wscols = [
                { wch: 5 },
                { wch: 22 },
                { wch: 22 },
                { wch: 22 },
                { wch: 20 },
                { wch: 15 },
                { wch: 15 },
                { wch: 20 },
            ];

            // 获取 需要设置样式的 sheet ，我这里只有 一个 sheet 所以索引默认加载了第一个
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            // 设置列宽
            sheet['!cols'] = wscols;
            // 定义框线样式
            const borderAll = {
                color: { auto: 1 },
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            };

            // 这里的意思为 先默认代表表格的 7 个列  的 列号
            // 比如 A2 意思是 第一列 第2行
            const _letterList = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

            // 定义一个 箭头函数，接受三个参数，分别为 当前Sheet , 行列号（例如：‘A2’）, 是否是新增样式
            const _mapCellStyle = (_sheet, _key, _type) => {
                const _cellStyle = {
                    border: borderAll,
                    alignment: {
                        wrapText: true,
                        horizontal: 'center',
                        vertical: 'center',
                    },
                    font: {
                        name: '微软雅黑',
                        sz: 10,
                        height: 50,
                    },
                    height: 50,
                    bold: true,
                    numFmt: '0',
                };
                const _title = {
                    // border: borderAll,
                    alignment: {
                        wrapText: true,
                        horizontal: 'center',
                        vertical: 'center',
                    },
                    font: {
                        name: '微软雅黑',
                        sz: 16,
                    },
                    bold: false,
                    numFmt: '0',
                };
                if (_key == 'A1') {
                    if (_type === 'append') {
                        // 需要新增样式，说明当前加载的sheet中并不存在该单元格，可能当前单元格被前边合并，这种被合并的单元格也得设置样式，
                        // 否则就会出现 合并单元格只有第一格带框线，后边没框线的情况出现，所以这里需要将后边的边框样式也加上。
                        _sheet[_key] = {
                            s: _title,
                        };
                    } else {
                        // 若不是新增样式 则代表sheet中已存在该表格直接修改其 s 属性即可使属性生效
                        if (typeof _sheet[_key] === 'object') {
                            _sheet[_key].s = _title;
                        }
                    }
                } else {
                    if (_type === 'append') {
                        // 需要新增样式，说明当前加载的sheet中并不存在该单元格，可能当前单元格被前边合并，这种被合并的单元格也得设置样式，
                        // 否则就会出现 合并单元格只有第一格带框线，后边没框线的情况出现，所以这里需要将后边的边框样式也加上。
                        _sheet[_key] = {
                            s: _cellStyle,
                            v: _sheet['v'] + '1',
                        };
                    } else {
                        // 若不是新增样式 则代表sheet中已存在该表格直接修改其 s 属性即可使属性生效
                        if (typeof _sheet[_key] === 'object') {
                            _sheet[_key].s = _cellStyle;
                        }
                    }
                }
            };

            // sheet 不懂得可以单独打印一下，它其实就是一个对象，键代表行列号（‘A2’），值为该单元格的值，样式等，
            // 我们需要做的就是修改其值中的样式
            Object.keys(sheet).forEach((i, _) => {
                // 无用属性直接过滤
                if (i !== '!ref' || i !== '!cols' || i !== '!merges' || i !== 'Am') {
                    // 首先设置遍历到的 当前 key
                    let _nowKey = i;
                    // 然后调用 _mapCellStyle  渲染当前单元格样式
                    _mapCellStyle(sheet, _nowKey);
                    // 我们这里先获取下一个行列号  例如当前_nowKey 是 A1  这里就是获取到 B1 ,及 当前行的 下一列数据
                    let _nextKey = _letterList[_letterList.indexOf(_nowKey[0]) + 1] + i.slice(1);

                    // 判断 B1 是否在 Sheet的key中,如果不存在，只可能是因为B1所在单元格已经被A1所合并，所以我们需要将B1也调用一下  _mapCellStyle
                    // 渲染 B1 的样式，不然就会出现 A1 B1 这两格是合并的状态，只有A1有框线，而B1 没有框线的情况。
                    // 这里用 while 就是 要把需要合并的单元格全部 渲染上样式，直到可能 B4 在 Sheet中 停止
                    while (_nowKey[0] !== 'G' && !Object.keys(sheet).includes(_nextKey)) {
                        _mapCellStyle(sheet, _nextKey, 'append');
                        // 这里就是简单的交换索引
                        _nowKey = _nextKey;
                        _nextKey = _letterList[_letterList.indexOf(_nowKey[0]) + 1] + _nowKey.slice(1);
                    }
                }
            });

            let wopts = {
                bookType: 'xlsx',
                bookSST: false,
                type: 'buffer',
            };
            let _blob = XLSXS.write(workbook, wopts);

            FileSaver.saveAs(
                new Blob([_blob], {
                    type: 'application/octet-stream',
                }),
                this.partName + '面积测算表' + new Date().getTime() + '.xlsx',
            );
        },
        //传入 YYYY-MM , YYYY-MM  (2020-09)  (2020-12)  返回 YYYY-MM 数组
        getYearAndMonth(start, end) {
            let result = [];
            let starts = start.split('-');
            let ends = end.split('-');
            let staYear = parseInt(starts[0]);
            let staMon = parseInt(starts[1]) - 1;
            let endYear = parseInt(ends[0]);
            let endMon = parseInt(ends[1]);
            while (staYear <= endYear) {
                if (staYear === endYear) {
                    while (staMon < endMon) {
                        staMon++;
                        var str = staYear + '-' + (staMon >= 10 ? staMon : '0' + staMon);
                        result.push(str);
                    }
                    staYear++;
                } else {
                    staMon++;
                    if (staMon > 12) {
                        staMon = 1;
                        staYear++;
                    }
                    let str = staYear + '-' + (staMon >= 10 ? staMon : '0' + staMon);
                    result.push(str);
                }
            }
            return result;
        },
    },
};
</script>
<style scoped lang="scss" rel="stylesheet/scss">
.ft500 {
    font-weight: 700;
}
/deep/ .el-table thead.is-group th {
    background: #fafafa !important;
}
.bg-white {
    background-color: #fff !important;
}
.cards {
    background: #fff;
    padding: 15px 10px 0px 10px !important;
    padding-right: 0px !important;
    margin-bottom: 15px;
}
</style>

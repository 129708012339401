<template>
  <div>
    <avue-form
      v-loading="loading"
      :option="option"
        ref="crud"
        v-model="form"
        >
        <template slot='name1'>
          <el-input disabled v-model='apiCollectionMap[form.apiCollectionId]'></el-input>
        </template>
        <!-- 入参 -->
        <template slot='inParams'>
          <avue-crud :option='inOption' :data='inputParams'>
            <template slot='dataType' slot-scope="{row}">
              {{paramTypes[row.dataType]}}
            </template>
          </avue-crud>
        </template>
        <!-- 返回参数 -->
        <template slot='backParams'>
          <avue-crud :option='outOption' :data='outputParams'>
            <template slot='dataType' slot-scope="{row}">
              {{paramTypes[row.dataType]}}
            </template>
          </avue-crud>
        </template>
        <!-- 授权应用 -->
        <template slot='app'>
          <avue-crud :option='licenseOption' :data='licensedList'>
          </avue-crud>
        </template>
    </avue-form>
  </div>
</template>
<script>

import api from '@/api/dataShare/index';
import { getDataAPICollectionSelect } from '@/api/dataService/dataAPICollection';
import * as dictbizService from '@/api/system/dictbiz';

export default {
  data(){
    return {
      inOption: {
        addBtn: false,
        viewBtn: false,
        delBtn: false,
        editBtn: false,
        refreshBtn: false,
        columnBtn:false,
        simplePage: false,
        header: false,
        menu: false,
        border: true,
        column:[
          {
            label:'参数',
            prop:'key',
            span: 24,
          },
          {
            label:'数据类型',
            prop:'dataType',
            slot: true
          },
          {
            label:'是否必填',
            prop:'required',
            formatter(row,value,label,column) {
              if (row.required) {
                return '是'
              } else {
                return '否'
              }
            }
          },
          {
            label:'默认值',
            prop:'default',
          },
          {
            label:'示例值',
            prop:'eg',
          },
          {
            label:'描述',
            prop:'desc',
          },
        ]
      },
      outOption: {
        addBtn: false,
        viewBtn: false,
        delBtn: false,
        editBtn: false,
        refreshBtn: false,
        columnBtn:false,
        simplePage: false,
        header: false,
        menu: false,
        border: true,
        column:[
          {
            label:'参数',
            prop:'key',
            span: 24,
          },
          {
            label:'数据类型',
            prop:'dataType',
            slot: true
          },
          {
            label:'是否必填',
            prop:'required',
            formatter(row,value,label,column) {
              if (row.required) {
                return '是'
              } else {
                return '否'
              }
            }
          },
          {
            label:'默认值',
            prop:'default',
          },
          {
            label:'示例值',
            prop:'eg',
          },
          {
            label:'描述',
            prop:'desc',
          },
        ]
      },
      licenseOption: {
        addBtn: false,
        viewBtn: false,
        delBtn: false,
        editBtn: false,
        refreshBtn: false,
        columnBtn:false,
        simplePage: false,
        header: false,
        menu: false,
        border: true,
        column:[
          {
            label:'应用名称',
            prop:'appName',
            span: 24,
          },
          {
            label:'授权时间',
            prop:'createTime',
          },
          {
            label:'有效时间',
            prop:'validTime',
          },
        ]
      },
      option: {
        labelWidth: 120,
        emptyBtn: false,
        submitBtn: false,
        detail:true,
        group: [
          {
            label: '基本信息',
            prop: 'base',
            icon: 'el-icon-edit-outline',
            column: [
              {
                label: 'API集合',
                prop: 'name1',
                formslot:true,
              },
              {
                label: '接口名称',
                prop: 'name',
              },
              {
                label:'请求方式',
                prop:'requestMethod',
                type: 'select',
                dicUrl: '/api/jida-system/dict-biz/dictionary?code=REQUEST_METHOD',
                props: {
                  label: 'dictValue',
                  value: 'dictKey'
                }
              },
              {
                label:'创建方式',
                prop:'typeName',
              },
              {
                label:'外部请求地址',
                prop:'registerShareUrl',
                span: 24
              },
              {
                label:'平台生成地址',
                prop:'shareUrl',
                span: 24
              },
              {
                label:'接口用途',
                prop:'description',
                span: 24,
              },
            ]
          }, {
            label: '入参',
            prop: 'inParams',
            icon: 'el-icon-s-order',
            column: [
              {
                label: '',
                prop: 'inParams',
                span: 24,
                labelWidth: 0,
                formslot:true,
              }
            ]
          }, {
            label: '返回参数',
            prop: 'backParams',
            icon: 'el-icon-s-order',
            column: [
              {
                label: '',
                prop: 'backParams',
                span: 24,
                labelWidth: 0,
                formslot:true,
              }
            ]
          }, {
            label: '授权应用',
            prop: 'app',
            icon: 'el-icon-s-order',
            column: [
              {
                label: '',
                prop: 'app',
                span: 24,
                labelWidth: 0,
                formslot:true,
              }
            ]
          }
        ]
      },
      loading: false,
      form: {
        apiCollectionId: null,
        name: '',
        shareUrl: '',
        description: '',
        requestMethod: ''
      },
      inputParams: [],
      outputParams: [],
      apiCollectionMap: {},
      paramTypes: {},
      methods: {},
      licensedList: []
    }
  },
  props: {
    id: String
  },
  mounted(){
    if(this.id){
      this.loading = true;
      this.initDicData().then(() => {
        this.getDataAPICollectionSelect().then(
          () => {
            this.get();
          }
        );
      });
    }
  },
  methods: {
    getDataAPICollectionSelect(){
      return getDataAPICollectionSelect().then(
        res => {
          (res.data.data || []).map( api => {
            this.apiCollectionMap[api['id']] = api['name'];
          });
        }
      )
    },
    initDicData(){
      return new Promise((a , b) => {
        dictbizService.getDictionary({
          code: 'REQUEST_METHOD'
        }).then(
          res => {
            (res.data.data || []).map(
              item => {
                this.methods[item.dictKey] = item['dictValue'];
              }
            )
            dictbizService.getDictionary({
              code: 'PARAM_TYPE'
            }).then(
              res => {
                (res.data.data || []).map(
                  item => {
                    this.paramTypes[item.dictKey] = item['dictValue'];
                  }
                )
                a();
              }
            )
          }
        )
      })
    },
    get(){
      this.loading = true;
      return api.get({
        id: this.id
      }).then(
        res => {
          this.loading = false;
          if(res.data){
            this.form = res.data.data;
            if (res.data.data.type==0) {
              this.form.typeName= '本地生成'
            } else if (res.data.data.type==1){
              this.form.typeName= '第三方注册'
            } else {
              this.form.typeName= ''
            }
            this.licensedList=[]
            this.inputParams = this.form.inputParams ? JSON.parse(this.form.inputParams) : [];
            this.outputParams = this.form.outputParams ? JSON.parse(this.form.outputParams) : [];
            this.form.licensedList.forEach((i) => {
              i.validTime=(i.startDate==''||i.startDate==null)?"":`${i.startDate}~${i.endDate}`
              this.licensedList.push(i)
            })
          }
        }
      )
    },
  }
}
</script>
<style lang="scss" scoped>
/deep/ .basic__label-row-item-label {
  width: 90px!important;
}
</style>

<template>
  <el-row>
    <el-col :span="24">
      <el-card class="mar-b-15">
        <avue-form
          ref="form"
          v-model="obj"
          :option="option"
          @submit="handleSearchForm"
        ></avue-form> </el-card></el-col
  ></el-row>
</template>
<script>
import { getDetailByPlanId, getQuest, postQuest } from "@/api/busiMode/meta";
export default {
  name: "tableDesign",
  components: {},
  data() {
    return {
      option: {},
      dataSoure: { dataSoureId: "", dataSoureName: "" },
      modelId: "",
      obj: {},
    };
  },
  created() {},
  mounted() {
    this.modelId = this.$route.fullPath.split(
      "/data/form/formCommon/?modleId"
    )[1];
    if (this.modelId) {
      this.getDetailByPlanId();
    }
  },
  methods: {
    getDetailByPlanId() {
      getDetailByPlanId({ id: this.modelId }).then((res) => {
        if (res.data.code == 200) {
          this.dataSoure.dataSoureId = res.data.data.id;
          this.dataSoure.dataSoureName = res.data.data.name;
          this.option = JSON.parse(res.data.data.content);
        }
      });
    },
    handleSearchForm(form, done) {
      if (this.option.queryType == "get") {
        getQuest(this.option.url, this.obj)
          .then((res) => {
            if (res.data.code != 200) {
              done();
              this.$refs.form.resetForm();
            }
          })
          .catch(() => {
            done();
            this.$refs.form.resetForm();
          });
      } else {
        postQuest(this.option.url, this.obj)
          .then((res) => {
            if (res.data.code != 200) {
              done();
              this.$refs.form.resetForm();
            }
          })
          .catch(() => {
            done();
            this.$refs.form.resetForm();
          });
      }
    },
  },
};
</script>

import * as InputTypes from './option-type';

export default {
  FontSize: (opts) => {
    return new InputTypes.NumberInputType({
      defaultValue: 16,
      code: 'fontSize',
      name: '字体大小',
      unit: 'px',
      getValue(value){
        return (value !== undefined ? value : this.value) + (this.unit || 'px');
      },
      ...opts
    })
  },
  LineHeight: (opts) => {
    return new InputTypes.NumberInputType({
      defaultValue: 30,
      code: 'lineHeight',
      name: '行高',
      unit: 'px',
      getValue(value){
        return (value !== undefined ? value : this.value) + (this.unit || 'px');
      },
      ...opts
    })
  },
  TextColor: (opts) => {
    return new InputTypes.ColorPickerInputType({
      defaultValue: '#000',
      code: 'color',
      name: '字体颜色',
      ...opts
    })
  },
  BackgroundColor: (opts) => {
    return newInputTypes.ColorPickerInputType({
      defaultValue: '#fff',
      code: 'backgroundColor',
      name: '背景颜色',
      ...opts
    })
  }
}
export class WidgetType {

  optionList = [];
  component = '';
  type = '';
  name = '';
  datasourceConf = {
    staticData: {
      type: 'text',
      value: ''
    },
    dataOption: []
  }
  testData = null;
  position = {
    x: 0,
    y: 0,
    width: 10,
    height: 5
  };
  public = {};
  testOption = {};

  constructor(opts){
    Object.keys(opts || {}).forEach(
      key => {
        if(this[key]){
          if(typeof opts[key] === 'object'){
            this[key] = {
              ...this[key],
              ...opts[key]
            }
          }else{
            this[key] = opts[key];
          }
        }else{
          this[key] = opts[key];
        }
      }
    )
    Object.assign(this , opts);
  }

  getTestOption(){
    let option = {};
    this.optionList.forEach(
      item => {
        option[item.code] = this.testOption && this.testOption[item.code] !== undefined ? this.testOption[item.code] : item.defaultValue;
      }
    )
    return option;
  }


}
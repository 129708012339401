<template>
  <basic-container
    isCard
    :body-style="{ padding: '0', height: '100%' }"
    class="h-100p"
  >
    <el-container class="h-100p">
      <el-header height="50px" class="lh-50 bor-b" v-if="!isTodo">
        <basic-page-header
          @back="back"
          class="lh-50"
          :title="'来访人详情'"
        ></basic-page-header>
      </el-header>
      <el-container style="height: calc(100% - 50px)" class="pad-20">
        <el-main class="ov-a">
          <el-descriptions
            title="来访人登记"
            :column="4"
            :colon="false"
            direction="vertical"
            :labelStyle="labelStyle"
          >
            <el-descriptions-item label="被访人">{{
              ruleForm.respondentName
            }}</el-descriptions-item>
            <el-descriptions-item label="被访人公司">{{
              ruleForm.companyName
            }}</el-descriptions-item>
            <el-descriptions-item label="来访人姓名">
              {{ ruleForm.visitorName }}</el-descriptions-item
            >
            <el-descriptions-item label="来访人电话">{{
              ruleForm.contact
            }}</el-descriptions-item>
            <el-descriptions-item label="来访人证件类型">
              {{ cardTypeName }}</el-descriptions-item
            >
            <el-descriptions-item label="证件号码">{{
              ruleForm.idCardNo
            }}</el-descriptions-item>

            <el-descriptions-item label="来访人单位">
              {{ ruleForm.visitorCompany }}</el-descriptions-item
            >
            <el-descriptions-item label="来访时间">{{
              ruleForm.visitorTime
            }}</el-descriptions-item>

            <el-descriptions-item label="通行方式">
              <span v-if="ruleForm.inOutType == 0">二维码</span>
              <span v-if="ruleForm.inOutType == 1">卡片</span>
              <span v-if="ruleForm.inOutType == 2">人脸识别</span>
              <span v-if="ruleForm.inOutType == 3">一键开闸</span>
            </el-descriptions-item>
            <el-descriptions-item label="卡号" v-if="ruleForm.inOutType == 1">
              {{ ruleForm.cardNo }}
            </el-descriptions-item>

            <el-descriptions-item label="审核人" v-if="ruleForm.type == 0">
              {{ ruleForm.shr }}
            </el-descriptions-item>
            <el-descriptions-item label="审核时间" v-if="ruleForm.type == 0">
              {{ ruleForm.shsj }}
            </el-descriptions-item>
            <el-descriptions-item label="值班人" v-if="ruleForm.type == 1">
              {{ ruleForm.dutyPerson }}
            </el-descriptions-item>
            <el-descriptions-item label="离开时间">{{
              ruleForm.departureTime
            }}</el-descriptions-item>
          </el-descriptions>
          <el-descriptions
            :column="4"
            :colon="false"
            direction="vertical"
            :labelStyle="labelStyle"
          >
            <el-descriptions-item label="访客车辆">
              {{ ruleForm.visitorCarNo }}
              <span v-if="ruleForm.companyId == deptId">
                <el-button
                  @click="addRulesNew"
                  size="small"
                  type="primary"
                  v-if="
                    ((ruleForm.type == 0 && ruleForm.approvalStatus == 3) ||
                      ruleForm.type == 1) &&
                    ruleForm.visitorCarNo != null
                  "
                  >重新下发车辆通行权限</el-button
                >
              </span>
            </el-descriptions-item>
          </el-descriptions>
          <el-descriptions
            :column="4"
            :colon="false"
            direction="vertical"
            :labelStyle="labelStyle"
          >
            <el-descriptions-item label="事由">{{
              ruleForm.reason
            }}</el-descriptions-item>
          </el-descriptions>
          <template v-if="imgform.imgUrl.length > 0">
            <el-divider content-position="left">访客照片</el-divider>
            <avue-form
              class="mar-t-20"
              :option="option1"
              v-model="imgform"
            ></avue-form>
          </template>
          <template v-if="tableData.length > 0">
            <el-divider content-position="left">随访人员</el-divider>
            <el-table :data="tableData" style="width: 100%">
              <el-table-column prop="visitor_name" label="访客姓名">
              </el-table-column>
              <el-table-column prop="contact" label="访客电话">
              </el-table-column>
              <el-table-column prop="visitor_company" label="访客单位">
              </el-table-column>
              <el-table-column prop="id_card_type" label="证件类型">
              </el-table-column>
              <el-table-column prop="id_card_no" label="证件号码">
              </el-table-column>
            </el-table>
          </template>
        </el-main>
      </el-container>
    </el-container>
  </basic-container>
</template>

<script>
import standardService from "@/api/metadata/standard";
import { submit } from "@/api/visitor/visitor";
import { mapGetters, mapState } from "vuex";
import * as dictbizService from "@/api/system/dictbiz";

import {
  getMetaCommonList,
  getMetaCommonInfo,
  getPicListByIds,
} from "@/api/busiMode/metaCommon";
import { addRulesNew } from "@/api/visitor/visitor";

export default {
  data() {
    return {
      ruleModel: {},
      labelStyle: {
        color: "#999",
        fontSize: "12px",
      },
      cardTypeName: "",

      option1: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,
        disabled: true,
        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,

            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      option: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,

            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      imgform: {
        imgUrl: [],
      },
      ruleForm: {},
      params: {
        zoom: 4,
        zoomEnable: false,
        dragEnable: true,
      },
      ruleModel: {},
      companyList: [],
      entModel: "wisdom_park.wp_company",
      model: "wisdom_park.v_process_instance_visitor_stat",
      cardTypeList: [],
      tableData: [],
      deptId: "",
    };
  },
  computed: {},
  mounted() {
    this.getMetaCommonList();
    this.getDicList();
    if (this.userInfo.companyId && this.userInfo.tenant_id == "000002") {
      this.ruleForm.companyId = this.userInfo.companyId;
    }
    console.log(this.$router.currentRoute.query.visitorId);
    if (this.$router.currentRoute.query.visitorId) {
      this.ruleForm.id = this.$router.currentRoute.query.visitorId;
      this.id = this.$router.currentRoute.query.visitorId;
      this.getMetaCommonInfo();
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    isTodo: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getMetaCommonInfo();
        }
      },
    },
  },
  methods: {
    getDicList() {
      dictbizService
        .getDictionary({
          code: "cardType",
        })
        .then((res) => {
          this.cardTypeList = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
      dictbizService
        .getDictionaryDetail({ code: "qqb_admin_dept" })
        .then((res) => {
          console.log(res.data.data[0]);
          this.deptId = res.data.data[0].dictKey;
        });
    },
    getCardType(val) {
      let name = "";
      this.cardTypeList.forEach((card) => {
        console.log(val, card.value);
        if (val == card.value) {
          name = card.name;
        }
      });
      return name;
    },
    changeCom() {
      let name = "";
      this.companyList.forEach((e) => {
        console.log(e.id, this.ruleForm.companyId);
        if (e.id == this.ruleForm.companyId) {
          name = e.name;
        }
      });
      this.ruleForm.companyName = name;
      this.ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
    },
    toHump(name) {
      return name.replace(/\_(\w)/g, function (all, letter) {
        return letter.toUpperCase();
      });
    },
    //toHump('hello_js_go') //helloJsGo

    getMetaCommonInfo() {
      let obj = {
        pk: "id",
        id: this.id,
      };
      getMetaCommonInfo(this.model, obj).then((res) => {
        if (res.data.code == 200) {
          let obj = {};
          Object.keys(res.data.data).forEach((ele) => {
            obj[this.toHump(ele)] = res.data.data[ele];
          });
          if (obj.idCardType) {
            this.cardTypeName = this.getCardType(obj.idCardType);
          }
          if (obj.idCardType == 0) {
            this.cardTypeName = "居民身份证";
          }
          if (!obj.companyName) {
            let name = "";
            this.companyList.forEach((e) => {
              if (e.id == obj.companyId) {
                name = e.name;
              }
            });
            obj.companyName = name;
          }

          if (obj.parentId || obj.parentId == 0) {
            this.getParMetaCommonList(obj.id);
          }
          this.ruleForm = obj;
          if (this.ruleForm.visitorPhoto) {
            this.getCompanyId(obj.visitorPhoto);
          }
        }
      });
    },
    getParMetaCommonList(parentId) {
      let body = {
        current: 1,
        size: 50,
      };
      body.wheres = [{ field: "parent_id", value: parentId }];
      getMetaCommonList(this.model, body).then((res) => {
        const data = res.data.data;
        data.records.forEach((e) => {
          if (e.id_card_type) {
            e.id_card_type = this.getCardType(e.id_card_type);
          }
          if (e.id_card_type == 0) {
            e.id_card_type = "居民身份证";
          }
        });
        this.tableData = data.records;
      });
    },

    getCompanyId(val) {
      getPicListByIds(val).then((res) => {
        if (res.data.code == 200) {
          console.log(res.data.data);
          let arr = [];
          res.data.data.forEach((pic) => {
            arr.push({
              label: pic.id,
              value: pic.link,
            });
          });
          this.imgform.imgUrl = arr;
        }
      });
    },

    getMetaCommonList() {
      let b = {
        size: 100,
        current: 1,
      };
      b.wheres = [{ field: "status", op: "IN", value: [1] }];
      getMetaCommonList(this.entModel, b).then((res) => {
        const data = res.data.data;
        this.companyList = data.records;
      });
    },
    addRulesNew() {
      addRulesNew({ visitorId: this.ruleForm.id }).then((res) => {
        const data = res.data.data;
        if (data.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        } else {
          this.$message({
            type: "error",
            message: "操作失败,稍等再试!",
          });
        }
      });
    },
    submit() {
      return new Promise((resolve) => {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            resolve({
              valid: true,
              form: this.ruleForm,
            });
          } else {
            let fields = this.$refs["ruleForm"].fields,
              firstError = {
                field: "",
                message: "",
                name: "",
              };
            for (let i = 0; i < fields.length; i++) {
              if (fields[i].validateState === "error") {
                firstError.message = fields[i].validateMessage;
                firstError.field = fields[i].prop;
                firstError.name = fields[i].label;
                break;
              }
            }
            resolve({
              valid: false,
              form: this.ruleForm,
              error: firstError,
            });
          }
        });
      });
    },
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "/visitor/index",
      // });
    },
    save() {
      Promise.all([this.submit()]).then((forms) => {
        //照片
        if (this.imgform) {
          let imgList = [];
          this.imgform.imgUrl.forEach((e) => {
            if (e.label) {
              imgList.push(e.label);
            }
          });
          this.ruleForm.attachment_id = imgList.toString();
        }
        if (this.ruleForm.id) {
          let o = {
            update: this.ruleForm,
            primaryKeyValue: this.ruleForm["id"],
            primaryKey: "id",
          };
          submit(this.ruleForm).then((res) => {
            if (res.data.code == 200) {
              this.$router.push({
                path: "/visitor/index",
              });
            }
          });
        } else {
          submit(this.ruleForm).then((res) => {
            if (res.data.code == 200) {
              this.$router.push({
                path: "/visitor/index",
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
  margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
  left: 0px;
}
/deep/ .el-divider__text {
  padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
  width: 68px;
  height: 68px;
  line-height: 72px;
  vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
}
.btnClass {
  display: flow-root;
  height: 50px;
  line-height: 50px;
}
/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
/deep/ .el-select {
  width: 100%;
}
</style>

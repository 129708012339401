<template>
  <div>
    <!-- <el-form ref="form" :size="config.size" label-width="40px">
      <el-form-item label="类型">
        <el-select class="dis-b" v-model="activeGroup">
          <el-option v-for="itme of widgetGroup" :label="itme" :key="itme" :value="itme"></el-option>
        </el-select>
      </el-form-item>
    </el-form> -->
    <!-- <el-tabs class="h-100p"  v-model="activeGroup">
      <el-tab-pane class="h-100p" v-for="itemGroup of widgetGroup" :key="itemGroup" :label="itemGroup" :name="itemGroup">
        <div style="height: calc(100% - 40px)"  class="widget-type-list">
          <template v-for="widgetItem of widgetTypes">
            <div :key="widgetItem.code" class="widget-type-list-item" :class="{'widget-type-list-item-active': widgetItem.code === widget.selector}" v-if="widgetItem.type === itemGroup" @click="selectWidgetType(widgetItem)">
              <i :class="widgetItem.icon || 'el-icon-menu'"></i>
              <div>
                <span>{{widgetItem.widgetName}}</span>
              </div>
            </div>
          </template>
        </div>
      </el-tab-pane>
    </el-tabs> -->
    <div class="widget-type-list">
      <template v-for="widgetItem of widgetTypes">
        <!-- v-if="widgetItem.type === activeGroup" -->
        <div :key="widgetItem.code" class="widget-type-list-item" :class="{'widget-type-list-item-active': widgetItem.code === widget.selector}" @click="selectWidgetType(widgetItem)">
          <i :class="widgetItem.icon || 'el-icon-menu'"></i>
          <div>
            <span>{{widgetItem.widgetName}}</span>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  inject: [ 'config' ],
  data(){
    return {
      widgetGroup: [],
      activeGroup: null,
      currentWidget: null,
    }
  },
  props: {
    columns: {
      type: Array,
      default(){
        return [];
      }
    },
    widgetTypes: {
      type: Array,
      default(){
        return []
      }
    },
    widget: {
      type: Object,
      default(){
        return null;
      }
    }
  },
  mounted(){
    this.initGroup();
    if(this.widgetGroup.length && (!this.activeGroup || this.activeGroup === '0')){
      this.activeGroup = this.widgetGroup[0];
    }
    if(this.widget.selector){
      this.widgetTypes.forEach(
        item => {
          if(item.code === this.widget.selector){
            this.currentWidget = item;
            this.activeGroup = item.type;
          }
        }
      )
    }
  },
  methods: {
    isArray(arr){
      return Array.isArray(arr);
    },
    initGroup(){
      (this.widgetTypes || []).forEach(
        item => {
          if(this.widgetGroup.indexOf(item.type) < 0){
            this.widgetGroup.push(item.type);
          }
        }
      )
    },
    selectWidgetType(widget){
      this.currentWidget = JSON.parse(JSON.stringify(widget));
      if(this.widget.selector !== widget.code){
        this.widget.selector = widget.code;
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.widget-type-list{
  .widget-type-list-item{
    display: inline-block;
    text-align: center;
    cursor: pointer;
    padding: 15px;
    border: 1px solid transparent;
    min-width: 90px;
    margin-bottom: 10px;
    i{
      font-size: 30px;
    }
    &:hover{
      border: 1px solid #ddd;
    }
  }
  .widget-type-list-item-active{
    color: #409EFF;
  }
}
</style>
<template>
    <div>
        <el-descriptions title="基本信息" :column="1" :colon="false" :labelStyle="labelStyle">
            <el-descriptions-item label="报修类型：">{{info.repair_type_name}}</el-descriptions-item>
            <el-descriptions-item label="状态：">{{info.status_name}}</el-descriptions-item>
            <el-descriptions-item label="维修人员：">{{info.repair_user_name || '-'}}</el-descriptions-item>
            <el-descriptions-item label="报修位置：">{{info.location_name}}</el-descriptions-item>
            <el-descriptions-item label="报修人：">{{info.applicant_name}}</el-descriptions-item>
            <el-descriptions-item label="联系方式：">{{info.applicant_phone}}</el-descriptions-item>
            <el-descriptions-item label="预约时间：">{{info.applicant_time | formatDate('YYYY-MM-DD HH:mm')}}</el-descriptions-item>
            <el-descriptions-item label="描述：">{{info.repair_desc}}</el-descriptions-item>
            <el-descriptions-item label="报修图片：">
                <el-image style="width: 100px; height: 100px" v-for="item of imgList" :src="item" :key="item" :preview-src-list="imgList">
                </el-image>
            </el-descriptions-item>
        </el-descriptions>
        <el-descriptions title="报修进度" :column="0" :colon="false" :labelStyle="{width: '0px'}" :contentStyle="{width: '100%'}">
            <el-descriptions-item label="" labelWidth="0px">
                <div class="w-100p">
                    <el-steps direction="vertical" :active="activeStep" finish-status="success" process-status="success">
                        <el-step v-for="(item , index) of steps" :title="item.title">
                            <div style="color: #999" slot="description">
                                <p class="mar-0" v-for="itemDesc of item.desc">{{itemDesc}}</p>
                            </div>
                        </el-step>
                    </el-steps>
                </div>
            </el-descriptions-item>
        </el-descriptions>
        <el-descriptions class="mar-t-20" title="服务评价" :column="1" :colon="false" :labelStyle="labelStyle" v-if="info.evaluation_time">
            <el-descriptions-item label="评价人：">{{info.evaluation_user}}</el-descriptions-item>
            <el-descriptions-item label="评价时间：">{{info.evaluation_time}}</el-descriptions-item>
            <el-descriptions-item label="评星："><el-rate disabled :value="info.evaluation_score"></el-rate></el-descriptions-item>
            <el-descriptions-item label="评价内容：">{{info.service_evaluation}}</el-descriptions-item>
            <el-descriptions-item label="评价图片：">
                <el-image style="width: 100px; height: 100px" v-for="item of imgList1" :src="item" :key="item" :preview-src-list="imgList1">
                </el-image>
            </el-descriptions-item>
        </el-descriptions>
        <el-descriptions class="mar-t-20" title="回访反馈" :column="1" :colon="false" :labelStyle="labelStyle" v-if="info.status == 4">
            <template v-for="item of returnVisit">
                <el-descriptions-item label="回访人：">{{item.return_visit_user_name}}</el-descriptions-item>
                <el-descriptions-item label="回访人电话：">{{item.return_visit_user_phone}}</el-descriptions-item>
                <el-descriptions-item label="反馈内容：">{{item.return_visit_content}}</el-descriptions-item>
            </template>
        </el-descriptions>
    </div>
</template>
<script>
import {
    saveMetaCommon,
    updateMetaCommon,
    getMetaCommonList,
    getMetaCommonDetail,
    getPicListByIds,
} from '@/api/busiMode/metaCommon';
import * as dictService from '@/api/system/dictbiz';
export default {
    data() {
        return {
            model: 'wisdom_park.wp_repair',
            detailTable: 'wp_repair_operation_log',
            masterIdColumnName: 'repair_id',
            info: {},
            labelStyle: {
                color: '#999',
                width: '100px',
            },
            activeStep: 0,
            steps: [
                { title: '提交报修', desc: ['未开始'] , type: 0},
                { title: '指派维修人员', desc: ['未开始'] , type: 1},
                { title: '开始维修', desc: ['未开始'] , type: 2},
                { title: '维修完成', desc: ['未开始'] , type: 3},
            ],
            relations: [
                {
                    code: 'repair_type',
                    model: 'RepairServerType',
                    props: [{ code: 'repair_type_name', name: 'dictValue' }],
                    type: 2,
                },
                {
                    code: 'status',
                    model: 'RepairServiceStatus',
                    props: [{ code: 'status_name', name: 'dictValue' }],
                    type: 2,
                },
            ],
            imgList: [],
            imgList1: [],
            imgLinkReplace: {
                source: '10.147.17.103',
                target: '10.100.170.103',
            },
            returnVisit: [],
        };
    },
    props: {
        id: {
            type: String,
            default: '',
        },
    },
    mounted() {
        if (this.id) {
            this.getDetail();
        }
    },
    methods: {
        getDetail() {
            getMetaCommonDetail(this.model, {
                pk: 'id',
                id: this.id,
                detailTable: this.detailTable,
                masterIdColumnName: this.masterIdColumnName,
            }).then(res => {
                let info = res.data.data;
                this.activeStep = Number(info.status);
                this.activeStep = this.activeStep > 3 ? 3 : this.activeStep;
                info.listDetailValues.forEach(
                    item => {
                    if(item.operation_type === 1){
                        this.steps[0].desc = [item.update_time];
                    }
                    if(item.operation_type === 2){
                        this.steps[1].desc = [item.operation_remark , item.update_time];
                    }
                    if(item.operation_type === 3){
                        this.steps[2].desc = [item.operation_remark , item.update_time];
                    }
                    if(item.operation_type === 4){
                        this.steps[3].desc =[item.operation_remark , item.update_time];
                    }
                    }
                );
                this.getPicListByIds(info);
                this.getRelationData(info);
                if (info.status == 4) {
                    getMetaCommonList('wisdom_park.wp_repair_return_visit', {
                        wheres: [{ field: 'repair_id', value: info.id }],
                    }).then(res => {
                        this.returnVisit = res.data.data.records;
                    });
                }
            });
        },
        getPicListByIds(info) {
            [
                { code: 'repair_pictures', prop: 'imgList' },
                { code: 'evaluation_pictures', prop: 'imgList1' },
            ].forEach(item => {
                let prop = item.prop;
                let ids = info[item.code] ? info[item.code].split(',') : [];
                if (ids.length) {
                    getPicListByIds(ids).then(res => {
                        (res.data.data || []).forEach(item => {
                            this[prop].push(item.link);
                        });
                    });
                }
            });
        },
        getRelationData(info) {
            let len = 0;
            let resHandle = res => {
                len++;
                info = {
                    ...info,
                    ...res,
                };
                if (len >= this.relations.length) {
                    this.info = info;
                }
            };
            if (this.relations.length) {
                this.relations.forEach(item => {
                    if (info[item.code] !== '' && info[item.code] !== null && info[item.code] !== undefined) {
                        if (item.type === 1) {
                            this.getMetaCommonDetail(item, info).then(resHandle);
                        } else {
                            this.getDictionary(item, info).then(resHandle);
                        }
                    } else {
                        item.props.forEach(prop => {
                            info[prop.code] = '-';
                        });
                    }
                });
            } else {
                this.info = info;
            }
        },
        getMetaCommonDetail(item, form) {
            return getMetaCommonDetail(`wisdom_park.${item.model}`, {
                pk: 'id',
                id: form[item.code],
            }).then(res => {
                let info = {};
                if (res.data.data) {
                    let data = res.data.data;
                    item.props.forEach(prop => {
                        info[prop.code] = data[prop.name];
                    });
                }
                return info;
            });
        },
        getDictionary(item, form) {
            return dictService
                .getDictionary({
                    code: item.model,
                })
                .then(res => {
                    let info = {};
                    if (res.data && res.data.data) {
                        let data = res.data.data.find(d => {
                            if (d['dictKey'] === `${form[item.code]}`) {
                                return d;
                            }
                        });
                        if (data) {
                            item.props.forEach(prop => {
                                info[prop.code] = data[prop.name];
                            });
                        }
                    }
                    return info;
                });
        },
    },
};
</script>
<style lang="scss" scoped>
.custom_process{
    .custom_process_item{
        display: flex;
        .custom_process_item_left{
            width: 20px;
        }
        .custom_process_iten.content{
            flex: 1;
        }
    }
}
</style>
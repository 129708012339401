<template>
    <avue-crud ref="table" :data="tableData" :page.sync="page" :option="tableOption" :table-loading="tableLoading" :search.sync="search" @search-change="searchChange" @on-load="onLoad"
               @refresh-change="onLoad" @sort-change="sortChange">
        <template slot-scope="{ size }" slot="menuLeft">
            <el-button icon="el-icon-download" :size="size || 'small'" :loading="downLoading" @click="getMetaCommonListExport">导 出</el-button>
        </template>
        <!-- <template slot="tran_dateSearch" slot-scope="scope">
      <el-date-picker class="w-100p" v-model="search.tran_date" :unlink-panels="true"
        value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始时间"
        end-placeholder="结束时间">
      </el-date-picker>
    </template>
    <template slot="acc_dateSearch" slot-scope="scope">
      <el-date-picker class="w-100p" v-model="search.acc_date" :unlink-panels="true"
        value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始时间"
        end-placeholder="结束时间">
      </el-date-picker>
    </template> -->
    </avue-crud>
</template>
<script>
import {
    saveMetaCommon,
    updateMetaCommon,
    getMetaCommonList,
    delMetaCommon,
    getMetaCommonDetail,
    getMetaCommonListTree,
    getMetaCommonListExport,
} from '@/api/busiMode/metaCommon';
import * as dictService from '@/api/system/dictbiz';

export default {
    data() {
        return {
            model: 'wisdom_park.v_wp_station_charging_order',
            modelView: 'wisdom_park.v_wp_station_charging_order',
            tableLoading: false,
            downLoading: false,
            tableData: [],
            search: {},
            page: {
                pageSizes: this.$store.state.common.pageSizes,
                pagerCount: this.$store.state.common.pagerCount,
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            tableOption: {
                height: 'auto',
                // calcHeight: 10,
                // labelPosition: 'top',
                // searchMenuPosition: 'right',
                searchMenuSpan: 24,
                border: true,
                delBtn: false,
                editBtn: false,
                addBtn: false,
                viewBtn: true,
                labelWidth: 200,
                menuWidth: 100,
                // defaultSort: {
                //   prop: 'tran_date',
                //   order: 'descending'
                // },
                column: [
                    {
                        label: '流水号',
                        prop: 'charging_seq',
                        width: 200,
                        // search: true
                    },
                    {
                        label: '充电站名称',
                        prop: 'station_name',
                        width: 200,
                        // search: true
                    },
                    {
                        label: '充电桩名称',
                        prop: 'device_name',
                        width: 200,
                        // search: true
                    },
                    {
                        label: '充电枪名称',
                        prop: 'gun_name',
                        width: 200,
                        // search: true
                    },
                    // {
                    //   label: "充电枪编号",
                    //   prop: "gun_code",
                    //   width: 200,
                    //   // search: true
                    // },
                    // {
                    //   label: "充电桩序列号",
                    //   prop: "device_code",
                    //   width: 200,
                    //   // search: true
                    // },
                    {
                        label: '开始充电时间',
                        prop: 'start_time',
                        width: 150,
                        // search: true,
                        type: 'datetime',
                        format: 'yyyy-MM-dd HH:mm:ss',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        searchSpan: 12,
                        searchRange: true,
                        // sortable: 'custom',
                    },
                    {
                        label: '结束充电时间',
                        prop: 'end_time',
                        width: 150,
                        // search: true,
                        type: 'datetime',
                        format: 'yyyy-MM-dd HH:mm:ss',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        searchSpan: 12,
                        searchRange: true,
                        // sortable: 'custom',
                    },
                    // {
                    //   label: "运营商ID",
                    //   prop: "dealer_id",
                    //   width: 150,
                    // },
                    // {
                    //   label: "充电站ID",
                    //   prop: "station_id",
                    //   width: 150,
                    // },
                    {
                        label: '用户账号/卡号',
                        prop: 'card_no',
                        width: 150,
                    },
                    {
                        label: '本次充电时长',
                        prop: 'charging_time',
                        width: 150,
                    },
                    {
                        label: '本次充电总电量',
                        prop: 'total_elec_quantity',
                        width: 150,
                    },
                    // {
                    //   label: "总启始电表读数",
                    //   prop: "total_start_value",
                    //   width: 150,
                    // },
                    // {
                    //   label: "总终止电表读数",
                    //   prop: "total_end_value",
                    //   width: 150,
                    // },
                    {
                        label: '电量费用(元)',
                        prop: 'charged_money',
                        width: 120,
                    },
                    {
                        label: '充电总费用(元)',
                        prop: 'total_charge',
                        width: 150,
                    },
                    {
                        label: '服务费用(元)',
                        prop: 'service_cost',
                        width: 150,
                    },
                    // {
                    //   label: "峰时段充电费用",
                    //   prop: "peak_cost",
                    //   width: 150,
                    // },
                    // {
                    //   label: "峰时段启始电表读数",
                    //   prop: "peak_start_value",
                    //   width: 150,
                    // },
                    // {
                    //   label: "峰时段终止电表读数",
                    //   prop: "peak_end_value",
                    //   width: 150,
                    // },
                    // {
                    //   label: "尖时段充电费用",
                    //   prop: "sharp_cost",
                    //   width: 150,
                    // },
                    // {
                    //   label: "尖时段启始电表读数",
                    //   prop: "sharp_start_value",
                    //   width: 150,
                    // },
                    // {
                    //   label: "尖时段终止电表读数",
                    //   prop: "sharp_end_value",
                    //   width: 150,
                    // },
                    // {
                    //   label: "平时段充电费用",
                    //   prop: "shoulder_cost",
                    //   width: 150,
                    // },
                    // {
                    //   label: "平时段起始电表读数",
                    //   prop: "shoulder_start_value",
                    //   width: 150,
                    // },
                    // {
                    //   label: "平时段终止电表读数",
                    //   prop: "shoulder_end_value",
                    //   width: 150,
                    // },
                    {
                        label: '优惠金额(元)',
                        prop: 'deduct_cost',
                        width: 150,
                    },
                    {
                        label: '实际金额(元)',
                        prop: 'actual_cost',
                        width: 150,
                    },
                ],
            },
            orders: [
                // {field: 'tran_date' , sort: 'DESC'}
            ],
        };
    },
    props: {},
    mounted() {},
    methods: {
        sortChange({ column, prop, order }) {
            this.orders = [
                {
                    field: prop,
                    sort: order === 'descending' ? 'DESC' : 'ASC',
                },
            ];
            this.page.currentPage = 1;
            this.onLoad();
        },
        getMetaCommonListExport() {
            let wheres = this.getSearch();
            this.downLoading = true;
            getMetaCommonListExport(this.modelView, {
                current: 1,
                size: this.page.pageSize,
                wheres: wheres,
            })
                .then(res => {
                    this.downLoading = false;
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.download = '银行对账流水' + '.xls';
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(e => {
                    this.downLoading = false;
                });
        },
        getSearch() {
            let form = this.search,
                wheres = [];
            Object.keys(form).forEach(keyName => {
                if (keyName !== 'ACTIVENAME' && keyName[0] !== '$' && (form[keyName] ?? '') !== '') {
                    const column = this.findObject(this.tableOption.column, keyName);
                    if (column['searchRange']) {
                        let startVal = form[keyName][0],
                            endVal = form[keyName][1];
                        if (startVal && endVal) {
                            // if(column['type'] === 'date'){
                            //   startVal = `${startVal} 00:00:00`
                            // }
                            wheres.push({ field: keyName, value: startVal, op: 'GE' });

                            // if(column['type'] === 'date'){
                            //   endVal = `${endVal} 23:59:59`
                            // }
                            wheres.push({ field: keyName, value: endVal, op: 'LE' });
                        }
                    } else if (column['searchLike']) {
                        wheres.push({ field: keyName, op: 'LIKE', value: form[keyName] });
                    } else {
                        wheres.push({ field: keyName, value: form[keyName] });
                    }
                }
            });
            return wheres;
        },
        searchChange(params, done) {
            this.page.currentPage = 1;
            done();
            this.onLoad();
        },
        onLoad() {
            console.log(111);
            this.tableLoading = true;
            this.tableData = [];
            let wheres = this.getSearch();

            getMetaCommonList(this.model, {
                current: this.page.currentPage,
                size: this.page.pageSize,
                wheres: wheres,
                orders: this.orders,
            })
                .then(res => {
                    this.tableLoading = false;
                    this.tableData = res.data.data.records;
                    this.page.total = res.data.data.total;
                })
                .catch(e => {
                    this.tableLoading = false;
                });
        },
    },
};
</script>
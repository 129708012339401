<template>
  <basic-container class="h-100p">
    <avue-crud
      ref="table"
      :data="tableData"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      @on-load="onLoad()"
      @refresh-change="onLoad()"
    >
      <template slot="menuLeft" slot-scope="scope">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="openDislog('add' , null)"
        >新增合同
        </el-button>
      </template>
      <template slot="menu" slot-scope="scope">
        <el-button
          type="text"
          size="small"
          icon="el-icon-edit"
          @click="openDislog('edit' , scope.row)"
          >编辑
        </el-button>
        <!-- <el-button
          type="text"
          size="small"
          icon="el-icon-document-copy"
          @click="openDislog('copy' , scope.row)"
          >复制
        </el-button> -->
        <el-button
          type="text"
          size="small"
          icon="el-icon-view"
          @click="openDislog('view' , scope.row)"
          >查看
        </el-button>
        <el-button icon="el-icon-delete" size="small" type="text" @click="rowDel(scope.row)">删除</el-button>
      </template>
    </avue-crud>
    <el-dialog
      class="manageForm"
      :title="(dialogTypes[dialogType])+ '合同'"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="75%"
      :top="'5vh'"
    >
      <SubContractForm ref="form" v-if="dialogVisible" :id="currentRow ? currentRow.id : null" :type="dialogType"></SubContractForm>
      <div v-if="dialogType !== 'view'" slot="footer" class="text-right bor-t pad-tb-8 pad-lr-15 ov-h">
        <el-button size="small" @click="dialogVisible = false">取消</el-button>
        <el-button size="small" :loading="saveLoading" @click="saveForm" type="primary" >提交</el-button>
      </div>
    </el-dialog>
  </basic-container>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import { getSubContractList , removeSubContract } from '@/api/contract/sub';
import SubContractForm from './subContractForm.vue';

export default {
  components: { SubContractForm },
  data(){
    return {
      dialogTypes: {
        'add': '新增',
        'edit': '编辑',
        'view': '查看',
        'copy': '新增'
      },
      saveLoading: false,
      dialogVisible: false,
      tableLoading: false,
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      tableData: [],
      tableOption: {
        height: "auto",
        addBtn: false,
        editBtn: false,
        delBtn: false,
        border: true,
        menuWidth: 220,
        column: [
          {
            label: "合同编号",
            prop: "code",
            width: 250,
          },
          {
            label: "合同名称",
            prop: "name",
            width: 250,
          },
          {
            label: "租金总金额",
            prop: "amount",
            width: 150,
            formatter: (row , val) => (`${val}元`)
          },
          {
            label: "合同签订时间",
            prop: "signedDate",
            width: 150,
            format: "yyyy年MM月dd日",
            type: 'date'
          },
          {
            label: "合同保证金",
            prop: "deposit",
            width: 150,
            formatter: (row , val) => (`${val}元`)
          },
          {
            label: "租赁开始时间",
            prop: "startDate",
            width: 150,
            format: "yyyy年MM月dd日",
            type: 'date'
          },
          {
            label: "租赁结束时间",
            prop: "endDate",
            width: 150,
            format: "yyyy年MM月dd日",
            type: 'date'
          },
          {
            label: "出租方签订人",
            prop: "lessorSigned",
            width: 120,
          },
          {
            label: "承租方公司",
            prop: "rentersName",
            width: 250,
          },
          {
            label: "承租方签订人",
            prop: "rentersSigned",
            width: 120,
          },
          {
            label: "承租方联系方式",
            prop: "rentersSignedContact",
            width: 120,
          },
        ]
      },
      dialogType: '',
      currentRow: null,
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    isCompany() {
      return this.userInfo.tenant_id !== "000000" && this.userInfo.companyId;
    },
  },
  mounted(){

  },
  methods: {
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let body = {
          ids: [row.id].join(','),
        }
        return removeSubContract(body);
      }).then(() => {
        this.onLoad();
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    },
    saveForm(){
      this.saveLoading = true;
      this.$refs.form.save().then(
        close => {
          this.saveLoading = false;
          if(close){
            this.onLoad();
            this.dialogVisible = false;
          }
        }
      )
    },
    openDislog(type , row){
      this.dialogType = type;
      this.currentRow = row;
      this.dialogVisible = true;
    },
    onLoad(){
      this.tableLoading = true;
      getSubContractList({
        current: this.page.currentPage,
        size: this.page.pageSize,
        companyId: this.userInfo.companyId
      }).then(
        res => {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          console.log(res);
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.manageForm{
  /deep/.el-dialog__body{
    height: calc(90vh - 110px);
    overflow: auto;
    padding: 15px;
  }
  /deep/.el-dialog__footer{
    text-align: left;
    padding: 0;
  }
}
</style>
import update from './imgs/update.png';
import apiImg from  './imgs/api.png';
import kafka from './imgs/kafka.png';
import process from './imgs/process.png';
import java from './imgs/java.png';


export default {
  'Process': {
    extend: true,
    props: {
      properties: [
        {
          "name": "startupModel",
          "isAttr": true,
          "type": "String"
        },
        {
          "name": "sjcfType",
          "isAttr": true,
          "type": "String"
        },
        {
          "name": "szbs",
          "isAttr": true,
          "type": "String"
        }
      ]
    }
  },
  'bpmn:ORM': {
    info: {
      url: update,
      name: 'ORM',
      className: 'icon-custom bpmn-icon-update',
      group: 'business'
    },
    props: {
      properties: [
        {
          name: 'taskKey',
          type: 'String',
          isAttr: true,
          show: true
        },
        {
          name: 'expression',
          type: 'String',
          isAttr: true,
          show: true
        },
        {
          name: 'operationList',
          type: 'String',
          show: true,
          isAttr: true,
        },
        {
          name: 'operate',
          type: 'String',
          isAttr: true,
          show: true
        }
      ]
    }
  },
  'bpmn:KAFKA': {
    info: {
      url: kafka,
      name: 'KAFKA',
      className: 'icon-custom bpmn-icon-kafka',
      group: 'business'
    },
    props: {
      properties: [
        {
          name: 'taskKey',
          type: 'String',
          isAttr: true,
          show: true
        },
        {
          name: 'expression',
          type: 'String',
          isAttr: true,
          show: true
        },
        {
          name: 'operationList',
          type: 'String',
          show: true,
          isAttr: true,
        },
        {
          name: 'operate',
          type: 'String',
          isAttr: true,
          show: true
        }
      ]
    }
  }
}
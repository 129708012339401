<template>
  <div>普通文本</div>
</template>
<script>

import WidgetMixins from '../widget-mixins'
export default {
  mixins: [ WidgetMixins ],
  name: 'BuiltInWidgetByText',
  data(){
    return {

    }
  }
}
</script>
<template>
<el-container style="height: calc(100% - 50px)" v-loading="pageLoading">
  <el-main class="pad-20 ov-a">
    <el-form class="mar-t-20" :model="form" :rules="rules" ref="form" :size="formSize" label-width="100px">
      <el-row>
        <el-col :span="12">
          <el-form-item label="报废单据号" prop="code">
            <el-input v-model="form.code" disabled placeholder="系统自动生成"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="报废时间" prop="scrap_date">
            <el-date-picker :disabled="disabled" class="dis-b w-100p" v-model="form.scrap_date" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="报废部门" prop="scrap_dept">
            <!-- <el-input v-model="form.scrap_dept_name" disabled></el-input> -->
            <model-cascader
              :option="{
                emitPath: false
              }"
              :show-all-levels="false"
              :disabled="disabled"
              :getData="getDeptData"
              v-model="form.scrap_dept"
              labelKey="title"
              @change="(ev) => {form['scrap_dept_name'] = ev.label;form.scrap_user = '';}"
            >
            </model-cascader>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="报废人" prop="scrap_user">
            <model-search-select
              class="dis-b w-100p"
              :disabled="disabled"
              v-model="form.scrap_user"
              :params="{tenantId: '000000' , deptId: form.scrap_dept}"
              :fillModel.sync="form"
              :fills="[
                {source: 'name' , target: 'scrap_user_name'},
              ]"
            >
              <template slot-scope="{item}">
                <span style="float: left">{{ item.name }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.postName }}</span>
              </template>
            </model-search-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="附件" prop="attachment_id">
            <form-file
              :disabled="disabled"
              v-model="form.attachment_id"
            ></form-file>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <el-form-item label="报废说明" prop="remarks">
            <el-input
              type="textarea"
              :rows="5"
              :disabled="disabled"
              placeholder="请输入内容"
              v-model="form.remarks">
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <div class="pad-tb-10">
        <el-divider content-position="left">报废资产(共{{listDetailValues.length}}条)</el-divider>
      </div>
      <!-- <el-row>
        <el-col :span="24">
          <el-form-item label="时间筛选类型">
            <el-radio-group size="small" v-model="search.timeType" @change="search.value = null;search.value1 = null;">
              <el-radio :label="1">指定时间</el-radio>
              <el-radio :label="2">区间时间</el-radio>
              <el-radio :label="3">小于指定时间</el-radio>
              <el-radio :label="4">大于指定时间</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-show="search.timeType === 2">
          <el-form-item label="使用时间">
            <el-date-picker
              @change="searchTimeChange"  
              v-model="search.value1"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            ></el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="24" v-show="search.timeType !== 2">
          <el-form-item label="使用时间">
            <el-date-picker 
              @change="searchTimeChange"
              v-model="search.value"
              type="date"
              value-format="yyyy-MM-dd"
              placeholder="选择日期"
            ></el-date-picker>
          </el-form-item>
        </el-col>
      </el-row> -->
      
      <el-form-item label-width="0px">
        <el-table
          :data="listDetailValues"
          :size="formSize"
          border
          row-key="asset_id"
          max-height="500"
        >
          <el-table-column v-if="!disabled" type="index" width="50">
            <template slot="header">
              <el-button size="mini" @click="openAddAssetDrawer()" type="primary" icon="el-icon-plus" circle></el-button>
            </template>
            <template slot-scope="scope">
              <el-button size="mini" @click="listDetailValues.splice(scope.$index , 1);" type="danger" icon="el-icon-minus" circle></el-button>
            </template>
          </el-table-column>
          <!-- <el-table-column v-if="!disabled" label="资产" width="210px">
            <template slot-scope="scope">
              <model-search-select
                class="dis-b w-100p"
                v-model="scope.row.asset_id"
                model="wp_asset_detail"
                :fillModel.sync="scope.row"
                labelKey="asset_name"
                valueKey="id"
                :fills="[
                  {source: 'asset_name' , target: 'asset_name'},
                  {source: 'system_asset_code' , target: 'system_asset_code'},
                  {source: 'asset_category_code' , target: 'asset_category_code'},
                  {source: 'cw_start_date' , target: 'cw_start_date'},
                ]"
                :disabledList="listDetailValues.map(item => item.asset_id)"
                :params="searchParams"
              >
                <template slot-scope="{item}">
                  <span style="float: left">{{ item.asset_name }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.system_asset_code }}</span>
                </template>
              </model-search-select>
            </template>
          </el-table-column> -->
          <el-table-column label="资产名称" width="150px" prop="asset_name"></el-table-column>
          <el-table-column label="资产编码" width="150px" prop="system_asset_code"></el-table-column>
          <el-table-column label="资产类型" width="150px" prop="asset_category_code">
            <template slot-scope="{row}">
              {{catalog[row['asset_category_code']] || '-'}}
            </template>
          </el-table-column>
          <el-table-column label="使用时间" width="150px" prop="cw_start_date"></el-table-column>
          <el-table-column min-width="200" label="报废说明" prop="scrap_remark">
            <template slot-scope="scope">
              <el-input :disabled="disabled" v-model="scope.row.scrap_remark"></el-input>
            </template>
          </el-table-column>
        </el-table>
      </el-form-item>
    </el-form>
  </el-main>
  <el-footer v-if="!isView" height="50px" class="lh-50">
    <div class="text-center">
      <el-button :size="formSize" :loading="btnLoading" icon="el-icon-close" @click="back">取 消</el-button>
      <el-popconfirm
        title="确定要清空表格内的全部数据吗？"
        icon="el-icon-info"
        icon-color="red"
        @confirm="listDetailValues = []"
      >
        <el-button class="mar-lr-10" slot="reference" :disabled="!listDetailValues.length" :size="formSize" :loading="btnLoading" icon="el-icon-delete" type="warning">清空表格数据</el-button>
      </el-popconfirm>
      <el-button :size="formSize" :loading="btnLoading" icon="el-icon-check" type="primary" @click="saveForm(false)">保 存</el-button>
      <!-- <el-button :size="formSize" :loading="btnLoading" icon="el-icon-check" type="primary" @click="saveForm(true)">保存并提交审批</el-button> -->
    </div>
  </el-footer>
  <el-drawer
    title="添加资产"
    :visible.sync="drawer"
    append-to-body
    size="800px"
    :withHeader="false"
    :close-on-press-escape="btnLoading ? false : true"
    :wrapperClosable="btnLoading ? false : true"
    :body-style="{'padding': '0'}"
    class="assets-drawer"
  >
    <el-container v-if="drawer" class="h-100p">
      <el-header class="bor-b" height="50px">
        <basic-page-header
          class="lh-50"
          @back="drawer = btnLoading ? true : false"
          title="添加资产"
        ></basic-page-header>
      </el-header>
      <el-main class="ov-h">
        <div class="pad-15 search-table">
          <avue-crud
            v-if="drawer"
            ref="searchTable"
            :page.sync="searchTablePage"
            :search.sync="searchForm"
            :data="searchTableData"
            :option="searchAssetsOption"
            :table-loading="searchTableLoading"
            @on-load="searchTableOnLoad()"
            @refresh-change="searchTableOnLoad()"
            @search-change="handleSearchSubmit"
            @selection-change="searchTableSelection"
            @sort-change="sortChange"
          >
            <template slot-scope="{ disabled , size }" slot="cw_start_dateSearch">
              <el-date-picker
                v-model="searchForm.cw_start_date"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                :unlink-panels="true"
              >
              </el-date-picker>
            </template>
          </avue-crud>
        </div>
      </el-main>
      <el-footer class="text-center pad-tb-15" height="113px">
        <div class="lh-34">
          <el-row >
            <el-col :span="4" style="width: 100px" >
              报废说明
            </el-col>
            <el-col :span="20">
              <el-input size="small" v-model="addScrapRemark" placeholder="请填写报废说明"></el-input>
            </el-col>
          </el-row>
        </div>
        <div class="lh-34 mar-t-15">
          <el-button
            :size="formSize"
            :loading="btnLoading"
            icon="el-icon-close"
            @click="drawer = false"
            >取 消</el-button
          >
          <el-button
            :size="formSize"
            :disabled="searchTableSelections.length === 0"
            :loading="btnLoading"
            icon="el-icon-check"
            type="primary"
            @click="addList(1)"
            >添加选中的数据({{searchTableSelections.length}}条)</el-button
          >
          <el-button
            :size="formSize"
            :disabled="searchTablePage.total === 0"
            :loading="btnLoading"
            icon="el-icon-check"
            @click="addList(2)"
            >添加全部数据({{searchTablePage.total}}条)</el-button
          >
        </div>
      </el-footer>
    </el-container>
  </el-drawer>
</el-container>

</template>
<script>

import dayjs from 'dayjs';

import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  delMetaCommon,
  getMetaCommonListTree,
  getMetaCommonDetail
} from "@/api/busiMode/metaCommon";
import * as deptService from "@/api/system/dept";
import { searchList } from '@/api/system/user'
import { uuid } from '@/util/util';
import i18n from "../../../lang";

import { startFlow } from '@/api/flow/flow.js';


export default {
  data(){
    return {
      pageLoading: false,
      btnLoading: false,
      form: {
        code: `TH-${dayjs().format('YYYYMMDD')}${uuid(6 , '1234567890')}`,
        scrap_user: '',
        scrap_user_name: '',
        scrap_date: dayjs().format('YYYY-MM-DD'),
        scrap_dept: '',
        scrap_dept_name: '',
        remarks: '',
        attachment_id: ''
      },
      listDetailValues: [],
      dictData: {
        userList: []
      },
      catalogModel: 'wisdom_park.wp_asset_category',
      model: 'wisdom_park.wp_asset_scrap',
      assetModel: `wisdom_park.wp_asset_detail`,
      detailTable: 'wp_asset_scrap_detail',
      masterIdColumnName: 'scrap_id',
      listValue: {
        field: [
          'asset_id' , 'system_asset_code' , 'asset_name' , 'scrap_remark' , 'asset_category_code' , 'cw_start_date'
        ],
        relate: 'scrap_id',
        master: 'asset_id'
      },
      search: {
        timeType: 1,
        value: null,
        value1: null,
      },
      searchParams: {
        wheres: []
      },
      catalog: {},

      drawer: false,
      searchTableLoading: false,
      searchTablePage: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      searchTableData: [],
      searchTableSelections: [],
      orders: [],
      searchAssetsOption: {
        height: 'auto',
        submitText: i18n.t("crud.searchBtn"),
        border: true,
        // searchMenuPosition: "top",
        // labelPosition: "top",
        addBtn: false,
        menu: false,
        selection: true,
        reserveSelection: true,
        rowKey: "id",
        header: true,
        searchShowBtn: false,
        refreshBtn: false,
        columnBtn: false,
        // searchBtn: false,
        // emptyBtn: false,
        tip: false,
        searchMenuSpan: 24,
        searchMenuPosition: "center",
        selectable: (row) => {
          return !!!this.listDetailValues.find(
            (a) => a[this.listValue.master] === row.id
          );
        },
        column: [
          {
            label: "资产名称",
            prop: "asset_name",
          },
          {
            label: "资产编码",
            prop: "system_asset_code",
          },
          {
            label: "资产类型",
            prop: "asset_category_code",
            type: "tree",
            props: {
              label: "asset_category_name",
              value: "id",
            },
            dicData: [],
            search: true,
            searchSpan: 8,
          },
          {
            label: "使用日期",
            prop: "cw_start_date",
            sortable: 'custom',
            search: true,
            searchSpan: 16
          },
          // {
          //   label: "资产位置",
          //   prop: "location_name",
          // },
          // {
          //   label: "资产使用人",
          //   prop: "collection_user_name",
          // }
        ],
      },
      searchForm: {
        asset_category_code: "",
        user_id: "",
        cw_start_date: ''
      },
      addScrapRemark: ''

    }
  },
  computed: {
    disabled(){
      return this.isTodo || this.isView || false
    },
    isView(){
      return this.isTodo ? this.isTodo : this.view
    },
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    formSize: {
      type: String,
      default: 'small'
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    view: {
      type: Boolean,
      default: false
    },
    isTodo: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  mounted(){
    if(this.isAdd){
      // this.addListDetailValue()
      // for(let i = 5; i > 0; i--){
      //   this.addListDetailValue()
      // }
    }
    if(this.id){
      this.form.code = '';
      this.getMetaCommonDetail();
    }
    getMetaCommonListTree(this.catalogModel, {
      labelField: "asset_category_name",
      valueField: "id",
      releaseField: "parent_id",
      isLazy: false,
    }).then((res) => {
      let handle = (data , parent) => {
        parent = parent || [];
        data.forEach(
          item => {
            this.catalog[item.id] = [...parent , item['asset_category_name']].join('/');
            if(item.children){
              handle(item.children , parent.concat(item['asset_category_name']));
            }
          }
        )
      }
      handle(res.data.data || []);
      const column = this.findObject(
        this.searchAssetsOption.column,
        "asset_category_code"
      );
      column.dicData = res.data.data;
    });
  },
  methods: {
    addList(type){
      let listDetailValuesId = this.listDetailValues.map(item => item[this.listValue.master]);
      let handle = (data) => {
        let list = [];
        data.forEach((item) => {
          let has = listDetailValuesId.includes(item.id);
          if (!has) {
            let newItem = {};
            this.listValue.field.forEach((field) => {
              if (typeof field === "string") {
                field = {
                  type: "string",
                  code: field,
                };
              }
              if (field.code !== this.listValue.master) {
                newItem[field.code] = item[field.code] || null;
              }
            });
            newItem['asset_category_code'] = item['asset_category_code'];
            newItem[this.listValue.master] = item["id"];
            if(this.addScrapRemark){
              newItem['scrap_remark'] = this.addScrapRemark;
            }
            list.push(newItem);
          }
        });
        return list;
      }
      if(type === 1){
        this.btnLoading = true;
        let list = handle(this.searchTableSelections);
        this.btnLoading = false;
        this.drawer = false;
        this.$nextTick(() => {
          this.listDetailValues = this.listDetailValues.concat(list);
        })
      }
      if(type === 2){
        this.addListByAllData().then(
          data => {
            let list = handle(data);
            this.listDetailValues = this.listDetailValues.concat(list);
            this.drawer = false;
          }
        );
      }
      console.log(this.searchTableSelections)
    },
    addListByAllData(){
      return new Promise((resolve , reject) => {
        let datas = [];
        this.btnLoading = true;
        let searchForm = this.searchForm;
        let wheres = [];
        if(searchForm.asset_category_code){
          wheres.push({ field: 'asset_category_code', value: searchForm['asset_category_code']});
        }
        if(searchForm.cw_start_date && Array.isArray(searchForm.cw_start_date) && searchForm.cw_start_date.length === 2){
          wheres.push({ field: 'cw_start_date', value: searchForm['cw_start_date'][0] , op: 'GE'});
          wheres.push({ field: 'cw_start_date', value: searchForm['cw_start_date'][1] , op: 'LE'});
        }
        let handle = (page) => {
          getMetaCommonList(this.assetModel, {
            current: page,
            size: 500,
            wheres: wheres,
            orders: this.orders
          }).then(
            res => {
              let resData = res.data.data;
              datas = [
                ...datas,
                ...resData.records
              ]
              if(page < resData.pages){
                handle(++page)
              }else{
                this.btnLoading = false;
                resolve(datas);
              }
            }
          ).catch(e => {
            this.btnLoading = false;
          })
        }
        handle(1);
      })

    },
    openAddAssetDrawer() {
      this.wherers = [];
      this.searchTableSelections = [];
      this.searchTableData = [];
      this.addScrapRemark = '';
      this.searchForm = {
        asset_category_code: "",
        user_id: "",
        cw_start_date: ''
      };
      this.drawer = true;
    },
    sortChange({column , prop , order}){
      let has = false;
      this.orders.forEach(
        (item , index) => {
          if(item.field === prop){
            has = true;
            if(!order){
              this.orders.splice(index , 1);
            }else{
              item.sort = order === 'descending' ? 'DESC' : 'ASC'
            }
          }
        }
      );
      if(order && has === false){
        this.orders.push({
          field: prop,
          sort: order === 'descending' ? 'DESC' : 'ASC'
        })
      }
      this.searchTableOnLoad()
    },
    searchTableSelection(ev) {
      this.searchTableSelections = ev;
      // this.addListFormUserTypeChange();
    },
    handleSearchSubmit(form, done) {
      let wheres = [];
      [
        "asset_category_code"
      ].forEach((item) => {
        if (form[item]) {
          wheres.push({ field: item, value: form[item]});
        }
      });
      if(form.cw_start_date && Array.isArray(form.cw_start_date) && form.cw_start_date.length === 2){
        wheres.push({ field: 'cw_start_date', value: form['cw_start_date'][0] , op: 'GE'});
        wheres.push({ field: 'cw_start_date', value: form['cw_start_date'][1] , op: 'LE'});
      }
      done && done();
      this.wherers = wheres;
      this.searchTableOnLoad(wheres);
    },
    searchTableOnLoad(wheres) {
      wheres = wheres || [];
      if (wheres.length == 0) {
        wheres = this.wherers;
      }
      this.searchTableData = [];
      this.searchTableLoading = true;
      getMetaCommonList(this.assetModel, {
        current: this.searchTablePage.currentPage,
        size: this.searchTablePage.pageSize,
        wheres: [
          ...wheres,
          {
            field: 'asset_current_status', value: [10001 , 10002 , 10003 , 10004] , op: 'IN'
          }
        ],
        orders: this.orders
      }).then((res) => {
        if (res?.data?.data) {
          this.searchTableData = res?.data?.data.records;
          // this.$nextTick(() => {
          //   let selections = [];
          //   this.searchTableData.forEach((item) => {
          //     if (
          //       this.listDetailValues.find(
          //         (a) => a[this.listValue.master] === item.id
          //       )
          //     ) {
          //       selections.push(item);
          //     }
          //   });
          //   this.$refs.searchTable.toggleSelection(selections);
          // });
          this.searchTablePage.total = res.data.data.total;
          this.searchTableLoading = false;
        }
      });
    },
    getDeptData(ev , done){
      deptService.getDeptTree().then(res => {
        done(res.data.data || []);
      })
    },
    getMetaCommonDetail(){
      this.pageLoading = true;
      getMetaCommonDetail(this.model , {
        pk: 'id',
        id: this.id,
        detailTable: this.detailTable,
        masterIdColumnName: this.masterIdColumnName,
      }).then(
        res => {

          let data = res.data.data;
          let listDetailValues = (data['listDetailValues'] || []).map(
            item => {
              return {
                ...item,
                assets: {
                  list: [],
                  loading: false
                }
              }
            }
          );

          delete data['listDetailValues'];
          
          this.form = data;
          this.listDetailValues = listDetailValues;
          this.pageLoading = false;
        }
      ).catch(e => {
        this.pageLoading = false;
      })
    },
    back(){
      this.$emit('back');
    },
    addListDetailValue(){
      let itemObj = {};
      this.listValue.field.forEach(
        item => {
          if(typeof item === 'string'){
            item = {
              type: 'string',
              code: item
            }
          }
          switch(item.type){
            case 'string':
              itemObj[item.code] = '';
              break;
            case 'number':
              itemObj[item.code] = null;
              break;
            case 'arrar':
              itemObj[item.code] = [];
              break;
            case 'arrarObj':
              itemObj[item.code] = [];
              break;
          }
        }
      )
      this.listDetailValues.push(itemObj)
    },
    getListDetailValues(){
      let listDetailValues = [];
      this.listDetailValues.forEach(
        item => {
          let master = [];
          if(typeof this.listValue.master === 'string'){
            master = [this.listValue.master]
          }else{
            master = this.listValue.master;
          }
          let add = true;
          master.forEach(
            name => {
              if(item[name] === '' || item[name] === null || item[name] === undefined){
                add = false;
              }
            }
          )
          if(add){
            let newItem = {};
            this.listValue.field.forEach(
              fieldItem => {
                if(typeof fieldItem === 'string'){
                  fieldItem = {
                    type: 'string',
                    code: fieldItem,
                    field: [],
                    isDel: false
                  }
                }
                switch(fieldItem.type){
                  case 'string':
                    if(fieldItem.isDel !== true){
                      newItem[fieldItem.code] = item[fieldItem.code];
                    }
                    break;
                  case 'number':
                    if(fieldItem.isDel !== true){
                      newItem[fieldItem.code] = Number(item[fieldItem.code]);
                    }
                    break;
                  case 'arrar':
                    if(fieldItem.isDel !== true){
                      newItem[fieldItem.code] = item[fieldItem.code].join(fieldItem.separate || '-');
                    }
                    break;
                  case 'arrarObj':
                    fieldItem.field.forEach(
                      (fieldName , index) => {
                        if(item[fieldItem.code][index]){
                          newItem[fieldName] = item[fieldItem.code][index];
                        }
                      }
                    )
                    break;
                }
              }
            )
            if(item.id){
              newItem['id'] = item.id;
            }
            if(item[this.listValue.relate]){
              newItem[this.listValue.relate] = item[this.listValue.relate];
            }
            listDetailValues.push(newItem)
          }
        }
      )
      return listDetailValues;
    },
    startFlow(row){
      return startFlow(row.id , this.$PCode.BIZ.ASSET_SCRAP , this.$PCode.ACTIONS.SUBMIT);
    },
    saveForm(isStartFlow){
      let listDetailValues = this.getListDetailValues();
      if(!listDetailValues.length){
        this.$message({
          type: 'error',
          message: '请完善报废资产'
        })
        return false;
      }

      this.$refs.form.validate(valid => {
        if(valid){
          let body = {
            ...this.form
          };
          if(body['location_name']){
            body['location_name'] = body['location_name'].join('-');
          }
          if(this.form.id){
            delete body.id;
            body = {
              update: body,
              primaryKeyValue: this.form.id,
              primaryKey: "id",
              listDetailValues: listDetailValues
            };
          }else{
            body = {
              values: body,
              listDetailValues: listDetailValues
            }
          }
          this.btnLoading = true;
          (this.form.id ? updateMetaCommon : saveMetaCommon)(this.model , {
            ...body,
            detailTable: this.detailTable,
            masterIdColumnName: this.masterIdColumnName,
          }).then((res) => {
            if(isStartFlow){
              return this.startFlow(this.form.id ? this.form : res.data.data).then(res1 => res1);
            }else{
              return res;
            }
          }).then(res => {
            this.btnLoading = false;
            this.$message.success(res.data.msg);
            this.back();
          }).catch((e) => {
            this.btnLoading = false;
          });
        }else{
          return false;
        }
      })

    }
  }
}
</script>
<style lang="scss" scoped>
.assets-drawer {
  /deep/.el-drawer__header {
    margin-bottom: 0;
  }
  /deep/.el-drawer__body {
    padding-top: 0;
  }
}
.assets-drawer {
  /deep/.avue-crud__menu {
    display: none;
  }
  /deep/.el-drawer__header {
    margin-bottom: 0;
  }
  /deep/.el-drawer__body {
    padding-top: 0;
  }
  /deep/.avue-crud__search .avue-form {
    padding: 0;
  }
  /deep/.el-select {
    display: inline-block;
    position: relative;
    width: 100%;
  }
}
</style>
import Component from './main.vue';

export const PieChartConfigs = [
  {
    widgetName: '饼图',
    type: '图表',
    code: 'BuiltInWidgetByEchartsPie',
    component: Component,
    icon: 'portal-iconfont portal-icon_fsux_tubiao_bingtu1',
    dataOption: [
      // {name: '分类' , code: 'category' , type: 'field'},
      {
        name: '值' , code: 'multiValue' , type: 'dynamic' ,
        optionList: [],
        meta: [
          {name: '值名' , code: 'name' , type: 'text'},
          {name: '值字段' , code: 'value' , type: 'field'}
        ]
      },
    ],
    optionList: [
      {
        code: 'chartType',
        value: 'pie',
        hide: true,
        type: 'text',
        name: '图表类型',
      },
      {
        code: '',
        name: '主题配置',
        optionList: [
          {
            code: 'color',
            type: 'theme',
            value: {
              name: '',
              color: []
            },
            name: '主题'
          }
        ]
      },
      {
        code: 'tooltip',
        name: '提示',
        optionList: [
          {
            code: 'show',
            name: '显示',
            type: 'boolean',
            value: true
          }, 
          {
            code: 'trigger',
            name: '触发类型',
            type: 'select',
            value: 'item',
            selectList: [
              {name: '数据项' , value: 'item'},
              {name: '坐标轴' , value: 'axis'}
            ]
          }
        ]
      },
      {
        code: 'legend',
        name: '图例',
        optionList: [
          {
            code: 'show',
            name: '显示',
            type: 'boolean',
            value: true
          }
        ]
      },
    ]
  }
]
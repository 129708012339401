<template>
<div class="receipt-table">
  <!-- <avue-data-display :option="dataBoxOption"></avue-data-display> -->
  <el-card v-if="showTotal" class="mar-b-15">
    <el-descriptions
      title=""
      :column="3"
      :colon="false"
      :labelStyle="labelStyle"
    >
      <el-descriptions-item label="应收"><span class="lh-28">￥{{total.receivableAmount | numConvertToThousandths}}元</span></el-descriptions-item>
      <el-descriptions-item label="减免"><span class="lh-28">￥{{total.reductionAmount | numConvertToThousandths}}元</span></el-descriptions-item>
    </el-descriptions>
  </el-card>
  <div class="w-100p">
    <el-tabs class="w-100p" v-model="activeName" @tab-click="tabsHandleClick">
      <el-tab-pane v-for="item of statusDict" :label="item.dictValue" :name="item.dictKey"></el-tab-pane>
    </el-tabs>
  </div>
  <avue-crud
    v-if="init"
    ref="table"
    :data="tableData"
    :page.sync="page"
    :option="option"
    :table-loading="tableLoading"
    :search.sync="search"
    @search-change="onLoad"
    @on-load="onLoad"
    @refresh-change="onLoad"
  >
    <template slot-scope="scope" slot="amountReceivable" >
      ￥{{scope.row.amountReceivable | numConvertToThousandths}}元
    </template>
    <template slot-scope="scope" slot="amountReceived" >
      ￥{{scope.row.amountReceived | numConvertToThousandths}}元
    </template>
    <template slot-scope="scope" slot="amountReduction" >
      ￥{{scope.row.amountReduction | numConvertToThousandths}}元
    </template>
    <template slot-scope="scope" slot="status">
      <el-tag size="small" :type="statusType[scope.row.status]" effect="dark">
        {{ scope.row.$status }}
      </el-tag>
    </template>
    <template slot-scope="scope" slot="menu">
      <el-button
        type="text"
        icon="el-icon-tickets"
        size="small"
        @click="viewRow(scope.row , 'reduction')"
        >优惠明细
      </el-button>
    </template>
  </avue-crud>
  <el-drawer
    title="账单详情"
    :visible.sync="drawer"
    :size="800"
    class="manageDetail"
    :withHeader="false"
  >
    <el-container class="h-100p">
      <el-header class="bor-b" height="50px">
        <el-page-header class="lh-50" content="账单详情" @back="drawer = false"></el-page-header>
      </el-header>
      <el-main class="ov-h ov-a-y">
        <ReceiptDetail v-if="drawer && currenRow" :id="currenRow.receiptNo || null" :activeTab="activeTab"></ReceiptDetail>
      </el-main>
    </el-container>
  </el-drawer>
</div>
</template>
<script>
import { getPreferentialList , preferentiaStatisticFee } from '@/api/finance/index.js'
import * as dictService from "@/api/system/dictbiz";
import { getDictionary } from '@/api/system/dictbiz.js'
import mathjs from '@/util/math.js';
import ReceiptDetail from './receipt-detail.vue';


export default {
  components: { ReceiptDetail },
  data(){
    return {
      activeName: 'all',
      init: false,
      activeTab: 'reduction',
      drawer: false,
      currenRow: null,
      labelStyle: {
        color: '#999',
        fontSize: '12px',
        lineHeight: '28px'
      },
      total: {
        receivableAmount: '0',
        reductionAmount: '0'
      },
      tableLoading: false,
      tableData: [],
      search: {},
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      statusType: {
        1: '',
        2: 'success',
        3: 'danger',
        4: 'warning'
      },
      option: {
        height: 'auto',
        // calcHeight: this.calcHeight,
        labelPosition: 'top',
        searchMenuPosition: 'right',
        searchMenuSpan: 24,
        border: true,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        menuWidth: 100,
        column: [
          {
            label: "账单编号",
            prop: "receiptNo",
            width: 200
          },
          {
            label: "公司名称",
            prop: "companyName",
            minWidth: 200
          },
          {
            label: "账单状态",
            prop: "status",
            type: "select",
            dataType: 'number',
            dicUrl: dictService.getUrl("ReceiptStatus"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            width: 120
          },
          {
            label: "应收金额",
            prop: "amountReceivable",
            width: 150
          },
          {
            label: "减免金额",
            prop: "reductionAmount",
            width: 150
          },
          {
            label: "减免政策",
            prop: "reductionPolicyTitle",
            width: 150
          },
          {
            label: "政策类型",
            prop: "reductionPolicyType",
            width: 150,
            type: "select",
            dataType: 'number',
            dicUrl: dictService.getUrl("ReductionPolicyType"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "减免说明",
            prop: "note"
          }
        ],
        ...this.tableOption
      },
      statusDict: []
    }
  },
  props: {
    queryParams: {
      type: Object,
      default(){
        return null
      }
    },
    requireQueryParams: {
      type: Boolean,
      default: false
    },
    calcHeight: {
      type: Number,
      default: 34
    },
    showTotal: {
      type: Boolean,
      default: true
    },
    tableOption: {
      type: Object,
      default(){
        return null;
      }
    }
  },
  watch: {
    queryParams(){
      this.onLoad();
    }
  },
  created(){ },
  mounted(){
    this.getDictionary();
  },
  methods: {
    getYSAmount(){
      return mathjs.subtract(this.total.receivableAmount || 0 , this.total.receivedAmount || 0)
    },
    getDictionary(){
      getDictionary({
        code: 'ReceiptStatus'
      }).then(
        res => {
          this.statusDict = [
            {dictValue: '全部' , dictKey: 'all'},
          ].concat(res.data.data || []);
          this.$nextTick(() => {
            this.init = true;
          })
        }
      )
    },
    tabsHandleClick(node){
      this.page.currentPage = 1;
      this.onLoad();
    },
    onLoad(){
      this.tableData = [];
      if(this.requireQueryParams && !Object.keys(this.queryParams).length){
        return [];
      }
      this.tableLoading = true;
      if(this.showTotal){
        preferentiaStatisticFee(this.queryParams).then(
          res => {
            let data = res.data.data;
            Object.keys(this.total).forEach(
              item => {
                this.total[item] = data[item] || '0';
              }
            )
          }
        )
      }
      let searchBody = {
        ...this.queryParams
      }
      if(this.activeName !== 'all'){
        searchBody['status'] = this.activeName;
      }
      getPreferentialList({
        current: this.page.currentPage,
        size: this.page.pageSize,
        ...searchBody
      }).then(
        res => {
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.tableLoading = false;
        }
      ).catch(e => {
        this.tableLoading = false;
      })
    },
    viewRow(row , activeTab){
      this.currenRow = row;
      this.activeTab = activeTab;
      this.drawer = true;
    }
  }
}
</script>
<style lang="scss" scoped>
.receipt-table {
  /deep/.el-drawer__header {
    margin-bottom: 0;
  }
  /deep/.el-drawer__body {
    padding-top: 0;
  }
  /deep/.avue-crud__left{
    flex: 1;
  }
}
</style>
<template>
  <basic-container>
    <avue-crud
      ref="crud"
      :table-loading="tableLoading"
      :data="tableData"
      :page.sync="page"
      :option="tableOption"
      :before-open="beforeOpen"
      v-model="form"
      @on-load="onLoad"
      @row-save="saveRow"
      @row-update="updateRow"
      @row-del="deleteRow"
      @refresh-change="onLoad"
    >
    </avue-crud>
  </basic-container>
</template>
<script>

  import { getPage , saveGradetype , updateGradetype , removeGradetype , getDetail} from '@/api/metadata/grade';

  export default {
    data(){
      return {
        form: {},
        tableLoading: false,
        tableData: [],
        page: {
          pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        tableOption: {
          // height: 'auto',
          // calcHeight: 30,
          // tip: false,
          // searchShow: true,
          // searchMenuSpan: 6,
          // border: false,
          // editBtn: true,
          // addBtn: true,
          // dialogClickModal: false,
          // menuWidth: 300,
          column: [
            {
              label: '分级名称',
              prop: 'name',
              span: 24,
              rules: [
                {
                  required: true,
                  message: "请输入分级名称",
                  trigger: "blur"
                }
              ]
            },
            {
              label: '描述',
              prop: 'description',
              type: 'textarea',
              minRows: 2,
              span: 24
            },
            {
              label: '创建时间',
              prop: 'createTime',
              display: false
            },
            {
              label: '更新时间',
              prop: 'updateTime',
              display: false
            },
            {
              label: '创建人',
              prop: 'createUserName',
              display: false
            }
          ]
        }
      }
    },
    methods: {
      beforeOpen(done , type){
        if(['edit'].includes(type)){
          getDetail({id: this.form.id}).then(
            res => {
              this.form = res.data.data;
            }
          )
        }
        done();
      },
      onLoad(){
        this.tableLoading = true;
        getPage({
          current: this.page.currentPage,
          size: this.page.pageSize
        }).then(
          res => {
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
            this.tableLoading = false;
          }
        )
      },
      saveRow(row , done){
        saveGradetype({
          name: row.name,
          description: row.description
        }).then(
          res => {
            done();
            if(res && res.data.success){
              this.onLoad();
              this.$message.success(res.data.msg);
            }else{
              this.$message.error(res.data.msg || '保存失败');
            }
          }
        )
      },
      updateRow(row , index , done){
        updateGradetype(row).then(
          res => {
            done();
            if(res && res.data.success){
              this.onLoad();
              this.$message.success(res.data.msg);
            }else{
              this.$message.error(res.data.msg || '更新失败');
            }
          }
        )
      },
      deleteRow(row){
        this.$confirm("确定将选择数据删除?", {
          type: "warning"
        }).then(() => {
          removeGradetype({
            ids: row.id
          }).then(
            res => {
              this.$message.success(res.data.msg || '删除成功');
              this.onLoad();
            }
          )
        })
      }
    }
  }
</script>

<template>
  <div>
    <!-- <el-button type="primary" @click="toggle">全屏展示</el-button> -->
    <fullscreen
      :fullscreen.sync="isFullscreen"
      :teleport="teleport"
      :page-only="pageOnly"
    >
      <div class="cockpit-container fullscreen-wrapp" ref="container">
        <div class='btn-screen'>
          <div class="closeBtn" @click="toggle">
            <img
              v-if="isFullscreen"
              class="w-40 h-40 mar-t-8"
              src="../../../public/assets/images/icon/outFull.png"
              alt=""
            />
            <img
              v-if="!isFullscreen"
              class="w-40 h-40 mar-t-8"
              src="../../../public/assets/images/icon/infull.png"
              alt=""
            />
          </div>
        </div>
        
        <!-- 头部  -->
        <div class="header">
          <img
            src="../../../public/assets/assets/img/header.svg"
            style="width: 100%"
          />
        </div>
        <div class="cockpit-data">
          <!-- 左侧部分 -->
          <div class="cockpit-left">
            <yyfx></yyfx>
            <div class="count">
              <lyfx></lyfx>
            </div>
          </div>
          <!-- 右侧部分 -->
          <div class="cockpit-right">
            <bbfx></bbfx>
          </div>
        </div>
      </div>
    </fullscreen>
  </div>
</template>

<script>
import yyfx from "./yyfx";
import lyfx from "./lyfx";
import bbfx from "./bbfx";

export default {
  components: { yyfx, lyfx, bbfx },
  data() {
    return {
      isFullscreen: false, // 是否为全屏状态
      teleport: true,
      pageOnly: false,
      options: {
        target: ".fullscreen-wrapper",
        callback(isFullscreen) {
          console.log(isFullscreen);
        },
      },
    };
  },
  mounted() {
    let dom = this.$el.querySelector(".cockpit-container");
    this.$fullscreen.request(dom);
    // this.$fullscreen.toggle(dom, this.options, true);
  },
  methods: {
    toggle() {
      this.isFullscreen = !this.isFullscreen;
    },
  },
};
</script>

<style scoped lang='scss'>
.closeBtn {
  background-color: rgba(0, 0, 0, 0.6);
  width: 60px;
  height: 60px;
  border-radius: 4px;
  position: absolute;
  left: 20px;
  top: 20px;
  text-align: center;
  z-index: 10000;
}

.cockpit-container {
  padding: 0;
  margin: 0;
  font-family: Microsoft YaHei;
  font-weight: 400;
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  background-color: #070731;
  display: flex;
  flex-direction: column;
  color: #fff;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
  .btn-screen {
    width: 100%;
    height: 89px;
    position: absolute;
    .closeBtn {
      opacity: 0;
    }
  }
  :hover.btn-screen {
    .closeBtn {
    opacity: 1;
    }
  }
  .cockpit-left {
    width: 979px;
    height: 980px;
    padding-top: 7px;
    margin-right: 10px;
    background: url(../../../public/assets/assets/img/bg001.svg) no-repeat;
    background-size: 100% 98%;
  }
  .cockpit-right {
    padding-top: 5px;
    height: 980px;
  }
}

.header {
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}

.cockpit-data {
  flex-shrink: 0;
  display: flex;
  flex-wrap: nowrap;
  overflow: auto;
  padding: 0 15px;
}
// .cockpit-container .cockpit-left {
//   // height: 1001px;
//   width: 979px;
//   margin-right: 10px;
// }

// .cockpit-container .cockpit-right {
//   height: 980px;
//   width: 901px;
// }

// .cockpit-container .cockpit-left {
//   background: url(../../../public/assets/assets/img/bg001.svg) no-repeat;
//   background-size: 100% 103%;
// }

/** 标题渐变色样式 **/
.cockpit-title {
  position: relative;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  color: #fff;
  font-weight: bold;
  background: linear-gradient(
    90deg,
    rgba(0, 238, 255, 0.7) 0%,
    rgba(60, 165, 255, 0) 100%
  );
}

.cockpit-title img {
  width: 40px;
}

.mt {
  margin-top: 27.5px;
}

.cockpit-mid-title {
  padding: 15px;
}

.flex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

/** 左侧 小框内容 **/
.data-content {
  display: flex;
  display: -webkit-flex;
  /* margin-top: 15px; */
}

.data-item {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 60px;
  margin-right: 8px;
}

.data-item > p.num {
  font-size: 18px;
  font-weight: bold;
}

.data-item > p {
  margin: 0px;
  margin-top: 5px;
  font-size: 11px;
}
.flex {
  display: flex;
}
</style>

<template>
  <avue-crud
    ref="table"
    :data="tableData"
    :page.sync="page"
    :option="tableOption"
    :table-loading="tableLoading"
    :search.sync="search"
    @search-change="searchChange"
    @on-load="onLoad"
    @refresh-change="onLoad"
    @sort-change="sortChange"
  >
    <template slot-scope="{ size }" slot="menuLeft">
      <el-button
          icon="el-icon-download"
          :size="size || 'small'"
          :loading="downLoading"
          @click="getMetaCommonListExport"
          >导 出</el-button
        >
    </template>
    <template slot="tran_dateSearch" slot-scope="scope">
      <el-date-picker class="w-100p" v-model="search.tran_date" :unlink-panels="true"
        value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始时间"
        end-placeholder="结束时间">
      </el-date-picker>
    </template>
    <template slot="acc_dateSearch" slot-scope="scope">
      <el-date-picker class="w-100p" v-model="search.acc_date" :unlink-panels="true"
        value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始时间"
        end-placeholder="结束时间">
      </el-date-picker>
    </template>
  </avue-crud>
</template>
<script>
import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  delMetaCommon,
  getMetaCommonDetail,
  getMetaCommonListTree,
  getMetaCommonListExport
} from "@/api/busiMode/metaCommon";
import * as dictService from "@/api/system/dictbiz";

export default {
  data(){
    return {
      model: 'wisdom_park.wp_ccb_bank_transaction',
      modelView: 'wisdom_park.v_wp_ccb_bank_transaction',
      tableLoading: false,
      downLoading: false,
      tableData: [],
      search: {
        tran_date: [],
        acc_date: []
      },
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableOption: {
        height: 'auto',
        // calcHeight: 10,
        // labelPosition: 'top',
        // searchMenuPosition: 'right',
        searchMenuSpan: 24,
        border: true,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        viewBtn: true,
        labelWidth: 150,
        menuWidth: 100,
        defaultSort: {
          prop: 'tran_date',
          order: 'descending'
        },
        column: [
          {
            label: "交易时间",
            prop: "tran_date",
            width: 150,
            search: true,
            type: 'datetime',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            searchSpan: 12,
            searchRange: true,
            sortable: 'custom',
          },
          {
            label: "记账日期",
            prop: "acc_date",
            width: 120,
            search: true,
            type: 'date',
            format: 'yyyy-MM-dd',
            valueFormat: 'yyyy-MM-dd',
            searchSpan: 12,
            searchRange: true,
            sortable: 'custom',
          },
          {
            label: "订单号",
            prop: "order",
            width: 200,
            search: true
          },
          {
            label: "银行流水号",
            prop: "oriovrlsttnev_trck_no",
            width: 200
          },
          {
            label: "订单状态",
            prop: "order_status",
            width: 150,
            dicUrl: dictService.getUrl("BankOrderStatus"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: 'string',
            search: true,
            type: 'select'
          },
          {
            label: "商户号",
            prop: "merchant_id",
            width: 250,
            search: true,
            type: 'select',
            dicUrl: dictService.getUrl("MERCHANT_ID"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            label: "商户流水号",
            prop: "msgrp_jrnl_no",
            width: 150,
          },
          {
            label: "柜台号",
            prop: "pos_id",
            width: 250,
            type: 'select',
            search: true,
            dicUrl: dictService.getUrl("POST_ID"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            // POST_ID
          },
          {
            label: "付款方账号",
            prop: "account",
            width: 150
          },
          {
            label: "付款方式",
            prop: "pay_mode",
            width: 150,
            dicUrl: dictService.getUrl("BankOrderPayMode"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: 'string',
            type: 'select',
            search: true
          },
          {
            label: "订单金额",
            prop: "orig_amt",
            width: 150
          },
          {
            label: "交易金额",
            prop: "txnamt",
            width: 150
          },
          {
            label: "支付金额",
            prop: "payment_money",
            width: 150
          },
          {
            label: "退款金额",
            prop: "refund_money",
            width: 150
          },
          {
            label: "结算金额",
            prop: "txn_clrgamt",
            width: 150
          },
          {
            label: "优惠金额",
            prop: "discount_amt",
            width: 150
          },
          {
            label: "手续费金额",
            prop: "mrchcmsn_amt",
            width: 150
          },
          {
            label: "客户类型优惠描述",
            prop: "cst_tp_prft_dsc",
            width: 200
          },
        ]
      },
      orders: [
        {field: 'tran_date' , sort: 'DESC'}
      ]
    }
  },
  props: {
    
  },
  mounted(){

  },
  methods: {
    sortChange({column , prop , order}){
      // let has = false;
      // this.orders.forEach(
      //   (item , index) => {
      //     if(item.field === prop){
      //       has = true;
      //       if(!order){
      //         this.orders.splice(index , 1);
      //       }else{
      //         item.sort = order === 'descending' ? 'DESC' : 'ASC'
      //       }
      //     }
      //   }
      // );
      // if(order && has === false){
      //   this.orders.push({
      //     field: prop,
      //     sort: order === 'descending' ? 'DESC' : 'ASC'
      //   })
      // }
      this.orders = [
        {
          field: prop,
          sort: order === 'descending' ? 'DESC' : 'ASC'
        }
      ];
      this.page.currentPage = 1;
      this.onLoad()
    },
    getMetaCommonListExport() {
      let wheres = this.getSearch();
      this.downLoading = true;
      getMetaCommonListExport(this.modelView, {
        current: 1,
        size: this.page.pageSize,
        wheres: wheres,
        orders: this.orders
      })
        .then((res) => {
          this.downLoading = false;
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.download = "银行对账流水" + ".xls";
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          this.downLoading = false;
        });
    },
    getSearch(){
      let form = this.search , wheres = [];
      Object.keys(form).forEach(
        keyName => {
          if(keyName !== 'ACTIVENAME' && keyName[0] !== '$' && (form[keyName] ?? '') !== ''){
            const column = this.findObject(this.tableOption.column, keyName);
            if(column['searchRange']){
              let startVal = form[keyName][0] , endVal = form[keyName][1];
              if(startVal && endVal){
                // if(column['type'] === 'date'){
                //   startVal = `${startVal} 00:00:00`
                // }
                wheres.push({ field: keyName, value: startVal, op: "GE" });

                // if(column['type'] === 'date'){
                //   endVal = `${endVal} 23:59:59`
                // }
                wheres.push({ field: keyName, value: endVal, op: "LE" });
              }
            }else if(column['searchLike']){
              wheres.push({field: keyName , op: 'LIKE', value: form[keyName]})
            }else{
              wheres.push({field: keyName , value: form[keyName]})
            }
          }
        }
      );
      return wheres;
    },
    searchChange(params, done){
      this.page.currentPage = 1;
      done();
      this.onLoad()
    },
    onLoad(){

      this.tableLoading = true;
      this.tableData = [];
      let wheres = this.getSearch();

      getMetaCommonList(this.model , {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres,
        orders: this.orders
      }).then(
        res => {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
        }
      ).catch(e => {
        this.tableLoading = false;
      })
    }
  }
}
</script>
<template>
  <div class="container designer form-designer-page">
    <el-row>
      <el-form
        ref="form"
        label-position="right"
        class="wid-500"
        label-width="60px"
      >
        <el-form-item label="名称" size="small">
          <el-input class="w-240" v-model="dashName"></el-input>
        </el-form-item>
      </el-form>
    </el-row>
    <el-row class="mar-t-15f">
      <el-col :span="24">
        <el-menu
          :default-active="activeBtn"
          class="el-menu-demo mar-b-15"
          mode="horizontal"
          @select="handleClick"
        >
          <el-menu-item index="data">属性</el-menu-item>
          <el-menu-item index="page">表格页面</el-menu-item>
          <el-menu-item index="new">表单</el-menu-item>
        </el-menu>
      </el-col>
    </el-row>
    <el-button
      type="primary"
      size="small"
      icon="el-icon-check"
      class="btnSaveClass"
      style="right: 110px"
      :loading="btnLoading"
      @click="saveForm"
      >保存
    </el-button>
    <el-button
      size="small"
      icon="el-icon-refresh-left"
      class="btnSaveClass"
      style=""
      :loading="btnLoading"
      @click="initDesign"
      >初始化
    </el-button>
    <template>
      <el-row v-show="activeBtn == 'data'">
        <el-col :span="24">
          <dataDesign
            ref="dataDesign"
            :entityId="entityId"
            :menuDo="menuDo"
            :modelId="modelId"
            @modelChoose="modelChoose"
          ></dataDesign>
        </el-col>
      </el-row>
      <el-row v-show="activeBtn == 'page'" style="width: 100%; height: 100%">
        <!-- <grid-layout :col-num="24" :row-height="10" :layout="layout"> -->
        <template v-if="options.showTree">
          <!-- <grid-item
              v-if="layout.length > 1"
              :w="layout[1].w"
              :h="layout[1].h"
              :x="layout[1].x"
              :y="layout[1].y"
              :i="layout[1].i"
            > -->
          <el-col :span="3" class="w-300">
            <designTree
              ref="designTree"
              :entityId="entityId"
              :tableColumns="importCode.column"
              :treeOption="options.treeOption"
              @handleNodeClick="handleNodeClick"
            ></designTree>
          </el-col>
          <!-- </grid-item> -->
        </template>
        <!-- <grid-item
            :w="layout[0].w"
            :h="layout[0].h"
            :x="layout[0].x"
            :y="layout[0].y"
            :i="layout[0].i"
          > -->
        <el-col
          :span="options.showTree ? 20 : 24"
          :class="{ 'w-calc-300': options.showTree }"
        >
          <el-card class="design" id="designTable">
            <div class="designDiv">
              <basic-dropdown
                @command="dropCommand"
                :options="[
                  {
                    label: '左侧筛选',
                    value: 'showTree',
                    default: options.showTree,
                    type: 'switch',
                    icon: '',
                  },
                  {
                    label: '高级配置',
                    value: 'moreSet',
                    default: 'false',
                    type: 'text',
                    icon: '',
                  },
                ]"
              ></basic-dropdown>
            </div>

            <el-scrollbar class="h-design">
              <!--搜索表单 :searchColums="importCode.column"-->
              <designSearch
                ref="degignSearch"
                class="mar-b-15"
                :widgetForm="options.seachOption"
                :searchColums="importCode.column"
                @handleSearchFormChange="handleSearchFormChange"
              ></designSearch>
              <!--搜索表单-->
              <!--table-->
              <avue-crud
                class="designTable"
                ref="crud"
                :option="importCode"
                :data="tableList"
                :page.sync="page"
                :loading="designLoading"
                v-model="form"
                @row-save="rowSave"
                @row-del="rowDel"
                @row-update="rowUpdate"
              >
                <template slot="menuLeft">
                  <menuDesignLeft
                    ref="menuDesignLeft"
                    :menuLeftList="options.menuList"
                    @btnLeftDo="btnLeftDo"
                    @operDo="operDo"
                  ></menuDesignLeft>
                </template>
                <template slot="menu" slot-scope="scope">
                  <menuDesign
                    ref="menuDesign"
                    @btnLeftDo="btnLeftDo"
                    @operDo="operDo"
                    @tableOperBtn="tableOperBtn"
                    :menuBtnList="options.menuList"
                    :tableOption="importCode"
                  ></menuDesign>
                </template>
              </avue-crud>
            </el-scrollbar>
          </el-card>
        </el-col>
        <!-- </grid-item>
        </grid-layout> -->
      </el-row>
      <el-row v-show="activeBtn == 'new'">
        <el-col :span="24">
          <newDesign
            ref="newDesign"
            :options="options.newOption"
            :newOpenType="options.newOpenType"
          ></newDesign>
        </el-col>
      </el-row>
      <el-row v-show="activeBtn == 'operation'">
        <operations></operations>
      </el-row>
    </template>

    <el-drawer
      title="全局设置"
      :visible.sync="moreSetVisible"
      :before-close="searchTable"
      destroy-on-close
    >
      <moreSet
        @moreSet="moreSetFuntion"
        :sortList="importCode.column"
        :options="options"
      ></moreSet>
    </el-drawer>

    <!-- <el-dialog
      title="选择操作类型"
      width="30%" operationVisible
      :visible.sync="operNewDialogVisible"
      append-to-body
      destroy-on-close
    >
          :title="operationObj.operName"

    </el-dialog> -->
    <el-drawer
      size="100%"
      :visible.sync="operNewDialogVisible"
      class="operationVisible-drawer"
      destroy-on-close
    >
      <el-row :gutter="10">
        <el-col :span="4" class="baseinfo">
          <chooseType
            ref="chooseType"
            @saveoperation="saveOperationObj"
          ></chooseType>
        </el-col>
        <el-col :span="20" style="border-left: 1px">
          <operations
            :dataSoure="dataSoure"
            :tableInfo="tableInfo"
            :entityId="entityId"
            :operationObj="operationObj"
            @back="closeOperationVisible"
            @modelOper="modelOper"
          >
          </operations>
        </el-col>
      </el-row>
    </el-drawer>
  </div>
</template>
<script>
import {
  allOptions,
  formatOptionsFromData,
  optionToModelS,
} from "../../../util/option";
import designSearch from "./designSearch";
import designTree from "./designTree";
import menuDesignLeft from "./designBtns/menuDesignLeft";
import menuDesign from "./designBtns/menuDesign";
import Draggable from "vuedraggable";
import { getLazyList, remove, update, add, getMenu } from "@/api/system/menu";

import menuDesignRight from "./designBtns/menuDesignRight";
import modelTree from "../../dataConfig/modelsTree";
import dataDesign from "../../dataConfig/dataDesign";
import newDesign from "./newDesign";
import moreSet from "./moreSet";
import operations from "../../operations";
import chooseType from "../../operations/chooseType";

import {
  saveDynamicForm,
  getDetailByPlanId,
  updateDynamicForm,
} from "@/api/busiMode/meta";
import { getMetaCommonList } from "@/api/busiMode/metaCommon";
import { automatic } from "@/api/busiMode/meta";
import _ from "lodash";
import { mapGetters, mapState } from "vuex";
import VueGridLayout from "vue-grid-layout";

export default {
  components: {
    Draggable,
    designSearch,
    menuDesignLeft,
    menuDesignRight,
    menuDesign,
    designTree,
    modelTree,
    dataDesign,
    newDesign,
    moreSet,
    operations,
    chooseType,
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
  },
  provide() {
    return {
      dataSoure: this.dataSoure,
    };
  },
  props: {
    app: {
      type: Object,
      default: function () {
        return {
          appId: "",
          id: "",
        };
      },
    },
    isPre: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    entityId: {
      type: String,
      default: function () {
        return "";
      },
    },
    modelId: {
      type: String,
      default: function () {
        return "";
      },
    },
    menuDo: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  watch: {
    $route() {
      this.init();
    },
    modelId(val) {
      if (val) {
        console.log(val);
      }
    },
    entityId(val) {
      if (val) {
        this.chooseType = false;
        this.dataSoure = { code: val.split(".")[0], name: val.split(".")[1] };
        this.init();
      }
    },
  },
  computed: {
    ...mapState({
      showNewPage: (state) => state.dynamic.showNewPage,
      viewEdit: (state) => state.common.viewEdit,
    }),
  },
  destroyed() {
    if (!this.viewEdit) {
      if (this.fromNewPage) {
        let model = this.$route.fullPath.split(
          "/data/table/tableCommon/?modleId"
        )[1];
        this.$router.push({
          path: "/data/table/newPage/?modleId" + model,
        });
      }
    }
  },
  data() {
    return {
      title: "",
      activeBtn: "data",
      dataSoure: { code: "", name: "" },
      importCode: {},
      preViewType: "add",
      treeModelVisible: false,
      moreSetVisible: false,
      type: "form",
      form: {},
      chooseFile: "", //当前操作的列的数据
      chooseSearchFile: "", //当前操作的搜索
      rowForm: {},
      pageForm: {},
      searchForm: {},
      search1Form: {},
      menuForm: {},
      moreSetOption: {},
      isImportJson: false,
      isSave: false,
      dialogForm: {},
      rowEditVisible: false,
      columnObj: "",
      tableList: [],
      options: allOptions,
      tabledraggabled: false,
      showTree: false,
      menuId: "",
      dashName: "",
      modelChooseEd: "false",
      pk: "",
      btnLoading: false,
      designLoading: false,
      page: {
        pageSizes: [10, 20, 30, 40], //默认
        currentPage: 1,
        total: 10,
        pageSize: 10,
      },
      dynamicFormId: "",
      fromNewPage: false,
      handleClicked: "", //判断是否点击过数据源
      tableOperList: [],
      resizable: true,
      layout: [{ x: 0, y: 0, w: 24, h: 30, i: "0" }],
      viewProps() {
        return Object.assign(
          {},
          {
            isDraggable: false,
            isResizable: false,
            isMirrored: false,
          }
        );
      },
      operationVisible: false,
      operNewDialogVisible: false,
      operationObj: {},
      tableInfo: {},
      currentBtn: {},
      serviceId: "", //当前流程id
      showPosotion: "",
    };
  },
  created() {
    this.init();
    if (this.menuDo == "des") {
      this.activeBtn = "page";
      this.getDetailByPlanId();
    }
  },
  methods: {
    tableOperBtn(e) {
      console.log(e);
      this.tableOperList = e;
    },
    operDo(val) {
      this.showPosotion = val;
      this.operationObj.type = "business";
      this.operationObj.icon = "";
      let obj = {
        type: "business",
        icon: "",
      };
      this.operNewDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.chooseType.operation = obj;
      });
    },
    modelOper(obj) {
      let btnType = "text";
      if (this.showPosotion == "left") {
        btnType = "primary";
      }
      let btn = {
        label: this.operationObj.operName,
        value: "service",
        type: "model",
        icon: this.operationObj.icon,
        showPosotion: this.showPosotion,
        show: true,
        model: obj,
      };
      this.options.menuList.push(btn);

      this.options.menuList = JSON.parse(JSON.stringify(this.options.menuList));
      //this.operationVisible = false;
      this.operNewDialogVisible = false;
    },
    closeOperationVisible(serviceId) {
      let btnType = "text";
      if (this.showPosotion == "left") {
        btnType = "primary";
      }
      let btn = {
        label: this.operationObj.operName,
        value: "service",
        type: btnType,
        icon: this.operationObj.icon,
        showPosotion: this.showPosotion,
        show: true,
        serviceId: serviceId,
      };
      this.options.menuList.push(btn);

      this.options.menuList = JSON.parse(JSON.stringify(this.options.menuList));
      //this.operationVisible = false;
      this.operNewDialogVisible = false;
    },
    saveOperationObj(obj) {
      this.operationObj = obj;
      this.currentBtn.label = obj.operName;
      this.operationVisible = true;
    },
    searchTable() {
      this.getMetaCommonList();
      this.moreSetVisible = false;
    },
    moreSetFuntion(data) {
      let dicQueryConfig = [];
      let orders = [];
      if (data.dicQueryConfig) {
        data.dicQueryConfig.forEach((e) => {
          dicQueryConfig.push({ field: e.field, value: e.value });
        });
      }
      if (data.sort) {
        data.sort.forEach((e) => {
          orders.push({ field: e.field, sort: e.sort });
        });
      }

      //orders.push({ field: data.sort, value: "DESC" });
      this.moreSetOption = {
        wheres: dicQueryConfig,
        orders: orders,
      };
    },
    btnLeftDo(btn) {
      if (btn.serviceId) {
        this.operationObj.operName = btn.label;
        this.operationObj.type = "business";
        this.operationObj.icon = btn.icon;
        this.operNewDialogVisible = true;
        this.currentBtn = btn;
      }
    },
    initDesign() {
      if (this.activeBtn == "data") {
        this.$refs.dataDesign.initDesign();
      } else {
        let a = allOptions;
        let o = JSON.parse(JSON.stringify(this.options));
        a.newOpenType = this.options.newOpenType;

        a.showTree = this.options.showTree;

        //a.tableOption = this.options.tableOption;
        a.tableOption.column = this.importCode.column;
        a.newOption.column = this.options.newOption.column;
        a.seachOption.column = this.options.seachOption.column;
        a.treeOption = this.options.treeOption;
        this.options = formatOptionsFromData(
          this.$refs.dataDesign.dataSoureObj,
          this.$refs.dataDesign.modelData,
          JSON.parse(JSON.stringify(a)),
          this.$refs.dataDesign.guid
        );
        this.options.guid = o.guid;
        this.options.pk = o.pk;
        this.options.menuList = allOptions.menuList;
        this.importCode = this.options.tableOption;
      }
    },
    init() {
      this.formatOptions();
      this.app.appId = this.$route.query.appId;
      this.app.id = this.$route.query.id;
      if (!this.modelId) {
        this.modelId = this.$route.fullPath.split(
          "/data/table/tableCommon/?modleId"
        )[1];
        this.menuId = this.$route.fullPath.split(
          "/data/table/tableCommon?menuId"
        )[1];
      }
      if (this.modelId && this.menuDo != "drawerDo") {
        this.modelChooseEd = "true";
        if (this.showNewPage) {
          this.getDetailByPlanId();
          this.activeBtn = "new";
          this.fromNewPage = this.showNewPage;
          this.$store.commit("SET_GUID", "");
          this.$store.commit("SET_SHOW_NEW", false);
        } else {
          this.activeBtn = "page";
          this.getDetailByPlanId();
        }
      } else {
        this.options = allOptions;
      }
    },

    handleClick(d) {
      if (d == "page") {
        //if (this.modelChooseEd == "true") {
        this.changeToPage();
        //}
      } else if (d == "data") {
        this.handleClicked = "data";
        this.$refs.dataDesign.modelData = optionToModelS(
          this.$refs.dataDesign.modelData,
          this.options.seachOption.column
        );
      } else if (d == "new") {
        // if (this.modelChooseEd == "true") {
        if (this.handleClicked == "data") {
          let option = formatOptionsFromData(
            this.$refs.dataDesign.dataSoureObj,
            this.$refs.dataDesign.modelData,
            this.options
          );
          this.$refs.newDesign.$refs.formDesign.widgetForm = option.newOption;
          if (this.importCode.column.length > 0) {
            this.handleClicked = "";
          }
          //this.handleClicked = "";
          // this.options.searchOption = option.searchOption;
          // this.importCode = option.tableOption;
        }
        //}
      }
      this.activeBtn = d;
    },
    modelChoose(d) {
      this.dataSoure.code = d[0].code;
      this.handleClicked = "data";
      this.tableInfo = d[1];
      this.entityId = d[0].code + "." + d[1].tableName;
      this.modelChooseEd = "true";
      this.options = allOptions;
    },
    addFormSubmit(data) {
      this.options.newOption = data;
      this.addVisible = false;
    },
    handleNodeClick(treeSearch) {
      this.getMetaCommonList(treeSearch);
    },
    handleSearchFormChange(searchForm) {
      this.getMetaCommonList(searchForm);
    },
    changeToPage() {
      this.activeBtn = "page";
      if (
        this.handleClicked == "data" ||
        this.menuDo == "add" ||
        this.menuDo == "des"
      ) {
        let newoptions = JSON.parse(JSON.stringify(this.options.newOption));
        let option = formatOptionsFromData(
          this.$refs.dataDesign.dataSoureObj,
          this.$refs.dataDesign.modelData,
          this.options,
          this.$refs.dataDesign.guid
        );
        this.options.searchOption = option.searchOption;
        this.options.newOption = newoptions;
        this.importCode = option.tableOption;
        if (newoptions.column.length > 0) {
          this.handleClicked = "";
        }
      }
      if (this.$refs.dataDesign.entityId) {
        this.entityId = this.$refs.dataDesign.entityId;
      }
      this.modelId = this.$refs.dataDesign.entityId;
      this.getMetaCommonList();
    },
    getDetailByPlanId() {
      this.designLoading = true;
      getDetailByPlanId({ id: this.modelId })
        .then((res) => {
          if (res.data.code == 200) {
            this.dynamicFormId = res.data.data.id;
            this.dashName = res.data.data.name;
            this.dataSoure.code = res.data.data.datasourceCode;
            if (res.data.data.code) {
              this.modelChooseEd = "true";
            }
            this.options = JSON.parse(res.data.data.content);
            if (this.options.showTree) {
              this.layout = [
                { x: 4, y: 0, w: 20, h: 30, i: "0" },
                { x: 0, y: 0, w: 4, h: 30, i: "1" },
              ];
            }
            this.importCode = this.options.tableOption;
            this.options.showTree = this.options.showTree
              ? this.options.showTree
              : false;
            // this.dataSoure.code = this.entityId.split(".")[0];
            // this.tableInfo.tableName = this.entityId.split(".")[1];
            this.entityId = this.options.guid;
            this.getMetaCommonList();
          }
          this.designLoading = false;
        })
        .catch((err) => {
          this.designLoading = false;
        });
    },
    selectedNode(data) {
      this.dataSoure = data[0];
      this.tableInfo = data[1];
      this.entityId = data[0].code + "." + data[1].tableName;
    },
    saveForm() {
      if (this.activeBtn == "data") {
        this.$refs.dataDesign.saveModelBuild();
      } else {
        this.saveDynamicForm();
      }
    },
    saveDynamicForm() {
      let appModelId = "";
      let obj = {};
      this.$refs.newDesign.$refs.formDesign.handleGenerate();
      this.options.seachOption = this.$refs.degignSearch.widgetForm;
      this.options.menuList = this.options.menuList;
      this.options.tableOption = this.importCode;
      this.options.guid = this.entityId;
      if (this.options.showTree) {
        this.options.treeOption = {
          dataOption: this.$refs.designTree.$refs.modelsTreeForTree.modelForm,
          pageOption: this.$refs.designTree.treeData,
        };
      } else {
        this.options.treeOption = {};
      }
      if (JSON.stringify(this.moreSetOption) != "{}") {
        this.options.tableQuery = this.moreSetOption;
      }

      this.options.newOption = this.$refs.newDesign.$refs.formDesign.widgetForm;
      this.options.newOpenType =
        this.$refs.newDesign.$refs.formDesign.newOpenType || "target";
      this.options.tableOperList = this.tableOperList;

      obj = {
        appId: 1,
        appModelId: 574567,
        tenantId: this.$store.state.user.tenantId,
        content: JSON.stringify(this.options),
        type: "T",
        code: this.entityId,
        name: this.dashName,
        datasourceCode: this.dataSoure.code,
      };
      this.btnLoading = true;
      if (this.dynamicFormId) {
        obj.id = this.dynamicFormId;
        obj.datasourceCode = this.dataSoure.code;
        updateDynamicForm(obj)
          .then((res) => {
            if (res.data.code == 200) {
              // this.saveEventModel();
              if (this.menuDo == "drawerDo") {
                this.$emit("goTableList");
              } else {
                this.$router.push({
                  path:
                    "/data/table/tableCommon" +
                    "/?modleId" +
                    this.dynamicFormId,
                });
                this.$store.commit("SET_VIEWEDIT", false);
              }
            }
            this.btnLoading = false;
          })
          .catch((err) => {
            this.btnLoading = false;
          });
      } else {
        saveDynamicForm(obj)
          .then((res) => {
            if (res.data.code == 200) {
              if (this.menuDo == "add") {
                this.$emit("goTableList");
              } else {
                this.$store.commit("SET_VIEWEDIT", false);
                this.menuUpdate(res.data.data.id);
              }
            }
            this.btnLoading = false;
          })
          .catch((err) => {
            this.btnLoading = false;
          });
      }
    },
    menuUpdate(id) {
      this.menuId = this.$route.fullPath.split(
        "/data/table/tableCommon?menuId"
      )[1];
      // if (!this.menuId) {
      //   this.menuId = this.modelId;
      // }
      if (this.menuId) {
        getMenu(this.menuId).then((res) => {
          let obj = res.data.data;
          obj.id = this.menuId;
          obj.path = "/data/table/tableCommon" + "/?modleId" + id;
          update(obj).then((m) => {
            this.entityId = "";
            //add
            let btns = ["add", "edit", "view", "update"];
            for (let i = 0; i < btns.length; i++) {
              let btn = {
                $isOpen: "否",
                name: id + "_" + btns[i],
                path: id + "_" + btns[i],
                parentId: m.data.data.id,
                source: "iconfont iconicon_safety",
                code: id + "_" + btns[i],
                category: 2,
                alias: id + "_" + btns[i],
                isOpen: 1,
                sort: 2,
                remark: "",
              };
              add(btn).then(() => {
                console.log("成功了～");
              });
            }
            //批量添加菜单
            this.$router.push({
              path: "/data/table/tableCommon" + "/?modleId" + id,
            });
          });
        });
      }
    },
    dropCommand(v) {
      if (v.value == "moreSet") {
        this.moreSetVisible = true;
      } else {
        this.options.showTree = v.default;
        if (this.options.showTree) {
          this.layout = [
            { x: 4, y: 0, w: 20, h: 30, i: "0" },
            { x: 0, y: 0, w: 4, h: 30, i: "1" },
          ];
        } else {
          this.layout = [{ x: 0, y: 0, w: 24, h: 30, i: "0" }];
        }
      }
    },

    rowDO(row) {
      this.importCode.column.forEach((e) => {
        if (row.includes("rowDel")) {
          if (e.prop == row.split("rowDel")[1]) {
            e.hide = true;
          }
        } else if (row.includes("rowEdit")) {
          if (e.prop == row.split("rowEdit")[1]) {
            this.columnObj = e.prop;
            e.sortable = e.sortable ? e.sortable : false;
            e.overHidden = e.overHidden ? e.overHidden : false;
            this.rowForm = Object.assign(this.rowForm, e);

            this.rowEditVisible = true;
          }
        }
      });
      this.importCode = JSON.parse(JSON.stringify(this.importCode));
    },
    handleWidgetGroupAdd(evt, column) {
      let newIndex = evt.newIndex;
      const item = evt.item;

      if (newIndex == 1 && newIndex > column.children.column.length - 1)
        newIndex = 0;
      if (["分组"].includes(item.textContent)) {
        column.children.column.splice(newIndex, 1);
        return;
      }

      const data = this.deepClone(column.children.column[newIndex]);
      if (!data.prop)
        data.prop = "a" + Date.now() + Math.ceil(Math.random() * 99999);
      delete data.icon;
      if (data.type == "dynamic") data.span = 24;
      else data.span = 12;
      this.$set(column.children.column, newIndex, { ...data });
      this.importCode.column = column.children.column[newIndex];
    },
    formatOptions() {
      this.initPageForm();
    },
    initPageForm() {
      this.pageForm.showLeftRight = "right";
      this.pageForm = JSON.parse(JSON.stringify(this.pageForm));
    },
    formatPageForm(newValue) {
      if (newValue.showPage) {
        this.page = {
          pageSizes: [10, 20, 30, 40], //默认
          currentPage: 1,
          total: 10,
          pageSize: 10,
        };
        this.$refs.crud.$refs.tablePage.$el.style.display = "block";
        this.$refs.crud.$refs.tablePage.$el.style.textAlign =
          this.pageForm.showLeftRight || "right";
      } else {
        this.$refs.crud.$refs.tablePage.$el.style.display = "none";
        this.page = {};
      }
    },
    getMetaCommonList(searchForm) {
      let s = searchForm ? searchForm : this.moreSetOption;
      getMetaCommonList(this.entityId, s).then((res) => {
        if (res.data.code == 200) {
          this.tableList = res.data.data.records;
        }
      });
    },
    rowDel(row, index) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableList.splice(index, 1);
        })
        .catch(() => {});
    },
    rowUpdate(row, index, done) {
      this.tableList.splice(index, 1, row);
      done();
    },
    handleAdd() {
      this.$refs.crud.rowAdd();
    },
    rowSave(row, done) {
      this.tableList.push(row);
    },

    getAnyForm(formName, arr, json) {
      function vaild(option = {}, ele = "") {
        const result = option[ele] + "" || "";
        return !result;
      }
      let _form = {};
      arr.forEach((item) => {
        if (vaild(json, item)) return;
        this.$set(_form, item, json[item]);
      });
      this.$set(this, formName, this.deepClone(Object.assign({}, _form)));
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
@import "../../../../../components/dynamic/form/styles/index.scss";

.baseinfo {
  padding-left: 15px !important;
  padding-right: 5px;
  border-right: 1px solid #ccc;
  height: 100%;
  min-height: 500px;
}
// .btnSaveClass {
//   position: fixed;
//   right: 10px;
//   top: 60px;
// }
/deep/ .el-drawer__header {
  margin-bottom: 0px !important;
  span {
    font-size: 16px;
  }
}
/deep/ .el-drawer__body {
  height: 100%;
  padding-top: 0px !important;
}

.operationVisible-drawer .el-drawer__header {
  margin-bottom: 0px !important;
}
.btnSaveClass {
  position: absolute;
  right: 20px;
  top: 50px;
}
.modelTreePo {
  position: absolute;
  right: 30px;
  z-index: 1;
}
.changSoureId {
  width: 220px;
  position: fixed;
  right: 70px;
  top: 57px;
  .el-form-item > .el-form-item__label {
    display: none;
  }
}
.el-button--medium {
  padding: 10px 10px;
  font-size: 14px;
  border-radius: 4px;
}
.menuhide {
  width: 10px;
  /* opacity: 0; */
  height: 10px;
  overflow: auto;
}
.box-sad {
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  &:hover {
    transform: scale(2);
    border: 1px solid #0c7dff;
  }
}
.arrowIcon {
  position: absolute;
  right: -5px;
  top: 9px;
  font-size: 20px;
  color: #006dcf;
}
/deep/ .el-card__body {
  position: relative;
}
.design {
  .designDiv {
    .basic_dropdown {
      /deep/ .el-dropdown {
        position: absolute;
        right: 25px;
        top: 25px;
        /deep/ .el-icon-setting {
          background: #f5896c !important;
          padding: 2px;
          color: #fff;
        }
      }
    }
    &:hover {
      border-color: #f18b62 !important;
      border-style: dashed;
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0;
      /deep/ .el-dropdown {
        right: 22px;
        top: 22px;
      }
      .divIcon {
        display: block;
      }
    }
  }
}

.designable-btn-dash .el-button--warning {
  border-color: #f18b62 !important;
  background-color: transparent !important;
  color: #f5896c !important;
  border-style: dashed;
}
.el-button--warning {
  border-color: #f18b62 !important;
  color: #f18b62 !important;
  background-color: transparent !important;
  color: #f5896c !important;
  border-style: dashed;
}
.designTable {
  .el-table__header-wrapper {
    .el-icon-rank {
      display: none;
      position: absolute;
      right: 15px;
      top: -10px;
      background: #f5896c !important;
      padding: 2px;
      color: #fff;
    }
    th {
      &:hover {
        .el-icon-rank {
          display: block;
        }
      }
    }
  }
  .el-icon-rank {
    position: absolute;
    right: 17px;
    top: -1px;
    background: #f5896c !important;
    padding: 2px;
    color: #fff;
  }

  .el-dropdown-custonm {
    display: flex;
    .el-icon-setting {
      display: none;
      background: #f5896c !important;
      padding: 2px;
      color: #fff;
      position: absolute;
      right: -3px;
      top: -33px;
      font-size: 12px;
    }
    &:hover {
      .el-icon-setting {
        display: block;
      }
    }
  }
  .el-table th {
    .cell {
      overflow: initial !important;
    }
    &:hover {
      border: #f5896c 1px dashed;
      border-style: dashed;
      .cell .el-icon-setting {
        display: block;
      }
    }
  }
  .el-table th:nth-child(7) {
    .cell {
      overflow: hidden !important;
    }
    &:hover {
      border-color: transparent;
      border-style: none;
      .cell .el-icon-setting {
        display: inherit !important;
      }
    }
  }
}
.active.menuBtnList {
  border-color: #f5896c;
  border-style: dashed;
  i.el-icon-setting {
    display: block;
  }
}
.menuBtnList {
  position: relative;
  border-color: transparent;
  .el-icon-setting {
    display: none;
    position: absolute;
    right: -43px;
    background: #f5896c !important;
    top: -20px;
    padding: 2px;
    color: #fff;
  }
  &:hover {
    border-color: #f5896c;
    border-style: dashed;
    .el-icon-setting {
      display: block;
    }
  }
}
.mnTable {
  position: absolute;
  z-index: 1;
  top: 118px;
  color: transparent;
  th {
    position: relative;
    height: 38px;
    line-height: 36px;
    &:hover {
      border-color: #f5896c;
      border-style: dashed;
      i {
        display: block;
      }
    }
    .dropBtnIcon {
      right: 20px;
      top: 0px;
    }
    i {
      display: none;
      position: absolute;
      background: #f5896c !important;
      padding: 2px;
      color: #fff;
    }
  }
}
.modelTreePo {
  position: absolute;
  right: 30px;
  z-index: 1;
}
.designTab {
  display: inline-block;
  padding: 0 20px;
  border-radius: 4px 4px 0 0;
  line-height: 36px;
  cursor: pointer;
  border-bottom: #1976d3 solid 0px;
  &.active {
    color: #1976d3;
    border-bottom: #1976d3 solid 3px;
    background: #fff;
  }
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus {
  color: #1976d3;
}
.el-form-item {
  border: 1px solid rgba(0, 0, 0, 0);
}
.form-designer .widget-form-container .widget-form-list .widget-form-item {
  border: 1px solid rgba(0, 0, 0, 0);
}
.form-designer
  .widget-form-container
  .widget-form-list
  .widget-form-item:hover {
  border-width: 1px;
}
.h-design {
  height: calc(100vh - 227px);
}
/deep/ .el-scrollbar__wrap {
  width: 100%;
}
/deep/ .el-scrollbar__bar.is-vertical {
  display: none;
}
/deep/ .el-scrollbar__bar {
  z-index: 99999;
}
/deep/ .widget-form-group__body {
  button {
    position: relative;
    .el-dropdown {
      position: absolute;
      top: 0;
      right: 18px;
      background: #f5896c;
      padding: 1px 2px;
      color: #fff;
      display: none;
    }
    .dropBtnIcon {
      position: absolute;
      top: 0;
      right: 0;
      display: none;
    }
    &:hover {
      border: #f5896c 1px dashed;
      .dropBtnIcon {
        display: block;
      }
      .el-dropdown {
        display: block;
      }
    }
  }
}
</style>

<template>
  <el-collapse v-model="activeTab">
    <el-collapse-item name="base">
      <div slot="title" class="panel-tab__title">
        <i class="el-icon-info"></i>基本信息
      </div>
      <taskInfo v-if="type === 'Process'" :id="id" :businessObject="elementBusinessObject" :bpmnElement="bpmnElement"></taskInfo>
      <baseInfo v-else ref="baseInfo" :id="id" :businessObject="elementBusinessObject" :bpmnElement="bpmnElement"/>
    </el-collapse-item>
    <template v-if="type !== 'Process'">
      <el-collapse-item name="base">
        <div slot="title" class="panel-tab__title">
          <i class="el-icon-info"></i> 限制
        </div>
        <condition
            ref="condition"
            :id="id"
            :dataSoure="dataSoure"
            :businessObject="elementBusinessObject"
            :bpmnElement="bpmnElement"
          />
      </el-collapse-item>

      <el-collapse-item name="base">
        <div slot="title" class="panel-tab__title">
          <i class="el-icon-info"></i> 操作
        </div>
        <taskOperation
          ref="taskOperation"
          :id="id"
          :dataSoure="dataSoure"
          :businessObject="elementBusinessObject"
          :bpmnElement="bpmnElement"
          :prefix="prefix"
        />
      </el-collapse-item>
    </template>
  </el-collapse>
</template>
<script>

import baseInfo from "./task-components/baseInfo";
import taskInfo from './task-components/taskInfo.vue';
import condition from "./task-components/condition";
import taskOperation from "./task-components/taskOperation";

export default {
  components: { baseInfo, taskInfo, condition, taskOperation },
  data(){
    return {
      activeTab: 'base'
    }
  },
  props: {
    id: String,
    type: String,
    bpmnModeler: {
      type: Object,
      default(){
        return null;
      }
    },
    bpmnElement: {
      type: Object,
      default(){
        return null;
      }
    },
    dataSoure: {
      type: Object,
      default() {
        return {};
      },
      immediate: true,
    },
    elementBusinessObject: {
      type: Object,
      default() {
        return {};
      },
      immediate: true,
    },
    prefix: String
  },
  watch: {
    
  }
}
</script>
<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" :page.sync="page" :before-open="beforeOpen" v-model="form" ref="crud" @row-update="rowUpdate" @row-save="rowSave"
                   @row-del="rowDel" @search-change="searchChange" @search-reset="searchReset" @current-change="currentChange" @size-change="sizeChange" @refresh-change="refreshChange"
                   @on-load="onLoad">
            <template slot-scope="scope" slot="menu">
                <template v-if="scope.row.status == 1">
                    <el-button type="text" size="small" icon="el-icon-delete" class="none-border" @click="operOrderStatus('确定删除订单?',scope.row,0)">删除
                    </el-button>
                    <el-button type="text" size="small" icon="el-icon-upload2" class="none-border" @click="operOrderStatus('确定提交订单?',scope.row,2)">提交
                    </el-button>
                </template>
                <template v-if="scope.row.status == 2">
                    <el-button @click.stop="handleDelivery(scope.row)" @submit="deliverySubmit" icon="el-icon-bank-card" size="mini" type="text">配送单</el-button>
                </template>
                <template v-if="scope.row.status == 4">
                    <el-button @click.stop="handleXPS(scope.row)" @submit="submitXPS" icon="el-icon-s-order" size="mini" type="text">物流单</el-button>
                </template>
            </template>
        </avue-crud>
        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" v-if="dialogVisible" width="70%" append-to-body @close="dialogCancel">
            <!-- ,,memo:备注,value:金额 -->
            <avue-form :option="formDeliveryOption" @submit="deliverySubmit" v-model="formDelivery"></avue-form>
        </el-dialog>

        <el-dialog :title="xpsDialogTitle" :visible.sync="xpsDialogVisible" v-if="xpsDialogVisible" width="70%" append-to-body @close="xpsDialogCancel">
            <!-- ,,memo:备注,value:金额 -->
            <avue-form :option="formXPSOption" @submit="submitXPS" v-model="formXPS"></avue-form>
        </el-dialog>

    </basic-container>
</template>

<script>
import {
    getList,
    getPage,
    getDetail,
    add,
    update,
    remove,
    createDeliveryOrder,
    toogleStatus,
    dispath2XPS,
} from '@/api/spcod/xpsexpressorder';
import { convertSimple2RegExpPattern } from 'monaco-editor/esm/vs/base/common/strings';
import { mapGetters } from 'vuex';

export default {
    data() {
        // 正则表达式 验证手机号
        const validateMobile = (rule, value, callback) => {
            if (!/^[1]([3-9])[0-9]{9}$/.test(value)) {
                callback(new Error('请输入正确的手机号格式'));
            } else {
                callback();
            }
        };
        return {
            formXPS: {},
            formXPSOption: {
                column: [
                    {
                        label: '快递公司',
                        prop: 'carrierId',
                        type: 'select',
                        rules: [
                            {
                                required: true,
                                message: '请输入承运方，针对不同的快递公司',
                                trigger: 'blur',
                            },
                        ],
                        dicUrl: '/api/park-spcod/xpscarrier/list?current=1&size=10000000',
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                        dicFormatter: data => {
                            let datalist = data.data.records;
                            datalist.forEach(ele => {
                                ele.id = parseInt(ele.id);
                            });
                            return datalist;
                        },
                    },
                    {
                        label: '快递单号',
                        prop: 'sheetNo',
                    },
                    {
                        label: '备注',
                        prop: 'memo',
                        type: 'textarea',
                        span: 24,
                        minRow: 2,
                    },
                ],
            },
            formDelivery: {},
            formDeliveryOption: {
                column: [
                    {
                        label: '取件人',
                        prop: 'pickerId',
                        hide: true,
                        type: 'select',
                        dicUrl: '/api/park-spcod/deliveryman/page?current=1&size=10000000',
                        props: {
                            label: 'deliveryName',
                            value: 'userId',
                        },
                        dicFormatter: res => {
                            return res.data.records;
                        },
                        rules: [
                            {
                                required: false,
                                message: '请输入楼宇Id',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '地址标签',
                        prop: 'addrTag',
                    },
                    {
                        label: '标题',
                        prop: 'deliveryTitle',
                    },
                    {
                        label: '备注',
                        prop: 'memo',
                        type: 'textarea',
                        span: 24,
                        minRow: 2,
                    },
                ],
            },
            dialogTitle: '配送单',
            dialogVisible: false,
            xpsDialogTitle: '快递物流信息',
            xpsDialogVisible: false,
            bizRow: {},
            form: {},
            query: {},
            loading: true,
            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            selectionList: [],
            option: {
                height: 'auto',
                calcHeight: 30,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: true,
                addBtn: true,
                editBtn: false,
                delBtn: false,
                viewBtn: true,
                dialogClickModal: false,
                labelWidth: 120,
                column: [
                    {
                        label: '物品种类',
                        prop: 'mtlTypeName',
                        search: true,
                        searchSpan: 4,
                        type: 'select',
                        dicData: [
                            {
                                label: '文件',
                                value: '文件',
                            },
                            {
                                label: '服装',
                                value: '服装',
                            },
                            {
                                label: '茶叶',
                                value: '茶叶',
                            },
                            {
                                label: '数码产品',
                                value: '数码产品',
                            },
                            {
                                label: '酒水',
                                value: '酒水',
                            },
                            {
                                label: '奶粉',
                                value: '奶粉',
                            },
                            {
                                label: '食物',
                                value: '食物',
                            },
                            {
                                label: '鞋靴',
                                value: '鞋靴',
                            },
                            {
                                label: '电器',
                                value: '电器',
                            },
                        ],
                        rules: [
                            {
                                required: true,
                                message: '请输入物品种类名称，日用品、服饰、文件',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '物品名称',
                        prop: 'mtlName',
                        rules: [
                            {
                                required: true,
                                message: '请输入物品名称',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '物品信息',
                        prop: 'mtlInfo',
                        addDisplay: false,
                        viewDisplay: false,
                        width: 150,
                        formatter(row, value, label, column) {
                            let info = '';
                            if (row.mtlWeight) info += row.mtlWeight + '公斤/';
                            if (row.mtlNum) info += row.mtlNum + '个/';
                            else info += '/';
                            if (row.mtlVolumn) info += row.mtlVolumn + '立方';
                            else info += '0立方';
                            return info;
                        },
                    },
                    {
                        label: '物品重量(kg)',
                        prop: 'mtlWeight',
                        hide: true,
                        rules: [
                            {
                                required: true,
                                message: '请输入物品重量',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '物品件数',
                        prop: 'mtlNum',
                        hide: true,
                        rules: [
                            {
                                required: true,
                                message: '请输入物品件数',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '物品体积(米)',
                        prop: 'mtlVolumn',
                        hide: true,
                        rules: [
                            {
                                required: true,
                                message: '请输入物品体积',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        addDisplay: false,
                        editDisplay: false,
                        label: '承运方',
                        prop: 'carrierName',
                    },
                    {
                        label: '承运方',
                        prop: 'carrierId',
                        hide: true,
                        search: true,
                        searchSpan: 4,
                        type: 'select',
                        rules: [
                            {
                                required: true,
                                message: '请输入承运方，针对不同的快递公司',
                                trigger: 'blur',
                            },
                        ],
                        dicUrl: '/api/park-spcod/xpscarrier/list?current=1&size=10000000',
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                        dicFormatter: data => {
                            let datalist = data.data.records;
                            datalist.forEach(ele => {
                                ele.id = parseInt(ele.id);
                            });
                            return datalist;
                        },
                    },
                    {
                        label: '运单号',
                        prop: 'sheetNo',
                        hide: true,
                        addDisplay: false,
                        editDisplay: false,
                        searchSpan: 5,
                        rules: [
                            {
                                required: true,
                                message: '请输入运单号，刚刚下单的时候未确定快递公司的运单，可以为空',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '寄件人',
                        prop: 'ctxSendorName',
                        rules: [
                            {
                                required: true,
                                message: '请输入发件人',
                                trigger: 'blur',
                            },
                        ],
                        formatter(row, value, label, column) {
                            return row.ctxSendorName + '/' + row.ctxSendorPhone;
                        },
                    },
                    {
                        label: '寄件人电话',
                        prop: 'ctxSendorPhone',
                        hide: true,
                        rules: [
                            {
                                required: true,
                                message: '请输入寄件人电话',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '收件人',
                        prop: 'ctxReceivorName',
                        rules: [
                            {
                                required: true,
                                message: '请输入收件人',
                                trigger: 'blur',
                            },
                        ],
                        formatter(row, value, label, column) {
                            return row.ctxReceivorName + '/' + row.ctxReceivorPhone;
                        },
                    },
                    {
                        label: '收件电话',
                        prop: 'ctxReceivorPhone',
                        hide: true,
                        searchSpan: 4,
                        rules: [
                            {
                                required: true,
                                message: '请输入收件人电话',
                                trigger: 'blur',
                            },
                            {
                                trigger: 'blur',
                                validator: validateMobile,
                            },
                        ],
                    },
                    {
                        label: '快递单时间',
                        prop: 'orderTime',
                        type: 'datetime',
                        format: 'yyyy-MM-dd',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        rules: [
                            {
                                required: true,
                                message: '请输入快递单时间',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '快递日',
                        prop: 'orderDay',
                        type: 'date',
                        hide: true,
                        search: true,
                        searchSpan: 4,
                        addDisplay: false,
                        viewDisplay: false,
                        editDisplay: false,
                        format: 'yyyy-MM-dd',
                        valueFormat: 'yyyy-MM-dd',
                        rules: [
                            {
                                required: false,
                                message: '请输入快递单时间',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '期望收货时间',
                        prop: 'expectTime',
                        type: 'datetime',
                        hide: true,
                        format: 'yyyy-MM-dd HH:mm:ss',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        rules: [
                            {
                                required: true,
                                message: '请输入期望收货时间',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '收货时间',
                        prop: 'pickTime',
                        hide: true,
                        type: 'datetime',
                        format: 'yyyy-MM-dd HH:mm:ss',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        rules: [
                            {
                                required: true,
                                message: '请输入收货时间',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '取件人',
                        prop: 'pickerId',
                        hide: true,
                        type: 'select',
                        dicUrl: '/api/park-spcod/deliveryman/page?current=1&size=10000000',
                        props: {
                            label: 'deliveryName',
                            value: 'userId',
                        },
                        dicFormatter: res => {
                            return res.data.records;
                        },
                        rules: [
                            {
                                required: false,
                                message: '请输入取件人Id',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '运费统计',
                        hide: true,
                        addDisplay: false,
                        editDisplay: false,
                        prop: 'totalFee',
                        rules: [
                            {
                                required: true,
                                message: '请输入运费统计',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '状态',
                        prop: 'status',
                        addDisplay: false,
                        editDisplay: false,
                        search: true,
                        searchSpan: 4,
                        type: 'select',
                        //状态,1新建/0删除/2提交/3已配派/4取货/5已物流
                        dicData: [
                            {
                                label: '新运单',
                                value: 1,
                            },
                            {
                                label: '已提交',
                                value: 2,
                            },
                            {
                                label: '已配派',
                                value: 3,
                            },
                            {
                                label: '已取货',
                                value: 4,
                            },
                            {
                                label: '已物流',
                                value: 5,
                            },
                            {
                                label: '删除',
                                value: 0,
                            },
                        ],
                        rules: [
                            {
                                required: false,
                                message: '请输入状态',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        addDisplay: false,
                        editDisplay: false,
                        hide: true,
                        label: '所属用户',
                        prop: 'userName',
                        rules: [
                            {
                                required: true,
                                message: '请输入所属用户',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '备注',
                        prop: 'memo',
                        hide: true,
                        span: 24,
                        minRows: 2,
                        type: 'textarea',
                        rules: [
                            {
                                required: false,
                                message: '请输入订购注意事项',
                                trigger: 'blur',
                            },
                        ],
                    },
                ],
            },
            data: [],
        };
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.xpsexpressorder_add, false),
                viewBtn: this.vaildData(this.permission.xpsexpressorder_view, false),
                delBtn: this.vaildData(this.permission.xpsexpressorder_delete, false),
                editBtn: this.vaildData(this.permission.xpsexpressorder_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    methods: {
        operOrderStatus(msg, row, nextStatus) {
            let _this = this;
            //状态，0删除/1待审核/2提交审核/3审核通过/4审核退回/5下架
            this.$confirm(msg, {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    //修改状态
                    toogleStatus(row.id, nextStatus);
                })
                .then(() => {
                    row.status = nextStatus;
                    _this.$forceUpdate();
                    //this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    this.$refs.crud.toggleSelection();
                });
        },
        rowSave(row, done, loading) {
            add(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    window.console.log(error);
                },
            );
        },
        rowUpdate(row, index, done, loading) {
            update(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    console.log(error);
                },
            );
        },
        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(row.id);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning('请选择至少一条数据');
                return;
            }
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(this.ids);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    this.$refs.crud.toggleSelection();
                });
        },
        beforeOpen(done, type) {
            if (['edit', 'view'].includes(type)) {
                getDetail(this.form.id).then(res => {
                    this.form = res.data.data;
                });
            }
            done();
        },
        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },
        searchChange(params, done) {
            this.query = params;
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
            done();
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        currentChange(currentPage) {
            this.page.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        handleXPS(row) {
            this.bizRow = row;
            this.xpsDialogVisible = true;
        },
        submitXPS() {
            let _this = this;
            this.formXPS.id = this.bizRow.id;
            console.log(this.formXPS);
            dispath2XPS(this.formXPS).then(res => {
                console.log(res);
                this.xpsDialogVisible = false;
            });
        },
        handleDelivery(row) {
            this.bizRow = row;
            this.dialogVisible = true;
        },
        deliverySubmit() {
            this.formDelivery.bizId = this.bizRow.id;
            this.formDelivery.bizType = 'XPS';
            this.formDelivery.receiptorId = this.bizRow.userId;
            createDeliveryOrder(this.formDelivery)
                .then(res => {
                    console.log('createDeliveryOrder:', res);
                    this.dialogVisible = false;
                    this.onLoad(this.page);
                })
                .catch(e => {
                    this.dialogVisible = false;
                    console.log(e);
                    //this.taskTableLoading = false;
                });
        },
        onLoad(page, params = {}) {
            this.loading = true;
            getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                this.loading = false;
                this.selectionClear();
            });
        },
        xpsDialogCancel() {},
        dialogCancel() {},
    },
};
</script>

<style>
</style>

<template>
  <basic-container
    isCard
    :body-style="{ padding: '0', height: '100%' }"
    class="h-100p"
  >
    <el-container class="h-100p">
      <el-header height="50px" class="lh-50 bor-b" v-if="!isTodo">
        <basic-page-header
          @back="back"
          class="lh-50"
          :title="'政策资讯详情'"
        ></basic-page-header>
      </el-header>
      <el-container style="height: calc(100% - 50px)" class="pad-20">
        <el-main class="ov-a">
          <el-descriptions
            title="政策资讯详情"
            :column="4"
            :colon="false"
            direction="vertical"
            :labelStyle="labelStyle"
          >
            <el-descriptions-item label="所属分类">{{
              policyForm.catgName
            }}</el-descriptions-item>
            <el-descriptions-item label="发布人">
              {{ policyForm.createUserName }}
            </el-descriptions-item>
            <el-descriptions-item label="发布时间" :span="2">
              {{ policyForm.releaseTime }}
            </el-descriptions-item>
            <el-descriptions-item label="接收人" :span="policyForm.visualId == 1 ? 4 : 1">
              {{ policyForm.visualName }}
            </el-descriptions-item>
            <el-descriptions-item v-if="policyForm.visualId != 1" label="接收企业" :span="3">{{
              policyForm.deptNames || '-'
            }}</el-descriptions-item>
            <el-descriptions-item label="标题" :span="4">
              {{ policyForm.title }}</el-descriptions-item
            >

            <el-descriptions-item label="政策资讯内容">
              <template>
                <!--<div v-html="policyForm.content1"></div>-->
                <div v-if="showEdt" v-html="policyForm.content1"></div>
                <!-- <div  class="content ql-editor" v-html="editor(form.content)"></div> -->
              </template>
            </el-descriptions-item>
          </el-descriptions>
        </el-main>
      </el-container>
    </el-container>

    <RichText style="display: none"></RichText>
  </basic-container>
</template>

<script>
import standardService from "@/api/metadata/standard";
import { submit } from "@/api/visitor/visitor";
import { mapGetters, mapState } from "vuex";
import * as dictbizService from "@/api/system/dictbiz";
import { getDetail, getProcess } from "@/api/spcod/prnpolicy";
import { getTaskInfo } from "@/api/flow/flow.js";
import "@wangeditor/editor-for-vue";
import editor from "@wangeditor/editor";
import { htmlDecode } from "@/util/util";
import {
  getMetaCommonList,
  delMetaCommon,
  updateMetaCommon,
} from "@/api/busiMode/metaCommon";
import { searchList } from '@/api/system/user'

export default {
  data() {
    return {
      showEdt: false,
      editor: null,
      htmlContent: "",
      ruleModel: {},
      labelStyle: {
        color: "#999",
        fontSize: "12px",
      },
      cardTypeName: "",
      option1: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,
        disabled: true,
        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,
            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      option: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,
            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      imgform: {
        imgUrl: [],
      },
      policyForm: {
      },
      params: {
        zoom: 4,
        zoomEnable: false,
        dragEnable: true,
      },
      ruleModel: {},
      companyList: [],
      model: "wisdom_park.wp_publish_schedule",
      programModel: "wisdom_park.wp_publish_program",
      cardTypeList: [],
    };
  },
  computed: {},
  mounted() {
    if (this.$router.currentRoute.query.id) {
      this.getPolicyInfo();
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    isTodo: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        console.log("watch:", val);
        //this.getPolicyInfo();
      },
    },
  },
  methods: {
    toHump(name) {
      return name.replace(/\_(\w)/g, function (all, letter) {
        return letter.toUpperCase();
      });
    },
    getPolicyInfo() {
      let _this = this;
      getDetail(this.id).then((res) => {
        //console.log(res);
        if (res.data.code == 200) {
          _this.policyForm = res.data.data;
          this.getDeptIds();
          this.searchUser();
          _this.editor = window.editor;
          _this.showEdt = true;
          _this.policyForm.content1 = htmlDecode(
            _this.policyForm.content,
            false
          );
        }
      });
    },
    searchUser(){
      searchList({
        id: this.policyForm.createUser
      }).then(
        res => {
          if(res.data.data.records && res.data.data.records.length){
            this.policyForm.createUserName = res.data.data.records[0].name;
            this.$forceUpdate();
          }
        }
      )
    },
    getDeptIds() {
      let deptIds = this.policyForm.deptIds.split(',');
      let body = {
        current: 1,
        size: 1000,
        wheres: [],
      };
      deptIds.forEach(
        item => {
          body.wheres.push({field: 'dept_id' , value: item});
        }
      );
      getMetaCommonList("wisdom_park.wp_company", body).then((res) => {
        const data = res.data.data;
        this.policyForm.deptNames = data.records.map(item => item.dept_name).join(',');
        // console.log(this.policyForm)
        this.$forceUpdate();
      });
    },
    submit() {
      return new Promise((resolve) => {
        this.$refs["policyForm"].validate((valid) => {
          if (valid) {
            resolve({
              valid: true,
              form: this.policyForm,
            });
          } else {
            let fields = this.$refs["policyForm"].fields,
              firstError = {
                field: "",
                message: "",
                name: "",
              };
            for (let i = 0; i < fields.length; i++) {
              if (fields[i].validateState === "error") {
                firstError.message = fields[i].validateMessage;
                firstError.field = fields[i].prop;
                firstError.name = fields[i].label;
                break;
              }
            }
            resolve({
              valid: false,
              form: this.policyForm,
              error: firstError,
            });
          }
        });
      });
    },
    back() {
      this.$router.push({
        path: "/information/publish",
      });
    },
    save() {
      Promise.all([this.submit()]).then((forms) => {
        //照片
        if (this.imgform) {
          let imgList = [];
          this.imgform.imgUrl.forEach((e) => {
            if (e.label) {
              imgList.push(e.label);
            }
          });
          this.policyForm.attachment_id = imgList.toString();
        }
        if (this.policyForm.id) {
          let o = {
            update: this.policyForm,
            primaryKeyValue: this.policyForm["id"],
            primaryKey: "id",
          };
          submit(this.policyForm).then((res) => {
            if (res.data.code == 200) {
              this.$router.push({
                path: "/visitor/index",
              });
            }
          });
        } else {
          submit(this.policyForm).then((res) => {
            if (res.data.code == 200) {
              this.$router.push({
                path: "/visitor/index",
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style src="@wangeditor/editor/dist/css/style.css"></style>
<style lang="scss" scoped>
.contenCon {
  display: none;
  width: 0 !important;
  height: 0 !important;
}

.contenCon .el-form-item__content {
  display: none;
  width: 0 !important;
  height: 0 !important;
}

/deep/ .el-divider--horizontal {
  margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
  left: 0px;
}
/deep/ .el-divider__text {
  padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
  width: 68px;
  height: 68px;
  line-height: 72px;
  vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
}
.btnClass {
  display: flow-root;
  height: 50px;
  line-height: 50px;
}
/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
/deep/ .el-select {
  width: 100%;
}
.dsdsdsdds {
  border: 1px solid black;
}
</style>

<template>
  <div>
    <!-- <el-divider content-position="left">基础信息</el-divider> -->
    <el-form
      class="pad-10"
      :model="ruleForm"
      ref="ruleForm"
      :disabled="doType == 'detail'"
      label-width="100px"
      label-position="top"
      size="small"
      :rules="ruleModel"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="企业名称" prop="name">
            <el-input
              :disabled="doType == 'edit'"
              v-model="ruleForm.name"
              placeholder="请输入企业名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item
            label="统一社会信用代码"
            prop="uniform_social_credit_code"
          >
            <el-input
              :disabled="doType == 'edit'"
              placeholder="请输入统一社会信用代码"
              v-model="ruleForm.uniform_social_credit_code"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6">
          <el-form-item label="企业法人" prop="legal_person">
            <el-input
              placeholder="请输入企业法人"
              :disabled="doType == 'edit'"
              v-model="ruleForm.legal_person"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="doType != 'bg'">
          <el-form-item label="联系方式" prop="legal_contact">
            <el-input
              placeholder="请输入联系方式"
              v-model="ruleForm.legal_contact"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="doType != 'bg'">
          <el-form-item label="企业规模" prop="firm_size">
            <el-select
              v-model="ruleForm.firm_size"
              size="small"
              placeholder="请选择企业规模"
            >
              <el-option
                v-for="item in firmSizeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="doType != 'bg'" class="roomForm">
          <el-form-item label="注册资金(万)" prop="registered_capital">
            <el-input
              placeholder="请输入注册资金"
              type="number"
              v-model.number="ruleForm.registered_capital"
              class="input-with-select"
            >
              <template slot="append">
                <el-select
                  v-model="ruleForm.registered_capital_currency"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="(item, index) of registeredCapitalCurrencys"
                    :key="index"
                    :label="item.name"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </template>
            </el-input>
          </el-form-item>
          <!-- <el-form-item label="注册资金(万元)" prop="registered_capital">
            <el-input
              placeholder="请输入注册资金"
              v-model="ruleForm.registered_capital"
            ></el-input>
          </el-form-item> -->
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6" v-if="doType != 'bg'">
          <el-form-item label="所属行业" prop="type">
            <el-select
              v-model="ruleForm.industry"
              size="small"
              placeholder="请选择行业"
            >
              <el-option
                v-for="item in industrys"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="doType != 'bg'">
          <el-form-item label="所属产业链" prop="industry_chain">
            <el-select
              v-model="ruleForm.industry_chain"
              size="small"
              placeholder="请选择所属产业链"
            >
              <el-option
                v-for="item in industryChains"
                :key="item.value"
                :label="item.name"
                :value="item.value + ''"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6" v-if="doType != 'bg'">
          <el-form-item label="企业类型" prop="type">
            <el-select
              v-model="ruleForm.type"
              size="small"
              placeholder="请选择企业类型"
            >
              <el-option
                v-for="item in enterpriseTypes"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="doType != 'bg'">
          <el-form-item label="科技型企业类型" prop="type">
            <el-select
              v-model="ruleForm.technical_type"
              size="small"
              multiple
              placeholder="请选择科技型企业类型"
            >
              <el-option
                v-for="item in companyTechnicalTypes"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="6" v-if="doType != 'bg'">
          <el-form-item label="企业资质" prop="qualification">
            <el-select
              v-model="ruleForm.qualification"
              size="small"
              placeholder="请选择企业资质"
            >
              <el-option
                v-for="item in qualifications"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="doType != 'bg'">
          <el-form-item label="上市状态">
            <!-- 
            <el-radio-group v-model="ruleForm.is_public">
              <el-radio :label="0">未上市</el-radio>
              <el-radio :label="1">已上市</el-radio>
            </el-radio-group> -->
            <el-select
              v-model="ruleForm.is_public"
              size="small"
              placeholder="请选择上市状态"
            >
              <el-option
                v-for="item in companyIsPublics"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="doType != 'bg'">
          <el-form-item label="负责人" prop="principal">
            <el-input
              placeholder="请输入负责人姓名"
              v-model="ruleForm.principal"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="doType != 'bg'">
          <el-form-item label="联系电话" prop="phone_num">
            <el-input
              placeholder="请输入联系电话"
              v-model="ruleForm.phone_num"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="注册地区" prop="registered_area">
            <el-input
              type="textarea"
              :disabled="doType == 'edit'"
              placeholder="请输入注册地区"
              :rows="2"
              v-model="ruleForm.registered_area"
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="注册详细地址" prop="registered_address">
            <el-input
              :disabled="doType == 'edit'"
              type="textarea"
              :rows="2"
              placeholder="请输入注册详细地址"
              v-model="ruleForm.registered_address"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <div class="pad-l-15 pad-r-15">
      <el-divider content-position="left">营业执照</el-divider>
      <div class="pad-10" v-if="imgform.imgUrl.length == 0">暂无照片</div>
      <avue-form :option="option1" v-model="imgform"></avue-form>
      <template v-if="doType != 'bg'">
        <el-divider content-position="left">企业LOGO</el-divider>
        <div class="pad-10" v-if="imgform1.imgUrl.length == 0">暂无照片</div>
        <avue-form :option="option2" v-model="imgform1"></avue-form>
      </template>
    </div>
  </div>
</template>

<script>
import * as dictbizService from "@/api/system/dictbiz";
import standardService from "@/api/metadata/standard";
import { getMetaCommonInfo, getPicListByIds } from "@/api/busiMode/metaCommon";

export default {
  data() {
    return {
      ruleModel: {},
      company_logo_base64: "",
      option1: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,

        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,

            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },

      option2: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,

            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      option: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,

        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,

            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      imgform: {
        imgUrl: [],
      },
      imgform1: {
        imgUrl: [],
      },
      dialogImageUrl: "",
      dialogVisible: false,
      ruleForm: {
        industry_chain: "5",
      },
      params: {
        zoom: 4,
        zoomEnable: false,
        dragEnable: true,
      },
      firmSizeList: [],
      enterpriseTypes: [],
      registeredCapitalCurrencys: [],
      companyTechnicalTypes: [],
      qualifications: [],
      companyIsPublics: [],
      industrys: [],
      industryChains: [],
      entModel: "wisdom_park.wp_company",
      oldCompany: {},
    };
  },
  computed: {},
  watch: {
    currentRow: {
      immediate: true,
      handler(val) {
        if (val.id) {
          this.getMetaCommonInfo();
        }
      },
    },
    doType: {
      immediate: true,
      handler(val) {
        if (val == "detail") {
          this.option1.disabled = true;
          this.option2.disabled = true;
          this.option1 = JSON.parse(JSON.stringify(this.option1));
          this.option2 = JSON.parse(JSON.stringify(this.option2));
        }
        if (val == "edit") {
          this.option1.disabled = true;
          this.option1 = JSON.parse(JSON.stringify(this.option1));
        } else {
          this.option1.disabled = false;
          this.option2.disabled = false;
          this.option1 = JSON.parse(JSON.stringify(this.option1));
          this.option2 = JSON.parse(JSON.stringify(this.option2));
        }
        if (val == "add") {
          this.ruleForm = {};
        }
      },
    },
  },
  mounted() {
    this.getDicList();
    if (this.currentRow.id) {
      this.getMetaCommonInfo();
    } else {
      this.queryByModelCode();
    }
  },
  props: {
    doType: {
      type: String,
      default() {
        return "";
      },
    },
    currentRow: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    queryByModelCode() {
      standardService.getModelRule(this.entModel).then((res) => {
        console.log(res);
        this.ruleModel = res;
      });
    },
    getDicList() {
      dictbizService
        .getDictionary({
          code: "firmSize",
        })
        .then((res) => {
          this.firmSizeList = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
      dictbizService
        .getDictionary({
          code: "enterpriseType",
        })
        .then((res) => {
          this.enterpriseTypes = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
      dictbizService
        .getDictionary({
          code: "companyTechnicalType",
        })
        .then((res) => {
          this.companyTechnicalTypes = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
      dictbizService
        .getDictionary({
          code: "companyIsPublic",
        })
        .then((res) => {
          this.companyIsPublics = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: Number(item["dictKey"]),
            };
          });
        });

      dictbizService
        .getDictionary({
          code: "registered_capital_currency",
        })
        .then((res) => {
          this.registeredCapitalCurrencys = (res.data.data || []).map(
            (item) => {
              return {
                name: item["dictValue"],
                value: Number(item["dictKey"]),
              };
            }
          );
        });

      dictbizService
        .getDictionary({
          code: "qualification",
        })
        .then((res) => {
          this.qualifications = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
      dictbizService
        .getDictionary({
          code: "industry",
        })
        .then((res) => {
          this.industrys = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
      dictbizService
        .getDictionary({
          code: "IndustryChain",
        })
        .then((res) => {
          this.industryChains = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    getMetaCommonInfo() {
      let obj = {
        pk: "id",
        id: this.currentRow.id,
      };
      this.imgform.imgUrl = [];
      this.imgform1.imgUrl = [];
      getMetaCommonInfo(this.entModel, obj).then((res) => {
        if (res.data.code == 200) {
          this.ruleForm = res.data.data;

          if (this.ruleForm.industry_chain) {
            this.ruleForm.industry_chain = this.ruleForm.industry_chain + "";
          }

          if (this.ruleForm.technical_type) {
            this.ruleForm.technical_type =
              this.ruleForm.technical_type.split(",");
          }
          this.height = res.data.data.floor_height;

          if (res.data.data["business_license"]) {
            getPicListByIds(res.data.data["business_license"]).then((res) => {
              if (res.data.code == 200) {
                let arr = [];
                res.data.data.forEach((pic) => {
                  arr.push({
                    label: pic.id,
                    value: pic.link,
                  });
                });
                this.imgform.imgUrl = arr;
              }
            });
          }
          if (res.data.data["company_logo"]) {
            getPicListByIds(res.data.data["company_logo"]).then((res) => {
              if (res.data.code == 200) {
                let arr = [];
                res.data.data.forEach((pic) => {
                  arr.push({
                    label: pic.id,
                    value: pic.link,
                  });
                });

                this.imgform1.imgUrl = arr;
              }
            });
          }
          this.oldCompany = JSON.stringify(this.ruleForm);
          this.queryByModelCode();
        }
      });
    },
    submit() {
      return new Promise((resolve) => {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            resolve({
              valid: true,
              form: this.ruleForm,
            });
          } else {
            let fields = this.$refs["ruleForm"].fields,
              firstError = {
                field: "",
                message: "",
                name: "",
              };
            for (let i = 0; i < fields.length; i++) {
              if (fields[i].validateState === "error") {
                firstError.message = fields[i].validateMessage;
                firstError.field = fields[i].prop;
                firstError.name = fields[i].label;
                break;
              }
            }
            resolve({
              valid: false,
              form: this.ruleForm,
              error: firstError,
            });
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
  margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
  left: 0px;
}
/deep/ .el-divider__text {
  padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
  width: 68px;
  height: 68px;
  line-height: 72px;
  vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
}
/deep/ .el-select {
  width: 100%;
}
.roomForm {
  .input-with-select {
    /deep/.el-input {
      width: 90px;
    }
  }
}
</style>

<template>
  <div>
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      label-position="top"
      size="small"
      :rules="ruleModel"
    >
      <el-divider content-position="left">楼层信息</el-divider>
      <el-row class="pad-l-10">
        <el-col :span="6">
          <el-form-item
            label="楼宇层数"
            prop="floor_num"
            :rules="[
              { required: true, message: '请输入楼宇层数', trigger: 'blur' },
            ]"
          >
            <el-input
              placeholder="请输入楼宇层数"
              v-model="ruleForm.floor_num"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="每层高度" prop="floor_height">
            <el-select
              v-model="ruleForm.floor_height"
              size="small"
              placeholder="请选择"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-divider content-position="left">房间信息</el-divider>
      <el-row>
        <el-col :span="6">
          <el-form-item label="出租单价" prop="num">
            <el-input v-model="ruleForm.floor_num">
              <template slot="append">元/m²/天</template></el-input
            >
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label=" " prop="hight">
            <el-input v-model="ruleForm.hight">
              <template slot="append">元/月</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="装修情况" prop="num">
            <el-input v-model="ruleForm.floor_num"> </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="付款方式" prop="num">
            <el-input v-model="ruleForm.floor_num"> </el-input>
          </el-form-item>
        </el-col>
      </el-row> -->
      <el-row>
        <el-col :span="24" class="h-100 pad-l-15">
          <el-form-item label="楼宇标签" prop="label">
            <el-popover
              placement="top-start"
              title="添加标签"
              width="300"
              trigger="click"
            >
              <el-button slot="reference" icon="el-icon-plus"></el-button>
              <div style="height: 74px">
                <template v-for="tag in roomTags">
                  <el-tag
                    class="cur-p mar-r-5 mar-b-5"
                    v-if="ruleForm.label.indexOf(tag.id) < 0"
                    :key="tag.id"
                    :disable-transitions="false"
                    @click="ruleForm.label.push(tag.id)"
                  >
                    {{ tag.dictValue }}
                  </el-tag>
                </template>
              </div>
            </el-popover>
            <el-tag
              :key="tag"
              v-for="(tag, index) in ruleForm.label"
              closable
              :disable-transitions="false"
              class="mar-l-5 mar-b-5"
              @close="ruleForm.label.splice(index, 1)"
            >
              {{ getTagName(tag) }}
            </el-tag>
          </el-form-item>
        </el-col>
      </el-row>

      <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </el-form>
    <el-divider content-position="left">楼层配置</el-divider>
    <el-row class="pad-l-10 pad-r-10">
      <el-col :span="24">
        <configTable
          :buildObj="ruleForm"
          :newFloor="true"
          :onlyRead="false"
          ref="configTable"
          @close="close"
        ></configTable>
      </el-col>
    </el-row>

    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import configTable from "../buildDetail/configTable";
import { getDictionary } from "@/api/system/dictbiz";
import standardService from "@/api/metadata/standard";

export default {
  components: { configTable },
  data() {
    return {
      ruleModel: {},
      ruleForm: {
        label: [],
      },
      model: "wisdom_park.wp_building",
      rules: {},
      dialogImageUrl: "",
      dialogVisible: false,
      onlyEdit: false,

      options: [
        {
          value: "1",
          label: "1～3米",
        },
        {
          value: "2",
          label: "3～4米",
        },
        {
          value: "3",
          label: "4～5米",
        },
        {
          value: "4",
          label: "5米以上",
        },
      ],
      roomTags: [],
    };
  },
  computed: {},
  created() {},
  mounted() {
    getDictionary({
      code: "buildBrightenedDot",
    }).then((res) => {
      this.roomTags = res.data.data || [];
      console.log(this.roomTags);
    });
    // this.queryByModelCode();
  },
  methods: {
    queryByModelCode() {
      standardService.getModelRule(this.model).then((res) => {
        this.ruleModel = res;
      });
    },
    submit() {
      return new Promise((resolve) => {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            resolve({
              valid: true,
              form: this.ruleForm,
            });
          } else {
            let fields = this.$refs["ruleForm"].fields,
              firstError = {
                field: "",
                message: "",
                name: "",
              };
            for (let i = 0; i < fields.length; i++) {
              if (fields[i].validateState === "error") {
                firstError.message = fields[i].validateMessage;
                firstError.field = fields[i].prop;
                firstError.name = fields[i].label;
                break;
              }
            }
            resolve({
              valid: false,
              form: this.ruleForm,
              error: firstError,
            });
          }
        });
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    save(id) {
      this.$refs.configTable.save(id);
    },
    getConfigTable(id) {
      let obj = {
        aboveFloorList: this.$refs.configTable.aboveFloorList,
        underFloorList: this.$refs.configTable.underFloorList,
      };
      return obj;
    },
    close() {
      this.$emit("close");
    },
    getTagName(tag) {
      let tagName = "";
      this.roomTags.forEach((item) => {
        if (item.id === tag) {
          tagName = item["dictValue"];
        }
      });
      return tagName;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-form-item.el-form-item--mini + .el-form-item {
  margin-top: 0px !important;
}
/deep/ .el-divider--horizontal {
  margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
  left: 0px;
}
/deep/ .el-divider__text {
  padding: 0px 10px 0px 0px;
}
.configInput {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 80px;
  margin: 0px 5px;
}
</style>

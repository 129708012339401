<template>
  <div class="h-100p ov-a">
    <component v-if="show" :is="item.component" :data="data" :option="item.option" :widget="item"></component>
  </div>
</template>
<script>

import BuiltInWidgetComponents from '../widget/index';

const debounce = (() => {
  let timer = null
  return (callback, wait) => {
    clearTimeout(timer)
    timer = setTimeout(callback, wait)
  }
})();

export default {
  components: { ...BuiltInWidgetComponents },
  data(){
    return {
      data: null,
      show: true,
    }
  },
  watch: {
    'item.datasourceConf': {
      deep: true,
      immediate: true,
      handler(){
        this.setData();
      }
    }
  },
  props: {
    item: {
      type: Object,
      default(){
        return {
          
        }
      }
    }
  },
  mounted(){
    this.setData();
  },
  methods: {
    resize(){
      debounce(() => {
        this.show = false;
        this.$nextTick(() => {
          this.show = true;
        })
      } , 300);
    },

    setData(){
      if(this.item.datasourceConf && this.item.datasourceConf.dataModel){
        if(typeof this[`dataHandle_${this.item.datasourceConf.dataModel}`] === 'function'){
          this.data = this[`dataHandle_${this.item.datasourceConf.dataModel}`]();
        }
      }
    },

    dataHandle_staticData(){
      if(this.item.datasourceConf.dataType === 'text'){
        return this.item.datasourceConf.staticValue;
      }else if(this.item.datasourceConf.dataType === 'json'){
        if(typeof this.item.datasourceConf.staticValue === 'object'){
          return this.item.datasourceConf.staticValue;
        }else{
          let data = null;
          try {
            data = JSON.parse(this.item.datasourceConf.staticValue);
          } catch (error) {
            data = null;
          }
          return data;
        }
      }else{
        return null;
      }
    }
  }
}
</script>
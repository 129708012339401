<template>
  <div class="manage-meta" v-loading="showMetaTree">
    <div class="display-flex">
      <div class="w-300" style="margin-bottom: 0">
        <el-card
          shadow="never"
          :body-style="{ padding: '20px 20px 0 20px' }"
          class="meta-height"
        >
          <el-input
            style="margin-bottom: 15px; width: calc(100% - 33px)"
            size="small"
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
            v-model="input2"
            @change="filterTree"
          >
          </el-input>
          <el-tooltip :content="'新增'" placement="top" :enterable="false">
            <span>
              <el-button
                style="margin-left: 5px"
                type="primary"
                size="mini"
                icon="el-icon-circle-plus-outline"
                @click="addCategory"
                circle
              ></el-button>
            </span>
          </el-tooltip>
          <el-tree
            ref="tree"
            :data="data"
            :load="handleNodeClick"
            node-key="id"
            lazy
            :expand-on-click-node="false"
            :props="defaultProps"
            @node-click="nodeClick"
            @node-contextmenu="contextMenuOperation"
          >
          </el-tree>
        </el-card>
      </div>
      <div
        class="meta-container"
        style="margin-bottom: 0; flex: 1"
        v-loading="showMeta"
      >
        <data-assets-detail
          v-if="showMetaInfo"
          :showOperation="true"
          :propDatabaseName="datasourceName"
          :isRoute="false"
          :propTableName="displayName"
          :propDatabaseId="datasourceId"
          :propGuid="guid"
          @back="showMetaInfo = false"
          @edit-meta="handleMetadaEdit"
          @delete-meta="handleDelMetadata"
        ></data-assets-detail>
        <el-card
          v-show="!showMetaInfo"
          shadow="never"
          :body-style="{ padding: '20px 20px 0 20px' }"
          class="meta-height"
        >
          <div :class="{ hidden: showMetaInfo }">
            <avue-crud
              ref="crud"
              v-model="modelForm"
              :before-close="beforeClose"
              :table-loading="modelLoading"
              :data="modelList"
              :page.sync="page"
              :option="modelOption"
              @on-load="getMetadataList"
              @refresh-change="getMetadataList"
              @row-save="handleMetadataSave"
              @row-update="handleMetadaUpdate"
            >
              <template slot="menuLeft" slot-scope="{ size }">
                <!-- <el-button
                  icon="el-icon-download"
                  size="small">导出
                </el-button> -->
                <el-input
                  v-model="searchVal"
                  @keyup.native.enter="getMetadataList"
                  size="small"
                  :placeholder="$t('Please enter keyword')"
                  type="text"
                  style="width: 180px"
                >
                  <el-button
                    class="mar-0 pad-0"
                    size="small"
                    @click="getMetadataList"
                    slot="append"
                    icon="el-icon-search"
                  ></el-button>
                </el-input>
              </template>
              <template slot-scope="{ type, size, row }" slot="menu">
                <el-button
                  @click.stop="handleMetadaEdit(row)"
                  icon="el-icon-edit"
                  :size="size"
                  :type="type"
                  >编辑</el-button
                >
                <el-button
                  @click.stop="handleDelMetadata(row)"
                  icon="el-icon-delete"
                  :size="size"
                  :type="type"
                  >删除</el-button
                >

                <!--<el-button @click="copyFormAdd" icon="el-icon-document-copy" :size="size" :type="type">复制</el-button>-->
              </template>
              <template slot="displayText" slot-scope="scope">
                <a @click="showMetaDetail(scope.row)">{{
                  scope.row.displayText
                }}</a>
              </template>
              <template slot="owner" slot-scope="scope">
                {{ scope.row.attributes["owner"] }}
              </template>
              <template slot="description" slot-scope="scope">
                {{ scope.row.attributes["description"] }}
              </template>
              <template slot-scope="{ row }" slot="modelPackageIdForm">
                <div style="width: 48%; float: left">
                  <avue-input-tree
                    v-model="modelForm.modelPackageId"
                    placeholder="请选择模型包"
                    type="tree"
                    :props="props"
                    :dic="modelPackageTreeData"
                    @change="changePackage"
                  ></avue-input-tree>
                </div>
                <div style="width: 48%; float: left; margin-left: 4%">
                  <avue-input-tree
                    v-model="modelForm.modelId"
                    placeholder="请选择模型"
                    type="tree"
                    :props="props"
                    :dic="modelTreeData"
                    @change="changeModel"
                  ></avue-input-tree>
                </div>
                <div style="clear: both">
                  <avue-crud
                    ref="property"
                    v-model="propertyForm"
                    :data="propertyList"
                    :option="propertyOption"
                  >
                    <template slot="property" slot-scope="scope">
                      <div
                        v-if="scope.row.typeName === 'string'"
                        style="display: flex"
                      >
                        <span
                          v-if="!scope.row.isOptional"
                          style="
                            color: #f56c6c;
                            margin-right: 5px;
                            padding-top: 5px;
                          "
                          >*</span
                        >
                        <avue-input
                          v-model="scope.row.property"
                          placeholder="请输入值"
                        ></avue-input>
                      </div>
                      <div
                        v-if="scope.row.typeName === 'date'"
                        style="display: flex"
                      >
                        <span
                          v-if="!scope.row.isOptional"
                          style="
                            color: #f56c6c;
                            margin-right: 5px;
                            padding-top: 5px;
                          "
                          >*</span
                        >
                        <avue-date
                          v-model="scope.row.property"
                          type="datetime"
                          format="yyyy年MM月dd日 hh:mm:ss"
                          value-format="timestamp"
                          placeholder="请选择日期"
                        ></avue-date>
                      </div>
                      <div
                        v-if="scope.row.typeName === 'int'"
                        style="display: flex"
                      >
                        <span
                          v-if="!scope.row.isOptional"
                          style="
                            color: #f56c6c;
                            margin-right: 5px;
                            padding-top: 5px;
                          "
                          >*</span
                        >
                        <el-input-number
                          v-model="scope.row.property"
                        ></el-input-number>
                      </div>
                      <div
                        v-if="scope.row.typeName === 'float'"
                        style="display: flex"
                      >
                        <span
                          v-if="!scope.row.isOptional"
                          style="
                            color: #f56c6c;
                            margin-right: 5px;
                            padding-top: 5px;
                          "
                          >*</span
                        >
                        <el-input-number
                          v-model="scope.row.property"
                          :precision="2"
                          :step="0.1"
                        ></el-input-number>
                      </div>
                      <div
                        v-if="scope.row.typeName === 'boolean'"
                        style="display: flex"
                      >
                        <span
                          v-if="!scope.row.isOptional"
                          style="color: #f56c6c; margin-right: 5px"
                          >*</span
                        >
                        <avue-radio
                          v-model="scope.row.property"
                          :dic="dic"
                        ></avue-radio>
                      </div>
                    </template>
                  </avue-crud>
                </div>
              </template>
            </avue-crud>
          </div>
          <!-- <div v-if="showMetaInfo">
            <span class="back" @click="showMetaInfo = false">
              <i class="el-icon-back"></i>
            </span> -->
          <!-- <meta-info :guid="guid" @edit-meta="handleMetadaEdit" @delete-meta="handleDelMetadata"></meta-info> -->
          <!-- </div> -->
          <!--<div v-if="showGroup" style="line-height: 20px;">-->
          <!--<template v-if="!!group.name">-->
          <!--<el-row>-->
          <!--<el-col >-->
          <!--<h3 class="metadata-title">{{group.name}}</h3>-->
          <!--</el-col>-->
          <!--</el-row>-->
          <!--<el-row>-->
          <!--<el-col :span="3">-->
          <!--短描述-->
          <!--</el-col>-->
          <!--<el-col :span="8">{{group.shortDescription}}</el-col>-->
          <!--</el-row>-->
          <!--<el-row>-->
          <!--<el-col :span="3">-->
          <!--长描述-->
          <!--</el-col>-->
          <!--<el-col :span="8">{{group.longDescription}}</el-col>-->
          <!--</el-row>-->
          <!--</template>-->
          <!--<template v-if="!group.name">-->
          <!--<div style="text-align: center">-->
          <!--<span>暂无数据</span>-->
          <!--</div>-->

          <!--</template>-->
          <!--</div>-->
          <!--<div v-if="!showGroup" id="metaInfo">-->
          <!--<meta-info :guid="guid"></meta-info>-->
          <!--</div>-->
        </el-card>
      </div>
    </div>
    <div
      class="context-menu"
      :style="{ top: top + 'px', left: left + 'px' }"
      v-if="visible"
      ref="mySelBox"
    >
      <el-card>
        <div class="btn" @click="addChildrenPackage">新增子目录</div>
        <div class="btn" @click="updatePackage">编辑</div>
        <div class="btn" @click="deletePackage">删除</div>
      </el-card>
    </div>

    <el-dialog
      custom-class="dialog-width"
      append-to-body
      title="元数据管理"
      :visible.sync="manageDBVisible"
    >
      <el-transfer
        v-model="dbs"
        :titles="['未加入', '已加入']"
        :props="{
          key: 'guid',
          label: 'displayText',
        }"
        :data="dblist"
      >
        <span slot-scope="{ option }">
          <el-row>
            <el-col :span="12">{{ option.displayText }}</el-col>
            <el-col
              :span="12"
              class="word-over"
              :title="option.attributes.description"
              >{{ option.attributes.description }}</el-col
            >
          </el-row>
        </span>
      </el-transfer>
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button
          size="small"
          icon="el-icon-circle-plus-outline"
          type="primary"
          @click="saveDBByCategory"
          >保存</el-button
        >
        <el-button
          size="small"
          icon="el-icon-circle-close"
          @click="manageDBVisible = false"
          >取 消</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      class="avue-dialog avue-crud__dialog"
      custom-class="dialog-width"
      @close="closeCateForm"
      append-to-body
      :title="operationTitle"
      :visible.sync="addCategoryVisible"
    >
      <avue-form ref="categoryForm" v-model="categoryForm" :option="cateOption">
        <template slot-scope="scope" slot="menuForm">
          <basic-form-button
            @submit="savePackage"
            @cancel="cancelAddPackage"
          ></basic-form-button>
        </template>
      </avue-form>
    </el-dialog>
    <el-dialog
      custom-class="dialog-width"
      append-to-body
      title="编辑"
      :visible.sync="dialogFormVisible"
    >
      <el-form :model="form">
        <template v-for="(item, index) in attr">
          <el-form-item
            :key="index"
            :label="item.label"
            :label-width="formLabelWidth"
          >
            <el-input v-model="form[item.label]" autocomplete="off"></el-input>
          </el-form-item>
        </template>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="updateAttr()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMetadataPackageList,
  savePackage,
  delPackage,
  getMetadataChildPackageList,
  getAllPackages,
  getAvailModelList,
  getModelDetail,
} from "@/api/metadata/model";
import {
  getMetaDataList,
  getGlossary,
  getMetadataDetail,
  getAudits,
  getTables,
  getTermsByCategory,
  saveMetadata,
  deleteMeta,
} from "@/api/metadata/metadata";
import { getList } from "@/api/metadata/grade";
import { getLabelTree } from "@/api/metadata/label";
import { listLabeltype } from "@/api/metadata/labeltype";
import mGraph from "./graph";
import metaInfo from "../dws/metainfo";
import dataAssetsDetail from "../data-assets/assets-detail";
import dateType from "@/config/dataType";
export default {
  components: { mGraph, metaInfo, dataAssetsDetail },
  data() {
    return {
      searchVal: "",
      search: {},
      showMetaInfo: false,
      displayName: "",
      datasourceName: null,
      dic: [
        {
          label: "否",
          value: false,
        },
        {
          label: "是",
          value: true,
        },
      ],
      datasourceId: null,
      isEdit: false,
      propertyForm: {},
      propertyList: [],
      propertyOption: {
        editBtn: false,
        delBtn: false,
        header: false,
        menu: false,
        column: [
          {
            label: "属性名称",
            prop: "name",
          },
          {
            label: "类型",
            prop: "typeName",
            type: "select",
            dicData: dateType,
          },
          {
            label: "描述",
            prop: "description",
            type: "textarea",
          },
          {
            label: "属性值",
            prop: "property",
          },
        ],
      },
      props: {
        label: "name",
        value: "id",
      },
      modelLoading: false,
      modelList: [],
      modelTreeData: [],
      modelPackageTreeData: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      modelOption: {
        searchMenuSpan: 5,
        editBtn: false,
        delBtn: false,
        addBtnText: "新增元数据",
        column: [
          {
            label: "名称",
            prop: "displayText",
            display: false,
          },
          {
            label: "负责人",
            prop: "owner",
            display: false,
          },
          {
            label: "描述",
            prop: "description",
            display: false,
          },
          {
            label: "类型",
            prop: "typeName",
            display: false,
          },
        ],
        group: [
          {
            labelWidth: 110,
            column: [
              {
                label: "名称",
                prop: "name",
                rules: [
                  {
                    required: true,
                    message: "请输入名称",
                    trigger: "blur",
                  },
                ],
              },
              {
                label: "代码",
                prop: "code",
                rules: [
                  {
                    required: true,
                    message: "请输入代码",
                    trigger: "blur",
                  },
                ],
                editDisabled: true,
              },
              {
                label: "标签",
                prop: "labels",
                type: "tree",
                multiple: true,
                props: {
                  label: "name",
                  value: "id",
                },
                dicData: [],
              },
              {
                label: "分级",
                prop: "grade",
                type: "tree",
                props: {
                  label: "name",
                  value: "id",
                },
                dicData: [],
              },
              {
                label: "分类",
                prop: "classification",
                type: "tree",
                props: {
                  label: "name",
                  value: "id",
                },
                dicData: [],
              },
              {
                label: "敏感",
                prop: "isSensitived",
                type: "radio",
                dicData: [
                  { label: "是", value: "1" },
                  { label: "否", value: "0" },
                ],
              },
              {
                label: "描述",
                prop: "remark",
                type: "textarea",
                minRows: 2,
                span: 24,
              },
              {
                label: "元模型",
                prop: "modelPackageId",
                span: 24,
              },
            ],
          },
        ],
      },
      currentData: null,
      guid: "",
      showMetaTree: false,
      showMeta: false,
      showGraph: false,
      operationTitle: "新增",
      manageDBVisible: false,
      dblist: [],
      dbs: [],
      addCategoryVisible: false,
      top: 0,
      left: 0,
      visible: false,
      showFields: false,
      categoryForm: {
        category: 2,
      },
      cateOption: {
        size: "small",
        menuPosition: "right",
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "代码",
            prop: "code",
            rules: [
              {
                required: true,
                message: "请输入代码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "名称",
            prop: "name",
            rules: [
              {
                required: true,
                message: "请输入名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "描述",
            prop: "remark",
            type: "textarea",
            minRows: 2,
            span: 24,
          },
        ],
      },
      rules: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
      },
      group: {
        name: "",
        shortDescription: "",
        longDescription: "",
      },
      showGroup: true,
      dialogFormVisible: false,
      formLabelWidth: "100px",
      input2: "",
      fields: [],
      data: [],
      defaultProps: {
        children: "children",
        label: "name",
        isLeaf: "isLeaf",
      },
      activeName: "property",
      entityAttr: {
        name: "",
        owner: "",
        description: "",
      },
      dyAttrs: [],
      entity: {
        createTime: "",
        updateTime: "",
        typeName: "",
      },
      classifications: [],
      audits: [],
      relations: [],
      attr: [],
      form: {},
      modelForm: {
        parentId: "",
        modelId: "",
        modelPackageId: "",
      },
      rtSelectedNode: {},
      termId: "",
      isPkgEdit: false,
      isAddChild: false,
      packageMap: {},
      currentPkgId: "",
      currentPkgNode: "",
      modelMap: {},
      currentModelCode: "",
      currentCategoryId: "",
    };
  },
  computed: {},
  mounted() {
    this.getTreeList("classification");
    document.removeEventListener("click", this.outClick);
    this.$nextTick(function () {
      document.addEventListener("click", this.outClick);
    });
    this.getAllPackageList();
    this.getGradeList();
    this.getLabelList();
    this.getClassfication();
  },
  beforeDestroy() {
    document.removeEventListener("click", this.outClick);
  },
  methods: {
    beforeClose(done) {
      let _t = this;
      _t.propertyList = [];
      _t.modelForm.name = "";
      _t.modelForm.code = "";
      _t.modelForm.remark = "";
      _t.modelForm.modelId = "";
      _t.modelForm.modelPackageId = "";
      _t.modelForm.classification = "";
      _t.modelForm.grade = "";
      _t.modelForm.labels = [];
      _t.isEdit = false;
      //this.$refs.crud.resetFields();
      done();
    },
    getGradeList() {
      getList().then((res) => {
        this.modelOption.group[0].column[3].dicData =
          res["data"]["data"]["records"];
      });
    },
    getLabelList() {
      getLabelTree().then((res) => {
        this.modelOption.group[0].column[2].dicData = this.translateTreeData(
          res["data"]["children"] || []
        );
      });
    },
    translateTreeData(treeData) {
      let newTreeData = [];
      treeData.forEach((item) => {
        let el = {
          ...item.label,
        };
        if (item.children && item.children.length) {
          el["children"] = this.translateTreeData(item["children"]);
        }
        newTreeData.push(el);
      });
      return newTreeData;
    },
    getClassfication() {
      listLabeltype().then((res) => {
        this.modelOption.group[0].column[4].dicData =
          res["data"]["data"]["records"];
      });
    },
    getAllPackageList() {
      getAllPackages().then((res) => {
        this.modelPackageTreeData = res.data.data.concat([]);
        this.handleModelMap(this.modelPackageTreeData);
      });
    },
    showMetaDetail(row) {
      this.guid = row["guid"];
      this.showMetaInfo = true;
      this.displayName = row["displayText"];
    },
    handleDelMetadata(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        deleteMeta({
          guid: row.guid,
        })
          .then((res) => {
            if (res["data"]["code"] === 200) {
              this.$message.success("删除成功");
              this.showMetaInfo = false;
              this.manageDB();
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((e) => {
            // this.$message.error(e.msg || '')
          });
      });
    },
    handleMetadaUpdate(row, index, done, loading) {
      let attr = {
        name: row["name"] || "",
        qualifiedName: row["code"] || "",
        description: row["remark"] || "",
      };
      let propertyMap = {};
      let isAllRequireHasValue = true;
      let tempName = "";
      this.propertyList.forEach((item) => {
        propertyMap[item["name"]] = item["property"];
        if (
          !item["isOptional"] &&
          (item["property"] === undefined ||
            item["property"] === "" ||
            item["property"] === null)
        ) {
          isAllRequireHasValue = false;
          tempName = item["name"];
        }
      });
      if (!isAllRequireHasValue) {
        this.$message.error(`${tempName}属性值必填`);
        loading();
        return;
      }
      let obj = {
        categoryId: this.currentCategoryId,
        entity: {
          entity: {
            attributes: {
              ...attr,
              ...propertyMap,
            },
            guid: row["guid"],
            typeName: row["typeName"],
            customAttributes: {
              grade: row["grade"],
              modelPkgId: this.modelForm.modelPackageId,
              modelId: this.modelForm.modelId,
              classification: row["classification"],
              isSensitived: row["isSensitived"],
              lables: this.modelForm.labels.join(","),
              parentguid: this.currentData.atlasGuid,
            },
            labels: this.modelForm.labels,
          },
        },
      };
      saveMetadata(obj)
        .then((res) => {
          if (res["data"]["code"] === 200) {
            done();
            this.getMetadataList();
          }
        })
        .catch((res) => {
          loading();
        });
    },
    searchChange(params, done) {
      console.log(this.search);
      done();
      this.getMetadataList();
    },
    handleMetadataSave(row, done, loading) {
      let attr = {
        name: row["name"],
        qualifiedName: row["code"],
        description: row["remark"],
      };
      let propertyMap = {};
      let isAllRequireHasValue = true;
      let tempName = "";
      this.propertyList.forEach((item) => {
        propertyMap[item["name"]] = item["property"];
        if (
          !item["isOptional"] &&
          (item["property"] === undefined ||
            item["property"] === "" ||
            item["property"] === null)
        ) {
          isAllRequireHasValue = false;
          tempName = item["name"];
        }
      });
      if (!isAllRequireHasValue) {
        this.$message.error(`${tempName}属性值必填`);
        loading();
        return;
      }
      let obj = {
        categoryId: this.currentCategoryId,
        entity: {
          entity: {
            attributes: {
              ...attr,
              ...propertyMap,
            },
            guid: -1,
            typeName: this.currentModelCode,
            customAttributes: {
              grade: row["grade"],
              modelPkgId: this.modelForm.modelPackageId,
              modelId: this.modelForm.modelId,
              classification: row["classification"],
              isSensitived: row["isSensitived"],
              lables: this.modelForm.labels.join(","),
              parentguid: this.currentData.atlasGuid,
            },
            labels: this.modelForm.labels,
          },
        },
      };
      saveMetadata(obj)
        .then((res) => {
          if (res["data"]["code"] === 200) {
            done();
            this.getMetadataList();
          }
        })
        .catch((res) => {
          loading();
        });
    },
    handleModelMap(arr) {
      arr.forEach((item) => {
        this.packageMap[item["id"]] = item;
        if (item.children && item.children.length > 0) {
          this.handleModelMap(item.children);
        }
      });
    },
    saveDBByCategory() {
      this.manageDBVisible = true;
    },
    manageAllDB() {
      this.modelLoading = true;
      let param = {
        excludeDeletedEntities: true,
        includeSubClassifications: true,
        includeSubTypes: true,
        includeClassificationAttributes: true,
        entityFilters: null,
        tagFilters: null,
        attributes: [],
        limit: this.page.pageSize,
        query: this.search["displayText"] || null,
        offset: (this.page.currentPage - 1) * this.page.pageSize,
        typeName: "_ALL_ENTITY_TYPES",
        classification: null,
        termName: null,
      };
      getMetaDataList(param).then((res) => {
        this.modelList = res["data"]["data"]["entities"];
        this.modelLoading = false;
        this.page.total = res["data"]["data"]["approximateCount"];
      });
    },
    manageDB() {
      this.modelLoading = true;
      let param = {
        excludeDeletedEntities: true,
        includeSubClassifications: true,
        includeSubTypes: true,
        includeClassificationAttributes: true,
        tagFilters: null,
        attributes: [],
        limit: this.page.pageSize,
        offset: (this.page.currentPage - 1) * this.page.pageSize,
        query: this.searchVal ? `*${this.searchVal}*` : null,
        typeName: "_ALL_ENTITY_TYPES",
        classification: null,
        // "termName":this.currentCategoryId,
        entityFilters:
          this.currentData && this.currentData.atlasGuid
            ? {
                criterion: [
                  {
                    attributeName: "__customAttributes",
                    attributeValue: `parentguid=${this.currentData.atlasGuid}`,
                    operator: "CONTAINS",
                  },
                ],
                condition: "AND",
              }
            : null,
      };
      getMetaDataList(param).then((res) => {
        this.modelList = res["data"]["data"]["entities"];
        this.modelLoading = false;
        this.page.total = res["data"]["data"]["approximateCount"];
      });
    },
    outClick(e) {
      if (this.$refs.mySelBox && !this.$refs.mySelBox.contains(e.target)) {
        this.visible = false;
      }
    },
    transformDate(time) {
      if (!time) return "-";
      let date = new Date(time);
      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDay() < 10 ? "0" + date.getDay() : date.getDay();
      let hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    filterTree() {
      this.getTreeList();
    },
    addCategory() {
      this.operationTitle = "新增一级目录";
      this.addCategoryVisible = true;
    },
    updateCategory() {
      this.operationTitle = "编辑目录";
      this.addCategoryVisible = true;
    },
    addSubCategory() {
      this.operationTitle = "新增子目录";
      this.addCategoryVisible = true;
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    changePackage(ev) {
      if (!ev) return;
      this.currentPkgId = ev;
      this.currentPkgNode = this.packageMap[ev]["code"];
      this.getModels();
    },
    changeModel(ev) {
      if (!ev) return;
      this.getModelDetail(ev);
      this.currentModelCode = this.modelMap[ev];
    },
    getModels() {
      let obj = {
        parentId: this.currentPkgId,
        code: this.currentPkgNode,
      };
      getAvailModelList(obj).then((res) => {
        if (res["data"]["code"] === 200) {
          this.modelTreeData = res["data"]["data"];
          this.modelTreeData.forEach((item) => {
            this.modelMap[item["id"]] = item["code"];
          });
        }
      });
    },
    getModelDetail(id) {
      getModelDetail(id).then((res) => {
        if (res["data"]["code"] === 200) {
          if (
            res["data"]["data"]["source"] &&
            res["data"]["data"]["source"] !== ""
          ) {
            let model = JSON.parse(res["data"]["data"]["source"]);
            this.propertyList = model["attributeDefs"];
            this.propertyList.forEach((item) => {
              if (item["typeName"] === "date") {
                item["property"] = new Date().getTime();
              }
            });
            if (this.isEdit) {
              this.propertyList.forEach((item) => {
                item["property"] = this.entityAttr[item["name"]];
              });
            }
          }
        }
      });
    },
    addChildrenPackage() {
      this.isAddChild = true;
      this.isPkgEdit = false;
      this.operationTitle = "新增";
      this.addCategoryVisible = true;
    },
    addPackage() {
      this.isAddChild = false;
      this.isPkgEdit = false;
      this.operationTitle = "新增";
      this.addCategoryVisible = true;
    },
    updatePackage() {
      this.isAddChild = false;
      this.isPkgEdit = true;
      this.operationTitle = "编辑";
      this.categoryForm["code"] = this.rtSelectedNode.code;
      this.categoryForm["name"] = this.rtSelectedNode.name;
      this.categoryForm["parentId"] = this.rtSelectedNode.parentId;
      this.categoryForm["remark"] = this.rtSelectedNode.remark;
      this.addCategoryVisible = true;
    },
    deletePackage() {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delPkg();
      });
    },
    delPkg() {
      delPackage({
        ids: this.rtSelectedNode.id,
      }).then((res) => {
        if (res["data"]["code"] === 200) {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.getTreeList();
        }
      });
    },
    getMetadataList() {
      this.manageDB();
      // if(this.currentData && this.currentData.nodeType === 'datebase'){
      // }
      // if(this.currentCategoryId === 'all'){
      //   this.manageAllDB();
      // } else {
      //   if(!!this.termId){
      //     this.manageDB();
      //     // getTables(this.termId).then( res => {
      //     //   this.modelList = res['data']['assignedEntities'];
      //     // });
      //   } else {
      //     this.modelList = [];
      //   }
      // }
    },
    closeCateForm() {
      this.$refs.categoryForm.resetForm();
      this.categoryForm.category = 2;
      this.isAddChild = false;
    },
    savePackage(done) {
      this.$refs.categoryForm.validate((vaild) => {
        if (vaild) {
          let obj = this.categoryForm;
          if (this.isPkgEdit) {
            obj = {
              id: this.rtSelectedNode["id"],
              ...this.categoryForm,
            };
          }
          if (this.isAddChild) {
            obj["parentId"] = this.rtSelectedNode["id"];
          }
          savePackage(obj)
            .then((res) => {
              done();
              if (res["data"]["code"] === 200) {
                this.addCategoryVisible = false;
                this.getTreeList();
              } else {
                this.$message.error(res.data.msg);
              }
              this.$refs.categoryForm.allDisabled = false;
            })
            .catch((e) => {
              done();
              this.$refs.categoryForm.allDisabled = false;
            });
        }
      });
    },
    closePackageForm(done) {
      this.$refs.categoryForm.resetForm();
    },
    cancelAddPackage(done) {
      this.addCategoryVisible = false;
      done();
    },

    updateAttr() {
      this.dialogFormVisible = false;
    },
    handleEdit(row) {
      this.attr = row["attr"];
      this.attr.forEach((item) => {
        this.$set(this.form, item.label, "");
      });
      this.dialogFormVisible = true;
    },
    handleDelete() {},
    getTreeList() {
      this.showMetaTree = true;
      getMetadataPackageList(this.input2).then((res) => {
        if (res["data"]["code"] === 200) {
          this.data = [
            // {
            //   'name':'全部',
            //   'id':'all'
            // }
          ];
          if (res["data"]["data"].length > 0) {
            this.data = this.data.concat(res["data"]["data"]);
            let defaultId = this.data[0]["id"];
            this.currentData = this.data[0];
            this.currentCategoryId = defaultId;
            this.termId = this.data[0]["outCode"];
            this.getMetadataList();
            // this.currentPkgNode = this.data[0]['code'];
            // if(this.currentPkgId) {
            //   defaultId = this.currentPkgId
            // } else {
            //   this.currentPkgId = defaultId;
            // }
            // this.modelForm.parentId = defaultId;
            this.$nextTick(() => {
              this.$refs.tree.setCurrentKey(defaultId); //获取已经设置的资源后渲染
            });
          }
        }
        this.showMetaTree = false;
      });
    },
    handleTree(arr) {
      let temp = [];
      for (let i = 0, len = arr.length; i < len; i++) {
        let item = arr[i];
        if (item["superTypes"].length === 0 && item["subTypes"].length === 0) {
          temp.push(item);
        } else if (
          item["superTypes"].length === 0 &&
          item["subTypes"].length > 0
        ) {
          item["children"] = [];
          this.getChildren(arr, item);
          temp.push(item);
        }
      }
      return temp;
    },
    getChildren(arr, item) {
      let _t = this;
      for (let j = 0, jlen = item["subTypes"].length; j < jlen; j++) {
        let child = item["subTypes"][j];
        let it = arr.find((el) => el.name === child);
        if (it["subTypes"].length > 0) {
          it["children"] = [];
          _t.getChildren(arr, it);
        } else {
          item["children"].push(it);
        }
      }
    },
    handleClick(tab) {
      if (tab["name"] === "graph") {
        this.showGraph = true;
      }
    },
    contextMenuOperation(ev, data) {
      this.rtSelectedNode = data;
      this.visible = true;
      this.top = ev["y"];
      this.left = ev["x"];
    },
    handleMetadaEdit(row) {
      let _t = this;
      this.isEdit = true;
      getMetadataDetail(row["guid"]).then((res) => {
        if (res["status"] === 200) {
          let entity = res["data"]["entity"];
          this.entityAttr = res["data"]["entity"]["attributes"];
          _t.$refs.crud.rowEdit(row);
          setTimeout(() => {
            _t.modelForm.name = row["attributes"]["name"];
            _t.modelForm.code = row["attributes"]["qualifiedName"];
            _t.modelForm.remark = row["attributes"]["description"];
            _t.modelForm.labels = entity["labels"] || [];
            if (!!entity["customAttributes"]) {
              _t.modelForm.modelId = entity["customAttributes"]["modelId"];
              _t.modelForm.modelPackageId =
                entity["customAttributes"]["modelPkgId"];
              _t.modelForm.classification =
                entity["customAttributes"]["classification"];
              _t.modelForm.grade = entity["customAttributes"]["grade"];
            }
          }, 0);
        }
      });
      // this.catalogForm.databaseType = row['globalParamMap']['datasourcetypekey'];
      //  this.datasourceTypeChange(row);
      // this.catalogForm.version = row['globalParamMap']['version'];
    },
    getMetadataDetail(id) {
      getMetadataDetail(id).then((res) => {
        if (res["status"] === 200) {
          this.entity = res["data"]["entity"];
          this.entityAttr = res["data"]["entity"]["attributes"];
        }
      });
    },
    nodeClick(item) {
      this.showMetaInfo = false;
      this.termId = item["outCode"];
      this.currentData = item;
      this.datasourceId = item.datasourceId || null;
      this.datasourceName = item.datasourceName || null;
      this.currentCategoryId = item["id"];
      this.page.currentPage = 1;
      this.getMetadataList();
      // this.showModelList = true;
      // // this.showModelList = !!data['isPkg'];
      // //
      // // if(this.showModelList){
      // this.currentPkgId = data['id'];
      // this.currentPkgNode = data['code'];
      // // 给新增模型的所属包默认值
      // this.modelForm.parentId = data['id'];
      // this.currentPackageName = data['name'];
      // } else {
      //   this.currentModelName = data['name'];
      //   this.currentModelId = data['id'];
      //   this.dyAttrs[0].value = data['code'];
      //   this.getModelDetail(data['id']);
      //   this.getVersions();
      // }
      // if(!!item['typeName']){
      //   this.showFields = item['typeName'] === 'Table';
      //   let guid = item['guid'];
      //   this.guid = guid;
      //   this.showGroup = false;
      // } else {
      //   this.showGroup = true;
      //   if(item['categoryGuid']){
      //     getTermsByCategory(item['categoryGuid']).then( res => {
      //       if(res['status'] === 200 ) {
      //         this.group = res['data'];
      //       }
      //     });
      //   } else {
      //     this.group = item;
      //   }
      //
      // }
    },
    handleNodeClick(item, resolve) {
      if (!item["data"]["id"]) return;
      getMetadataChildPackageList({
        parentId: item["data"]["id"],
        name: this.input2,
      }).then((res) => {
        let data = res["data"]["data"].map((item) => {
          return {
            ...item,
            isLeaf: item["nodeType"] === "datebase" ? true : false,
          };
        });
        resolve(data);
      });
    },
  },
};
</script>

<style lang="scss">
/*.el-dialog__header {*/
/*padding: 16px 24px;*/
/*min-height: 20px;*/
/*border-bottom: 1px solid #f0f0f0;*/
/*}*/
/*.el-dialog__body {*/
/*padding: 20px 20px 5px 10px;*/
/*}*/
.display-flex {
  display: flex;
}
.flex-1 {
  flex: 1;
}
.manage-meta {
  position: relative;
  a {
    color: #337ab7 !important;
    cursor: pointer;
  }
  .context-menu {
    position: fixed;
    padding: 0;
    width: 132px;
    height: 142px;
    border-radius: 4px;
    border: 1px solid #ebeef5;
    .btn {
      cursor: pointer;
    }
  }
  .basic-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .meta-height {
    height: calc(100vh - 124px) !important;
    overflow: auto;
  }
  .meta-container {
    width: calc(79.16667% - 8px);
    margin-left: 8px;
  }
  .relation-select {
    display: flex;
    justify-content: space-between;
    .el-select {
      width: 45% !important;
    }
  }
  .back {
    cursor: pointer;
    font-size: 25px;
  }
}
.word-over {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.add-btn {
  height: 28px;
  margin-bottom: 10px;
  button {
    float: right;
  }
}
.metadata-title {
  margin-top: 0;
  font-size: 24px;
}
.dialog-width {
  width: 60% !important;
}
.property-tags .el-tag + .el-tag {
  margin-left: 10px;
}
.col {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  float: left;
}
.innerTable td {
  border: none;
}
.w-100p {
  width: 100%;
}
.operation {
  cursor: pointer;
}
.mar-l-10 {
  margin-left: 10px;
}
.matate-left {
  position: fixed;
  left: 0;
  top: 120px;
  margin-right: 240px;
  height: 100%;
  z-index: 1025;
}
.el-dialog__body {
  .el-transfer {
    display: -webkit-box;
    display: -ms-flexbox;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
  }
}
</style>

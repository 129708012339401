<template>
  <div>
    <DataView v-if="init" @save="saveDash" :dashJson="dash" namespaces="dataView"></DataView>
    <el-dialog
      title="保存视图"
      :visible.sync="dialogVisible"
      width="30%"
      append-to-body
    >
      <el-form ref="form" :model="form" size="small" label-width="80px">
        <el-form-item label="视图名称" prop="name">
          <el-input v-model="form.name"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button v-loading="formLoading" @click="dialogVisible = false">取 消</el-button>
        <el-button v-loading="formLoading" type="primary" @click="saveData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

// import AutoLayout from '@/components/auto-layout/main';

import DataView from '@/data-view/main';

import {
  saveDynamicForm,
  getDetailByPlanId
} from '@/api/busiMode/meta.js'

import { DataViewConnector } from '../../data-view'

import * as DataViewService from '@/api/redash/index.js'

import { RedashService ,  StaticDataService} from './register';



export default {
  components: {
    // AutoLayout,
    DataView
  },
  data(){
    return {
      widgets: [],
      dialogVisible: false,
      form: {
        code: '',
        appId: '',
        appModelId: '',
        name: '',
        type: 'V',
        content: ''
      },
      formLoading: false,
      id: this.$router.currentRoute.params.id,
      type: this.$router.currentRoute.params.type,
      dash: null,
      init: false
    }
  },
  created(){
  },
  mounted(){
    DataViewConnector.init('dataView');
    DataViewConnector.Datasource.register({
      type: 'staticData',
      name: '静态数据',
      id: 'staticData',
      optionList: [
        {name: '数据类型' , code: 'staticDataType' , value: 'json' , hide: true},
        {
          name: '静态数据' ,
          code: 'staticData' ,
          value: JSON.stringify([
            {name: '周一' , bug: 30 , task: 200 , num: 10 , works: 80},
            {name: '周二' , bug: 10 , task: 150 , num: 6 , works: 60},
            {name: '周三' , bug: 15 , task: 180 , num: 8 , works: 64},
            {name: '周四' , bug: 3 , task: 50 , num: 3 , works: 30},
            {name: '周五' , bug: 25 , task: 100 , num: 4 , works: 28},] , null , 2),
          type: 'textarea' ,
          props: { rows: 5 }
        }
      ]
    })
    DataViewConnector.RequestService.register([StaticDataService , RedashService(DataViewService)])
    DataViewService.getDatasourceList().then(
      res => {
        let datasource = [];
        (res.data || []).forEach(
          item => {
            let ds = {
              id: `RedashDatasource-${item.name}-${item.id}`,
              type: 'RedashDatasource',
              name: item.name,
              optionList: [
                {name: '表达式' , code: 'queryString' , value: 'select * from users' , type: 'textarea' , props: { rows: 5 } }
              ]
            }
            Object.keys(item).forEach(
              key => {
                ds.optionList.push({name: key , code: key , value: item[key] , hide: true})
              }
            )
            datasource.push(ds)
          }
        )
        DataViewConnector.Datasource.register(datasource)
        if(this.id){
          this.getDetailByPlanId();
        }else{
          this.init = true;
        }
      }
    )
  },
  methods: {
    saveDash(dash){
      console.log(dash)
      this.dialogVisible = true;
      this.form.content = JSON.stringify(dash);
    },
    saveData(){
      this.formLoading = true;
      saveDynamicForm({
        ...this.form
      }).then(
        res => {
          if(res.data.code === 200){
            this.dialogVisible = false;
            this.$router.back();
          }
        }
      )
    },
    getDetailByPlanId(){
      getDetailByPlanId({
        id: this.id
      }).then(
        res => {
          this.form = res.data.data;
          this.dash = JSON.parse(this.form.content);
          console.log(this.dash);
          this.init = true;
        }
      )
    }
  }
}
</script>
<template>
  <div>
    <el-tabs v-model="search.ACTIVENAME" @tab-click="tabsHandleClick">
      <el-tab-pane v-for="item of tabs" :label="item.dictValue" :name="item.dictKey"></el-tab-pane>
    </el-tabs> 
    <avue-crud
      v-if="init"
      ref="table"
      :data="tableData"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      :search.sync="search"
      v-model="form"
      @search-change="searchChange"
      @on-load="onLoad"
      @refresh-change="onLoad"
      @row-save="saveTableForm"
      @row-update="(row, index, done, loading) => saveTableForm(row, done, loading)"
      @row-del="rowDel"
    >
      <template slot="menu" slot-scope="scope">
        <el-button
          type="text"
          size="small"
          icon="el-icon-location"
          @click="cfztd(scope.row)"
          v-if="scope.row.status === 3"
          >存放自提点
        </el-button>
        <el-button
          type="text"
          size="small"
          icon="el-icon-finished"
          @click="sign(scope.row)"
          v-if="scope.row.status === 3 || scope.row.status === 4"
          >签收
        </el-button>
        <el-button
          type="text"
          size="small"
          icon="el-icon-s-order"
          @click="sorting(scope.row)"
          v-if="scope.row.status < 3"
          >分拣
        </el-button>
        <el-button
          type="text"
          size="small"
          icon="el-icon-link"
          @click="delivery(scope.row)"
          v-if="scope.row.status === 2"
          >关联配送
        </el-button>
      </template>
      <template slot="express_company_idForm" slot-scope="{disabled,size}">
        <el-select :size="size" v-model="form.express_company_id" :disabled="disabled" @change="selectChange(dicData.express_company , 'express_company_id' , 'id' , [{target: 'express_company' , source: 'name'}])">
          <el-option v-for="item of dicData.express_company" :label="item.name" :value="item.id" :key="item.id"></el-option>
        </el-select>
      </template>
      <template slot="delivery_idForm" slot-scope="{disabled,size}">
        <el-select :size="size" v-model="form.delivery_id" :disabled="disabled" @change="selectChange(dicData.delivery , 'delivery_id' , 'id' , [{target: 'delivery' , source: 'deliveryName'} , {target: 'delivery_tel' , source: 'deliveryPhone'}])">
          <el-option v-for="item of dicData.delivery" :label="item.deliveryName" :value="item.id" :key="item.id"></el-option>
        </el-select>
      </template>
      <template slot="pickupForm" slot-scope="{disabled,size}">
        <model-search-select
          :disabled="disabled"
          class="dis-b w-100p"
          model="wp_address_label"
          :params="{
            type: 2
          }"
          v-model="form.pickup_id"
          labelKey="name"
          :fillModel.sync="form"
          :fills="[{ source: 'name', target: 'pickup' }]"
        ></model-search-select>
      </template>
      <template slot="address_label_idSearch" slot-scope="{disabled,size}">
        <model-search-select
          :disabled="disabled"
          class="dis-b w-100p"
          model="wp_address_label"
          v-model="search.address_label_id"
          labelKey="name"
        ></model-search-select>
      </template>
    </avue-crud>
    <el-dialog width="500px" title="分拣" :visible.sync="sortingVisible">
      <el-form v-if="sortingVisible" size="small" :model="dialogForm.form" label-width="100px">
        <el-form-item label="收件人">
          {{dialogForm.row.receiver}}
        </el-form-item>
        <el-form-item label="收件人电话">
          {{dialogForm.row.receiver_tel}}
        </el-form-item>
        <el-form-item label="配送地址">
          {{dialogForm.row.receiv_address}}
        </el-form-item>
        <el-form-item label="地址标签" prop="address_label_id">
          <model-search-select
            class="dis-b w-100p"
            model="wp_address_label"
            v-model="dialogForm.form.address_label_id"
            labelKey="name"
            :fillModel.sync="dialogForm.form"
            :fills="[{ source: 'name', target: 'address_label' }]"
          >
            <template slot-scope="{ item }">
              <span style="display: block;line-height: 24px;">{{item.name}}</span>
              <span class="mar-b-5" style="color: #8492a6; font-size: 12px;line-height: 14px;font-weight: normal;display: block;">详细：{{item.note}}</span>
            </template>
          </model-search-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button :loading="dialogForm.saving" size="small" @click="sortingVisible = false">关 闭</el-button>
        <el-button :loading="dialogForm.saving" size="small" type="primary" @click="dialogFormSubmit">提 交</el-button>
      </div>
    </el-dialog>
    <el-dialog width="500px" title="关联配送单" :visible.sync="deliveryVisible">
      <el-form v-if="deliveryVisible" size="small" :model="dialogForm.form" label-width="100px">
        <el-form-item label="收件人">
          {{dialogForm.row.receiver}}
        </el-form-item>
        <el-form-item label="收件人电话">
          {{dialogForm.row.receiver_tel}}
        </el-form-item>
        <el-form-item label="配送地址">
          {{dialogForm.row.receiv_address}}
        </el-form-item>
        <el-form-item label="配送单" prop="address_label_id">
          <model-search-select
            class="dis-b w-100p"
            model="wp_delivery_bill"
            v-model="dialogForm.form.delivery_order_id"
            labelKey="name"
            :fillModel.sync="dialogForm.form"
            :fills="[
              { source: 'delivery_id', target: 'delivery_id' },
              { source: 'delivery_tel', target: 'delivery_tel' },
              { source: 'delivery', target: 'delivery' }
            ]"
          >
          </model-search-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button :loading="dialogForm.saving" size="small" @click="deliveryVisible = false">关 闭</el-button>
        <el-button :loading="dialogForm.saving" size="small" type="primary" @click="dialogFormSubmit">提 交</el-button>
      </div>
    </el-dialog>
    <el-dialog width="500px" title="存放自提点" :visible.sync="cfztdVisible">
      <el-form v-if="cfztdVisible" size="small" :model="dialogForm.form" label-width="100px">
        <el-form-item label="收件人">
          {{dialogForm.row.receiver}}
        </el-form-item>
        <el-form-item label="收件人电话">
          {{dialogForm.row.receiver_tel}}
        </el-form-item>
        <el-form-item label="配送地址">
          {{dialogForm.row.receiv_address}}
        </el-form-item>
        <el-form-item label="自提点" prop="address_label_id">
          <model-search-select
            class="dis-b w-100p"
            model="wp_address_label"
            v-model="dialogForm.form.pickup_id"
            labelKey="name"
            :params="{
              type: 2
            }"
            :fillModel.sync="dialogForm.form"
            :fills="[{ source: 'name', target: 'pickup' }]"
          >
            <template slot-scope="{ item }">
              <span style="display: block;line-height: 24px;">{{item.name}}</span>
              <span class="mar-b-5" style="color: #8492a6; font-size: 12px;line-height: 14px;font-weight: normal;display: block;">详细：{{item.note}}</span>
            </template>
          </model-search-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button :loading="dialogForm.saving" size="small" @click="cfztdVisible = false">关 闭</el-button>
        <el-button :loading="dialogForm.saving" size="small" type="primary" @click="dialogFormSubmit">提 交</el-button>
      </div>
    </el-dialog>
    <el-dialog width="300px" title="签收" :visible.sync="signVisible">
      <el-form v-if="signVisible" size="small" :model="dialogForm.form">
        <el-form-item label="签收时间" prop="sign_time">
          <el-date-picker
          v-model="dialogForm.form.sign_time"
          type="datetime"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="选择日期时间">
        </el-date-picker>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button :loading="dialogForm.saving" size="small" @click="signVisible = false">关 闭</el-button>
        <el-button :loading="dialogForm.saving" size="small" type="primary" @click="dialogFormSubmit">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getMetaCommonList,
  delMetaCommon,
  getMetaCommonListExport,
  saveMetaCommon,
  updateMetaCommon,
} from "@/api/busiMode/metaCommon";
import * as dictService from "@/api/system/dictbiz";
import { getCarrierPage } from "@/api/spcod/xpscarrier";
import { getDeliveryMan } from '@/api/spcod/xpsexpressorder';
export default {
  data(){
    return {
      sortingVisible: false,
      deliveryVisible: false,
      cfztdVisible: false,
      signVisible: false,
      dialogForm: {
        saving: false,
        form: {},
        row: null
      },
      form: {
        express_company: '',
        picker: ''
      },
      model: 'wisdom_park.wp_receiv_express_bill',
      tableLoading: false,
      tableData: [],
      search: {
        ACTIVENAME: 'all'
      },
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableOption: {
        height: 'auto',
        labelPosition: 'top',
        searchMenuPosition: 'right',
        searchLabelPosition: 'right',
        searchMenuSpan: 24,
        border: true,
        viewBtn: true,
        menuWidth: 330,
        column: [
          {
            label: "快递单号",
            prop: "express_number",
            span: 12,
            search: true,
            searchSpan: 6,
            width: 150,
          },
          {
            label: "快递公司",
            prop: "express_company_id",
            span: 12,
            search: true,
            searchSpan: 6,
            width: 150,
            type: 'select',
            dicData: [],
            props: {
              label: "name",
              value: "id",
            }
          },
          {
            label: "地址标签",
            prop: "address_label",
            span: 12,
            display: false
          },
          {
            label: "地址标签",
            prop: "address_label_id",
            span: 12,
            display: false,
            hide: true,
            showColumn: false,
            search: true
          },
          {
            label: "收件人",
            prop: "receiver",
            span: 12,
            search: true,
            searchLike: true
          },
          {
            label: "收件人联系方式",
            prop: "receiver_tel",
            span: 12,
            width: 200,
          },
          {
            label: "收件地址",
            prop: "receiv_address",
            width: 200,
            span: 24,
            type: 'textarea',
            search: true,
            searchLike: true
          },
          {
            label: "寄件人",
            prop: "sender",
            span: 12,
            search: true,
            searchLike: true
          },
          {
            label: "寄件人联系方式",
            prop: "sender_tel",
            span: 12,
            width: 200,
          },
          {
            label: "寄件地址",
            prop: "send_address",
            width: 200,
            span: 24,
            type: 'textarea'
          },
          {
            label: "配送员",
            prop: "delivery",
            span: 12,
            width: 150,
            // dicData: [],
            display: false,
            viewDisplay: true,
            // props: {
            //   label: "deliveryName",
            //   value: "id",
            // }
          },
          {
            label: "取件人联系方式",
            prop: "delivery_tel",
            span: 12,
            width: 200,
            display: false,
            viewDisplay: true,
          },
          {
            label: "取件码",
            prop: "verification_code",
            span: 12,
            display: false,
            search: true
          },
          {
            label: "配送方式",
            prop: "delivery_type",
            span: 12,
            search: true,
            type: 'select',
            dicData: [
              { label: "上门配送", value: 1},
              { label: "存放自提点", value: 2}
            ] 
          },
          {
            label: "存放地址",
            prop: "pickup",
            span: 12,
          },
          {
            label: "状态",
            prop: "status",
            span: 12,
            type: "select",
            dicUrl: dictService.getUrl("ReceivExpressStatus"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: 'number'
          },
          {
            label: "签收时间",
            prop: "sign_time",
            span: 12,
            width: 150,
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            format: 'yyyy-MM-dd HH:mm:ss'
          },
          {
            label: "备注",
            prop: "memo",
            width: 200,
            span: 24,
            type: 'textarea'
          },
        ]
      },
      init: false,
      tabs: [],
      dicData: {
        express_company: [],
        delivery: []
      }
    }
  },
  mounted(){
    this.getTabs();
    this.getCarrierPage();
    // this.getDeliveryMan();
  },
  methods: {
    getTabs(){
      dictService.getDictionary({
        code: 'ReceivExpressStatus'
      }).then(
        res => {
          this.tabs = [
            {dictValue: '全部' , dictKey: 'all'},
          ].concat(res.data.data || []);
          this.$nextTick(() => {
            this.init = true;
          })
        }
      ).catch(e => {
        this.init = true;
      })
    },
    delivery(row){
      this.dialogForm.form = {
        delivery_order_id: row.delivery_order_id,
        delivery_id: row.delivery_id,
        delivery_tel: row.delivery_tel,
        delivery: row.delivery,
        status: 3
      },
      this.dialogForm.row = row;
      this.deliveryVisible = true;
    },
    sorting(row){
      this.dialogForm.form = {
        address_label_id: row.address_label_id,
        address_label: row.address_label,
        status: 2
      },
      this.dialogForm.row = row;
      this.sortingVisible = true;
    },
    cfztd(row){
      this.dialogForm.form = {
        pickup_id: row.pickup_id,
        pickup: row.pickup,
        delivery_type: 2,
        status: 4
      },
      this.dialogForm.row = row;
      this.cfztdVisible = true;
    },
    sign(row){
      this.dialogForm.form = {
        sign_time: row.sign_time,
        status: 5
      },
      this.dialogForm.row = row;
      this.signVisible = true;
    },
    dialogFormSubmit(){
      let body = {
        ...this.dialogForm.form
      };

      Object.keys(body).forEach(
        key => {
          if(key[0] === '$'){
            delete body[key];
          }
        }
      );
      let msg = this.$message({
        type: 'info',
        duration: 0,
        message: `正在提交...`
      });
      this.dialogForm.saving = true;
      updateMetaCommon(this.model , {
        update: {...body},
        primaryKeyValue: this.dialogForm.row.id,
        primaryKey: "id",
      }).then(
        res => {
          this.signVisible = false;
          this.cfztdVisible = false;
          this.sortingVisible = false;
          this.deliveryVisible = false;
          this.dialogForm.saving = false;
          this.$message.success('保存成功');
          msg.close();
          this.onLoad()
        }
      )
    },
    getCarrierPage(){
      getCarrierPage(1 , 10000 , {
        status: 1
      }).then(
        res => {
          const column = this.findObject(this.tableOption.column, "express_company_id");
          column.dicData = res.data.data.records;
          this.dicData.express_company = res.data.data.records;
        }
      )
    },
    // getDeliveryMan(){
    //   getDeliveryMan(1 , 10000 , {

    //   }).then(
    //     res => {
    //       const column = this.findObject(this.tableOption.column, "delivery_id");
    //       column.dicData = res.data.data.records;
    //       this.dicData.delivery = res.data.data.records;
    //     }
    //   )
    // },
    selectChange(data , prop , prop1 , params){
      data.forEach(
        item => {
          if(item[prop1] === this.form[prop]){
            params.forEach(
              itemParam => {
                this.form[itemParam.target] = item[itemParam.source]
              }
            )
          }
        }
      );
    },
    tabsHandleClick(){
      this.page.currentPage = 1;
      this.onLoad();
    },
    getSearch(){
      let form = this.search , wheres = [];
      Object.keys(form).forEach(
        keyName => {
          if(keyName !== 'ACTIVENAME' && keyName[0] !== '$' && (form[keyName] ?? '') !== ''){
            const column = this.findObject(this.tableOption.column, keyName);
            if(column['searchRange']){
              let startVal = form[keyName][0] , endVal = form[keyName][1];
              if(startVal){
                if(column['type'] === 'date'){
                  startVal = `${startVal} 00:00:00`
                }
                wheres.push({ field: keyName, value: startVal, op: "GE" });
              }
              if(endVal){
                if(column['type'] === 'date'){
                  endVal = `${endVal} 23:59:59`
                }
                wheres.push({ field: keyName, value: endVal, op: "LE" });
              }
            }else if(column['searchLike']){
              wheres.push({field: keyName , op: 'LIKE', value: form[keyName]})
            }else{
              wheres.push({field: keyName , value: form[keyName]})
            }
          }
        }
      );
      return wheres;
    },
    searchChange(form , done){
      done();
      this.onLoad()
    },
    onLoad(weres){
      let wheres = this.getSearch();
      this.tableLoading = true;
      if(this.search.ACTIVENAME !== 'all'){
        wheres.push({field: 'status' , value: this.search.ACTIVENAME})
      }
      this.tableData = [];
      getMetaCommonList(this.model , {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres,
        orders: this.orders
      }).then(
        res => {
          this.tableLoading = false;
          if(res.data && res.data.code === 200){
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
          }else{
            this.tableData = [];
            this.page.total = 0;
          }
        }
      ).catch(e => {
        this.tableLoading = false;
      })
    },
    saveTableForm(row, done, loading) {

      let body = {
        ...row
      };

      Object.keys(body).forEach(
        key => {
          if(key[0] === '$'){
            delete body[key];
          }
        }
      );
     
      if(row.id){
        body = {
          update: body,
          primaryKeyValue: row.id,
          primaryKey: "id",
        };
      }else{
        body = {
          values: {
            ...body,
            verification_code: `${parseInt(Math.random() * 899999) + 100000}`
          }
        }
      };
      (row.id ? updateMetaCommon : saveMetaCommon)(this.model , body).then((res) => {
        this.responeHandle(res, done, loading);
      }).catch((e) => {
        loading();
      });
    },
    responeHandle(res, done, loading) {
      if (res.data && res.data.code === 200) {
        done && done();
        this.onLoad();
        this.$message.success(res.data.msg);
      } else {
        loading && loading();
        this.$message.error(res.data.msg);
      }
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        return delMetaCommon(this.model , {pk: 'id' , ids: [row.id]});
      }).then(() => {
        this.onLoad();
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    },
  }
}
</script>
<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleClick" class="pad-l-5">
            <el-tab-pane label="全部" name="1"></el-tab-pane>
            <el-tab-pane label="待审批" name="2"></el-tab-pane>
            <el-tab-pane label="审批通过" name="3"></el-tab-pane>
            <el-tab-pane label="审批未通过" name="4"></el-tab-pane>
        </el-tabs>
        <basic-container>
            <avue-crud :option="option" :data="data" :page.sync="page" :search.sync="search" @refresh-change="refreshChange" @search-change="searchChange" @search-reset="searchReset"
                       @on-load="onLoad">
                <template slot="searchMenu">
                    <el-button size="small" icon="el-icon-delete" @click="searchReset">清空</el-button>
                </template>
                <template slot="menuLeft" slot-scope="scope">
                    <el-button v-if="activeName == 2" type="primary" size="small" icon="el-icon-plus" @click="openDislog('add')">企业入驻
                    </el-button>
                    <el-input v-model="searchText" @keyup.native.enter="refreshChange" size="small" placeholder="请输入企业名称" type="text" style="width: 250px">
                        <el-button class="mar-0 pad-0" size="small" @click="refreshChange" slot="append" icon="el-icon-search"></el-button>
                    </el-input>
                </template>
                <template slot-scope="scope" slot="menu">
                    <el-button type="text" size="small" icon="el-icon-view" class="none-border" @click="view(scope.row)">查看
                    </el-button>
                    <el-button v-if="activeName == 3" type="text" size="small" icon="el-icon-bell" class="none-border" @click="checkInCallback(scope.row)">重新下发门禁权限
                    </el-button>
                </template>
            </avue-crud>
            <el-dialog :visible.sync="detailDia" width="60%" class="enterDetail" destroy-on-close append-to-body>
                <div slot="title" class="header-title">
                    <span class="fts-16">{{ title }}</span>
                </div>
                <addEnterprise @close="close" :currentRow="currentRow" :doType="doType"></addEnterprise>
            </el-dialog>
            <el-drawer :visible.sync="drawerDia" size="60%" :withHeader="false">
                <!-- <div slot="title" class="header-title">
          <span class="fts-16">{{ title }}</span>
        </div> -->
                <el-container class="h-100p o-h">
                    <el-header class="bor-b" height="50px">
                        <el-page-header class="lh-50" @back="drawerBack()" :content="title"></el-page-header>
                    </el-header>
                    <el-container style="height: calc(100% - 100px)">
                        <el-main>
                            <div class="pull-left fts-18">
                                <template>
                                    <span class="statusval" :class="'col' + currentRow.approval_status">【
                                        <span v-if="currentRow.approval_status == 1">待提交</span>
                                        <span v-if="currentRow.approval_status == 2">审批中</span>
                                        <span v-if="currentRow.approval_status == 3">审批通过</span>
                                        <span v-if="currentRow.approval_status == 4">审批未通过</span>
                                        <span v-if="currentRow.approval_status == 5">已撤回</span>
                                        】</span>
                                </template>
                            </div>
                            <enterDetail :id="currentRow.id"></enterDetail>
                        </el-main>
                    </el-container>
                </el-container>
            </el-drawer>
        </basic-container>
    </div>
</template>
<script>
import { getMetaCommonList, delMetaCommon } from '@/api/busiMode/metaCommon';
import { mapGetters, mapState } from 'vuex';
import addEnterprise from './addEnterprise';
import { checkout, checkInCallback } from '@/api/contract/index';
import enterDetail from './enterDetail';
import * as dictbizService from '@/api/system/dictbiz';
import * as dictService from '@/api/system/dictbiz';

import { optionEnter } from './optionEnter';

export default {
    components: { addEnterprise, enterDetail },
    name: 'enter',
    data() {
        return {
            data: [],
            loading: false,
            detailDia: false,
            drawerDia: false,
            activeName: '2',
            searchText: '',
            viewPortHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
            form: {
                name: '',
                uniform_social_credit_code: '',
            },
            page: {
                size: 10,
                current: 1,
                total: 0,
            },
            model: 'wisdom_park.wp_company_checkin',
            option: optionEnter,
            currentRow: {},
            title: '',
            enterpriseTypes: [],
            firmSizeList: [],
            qualifications: [],
            doType: '',
            search: {
                industry: '',
                name: '',
                uniform_social_credit_code: '',
            },
        };
    },
    props: {
        enter: {
            type: String,
            default() {
                return '';
            },
        },
        approvalType: {
            type: Number,
            default() {
                return 0;
            },
        },
    },
    watch: {
        enter: {
            immediate: true,
            handler(val) {
                if (val == 'enter') {
                    this.option = JSON.parse(JSON.stringify(optionEnter));
                }
            },
        },
    },
    mounted() {},
    created() {
        this.getDicList();
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.code_add, false),
                viewBtn: this.vaildData(this.permission.code_view, false),
                delBtn: this.vaildData(this.permission.code_delete, false),
                editBtn: this.vaildData(this.permission.code_edit, false),
            };
        },
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    methods: {
        drawerBack() {
            this.drawerDia = false;
        },
        handleProcess(row) {
            this.$store.commit('SET_CURRENT_TODO_INFO', row);
            this.$router.push('/flow/todo/detail?id=' + row.process_instance_id);
        },
        checkout(row) {
            checkout(row).then(res => {
                this.onLoad();
            });
        },
        checkInCallback(row) {
            let obj = {
                result: true,
                businessId: row.id,
            };
            checkInCallback(obj).then(res => {
                this.$message({
                    type: 'success',
                    message: '操作成功!',
                });
                this.onLoad(this.page);
            });
        },
        handleClick(tab, event) {
            this.activeName = tab.name;
            this.onLoad();
        },
        getDicList() {
            dictbizService
                .getDictionary({
                    code: 'firmSize',
                })
                .then(res => {
                    this.firmSizeList = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
            dictbizService
                .getDictionary({
                    code: 'enterpriseType',
                })
                .then(res => {
                    this.enterpriseTypes = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
            dictbizService
                .getDictionary({
                    code: 'qualification',
                })
                .then(res => {
                    this.qualifications = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
        },
        view(row) {
            this.title = '入驻申请详情';
            this.drawerDia = true;
            this.$nextTick(() => (this.currentRow = row));
        },
        openDislog(type, row) {
            this.doType = type;
            this.$router.push({
                path: '/enterprise/enterpriseEnter',
                query: {
                    type: type,
                },
            });
            // this.$router.push({
            //   path: `/enterprise/enterpriseEnter`,
            // });
        },
        refreshChange() {
            this.onLoad(this.page);
        },
        close() {
            this.detailDia = false;
            this.refreshChange();
        },
        searchChange(params, done) {
            let wheres = [];
            for (let key in params) {
                if (params[key]) {
                    wheres.push({ field: key, value: params[key] });
                }
            }
            let body = {
                current: 1,
                size: this.page.pageSize,
                wheres: wheres,
            };
            this.getMetaCommonList(body);
            done();
        },
        searchReset() {
            this.onLoad();
        },
        refreshChange() {
            this.onLoad(this.page);
        },
        onLoad() {
            this.loading = true;
            let body = {
                current: this.page.currentPage,
                size: this.page.pageSize,
                wheres: [
                    {
                        field: 'approval_type',
                        value: this.approvalType,
                    },
                ],
                orders: [{ field: 'approval_status', sort: 'DESC' }],
            };
            if (this.form.name) {
                body['wheres'].push({
                    field: 'name',
                    op: 'LIKE',
                    value: this.form.name,
                });
            }
            if (this.searchText) {
                body['wheres'].push({
                    field: 'name',
                    op: 'LIKE',
                    value: this.searchText,
                });
            }
            if (this.activeName && this.activeName != 1) {
                body['wheres'].push({
                    field: 'approval_status',
                    value: Number(this.activeName),
                });
            }

            this.getMetaCommonList(body);
        },
        getMetaCommonList(body) {
            getMetaCommonList(this.model, body).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                data.records.forEach(e => {
                    if (e.type) {
                        this.enterpriseTypes.forEach(type => {
                            if (e.type == type.value) {
                                e.type = type.name;
                            }
                        });
                    }
                    if (e.qualification) {
                        this.qualifications.forEach(q => {
                            if (e.qualification == q.value) {
                                e.qualification = q.name;
                            }
                        });
                    }
                    if (e.firm_size) {
                        this.firmSizeList.forEach(q => {
                            if (e.firm_size == q.value) {
                                e.firm_size = q.name;
                            }
                        });
                    }
                });
                this.data = data.records;
                this.loading = false;
            });
        },
        rowDel(row, index) {
            this.$confirm('是否删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let pk = 'id';
                    delMetaCommon(this.model, { pk: pk, ids: [row[pk]] }).then(res => {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        this.onLoad(this.page);
                    });
                })
                .catch(() => {});
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__body {
    padding-top: 0px;
}
/deep/ .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 15px;
}
.enterDetail /deep/ .el-dialog__body {
    padding: 0px;
}
/deep/ .el-drawer__body {
    padding-top: 0px;
    overflow-x: hidden !important;
}
/deep/ .el-drawer__header {
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 0px;
    padding-bottom: 15px;
}
/deep/.el-tabs__nav-wrap::after {
    position: static !important;
}
.o-h {
    overflow: hidden;
}
.col1 {
    color: #409eff;
}
.col2 {
    color: #409eff;
}
.col3 {
    color: #67c23a;
}
.col4 {
    color: #f56c6c;
}
.col5 {
    color: #909399;
}
.statusval {
    position: absolute;
    right: 10px;
    top: 60px;
}
</style>

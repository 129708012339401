import { assign } from "min-dash";
import { isAny } from "bpmn-js/lib/features/modeling/util/ModelingUtil";
import ContextPadProvider from 'bpmn-js/lib/features/context-pad/ContextPadProvider.js'

class CustomContextPadProvider extends ContextPadProvider{

  constructor(
    config, injector, eventBus,
    contextPad, modeling, elementFactory,
    connect, create, popupMenu,
    canvas, rules, translate
  ){

    super(
      config, injector, eventBus,
      contextPad, modeling, elementFactory,
      connect, create, popupMenu,
      canvas, rules, translate
    )

  }

  getContextPadEntries(element){
    let modeling = this._modeling,
        elementFactory = this._elementFactory,
        connect = this._connect,
        create = this._create,
        autoPlace = this._autoPlace,
        translate = this._translate,
        actions = {};
    
    if (element.type === "label") {
      return actions;
    }
  
    var businessObject = element.businessObject;

    function removeElement() {
      modeling.removeElements([element]);
    }

    function startConnect(event, element) {
      connect.start(event, element);
    }

    function appendAction(type, className, title, options) {
      if (typeof title !== "string") {
        options = title;
        title = translate("Append {type}", { type: type.replace(/^bpmn:/, "") });
      }
  
      function appendStart(event, element) {
        var shape = elementFactory.createShape(assign({ type: type }, options));
        create.start(event, shape, {
          source: element
        });
      }
  
      var append = autoPlace
        ? function(event, element) {
            var shape = elementFactory.createShape(assign({ type: type }, options));
  
            autoPlace.append(element, shape);
          }
        : appendStart;
  
      return {
        group: "model",
        className: className,
        title: title,
        action: {
          dragstart: appendStart,
          click: append
        }
      };
    }

    if (isAny(businessObject, ["bpmn:FlowNode", "bpmn:InteractionNode", "bpmn:DataObjectReference", "bpmn:DataStoreReference"])) {
      assign(actions, {
        "append.text-annotation": appendAction("bpmn:TextAnnotation", "bpmn-icon-text-annotation"),
        connect: {
          group: "connect",
          className: "bpmn-icon-connection-multi",
          title: translate("Connect using " + (businessObject.isForCompensation ? "" : "Sequence/MessageFlow or ") + "Association"),
          action: {
            click: startConnect,
            dragstart: startConnect
          }
        }
      });
    }

    assign(actions , {
      delete: {
        group: "edit",
        className: "bpmn-icon-trash",
        title: translate("Remove"),
        action: {
          click: removeElement
        }
      }
    })

    return actions;
  }

}

export default {
  __init__: ["contextPadProvider"],
  contextPadProvider: ["type", CustomContextPadProvider]
}
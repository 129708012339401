export class InputType{

  _type = '';
  defaultValue = null;
  value = null;
  code = '';
  show = true;

  propData = {};

  get type(){
    return this._type.toLocaleLowerCase();
  }

  constructor(option){
    delete option.type;
    Object.assign(this , option);
  }

  getValue(){
    return this.value;
  }


}

export class TextInputType extends InputType{
  _type = 'Text';
  constructor(option){
    super(option)
  }
}

export class NumberInputType extends InputType{
  _type = 'Number';
  constructor(option){
    super(option)
  }
}

export class TextareaInputType extends InputType{
  _type = 'Textarea';
  constructor(option){
    super(option)
  }
}

export class ColorPickerInputType extends InputType{
  _type = 'ColorPicker';
  defaultValue = '#000';
  constructor(option){
    super(option)
  }
}

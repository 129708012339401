<template>
  <div>123</div>
</template>
<script>

import WidgetMixins from '../widget-mixins'

export default {
  name: 'BuiltInWidgetByTest',
  data(){
    return {

    }
  },
  mixins: [ WidgetMixins ]
}
</script>
<template>
<basic-container isCard :body-style="{'padding': '0' , 'height': '100%'}" class="h-100p">
  <el-container style="height: 100%">
    <el-header height="50px" class="bor-b">
      <basic-page-header @back="back" class="lh-50" title="减免政策"></basic-page-header>
    </el-header>
    <el-main class="pad-20 ov-a">
      <el-form class="mar-t-20" :model="form" ref="form" :size="formSize" label-width="100px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="减免政策标题" prop="reduction_policy_title">
              <el-input :disabled="disabled" v-model="form.reduction_policy_title"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="减免政策类型" prop="reduction_policy_type">
              <DictSelect :disabled="disabled" code="ReductionPolicyType" v-model="form.reduction_policy_type"></DictSelect>
              <!-- <el-select :disabled="disabled" v-model="form.reduction_policy_type">
                <el-option v-for="itme of dictData.ReductionPolicyType" :key="itme.id" :label="itme.dictValue" :value="itme.dictKey"></el-option>
              </el-select> -->
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="企业减免限额" prop="company_limit">
              <el-input type="number" :disabled="disabled" v-model.number="form.company_limit"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="政策减免限额" prop="reduction_limit">
              <el-input type="number" :disabled="disabled" v-model.number="form.reduction_limit"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="政策开始时间" prop="start_time">
              <el-date-picker :disabled="disabled" class="dis-b w-100p" v-model="form.start_time" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd 00:00:00"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="政策结束时间" prop="end_time">
              <el-date-picker :disabled="disabled" class="dis-b w-100p" v-model="form.end_time" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd 23:59:59"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="政策附件" prop="attachment_id">
              <FormFile :disabled="disabled" v-model="form.attachment_id"></FormFile>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="政策说明" prop="note">
              <el-input type="textarea" rows="5" v-model="form.note"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="pad-tb-10">
          <el-divider content-position="left">减免费用</el-divider>
        </div>
        <el-form-item label-width="0px">
          <el-table
            :data="listDetailValues"
            :size="formSize"
            border
          >
            <el-table-column v-if="!disabled" type="index" width="50">
              <template slot="header">
                <el-button size="mini" @click="addListDetai" type="primary" icon="el-icon-plus" circle></el-button>
              </template>
              <template slot-scope="scope">
                <el-button size="mini" @click="listDetailValues.splice(scope.$index , 1);" type="danger" icon="el-icon-minus" circle></el-button>
              </template>
            </el-table-column>
            <el-table-column label="费用项目" prop="fee_item">
              <template slot-scope="scope">
                <model-cascader :disabled="disabled" labelKey="expense_account_name" :option="{emitPath: false}" valueKey="expense_account_code" :model="catalogModel" v-model="scope.row.fee_item"></model-cascader>
              </template>
            </el-table-column>
            <el-table-column label="减免方式" prop="reduction_type">
              <template slot-scope="scope">
                <DictSelect :disabled="disabled" @change="resetListDetailItemValue(scope.row , 'reduction_type' , 3 , 'reduction_value')" code="ReductionType" v-model="scope.row.reduction_type"></DictSelect>
              </template>
            </el-table-column>
            <el-table-column label="减免值" prop="reduction_value">
              <template slot-scope="scope">
                <el-input type="number" :disabled="disabled || scope.row.reduction_type == 3" v-model.number="scope.row.reduction_value"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="减免周期" prop="reduction_period">
              <template slot-scope="scope">
                <DictSelect :disabled="disabled" @change="resetListDetailItemValue(scope.row , 'reduction_period' , 3 , 'reduction_period_value')" code="ReductionPeriod" v-model="scope.row.reduction_period"></DictSelect>
              </template>
            </el-table-column>
            <el-table-column label="减免周期值" prop="reduction_period_value">
              <template slot-scope="scope">
                <el-input type="number" :disabled="disabled || scope.row.reduction_period == 3" v-model.number="scope.row.reduction_period_value"></el-input>
              </template>
            </el-table-column>
            <el-table-column label="减免优先级" prop="priority">
              <template slot-scope="scope">
                <DictSelect :disabled="disabled" code="ReductionPeriodPriority" v-model="scope.row.priority"></DictSelect>
              </template>
            </el-table-column>

          </el-table>
        </el-form-item>
      </el-form>

    </el-main>
    <el-footer v-if="!isView" height="50px" class="lh-50">
      <div class="text-center">
        <el-button :size="formSize" :loading="btnLoading" icon="el-icon-close" @click="back">取 消</el-button>
        <el-button :size="formSize" :loading="btnLoading" icon="el-icon-check" type="primary" @click="saveForm(false)">保 存</el-button>
      </div>
    </el-footer>
  </el-container>
</basic-container>
</template>
<script>
import dayjs from 'dayjs';
import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonDetail
} from "@/api/busiMode/metaCommon";
import * as dictService from "@/api/system/dictbiz";

import FormFile from './formFile';

export default {
  components: { FormFile },
  data(){
    return {
      btnLoading: false,
      listDetailValues: [],
      form: {
        reduction_policy_title: '',
        reduction_policy_type: '',
        company_limit: null,
        reduction_limit: null,
        start_time: '',
        end_time: '',
        note: '',
        attachment_id: ''
      },
      dictData: {
        ReductionPolicyType: []
      },
      listValue: {
        field: ['reduction_policy_type' , 'fee_item' , 'reduction_type' , 'reduction_value' , 'reduction_period' , 'reduction_period_value' , 'priority'],
        relate: 'reduction_policy_id',
        master: ['fee_item' , 'reduction_type' , 'reduction_period'],
        patent: ['reduction_policy_type']
      },
      catalogModel: 'wisdom_park.wp_expense_account',
      model: 'wisdom_park.wp_reduction_policy',
      detailTable: 'wp_reduction_policy_detail',
      masterIdColumnName: 'reduction_policy_id',
    }
  },
  computed: {
    isView(){
      return this.$router.currentRoute.params['type'] === 'view'
    },
    isAdd(){
      return this.$router.currentRoute.params['type'] === 'add'
    },
    formSize(){
      return this.$store.state.common.size || 'small'
    },
    id(){
      return this.$router.currentRoute.query['id'];
    },
    disabled(){
      return this.isView;
    },
    formSize(){
      return this.$store.state.common.size || 'small'
    },
  },
  mounted(){
    if(this.isAdd){
      for(let i = 5; i > 0; i--){
        this.addListDetai()
      }
    }
    if(this.id){
      this.getDetail();
    }
  },
  methods: {
    back(){
      this.$router.back();
      this.$breadcrumb.pop();
    },
    getDetail(){
      getMetaCommonDetail(this.model , {
        pk: 'id',
        id: this.id,
        detailTable: this.detailTable,
        masterIdColumnName: this.masterIdColumnName,
      }).then(
        res => {
          let data = res.data.data;
          let listDetailValues = data['listDetailValues'] || [];

          delete data['listDetailValues'];

          this.listDetailValues = listDetailValues;
          
          this.form = data;
        }
      )
    },
    addListDetai(){
      let itemObj = {
        assets: {
          list: [],
          loading: false
        }
      };
      this.listValue.field.forEach(
        item => {
          if(typeof item === 'string'){
            item = {
              type: 'string',
              code: item
            }
          }
          switch(item.type){
            case 'string':
              itemObj[item.code] = '';
              break;
            case 'number':
              itemObj[item.code] = null;
              break;
            case 'arrar':
              itemObj[item.code] = [];
              break;
          }
        }
      )
      this.listDetailValues.push(itemObj)
    },
    getListDetailValues(){
      let listDetailValues = [];
      this.listDetailValues.forEach(
        item => {
          let master = [];
          if(typeof this.listValue.master === 'string'){
            master = [this.listValue.master]
          }else{
            master = this.listValue.master;
          }
          let add = true;
          master.forEach(
            name => {
              if(item[name] === '' || item[name] === null || item[name] === undefined){
                add = false;
              }
            }
          )
          if(add){
            let newItem = {};
            this.listValue.field.forEach(
              fieldItem => {
                if(typeof fieldItem === 'string'){
                  fieldItem = {
                    type: 'string',
                    code: fieldItem
                  }
                }
                switch(fieldItem.type){
                  case 'string':
                    newItem[fieldItem.code] = item[fieldItem.code];
                    break;
                  case 'number':
                    newItem[fieldItem.code] = Number(item[fieldItem.code]);
                    break;
                  case 'arrar':
                    newItem[fieldItem.code] = item[fieldItem.code].join(fieldItem.separate || '-');
                    break;
                }
              }
            )
            if(item.id){
              newItem['id'] = item.id;
            }
            if(item[this.listValue.relate]){
              newItem[this.listValue.relate] = item[this.listValue.relate];
            }
            if(this.listValue.patent){
              this.listValue.patent.forEach(
                fieldItem => {
                  if(typeof fieldItem === 'string'){
                    fieldItem = {
                      parent: fieldItem,
                      prop: fieldItem
                    }
                  }
                  newItem[fieldItem.prop] = this.form[fieldItem.parent];
                }
              )
            }
            listDetailValues.push(newItem)
          }
        }
      )
      return listDetailValues;
    },
    resetListDetailItemValue(item , source , value , target){
      if(item[source] == value){
        item[target] = null;
      }
    },
    saveForm(){
      let listDetailValues = this.getListDetailValues();
      if(!listDetailValues.length){
        this.$message({
          type: 'error',
          message: '请完善退回资产'
        })
        return false;
      }

      this.$refs.form.validate(valid => {
        if(valid){
          let body = {
            ...this.form
          };
          if(this.form.id){
            delete body.id;
            body = {
              update: body,
              primaryKeyValue: this.form.id,
              primaryKey: "id",
              listDetailValues: listDetailValues
            };
          }else{
            body = {
              values: body,
              listDetailValues: listDetailValues
            }
          }
          this.btnLoading = true;
          (this.form.id ? updateMetaCommon : saveMetaCommon)(this.model , {
            ...body,
            detailTable: this.detailTable,
            masterIdColumnName: this.masterIdColumnName,
          }).then((res) => {
              return res;
          }).then(res => {
            this.btnLoading = false;
            this.$message.success(res.data.msg);
            this.back();
          }).catch((e) => {
            this.btnLoading = false;
          });
        }else{
          return false;
        }
      })
      
    }
  }
}
</script>
export default [
  {
    'label':'继续',
    'value':'CONTINUE'
  },
  {
    'label':'结束',
    'value':'END'
  }
]

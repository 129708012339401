import Component from './main.vue';

export default [
  {
    widgetName: '普通文本',
    type: '基础部件',
    code: 'BuiltInWidgetByText',
    component: Component,
    icon: 'portal-iconfont portal-icon_wenben',
    dataOption: [
      // {name: '分类' , code: 'category' , type: 'field'},
    ],
    optionList: [
      {

      }
    ]
  },
]
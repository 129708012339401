<template>
  <div>
    <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" :page.sync="page" @refresh-change="refreshChange" @on-load="onLoad">
      <template slot="menuLeft" slot-scope="scope">
        <el-button type="primary" size="small" icon="el-icon-plus" @click="addRoom()">新增房间 </el-button>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button type="text" size="small" class="none-border" icon="el-icon-view" @click="getDetail(scope.row, true)">查看 </el-button>
        <el-button type="text" size="small" class="none-border" icon="el-icon-edit" @click="getDetail(scope.row, false)">修改 </el-button>
        <el-button type="text" size="small" icon="el-icon-delete" class="none-border" @click="rowDel(scope.row)">删除 </el-button>
      </template>
    </avue-crud>
    <el-dialog :title="roomTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" destroy-on-close :top="'8vh'" width="40%" append-to-body>
      <roomInfo v-if="dialogVisible" ref="roomInfo" :buildRead="buildRead" :onlyRead="onlyRead" :floors="floors" :ruleFormObj="ruleForm" @roomSucess="closeDialogVisible"></roomInfo>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" type="primary" v-if="!onlyRead" @click="saveMetaCommon">确 定</el-button>
        <el-button size="small" type="primary" v-if="onlyRead" @click="editRoom">编 辑</el-button>
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import roomInfo from "./roomInfo"

import { getMetaCommonList, delMetaCommon, saveMetaCommon } from "@/api/busiMode/metaCommon"

export default {
  components: { roomInfo },
  data() {
    return {
      formInline: {},
      detailDia: false,
      dialogVisible: false,
      buildId: "",
      buildName: "",
      title: "",
      btnLoding: false,
      downLoading: false,
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      model: "wisdom_park.wp_room",
      floorModel: "wisdom_park.wp_floor",

      spaces: [],
      floors: [],
      ruleForm: { label: [] },
      currentIndex: "",
      onlyRead: false,
      roomTitle: "新增房间",
      loading: false,
      searchText: "",
      data: [],
      buildRead: false,
      option: {
        align: "center",
        calcHeight: 30,
        dialogWidth: 900,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        // selection: true,
        labelWidth: 120,
        menuWidth: 200,
        viewBtn: false,
        dialogClickModal: false,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        cancelBtn: false,
        column: [
          {
            width: 80,
            overHidden: true,
            align: "left",
            label: "房间名称",
            prop: "room_name",
            hide: false,
          },
          {
            width: 250,
            overHidden: true,
            align: "left",
            label: "入驻企业",
            prop: "company_names",
            hide: false,
            formatter: (row, col, val) => {
              return val.replaceAll(",", "\n\r")
            },
          },

          {
            width: 140,
            overHidden: true,
            align: "left",
            label: "楼宇名称",
            prop: "building_name",
            hide: false,
          },

          {
            width: 50,
            overHidden: true,
            label: "楼层",
            prop: "floor_name",
          },

          {
            width: 80,
            overHidden: true,
            label: "状态",
            prop: "status",
            dataType: "number",
            dicData: [
              {
                value: 1,
                label: "已生效",
              },
              {
                value: 2,
                label: "已失效",
              },
              {
                value: 3,
                label: "待生效",
              },
              {
                value: 4,
                label: "永久有效",
              },
            ],
          },
          {
            width: 100,
            span: 12,
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            overHidden: true,
            label: "有效开始时间",
            prop: "valid_start_time",
          },
          {
            width: 100,
            span: 12,
            type: "date",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd HH:mm:ss",
            overHidden: true,
            label: "有效结束时间",
            prop: "valid_end_time",
          },
          {
            width: 120,
            span: 12,
            overHidden: true,
            label: "备注",
            prop: "note",
          },
        ],
      },
    }
  },
  props: {
    companyId: {
      type: String,
    },
  },
  mounted() {
    this.onLoad()
  },
  methods: {
    /**
     * 刷新操作
     */
    refreshChange() {
      this.onLoad(this.page)
    },

    /**
     * 关闭模态框并重载数据
     */
    closeDialogVisible() {
      this.dialogVisible = false
      this.onLoad()
    },

    /**
     * 新增房间
     */
    addRoom() {
      this.ruleForm = {}
      this.onlyRead = false
      this.buildRead = true
      this.roomTitle = "新增房间"
      this.dialogVisible = true
    },

    /**
     * 新增或修改（提交事件）
     */
    saveMetaCommon() {
      this.$refs.roomInfo.saveRooms(this.companyId)
    },

    /**
     * 查看或编辑房间数据
     * @param {房间数据} room
     * @param {编辑标志} onlyRead
     */
    getDetail(room, onlyRead) {
      this.ruleForm = room
      this.roomTitle = onlyRead ? "房间详情" : "房间编辑"
      this.onlyRead = onlyRead
      this.buildRead = false
      this.dialogVisible = true
    },

    /**
     * 删除房间
     * @param {*} row
     */
    rowDel(row) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let pk = "id"
          delMetaCommon("wisdom_park.wp_sublessor_room", {
            pk: pk,
            ids: [row.id],
          }).then((res) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            })
            this.onLoad(this.page)
          })
        })
        .catch(() => {})
    },

    /**
     * 根据企业ID查询房间数据
     */
    onLoad() {
      let obj = {
        current: this.page.currentPage,
        size: this.page.pageSize,
      }

      let wheres = [{ field: "company_id", value: this.companyId }]
      obj.wheres = wheres
      this.getMetaCommonList(obj)
    },

    getMetaCommonList(obj) {
      this.btnLoding = true
      getMetaCommonList("wisdom_park.v_wp_sublessor_room", obj).then((res) => {
        const data = res.data.data
        this.page.total = data.total
        data.records.forEach((e) => {
          if (e.status) {
            e.status = e.status.toString()
          }
        })
        this.data = data.records
        this.btnLoding = false
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.titleclazz {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.configInput {
  position: relative;
  font-size: 14px;
  display: inline-block;
  width: 80px;
  margin: 0px 5px;
}
.card {
  padding: 0px 10px 10px 0px;
}
ul,
li {
  list-style: none;
  margin-bottom: 5px;
}
.spaceCard {
  display: inline-block;
  min-width: 100px;
  height: 105px;
}
.cardBg {
  background: #f5f7fa;
}
.spaceCard {
  &:hover {
    .addbtn {
      display: block;
    }
  }
}
.addbtn {
  border: 1px dotted #1976d3;
  padding: 5px 10px;
  color: #1976d3;
  margin-bottom: 10px;
  border-radius: 0px;
}
.roomClazz {
  white-space: nowrap;
  font-size: 0px;
  overflow-x: auto;
  width: 100%;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 20px;
}
.b-b {
  border-bottom: 1px solid #dcdfe6;
}

.dropclazz {
  position: absolute;
  right: 5px;
  top: 5px;
  padding: 3px 5px 3px 0px;
  transform: rotate(-90deg);
}
.p-r {
  position: relative;
}
/deep/ .el-drawer__header {
  border-bottom: 1px solid #ebebeb;
  padding: 10px 20px;
  margin-bottom: 0px;
}
/deep/ .el-drawer__body {
  padding: 0px;
}
/deep/.el-dialog__body {
  height: calc(80vh - 110px);
  overflow: auto;
  padding: 15px;
}

.cz-pos {
  position: absolute;
  top: 10px;
  right: 20px;
  opacity: 0.3;
}

/deep/ .el-table .cell.el-tooltip {
  white-space: pre-line;
}
</style>

<template>
  <div>
    <div v-if="title || (optionItem && optionItem.optionList && optionItem.name)" @click="show = !show" class="option-group-title">
      <b>{{title || (optionItem && optionItem.name)}} </b>
      <el-tooltip v-if="optionItem.desc" class="item" effect="dark" placement="top">
        <pre slot="content">{{getTooltipContent()}}</pre>
        <i class="mar-l-5 el-icon-info"></i>
      </el-tooltip>
      <span class="right-btn">
        <el-link v-if="optionItem.type === 'dynamic' && optionItem.meta" class="mar-r-15" :underline="false">
          <el-tooltip class="item" effect="dark" :content="'添加' + (optionItem && optionItem.name)" placement="top">
            <i class="el-icon-plus" @click.stop="addOption"></i>
          </el-tooltip>
        </el-link>
        <el-link :underline="false">
          <i :class="{'el-icon-arrow-right': !show , 'el-icon-arrow-down': show}"></i>
        </el-link>
      </span>
    </div>
    <div v-if="show && getDisplay()">
      <template v-if="optionItem && !optionItem.optionList">
        <template v-if="!optionItem.hide">
          <el-form-item :label="optionItem.name">
            
            <template slot="label">
              {{optionItem.name}}
              <el-tooltip v-if="optionItem.desc" class="item" effect="dark" placement="top">
                <pre slot="content">{{getTooltipContent()}}</pre>
                <i class="mar-l-5 el-icon-info"></i>
              </el-tooltip>
            </template>

            <el-input v-if="optionItem.type === 'text'" type="text" v-bind="optionItem.props" v-model="option[optionItem.code]"></el-input>

            <el-input v-if="optionItem.type === 'textarea'" type="textarea" v-bind="optionItem.props" v-model="option[optionItem.code]"></el-input>

            <el-switch v-if="optionItem.type === 'boolean'" v-bind="optionItem.props" v-model="option[optionItem.code]"></el-switch>

            <el-select v-if="optionItem.type === 'select'"  class="dis-b" v-bind="optionItem.props" v-model="option[optionItem.code]">
              <el-option v-for="selectItem of optionItem.selectList" :label="selectItem.name" :value="selectItem.value"></el-option>
            </el-select>

            <el-select v-if="optionItem.type === 'field'" class="dis-b" v-bind="optionItem.props" v-model="option[optionItem.code]">
              <el-option v-for="col of columns" :label="col.name" :value="col.value"></el-option>
            </el-select>

            <template v-if="optionItem.type === 'radio'">
              <el-radio v-model="option[optionItem.code]" v-for="selectItem of optionItem.selectList" :label="selectItem.value">{{selectItem.name}}</el-radio>
            </template>


            <el-color-picker v-if="optionItem.type === 'color'" v-bind="optionItem.props" v-model="option[optionItem.code]"></el-color-picker>

            <el-input v-if="optionItem.type === 'number'" type="number" v-bind="optionItem.props" v-model="option[optionItem.code]">
              <el-select v-if="optionItem.units" class="dv-el-select-input" v-model="option['unit'][optionItem.code]" slot="append" placeholder="请选择">
                <el-option v-for="item of optionItem.units" :value="item.value" :label="item.name" :key="item.value">
                  <span style="float: left">{{ item.name }}</span>
                  <span v-if="item.desc" style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                </el-option>
              </el-select>
            </el-input>

            <template v-if="optionItem.type === 'theme'">
              <div>
                <el-select class="dis-b" v-bind="optionItem.props" v-model="option[optionItem.code]['name']" @change="themeChange">
                  <el-option v-for="themeItem of themes" :label="themeItem.name" :value="themeItem.code"></el-option>
                </el-select>
              </div>
              <div style="margin-top: 10px;">
                <el-color-picker class="mar-r-5" v-for="(optColorItem , index) of option[optionItem.code]['color']" v-model="option[optionItem.code]['color'][index]" show-alpha></el-color-picker>
                <div v-if="option[optionItem.code]['name']" class="optColorItemAdd" @click="option[optionItem.code]['color'].push(null)"><i class="el-icon-plus"></i></div>
              </div>
            </template>

          </el-form-item>
        </template>
      </template>
      <template v-if="optionItem && optionItem.optionList">
        <template v-for="(item , index) of optionItem.optionList">
          <template v-if="isArray(item)">
            <div class="lh-34 bor-b mar-b-10">{{optionItem.name || ''}}{{index + 1}} 
              <span class="pull-right">
                <el-button size="mini" type="text" icon="el-icon-delete" @click="deleteOption(index)" circle></el-button>
              </span>
            </div>
            <template v-for="(item1 , index1) of item">
              <OptionGroup :optionItem="item1" :columns="columns" :rootOption="rootPropOption" :option="optionItem.code ? option[optionItem['code']][index] : option[index]"></OptionGroup>
            </template>
          </template>
          <template v-else>
            <template v-if="!item.hide">
              <OptionGroup :optionItem="item" :columns="columns" :rootOption="rootPropOption" :option="optionItem.code ? (isArray(option[optionItem['code']]) ? option[optionItem['code']][index] : option[optionItem['code']]) : option"></OptionGroup>
            </template>
          </template>
        </template>
        <!-- <el-button icon="el-icon-plus" :size="config.size" v-if="optionItem.dynamic && optionItem.meta" @click="addOption(optionItem)">添加{{optionItem.name || ''}}</el-button> -->
      </template>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'OptionGroup',
  inject: ['config'],
  data(){
    return {
      show: true,
      themes: [
        {name: '测试主题1' , code: 'test-theme-1' , color: ['#3fb1e3' , '#6be6c1' , '#626c91']},
        {name: '测试主题2' , code: 'test-theme-2' , color: ['#2ec7c9' , '#b6a2de' , '#5ab1ef']}
      ],
    }
  },
  props: {
    columns: {
      type: Array,
      default(){
        return [];
      }
    },
    title: {
      type: String,
      default: ''
    },
    optionItem: {
      type: Object,
      default(){
        return {}
      }
    },
    option: {
      type: Object,
      default(){
        return null
      }
    },
    rootOption: {
      type: Object,
      default(){
        return null
      }
    }
  },
  computed: {
    rootPropOption(){
      return this.rootOption === null ? this.option : this.rootOption;
    }
  },
  mounted(){
    // console.log(this.optionItem , this.option);
  },
  methods: {
    themeChange(){
      this.themes.forEach(
        item => {
          if(item.code === this.option[this.optionItem.code]['name']){
            let color = [];
            item.color.forEach(
              cItem => {
                color.push(cItem);
              }
            )
            this.option[this.optionItem.code]['color'] = color;
          }
        }
      )
    },
    isArray(arr){
      return Array.isArray(arr);
    },
    addOption(evt){
      console.log(evt)
      let obj = {};
      this.optionItem.meta.forEach(
        item => {
          obj[item.code] = item['value'] || null;
        }
      )
      this.option[this.optionItem.code].push(obj);
      this.optionItem.optionList.push(this.optionItem.meta);
    },
    deleteOption(index){
      this.optionItem.optionList.splice(index , 1);
      this.option[this.optionItem.code].splice(index , 1);
    },
    getTooltipContent(){
      if(this.optionItem['desc']){
        const desc = this.optionItem['desc'];
        if(this.isArray(desc)){
          return desc.join('\n');
        }else{
          return desc;
        }
      }
    },
    getDisplay(){
      let display = false;
      if(this.optionItem.displayConditions){
        let conditionsResults = [];
        this.optionItem.displayConditions.forEach(
          item => {
            let value = item.prop.split('.').reduce((prev , current) => {
              return prev[current];
            } , this.rootPropOption);
            switch(item.operator){
              case 'equal':
                if(item.value === value){
                  conditionsResults.push(true);
                }else{
                  conditionsResults.push(false);
                }
                break;
            }
          }
        )
        if(!conditionsResults.includes(false)){
          display = true;
        }
      }else{
        display = true;
      }
      return display;
    }
  }
}
</script>
<style lang="scss" scoped>
.option-group-title{
  font-size: 14px;
  padding: 10px 0;
  cursor: pointer;
  .right-btn{
    float: right;
  }
}
.optColorItemAdd{
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 5px;
  border: 1px solid #ddd;
  vertical-align: top;
  text-align: center;
  border-radius: 4px;
}
</style>
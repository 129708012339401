<template>
  <basic-container>
    <avue-crud
      ref="crud"
      :table-loading="tableLoading"
      :data="tableData"
      :option="option"
      :page.sync="page"
      @on-load="onLoad"
      @refresh-change="onLoad"
    >
    <!-- 搜索 -->
      <template slot="menuLeft">
        <el-row :gutter="$store.state.common.gutter">
          <el-col :span="10">
            <el-date-picker
              size="small"
              v-model="search.createTime"
              format="yyyy-MM-dd HH:mm:ss"
              valueFormat="yyyy-MM-dd HH:mm:ss"
              type="datetimerange"
              range-separator="至"
              start-placeholder="请选择发现开始日期"
              end-placeholder="请选择发现结束日期"
            >
            </el-date-picker>
          </el-col>
          <el-col :span="4">
            <el-select size="small" v-model='search.database' @change='changeDatabase' clearable placeholder="请选择数据库">
              <el-option v-for='item in dataBaseList' :key='item.guid' :value='item.guid' :label='item.displayText'></el-option>
            </el-select>
          </el-col>
          <el-col :span="4">
            <el-select size="small" v-model='search.table' clearable placeholder="请选择数据表">
              <el-option v-for='item in tableList' :key='item.guid' :value='item.guid' :label='item.displayText'></el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-button size="small" icon="el-icon-search" type="primary" @click="searchChange">搜索</el-button>
            <el-button size="small" icon="el-icon-delete" @click="emptHandle">清空</el-button>
          </el-col>
        </el-row>
      </template>
      <template slot="addSensitiveColumns" slot-scope="{ row }">
        <el-tag size="mini" v-for="item of row.addSensitiveColumns" :key="item">{{item}}</el-tag>
      </template>
      <template slot="dropSensitiveColumns" slot-scope="{ row }">
        <el-tag size="mini" v-for="item of row.dropSensitiveColumns" :key="item">{{item}}</el-tag>
      </template>
      <template slot="searchMenu" slot-scope="scope">
        <span class="pull-right">
          <el-button size="small" class="mar-0" icon="el-icon-refresh" @click="btnClick('refresh')" circle></el-button>
          <el-button size="small" class="mar-0 mar-l-5" icon="el-icon-s-operation" @click="btnClick('operation')" circle></el-button>
        </span>
      </template>
    </avue-crud>
  </basic-container>
</template>
<script>

import { getColumnSensitiveLog } from '@/api/metadata/metadata';
import standardService from '@/api/metadata/standard';
export default {
  data(){
    return {
      tableData: [],
      tableLoading: false,
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      option: {
        addBtn: false,
        menu: false,
        // searchMenuSpan: 6,
        // searchShowBtn: false,
        columnBtn: false,
        refreshBtn: false,
        searchMenuPosition: 'left',
        column: [
          {
            label: "发现时间",
            prop: "createTime",
            width: 150,
            type: 'datetime',
            valueFormat: 'yyyy-MM-dd HH:mm:ss'
          },
          {
            label: "数据库",
            prop: "databaseName",
          },
          {
            label: "数据表",
            prop: "tableName",
          },
          {
            label: "增加字段",
            prop: "addSensitiveColumns",
            minWidth: 200
          },
          {
            label: "减少字段",
            prop: "dropSensitiveColumns",
            minWidth: 200
          },
          {
            label: "处理状态",
            prop: "clState"
          },
          {
            label: "处理结果",
            prop: "biaoji"
          },
        ]
      },
      search: {
        createTime: null,
        database: '',
        table: ''
      },
      dataBaseList: [],//数据库数据
      tableList: []//数据表数据
    }
  },
  created(){
    // 获取数据库数据
    standardService.getAtlasDataBaseList().then((res) => {
      if (res.data.code==200) {
        this.dataBaseList=res.data.data
      } else {
        this.dataBaseList==[]
      }
    })
  },
  methods: {
    // 数据库改变时 获取数据表数据
    changeDatabase (value) {
      this.search.table=''
      let params= {
        dataBaseGuid: value
      }
      standardService.getAtlasDataTableList(params).then((res) => {
        if (res.data.code==200) {
          this.tableList=res.data.data
        } else {
          this.tableList==[]
        }
      })
    },
    onLoad(){
      let params = {
        size: this.page.pageSize,
        current: this.page.currentPage
      }
      if(this.search.createTime && this.search.createTime.length === 2){
        params['startDate'] = this.search.createTime[0];
        params['endDate'] = this.search.createTime[1];
      }
      if(this.search.database){
        params['databaseGuid'] = this.search.database;
      }
      if(this.search.table){
        params['tableGuid'] = this.search.table;
      }
      this.tableLoading = true;
      getColumnSensitiveLog(params).then(
        res => {
          if(res.data.data){
            this.tableData = res.data.data.records.map(
              item => {
                let addSensitiveColumn = item.addSensitiveColumn ? JSON.parse(item.addSensitiveColumn) : [] ,
                    dropSensitiveColumn = item.dropSensitiveColumn ? JSON.parse(item.dropSensitiveColumn) : [] ;
                return {
                  ...item,
                  addSensitiveColumns: addSensitiveColumn.map(col => col.columnName),
                  dropSensitiveColumns: dropSensitiveColumn.map(col => col.columnName),
                  biaoji: '已标记敏感',
                  clState: '已处理'
                }
              }
            );
            this.page.total = res.data.data.total;
          }
          this.tableLoading = false;
        }
      ).catch(e => {
        this.tableLoading = false;
      })
    },
    // 清空
    emptHandle () {
      this.search.createTime=null
      this.search.database=''
      this.search.table=''
    },
    // 搜索
    searchChange(){
      this.page.currentPage = 1;
      this.onLoad();
    },
    btnClick(code){
      if(code === 'refresh'){
        this.$refs.crud.refreshChange();
      }
      if(code === 'operation'){
        this.$refs.crud.$refs.dialogColumn.columnBox = true;
      }
    }
  }
}
</script>

<template>
  <avue-crud
    ref="table"
    :data="tableData"
    :page.sync="page"
    :option="tableOption"
    :table-loading="tableLoading"
    :search.sync="search"
    @search-change="searchChange"
    @on-load="onLoad"
    @refresh-change="onLoad"
    @sort-change="sortChange"
  >
    <!-- <template slot-scope="{ size }" slot="menuLeft">
      <el-button
          icon="el-icon-download"
          :size="size || 'small'"
          :loading="downLoading"
          @click="getMetaCommonListExport"
          >导 出</el-button
        >
    </template> -->
    <!-- <template slot="tran_dateSearch" slot-scope="scope">
      <el-date-picker class="w-100p" v-model="search.tran_date" :unlink-panels="true"
        value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始时间"
        end-placeholder="结束时间">
      </el-date-picker>
    </template>
    <template slot="acc_dateSearch" slot-scope="scope">
      <el-date-picker class="w-100p" v-model="search.acc_date" :unlink-panels="true"
        value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始时间"
        end-placeholder="结束时间">
      </el-date-picker>
    </template> -->
  </avue-crud>
</template>
<script>
import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  delMetaCommon,
  getMetaCommonDetail,
  getMetaCommonListTree,
  getMetaCommonListExport
} from "@/api/busiMode/metaCommon";
import * as dictService from "@/api/system/dictbiz";

export default {
  data(){
    return {
      model: 'wisdom_park.wp_station_info',
      modelView: 'wisdom_park.v_wp_station_info',
      tableLoading: false,
      downLoading: false,
      tableData: [],
      search: {
        
      },
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableOption: {
        height: 'auto',
        // calcHeight: 10,
        // labelPosition: 'top',
        // searchMenuPosition: 'right',
        searchMenuSpan: 24,
        border: true,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        viewBtn: true,
        labelWidth: 200,
        menuWidth: 100,
        // defaultSort: {
        //   prop: 'tran_date',
        //   order: 'descending'
        // },
        column: [
          {
            label: "充电站设备ID",
            prop: "station_id",
            width: 200,
            // search: true
          },
          {
            label: "充电站设备名称",
            prop: "station_name",
            width: 200,
            // search: true
          },
          {
            label: "充电站状态",
            prop: "station_status",
            width: 200,
            dicData: [
              { label: "关闭下线", value: '5'},
              { label: "正常使用", value: '50'}
            ],
            dataType: 'string',
            // search: true
          },
          {
            label: "运营商ID",
            prop: "dealer_id",
            width: 150,
          },
          {
            label: "省市行政编码",
            prop: "area_code",
            width: 150,
          },
          {
            label: "详细地址",
            prop: "address",
            width: 250,
          },
          {
            label: "站点电话",
            prop: "station_tel",
            width: 150,
          },
          {
            label: "平台服务电话",
            prop: "service_tel",
            width: 150,
          },
          {
            label: "站点类型",
            prop: "station_type",
            width: 150,
            type: 'select',
            dicUrl: dictService.getUrl("StationType"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: 'string',
          },
          {
            label: "车位数量",
            prop: "park_nums",
            width: 150,
          },
          {
            label: "经度",
            prop: "station_lng",
            width: 200,
          },
          {
            label: "纬度",
            prop: "station_lat",
            width: 150,
          },
          {
            label: "建设场所",
            prop: "construction",
            width: 150,
            type: 'select',
            dicUrl: dictService.getUrl("StationConstruction"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: 'string',
          },
          {
            label: "营业时间描述",
            prop: "busine_hours",
            width: 150,
          },
          {
            label: "电费描述",
            prop: "electricity_fee",
            width: 150,
          },
          {
            label: "服务费描述",
            prop: "service_fee",
            width: 150,
          },
          {
            label: "备注信息",
            prop: "remark",
            width: 150,
          },
        ]
      },
      orders: [
        // {field: 'tran_date' , sort: 'DESC'}
      ]
    }
  },
  props: {
    
  },
  mounted(){

  },
  methods: {
    sortChange({column , prop , order}){
      this.orders = [
        {
          field: prop,
          sort: order === 'descending' ? 'DESC' : 'ASC'
        }
      ];
      this.page.currentPage = 1;
      this.onLoad()
    },
    getMetaCommonListExport() {
      let wheres = this.getSearch();
      this.downLoading = true;
      getMetaCommonListExport(this.modelView, {
        current: 1,
        size: this.page.pageSize,
        wheres: wheres,
      })
        .then((res) => {
          this.downLoading = false;
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.download = "银行对账流水" + ".xls";
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          this.downLoading = false;
        });
    },
    getSearch(){
      let form = this.search , wheres = [];
      Object.keys(form).forEach(
        keyName => {
          if(keyName !== 'ACTIVENAME' && keyName[0] !== '$' && (form[keyName] ?? '') !== ''){
            const column = this.findObject(this.tableOption.column, keyName);
            if(column['searchRange']){
              let startVal = form[keyName][0] , endVal = form[keyName][1];
              if(startVal && endVal){
                // if(column['type'] === 'date'){
                //   startVal = `${startVal} 00:00:00`
                // }
                wheres.push({ field: keyName, value: startVal, op: "GE" });

                // if(column['type'] === 'date'){
                //   endVal = `${endVal} 23:59:59`
                // }
                wheres.push({ field: keyName, value: endVal, op: "LE" });
              }
            }else if(column['searchLike']){
              wheres.push({field: keyName , op: 'LIKE', value: form[keyName]})
            }else{
              wheres.push({field: keyName , value: form[keyName]})
            }
          }
        }
      );
      return wheres;
    },
    searchChange(params, done){
      this.page.currentPage = 1;
      done();
      this.onLoad()
    },
    onLoad(){

      this.tableLoading = true;
      this.tableData = [];
      let wheres = this.getSearch();

      getMetaCommonList(this.model , {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres,
        orders: this.orders
      }).then(
        res => {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
        }
      ).catch(e => {
        this.tableLoading = false;
      })
    }
  }
}
</script>
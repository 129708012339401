
export const ynList = [{
      label: '否',
      value: false
    },
     {
      label: '是',
      value: true
    }]
    export const alignList = [{
      label: '居左',
      value: 'left'
    }, {
      label: '居中',
      value: 'center'
    }, {
      label: '居右',
      value: 'right'
    }]
    export const operBtns= [
      {
        operName: "回呼",
        key: "phoneHH",
        appl: "row",
        operType: "js",
        tip: "确定要回拨电话吗？",
        click: "window.softphone.dialout('#query#')",
      },
      {
        operName: "转译文本",
        key: "zywb",
        appl: "row",
        operType: "dict",
        tip: "确定呀之前当前操作吗？",
        model: "",
      },
      {
        operName: "录音回放",
        appl: "row",
        key: "lyhf",
        operType: "js",
        tip: "",
        click: "",
      },
    ]
   export const tableSetOption = {
    menuBtn: false,
    size: 'mini',
    column: [
    {
      label: '表格高度',
      prop: 'height',
      type: 'number',
      span: 24,
    }, 
    {
      label: '显示序号',
      prop: 'index',
      type: 'radio',
      span: 24,
      dicData: ynList
    }, {
      label: '序号标题',
      span: 24,
      prop: 'indexLabel'
    }, {
      label: '支持多选',
      prop: 'selection',
      type: 'radio',
      span: 24,

      dicData: ynList
    }, {
      label: '显示边框',
      prop: 'border',
      type: 'radio',
      span: 24,
      dicData: ynList
    }, 
    // {
    //   label: '折叠',
    //   prop: 'expand',
    //   type: 'radio',
    //   span: 24,
    //   dicData: ynList
    // }, 
    {
      label: '表头对齐',
      prop: 'headerAlign',
      type: 'select',
      span: 24,
      dicData: alignList
    }, {
      label: '单元格对齐',
      prop: 'menuAlign',
      type: 'select',
      span: 24,
      dicData: alignList
    }, {
      label: '选中高亮',
      prop: 'highlightCurrentRow',
      type: 'radio',
      span: 24,
      dicData: ynList
    }, {
      label: '展示斑马线',
      prop: 'stripe',
      type: 'radio',
      span: 24,
      dicData: ynList
    },
    {
      label: '显示分页',
      prop: 'showPage',
      type: 'radio',
      span: 24,
      dicData: ynList
    }]
    // }, {
    //   labelWidth: 140,
    //   label: '搜索按钮单独成行',
    //   prop: 'searchMenuSpan',
    //   type: 'number',
    //   span: 12
    // }]
  }

  export const newOption={
    column: [],
    labelPosition: 'right',
    labelSuffix: ' ',
    labelWidth: 80,
    gutter: 0,
    menuBtn: true,
    submitBtn: true,
    submitText: '保存',
    emptyBtn: true,
    emptyText: '清空',
    menuPosition: 'center'
  }
  export const allOptions = {
    tableOption:{
      dateBtn: false,
      border: false,
      height: "auto",
      tip:true,
      searchShow: true,
      searchMenuSpan: 12,
      addBtn:false,
      refreshBtn: true, //刷新按钮
      addRowBtn: false, //表格行内添加按钮
      columnBtn: true, //列显隐按钮
      delBtn: false, //	行内删除按钮
      editBtn: false, //行内编辑按钮
      cancelBtn: false, //行编辑取消按钮
      rowKey: "",
      showPage: true,
      index: true,
      indexLabel: "序号",
      selection: false,
      showHeader:true,
      expand: false,
      // headerAlign: "left",
      // align: "left",
      highlightCurrentRow: true,
      stripe: false,
      menuWidth:200,
      reserveSelection: true,//换页不清空当前的选中数据
      column: []
    },
    newOption:{
      column: [],
      labelPosition: 'right',
      labelSuffix: ' ',
      labelWidth: 80,
      gutter: 0,
      menuBtn: true,
      submitBtn: false,
      submitText: '保存',
      emptyBtn: false,
      emptyText: '清空',
      menuPosition: 'center'
    },
    seachOption:{
      column: [],
      searchBtn:false,
      labelPosition: 'left',
      labelWidth: 80,
      gutter: 0,
      menuBtn: true,
      menuSpan:3,
      menuPosition:"left",
      submitText: '搜索',
      submitBtn:false,
      emptyBtn: false,
      emptyText: '清空',
      btnLoading:true
    },
    menuList:[{
      label: '新增',
      value: 'add',
      icon:"el-icon-plus",
      type:"primary",
      show:true,
      actionUrl:"/api/system/add",
      queryParam:"",
      queryType:"get",
      showPosotion:"left"  //left(在表格的左上) table(在表格里面)
    },
    {
      label: '导入',
      value: 'important',
      type:"",
      icon:"el-icon-upload2",
      show:true,
      showPosotion:"left" 
    },
    {
      label: '修改',
      value: 'edit',
      type:"text",
      icon:"el-icon-edit",
      show:true,
      showPosotion:"table" 
    }, 
    {
      label: '查看',
      value: 'view',
      type:"text",
      icon:"el-icon-view",
      show:true,
      showPosotion:"table" 
    }, 
    {
      label: '删除',
      value: 'del',
      type:"text",
      icon:"el-icon-delete",
      showPosotion:"table" ,
      show:true
    }],
    newOpenType:"dialog",//"target"
    showTree:false
  }
  export const dicList = [{
    label: '输入框',
    value: 'input'
  }, {
    label: '选择框',
    value: 'select'
  }, {
    label: '密码框',
    value: 'password'
  }, {
    label: '数字框',
    value: 'number'
  }, {
    label: '文本框',
    value: 'textarea'
  }, {
    label: '上传框',
    value: 'upload'
  }, {
    label: '颜色选择器',
    value: 'color'
  }, {
    label: '图标选择器',
    value: 'icon-select'
  }, {
    label: '树框',
    value: 'tree'
  }, {
    label: '单选框',
    value: 'radio'
  }, {
    label: '多选',
    value: 'checkbox'
  }, {
    label: '开关框',
    value: 'switch'
  }, {
    label: '级联框',
    value: 'cascader'
  }, {
    label: '日期框',
    value: 'date'
  }, {
    label: '时间框',
    value: 'time'
  }, {
    label: '日期时间框',
    value: 'datetime'
  }, {
    label: '日期范围',
    value: 'daterange'
  }, {
    label: '时间范围',
    value: 'timerange'
  }, {
    label: '日期时间范围',
    value: 'datetimerange'
  }, {
    label: '年',
    value: 'year'
  }, {
    label: '月',
    value: 'month'
  }, {
    label: '周',
    value: 'week'
  }, {
    label: '多个日期',
    value: 'dates'
  }]
  export function formatOptionsFromData(datasource,modelData,options,modelChooseGuid) {
     
      let datasourceId=datasource.code;
      options.tableOption.column=[];
      options.seachOption.column=[];
      if(options.newOption){
        options.newOption.column=[];
      }else{
        options.newOption=newOption
      }
      options.seachOption.menuSpan=4
      let width=0;
      let formLable=formatFormMaxLabelWidth(JSON.stringify(modelData))
      modelData.forEach((element,index) => {
        if(element.isPk){
          options.PK=element.columnName
        }
        if(element.tableShow){
           width+=Number(formatWidth(element))
        }
       
        let table={
          width: formatWidth(element),
          overHidden:true,
          align:formatAlign(element),
          label: element.description,
          prop: element.columnName,
          hide: !element.tableShow,
        }
        if(element.fields){
          if(element.model&&element.showFiled&&element.backfill!=""&&element.backfill==element.fields){
            console.log(element)
            table.showFiledBackfill=element.columnName+"_"+element.showFiled+"@"+element.model+"."+element.showFiled
          }
        }
        
        let tables= Array.from(options.tableOption.column,({prop})=>prop)
       // if(!table.showFiledBackfill){
          options.tableOption.column.push(table);
       // }

       
        
      //搜索
       if(element.IsSearch){
         let props= Array.from(options.seachOption.column,({prop})=>prop)
         if(props.includes(element.columnName)){
          options.seachOption.column.forEach(o => {
            if(o.prop==element.columnName){
              o.type=formatType(element)
              if(element.dateTimeFormat!=''){
                o.format= element.dateTimeFormat;
                o.valueFormat=element.valueFormat=='timestamp'?'timestamp':"";
              }
              o.dataType=formatDataType(element.type),
              o=Object.assign(o,element)
            }
          });
         }else{
           let obj={ 
            label: element.description,
            prop: element.columnName,
            valueDefault:element.defaultValue,
            type:formatTypeSearch(element),
            dataType:formatDataType(element.type),
            display:true,
            labelWidth:formatFormLabelWidth(element),
            width:'120',
            span:formatSpan(options,element),
            show:true,
              // rules: [
              //   {  max: element.columnLength, message: '长度不能超过'+ element.columnLength+'个字符', trigger: 'blur' }
              // ],
            }
          if(element.fields){
          
            obj.dicUrl="/api/jida-meta/common-ops/list/"+datasourceId+"."+element.model
            obj.props= {
              label: element.showFiled,
              value: element.fields,
            }
            obj.dicMethod='post'
            obj.dicQuery={"hasPage":false}
            obj.dataType=formatDataType(element.type)
            obj.filterable=true
            obj.type="select"
          }          
          if(element.dictionaryCode!=''){
            obj.type="select"
            obj.dataType=formatDataType(element.type)
            obj.filterable=true
            obj.dicUrl= "/api/jida-system/dict-biz/dictionary-tree?code="+element.dictionaryCode,
            obj.props= {
              label: "dictValue",
              value: "dictKey",
            }
          }
          if(element.type=='region'||element.type=='cascader'){
            // newObj.dicUrl= `/api/jida-system/region/lazy-tree?parentCode=00`,
            obj.props= {
                   label: "title",
                   value: "value",
             },
             obj.checkStrictly=true
             obj.lazy= true;
           }
          if(element.dateTimeFormat){
            obj.format=element.dateTimeFormat
            obj.valueFormat=element.dateTimeFormat
          }
         
          options.seachOption.column.push(obj)
         }
        }
        
        //新增
          let newObj = {
          label: element.description,
          prop: element.columnName,
          valueDefault:element.defaultValue,
          type:formatType(element),
          dataType:formatDataType(element.type),
          readonly: element.isEdit?false:true,
          display:element.defaultValue?false:true,
          rules: [
            {
              required: element.isNotNull,
              message: "请输入"+element.description,
              trigger: "blur",
            },
            {  max: element.columnLength, message: '长度不能超过'+ element.columnLength+'个字符', trigger: 'blur' }
            ],
          }
          if(element.model){
            //关联字段和回填字段不等的时候 为冗余字段
              if(element.backfill&&modelChooseGuid!=element.model&&element.backfill!=element.fields){//判断冗余字段
                newObj.dicUrl="/api/jida-meta/common-ops/list/"+datasourceId+"."+element.model
                newObj.dicMethod='post'
                newObj.dicQuery={"hasPage":false}
                newObj.display=false
                newObj.dataType=formatDataType(element.type)
                newObj.filterable=true

                newObj.fields=element.fields;
                newObj.backfill=element.backfill;
                newObj.props= {
                  label: element.showFiled,
                  value: element.backfill,
                }
              }else{
                newObj.dicUrl="/api/jida-meta/common-ops/list/"+datasourceId+"."+element.model
                newObj.props= {
                    label: element.showFiled,
                    value: element.fields,
                  }
                  newObj.dicMethod='post'
                  newObj.dicQuery= {"hasPage":false}
                  
                  newObj.dataType=formatDataType(element.type)
                  newObj.filterable=true
                  newObj.type="select"
              }
            }else if(element.dictionaryCode!=''){
            newObj.type="select"
            newObj.dataType=formatDataType(element.type)
            newObj.filterable=true
            newObj.dicUrl= "/api/jida-system/dict-biz/dictionary-tree?code="+element.dictionaryCode,
            newObj.props= {
              label: "dictValue",
              value: "dictKey",
            }
            newObj.dicQuery= {"hasPage":false}
                
          } else if(element.type=='upload'||element.type=='attachFile'){
            newObj.listType= 'picture-img';
            newObj.accept=element.fileSuffix;//上传类型
            newObj.propsHttp= {
              res: "data",
              url: "link",
              name: "attachId",
            }
            //newObj.limit=1
            newObj.listType= "picture-card"
          //  newObj.tip='只能上传'+element.fileSuffix+'的图片'
            newObj.action= '/api/jida-resource/oss/endpoint/put-file-attach'
          }
          if(element.type=='map'||element.type=='coordinate'){
            newObj.params={
              zoom: 10,
            }
            newObj.coordinateType=element.coordinateType
            newObj.rules=[
              {
                required: element.isNotNull,
                message: "请输入"+element.description,
                trigger: "change",
              }
              ]
            console.log(newObj)
          }
          if(element.type=='region'||element.type=='cascader'){
           // newObj.dicUrl= `/api/jida-system/region/lazy-tree?parentCode=00`,
            newObj.props= {
                  label: "title",
                  value: "value",
            },
            newObj.checkStrictly=true
            newObj.lazy= true;
            newObj.rules=[
              {
                required: element.isNotNull,
                message: "请输入"+element.description,
                trigger: "change",
              }
              ]
          }
          if(element.dateTimeFormat){
            newObj.format=element.dateTimeFormat
            newObj.valueFormat=element.dateTimeFormat
          }
          newObj.labelWidth=formLable;
          newObj.span=12;
          options["newOption"]["column"].push(newObj)
    });
    if(width<document.getElementsByClassName("designer")[0].clientWidth){
      options.tableOption.column.forEach((e,i) => {
          e.width='auto'
      });
    }
    document.getElementsByClassName("designer")[0].clientWidth
    return options;
  }
  export function optionToModelS(modelData,searchOptions){
      searchOptions.forEach(search => {
        modelData.forEach((model,i) => {
        if(model.columnName==search.prop){
          modelData[i].IsSearch=true
        }
      })
    });
    return modelData
  }
  
  export function formatTypeSearch(t){
    let type="input"
    if(t.type=="DateTime"){
      type="datetimerange"
    }
    else if(t.type=="attachFile"){
      type="upload"
    }else 
    if(t.type=="coordinate"){
      type="map"
    }else
     if(t.type=="region"){
      type="cascader"
    }else{
      type="input"
    }
    return type
  }
  export function formatType(t){
    let type="input"
    //dateTimeFormat 
    if(t.type=="DateTime"){
      if(t.dateTimeFormat=='yyyy-MM-dd'){
        type='date'
      }else if(t.dateTimeFormat=='yyyy-MM-dd HH:mm:ss'){
        type='datetime'
      }else {
        type='date'
      }
    }
    else if(t.type=="attachFile"){
      type="upload"
    }else 
    if(t.type=="coordinate"){
      type="map"
    }else
     if(t.type=="region"){
      type="cascader"
    }else{
      type="input"
    }
    return type
  }

  export function formatDataType(t){
    let type="String"
    if(t=="DateTime"){
      type="date"
    }
    if(t=="Integer"){
      type="number"
    }
    else {
      type="String"
    }
    return type
  }
  export function formatAlign(t){
    let align='left';
    if(t.type=="Integer"||t.type=="Long"||t.type=="Double"){
      align='right'
    }else if(t.type=="DateTime"){
      align='center'
    }
    return align
  } 
  export function formatWidth(t){
    let width=100;
    // console.log(columns.length,'@@@',index)
    // if(columns.length==index+1){
    //   width='auto'
    // }else{
      if(t.type=='Boolean'){
        width='50'
      }else if(t.type=='String'){
        if(t.columnLength<50||t.columnLength>100){
          width=150;
        }else{
          width=t.columnLength*2;
        }
      }else if(t.type=="DateTime"){
        width='150'
        if(t.dateTimeFormat=='yyyy-MM-dd HH:mm:ss'){
          width='150'
          console.log(width)
        }
    }   
    return width
  }
  export function formatFormLabelWidth(t){
    let width=80;
    if(t && t.description){
      width=t.description.length*13+20
    }
    return width
  }
  export function formatSpan(options,element){
    console.log(element)
    let span=4;
    let s=document.body.offsetWidth/24;
    if(options.showTree){
      s=(document.body.offsetWidth-320)/24
    }
    if(element.type=="DateTime"){
      span=(formatFormLabelWidth(element)+360)/s;
    }else{
      span=(formatFormLabelWidth(element)+180)/s;
    }
   
    return Math.round(span)
  }
  
  export function formatFormMaxLabelWidth(models){
    let w=150;
    let modelList=JSON.parse(models)
    modelList.sort((a,b)=> {
        return formatFormLabelWidth(a)-formatFormLabelWidth(b)
    });
    w=formatFormLabelWidth(modelList[modelList.length-1])
    return w;
  }
  
  
  
  

import * as dictService from "@/api/system/dictbiz";

   export const optionEnter = {
    height:"auto",
    
      search:false,
      dateBtn: false,
      border: true,
      tip: true,
      searchShow: false,
      addBtn: false,
      refreshBtn: true,
      addRowBtn: false,
      columnBtn: true,
      delBtn: false,
      editBtn: false,
      cancelBtn: false,
      rowKey: "",
      showPage: true,
      index: true,
      indexLabel: "序号",
      selection: false,
      showHeader: true,
      calcHeight: 0,
      expand: false,
      highlightCurrentRow: true,
      stripe: false,
      emptyBtn:false,
      menuWidth: 150,
      reserveSelection: true,
      column: [
        {
          width: "auto",
          overHidden: true,
          align: "right",
          label: "主键id",
          prop: "id",
          hide: true,
        },
        {
          width: "200",
          overHidden: true,
          align: "left",
          label: "名称",
          fixed: true,
          prop: "name",
          search:false,
          hide: false,
        },
       
        {
          width: "200",
          sortable:true,
          overHidden: true,
          align: "left",
          type: "date",
          label: "开始时间",
          prop: "start_time",
          format: "yyyy-MM-dd HH:mm:ss",
          valueFormat: "yyyy-MM-dd HH:mm:ss",
        },
        {
          width: "200",
          sortable:true,
          overHidden: true,
          align: "left",
          type: "date",
          label: "结束时间",
          prop: "end_time",
          format: "yyyy-MM-dd HH:mm:ss",
          valueFormat: "yyyy-MM-dd HH:mm:ss",
        },
        {
          width: "100",
          overHidden: true,
          align: "left",
          label: "播放总时长",
          prop: "play_total_times",
        },
        {
         
          overHidden: true,
          align: "left",
          label: "播放频率",
          prop: "mode",
          hide: false,
          dicData:[ 
          { label: '天', value: 'daily' },
          { label: '周', value: 'weekly' },
          { label: '轮播', value: 'loop' },
          { label: '自定义', value: 'selfDefine' },
        ]
        },
       
        {
          width: "200",
          overHidden: true,
          align: "left",
          label: "创建人",
          prop: "create_user",
          hide: false,
        },
        {
          width: "200",
          sortable:true,
          overHidden: true,
          align: "left",
          type: "date",
          label: "创建时间",
          prop: "create_time",
          format: "yyyy-MM-dd HH:mm:ss",
          valueFormat: "yyyy-MM-dd HH:mm:ss",
        },
        
       
       
 
      ],
    
    }

 
  
  
  
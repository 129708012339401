<template>
  <div class="container designer form-designer-page">
    <el-row class="mar-t-15f">
      <el-col :span="24">
        <el-menu
          :default-active="activeBtn"
          class="el-menu-demo mar-b-15"
          mode="horizontal"
          @select="handleClick"
        >
          <el-menu-item index="data">数据配置</el-menu-item>
          <el-menu-item index="page" :disabled="modelChooseEd == 'false'"
            >表单页面</el-menu-item
          >
        </el-menu>
      </el-col>
    </el-row>
    <el-button
      type="primary"
      size="small"
      icon="el-icon-check"
      class="btnSaveClass mar-t-15f"
      @click="saveDynamicForm"
      >保存
    </el-button>
    <el-row v-show="activeBtn == 'data'">
      <el-col :span="24">
        <dataDesign
          ref="dataDesign"
          :entityId="options.guid"
          @modelChoose="modelChoose"
        ></dataDesign>
      </el-col>
    </el-row>
    <el-row v-show="activeBtn == 'page'">
      <avue-form-design
        ref="formDesign"
        v-if="activeBtn == 'page'"
        style="height: calc(100vh - 185px)"
        :options="options"
        @submit="handleSubmit"
        storage
        show-avue-doc
      >
      </avue-form-design>
    </el-row>
  </div>
</template>
<script>
/**
 * 1.0版本
 */
import draggable from "vuedraggable";
import {
  saveDynamicForm,
  getDetailByPlanId,
  updateModelBuild,
} from "@/api/busiMode/meta";
import modelTree from "../table/dataConfig/modelsTree";
import { getLazyList, remove, update, add, getMenu } from "@/api/system/menu";
import dataDesign from "../table/dataConfig/dataDesign";
import { automatic } from "@/api/busiMode/meta";

let tempActiveData;

export default {
  name: "formDesigner",
  components: {
    draggable,
    modelTree,
    dataDesign,
  },
  props: {
    app: {
      type: Object,
      default: function () {
        return {
          activeBtn: "page",
          appId: "",
          id: "",
        };
      },
    },
  },
  watch: {
    // options: {
    //   handler(newName, oldName) {
    //     console.log(newName);
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },
  data() {
    return {
      dataSoure: { dataSourceId: "", dataSourceName: "" },
      formItems: {},
      designList: [],
      activeData: {},
      options: {},
      menuId: "",
      modelId: "",
      activeBtn: "data",
      entityId: "",
      dashbordId: "",
    };
  },
  mounted() {
    this.modelId = this.$route.fullPath.split(
      "/data/form/formCommon/?modleId"
    )[1];
    this.app.appId = this.$route.query.appId;
    this.app.id = this.$route.query.id;
    this.menuId = this.$route.query.menuId;
    if (this.modelId) {
      this.getDetailByPlanId();
    }
  },
  methods: {
    handleClick(d) {
      if (d == "page") {
        this.changeToPage();
      }
      this.activeBtn = d;
    },
    getDetailByPlanId() {
      getDetailByPlanId({ id: this.modelId }).then((res) => {
        if (res.data.code == 200) {
          this.dataSoure.dataSourceId = res.data.data.id;
          console.log(res.data.data.id, "res.data.data.id");
          this.dashbordId = res.data.data.id;
          this.dataSoure.dataSourceName = res.data.data.name;
          this.options = JSON.parse(res.data.data.content);
        }
      });
    },
    changeToPage() {
      this.activeBtn = "page";
      // this.options = formatOptionsFromData(this.$refs.dataDesign.modelData);
      // this.importCode = this.options.tableOption;
      // this.entityId = this.$refs.dataDesign.entityId;
      // this.modelId = this.$refs.dataDesign.entityId;
      // this.getMetaCommonList();
      console.log(JSON.stringify(this.$refs.dataDesign.modelData));
    },
    //选完模型
    modelChoose(data) {
      this.dataSoure = data;
    },
    automaticById(id) {
      let obj = { guid: id, type: "F" };
      automatic(obj).then((res) => {
        if (res.data.code == 200) {
          let obj = { planId: res.data.data };
          this.options = res.data.data;
          this.getMetaCommonList();
        }
      });
    },
    saveDynamicForm() {
      //updateModelBuild
      this.$refs.formDesign.handleGenerate();
      let obj = {};
      alert(123123);
      setTimeout(() => {
        this.options.guid = this.entityId;
        obj = {
          appId: 1,
          appModelId: 574567,
          tenantId: this.$store.state.user.tenantId,
          content: JSON.stringify(this.options),
          type: "F",
        };

        if (this.dashbordId) {
          obj.id = this.dashbordId;
          updateModelBuild(obj).then((res) => {
            if (res.data.code == 200) {
              //修改菜单
              this.rowUpdate(res.data.data.id);
            }
          });
        } else {
          saveDynamicForm(obj).then((res) => {
            if (res.data.code == 200) {
              //修改菜单
              this.rowUpdate(res.data.data.id);
            }
          });
        }
      }, 500);
    },
    rowUpdate(id) {
      if (!this.menuId) {
        this.menuId = this.modelId;
      }
      getMenu(this.menuId).then((res) => {
        let obj = res.data.data;
        obj.path = "/data/form/formCommon" + "/?modleId" + id;
        update(obj).then(() => {
          this.$router.push({
            path: "/data/form/formCommon" + "/?modleId" + id,
          });
          this.$store.commit("SET_VIEWEDIT", false);
        });
      });
    },
    handleSubmit(data) {
      this.options = data;
    },
    cloneComponent(origin) {
      const clone = JSON.parse(JSON.stringify(origin));
      if (!clone.layout) clone.layout = "colItem";
      if (clone.layout === "colItem" || clone.layout === "dynamicItem") {
        let uId = "fd_";
        clone.id = uId;
        clone._id = uId;
        tempActiveData = clone;
      } else {
        let uId = "row_";
        clone.id = uId;
        clone._id = uId;
        tempActiveData = clone;
      }
      this.$refs.designer.activeItem = tempActiveData;
    },
    onEnd(obj) {
      if (obj.from !== obj.to) {
        this.activeData = tempActiveData;
        this.$refs.designer.activeItem = this.activeData;
        if (obj.to.className.indexOf("row-drag") < 0) {
          this.designList.splice(obj.newIndex, 0, this.activeData);
        }
      } else {
        this.$refs.designer.activeItem = {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btnSaveClass {
  position: absolute;
  right: 20px;
  top: 50px;
}
.modelTreePo {
  position: absolute;
  right: 30px;
  z-index: 1;
}
</style>

<template>
    <div class="h-100p">
        <basic-container v-show="!showTableDetail" v-loading="loading"  class="h-100p" :body-style="{'height': '100%' , padding: '0' , position: 'relative'}">
            <!-- <div class="bor" ref="dataAssetsMapMinimap" style="position: absolute; bottom: 5px; right: 5px;background: #fff;"></div> -->
            <div v-if="!isError">
                <div class="lh-50 pad-lr-15 bor-b">
                    <template v-for="(item , index) of toolbarButton">
                        <div :key="index" v-if="item.type === 'divider'" class="el-divider el-divider--vertical"></div>
                        <el-tooltip :key="index" v-if="item.type === 'button'" class="item" effect="dark" :content="item.desc || item.name || ''" placement="top">
                            <el-button :disabled="item.disabled" @click="toolbarClick(item)" size="small" class="el-button operBtn el-button--text"><i v-if="item.icon" :class="item.icon"></i> {{item.name || ''}}</el-button>
                        </el-tooltip>
                        <span :key="index" v-if="item.type === 'search'" class="push-right">
                            <el-select
                                v-model="searchTableId"
                                clearable
                                filterable
                                remote
                                :placeholder="item.desc || item.name || ''"
                                :remote-method="remoteMethod"
                                @change="searchTableNameChange"
                            >
                                <el-option
                                v-for="item in searchTables"
                                :key="item.id"
                                :label="item.label"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </span>
                    </template>
                </div>
            </div>
            <no-data class="pad-t-40" v-if="isError">
                <div class="text-center">
                    <el-button size="small" type="primary" @click="loadInitData">重新加载</el-button>
                </div>
            </no-data>
            <div v-if="!isError" style="height: calc(100% - 52px)" class="w-100p">
                <div v-show="(level === 1 || (level === 2 && showType === 'chartShow'))" class="w-100p h-100p" ref="dataAssetsMap"></div>
                <div class="pad-20 w-100p h-100p" v-if="(level === 2 && showType === 'tableShow')">
                    <avue-crud
                        ref="table"
                        :data="tableData"
                        :page.sync="page"
                        :option="tablesOption"
                        @on-load="searchTable"
                        @refresh-change="searchTable"
                    >
                        <template slot="tableName" slot-scope="{row}">
                            <a class="cur-p" @click="_event_page_tableClick(row)">{{row.tableName}}</a>
                        </template>
                        <template slot="menuLeft">
                            <el-input
                                v-model="keyword"
                                @keyup.native.enter="page.currentPage = 1; searchTable()"
                                size="small"
                                :placeholder="$t('Please enter keyword')"
                                type="text"
                                style="width:180px;"
                                >
                                <el-button
                                    class="mar-0 pad-0"
                                    size="small"
                                    @click="page.currentPage = 1; searchTable()"
                                    slot="append"
                                    icon="el-icon-search"
                                ></el-button>
                            </el-input>
                        </template>
                    </avue-crud>
                </div>
            </div>
            <!-- <el-drawer
                :visible.sync="drawer"
                :with-header="false"
                append-to-body
                size="70%"
            >
                <DataAssetsDetail
                    v-if="drawer"
                    :isRoute="false"
                    :propGuid="dataAssetsDetailProps.propGuid"
                    :propTableName="dataAssetsDetailProps.propTableName"
                    :propDatabaseId="dataAssetsDetailProps.propDatabaseId"
                    :propDatabaseName="dataAssetsDetailProps.propDatabaseName"
                ></DataAssetsDetail>
            </el-drawer> -->
        </basic-container>
        <DataAssetsDetail
            v-if="showTableDetail"
            :isRoute="false"
            :showOperation="false"
            :propGuid="dataAssetsDetailProps.propGuid"
            :propTableName="dataAssetsDetailProps.propTableName"
            :propDatabaseId="dataAssetsDetailProps.propDatabaseId"
            :propDatabaseName="dataAssetsDetailProps.propDatabaseName"
            @back="closeOpen('showTableDetail')"
        ></DataAssetsDetail>
    </div>
</template>

<script>

import { G6 , GraphOptions , LayoutConfigs , ToolbarButtons} from './map/g6'
import CreateRanderData from './map/map.js';
import MapEvents from './map/map-event';
import RequestData from './map/request-data';
import DataAssetsDetail from './assets-detail';

export default {
    components: {
        DataAssetsDetail
    },
    mixins: [ CreateRanderData , MapEvents , RequestData],
    data(){
        return {
            keyword: '',
            dataAssetsDetailProps: {
                propGuid: null,
                propTableName: '',
                propDatabaseId: null,
                propDatabaseName: ''
            },
            showTableDetail: false,
            graph: null,
            dataHierarchys: [],
            datasources: [],
            applications: [],
            tables: [],
            tasks: [],
            level: 1,
            currentLayou: null,
            loading: false,
            isError: false,
            datasourceCombo: false,
            showDHIndicators: ['tableNum' , 'allRecordNum' , 'allStorage' , 'columnNum'],
            showTableIndicators: ['allRecordNum' , 'allStorage' , 'columnNum' , 'dayRecordNum'],
            toolbarButton: [],
            searchTableId: '',
            searchTables: [],
            showType: 'chartShow',
            tableData: [],
            page: {
          pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
                pageSize: 10,
                currentPage: 1,
                total: 0
            },
            tablesOption: {
                addBtn: false,
                editBtn: false,
                delBtn: false,
                menu: false,
                refreshBtn: false,
                columnBtn: false,
                column: [
                    {
                        label: "表名称",
                        prop: "tableName",
                        width: 200
                    },
                    {
                        label: "字段数",
                        prop: "columnNum"
                    },
                    {
                        label: "数据量（条）",
                        prop: "dataRows"
                    },
                    {
                        label: "日增数据量（条）",
                        prop: "dataRowsIncreaseByDay"
                    },
                    {
                        label: "占用存储空间",
                        prop: "dataSize"
                    },
                    {
                        label: "日增占用存储空间",
                        prop: "dataSizeIncreaseByDay"
                    },
                ]
            }
        }
    },
    mounted(){
        this.loadInitData();
    },
    methods: {
        closeOpen(name){
            this.$nextTick(() => {
                this[name] = false;
            })
        },
        loadInitData(){
            this.isError = false;
            this.loading = true;
            this.axios.all([
                this._getData_datasourcesList(),
                this._getData_taskCount(),
                this._getData_datahierarchy(),
                this._getData_getApps()
            ]).then(
                this.axios.spread((datasources , taskCount) => {
                    if(this.datasources.length > 10){
                        this.datasourceCombo = true;
                    }
                    this.loading = false;
                    if(!this.isError){
                        this.render(this.getRanderData());
                    }
                })
            ).catch(e => {

            });
        },
        initGraph(){
            if(this.graph){
                this.graph.destroy();
                this.graph = null;
            }
            this.toolbarButton = this.getToolbarButton();
            this.graph = new G6.Graph({
                container: this.$refs.dataAssetsMap,
                ...this.getGraphOption(),
                // plugins: [ this.toolbar ]
            })
            this.bindEvents();
        },
        getToolbarButton(){
            return ToolbarButtons['level_' + this.level] || {};
        },
        getGraphOption(){
            return GraphOptions['level_' + this.level] || [];
        },
        setLayout(){
            if(this.currentLayou !== this.level){
                this.currentLayou = this.level;
                let layoutConfig = LayoutConfigs['level_' + this.level];
                if(layoutConfig){
                    this.graph.updateLayout(layoutConfig);
                }
            }
        },
        render(data){
            if(!this.graph){
                this.initGraph();
            }
            this.setLayout();
            this.graph.data(data);
            this.graph.render();
        },
        clearRender(data){
            this.graph.clear();
            this.render(data || this.getRanderData())
        },
        toolbarClick(item){
            this._event_toolbarClick(item.code);
        },
        searchTableNameChange(){
            if(this.searchTableId){
                this.graph.getNodes().forEach(
                    node => {
                        let model = node.getModel();
                        node.setState('active' , false);
                        if(model.id === this.searchTableId){
                            this.graph.focusItem(model.id , true);
                            node.setState('active' , true);
                        }
                    }
                )
            }
        },
        remoteMethod(query){
            this.searchTables = [];
            let searchTables = [];
            this.tables.forEach(
                item => {
                    if(item.label.indexOf(query) > -1){
                        searchTables.push(item);
                    }
                }
            )
            this.searchTables = searchTables;
        },
        getToolbarShow(item){
            if(item.show === undefined){
                return true;
            }
            if(typeof item.show === 'function'){
                return item.show(this);
            }
            return item.show;
        },
        searchTable(){
            let tables = [];
            if(this.keyword){
                this.tables.forEach(
                    item => {
                        if(item.tableName.indexOf(this.keyword) > -1){
                            tables.push(item);
                        }
                    }
                )
            }else{
                tables = this.tables;
            }
            this._setTableData(tables);
        }
    }
}
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner{
    line-height: 32px;
    height: 32px;
}
.el-input-group__append .el-select, .el-input-group__append .el-button, .el-input-group__prepend .el-select, .el-input-group__prepend .el-button {
    display: inline-block;
    margin: -10px -28px;
}
/deep/ .el-button--text.is-disabled{
    color: #CCC!important;
}
</style>
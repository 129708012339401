<template>
  <basic-container>
    <avue-crud
      ref="table"
      :data="tableData"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      :search.sync="search"
      v-model="form"
      @search-change="onLoad"
      @on-load="onLoad"
      @refresh-change="onLoad"
      @row-save="saveTableForm"
      @row-update="(row, index, done, loading) => saveTableForm(row, done, loading)"
      @row-del="rowDel"
    >
    </avue-crud>
  </basic-container>
</template>
<script>
import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  delMetaCommon,
} from "@/api/busiMode/metaCommon";
import * as dictService from "@/api/system/dictbiz";
export default {
  data(){
    return {
      form: {
        supplementary_terms: ''
      },
      model: 'wisdom_park.wp_asset_storage',
      tableLoading: false,
      tableData: [],
      search: {},
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableOption: {
        height: 'auto',
        labelPosition: 'top',
        searchMenuPosition: 'right',
        searchLabelPosition: 'right',
        searchMenuSpan: 24,
        border: true,
        column: [
          {
            label: "入库单据号",
            prop: "code"
          },
          {
            label: "入库说明",
            prop: "remarks"
          },
          {
            label: "入库人",
            prop: "storage_user"
          },
          {
            label: "入库时间",
            prop: "storage_date"
          },
          {
            label: "入库审批状态",
            prop: "status",
            display: false,
            dicUrl: dictService.getUrl("ApprovalStatus"),
            props: {
              label: "dictValue",
              value: "dictKey",
            }
          }
        ]
      },
    }
  },
  mounted(){
    
  },
  methods: {
    onLoad(){
      let wheres = [];
      ['template_type' , 'template_name' , 'create_time' , 'template_use'].forEach(
        name => {
          if(this.search[name]){
            if(name === 'template_name'){
              wheres.push({field: name , op: 'LIKE' , value: this.search[name]})
            }else{
              wheres.push({field: name , value: this.search[name]})
            }
          }
        }
      )
      this.tableLoading = true;
      this.tableData = [];
      getMetaCommonList(this.model , {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres
      }).then(
        res => {
          this.tableLoading = false;
          if(res.data && res.data.code === 200){
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
          }else{
            this.tableData = [];
            this.page.total = 0;
          }
        }
      ).catch(e => {
        this.tableLoading = false;
      })
    },
    saveTableForm(row, done, loading) {
      let body = {};
      ['template_name' , 'template_type' , 'template_use' , 'rent_way' , 'rent_type' , 'note' , 'supplementary_terms'].forEach(
        key => {
          if(row[key] !== undefined){
            body[key] = row[key];
          }
        }
      );
      if(row.id){
        body = {
          update: body,
          primaryKeyValue: row.id,
          primaryKey: "id",
        };
      }else{
        body = {
          values: body
        }
      }
      (row.id ? updateMetaCommon : saveMetaCommon)(this.model , body).then((res) => {
        this.responeHandle(res, done, loading);
      }).catch((e) => {
        loading();
      });
    },
    responeHandle(res, done, loading) {
      if (res.data && res.data.code === 200) {
        done && done();
        this.onLoad();
        this.$message.success(res.data.msg);
      } else {
        loading && loading();
        this.$message.error(res.data.msg);
      }
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        return delMetaCommon(this.model , {pk: 'id' , ids: [row.id]});
      }).then(() => {
        this.onLoad();
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    },
  }
}
</script>
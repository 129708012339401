<template>
  <el-card>
    <el-row>
      <el-col :span="24">
        <el-form
          size="small"
          :v-model="operForm"
          label-position="left"
          label-width="80px"
        >
          <el-form-item label="适用场景">
            <el-radio-group v-model="operForm.appl">
              <el-radio label="dict">远端请求</el-radio>
              <el-radio label="new">打开新页面</el-radio>
              <el-radio label="row">打开外网地址</el-radio>
              <el-radio label="model">引用模块</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="模块名称" v-if="operForm.appl == 'model'">
            <el-input
              v-model="operForm.modelName"
              class="w-300"
              placeholder="例如：<user></user>"
            ></el-input>
          </el-form-item>
          <el-form-item label="外网地址" v-if="operForm.appl == 'row'">
            <el-input
              v-model="operForm.field"
              class="w-300"
              placeholder="例如：http://www.jidaflex.com/"
            ></el-input>
          </el-form-item>
          <el-form-item label="页面" v-if="operForm.appl == 'new'">
            <el-select
              v-model="page"
              size="small"
              class="w-300"
              placeholder="请选择页面"
              clearable
            >
              <el-option
                v-for="item in pageList"
                :key="item.id"
                :value="item.id"
                :label="item.name"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="提示语">
            <el-input
              class="w-300"
              v-model="operForm.tip"
              placeholder="例如：确定要给改用户拨打电话？"
            ></el-input>
          </el-form-item> -->
          <div
            class="el-form-item el-form-item--small el-form--label-top"
            v-if="operForm.operType == 'js'"
          >
            <label class="el-form-item__label" style="padding: 0"
              >change：</label
            >
            <div class="el-form-item__content">
              <monaco-editor
                v-model="operForm.click"
                height="200"
                :options="options"
              ></monaco-editor>
            </div>
          </div>

          <div v-if="operForm.appl == 'dict'">
            <el-form-item label="远端请求网址">
              <el-input
                v-model="operForm.dicUrl"
                class="w-300"
                placeholder="例如：http://jidaflex.com/"
              ></el-input>
            </el-form-item>
            <el-form-item label="远程搜索">
              <el-switch v-model="operForm.remote"></el-switch><br />
            </el-form-item>

            <el-form-item label="请求方法">
              <el-select
                v-model="operForm.dicMethod"
                placeholder="请求方法"
                class="w-300"
                style="width: 100%"
              >
                <el-option label="POST" value="post"></el-option>
                <el-option label="GET" value="get"></el-option>
              </el-select>
            </el-form-item>
            <!-- <p v-if="operForm.dicMethod == 'post' || operForm.appl == 'new'">
              <el-form-item label="请求参数">
                <el-col :span="12" class="pad-l-0">
                  <avue-dynamic
                    style="padding: 0px"
                    v-model="operList"
                    :children="tableDataOption"
                  ></avue-dynamic>
                </el-col>
              </el-form-item>
            </p> -->
          </div>
          <p v-if="operForm.dicMethod == 'post' || operForm.appl == 'new'">
            <el-form-item label="请求参数">
              <el-col :span="12" class="pad-l-0">
                <avue-dynamic
                  style="padding: 0px"
                  v-model="operList"
                  :children="tableDataOption"
                ></avue-dynamic>
              </el-col>
            </el-form-item>
          </p>
        </el-form>
      </el-col>
      <el-col :span="24" class="text-center">
        <el-button type="primary" size="small" @click="saveOperBtn()"
          >确 定</el-button
        >
        <el-button size="small" @click="btnEditVisible = false"
          >取 消</el-button
        >
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import MonacoEditor from "../../../../components/dynamic/form/utils/monaco-editor";
import { operBtns } from "../../util/option";
import { getDynamicFormList } from "@/api/busiMode/meta";

export default {
  name: "designBtn",
  watch: {},
  components: { MonacoEditor },
  props: {
    tableOption: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      change: {},
      tableDataOption: {},
      pageList: [],
      page: "",
      dicQueryConfig: {
        column: [
          { type: "input", prop: "field", label: "key", cell: true },
          {
            type: "input",
            prop: "value",
            label: "value",
            cell: true,
          },
        ],
      },
      options: {
        minimap: {
          enabled: false,
        },
      },
      operForm: {
        appl: "dict",
        field: "",
        remote: true,
        dicMethod: "post",
        // click: "({value}) => {\r\n\r\n}",
        click: "\r\n\r\n",
      },
      operFormCustom: false,
      operList: [],
      operBtnList: operBtns,
    };
  },

  created() {
    this.getDynamicFormList();
  },
  mounted() {
    this.tableDataOption = {
      column: [
        {
          type: "input",
          prop: "key",
          label: "参数名称",
          cell: true,
          dicData: this.operBtnList,
          props: {
            label: "operName",
            value: "key",
          },
        },
        // {
        //   type: "select",
        //   prop: "appl",
        //   label: "类型",
        //   cell: true,
        //   dicData: [
        //     { label: "行内", value: "row" },
        //     { label: "新增按钮", value: "new" },
        //   ],
        //   props: {
        //     label: "label",
        //     value: "value",
        //   },
        // },
        {
          type: "input",
          prop: "field",
          label: "值",
          cell: true,
          dicData: this.tableOption.column,
          props: {
            label: "label",
            value: "prop",
          },
        },
      ],
    };
  },

  methods: {
    updateFiled() {},
    newROper() {
      this.operForm = {
        appl: "row",
        field: "",
        click: "({value}) => {\r\n\r\n}",
      };
      this.operBtnList = operBtns;
      this.operFormCustom = true;
    },
    closeROper() {
      this.operList.push(this.operForm);
      this.operBtnList.push(this.operForm);
      this.operFormCustom = false;
    },
    saveOperBtn() {
      console.log(this.operList);
      let obj = {
        model: this.operForm.modelName,
        params: this.operList,
      };
      console.log(obj);
      this.$emit("oper", obj);
    },
    getDynamicFormList(id, type) {
      let obj = {
        current: 1,
        size: 100,
      };
      getDynamicFormList(obj).then((res) => {
        this.pageList = res.data.data.records;
      });
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped></style>

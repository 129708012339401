<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" :search.sync="query" :page.sync="page" :before-open="beforeOpen" v-model="form" ref="crud" @row-update="rowUpdate"
                   @row-save="rowSave" @row-del="rowDel" @search-change="searchChange" @search-reset="searchReset" @selection-change="selectionChange" @current-change="currentChange"
                   @size-change="sizeChange" @refresh-change="refreshChange" @on-load="onLoad">
            <template slot="menuLeft" slot-scope="scope">
                <el-button class="mar-l-5" type="primary" icon="el-icon-bank-card" @click="putOffList" size="small">年卡批量延期</el-button>
                <el-button class="mar-l-5" icon="el-icon-download" :loading="downLoading" @click="getMetaCommonListExport" size="small">导出</el-button>
            </template>
            <template slot="menuLeft">
                <el-button type="danger" size="small" icon="el-icon-delete" plain v-if="permission.spcodaccount_delete" @click="handleDelete">删 除
                </el-button>
            </template>

            <template slot-scope="{ row }" slot="menu">
                <!--<el-button @click.stop="handleRecharge(row)" icon="el-icon-money" size="mini" type="text">充值</el-button>-->
                <el-button @click.stop="clickDetailList(row)" icon="el-icon-tickets" size="mini" type="text">明细</el-button>
                <el-button @click.stop="gotoCard(row)" icon="el-icon-user" size="mini" type="text">会员卡</el-button>
            </template>
        </avue-crud>

        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" v-if="dialogVisible" width="70%" append-to-body @close="dialogCancel">
            <!-- ,,memo:备注,value:金额 -->
            <avue-form :option="formRechargeOption" @submit="rechargeSubmit" v-model="formRecharge"></avue-form>
        </el-dialog>
        <el-dialog title="年卡延期" :visible.sync="putOffDialogVisible" v-if="putOffDialogVisible" width="70%" append-to-body @close="putOffDialogVisible = false">
            <spcodaccountPutOff @closeDia="closeDia" :vipNoList="vipNoList"></spcodaccountPutOff>
            <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="sure_yq_card()" size="mini" type="primary"
          >确 认</el-button
        >
        <el-button @click="dialogVisible = false" size="mini" type="defaut"
          >取消
        </el-button>
      </span> -->
        </el-dialog>
    </basic-container>
</template>

<script>
import {
    getList,
    getPage,
    getAccDetail,
    add,
    update,
    remove,
    procAccountDetail,
    getDetailAcc,
} from '@/api/spcod/spcodaccount';
import { getEmployeeDetail } from '@/api/user';

import { getMetaCommonListExport } from '@/api/busiMode/metaCommon';

import { addSpcodAccountHk } from '@/api/spcod/hkmeeting';
import { mapGetters } from 'vuex';
import spcodaccountPutOff from './spcodaccountPutOff';
import * as dictService from '@/api/system/dictbiz';

export default {
    components: { spcodaccountPutOff },
    data() {
        // 正则表达式 验证手机号
        const validateMobile = (rule, value, callback) => {
            if (!/^[1]([3-9])[0-9]{9}$/.test(value)) {
                callback(new Error('请输入正确的手机号格式'));
            } else {
                callback();
            }
        };
        return {
            //{vipNo:会员号(当前不用选),operType:操作类型(1充值、2消费),,memo:备注,value:金额}
            formRecharge: {},
            vipNo: '',
            downLoading: false,
            putOffDialogVisible: false,
            formRechargeOption: {
                column: [
                    {
                        label: '金额',
                        prop: 'value',
                        type: 'number',
                        precision: 2,
                    },
                    {
                        label: '操作类型',
                        prop: 'operType',
                        type: 'select',
                        dicData: [
                            {
                                value: 1,
                                label: '充值',
                            },
                            {
                                value: 2,
                                label: '消费',
                            },
                        ],
                    },
                    {
                        label: '业务类型',
                        prop: 'bizType',
                        type: 'select',
                        dicData: [
                            {
                                value: 'COD',
                                label: '会议',
                            },
                            {
                                value: 'SPT',
                                label: 'SPT文体',
                            },
                            {
                                value: 'FOD',
                                label: '餐食',
                            },
                            {
                                value: 'XPS',
                                label: '快递类型',
                            },
                        ],
                    },
                    {
                        label: '备注',
                        prop: 'memo',
                        type: 'textarea',
                    },
                ],
            },
            dialogTitle: '充值',
            dialogVisible: false,
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: '',
            },
            formLabelWidth: '120px',
            query: {},
            loading: true,
            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            selectionList: [],
            dialogFormVisible: false,
            option: {
                height: 'auto',
                calcHeight: 30,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                addBtn: true,
                delBtn: false,
                index: true,
                viewBtn: true,
                selection: true,
                dialogClickModal: false,
                menuWidth: 250,
                column: [
                    {
                        label: '会员号',
                        prop: 'vipNo',
                        search: true,
                        addDisplay: false,
                        editDisplay: false,
                        searchSpan: 6,
                        rules: [
                            {
                                required: true,
                                message: '请输入会员号,最长20位',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '姓名',
                        prop: 'vipName',
                        search: true,
                        searchSpan: 6,
                        minWidth: 200,
                        rules: [
                            {
                                required: true,
                                message: '请输入姓名',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '联系电话',
                        prop: 'vipPhone',
                        search: true,
                        searchSpan: 6,
                        minWidth: 120,
                        rules: [
                            {
                                required: true,
                                message: '请输入联系电话',
                                trigger: 'blur',
                            },
                            {
                                trigger: 'blur',
                                validator: validateMobile,
                            },
                        ],
                    },
                    {
                        label: '会员级别',
                        prop: 'vipGrade',
                        dicUrl: dictService.getUrl('VIPGRADE'),
                        addDisplay: true,
                        editDisplay: true,
                        search: true,
                        searchSpan: 6,
                        type: 'select',
                        props: {
                            label: 'dictValue',
                            value: 'dictKey',
                        },
                        dataType: 'number',
                        rules: [
                            {
                                required: true,
                                message: '请选择会员级别',
                                trigger: 'blur',
                            },
                        ],
                    },
                    // {
                    //   label: "头像",
                    //   prop: "headFiles",
                    //   listType: "picture-card",
                    //   hide: true,
                    //   propsHttp: {
                    //     res: "data",
                    //     url: "link",
                    //     name: "attachId",
                    //   },
                    //   type: "upload",
                    //   tip: "只能上传jpg/png用户头像，且不超过500kb",
                    //   action: "/api/jida-resource/oss/endpoint/put-file-attach",
                    //   span: 24,
                    //   rules: [
                    //     {
                    //       required: false,
                    //       message: "请上传图片",
                    //       trigger: "change",
                    //     },
                    //   ],
                    // },
                    {
                        label: '账号',
                        prop: 'userName',
                        addDisplay: false,
                        editDisplay: false,
                        rules: [
                            {
                                required: false,
                                message: '请输入账号',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '余额',
                        prop: 'memBalance',
                        hide: true,
                        addDisplay: false,
                        editDisplay: false,
                        viewDisplay: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入余额',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '状态',
                        prop: 'status',
                        addDisplay: false,
                        editDisplay: false,
                        search: true,
                        searchSpan: 6,
                        type: 'select',
                        dicData: [
                            {
                                label: '有效',
                                value: 1,
                            },
                            {
                                label: '无效',
                                value: 0,
                            },
                        ],
                        rules: [
                            {
                                required: true,
                                message: '请输入 0无效 1有效',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '操作人',
                        width: 150,
                        prop: 'userName',
                        hide: true,
                        display: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入操作人',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        addDisplay: false,
                        editDisplay: false,
                        label: '创建时间',
                        prop: 'createdTime',
                        type: 'datetime',
                        format: 'yyyy-MM-dd HH:mm:ss',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        width: 150,
                        rules: [
                            {
                                required: true,
                                message: '请输入创建时间',
                                trigger: 'blur',
                            },
                        ],
                    },
                ],
            },
            data: [],
            vipNoList: [],
            region1: [
                {
                    label: '充值',
                    value: '1',
                },
                {
                    label: '充值',
                    value: '2',
                },
            ],
            region2: [
                {
                    label: 'COD会议',
                    value: '1',
                },
                {
                    label: 'SPT文体',
                    value: '2',
                },
                {
                    label: 'FOD餐食',
                    value: '3',
                },
                {
                    label: 'XPS快递',
                    value: '4',
                },
            ],
        };
    },
    computed: {
        ...mapGetters(['permission', 'userInfo']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.spcodaccount_add, false),
                viewBtn: this.vaildData(this.permission.spcodaccount_view, false),
                delBtn: this.vaildData(this.permission.spcodaccount_delete, false),
                editBtn: this.vaildData(this.permission.spcodaccount_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },

    methods: {
        putOffList() {
            this.selectionList.forEach(e => {
                this.vipNoList.push(e.id);
            });
            this.putOffDialogVisible = true;
            // console.log(this.selectionList());
        },
        closeDia() {
            this.putOffDialogVisible = false;
        },
        getMetaCommonListExport() {
            this.downLoading = true;
            let body = this.getSearch();
            getMetaCommonListExport('wisdom_park.v_wp_spcod_accountAndCard_stat', body)
                .then(res => {
                    this.downLoading = false;
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.download = '会员卡' + '.xls';
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(e => {
                    this.downLoading = false;
                });
        },
        rowSave(row, done, loading) {
            getDetailAcc(row.vipPhone).then(res => {
                if (res.data.data && JSON.stringify(res.data.data) != '{}') {
                    this.$message({
                        type: 'error',
                        message: '请校验手机号的唯一性',
                    });
                } else {
                    if (row.headFiles && row.headFiles.length > 0) {
                        row.iconFile = row.headFiles[0].value;
                        row.iconAttachId = row.headFiles[0].label;
                    }
                    row.userName = this.userInfo.user_name;
                    add(row).then(
                        () => {
                            this.onLoad(this.page);
                            this.$message({
                                type: 'success',
                                message: '操作成功!',
                            });
                            //调用海康接口
                            let hkObj = {};
                            hkObj.vipName = row.vipName;
                            hkObj.vipPhone = row.vipPhone;
                            hkObj.iconFile = row.iconAttachId;
                            if (row.iconAttachId != undefined && row.iconAttachId != '') {
                                addSpcodAccountHk(hkObj).then(resHk => {
                                    console.log(resHk);
                                });
                            }
                            done();
                        },
                        error => {
                            loading();
                            window.console.log(error);
                        },
                    );
                }
            });
        },
        rowUpdate(row, index, done, loading) {
            if (row.headFiles && row.headFiles.length > 0) {
                row.iconFile = row.headFiles[0].value;
                row.iconAttachId = row.headFiles[0].label;
            }
            update(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    //调用海康接口
                    let hkObj = {};
                    hkObj.vipName = row.vipName;
                    hkObj.vipPhone = row.vipPhone;
                    hkObj.iconFile = row.iconAttachId;
                    if (row.iconAttachId != undefined && row.iconAttachId != '') {
                        addSpcodAccountHk(hkObj).then(resHk => {
                            console.log(resHk);
                        });
                    }
                    done();
                },
                error => {
                    loading();
                    console.log(error);
                },
            );
        },
        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(row.id);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning('请选择至少一条数据');
                return;
            }
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(this.ids);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    this.$refs.crud.toggleSelection();
                });
        },
        beforeOpen(done, type) {
            let _this = this;
            if (['edit', 'view'].includes(type)) {
                getAccDetail(this.form.id).then(res => {
                    _this.form.headFiles = [];
                    _this.form = res.data.data;
                    let headFiles = [];
                    if (res.data.data.iconFile != '') {
                        let obj = {
                            label: res.data.data.iconAttachId,
                            value: res.data.data.iconFile,
                        };
                        headFiles.push(obj);
                    }
                    this.form.headFiles = headFiles;
                });
            }
            done();
        },
        searchReset() {
            // this.query = {};
            this.onLoad(this.page);
        },
        searchChange(params, done) {
            // this.query = params;
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
            done();
        },
        selectionChange(list) {
            this.selectionList = list;
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        currentChange(currentPage) {
            this.page.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        getSearch() {
            let obj = {
                current: this.page.currentPage,
                size: this.page.pageSize,
            };
            let wheres = [];
            for (let key in this.query) {
                if (this.query[key] != '' && key[0] !== '$') {
                    wheres.push({
                        field: key,
                        value: this.query[key],
                    });
                }
            }
            if (wheres.length > 0) {
                obj.wheres = wheres;
            }

            return obj;
        },
        onLoad(page, params = {}) {
            this.loading = true;
            getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                this.loading = false;
                this.selectionClear();
            });
        },
        // 跳转
        clickDetailList(row) {
            this.$router.push({
                path: '/spcodaccount/detailist',
                query: {
                    vipNo: row.vipNo,
                    accId: row.id,
                },
            });
        },
        gotoCard(row) {
            this.$router.push({
                path: '/spcodaccount/cardlist',
                query: {
                    accId: row.id,
                },
            });
        },
        procAccountDetail(row) {
            procAccountDetail(row).then(res => {
                console.error('获取');
                console.log(res);
            });
        },
        // 充值
        handleRecharge(row) {
            this.vipNo = row.vipNo;
            this.dialogVisible = true;
        },
        // 充值弹窗关闭事件
        dialogCancel() {},
        // 充值提交
        rechargeSubmit() {
            let _this = this;
            console.log(this.formRecharge);
            this.formRecharge.vipNo = this.vipNo;
            procAccountDetail(this.formRecharge).then(res => {
                //console.log(res);
                _this.$message({
                    type: 'success',
                    message: res.data.msg,
                });
                this.dialogVisible = false;
            });
        },
    },
};
</script>

<style></style>

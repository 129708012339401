<template>
  <el-row>
    <statistics></statistics>
    <chart></chart>
  </el-row>
</template>

<script>
import statistics from "./statistics.vue";
import chart from "./chart.vue";

export default {
  components: { statistics, chart },

  data() {
    return {
      loading: true,
    };
  },
  methods: {
    handleClick(tab, event) {
      this.activeName = tab.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-card {
  border-radius: 0px !important;
}
</style>

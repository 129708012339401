<template>
  <el-col :span="24" class="pad-0">
    <el-col :span="8">
      <el-card class="h-113" shadow="hover">
        <div class="fts-20 mar-b-5">{{ ruleForm.name }}</div>
        <div class="czl ftzClor mar-t-5">
          {{ ruleForm.uniform_social_credit_code
          }}<span class="mar-l-10 mar-r-10">{{ ruleForm.industry }}</span>

          <!-- {{ ruleForm.firm_size }} -->
        </div>
        <div class="ftzClor mar-t-10">{{ ruleForm.registered_address }}</div>
      </el-card>
    </el-col>
    <el-col :span="4">
      <el-card class="h-113" shadow="hover">
        <div class="czmj">租赁面积(m²)</div>
        <div class="czmjNum">{{ comObj.sum_area || "-" }}</div>
        <!-- <div class="czl"><span class="ftzClor mar-r-20">同期增长</span>20%</div> -->
      </el-card>
    </el-col>

    <el-col :span="4">
      <el-card class="h-113" shadow="hover">
        <div class="czmj">企业人数</div>
        <div class="czmjNum">{{ comObj.employee_nums || "-" }}</div>
        <!-- <div class="czl">
          <span class="ftzClor mar-r-20">同期增长 </span>20%
        </div> -->
      </el-card>
    </el-col>
    <el-col :span="4">
      <el-card class="h-113" shadow="hover">
        <div class="czmj">账单数</div>
        <div class="czmjNum">{{ comObj.receipt_nums || "-" }}</div>
        <!-- <div class="czl">
          同期增长<span class="ftzClor mar-l-10">409</span
          ><span class="pull-right"
            >已认购<span class="ftzClor mar-l-10">20</span></span
          >
        </div> -->
      </el-card>
    </el-col>
    <el-col :span="4">
      <el-card class="h-113" shadow="hover">
        <div class="czmj">合同数</div>
        <div class="czmjNum">{{ comObj.contract_nums || "-" }}</div>
        <!-- <div class="czl">
          <span class="ftzClor">超期20天</span>
        </div> -->
      </el-card>
    </el-col>
  </el-col>
</template>

<script>
import { getMetaCommonInfo, getPicListByIds } from "@/api/busiMode/metaCommon";
import { mapGetters, mapState } from "vuex";

export default {
  data() {
    return {
      ruleForm: {},
      entModel: "wisdom_park.wp_company",
      model: "wisdom_park.v_wp_company_stat",
      comObj: {},
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    ...mapGetters(["tag", "lockPasswd"]),
  },
  mounted() {
    if (this.userInfo.companyId && this.userInfo.tenant_id == "000002") {
      this.getMetaCommonInfos();
    }
    this.getMetaCommonComInfo();
  },
  methods: {
    getMetaCommonComInfo() {
      if (this.userInfo.companyId) {
        let obj = {
          pk: "id",
          id: this.userInfo.companyId,
        };
        getMetaCommonInfo(this.model, obj).then((res) => {
          if (res.data.code == 200) {
            this.comObj = res.data.data;
          }
        });
      }
    },
    getMetaCommonInfos() {
      let obj = {
        pk: "id",
        id: this.userInfo.companyId,
      };
      getMetaCommonInfo(this.entModel, obj).then((res) => {
        if (res.data.code == 200) {
          this.ruleForm = res.data.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-card__body {
  padding: 15px;
}
.czmj {
  color: rgb(153, 153, 153);
  font-size: 12px;
}
.czmjNum {
  font-size: 20px;
  margin-top: 20px;
  padding-bottom: 8px;
  // border-bottom: 1px solid #dcdfe6 !important;
}
.czl {
  font-size: 12px;
  margin-top: 10px;
}
.ftzClor {
  color: rgb(153, 153, 153);
}
</style>

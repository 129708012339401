<template>
  <div>
    <!-- <basic-page-header @back="goBack"></basic-page-header>
    <el-divider></el-divider> -->
    <div class="pad-lr-15" v-loading="loading">
      <basic-label-row>
        <BasicLabelItem label="API集合">{{apiCollectionMap[form.apiCollectionId] || '-'}}</BasicLabelItem>
        <basic-label-item label="接口名称">{{form.name}}</basic-label-item>
        <BasicLabelItem label="请求地址">{{form.shareUrl || '-'}}</BasicLabelItem>
        <basic-label-item label="用途">{{form.description}}</basic-label-item>
        <!-- <basic-label-item label="Token">
          <div style="width: 100%;word-break: break-all;" class="h-150 ov-a bor pad-5">{{'Bearer ' + form.token}}</div> -->
          <!-- <div class="mar-t-15">
            <el-button :loading="tokenBtnLoading" @click="createToken" type="primary" size="small">生成Token</el-button>
          </div> -->
        <!-- </basic-label-item> -->
      </basic-label-row>
      <div class="mar-t-15"><el-divider direction="vertical"></el-divider> 入参</div>
      <el-table
        class="mar-t-15"
        border
        :data="inputParams"
        max-height="250"
        tooltip-effect="dark"
        style="width: 100%"
        >
        <el-table-column
          label="字段"
          prop="key">
        </el-table-column>
        <el-table-column
          prop="dataType"
          label="数据类型">
        </el-table-column>
        <el-table-column
          prop="value"
          label="值">
          <template slot-scope="{row}">
            <el-input v-model="row.value" size="small"></el-input>
          </template>
        </el-table-column>
      </el-table>
      <!-- <div class="mar-t-15"><el-divider direction="vertical"></el-divider> 排序</div>
      <el-table
        class="mar-t-15"
        border
        :data="sorts"
        max-height="250"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column
          label="字段"
          prop="key">
          <template slot-scope="{row , $index}">
            <el-select size="small" v-model="row.key">
              <el-option v-for="item of tableDescList" :value="item.columnName" :label="item.columnName"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          prop="sort"
          label="排序类型">
          <template slot-scope="{row , $index}">
            <el-select size="small"  v-model="row.sort">
              <el-option value="desc" label="DESC"></el-option>
              <el-option value="asc" label="ASC"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          label="操作">
          <template slot-scope="{row , $index}">
            <el-button size="small" icon="el-icon-delete" type="text" @click="sort.splice($index)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="mar-t-15">
        <el-button size="small"  type="text" icon="el-icon-plus" @click="sorts.push({key: '' , sort: 'desc'})">添加排序</el-button>
      </div> -->
      <div class="mar-t-15">
        <el-button type="primary" size="small" :loading="submitBtnLoading" @click="query">调用</el-button>
      </div>
      <basic-label-row class="mar-t-15">
        <basic-label-item label="返回值">
          <basic-codemirror style="max-height: 250px" :value="code" mode="application/json"></basic-codemirror>
        </basic-label-item>
      </basic-label-row>
    </div>
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import api from '@/api/dataShare/index';
import { getDataAPICollectionSelect } from '@/api/dataService/dataAPICollection';
import {getAppToken} from '@/api/dataApiAccredit/dataApiAccredit'
export default {
  data(){
    return {
      submitBtnLoading: false,
      tokenBtnLoading: false,
      loading: false,
      form: {
        dataSourceType: '',
        dataSourceDb: '',
        dataSourceTable: '',
        name: '',
        token: '',
        description: ''
      },
      inputParams: [],
      outputParams: [],
      code: '',
      tableDescList: [],
      sorts: [],
      apiCollectionMap: {},
    }
  },
  mounted(){
    if(this.id){
      // this.id = this.$router.currentRoute.params.id;
      this.loading = true;
      this.getDataAPICollectionSelect().then(
        res => {
          this.get().then(
            res => {
              this.loading = false;
            }
          );
        }
      )
    }
  },
  props: {
    id: String,
  },
  methods: {
    ...mapActions('datasource', ['getDatasourcesList', 'queryDataSourceTableDesc']),
    getDataAPICollectionSelect(){
      return getDataAPICollectionSelect().then(
        res => {
          (res.data.data || []).map( api => {
            this.apiCollectionMap[api['id']] = api['name'];
          });
        }
      )
    },
    get(){
      this.loading = true;
      return api.get({
        id: this.id
      }).then(
        res => {
          if(res.data){
            this.form = res.data.data;
            let inputParams = [];
            this.inputParams = [];
            try {
              inputParams = JSON.parse(this.form.inputParams);
            } catch (error) {
              inputParams = [];
            }
            inputParams.forEach(
              item => {
                this.inputParams.push({
                  key: item.key,
                  dataType: item.dataType,
                  value: ''
                })
              }
            )
          }
        }
      )
    },
    goBack(){
      this.$emit('close' , null)
    },
    query(){
      let params= {
        appId: '10000000',
        expireTime: 10000
      }
      getAppToken(params).then((res) => {
        if (res.data.code==200) {
            this.submitBtnLoading = true;
            let inputParams = [];
            this.inputParams.forEach(
              item => {
                inputParams.push({
                  key: item.key,
                  value: item.value,
                  key_type: item.dataType
                })
              }
            )
            api.query2({
              tableName: this.form.dataSourceTable,
              body: {
                current: 1,
                size: 10,
                data: inputParams
              },
              headers: {
                token: res.data.data.token
              },
              url: this.form.shareUrl
            }).then(
              res => {
                this.submitBtnLoading = false;
                if(res.data){
                  this.code = JSON.stringify(res.data , null , 2);
                }
              }
            ).catch(() => {
              this.submitBtnLoading = false;
            })
        }else{
          this.$message.warning('请点击调用重新生成token');
        }
      }).catch((error) => {
        this.$message.warning('请点击调用重新生成token');
      })
    },
    createToken(){
      if(this.form.id){
        this.tokenBtnLoading = true;
        api.createToken(this.form).then(
          res => {
            this.tokenBtnLoading = false;
            if(res.data){
              this.form = res.data.data;
            }
          }
        ).catch(() => {
          this.tokenBtnLoading = true;
        })
      }else{
        this.$message.warning('数据错误')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.basic__label-row-item-content {
  flex: none!important;
  width: calc(100% - 75px)!important;
  word-break: break-all;
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._m(0),_c('div',{staticStyle:{"padding":"0 20px"}},[_c('div',{staticClass:"count"},[_vm._m(1),_c('div',{staticClass:"data-content"},[_c('div',{staticClass:"fw-data flex"},_vm._l((_vm.d1),function(item){return _c('div',{key:item.id,staticClass:"fw data-item"},[_c('p',{staticClass:"num"},[_vm._v(_vm._s(item.num))]),_c('p',[_vm._v(_vm._s(item.desc))])])}),0)])]),_c('div',{staticClass:"count"},[_vm._m(2),_c('div',{staticClass:"data-content"},[_c('div',{staticClass:"fw-data flex"},_vm._l((_vm.d2),function(item){return _c('div',{key:item.id,staticClass:"qy data-item"},[_c('p',{staticClass:"num"},[_vm._v(_vm._s(item.num))]),_c('p',[_vm._v(_vm._s(item.desc))])])}),0)])]),_c('div',{staticClass:"count"},[_vm._m(3),_c('div',{staticClass:"data-content"},[_c('div',{staticClass:"fw-data flex"},_vm._l((_vm.d3),function(item){return _c('div',{key:item.id,staticClass:"hn data-item"},[_c('p',{staticClass:"num"},[_vm._v(" "+_vm._s(item.num)),_c('span',{staticClass:"unit"},[_vm._v(_vm._s(item.unit))])]),_c('p',[_vm._v(_vm._s(item.desc))])])}),0)])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"count"},[_vm._m(4),_c('div',{staticClass:"data-content"},[_c('div',{staticClass:"fw-data flex"},_vm._l((_vm.d4),function(item){return _c('div',{key:item.id,staticClass:"fwsr data-item"},[_c('p',{staticClass:"num"},[_vm._v(" "+_vm._s(_vm._f("fixed2")(item.num))),(item.unit)?_c('span',{staticClass:"unit"},[_vm._v(_vm._s(item.unit))]):_vm._e()]),_c('p',[_vm._v(_vm._s(item.desc))])])}),0)])]),_c('div',{staticClass:"count"},[_vm._m(5),_c('div',{staticClass:"data-content"},[_c('div',{staticClass:"fw-data flex"},_vm._l((_vm.d5),function(item){return _c('div',{key:item.id,staticClass:"tcf data-item"},[_c('p',{staticClass:"num"},[_vm._v(" "+_vm._s(item.num)),(item.unit)?_c('span',{staticClass:"unit"},[_vm._v(_vm._s(item.unit))]):_vm._e()]),_c('p',[_vm._v(_vm._s(item.desc))])])}),0)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cockpit-title"},[_vm._v(" 园区运营分析 "),_c('img',{staticClass:"more",attrs:{"src":require("../../../public/assets/assets/img/more.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex f14"},[_c('i',{staticClass:"cockpit-icon icon_1"}),_vm._v(" 房屋租赁统计 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex f14"},[_c('i',{staticClass:"cockpit-icon icon_2"}),_vm._v(" 企业统计 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex f14"},[_c('i',{staticClass:"cockpit-icon icon_3"}),_vm._v(" 耗能统计 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex f14"},[_c('i',{staticClass:"cockpit-icon icon_4"}),_vm._v(" 房屋收入统计 ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex f14"},[_c('i',{staticClass:"cockpit-icon icon_5"}),_vm._v(" 停车费统计 ")])
}]

export { render, staticRenderFns }
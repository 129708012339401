<template>
    <div>
        <basic-container class="cards">
            <el-form :inline="true" :model="formInline" size="small" class="demo-form-inline">
                <el-form-item label="时间">
                    <el-date-picker class="w-150" @change="changeData" v-model="formInline.startDate" :picker-options="startTime" placeholder="开始日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="-" width="300px">
                    <el-date-picker class="w-150" @change="changeData" v-model="formInline.endDate" :picker-options="endtTime" placeholder="结束日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="所属区域" prop="name">
                    <el-select v-model="formInline.subPartId" size="small" clearable filterable @change="getBuildList" placeholder="请选择区域">
                        <template v-for="(item, index) in partList">
                            <el-option v-if="item.parent_id != 0" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="楼宇">
                    <el-select class="w-200" filterable v-model="formInline.buildingId" clearable size="small" @change="getCompanyList" placeholder="请选择所属楼宇">
                        <el-option v-for="(item, index) in buildList" :key="index" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="企业">
                    <el-select class="w-200" v-model="formInline.companyId" clearable filterable size="small" @change="getContractList" placeholder="请选择企业" @clear="clearCom">
                        <el-option v-for="(item, index) in companyList" :key="index" :label="item.name" :value="item.company_id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="合同">
                    <el-select class="w-240" v-model="formInline.contractId" clearable filterable size="small" @change.native="selectBlur" @blur.native="selectBlur" placeholder="请选择合同">
                        <el-option v-for="(item, index) in contractList" :key="index" :label="item.contract_code" :value="item.contract_code">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="search">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button class="pull-right" type="primary" plain @click="exportTable" icon="el-icon-download" size="small">导出</el-button>
                </el-form-item>
            </el-form>
        </basic-container>
        <basic-container isCard>
            <div class="cost">
                <div class="fts-16 text-center mar-b-10">
                    {{ formInline.startDate }}至 {{formInline.endDate}}应收未收明细表
                </div>
                <el-table :data="tableData" style="width: 100%" id="excelTable" border :height="height" v-loading="isLoading" element-loading-text="加载中，请稍后..." :span-method="objectSpanMethod">
                    <!-- <el-table-column header-align="center" :label="`${formInline.startDate} 至 ${formInline.endDate}应收未收明细表`"> -->
                    <el-table-column type="index" fixed header-align="center" width="50">
                    </el-table-column>
                    <el-table-column prop="building_name" fixed align="center" label="楼宇" width="200">
                    </el-table-column>
                    <el-table-column prop="contract_code" fixed align="center" label="合同号" width="220">
                    </el-table-column>
                    <el-table-column prop="renters_name" fixed align="left" header-align="center" label="企业名称" width="220">
                        <template slot-scope="{ row }">
                            <span :class="{ ft500: !row.contract_code }">{{
                  row.renters_name
                }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column fixed prop="end_date" align="center" label="截止挂账时间" width="200">
                        <template slot-scope="{ row }">
                            <span v-if="row.end_date">
                                {{ formatDate(row.start_date) }}
                                <span v-if="row.start_date">至</span>
                                {{ formatDate(row.end_date) }}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column label="房租" header-align="center">
                        <el-table-column prop="prices_fz" header-align="center" align="right" label="总额(元)" width="150">
                            <template slot-scope="{ row }">
                                <span v-if="row.prices_fz">
                                    <span :class="{ ft500: !row.contract_code }">{{
                      toqfw(row.prices_fz)
                    }}</span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="fzbj" align="center" label="本金(元)" width="150">
                        </el-table-column>
                        <el-table-column prop="fzse" align="center" label="税额(元)" width="150">
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="综合服务" align="center" header-align="center">
                        <el-table-column prop="prices_zhfwf" header-align="center" align="right" label="总额(元)" width="150">
                            <template slot-scope="{ row }">
                                <span v-if="row.prices_zhfwf">
                                    <span :class="{ ft500: !row.contract_code }">{{
                      toqfw(row.prices_zhfwf)
                    }}</span>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column prop="zhbj" align="center" label="本金(元)" width="150">
                        </el-table-column>
                        <el-table-column prop="zhse" align="center" label="税额(元)" width="150">
                        </el-table-column>
                    </el-table-column>
                    <el-table-column prop="prices_all" width="150" header-align="center" align="right" label="合计(元)">
                        <template slot-scope="{ row }">
                            <span v-if="row.prices_all">
                                <span :class="{ ft500: !row.contract_code }">{{
                    toqfw(row.prices_all)
                  }}</span>
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="tag" align="center" width="250" label="备注">
                    </el-table-column>
                    <!-- </el-table-column> -->
                </el-table>
            </div>
        </basic-container>
    </div>
</template>

<script>
import { getMetaCommonList } from '@/api/busiMode/metaCommon';
import Vue from 'vue';
import XLSXS from 'xlsx-style';
import dayjs from 'dayjs';
import FileSaver from 'file-saver';
import { getAppToken, getReceivable } from '@/api/reportForms/reportForms';
const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
export default {
    data() {
        return {
            formInline: {
                startDate: '2024-01-01',
                endDate: '',
            },
            height: viewPortHeight - 320,
            value1: '2021',
            tableData: [],
            buildList: [],
            companyList: [],
            partList: [],
            contractList: [],
            isLoading: false,
            startTime: {
                disabledDate: time => {
                    if (this.formInline.endDate) {
                        return time.getTime() > dayjs(this.formInline.endDate);
                    }
                },
            },
            endtTime: {
                disabledDate: time => {
                    if (this.formInline.startDate) {
                        //return dayjs(this.formInline.startDate).format('YYYY-MM-DD');
                        return time.getTime() < dayjs(this.formInline.startDate);
                    }
                },
            },
        };
    },
    mounted() {
        this.getPart();
        this.formInline.endDate = dayjs().format('YYYY-MM-DD');
        this.search();
    },
    methods: {
        selectBlur(e) {
            Vue.set(this.formInline, 'contractId', e.target.value);
        },
        clearCom() {
            delete this.formInline.contractId;
            delete this.formInline.contractCode;
            this.contractList = [];
            this.$forceUpdate();
        },
        toqfw(num) {
            if (Number(num)) {
                let n = num;
                n = this.addDou(Number(num).toFixed(2));
                return n;
            } else {
                return num;
            }
        },
        addDou(value) {
            var isNegative = value < 0;
            value = Math.abs(value).toString();
            var decimalIndex = value.indexOf('.');
            if (decimalIndex === -1) {
                decimalIndex = value.length;
            }
            for (var i = decimalIndex - 3; i > 0; i -= 3) {
                value = value.slice(0, i) + ',' + value.slice(i);
            }
            if (isNegative) {
                value = '-' + value;
            }
            return value;
        },
        changeData() {
            if (this.formInline.startDate) {
                this.formInline.startDate = this.formatDate(this.formInline.startDate);
            } else {
                this.formInline.startDate = '';
            }
            if (this.formInline.endDate) {
                this.formInline.endDate = this.formatDate(this.formInline.endDate);
            } else {
                this.formInline.endDate = '';
            }
        },
        search() {
            this.changeData();
            this.getStatFinanceCalculateArea(this.formInline);
        },
        formatDate(createTime) {
            let obj = '';
            if (createTime) {
                obj = dayjs(createTime).format('YYYY-MM-DD');
            }
            return obj;
        },
        getStatFinanceCalculateArea(data) {
            let o = {};
            if (data) {
                o = data;
            }
            this.isLoading = true;
            getReceivable(o)
                .then(res => {
                    this.tableData = [];
                    this.tdList = [];

                    const data = res.data;
                    let tableData = [];
                    data.data.resultDetail.forEach(e => {
                        tableData = tableData.concat(e.detail);
                        let obj = {
                            renters_name: ' 小计',
                        };

                        obj.prices_fz = e.total.prices_fz;
                        obj.prices_all = e.total.prices;
                        obj.prices_zhfwf = e.total.prices_zhfwf;
                        tableData.push(obj);
                    });
                    let total = {
                        renters_name: '合计',
                        prices_fz: data.data.resultTotal.prices_fz,
                        prices_all: data.data.resultTotal.prices,
                        prices_zhfwf: data.data.resultTotal.prices_zhfwf,
                    };

                    tableData.push(total);
                    this.tableData = tableData;
                    this.mergeTableRow(this.tableData, ['building_name', 'date']);
                    //this.tdList = data.data.month;
                    this.isLoading = false;
                })
                .catch(() => {
                    this.isLoading = false;
                });
        },
        mergeTableRow(data, merge) {
            if (!merge || merge.length === 0) {
                return data;
            }
            merge.forEach(m => {
                const mList = {};
                data = data.map((v, index) => {
                    const rowVal = v[m];
                    if (mList[rowVal] && mList[rowVal].newIndex === index) {
                        mList[rowVal]['num']++;
                        mList[rowVal]['newIndex']++;
                        data[mList[rowVal]['index']][m + '-span'].rowspan++;
                        v[m + '-span'] = {
                            rowspan: 0,
                            colspan: 0,
                        };
                    } else {
                        mList[rowVal] = { num: 1, index: index, newIndex: index + 1 };
                        v[m + '-span'] = {
                            rowspan: 1,
                            colspan: 1,
                        };
                    }
                    return v;
                });
            });
            return data;
        },

        getPart() {
            let body = {
                current: 1,
                size: 100,
                orders: [{ field: 'sort', sort: 'ASC' }],
            };
            getMetaCommonList('wisdom_park.wp_part', body).then(res => {
                const data = res.data.data;
                this.partList = data.records;
            });
        },
        getBuildList(e) {
            this.formInline.buildingId = '';
            this.partList.forEach(e => {
                if (e.id == this.formInline.subPartId) {
                    this.partName = e.name;
                }
            });
            getMetaCommonList('wisdom_park.wp_building', {
                size: 100,
                current: 1,
                wheres: [{ field: 'sub_part_id', value: this.formInline.subPartId }],
            }).then(res => {
                const data = res.data.data;
                this.buildList = data.records;
            });
        },
        getCompanyList() {
            getMetaCommonList('wisdom_park_statistics.v_wp_company_building_stat', {
                size: 100,
                current: 1,
                wheres: [{ field: 'building_id', value: this.formInline.buildingId }],
            }).then(res => {
                const data = res.data.data;
                this.companyList = data.records;
            });
        },
        getContractList() {
            if (this.formInline.companyId) {
                getMetaCommonList('wisdom_park_statistics.v_company_contract_stat', {
                    size: 100,
                    current: 1,
                    wheres: [{ field: 'company_id', value: this.formInline.companyId }],
                }).then(res => {
                    const data = res.data.data;
                    this.contractList = data.records;
                });
            }
        },
        flitterData4() {
            let spanOneArr = [];
            let concatOne = 0;
            this.tableData.forEach((item, index) => {
                //循环后端查询出来的数据(orderdata)
                if (index === 0) {
                    spanOneArr.push(1);
                } else {
                    if (item.qymc === this.tableData[index - 1].qymc) {
                        //第一列需合并相同内容的字段
                        spanOneArr[concatOne] += 1;
                        spanOneArr.push(0);
                    } else {
                        spanOneArr.push(1);
                        concatOne = index;
                    }
                }
            });
            return {
                one: spanOneArr,
            };
        },
        handleTableData(tableData) {
            let rowSpanArr = [],
                position = 0;
            for (let [index, item] of tableData.entries()) {
                if (index == 0) {
                    rowSpanArr.push(1);
                    position = 0;
                } else {
                    if (item.projectName == tableData[index - 1].projectName) {
                        rowSpanArr[position] += 1; //项目名称相同，合并到同一个数组中
                        rowSpanArr.push(0);
                    } else {
                        rowSpanArr.push(1);
                        position = index;
                    }
                }
            }
            this.tableData = rowSpanArr;
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            const span = column['property'] + '-span';
            if (row[span]) {
                return row[span];
            }
        },

        // 点击事件触发的方法
        exportTable() {
            // 首先加载  <table id='excelTable'> 标签 为 workbook对象
            // let workbook = this.$XLSX.utils.table_to_book(document.getElementById('excelTable'), { raw: true });
            // var fix = document.querySelector('.el-table__fixed');
            // var workbook;
            // // 判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
            // if (fix) {
            //     /* 从表生成工作簿对象 */
            //     workbook = this.$XLSX.utils.table_to_book(document.querySelector('#excelTable').removeChild(fix));
            //     document.querySelector('#excelTable').appendChild(fix);
            // } else {
            //     workbook = this.$XLSX.utils.table_to_book(document.querySelector('#excelTable'));
            // }

            var workbook;
            var fix = document.querySelector('.el-table__fixed');
            var dt = document.querySelector('#excelTable');
            var xlsxParam = { raw: true }; //转换成excel时，使用原始的格式,解决工单号过长导致数字自动转换为科学计数法导致精度缺失
            if (fix) {
                workbook = this.$XLSX.utils.table_to_book(dt.removeChild(fix), xlsxParam);
                dt.appendChild(fix);
            } else {
                workbook = this.$xlsx.utils.table_to_book(dt, xlsxParam);
            }
            // 先定义列宽 ， 我这里文件一共有7 列 ，所以设置7列宽度相等都为 20 ，如果你有很多列建议直接 map()
            let wscols = [
                { wch: 5 },
                { wch: 20 },
                { wch: 20 },
                { wch: 25 },
                { wch: 25 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 20 },
            ];

            // 获取 需要设置样式的 sheet ，我这里只有 一个 sheet 所以索引默认加载了第一个
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            // 设置列宽
            sheet['!cols'] = wscols;
            // 定义框线样式
            const borderAll = {
                color: { auto: 1 },
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            };

            // 这里的意思为 先默认代表表格的 7 个列  的 列号
            // 比如 A2 意思是 第一列 第2行
            const _letterList = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
            // 定义一个 箭头函数，接受三个参数，分别为 当前Sheet , 行列号（例如：‘A2’）, 是否是新增样式
            const _mapCellStyle = (_sheet, _key, _type) => {
                const _cellStyle = {
                    border: borderAll,
                    alignment: {
                        wrapText: true,
                        horizontal: 'center',
                        vertical: 'center',
                    },
                    font: {
                        name: '微软雅黑',
                        sz: 10,
                        height: 50,
                    },
                    height: 50,
                    bold: true,
                    numFmt: '0',
                };

                const _title = {
                    // border: borderAll,
                    alignment: {
                        wrapText: true,
                        horizontal: 'center',
                        vertical: 'center',
                    },
                    font: {
                        name: '微软雅黑',
                        sz: 16,
                    },
                    bold: false,
                    numFmt: '0',
                };
                if (_key == 'A1') {
                    if (_type === 'append') {
                        // 需要新增样式，说明当前加载的sheet中并不存在该单元格，可能当前单元格被前边合并，这种被合并的单元格也得设置样式，
                        // 否则就会出现 合并单元格只有第一格带框线，后边没框线的情况出现，所以这里需要将后边的边框样式也加上。
                        _sheet[_key] = {
                            s: _title,
                        };
                    } else {
                        // 若不是新增样式 则代表sheet中已存在该表格直接修改其 s 属性即可使属性生效
                        if (typeof _sheet[_key] === 'object') {
                            _sheet[_key].s = _title;
                        }
                    }
                } else {
                    if (_type === 'append') {
                        // 需要新增样式，说明当前加载的sheet中并不存在该单元格，可能当前单元格被前边合并，这种被合并的单元格也得设置样式，
                        // 否则就会出现 合并单元格只有第一格带框线，后边没框线的情况出现，所以这里需要将后边的边框样式也加上。
                        _sheet[_key] = {
                            s: _cellStyle,
                        };
                    } else {
                        // 若不是新增样式 则代表sheet中已存在该表格直接修改其 s 属性即可使属性生效
                        if (typeof _sheet[_key] === 'object') {
                            _sheet[_key].s = _cellStyle;
                        }
                    }
                }
            };

            // sheet 不懂得可以单独打印一下，它其实就是一个对象，键代表行列号（‘A2’），值为该单元格的值，样式等，
            // 我们需要做的就是修改其值中的样式
            Object.keys(sheet).forEach((i, _) => {
                // 无用属性直接过滤
                if (i !== '!ref' || i !== '!cols' || i !== '!merges' || i !== 'Am') {
                    // 首先设置遍历到的 当前 key
                    let _nowKey = i;
                    // 然后调用 _mapCellStyle  渲染当前单元格样式
                    _mapCellStyle(sheet, _nowKey);
                    // 我们这里先获取下一个行列号  例如当前_nowKey 是 A1  这里就是获取到 B1 ,及 当前行的 下一列数据
                    let _nextKey = _letterList[_letterList.indexOf(_nowKey[0]) + 1] + i.slice(1);

                    // 判断 B1 是否在 Sheet的key中,如果不存在，只可能是因为B1所在单元格已经被A1所合并，所以我们需要将B1也调用一下  _mapCellStyle
                    // 渲染 B1 的样式，不然就会出现 A1 B1 这两格是合并的状态，只有A1有框线，而B1 没有框线的情况。
                    // 这里用 while 就是 要把需要合并的单元格全部 渲染上样式，直到可能 B4 在 Sheet中 停止
                    while (_nowKey[0] !== 'G' && !Object.keys(sheet).includes(_nextKey)) {
                        _mapCellStyle(sheet, _nextKey, 'append');
                        // 这里就是简单的交换索引
                        _nowKey = _nextKey;
                        _nextKey = _letterList[_letterList.indexOf(_nowKey[0]) + 1] + _nowKey.slice(1);
                    }
                }
            });

            let wopts = {
                bookType: 'xlsx',
                bookSST: false,
                type: 'buffer',
            };
            let _blob = XLSXS.write(workbook, wopts);

            FileSaver.saveAs(
                new Blob([_blob], {
                    type: 'application/octet-stream',
                }),
                this.formInline.startDate +
                    '至' +
                    this.formInline.endDate +
                    '年应收未收明细表' +
                    new Date().getTime() +
                    '.xlsx',
            );
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
// /deep/ .el-form-item {
//   margin-bottom: 0px !important;
// }
/deep/.el-table thead.is-group th {
    background: #fafafa;
}
/deep/ .el-form-item--small.el-form-item {
    margin-bottom: 18px !important;
}
// /deep/ .el-table thead.is-group th {
//   background: #fafafa !important;
// }
.cards {
    background: #fff;
    padding: 15px 10px 0px 10px !important;
    padding-right: 0px !important;
    margin-bottom: 15px;
}
.ft500 {
    font-weight: 700;
}
</style>

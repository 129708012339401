<template>
  <div class="data-view-widget-design">
    <el-container style="width: 100%; height: 100%">
      <el-header class="el-header-dv bor-b lh-50" height="50px" style="background: #fff">
        <div class="pull-left">部件配置</div>
        <div class="pull-right">
          <el-button :size="config.size" type="text" @click="$emit('close')" icon="el-icon-finished">保存</el-button>
        </div>
      </el-header>
      <el-container style="height: calc(100% - 50px)">
        <el-aside class="bor-r" width="400px" style="background: #fff">
          <el-tabs class="pad-lr-10" v-model="activeLeftAsideName">
            <el-tab-pane label="数据配置" name="dataConfig"></el-tab-pane>
            <el-tab-pane label="部件" name="widgetConfig"></el-tab-pane>
          </el-tabs>
          <div class="pad-lr-10">
            <DataAside v-if="activeLeftAsideName === 'dataConfig'" :widget="widget" :dash="dash" @loadData="loadData" :columns="columns"></DataAside>
            <template v-if="activeLeftAsideName === 'widgetConfig'">
              <WidgetTypeAside :widgetTypes="widgetTypes" :columns="columns" :widget="widget"></WidgetTypeAside>
              <FooterAside :columns="columns" :widget="widget" :currentWidget="currentWidgetType"></FooterAside>
            </template>
          </div>
        </el-aside>
        <el-container>
          <!-- <el-aside class="bor-r" width="280px" style="background: #fff"> -->
            <!-- <WidgetTypeAside :widgetTypes="widgetTypes" :columns="columns" :widget="widget"></WidgetTypeAside> -->
            <!-- <FooterAside :columns="columns" :widget="widget" :currentWidget="currentWidgetType"></FooterAside> -->
          <!-- </el-aside> -->
          <el-container>
            <el-main style="padding: 20px !important;">
              <DataViewWidgetContainer :widget="widget" :dash="dash" ref="DataViewWidgetContainer"></DataViewWidgetContainer>
            </el-main>
            <el-footer class="bor-t" height="250px" style="background: #fff">
              <!-- <WidgetTypeAside :widgetTypes="widgetTypes" :columns="columns" :widget="widget"></WidgetTypeAside> -->
              <!-- <FooterAside :columns="columns" :widget="widget" :currentWidget="currentWidgetType"></FooterAside> -->
            </el-footer>
          </el-container>
        </el-container>
        <el-aside class="bor-l" width="400px" style="background: #fff">
          <OptionAside :widget="widget" :dash="dash" :columns="columns" :currentWidgetType="currentWidgetType"></OptionAside>
        </el-aside>
      </el-container>
    </el-container>
  </div>
</template>
<script>

import DataViewWidgetContainer from '../widget-container.vue';
// import {  BuiltInWidgets } from '../../widgets';

import { DataViewConnector } from '../../index.js';

import FooterAside from './footer-aside.vue';
import OptionAside from './option-aside.vue';
import DataAside from './data-aside.vue';
import WidgetTypeAside from './widget-type.vue'

export default {
  inject: ['config' , 'customWidgets'],
  components: { DataViewWidgetContainer , FooterAside , OptionAside , DataAside , WidgetTypeAside },
  data(){
    return {
      widgetTypes: DataViewConnector.WidgetType.getWidgetTypes(),
      currentWidgetType: null,
      columns: [],
      activeLeftAsideName: 'dataConfig'
    }
  },
  watch: {
    'widget.selector': {
      handler(){
        this.currentWidgetType = null;
        this.optionParse(null);
        this.widgetTypes.forEach(
          item => {
            if(item.code === this.widget.selector){
              this.optionParse(item);
              this.currentWidgetType = item;
              this.widget.dataOption = null;
              this.initDataOption();
              console.log(11111);
            }
          }
        );
      }
    }
  },
  props: {
    widget: {
      type: Object,
      default(){
        return null;
      }
    },
    dash: {
      type: Object,
      default(){
        return null;
      }
    }
  },
  mounted(){
    if(this.widget){
      this.widgetTypes.forEach(
        item => {
          if(item.code === this.widget.selector){
            this.currentWidgetType = item;
            this.initDataOption();
          }
        }
      );
    }
  },
  methods: {
    loadData(){
      this.$refs.DataViewWidgetContainer.loadData().then(
        res => {
          if(res){
            this.columns = res.columns || [];
          }
        }
      );
    },
    initDataOption(){
      let widgetDataOptions = this.widget && this.widget.dataOption ? this.widget.dataOption : {};
      if(this.currentWidgetType && this.currentWidgetType.dataOption){
        this.currentWidgetType.dataOption.forEach(
          item => {
            
            if(widgetDataOptions[item.code] === undefined){
              if(item.optionList){
                widgetDataOptions[item.code] = [];
              }else{
                widgetDataOptions[item.code] = item['value'] || null;
              }
            }

            if(item.type === 'group' && widgetDataOptions[item.code] && widgetDataOptions[item.code].length){
              item.optionList = [];
              widgetDataOptions[item.code].forEach(
                a => {
                  item.optionList.push(item.meta);
                }
              )
            }
          }
        )
        this.widget.dataOption = {
          ...widgetDataOptions
        }
      }
    },
    optionParse(widgetType){
      let option = null;
      if(widgetType && widgetType['optionList']){
        option = this.optionParseEach(widgetType['optionList']);
      }
      this.widget.private = option;
    },
    optionParseEach(optionList , option){
      option = option || {};
      optionList.forEach(
        item => {
          if(item['optionList']){
            if(item.code){
              option[item.code] = this.optionParseEach(item['optionList']);
            }else{
              this.optionParseEach(item['optionList'] , option);
            }
          }else{
            option[item.code] = item['value'] || null;
          }
          if(item.units){
            option['unit'] = option['unit'] || {};
            option['unit'][item.code] = item['unitValue'] || null;
          }
        }
      )
      return option;
    }
  }
}
</script>
<style lang="scss" scoped>
.data-view-widget-design{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1020;
}
.el-header-dv{
  background: #fff;
}
</style>
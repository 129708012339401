<template>
  <!-- 抽屉 -->
  <el-drawer :visible.sync="show" size="medium" :wrapperClosable="false" :withHeader="false" :close-on-click-modal="false" :destroy-on-close="true" append-to-body @close="data = []">
    <el-header class="bor-b" height="50px">
      <el-page-header class="lh-50" @back="toggle" content="企业列表"></el-page-header>
    </el-header>
    <avue-crud :option="option" :table-loading="loading" :data="data" @search-change="doSearch" :page.sync="page" @on-load="onLoad"> </avue-crud>
  </el-drawer>
</template>

<script>
import option from "./option"
import { getMetaCommonList } from "@/api/busiMode/metaCommon"

export default {
  props: {
    /** 二房东机构ID */
    id: String,
  },
  data() {
    return {
      /** 列表信息 */
      option: {
        ...option,
        tip: false,
        searchShow: true,
        searchMenuSpan: 8,
        border: true,
        index: true,
        labelWidth: 120,
        menuAlign: "left",
        cancelBtn: false,
        delBtn: false,
        addBtn: false,
        searchShowBtn: false,
        refreshBtn: false,
        menu: false,
        index: true,
        searchSpan: 8,
        searchMenuPosition: "right",
        column: [
          {
            overHidden: true,
            label: "企业名称",
            align: "left",
            prop: "renter_company_name",
            hide: false,
            search: true,
          },
          {
            label: "入住房间",
            aligin: "left",
            prop: "renter_rooms",
            search: false,
            search: true,
          },
        ],
      },
      /** 分页信息 */
      page: {
        currentPage: 1,
        pageSize: 10,
      },

      show: false,
      loading: true,
      data: [],
    }
  },

  methods: {
    toggle() {
      this.show = !this.show
    },
    /** 搜索 */
    doSearch(params, done) {
      this.page.currentPage = 1
      this.onLoad(this.page, params)
      done()
    },
    /** 加载企业数据 */
    onLoad(page, params = {}) {
      this.loading = true
      let body = {
        current: page.currentPage,
        size: page.pageSize,
        wheres: [{ field: "sublessor_company_id", value: this.id }],
      }
      /** 添加搜索数据 */
      Object.keys(params).forEach((item) => {
        body.wheres.push({ field: item, op: "LIKE", value: params[item] })
      })
      getMetaCommonList("wisdom_park.v_wp_sublessor_renter_company", body).then(({ data }) => {
        if (data.success) {
          this.page.total = data.data.total
          this.data = data.data.records
        }
        this.loading = false
      })
    },
  },
}
</script>

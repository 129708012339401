<template>
  <basic-container>
    <visitor :register="true"></visitor>
  </basic-container>
</template>

<script>
import visitor from "./index";

export default {
  components: { visitor },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
  props: {},
  watch: {},
  methods: {},
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped></style>

<template>
  <div class="login-container" ref="login">
    <div class="login-weaper animated bounceInDown">
      <div class="titleimg text-center">
        <img src="/assets/images/app/main/login-text.png" alt="" />
      </div>
      <div class="formborder">
        <div class="login-left">
          <img
            src="/assets/images/app/main/login-map-bg.png"
            class="login-map-bg"
            alt=""
          />
          <img class="img" src="/assets/images/app/main/map.png" alt="" />
        </div>
        <div class="login-border">
          <div class="login-main">
            <h4 class="login-title">
              <!-- <img
              :src="'/assets/images/app/main/' + logoConfig.logo"
              alt=""
              srcset=""
              width="200px"
            /> -->
              <!-- <img
              src="/assets/images/app/main/login_logo.png"
              alt=""
              width="60px"
            /> -->
              <span>欢迎使用</span>
              <!-- <top-lang class="login-top-lang"></top-lang> -->
            </h4>
            <!-- <h4 class="login-title-th">
            <img
              :src="'/assets/images/app/main/' + logoConfig.logoTh"
              alt=""
              srcset=""
              width="200px"
            />
            <top-lang class="login-top-lang"></top-lang>
          </h4> -->
            <userLogin v-if="activeName === 'user'"></userLogin>
            <!-- <div class="login-menu">
            <a href="#" >{{ $t('login.findPassword') }}</a>
          </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import userLogin from "./userlogin";
import logoConfig from "@/config/logo";
import topLang from "../index/top/top-lang";
import { getList } from "@/api/system/param.js";

export default {
  name: "login",
  components: {
    userLogin,
    topLang,
  },
  data() {
    return {
      logoConfig: logoConfig,
      activeName: "user",
    };
  },
  created() {},
};
</script>

<style lang="scss">
@import "@/styles/login.scss";
.titleimg img {
  width: 60%;
  margin-bottom: 5%;
}
.login-title-jcj {
  display: none;
}
.login-bg {
  &.login-container {
    display: block;
    height: 100%;
    .login-weaper {
      width: 100%;
      box-shadow: none;
      height: 100%;
    }
    .login-map-bg {
      display: none;
    }
    .login-title {
      display: none;
    }
    .login-title-jcj {
      margin-bottom: 40px;
      font-weight: 500;
      font-size: 22px;
      text-align: center;
      color: #444;
      display: block;
    }
    .login-left {
      width: 70%;
      height: 100%;
      background: #fff;
      img {
        width: 500px;
      }
    }
    .login-border {
      width: 30%;
      background: #f8f8f8;
      height: 100%;
    }
    .login-form .el-input input {
      border-bottom: 0px solid #ebedf2;
    }
    .login-form .el-input input {
      color: #444;
    }
    .el-input {
      border: 1px solid rgba(120, 130, 140, 0.3);
      background: rgba(255, 255, 255, 0.1);
      color: #444;
    }
    .el-input__prefix {
      i {
        color: #444;
      }
    }
  }
}
.formborder {
  box-shadow: -4px -5px 20px rgba(246, 232, 232, 0.2);
  min-height: 400px;
}
.theme-fb {
  .login-bg {
    &.login-container {
      display: block;
      height: 100%;
      .login-weaper {
        width: 100%;
        box-shadow: none;
        height: 100%;
      }
      .login-map-bg {
        display: none;
      }
      .login-title {
        display: none;
      }
      .login-title-jcj {
        margin-bottom: 40px;
        font-weight: 500;
        font-size: 22px;
        text-align: center;
        color: #fff;
        display: block;
      }
      .login-left {
        width: 70%;
        height: 100%;

        background: #090d2d;
        .login-left img {
          width: 500px;
        }
      }
      .login-border {
        width: 30%;
        background: #19214f;
        height: 100%;
      }
      .login-form .el-input input {
        border-bottom: 0px solid #ebedf2;
      }
      .login-form .el-input input {
        color: #fff;
      }
      .el-input {
        border: 1px solid rgba(120, 130, 140, 0.2);
        background: rgba(255, 255, 255, 0.1);
        color: #fff;
      }
      .el-input__prefix {
        i {
          color: #fff;
        }
      }
    }
  }
}
</style>

<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      :page.sync="page"
      :before-open="beforeOpen"
      v-model="form"
      ref="crud"
      @row-update="rowUpdate"
      @row-save="rowSave"
      @row-del="rowDel"
      @search-change="searchChange"
      @search-reset="searchReset"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot="menuLeft">
        <el-button
          type="danger"
          size="small"
          icon="el-icon-delete"
          plain
          v-if="permission.epmallorder_delete"
          @click="handleDelete"
          >删 除
        </el-button>
      </template>
      <!-- 修改状态 -->

      <template slot-scope="scope" slot="menu">
        <template v-if="scope.row.status == 1">
          <el-button
            type="text"
            size="small"
            icon="el-icon-edit"
            class="none-border"
            @click="gotoReadyMeal(scope.row)"
            >去备餐</el-button
          >
        </template>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getList, getPage, getDetail, toggleStatus } from "@/api/epmall/order";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      form: {},
      query: {},
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      option: {
        height: "auto",
        calcHeight: 30,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        addBtn: false,
        delBtn: false,
        editBtn: false,
        viewBtn: true,
        dialogClickModal: false,
        column: [
          {
            label: "订单号",
            prop: "orderId",
            rules: [
              {
                required: true,
                message: "请输入订单id",
                trigger: "blur",
              },
            ],
          },
          {
            label: "实付金额",
            prop: "payment",
            rules: [
              {
                required: true,
                message: "请输入实付金额",
                trigger: "blur",
              },
            ],
          },
          {
            label: "支付类型",
            prop: "paymentType",
            hide: true,
            type: "select",
            dicData: [
              {
                label: "在线支付",
                value: 1,
              },
              {
                label: "货到付款",
                value: 2,
              },
            ],
            rules: [
              {
                required: true,
                message: "请输入支付类型 1在线支付 2货到付款",
                trigger: "blur",
              },
            ],
          },
          {
            label: "订单时间",
            prop: "createTime",
            addDisplay: false,
            rules: [
              {
                required: true,
                message: "请输入订单时间",
                trigger: "blur",
              },
            ],
          },
          {
            label: "付款时间",
            prop: "paymentTime",
            addDisplay: false,
            rules: [
              {
                required: true,
                message: "请输入付款时间",
                trigger: "blur",
              },
            ],
          },
          {
            label: "发货时间",
            hide: true,
            prop: "consignTime",
            addDisplay: false,
            rules: [
              {
                required: true,
                message: "请输入发货时间",
                trigger: "blur",
              },
            ],
          },

          {
            label: "用户",
            prop: "userName",
          },
          {
            label: "用户",
            hide: true,
            viewDisplay: false,
            prop: "userId",
            rules: [
              {
                required: true,
                message: "请输入用户id",
                trigger: "blur",
              },
            ],
          },
          {
            label: "买家是否已经评价",
            hide: true,
            prop: "buyerComment",
            type: "select",
            dicData: [
              {
                label: "是",
                value: true,
              },
              {
                label: "否",
                value: false,
              },
            ],
            rules: [
              {
                required: true,
                message: "请输入买家是否已经评价",
                trigger: "blur",
              },
            ],
          },

          {
            label: "店铺",
            prop: "storeName",
          },
          {
            label: "店铺",
            viewDisplay: false,
            hide: true,
            prop: "storeId",
            type: "select",
            search: true,
            searchSpan: 4,
            with: 40,
            dicUrl: "/api/park-epmall/store/list?current=1&size=10000000",
            props: {
              label: "name",
              value: "id",
            },
            dicFormatter: (res) => {
              let ds = res.data.records;
              ds.forEach((e) => {
                e.id = Number(e.id);
              });
              return ds;
            },
            rules: [
              {
                required: true,
                message: "请输入店铺Id",
                trigger: "blur",
              },
            ],
          },
          {
            label: "订单日",
            prop: "orderDay",
            addDisplay: false,
            editDisplay: false,
            viewDisplay: false,
            hide: true,
            search: true,
            searchSpan: 5,
            type: "datetime",
            format: "yyyy-MM-dd",
            valueFormat: "yyyy-MM-dd",
            rules: [
              {
                required: true,
                message: "请输入订单id",
                trigger: "blur",
              },
            ],
          },
          {
            label: "收货地址",
            prop: "streetName",
            rules: [
              {
                required: true,
                message: "请输入对应的收货地址",
                trigger: "blur",
              },
            ],
          },
          {
            label: "状态",
            prop: "status",
            searchSpan: 5,
            search: true,
            addDisplay: false,
            editDisplay: false,
            viewDisplay: false,
            hide: false,
            type: "select",
            dicData: [
              {
                label: "未支付",
                value: 0,
              },
              {
                label: "已支付",
                value: 1,
              },
              {
                label: "未发货",
                value: 2,
              },
              {
                label: "已发货",
                value: 3,
              },
              {
                label: "交易成功",
                value: 4,
              },
              {
                label: "交易关闭",
                value: 5,
              },
              {
                label: "交易失败",
                value: 6,
              },
              {
                label: "交易取消",
                value: 7,
              },

              {
                label: "完成备餐",
                value: 8,
              },
            ],
            rules: [
              {
                required: true,
                message: "请输入状态,1=正常,0=关闭",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      data: [],
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.epmallorder_add, false),
        viewBtn: this.vaildData(this.permission.epmallorder_view, false),
        delBtn: this.vaildData(this.permission.epmallorder_delete, false),
        editBtn: this.vaildData(this.permission.epmallorder_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  methods: {
    gotoReadyMeal(row) {
      this.$confirm("确认备餐就绪？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          toggleStatus(row.orderId, 8);
        })
        .then(() => {
          row.status = 8;
          _this.$forceUpdate();
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    rowSave(row, done, loading) {
      add(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          loading();
          window.console.log(error);
        }
      );
    },
    rowUpdate(row, index, done, loading) {
      update(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          loading();
          console.log(error);
        }
      );
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
        });
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        getDetail(this.form.id).then((res) => {
          this.form = res.data.data;
        });
      }
      done();
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      getPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
      });
    },
  },
};
</script>

<style></style>

<template>
<div class="panel-tab__content">
  <el-form
    ref="form"
    :model="modelForm"
    label-width="60px"
    label-position="right"
    size="small"
  >
    <el-form-item label="表达式">
      <el-input class="w-240" v-model="modelForm.expression" clearable placeholder="表达式" @change="formChange('expression')"></el-input>
    </el-form-item>

    <template v-for="(opera, i) of modelForm.operationList">
      <div :key="i">
        <div class="strong">
          <span>条件{{ i + 1 }}</span>
          <i class="el-icon-delete text-red" @click="delOpra(i)"></i>
        </div>
        <el-form-item label="模型">
          <el-select
            label="模型"
            class="w-240"
            v-model="opera.model"
            size="small"
            filterable
            :loading="modelLoading"
            placeholder="请选择模型"
            @change="changeVal(opera , i)"
          >
            <el-option
              v-for="item in modelList"
              :key="item.tableName"
              :label="item.description"
              :value="item.tableName"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <avue-dynamic
          v-model="opera.fields"
          :children="tableDataOption[i]"
        ></avue-dynamic>
      </div>
    </template>
    <el-form-item class="text-right">
      <el-button size="mini" type="text" @click="addOpera">+ 新增条件</el-button>
    </el-form-item>
  </el-form>
</div>
</template>
<script>
import { findTableByDatasourceid } from "@/api/dataService/appModel";
import { getColumnsByTable } from "@/api/busiMode/meta";

const tableOption = () => {
  return {
        column: [
          {
            type: "select",
            prop: "field",
            label: "字段",
            cell: true,
            dicData: [],
            props: {
              label: "description",
              value: "guid",
            },
          },
          {
            type: "select",
            prop: "operation",
            label: "操作",
            cell: true,
            dicData: [
              { label: "大于", value: "lt" },
              { label: "等于", value: "equal" },
              { label: "小于", value: "gt" },
            ],
            props: {
              label: "label",
              value: "value",
            },
          },
          { type: "input", prop: "value", label: "值", cell: true },
        ],
      }
}

export default {
  name: "modelTree",

  data() {
    return {
      modelForm: {
        expression: "",
        operationList: [],
      },
      currentModel: "",
      operList: [],
      tableOption: {
        column: [
          {
            type: "select",
            prop: "field",
            label: "字段",
            cell: true,
            dicData: [],
            props: {
              label: "description",
              value: "guid",
            },
          },
          {
            type: "select",
            prop: "operation",
            label: "操作",
            cell: true,
            dicData: [
              { label: "大于", value: "lt" },
              { label: "等于", value: "equal" },
              { label: "小于", value: "gt" },
            ],
            props: {
              label: "label",
              value: "value",
            },
          },
          { type: "input", prop: "value", label: "值", cell: true },
        ],
      },
      tableDataOption: [],
      fieldLoading: false,
      modelLoading: false,
      modelList: [],
    };
  },
  mounted() {
    this.resetFormInfo();
    this.$nextTick(() => {
      this.findTableByDatasourceid();
    })
  },
  created() {
    
  },
  props: {
    id: String,
    dataSoure: {
      type: Object,
      default() {
        return {};
      },
      immediate: true,
    },
    businessObject: Object,
    bpmnElement: {
      type: Object,
      default(){
        return null;
      }
    },
  },
  watch: {
    id: {
      handler(){
        this.resetFormInfo();
      }
    },
    'modelForm.operationList': {
      deep: true,
      handler(newVal){
        this.formChange('operationList' , JSON.stringify(newVal))
      }
    }
  },
  methods: {
    resetFormInfo(){
      if(this.businessObject){
        this.modelForm.expression = this.businessObject.expression || '';
        try {
          this.modelForm.operationList = JSON.parse(this.businessObject.operationList);
        } catch (error) {
          this.modelForm.operationList = [];
        }
        this.$nextTick(() => {
          this.modelForm.operationList.forEach(
            (item , index) => {
              this.tableDataOption.push(tableOption())
              if (item.model) {
                this.changeVal(item , index);
              }
            }
          )
        })
      }
    },
    findTableByDatasourceid() {
      this.modelLoading = true;
      let o = {
        datasourceType: 0,
        datasourceCode: this.dataSoure.code,
        isSimple: true,
      };
      if (this.dataSoure.code) {
        findTableByDatasourceid(o)
          .then((res) => {
            this.modelList = res["data"]["data"] || [];
            this.modelLoading = false;
          })
          .catch((e) => {
            this.tables = [];
            this.modelLoading = false;
          });
      }
    },
    changeVal(item , index) {
      this.getColumnsByTable(item.model , index);
    },
    getColumnsByTable(model , index) {
      let o = {
        datasourceCode: this.dataSoure.code,
        tableName: model,
        datasourceType: 0,
      };
      if (this.dataSoure.code) {
        getColumnsByTable(o).then((res) => {
          if (res.data.code == 200) {
            this.tableDataOption[index].column[0].dicData = res.data.data.columnVOList || [];
          }
        }).catch((e) => {});
      }
    },
    addOpera() {
      this.modelForm["operationList"] = this.modelForm["operationList"] || [];
      this.tableDataOption.push(tableOption())
      this.modelForm["operationList"].push({
        model: "",
        fields: [
          {
            field: "",
            value: "",
            operationType: "", //lt大于，小于gt 等于equal  like
          },
        ],
      });
    },
    delOpra(i) {
      this.modelForm.operationList.splice(i, 1);
      this.tableDataOption.splice(i , 1);
    },
    formChange(key , val){
      let attrObj = {
        [key]: val ? val : this.modelForm[key]
      }
      if(this.bpmnElement){
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, attrObj);
      }
    }
  },
};
</script>
<style lang="scss" scoped>
/deep/ .avue-crud .el-table .el-form-item {
  margin-bottom: 1px !important;
}
.text-red {
  color: #f56c6c;
}
.strong {
  padding: 0px 0px 15px 20px;
}
/deep/ .avue-crud__pagination {
  position: relative;
  padding: 5px 0 10px 10px;
  text-align: right;
}
/deep/ .avue-crud__empty {
  padding: 10px 0;
}
</style>

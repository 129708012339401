<template>
  <div>
    <div v-if="title || (optionItem && optionItem.optionList && optionItem.name)" @click="show = !show" class="option-group-title">
      <b>{{title || (optionItem && optionItem.name)}} </b>
      <el-tooltip v-if="optionItem.desc" class="item" effect="dark" placement="top">
        <pre slot="content">{{getTooltipContent()}}</pre>
        <i class="mar-l-5 el-icon-info"></i>
      </el-tooltip>
      <span class="right-btn">
        <el-link v-if="optionItem.type === 'dynamic' && optionItem.meta" class="mar-r-15" :underline="false">
          <el-tooltip class="item" effect="dark" :content="'添加' + (optionItem && optionItem.name)" placement="top">
            <i class="el-icon-plus" @click.stop="addOption"></i>
          </el-tooltip>
        </el-link>
        <el-link :underline="false">
          <i :class="{'el-icon-arrow-right': !show , 'el-icon-arrow-down': show}"></i>
        </el-link>
      </span>
    </div>
    <div v-if="show && getDisplay()">
      <template v-if="optionItem && !optionItem.optionList">
        <template v-if="!optionItem.hide">
          <el-form-item>
            
            <template slot="label">
              {{i18n(optionItem.title ? optionItem.title : firstUpperCase(optionItem.name))}}
              <el-tooltip v-if="optionItem.desc" class="item" effect="dark" placement="top">
                <pre slot="content">{{getTooltipContent()}}</pre>
                <i class="mar-l-5 el-icon-info"></i>
              </el-tooltip>
            </template>

            <el-input v-if="optionItem.type === 'String'" type="text" v-bind="optionItem.props" v-model="option[optionItem.code]"></el-input>

            <el-input v-if="optionItem.type === 'textarea'" type="textarea" v-bind="optionItem.props" v-model="option[optionItem.code]"></el-input>

            <el-switch v-if="optionItem.type === 'Boolean'" v-bind="optionItem.props" v-model="option[optionItem.code]"></el-switch>

            <el-select v-if="optionItem.type === 'Select'"  class="dis-b" v-bind="optionItem.props" v-model="option[optionItem.code]">
              <el-option v-for="selectItem of optionItem.selectList" :label="selectItem.name" :value="selectItem.value"></el-option>
            </el-select>

            <template v-if="optionItem.type === 'radio'">
              <el-radio v-model="option[optionItem.code]" v-for="selectItem of optionItem.selectList" :label="selectItem.value">{{selectItem.name}}</el-radio>
            </template>


            <el-color-picker v-if="optionItem.type === 'color'" v-bind="optionItem.props" v-model="option[optionItem.code]"></el-color-picker>

            <el-input v-if="optionItem.type === 'number'" type="number" v-bind="optionItem.props" v-model="option[optionItem.code]">
              <el-select v-if="optionItem.units" class="dv-el-select-input" v-model="option['unit'][optionItem.code]" slot="append" placeholder="请选择">
                <el-option v-for="item of optionItem.units" :value="item.value" :label="item.name" :key="item.value">
                  <span style="float: left">{{ item.name }}</span>
                  <span v-if="item.desc" style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                </el-option>
              </el-select>
            </el-input>

          </el-form-item>
        </template>
      </template>
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'FormItem',
  data(){
    return {
      show: true
    }
  },
  props: {
    i18n: Function,
    title: {
      type: String,
      default: ''
    },
    optionItem: {
      type: Object,
      default(){
        return {}
      }
    },
    option: {
      type: Object,
      default(){
        return null
      }
    },
    rootOption: {
      type: Object,
      default(){
        return null
      }
    }
  },
  computed: {
    rootPropOption(){
      return this.rootOption === null ? this.option : this.rootOption;
    }
  },
  mounted(){
    // console.log(this.optionItem , this.option);
  },
  methods: {
    isArray(arr){
      return Array.isArray(arr);
    },
    addOption(evt){
      console.log(evt)
      let obj = {};
      this.optionItem.meta.forEach(
        item => {
          obj[item.code] = item['value'] || null;
        }
      )
      this.option[this.optionItem.code].push(obj);
      this.optionItem.optionList.push(this.optionItem.meta);
    },
    deleteOption(index){
      this.optionItem.optionList.splice(index , 1);
      this.option[this.optionItem.code].splice(index , 1);
    },
    getTooltipContent(){
      if(this.optionItem['desc']){
        const desc = this.optionItem['desc'];
        if(this.isArray(desc)){
          return desc.join('\n');
        }else{
          return desc;
        }
      }
    },
    firstUpperCase(str , splitStr){
      if(str && typeof str === 'string'){
        let strArr = [];
        if(splitStr){
          strArr = str.split(splitStr);
        }else{
          strArr = [str];
        }
        return strArr.map(itemStr => {
          return itemStr.slice(0 , 1).toLocaleUpperCase() + itemStr.slice(1);
        }).join('');
      }else{
        return str;
      }

    },
    getDisplay(){
      let display = false;
      if(this.optionItem.displayConditions){
        let conditionsResults = [];
        this.optionItem.displayConditions.forEach(
          item => {
            let value = item.prop.split('.').reduce((prev , current) => {
              return prev[current];
            } , this.rootPropOption);
            switch(item.operator){
              case 'equal':
                if(item.value === value){
                  conditionsResults.push(true);
                }else{
                  conditionsResults.push(false);
                }
                break;
            }
          }
        )
        if(!conditionsResults.includes(false)){
          display = true;
        }
      }else{
        display = true;
      }
      return display;
    }
  }
}
</script>
<style lang="scss" scoped>
.option-group-title{
  font-size: 14px;
  padding: 10px 0;
  cursor: pointer;
  .right-btn{
    float: right;
  }
}
.optColorItemAdd{
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-right: 5px;
  border: 1px solid #ddd;
  vertical-align: top;
  text-align: center;
  border-radius: 4px;
}
</style>
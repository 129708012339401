<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" :search.sync="query" :page.sync="page" ref="crud" :form="form" @row-update="rowSave" @row-save="rowSave" @row-del="rowDel"
                   @search-change="searchChange" @search-reset="searchReset" @selection-change="selectionChange" @current-change="currentChange" @size-change="sizeChange"
                   @refresh-change="refreshChange" @on-load="onLoad">
            <template slot="menuLeft">
                <el-button type="primary " size="small" icon="el-icon-user" @click="yjps">一键配送
                </el-button>
            </template>
        </avue-crud>
    </basic-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { getMetaCommonList, delMetaCommon, saveMetaCommon, updateMetaCommon } from '@/api/busiMode/metaCommon';

export default {
    data() {
        // 正则表达式 验证手机号
        const validateMobile = (rule, value, callback) => {
            if (!/^[1]([3-9])[0-9]{9}$/.test(value)) {
                callback(new Error('请输入正确的手机号格式'));
            } else {
                callback();
            }
        };
        return {
            //{vipNo:会员号(当前不用选),operType:操作类型(1充值、2消费),,memo:备注,value:金额}
            formRecharge: {},
            vipNo: '',
            form: {},
            downLoading: false,
            model: 'wisdom_park.wp_order_dispense',
            formLabelWidth: '120px',
            dialogVisible: false,
            query: {},
            currentRow: {},
            loading: true,
            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            czje: 1,
            selectionList: [],
            option: {
                height: 'auto',
                calcHeight: 30,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                addBtn: false,
                delBtn: true,
                editBtn: false,
                index: true,
                viewBtn: true,
                selection: true,
                dialogClickModal: false,
                menuWidth: 250,
                column: [
                    {
                        label: '送餐员',
                        prop: 'deliver',
                        search: true,
                        searchSpan: 6,
                        rules: [
                            {
                                required: true,
                                message: '请输入送餐员,最长20位',
                                trigger: 'blur',
                            },
                        ],
                    },

                    {
                        label: '联系电话',
                        prop: 'phone',
                        search: true,
                        searchSpan: 6,
                        minWidth: 120,
                        rules: [
                            {
                                required: true,
                                message: '请输入配送人员联系电话',
                                trigger: 'blur',
                            },
                            {
                                trigger: 'blur',
                                validator: validateMobile,
                            },
                        ],
                    },
                    {
                        label: '配送时间',
                        prop: 'dispense_time',
                        search: true,
                        searchSpan: 6,
                        rules: [
                            {
                                required: true,
                                message: '请选择配送时间',
                                trigger: 'blur',
                            },
                        ],
                    },

                    {
                        label: '配送状态',
                        prop: 'status',
                        addDisplay: false,
                        editDisplay: true,
                        searchSpan: 6,
                        type: 'radio',
                        dicData: [
                            {
                                label: '已经配送',
                                value: '1',
                            },
                            {
                                label: '未配送',
                                value: '0',
                            },
                        ],
                        rules: [
                            {
                                required: true,
                                message: '请输入 0无效 1有效',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        addDisplay: false,
                        editDisplay: false,
                        label: '创建时间',
                        prop: 'create_time',
                        type: 'datetime',
                        format: 'yyyy-MM-dd HH:mm:ss',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        width: 150,
                        rules: [
                            {
                                required: true,
                                message: '请输入创建时间',
                                trigger: 'blur',
                            },
                        ],
                    },
                ],
            },
            data: [],
        };
    },
    computed: {
        ...mapGetters(['permission', 'userInfo']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.spcodaccount_add, false),
                viewBtn: this.vaildData(this.permission.spcodaccount_view, false),
                delBtn: this.vaildData(this.permission.spcodaccount_delete, false),
                editBtn: this.vaildData(this.permission.spcodaccount_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },

    methods: {
        yjps() {
            this.$router.push({
                path: '/deliveryOne',
            });
        },
        putOffList() {
            this.putOffDialogVisible = true;
            // console.log(this.selectionList());
        },
        closeDia() {
            this.putOffDialogVisible = false;
        },
        openRowUpdate(row) {
            this.currentRow = row;
            this.dialogVisible = true;
        },
        sure_czje() {
            this.currentRow.balance = Number(this.currentRow.balance) + this.czje;
            let obj = {};
            Object.keys(this.currentRow).forEach(ele => {
                if (!ele.includes('$') && !ele.includes('_')) {
                    obj[ele] = this.currentRow[ele];
                }
            });
            let body = {
                update: obj,
                primaryKeyValue: this.currentRow.id,
                primaryKey: 'id',
            };
            updateMetaCommon(this.model, body).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '充值成功!',
                    });
                    this.dialogVisible = false;
                    done();
                },
                error => {
                    loading();
                    window.console.log(error);
                },
            );
        },

        rowSave(row, index, done, loading) {
            if (row.id) {
                let body = {
                    update: row,
                    primaryKeyValue: row.id,
                    primaryKey: 'id',
                };
                updateMetaCommon(this.model, body).then(
                    () => {
                        this.onLoad(this.page);
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        done();
                    },
                    error => {
                        loading();
                        window.console.log(error);
                    },
                );
            } else {
                row.status = 1;
                saveMetaCommon(this.model, {
                    values: row,
                }).then(
                    () => {
                        this.onLoad(this.page);
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        done();
                    },
                    error => {
                        loading();
                        window.console.log(error);
                    },
                );
            }
        },

        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                let pk = 'id';
                delMetaCommon(this.model, { pk: pk, ids: [row[pk]] }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    this.onLoad(this.page);
                });
            });
        },
        searchReset() {
            this.onLoad(this.page);
        },
        searchChange(params, done) {
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
            done();
        },
        selectionChange(list) {
            this.selectionList = list;
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        currentChange(currentPage) {
            this.page.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        getSearch() {
            let obj = {
                current: this.page.currentPage,
                size: this.page.pageSize,
            };
            let wheres = [];
            for (let key in this.query) {
                if (this.query[key] != '' && key[0] !== '$') {
                    wheres.push({
                        field: key,
                        value: this.query[key],
                    });
                }
            }
            if (wheres.length > 0) {
                obj.wheres = wheres;
            }

            return obj;
        },
        onLoad(page, params = {}) {
            this.loading = true;
            let body = {
                current: this.page.currentPage,
                size: this.page.pageSize,
                wheres: [{ field: 'status', value: 1 }],
            };
            console.log(this.form, params);
            if (params.deliver) {
                body['wheres'].push({ field: 'deliver', op: 'LIKE', value: params.deliver });
            }
            if (params.phone) {
                body['wheres'].push({ field: 'phone', value: params.phone });
            }
            if (params.dispense_time) {
                body['wheres'].push({ field: 'dispense_time', value: params.dispense_time });
            }
            getMetaCommonList(this.model, body).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                this.loading = false;
                this.selectionClear();
            });
        },
        // 跳转
        clickDetailList(row) {
            this.$router.push({
                path: '/spcodaccount/detailist',
                query: {
                    vipNo: row.vipNo,
                    accId: row.id,
                },
            });
        },
        gotoCard(row) {
            this.$router.push({
                path: '/spcodaccount/cardlist',
                query: {
                    accId: row.id,
                },
            });
        },
        procAccountDetail(row) {
            procAccountDetail(row).then(res => {
                console.error('获取');
                console.log(res);
            });
        },
        // 充值
        handleRecharge(row) {
            this.vipNo = row.vipNo;
            this.dialogVisible = true;
        },
        // 充值弹窗关闭事件
        dialogCancel() {},
        // 充值提交
        rechargeSubmit() {
            let _this = this;
            console.log(this.formRecharge);
            this.formRecharge.vipNo = this.vipNo;
            procAccountDetail(this.formRecharge).then(res => {
                //console.log(res);
                _this.$message({
                    type: 'success',
                    message: res.data.msg,
                });
                this.dialogVisible = false;
            });
        },
    },
};
</script>

<style></style>

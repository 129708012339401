import service from '@/api/dataAssets/dataMap'

export default {
    methods: {
        getNodeId(id){
            ['_getData_datasource_id_' , '_getData_datahierarchy_id_' , '_getData_app_id_' , '_getData_table_id_'].forEach(
                item => {
                    id = id.replace(item , '');
                }
            )
            return id;
        },
        _getData_errorHandle(e){
            this.loading = false;
            this.isError = true;
        },
        _getData_getTables(dhId){
            this.loading = true;
            return service.getTables(dhId).then(
                res => {
                    let tables = (res.data.data || []).map(
                        item => {
                            let indicators = [];
                            (item.indicators || []).forEach(
                                indicator => {
                                    if(this.showTableIndicators.includes(indicator.code)){
                                        indicators.push(indicator);
                                    }
                                }
                            )
                            return {
                                ...item,
                                indicators: indicators,
                                label: item.tableName,
                                id: '_getData_table_id_' + item.id,
                                _id: item.id,
                                relation: item.relation || [],
                                attrs: item.attrs || []
                            }
                        }
                    );
                    this.tables = tables;
                    this.loading = false;
                }
            )
        },
        _getData_datasourcesList(){
            return service.datasourcesList({
                projectName: this.$store.state.dag.projectName
            }).then(
                res => {
                    this.datasources = (res.data.data || []).map(item => {
                        return {
                            ...item,
                            id: '_getData_datasource_id_' + item.id,
                            _id: item.id,
                        }
                    });
                    return res;
                }
            ).catch(e => {
                this._getData_errorHandle(e);
            })
        },
        _getData_taskCount(){
            return service.taskCount({
                type: [9,8].join(','),
                projectName: this.$store.state.dag.projectName
            }).then(
                res => {
                    this.tasks = res.data.data;
                    return res;
                }
            ).catch(e => {
                this._getData_errorHandle(e);
            })
        },
        _getData_datahierarchy(){
            return service.datahierarchy().then(
                res => {
                    let dataHierarchys = [];
                    (res.data.data || []).forEach(
                        item => {
                            let itemDataHierarchys = {
                                label: item.label,
                                id: '_getData_datahierarchy_id_' + item.id,
                                _id: item.id,
                                indicators: []
                            }
                            item.indicators.forEach(item => {
                                if(this.showDHIndicators.includes(item.code)){
                                    itemDataHierarchys.indicators.push(item);
                                }
                            })
                            dataHierarchys.push(itemDataHierarchys);
                        }
                    )
                    this.dataHierarchys = dataHierarchys;
                    return res;
                }
            ).catch(e => {
                this._getData_errorHandle(e);
            })
        },
        _getData_getApps(){
            return service.getApps().then(
                res => {
                    let applications = [];
                    (res.data.data || []).forEach(
                        item => {
                            applications.push({
                                ...item,
                                name: item.appName,
                                id: '_getData_app_id_' + item.id,
                                _id: item.id,
                            })
                        }
                    )
                    this.applications = applications;
                    return res;
                }
            ).catch(e => {
                this._getData_errorHandle(e);
            })
        }
    }
}
<template>
  <basic-container>
    <avue-crud
      :data="tableDatas"
      :option="tableOption"
      :table-loading="tableLoading"
      @on-load="onLoad"
      @refresh-change="onLoad"
    >
      <template slot="menuLeft">
        <el-button @click="$router.back()" icon="el-icon-back" type="text" size="small">返回</el-button>
      </template>
      <template slot="dataBaseName" slot-scope="scope">
        <el-link type="primary" :href="'#/dws/datamodel/table/' + scope.row.dataBaseId + '?guid=' + scope.row.guid">{{scope.row.dataBaseName}}</el-link>
      </template>
    </avue-crud>
  </basic-container>
</template>
<script>
import { 
  queryDataBaseByDataModelId
} from "@/api/dws/datamodel";
export default {
  data(){
    return {
      dataModelId: this.$router.currentRoute.params.id,
      tableDatas: [],
      tableLoading: false,
      tableOption: {
        menu:false,
        addBtn:false,
        editBtn:false,
        delBtn:false,
        column:[
          {
            label:'库名称',
            prop:'dataBaseName'
          },
          {
            label:'库类型',
            prop:'modeltype'
          }
        ]
      }
    }
  },
  methods: {
    onLoad(){
      let obj = {
        id: this.dataModelId
      };
      this.tableLoading = true;
      queryDataBaseByDataModelId(obj).then( res => {
        this.tableDatas = res['data']['data'];
        this.tableLoading = false;
      }).catch(() => {
        this.tableDatas = [];
        this.tableLoading = false;
      });
    }
  }
}
</script>
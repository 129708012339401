<template>
  <basic-container>
    <el-row>
      <el-col :span="24">
        <modelsTree :menuDo="`showDetail`" @showDetail="showDetail"></modelsTree>
      </el-col>
    </el-row>
  </basic-container>
</template>

<script>
import modelsTree from "../data/table/dataConfig/modelsTree";

export default {
  components: { modelsTree },
  data() {
    return {};
  },
  computed: {},
  methods: {
    showDetail(data) {
      console.log(data);
      this.$router.push({
        path: "/tool/modelDetail",
        query: {
          dataSource: JSON.stringify(data[0]),
          row: JSON.stringify(data[1]),
        },
      });
    },
  },
};
</script>

<style></style>

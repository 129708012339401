<template>
  <basic-container>
    <div class="diaTitle">
      <span
        class="fts-14 title titleavtive mar-l-20"
        :class="{ titleactive: active == 1 }"
        >1 楼宇信息</span
      >
      <span class="fts-14 title mar-l-15" :class="{ titleactive: active == 2 }"
        >2 楼层信息</span
      >
    </div>
    <div>
      <div style="height: calc(80vh - 110px); overflow: auto; padding: 15px">
        <buildInfo
          v-show="active == 1"
          ref="buildInfo"
          :ruleModel="ruleModel"
        ></buildInfo>
        <roomInfo v-show="active == 2" ref="roomInfo" @close="close"></roomInfo>
      </div>
    </div>
    <div class="btnClass bor-t">
      <el-button size="small" type="" @click="close" v-if="active == 1"
        >取消</el-button
      >
      <el-button
        size="small"
        type="primary"
        @click="saveBuild"
        v-if="active == 1"
        class="pull-right mar-t-10"
        >下一步</el-button
      >

      <el-button size="small" @click="pre" v-if="active == 2">上一步</el-button>
      <el-button
        size="small"
        class="pull-right mar-t-10"
        type="primary"
        @click="saveRoom"
        v-if="active == 2"
        >保存</el-button
      >
    </div>
  </basic-container>
</template>

<script>
import buildInfo from "./buildInfo";
import roomInfo from "./roomInfo";
import { saveMetaCommon } from "@/api/busiMode/metaCommon";
export default {
  components: { buildInfo, roomInfo },
  props: {
    ruleModel: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      active: 1,
      pkBuild: "wisdom_park.wp_building",
      pkRoom: "wisdom_park.room",
    };
  },
  computed: {},
  methods: {
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    pre() {
      this.active--;
    },
    close() {
      this.$emit("close");
    },
    saveRoom() {
      Promise.all([
        this.$refs.buildInfo.submit(),
        this.$refs.roomInfo.submit(),
      ]).then((forms) => {
        let valid = true,
          errorObj = null;
        forms.forEach((itemForm) => {
          if (itemForm.valid !== true) {
            valid = false;
            if (!errorObj) {
              errorObj = itemForm.error;
            }
          }
        });
        if (valid) {
          let buildInfo = forms[0].form,
            roomInfo = forms[1].form;
          if (buildInfo) {
            buildInfo.address = this.$refs.buildInfo.address;
            // buildInfo.longitude = this.$refs.buildInfo.adressForm[0];
            // buildInfo.latitude = this.$refs.buildInfo.adressForm[1];
            buildInfo.floor_height = roomInfo.floor_height;
            //照片
            if (this.$refs.buildInfo.imgform) {
              let imgList = [];
              this.$refs.buildInfo.imgform.imgUrl.forEach((e) => {
                if (e.label) {
                  imgList.push(e.label);
                }
              });
              buildInfo.attachment_id = imgList.toString();
            }
            buildInfo.floor_num = roomInfo.floor_num;

            let configtable = this.$refs.roomInfo.getConfigTable();
            console.log(configtable);
            if (configtable) {
              if (
                configtable.aboveFloorList.length +
                  configtable.underFloorList.length >
                buildInfo.floor_num
              ) {
                this.$message.error("地上和地下的层数不能大于整栋楼宇的层数");
                return;
              }

              let floorage = 0;
              configtable.aboveFloorList.forEach((e) => {
                if (e.floor_area) {
                  floorage = floorage + Number(e.floor_area);
                }
              });
              configtable.underFloorList.forEach((e) => {
                if (e.floor_area) {
                  floorage = floorage + Number(e.floor_area);
                }
              });

              if (this.$refs.buildInfo.ruleForm.floorage) {
                console.log(floorage);
                console.log(this.$refs.buildInfo.ruleForm.floorage);
                if (this.$refs.buildInfo.ruleForm.floorage < floorage) {
                  this.$message.error("地上和地下的面积不能大于整栋楼宇的面积");
                  return;
                }
              }
            }
            saveMetaCommon(this.pkBuild, {
              values: {
                ...buildInfo,
                ...roomInfo,
                label: roomInfo.label.join(","),
              },
            }).then((res) => {
              if (res.data.code == 200) {
                if (res.data.data.id) {
                  Promise.all([
                    this.$refs.roomInfo.save(res.data.data.id),
                  ]).then((forms) => {
                    this.$emit("close");
                  });
                } else {
                  this.$emit("close");
                }
              }
            });
          }
        } else {
          this.$message.warning(
            `${errorObj ? errorObj.message : "请按要求填写表单"}`
          );
        }
      });
    },
    saveBuild() {
      this.next();
      let buildInfo = this.$refs.buildInfo.ruleForm;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-card__body {
  padding-top: 0px;
}
.titleactive {
  color: #475570 !important;
}
.title {
  color: #8d9ab2;
}

.diaTitle {
  position: absolute;
  top: 23px;
  left: 80px;
}
.btnClass {
  display: flow-root;
  height: 50px;
  line-height: 50px;
  padding: 0px 15px;
}
</style>

<template>
    <basic-container>
        <el-row class="pad-l-30 pad-r-30 mar-t-10">
            <el-descriptions title="" :column="5" direction="vertical" :colon="false" :labelStyle="labelStyle">
                <el-descriptions-item label="所属楼宇" :span="1">
                    {{
            getDataFromDict(buildList, ruleForm.building_id)
          }}</el-descriptions-item>
                <el-descriptions-item label="所属楼层" :span="1">
                    {{ getDataFromDict(floors, ruleForm.floor_id) }}</el-descriptions-item>
                <el-descriptions-item label="房间面积(m²)" :span="1">{{ ruleForm.room_area || 0 }} m²</el-descriptions-item>

                <el-descriptions-item label="房源类型" :span="1">
                    <span v-if="ruleForm.housing_resources_type == 1">办公</span>
                    <span v-if="ruleForm.housing_resources_type == 2">商品</span>
                    <span v-if="ruleForm.housing_resources_type == 3">公寓</span>
                </el-descriptions-item>
                <el-descriptions-item label="拆分数量">
                    <el-input-number v-model="num" size="mini" @change="handleChange" :min="1" :max="6" label=""></el-input-number>
                </el-descriptions-item>
            </el-descriptions>
        </el-row>

        <el-row class="pad-10">
            <template v-if="sonRoomList.length == 0">
                <template v-for="(item, index) of roomList">
                    <el-col :span="12" class="mar-b-15" :key="index">
                        <el-card>
                            <!-- <div class="deleteicon" @click="deleteRoom(index)">
              <i class="el-icon-delete"></i>
            </div> -->
                            <splitR :ref="'splitR' + index" :roomObj="getRoom(index)" :index="index"></splitR>
                        </el-card></el-col>
                </template>
            </template>
            <template v-if="sonRoomList.length > 0">
                <template v-for="(item, index) of sonRoomList">
                    <el-col :span="12" class="mar-b-15" :key="index">
                        <el-card>
                            <splitR :ref="'splitR' + index" :roomObj="getRoomFZ(sonRoomList[index])" :index="index"></splitR>
                        </el-card></el-col>
                </template>
            </template>
        </el-row>
    </basic-container>
</template>

<script>
import {
    getMetaCommonList,
    updateMetaCommon,
    saveMetaCommon,
    getMetaCommonInfo,
    delMetaCommon,
} from '@/api/busiMode/metaCommon';
import mathjs from '@/util/math.js';

import * as dictbizService from '@/api/system/dictbiz';
import splitR from './splitR';
export default {
    components: { splitR },
    data() {
        return {
            num: 2,
            numList: 2,
            ruleForm: {
                label: [],
            },
            labelStyle: {
                color: '#999',
                fontSize: '12px',
            },
            view_model: 'wisdom_park.v_wp_room_stat',
            roomList: [0, 1],
            sonRoomList: [],
            roomTags: [],
            dialogImageUrl: '',
            page: {
                size: 1000,
                current: 1,
                total: 0,
            },
            buildList: [],
            decorationList: [],
            paymentTypeList: [],
            housingResourcesTypes: [],
            model: 'wisdom_park.wp_room',
            deletes: [],
        };
    },
    props: {
        floors: {
            type: Array,
            default: function () {
                return [];
            },
        },
        ruleFormObj: {
            type: Object,
            default: function (val) {
                console.log(val);
                return { label: '' };
            },
        },
        room: {
            type: Object,
            default: function () {
                return { label: '' };
            },
        },
    },
    watch: {
        ruleFormObj: {
            handler: function (newValue, oldName) {
                if (newValue) {
                    console.log(newValue);
                    if (newValue.son_num > 0) {
                        this.getSonRoomList(newValue.room_id);
                        this.num = newValue.son_num;
                    } else {
                        this.getMetaCommonInfo(newValue);
                    }
                }
            },
            deep: true,
            immediate: true,
        },
    },
    computed: {},
    mounted() {},

    created() {
        this.getBuildList();
        this.getDicList();
    },
    methods: {
        //查询已有的字房间
        getSonRoomList(parentId) {
            let obj = {
                current: 1,
                size: 100,
            };
            if (parentId) {
                let wheres = [
                    { field: 'parent_id', value: parentId },
                    { field: 'is_cancel', value: 0 },
                ];
                obj.wheres = wheres;
            }
            getMetaCommonList(this.view_model, obj).then(res => {
                if (res.data.data.records) {
                    res.data.data.records.forEach(e => {
                        e.id = e.room_id;
                        if (e.son_num > 0) {
                            e.hasChildren = true;
                        }
                    });
                    this.sonRoomList = res.data.data.records;
                    this.getMetaCommonInfo(this.ruleFormObj);
                } else {
                    this.sonRoomList = [];
                }
            });
        },
        getMetaCommonInfo(room) {
            let obj = {
                pk: 'id',
                id: room.room_id,
            };
            getMetaCommonInfo(this.model, obj).then(res => {
                if (res.data.code == 200) {
                    let newValue = res.data.data;
                    if (newValue.label) {
                        if (
                            typeof newValue.label == 'string' &&
                            newValue.label.constructor == String &&
                            newValue.label != ''
                        ) {
                            newValue.label = newValue.label.split(',');
                        }
                    }
                    if (newValue.housing_resources_type) {
                        if (
                            typeof newValue.housing_resources_type == 'string' &&
                            newValue.housing_resources_type.constructor == String &&
                            newValue.housing_resources_type != ''
                        ) {
                            newValue.housing_resources_type = newValue.housing_resources_type.split(',');
                        }
                    }
                    this.ruleForm = newValue;
                }
            });
        },
        getRoomFZ(room) {
            room.name = room.room_name;
            return JSON.stringify(room);
        },
        getRoom(i) {
            let room = JSON.parse(JSON.stringify(this.ruleForm));
            if (room.name) {
                room.name = room.name + '-' + i;
            }
            if (room.room_name) {
                room.name = room.room_name + '-' + i;
            }
            if (room.room_area) {
                room.room_area = room.room_area / 2;
            }
            room.parent_id = room.id;
            delete room.id;
            return JSON.stringify(room);
        },
        async saveRooms() {
            if (this.sonRoomList.length > 0) {
                this.roomList = this.sonRoomList;
            }
            let areas = 0;
            this.roomList.forEach((e, i) => {
                let obj;
                if (i == 0) {
                    obj = this.$refs.splitR0;
                }
                if (i == 1) {
                    obj = this.$refs.splitR1;
                }
                if (i == 2) {
                    obj = this.$refs.splitR2;
                }
                if (i == 3) {
                    obj = this.$refs.splitR3;
                }
                if (i == 4) {
                    obj = this.$refs.splitR4;
                }
                if (i == 5) {
                    obj = this.$refs.splitR5;
                }
                if (i == 6) {
                    obj = this.$refs.splitR6;
                }
                if (obj[0].ruleForm.room_area) {
                    areas = mathjs.add(areas, obj[0].ruleForm.room_area);
                    // areas = Number(areas) + Number(obj[0].ruleForm.room_area);
                }
            });
            if (Number(areas) > this.ruleForm.room_area) {
                this.$message.warning('拆分的房间面积不能大于父房间面积');
                return;
            }
            this.numList = 0;
            for (let i = 0; i < this.roomList.length; i++) {
                let obj;
                if (i == 0) {
                    obj = this.$refs.splitR0;
                }
                if (i == 1) {
                    obj = this.$refs.splitR1;
                }
                if (i == 2) {
                    obj = this.$refs.splitR2;
                }
                if (i == 3) {
                    obj = this.$refs.splitR3;
                }
                if (i == 4) {
                    obj = this.$refs.splitR4;
                }
                if (i == 5) {
                    obj = this.$refs.splitR5;
                }
                if (i == 6) {
                    obj = this.$refs.splitR6;
                }
                await this.saveMetaCommon(obj[0].ruleForm);
            }
            for (let i = 0; i < this.deletes.length; i++) {
                await this.delMetaCommon(this.deletes[i]);
            }
        },
        deleteRoom(i) {
            this.roomList.splice(i, 1);
            this.num = this.roomList.length;
        },
        handleChange(value) {
            if (this.sonRoomList.length == 0) {
                this.roomList = [];
                for (let i = 0; i < this.num; i++) {
                    this.roomList.push(i);
                }
            } else {
                if (this.sonRoomList.length < value) {
                    let room = {};
                    if (this.ruleForm.name) {
                        room.name = this.ruleForm.name + '-' + value;
                    }
                    if (this.ruleForm.room_name) {
                        room.name = this.ruleForm.room_name + '-' + value;
                    }
                    if (this.ruleForm.room_area) {
                        room.room_area = this.ruleForm.room_area / value;
                    }
                    room.parent_id = this.ruleFormObj.room_id || id;
                    this.sonRoomList.push(room);
                } else {
                    this.deletes.push(this.sonRoomList[this.sonRoomList.length - 1]);
                    this.sonRoomList.pop();
                }
            }
            this.ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
        },

        async delMetaCommon(row) {
            delMetaCommon(this.model, { pk: 'id', ids: [row['id']] }).then(res => {
                this.numList = this.numList + 1;
                if (this.numList == this.deletes.length) {
                    this.$emit('close');
                }
            });
        },
        async saveMetaCommon(r) {
            let room = r;
            let roomObj = {};
            if (room.id) {
                roomObj.id = room.id;
            }
            roomObj.name = room.name;
            roomObj.room_area = room.room_area;
            roomObj.payment_type = room.payment_type;
            roomObj.rental_unit_price_day = room.rental_unit_price_day;
            roomObj.rental_unit_price_month = room.rental_unit_price_month;

            roomObj.parent_id = this.ruleFormObj.room_id;
            roomObj.parent_name = this.ruleFormObj.room_name;
            roomObj.building_id = this.ruleFormObj.building_id;

            roomObj.code = this.ruleFormObj.room_code;
            roomObj.renovation = this.ruleFormObj.renovation;
            roomObj.floor_id = this.ruleFormObj.floor_id;
            roomObj.building_name = this.ruleFormObj.building_name;
            roomObj.floor_name = this.ruleFormObj.floor_code;
            roomObj.has_ownership = this.ruleFormObj.has_ownership;
            roomObj.is_invented = this.ruleFormObj.is_invented;
            roomObj.buy_company_id = room.buy_company_id;
            roomObj.buy_company_name = room.buy_company_id;
            if (r.label && r.label.length > 0) {
                roomObj.label = this.ruleFormObj.label.toString();
            }
            if (r.housing_resources_type && r.housing_resources_type.length > 0) {
                roomObj.housing_resources_type = r.housing_resources_type.toString();
            }
            if (r.housing_resources_type && r.housing_resources_type.length == 0) {
                roomObj.housing_resources_type = '';
            }
            console.log(r);
            console.log(roomObj);
            console.log(this.ruleFormObj);
            // return false;

            if (roomObj.id) {
                let o = {
                    update: JSON.parse(JSON.stringify(roomObj)),
                    primaryKeyValue: room['id'],
                    primaryKey: 'id',
                };
                updateMetaCommon(this.model, o).then(res => {
                    if (res.data.code == 200) {
                        if (this.deletes.length == 0) {
                            this.numList = this.numList + 1;
                            if (this.numList == this.num) {
                                this.$emit('close');
                            }
                        }
                    }
                });
            } else {
                saveMetaCommon(this.model, {
                    values: JSON.parse(JSON.stringify(roomObj)),
                }).then(res => {
                    if (res.data.code == 200) {
                        if (this.deletes.length == 0) {
                            this.numList = this.numList + 1;
                            if (this.numList == this.num) {
                                this.$emit('close');
                            }
                        }
                    }
                });
            }
        },

        setMonthPrice() {
            if (this.ruleForm.room_area != '' && this.ruleForm.rental_unit_price_day != '') {
                let m = Number(this.ruleForm.room_area) * Number(this.ruleForm.rental_unit_price_day);
                this.ruleForm.rental_unit_price_month = m ? m * 30 : 0;
            } else {
                this.ruleForm.rental_unit_price_month = 0;
            }
        },

        getDicList() {
            dictbizService
                .getDictionary({
                    code: 'RoomBrightenedDot',
                })
                .then(res => {
                    this.roomTags = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                            id: item['id'],
                        };
                    });
                });
            dictbizService
                .getDictionary({
                    code: 'decoration',
                })
                .then(res => {
                    this.decorationList = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
            dictbizService
                .getDictionary({
                    code: 'paymentType',
                })
                .then(res => {
                    this.paymentTypeList = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });

            dictbizService
                .getDictionary({
                    code: 'housingResourcesType',
                })
                .then(res => {
                    this.housingResourcesTypes = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
        },
        getDataFromDict(data, id) {
            let title = '';
            data.forEach(e => {
                if (e.id == id) {
                    title = e.name || e.code || e.name;
                }
            });
            return title;
        },
        getBuildList() {
            getMetaCommonList('wisdom_park.wp_building', this.page).then(res => {
                const data = res.data.data;
                this.buildList = data.records;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
    margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
    left: 0px;
}
/deep/ .el-divider__text {
    padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
    width: 68px;
    height: 68px;
    line-height: 72px;
    vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 68px;
    height: 68px;
}
.deleteicon {
    margin-top: -10px;
    text-align: right;
    margin-right: -8px;
    cursor: pointer;
    font-size: 14px;
}
o-a {
    overflow-x: auto;
}
</style>

<template>
  <basic-container>
    <avue-crud :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               :before-open="beforeOpen"
               v-model="form"
               ref="crud"
               @row-update="rowUpdate"
               @row-save="rowSave"
               @row-del="rowDel"
               @search-change="searchChange"
               @search-reset="searchReset"
               @selection-change="selectionChange"
               @current-change="currentChange"
               @size-change="sizeChange"
               @refresh-change="refreshChange"
               @on-load="onLoad">
      <template slot="menuLeft">
        <el-button type="danger"
                   size="small"
                   icon="el-icon-delete"
                   plain
                   v-if="permission.spcoddeliveryorder_delete"
                   @click="handleDelete">删 除
        </el-button>
      </template>

       <template slot-scope="{type,size,row}" slot="menu">
        <template v-if="row.status == 1">
          <el-button icon="el-icon-s-promotion" :size="size" :type="type" @click="assignDelivery(row)">指派</el-button>
        </template>
        <template v-if="row.status == 2">
          <el-button icon="el-icon-top-right" :size="size" :type="type" @click="assignDelivery(row)">再指派</el-button>
        </template>
      </template>
    </avue-crud>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      width="70%"
      append-to-body
      @close="dialogCancel"
    >
    <!-- ,,memo:备注,value:金额 -->
    <avue-form :option="formDeliveryOption" @submit="deliverySubmit" v-model="formDelivery"></avue-form>
    </el-dialog>

  </basic-container>
</template>

<script>
  import {getList, getPage, getDetail, add, update, remove,createOrder, assignOrder} from "@/api/spcod/spcoddeliveryorder";
  import {mapGetters} from "vuex";

  export default {
    data() {
      return {
        form: {},
        query: {},
        loading: true,
        curDeliveryRow:{},
        formDelivery:{},
        formDeliveryOption: {
          column: [
            {
              label: '配送员',
              prop: 'deliveryId',
              type: 'select',
              dicUrl: '/api/park-spcod/deliveryman/page?current=1&size=100',
               props: {
                label: 'deliveryName',
                value: 'userId'
              },
              dicFormatter:(res)=>{
                //console.log(res.data.data.records);
                return res.data.records;
              },
            },
            {
              label: '备注',
              prop: 'memo',
              type: 'textarea',
              span:24,
              minRow:2
            }
          ]
        },
        dialogTitle: '配送指派',
        dialogVisible: false,
        page: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        selectionList: [],
        option: {
          height:'auto',
          calcHeight: 30,
          tip: false,
          addBtn:false,
          delBtn:false,
          searchShow: true,
          searchMenuSpan: 6,
          border: true,
          index: true,
          viewBtn: true,
          // selection: true,
          dialogClickModal: false,
          column: [
            {
              label: "配送单号",
              prop: "orderNo",
              search:true,
              searchSpan:4,
              rules: [{
                required: true,
                message: "请输入配送单号",
                trigger: "blur"
              }]
            },
            {
              label: "订单时间",
              prop: "orderTime",
              rules: [{
                required: true,
                message: "请输入订单时间",
                trigger: "blur"
              }]
            },
            {
              label: "订单时间",
              prop: "orderDay",
              hide:true,
              search:true,
              searchSpan:4,
              type: "datetime",
              format: "yyyy-MM-dd",
              valueFormat: "yyyy-MM-dd",
              rules: [{
                required: true,
                message: "请输入订单时间",
                trigger: "blur"
              }]
            },
            {
              label: "配送时间",
              prop: "deliveryTime",
              rules: [{
                required: true,
                message: "请输入配送时间",
                trigger: "blur"
              }]
            },
            {
              label: "收货时间",
              prop: "receiptTime",
              rules: [{
                required: true,
                message: "请输入收货时间",
                trigger: "blur"
              }]
            },
            {
              label: "送货人",
              prop: "deliveryName",
              rules: [{
                required: true,
                message: "请输入送货人，指派操作时候指定",
                trigger: "blur"
              }]
            },
            {
              label: "送货电话",
              prop: "deliveryPhone",
              addDisplay:false,
              editDisplay:false,
              rules: [{
                required: false,
                message: "",
                trigger: "blur"
              }]
            },
            {
              label: "地址标签",
              prop: "addrTag",
              search:true,
              searchSpan:4,
              rules: [{
                required: true,
                message: "请输入地址标签,一号楼/21栋，便于查询",
                trigger: "blur"
              }]
            },
            {
              label: "业务",
              prop: "bizType",
              type:"select",
              search:true,
              searchSpan:3,
              dicData:[{
                label:'XPS快递',
                value:'XPS'
              },{
                label:'餐食',
                value:'FOD'
              }],
              rules: [{
                required: true,
                message: "请输入业务类型，XPS快递/FOD餐食",
                trigger: "blur"
              }]
            },
            {
              label: "业务主ID",
              prop: "bizId",
              hide:true,
              addDisplay:false,
              editDisplay:false,
              rules: [{
                required: true,
                message: "请输入业务主ID，对应到快递单ID/餐饮订单ID",
                trigger: "blur"
              }]
            },
            {
              label: "配送单信息",
              prop: "deliveryTitle",
              rules: [{
                required: true,
                message: "请输入配送单信息",
                trigger: "blur"
              }]
            },
            {
              label: "备注",
              prop: "memo",
              hide:true,
              rules: [{
                required: true,
                message: "请输入备注",
                trigger: "blur"
              }]
            },
            {
              label: "收货人",
              prop: "receiptorName",
              rules: [{
                required: true,
                message: "请输入收货人，配送单生成时写入",
                trigger: "blur"
              }]
            },
            {
              label: "状态",
              prop: "status",
              search:true,
              searchSpan:3,
              addDisplay: false,
              editDisplay :false,
              viewDisplay: false,
              hide: false,
              type: 'select',
              dicData:[{
                label:'新单',
                value:1
              },
              {
                label: '已指派',
                value: 2
              },
              {
                label: '已收货',
                value: 3
              }],
              rules: [{
                required: true,
                message: "请输入状态",
                trigger: "blur"
              }]
            },
          ]
        },
        data: []
      };
    },
    computed: {
      ...mapGetters(["permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.spcoddeliveryorder_add, false),
          viewBtn: this.vaildData(this.permission.spcoddeliveryorder_view, false),
          delBtn: this.vaildData(this.permission.spcoddeliveryorder_delete, false),
          editBtn: this.vaildData(this.permission.spcoddeliveryorder_edit, false)
        };
      },
      ids() {
        let ids = [];
        this.selectionList.forEach(ele => {
          ids.push(ele.id);
        });
        return ids.join(",");
      }
    },
    methods: {
      deliverySubmit(){
        let _this = this;
        console.log(this.formDelivery);
        let formObj = {};
        formObj.orderNo = this.curDeliveryRow.orderNo;
        formObj.shipperId =this.formDelivery.deliveryId;
        formObj.memo = this.formDelivery.memo;
        console.log(formObj);
        assignOrder(formObj).then(res=>{
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          _this.dialogVisible = false;
        });
      },
      assignDelivery(row){
        this.curDeliveryRow = row;
        this.dialogVisible = true;
      },
      rowSave(row, done, loading) {
        add(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          window.console.log(error);
        });
      },
      rowUpdate(row, index, done, loading) {
        update(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          console.log(error);
        });
      },
      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(row.id);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
          });
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
            this.$refs.crud.toggleSelection();
          });
      },
      beforeOpen(done, type) {
        if (["edit", "view"].includes(type)) {
          getDetail(this.form.id).then(res => {
            this.form = res.data.data;
          });
        }
        done();
      },
      searchReset() {
        this.query = {};
        this.onLoad(this.page);
      },
      searchChange(params, done) {
        this.query = params;
        this.page.currentPage = 1;
        this.onLoad(this.page, params);
        done();
      },
      selectionChange(list) {
        this.selectionList = list;
      },
      selectionClear() {
        this.selectionList = [];
        this.$refs.crud.toggleSelection();
      },
      currentChange(currentPage){
        this.page.currentPage = currentPage;
      },
      sizeChange(pageSize){
        this.page.pageSize = pageSize;
      },
      refreshChange() {
        this.onLoad(this.page, this.query);
      },
      onLoad(page, params = {}) {
        this.loading = true;
        getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
          const data = res.data.data;
          this.page.total = data.total;
          this.data = data.records;
          this.loading = false;
          this.selectionClear();
        });
      }
    }
  };
</script>

<style>
</style>

<template>
  <el-row> 测试测试 </el-row>
</template>
<script>
export default {
  name: "tableClickCunstom",
  data() {
    return {};
  },
  created() {},

  props: {
    sortList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    options: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  mounted() {},
  methods: {},
};
</script>

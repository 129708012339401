<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      ref="crud"
      v-model="form"
      :page.sync="page"
      @selection-change="selectionChange"
      @row-del="rowDel"
      @row-update="rowUpdate"
      @row-save="rowSave"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot="menuLeft" slot-scope="scope">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="openDislog('add')"
          >新增楼宇
        </el-button>
        <el-input
          v-model="searchText"
          @keyup.native.enter="refreshChange"
          size="small"
          placeholder="请输入楼宇名称"
          type="text"
          style="width: 250px"
        >
          <el-button
            class="mar-0 pad-0"
            size="small"
            @click="refreshChange"
            slot="append"
            icon="el-icon-search"
          ></el-button>
        </el-input>
        <!-- <el-button
          type="danger"
          size="small"
          icon="el-icon-delete"
          v-if="permission.code_delete"
          plain
          @click="handleDelete"
          >删 除
        </el-button> -->
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button
          type="text"
          size="small"
          class="none-border"
          icon="el-icon-view"
          @click="openDislog('detail', scope.row)"
          >楼宇详情
        </el-button>
        <el-button
          type="text"
          size="small"
          class="none-border"
          icon="el-icon-edit"
          @click="openDislog('space', scope.row)"
          >空间管理
        </el-button>

        <el-button
          type="text"
          size="small"
          icon="el-icon-delete"
          class="none-border"
          @click="rowDel(scope.row)"
          >删除
        </el-button>
      </template>
    </avue-crud>
    <el-dialog
      :size="size"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      destroy-on-close
      :with-header="false"
      :top="'8vh'"
      :body-style="{ padding: '0px' }"
      width="70%"
      class="buildDetail"
    >
      <template v-if="dialogVisible">
        <div slot="title" v-if="dialogType != 'space'" class="header-title">
          <span class="fts-16">新增楼宇</span>
        </div>
        <div>
          <addBuild
            v-if="dialogType == 'add'"
            :ruleModel="ruleModel"
            @close="closeDia"
          ></addBuild>
          <space v-if="dialogType == 'space'"></space>
        </div>
      </template>
    </el-dialog>
    <el-drawer :visible.sync="detailDia" size="60%" :withHeader="false">
      <!-- <div slot="title" class="header-title">
        <span class="fts-16">{{ title }}</span>
      </div> -->
      <el-header class="bor-b" height="50px">
        <el-page-header
          class="lh-50"
          @back="back"
          :content="title"
        ></el-page-header>
      </el-header>
      <detail :currentRow="currentRow" @changName="changName"></detail>
    </el-drawer>
  </basic-container>
</template>

<script>
import { getMetaCommonList, delMetaCommon } from "@/api/busiMode/metaCommon";
import standardService from "@/api/metadata/standard";
import { mapGetters } from "vuex";
import addBuild from "./addBuild";
import space from "./space";
import detail from "./buildDetail";

export default {
  components: { addBuild, space, detail },
  data() {
    return {
      searchText: "",
      form: {},
      currentRow: {},
      selectionList: [],
      loading: true,
      dialogVisible: false,
      detailDia: false,
      query: {},
      dialogType: "",
      size: "60%",
      title: "",
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      option: {
        calcHeight: 30,
        dialogWidth: 900,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        // selection: true,
        labelWidth: 120,
        menuWidth: 250,
        viewBtn: false,
        dialogClickModal: false,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        cancelBtn: false,
        column: [
          {
            width: 200,
            overHidden: true,
            align: "left",
            label: "名称",
            prop: "room_name",
            hide: false,
          },
          {
            width: 150,
            overHidden: true,
            align: "left",
            label: "楼宇名称",
            prop: "building_name",
            hide: false,
          },

          {
            width: 80,
            overHidden: true,
            label: "楼层",
            prop: "floor_code",
          },

          {
            width: 100,
            overHidden: true,
            label: "房间面积(m²)",
            prop: "room_area",
          },

          {
            width: 100,
            overHidden: true,
            label: "出租单价(m²/天)",
            prop: "rental_unit_price_day",
            hide: false,
          },
          {
            width: 150,
            overHidden: true,
            label: "入驻企业",
            prop: "company_name",
            hide: false,
          },
          // {
          //   overHidden: true,
          //   align: "left",
          //   label: "房间标签",
          //   prop: "label",
          // },
        ],
      },
      model: "wisdom_park.v_wp_room_stat",
      data: [],

      ruleModel: {},
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.code_add, false),
        viewBtn: this.vaildData(this.permission.code_view, false),
        delBtn: this.vaildData(this.permission.code_delete, false),
        editBtn: this.vaildData(this.permission.code_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  mounted() {
    this.queryByModelCode();
  },
  methods: {
    back() {
      this.detailDia = false;
      this.searchReset();
    },
    queryByModelCode() {
      standardService.getModelRule(this.model).then((res) => {
        this.ruleModel = res;
      });
    },
    openDislog(type, row) {
      this.dialogType = type;
      //this.size = type == "space" ? "100%" : "60%";
      if (type == "detail") {
        this.currentRow = row;
        this.title = row.name;
        this.detailDia = true;
      } else if (type == "space") {
        //跳转
        this.$router.push({
          path: "/build/space",
          query: {
            buildId: row.id,
            buildName: row.name,
          },
        });
      } else {
        this.dialogVisible = true;
      }
    },
    changName(row) {
      this.title = row.name;
    },
    handleBuild() {},
    rowSave(row, done, loading) {
      add(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          window.console.log(error);
          loading();
        }
      );
    },
    rowUpdate(row, index, done, loading) {
      update(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          window.console.log(error);
          loading();
        }
      );
    },
    rowDel(row, index) {
      console.log(row);
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let pk = "id";
          delMetaCommon(this.model, { pk: pk, ids: [row[pk]] }).then((res) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.onLoad(this.page);
          });
        })
        .catch(() => {});
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },

    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let pk = "id";
          let ids = [];
          this.selectionList.forEach((e) => {
            if (e.id) {
              ids.push(e.id);
            }
          });
          delMetaCommon(this.model, { pk: pk, ids: ids }).then((res) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.data.splice(index, 1);
          });
        })
        .then(() => {
          this.onLoad(this.page);

          this.$refs.crud.toggleSelection();
        });
    },

    refreshChange() {
      this.onLoad(this.page);
    },
    closeDia() {
      this.dialogVisible = false;
      this.refreshChange();
    },
    onLoad() {
      this.loading = true;
      let body = {
        current: this.page.currentPage,
        size: this.page.pageSize,
      };
      if (this.searchText) {
        body["wheres"] = [
          { field: "name", op: "LIKE", value: this.searchText },
        ];
      }
      getMetaCommonList(this.model, body).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;

        this.loading = false;
        this.selectionClear();
      });
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.deleteIcon {
  font-size: 18px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 1;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
}
/deep/ .el-drawer__header {
  border-bottom: 1px solid #ebebeb;
  padding: 10px 20px;
  margin-bottom: 0px;
}
/deep/ .el-drawer__body {
  padding: 0px;
}
.buildDetail /deep/ .el-dialog__body {
  padding: 0px;
}
</style>

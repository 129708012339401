<template>
    <div>
        <basic-container class="cards">
            <el-form :inline="true" :model="formInline" size="small" class="demo-form-inline">
                <el-form-item label="时间">
                    <el-date-picker class="w-150" type="year" value-format="yyyy" :clearable="false" @change="changeData" v-model="formInline.count_year" placeholder="开始日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="季度">
                    <el-select class="w-250" v-model="formInline.quarter" :clearable="false" size="small" placeholder="请选择季度">
                        <el-option label="第一季度" :value="1"> </el-option>
                        <el-option label="第二季度" :value="2"> </el-option>
                        <el-option label="第三季度" :value="3"> </el-option>
                        <el-option label="第四季度" :value="4"> </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属区域">
                    <el-select v-model="formInline.sub_part_id" size="small" filterable clearable @change="getBuildList" @clear="clearForm(3)" placeholder="请选择区域">
                        <template v-for="(item, index) in partList">
                            <el-option v-if="item.parent_id != 0" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="楼宇">
                    <el-select filterable v-model="formInline.building_id" clearable class="w-200" size="small" @change="getCompanyList" @clear="clearForm(2)" placeholder="请选择所属楼宇">
                        <el-option v-for="(item, index) in buildList" :key="index" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="企业">
                    <el-select v-model="formInline.company_id" clearable class="w-200" filterable size="small" @change="getContractList" placeholder="请选择企业" @clear="clearForm(1)">
                        <el-option v-for="(item, index) in companyList" :key="index" :label="item.name" :value="item.company_id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="合同">
                    <el-select class="w-200" v-model="formInline.contract_code" clearable filterable size="small" placeholder="请选择合同">
                        <el-option v-for="(item, index) in contractList" :key="index" :label="item.contract_code" :value="item.contract_code">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="getViewData">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button class="pull-right" type="primary" plain @click="exportTable" icon="el-icon-download" size="small">导出</el-button>
                </el-form-item>
            </el-form>
        </basic-container>
        <basic-container isCard>
            <div class="fts-16 text-center mar-b-10">
                {{oldPartName}}{{ formInline.count_year }}年{{quarters.map(a => a.name)[formInline.quarter - 1]}}季度政策企业挂账
            </div>
            <el-table :row-class-name="tableRowClassName" v-loading="isLoading" element-loading-text="加载中，请稍后..." :data="tableData" :span-method="objectSpanMethod" border :height="height"
                      id="excelTable" style="width: 100%">
                <el-table-column type="index" width="80" fixed>
                </el-table-column>
                <el-table-column prop="reduction_policy_title" fixed label="政策" header-align="center" width="250">
                    <template slot-scope="{row}">
                        <div :class="{'text-right': row.IsCount}">{{row.reduction_policy_title}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="contract_code" fixed label="合同号" header-align="center" width="200">
                </el-table-column>
                <el-table-column prop="renters_name" fixed label="企业名称" header-align="center" width="250">
                </el-table-column>
                <el-table-column label="合同期限" header-align="center" align="center" width="200">
                    <template slot-scope="{ row }">
                        <span v-if="!row.IsCount">{{row.start_date}} 至 {{row.end_date}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="合同租期" header-align="center" align="center" width="200">
                    <template slot-scope="{ row }">
                        <span v-if="!row.IsCount">{{row.period_start_time}} 至 {{row.period_end_time}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="room_name" label="房间号" header-align="center" width="250">
                </el-table-column>
                <el-table-column prop="rent_area" label="建筑面积" header-align="center" align="right" width="150">
                </el-table-column>
                <el-table-column prop="fee_name" label="项目" header-align="center" width="150">
                </el-table-column>
                <el-table-column :label="`${formInline.count_year}年应收款`" header-align="center" align="right">
                    <el-table-column prop="redu_rent_amount" label="其中政策" header-align="center" align="right" width="150">
                    </el-table-column>
                    <el-table-column prop="comp_rent_amount" label="其中减半收取" header-align="center" align="right" width="150">
                    </el-table-column>
                    <el-table-column prop="rent_amount" header-align="center" align="right" label="小计" width="150">
                    </el-table-column>
                </el-table-column>
                <template v-for="item of quarters">
                    <template v-if="item.value <= formInline.quarter">
                        <el-table-column :label="`${formInline.count_year}年${item.name}季度已挂账`" header-align="center" align="right">
                            <el-table-column :prop="`q${item.value}_redu_rent_amount`" :label="`${item.name}季度挂账(政策)`" header-align="center" align="right" width="160">
                            </el-table-column>
                            <el-table-column :prop="`q${item.value}_comp_rent_amount`" :label="`${item.name}季度挂账(企业缴纳减半)`" header-align="center" align="right" width="160">
                            </el-table-column>
                            <el-table-column :prop="`q${item.value}_rent_amount`" header-align="center" align="right" :label="`第${item.name}季度挂账(小计)`" width="160">
                            </el-table-column>
                        </el-table-column>
                    </template>
                </template>
                <el-table-column prop="payment_amount" :label="`已确认${formInline.count_year}年减半收入`" header-align="center" align="right" width="180">
                </el-table-column>
                <el-table-column :label="`截止到${formInline.count_year}年12月31日期末余额`" header-align="center" align="right">
                    <el-table-column label="政策" header-align="center" align="right" width="150">
                        <template slot-scope="{ row }">
                            {{getJSNum(row , 'zc')}}
                        </template>
                    </el-table-column>
                    <el-table-column label="企业缴纳" header-align="center" align="right" width="150">
                        <template slot-scope="{ row }">
                            {{getJSNum(row , 'qyjn')}}
                        </template>
                    </el-table-column>
                </el-table-column>
                <el-table-column prop="note" label="备注" header-align="center" width="250">
                </el-table-column>
            </el-table>
        </basic-container>
    </div>
</template>
<script>
import dayjs from 'dayjs';
import MathJS from '@/util/math';
import { getMetaCommonList } from '@/api/busiMode/metaCommon';
import XLSXS from 'xlsx-style';
import FileSaver from 'file-saver';

var quarterOfYear = require('dayjs/plugin/quarterOfYear');
dayjs.extend(quarterOfYear);
const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
export default {
    data() {
        return {
            isLoading: false,
            height: window.innerHeight - 320,
            tableData: [],
            formInline: {
                count_year: dayjs().format('YYYY'),
                quarter: dayjs().quarter(),
                sub_part_id: '',
                building_id: '',
                company_id: '',
                contract_code: '',
            },
            buildList: [],
            companyList: [],
            partList: [],
            contractList: [],
            oldPartName: '',
            partName: '',
            quarters: [
                { name: '一', value: 1 },
                { name: '二', value: 2 },
                { name: '三', value: 3 },
                { name: '四', value: 4 },
            ],
        };
    },
    mounted() {
        this.getViewData();
        this.getPart();
    },
    methods: {
        // tableRowClassName({ row, rowIndex }) {
        //     if (row.IsCount) {
        //         return 'bg-gray-1';
        //     } else {
        //         if (rowIndex % 3 === 2) {
        //             return 'bg-gray-1';
        //         }
        //     }
        // },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (row.IsCount) {
                if (columnIndex === 1) {
                    return {
                        colspan: 8,
                        rowspan: 1,
                    };
                }
                if (columnIndex > 1 && columnIndex <= 8) {
                    return {
                        colspan: 0,
                        rowspan: 0,
                    };
                }
            } else {
                if (columnIndex === 1) {
                    if (row.SpanObj) {
                        return row.SpanObj;
                    }
                }
                if (columnIndex > 1 && columnIndex <= 7) {
                    if (rowIndex % 3 === 0) {
                        return {
                            colspan: 1,
                            rowspan: 3,
                        };
                    } else {
                        return {
                            colspan: 0,
                            rowspan: 0,
                        };
                    }
                }
            }
        },
        changeData() {},
        clearForm(len) {
            ['contract_code', 'company_id', 'building_id', 'sub_part_id'].forEach((item, index) => {
                if (len >= index) {
                    this.formInline[item] = '';
                }
            });
            // this.formInline.
            // this.formInline.contract_code = '';
        },
        getJSNum(row, type) {
            if (type === 'zc') {
                let i = 1,
                    num = MathJS.chain(row['redu_rent_amount']);
                while (i < Number(this.formInline.month)) {
                    num = num.subtract(Number(row[`q${i}_redu_rent_amount`]) || 0);
                    i++;
                }
                return num.done();
            } else {
                return MathJS.subtract(row['comp_rent_amount'], row['payment_amount']);
            }
        },
        mergeTableData(data) {
            // ['reduction_policy_title' , 'contract_code' , 'renters_name' , '']
            let dataMap = {};
            data.forEach((item, index) => {
                if (!dataMap[item['reduction_policy_title']]) {
                    dataMap[item['reduction_policy_title']] = {
                        SpanObj: {
                            colspan: 1,
                            rowspan: 1,
                        },
                        data: [item],
                    };
                } else {
                    dataMap[item['reduction_policy_title']].SpanObj.rowspan++;
                    dataMap[item['reduction_policy_title']].data.push({
                        ...item,
                        SpanObj: {
                            colspan: 0,
                            rowspan: 0,
                        },
                    });
                }
            });
            let result = [];
            Object.values(dataMap).forEach(item => {
                item.data[0].SpanObj = item.SpanObj;
                result = [...result, ...item.data];
            });
            // result.forEach(
            //   (item ,index) => {
            //     result[index]['js_zc'] = MathJS.subtract(item['redu_rent_amount'] , item['q1_redu_rent_amount']);
            //     result[index]['js_qyjn'] = MathJS.subtract(item['comp_rent_amount'] , item['payment_amount']);
            //     // ['redu_rent_amount' , 'comp_rent_amount' , 'rent_amount' , 'q1_redu_rent_amount' , 'q1_comp_rent_amount' , 'q1_rent_amount' , 'payment_amount'].forEach(
            //     //   v => {
            //     //     item[v] = Number(`${item[v]}`.match(/^\d+(?:\.\d{0,2})?/));
            //     //   }
            //     // )
            //   }
            // )
            let obj = {};
            result.forEach(item => {
                ['redu_rent_amount', 'comp_rent_amount', 'rent_amount', 'payment_amount'].forEach(v => {
                    if ((obj[v] ?? '') === '') {
                        obj[v] = 0;
                    }
                    obj[v] = MathJS.add(obj[v], Number(item[v]) || 0);
                });
                ['redu_rent_amount', 'comp_rent_amount', 'rent_amount'].forEach(v => {
                    let i = 1;
                    while (i < 5) {
                        let name = `q${i}_${v}`;
                        if ((obj[name] ?? '') === '') {
                            obj[name] = 0;
                        }
                        obj[name] = MathJS.add(obj[name], Number(item[name]) || 0);
                        i++;
                    }
                });
            });
            console.log(obj);
            result.push({
                ...obj,
                reduction_policy_title: '合计',
                IsCount: true,
            });
            return result;
        },
        getViewData() {
            let partName = this.partName;
            let wheres = [];
            Object.keys(this.formInline).forEach(item => {
                if (item !== 'quarter' && (this.formInline[item] ?? '') !== '') {
                    wheres.push({ field: item, value: this.formInline[item] });
                }
            });
            this.isLoading = true;
            getMetaCommonList('wisdom_park.v_rpt_annual_income_redu_quarter', {
                size: 10000,
                current: 1,
                wheres,
            }).then(res => {
                this.oldPartName = partName;
                this.tableData = [];
                if (res.data.data.records.length > 0) {
                    this.tableData = this.mergeTableData(res.data.data.records);
                }

                this.isLoading = false;
            });
        },
        getPart() {
            let body = {
                current: 1,
                size: 1000,
                orders: [{ field: 'sort', sort: 'ASC' }],
            };
            getMetaCommonList('wisdom_park.wp_part', body).then(res => {
                const data = res.data.data;
                this.partList = data.records;
            });
        },
        getBuildList(e) {
            this.formInline.building_id = '';
            this.partList.forEach(e => {
                if (e.id == this.formInline.sub_part_id) {
                    this.partName = e.name;
                }
            });
            getMetaCommonList('wisdom_park.wp_building', {
                size: 1000,
                current: 1,
                wheres: [{ field: 'sub_part_id', value: this.formInline.sub_part_id }],
            }).then(res => {
                const data = res.data.data;
                this.buildList = data.records;
            });
        },
        getCompanyList() {
            getMetaCommonList('wisdom_park_statistics.v_wp_company_building_stat', {
                size: 1000,
                current: 1,
                wheres: [{ field: 'building_id', value: this.formInline.building_id }],
            }).then(res => {
                const data = res.data.data;
                this.companyList = data.records;
            });
        },
        getContractList() {
            if (this.formInline.company_id) {
                getMetaCommonList('wisdom_park_statistics.v_company_contract_stat', {
                    size: 1000,
                    current: 1,
                    wheres: [{ field: 'company_id', value: this.formInline.company_id }],
                }).then(res => {
                    const data = res.data.data;
                    this.contractList = data.records;
                });
            }
        },
        exportTable() {
            // 首先加载  <table id='excelTable'> 标签 为 workbook对象
            // let workbook = this.$XLSX.utils.table_to_book(document.getElementById('excelTable'), { raw: true });
            // var fix = document.querySelector('.el-table__fixed');
            // var workbook;
            // // 判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
            // if (fix) {
            //     /* 从表生成工作簿对象 */
            //     workbook = this.$XLSX.utils.table_to_book(document.querySelector('#excelTable').removeChild(fix));
            //     document.querySelector('#excelTable').appendChild(fix);
            // } else {
            //     workbook = this.$XLSX.utils.table_to_book(document.querySelector('#excelTable'));
            // }

            var workbook;
            var fix = document.querySelector('.el-table__fixed');
            var dt = document.querySelector('#excelTable');
            var xlsxParam = { raw: true }; //转换成excel时，使用原始的格式,解决工单号过长导致数字自动转换为科学计数法导致精度缺失
            if (fix) {
                workbook = this.$XLSX.utils.table_to_book(dt.removeChild(fix), xlsxParam);
                dt.appendChild(fix);
            } else {
                workbook = this.$xlsx.utils.table_to_book(dt, xlsxParam);
            }
            // 先定义列宽 ， 我这里文件一共有7 列 ，所以设置7列宽度相等都为 20 ，如果你有很多列建议直接 map()
            let wscols = [
                { wch: 5 },
                { wch: 20 },
                { wch: 20 },
                { wch: 25 },
                { wch: 25 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 20 },
            ];

            // 获取 需要设置样式的 sheet ，我这里只有 一个 sheet 所以索引默认加载了第一个
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            // 设置列宽
            sheet['!cols'] = wscols;
            // 定义框线样式
            const borderAll = {
                color: { auto: 1 },
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            };

            // 这里的意思为 先默认代表表格的 7 个列  的 列号
            // 比如 A2 意思是 第一列 第2行
            const _letterList = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
            // 定义一个 箭头函数，接受三个参数，分别为 当前Sheet , 行列号（例如：‘A2’）, 是否是新增样式
            const _mapCellStyle = (_sheet, _key, _type) => {
                const _cellStyle = {
                    border: borderAll,
                    alignment: {
                        wrapText: true,
                        horizontal: 'center',
                        vertical: 'center',
                    },
                    font: {
                        name: '微软雅黑',
                        sz: 10,
                        height: 50,
                    },
                    height: 50,
                    bold: true,
                    numFmt: '0',
                };

                const _title = {
                    // border: borderAll,
                    alignment: {
                        wrapText: true,
                        horizontal: 'center',
                        vertical: 'center',
                    },
                    font: {
                        name: '微软雅黑',
                        sz: 16,
                    },
                    bold: false,
                    numFmt: '0',
                };
                if (_key == 'A1') {
                    if (_type === 'append') {
                        // 需要新增样式，说明当前加载的sheet中并不存在该单元格，可能当前单元格被前边合并，这种被合并的单元格也得设置样式，
                        // 否则就会出现 合并单元格只有第一格带框线，后边没框线的情况出现，所以这里需要将后边的边框样式也加上。
                        _sheet[_key] = {
                            s: _title,
                        };
                    } else {
                        // 若不是新增样式 则代表sheet中已存在该表格直接修改其 s 属性即可使属性生效
                        if (typeof _sheet[_key] === 'object') {
                            _sheet[_key].s = _title;
                        }
                    }
                } else {
                    if (_type === 'append') {
                        // 需要新增样式，说明当前加载的sheet中并不存在该单元格，可能当前单元格被前边合并，这种被合并的单元格也得设置样式，
                        // 否则就会出现 合并单元格只有第一格带框线，后边没框线的情况出现，所以这里需要将后边的边框样式也加上。
                        _sheet[_key] = {
                            s: _cellStyle,
                        };
                    } else {
                        // 若不是新增样式 则代表sheet中已存在该表格直接修改其 s 属性即可使属性生效
                        if (typeof _sheet[_key] === 'object') {
                            _sheet[_key].s = _cellStyle;
                        }
                    }
                }
            };

            // sheet 不懂得可以单独打印一下，它其实就是一个对象，键代表行列号（‘A2’），值为该单元格的值，样式等，
            // 我们需要做的就是修改其值中的样式
            Object.keys(sheet).forEach((i, _) => {
                // 无用属性直接过滤
                if (i !== '!ref' || i !== '!cols' || i !== '!merges' || i !== 'Am') {
                    // 首先设置遍历到的 当前 key
                    let _nowKey = i;
                    // 然后调用 _mapCellStyle  渲染当前单元格样式
                    _mapCellStyle(sheet, _nowKey);
                    // 我们这里先获取下一个行列号  例如当前_nowKey 是 A1  这里就是获取到 B1 ,及 当前行的 下一列数据
                    let _nextKey = _letterList[_letterList.indexOf(_nowKey[0]) + 1] + i.slice(1);

                    // 判断 B1 是否在 Sheet的key中,如果不存在，只可能是因为B1所在单元格已经被A1所合并，所以我们需要将B1也调用一下  _mapCellStyle
                    // 渲染 B1 的样式，不然就会出现 A1 B1 这两格是合并的状态，只有A1有框线，而B1 没有框线的情况。
                    // 这里用 while 就是 要把需要合并的单元格全部 渲染上样式，直到可能 B4 在 Sheet中 停止
                    while (_nowKey[0] !== 'G' && !Object.keys(sheet).includes(_nextKey)) {
                        _mapCellStyle(sheet, _nextKey, 'append');
                        // 这里就是简单的交换索引
                        _nowKey = _nextKey;
                        _nextKey = _letterList[_letterList.indexOf(_nowKey[0]) + 1] + _nowKey.slice(1);
                    }
                }
            });

            let wopts = {
                bookType: 'xlsx',
                bookSST: false,
                type: 'buffer',
            };
            let _blob = XLSXS.write(workbook, wopts);

            FileSaver.saveAs(
                new Blob([_blob], {
                    type: 'application/octet-stream',
                }),

                this.oldPartName +
                    this.formInline.count_year +
                    '年' +
                    this.quarters.map(a => a.name)[this.formInline.quarter - 1] +
                    '季度政策企业挂账' +
                    new Date().getTime() +
                    '.xlsx',
            );
        },
    },
};
</script>
<style scoped lang="scss" rel="stylesheet/scss">
.ft500 {
    font-weight: 700;
}
/deep/ .el-table thead.is-group th {
    background: #fafafa !important;
}
.bg-white {
    background-color: #fff !important;
}
.cards {
    background: #fff;
    padding: 15px 10px 0px 10px !important;
    padding-right: 0px !important;
    margin-bottom: 15px;
}
</style>

<template>
  <basic-container>
    <avue-crud
      :data="tableDatas"
      v-model="modelForm"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      :before-close="beforeClose"
      @row-save="handleSave"
      @row-update="handleUpdate"
      @row-del="handleDel"
      @on-load="onLoad"
      @refresh-change="onLoad"
    >
      <template slot="sourceIdForm" slot-scope="scope">
        <el-select size="small" @change="datasourceChange" v-model="modelForm.sourceId" placeholder="请选择数据源">
          <el-option v-for="item of datasourcesList" :label="item.name" :value="item.id" :key="item.id"></el-option>
        </el-select>
      </template>
    </avue-crud>
  </basic-container>
</template>
<script>
import {
  getDataTopic,
  saveTopic,
  updateTopic,
  delTopic
} from '@/api/dataService/dataTopic'
import { mapActions } from 'vuex'
import { dateFormat } from '@/util/date'
  export default {
    data(){
      return {
        modelForm: {
          sourceId:'',
        },
        datasourcesList:[],
        currentSource: null,
        sourceMap: {},
        page: {
          pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        tableLoading: false,
        tableDatas: [],
        tableOption: {
          labelWidth: 120,
          column:[
            {
              label:'Topic名称',
              prop:'topicName',
              span: 24,
              rules: [{
                required: true,
                message: "请输入名称",
                trigger: "blur"
              }]
            },
            {
              label:'数据源类型',
              prop:'sourceType',
              span: 24,
              rules: [{
                required: true,
                message: "请选择数据源",
                trigger: "blur"
              }],
              display: false
            },
            {
              label:'数据源名称',
              prop:'sourceName',
              span: 24,
              display: false
            },
            {
              label:'数据源',
              prop:'sourceId',
              span: 24,
              hide: true,
              rules: [{
                required: true,
                message: "请选择数据源",
                trigger: "blur"
              }]
            },
            {
              label:'描述',
              prop:'additionalInformation',
              type: "textarea",
              minRows: 2,
              span: 24,
            },
            {
              label:'创建人',
              prop:'createUserName',
              display: false
            },
            {
              label:'创建时间',
              prop:'createTime',
              display: false
            }
          ]
        }
      }
    },
    mounted() {
      this._getList();
    },
    methods: {
      ...mapActions('datasource', ['getDatasourcesListP']),
      onLoad() {
        this.tableLoading = true;
        let obj = {
          'current': this.page.currentPage,
          'size': this.page.pageSize
        };
        getDataTopic(obj).then( res => {
          this.tableLoading = false;
          this.tableDatas = res.data.data.records;
          this.page.total = res.data.data.total
        }).catch( e => {
          this.tableLoading = false;
        });
      },
      datasourceChange(item) {
        this.currentSource = this.sourceMap[item];
      },
      _getList (flag) {
        this.getDatasourcesListP({
          pageSize: 10,
          pageNo: 1
        }).then(res => {
          this.datasourcesList = [];
          this.datasourcesList = res.totalList
          this.datasourcesList.forEach(item => {
            item.id = item.id + '';
            this.sourceMap[item.id] = item;
          })
        }).catch(e => {
        })
      },
      beforeClose(done) {
        this.currentSource = null;
        done();
      },
      handleSave(row,done,loading) {
        if(!this.currentSource){
          this.$message.error('请选择数据源');
          return;
        }
        let obj = {
          'topicName':row['topicName'],
          'additionalInformation':row['additionalInformation'],
          'sourceType':this.currentSource['type'],
          'sourceName':this.currentSource['name'],
          'sourceId':this.currentSource['id']+'',
          "status": 0,
          'createTime':dateFormat(new Date())
        };
        saveTopic(obj).then( res => {
          done();
          loading();
          this.onLoad();
        }).catch( e => {
          loading();
        });
      },
      handleUpdate(row, index, done, loading) {
        let append = {};
        if(this.currentSource) {
          append = {
            'sourceType':this.currentSource['type'],
            'sourceName':this.currentSource['name'],
            'sourceId':this.currentSource['id']
          }
        }
        let obj = {
          'id':row['id'],
          'topicName':row['topicName'],
          'additionalInformation':row['additionalInformation'],
          ...append
        };
        updateTopic(obj).then( res => {
          done();
          loading();
          this.onLoad();
        });
      },
      handleDel(row) {
        let _t = this;
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            delTopic({
              id:row['id']
            }).then(res => {
              _t.onLoad();
            })
          })
      }
    }
  }
</script>

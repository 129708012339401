<template>
  <basic-container
    isCard
    :body-style="{ padding: '0', height: '100%' }"
    class="h-100p"
  >
    <el-container class="h-100p">
      <el-header height="50px" class="lh-50 bor-b">
        <basic-page-header
          @back="go"
          class="lh-50"
          :title="'操作记录'"
        ></basic-page-header>
      </el-header>
      <avue-crud
        :option="option"
        :table-loading="loading"
        :data="data"
        ref="crud"
        v-model="form"
        :page.sync="page"
        @search-change="searchChange"
        @search-reset="searchReset"
        @selection-change="selectionChange"
        @reset-change="emptytChange"
        @refresh-change="refreshChange"
        @on-load="onLoad"
      >
      </avue-crud>
      <el-dialog
        :visible.sync="detailDia"
        width="40%"
        class="enterDetail"
        destroy-on-close
        append-to-body
      >
        <div slot="title" class="header-title">
          <span class="fts-16">关联设置</span>
        </div>
        <glsz @close="closeDia" :currentRow="currentRow"></glsz>
      </el-dialog>
    </el-container>
  </basic-container>
</template>

<script>
import { getMetaCommonList, delMetaCommon } from "@/api/busiMode/metaCommon";
import standardService from "@/api/metadata/standard";
import { mapGetters } from "vuex";
import glsz from "./glsz";
import { getHkDoorEvents } from "@/api/visitor/visitor";
import * as dictbizService from "@/api/system/dictbiz";
import { dateFormat } from "@/util/date";

export default {
  components: { glsz },
  data() {
    return {
      searchText: "",
      form: {},
      selectionList: [],
      loading: true,
      dialogVisible: false,
      detailDia: false,
      query: {},
      dialogType: "",
      type: "",
      size: "60%",
      currentRow: {},
      title: "",
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      activeName: "1",
      option: {
        dateBtn: false,
        border: true,
        height: "auto",
        tip: true,
        searchShow: true,
        searchMenuSpan: 6,
        searchMenuPosition: "left",
        addBtn: false,
        refreshBtn: true,
        addRowBtn: false,
        columnBtn: true,
        delBtn: false,
        editBtn: false,
        cancelBtn: false,
        rowKey: "",
        showPage: true,
        index: true,
        indexLabel: "序号",
        selection: false,
        showHeader: true,
        expand: false,
        highlightCurrentRow: true,
        stripe: false,
        menuWidth: 150,
        menu: false,
        reserveSelection: true,
        column: [
          {
            overHidden: true,
            align: "left",
            label: "操作日期",
            prop: "create_time",
            hide: false,
            search: true,
            type: "date",
            show: true,
            span: 4,
            key: "create_time",
          },
          {
            overHidden: true,
            align: "left",
            label: "操作人",
            prop: "create_user",
            hide: false,
            search: true,
            show: true,
            span: 4,
            key: "name",
          },
          {
            overHidden: true,
            align: "left",
            label: "操作名称",
            prop: "control_type_name",
            hide: false,
            show: false,
            span: 4,
            key: "control_type_name",
            dicData: [
              { label: "常开", value: 0 },
              { label: "门闭", value: 1 },
              { label: "门开", value: 2 },
              { label: "常闭", value: 3 },
            ],
          },
        ],
      },
      model: "wisdom_park.wp_third_hk_door_control",
      data: [],
      eventType: [],
      doorEventTypes: [],
      ruleModel: {},
      query: {},
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.code_add, false),
        viewBtn: this.vaildData(this.permission.code_view, false),
        delBtn: this.vaildData(this.permission.code_delete, false),
        editBtn: this.vaildData(this.permission.code_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  mounted() {
    this.queryByModelCode();
    this.getDicList();
  },
  props: {},
  watch: {},
  methods: {
    go() {
      this.$router.push({
        path: "/door/main",
      });
    },
    getDicList() {
      dictbizService
        .getDictionary({
          code: "DoorEventTypes",
        })
        .then((res) => {
          this.doorEventTypes = res.data.data;
        });
    },
    add() {
      this.type = "add";
      this.detailDia = true;
    },
    handleClick(val) {
      this.activeName = val["name"];
      this.onLoad();
    },
    searchChange(params, done) {
      this.query = params;
      this.onLoad();
      done();
    },
    openDetailDia(type, row) {
      this.type = type;
      this.detailDia = true;
      setTimeout(() => {
        this.currentRow = row;
      }, 0);
    },
    back() {
      this.detailDia = false;
      this.searchReset();
    },
    queryByModelCode() {
      standardService.getModelRule(this.model).then((res) => {
        this.ruleModel = res;
      });
    },
    changName(row) {
      this.title = row.name;
    },

    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },

    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },

    refreshChange() {
      this.onLoad();
    },
    closeDia() {
      this.detailDia = false;
      this.refreshChange();
    },
    onLoad() {
      this.loading = true;
      let body = {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: [],
      };
      if (this.$route.query.indexCode) {
        body["wheres"].push({
          field: "door_index_codes",
          value: this.$route.query.indexCode,
        });
      }
      if (this.query["create_user"]) {
        body["wheres"].push({
          field: "create_user",
          op: "LIKE",
          value: this.query["create_user"],
        });
      }
      if (this.query["create_time"]) {
        body["wheres"].push({
          field: "create_time",
          op: "LIKE",
          value: dateFormat(this.query["create_time"], "yyyy-MM-dd"),
        });
      }
      getMetaCommonList(this.model, body).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
      });
    },
    doorevents(row) {
      this.detailDia = true;
      this.currentRow = row;
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.deleteIcon {
  font-size: 18px;
  position: absolute;
  left: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 1;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
}
/deep/ .el-drawer__header {
  border-bottom: 1px solid #ebebeb;
  padding: 10px 20px;
  margin-bottom: 0px;
}
/deep/ .el-drawer__body {
  padding: 0px;
}
/deep/ .el-dialog__body {
  padding: 0px;
}
.buildDetail /deep/ .el-dialog__body {
  padding: 0px;
}
</style>

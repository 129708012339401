<template>
  <div>
    <template>
      <statistics></statistics>
      <contentdiv></contentdiv>
    </template>
    <!-- <template v-if="approval_status != 3">
      <basic-container isCard :style="{ height: viewPortHeight - 125 + 'px' }">
        <feedback class="mar-t-100 mar-l-30f"> </feedback>
      </basic-container>
    </template> -->
  </div>
</template>

<script>
import statistics from "./statistics.vue";
import contentdiv from "./content.vue";
import feedback from "../feedback.vue";
import { mapGetters, mapState } from "vuex";
import {
  getMetaCommonList,
  getMetaCommonInfo,
} from "@/api/busiMode/metaCommon";

export default {
  components: { statistics, contentdiv, feedback },

  data() {
    return {
      model: "wisdom_park.wp_company",
      checkinModel: "wisdom_park.wp_company_checkin",
      approval_status: 999,
      viewPortHeight:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight,
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
    ...mapGetters(["tag", "lockPasswd"]),
  },
  mounted() {
    if (this.userInfo.companyId && this.userInfo.tenant_id == "000002") {
      this.getMetaCommonInfo();
    }
  },
  methods: {
    getMetaCommonInfo() {
      let obj = {
        pk: "id",
        id: this.userInfo.companyId,
      };
      getMetaCommonInfo(this.model, obj).then((res) => {
        if (res.data.code == 200) {
          this.ruleForm = res.data.data;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>

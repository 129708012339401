<template>
  <div>
    <enterInfo @selectRoomList="selectRoomList"></enterInfo>
    <div class="mar-t-10">
      <avue-crud
        :option="option"
        :table-loading="loading"
        :data="data"
        ref="crudtable"
        @selection-change="selectionChange"
        @on-load="onLoad"
      >
        <template slot-scope="{ row }" slot="room_name">
          <a class="cur-p">{{ row.room_name }}</a>
        </template>
      </avue-crud>
    </div>
  </div>
</template>

<script>
const viewPortHeight =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;
import * as dictService from "@/api/system/dictbiz";

import {
  getMetaCommonList,
  delMetaCommon,
  saveMetaCommon,
} from "@/api/busiMode/metaCommon";
import enterInfo from "./enterInfo";
export default {
  components: { enterInfo },
  data() {
    return {
      option: {
        tip: false,
        height: viewPortHeight * 0.5,
        searchShow: false,
        searchMenuSpan: 6,
        border: true,
        index: false,
        refreshBtn: false,
        columnBtn: false,
        searchBtn: false,
        labelWidth: 120,
        menuWidth: 250,
        viewBtn: false,
        dialogClickModal: false,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        cancelBtn: false,
        menu: false,
        page: false,
        selection: true,
        column: [
          {
            overHidden: true,
            label: "房间名称",
            prop: "name",
            hide: false,
            fixed: true,
            slot: true,
          },

          {
            width: 80,
            overHidden: true,
            label: "出租状态",
            prop: "status",
            dicData: [
              {
                value: 0,
                label: "未出租",
              },
              {
                value: 1,
                label: "已出租",
              },
              {
                value: 2,
                label: "已售",
              },
              {
                value: 3,
                label: "自用",
              },
            ],
          },

          {
            overHidden: true,
            align: "right",
            label: "房间面积(m²)",
            prop: "room_area",
          },

          {
            overHidden: true,
            label: "装修情况",
            prop: "renovation",
            hide: false,
            type: "select",
            dicUrl: dictService.getUrl("decoration"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
          },
          {
            overHidden: true,
            label: "房源类型",
            prop: "housing_resources_type",
            hide: false,
            dicUrl: dictService.getUrl("housingResourcesType"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            search: true,
            searchSpan: 6,
            type: "select",
            width: 80,
          },

          {
            width: 120,
            overHidden: true,
            label: "出租单价(元/月)",
            prop: "rental_unit_price_month",
            hide: false,
            align: "right",
          },

          // {
          //   width: 220,
          //   overHidden: true,
          //   label: "房间标签",
          //   prop: "label",
          //   hide: false,
          //   align: "left",
          // },
        ],
      },
      loading: true,
      model: "wisdom_park.wp_room",
      houseModel: "wisdom_park.wp_housing_resources",
      data: [],
      buildId: "",
      floorId: "",
      selections: [],
      buildList: [],
      floors: [],
      houseObj: {
        name: "",
        label: "",
        address: "",
        area: "",
        online_status: "",
        status: "",
        renovation: "",
        attachment: "",
        rental_unit_price_month: "",
        rental_unit_price_day: "",
        contact_person: "",
        contact_phone: "",
        address_coordinate: "",
        features: "",
        service_note: "",
        property_management_fee: "",
        payment_type: "",
        publish_user: "",
        publish_time: "",
        storey: "",
        online_vr: "",
        immigration_time: "",
      },
    };
  },
  props: {
    currentHouse: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  computed: {},
  mounted() {
    this.onLoad();
    this.getBuildList();
    this.getFloors();
  },
  methods: {
    selectionChange(list) {
      this.selections = list;
      // if (list[list.length - 1].status == 0) {
      //   let _this = this;
      //   this.$confirm("该房间为已出租状态，确定维护房源信息么？", {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //   })
      //     .then(() => {
      //       this.selections = list;
      //     })
      //     .catch(function (e) {
      //       console.log(list);
      //       let o = list[list.length - 1];
      //       let currentRow = {};
      //       Object.keys(o).forEach((ele) => {
      //         if (!ele.includes("$")) {
      //           currentRow[ele] = o[ele];
      //         }
      //       });
      //       console.log(currentRow);
      //       _this.$refs.crudtable.toggleSelection([currentRow]);
      //     });
      // }
    },
    selectRoomList(v) {
      this.buildId = v.building;
      this.floorId = v.floor;
      console.log(v);
      this.onLoad();
    },
    onLoad() {
      this.loading = true;
      let body = {
        current: 1,
        size: 50,
      };
      body.wheres = [
        { field: "building_id", value: this.buildId },
        { field: "floor_id", value: this.floorId },
        { field: "status", value: 0 },
        { field: "is_cancel", value: 0 },
      ];
      getMetaCommonList(this.model, body).then((res) => {
        const data = res.data.data;
        this.data = data.records;
        console.log(this.data);
        this.getDicList();
        this.loading = false;
      });
    },
    getBuildList() {
      let obj = {
        size: 1000,
        current: 1,
      };
      getMetaCommonList("wisdom_park.wp_building", obj).then((res) => {
        const data = res.data.data;
        this.buildList = data.records;
      });
    },
    getFloors() {
      let obj = {
        size: 1000,
        current: 1,
      };
      getMetaCommonList("wisdom_park.wp_floor", obj).then((res) => {
        const data = res.data.data;
        this.floors = data.records;
      });
    },
    getDicList() {
      dictService
        .getDictionary({
          code: "RoomBrightenedDot",
        })
        .then((res) => {
          let roomTags = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
              id: item["id"],
            };
          });
        });
    },
    save() {
      this.saveMetaCommon(this.selections);
    },
    async saveMetaCommon(data) {
      var ary = [];
      data.forEach((v, index) => {
        console.log(v);
        let obj = {
          name: v.name,
          label: v.label,
          area: v.room_area,
          renovation: v.renovation,
          attachment: v.attachment,
          rental_unit_price_month: v.rental_unit_price_month,
          rental_unit_price_day: v.rental_unit_price_day,
          payment_type: v.payment_type,

          subject_type: 0,
          address: v.address,
          subject_id: v.id,
          building_name: v.building_name,
          building_id: v.building_id,
          floor_name: v.floor_name,
          floor_id: v.floor_id,
        };
        if (!obj.building_name) {
          this.buildList.forEach((e) => {
            if (e.id == obj.building_id) {
              obj.building_name = e.name;
            }
          });
        }
        if (!obj.floor_name) {
          this.floors.forEach((e) => {
            if (e.id == obj.floor_id) {
              obj.floor_name = e.code + "层";
            }
          });
        }
        const res = saveMetaCommon(this.houseModel, {
          values: obj,
        }).then((_res) => {
          if (_res.code == 200) {
            return {
              id: index + 1,
              imgs: _res.data,
            };
          }
          return "";
        });
        ary.push(res);
      });
      await Promise.all(ary).then((result) => {
        this.$emit("close");
      });
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
/deep/ .avue-crud__menu {
  display: none;
}
</style>

<template>
    <el-col :span="24" class="pad-0">
        <el-col :span="4" class="w-20p">
            <el-card shadow="hover">
                <div class="czmj">出租面积</div>
                <div class="czmjNum">
                    {{ graden.sum_room_area_yz || "-" }}
                    <span class="pull-right ftzClor fts-13">m²</span>
                </div>
                <div class="czl">
                    <span class="ftzClor mar-r-25">出租率 </span>
                    <span class="pull-right fts-13">
                        {{ graden.czl }}%</span>
                </div>
            </el-card>
        </el-col>
        <el-col :span="4" class="w-20p">
            <el-card shadow="hover">
                <div class="czmj">入驻企业</div>
                <div class="czmjNum">
                    {{rzqy}}
                    <span class="pull-right ftzClor fts-13">家</span>
                </div>
                <div class="czl">
                    <span class="ftzClor mar-r-20">入驻人数</span>
                    <span class="pull-right fts-13">{{rzsl}}</span>
                </div>
            </el-card>
        </el-col>
        <el-col :span="4" class="w-20p">
            <el-card shadow="hover">
                <div class="czmj">本年累计收入</div>
                <div class="czmjNum">
                    {{ amountSumObj.current_amount || "-"
          }}<span class="pull-right ftzClor fts-13">万元</span>
                </div>
                <div class="czl">
                    <span class="ftzClor">同期增长 </span>
                    <span class="pull-right fts-13">
                        <i v-if="amountSumObj.zzb < 0" class="el-icon-bottom down"></i>
                        <i v-if="amountSumObj.zzb > 0" class="el-icon-top top"></i>
                        {{ amountSumObj["zzb"] }}%</span>
                </div>
            </el-card>
        </el-col>
        <el-col :span="4" class="w-20p">
            <el-card shadow="hover">
                <div class="czmj">本年累计租金费收入</div>
                <div class="czmjNum">
                    {{ amountSumObj.current_rent_amount  || "-"
          }}<span class="pull-right ftzClor fts-13">万元</span>
                </div>
                <div class="czl">
                    <span class="ftzClor">同期增长 </span>
                    <span class="pull-right fts-13">
                        <i v-if="amountSumObj['renZzb'] < 0" class="el-icon-bottom down"></i>
                        <i v-if="amountSumObj['renZzb'] > 0" class="el-icon-top top"></i>
                        {{ amountSumObj['renZzb']}}%</span>
                </div>
            </el-card>
        </el-col>
        <!-- <el-col :span="4">
            <el-card shadow="hover">
                <div class="czmj">本年累计物业费收入</div>
                <div class="czmjNum">
                    {{ dictData["wyfsr"]["value"].current_amount_count || "-"
          }}<span class="pull-right ftzClor fts-13">万元</span>
                </div>
                <div class="czl">
                    <span class="ftzClor">同期增长 </span>
                    <span class="pull-right fts-13">
                        <i v-if="dictData['wyfsr']['value'].zzb < 0" class="el-icon-bottom down"></i>
                        <i v-if="dictData['wyfsr']['value'].zzb > 0" class="el-icon-top top"></i>
                        {{ dictData["wyfsr"]["value"].zzb }}%</span>
                </div>
            </el-card>
        </el-col> -->

        <el-col :span="4" class="w-20p">
            <el-card shadow="hover">
                <div class="czmj">本年累计其他收入</div>
                <div class="czmjNum">
                    {{amountSumObj.current_other_amount }}
                    <span class="ftzClor fts-13">万元</span>
                </div>
                <div class="czl" style='height:18px'>
                    <span class="ftzClor">同期增长 </span>
                    <span class="pull-right fts-13">
                        <i v-if="amountSumObj['otheZzb'] < 0" class="el-icon-bottom down"></i>
                        <i v-if="amountSumObj['otheZzb'] > 0" class="el-icon-top top"></i>
                        {{ amountSumObj['otheZzb'] }}%</span>
                </div>
            </el-card>
        </el-col>
    </el-col>
</template>

<script>
import { getMetaCommonList, getPicListByIds } from '@/api/busiMode/metaCommon';
import dayjs from 'dayjs';

export default {
    data() {
        return {
            graden: {},
            realfee: 0,
            amount_sum: 'wisdom_park.v_rpt_curyear_amount_sum',
            dictData: {
                ccm: { key: 'stat_overview_rent', value: { zzb: '-' } },
                rzqy: { key: 'stat_overview_enterprise', value: { zzb: '-' } },
                zsr: { key: 'stat_overview_cumulative', value: { zzb: '-' } },
                zjsr: { key: 'stat_overview_lease', value: { zzb: '-' } },
                wyfsr: { key: 'stat_overview_property_fees', value: { zzb: '-' } },
                qtsr: { key: 'stat_overview_others', value: { zzb: '-' } },
            },
            amountSumObj: {
                zzb: '0',
                renZzb: '0',
                otheZzb: '0',
            },
            rzqy: '',
            rzsl: '',
        };
    },
    created() {
        let t = this;
        setTimeout(() => {
            for (let key in this.dictData) {
                t.getMetaCommonList(key);
            }
        }, 100);
        this.onLoad();
        this.getMetaYearCommonList();
        this.getMetaCommonListAmountSum();
    },
    methods: {
        onLoad() {
            getMetaCommonList('wisdom_park.v_wp_park_stat', {
                current: 1,
                size: 500,
            }).then(res => {
                const data = res.data.data;
                this.graden = data.records[0];

                if (this.graden.sum_free_area) {
                    this.graden.sum_free_area = this.graden.sum_free_area.toFixed(2);
                }
                if (this.graden.sum_checkin_area) {
                    this.graden.sum_checkin_area = this.graden.sum_checkin_area.toFixed(2);
                }
                if (this.graden.sum_floor_area) {
                    this.graden.sum_floor_area = this.graden.sum_floor_area.toFixed(2);
                }
            });
        },
        getMetaCommonListAmountSum() {
            getMetaCommonList(this.amount_sum, {
                current: 1,
                size: 500,
            }).then(res => {
                this.amountSumObj = res.data.data.records[0];
                this.amountSumObj.zzb = (
                    (this.amountSumObj.current_amount * 100) /
                    (this.amountSumObj.previous_amount * 100)
                ).toFixed(2);
                this.amountSumObj.renZzb = (
                    (this.amountSumObj.current_rent_amount * 100) /
                    (this.amountSumObj.previous_rent_amount * 100)
                ).toFixed(2);
                this.amountSumObj.otheZzb = (
                    (this.amountSumObj.current_other_amount * 100) /
                    (this.amountSumObj.previous_other_amount * 100)
                ).toFixed(2);
            });
            getMetaCommonList('wisdom_park.v_jsc_wp_company_stat', {
                current: 1,
                size: 500,
            }).then(res => {
                console.log(res.data.data.records[0]);
                this.rzqy = res.data.data.records[0].sum_checkin_num;
                this.rzsl = res.data.data.records[0].sum_emp_num;
            });
        },
        getMetaCommonList(prop) {
            let code = this.dictData[prop].key;
            return getMetaCommonList(`wisdom_park_statistics.${code}`, {
                current: 1,
                size: 500,
            }).then(res => {
                if (res.data && res.data.data && prop) {
                    let value = res.data.data.records[0];
                    if (prop == 'zjsr' || prop == 'zsr' || prop == 'wyfsr' || prop == 'qtsr') {
                        if (value.previous_amount_count * 100 == 0) {
                            value['zzb'] = '--';
                        } else {
                            value['zzb'] = (
                                (value.current_amount_count * 100) /
                                (value.previous_amount_count * 100)
                            ).toFixed(2);
                        }

                        if (value.current_amount_count * 100 > 0) {
                            value['current_amount_count'] = value['current_amount_count'] / 10000;
                        }
                    }
                    this.dictData[prop].value = value;
                }
                return res;
            });
        },
        getMetaYearCommonList() {
            getMetaCommonList(`wisdom_park.v_year_other_amount_stat`, {
                current: 1,
                size: 500,
                statyear: '2023',
            }).then(res => {
                res.data.data.records.forEach(e => {
                    if (e.statyear == dayjs().year()) {
                        this.realfee = (e.realfee / 10000).toFixed(4);
                    }
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-card__body {
    padding: 15px;
}
.czmj {
    color: rgb(153, 153, 153);
    font-size: 12px;
}
.czmjNum {
    font-size: 20px;
    margin-top: 5px;
    padding-bottom: 8px;
    border-bottom: 1px solid #dcdfe6 !important;
}
.no-bor {
    border-bottom: 0px solid #dcdfe6 !important;
}
.czl {
    font-size: 12px;
    margin-top: 10px;
}
.ftzClor {
    color: rgb(153, 153, 153);
}
.down {
    color: #67c23a;
}
.top {
    color: #f56c6c;
}
</style>

import Component from './main.vue';

export const BarChartConfigs = [
  {
    widgetName: '柱状图',
    type: '图表',
    code: 'BuiltInWidgetByEchartsBar',
    icon: 'portal-iconfont portal-icon_fsux_tubiao_duijizhuzhuangtu',
    component: Component,
    dataOption: [
      {name: '分类' , code: 'category' , type: 'field'},
      {
        name: '系列' , code: 'value' , type: 'dynamic',
        optionList: [],
        desc: ['数据系列'],
        meta: [
          {name: '系列名' , code: 'name' , type: 'text'},
          {name: '系列值' , code: 'value' , type: 'field'}
        ]
      },
    ],
    optionList: [
      {
        code: 'chartType',
        value: 'bar',
        hide: true,
        type: 'text',
        name: '图表类型',
      },
      {
        code: '',
        name: '主题配置',
        optionList: [
          {
            code: 'color',
            type: 'theme',
            value: {
              name: '',
              color: []
            },
            name: '主题'
          }
        ]
      },
      {
        code: 'tooltip',
        name: '提示',
        optionList: [
          {
            code: 'show',
            name: '显示',
            type: 'boolean',
            value: true
          },
          {
            code: 'trigger',
            name: '触发类型',
            type: 'select',
            value: 'item',
            selectList: [
              {name: '数据项' , value: 'item'},
              {name: '坐标轴' , value: 'axis'}
            ]
          }
        ]
      },
      {
        code: 'legend',
        name: '图例',
        optionList: [
          {
            code: 'show',
            name: '显示',
            type: 'boolean',
            value: true
          }
        ]
      },
      {
        code: 'xAxis',
        name: 'X轴配置',
        optionList: [
          {
            code: 'type',
            value: 'category',
            type: 'select',
            name: '类型',
            selectList: [
              {name: '类目轴' , value: 'category'},
              {name: '数值轴' , value: 'value'},
              {name: '时间轴' , value: 'time'},
              {name: '对数轴' , value: 'log'}
            ]
          }
        ]
      },
      {
        code: 'yAxis',
        name: 'Y轴配置',
        optionList: [
          {
            code: 'type',
            value: 'value',
            type: 'select',
            name: '类型',
            selectList: [
              {name: '类目轴' , value: 'category'},
              {name: '数值轴' , value: 'value'},
              {name: '时间轴' , value: 'time'},
              {name: '对数轴' , value: 'log'}
            ]
          }
        ]
      },
      {
        code: '_series',
        name: '其他配置',
        optionList: [
          {
            code: 'stack',
            name: '堆叠',
            type: 'boolean',
            value: false,
            desc: ['数据堆叠',"目前数据堆叠只支持堆叠于数值和对数类型的类目轴上，不支持时间和分类类型的类目轴。"]
          },
          {
            code: 'barWidth',
            name: '柱子宽度',
            type: 'text',
            value: 'auto',
            desc: ["柱条的宽度，不设时自适应。" , "可以是绝对值例如 40 或者百分数例如 '60%'。" , "百分数基于自动计算出的每一类目的宽度。"]
          }
        ]
      }
    ]
  }
];

export const BarParse = () => {

}
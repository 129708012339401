<template>
<div class="panel-tab__content">
  <el-form
    ref="form"
    :model="infoForm"
    label-position="right"
    label-width="60px"
    size="small"
  >
    <el-form-item label="名称" size="small">
      <el-input class="w-240" v-model="infoForm.name" @change="taskInfoChange('name')"></el-input>
    </el-form-item>
    <el-form-item label="KEY" size="small">
      <el-input class="w-240" v-model="infoForm.taskKey" @change="taskInfoChange('taskKey')"></el-input>
    </el-form-item>
  </el-form>
</div>
</template>
<script>
export default {
  name: "baseInfo",
  data() {
    return {
      infoForm: {
        name: "",
        taskKey: "",
      }
    };
  },
  watch: {
    id: {
      immediate: true,
      handler(){
        this.resetBaseInfo();
      }
    }
  },
  mounted() {},
  created() {

  },
  props: {
    id: String,
    businessObject: Object,
    bpmnElement: {
      type: Object,
      default(){
        return null;
      }
    },
  },
  methods: {
    resetBaseInfo() {
      if(this.businessObject){
        this.infoForm.name = this.businessObject.name || '';
        this.infoForm.taskKey = this.businessObject.taskKey || this.businessObject.id || '';
        this.taskInfoChange();
      }
    },
    taskInfoChange(key){
      let attrObj = {};
      if(key){
        attrObj = {
          [key]: this.infoForm[key]
        }
      }else{
        Object.keys(this.infoForm).forEach(
          keyName => {
            attrObj[keyName] = this.infoForm[keyName];
          }
        )
      }
      
      if(this.bpmnElement){
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, attrObj);
      }
    }
  },
};
</script>
<style lang="scss" scoped></style>

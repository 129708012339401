<template>
  <el-form v-loading="loading" :size="size" ref="form" :model="form" :rules="rules" label-width="120px">
    <el-row :gutter="gutter">
      <el-col :span="12">
        <el-form-item label="API集合" prop="apiCollectionId">
          <el-select class="dis-b" v-model="form.apiCollectionId" placeholder="请选择">
            <el-option
              v-for="item in apiCollections"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="请求方式" prop="requestMethod">
          <el-select class="dis-b" v-model="form.requestMethod" placeholder="请选择">
            <el-option
              v-for="item in requestMethods"
              :key="item.value"
              :label="item.name"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="接口名称" prop="name">
          <el-input placeholder="请输入接口名称" v-model="form.name"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="外部请求地址" prop="registerShareUrl">
          <el-input v-model="form.registerShareUrl"></el-input>
        </el-form-item>
        <el-form-item v-if='type=="register-edit"' label="平台生成地址" prop="shareUrl1">
          <el-input disabled v-model="form.shareUrl"></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="用途" prop="description">
          <el-input  :rows="3" v-model="form.description" type="textarea"></el-input>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="gutter">
      <el-col :span="12">
        <div>
          <el-divider direction="vertical"></el-divider> 输入参数
          <el-divider></el-divider>
        </div>
        <div>
          <el-table
            border
            :data="inputParams"
            max-height="250"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column
              label="参数"
              width="120"
              prop="name">
              <template slot-scope="{row}">
                <el-input :size="size" v-model="row.key"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="dataType"
              label="数据类型"
              width="120">
              <template slot-scope="{row}">
                <el-select :size="size" class="dis-b" v-model="row.dataType" placeholder="请选择">
                  <el-option
                    v-for="item in paramTypes"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              prop="required"
              label="是否必填">
              <template slot-scope="{row}">
                <el-radio :size="size" v-model="row.required" :label="true">是</el-radio>
                <el-radio :size="size" v-model="row.required" :label="false">否</el-radio>
              </template>
            </el-table-column>
            <el-table-column
              label="默认值"
              width="120"
              prop="default">
              <template slot-scope="{row}">
                <el-input :size="size" v-model="row.default"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="示例值"
              width="120"
              prop="eg">
              <template slot-scope="{row}">
                <el-input :size="size" v-model="row.eg"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="desc"
              label="描述"
              width="120"
            >
              <template slot-scope="{row}">
                <el-input :size="size" v-model="row.desc"></el-input>
              </template>
            </el-table-column>
            <el-table-column
            fixed="right"
            label="操作"
            width="100">
            <template slot-scope="{row , $index}">
              <el-button @click="inputParams.splice($index , 1)" icon="el-icon-delete" type="text" :size="size">删除</el-button>
            </template>
          </el-table-column>
          <template slot="empty">
            <no-data></no-data>
          </template>
          </el-table>
          <div>
            <el-button @click="inputParamsAdd()" icon="el-icon-plus" type="text" :size="size">添加</el-button>
          </div>
        </div>
      </el-col>
      <el-col :span="12">
        <div>
          <el-divider direction="vertical"></el-divider> 返回参数
          <el-divider></el-divider>
        </div>
        <div>
          <el-table
            border
            :data="outputParams"
            max-height="250"
            tooltip-effect="dark"
            style="width: 100%"
          >
            <el-table-column
              label="参数"
              width="120"
              prop="name">
              <template slot-scope="{row}">
                <el-input :size="size" v-model="row.key"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="dataType"
              label="数据类型"
              width="120">
              <template slot-scope="{row}">
                <el-select :size="size" class="dis-b" v-model="row.dataType" placeholder="请选择">
                  <el-option
                    v-for="item in paramTypes"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value">
                  </el-option>
                </el-select>
              </template>
            </el-table-column>
            <el-table-column
              label="默认值"
              width="120"
              prop="default">
              <template slot-scope="{row}">
                <el-input :size="size" v-model="row.default"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              label="示例值"
              width="120"
              prop="eg">
              <template slot-scope="{row}">
                <el-input :size="size" v-model="row.eg"></el-input>
              </template>
            </el-table-column>
            <el-table-column
              prop="desc"
              label="描述"
              width="120"
            >
              <template slot-scope="{row}">
                <el-input :size="size" v-model="row.desc"></el-input>
              </template>
            </el-table-column>
            <el-table-column
            fixed="right"
            label="操作"
            width="100">
            <template slot-scope="{row , $index}">
              <el-button @click="outputParams.splice($index , 1)" icon="el-icon-delete" type="text" :size="size">删除</el-button>
            </template>
          </el-table-column>
          <template slot="empty">
            <no-data></no-data>
          </template>
          </el-table>
          <div>
            <el-button @click="outputParamsAdd()" icon="el-icon-plus" type="text" :size="size">添加</el-button>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-form-item>
      <basic-form-button @cancel="close" @submit="submit"></basic-form-button>
    </el-form-item>
  </el-form>
</template>
<script>

import api from '@/api/dataShare/index';
import { getDataAPICollectionSelect } from '@/api/dataService/dataAPICollection';
import * as dictbizService from '@/api/system/dictbiz';
import { env , isDev } from '@/config/env';
var shareUrlValidator = (rule, value, callback) => {
  var urlreg=/^((https|http|ftp|rtsp|mms)?:\/\/)+[A-Za-z0-9]/
    if (value === '') {
      callback(new Error('请输入请求地址'));
    } else if (!urlreg.test(value)) {
      callback(new Error('请输入正确的请求地址'));
    } else {
      callback();
    }
  };
export default {
  data(){
    let {size , gutter} = this.$store.state.common;
    return {
      buttons: [
        {text: '测试' , code: 'test' , icon: '' , type: 'success'},
        {text: '' , code: 'cancel' , icon: '' , type: ''},
        {text: '' , code: 'submit' , icon: '' , type: 'primary'}
      ],
      loading: false,
      size,
      gutter: 40,
      form: {
        apiCollectionId: null,
        name: '',
        shareUrl: '',
        registerShareUrl: '',
        description: '',
        requestMethod: ''
      },
      rules: {
        apiCollectionId: [
          { required: true, message: "请选择API集合", trigger: "blur"}
        ],
        name: [
          { required: true, message: "请输入接口名称", trigger: "blur"}
        ],
        registerShareUrl: [
          { required: true,validator: shareUrlValidator, trigger: "blur"},
        ],
        requestMethod: [
          { required: true, message: "请选择请求方式", trigger: "blur"}
        ]
      },
      apiCollections: [],
      requestMethods: [],
      paramTypes: [],
      apiCollectionMap: {},
      inputParams: [],
      outputParams: []
    }
  },
  computed: {
    origin(){
      if(isDev){
        return env.VUE_APP_LOCATION_ORIGIN || '';
      }else{
        return location.origin;
      }
    }
  },
  props: {
    id: String,
    type: String
  },
  mounted(){
    if(this.id){
      this.buttons = [
        {text: '测试' , code: 'test' , icon: '' , type: 'success'},
        {text: '' , code: 'cancel' , icon: '' , type: ''},
        {text: '' , code: 'submit' , icon: '' , type: 'primary'}
      ];
      this.get();
    }else{
      this.buttons = [
        {text: '' , code: 'cancel' , icon: '' , type: ''},
        {text: '' , code: 'submit' , icon: '' , type: 'primary'}
      ];
    }
    this.getDataAPICollectionSelect();
    this.initDicData();
  },
  methods: {
    get(){
      this.loading = true;
      return api.get({
        id: this.id
      }).then(
        res => {
          this.loading = false;
          if(res.data){
            this.form = res.data.data;
            if (res.data.data.type==0) {
              this.form.typeName= '本地生成'
            } else if (res.data.data.type==1){
              this.form.typeName= '第三方注册'
            } else {
              this.form.typeName= ''
            }
            this.inputParams = this.form.inputParams ? JSON.parse(this.form.inputParams) : [];
            this.outputParams = this.form.outputParams ? JSON.parse(this.form.outputParams) : [];
          }
        }
      )
    },
    initDicData(){
      dictbizService.getDictionary({
        code: 'REQUEST_METHOD'
      }).then(
        res => {
          this.requestMethods = (res.data.data || []).map(
            item => {
              return {
                name: item['dictValue'],
                value: item['dictKey']
              }
            }
          )
        }
      )
      dictbizService.getDictionary({
        code: 'PARAM_TYPE'
      }).then(
        res => {
          this.paramTypes = (res.data.data || []).map(
            item => {
              return {
                name: item['dictValue'],
                value: item['dictKey']
              }
            }
          )
        }
      )
    },
    getDataAPICollectionSelect(){
      getDataAPICollectionSelect().then(
        res => {
          this.apiCollections = (res.data.data || [])
          this.apiCollections.map( api => {
            this.apiCollectionMap[api['id']] = api['name'];
          });
        }
      )
    },
    inputParamsAdd(){
      this.inputParams.push({
        key: '',
        dataType: '',
        required: true,
        default: '',
        eg: '',
        desc: ''
      })
    },
    outputParamsAdd(){
      this.outputParams.push({
        key: '',
        dataType: '',
        required: true,
        default: '',
        eg: '',
        desc: ''
      })
    },
    responeHandle(res , done , loading){
      if(res.data && res.data.code === 200){
        done && done();
        this.$message.success(res.data.msg);
        this.$emit('refresh');
        this.$emit('close');
      }else{
        loading && loading();
        this.$message.error(res.data.msg);
      }
    },
    submit(done){
      this.$refs.form.validate( valid => {
        if(valid){
          this.form.apiCollectionName = this.apiCollectionMap[this.form.apiCollectionId];


          if(this.form.id){
            if(this.validatenull(this.form.registerShareUrl)){
              let shareUrls = this.form.shareUrl.split('/');
              shareUrls.pop();
              shareUrls.push(this.form.dataSourceTable);
              this.form.shareUrl = shareUrls.join('/');
            }else{
              this.form.shareUrl = this.origin + `/api/data-asset/datashare/query2/${this.form.id}`
            }
          }else{
            this.form.shareUrl = this.origin + '/api/data-asset/datashare/query2/${APIID}'
          }
          api.registerApi({
            ...this.form,
            outputParams: JSON.stringify(this.outputParams),
            inputParams: JSON.stringify(this.inputParams)
          }).then(
            res => {
              this.responeHandle(res);
              const h = this.$createElement;
              this.$message({
                message: h('p', null, [
                  h('span', { style: 'color: teal' }, '保存成功'),
                  h('p', { style: 'color: teal' }, `生成的地址为：${res.data.data.shareUrl}`)
                ]),
                type: 'success'
              });
              done();
            }
          ).catch(e => done());
        }else{
          done();
          return false;
        }
      })
    },
    close(){
      this.$emit('close');
    },
    test(){
      if(this.form.id){
        this.$emit('close' , {
          title: '测试',
          type: 'register-test'
        });
      }else{
        this.$message.warning('请先保存数据');
      }
    }
  }
}
</script>

<template>
  <el-card>
    <avue-crud
      ref="crud"
      :option="option"
      :data="list"
      :page="page"
      @row-save="rowSave"
      @row-del="rowDel"
      @row-update="rowUpdate"
    >
    </avue-crud>
  </el-card>
</template>
<script>
import {
  getTemList,
  updateTemItem,
  removeTemItem,
  addTemItem,
} from "@/api/busiMode/meta";
export default {
  name: "temlist",
  components: {},
  props: {
    temId: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      chooseModelbox: false,
      option: {
        refreshBtn: true,
        sortable: true,
        menu: true, //操作菜单栏

        columnBtn: false, //列显隐
        column: [
          {
            label: "文件名",
            prop: "fileName",
          },
          {
            label: "文件编码",
            prop: "fileCode",
          },
          {
            label: "类型",
            prop: "type",
            type: "tree",
            dicUrl: "/api/jida-system/dict/dictionary?code=FormType",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            rules: [
              {
                required: false,
                message: "请选择类型",
                trigger: "click",
              },
            ],
          },
          {
            label: "备注",
            prop: "note",
          },

          {
            label: "内容",
            prop: "content",
            type: "textarea",
            span: 24,
            overHidden: true,
          },
        ],
      },
      list: [],
      page: {
        pageSizes: [10, 20, 30, 40], //默认
        currentPage: 1,
        total: 10,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.initData();
  },
  watch: {
    temId() {
      this.initData();
    },
  },
  methods: {
    initData() {
      console.log(this.temId);
      let obj = { templatePlanId: this.temId };
      getTemList(obj).then((res) => {
        res.data.data.records.forEach((row) => {
          if (row.type == "表格") {
            row.type = "T";
          } else if (row.type == "表格") {
            row.type = "F";
          }
        });
        this.list = res.data.data.records;
      });
    },
    replaceRevertAllHTML(txt) {
      var value = txt;
      var leftCarr = "&lt;";
      var rightCarr = "&gt;";
      var dan = /&#39;/g;
      var and = /&amp;/g;
      var shuang = /&quot;/g;
      value = value.replace("<", leftCarr);
      value = value.replace(">", rightCarr);
      return value;
    },
    rowSave(row, done) {
      console.log(row.content);
      Object.keys(row).forEach((ele) => {
        if (ele.includes("$")) delete row[ele];
      });
      row.content = this.replaceRevertAllHTML(row.content);
      console.log(row.content);

      addTemItem(row).then(() => {
        this.$message.success("新增成功");
        this.initData();
        done();
      });
    },
    rowDel(row, index) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removeTemItem(row.id).then(() => {
            this.list.splice(index, 1);
          });
        })
        .catch(() => {});
    },
    rowUpdate(row, index, done) {
      if (row.type == "表格") {
        row.type = "T";
      } else if (row.type == "表格") {
        row.type = "F";
      }
      console.log(row.content);
      Object.keys(row).forEach((ele) => {
        if (ele.includes("$")) delete row[ele];
      });
      row.content = this.replaceRevertAllHTML(row.content);
      console.log(row.content);

      updateTemItem(row).then(() => {
        this.list.splice(index, 1, row);
        done();
      });
    },
    mbpz(row) {
      console.log(row);
    },
  },
};
</script>
<style scoped></style>

<template>
    <basic-container>
        <avue-crud ref="table" :data="tableData" :page.sync="page" :option="tableOption" :table-loading="tableLoading" :search.sync="search" v-model="form" :before-open="beforeOpen"
                   @search-change="searchChange" @on-load="onLoad" @refresh-change="onLoad" @row-save="saveTableForm" @row-update="(row, index, done, loading) => saveTableForm(row, done, loading)"
                   @row-del="rowDel">
            <!-- <template slot-scope="{size}" slot="menuLeft">
        <el-button icon="el-icon-plus" :size="size || 'small'" type="primary" @click="gotoForm()">新 增</el-button>
      </template> -->
            <template slot-scope="{size , row}" slot="menu">
                <!-- <el-button icon="el-icon-view" :size="size || 'small'" type="text" @click="gotoForm(row , 'view')">查看</el-button>
        <el-button icon="el-icon-edit" :size="size || 'small'" type="text" @click="gotoForm(row , 'edit')">编辑</el-button>
        <el-button icon="el-icon-link" :size="size || 'small'" type="text" @click="relation(row)">关联企业</el-button>
        <el-button icon="el-icon-delete" :size="size || 'small'" type="text" @click="rowDel(row)">删除</el-button> -->
      </template>
      <template slot-scope="{size , row , disabled}" slot="company_idForm">
        <model-search-select
          model="wisdom_park.wp_company"
          :size="size || 'small'"
          :disabled="disabled"
          v-model="form.company_id"
          :fillModel.sync="form"
          :fills="[
            {source: 'name' , target: 'company_name'}
          ]"
        ></model-search-select>
      </template>
      <template slot-scope="{size , row , disabled}" slot="reduction_policy_idForm">
        <model-search-select
          v-model="form.reduction_policy_id"
          :size="size || 'small'"
          :disabled="disabled"
          model="wp_reduction_policy"
          labelKey="reduction_policy_title"
          searchFieldName="reduction_policy_title"
          :fillModel.sync="form"
          :fills="[
            {source: 'reduction_policy_title' , target: 'reduction_policy_title'}
          ]">
                </model-search-select>
            </template>
        </avue-crud>
    </basic-container>
</template>
<script>
import { saveMetaCommon, updateMetaCommon, getMetaCommonList, delMetaCommon } from '@/api/busiMode/metaCommon';
export default {
    data() {
        return {
            form: {
                company_id: '',
                reduction_policy_id: '',
                company_name: '',
                reduction_policy_title: '',
            },
            model: 'wisdom_park.wp_reduction_policy_company',
            tableLoading: false,
            tableData: [],
            search: {},
            page: {
                pageSizes: this.$store.state.common.pageSizes,
                pagerCount: this.$store.state.common.pagerCount,
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            tableOption: {
                height: 'auto',
                searchMenuSpan: 6,
                border: true,
                // addBtn: false,
                // editBtn: false,
                // delBtn: false,
                searchLabelWidth: 100,
                // menuWidth: 250,
                column: [
                    {
                        label: '减免政策',
                        prop: 'reduction_policy_id',
                        hide: true,
                        showColumn: false,
                        disabled: true,
                    },
                    {
                        label: '企业',
                        prop: 'company_id',
                        hide: true,
                        showColumn: false,
                    },
                    {
                        label: '减免政策',
                        prop: 'reduction_policy_title',
                        span: 6,
                        // search: true,
                        // searchSpan: 6,
                        display: false,
                    },
                    {
                        label: '企业名称',
                        prop: 'company_name',
                        span: 6,
                        search: true,
                        searchSpan: 6,
                        display: false,
                    },
                ],
            },
        };
    },
    computed: {
        id() {
            return this.$router.currentRoute.query['id'];
        },
    },
    mounted() {},
    methods: {
        beforeOpen(done, type) {
            if (type === 'add') {
                this.form.reduction_policy_id = this.id;
            }
            done();
        },
        searchChange(form, done) {
            this.onLoad();
            done();
        },
        modelChange(ev, source, target) {
            if (ev && (ev.value ?? '') !== '' && ev.data) {
                ev.data.forEach(item => {
                    if (item['id'] == this.form[source]) {
                        this.form[target] = item[ev.labelKey];
                    }
                });
            } else {
                this.form[target] = '';
            }
        },
        onLoad() {
            let wheres = [{ field: 'reduction_policy_id', value: this.id }];
            ['reduction_policy_title', 'reduction_policy_type' , 'company_name'].forEach(name => {
                if (this.search[name]) {
                    if (name === 'reduction_policy_title') {
                        wheres.push({ field: name, op: 'LIKE', value: this.search[name] });
                    } else {
                        wheres.push({ field: name, value: this.search[name] });
                    }
                }
            });
            this.tableLoading = true;
            getMetaCommonList(this.model, {
                current: this.page.currentPage,
                size: this.page.pageSize,
                wheres: wheres,
            })
                .then(res => {
                    this.tableLoading = false;
                    if (res.data && res.data.code === 200) {
                        this.tableData = res.data.data.records;
                        this.page.total = res.data.data.total;
                    } else {
                        this.tableData = [];
                        this.page.total = 0;
                    }
                })
                .catch(e => {
                    this.tableLoading = false;
                });
        },
        saveTableForm(row, done, loading) {
            console.log(row);
            let body = {};
            this.tableOption.column.forEach(item => {
                if (row[item.prop] !== undefined) {
                    body[item.prop] = row[item.prop];
                }
            });
            if (row.id) {
                body = {
                    update: body,
                    primaryKeyValue: row.id,
                    primaryKey: 'id',
                };
            } else {
                body = {
                    values: body,
                };
            }
            (row.id ? updateMetaCommon : saveMetaCommon)(this.model, body)
                .then(res => {
                    this.responeHandle(res, done, loading);
                })
                .catch(e => {
                    loading();
                });
        },
        responeHandle(res, done, loading) {
            if (res.data && res.data.code === 200) {
                done && done();
                this.onLoad();
                this.$message.success(res.data.msg);
            } else {
                loading && loading();
                this.$message.error(res.data.msg);
            }
        },
        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return delMetaCommon(this.model, { pk: 'id', ids: [row.id] });
                })
                .then(() => {
                    this.onLoad();
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
    },
};
</script>
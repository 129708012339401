<template>
  <el-row>
    <basic-container>
      <!-- <el-steps :active="steps" align-center>
        <el-step title="基础信息" description=""></el-step>
        <el-step title="生成表格" description=""></el-step>
        <el-step title="生成菜单" description=""></el-step>
      </el-steps> -->
      <el-col :span="12" :offset="6">
        <el-steps
          :active="steps"
          finish-status="success"
          simple
          style="margin-top: -10px"
        >
          <el-step title="基础信息"></el-step>
          <el-step v-if="showType == 'table'" title="生成表格"></el-step>
          <el-step v-if="showType == 'form'" title="生成表单"></el-step>
          <!-- <el-step title="生成菜单"></el-step> -->
        </el-steps>
      </el-col>
      <el-col :span="6">
        <el-button
          class="pull-right"
          type="defaut"
          size="small"
          :disabled="steps == 2"
          @click="steps++"
          >下一步</el-button
        >
        <el-button
          class="pull-right mar-r-10"
          type="defaut"
          size="small"
          @click="steps--"
          :disabled="steps == 1"
          >上一步</el-button
        >
      </el-col>
    </basic-container>
    <el-col :span="24" v-if="steps == 1">
      <baseInfo></baseInfo>
    </el-col>

    <el-col :span="24" v-if="steps == 2">
      <!-- <tablePreview
        v-if="showType == 'table'"
        :isEdit="false"
        :importCode="preOption"
      ></tablePreview> -->
      <formPreview v-if="showType == 'form'"></formPreview>
    </el-col>
    <createMenu v-if="steps == 3"></createMenu>
  </el-row>
</template>

<script>
import baseInfo from "./baseInfo";
import formPreview from "../form/design";

// import tablePreview from "./design";
import createMenu from "./createMenu";
export default {
  name: "tableList",
  components: { createMenu, baseInfo, formPreview },
  data() {
    return {
      preOption: {},
      steps: 1,
      showType: "table",
    };
  },
  watch: {},
  mounted() {
    this.showType = this.$router.currentRoute.query.type;
  },
  methods: {
    createMenu() {
      this.steps = 1;
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss">
.el-step__title {
  font-size: 13px;
  line-height: 30px;
}
.sccd {
  position: absolute;
  right: 15px;
  z-index: 1000;
}
</style>

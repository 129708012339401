<template>
  <div>
    <avue-dynamic
      v-model="ruleFormScryy"
      :rules="ruleModel"
      :children="stryOption"
    ></avue-dynamic>
    <div class="btnClass bor-t">
      <el-button
        class="pull-right mar-t-10"
        size="small"
        type=""
        @click="closeVrDia"
        >取消</el-button
      >
      <el-button
        size="small"
        class="pull-right mar-r-10 mar-t-10"
        type="primary"
        @click="save"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import {
  updateMetaCommon,
  getMetaCommonDetail,
  getMetaCommonList,
} from "@/api/busiMode/metaCommon";
export default {
  name: "setVr",
  data() {
    return { ruleModel: {}, stryOption: {}, ruleFormScryy: [], housingObj: {} };
  },
  watch: {},
  mounted() {
    this.setOption();
    this.getMetaCommonDetail();
  },
  computed: {},
  props: {
    currentRow: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    setOption() {
      this.stryOption = {
        column: [
          {
            type: "input",
            width: 200,
            prop: "name",
            label: "区域名称",
            align: "center",
            cell: true,
            rules: [
              {
                required: true,
                message: "请填入",
                trigger: "blur",
              },
            ],
          },
          {
            label: "上",
            prop: "area_top",
            type: "upload",
            span: 24,
            align: "center",
            limit: 1,
            listType: "picture-img",
            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
            rules: [
              {
                required: true,
                message: "请上传上方的图片",
                trigger: "blur",
              },
            ],
          },
          {
            label: "下",
            prop: "area_bottom",
            type: "upload",
            listType: "picture-img",
            span: 24,
            align: "center",
            propsHttp: {
              res: "data",
              url: "link",
            },
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
            rules: [
              {
                required: true,
                message: "请上传下方的图片",
                trigger: "blur",
              },
            ],
          },
          {
            label: "左",
            prop: "area_left",
            type: "upload",
            listType: "picture-img",
            span: 24,
            align: "center",
            propsHttp: {
              res: "data",
              url: "link",
            },
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
            rules: [
              {
                required: true,
                message: "请上传左方的图片",
                trigger: "blur",
              },
            ],
          },
          {
            label: "右",
            prop: "area_right",
            type: "upload",
            listType: "picture-img",
            span: 24,
            align: "center",
            propsHttp: {
              res: "data",
              url: "link",
            },
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
            rules: [
              {
                required: true,
                message: "请上传右方的图片",
                trigger: "blur",
              },
            ],
          },
          {
            label: "前",
            prop: "area_front",
            type: "upload",
            listType: "picture-img",
            span: 24,
            align: "center",
            propsHttp: {
              res: "data",
              url: "link",
            },
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
            rules: [
              {
                required: true,
                message: "请上传前方的图片",
                trigger: "blur",
              },
            ],
          },
          {
            label: "后",
            prop: "area_back",
            type: "upload",
            listType: "picture-img",
            span: 24,
            align: "center",
            propsHttp: {
              res: "data",
              url: "link",
            },
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
            rules: [
              {
                required: true,
                message: "请上传后方的图片",
                trigger: "blur",
              },
            ],
          },
          {
            label: "默认显示",
            prop: "default_display",
            type: "switch",
            dicData: [
              {
                label: "",
                value: false,
              },
              {
                label: "",
                value: true,
              },
            ],
            value: 0,
            align: "center",
            span: 24,
            width: 80,
            change: ({ row, index, value, column }) => {
              if (value) {
                this.changeDisplay(value, index);
              }
            },
          },
        ],
      };
    },
    changeDisplay(value, index) {
      this.ruleFormScryy.forEach((element, i) => {
        if (i == index) {
          element.default_display = true;
        } else {
          if (value) {
            element.default_display = false;
          }
        }
      });
      this.$forceUpdate();
    },
    getMetaCommonDetail() {
      this.loading = true;
      getMetaCommonDetail("wisdom_park.wp_housing_resources", {
        pk: "id",
        id: this.currentRow.id,
      }).then((res) => {
        let form = res.data.data;
        console.log(res.data.data, "12312");
        this.housingObj = form;

        let b = {
          current: 1,
          size: 20,
          wheres: [{ field: "housing_resource_id", value: this.currentRow.id }],
        };
        getMetaCommonList("wisdom_park.wp_housing_resource_area", b).then(
          (res) => {
            this.ruleFormScryy = res.data.data.records;
          }
        );
      });
    },
    save() {
      let ruleFormScryy = [];
      this.ruleFormScryy.forEach((e) => {
        let obj = {};
        Object.keys(e).forEach((ele) => {
          if (!ele.includes("$")) {
            obj[ele] = e[ele];
          }
        });
        if (obj.default_display) {
          obj.default_display = 1;
        } else {
          obj.default_display = 0;
        }
        delete obj._index;
        console.log(obj);
        ruleFormScryy.push(obj);
      });
      let currentRow = {};
      Object.keys(this.housingObj).forEach((ele) => {
        if (!ele.includes("$")) {
          currentRow[ele] = this.housingObj[ele];
        }
      });
      let o = {
        update: currentRow,
        primaryKeyValue: this.currentRow["id"],
        primaryKey: "id",
        detailTable: "wp_housing_resource_area",
        listDetailValues: ruleFormScryy,
        masterIdColumnName: "housing_resource_id",
      };
      updateMetaCommon("wisdom_park.wp_housing_resources", o).then((res) => {
        if (res.data.success) {
          this.$message.success(res.data.msg);
        } else {
          this.$message.success(res.data.msg);
        }
        this.closeVrDia();
      });
    },
    closeVrDia() {
      this.$emit("closeVrDia");
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .avue-upload__avatar {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.btnClass {
  display: flow-root;
  height: 50px;
  line-height: 50px;
  padding: 0px 15px;
}
</style>

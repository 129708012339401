<template>
<el-form v-loading="loading" ref="form" :model="form" :rules="rules" size="small" label-width="100px" label-position="top">
  <el-row>
    <el-col :span="12">
      <el-form-item label="协议类型" prop="supplementary_type">
        <el-select class="dis-b" v-model="form.supplementary_type" disabled>
          <el-option v-for="item of supplementaryType" :key="item.id" :label="item.dictValue" :value="item.dictKey"></el-option>
        </el-select>
      </el-form-item>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="12">
      <el-form-item label="协议编号" prop="supplementary_code">
        <el-input
          :disabled="disabled"
          v-model="form.supplementary_code"
        ></el-input>
      </el-form-item>
    </el-col>
    <el-col :span="12">
      <el-form-item label="协议模板" prop="supplementary_template_id">
        <model-search-select
          class="dis-b"
          :disabled="disabled"
          v-model="form.supplementary_template_id"
          model="wp_contract_template"
          labelKey="template_name"
          valueKey="id"
          :params="{template_type: '2'}"
        />
      </el-form-item>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="12">
      <el-form-item label="约定开始时间" prop="start_date">
        <el-date-picker
          :disabled="disabled"
          class="dis-b w-100p"
          v-model="form.start_date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd 00:00:00"
        >
        </el-date-picker>
      </el-form-item>
    </el-col>
    <el-col :span="12">
      <el-form-item label="约定结束时间" prop="end_date">
        <el-date-picker
          :disabled="disabled"
          class="dis-b w-100p"
          v-model="form.end_date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd 23:59:59"
        >
        </el-date-picker>
      </el-form-item>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="12">
      <el-form-item label="签订时间" prop="sign_date">
        <el-date-picker
          :disabled="disabled"
          class="dis-b w-100p"
          v-model="form.sign_date"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </el-form-item>
    </el-col>
    <el-col :span="12">
      <el-form-item label="甲方签订人" prop="sign_person">
        <el-input
          :disabled="disabled"
          v-model="form.sign_person"
        ></el-input>
      </el-form-item>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="12">
      <el-form-item label="乙方公司" prop="lessee_a_company_id">
        <model-search-select
          :disabled="disabled"
          class="dis-b w-100p"
          v-model="form.lessee_a_company_id"
          model="wp_company"
          :fillModel.sync="form"
          :fills="[
            {source: 'name' , target: 'lessee_a_name'}
          ]"
        />
      </el-form-item>
    </el-col>
    <el-col :span="12">
      <el-form-item label="乙方签订人" prop="lessee_a_sign">
        <el-input
          :disabled="disabled"
          v-model="form.lessee_a_sign"
        ></el-input>
      </el-form-item>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="12">
      <el-form-item label="丙方公司" prop="lessee_b_company_id">
        <model-search-select
          :disabled="disabled"
          class="dis-b w-100p"
          v-model="form.lessee_b_company_id"
          model="wp_company"
          :fillModel.sync="form"
          :fills="[
            {source: 'name' , target: 'lessee_b_name'}
          ]"
        />
      </el-form-item>
    </el-col>
    <el-col :span="12">
      <el-form-item label="丙方签订人" prop="lessee_b_sign">
        <el-input
          :disabled="disabled"
          v-model="form.lessee_b_sign"
        ></el-input>
      </el-form-item>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="24">
      <el-form-item label="备注" prop="note">
        <el-input
          :disabled="disabled"
          v-model="form.note"
          type="textarea"
        ></el-input>
      </el-form-item>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="24">
      <el-form-item label="附件" prop="attachment_ids">
        <ManageFormFile :disabled="disabled" v-model="form.attachment_ids"></ManageFormFile>
      </el-form-item>
    </el-col>
  </el-row>
</el-form>
</template>
<script>
import ManageFormFile from '../manageFormFile.vue';
import { getMetaCommonDetail } from '@/api/busiMode/metaCommon';

export default {
  components: { ManageFormFile },
  data(){
    return {
      loading: false,
      form: {
        contract_id: '',
        contract_code: '',
        supplementary_code: '',
        supplementary_type: '',
        supplementary_template_id: '',
        start_date: '',
        end_date: '',
        sign_date: '',
        sign_person: '',
        lessee_a_name: '',
        lessee_a_company_id: '',
        lessee_a_sign: '',
        lessee_b_name: '',
        lessee_b_company_id: '',
        lessee_b_sign: '',
        note: '',
        attachment_ids: ''
      },
      rules: {
        supplementary_code: [{required: true , trigger: "blur" ,  message: '请输入 协议编号'}],
        supplementary_template_id: [ {required: true , trigger: "blur" , message: '请选择 协议模板'} ],
        supplementary_type: [ {required: true , trigger: "blur" ,  message: '请选择 协议类型'} ],
        lessee_b_company_id: [ {required: true , trigger: "blur" ,  message: '请选择 丙方公司'} ],
        lessee_a_company_id: [ {required: true , trigger: "blur" ,  message: '请选择 乙方公司'} ],
        sign_person: [ {required: true , trigger: "blur" ,  message: '请输入 甲方签订人'} ],
        sign_date: [ {required: true , trigger: "blur" , message: '请选择 签订时间'} ],
        lessee_a_sign: [ {required: true , trigger: "blur" ,  message: '请输入 乙方签订人'} ],
        lessee_b_sign: [ {required: true , trigger: "blur" ,  message: '请输入 丙方签订人'} ],
      },
      model: 'wisdom_park.wp_contract_supplementary',
    }
  },
  computed: {
    disabled(){
      return this.mode === 'view' ? true : false;
    }
  },
  props: {
    supplementaryType: {
      type: Array,
      default(){
        return []
      }
    },
    contractInfo: {
      type: Object,
      default(){
        return null;
      }
    },
    type: {
      type: String,
      default: ''
    },
    id: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: ''
    }
  },
  mounted(){
    this.form['supplementary_type'] = this.type;
    if(this.id){
      this.getMetaCommonDetail();
    }else{
      this.form['lessee_a_company_id'] = this.contractInfo.companyId;
      this.form['lessee_a_name'] = this.contractInfo.rentersName;
      this.form['start_date'] = this.contractInfo.startDate;
      this.form['end_date'] = this.contractInfo.endDate;
      this.form['contract_code'] = this.contractInfo.code;
      this.form['contract_id'] = this.contractInfo.id;
    }
  },
  methods: {
    getMetaCommonDetail(){
      this.loading = true;
      getMetaCommonDetail(this.model , {
        pk: 'id',
        id: this.id,
      }).then(
        res => {
          this.loading = false;
          let form = res.data.data;
          Object.keys(form).forEach(
            keyName => {
              if(typeof form[keyName] === 'number'){
                form[keyName] = `${form[keyName]}`;
              }
            }
          );
          this.form = form;
        }
      )
    },
    save(callback){
      this.$refs.form.validate(valid => {
        if(valid){
          callback(this.form)
        }else{
          callback(null);
        }
      })
    }
  }
}
</script>
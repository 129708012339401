<template>
  <basic-container class="h-100p">
    <div class="car-avue-data-tabs">
      <avue-data-tabs :option="option"></avue-data-tabs>
    </div>
    <el-tabs v-model="activeName">
      <el-tab-pane v-for="item of tabs" :label="item.label" :name="item.value"></el-tab-pane>
    </el-tabs> 
    <div>
      <LongCarRent v-if="activeName === 'long'"></LongCarRent>
      <TemporaryCarRent v-if="activeName === 'temporary'"></TemporaryCarRent>
    </div>
  </basic-container>
</template>
<script>
import { getMetaCommonList } from "@/api/busiMode/metaCommon";

import { numConvertToStr } from '@/filters/numConvert'

import LongCarRent from './carRent/long.vue';
import TemporaryCarRent from './carRent/temporary.vue';

export default {
  components: { TemporaryCarRent , LongCarRent },
  data(){
    return {
      activeName: 'long',
      tabs: [
        {label: '长租车' , value: 'long'},
        {label: '临时车' , value: 'temporary'},
      ],
      option: {
        span: 8,
        height: 100,
        data: [
          {
            title: '上周实收',
            subtitle: '实时',
            count: '0',
            color: '#409EFF',
            _key: 'fee_week',
            key: '周',
            countSuffix: '万',
            decimals: 2
          },
          {
            title: '本月实收',
            subtitle: '实时',
            count: '0',
            color: '#409EFF',
            _key: 'fee_month',
            key: '月',
            countSuffix: '万',
            decimals: 2
          },
          {
            title: '本年度实收',
            subtitle: '实时',
            count: '0',
            color: '#409EFF',
            _key: 'fee_year',
            key: '年',
            countSuffix: '万',
            decimals: 2
          }
        ]
      }
    }
  },
  mounted(){
    this.getMetaCommonList();
  },
  methods: {
    getMetaCommonList(){
      getMetaCommonList('wisdom_park.v_jsc_wp_parking_fee_stat' , {
        size: 10,
        current: 1
      }).then(
        res => {
          if(res.data.data.records && res.data.data.records.length){
            let data = res.data.data.records[0];
            this.option.data.forEach(
              item => {
                item.count = `${data[item._key]}`; //numConvertToStr(data[item._key] , 2 , true , true);
                // item.count = `${data[item._key]} 万`
              }
            )
          }
        }
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.car-avue-data-tabs{
  /deep/.avue-data-tabs{
    .item{
      height: 100px;
    }
    .item-tip{
      top: -35px;
    }
  }
}
</style>
<template>
  <div>
    <el-divider content-position="left">楼宇信息</el-divider>
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      label-position="top"
      size="small"
      :rules="rules"
    >
      <el-row>
        <el-col :span="6">
          <el-form-item label="楼宇名称" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="物业费" prop="property_management_fee">
            <el-input v-model="ruleForm.property_management_fee"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="建筑面积" prop="floorage">
            <el-input v-model="ruleForm.floorage"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="在管面积" prop="manger_area">
            <el-input v-model="ruleForm.manger_area"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="楼宇地址" prop="address">
        <el-input v-model="ruleForm.address"></el-input>
      </el-form-item>
      <el-form-item label="楼宇描述" prop="description">
        <el-input type="textarea" v-model="ruleForm.description"></el-input>
      </el-form-item>
      <el-form-item label="租赁方式">
        <el-radio-group v-model="ruleForm.zlfs">
          <el-radio label="单间"></el-radio>
          <el-radio label="整层"></el-radio>
        </el-radio-group>
      </el-form-item>
      <el-divider content-position="left">楼宇图片</el-divider>
      <el-upload
        action="https://jsonplaceholder.typicode.com/posts/"
        list-type="picture-card"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </el-form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogImageUrl: "",
      dialogVisible: false,
      ruleForm: {},
      rules: {
        name: [{ required: true, message: "请输入楼宇名称", trigger: "blur" }],
        address: [
          { required: true, message: "请输入楼宇地址", trigger: "blur" },
        ],
        property_management_fee: [
          { required: true, message: "请输入在管面积", trigger: "blur" },
        ],
        zlfs: [{ required: true, message: "请输入租赁方式", trigger: "blur" }],
      },
    };
  },
  computed: {},
  methods: {
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
  margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
  left: 0px;
}
/deep/ .el-divider__text {
  padding: 0px 10px 0px 0px;
}
</style>

<template>
  <basic-container>
    <avue-crud
      ref="table"
      :data="tableData"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      :search.sync="search"
      @search-change="onLoad"
      @on-load="onLoad"
      @refresh-change="onLoad"
      @row-update="(row, index, done, loading) => saveTableForm(row, done, loading)"
    >
      <template slot-scope="{size , row}" slot="menu">
        <el-button icon="el-icon-edit" :size="size || 'small'" type="text" @click="handleProcess(row)">处理</el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>
<script>

import { getTodoList } from '@/api/flow/flow.js';

export default {
  data(){
    return {
      tableLoading: false,
      tableData: [],
      search: {},
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableOption: {
        height: 'auto',
        labelPosition: 'top',
        searchMenuPosition: 'right',
        searchLabelPosition: 'right',
        searchMenuSpan: 24,
        border: true,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        column: [
          {
            label: '待办名称',
            prop: 'taskName'
          },
          {
            label: '创建时间',
            prop: 'createTime'
          }
        ]
      },
    }
  },
  mounted(){
    
  },
  methods: {
    onLoad(){
      
      getTodoList({
        current: this.page.currentPage,
        size: this.page.pageSize,
      }).then(
        res => {
          this.tableLoading = false;
          if(res.data && res.data.code === 200){
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
          }else{
            this.tableData = [];
            this.page.total = 0;
          }
        }
      ).catch(e => {
        this.tableLoading = false;
      })
    },
    handleProcess(row){
      this.$store.commit("SET_CURRENT_TODO_INFO", row);
      this.$router.push('/flow/todo/detail?id=' + row.taskId);
    }
  }
}
</script>
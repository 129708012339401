<template>
  <basic-container isCard>
    <div>
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="110px"
        size="small"
        :disabled="type == 'detail'"
        label-position="right"
        :rules="ruleModel"
      >
        <el-row>
          <el-col :span="20">
            <el-form-item label="门禁名称" prop="door_name">
              <el-input
                v-model="ruleForm.door_name"
                placeholder="请输入门禁名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="门禁所在楼宇" prop="building_id">
              <el-select
                @change="changeBuild"
                v-model="ruleForm.building_id"
                size="small"
                placeholder="请选择门禁所在楼宇"
              >
                <el-option
                  v-for="item in buildList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="门禁标识" prop="door_index_codes">
              <el-input
                placeholder="门禁标识"
                v-model="ruleForm.door_index_codes"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <el-form-item label="门禁设备编码" prop="door_ip">
              <el-input
                placeholder="门禁设备编码"
                v-model="ruleForm.door_ip"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="btnClass bor-t">
        <el-button
          size="small"
          class="mar-t-10 mar-r-10 pull-right"
          type=""
          @click="close"
          >取消</el-button
        >
        <el-button
          v-if="type != 'detail'"
          size="small"
          class="mar-t-10 mar-r-5 pull-right"
          type="primary"
          :loading="saveLoading"
          @click="save"
          >保存</el-button
        >
      </div>
    </div>
  </basic-container>
</template>

<script>
import * as dictbizService from "@/api/system/dictbiz";
import standardService from "@/api/metadata/standard";
import { mapGetters, mapState } from "vuex";

import {
  getMetaCommonInfo,
  saveMetaCommon,
  updateMetaCommon,
  getPicListByIds,
} from "@/api/busiMode/metaCommon";
import { getMetaCommonList, delMetaCommon } from "@/api/busiMode/metaCommon";

export default {
  data() {
    return {
      ruleModel: {},
      ruleForm: {},
      buildList: [],
      saveLoading: false,
      entModel: "wisdom_park.wp_door",
      buildModel: "wisdom_park.wp_building",
    };
  },
  computed: {},
  mounted() {
    this.queryByModelCode();
    this.getMetaCommonList();
  },
  computed: {},
  props: {
    currentRow: {
      type: Object,
      default: {},
    },
    type: {
      type: String,
      default: "detail",
    },
  },
  watch: {
    currentRow: {
      immediate: true,
      handler(val) {
        if (val.id) {
          this.getMetaCommonInfo();
        }
      },
    },
    type: {
      immediate: true,
      handler(val) {},
    },
  },
  methods: {
    changeBuild() {
      let name = "";
      this.buildList.forEach((b) => {
        if (b.id == this.ruleForm.building_id) {
          console.log(b);
          name = b.name;
        }
      });
      this.ruleForm.building_name = name;
    },
    getMetaCommonInfo() {
      let obj = {
        pk: "id",
        id: this.currentRow.id,
      };
      getMetaCommonInfo(this.entModel, obj).then((res) => {
        if (res.data.code == 200) {
          this.ruleForm = res.data.data;
        }
      });
    },
    queryByModelCode() {
      standardService.getModelRule(this.entModel).then((res) => {
        this.ruleModel = res;
      });
    },
    getMetaCommonList() {
      let b = {
        page: {
          size: 100,
          current: 1,
          total: 0,
        },
      };
      getMetaCommonList(this.buildModel, {
        size: 100,
        current: 1,
        total: 0,
      }).then((res) => {
        const data = res.data.data;
        this.buildList = data.records;
      });
    },
    submit() {
      return new Promise((resolve) => {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            resolve({
              valid: true,
              form: this.ruleForm,
            });
          } else {
            let fields = this.$refs["ruleForm"].fields,
              firstError = {
                field: "",
                message: "",
                name: "",
              };
            for (let i = 0; i < fields.length; i++) {
              if (fields[i].validateState === "error") {
                firstError.message = fields[i].validateMessage;
                firstError.field = fields[i].prop;
                firstError.name = fields[i].label;
                break;
              }
            }
            resolve({
              valid: false,
              form: this.ruleForm,
              error: firstError,
            });
          }
        });
      });
    },
    close() {
      this.$emit("close");
    },
    save() {
      Promise.all([this.submit()]).then((forms) => {
        this.saveLoading = true;
        if (this.ruleForm.id) {
          let o = {
            update: this.ruleForm,
            primaryKeyValue: this.ruleForm["id"],
            primaryKey: "id",
          };
          updateMetaCommon(this.entModel, o)
            .then((res) => {
              if (res.data.code == 200) {
                if (res.data.data.id) {
                  this.$emit("close");
                } else {
                  this.$emit("close");
                }
                this.saveLoading = false;
              }
            })
            .catch((e) => {
              this.saveLoading = false;
            });
        } else {
          saveMetaCommon(this.entModel, {
            values: this.ruleForm,
          })
            .then((res) => {
              if (res.data.code == 200) {
                if (res.data.data.id) {
                  this.$emit("close");
                } else {
                  this.$emit("close");
                }
                this.saveLoading = false;
              }
            })
            .catch((e) => {
              this.saveLoading = false;
            });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
  margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
  left: 0px;
}
/deep/ .el-divider__text {
  padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
  width: 68px;
  height: 68px;
  line-height: 72px;
  vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
}
.btnClass {
  display: flow-root;
  height: 50px;
  line-height: 50px;
}
/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
/deep/ .el-select {
  width: 100%;
}
</style>

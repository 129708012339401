<template>
  <div class="container">
    <avue-form-design
      style="height: 100vh"
      :options="options"
      @submit="handleSubmit"
      storage
      show-avue-doc
    >
    </avue-form-design>
  </div>
</template>
<script>
/**
 * 1.0版本
 */
import draggable from "vuedraggable";

let tempActiveData;

export default {
  name: "formDesigner",
  components: {
    draggable,
  },
  data() {
    return {
      options: {},
      formItems: {},
      designList: [],
      activeData: {},
    };
  },
  mounted() {},
  methods: {
    handleSubmit(data) {
      this.$message.success("查看控制台");
    },
    cloneComponent(origin) {
      const clone = JSON.parse(JSON.stringify(origin));
      if (!clone.layout) clone.layout = "colItem";
      if (clone.layout === "colItem" || clone.layout === "dynamicItem") {
        let uId = "fd_";
        clone.id = uId;
        clone._id = uId;
        tempActiveData = clone;
      } else {
        let uId = "row_";
        clone.id = uId;
        clone._id = uId;
        tempActiveData = clone;
      }
      this.$refs.designer.activeItem = tempActiveData;
    },
    onEnd(obj) {
      if (obj.from !== obj.to) {
        this.activeData = tempActiveData;
        this.$refs.designer.activeItem = this.activeData;
        if (obj.to.className.indexOf("row-drag") < 0) {
          this.designList.splice(obj.newIndex, 0, this.activeData);
        }
      } else {
        this.$refs.designer.activeItem = {};
      }
    },
  },
};
</script>
<style scoped>
.container {
  padding: 0px;
}
</style>

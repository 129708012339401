<template>
  <div>
    <el-row>
      <el-col :span="24">
        <basic-container>
          <p style="margin-bottom: 10px;">数据总览</p>
          <el-row type="flex">
            <el-col>
              <el-row type="flex" class="h-100p" :gutter="40" style="align-items:center;justify-content:center;">
                <el-col :span="5" class="text-center">
                  <span class="fts-18">128</span>
                  <br>
                  <el-divider class="dis-ib w-100p zoom-d5"></el-divider><br>
                  <span class="fts-12">累计已发布API数量</span>
                </el-col>
                <el-col :span="5" class="text-center">
                  <span class="fts-18">96</span>
                  <br>
                  <el-divider class="dis-ib w-100p zoom-d5"></el-divider><br>
                  <span class="fts-12">累计调用API数量</span>
                </el-col>
                <el-col :span="5" class="text-center">
                  <span class="fts-18">1000</span>
                  <br>
                  <el-divider class="dis-ib w-100p zoom-d5"></el-divider><br>
                  <span class="fts-12">累计调用API次数</span>
                </el-col>
                <el-col :span="5" class="text-center">
                  <span class="fts-18">1000</span>
                  <br>
                  <el-divider class="dis-ib w-100p zoom-d5"></el-divider><br>
                  <span class="fts-12">累计调用API成功次数</span>
                </el-col>
                <el-col :span="5" class="text-center">
                  <span class="fts-18">1000</span>
                  <br>
                  <el-divider class="dis-ib w-100p zoom-d5"></el-divider><br>
                  <span class="fts-12">累计调用API成功次数</span>
                </el-col>
              </el-row>
            </el-col>
          </el-row>
        </basic-container>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="12" style="position: relative">
        <basic-container>
          <p>服务调用(TOP10)</p>
          <div style="height: 300px">
            <basic-echarts :option="chartOption1"></basic-echarts>
          </div>

        </basic-container>
      </el-col>
      <el-col :span="12" style="position: relative">
        <basic-container>
          <p>服务调用比率(TOP10)</p>
          <div style="height: 300px">
            <basic-echarts :option="chartOption1"></basic-echarts>
          </div>

        </basic-container>
      </el-col>
      <el-col :span="12" style="position: relative">
        <basic-container>
          <p>API调用失败率(TOP10)</p>
          <div style="height: 300px">
            <basic-echarts :option="chartOption1"></basic-echarts>
          </div>

        </basic-container>
      </el-col>
      <el-col :span="12" style="position: relative">
        <basic-container>
          <p>API调用平均耗时(TOP10)</p>
          <div style="height: 300px">
            <basic-echarts :option="chartOption1"></basic-echarts>
          </div>

        </basic-container>
      </el-col>
    </el-row>

  </div>
</template>
<script>
  import {
    getOverViewData,
    getLevelStaticsData,
    getFileTopData,
    getDBTopData
  } from '@/api/dataMaintenance/dataStatics';
export default {
  data() {
    return {
      dataFileSort: 'industry',
      dataFileCountSort: 'industry',
      chartOption1: null,
      option: {
        header:false,
        menu: false,
        excelBtn:true,
        border: true,
        index: true,
        expandLevel: 3,
        headerAlign: 'center',
        align: 'center',
        tree: true,
        labelWidth: 100,
        column: [{
          label: '分层',
          prop: 'name',
          width:140,
        },
          {
            label: '数据文件',
            children: [{
              label: '数据文件(份)',
              prop:'test'
            }, {
              label: '文件大小(G)',
              prop: 'test1',
            }]
          },
          {
            label: '数据库表',
            children: [{
              label: '数据库(个)',
              prop:'dbs'
            }, {
              label: '表(个)',
              prop: 'tablse',
            },{
              label: '数据量(条)',
              prop:'count'
            }]
          }],
      },
      data: [{
        name: '原始数据层',
        age: 14,
        address: '110000',
        phone1: '17547400800',
        phone: '17547400800',
        icon: 'el-icon-time',
        test: 1,
        test1:1,
        dbs:1,
        tablse:1,
        count:1
      }, {
        name: '应用数据层',
        age: 10,
        address: '120000',
        test: 1,
        test1:1,
        dbs:1,
        tablse:1,
        count:1
      }]
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.chartOption1 = this.getOption();
    },
    getOverViewData() {
      getOverViewData().then( res => {

      }).catch( error => {

      });
    },
    getLevelStaticsData() {
      getLevelStaticsData().then( res => {

      }).catch( error => {

      });
    },
    getFileTopData() {
      getFileTopData().then( res => {

      }).catch( error => {

      });
    },
    getDBTopData() {
      getDBTopData().then( res => {

      }).catch( error => {

      });
    },
    getOption(){
      return {
        grid: {
          left: '3%',
          right: '3%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: ['service', 'app', 'jenkins', 'nginx', 'tomcat', ]
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: [120, 200, 150, 80, 70, 110, 130],
          type: 'bar',
          barWidth: 30
        }]
      };
    }
  }

}
</script>
<style lang="scss">
  .fts-18 {
    font-size: 18px;
  }
  .fts-12 {
    font-size: 12px;
  }
  .w-100p {
    width: 100%;
  }
  .zoom-d5{
    zoom: .5;
  }
</style>

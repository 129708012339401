<template>
  <basic-container>
    <avue-crud
      :data="tableData"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      @on-load="onLoad"
      @refresh-change="onLoad"
    >
      <template slot="menuLeft" slot-scope="{size}">
        <el-button icon="el-icon-plus" :size="size" @click="addView">新增</el-button>
      </template>
      <template slot="menu" slot-scope="{size , row}">
        <el-button type="text" icon="el-icon-edit" :size="size" @click="editView(row)">编辑</el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>
<script>

import {
  getDynamicFormList
} from '@/api/busiMode/meta.js'

export default {
  data(){
    return {
      tableData: [],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableOption: {
        addBtn: false,
        editBtn: false,
        column: [
          {
            label: '视图名称',
            prop: 'name'
          },
          {
            label: '创建时间',
            prop: 'createTime'
          }
        ]
      },
      tableLoading: false
    }
  },
  methods: {
    onLoad(){
      getDynamicFormList({
        type: 'V'
      }).then(
        res => {
          console.log(res);
          this.tableData = res.data.data.records || [];
          this.page.total = res.data.data.total;
        }
      )
    },
    addView(){
      this.$router.push('/mypages/design')
    },
    editView(row){
      console.log(row);
      this.$router.push('/mypages/design/' + row.id)
    }
  }
}
</script>
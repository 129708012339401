<template>
    <basic-container>
        <el-form ref="form" label-width="80px" class="mar-t-20f">
            <el-form-item label="延期天数">
                <el-input-number size="small" v-model="card_yq_num" :min="1" @change="changNum"></el-input-number>
            </el-form-item>
        </el-form>
        <div class="mar-t-50f">
            <avue-crud :option="option" :data="data" v-model="form" @selection-change="selectionChange" ref="crud" :top="'8vh'" @on-load="onLoad">
            </avue-crud>
            <div style="padding: 0px 15px 20px">
                <el-button class="pull-right" @click="closeDia" size="mini" type="defaut">取消
                </el-button>
                <el-button class="pull-right mar-r-10" @click="sure_yq_card()" size="mini" type="primary">确 认</el-button>
            </div>
        </div>
    </basic-container>
</template>

<script>
import { queryYearCard, saveOverDate } from '@/api/spcod/spcodaccountcard';
export default {
    ///vipNo
    data() {
        return {
            form: {},
            data: [],
            card_yq_num: 1,
            selectionList: [],
            option: {
                height: '350px',
                calcHeight: 30,
                selection: true,
                tip: false,
                searchShow: false,
                border: true,
                addBtn: false,
                delBtn: false,
                editBtn: false,
                viewBtn: false,
                menu: false,
                column: [
                    {
                        label: '卡编号',
                        prop: 'cardNo',
                    },
                    {
                        label: '卡名称',
                        prop: 'cardName',
                    },
                    {
                        label: '延期前开始时间',
                        prop: 'effectFrm',
                    },
                    {
                        label: '延期前结束时间',
                        prop: 'endDay',
                    },
                    {
                        label: '延期后结束时间',
                        prop: 'yq_endDay',
                    },
                ],
            },
        };
    },
    props: {
        vipNoList: {
            type: Array,
            default: function () {
                return [];
            },
        },
    },
    watch: {
        vipNoList: {
            handler: function (newValue, oldName) {
                if (newValue) {
                    console.log('jieshouujieshoudsafdjs');
                    this.onLoad();
                }
            },
            deep: true,
        },
    },
    computed: {},
    methods: {
        selectionChange(list) {
            this.selectionList = list;
        },
        closeDia() {
            this.$emit('closeDia');
        },
        sure_yq_card() {
            if (this.selectionList.length == 0) {
                this.$message.warning('请选中至少一个会员卡');
            } else {
                let selectionList = [];
                this.selectionList.forEach(e => {
                    selectionList.push(e.id);
                });
                let obj = {
                    ids: selectionList.toString(),
                    overDay: this.card_yq_num,
                };
                saveOverDate(obj).then(res => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '延期成功!',
                        });
                        this.$emit('closeDia');
                        this.card_yq_num = 1;
                    }
                });
            }
        },
        changNum() {
            if (this.selectionList.length == 0) {
                this.$message({
                    type: 'error',
                    message: '请选择需要延期的卡!',
                });
            }
            this.selectionList.forEach(s => {
                this.data.forEach(e => {
                    if (s.id == e.id) {
                        if (e.endDay) {
                            e.yq_endDay = dayjs(e.endDay).add(this.card_yq_num, 'day').format('YYYY-MM-DD');
                        }
                    }
                });
            });

            this.$forceUpdate();
        },
        onLoad() {
            queryYearCard(this.vipNoList).then(res => {
                res.data.forEach(e => {
                    if (e.endDay) {
                        e.yq_endDay = e.endDay;
                    }
                });
                this.data = res.data;
            });
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
/deep/ .avue-crud__menu {
    display: none !important;
}
</style>

<template>
  <div style="display: contents">
    <el-dropdown>
      <el-button
        class="mar-l-10 designable-btn-dash"
        size="small"
        split-button
        icon="el-icon-setting"
        type="warning"
        plain
        >配置操作</el-button
      >
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item>启用操作</el-dropdown-item>
        <template v-for="(menu, index) in menuLeftList">
          <el-dropdown-item :key="index">
            {{ menu.label }}
            <el-switch v-model="menu.show" class="mar-l-10"></el-switch>
          </el-dropdown-item>
        </template>
        <el-dropdown-item divided>
          <span @click="btbDO('btnAdd')">新增操作</span>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <el-dialog
      title="编辑"
      width="30%"
      append-to-body
      :visible.sync="btnEditVisible"
    >
      <template v-if="btnEditObj != 'btnAdd'">
        <template v-for="(m, i) in menuLeftList">
          <el-form :key="i">
            <el-form-item label="显示名称">
              <el-input v-model="m.label" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="图标">
              <el-col :span="24">
                <el-button-group>
                  <el-button size="small" :icon="m.icon"></el-button>
                  <el-button size="small" icon="el-icon-close"></el-button>
                </el-button-group>
              </el-col>
            </el-form-item>
          </el-form>
        </template>
      </template>
      <el-form v-else>
        <el-form-item label="显示名称">
          <el-input v-model="newBtn.label" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="图标">
          <el-col :span="24">
            <el-button-group>
              <el-button size="small" :icon="newBtn.icon"></el-button>
              <el-button size="small" icon="el-icon-close"></el-button>
            </el-button-group>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" @click="btnEditVisible = false"
          >取 消</el-button
        >
        <el-button
          type="primary"
          size="small"
          @click="
            saveBtn();
            btnEditVisible = false;
          "
          >确 定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  menuLeftList,
  menuList,
} from "../../../../../../components/dynamic/table/options";
export default {
  name: "designBtn",
  watch: {},
  props: {},
  data() {
    return {
      menuLeftList: menuLeftList,
      menuList: menuList,
      btnEditVisible: false,
      btnEditObj: "",
      newBtn: {},
    };
  },

  created() {},
  mounted() {},

  methods: {
    operations(type, row, index, done) {
      // switch (type) {
      //   case "add":
      //     this.preViewDia("add");
      //     break;
      //   case "edit":
      //     this.rowUpdate(row, index, done);
      //     break;
      // }
    },
    btbDO(btn) {
      this.menuLeftList.forEach((e) => {
        if (btn.includes("btnEdit")) {
          this.btnEditVisible = true;
          this.btnEditObj = btn.split("btnEdit")[1];
        } else if (btn.includes("btnAdd")) {
          this.btnEditVisible = true;
          this.btnEditObj = "btnAdd";
        } else {
          if (e.value == btn) {
            // e.show = false;
          }
        }
      });
      console.log(menuLeftList);
      this.menuLeftList = JSON.parse(JSON.stringify(this.menuLeftList));
    },
  },
};
</script>

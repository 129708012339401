<template>
  <el-row class="mar-0">
    <el-col :span="24">
      <el-form
        v-if="operFormCustom"
        size="small"
        :v-model="operForm"
        label-position="left"
        label-width="80px"
      >
        <el-form-item label="操作名称">
          <el-input
            style="width: 185px"
            v-model="operForm.operName"
            placeholder="操作名称"
          ></el-input>
        </el-form-item>
        <el-form-item label="操作key">
          <el-input
            style="width: 185px"
            v-model="operForm.phoneHH"
            placeholder="操作Key"
          ></el-input>
        </el-form-item>
        <el-form-item label="适用场景">
          <el-radio-group v-model="operForm.appl">
            <el-radio label="new">新建按钮</el-radio>
            <el-radio label="row">字段操作</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="字段" v-if="operForm.appl == 'row'">
          <el-select v-model="operForm.field" @change="updateFiled()">
            <el-option
              v-for="table in tableOption.column"
              :key="table.prop"
              :label="table.label"
              :value="table.prop"
            />
          </el-select>
        </el-form-item>
      </el-form>
    </el-col>
    <el-col :span="24" class="text-center">
      <el-button type="primary" size="small" @click="saveOperBtn()"
        >确 定</el-button
      >
      <el-button size="small" @click="btnEditVisible = false">取 消</el-button>
    </el-col>
  </el-row>
</template>

<script>
import MonacoEditor from "@/components/dynamic/form/utils/monaco-editor";
export default {
  name: "designBtn",
  watch: {},
  components: { MonacoEditor },
  props: {
    tableOption: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {
      change: {},
      tableDataOption: {},
      dicQueryConfig: {
        column: [
          { type: "input", prop: "field", label: "key", cell: true },
          {
            type: "input",
            prop: "value",
            label: "value",
            cell: true,
          },
        ],
      },
      options: {
        minimap: {
          enabled: false,
        },
      },
      operForm: {
        appl: "row",
        field: "",
        // click: "({value}) => {\r\n\r\n}",
        click: "\r\n\r\n",
      },
      operFormCustom: false,
      operList: [],
      operBtnList: [],
    };
  },

  created() {},
  mounted() {
    this.tableDataOption = {
      column: [
        {
          type: "select",
          prop: "key",
          label: "操作",
          cell: true,
          dicData: this.operBtnList,
          props: {
            label: "operName",
            value: "key",
          },
        },
        {
          type: "select",
          prop: "appl",
          label: "类型",
          cell: true,
          dicData: [
            { label: "行内", value: "row" },
            { label: "新增按钮", value: "new" },
          ],
          props: {
            label: "label",
            value: "value",
          },
        },
        {
          type: "select",
          prop: "field",
          label: "字段",
          cell: true,
          dicData: this.tableOption.column,
          props: {
            label: "label",
            value: "prop",
          },
        },
      ],
    };
  },

  methods: {
    updateFiled() {},
    newROper() {
      this.operForm = {
        appl: "row",
        field: "",
        click: "({value}) => {\r\n\r\n}",
      };
      this.operBtnList = operBtns;
      this.operFormCustom = true;
    },
    closeROper() {
      this.operList.push(this.operForm);
      this.operBtnList.push(this.operForm);
      this.operFormCustom = false;
    },
    saveOperBtn() {
      this.$emit("tableOperBtn", this.operList);
      //this.operFormCustom = false;
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped></style>

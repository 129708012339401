<template>
  <div v-loading="showMeta">
    <div>
      <h3 class="metadata-title">
        {{entityAttr.name}}{{'('+entity.typeName + ')'}}
      </h3>
    </div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="技术属性" name="property" style="line-height: 20px;">
        <!--<h4>技术属性</h4>-->
        <div style="position: absolute;right:10px;z-index: 1;" v-if="showOperation">
          <button type="text" class="el-button el-button--text el-button--small" icon="el-icon-thumb" size="small">
            <!----><i class="el-icon-thumb"></i><span>确认</span>
          </button>
          <!----><!----><!----><!----><!---->
          <button @click="editMeta" type="text" class="el-button el-button--text el-button--small" icon="el-icon-edit" size="small">
            <!----><i class="el-icon-edit"></i><span>编辑</span>
          </button>
          <button @click="deleteMeta" type="text" class="el-button el-button--text el-button--small" icon="el-icon-delete" size="small">
            <!----><i class="el-icon-delete"></i><span>删除</span>
          </button>
        </div>
       <div style="height: calc(100vh - 284px);overflow: auto;">
         <el-row v-for="(item,index) in dyAttrs" :key="index">
           <el-col :span="3">
             {{item.label}}
           </el-col>
           <el-col :span="8">{{item.value}}</el-col>
         </el-row>
         <el-row>
           <el-col :span="3">
             标签
           </el-col>
           <el-col :span="21" class="property-tags">
             <el-tag
               size="mini"
               v-for="(tag,index) in tags"
               :key="tag.name"
               @close="deleteTag(tag,index)"
               :type="tag.type">
               {{tag.name}}
             </el-tag>
             <!--<i class="el-icon-edit operation mar-l-10" @click="addTag"></i>-->
           </el-col>
         </el-row>
         <el-row>
           <el-col :span="3">
             分类
           </el-col>
           <el-col :span="21" class="property-tags">
             <el-tag
               size="mini"
               v-for="(tag,index) in classes"
               :key="tag.name"
               @close="deleteTag(tag,index)"
               :type="tag.type">
               {{tag.name}}
             </el-tag>
             <!--<i class="el-icon-edit operation mar-l-10" @click="addClasses"></i>-->
           </el-col>
         </el-row>
         <el-row>
           <el-col :span="3">
             分级
           </el-col>
           <el-col :span="21" class="property-tags">
             <el-tag
               size="mini"
               v-for="(tag,index) in levels"
               :key="tag.name"
               @close="deleteTag(tag,index)"
               :type="tag.type">
               {{tag.name}}
             </el-tag>
             <!--<i class="el-icon-edit operation mar-l-10" @click="addLevel"></i>-->
           </el-col>
         </el-row>
       </div>

      </el-tab-pane>
      <el-tab-pane label="关系" name="relation">
        <el-row v-for="(item,index) in relations" :key="index" style="line-height: 20px">
          <el-col :span="4">
            {{item.label}}
          </el-col>
          <el-col :span="8">{{item.value}}</el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane label="分类" name="classfication">
        <el-table
          :data="classifications"
          style="width: 100%">
          <el-table-column
            label="分类"
            prop="typeName">
          </el-table-column>
          <el-table-column
            label="属性">
            <template slot-scope="scope">
              <div class="w-100p" v-if="scope.row.attr.length > 0">
                <div>
                  <div class="col">名称</div>
                  <div class="col">值</div>
                </div>
                <div v-for="(item,index) in scope.row.attr">
                  <div class="col">{{item.label}}</div>
                  <div class="col">{{item.value}}</div>
                </div>
              </div>
              <span v-if="scope.row.attr.length === 0">
                  NA
                </span>
            </template>
          </el-table-column>
          <!--<el-table-column label="操作">-->
          <!--<template slot-scope="scope">-->
          <!--<el-tooltip :content="$t('Edit')" placement="top" :enterable="false">-->
          <!--<span><el-button type="primary" size="mini" icon="el-icon-edit-outline" @click="handleEdit(scope.row)" circle></el-button></span>-->
          <!--</el-tooltip>-->
          <!--<el-tooltip :content="$t('Delete')" placement="top" :enterable="false">-->
          <!--<el-popconfirm-->
          <!--:confirmButtonText="$t('Confirm')"-->
          <!--:cancelButtonText="$t('Cancel')"-->
          <!--icon="el-icon-info"-->
          <!--iconColor="red"-->
          <!--:metadata-title="$t('Delete?')"-->
          <!--@confirm="handleDelete(scope.row,scope.row.id)"-->
          <!--&gt;-->
          <!--<el-button class="mar-l-10" type="danger" size="mini" icon="el-icon-delete" circle slot="reference"></el-button>-->
          <!--</el-popconfirm>-->
          <!--</el-tooltip>-->
          <!--</template>-->
          <!--</el-table-column>-->
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="审计" name="audit">
        <el-table
          :data="audits"
          style="width: 100%">
          <el-table-column
            prop="user"
            label="用户"
            width="180">
          </el-table-column>
          <el-table-column
            label="创建时间"
            width="180">
            <template slot-scope="scope">
              {{transformDate(scope.row.timestamp)}}
            </template>
          </el-table-column>
          <el-table-column
            prop="action"
            label="操作">
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="血缘关系" name="graph">
        <div style="height: calc(100vh - 280px)">
          <mGraph :guid="guid" v-if="showGraph"></mGraph>
        </div>
      </el-tab-pane>
      <el-tab-pane label="历史版本" name="version">
        <avue-crud
          ref="version"
          :data="versionList"
          :option="versionOption"
          @refresh-change="getVersions"
        >
        </avue-crud>
      </el-tab-pane>
      <el-tab-pane v-if="showFields" label="字段" name="field">
        <el-table
          :data="fields"
          style="width: 100%">
          <el-table-column
            label="字段名"
            width="180">
            <template slot-scope="scope">
              <a @click="toColumn(scope.row)">{{scope.row.name}}</a>
            </template>
          </el-table-column>
          <el-table-column
            prop="comment"
            label="描述"
            width="180">
          </el-table-column>
          <el-table-column
            label="创建时间">
            <template slot-scope="scope">
              {{transformDate(scope.row.time)}}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
  import {
    getVersionList
  } from '@/api/metadata/model';
  import { getList } from '@/api/metadata/grade';
  import { getLabelTree} from '@/api/metadata/label';
  import { listLabeltype } from '@/api/metadata/labeltype';
  import { getMetadataDetail, getAudits} from '@/api/metadata/metadata';
  import mGraph from '../metadata/graph';
  export default {
    name:'meta-info',
    components: { mGraph },
    props:{
      guid: {
        type: String
      },
      showOperation:{
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        showMeta:false,
        showGraph:false,
        activeName: 'property',
        entityAttr:{
          'name':'',
          'owner':'',
          'description':''
        },
        dyAttrs:[],
        entity:{
          'createTime':'',
          'updateTime':'',
          'typeName':''
        },
        tags: [
        ],
        levels: [
        ],
        classes: [
        ],
        classifications:[],
        audits:[],
        relations:[],
        showFields:false,
        tagFormVisible:false,
        classesFormVisible:false,
        levelFormVisible:false,
        tagForm:{
          tag:''
        },
        tagOption:{
          submitBtn:false,
          emptyBtn:false,
          column: [
            {
              label:'标签',
              prop:'typeName',
              type:'select',
              dicData: [],
              rules: [{
                required: true,
                message: "标签",
                trigger: "change"
              }]
            },
          ]
        },
        levelForm:{

        },
        levelOption:{
          submitBtn:false,
          emptyBtn:false,
          column: [
            {
              label:'分级',
              prop:'typeName',
              type:'select',
              dicData: [],
              rules: [{
                required: true,
                message: "请选择分级",
                trigger: "change"
              }]
            },
          ]
        },
        classesForm:{},
        classesOption:{
          submitBtn:false,
          emptyBtn:false,
          column: [
            {
              label:'分类',
              prop:'typeName',
              type:'select',
              dicData: [],
              rules: [{
                required: true,
                message: "请选择分类",
                trigger: "change"
              }]
            },
          ]
        },
        versionList:[],
        versionOption:{
          header:false,
          menu:false,
          column:[
            {
              label:'版本',
              prop:'version',
              display:false
            },
            {
              label:'发布时间',
              prop:'createTime',
              display:false
            },
            {
              label:'发布人',
              prop:'publisher',
              display:false
            }
          ]
        },
        grades:[],
        labels:[],
        metaclasses:[]
      }
    },
    watch: {
      'guid'() {
        this.getMetadataDetail(this.guid);
        this.getMetaAudits(this.guid);
      }
    },
    mounted() {
      this.getMetadataDetail(this.guid);
      this.getMetaAudits(this.guid);
    },
    methods: {
      editMeta() {
        this.$emit('edit-meta',this.entity)
      },
      deleteMeta() {
        this.$emit('delete-meta',this.entity)
      },
      getGradeList() {
        getList().then(res => {
          if(res['data']['code'] === 200 ) {
            this.grades = res['data']['data']['records'];
            if(!!this.entity.customAttributes){
              let gradeId = this.entity.customAttributes.grade;
              this.levels = this.grades.filter(item => {
                return item.id === gradeId;
              })
            }
          }
        });
      },
      getLabelList() {
        getLabelTree().then(res => {
          this.labels = [];
          this.translateTreeData(res['data']['children'] || [])
          let labels = this.entity['labels'];
          let temp = [];
          for(let i = 0,len = this.labels.length ; i < len; i ++){
            let id = this.labels[i]['id'];
            if(labels.indexOf(id) > -1){
              temp.push(this.labels[i])
            }
          }
          this.tags = temp;
        });
      },
      translateTreeData(treeData){
        let newTreeData = [];
        treeData.forEach(
          item => {
            let el = {
              ...item.label
            }
            this.labels.push(el);
            if(item.children && item.children.length){
              el['children'] = this.translateTreeData(item['children']);
            }
            newTreeData.push(el);
          }
        )
        return newTreeData;
      },
      getClassfication() {
        listLabeltype().then(res => {
          if(res['data']['code'] === 200){
            this.metaclasses = res['data']['data']['records'];
            if(!!this.entity.customAttributes){
              let classesId = this.entity.customAttributes.classification;
              this.classes = this.metaclasses.filter(item => {
                return item.id === classesId;
              })
            }
          }
        });
      },
      transformDate(time){
        if(!time) return '-';
        let date = new Date(time);
        let year = date.getFullYear();
        let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1):(date.getMonth() + 1) ;
        let day = date.getDay() < 10 ? '0' + date.getDay() : date.getDay();
        let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
        let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        let seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      },
      getMetadataDetail(id) {
        this.showMeta = true;
        getMetadataDetail(id).then(res => {
          console.log(res)
          if(res['status'] === 200 ) {
            this.entity = res['data']['entity'];
            this.entityAttr = res['data']['entity']['attributes'];
            this.classifications = res['data']['entity']['classifications'] || [];
            this.classifications.forEach((item) => {
              item['attr'] = [];
              if(item['attributes']){
                let labels = Object.keys(item['attributes']);
                labels.forEach((label) => {
                  item['attr'].push({
                    label:label,
                    value:item['attributes'][label] || '-'
                  })
                });
              }
            });
            let attrs = Object.keys(this.entityAttr);
            let tempAttrs = [];
            for(let i = 0,len = attrs.length; i < len; i ++ ){
              if(!!this.entityAttr[attrs[i]]){
                tempAttrs.push({
                  'label':attrs[i],
                  'value':this.entityAttr[attrs[i]]
                })
              }
            }
            this.dyAttrs = tempAttrs.concat([]);
            let relation = res['data']['entity']['relationshipAttributes'];
            let relationAttr = Object.keys(relation);
            let temp = [];
            relationAttr.forEach(item => {
              if(relation[item]){
                if(Object.prototype.toString.call(relation[item]) === '[object Array]'){
                  let column = [];
                  relation[item].forEach(et => {
                    column.push(et['displayText']);
                  });
                  if(column.length > 0 ){
                    temp.push({
                      'label':item,
                      'value':column.join(',')
                    })
                  }
                } else {
                  temp.push({
                    'label':item,
                    'value':relation[item]['displayText']
                  })
                }
              }

            });
            this.relations = temp.concat([]);
            let referredEntities = res['data']['referredEntities'];
            let referKeys = Object.keys(referredEntities);
            let tempFields = [];
            referKeys.forEach(item => {

              if(referredEntities[item]['typeName'] === 'Column'){
                tempFields.push({
                  'guid':referredEntities[item]['guid'],
                  'name':referredEntities[item]['attributes']['name'],
                  'comment':referredEntities[item]['attributes']['comment'],
                  'time':referredEntities[item]['createTime']
                })
              }
            });
            this.fields = tempFields.concat([]);
            this.getGradeList();
            this.getClassfication();
            this.getLabelList();
          }
          this.showMeta = false;
        });
      },
      getMetaAudits(id){
        getAudits(id).then(res => {
          if(res['status'] === 200 ) {
            this.audits = res['data'];
          }
        });
      },
      handleClick(tab) {
        if(tab['name'] === 'graph'){
          this.showGraph = true;
        }
      },
      addTag() {
        this.tagFormVisible = true;
      },
      confirmAddTag() {
        this.tagFormVisible = false;
        this.tagForm.tag = '';
      },
      tagCancel() {
        this.tagFormVisible = false;
        this.tagForm.tag = '';
      },
      deleteTag(tag,index) {
        this.tags.splice(index,1)
      },
      toColumn(item) {
        this.showFields = false;
        let guid = item['guid'];
        this.getMetadataDetail(guid);
        this.getMetaAudits(guid);
        this.activeName = 'property';
      },
      getVersions(id) {
        let obj = {
          'metadataId':id
        };
        getVersionList(obj).then( res => {
          if(res['data']['code'] === 200){
            this.versionList = res['data']['data']['records'];
          }
        });
      },
      addClasses() {
        this.classesFormVisible = true;
      },
      addLevel() {
        this.levelFormVisible = true;
      },
    }
  }
</script>
<style>
  .property-tags .el-tag+.el-tag {
    margin-left: 10px;
  }
</style>

<template>
  <div>
    <div v-show="editWidget === false" class="data-view-dashboard-component">
      <div class="data-view-dashboard-component-header">
        <div class="right-container">
          <template v-if="isEdit">
            <el-button
              type="text"
              icon="el-icon-notebook-2"
              :size="config.size"
              @click="dashVarDrawer = true"
              >变量管理</el-button
            >
            <el-button
              type="text"
              icon="el-icon-setting"
              :size="config.size"
              @click="dashSettingDrawer = true"
              >视图配置</el-button
            >
            <el-button
              type="text"
              icon="el-icon-plus"
              :size="config.size"
              @click="addWidget"
              >添加部件</el-button
            >
            <el-button
              type="text"
              icon="el-icon-finished"
              :size="config.size"
              @click="saveDash"
              >保存</el-button
            >
          </template>
          <template v-if="!isEdit">
            <el-button
              type="text"
              icon="el-icon-edit-outline"
              :size="config.size"
              @click="isEdit = true"
              >编辑</el-button
            >
          </template>
        </div>
      </div>
      <div class="data-view-dashboard-component-main">
        <grid-layout
          v-bind="isEdit ? dashJson.props : viewProps"
          :layout.sync="dashJson.widgets"
          @layout-ready="layoutReadyEvent"
        >
          <grid-item
            v-for="(item, index) of dashJson.widgets"
            :x="item.x"
            :y="item.y"
            :w="item.w"
            :h="item.h"
            :i="item.i"
            :key="item.i"
            @resize="resizeEvent(item)"
          >
            <el-popover
              trigger="hover"
              placement="top-end"
              popper-class="el-popper-min"
              :visible-arrow="false"
              v-if="layoutReady"
            >
              <DataViewWidgetContainer
                :ref="item.i"
                slot="reference"
                :widget="item"
                :dash="dashJson"
              ></DataViewWidgetContainer>
              <div v-if="isEdit">
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="编辑"
                  placement="top-start"
                >
                  <el-link :underline="false" @click="toEditWidget(item)"
                    ><i class="el-icon-edit"></i
                  ></el-link>
                </el-tooltip>
                <el-divider class="mar-lr-10" direction="vertical"></el-divider>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="关联和跳转"
                  placement="top-start"
                >
                  <el-link :underline="false" @click="linkAndJump(item)"
                    ><i class="el-icon-link"></i
                  ></el-link>
                </el-tooltip>
                <el-divider class="mar-lr-10" direction="vertical"></el-divider>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="删除"
                  placement="top-start"
                >
                  <el-link
                    type="danger"
                    :underline="false"
                    @click="dashJson.widgets.splice(index, 1)"
                    ><i class="el-icon-delete"></i
                  ></el-link>
                </el-tooltip>
              </div>
            </el-popover>
            <!-- <DataViewWidgetContainer v-if="!isEdit" :ref="item.i" slot="reference" :widget="item"></DataViewWidgetContainer> -->
          </grid-item>
        </grid-layout>
      </div>
    </div>
    <DataViewWidgetDesign
      v-if="editWidget"
      :widget="currentWidget"
      :dash="dashJson"
      @close="editWidgetClose"
    ></DataViewWidgetDesign>
    <el-drawer
      title="视图配置"
      append-to-body
      :visible.sync="dashSettingDrawer"
    >
      <div class="pad-lr-15" v-if="dashJson">
        <el-form :size="config.size" :label-width="config.labelWidth">
          <el-form-item label="可拖拽">
            <el-switch v-model="dashJson.props.isDraggable"></el-switch>
          </el-form-item>
          <el-form-item label="可调整大小">
            <el-switch v-model="dashJson.props.isResizable"></el-switch>
          </el-form-item>
          <el-form-item label="防止碰撞">
            <el-switch v-model="dashJson.props.preventCollision"></el-switch>
          </el-form-item>
          <el-form-item label="响应式布局">
            <el-switch v-model="dashJson.props.responsive"></el-switch>
          </el-form-item>
          <el-form-item label="水平边距">
            <el-input-number
              v-model="dashJson.props.margin[0]"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
          <el-form-item label="垂直边距">
            <el-input-number
              v-model="dashJson.props.margin[1]"
              controls-position="right"
            ></el-input-number>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
    <el-drawer title="变量管理" append-to-body :visible.sync="dashVarDrawer">
      <div class="pad-lr-10" v-if="dashJson">
        <div class="mar-b-10">
          <el-button
            :size="config.size"
            icon="el-icon-plus"
            @click="
              dashJson.variables.push({ name: '', value: '', type: 'dash' })
            "
            >添加变量</el-button
          >
          <el-tooltip class="item" effect="dark" placement="top">
            <pre slot="content">
可以在输入框内使用${变量编码}使用变量值，如:${name}</pre
            >
            <i class="mar-l-5 el-icon-info"></i>
          </el-tooltip>
        </div>
        <el-table :data="dashJson.variables" border style="width: 100%">
          <el-table-column prop="name" label="变量编码">
            <template slot-scope="scope">
              <el-input
                :size="config.size"
                type="text"
                v-model="scope.row.name"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="value" label="变量值">
            <template slot-scope="scope">
              <el-input
                :size="config.size"
                type="text"
                v-model="scope.row.value"
              ></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                :size="config.size"
                icon="el-icon-delete"
                type="text"
                @click="handleDeleteVar(scope.$index, scope.row)"
                >删除</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
    <el-dialog
      title="关联和跳转"
      :visible.sync="linkAndJumpDialogVisible"
      width="50%"
      append-to-body
    >
      <span slot="footer" class="dialog-footer">
        <!-- <el-button size="small" @click="linkAndJumpDialogVisible = false">取 消</el-button> -->
        <el-button
          size="small"
          type="primary"
          @click="linkAndJumpDialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import VueGridLayout from "vue-grid-layout";

import { DataViewWidget } from "../meta/widget";
import DataViewWidgetDesign from "./widget-design/index.vue";
import DataViewWidgetContainer from "./widget-container.vue";

export default {
  name: "data-view-dashboard-component",
  inject: ["config"],
  components: {
    GridLayout: VueGridLayout.GridLayout,
    GridItem: VueGridLayout.GridItem,
    DataViewWidgetDesign,
    DataViewWidgetContainer,
  },
  data() {
    return {
      editWidget: false,
      currentWidget: null,
      dashSettingDrawer: false,
      dashVarDrawer: false,
      linkAndJumpDialogVisible: false,
      isEdit: true,
      layoutReady: false,
    };
  },
  computed: {
    viewProps() {
      return Object.assign({}, this.dashJson.props, {
        isDraggable: false,
        isResizable: false,
        isMirrored: false,
      });
    },
  },
  props: {
    dashJson: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  mounted() {
    console.log(this.dashJson);
  },
  methods: {
    handleDeleteVar(index) {
      this.dashJson.variables.splice(index, 1);
    },
    linkAndJump(item) {
      this.linkAndJumpDialogVisible = true;
    },
    addWidget() {
      let widget = new DataViewWidget();
      this.dashJson.widgets.push(widget);
      this.currentWidget =this.dashJson.widgets[this.dashJson.widgets.length - 1];
      this.editWidget = true;
    },
    toEditWidget(widget) {
      this.currentWidget = widget;
      this.editWidget = true;
      console.log(this.currentWidget);
    },
    resizeEvent(item) {
      // console.log(item);
    },
    editWidgetClose() {
      this.editWidget = false;
      if (this.$refs[this.currentWidget.i]) {
        if (Array.isArray(this.$refs[this.currentWidget.i])) {
          this.$refs[this.currentWidget.i].forEach((item) => {
            item.init && item.init();
          });
        }
      }
      this.currentWidget = null;
    },
    saveDash() {
      this.$emit("save", this.dashJson);
    },
    layoutReadyEvent() {
      this.layoutReady = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.data-view-dashboard-component {
  .data-view-dashboard-component-header {
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid #ddd;
    // background: #fff;
    padding: 0 10px;
    > .right-container {
      float: right;
    }
  }
  .data-view-dashboard-component-main {
    position: relative;
  }
}
/deep/.vue-grid-item.vue-grid-placeholder {
  background: #99a9bf !important;
}
</style>
<style lang="scss">
.el-popper-min {
  min-width: auto;
}
</style>

<template>
    <div>
        <el-card class="card">
            <el-col :span="3" class="fts-16 mar-t-5f"><span><i class="el-icon-back cur-p" @click="back"></i></span>
                {{ buildName }}
            </el-col>
            <el-col :span="21" class="block">
                <div class="demo-input-suffix mar-t-5f">
                    <span class="pad-l-20 mar-r-5">装修情况</span>
                    <el-select v-model="formInline.renovation" size="mini" style="width: 100px" clearable placeholder="请选择">
                        <el-option v-for="item in decorationList" :key="item.value" :label="item.name" :value="item.value">
                        </el-option>
                    </el-select>
                    <span class="pad-l-20 mar-r-5">房间状态</span>
                    <el-select style="width: 100px" v-model="formInline.status" size="mini" clearable placeholder="请选择">
                        <el-option v-for="item in roomStatusList" :key="item.value" :label="item.name" :value="item.value">
                        </el-option>
                    </el-select>
                    <span class="pad-l-20">面积</span>
                    <el-input placeholder="面积" size="mini" class="configInput" v-model="formInline.room_area">
                    </el-input>-
                    <el-input placeholder="面积" size="mini" class="configInput" v-model="formInline.room_area1">
                    </el-input>
                    <span class="pad-l-20">出租单价</span>
                    <el-input placeholder="" size="mini" class="configInput" v-model="formInline.rental_unit_price_day">
                    </el-input>-
                    <el-input placeholder="" size="mini" class="configInput" v-model="formInline.rental_unit_price_day1">
                    </el-input>
                    <span class="pad-l-20">房间号</span>
                    <el-input placeholder="" size="mini" class="configInput" clearable v-model="formInline.room_name">
                    </el-input>
                    <el-button class="pull-right mar-l-5" @click="formInline = {}" size="mini">重置</el-button>
                    <el-button size="mini" class="pull-right" type="primary" @click="search" :loading="btnLoding">搜索</el-button>
                </div>
            </el-col>
        </el-card>
        <el-card class="mar-t-15" v-if="spaces.length > 0">
            <el-row class="mar-b-10 pad-b-10" :class="{ 'b-b': spaces.length != 1 }" v-for="(space, i) in spaces" :key="i">
                <el-col :span="2">
                    <span class="spaceCard text-center pad-l-10 pad-r-10">
                        <p class="fts-18">
                            <i class="el-icon-edit cur-p fts-12" @click="openFloorPicDialogVisible(space.floor)"></i>
                            {{ space.floor.code }}层
                            <small class="fts-12">
                                <span class="text" v-if="space.floor.above_under == 'above'">地上</span>
                                <span class="text" v-if="space.floor.above_under == 'under'">地下</span>
                            </small>

                        </p>

                        <p class="mar-t-10f">{{ space.floor.floor_area }}平方米</p>
                        <template v-if="website.permission">
                            <el-button icon="el-icon-plus" class="addbtn fts-12" v-if="permission.buildList_add_room" @click="addRoom(space.floor, i)">新增房间
                            </el-button>
                        </template>
                        <template v-else>
                            <el-button icon="el-icon-plus" class="addbtn fts-12" @click="addRoom(space.floor, i)">新增房间
                            </el-button>
                        </template>
                    </span>
                </el-col>
                <el-col :span="22">
                    <div class="roomClazz">
                        <template v-for="(room, k) in space.rooms">
                            <span class="spaceCard pad-l-10 pad-r-10 w-150 cardBg mar-r-10 cur-p p-r" :key="k" @click="getDetail(room, i)">
                                <span class="dropclazz" @click.stop>
                                    <el-dropdown trigger="click">
                                        <span class="el-dropdown-link">
                                            <i class="el-icon-more el-icon--right"></i>
                                        </span>
                                        <el-dropdown-menu slot="dropdown">
                                            <template v-if="website.permission">
                                                <el-dropdown-item icon="el-icon-document-copy" v-if="permission.buildList_room_split" @click.native="btbDO('splitRoom', room)">房间拆分</el-dropdown-item>
                                                <!-- <el-dropdown-item
                          icon="el-icon-delete"
                          v-if="permission.buildList_room_delete"
                          @click.native="btbDO('rowDel', room)"
                          >删除</el-dropdown-item
                        > -->
                                                <el-dropdown-item icon="el-icon-delete" :disabled="room.son_num>0" v-if="permission.buildList_room_delete"
                                                                  @click.native="btbDO('is_cancel', room)">删除</el-dropdown-item>
                                            </template>
                                            <template v-else>
                                                <el-dropdown-item icon="el-icon-document-copy" @click.native="btbDO('splitRoom', room)">房间拆分</el-dropdown-item>
                                                <!-- <el-dropdown-item icon="el-icon-delete" @click.native="btbDO('rowDel', room)">删除</el-dropdown-item> -->
                                                <el-dropdown-item icon="el-icon-delete" :disabled="room.son_num>0" @click.native="btbDO('is_cancel', room)">作废</el-dropdown-item>
                                            </template>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </span>

                                <el-tooltip :content="room.room_name" placement="top">
                                    <div class="fts-16 mar-t-15 mar-b-10 titleclazz">
                                        {{ room.room_name }}
                                    </div>
                                </el-tooltip>
                                <el-tooltip v-if="room.company_name" :content="room.company_name" placement="top">

                                    <div class="fts-12">
                                        {{ room.room_area || "-" }} m²
                                        <small>{{ getRenovation(room.renovation) }}</small>
                                    </div>
                                </el-tooltip>
                                <div class="fts-12" v-else>
                                    {{ room.room_area || "-" }} m²
                                    <small>{{ getRenovation(room.renovation) }}</small>
                                </div>
                                <div class="fts-12 mar-t-15 mar-b-10">
                                    {{ room.rental_unit_price_day || "-" }} 元/平方·日
                                    <div class="sonroom" v-if="room.son_num > 0">
                                        子房间({{ room.son_num }})
                                    </div>
                                    <template v-for="item in roomStatusList">
                                        <span class="pull-right" :class="{
                        'cz-wz': room.status == 0,
                        'cz-yz': room.status == 1,
                        'cz-ys': room.status == 2,
                        'cz-zy': room.status == 3,
                      }" v-if="room.status == item.value">{{ item.name }}</span>
                                    </template>
                                </div>
                            </span>
                        </template>
                    </div>
                </el-col>
            </el-row>
        </el-card>
        <el-dialog :title="roomTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" destroy-on-close :top="'8vh'" width="60%" @close="handleCloseDialog">
            <roomInfo v-if="dialogVisible" ref="roomInfo" :onlyRead="onlyRead" :floors="floors" :ruleFormObj="ruleForm"></roomInfo>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" v-if="!onlyRead" @click="saveMetaCommon">确 定</el-button>
                <template v-if="website.permission">
                    <el-button size="small" type="primary" v-if="onlyRead && permission.buildList_room_edit" @click="onlyRead = !onlyRead">编 辑</el-button>
                </template>
                <template v-else>
                    <el-button size="small" type="primary" v-if="onlyRead" @click="onlyRead = !onlyRead">编 辑</el-button>
                </template>
                <el-button size="small" @click="closeDias">取 消</el-button>
            </span>
        </el-dialog>
        <el-drawer :visible.sync="detailDia" size="40%" :withHeader="false" destroy-on-close>
            <el-header class="bor-b" height="50px">
                <el-page-header class="lh-50" @back="close" :content="title"></el-page-header>
            </el-header>
            <el-container style="height: calc(100% - 100px)" class="o-a">
                <splitRoom :ruleFormObj="ruleForm" :floors="floors" @close="close" ref="saveSplitRoom"></splitRoom>
            </el-container>
            <el-footer class="bor-t lh-49" height="50px">
                <div class="pull-right">
                    <template>
                        <el-button size="small" type="primary" @click="saveSplitRoom">保存</el-button>
                        <el-button size="small" type="" @click="close">取消</el-button>
                    </template>
                </div>
            </el-footer>
            <!-- <detail :currentRow="currentRow" @changName="changName"></detail> -->
        </el-drawer>
        <el-dialog title="楼层图片" :visible.sync="floorPicDialogVisible" :close-on-click-modal="false" destroy-on-close :top="'8vh'" width="60%">
            <avue-form :option="floorPicOption" v-model="imgform"></avue-form>

            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" @click="saveFloor">确 定</el-button>
                <el-button size="small" @click="closeFloorPicDia">取 消</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import roomInfo from './roomInfo';
import * as dictbizService from '@/api/system/dictbiz';
import splitRoom from './splitRoom';
import { mapGetters } from 'vuex';
import website from '@/config/website';

import {
    getMetaCommonList,
    delMetaCommon,
    saveMetaCommon,
    updateMetaCommon,
    getMetaCommonInfo,
    getPicListByIds,
} from '@/api/busiMode/metaCommon';

export default {
    components: { roomInfo, splitRoom },
    data() {
        return {
            formInline: {},
            detailDia: false,
            dialogVisible: false,
            buildId: '1574949165742473217',
            buildName: '',
            title: '',
            page: {
                size: 1000,
                currentPage: 1,
                total: 0,
            },
            model: 'wisdom_park.wp_room',
            view_model: 'wisdom_park.v_wp_room_stat',
            floorModel: 'wisdom_park.wp_floor',
            spaces: [],
            floors: [],
            ruleForm: { label: [] },
            currentIndex: '',
            onlyRead: false,
            roomTitle: '新增房间',
            decorationList: [],
            roomStatusList: [],
            sumCheckinArea: '',
            floorPicDialogVisible: false,
            btnLoding: false,
            floorPicOption: {
                labelWidth: 0,
                submitBtn: false,
                emptyBtn: false,
                column: [
                    {
                        label: '',
                        prop: 'imgUrl',
                        type: 'upload',
                        listType: 'picture-card',
                        loadText: '附件上传中，请稍等',
                        span: 24,
                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '只能上传jpg/png文件，且不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },
                ],
            },
            imgform: {
                imgUrl: [],
            },
            currentFloor: {},
        };
    },
    computed: {
        ...mapGetters(['permission']),
    },
    mounted() {
        this.buildId = this.$router.currentRoute.query.buildId;
        this.buildName = this.$router.currentRoute.query.buildName;
        this.onLoad();
        this.getDicList();
    },
    methods: {
        openFloorPicDialogVisible(item) {
            this.currentFloor = item;
            this.floorPicDialogVisible = true;
            if (item.floor_pics) {
                this.getpicList(item.floor_pics);
            }
        },
        closeFloorPicDia() {
            this.floorPicDialogVisible = false;
            this.imgform = { imgUrl: [] };
        },
        getpicList(ids) {
            getPicListByIds(ids).then(res => {
                if (res.data.code == 200) {
                    let arr = [];
                    res.data.data.forEach(pic => {
                        arr.push({
                            label: pic.id,
                            value: pic.link,
                        });
                    });
                    this.imgform.imgUrl = arr;
                    //this.$forceUpdate();
                }
            });
        },
        saveFloor() {
            if (this.imgform) {
                let imgList = [];
                this.imgform.imgUrl.forEach(e => {
                    if (e.label) {
                        imgList.push(e.label);
                    }
                });
                let ruleForm = {};
                ruleForm.floor_pics = imgList.toString();
                let o = {
                    update: ruleForm,
                    primaryKeyValue: this.currentFloor['id'],
                    primaryKey: 'id',
                };
                updateMetaCommon(this.floorModel, o).then(res => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        this.floorPicDialogVisible = false;
                    }
                });
            }
        },
        handleCloseDialog() {
            this.onLoad();
        },
        close() {
            this.detailDia = false;
            this.onLoad();
        },
        back() {
            history.go(-1);
        },
        getDicList() {
            dictbizService
                .getDictionary({
                    code: 'decoration',
                })
                .then(res => {
                    this.decorationList = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
            dictbizService
                .getDictionary({
                    code: 'RoomStatus',
                })
                .then(res => {
                    this.roomStatusList = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                            id: item['id'],
                        };
                    });
                });
        },
        saveSplitRoom() {
            this.$refs.saveSplitRoom.saveRooms();
        },
        getRenovation(r) {
            let str = '';
            this.decorationList.forEach(d => {
                if (d.value == r) {
                    str = d.name;
                }
            });
            return str;
        },
        addRoom(floor, i) {
            console.log(floor);
            this.ruleForm = { label: [] };
            this.currentIndex = i;
            this.ruleForm.floor_id = floor.id;
            this.ruleForm.label = [];
            this.ruleForm.has_ownership = 1;
            this.ruleForm.is_invented = 1;
            this.ruleForm.status = 0;
            this.ruleForm.building_id = this.buildId;
            this.onlyRead = false;
            this.roomTitle = '新增房间';
            this.dialogVisible = true;
            this.onFloorLoad(floor.id);
        },
        btbDO(type, index) {
            if (type == 'rowDel') {
                this.rowDel(index);
            }
            if (type == 'is_cancel') {
                this.cancle(index);
            }
            if (type == 'splitRoom') {
                this.splitRoom(index);
            }
        },
        cancle(room) {
            this.$confirm('是否作废?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                let r = {
                    is_cancel: 1,
                    id: room.room_id,
                };
                let o = {
                    update: JSON.parse(JSON.stringify(r)),
                    primaryKeyValue: room.room_id,
                    primaryKey: 'id',
                };
                updateMetaCommon(this.model, o).then(res => {
                    if (res.data.code == 200) {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        this.onLoad(this.page);
                    }
                });
            });
        },
        splitRoom(room) {
            this.title = room.name || room.room_name;
            this.detailDia = true;
            this.ruleForm = room;
        },
        rowDel(row) {
            if (row.room_id) {
                this.$confirm('是否删除?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                    .then(() => {
                        let pk = 'id';
                        delMetaCommon(this.model, { pk: pk, ids: [row.room_id] }).then(res => {
                            this.$message({
                                type: 'success',
                                message: '操作成功!',
                            });
                            this.onLoad(this.page);
                        });
                    })
                    .catch(() => {});
            }
        },
        //
        onFloorLoad(id) {
            console.log('ididiid');
            let params = {
                wheres: [
                    {
                        field: 'floor_id',
                        value: id,
                    },
                ],
            };
            getMetaCommonList('wisdom_park.v_wp_floor_stat', {
                current: 1,
                size: 500,
                ...params,
            }).then(res => {
                const data = res.data.data;
                this.sumCheckinArea = data.records[0].sum_free_area;
            });
        },
        saveMetaCommon() {
            let room = this.$refs.roomInfo.ruleForm;
            // console.log(this.sumCheckinArea);

            // 后期根据是否添加虚拟房间，有待商榷，故先注释
            // if (this.sumCheckinArea <= room.room_area) {
            //   this.$message.error("房间面积不能大于该楼层的空闲面积");
            //   return false;
            // }
            if (this.$refs.roomInfo.imgform) {
                let imgList = [];
                this.$refs.roomInfo.imgform.imgUrl.forEach(e => {
                    if (e.label) {
                        imgList.push(e.label);
                    }
                });
                room.attachment = imgList.toString();
            }
            if (this.$refs.roomInfo.imgform1) {
                let imgList = [];
                this.$refs.roomInfo.imgform1.imgUrl.forEach(e => {
                    if (e.label) {
                        imgList.push(e.label);
                    }
                });
                room.layout_attachment = imgList.toString();
            }
            if (this.ruleForm.housing_resources_type) {
                this.ruleForm.housing_resources_type = this.ruleForm.housing_resources_type.toString();
            }
            if (!room.id) {
                room.code = room.code != '' ? room.code : room.name;
                let floorModel = {
                    pk: 'id',
                    id: room.floor_id,
                };
                getMetaCommonInfo(this.floorModel, floorModel).then(res => {
                    if (res.data.code == 200) {
                        room.floor_name = res.data.data.name;
                        room.floor_id = res.data.data.id;
                        room.building_name = res.data.data.building_name;
                        room.building_id = res.data.data.building_id;
                        if (room.label && room.label.length > 0) {
                            room.label = room.label.toString();
                        }
                        if (room.label && room.label.length == 0) {
                            room.label = '';
                        }
                        if (room.housing_resources_type && room.housing_resources_type.length > 0) {
                            room.housing_resources_type = room.housing_resources_type.toString();
                        }
                        if (room.housing_resources_type && room.housing_resources_type.length == 0) {
                            room.housing_resources_type = '';
                        }
                        saveMetaCommon(this.model, {
                            values: JSON.parse(JSON.stringify(room)),
                        }).then(res => {
                            if (res.data.code == 200) {
                                this.dialogVisible = false;
                                if (this.spaces[this.currentIndex]) {
                                    this.spaces[this.currentIndex].rooms.push(res.data.data);
                                }
                                this.onLoad();
                            }
                        });
                    }
                });
            } else {
                if (room.label && room.label.length > 0) {
                    room.label = room.label.toString();
                    console.log('转换', JSON.stringify(room.label));
                }
                if (room.label && room.label.length == 0) {
                    room.label = '';
                }
                if (room.housing_resources_type && room.housing_resources_type.length > 0) {
                    room.housing_resources_type = room.housing_resources_type.toString();
                }
                if (room.housing_resources_type && room.housing_resources_type.length == 0) {
                    room.housing_resources_type = '';
                }
                let o = {
                    update: JSON.parse(JSON.stringify(room)),
                    primaryKeyValue: room['id'],
                    primaryKey: 'id',
                };
                updateMetaCommon(this.model, o).then(res => {
                    if (res.data.code == 200) {
                        this.dialogVisible = false;
                        if (this.spaces[this.currentIndex]) {
                            this.spaces[this.currentIndex].rooms.forEach((r, index) => {
                                if (r.id == room.id) {
                                    this.spaces[this.currentIndex].rooms[index] = room;
                                }
                            });
                        }
                    }
                });
            }
        },
        getDetail(room, i) {
            this.ruleForm = {};

            this.ruleForm = JSON.parse(JSON.stringify(room));
            if (this.ruleForm.label == '') {
                this.ruleForm.label = [];
            }
            //  JSON.stringify
            this.roomTitle = '房间详情';
            this.currentIndex = i;
            this.onlyRead = true;
            this.onFloorLoad(room.floor_id);
            this.dialogVisible = true;
        },
        closeDias() {
            this.dialogVisible = false;
            this.ruleForm = {};
        },
        onLoad() {
            let obj = this.page;
            obj.wheres = [{ field: 'building_id', value: this.buildId }];
            this.getMetaCommonList(obj);
        },
        getMetaCommonList(obj) {
            let b = JSON.parse(JSON.stringify(obj));
            b.orders = [{ field: 'code+1000', sort: 'DESC' }];
            this.floors = [];
            getMetaCommonList(this.floorModel, b).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                console.log(data.records.length, 'floorModeldata.records');
                // data.records.forEach(element => {
                //     this.floors.push(element);
                // });
                this.floors = data.records;
                let obj = this.page;
                obj.wheres = [
                    { field: 'building_id', value: this.buildId },
                    { field: 'parent_id', value: 0 },
                    { field: 'is_cancel', value: 0 },
                ];

                this.getroomCommonList(obj);
            });
        },
        search() {
            let wheres = [
                { field: 'building_id', value: this.buildId },
                { field: 'parent_id', value: 0 },
                { field: 'is_cancel', value: 0 },
            ];
            for (let key in this.formInline) {
                if (key == 'rental_unit_price_day') {
                    wheres.push({ field: key, value: this.formInline[key], op: 'GE' });
                } else if (key == 'rental_unit_price_day1') {
                    wheres.push({
                        field: 'rental_unit_price_day',
                        value: this.formInline[key],
                        op: 'LE',
                    });
                }

                if (this.formInline.renovation) {
                    wheres.push({
                        field: 'renovation',
                        value: this.formInline.renovation,
                    });
                }
                if (key == 'room_area') {
                    wheres.push({ field: key, value: this.formInline[key], op: 'GE' });
                } else if (key == 'room_area1') {
                    wheres.push({
                        field: 'room_area',
                        value: this.formInline[key],
                        op: 'LE',
                    });
                }
                if (key == 'room_name') {
                    wheres.push({
                        field: 'room_name',
                        value: this.formInline[key],
                        op: 'LIKE',
                    });
                }
                if (key == 'status') {
                    wheres.push({
                        field: 'status',
                        value: this.formInline[key],
                    });
                }
            }
            let obj = this.page;
            obj.wheres = wheres;
            this.spaces = [];
            this.getroomCommonList(obj);
        },
        getroomCommonList(obj) {
            this.btnLoding = true;
            obj.orders = [{ field: 'room_name', sort: 'ASC' }];
            getMetaCommonList(this.view_model, obj).then(res => {
                const data = res.data.data;
                let rooms = data.records;
                let spaces = [];
                this.spaces = [];
                console.log('this.floors.length', this.floors.length);
                this.floors.forEach(floor => {
                    let roomList = [];
                    rooms.forEach(room => {
                        if (floor.id == room.floor_id) {
                            roomList.push(room);
                        }
                    });
                    let obj = {
                        floor: floor,
                        rooms: roomList,
                    };
                    spaces.push(obj);
                });
                this.btnLoding = false;
                this.spaces = spaces;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.titleclazz {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.configInput {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 80px;
    margin: 0px 5px;
}
.card {
    padding: 0px 10px 10px 0px;
}
ul,
li {
    list-style: none;
    margin-bottom: 5px;
}
.spaceCard {
    display: inline-block;
    min-width: 100px;
    height: 105px;
}
.cardBg {
    background: #f5f7fa;
}
.spaceCard {
    &:hover {
        .addbtn {
            display: block;
        }
    }
}
.addbtn {
    border: 1px dotted #1976d3;
    padding: 5px 10px;
    color: #1976d3;
    margin-bottom: 10px;
    border-radius: 0px;
}
.roomClazz {
    white-space: nowrap;
    font-size: 0px;
    overflow-x: auto;
    width: 100%;
}
/deep/ .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 20px;
}
.b-b {
    border-bottom: 1px solid #dcdfe6;
}

.dropclazz {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 3px 5px 3px 0px;
    transform: rotate(-90deg);
}
.p-r {
    position: relative;
}
/deep/ .el-drawer__header {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 20px;
    margin-bottom: 0px;
}
/deep/ .el-drawer__body {
    padding: 0px;
}
/deep/.el-dialog__body {
    height: calc(80vh - 110px);
    overflow: auto;
    padding: 15px;
}

.cz-yz {
    padding: 2px 9px;
    border-radius: 4px;
    margin-top: -2px;
    background: rgb(243, 207, 153);
    color: #fff;
}
.cz-wz {
    background: rgb(211, 246, 145);
    padding: 2px 9px;
    border-radius: 4px;
    margin-top: -2px;
}
.cz-ys {
    background: rgb(220, 94, 82);
    padding: 2px 9px;
    border-radius: 4px;
    margin-top: -2px;
    color: #fff;
}
.cz-zy {
    background: #90bdf0;
    padding: 2px 9px;
    border-radius: 4px;
    margin-top: -2px;
    color: #fff;
}
.sonroom {
    position: absolute;
    right: 10px;
    bottom: 40px;
    color: #1976d3;
}
</style>

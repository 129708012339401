<template>
<div>
  <basic-container v-loading="loading" v-show="!showDataAssetsDetail" class="datalayer-container">
    <div v-if="showLayerList">
      <avue-crud
        ref="crud"
        v-model="catalogForm"
        :data="tableData"
        :table-loading="layerLoading"
        :page.sync="page"
        :option="option"
        :before-close="beforeClose"
        @on-load="onLoad"
        @refresh-change="onLoad"
        @row-save="handleRowSave"
        @row-update="rowUpdate"
      >
        <template slot-scope="{type,size,row}" slot="menu">
          <el-button @click.stop="handleUpload(row)" icon="el-icon-upload2" :size="size" :type="type">上传</el-button>
          <el-button @click.stop="handleEdit(row)" icon="el-icon-edit" :size="size" :type="type">编辑</el-button>
          <el-button @click.stop="rowDel(row)" icon="el-icon-delete" :size="size" :type="type">删除</el-button>
          <el-button @click="updateEnableStatus(row,'1')" v-if="row.entableStatus === '0'" icon="el-icon-open" :size="size" :type="type">启用</el-button>
          <el-button @click="updateEnableStatus(row,'0')" v-if="row.entableStatus === '1'" icon="el-icon-turn-off" :size="size" :type="type">禁用</el-button>
          <el-button @click="updateShowStatus(row,'1')" v-if="row.showStatus === '0'" icon="el-icon-unlock" :size="size" :type="type">暴露</el-button>
          <el-button @click="updateShowStatus(row,'0')" v-if="row.showStatus === '1'" icon="el-icon-lock" :size="size" :type="type">不暴露</el-button>
        </template>
        <template slot="dhName" slot-scope="scope">
          <a @click="showLayerDetail(scope.row)">{{scope.row.dhName}}</a>
        </template>
        <template slot="tableGrabbingRules" slot-scope="scope">
          <div v-for="(item,index) in scope.row.ruleList" :key="index">
            <div>
              <div>
                <span>库:</span><span>{{item.databaseName}}</span>
              </div>
              <div>
                <span>命名规则:</span><span>{{item.hitRule}}</span>
              </div>
              <div>
                <span>剔除规则:</span><span>{{item.eliminationRule}}</span>
              </div>
            </div>
          </div>
        </template>
        <template slot-scope="{row}" slot="ruleForm">
          <div>
            <el-table
              v-if="ruleTableData.length > 0"
              :data="ruleTableData"
              style="width: 100%;">
              <el-table-column
                label="库类型">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.databaseType" placeholder="请选择" @change="datasourceTypeChange(scope.row)">
                    <el-option
                      v-for="item in datasourceTypeList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                label="库">
                <template slot-scope="scope">
                  <el-select v-model="scope.row.databaseId" placeholder="请选择库" @focus="queryDataBase(scope.row)">
                    <el-option
                      v-for="item in scope.row.databases"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                label="命中规则">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.hitRule" placeholder="请输入命中规则"></el-input>
                </template>
              </el-table-column>
              <el-table-column
                label="剔除规则">
                <template slot-scope="scope">
                  <el-input v-model="scope.row.eliminationRule" placeholder="请输入剔除规则"></el-input>
                </template>
              </el-table-column>
              <el-table-column
                label=""
                width="30px"
              >
                <template slot-scope="scope">
                  <span style="cursor: pointer;" @click="closeRule(scope)"><i class="el-icon-delete"></i></span>
                </template>
              </el-table-column>
            </el-table>
            <div style="color: #1976d3;">
              <i class="el-icon-plus"></i>
              <span @click="addRule" style="margin-left: 5px;cursor: pointer">添加</span>
            </div>
          </div>
        </template>
      </avue-crud>
    </div>
    <div v-if="showDBTable" class="table-height">
      <!-- <span class="back" @click="showDBTable = false;showLayerList = true;">
          <i class="el-icon-back"></i>
        </span> -->
      <avue-crud
        ref="dbtable"
        :data="dbTables"
        :option="dbTablesOption"
      >
        <template slot="menuLeft">
          <el-button @click="showDBTable = false;showLayerList = true;" icon="el-icon-back" type="text" size="small">返回</el-button>
           <!-- <el-button @click="showTables = false;showDBTable = true;" icon="el-icon-back" type="text" size="small">返回</el-button> -->
        </template>
        <template slot="name" slot-scope="scope">
          <a @click="toShowTables(scope.row)">{{scope.row.name}}</a>
        </template>
      </avue-crud>
    </div>
    <div v-if="showTables" class="table-height">
      <!-- <span class="back" @click="showTables = false;showDBTable = true;">
          <i class="el-icon-back"></i>
        </span> -->
      <avue-crud
        ref="table"
        :data="tables"
        :page.sync="tablePage"
        :option="tablesOption"
        @on-load="tableLoad"
        @refresh-change="tableLoad"
      >
         <template slot="menuLeft">
           <el-button @click="showTables = false;showDBTable = true;" icon="el-icon-back" type="text" size="small">返回</el-button>
              <el-input class="mar-l-5" v-model="searchVal"
                      @keyup.native.enter="tableLoad"
                      size="small"
                      :placeholder="$t('Please enter keyword')"
                      type="text"
                      style="width:180px;">
                <el-button class="mar-0 pad-0" size="small" @click="tableLoad" slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </template>
        <template slot="tableName" slot-scope="scope">
          <a @click="showMetaDetail(scope.row)">{{scope.row.tableName}}</a>
        </template>
        <!-- <template slot="menu" slot-scope="{row , size}">
          <el-button type="text" icon="el-icon-view" @click="view(row)" :size="size">预览</el-button>
        </template> -->
      </avue-crud>
    </div>
    <div v-if="showMetaInfo" class="table-height">
        <span class="back" @click="showMetaInfo = false;showTables = true;">
          <i class="el-icon-back"></i>
        </span>
      <div style="margin-top: 30px">
        <meta-info :guid="guid" :showOperation="false"></meta-info>
      </div>
    </div>
    <upload-dialog :datahierarchyId='datahierarchyId' ref='uploadDialog'></upload-dialog>
  </basic-container>
  <DataAssetsDetail
    v-if="showDataAssetsDetail"
    :isRoute="false"
    :showOperation="false"
    :propGuid="dataAssetsDetailProps.propGuid"
    :propTableName="dataAssetsDetailProps.propTableName"
    :propDatabaseId="dataAssetsDetailProps.propDatabaseId"
    :propDatabaseName="dataAssetsDetailProps.propDatabaseName"
    @back="showDataAssetsDetail = false"
  ></DataAssetsDetail>
</div>
</template>
<script>
  import Vue from 'vue'
  import {
    getDataLayerList,
    saveDataLayer,
    delDataLayer,
    updateEnableStatus,
    updateShowStatus,
    updateDataLayer,
    queryTablesByDataBaseIdAndDhId,
    queryDataBaseByDataHierarchyId,
    getDataLayerDetail
  } from '@/api/dws/datalayer';
  import datasourceTypeList from '@/config/datasourceTypeList'
  import { getDataBase } from "@/api/metadata/task";
  import metaInfo from './metainfo';
  import {
  queryDataByDataBaseIdAndTableName
} from "@/api/dws/datamodel";
import uploadDialog from './components/datalayer/uploadDialog.vue'
import DataAssetsDetail from '../data-assets/assets-detail';
export default {
  components:{ metaInfo,uploadDialog , DataAssetsDetail},
  data() {
    return {
      loading: false,
      datasourceTypeList:datasourceTypeList,
      guid:'',
      layerLoading:false,
      catalogForm:{
        database:''
      },
      showDataAssetsDetail: false,
      dataAssetsDetailProps: {
        propGuid: null,
        propTableName: '',
        propDatabaseId: null,
        propDatabaseName: ''
      },
      tableData:[],
      ruleTableData:[
        {
          'hitRule':'',
          'eliminationRule':'',
          'databaseId':'',
          'databaseType':'',
          'databases':[]
        }
      ],
      databases:[],
      databaseList:[
        {
          'database':''
        }
      ],
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      option:{
        labelWidth:120,
        editBtn:false,
        delBtn:false,
        column:[
          {
            label:'分层名称',
            prop:'dhName',
            rules: [{
              required: true,
              message: "请输入分层名称",
              trigger: "blur"
            }],
          },
          {
            label:'英文标识',
            prop:'dhCode',
            rules: [{
              required: true,
              message: "请输入英文标识",
              trigger: "blur"
            }],
          },
          {
            label:'描述',
            prop:'dhDesc',
            type: "textarea",
            minRows: 2,
            span: 24,
            rules: [{
              required: true,
              message: "请输入描述",
              trigger: "blur"
            }]
          },
          {
            label:'启用状态',
            prop:'entableStatus',
            dicData: [
              {
                label: '启用',
                value: '1'
              },
              {
                label: '未启用',
                value: '0'
              }
            ],
            display:false
          },
          {
            label:'显示控制',
            prop:'showStatus',
            dicData: [
              {
                label: '暴露',
                value: '1'
              },
              {
                label: '不暴露',
                value: '0'
              }
            ],
            display:false
          },
          {
            label: "表抓取规则",
            prop: "tableGrabbingRules",
            display:false
          },
          {
            label: "允许建表的库",
            prop: "libraryAllowingTableCreation",
            display:false,
            rules: [{
              required: true,
              message: "请输入允许建表的库",
              trigger: "blur"
            }]
          }
        ],
        group: [
          {
            column: [
              {
                label: "表抓取规则",
                prop: "rule",
                span:24
              },
              {
                label: "允许建表的库",
                prop: "libraryAllowingTableCreation",
                span:24,
                rules: [{
                  required: true,
                  message: "请输入允许建表的库",
                  trigger: "blur"
                }]
              }
            ]
          }
        ]
      },
      showLayerList:true,
      showDBTable:false,
      showTables:false,
      showMetaInfo:false,
      dbTables:[],
      dbTablesOption:{
        menu:false,
        addBtn:false,
        editBtn:false,
        delBtn:false,
        column:[
          {
            label:'库名称',
            prop:'name'
          },
          {
            label:'库类型',
            prop:'modeltype'
          },
          {
            label:'分层名称',
            prop:'dhName'
          },
          {
            label:'分层标识',
            prop:'dhCode'
          },
        ],
      },
      tables:[],
      tablesOption:{
        addBtn:false,
        editBtn:false,
        delBtn:false,
        menu: false,
        column:[
          {
            label:'表名称',
            prop:'tableName'
          },
          {
            label:'表类型',
            prop:'typeName'
          },
          {
            label:'分层名称',
            prop:'dhName'
          },
          {
            label:'分层标识',
            prop:'dhCode'
          },
        ],
      },
      dialogViewVisible: false,
      dialogViewLoading: false,
      viewColumns: [],
      viewData: [],
      currentDatabase: null,
      dhCode: '',
      tablePage: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      searchVal: '',
      datahierarchyId: ''//数据分层id
    }
  },
  mounted() {
    //this.getDataBase();
  },
  methods:{
    resetViewData(columnNameList , data){
      let columnNameListTemp = [] , dataTemp = [];
      columnNameList.forEach(
        item => {
          let itemArr = item.split('.');
          columnNameListTemp.push({
            label: itemArr.join('.'),
            prop: itemArr.join('')
          });
        }
      )
      this.viewColumns = columnNameListTemp;
      data.forEach(
        item => {
          let itemTemp = {};
          Object.keys(item).forEach(
            name => {
              itemTemp[name.split('.').join('')] = item[name];
            }
          )
          dataTemp.push(itemTemp);
        }
      )
      this.viewData = dataTemp;
    },
    view(row){
      this.dialogViewVisible = true;
      this.dialogViewLoading = true;
      this.viewColumns = [];
      this.viewData = [];
      queryDataByDataBaseIdAndTableName({
        databaseId: this.currentDatabase.dataBaseId,
        tableName: row.tableName,
        rowSize: 10
      }).then(
        res => {
          this.resetViewData(res.data['columnNameList'] || [] , res.data['data'] || []);
          this.dialogViewLoading = false;
        }
      )
    },
    onLoad() {
      this.layerLoading = true;
      let obj = {
        dhName:'',
        pageNo:this.page.currentPage,
        pageSize:this.page.pageSize
      };
      getDataLayerList(obj).then( res => {
        res['data']['data']['lists'].forEach(item => {
          item['ruleList'] = JSON.parse(item['tableGrabbingRules']);
        });
        this.tableData = res['data']['data']['lists'];
        this.page.total = res['data']['data']['totalCount'];
        this.layerLoading = false;
      });
    },
    beforeClose(done) {
      done();
      this.ruleTableData = [
        {
          'hitRule':'',
          'eliminationRule':'',
          'databaseId':'',
          'databaseType':'',
          'databases':[]
        }
      ];
      this.databaseList = [];

    },
    handleRowSave(row,done , loading) {
      let temp = [] , err = false;
      // 按表规则
      this.ruleTableData.forEach( item => {
        let dataBaseMap = {} , databaseIds = [];
        item.databases.forEach( item => {
          dataBaseMap[item['value']] = item['label'];
        });
        if(item['databaseId'] === '' || item['databaseType'] === ''){
          err = true;
        }
        databaseIds.push(item['databaseId']);
        temp.push({
          'hitRule':item['hitRule'],
          'eliminationRule':item['eliminationRule'],
          'databaseId':item['databaseId'],
          'databaseName':dataBaseMap[item['databaseId']],
          'databaseType':item['databaseType']
        })
      });
      if(err){
        this.$message({
          type: 'error',
          message: '库类型和库必填'
        })
        loading();
        return;
      }
      let obj = {
        'dhCode':row['dhCode'],
        'dhName':row['dhName'],
        'dhDesc':row['dhDesc'],
        'entableStatus':'1',
        'showStatus':'1',
        'tableGrabbingRules':JSON.stringify(temp),
        'libraryAllowingTableCreation':row['libraryAllowingTableCreation'],
        'databaseIds': JSON.stringify(temp)
      };
      saveDataLayer(obj).then(res => {
        this.$message({
          type: 'success',
          message: res.msg || '操作成功'
        })
        done();
        this.onLoad();
      }).catch(e => loading());
    },
    updateEnableStatus(row,status) {
      let obj = {
        'id':row['id'],
        'enableStatus':status
      };
      updateEnableStatus(obj).then( res => {
        this.onLoad();
      });
    },
    updateShowStatus(row,status) {
      let obj = {
        'id':row['id'],
        'showStatus':status
      };
      updateShowStatus(obj).then( res => {
        this.onLoad();
      });
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          delDataLayer({
            id: row.id
          }).then(res => {
            this.$message.success('操作成功');
            this.onLoad();
          }).catch(e => {
            // this.$message.error(e.msg || '')
          })
        })
    },
    // 上传
    handleUpload (row) {
      this.datahierarchyId=row.id
      this.$refs.uploadDialog.dialogViewVisible=true
    },
    handleEdit(_row) {
      
      getDataLayerDetail({id: _row.id}).then(
        res => {
          let row = res.data.data;
          this.$refs.crud.rowEdit(row);
          this.ruleTableData = [];
          let ruleList = [];
          try {
            ruleList = JSON.parse(row['tableGrabbingRules']);
          } catch (error) {
            ruleList = [];
          }
          ruleList.forEach(item => {
            let len = this.ruleTableData.length;
            Vue.set(this.ruleTableData,len,
              {
                'hitRule':item['hitRule'],
                'eliminationRule':item['eliminationRule'],
                'databaseId':item['databaseId'],
                'databaseType':item['databaseType'],
                'databases':[]
              });
          });
          this.ruleTableData.forEach(row => {
            let obj = {
              type: row.databaseType
            };
            getDataBase(obj).then( res => {
              if(res['status'] === 200) {
                res['data']['data'].forEach(item => {
                  let obj ={
                    'value':item['id'],
                    'label':item['name']
                  };
                  row.databases.push(obj);
                });
              }
            })
          });
          //this.ruleTableData = row.ruleList.concat([]);
          setTimeout(()=>{
          },0)
        }
      )
    },
    rowUpdate(row,index,done , loading) {
      let temp = [] , err = false;
      this.ruleTableData.forEach( item => {
        let dataBaseMap = {};
        item.databases.forEach( item => {
          dataBaseMap[item['value']] = item['label'];
        });
         if(item['databaseId'] === '' || item['databaseType'] === ''){
          err = true;
        }
        temp.push({
          'hitRule':item['hitRule'],
          'eliminationRule':item['eliminationRule'],
          'databaseId':item['databaseId'],
          'databaseName':dataBaseMap[item['databaseId']],
          'databaseType':item['databaseType']
        })
      });
      if(err){
        this.$message({
          type: 'error',
          message: '库类型和库必填'
        })
        loading();
        return;
      }
      let obj = {
        'id':row['id'],
        'dhCode':row['dhCode'],
        'dhName':row['dhName'],
        'dhDesc':row['dhDesc'],
        'entableStatus':'1',
        'showStatus':'1',
        'tableGrabbingRules':JSON.stringify(temp),
        'libraryAllowingTableCreation':row['libraryAllowingTableCreation'],
        'databaseIds': JSON.stringify(temp)
      };
      updateDataLayer(obj).then(res => {
        this.$message({
          type: 'success',
          message: res.msg || '操作成功'
        })
        done();
        this.onLoad();
      }).catch(e => loading());
    },
    addRule() {
      let len = this.ruleTableData.length;
      Vue.set(this.ruleTableData,len,{
        'hitRule':'',
        'eliminationRule':'',
        'databaseId':'',
        'databaseType':'',
        'databases':[]
      });
    },
    addDataBase() {
      this.databaseList.push({
        'database':''
      })
    },
    closeDataBase(index) {
      if(this.databaseList.length === 1) return;
      this.databaseList.splice(index,1);
    },
    closeRule(scope) {
      if(this.ruleTableData.length === 1) {
        return;
      }
      this.ruleTableData.splice(scope.$index,1);
    },
    changeGrabRule() {
      console.log(this.grabType)
    },
    getDataBase() {
      let obj = {
        type: 'HIVE'
      };
      getDataBase(obj).then( res => {
        if(res['status'] === 200) {
          res['data']['data'].forEach(item => {
            let obj ={
              'value':item['id'],
              'label':item['name']
            };
            this.databases.push(obj);
          });
        }
      })
    },
    showLayerDetail(row) {
      this.loading = true;
      this.dhCode = row.dhCode;
      let obj = {
        id:row['id']
      };
      queryDataBaseByDataHierarchyId(obj).then( res => {
        this.dbTables = res['data']['data'];
        this.showDBTable = true;
        this.showLayerList = false;
        this.loading = false;
      }).catch(e => {
        this.loading = false;
      });
    },
    toShowTables(row) {
      this.currentDatabase = row;
      if(!row['guid']) {
        //this.$message.error('未绑定数据');
        return;
      }
      this.tablePage.total = 0;
      this.tablePage.currentPage = 1;
      this.tablePage.pageSize = 10;
      this.tableLoad();
    },
    tableLoad(){
      this.loading = true;
      this.tables = [];
      let obj = {
        id:this.currentDatabase['dhId'],
        guid:this.currentDatabase['guid'],
        databaseId:this.currentDatabase['dataBaseId'],
        pageSize: this.tablePage.pageSize,
        pageNo: this.tablePage.currentPage,
        searchVal: this.searchVal
      };
      queryTablesByDataBaseIdAndDhId(obj).then( res => {
        this.tables = res['data']['data'];
        this.tablePage.total = res['data']['total'];
        this.showDBTable = false;
        this.showTables = true;
        this.loading = false;
      }).catch(e => {
        this.loading = false;
      });
    },
    showMetaDetail(row) {
      this.guid = row['guid'];
      // this.$router.push({
      //   path: "/data-assets/assets-detail/" + row.guid,
      //   query: {
      //     rowData: row,
      //     dataBaseId: this.currentDatabase.dataBaseId,
      //     dataBaseName: this.currentDatabase.dhCode
      //   }
      // });
      this.dataAssetsDetailProps.propDatabaseId = this.currentDatabase.dataBaseId;
      this.dataAssetsDetailProps.propDatabaseName = this.currentDatabase.dhCode;
      this.dataAssetsDetailProps.propGuid = row.guid;
      this.dataAssetsDetailProps.propTableName = row.tableName;
      this.showDataAssetsDetail = true;
      // this.showMetaInfo = false;
      // this.showTables = false;
      // this.showLayerList = false;
    },
    datasourceTypeChange(row) {
      row.databaseId = '';
      // let _t = this;
      // row.databases = [];
      // let obj = {
      //   type: row.databaseType
      // };
      // getDataBase(obj).then( res => {
      //   if(res['status'] === 200) {
      //     res['data']['data'].forEach(item => {
      //       let obj ={
      //         'value':item['name'],
      //         'label':item['name']
      //       };
      //       row.databases.push(obj);
      //     });
      //   }
      // })
    },
    queryDataBase(row) {
      if(!row.databaseType) return;
      let _t = this;
      row.databases = [];
      let obj = {
        type: row.databaseType
      };
      getDataBase(obj).then( res => {
        if(res['status'] === 200) {
          res['data']['data'].forEach(item => {
            let obj ={
              'value':item['id'],
              'label':item['name']
            };
            row.databases.push(obj);
          });
        }
      })
    }
  }
}
</script>
<style lang="scss">
  .datalayer-container{
    a {
      cursor: pointer;
      color: #337ab7;
    }
    .table-height{
      height: calc(100vh - 170px)!important;
      overflow: auto;
      position: relative;
    }
    .back {
      position: absolute;
      cursor: pointer;
      z-index: 1;
    }
  }
 .rule-close {
   position: absolute;
   right: -25px;
   z-index: 1;
   font-size: 18px;
   cursor: pointer;
 }
</style>

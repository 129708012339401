<template>
  <div class="meta-model" v-loading="showModelTree">
    <div class='display-flex'>
      <div class='w-300' style="margin-bottom: 0;">
        <el-card shadow="never" :body-style="{ padding: '20px 20px 0 20px' }" class="model-height w-300">
          <el-input
            style="margin-bottom: 15px;width: calc(100% - 33px);"
            size="small"
            placeholder="请输入内容"
            suffix-icon="el-icon-search"
            v-model="modelSearch"
            @change="filterTree"
          >
          </el-input>
          <el-tooltip :content="'新增包'" placement="top" :enterable="false">
              <span>
                <el-button style="margin-left:5px;" type="primary" size="mini" icon="el-icon-circle-plus-outline" @click="addPackage" circle></el-button>
              </span>
          </el-tooltip>
          <el-tree
            ref="tree"
            :data="data"
            :load="handleNodeClick"
            lazy
            node-key="id"
            :default-expanded-keys="['1']"
            :expand-on-click-node="false"
            :props="defaultProps"
            @node-click="nodeClick"
            @node-contextmenu="contextMenuOperation">
          </el-tree>
        </el-card>
      </div>
      <div class="meta-container model-height" style="margin-bottom: 0;flex:1">
        <el-card v-if="showModelList" shadow="never" :body-style="{ padding: '20px 20px 0 20px' }" class="model-height">
          <avue-crud
            ref="crud"
            v-model="modelForm"
            :table-loading="modelLoading"
            :data="modelList"
            :page.sync="page"
            :option="modelOption"
            :before-open="beforeOpen"
            :before-close="beforeClose"
            @refresh-change="getModels"
            @row-save="handleRowSave"
            @row-update="rowUpdate"
            @row-del="rowDel"
            @selection-change="modelSelectionChange"
          >
            <template slot-scope="{type,size,row}" slot="menu">
              <el-button @click.stop="handleModelEdit(row)" :disabled="!!row.outCode" icon="el-icon-edit" :size="size" :type="type">编辑</el-button>
              <el-button @click.stop="rowDel(row)" :disabled="!!row.outCode" icon="el-icon-delete" :size="size" :type="type">删除</el-button>
            </template>
            <template slot="menuLeft" slot-scope="{size}">
              <el-button
                @click="showPublishModel"
                icon="el-icon-share"
                size="small">发布
              </el-button>
              <el-button
                @click="dismissModel"
                icon="el-icon-refresh-left"
                size="small">撤回
              </el-button>
              <el-button
                icon="el-icon-download"
                size="small">导出
              </el-button>
            </template>
            <template slot="outCode" slot-scope="{row}">
              <span v-if="!row.outCode">
                草稿
              </span>
              <span v-if="!!row.outCode">
                发布
              </span>
            </template>
            <template slot="name" slot-scope="{row}">
              <a @click="showMetaInfo(row)">{{row.name}}</a>
            </template>
          </avue-crud>
        </el-card>
        <el-card v-if="!showModelList" shadow="never" :body-style="{ padding: '20px 20px 0 20px' }" class="model-height" v-loading="infoLoading">
          <basic-page-header @back="() => {showModelList = true}"></basic-page-header>
          <div>
            <h3 class="metadata-title">
              {{dyAttrs[1].value}}
            </h3>
          </div>
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="基本信息" name="info" style="line-height: 20px">
              <el-row v-for="(item,index) in dyAttrs" :key="index">
                <el-col :span="3">
                  {{item.label}}
                </el-col>
                <el-col :span="8">
                  <span v-if="item.isText">
                    {{item.value}}
                  </span>
                  <span v-if="!item.isText">
                    <el-checkbox :value="!item.isText" disabled></el-checkbox>
                  </span>
                </el-col>
              </el-row>
            </el-tab-pane>
            <el-tab-pane label="属性" name="property">
              <avue-crud
                ref="property"
                v-model="propertyForm"
                :table-loading="propertyLoading"
                :data="propertyList"
                :option="propertyOption"
                :before-close="beforeClose"
                @refresh-change="onPropertyLoad"
                @row-save="handlePropertySave"
                @row-update="propertyUpdate"
                @selection-change="propertySelectionChange"
              >
                <template slot="menu" slot-scope="{type,size,row}">
                  <el-button
                    @click.stop="handleEdit(row)"
                    icon="el-icon-edit"
                    :size="size"
                    :type="type"
                  >编辑
                  </el-button>
                  <el-button
                    @click.stop="delProperty(row)"
                    icon="el-icon-delete"
                    :size="size"
                    :type="type"
                  >删除
                  </el-button>
                  <el-button
                    @click.stop="handlePropertyUp(row)"
                    icon="el-icon-top"
                    :size="size"
                    :type="type"
                  >上移
                  </el-button>
                  <el-button
                    @click.stop="handlePropertyDown(row)"
                    icon="el-icon-bottom"
                    :size="size"
                    :type="type">下移
                  </el-button>
                </template>
              </avue-crud>
            </el-tab-pane>
            <el-tab-pane label="历史版本" name="version">
              <avue-crud
                ref="version"
                :data="versionList"
                :option="versionOption"
                @refresh-change="getVersions"
              >
              </avue-crud>
            </el-tab-pane>
          </el-tabs>
        </el-card>
      </div>
    </div>
    <div class="context-menu" :style="{top:top+'px',left:left+'px'}" v-if="visible" ref="mySelBox">
      <el-card>
        <div class="btn" @click="addPackage">新增</div>
        <div class="btn" @click="updatePackage">编辑</div>
        <div class="btn" @click="deletePackage">删除</div>
      </el-card>
    </div>
    <el-dialog class="avue-dialog avue-crud__dialog" custom-class="dialog-width" @close="closePackageForm"  append-to-body :title="operationTitle" :visible.sync="addPackageVisible">
      <avue-form ref="packageForm" v-model="packageForm" :option="packageFormOption">
        <template slot-scope="scope" slot="menuForm">
        <basic-form-button @submit="savePackage" @cancel="cancelAddPackage"></basic-form-button>
      </template>
      </avue-form>
    </el-dialog>
    <el-dialog class="avue-dialog avue-crud__dialog"  append-to-body title="发布模型" :visible.sync="publishModelVisible">
      <avue-form ref="publishModelForm" v-model="publishModelForm" :option="publishModelFormOption">
        <template slot-scope="scope" slot="name">
          admin
        </template>
        <template slot-scope="scope" slot="publishDate">
          2021-05-24 14:30:30
        </template>
        <template slot-scope="scope" slot="publishPath">
          Kettle包
        </template>
        <template slot-scope="scope" slot="menuForm">
          <basic-form-button submitText="发布" @submit="publishVersion" @cancel="publishModelVisible = false"></basic-form-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  getPackageList,
  savePackage,
  delPackage,
  getModelList,
  getVersionList,
  saveModel,
  publishModel,
  dismissModel,
  delModel,
  getModelDetail,
  updateProperty,
  getChildPackageList,
  getAllPackages
} from '@/api/metadata/model';
import dateType from '@/config/dataType';
const dict = [
  {
    'label':'否',
    'value':false
  },
  {
    'label':'是',
    'value':true
  }

];
export default {
  data(){
    return {
      showModelTree:false,
      infoLoading:false,
      visible:false,
      isPkgEdit:false,
      operationTitle:'新增模型包',
      addPackageVisible:false,
      packageForm:{
      },
      packageFormOption:{
        size: 'small',
        menuPosition:'right',
        submitBtn:false,
        emptyBtn:false,
        column: [
          {
            label: "代码",
            prop: "code",
            rules: [{
              required: true,
              message: "请输入代码",
              trigger: "blur"
            }]
          },
          {
            label: "名称",
            prop: "name",
            rules: [{
              required: true,
              message: "请输入名称",
              trigger: "blur"
            }]
          },
          {
            label:'父节点',
            prop:'parentId',
            type:'tree',
            props: {
              label: "name",
              value: "id"
            },
            dicData: []
          },
          {
            label: "描述",
            prop: "remark",
            type: "textarea",
            minRows: 2,
            span: 24
          }
        ]
      },
      size:'mini',
      modelSearch:'',
      data:[],
      defaultProps:{
        children: 'children',
        label: 'name',
        isLeaf: 'leaf'
      },
      showModelList:true,
      activeName:'info',
      modelForm:{
        'parentId':''
      },
      modelLoading:false,
      modelList:[],
      page:{

      },
      modelOption:{
        editBtn:false,
        delBtn:false,
        selection: true,
        tip:false,
        addBtnText:'新增模型',
        column:[
          {
            label:'名称',
            prop:'name',
            display:false
          },
          {
            label:'代码',
            prop:'code',
            display:false
          },
          {
            label:'状态',
            prop:'outCode',
            display:false
          },
          {
            label:'描述',
            prop:'remark',
            display:false
          },
          // {
          //   label:'创建者',
          //   prop:'creator',
          //   display:false
          // },
          // {
          //   label:'创建时间',
          //   prop:'createDate',
          //   type: "date",
          //   format: "yyyy-MM-dd hh:mm:ss",
          //   valueFormat: "yyyy-MM-dd hh:mm:ss",
          //   display:false
          // }
        ],
        group: [
          {
            labelWidth:110,
            column: [
              {
                label: "名称",
                prop: "name",
                rules: [{
                  required: true,
                  message: "请输入名称",
                  trigger: "blur"
                }]
              },
              {
                label: "代码",
                prop: "code",
                rules: [{
                  required: true,
                  message: "请输入代码",
                  trigger: "blur"
                }],
                editDisabled:true
              },
              {
                label:'所属包',
                prop:'parentId',
                type:'tree',
                props: {
                  label: "name",
                  value: "id"
                },
                dicData: [],
                rules: [{
                  required: true,
                  message: "请选择",
                  trigger: "click"
                }]
              },
              {
                label: "描述",
                prop: "remark",
                type: "textarea",
                minRows: 2,
                span: 24
              }
            ]
          }
        ]
      },
      dyAttrs:[
        {
          label:'代码',
          value:'',
          isText:true
        },
        {
          label:'名称',
          value:'',
          isText:true
        },
        {
          label:'所属包',
          value:'',
          isText:true
        },
        {
          label:'创建者',
          value:'',
          isText:true
        },
        {
          label:'创建时间',
          value:'',
          isText:true
        },
        {
          label:'发布时间',
          value:'',
          isText:true
        },
        {
          label:'版本',
          value:'',
          isText:true
        },
        {
          label:'描述',
          value:'',
          isText:true
        }
      ],
      propertyForm:{
        isOptional:false,
        isIndexable:false,
        isUnique:false
      },
      propertyList:[],
      propertyOption:{
        labelWidth:120,
        index:true,
        indexLabel:'序号',
        //menu:false,
        editBtn:false,
        delBtn:false,
        selection: true,
        column:[
          {
            label:'属性名称',
            prop:'name',
            rules: [{
              required: true,
              message: "请输入名称",
              trigger: "blur"
            }]
          },
          {
            label:'数据类型',
            prop:'typeName',
            type:'select',
            dicData: dateType,
            rules: [{
              required: true,
              message: "请选择数据类型",
              trigger: "change"
            }]
          },
          {
            label:'是否可选',
            prop:'isOptional',
            type:'radio',
            dicData: dict,
            value:false
          },
          {
            label:'是否建立索引',
            prop:'isIndexable',
            type:'radio',
            dicData: dict,
            value:false
          },
          {
            label:'是否唯一',
            prop:'isUnique',
            type:'radio',
            dicData: dict,
            value:false
          },
          {
            label:'描述',
            prop:'description',
            type: "textarea",
            span: 24,
            minRows: 2
          }
        ],
        // group:[
        //   {
        //     column:[
        //       {
        //         label:'是否可选',
        //         prop:'isOptional',
        //         type: "radio",
        //         dicData: dict
        //       },
        //       {
        //         label:'是否建立索引',
        //         prop:'isIndexable',
        //         type: "radio",
        //         dicData: dict
        //       },
        //       {
        //         label:'是否唯一',
        //         prop:'isUnique',
        //         type: "radio",
        //         dicData: dict
        //       },
        //       {
        //         label:'描述',
        //         prop:'description',
        //         type: "textarea",
        //         span: 24,
        //         minRows: 2
        //       }
        //     ]
        //   }
        // ]
      },
      versionList:[],
      versionOption:{
        header:false,
        menu:false,
        column:[
          {
            label:'版本',
            prop:'version',
            display:false
          },
          {
            label:'发布时间',
            prop:'createTime',
            display:false
          },
          {
            label:'发布人',
            prop:'publisher',
            display:false
          }
        ]
      },
      publishModelVisible:false,
      publishModelForm:{
        syncUpdate:false
      },
      publishModelFormOption:{
        size: 'small',
        menuPosition:'right',
        submitBtn:false,
        emptyBtn:false,
        column: [
          {
            label: '发布人',
            prop: 'name'
          },
          {
            label: '发布日期',
            prop: 'publishDate'
          },
          {
            label: '发布路径',
            prop: 'publishPath'
          },
          {
            label: '描述',
            prop: 'description',
            type:'textarea',
            minRows: 2,
            span: 24
          }
        ]
      },
      selectedPropertyList:[],
      rtSelectedNode:{},
      currentPkgId:'',
      currentPkgNode:'',
      currentModelName:'',
      currentPackageName:'',
      currentModelId:'',
      guid:'',
      propertyLoading:true,
      selectedModel:[]
    }
  },
  mounted() {
    this.getAllPackageList();
    this.getPackages();
    this.bindClick();
  },
  methods: {
    getAllPackageList() {
      getAllPackages().then( res => {
        this.packageFormOption.column[2].dicData = res.data.data.concat([]);
        this.modelOption.group[0].column[2].dicData = res.data.data.concat([]);
      });
    },
    getPackages() {
      let _t = this;
      this.showModelTree = true;
      getPackageList(this.modelSearch).then( res => {
        if(res['data']['code'] === 200 ) {
          // res['data']['data'].forEach(item => {
          //   item['isPkg'] = true;
          // });
          this.data = res['data']['data'];
          let defaultId = this.data[0]['id'];
          this.currentPkgNode = this.data[0]['code'];
          if(this.currentPkgId) {
            defaultId = this.currentPkgId
          } else {
            this.currentPkgId = defaultId;
          }
          this.modelForm.parentId = defaultId;
          _t.getModels();
          this.$nextTick(() => {
            this.$refs.tree.setCurrentKey(defaultId);//获取已经设置的资源后渲染
          });
        }
        this.showModelTree = false;
      });
    },
    getModels() {
      this.modelLoading = true;
      let obj = {
        parentId:this.currentPkgId,
        code:this.currentPkgNode
      };
      getModelList(obj).then( res => {
        if(res['data']['code'] === 200){
          this.modelList = res['data']['data'];
        }
         this.modelLoading = false;
      });
    },
    getModelDetail(name) {
      this.propertyLoading = true;
      this.infoLoading = true;
      getModelDetail(name).then( res => {
        if(res['data']['code'] === 200) {
          if(res['data']['data']['source'] && res['data']['data']['source'] !== '') {
            let model = JSON.parse(res['data']['data']['source']);
            this.propertyList = model['attributeDefs'];
            this.guid = model['guid'];
            //this.dyAttrs[1].value = model['name'];
            //this.dyAttrs[2].value = model['serviceType'];
            this.dyAttrs[3].value = model['createdBy'];
            this.dyAttrs[4].value = model['createTime'];
            // this.dyAttrs[5].value = model['updateTime'];
            // this.dyAttrs[6].value = model['version'];
            this.dyAttrs[7].value = model['description'];
            this.setPropertyPermission(res['data']['data']['outCode']);
          }
          //this.activeName = 'info';
          this.propertyLoading = false;
          this.infoLoading = false;
        }

      });
    },
    setPropertyPermission(outCode) {
      if(!!outCode) {
        this.propertyOption.selection = false;
        this.propertyOption.index = false;
        this.propertyOption.header = false;
        this.propertyOption.menu = false;
      } else {
        this.propertyOption.selection = true;
        this.propertyOption.index = true;
        this.propertyOption.header = true;
        this.propertyOption.menu = true;
      }

    },
    getVersions() {
      let obj = {
        'metadataId':this.currentModelId
      };
      getVersionList(obj).then( res => {
        if(res['data']['code'] === 200){
          this.versionList = res['data']['data']['records'];
        }
      });
    },
    filterTree() {
      this.getPackages();
    },
    addPackage() {
      this.isPkgEdit = false;
      this.operationTitle='新增模型包';
      this.addPackageVisible = true;
    },
    addChildrenPackage() {
      this.operationTitle='新增模型包';
      this.addPackageVisible = true;
    },
    updatePackage() {
      this.isPkgEdit = true;
      this.operationTitle='编辑模型包';
      this.packageForm['code'] = this.rtSelectedNode.code;
      this.packageForm['name'] = this.rtSelectedNode.name;
      this.packageForm['parentId'] = this.rtSelectedNode.parentId;
      this.packageForm['remark'] = this.rtSelectedNode.remark;
      this.addPackageVisible = true;
    },
    deletePackage() {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.delPkg();
        })
    },
    delPkg() {
      delPackage({
        ids:this.rtSelectedNode.id
      }).then(res => {
        if(res['data']['code'] === 200){
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          this.getPackages();
        }

      })
    },
    savePackage() {
      this.$refs.packageForm.validate((vaild)=>{
        if(vaild){
          let obj = this.packageForm;
          if(this.isPkgEdit){
            obj = {
              id:this.rtSelectedNode['id'],
              ...this.packageForm
            };
          }
          savePackage(obj).then(res => {
            if(res['data']['code'] === 200 ) {
              this.addPackageVisible = false;
              this.getPackages();
              this.getAllPackageList();
            } else {
              this.$message.error(res.data.msg)
            }
            this.$refs.packageForm.allDisabled = false;
          });
        }
      })

    },
    closePackageForm(done){
      this.$refs.packageForm.resetForm();
    },
    cancelAddPackage(){
      this.addPackageVisible = false;
    },
    handleNodeClick(item,resolve){
      if(!item['data']['id']) return;
      getChildPackageList({
        'parentId':item['data']['id'],
        'name':this.modelSearch
      }).then( res => {
        // res['data']['data'].forEach(item => {
        //   item['leaf'] = true;
        // });
        resolve(res['data']['data']);
      });
    },
    nodeClick(data) {
      this.showModelList = true;
      // this.showModelList = !!data['isPkg'];
      //
      // if(this.showModelList){
        this.currentPkgId = data['id'];
        this.currentPkgNode = data['code'];
        this.getModels();
        // 给新增模型的所属包默认值
        this.modelForm.parentId = data['id'];
        this.currentPackageName = data['name'];
      // } else {
      //   this.currentModelName = data['name'];
      //   this.currentModelId = data['id'];
      //   this.dyAttrs[0].value = data['code'];
      //   this.getModelDetail(data['id']);
      //   this.getVersions();
      // }

    },
    showMetaInfo(data) {
      this.showModelList = false;
      this.currentModelName = data['name'];
      this.currentModelId = data['id'];
      this.dyAttrs[0].value = data['code'];
      this.dyAttrs[1].value = data['name'];
      this.dyAttrs[2].value = this.currentPackageName;
      this.getModelDetail(data['id']);
      this.getVersions();
    },
    contextMenuOperation(ev,data) {
      this.rtSelectedNode = data;
      this.visible = true;
      this.top = ev['y'];
      this.left = ev['x'];
    },
    bindClick(){
      document.removeEventListener('click',this.outClick);
      this.$nextTick(function() {
        document.addEventListener("click",this.outClick);
      });
    },
    outClick(e) {
      if (this.$refs.mySelBox&&!this.$refs.mySelBox.contains(e.target)) {
        this.visible = false;
      }
    },
    handleClick(item) {
      // if(item['name'] === 'property'){
      //   this.getPropertys();
      // }
      if(item['name'] === 'version') {
        this.getVersions();
      }
    },
    beforeOpen(done , type){
      // getModelDetail
      this.modelForm.parentId = this.currentPkgId;
      if(['edit'].includes(type)){
        getModelDetail(this.modelForm.id).then(
          res => {
            this.modelForm = res.data.data;
            this.modelForm.parentId = this.currentPkgId;
          }
        )
      }
      done();
    },
    beforeClose(done) {
      done();
    },
    onLoad() {

    },
    handleModelEdit(row){
      this.$refs.crud.rowEdit(row);
    },
    handleRowSave(row,done) {
      let obj = {
        'name':row['name'],
        'code':row['code'],
        'parentId':row['parentId'],
        'remark':row['remark']
      };
      saveModel(obj).then( res => {
        if(res['data']['code'] === 200){
          this.getModels();
        } else {
          this.$message.error(res.data.msg)
        }
        done();
      });
    },
    rowUpdate(row,index,done) {
      let obj = {
        'id':row['id'],
        'name':row['name'],
        'code':row['code'],
        'parentId':row['parentId'],
        'remark':row['remark']
      };
      saveModel(obj).then( res => {
        if(res['data']['code'] === 200){
          this.getModels();
        } else {
          this.$message.error(res.data.msg);
        }
        done();
      });
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          delModel({
            ids: row.id
          }).then(res => {
            if(res['data']['code'] === 200){
              this.$message.success('删除成功');
              this.getModels();
            } else {
              this.$message.error(res.data.msg)
            }

          }).catch(e => {
            // this.$message.error(e.msg || '')
          })
        })
    },
    showPublishModel() {
      if(this.selectedModel.length === 0){
        this.$message.error('请至少选择一条数据');
        return;
      }
      let ids = [];
      let isContainPub = false;
      this.selectedModel.forEach(item => {
        ids.push(item['id']);
        if(!!item['outCode']){
          isContainPub = true;
        }
      });
      if(isContainPub) {
        this.$message.error('选择的数据包含已发布状态的数据，请重新选择');
        return;
      }
      this.$confirm("确定将选择数据发布?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.modelLoading = true;
          publishModel(ids).then(res => {
            if(res['data']['code'] === 200) {
              this.$message.success('发布成功');
              this.getModels();
            } else {
              this.$message.error(res.data.msg || '')
            }
            this.modelLoading = false;
          }).catch(e => {
            // this.$message.error(e.msg || '')
          })
        })
    },
    dismissModel() {
      if(this.selectedModel.length === 0){
        this.$message.error('请至少选择一条数据');
        return;
      }
      let ids = [];
      let flg = false;
      this.selectedModel.forEach(item => {
        ids.push(item['id']);
        if(!item['outCode']) {
          flg = true;
        }
      });
      if(flg) {
        this.$message.error('选择的数据包含草稿状态的数据，请重新选择');
        return;
      }
      this.$confirm("确定将选择的数据撤回?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.modelLoading = true;
          dismissModel(ids).then(res => {
            if(res['data']['code'] === 200) {
              this.$message.success('撤回成功');
              this.getModels();
            } else {
              this.$message.error(res.data.msg || '')
            }
          })
        })
    },
    modelSelectionChange(list) {
      this.selectedModel = list;
    },
    onPropertyLoad() {
      this.getModelDetail(this.currentModelId);
    },
    propertyUpdate(row,index,done) {
      let temp = [];
      for(let i = 0,len = this.propertyList.length;  i < len; i ++ ) {
        let item = this.propertyList[i];
        let obj = {
          "cardinality": "SINGLE",
          "typeName":item['typeName'],
          "description": item['description'],
          "isIndexable": item['isIndexable'],
          "isOptional": item['isOptional'],
          "isUnique": item['isUnique'],
          "name": item['name'],
          "valuesMaxCount": 0,
          "valuesMinCount": 1
        };
        if(i === row['$index']){
          obj = {
            "cardinality": "SINGLE",
            "typeName":row['typeName'],
            "description": row['description'],
            "isIndexable": row['isIndexable']  || false,
            "isOptional": row['isOptional']  || false,
            "isUnique": row['isUnique']  || false,
            "name": row['name'],
            "valuesMaxCount": 0,
            "valuesMinCount": 1
          };
        }
        temp.push(obj);
      }
      let obj = {
        "attributeDefs": temp
        //'guid':this.guid
      };
      updateProperty(obj,this.currentModelId).then( res => {
        if(res['data']['code'] === 200){
          this.getModelDetail(this.currentModelId);
        } else {
          this.$message.error(res['data']['msg']);
        }
        done();
      });
    },
    handlePropertySave(row,done) {
      let temp = [
        {
          "cardinality": "SINGLE",
          "typeName":row['typeName'],
          "description": row['description'],
          "isIndexable": row['isIndexable'] || false,
          "isOptional": row['isOptional'] || false,
          "isUnique": row['isUnique'] || false,
          "name": row['name'],
          "valuesMaxCount": 0,
          "valuesMinCount": 1
        }
      ];
      for(let i = 0,len = this.propertyList.length;  i < len; i ++ ) {
        let item = this.propertyList[i];
        let obj = {
          "cardinality": "SINGLE",
          "typeName":item['typeName'],
          "description": item['description'],
          "isIndexable": item['isIndexable'],
          "isOptional": item['isOptional'],
          "isUnique": item['isUnique'],
          "name": item['name'],
          "valuesMaxCount": 0,
          "valuesMinCount": 1
        };
        temp.push(obj);
      }
      let obj = {
        "attributeDefs": temp
        //'guid':this.guid
      };
      updateProperty(obj,this.currentModelId).then( res => {
        if(res['data']['code'] === 200){
          this.getModelDetail(this.currentModelId);
        } else {
          this.$message.error(res['data']['msg']);
        }
        done();
      });
    },
    propertySelectionChange(list) {
      this.selectedPropertyList = list;
    },
    handleEdit(row) {
      this.$refs.property.rowEdit(row);
    },
    delProperty(row) {
      // if(this.selectedPropertyList.length === 0 ) {
      //   this.$message.warning("请选择至少一条数据");
      //   return;
      // }
      this.$confirm("确定将选择的数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.handlePropertyDel(row);
        })
    },
    handlePropertyDel(row) {
      let temp = [];
      for(let i = 0,len = this.propertyList.length; i <  len; i ++ ) {
        let item = this.propertyList[i];
        // let flg = false;
        // for(let j = 0,jlen = this.selectedPropertyList.length; j <  jlen; j ++ ) {
        //   let sel = this.selectedPropertyList[j];
        //   if(i === sel['$index']){
        //     flg = true
        //   }
        // }
        if(item.name !== row.name) {
          let obj = {
            "cardinality": "SINGLE",
            "typeName":item['typeName'],
            "description": item['description'],
            "isIndexable": item['isIndexable'],
            "isOptional": item['isOptional'],
            "isUnique": item['isUnique'],
            "name": item['name'],
            "valuesMaxCount": 0,
            "valuesMinCount": 1
          };
          temp.push(obj);
        }
      }
      let obj = {
        "attributeDefs": temp
        //'guid':this.guid
      };
      updateProperty(obj,this.currentModelId).then( res => {
        if(res['data']['code'] === 200) {
          this.getModelDetail(this.currentModelId);
        } else {
          this.$message.error(res.data.msg)
        }

      });
    },
    handlePropertyUp(row) {
      // if(this.selectedPropertyList.length !== 1 ) {
      //   this.$message.warning("请选择一条数据");
      //   return;
      // }
      // let item = this.selectedPropertyList[0];
      let index = row['$index'];
      if( index === 0 ) {
        return;
      } else {
        let tempProperty = this.propertyList[index - 1];
        this.propertyList[index - 1] = this.propertyList[index];
        this.propertyList[index] = tempProperty;
        let temp = [];
        for(let i = 0,len = this.propertyList.length;  i < len; i ++ ) {
          let item = this.propertyList[i];
          let obj = {
            "cardinality": "SINGLE",
            "typeName":item['typeName'],
            "description": item['description'],
            "isIndexable": item['isIndexable'],
            "isOptional": item['isOptional'],
            "isUnique": item['isUnique'],
            "name": item['name'],
            "valuesMaxCount": 0,
            "valuesMinCount": 1
          };
          temp.push(obj);
        }
        let obj = {
          "attributeDefs": temp
          //'guid':this.guid
        };
        updateProperty(obj,this.currentModelId).then( res => {
          if(res['data']['code'] === 200){
            this.getModelDetail(this.currentModelId);
          } else {
            this.$message.error(res['data']['msg']);
          }
        });
      }

    },
    handlePropertyDown(row) {
      // if(this.selectedPropertyList.length !== 1 ) {
      //   this.$message.warning("请选择一条数据");
      //   return;
      // }
      //let item = this.selectedPropertyList[0];
      let index = row['$index'];
      if( index === this.propertyList.length - 1 ) {
        return;
      } else {
        let tempProperty = this.propertyList[index + 1];
        this.propertyList[index + 1] = this.propertyList[index];
        this.propertyList[index] = tempProperty;
        let temp = [];
        for(let i = 0,len = this.propertyList.length;  i < len; i ++ ) {
          let item = this.propertyList[i];
          let obj = {
            "cardinality": "SINGLE",
            "typeName":item['typeName'],
            "description": item['description'],
            "isIndexable": item['isIndexable'],
            "isOptional": item['isOptional'],
            "isUnique": item['isUnique'],
            "name": item['name'],
            "valuesMaxCount": 0,
            "valuesMinCount": 1
          };
          temp.push(obj);
        }
        let obj = {
          "attributeDefs": temp
         // 'guid':this.guid
        };
        updateProperty(obj,this.currentModelId).then( res => {
          if(res['data']['code'] === 200){
            this.getModelDetail(this.currentModelId);
          } else {
            this.$message.error(res['data']['msg']);
          }
        });
      }
    }
  }
}
</script>
<style lang="scss">
  .meta-model {
    position: relative;
    a {
      color: #337ab7 !important;
      cursor: pointer;
    }
    .context-menu {
      position: fixed;
      padding: 0;
      width: 132px;
      height: 142px;
      border-radius: 4px;
      border: 1px solid #ebeef5;
      .btn {
        cursor: pointer;
      }
    }
    .basic-container {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    .model-height{
      height: calc(100vh - 124px)!important;
      overflow: auto;
    }
    .meta-container {
      width: calc(79.16667% - 8px);
      margin-left: 8px;
    }
  }
</style>

<template>
  <el-form :v-model="dataSource" class="ov-h">
    <el-row type="flex" class="mar-b-10">
      <el-col class="w-240 mar-r-10">
        <el-select
          class="w-240"
          v-model="dataSourceId"
          size="small"
          placeholder="请选择数据源"
          @change="selectClassfy"
        >
          <el-option
            v-for="item in data"
            :key="item.id"
            :label="item.name"
            :value="item.code"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col class="w-240">
        <el-input
          class="w-240"
          v-model="modelName"
          size="small"
          clearable
          @clear="keyupClick"
          @keyup.enter.native="keyupClick"
          placeholder="请输入模型名称回车搜索"
        >
          <el-button
            slot="append"
            @click="keyupClick"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </el-col>
      <el-col :span="1" style="flex: 1" class="text-right">
        <el-button size="small" type="primary" icon="el-icon-refresh" @click="collectionDatasource">同步</el-button>
      </el-col>
    </el-row>
    <el-row class="modelsTreeTable">
      <el-col :span="24">
        <avue-crud
          ref="table"
          :data="tables"
          :page.sync="page"
          :option="tablesOption"
          :table-loading="contentLoading"
          element-loading-text="正在加载中..."
          @row-click="showDetail"
          @on-load="showTables"
          @refresh-change="showTables"
        >
          <template slot="menu" slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="handleRowClick(scope.row)"
              >选择</el-button
            >

            <el-button
              v-if="menuDo == 'showDetail'"
              type="text"
              size="small"
              @click="showDetail(scope.row)"
              >详情</el-button
            ></template
          >
        </avue-crud>
      </el-col>
    </el-row>
  </el-form>
</template>
<script>
import {
  getTree,
  findTableByDatasourceid,
  listPaging,
} from "@/api/dataService/appModel";
import { getList, getDetail, add, update, remove , collectionDatasource} from "@/api/tool/datasource";
import { mapActions } from "vuex";

export default {
  name: "modelTree",
  data() {
    return {
      data: [],
      isShowSelect: false,
      contentLoading: false,
      page: {},
      tables: [],
      tableAll: [],
      modelName: "",
      rowData: {},
      modelVisible: false,
      dataSourceObj: {},
      dataSourceId: "",
      tablesOption: {
        tip: false,
        page: false,
        searchMenuSpan: 1,
        editBtn: false,
        delBtn: false,
        addBtn: false,
        refreshBtn: false, //刷新按钮
        columnBtn: false, //列显隐按钮
        menuWidth: 120,
        column: [
          {
            label: "类型",
            prop: "tableType",
            display: false,
            search: false,
            width: 100,
          },
          {
            label: "模型名称",
            prop: "tableName",
            width: 150,
            display: false,
            search: false,
          },
          {
            label: "中文名",
            prop: "description",
            display: false,
            search: false,
          },
          {
            label: "字段数量",
            prop: "columnCount",
            width: 100,
            display: false,
            search: false,
          },
          {
            label: "最后修改时间",
            prop: "lastUpdateDate",
            width: 150,
            display: false,
            search: false,
          },
          {
            label: "负责人",
            prop: "owner",
            width: 100,
            display: false,
            search: false,
          },
        ],
      },
    };
  },
  watch: {
    dataSource: {
      handler(dataSource) {
        if (this.isShowSelect) {
          this.$refs.selectTree.filter(form.dataSourceId);
        }
      },
      deep: true,
    },
  },
  props: {
    dataSource: {
      type: Object,
      default: function () {
        return { code: "", name: "" };
      },
    },
    menuDo: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  created() {
    if (this.menuDo == "") {
      this.tablesOption.height = 400;
    } else {
      this.tablesOption.height = 600;
    }
    this._getList();
    this.dataSourceObj = this.dataSource;
  },
  mounted() {
    // this._getList();
  },
  methods: {
    collectionDatasource(){
      if(this.dataSourceObj && this.dataSourceObj.id){
        collectionDatasource(this.dataSourceObj.id).then(
          res => {
            this.findTableByDatasourceid(this.dataSourceObj.code , false)
            this.$message({
              message: '同步指令已下发，请3分钟后刷新列表查看',
              type: 'success'
            });
          }
        )
      }
    },
    keyupClick() {
      if (this.modelName == "") {
        this.findTableByDatasourceid(this.dataSourceId, false);
      } else {
        let arr = [];
        this.tables.forEach((e) => {
          for (let key in e) {
            if (JSON.stringify(e[key]).includes(this.modelName)) {
              arr.push(e);
            }
          }
        });
        this.tables = arr;
      }
    },
    _getList() {
      this.contentLoading = true;
      listPaging({
        pageSize: 50,
        pageNo: 1,
      })
        .then((res) => {
          this.data = [];
          console.log(res);
          this.data = res.data.data.totalList;
          if (this.data[0]) {
            this.dataSourceObj = this.data[0];
            this.dataSourceId = this.data[0].code;
            this.dataSource.code = this.data[0].code;
            this.dataSource.name = this.data[0].name;
            this.contentLoading = false;
            this.findTableByDatasourceid(this.data[0].code);
          }
        })
        .catch((e) => {});
    },
    showTables() {
      let row = this.currentDatabase;
      this.tables = [];
      if (!row || !row["guid"]) {
        return;
      }
      this.contentLoading = true;
      let obj = {
        id: row["dhId"],
        guid: row["guid"],
        databaseId: row["dataBaseId"],
        pageSize: this.page.pageSize,
        pageNo: this.page.currentPage,
      };
    },
    getDataSourceList() {
      getList(this.page.currentPage, this.page.pageSize).then((res) => {
        this.data = res.data.data.records;
      });
    },
    getTree() {
      getTree()
        .then((res) => {
          this.data = res.data.data;
        })
        .catch((e) => {});
    },
    findTableByDatasourceid(datasourceid, isSimple) {
      if (datasourceid) {
        this.contentLoading = true;
        let o = {
          datasourceType: 0,
          datasourceCode: datasourceid,
        };

        findTableByDatasourceid(o)
          .then((res) => {
            this.tables = res["data"]["data"] || [];
            this.tableAll = res["data"]["data"] || [];
            this.page.total = res["data"]["total"];
            this.contentLoading = false;
          })
          .catch((e) => {
            this.contentLoading = false;
          });
      }
    },
    handleRowClick(data) {
      this.rowData = data;
      this.$emit("selectedNode", [
        this.dataSourceObj,
        this.rowData,
        this.tableAll,
      ]);
    },
    showDetail(data) {
      if (this.menuDo == "showDetail") {
        this.$emit("showDetail", [this.dataSourceObj, data]);
      } else {
        this.handleRowClick(data);
      }
    },
    closeVisibal() {
      if (!this.rowData) {
        this.$message.warning("请选择一条数据进行操作!");
      }
      this.modelVisible = false;
      this.$emit("selectedNode", [
        this.dataSourceObj,
        this.rowData,
        this.tableAll,
      ]);
    },
    selectClassfy() {
      this.data.forEach((e) => {
        if (e.code == this.dataSourceId) {
          this.dataSourceObj = e;
        }
      });
      this.tables = [];
      this.findTableByDatasourceid(this.dataSourceObj.code, false);
    },
    changeSelectTree() {
      this.isShowSelect = true;
    },
    selectionChange(selection) {
      this.rowData = selection;
      this.$emit("showDetail", [this.dataSourceObj, data]);
    },
    hideParentClick(e) {
      var isOther =
        e.relatedTarget == null ||
        e.relatedTarget.closest("div.el-tree") == null ||
        e.relatedTarget.closest("div.el-tree").id != "floatTree";

      if (isOther) {
        this.isShowSelect = false;
      } else {
        e.target.focus();
      }
    },
    // 选择器的树节点
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
  },
};
</script>

<style scoped lang="scss">
/*下拉框选择树*/
.objectTree {
  margin-left: 97px;
  position: absolute;
  overflow: auto;
  z-index: 100;
  height: 200px;
  border: 1px solid #ddd;
  line-height: normal;
  z-index: 204;
}
.width-220 {
  width: 220px;
}
.f-l {
  float: left;
}
.meta-height {
  height: calc(100vh - 124px) !important;
  overflow: auto;
}
.modelsTreeTable {
  /deep/ .avue-crud__menu {
    display: none;
  }
}
/deep/ .el-scrollbar__wrap {
  width: 100%;
}
/deep/ .el-scrollbar__bar.is-vertical {
  display: none;
}
</style>

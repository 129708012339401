<template>
    <div>
        <employee v-if="!isCompany" :companyNode="node" :selection="true" :identity="2"></employee>

        <employeeAll v-else :identity="2"></employeeAll>

    </div>
</template>

<script>
import employeeAll from './index';
import employee from './employee';

import { mapGetters, mapState } from 'vuex';

export default {
    components: { employee, employeeAll },
    data() {
        return {
            isCompany: true,
            node: '',
        };
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    mounted() {
        if (this.userInfo.companyId && this.userInfo.tenant_id == '000002') {
            this.isCompany = false;
            let dept_id = '';
            if (this.userInfo.role_name.indexOf('administrator') < 0) {
                if (this.userInfo.dept_id) {
                    if (this.userInfo.dept_id.split(',').length > 1) {
                        let deptIds = this.userInfo.dept_id.split(',');
                        dept_id = deptIds[0];
                    } else {
                        dept_id = this.userInfo.dept_id;
                    }
                }
            }
            console.log(dept_id);
            this.node = {
                id: this.userInfo.companyId,
                dept_id: dept_id,
            };
        }
        console.log(this.userInfo, 'this.userInfo');
    },
    methods: {},
};
</script>

<style></style>

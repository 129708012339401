/**
 * 工作流统一分类格式
 * @param category 分类字典号
 * @returns {string}
 */
export function flowCategory(category) {
  return `flow_${category}`;
}

/**
 * 根据key获取流程路由
 * @param routes
 * @param key
 */
export function flowRoute(routes, key) {
  const data = routes.filter(d => {
    return d.routeKey === key;
  });
  return data.length === 0 ? [] : data[0].routeValue;
}

/**
 * 
 * @param {Blob} fileData 下载文件的二进制数据
 * @param {String} fileName 文件名称
 * @returns 
 */
export const downloadBlob = (fileData, fileName = '') => {
  if (!fileData) {
    return
  }
  const blob = new Blob([fileData])
  const _fileName = `${decodeURIComponent(fileName)}`
  if ('download' in document.createElement('a')) { // 不是IE浏览器
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.style.display = 'none'
    link.href = url
    link.setAttribute('download', _fileName)
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link) // 下载完成移除元素
    window.URL.revokeObjectURL(url) // 释放掉blob对象
  } else { // IE 10+
    window.navigator.msSaveBlob(blob, _fileName)
  }
}
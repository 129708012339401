<template>
  <div>
    <div :style="position === 1 ? getNumberStyle() : getTitleStyle()">{{position === 1 ? value : title}}</div>
    <div :style="position === 1 ? getTitleStyle() : getNumberStyle()">{{position === 1 ? title : value}}</div>
  </div>
</template>
<script>

import WidgetMixins from '../widget-mixins'

import { assign , getStyleByObject } from '../../util';

export default {
  mixins: [ WidgetMixins ],
  name: 'BuiltInWidgetByNumCard',
  data(){
    return {
      title: '',
      value: '',
      position: 1
    }
  },
  mounted(){

  },
  methods: {
    refresh(type){
      if(this.widget){
        if(['widget.private' , 'widget.dataOption' , 'data'].includes(type)){
          this.setCard();
        }
      }
    },
    setCard(){
      const { name , nameType , value} = this.widget.dataOption;
      const data = this.getObjData();
      if(data){
        if(nameType === 'field'){
          this.title = data[name];
        }else{
          this.title = name;
        }
        this.value = data[value];
      }
      if(this.widget.private.position !== undefined){
        this.position = this.widget.private.position;
      }
    },
    getNumberStyle(){
      return getStyleByObject(this.widget ? (this.widget.private.numStyle || {}) : {});
    },
    getTitleStyle(){
      return getStyleByObject(this.widget ? (this.widget.private.titleStyle || {}) : {});
    }
  }
}
</script>
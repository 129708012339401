<template>
  <el-card>
    <!-- 年和月 -->
    <el-row type="flex" justify="end">
      <!-- 年 用组件给定一个日期，日期获取年，年取前五年 + 后五年 -->
      <el-select v-model="currentYear" size="small" style="width: 120px" @change="dataChange">
        <el-option v-for="item in yearList" :key="item" :label="item" :value="item" />
      </el-select>
      <!-- 月份 -->
      <el-select v-model="currentMonth" size="small" style="width: 120px;margin-left: 10px" @change="dataChange">
        <!-- 循环 -->
        <el-option v-for="item in 12" :key="item" :label="item" :value="item" />
      </el-select>
    </el-row>
    <!-- 放置一个日历组件 -->
    <!-- <el-calendar v-model="currentDate">

      <template slot="dateCell" slot-scope="{ date, data }"> -->
        <!-- date是当前单元格的日期 data是对象 对象里有要显示的day -->
        <!-- <template v-slot:dateCell="{ date, data }"> -->
        <!-- <div class="date-content">
          <span class="text"> {{ data.day | getDay }}</span>
          {{data}}
          <span v-if="isWeek(date)" class="rest">休</span>
          <span v-if="isWork(date)" class="work">班</span>
        </div>
      </template>
    </el-calendar> -->
    <el-calendar :v-model="rangCal" v-if="showcal">
    <!-- <template slot="dateCell"  slot-scope="{date, data }">-->
      <!-- date是当前单元格的日期 data是对象 对象里有要显示的day -->
      <template v-slot:dateCell="{ date, data }">
      <div slot="reference" @click="checkDay(data.day)" class="date-content">
        <span class="text"> {{ data.day | getDay }}</span>
        <!-- <span>{{data.day}}</span> -->
        <span v-for="item in dayDatas" :key="item.id">
          <span class="rest" v-if="item.day === data.day && item.dayType==='H'">休</span>
          <span class="work" v-if="item.day === data.day && item.dayType==='W'">班</span>
        </span>
      </div>
    </template>
    </el-calendar>
  </el-card>
</template>

<script>
import {filterCalendar, toogleCalendar} from "@/api/spcod/sportcalendar";

export default {
  filters: {
    getDay(value) {
      //console.log('getDay:',value);
      const day = value.split('-')[2]
      return day.startsWith('0') ? day.substr(1) : day
    }
  },

  data() {
    return {
      yearList: [], // 要遍历的年的数组
      currentYear: null, // 当前年份
      currentMonth: null, // 当前月份
      currentDate: null, // 当前时间
      rangCal: [],
      dayDatas: [],
      showcal:false
    }
  },
  created() {
    // 获取当前的年份
    this.currentYear = new Date().getFullYear() // 得到当前的年份
    this.currentMonth = new Date().getMonth() + 1 // 当前月份
    this.yearList = Array.from(Array(11), (v, i) => this.currentYear + i - 5)
    this.dataChange() // 主动调用一下方法
  },
  methods: {
    dataChange() {
      let _this = this;
      // 生成新的日期
      this.currentDate = new Date(`${this.currentYear}-${this.currentMonth}-01`) // 以1号为开始
      _this.dayDatas = [];
      _this.rangCal = [];
      _this.showcal = false;
      filterCalendar(this.currentYear + '-' + this.currentMonth).then(res => {
          //const data = res.data.data;
          // this.currentDate = res.data.data;
          let day = res.data.data.map((i) => {
            return i.day;
          })
          console.log('rangCal:');
          console.log(day)
          console.log('dayDatas:');
          console.log(res.data.data);
          _this.rangCal = day;
          _this.dayDatas = res.data.data;
          _this.$forceUpdate();
          _this.showcal = true;
        });
    },
    // 判断当前是否是周末
    isWeek(date) {
      return date.getDay() === 6 || date.getDay() === 0
    },
    // 判断当前是否工作日
    isWork(date) {
      return date.getDay() !== 6 && date.getDay() !== 0
    },
    checkDay(day) {
      //通过workaround来解决了！
      console.log(day,typeof day);
      day = day.split('-')[2];
      console.log(day);
      if(day.length == 1)
        day = '0' + day;
      if(this.currentMonth.length == 1)
        day = this.currentMonth + '-0' + day;
      else
        day = this.currentMonth + '-' + day;
      day =  this.currentYear + '-' + day;
      let currentId = '';
      this.dayDatas.forEach((i) =>{
        //console.log('sel:',i.day);
        if(i.day === day) {
          // 加提示
          // this.$alert('确认修改状态吗？', '确认').then(() => {
          //   toogleCalendar(i.day).then((res) => {
          //     if(res.data.success) {
          //       if(i.dayType==='W') {
          //         i.dayType = 'H'
          //         this.$forceUpdate()
          //       }else if(i.dayType==='H') {
          //         i.dayType = 'W'
          //         this.$forceUpdate()
          //       }
          //       this.$message.success(res.data.msg)
          //     } else {
          //       this.$message.error(res.data.msg)
          //     }

          //   })
          // })

          //不加提示
          toogleCalendar(i.day).then((res) => {
              if(res.data.success) {
                if(i.dayType==='W') {
                  i.dayType = 'H'
                  this.$forceUpdate()
                }else if(i.dayType==='H') {
                  i.dayType = 'W'
                  this.$forceUpdate()
                }
                this.$message.success(res.data.msg)
              } else {
                this.$message.error(res.data.msg)
              }
            })
          currentId = i.id;
        }
      })
      console.log(currentId)
    }
  }
}
</script>

<style  scoped>
 div /deep/ .el-calendar-day {
  height:  auto;
 }
 div /deep/ .el-calendar-table__row td,div /deep/ .el-calendar-table tr td:first-child, div /deep/ .el-calendar-table__row td.prev{
  border:none;
 }
.date-content {
  height: 40px;
  text-align: center;
  line-height: 40px;
  font-size: 14px;
}
.date-content .rest {
  color: #fff;
  border-radius: 50%;
  background: rgb(250, 124, 77);
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  font-size: 12px;
  margin-left: 10px;
}
.date-content .work {
  color: #fff;
  border-radius: 50%;
  background: rgb(91, 77, 250);
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  font-size: 12px;
  margin-left: 10px;
}
.date-content .text{
  width: 20px;
  height: 20px;
  line-height: 20px;
 display: inline-block;

}
 div /deep/ .el-calendar-table td.is-selected .text{
   background: #409eff;
   color: #fff;
   border-radius: 50%;
 }
 div /deep/ .el-calendar__header {
   display: none
 }
</style>

import { getStyle } from './style';

export default {
  data(){
    return {
      style: null
    }
  },
  watch: {
    option: {
      immediate: true,
      handler(){
        this.style = this.getStyle();
      }
    },
    data: {
      immediate: true,
      handler(){
        this.dataChange && this.dataChange();
      }
    }
  },
  methods: {
    getStyle(){
      return getStyle(this.widget.optionList , this.option , this.styleNameDict || {});
    },
    getDataOption(){
      let result = {};
      if(this.widget && this.widget.datasourceConf && this.widget.datasourceConf.dataOption){
        (this.widget.datasourceConf.dataOption || []).forEach(
          item => {
            result[item.code] = item['value'] || item['testValue'] || '';
          }
        )
      }
      return result;
    },
  },
  props: {
    widget: {
      type: Object,
      default(){
        return null;
      }
    },
    option: {
      type: Object,
      default(){
        return null;
      }
    },
    variables: {
      type: Array,
      default(){
        return [];
      }
    },
    dict: {
      type: Array,
      default(){
        return [];
      }
    },
    data: {
      type: [Object , Array , Boolean , String , Number],
      default: null
    },
    isTest: {
      type: Boolean,
      default: false
    }
  },
  
}
import * as dictService from "@/api/system/dictbiz";

export const option = {
  height: "auto",
  dialogWidth: 900,
  tip: false,
  searchShow: true,
  searchMenuSpan: 4,
  border: true,
  index: true,
  // selection: true,
  labelWidth: 120,
  menuWidth: 300,
  menuAlign: "left",
  viewBtn: false,
  dialogClickModal: false,
  delBtn: true,
  editBtn: false,
  emptyBtn: true,
  addBtn: false,
  cancelBtn: false,
  column: [
    {
      overHidden: true,
      label: "企业名称",
      align: "center",
      prop: "company_name",
      hide: false,
    },
    // {
    //   overHidden: true,
    //   label: "授权角色",
    //   align: "center",
    //   prop: "roleIds",
    //   hide: false,
    // },
    {
      overHidden: true,
      label: "备注",
      align: "center",
      prop: "notes",
      hide: false,

      searchSpan: 5,
    },
  ],
};

<template>
  <basic-container>
    <avue-crud
      ref="table"
      :data="tableData"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      :search.sync="search"
      v-model="form"
      @search-change="searchChange"
      @on-load="onLoad"
      @refresh-change="onLoad"
      @row-save="saveTableForm"
      @row-update="(row, index, done, loading) => saveTableForm(row, done, loading)"
      @row-del="rowDel"
    >
      <!-- <template slot="attachment_idForm" slot-scope="{disabled,size}">

      </template> -->
    </avue-crud>
  </basic-container>
</template>
<script>
import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  delMetaCommon,
  getPicListByIds
} from "@/api/busiMode/metaCommon";
import * as dictService from "@/api/system/dictbiz";
export default {
  data(){
    return {
      form: {

      },
      model: 'wisdom_park.wp_address_label',
      tableLoading: false,
      tableData: [],
      search: {},
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableOption: {
        height: 'auto',
        labelPosition: 'top',
        searchMenuPosition: 'right',
        searchLabelPosition: 'right',
        searchMenuSpan: 24,
        border: true,
        column: [
          {
            label: "名称",
            prop: "name",
            width: 200,
            span: 12,
            search: true,
          },
          {
            label: "类型",
            prop: "type",
            width: 200,
            span: 12,
            type: 'select',
            dicData: [
              { label: "配送区域", value: 1},
              { label: "自提点", value: 2}
            ],
            search: true,
          },
          {
            label: "详细信息",
            prop: "note",
            span: 24,
            type: 'textarea'
          },
        ]
      }
    }
  },
  mounted(){
    
  },
  methods: {
    onLoad(){
      let wheres = [];
      ['name' , 'type' , 'desc'].forEach(
        name => {
          if(this.search[name]){
            if(name === 'name'){
              wheres.push({field: name , op: 'LIKE' , value: this.search[name]})
            }else{
              wheres.push({field: name , value: this.search[name]})
            }
          }
        }
      )
      this.tableData = [];
      this.tableLoading = true;
      return getMetaCommonList(this.model , {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres
      }).then(
        res => {
          this.tableLoading = false;
          if(res.data && res.data.code === 200){
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
          }else{
            this.tableData = [];
            this.page.total = 0;
          }
        }
      ).catch(e => {
        this.tableLoading = false;
      })
    },
    searchChange(params, done) {
      this.page.currentPage = 1;
      this.onLoad(params)
        .then((res) => {
          done();
        })
        .catch((e) => {
          done();
        });
    },
    saveTableForm(row, done, loading) {
      // console.log(row);
      let body = {
        ...row
      };

      Object.keys(body).forEach(
        key => {
          if(key[0] === '$'){
            delete body[key];
          }
        }
      );
     
      if(row.id){
        body = {
          update: body,
          primaryKeyValue: row.id,
          primaryKey: "id",
        };
      }else{
        body = {
          values: body
        }
      };
      (row.id ? updateMetaCommon : saveMetaCommon)(this.model , body).then((res) => {
        this.responeHandle(res, done, loading);
      }).catch((e) => {
        loading();
      });
    },
    responeHandle(res, done, loading) {
      if (res.data && res.data.code === 200) {
        done && done();
        this.onLoad();
        this.$message.success(res.data.msg);
      } else {
        loading && loading();
        this.$message.error(res.data.msg);
      }
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        return delMetaCommon(this.model , {pk: 'id' , ids: [row.id]});
      }).then(() => {
        this.onLoad();
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    },
  }
}
</script>
<template>
  <basic-container>
    <avue-crud
      ref="crud"
      v-model="timeObj"
      :data="tableData"
      :table-loading="taskLoading"
      :page.sync="page"
      :option="option"
      :before-close="beforeClose"
      @on-load="onLoad"
      @refresh-change="onLoad"
      @row-update="timeSubmit"
    >
      <template slot="menuLeft">
        <el-button @click="$router.back()" icon="el-icon-back" type="text" size="small">返回</el-button>
      </template>
      <template slot-scope="{type,size,row}" slot="menu">
        <el-button @click.stop="handleEdit(row)" :disabled="row.releaseState === 'ONLINE'" icon="el-icon-edit" :size="size" :type="type">编辑</el-button>
        <el-button @click.stop="rowDel(row)" :disabled="row.releaseState === 'ONLINE'" icon="el-icon-delete" :size="size" :type="type">删除</el-button>
        <el-button @click="_poponline(row)" v-if="row.releaseState === 'OFFLINE'" icon="el-icon-upload2" :size="size" :type="type">上线</el-button>
        <el-button @click="_downline(row)" v-if="row.releaseState === 'ONLINE'" icon="el-icon-download" :size="size" :type="type">下线</el-button>
      </template>
      <template slot-scope="{row}" slot="timerForm">
        <el-popover
          placement="bottom-start"
          trigger="click">
          <template slot="reference">
            <el-input
              style="width: calc(100% - 90px)"
              type="text"
              size="small"
              readonly
              :value="crontab">
            </el-input>
          </template>
          <div class="crontab-box">
            <v-crontab v-model="crontab" :locale="i18n"></v-crontab>
          </div>
        </el-popover>

        <el-button class="mar-l-10" type="primary"  @click="showPreviewClick">执行时间</el-button>
        <div>接下来五次执行时间</div>
        <template v-if="showPreview">
          <div v-for="(item,index) in exectPreview">
            {{item}}
          </div>
          <div v-if="exectPreview.length === 0">
            暂无数据
          </div>
        </template>

      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
  import { mapActions } from 'vuex';
  import i18n from '@/lang';
  import datasourceTypeList from '@/config/datasourceTypeList'
  import failureStrategyList from '@/config/failureStrategyList'
  import processInstancePriorityList from '@/config/processInstancePriorityList'
  import warningTypeList from '@/config/warningTypeList'
  import { vCrontab } from '@/view-module/scheduler/common/components/crontab/index'
  export default {
    components: { vCrontab },
    data() {
      return {
        taskLoading:false,
        showPreview:false,
        cronPopover:false,
        runFormVisible:false,
        timeFormVisible:false,
        copyFormVisible:false,
        tableData: [],
        datasourceTypeList:datasourceTypeList,
        databases: [],
        versions:[],
        workFlowName:'',
        processDefinitionId:'',
        workGroupList:[],
        page: {
          pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        option: {
          addBtn:false,
          editBtn:false,
          delBtn:false,
          column:[
            {
              label:'名称',
              prop:'processDefinitionName',
              display:false
            },
            {
              label: '开始时间',
              prop: 'startTime',
              type: "date",
              format: "yyyy-MM-dd hh:mm:ss",
              width: 140,
              display:false
            },
            {
              label: '结束时间',
              prop: 'endTime',
              type: "date",
              format: "yyyy-MM-dd hh:mm:ss",
              width: 140,
              display:false
            },
            {
              label:'crontab',
              prop:'crontab',
              display:false
            },
            {
              label:'失败策略',
              prop:'failureStrategy',
              display:false
            },
            {
              label:'定时状态',
              prop:'releaseState',
              dicData: [
                {
                  label: '上线',
                  value: 'ONLINE'
                },
                {
                  label: '下线',
                  value: 'OFFLINE'
                }
              ],
              display:false
            },
            {
              label: '创建时间',
              prop: 'createTime',
              type: "date",
              format: "yyyy-MM-dd hh:mm:ss",
              width: 140,
              display:false
            },
            {
              label: '更新时间',
              prop: 'updateTime',
              type: "date",
              format: "yyyy-MM-dd hh:mm:ss",
              width: 140,
              display:false
            },
          ],
          group: [
            {
              column: [
                {
                  label: "起止时间",
                  type: 'datetimerange',
                  prop:'datetimerange',
                  defaultTime: ['2021-08-04 12:00:00', '2021-08-05 08:00:00'],
                  span:24
                },
                {
                  label: "定时",
                  prop:'timer',
                  span:24
                },
                {
                  label: "失败策略",
                  prop: "failureStrategy",
                  type: "radio",
                  dicData: failureStrategyList
                },
                {
                  label: "通知策略",
                  prop: "warningType",
                  type: "select",
                  dicData: warningTypeList
                },
                {
                  label: "流程优先级",
                  prop: "processInstancePriority",
                  type: "select",
                  dicData: processInstancePriorityList
                },
                {
                  label: "Worker分组",
                  prop: "workerGroup",
                  props: {
                    label: "name",
                    value: "id"
                  },
                  type: "select",
                  dicData: []
                }
              ]
            }
          ]
        },
        runObj:{
          'failureStrategy':'CONTINUE',
          'warningType':'NONE',
          'processInstancePriority':'MEDIUM',
          'workerGroup':'default'
        },
        runOption: {
          size: 'small',
          labelWidth: 120,
          menuPosition:'right',
          submitBtn:false,
          emptyBtn:false,
          column:[
            {
              label: "任务名称",
              prop: "name",
              span: 24
            },
            {
              label: "失败策略",
              prop: "failureStrategy",
              type: "radio",
              dicData: failureStrategyList
            },
            {
              label: "通知策略",
              prop: "warningType",
              type: "select",
              dicData: warningTypeList
            },
            {
              label: "流程优先级",
              prop: "processInstancePriority",
              type: "select",
              dicData: processInstancePriorityList
            },
            {
              label: "Worker分组",
              prop: "workerGroup",
              props: {
                label: "name",
                value: "id"
              },
              type: "select",
              dicData: []
            }
          ]
        },
        timeObj:{
          'failureStrategy':'CONTINUE',
          'warningType':'NONE',
          'processInstancePriority':'MEDIUM',
          'workerGroup':'default',
          'datetimerange':''
        },
        timeOption:{
          size: 'small',
          labelWidth: 120,
          menuPosition:'right',
          submitBtn:false,
          emptyBtn:false,
          column:[
            {
              label: "起止时间",
              type: 'datetimerange',
              prop:'datetimerange',
              span:24
            },
            {
              label: "定时",
              prop:'timer',
              span:24
            },
            {
              label: "失败策略",
              prop: "failureStrategy",
              type: "radio",
              dicData: failureStrategyList
            },
            {
              label: "通知策略",
              prop: "warningType",
              type: "select",
              dicData: warningTypeList
            },
            {
              label: "流程优先级",
              prop: "processInstancePriority",
              type: "select",
              dicData: processInstancePriorityList
            },
            {
              label: "Worker分组",
              prop: "workerGroup",
              props: {
                label: "name",
                value: "id"
              },
              type: "select",
              dicData: []
            }
          ]
        },
        crontab:'0 0 * * * ? *',
        i18n: i18n.globalScope.LOCALE,
        exectPreview:[],
        copyObj:{},
        copyOption:{
          size: 'small',
          labelWidth: 90,
          menuPosition:'right',
          submitBtn:false,
          emptyBtn:false,
          group: [
            {
              label: '基础信息',
              prop: 'baseInfo',
              column: [
                {
                  label: "名称",
                  prop: "name",
                  rules: [{
                    required: true,
                    message: "请输入名称",
                    trigger: "blur"
                  }],
                },
                {
                  label: "描述",
                  prop: "description",
                  type: "textarea",
                  span: 24
                },
              ]
            },
            {
              label: '数据来源',
              prop: 'dataRelation',
              column: [
                {
                  label: "数据源",
                  prop: "relation",
                  span: 24
                }
              ]
            }
          ]
        }
      }
    },
    mounted() {
      this.setWorkGroup();
    },
    methods: {
      ...mapActions('dag', ['getScheduleList', 'scheduleOffline', 'scheduleOnline', 'deleteTiming','previewSchedule','updateSchedule']),
      ...mapActions('security', ['getWorkerGroupsAll']),
      transformDate(time){
        if(!time) return '-';
        let date = new Date(time);
        let year = date.getFullYear();
        let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1):(date.getMonth() + 1) ;
        let day = date.getDay() < 10 ? '0' + date.getDay() : date.getDay();
        let hours = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
        let minutes = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        let seconds = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      },
      showPreviewClick() {
        let obj = {
          schedule: JSON.stringify({
            "startTime":this.transformDate(this.timeObj.datetimerange[0]),
            "endTime":this.transformDate(this.timeObj.datetimerange[1]),
            "crontab":this.crontab
          })
        };
        this.previewSchedule(obj).then(res => {
          this.exectPreview = res;
          this.showPreview = true;
        });

      },
      setWorkGroup() {
        let _t = this;
        this.getWorkerGroupsAll().then( res => {
          res.forEach(item => {
            _t.workGroupList.push(item);
          });
          _t.runOption.column[4]['dicData'] = _t.workGroupList;
          _t.timeOption.column[5]['dicData'] = _t.workGroupList;
        });
      },
      /*
      * 重置缓存数据
      */
      beforeClose(done) {
        done();
      },
      handleEdit(row){
        let _t = this;
        console.log(row)
        // this.timeObj.datetimerange = "['12:00:00', '08:00:00']"//`[${row['startTime']},${row['endTime']}]`;
        // console.log(this.timeObj.datetimerange)
        this.crontab = row['crontab'];
        this.$refs.crud.rowEdit(row);
      },
      rowDel(item) {
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            this.deleteTiming({
              scheduleId: item.id
            }).then(res => {
             this.onLoad();
              this.$message.success(res.msg)
            }).catch(e => {
              // this.$message.error(e.msg || '')
            })
          })
          .then(() => {
            // this.onLoad(this.page);
            // this.$message({
            //   type: "success",
            //   message: "操作成功!"
            // });
          });
      },
      /**
       * Offline
       */
      _downline (item) {
        this.scheduleOffline({
          id: item.id
        }).then(res => {
          this.$message.success(res.msg);
          this.onLoad()
        }).catch(e => {
          // this.$message.error(e.msg || '')
        })
      },
      _poponline (item) {
        this.scheduleOnline({
          id: item.id
        }).then(res => {
          this.$message.success(res.msg);
          this.onLoad()
        }).catch(e => {
          // this.$message.error(e.msg || '')
        })
      },

      timeManage(item) {
        this.$router.push({
          path: "/form-detail/index",
          query: {
            id: item['id']
          }
        });
      },
      timeSubmit(row,index,done,loading) {
        let obj = {
          id: row['id'],
          schedule: JSON.stringify({
            "startTime":this.transformDate(this.timeObj.datetimerange[0]),
            "endTime":this.transformDate(this.timeObj.datetimerange[1]),
            "crontab":this.crontab
          }),
          processDefinitionId:this.processDefinitionId,
          failureStrategy:this.timeObj['failureStrategy'],
          processInstancePriority:this.timeObj['processInstancePriority'],
          warningType:this.timeObj['warningType'],
          workerGroup:this.timeObj['workerGroup'],
          warningGroupId: 0 // 改造的页面没有这个字段，接口还需要传默认传0
        };
        this.updateSchedule(obj).then(res => {
          if(res['code'] === 0) {
            this.$message({
              type: "success",
              message: "操作成功!"
            });
            this.timeFormVisible = false;
          } else {
            // this.$message.error(e.msg || '')
          }
          done();
        }).catch(e => loading());
      },
      changeCron(val){
        this.cron=val
      },
      onLoad(){
        this.taskLoading = true;
        this.getScheduleList({
          pageSize: this.page.pageSize,
          pageNo: this.page.currentPage,
          processDefinitionId: this.$router.currentRoute.params.id,
        }).then(
          res => {
            if(res){
              this.page.total = res.data.total;
              this.tableData = res.data.totalList
            }
            this.taskLoading = false;
          }
        )
      }
    }
  }
</script>

<style lang="scss">
  .task-relation-select {
    display: flex;
    justify-content: space-between;
    .el-select {
      width: 49% !important;
    }
  }
</style>

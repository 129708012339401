import { render, staticRenderFns } from "./dataAppTest.vue?vue&type=template&id=012175dc&scoped=true"
import script from "./dataAppTest.vue?vue&type=script&lang=js"
export * from "./dataAppTest.vue?vue&type=script&lang=js"
import style0 from "./dataAppTest.vue?vue&type=style&index=0&id=012175dc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "012175dc",
  null
  
)

export default component.exports
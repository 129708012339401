<template>
  <div v-if="!dashboardJson">
    <el-button size="small" @click="createDash" icon="el-icon-plus">创建视图</el-button>
  </div>
  <DataViewDashboardComponent v-else :dashJson="dashboardJson" @save="saveDash"></DataViewDashboardComponent>
</template>
<script>

import { DataViewWidget } from './meta/widget';
import { DataViewDashboard } from './meta/dashboard';
import config from './meta/config';

import DataViewDashboardComponent from './core/dash';

import { DataViewConnector } from './index.js';

import { BuiltInWidgets } from './widgets/index';


export default {

  components: { DataViewDashboardComponent },

  data(){
    return {
      dashboardJson: null,
      config: config
    }
  },
  provide(){
    return {
      config: this.config,
      customWidgets: this.widgetTypes
    }
  },
  props: {
    name: {
      type: String
    },
    widgetTypes: {
      type: Array,
      default(){
        return []
      }
    },
    dashJson: {
      type: Object,
      default(){
        return null
      }
    }
  },
  created(){
    console.log(this.name);
    BuiltInWidgets.forEach(
      item => {
        DataViewConnector.Widgets.register(item.component);
        DataViewConnector.WidgetType.register({
          ...item,
          component: item.component.name
        })
      }
    )
  },
  mounted(){
    if(this.dashJson){
      let dashboardJson = new DataViewDashboard({
        ...this.dashJson
      });
      let widgets = [];
      if(this.dashJson.widgets){
        this.dashJson.widgets.forEach(
          item => {
            widgets.push(new DataViewWidget(item));
          }
        )
      }
      dashboardJson.widgets = widgets;
      this.dashboardJson = dashboardJson;
    }else{
      this.createDash();
    }
  },
  methods: {
    createDash(){
      this.dashboardJson = new DataViewDashboard();
    },
    saveDash(dash){
      this.$emit('save' , dash);
    }
  },
  destroyed(){
    DataViewConnector.clear();
  }
}
</script>
<template>
  <div class="design">
    <el-card class="leftTree">
      <el-row>
        <el-col :span="24">
          <el-menu
            :default-active="activeMenu"
            class="el-menu-demo mar-b-15"
            mode="horizontal"
            @select="menuClick"
          >
            <el-menu-item index="data" class="w-50p text-center"
              >数据配置</el-menu-item
            >
            <el-menu-item index="page" class="w-50p text-center"
              >设计页面</el-menu-item
            >
          </el-menu>
        </el-col>
      </el-row>
      <modelsTreeForTree
        v-show="activeMenu == 'data'"
        ref="modelsTreeForTree"
        :tableColumns="tableColumns"
      ></modelsTreeForTree>
      <div class="h-treeSeach" v-if="activeMenu == 'page'">
        <!-- <div class="designDiv">
          <basic-dropdown
            @command="dropCommand"
            :options="dropList"
          ></basic-dropdown>
        </div> -->
        <template v-for="(btn, index) in dropList">
          <div :key="index">
            <el-input
              v-if="btn.value == 'showQuery' && btn.default"
              style="margin-bottom: 15px; width: calc(100%)"
              size="small"
              placeholder="请输入内容"
              suffix-icon="el-icon-search"
              v-model="filterText"
            >
            </el-input>
            <!-- <el-button
              v-if="btn.value == 'showQuery' && btn.default"
              style="margin-left: 5px"
              type="primary"
              size="mini"
              icon="el-icon-refresh"
              circle
            ></el-button> -->
          </div>
        </template>
        <el-scrollbar class="h-tree">
          <el-tree
            v-if="modelFormType == 'tree'"
            ref="tree"
            :load="loadNode"
            lazy
            element-loading-text="加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.2)"
            draggable
            @node-click="handleNodeClick"
            :filter-node-method="filterNode"
          >
          </el-tree>
          <el-tree
            v-if="modelFormType == 'list'"
            ref="tree"
            :data="treeData"
            element-loading-text="加载中"
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(0, 0, 0, 0.2)"
            draggable
            @node-click="handleNodeClick"
            :filter-node-method="filterNode"
          >
          </el-tree>
        </el-scrollbar>
      </div>
    </el-card>
  </div>
  <!-- @node-contextmenu="contextMenuOperation" -->
</template>
<script>
import modelsTreeForTree from "./modelsTreeForTree";
import {
  getMetaCommonTree,
  getMetaCommonList,
} from "@/api/busiMode/metaCommon";

export default {
  name: "designTree",
  inject: ["dataSoure"],
  components: { modelsTreeForTree },
  data() {
    return {
      form: {},
      activeMenu: "data",
      operationForm: {
        clickDo: false,
        clickObj: {},
        dbClickDo: false,
        rightClickDo: false,
      },
      filterText: "",
      activeName: "setData",
      treeDataVisible: false,
      option: {
        showSearch: false,
      },
      node_had: undefined,
      resolve_had: undefined,
      pageChoosed: "",
      modelFormType: "list",
      dropList: [
        {
          label: "查询",
          value: "showQuery",
          default: true,
          type: "switch",
          icon: "",
        },
        {
          label: "默认展开节点",
          default: true,
          value: "openAllNode",
          type: "switch",
        },
        {
          label: "开启右键",
          default: false,
          value: "openRight",
          type: "switch",
        },
        {
          label: "多选",
          default: true,
          value: "changeMoreNode",
          type: "switch",
        },
        { label: "懒加载", default: true, value: "lazyLoad", type: "switch" },
        {
          label: "数据设置",
          default: true,
          value: "setTreeData",
          type: "text",
          icon: "el-icon-edit-outline",
        },
      ],
      treeData: [],
    };
  },

  created() {},
  mounted() {
    if (this.treeOption.pageOption) {
      // this.activeMenu = "page";
      this.modelFormType = this.treeOption.dataOption.type || "list";
      this.treeData = this.treeOption.pageOption;
    }
    if (this.treeOption.dataOption) {
      this.$refs.modelsTreeForTree.modelForm = this.treeOption.dataOption;
    }
  },
  props: {
    entityId: {
      type: String,
      default: function () {
        return "";
      },
    },
    tableColumns: {
      type: Array,
      default: function () {
        return [];
      },
    },
    treeOption: {
      type: Object,
      default: function () {
        return {};
      },
    },
    dataSoure: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    entityId: {
      handler: function (newValue, oldName) {
        if (newValue) {
          this.dataSoure.code = newValue.split(".")[0];
        }
      },
      immediate: true,
      deep: true,
    },
    tableColumns: {
      handler: function (newValue, oldName) {},
      immediate: true,
      deep: true,
    },
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    menuClick(d) {
      if (d == "page") {
        this.modelFormType = this.$refs.modelsTreeForTree.modelForm.type;
        if (
          this.$refs.modelsTreeForTree.modelForm &&
          this.$refs.modelsTreeForTree.modelForm.type == "list"
        ) {
          this.getMetaCommonList(this.$refs.modelsTreeForTree.modelForm);
          this.pageChoosed = "";
        } else {
          this.pageChoosed = "true";
        }
      } else {
        if (this.treeOption.dataOption) {
          this.$refs.modelsTreeForTree.modelForm = this.treeOption.dataOption;
        }
      }
      this.activeMenu = d;
    },
    refreshTree(item, resolve) {
      console.log(item, resolve);
    },
    loadNode(item, resolve) {
      console.log(item);
      this.resolve = resolve;
      let data = [];
      if (item.level === 0) {
        this.node_had = item; // 保存一下根节点
        this.resolve_had = resolve;
      }
      // if (!item["data"] && this.pageChoosed == "") {
      //   if (this.treeOption.pageOption) {
      //     let treeDataList = this.treeOption.pageOption;
      //     this.treeData = treeDataList;
      //     resolve(treeDataList);
      //   }
      // } else {
      console.log("asdfsadfdsf");
      console.log(this.treeOption);
      let model = this.treeOption.dataOption;
      console.log(model);
      if (!this.treeOption.dataOption) {
        model = this.$refs.modelsTreeForTree.modelForm;
      }
      if (model.model) {
        let obj = {};
        let treeData = [];
        if (item["data"]) {
          obj = {
            labelField: model.labelField, //标签显示字段
            valueField: model.valueField, //取值的字段 key
            releaseField: model.releaseField, //关联字段
            rootValue: item["data"].value,
          };
        } else {
          obj = {
            labelField: model.labelField, //标签显示字段
            valueField: model.valueField, //取值的字段 key
            releaseField: model.releaseField, //关联字段
          };
          treeData.push({
            children: [],
            label: "全部",
            value: -99,
          });
        }
        getMetaCommonTree(this.dataSoure.code + "." + model.model, obj).then(
          (res) => {
            this.treeData = [];
            if (res.data.code == 200) {
              res.data.data.forEach((element) => {
                treeData.push({
                  label: element.label,
                  value: element.value,
                  children: element.children,
                });
              });
            }
            this.treeData = treeData;
            resolve(treeData);
          }
        );
      }
      //}
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
    getMetaCommonTree(model) {
      this.treeData = [];
      let treeData = [];
      let obj = {
        labelField: model.labelField, //标签显示字段
        valueField: model.valueField, //取值的字段 key
        sourceField: model.sourceField, //源字段（默认为主键）
        releaseField: model.releaseField, //关联字段
      };
      getMetaCommonTree(this.dataSoure.code + "." + model.model, obj).then(
        (res) => {
          if (res.data.code == 200) {
            this.treeData = [];
            res.data.data.forEach((element) => {
              let tree = {
                label: element.label,
                value: element.value,
                children: element.children,
              };
              this.treeData.push(tree);
              this.$refs.tree.append(tree, 0);
            });
          }
        }
      );
    },
    getMetaCommonList(model) {
      console.log(model);
      if (model.model) {
        getMetaCommonList(this.dataSoure.code + "." + model.model, {}).then(
          (res) => {
            if (res.data.code == 200) {
              this.treeData = [
                {
                  children: [],
                  label: "全部",
                  value: -99,
                },
              ];
              res.data.data.records.forEach((element) => {
                this.treeData.push({
                  label: element[model.labelField],
                  value: element[model.valueField],
                  children: [],
                });
              });
            }
          }
        );
      }
    },
    contextMenuOperation(ev, data, node) {
      //this.node = node;
      let menus = [];
      if (data.type == "A") {
        menus = [
          { name: "新增模块", code: 1, icon: "el-icon-plus" },
          { name: "修改", code: 2, icon: "el-icon-edit-outline" },
          { name: "删除", code: 6, icon: "el-icon-delete" },
        ];
      } else {
        menus = [
          { name: "新增功能", code: 1, icon: "el-icon-plus" },
          { name: "修改", code: 2, icon: "el-icon-edit-outline" },
          { name: "删除", code: 6, icon: "el-icon-delete" },
        ];
      }
      this.$contextmenu({
        menus: menus,
        props: {
          label: "name",
          divider: "isDivider",
          type: "menuType",
        },
        x: ev["x"],
        y: ev["y"],
      }).click((item) => {
        this.excueteCommand(item, data);
      });
    },

    handleNodeClick(data) {
      let o = {
        label: this.$refs.modelsTreeForTree.modelForm.label,
        value: data.value,
      };
      this.$emit("handleNodeClick", o);
    },
    dropCommand(v) {
      this.dropList.forEach((e, i) => {
        if (v.value == "openAllNode") {
          this.changeTreeNodeStatus(this.$refs.tree.store.root, v.default);
        } else if (v.value == "openAllNode") {
          this.changeTreeNodeStatus(this.$refs.tree.store.root, v.default);
        } else if (v.value == "setTreeData") {
          this.treeDataVisible = true;
        } else if (v.value == "openRight") {
          //右键
          this.contextMenuOperation();
          //this.treeDataVisible = true;
        } else if (v.value == e.value) {
          this.dropList[i] = v;
        }
      });
      this.dropList = JSON.parse(JSON.stringify(this.dropList));
    },
    changeTreeNodeStatus(node, expandAll) {
      node.expanded = expandAll;
      for (let i = 0; i < node.childNodes.length; i++) {
        // 改变节点的自身expanded状态
        node.childNodes[i].expanded = expandAll;
        // 遍历子节点
        if (node.childNodes[i].childNodes.length > 0) {
          this.changeTreeNodeStatus(node.childNodes[i], expandAll);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-card__body {
  position: relative;
}
.design {
  position: relative;
  .designDiv {
    .basic_dropdown {
      /deep/ .el-dropdown {
        position: absolute;
        right: 25px;
        top: 25px;
        /deep/ .el-icon-setting {
          background: #f5896c !important;
          padding: 2px;
          color: #fff;
        }
      }
    }
    &:hover {
      border-color: #f18b62 !important;
      border-style: dashed;
      position: absolute;
      left: 0px;
      top: 0px;
      right: 0px;
      bottom: 0;
      /deep/ .el-dropdown {
        right: 22px;
        top: 22px;
      }
      .divIcon {
        display: block;
      }
    }
  }
}
/deep/ .el-scrollbar__wrap {
  width: 100%;
}
/deep/ .el-scrollbar__bar.is-vertical {
  display: none;
}
.leftTree /deep/.el-card__body {
  padding: 0px 10px 10px 10px;
  .el-form--inline .el-form-item {
    margin-right: 0px;
    padding-left: 10px;
  }
  h6 {
    padding: 10px 10px;
    border-bottom: 1px solid #ccc;
    margin: 0;
  }
  .h-treeSeach {
    height: calc(100vh - 263px);
  }
  .h-tree {
    height: calc(100vh - 310px);
  }
}
</style>

<template>
<basic-container isCard :body-style="{'padding': '0' , 'height': '100%'}" class="h-100p">
  <el-container style="height: 100%">
    <el-header height="50px" class="bor-b">
      <basic-page-header @back="back" class="lh-50" :title="(isEdit ? '编辑' : '新增') + '收费单'"></basic-page-header>
    </el-header>
    <el-main class="pad-20 ov-a">
      <el-form class="mar-t-20" :model="form" :rules="rules" ref="form" :size="formSize" label-width="120px">
        <el-row>
          <el-col :span="10">
            <el-form-item label="费用科目" prop="expenseAccountCode">
              <model-cascader
                model="wp_expense_account"
                v-model="form.expenseAccountCode"
                :option="{emitPath: false}"
                separator=" / "
                valueKey="expense_account_code"
                labelKey="expense_account_name"
              ></model-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="账单对象类型" prop="subjectType">
              <dict-select
                v-model="form.subjectType" code="ReceiptSubjectType"
              ></dict-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
              <div style="display: flex;">
                <div style="flex: 1">
                  <el-form-item v-if="subjectType === 1" label="账单对象" prop="subjectId">
                    <model-search-select
                      class="dis-b w-100p"
                      v-model="form.subjectId"
                      model="wp_company"
                      :fillModel.sync="form"
                      :fills="[
                        {source: 'name' , target: 'subject'},
                        {source: 'registered_address' , target: 'subjectAddress'}
                      ]"
                    ></model-search-select>
                  </el-form-item>
                  <el-form-item v-if="subjectType === 2" label="账单对象" prop="subject">
                    <el-input v-model="form.subject"></el-input>
                  </el-form-item>
                </div>
                <div>
                  <span @click="subjectTypeChange()" style="padding: 0 7px" class="cur-p text-hover lh-34">
                    <i class="iconfont iconicon_exchange"></i>
                  </span>
                </div>
              </div>
          </el-col>
          <el-col :span="10">
            <el-form-item v-if="form.subjectId" label="关联合同" prop="resourceSubjectId">
              <model-search-select
                class="dis-b w-100p"
                :getData="getContractList"
                v-model="form.resourceSubjectId"
                labelKey="code"
                valueKey="id"
                :fillModel.sync="form"
                :fills="[
                  {source: 'roomName' , target: 'note'}
                ]"
              ></model-search-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="账单地址" prop="subjectAddress">
              <el-input v-model="form.subjectAddress" placeholder="选择账单对象自动获取"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="!hidePeriodTime">
          <el-col :span="10">
            <el-form-item label="账单开始时间" prop="periodStartTime">
              <el-date-picker class="dis-b w-100p" format="yyyy-MM-dd" value-format="yyyy-MM-dd 00:00:00" v-model="form.periodStartTime" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="账单结束时间" prop="periodEndTime">
              <el-date-picker class="dis-b w-100p" :picker-options="pickerOptions" format="yyyy-MM-dd" value-format="yyyy-MM-dd 23:59:59" v-model="form.periodEndTime" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="应收日期" prop="receivableDate">
              <el-date-picker class="dis-b w-100p" format="yyyy-MM-dd" value-format="yyyy-MM-dd 23:59:59" v-model="form.receivableDate" type="date" placeholder="选择日期"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="应收金额" prop="amountReceivable">
              <el-input class=" w-100p" type="number" v-model="form.amountReceivable">
                <template slot="prepend">￥</template>
                <template slot="append">元</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-main>
    <el-footer height="50px" class="lh-50">
      <div class="text-center">
        <el-button :size="formSize" :loading="btnLoading" icon="el-icon-close" @click="back">取 消</el-button>
        <el-button :size="formSize" :loading="btnLoading" icon="el-icon-check" type="primary" @click="saveForm()">保 存</el-button>
      </div>
    </el-footer>
  </el-container>
</basic-container>
</template>
<script>
import dayjs from 'dayjs';

import { getList as getContractList } from "@/api/contract/index.js";

import { saveReceipt , updateReceipt , getDetail} from '@/api/finance/index.js';

export default {
  data(){
    return {
      catalogModel: 'wisdom_park.wp_expense_account',
      model: 'wisdom_park.wp_receipt',
      catalogData: [],
      form: {
        periodStartTime: null,
        periodEndTime: null,
        receivableDate: null,
        amountReceivable: '',
        subjectId: null,
        subject: '',
        subjectAddress: '',
        expenseAccountCode: this.$route.query.expenseAccountCode || '',
        resourceSubjectId: '',
        subjectType: '1'
      },
      subjectType: 1,
      rules: {
        expenseAccountCode: [
          { required: true, message: '请选择费用科目', trigger: 'blur' }
        ],
        subjectId: [
          { required: true, message: '请选择账单对象', trigger: 'blur' }
        ],
        subject: [
          { required: true, message: '请输入账单对象', trigger: 'blur' }
        ],
        // periodStartTime: [
        //   { required: true, message: '请选择账单开始时间', trigger: 'blur' }
        // ],
        // periodEndTime: [
        //   { required: true, message: '请选择账单结束时间', trigger: 'blur' }
        // ],
        receivableDate: [
          { required: true, message: '请选择应收日期', trigger: 'blur' }
        ],
        amountReceivable: [
          { required: true, message: '请输入应收金额', trigger: 'blur' }
        ]
      },
      pickerOptions: {
        disabledDate: (time) => {
          if(!this.form.periodStartTime){
            return false;
          }else{
            return dayjs(time).valueOf() < dayjs(this.form.periodStartTime).valueOf()
          }
        }
      },
      btnLoading: false
    }
  },
  computed: {
    formSize(){
      return this.$store.state.common.size || 'small'
    },
    isView(){
      return this.$router.currentRoute.params['type'] === 'view'
    },
    isEdit(){
      return this.$router.currentRoute.params['type'] === 'edit'
    },
    receiptNo(){
      return this.$router.currentRoute.query['id'] || '';
    },
    hidePeriodTime(){
      return ['1008' , '1009'].includes(this.form.expenseAccountCode);
    }
  },
  watch: {
    'form.subjectType': {
      handler(){
        this.subjectType = this.form.subjectType ? Number(this.form.subjectType) : this.subjectType
      }
    },
    'subjectType': {
      handler(){
        if(this.subjectType === 1){
          this.form.subject = '';
        }else{
          this.form.subjectId = '';
        }
      }
    },
    'form.expenseAccountCode': {
      handler(){
        if(['1008' , '1009'].includes(this.form.expenseAccountCode)){
          this.form.periodStartTime = '';
          this.form.periodEndTime = '';
        }
      }
    }
  },
  mounted(){
    // this.getMetaCommonListTree();
    if(this.receiptNo){
      this.getDetail();
    }
  },
  methods: {
    subjectTypeChange(){
      if(this.subjectType === 1){
        this.subjectType = 2;
      }else{
        this.subjectType = 1;
      }
    },
    back(){
      this.$router.back();
      this.$breadcrumb.pop();
    },
    getDetail(){
      getDetail({
        receiptNo: this.receiptNo
      }).then(
        res => {
          this.form = res.data.data;
        }
      )
    },
    getContractList(ev , done){
      getContractList({
        current: ev.current,
        size: ev.size,
        contractStatus: 2,
        companyId: this.form.subjectId
      }).then((res) => {
          done(res);
      })
    },
    saveForm(){
      this.$refs.form.validate(valid => {
        if(valid){
          this.btnLoading = true;
          (this.receiptNo ? updateReceipt : saveReceipt)(this.form).then((res) => {
            // if(isStartFlow){
            //   return this.startFlow(res.data.data).then(res1 => res1);
            // }else{
            //   return res;
            // }
            return res;
          }).then(res => {
            this.btnLoading = false;
            this.$message.success(res.data.msg);
            this.back();
          }).catch((e) => {
            this.btnLoading = false;
          });
        }else{
          return false;
        }
      })
    }
  }
}
</script>
<template>
  <div class="pad-20">
    <div class="mar-t-10f" v-if="active == 1">
      <el-form>
        <el-form-item label="房源类型" prop="resource">
          <el-radio-group v-model="houseResourType">
            <el-radio label="build">楼宇</el-radio>
            <el-radio label="room">房间</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <buildList
        v-if="houseResourType == 'build'"
        @oneBuild="oneBuild"
        ref="buildList"
        @close="close"
      ></buildList>
      <rooms
        ref="room"
        v-if="houseResourType == 'room'"
        @oneBuild="oneBuild"
        @close="close"
        :currentHouse="currentHouse"
      ></rooms>
    </div>
    <div class="pad-t-10 btnClass">
      <el-button class="pull-right" size="small" type="" @click="close"
        >取消</el-button
      >
      <el-button
        size="small"
        class="pull-right mar-r-10"
        type="primary"
        @click="saveHouse"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import rooms from "./room";
import buildList from "./buildList";
import houseInfo from "./houseInfo";

export default {
  name: "addHousingResources",
  components: { rooms, buildList, houseInfo },

  props: {
    ruleModel: {
      type: Object,
      default() {
        return {};
      },
    },
    currentHouse: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      houseResourType: "room",
      active: 1,
      currentObj: {},
    };
  },
  watch: {},
  mounted() {},
  computed: {},
  methods: {
    oneBuild(e) {
      this.currentObj = e;
    },
    close() {
      this.$emit("close");
    },
    saveHouse() {
      if (this.houseResourType == "room") {
        this.$refs.room.save();
      } else {
        this.$refs.buildList.save();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-card__body {
  padding-top: 0px;
}
.titleactive {
  color: #475570 !important;
}
.title {
  color: #8d9ab2;
}
/deep/ .el-divider__text {
  color: rgba(48, 49, 51, 0.5);
}
.diaTitle {
  position: absolute;
  top: 23px;
  left: 80px;
}
.btnClass {
  display: flow-root;
  margin-top: 5px;
}
</style>

<template>
  <div class="h-100p" v-if="currentWidget && currentWidget.dataOption && currentWidget.dataOption.length && widget.dataOption">
    <h4 class="bor-b h-40 lh-40 pad-0 mar-0"><b>部件数据配置</b></h4>
    <div :style="{'height': 'calc(100% - 40px)'}" class="pad-15 ov-a">
      <div>
        <el-form ref="dataConfig" :size="config.size" :label-width="'70px'">
          <OptionGroup v-for="item of currentWidget.dataOption" :columns="columns" :optionItem="item" :option="widget.dataOption"></OptionGroup>
        </el-form>
      </div>
      <!-- <div v-else class="lh-240 text-center">
        <p>此部件无数据配置项</p>
      </div> -->
    </div>
  </div>
  <!-- <el-row class="h-100p"> -->
    <!-- <el-col class="h-100p" :span="currentWidget && currentWidget.dataOption && currentWidget.dataOption.length ? 14 : 24">
      <el-tabs class="h-100p"  v-model="activeGroup">
        <el-tab-pane class="h-100p" v-for="itemGroup of widgetGroup" :key="itemGroup" :label="itemGroup" :name="itemGroup">
          <div style="height: calc(100% - 40px)"  class="widget-type-list">
            <template v-for="widgetItem of widgetTypes">
              <div :key="widgetItem.code" class="widget-type-list-item" :class="{'widget-type-list-item-active': widgetItem.code === widget.selector}" v-if="widgetItem.type === itemGroup" @click="selectWidgetType(widgetItem)">
                <i :class="widgetItem.icon || 'el-icon-menu'"></i>
                <div>
                  <span>{{widgetItem.widgetName}}</span>
                </div>
              </div>
            </template>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-col> -->
    <!-- <el-col class="h-100p" v-if="currentWidget && currentWidget.dataOption && currentWidget.dataOption.length && widget.dataOption" :span="10">
      <el-tabs class="h-40" :value="'dataOPtion'">
        <el-tab-pane label="数据配置" name="dataOPtion">
        </el-tab-pane>
      </el-tabs>
      <div class="pad-15 ov-a" :style="{'height': 'calc(100% - 40px)'}">
        <el-form ref="dataConfig" :size="config.size" :label-width="config.labelWidth">
          <OptionGroup v-for="item of currentWidget.dataOption" :columns="columns" :optionItem="item" :option="widget.dataOption"></OptionGroup>
        </el-form>
      </div>
    </el-col>
  </el-row> -->
</template>
<script>

import OptionGroup from './option-group.vue';

export default {
  inject: [ 'config' ],
  components: { OptionGroup },
  data(){
    return {
      widgetGroup: [],
      activeGroup: null
    }
  },
  props: {
    currentWidget: {
      type: Object,
      default(){
        return null;
      }
    },
    columns: {
      type: Array,
      default(){
        return [];
      }
    },
    widgetTypes: {
      type: Array,
      default(){
        return []
      }
    },
    widget: {
      type: Object,
      default(){
        return null;
      }
    }
  },
  mounted(){
    this.initGroup();
    if(this.widgetGroup.length && (!this.activeGroup || this.activeGroup === '0')){
      this.activeGroup = this.widgetGroup[0];
    }
    if(this.widget.selector){
      this.widgetTypes.forEach(
        item => {
          if(item.code === this.widget.selector){
            this.currentWidget = item;
            this.activeGroup = item.type;
          }
        }
      )
    }
  },
  methods: {
    isArray(arr){
      return Array.isArray(arr);
    },
    initGroup(){
      (this.widgetTypes || []).forEach(
        item => {
          if(this.widgetGroup.indexOf(item.type) < 0){
            this.widgetGroup.push(item.type);
          }
        }
      )
    },
    selectWidgetType(widget){
      this.currentWidget = JSON.parse(JSON.stringify(widget));
      if(this.widget.selector !== widget.code){
        this.widget.selector = widget.code;
      }
    }
  }
}
</script>
<template>
  <div></div>
</template>
<script>
import { getItcToken } from '@/api/baseService/index.js';
export default {
  data(){
    return {
      baseUrl: 'http://172.16.34.2:81',
    }
  },
  mounted(){
    getItcToken().then(
      res => {
        let openUrl = `${this.baseUrl}/homecontent?authorization=${res.data.data}`;
        console.log(openUrl);
        window.open(openUrl)
        this.$router.back();
      }
    )
  }
}
</script>
import G6 from '@antv/g6';

import './g6-custom-register';

const LayoutConfigs = {
    level_1: {
        type: 'dagre',
        rankdir: 'LR',
        controlPoints: false,
        nodesep: 10,
        ranksepFunc: (node) => {
            if(node.nodeType && node.nodeType === 'app'){
                return 150;
            }
            return 50;
        },
        align: undefined,
        sortByCombo: true,
    },
    level_2: {
        type: 'grid',
        preventOverlap: true
    }
}

const GraphOptions = {
    level_1: {
        fitCenter: true,
        animate: true,
        modes: {
            default: [
                {
                    type: 'zoom-canvas',
                    sensitivity: 1
                },
                'drag-canvas',
                'drag-node',
                'drag-combo',
            ]
        },
        defaultNode: {
            style: {
                lineWidth: 2,
                stroke: '#5B8FF9',
                fill: '#5B8FF9',
                fontSize: 10
            },
        },
        defaultEdge: {
            type: 'polyline',
            style: {
                endArrow: {
                    path: 'M 0,0 L 8,4 L 8,-4 Z',
                    fill: '#e2e2e2'
                },
                lineWidth: 2,
                cursor: 'pointer',
                offset: 50
            },
            labelCfg: {
                refY: 10,
                style: {
                    cursor: 'pointer',
                }
            }
        },
        defaultCombo: {
            type: 'rect'
        }
    },
    level_2: {
        // fitView: true,
        fitCenter: true,
        // animate: true,
        modes: {
            default: [
                {
                    type: 'zoom-canvas',
                    enableOptimize: true,
                    sensitivity: 1
                },
                {
                    type: 'drag-canvas',
                    enableOptimize: true,
                },
                // 'drag-node',
                // 'drag-combo',
            ]
        },
        defaultNode: {
            style: {
                lineWidth: 2,
                stroke: '#5B8FF9',
                fill: '#5B8FF9',
                fontSize: 10
            },
        },
        nodeStateStyles: {
            active: {
                stroke: '#67C23A',
            }
        },
        defaultEdge: {
            type: 'polyline',
            style: {
                endArrow: {
                    path: 'M 0,0 L 8,4 L 8,-4 Z',
                    fill: '#e2e2e2'
                },
                lineWidth: 2,
                cursor: 'pointer',
                offset: 50
            },
            labelCfg: {
                refY: 10,
                style: {
                    cursor: 'pointer',
                }
            }
        },
    }
}

const ToolbarButtons = {
    level_1: [
        // {desc: '刷新' , icon: 'el-icon-refresh' , code: 'refresh' , type: 'button'},
        {desc: '放大' , icon: 'el-icon-zoom-in' , code: 'zoomIn' , type: 'button'},
        {desc: '缩小' , icon: 'el-icon-zoom-out' , code: 'zoomOut' , type: 'button'},
        {type: 'divider'},
        {desc: '实际大小' , icon: 'portal-iconfont portal-icon_jingquezhunque' , code: 'realZoom' , type: 'button'},
        {desc: '适应画布' , icon: 'el-icon-full-screen' , code: 'autoZoom' , type: 'button'},
    ],
    level_2: [
        {name: '返回' , icon: 'el-icon-arrow-left' , code: 'back' , type: 'button'},
        {type: 'divider'},
        // {desc: '刷新' , icon: 'el-icon-refresh' , code: 'refresh' , type: 'button'},
        {desc: '放大' , icon: 'el-icon-zoom-in' , code: 'zoomIn' , type: 'button'},
        {desc: '缩小' , icon: 'el-icon-zoom-out' , code: 'zoomOut' , type: 'button'},
        {type: 'divider'},
        {desc: '实际大小' , icon: 'portal-iconfont portal-icon_jingquezhunque' , code: 'realZoom' , type: 'button'},
        {desc: '适应画布' , icon: 'el-icon-full-screen' , code: 'autoZoom' , type: 'button'},
        {type: 'search' , desc: '输入表名定位'},
        {type: 'divider'},
        {desc: '表格显示' , icon: 'portal-iconfont portal-icon_fsux_tubiao_biaoge' , code: 'tableShow' , type: 'button'},
    ],
    level_2_tableShow: [
        {name: '返回' , icon: 'el-icon-arrow-left' , code: 'back' , type: 'button'},
        {type: 'divider'},
        {desc: '放大' , icon: 'el-icon-zoom-in' , code: 'zoomIn' , type: 'button' , disabled: true},
        {desc: '缩小' , icon: 'el-icon-zoom-out' , code: 'zoomOut' , type: 'button' , disabled: true},
        {type: 'divider'},
        {desc: '实际大小' , icon: 'portal-iconfont portal-icon_jingquezhunque' , code: 'realZoom' , type: 'button' , disabled: true},
        {desc: '适应画布' , icon: 'el-icon-full-screen' , code: 'autoZoom' , type: 'button' , disabled: true},
        {type: 'divider'},
        {desc: '图表显示' , icon: 'portal-iconfont portal-icon_fsux_tubiao_juxingshutu' , code: 'chartShow' , type: 'button'},
    ]
}


export {
    G6,
    GraphOptions,
    LayoutConfigs,
    ToolbarButtons
};
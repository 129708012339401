import { render, staticRenderFns } from "./minute.vue?vue&type=template&id=90a46bce"
import script from "./minute.vue?vue&type=script&lang=js"
export * from "./minute.vue?vue&type=script&lang=js"
import style0 from "./minute.vue?vue&type=style&index=0&id=90a46bce&prod&lang=scss&rel=stylesheet%2Fscss"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
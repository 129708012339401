import { BarChartConfigs } from './bar';
import { LineChartConfigs } from './line';
import { GaugeChartConfigs } from './gauge';
import { PieChartConfigs } from './pie';

export default [

  ...BarChartConfigs,
  ...LineChartConfigs,
  ...GaugeChartConfigs,
  ...PieChartConfigs

]
<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      ref="crud"
      v-model="form"
      :before-open="beforeOpen"
      :before-close="beforeClose"
      @row-del="rowDel"
      @row-update="(row, index, done, loading) => rowSave(row, done, loading)"
      @row-save="rowSave"
      @refresh-change="refreshChange"
      @on-load="onLoad"
      @tree-load="treeLoad"
    >
      <template slot-scope="scope" slot="menu">
        <el-button
          type="text"
          icon="el-icon-circle-plus-outline"
          size="small"
          @click.stop="handleAdd(scope.row, scope.index)"
          v-if="userInfo.role_name.includes('admin')"
          >新增子项
        </el-button>
      </template>
      <template slot-scope="{ row }" slot="deptCategory">
        <el-tag>{{ row.deptCategoryName }}</el-tag>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  delMetaCommon,
  getMetaCommonDetail,
  getMetaCommonListTree
} from "@/api/busiMode/metaCommon";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      form: {},
      query: {},
      loading: true,
      parentId: 0,
      option: {
        height: 'auto',
        lazy: true,
        tip: false,
        simplePage: true,
        searchShow: true,
        searchMenuSpan: 6,
        dialogWidth: "40%",
        tree: true,
        border: true,
        index: true,
        viewBtn: true,
        menuWidth: 300,
        dialogClickModal: false,
        rowKey:'id',
        rowParentKey:'parent_id',
        column: [
          {
            label: "类别名称",
            prop: "asset_category_name",
            rules: [
              {
                required: true,
                message: "请输入类别名称",
                trigger: "blur",
              },
            ],
            span: 24,
            row: true
          },
          {
            label: "类别编码",
            prop: "asset_category_code",
            rules: [
              {
                required: true,
                message: "请输入类别编码",
                trigger: "blur",
              },
            ],
            span: 24,
            row: true
          },
          {
            label: "排序",
            prop: "sort",
            rules: [
              {
                required: true,
                message: "请输入排序",
                trigger: "blur",
              },
            ],
            span: 24,
            row: true,
            type: 'number'
          },
          {
            label: "上级类别",
            prop: "parent_id",
            dicData: [],
            type: "tree",
            hide: true,
            addDisabled: false,
            props: {
              label: "asset_category_name",
              value: 'id'
            },
            rules: [
              {
                required: false,
                message: "请选择上级科目",
                trigger: "click",
              },
            ],
            span: 24,
            row: true
          },
          {
            label: "备注",
            prop: "remarks",
            type: 'textarea',
            rules: [
              {
                required: false,
                message: "请输入备注",
                trigger: "blur",
              },
            ],
            hide: true,
            span: 24,
            row: true
          },
        ],
      },
      data: [],
      model: 'wisdom_park.wp_asset_category'
    };
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    // permissionList() {
    //   return {
    //     addBtn: this.vaildData(this.permission.dept_add, false),
    //     viewBtn: this.vaildData(this.permission.dept_view, false),
    //     delBtn: this.vaildData(this.permission.dept_delete, false),
    //     editBtn: this.vaildData(this.permission.dept_edit, false),
    //   };
    // },
  },
  methods: {
    initData() {
      getMetaCommonListTree(this.model , {
        labelField: 'asset_category_name',
        valueField: 'id',
        releaseField: 'parent_id',
        isLazy: false
      }).then((res) => {
        const column = this.findObject(this.option.column, "parent_id");
        column.dicData = res.data.data;
      });
    },
    handleAdd(row) {
      this.parentId = row.id;
      const column = this.findObject(this.option.column, "parent_id");
      column.value = row.id;
      column.addDisabled = true;
      this.$refs.crud.rowAdd();
    },
    
    rowSave(row, done, loading) {
      let body = {};
      ['asset_category_name' , 'asset_category_code' , 'parent_id' , 'sort' , 'remarks'].forEach(
        key => {
          if(row[key] !== undefined){
            body[key] = row[key];
          }
        }
      );
      body['parent_id'] = body['parent_id'] ? body['parent_id'] : 0;
      if(row.id){
        body = {
          update: body,
          primaryKeyValue: row.id,
          primaryKey: "id",
        };
      }else{
        body = {
          values: body
        }
      }
      (row.id ? updateMetaCommon : saveMetaCommon)(this.model , body).then((res) => {
        // 获取新增数据的相关字段
        const data = res.data.data;
        row.id = data.id;
        this.$message({
          type: "success",
          message: "操作成功!"
        });
        // 数据回调进行刷新
        done(row);
        // this.parentId = 0;
        // this.onLoad();
      }, error => {
        window.console.log(error);
        loading();
      });
    },
    rowDel(row, index, done) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return delMetaCommon(this.model , {pk: 'id' , ids: [row.id]});
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          // 数据回调进行刷新
          done();
          // this.parentId = 0;
          // this.onLoad();
        });
    },
    beforeOpen(done, type) {
      if (["add", "edit"].includes(type)) {
        this.initData();
      }
      if (["edit", "view"].includes(type)) {
        getMetaCommonDetail(this.model , {
          pk: 'id',
          id: this.form.id
        }).then((res) => {
          this.form = res.data.data;
        });
      }
      done();
    },
    beforeClose(done) {
      this.parentId = "";
      const column = this.findObject(this.option.column, "parent_id");
      column.value = "";
      column.addDisabled = false;
      done();
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad() {
      this.loading = true;
      getMetaCommonListTree(this.model , {
        labelField: 'asset_category_name',
        valueField: 'id',
        releaseField: 'parent_id',
        rootValue: this.parentId ? this.parentId : 0
      }).then(
        res => {
          this.data = res.data.data.map(
            item => {
              return {
                ...item,
                children: []
              }
            }
          );
          this.loading = false;
        }
      )
    },
    treeLoad(tree, treeNode, resolve) {
      const parentId = tree.id;
      getMetaCommonListTree(this.model , {
        labelField: 'asset_category_name',
        valueField: 'id',
        releaseField: 'parent_id',
        rootValue: parentId
      }).then((res) => {
        resolve(res.data.data);
      });
    },
  },
};
</script>

<style></style>

<template>
  <el-form ref="form" :model="widget" size="small" label-width="80px">
    <el-collapse>
      <el-collapse-item title="位置与大小" name="position">
        <el-form-item class="mar-t-8" label="横向位置">
          <el-input-number v-model="widget.x" controls-position="right" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item class="mar-t-8" label="纵向位置">
          <el-input-number v-model="widget.y" controls-position="right" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item class="mar-t-8" label="宽度">
          <el-input-number v-model="widget.w" @change="resize" controls-position="right" :min="0" :max="maxCol"></el-input-number>
        </el-form-item>
        <el-form-item class="mar-t-8" label="高度">
          <el-input-number v-model="widget.h" @change="resize"controls-position="right" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item class="mar-t-8" label="上下边距">
          <el-input-number v-model="widget.public.padding[0]" @change="resize" controls-position="right" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item class="mar-t-8" label="左右边距">
          <el-input-number v-model="widget.public.padding[1]" @change="resize" controls-position="right" :min="0"></el-input-number>
        </el-form-item>
      </el-collapse-item>
      <el-collapse-item title="主题">
        <el-form-item class="mar-t-8" label="背景颜色">
          <el-color-picker v-model="widget.style.background" show-alpha></el-color-picker>
        </el-form-item>
        <el-form-item class="mar-t-8" label="阴影">
          <el-radio v-model="widget.public.shadow" label="always">显示</el-radio>
          <el-radio v-model="widget.public.shadow" label="hover">悬浮显示</el-radio>
          <el-radio v-model="widget.public.shadow" label="never">不显示</el-radio>
        </el-form-item>
      </el-collapse-item>
    </el-collapse>
  </el-form>
</template>
<script>
export default {
  name: 'WidgetConfigByBaseInfo',
  tabName: '基础设置',
  data(){
    return {

    }
  },
  props: {
    widget: {
      type: Object,
      default: () => {
        return {}
      }
    },
    option: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    maxCol(){
      return this.option.colNum || 100;
    }
  },
  methods: {
    resize(){
      this.$emit('resize' , {i: this.widget.i});
    }
  }
}
</script>
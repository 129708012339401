import Component from './main.vue';

export default [
  {
    widgetName: '表格',
    type: '基础部件',
    code: 'BuiltInWidgetByTable',
    component: Component,
    icon: 'portal-iconfont portal-icon_fsux_tubiao_biaoge',
    dataOption: [
      {name: '表格列' , type: 'dynamic' , code: 'columns' , optionList: [],
      desc: ['表格的表头'],
      meta: [
        {name: '列名' , code: 'name' , type: 'text'},
        {name: '列值' , code: 'value' , type: 'field'},
        {name: '列宽' , code: 'width' , type: 'text'}
      ]}
    ],
    optionList: [
      {
        name: '表格配置',
        code: 'tableProps',
        optionList: [
          {name: '斑马线' , code: 'stripe' , type: 'boolean' , value: false},
          {name: '边框' , code: 'border' , type: 'boolean' , value: false}
        ]
      }
    ]
  }
]
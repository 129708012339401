<template>
    <basic-container isCard :body-style="{ padding: '0', height: '100%' }" class="h-100p">
        <el-container class="h-100p">
            <el-header height="50px" class="lh-50 bor-b">
                <basic-page-header @back="back" class="lh-50" :title="title"></basic-page-header>
            </el-header>
            <el-container style="height: calc(100% - 50px)">
                <el-main class="ov-a bor-r">
                    <div class="pad-b-10 mar-t-10">
                        <el-form class="pad-10" :model="ruleForm" ref="ruleForm" label-width="100px" size="small" labelPosition="right" :rules="ruleModel">
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="被访人" prop="respondentName">
                                        <el-input placeholder="请输入被访人" v-model="ruleForm.respondentName"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row v-if="!(userInfo.companyId && userInfo.tenant_id == '000002')">
                                <el-col :span="16">
                                    <el-form-item label="被访单位" prop="companyId" :rules="[
                      {
                        required: true,
                        message: '请选择入被访单位',
                        trigger: 'blur',
                      },
                    ]">
                                        <el-cascader :options="options" :props="defaultProps" clearable filterable ref="tree" @change="getFullName" v-model="ruleForm.companyId"></el-cascader>
                                        <!-- <el-select
                      @change="changeCom"
                      v-model="ruleForm.companyId"
                      filterable
                      placeholder="请选择入被访单位"
                    >
                      <el-option
                        v-for="item in companyList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      >
                      </el-option>
                    </el-select> -->
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="来访人姓名" prop="visitorName" :rules="[
                      {
                        required: true,
                        message: '请输入来访人姓名',
                        trigger: 'blur',
                      },
                    ]">
                                        <el-input v-model="ruleForm.visitorName" placeholder="请输入来访人姓名"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8">
                                    <el-form-item label="来访人电话" prop="contact" :rules="[
                      {
                        required: true,
                        message: '请输入来访人电话',
                        trigger: 'blur',
                      },
                    {
                        pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
                        message: '请输入正确的手机号码或者座机号',
                    },
                    ]">
                                        <el-input placeholder="来访人电话" v-model="ruleForm.contact"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="证件类型" prop="idCard">
                                        <el-select v-model="ruleForm.idCardType" filterable placeholder="请选择证件类型">
                                            <el-option v-for="item in cardTypeList" :key="item.value" :label="item.name" :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-form-item>
                                </el-col>
                                <el-col :span="8" v-if="ruleForm.idCardType==1">
                                    <el-form-item label="证件号码" prop="idCardNo1" :rules="[
                                       
                                        {
                                            pattern: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}$)/,
                                            message: '请输入正确的证件号码',
                                            trigger: 'blur',
                                        },
                                        ]">
                                        <el-input placeholder="请输入证件号码" v-model="ruleForm.idCardNo1"></el-input>
                                    </el-form-item>

                                </el-col>
                                <el-col :span="8" v-if="ruleForm.idCardType!=1">
                                    <el-form-item label="证件号码" prop="idCardNo">
                                        <el-input placeholder="请输入证件号码" v-model="ruleForm.idCardNo"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="来访人单位" prop="visitorCompany">
                                        <el-input placeholder="请输入来访人单位" v-model="ruleForm.visitorCompany"></el-input>
                                    </el-form-item>
                                </el-col>

                                <el-col :span="8">
                                    <el-form-item label="来访时间" :rules="[
                      {
                        required: true,
                        message: '请选择预计来访时间',
                        trigger: 'blur',
                      },
                    ]" prop="arrivalTime" v-if="ruleForm.type == 1">
                                        <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="ruleForm.arrivalTime" placeholder="选择来访时间">
                                        </el-date-picker>
                                    </el-form-item>
                                    <el-form-item label="来访时间" prop="visitorTime" v-if="ruleForm.type == 0" :rules="[
                      {
                        required: true,
                        message: '请选择预计来访时间',
                        trigger: 'blur',
                      },
                    ]">
                                        <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="ruleForm.visitorTime" placeholder="选择预计来访时间">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="离开时间">
                                        <el-date-picker type="datetime" value-format="yyyy-MM-dd HH:mm:ss" v-model="ruleForm.departureTime" placeholder="选择预计离开时间">
                                        </el-date-picker>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="20">
                                    <el-form-item label="随同人员">
                                        <div class="zdy-avue-dynamic">
                                            <avue-dynamic v-model="ruleFormScryy" :children="stryOption"></avue-dynamic>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="20">
                                    <el-form-item label="访客车辆">
                                        <div class="zdy-avue-dynamic">
                                            <avue-dynamic v-model="visitorCarNos" :children="carNoOption"></avue-dynamic>
                                            <el-col :span="24" class="el-upload__tip el-col el-col-24 pad-0 mar-t-0"><i class="el-icon-warning-outline mar-r-5"></i>{{ visitorTips }}
                                            </el-col>
                                        </div>
                                    </el-form-item>
                                </el-col>
                            </el-row>

                            <el-row>
                                <el-col :span="8">
                                    <el-form-item label="通行方式" prop="inOutType">
                                        <el-switch v-model="ruleForm.inOutTypeSwitch" active-text="人脸通行" @change="changeInOutType"></el-switch>
                                        <!-- <el-radio-group v-model="ruleForm.inOutType">
                      <el-radio :label="0" v-if="ruleForm.type == 0"
                        >二维码</el-radio
                      >
                      <el-radio :label="1">卡片</el-radio>
                      <el-radio :label="2">人脸识别</el-radio>
                      <el-radio :label="3" v-if="ruleForm.type == 1"
                        >一键开闸</el-radio
                      >
                    </el-radio-group> -->
                                    </el-form-item>
                                </el-col>

                            </el-row>
                            <el-row>
                                <el-col :span="16">
                                    <el-form-item label="事由" prop="reason">
                                        <el-input type="textarea" :rows="5" placeholder="请输入事由" v-model="ruleForm.reason">
                                        </el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-form>
                        <div class="pad-20 pad-t-5">
                            <div v-if="ruleForm.inOutType == 2">
                                <el-divider content-position="left">照片 </el-divider>
                                <!-- <div class="pad-10" v-if="imgform.imgUrl.length == 0">
                  暂无照片
                </div> -->
                                <avue-form :option="option" v-model="imgform"></avue-form>
                            </div>

                            <div class="btnClass mar-l-40p">
                                <el-button size="small" class="mar-t-10 mar-r-5" type="primary" @click="save">保存</el-button>
                                <el-button size="small" class="mar-t-10 mar-r-10" type="" @click="back">取消</el-button>
                            </div>
                        </div>
                    </div>
                </el-main>
            </el-container>
        </el-container>
    </basic-container>
</template>

<script>
import standardService from '@/api/metadata/standard';
import { submit } from '@/api/visitor/visitor';
import { mapState } from 'vuex';
import { dateFormat } from '@/util/date';
import * as dictbizService from '@/api/system/dictbiz';
import { getDeptTree, getDeptLazyTree } from '@/api/system/dept';
import { getDictionary } from '@/api/system/dict';
import { isvalidatemobile } from '@/util/validate';

import { getMetaCommonList, getMetaCommonInfo } from '@/api/busiMode/metaCommon';

export default {
    data() {
        const validatePhone = (rule, value, callback) => {
            if (isvalidatemobile(value)[0]) {
                callback(new Error(isvalidatemobile(value)[1]));
            } else {
                callback();
            }
        };
        return {
            ruleModel: {
                contact: [
                    { required: true, message: '请输入来访人电话', trigger: 'blur' },
                    {
                        pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/,
                        message: '请输入正确的手机号码或者座机号',
                    },
                ],
            },
            title: '来访人预约',
            cardTypeList: [],
            options: [],
            currentDept: '',
            defaultProps: {
                lazy: true,
                label: 'title',
                value: 'value',
                lazyLoad: (node, resolve) => {
                    const parentId = node.level === 0 ? 0 : node.data.id;
                    getDeptLazyTree(parentId).then(res => {
                        let arr = [];
                        res.data.data.forEach(item => {
                            if (this.disShowList.indexOf(item.id) == -1) {
                                arr.push({ ...item, leaf: !item.hasChildren });
                            }
                        });
                        resolve(arr);
                    });
                },
            },
            option1: {
                labelWidth: 0,
                submitBtn: false,
                emptyBtn: false,

                column: [
                    {
                        label: '',
                        prop: 'visitorPhoto',
                        type: 'upload',
                        listType: 'picture-card',
                        loadText: '附件上传中，请稍等',
                        span: 24,

                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '只能上传jpg/png文件，且不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },
                ],
            },
            option: {
                labelWidth: 0,
                submitBtn: false,
                emptyBtn: false,
                column: [
                    {
                        label: '',
                        prop: 'imgUrl',
                        type: 'upload',
                        listType: 'picture-card',
                        loadText: '附件上传中，请稍等',
                        span: 24,

                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '只能上传jpg/png文件，且不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },
                ],
            },
            imgform: {
                imgUrl: [],
            },
            ruleForm: {
                inOutType: 2,
                idCardType: '1',
            },
            params: {
                zoom: 4,
                zoomEnable: false,
                dragEnable: true,
            },
            companyList: [],
            entModel: 'wisdom_park.wp_company',
            model: 'wisdom_park.wp_visitor',
            ruleFormScryy: [],
            visitorCarNos: [],
            stryOption: {},
            carNoOption: {},
            disShowList: [],
            visitorTips: '',
        };
    },
    mounted() {
        this.getMetaCommonList();

        if (this.userInfo.companyId && this.userInfo.tenant_id == '000002') {
            this.ruleForm.companyId = this.userInfo.companyId;
            this.ruleForm.fullCompanyName = this.userInfo.companyName;
        }
        if (this.$router.currentRoute.query.visitorId) {
            this.ruleForm.id = this.$router.currentRoute.query.visitorId;
            this.getMetaCommonInfo();
        }
        if (this.$router.currentRoute.query.type) {
            this.ruleForm.type = this.$router.currentRoute.query.type;
            if (this.ruleForm.type == 0) {
                this.title = '来访人预约';
                this.ruleForm.inOutType = 2;
                this.ruleForm.inOutTypeSwitch = true;
            }
            if (this.ruleForm.type == 1) {
                this.title = '来访人登记';
                this.ruleForm.inOutType = 2;
                this.ruleForm.inOutTypeSwitch = true;
            }
        }
        if (this.userInfo.user_name) {
            this.ruleForm.dutyPerson = this.userInfo.user_name;
        }
        //this.ruleForm.arrivalTime = dateFormat(new Date());
        this.getDeptList();
        this.getDisShow();
        this.getDicList();
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    props: {},
    methods: {
        changeInOutType(v) {
            if (v) {
                this.ruleForm.inOutType = 2;
            } else {
                this.ruleForm.inOutType = 0;
            }
        },
        getFullName() {
            let data = this.$refs.tree.getCheckedNodes()[0].pathLabels.toString();
            this.ruleForm.fullCompanyName = data.replace(',', '/');
            console.log(this.ruleForm.fullCompanyName);
        },
        getDisShow() {
            this.disShowList = [];
            getDictionary({ code: 'default_spcod_account_dept' }).then(res => {
                this.disShowList.push(res.data.data[0].dictValue);
            });
            getDictionary({ code: 'default_visitor_dept' }).then(res => {
                this.disShowList.push(res.data.data[0].dictValue);
            });
        },
        getDeptList() {
            let data = this.userInfo;
            let deptIds = [];
            if (data.dept_id) {
                deptIds = data.dept_id.split(',');
            }
            if (deptIds.length > 1) {
                getDictionary({ code: 'default_visitor_dept' }).then(res => {
                    this.deptList = res.data.data;
                    let dIds = [];
                    deptIds.forEach(e => {
                        if (e != this.deptList[0]['dictValue']) {
                            dIds.push(e);
                        }
                    });
                    this.currentDept = dIds[0].toString();
                });
            } else {
                this.currentDept = data.dept_id;
            }
            //如果取到多个deptid， 过滤掉 jida_dict key 为“default_visitor_dept”的deptid，之后，还是多个的话，用in 查。获取第一个企业数据。
        },
        getDeptTree(tenantId) {
            getDeptTree(tenantId).then(res => {
                this.options = res.data.data;
            });
        },
        getDicList() {
            dictbizService
                .getDictionary({
                    code: 'cardType',
                })
                .then(res => {
                    this.cardTypeList = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                    this.setOptions();
                });
            dictbizService
                .getDictionary({
                    code: 'VISITOR_TIPS',
                })
                .then(res => {
                    this.visitorTips = res.data.data[0].dictValue;
                });
        },
        setOptions() {
            this.stryOption = {
                column: [
                    { type: 'input', prop: 'visitorName', label: '访客姓名', cell: true },
                    {
                        type: 'input',
                        prop: 'contact',
                        label: '访客电话',
                        cell: true,
                    },
                    {
                        type: 'input',
                        prop: 'visitorCompany',
                        label: '访客单位',
                        cell: true,
                    },
                    {
                        type: 'select',
                        prop: 'idCardType',
                        label: '证件类型',
                        cell: true,
                        dicData: this.cardTypeList,
                        props: {
                            label: 'name',
                            value: 'value',
                        },
                    },

                    {
                        type: 'input',
                        prop: 'idCardNo',
                        label: '证件号码',
                        cell: true,
                    },
                    {
                        label: '访客照片',
                        prop: 'visitorPhoto',
                        type: 'upload',
                        listType: 'picture-card',
                        loadText: '附件上传中，请稍等',
                        span: 24,
                        width: 200,
                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },
                ],
            };
            this.carNoOption = {
                column: [{ type: 'input', prop: 'carNo', label: '车牌号码', cell: true }],
            };
        },
        changeCom() {
            let name = '';
            this.companyList.forEach(e => {
                if (e.id == this.ruleForm.companyId) {
                    name = e.name;
                }
            });
            this.ruleForm.companyName = name;
        },
        toHump(name) {
            return name.replace(/\_(\w)/g, function (all, letter) {
                return letter.toUpperCase();
            });
        },
        getComMetaCommonInfo() {
            let obj = {
                pk: 'id',
                id: this.userInfo.companyId,
            };
            getMetaCommonInfo(this.entModel, obj).then(res => {
                if (res.data.code == 200) {
                    let obj = {};
                    Object.keys(res.data.data).forEach(ele => {
                        obj[this.toHump(ele)] = res.data.data[ele];
                    });
                    this.ruleForm = obj;
                    this.ruleForm.companyId = obj.id;
                }
            });
        },
        getMetaCommonInfo() {
            let obj = {
                pk: 'id',
                id: this.ruleForm.id,
            };
            getMetaCommonInfo(this.model, obj).then(res => {
                if (res.data.code == 200) {
                    this.ruleForm.companyName = res.data.data[0].company_name;
                }
            });
        },
        queryByModelCode() {
            standardService.getModelRule(this.entModel).then(res => {
                this.ruleModel = res;
            });
        },
        getMetaCommonList() {
            let body = {
                current: 1,
                size: 50,
            };
            body.wheres = [{ field: 'status', op: 'IN', value: [1] }];
            //
            getMetaCommonList(this.entModel, body).then(res => {
                const data = res.data.data;
                this.companyList = data.records;
            });
        },
        submit() {
            return new Promise(resolve => {
                this.$refs['ruleForm'].validate(valid => {
                    if (valid) {
                        resolve({
                            valid: true,
                            form: this.ruleForm,
                        });
                    } else {
                        let fields = this.$refs['ruleForm'].fields,
                            firstError = {
                                field: '',
                                message: '',
                                name: '',
                            };
                        for (let i = 0; i < fields.length; i++) {
                            if (fields[i].validateState === 'error') {
                                firstError.message = fields[i].validateMessage;
                                firstError.field = fields[i].prop;
                                firstError.name = fields[i].label;
                                break;
                            }
                        }
                        resolve({
                            valid: false,
                            form: this.ruleForm,
                            error: firstError,
                        });
                    }
                });
            });
        },
        back() {
            this.$router.go(-1);
        },

        save() {
            Promise.all([this.submit()]).then(forms => {
                console.log(forms);
                let valid = true,
                    errorObj = null;
                forms.forEach(itemForm => {
                    if (itemForm.valid !== true) {
                        valid = false;
                        if (!errorObj) {
                            errorObj = itemForm.error;
                        }
                    }
                });
                if (valid) {
                    //照片
                    if (this.imgform) {
                        let imgList = [];
                        this.imgform.imgUrl.forEach(e => {
                            if (e.label) {
                                imgList.push(e.label);
                            }
                        });
                        this.ruleForm.visitorPhoto = imgList.toString();
                    }
                    if (this.ruleForm.type) {
                        this.ruleForm.type = Number(this.ruleForm.type);
                    } else {
                        this.ruleForm.type = 1;
                        if (this.userInfo.tenant_id == '000002') {
                            this.ruleForm.type = 0;
                        }
                    }

                    if (this.visitorCarNos.length > 0) {
                        let cars = [];
                        this.visitorCarNos.forEach(e => {
                            cars.push(e.carNo);
                        });
                        this.ruleForm.visitorCarNo = cars.toString();
                    }
                    if (this.userInfo.companyId && this.userInfo.tenant_id == '000002') {
                        this.ruleForm.companyName = this.currentDept;
                        this.ruleForm.companyId = this.userInfo.companyId;
                    } else {
                        if (this.ruleForm.companyId) {
                            this.ruleForm.companyName = this.ruleForm.companyId.toString();
                            this.ruleForm.companyId = this.userInfo.companyId;
                        }
                    }
                    if (this.ruleForm.idCardNo1) {
                        this.ruleForm.idCardNo = this.ruleForm.idCardNo1;
                    }
                    if (this.ruleFormScryy.length > 0) {
                        let ruleFormScryy = [];
                        this.ruleFormScryy.forEach(e => {
                            if (!e.contact) {
                                this.$message.success('请检查随同人员的信息');
                                return false;
                            }
                            if (!e.visitorName) {
                                this.$message.success('请检查随同人员的信息');
                                return false;
                            }
                            e.respondentName = this.ruleForm.respondentName;
                            e.type = this.ruleForm.type;
                            e.reason = this.ruleForm.reason;
                            e.companyId = this.ruleForm.companyId;
                            e.companyName = this.ruleForm.companyName;
                            e.fullCompanyName = this.ruleForm.fullCompanyName;
                            if (this.ruleForm.type == 1) {
                                this.ruleForm.visitorTime = this.ruleForm.arrivalTime;
                            }
                            if (this.ruleForm.type == 0) {
                                this.ruleForm.arrivalTime = this.ruleForm.visitorTime;
                            }
                            e.visitorTime = this.ruleForm.visitorTime;
                            e.arrivalTime = this.ruleForm.arrivalTime;
                            e.inOutType = this.ruleForm.inOutType;
                            e.companyName = this.ruleForm.companyName;
                            let obj = {};
                            Object.keys(e).forEach(ele => {
                                if (!ele.includes('$') && !ele.includes('_')) {
                                    obj[ele] = e[ele];
                                }
                            });
                            if (obj.visitorPhoto) {
                                let imgList = [];
                                obj.visitorPhoto.forEach(e => {
                                    if (e.label) {
                                        imgList.push(e.label);
                                    }
                                });
                                obj.visitorPhoto = imgList.toString();
                            }
                            ruleFormScryy.push(obj);
                        });
                        this.ruleForm.otherVisitors = ruleFormScryy;
                    }

                    if (this.ruleForm.type == 1) {
                        this.ruleForm.visitorTime = this.ruleForm.arrivalTime;
                    }
                    if (this.ruleForm.type == 0) {
                        this.ruleForm.arrivalTime = this.ruleForm.visitorTime;
                    }
                    if (this.ruleForm.id) {
                        delete this.ruleForm.id;
                        submit(this.ruleForm).then(res => {
                            if (res.data.code == 200) {
                                this.back();
                            }
                        });
                    } else {
                        submit(this.ruleForm).then(res => {
                            if (res.data.code == 200) {
                                this.back();
                            }
                        });
                    }
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
    margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
    left: 0px;
}
/deep/ .el-divider__text {
    padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
    width: 68px;
    height: 68px;
    line-height: 72px;
    vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 68px;
    height: 68px;
}
.btnClass {
    display: flow-root;
    height: 50px;
    line-height: 50px;
}
/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 100%;
}
/deep/ .el-select {
    width: 100%;
}
/deep/ .el-cascader {
    width: 100%;
}
.zdy-avue-dynamic /deep/ .pad-15 {
    padding: 0px !important;
}
.zdy-avue-dynamic /deep/ .el-table__empty-text {
    display: none;
}
</style>

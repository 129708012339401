<template>
    <basic-container isCard :body-style="{ padding: '0', height: '100%' }" class="h-100p">
        <div class="h-100p">
            <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" v-model="form" :page.sync="page" @selection-change="selectionChange" @refresh-change="refreshChange"
                       @row-update="rowUpdate" @row-save="rowSave" @row-del="rowDel" @on-load="onLoad" @search-change="searchChange">
            </avue-crud>

        </div>
    </basic-container>
</template>

<script>
import {
    saveMetaCommon,
    updateMetaCommon,
    getMetaCommonList,
    delMetaCommon,
    getMetaCommonListTree,
    getMetaCommonDetail,
    getMetaListUrl,
} from '@/api/busiMode/metaCommon';
import standardService from '@/api/metadata/standard';
import { mapGetters } from 'vuex';
import * as dictbizService from '@/api/system/dictbiz';
const modelPrefix = 'wisdom_park.';
export default {
    data() {
        return {
            searchText: '',
            form: {},
            selectionList: [],
            loading: true,
            dialogVisible: false,
            detailDia: false,
            query: {},
            dialogType: '',
            type: '',
            size: '60%',
            currentRow: {},
            title: '',
            page: {
                size: 10,
                current: 1,
                total: 0,
            },
            option: {
                dateBtn: false,
                border: true,
                height: 'auto',
                tip: true,
                searchShow: true,
                searchMenuSpan: 12,
                addBtn: true,
                refreshBtn: true,
                addRowBtn: false,
                columnBtn: true,
                delBtn: true,
                editBtn: true,
                cancelBtn: true,
                rowKey: '',
                showPage: true,
                index: true,
                indexLabel: '序号',
                selection: false,
                showHeader: true,
                expand: false,
                highlightCurrentRow: true,
                stripe: false,
                menuWidth: 150,
                menu: true,
                reserveSelection: true,
                column: [
                    {
                        overHidden: true,
                        align: 'left',
                        label: '车位名称',
                        prop: 'name',
                        span: 12,
                        key: 'id',
                    },
                    // {
                    //     overHidden: true,
                    //     align: 'left',
                    //     label: '停车场',
                    //     prop: 'park_name',
                    //     span: 12,
                    //     hide: false,
                    //     showColumn: false,
                    //     addDisplay: false,
                    //     editDisplay: false,
                    //     viewDisplay: true,
                    // },
                    {
                        label: '停车场',
                        prop: 'park_id',
                        width: 200,
                        dicUrl: getMetaListUrl(modelPrefix + 'wp_third_qqb_park'),
                        props: {
                            label: 'parkName',
                            value: 'parkId',
                            res: 'data.records',
                        },
                        dicQuery: {
                            current: 1,
                            size: 500,
                        },
                        dicMethod: 'post',
                        addDisplay: true,
                        editDisplay: true,
                        viewDisplay: false,
                        search: true,
                        searchSpan: 8,
                        type: 'select',
                    },
                    {
                        overHidden: true,
                        align: 'left',
                        label: '车位定价(元)',
                        prop: 'price',
                        span: 12,
                        key: 'id',
                    },
                    {
                        overHidden: true,
                        align: 'left',
                        label: '位置描述',
                        prop: 'park_direction',
                        type: 'textarea',
                        rows: 2,
                        span: 24,
                        key: 'id',
                    },
                    {
                        overHidden: true,
                        align: 'left',
                        label: '出租类型',
                        prop: 'type',
                        type: 'select',
                        dicData: [
                            {
                                label: '包天',
                                value: '0',
                            },
                            {
                                label: '包月',
                                value: '1',
                            },
                            {
                                label: '计时',
                                value: '2',
                            },
                            {
                                label: '计次',
                                value: '3',
                            },
                        ],
                    },
                    {
                        overHidden: true,
                        align: 'left',
                        label: '开始时间',
                        prop: 'begin_time',
                        span: 12,
                        type: 'date',
                        format: 'yyyy-MM-dd hh:mm:ss',
                        valueFormat: 'yyyy-MM-dd hh:mm:ss',
                        key: 'id',
                    },
                    {
                        overHidden: true,
                        align: 'left',
                        label: '结束时间',
                        prop: 'end_time',
                        span: 12,
                        type: 'date',
                        format: 'yyyy-MM-dd hh:mm:ss',
                        valueFormat: 'yyyy-MM-dd hh:mm:ss',
                        key: 'id',
                    },
                    {
                        overHidden: true,
                        align: 'left',
                        label: '状态',
                        type: 'select',
                        prop: 'status',
                        dicData: [
                            {
                                label: '未启用',
                                value: '0',
                            },
                            {
                                label: '启用',
                                value: '1',
                            },
                        ],
                    },
                ],
            },
            model: 'wisdom_park.wp_third_qqb_car_space',
            data: [],
            eventType: [],
            doorEventTypes: [],
            ruleModel: {},
            activeTabName: '1',
            indexCode: this.$route.query.indexCode,
            query: {},
        };
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.code_add, false),
                viewBtn: this.vaildData(this.permission.code_view, false),
                delBtn: this.vaildData(this.permission.code_delete, false),
                editBtn: this.vaildData(this.permission.code_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    created() {
        this.queryByModelCode();
    },
    mounted() {
        this.indexCode = this.$route.query.indexCode;
    },
    props: {
        activeName: {
            type: String,
            default: function () {
                return '1';
            },
        },
        onlyError: {
            type: Boolean,
            default: function () {
                return false;
            },
        },
    },
    watch: {
        activeName: {
            handler: function (newValue, oldName) {
                if (newValue) {
                    this.activeTabName = newValue;
                    this.getDicList();
                }
            },
            immediate: true,
            deep: true,
        },
        onlyError: {
            handler: function (newValue, oldName) {},
            immediate: true,
            deep: true,
        },
    },
    methods: {
        go() {
            this.$router.push({
                path: '/door/main',
            });
        },
        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                let pk = 'id';
                delMetaCommon(this.model, { pk: pk, ids: [row[pk]] }).then(res => {
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    this.onLoad(this.page);
                });
            });
        },
        rowSave(row, done, loading) {
            saveMetaCommon(this.model, {
                values: row,
            }).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    window.console.log(error);
                },
            );
        },
        rowUpdate(row, index, done, loading) {
            let o = {
                update: row,
                primaryKeyValue: row['id'],
                primaryKey: 'id',
            };
            updateMetaCommon(this.model, o).then(
                () => {
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                    this.onLoad(this.page);
                },
                error => {
                    loading();
                    window.console.log(error);
                },
            );
        },
        getDicList() {
            if (this.getDicList.length > 0) {
                this.onLoad();
            } else {
                dictbizService
                    .getDictionary({
                        code: 'DoorEventTypes',
                    })
                    .then(res => {
                        this.doorEventTypes = res.data.data;
                        this.onLoad();
                    });
            }
        },
        add() {
            this.type = 'add';
            this.detailDia = true;
        },
        handleClick(val) {
            this.activeTabName = val['name'];
            this.onLoad();
        },
        openDetailDia(type, row) {
            this.type = type;
            this.detailDia = true;
            setTimeout(() => {
                this.currentRow = row;
            }, 0);
        },
        back() {
            this.detailDia = false;
            this.searchReset();
        },
        queryByModelCode() {
            standardService.getModelRule(this.model).then(res => {
                this.ruleModel = res;
            });
        },
        changName(row) {
            this.title = row.name;
        },

        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },

        selectionChange(list) {
            this.selectionList = list;
        },
        selectionClear() {
            this.selectionList = [];
        },

        refreshChange() {
            this.onLoad(this.page);
        },
        closeDia() {
            this.detailDia = false;
            this.refreshChange();
        },
        searchChange(params, done) {
            this.query = params;
            this.onLoad();
            done();
        },
        onLoad() {
            this.loading = true;

            let body = {
                current: this.page.currentPage || 1,
                size: this.page.pageSize || 10,
            };
            if (this.query.parkId) {
                body.wheres = [{ field: 'parkId', value: this.query.parkId }];
            }
            getMetaCommonList(this.model, body).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                this.loading = false;
            });
        },
        doorevents(row) {
            this.detailDia = true;
            this.currentRow = row;
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.deleteIcon {
    font-size: 18px;
    position: absolute;
    left: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 1;
}
/deep/ .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 15px;
}
/deep/ .el-drawer__header {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 20px;
    margin-bottom: 0px;
}
/deep/ .el-drawer__body {
    padding: 0px;
}
/deep/ .el-dialog__body {
    padding: 0px;
}
.buildDetail /deep/ .el-dialog__body {
    padding: 0px;
}
</style>

<template>
  <avue-crud
    ref="table"
    :data="tableData"
    :page.sync="page"
    :option="tableOption"
    :table-loading="tableLoading"
    :search.sync="search"
    @search-change="searchChange"
    @on-load="onLoad"
    @refresh-change="onLoad"
    @sort-change="sortChange"
  >
    <!-- <template slot-scope="{ size }" slot="menuLeft">
      <el-button
          icon="el-icon-download"
          :size="size || 'small'"
          :loading="downLoading"
          @click="getMetaCommonListExport"
          >导 出</el-button
        >
    </template> -->
    <!-- <template slot="tran_dateSearch" slot-scope="scope">
      <el-date-picker class="w-100p" v-model="search.tran_date" :unlink-panels="true"
        value-format="yyyy-MM-dd HH:mm:ss" type="datetimerange" range-separator="至" start-placeholder="开始时间"
        end-placeholder="结束时间">
      </el-date-picker>
    </template>
    <template slot="acc_dateSearch" slot-scope="scope">
      <el-date-picker class="w-100p" v-model="search.acc_date" :unlink-panels="true"
        value-format="yyyy-MM-dd" type="daterange" range-separator="至" start-placeholder="开始时间"
        end-placeholder="结束时间">
      </el-date-picker>
    </template> -->
  </avue-crud>
</template>
<script>
import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  delMetaCommon,
  getMetaCommonDetail,
  getMetaCommonListTree,
  getMetaCommonListExport
} from "@/api/busiMode/metaCommon";
import * as dictService from "@/api/system/dictbiz";

export default {
  data(){
    return {
      model: 'wisdom_park.wp_station_equipment_info',
      modelView: 'wisdom_park.v_wp_station_equipment_info',
      tableLoading: false,
      downLoading: false,
      tableData: [],
      search: {
        
      },
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableOption: {
        height: 'auto',
        // calcHeight: 10,
        // labelPosition: 'top',
        // searchMenuPosition: 'right',
        searchMenuSpan: 24,
        border: true,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        viewBtn: true,
        labelWidth: 200,
        menuWidth: 100,
        // defaultSort: {
        //   prop: 'tran_date',
        //   order: 'descending'
        // },
        column: [
          {
            label: "充电桩设备编码",
            prop: "device_code",
            // search: true
          },
          {
            label: "充电桩设备名称",
            prop: "device_name",
            // search: true
          },
          {
            label: "设备类型",
            prop: "equipment_type",
            width: 200,
            dicData: [
              { label: "直流桩", value: '1'},
              { label: "交流桩", value: '2'}
            ],
            dataType: 'string',
            // search: true
          },
          {
            label: "总功率",
            prop: "power",
            width: 150,
          },
          {
            label: "充电站编码",
            prop: "station_code",
            width: 150,
          }
        ]
      },
      orders: [
        // {field: 'tran_date' , sort: 'DESC'}
      ]
    }
  },
  props: {
    
  },
  mounted(){

  },
  methods: {
    sortChange({column , prop , order}){
      this.orders = [
        {
          field: prop,
          sort: order === 'descending' ? 'DESC' : 'ASC'
        }
      ];
      this.page.currentPage = 1;
      this.onLoad()
    },
    getMetaCommonListExport() {
      let wheres = this.getSearch();
      this.downLoading = true;
      getMetaCommonListExport(this.modelView, {
        current: 1,
        size: this.page.pageSize,
        wheres: wheres,
      })
        .then((res) => {
          this.downLoading = false;
          let url = window.URL.createObjectURL(new Blob([res.data]));
          let link = document.createElement("a");
          link.style.display = "none";
          link.href = url;
          link.download = "银行对账流水" + ".xls";
          document.body.appendChild(link);
          link.click();
        })
        .catch((e) => {
          this.downLoading = false;
        });
    },
    getSearch(){
      let form = this.search , wheres = [];
      Object.keys(form).forEach(
        keyName => {
          if(keyName !== 'ACTIVENAME' && keyName[0] !== '$' && (form[keyName] ?? '') !== ''){
            const column = this.findObject(this.tableOption.column, keyName);
            if(column['searchRange']){
              let startVal = form[keyName][0] , endVal = form[keyName][1];
              if(startVal && endVal){
                // if(column['type'] === 'date'){
                //   startVal = `${startVal} 00:00:00`
                // }
                wheres.push({ field: keyName, value: startVal, op: "GE" });

                // if(column['type'] === 'date'){
                //   endVal = `${endVal} 23:59:59`
                // }
                wheres.push({ field: keyName, value: endVal, op: "LE" });
              }
            }else if(column['searchLike']){
              wheres.push({field: keyName , op: 'LIKE', value: form[keyName]})
            }else{
              wheres.push({field: keyName , value: form[keyName]})
            }
          }
        }
      );
      return wheres;
    },
    searchChange(params, done){
      this.page.currentPage = 1;
      done();
      this.onLoad()
    },
    onLoad(){

      this.tableLoading = true;
      this.tableData = [];
      let wheres = this.getSearch();

      getMetaCommonList(this.model , {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres,
        orders: this.orders
      }).then(
        res => {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
        }
      ).catch(e => {
        this.tableLoading = false;
      })
    }
  }
}
</script>
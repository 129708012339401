<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" :page.sync="page" :before-open="beforeOpen" v-model="form" ref="crud" @row-update="rowUpdate" @row-save="rowSave"
                   @row-del="rowDel" @search-change="searchChange" @search-reset="searchReset" @current-change="currentChange" @size-change="sizeChange" @refresh-change="refreshChange"
                   @on-load="onLoad">
            <template slot="menuLeft">
                <el-button type="danger" size="small" icon="el-icon-delete" plain v-if="permission.epmallorder_delete" @click="handleDelete">删 除
                </el-button>
                <el-button type="text" size="small" icon="el-icon-close" class="none-border" @click="updateStoreState(scope.row)">取消</el-button>
            </template>
        </avue-crud>
    </basic-container>
</template>

<script>
import { getList, getPage, getDetail, update } from '@/api/epmall/orderitem';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            form: {},
            query: {},
            loading: true,
            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            selectionList: [],
            option: {
                height: 'auto',
                calcHeight: 30,
                tip: false,
                searchShow: true,
                addBtn: false,
                searchMenuSpan: 6,
                border: true,
                index: true,
                viewBtn: true,
                dialogClickModal: false,
                column: [
                    {
                        label: '订单号',
                        prop: 'orderId',
                        width: 200,
                        rules: [
                            {
                                required: true,
                                message: '请输入订单id',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '实付金额',
                        prop: 'totalFee',
                        rules: [
                            {
                                required: true,
                                message: '请输入实付金额',
                                trigger: 'blur',
                            },
                        ],
                    },
                    // {
                    //     label: '支付类型',
                    //     prop: 'paymentType',
                    //     search: true,
                    //     type: 'select',
                    //     dicData: [
                    //         {
                    //             label: '在线支付',
                    //             value: 1,
                    //         },
                    //         {
                    //             label: '货到付款',
                    //             value: 2,
                    //         },
                    //     ],
                    //     rules: [
                    //         {
                    //             required: true,
                    //             message: '请输入支付类型 1在线支付 2货到付款',
                    //             trigger: 'blur',
                    //         },
                    //     ],
                    // },
                    // {
                    //     label: '邮费',
                    //     prop: 'postFee',
                    //     addDisplay: false,
                    //     rules: [
                    //         {
                    //             required: true,
                    //             message: '请输入邮费',
                    //             trigger: 'blur',
                    //         },
                    //     ],
                    // },
                    // {
                    //   label: "付款时间",
                    //   prop: "paymentTime",
                    //   addDisplay: false,
                    //   rules: [{
                    //     required: true,
                    //     message: "请输入付款时间",
                    //     trigger: "blur"
                    //   }]
                    // },
                    {
                        label: '付款时间',
                        prop: 'orderTime',
                        type: 'datetime',
                        format: 'yyyy-MM-dd',
                        addDisplay: false,
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        rules: [
                            {
                                required: true,
                                message: '请输入付款时间',
                                trigger: 'blur',
                            },
                        ],
                    },
                    // {
                    //   label: "发货时间",
                    //   prop: "consignTime",
                    //   addDisplay: false,
                    //   rules: [{
                    //     required: true,
                    //     message: "请输入发货时间",
                    //     trigger: "blur"
                    //   }]
                    // },
                    // {
                    //     label: '发货时间',
                    //     prop: 'consignTime',
                    //     type: 'datetime',
                    //     addDisplay: false,
                    //     format: 'yyyy-MM-dd',
                    //     valueFormat: 'yyyy-MM-dd HH:mm:ss',
                    //     rules: [
                    //         {
                    //             required: true,
                    //             message: '请输入发货时间',
                    //             trigger: 'blur',
                    //         },
                    //     ],
                    // },
                    // {
                    //   label: "交易完成时间",
                    //   prop: "endTime",
                    //   addDisplay: false,
                    //   rules: [{
                    //     required: true,
                    //     message: "请输入交易完成时间",
                    //     trigger: "blur"
                    //   }]
                    // },
                    // {
                    //     label: '交易完成时间',
                    //     prop: 'endTime',
                    //     type: 'datetime',
                    //     addDisplay: false,
                    //     format: 'yyyy-MM-dd',
                    //     valueFormat: 'yyyy-MM-dd HH:mm:ss',
                    //     rules: [
                    //         {
                    //             required: true,
                    //             message: '请输入交易完成时间',
                    //             trigger: 'blur',
                    //         },
                    //     ],
                    // },
                    // {
                    //   label: "交易关闭时间",
                    //   prop: "closeTime",
                    //   addDisplay: false,
                    //   rules: [{
                    //     required: true,
                    //     message: "请输入交易关闭时间",
                    //     trigger: "blur"
                    //   }]
                    // },
                    // {
                    //     label: '交易关闭时间',
                    //     prop: 'closeTime',
                    //     type: 'datetime',
                    //     addDisplay: false,
                    //     format: 'yyyy-MM-dd',
                    //     valueFormat: 'yyyy-MM-dd HH:mm:ss',
                    //     rules: [
                    //         {
                    //             required: true,
                    //             message: '请输入交易关闭时间',
                    //             trigger: 'blur',
                    //         },
                    //     ],
                    // },
                    // {
                    //     label: '物流名称',
                    //     prop: 'shippingName',
                    //     search: true,
                    //     addDisplay: false,
                    //     rules: [
                    //         {
                    //             required: true,
                    //             message: '请输入物流名称，不显示',
                    //             trigger: 'blur',
                    //         },
                    //     ],
                    // },
                    // {
                    //     label: '物流单号',
                    //     prop: 'shippingCode',
                    //     search: true,
                    //     addDisplay: false,
                    //     rules: [
                    //         {
                    //             required: true,
                    //             message: '请输入物流单号，不显示',
                    //             trigger: 'blur',
                    //         },
                    //     ],
                    // },
                    // {
                    //     label: '用户id',
                    //     prop: 'userId',
                    //     rules: [
                    //         {
                    //             required: true,
                    //             message: '请输入用户id',
                    //             trigger: 'blur',
                    //         },
                    //     ],
                    // },
                    // {
                    //     label: '买家昵称',
                    //     prop: 'buyerNick',
                    //     rules: [
                    //         {
                    //             required: true,
                    //             message: '请输入买家昵称',
                    //             trigger: 'blur',
                    //         },
                    //     ],
                    // },
                    {
                        label: '交易状态',
                        prop: 'status',
                        type: 'select',
                        dicData: [
                            {
                                label: '未付款',
                                value: 0,
                            },
                            {
                                label: '已付款',
                                value: 1,
                            },
                            {
                                label: '未发货',
                                value: 2,
                            },
                            {
                                label: '已发货',
                                value: 3,
                            },
                            {
                                label: '交易成功',
                                value: 4,
                            },

                            {
                                label: '交易关闭',
                                value: 5,
                            },
                            {
                                label: '交易失败',
                                value: 6,
                            },
                            {
                                label: '订单取消',
                                value: 7,
                            },
                        ],
                        rules: [
                            {
                                required: true,
                                message: '请输入买家是否已经评价',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '买家留言',
                        prop: 'sellPoint',
                        span: 24,
                        minRows: 6,
                        width: 200,
                        type: 'textarea',
                        rules: [
                            {
                                required: true,
                                message: '请输入买家留言',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '店铺名称',
                        prop: 'storeName',
                        rules: [
                            {
                                required: true,
                                message: '请输入店铺id',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '组织',
                        prop: 'orgId',
                        hide: true,
                        rules: [
                            {
                                required: true,
                                message: '请输入组织ID',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '发票',
                        prop: 'billId',
                        hidden: false,
                        hide: true,
                        rules: [
                            {
                                required: true,
                                message: '请输入发票id',
                                trigger: 'blur',
                            },
                        ],
                    },
                    // {
                    //     label: '收货地址',
                    //     prop: 'streetName',
                    //     rules: [
                    //         {
                    //             required: true,
                    //             message: '请输入对应的收货地址',
                    //             trigger: 'blur',
                    //         },
                    //     ],
                    // },
                ],
            },
            data: [],
        };
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.epmallorder_add, false),
                viewBtn: this.vaildData(this.permission.epmallorder_view, false),
                delBtn: this.vaildData(this.permission.epmallorder_delete, false),
                editBtn: this.vaildData(this.permission.epmallorder_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    methods: {
        rowSave(row, done, loading) {
            add(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    window.console.log(error);
                },
            );
        },
        rowUpdate(row, index, done, loading) {
            update(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    console.log(error);
                },
            );
        },
        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(row.id);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning('请选择至少一条数据');
                return;
            }
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(this.ids);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    this.$refs.crud.toggleSelection();
                });
        },
        beforeOpen(done, type) {
            if (['edit', 'view'].includes(type)) {
                getDetail(this.form.id).then(res => {
                    this.form = res.data.data;
                });
            }
            done();
        },
        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },
        searchChange(params, done) {
            this.query = params;
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
            done();
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        currentChange(currentPage) {
            this.page.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        onLoad(page, params = {}) {
            this.loading = true;
            getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                this.loading = false;
                this.selectionClear();
            });
        },
    },
};
</script>

<style>
</style>

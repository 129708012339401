export default {
  colNum: 100,
  rowHeight: 10,
  maxRows: Infinity,
  margin: [10, 10],
  isDraggable: true,
  isResizable: true,
  isMirrored: false,
  autoSize: true,
  verticalCompact: true,
  preventCollision: false,
  useCssTransforms: true,
  responsive: false
}
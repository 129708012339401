<template>
  <el-row>
    <el-col :span="24">
      <basic-container>
        <avue-crud
          ref="crud"
          :option="option"
          :data="list"
          :page="page"
          @row-save="rowSave"
          @row-del="rowDel"
          @row-update="rowUpdate"
        >
        </avue-crud>
      </basic-container>
    </el-col>
  </el-row>
</template>

<script>
import { automatic } from "@/api/busiMode/meta";
export default {
  name: "tablePreview",
  components: {},
  props: {
    option: {
      type: Object,
      default: function () {
        return {};
      },
    },
    page: {
      type: Object,
      default: function () {
        return {};
      },
    },
    temId: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      list: [],
    };
  },
  watch: {},
  methods: {},
  created() {},
  mounted() {
    this.$nextTick(() => {});
  },
  beforeCreate() {},
  computed: {},
};
</script>
<style scoped></style>

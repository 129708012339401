<template>
  <el-row>
    <!-- <el-divider content-position="left">开户信息</el-divider> -->
    <div class="pad-10" v-if="formList.length == 0 && doType == 'detail'">
      暂无数据
    </div>
    <div
      class="cur-p pad-10"
      v-if="formList.length == 0 && doType != 'detail'"
      @click="addOne"
    >
      <i class="el-icon-plus cur-p"></i>
      添加
    </div>
    <el-col :span="24" class="mar-t-10" v-for="(form, i) of formList" :key="i">
      <el-form
        :model="form"
        size="small"
        label-width="100px"
        label-position="top"
        :rules="ruleModel"
        :disabled="doType == 'detail'"
      >
        <el-col :span="6">
          <el-form-item label="账户" prop="collection_account">
            <el-select
              v-model="form.collection_account"
              filterable
              clearable
              @change.native="selectBlur"
              @blur.native="selectBlur"
              placeholder="选择收款账号"
              :loading="loading"
            >
              <el-option
                v-for="item of dictData.collection_account"
                :key="item.id"
                :label="item.collection_account"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <!-- <el-form-item
            label="账户"
            prop="collection_account"
            v-if="oldFormList.length <= formList.length"
          >
            <el-input v-model="form.collection_account" clearable></el-input>
          </el-form-item> -->
        </el-col>
        <el-col :span="8">
          <el-form-item label="开户银行" prop="deposit_bank">
            <el-input v-model="form.deposit_bank" clearable></el-input>
          </el-form-item>
        </el-col>
        <template v-if="doType != 'detail'">
          <el-col :span="2" class="pad-0">
            <i
              class="el-icon-close mar-t-40 cur-p mar-r-5"
              @click="delOne(i)"
            ></i>

            <i
              v-if="i + 1 == formList.length"
              class="el-icon-plus mar-t-40 cur-p"
              @click="addOne"
            ></i>
          </el-col>
        </template>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
import standardService from "@/api/metadata/standard";
import Vue from "vue";
import {
  getMetaCommonList,
  saveMetaCommon,
  updateMetaCommon,
  delMetaCommon,
} from "@/api/busiMode/metaCommon";

export default {
  data() {
    return {
      ruleModel: {},
      loading: false,
      oldFormList: [],
      form: {},
      delList: [],
      formList: [
        {
          id: "",
          deposit_bank: "",
          account_name: "",
          collection_account: "",
        },
      ],
      dictData: {
        collection_account: [],
      },
      model: "wisdom_park.wp_company",
    };
  },
  computed: {},
  props: {
    doType: {
      type: String,
      default() {
        return "";
      },
    },
    currentRow: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {
    this.queryByModelCode();
  },
  watch: {
    currentRow: {
      immediate: true,
      handler(val) {
        if (val.id) {
          this.getMetaCommonInfo();
          this.getDictData();
        }
      },
    },
    doType: {
      immediate: true,
      handler(val) {},
    },
  },
  methods: {
    // selectBlur(e) {
    //   Vue.set(this.form, "collection_account", e.target.value);
    // },
    selectBlur(e) {
      // 意见类型
      console.log(e.target.value);
      if (e.target.value !== "") {
        console.log(this.formList[this.formList.length - 1]);

        this.formList[this.formList.length - 1].collection_account =
          e.target.value;
        Vue.set(this.formList, "", e.target.value);
        this.$forceUpdate(); // 强制更新
      }
    },
    searchModel(queryStr) {
      console.log(this.currentRow);
      let params = {
        wheres: [
          {
            field: "enterprise_id",
            value: this.currentRow.id,
          },
        ],
      };
      if (queryStr) {
        params.wheres.push({
          field: "collection_account",
          op: "LIKE",
          value: queryStr,
        });
      }
      this.loading = true;
      getMetaCommonList(`wisdom_park.wp_collection_account`, {
        current: 1,
        size: 500,
        ...params,
      }).then((res) => {
        this.dictData.collection_account = res.data.data.records;
        this.loading = false;
      });
    },
    queryByModelCode() {
      standardService.getModelRule(this.model).then((res) => {
        this.ruleModel = res.data;
      });
    },
    addOne() {
      this.formList.push({
        id: "",
        deposit_bank: "",
        account_name: "",
        collection_account: "",
      });
      console.log(this.formList);
    },
    delOne(i) {
      this.delList.push(i);
      this.formList.splice(i, 1);
    },
    getDictData() {
      let params = {
        wheres: [
          {
            field: "enterprise_id",
            value: this.currentRow.id,
          },
        ],
      };
      [{ code: "wp_collection_account", prop: "collection_account" }].forEach(
        (item) => {
          this.getMetaCommonList(item.code, item.prop, params);
        }
      );
    },
    getMetaCommonInfo() {
      let body = {
        current: 1,
        size: 1000,
      };
      if (this.currentRow.id) {
        body["wheres"] = [
          { field: "enterprise_id", value: this.currentRow.id },
        ];
      }
      this.formList = [];
      getMetaCommonList(`wisdom_park.wp_collection_account`, body).then(
        (res) => {
          const data = res.data.data;
          this.oldFormList = JSON.parse(JSON.stringify(res.data.data.records));
          this.formList = data.records;
        }
      );
    },
    getMetaCommonList(code, prop, params) {
      console.log(params);
      return getMetaCommonList(`wisdom_park.${code}`, {
        current: 1,
        size: 500,
        ...params,
      }).then((res) => {
        if (res.data && res.data.data && prop) {
          this.dictData[prop] = res.data.data.records;
        }
        return res;
      });
    },
    // modelChange(i, form) {
    //   console.log(i, form);
    //   let collectionAccounts = this.dictData.collection_account;
    //   collectionAccounts.forEach((e) => {
    //     if (e.collection_account == this.formList[i].collection_account) {
    //       this.formList[i] = e;
    //     }
    //   });
    // },

    async save(companyId) {
      console.log(this.oldFormList);
      console.log(this.formList);
      let promiseList = [];
      this.formList.forEach((form, index) => {
        if (form.id) {
          this.oldFormList.forEach((old, index) => {
            if (form.id == old.id) {
              if (JSON.stringify(form) != JSON.stringify(old)) {
                console.log("xiug");
                let o = {
                  update: form,
                  primaryKeyValue: form["id"],
                  primaryKey: "id",
                };
                promiseList.push(
                  new Promise((resolve, reject) => {
                    updateMetaCommon(
                      "wisdom_park.wp_collection_account",
                      o
                    ).then((res) => {
                      if (res.data.code == 200) {
                        console.log("修改成功");
                      }
                    });
                  })
                );
              }
            }
          });
        } else {
          let obj = {
            deposit_bank: form.deposit_bank,
            collection_account: form.collection_account,
            id: form.collection_account_id,
            enterprise_id: companyId,
          };
          promiseList.push(
            new Promise((resolve, reject) => {
              saveMetaCommon("wisdom_park.wp_collection_account", {
                values: obj,
              }).then((_res) => {});
            })
          );
        }
      });

      if (this.delList.length > 0) {
        let dels = [];
        this.delList.forEach((del, index) => {
          dels.push(del.id);
        });
        promiseList.push(
          new Promise((resolve, reject) => {
            saveMetaCommon("wisdom_park.wp_collection_account", {
              pk: "id",
              ids: dels,
            }).then((_res) => {});
          })
        );
      }
      Promise.all(promiseList).then((result) => {
        this.$emit("close");
      });
    },
    // async saveMetaCommon(data, companyId) {
    //   console.log(this.currentRow);
    //   var ary = [];
    //   data.forEach((v, index) => {
    //     let obj = {
    //       account_bank: v.account_bank,
    //       collection_account: v.collection_account,
    //       id: v.collection_account_id,
    //       enterprise_id: companyId,
    //     };
    //     console.log("here");
    //     const res = saveMetaCommon("wisdom_park.wp_collection_account", {
    //       values: obj,
    //     }).then((_res) => {
    //       if (_res.code == 200) {
    //         return {
    //           id: index + 1,
    //           imgs: _res.data,
    //         };
    //       }
    //       return "";
    //     });
    //     ary.push(res);
    //   });
    //   Promise.all(ary).then((result) => {
    //     this.$emit("close");
    //   });
    // },
  },
};
</script>
<style lang="scss" scoped></style>

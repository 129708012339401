<!--调用日志--->
<template>
  <basic-container>
    <el-button @click="goBack" icon="el-icon-back" type="text" size="small">返回</el-button>
    <avue-crud
      ref="crud"
      :table-loading="tableLoading"
      :data="tableData"
      :page.sync="page"
      :option="tableOption"
      @on-load="onLoad"
      @refresh-change="onLoad"
    >
      <template slot="shareUrl" slot-scope="scope">
        <el-tooltip class="item" :content="scope.row.shareUrl" placement="top-start">
          <span>{{scope.row.shareUrl.split('/').shift()}}...{{scope.row.shareUrl.split('/').pop()}}</span>
          <div slot="content"><span class="mar-r-15"><i @click="$copy(scope.row.shareUrl)" class="el-icon-document-copy cur-p"></i></span>{{scope.row.shareUrl}}</div>
        </el-tooltip>
      </template>
      <template slot="menuLeft">
        <!-- 搜索 -->
        <el-row :gutter="$store.state.common.gutter">
         <el-col :span='12'>
           <el-date-picker
              size="small"
              v-model="search.createTime"
              format="yyyy-MM-dd HH:mm:ss"
              valueFormat="yyyy-MM-dd HH:mm:ss"
              type="datetimerange"
              range-separator="至"
              start-placeholder="请选择开始日期"
              end-placeholder="请选择结束日期"
            >
            </el-date-picker>
         </el-col>
          <el-col :span="6">
            <el-select size="small" v-model='search.appId' clearable placeholder="请选择调用系统">
              <el-option v-for='item in appList' :key='item.id' :value='item.id' :label='item.appName'></el-option>
            </el-select>
          </el-col>
          <el-col :span="6">
            <el-button size="small" icon="el-icon-search" type="primary" @click="searchChange">搜索</el-button>
            <el-button size="small" icon="el-icon-delete" @click="emptHandle">清空</el-button>
          </el-col>
        </el-row>
      </template>
    </avue-crud>
  </basic-container>
</template>
<script>
import api from '@/api/dataShare/index';
import * as dictbizService from '@/api/system/dictbiz';
import dataShareForm from './datashareForm.vue';
import dataShareTest from './datashareTest.vue';
import dataShareView from './datashareView.vue';
import dataShareRegister from './datashareRegister';
import dataShareRegisterView from './datashareRegisterView.vue';
import apiStatus from '@/config/apiStatus';
import {apicollectionSelect,datasharelogPage} from '@/api/dataApiAccredit/dataApiAccredit'

import {
  getDataAppList
} from '@/api/dataService/dataApp'
export default {
  components: {
    dataShareForm,
    dataShareTest,
    dataShareView,
    dataShareRegister,
    dataShareRegisterView,
  },
  data(){
    return {
      appList: [],
      stateData: [
        {
          label: '未上架',
          value: 0
        },
        {
          label: '已上架',
          value: 1
        }
      ],
      search: {},
      drawer: false,
      drawerType: '',
      dialogTitle: '',
      dialogVisibleType: '',
      dialogWidth: '70%',
      dialogVisible: false,
      tableLoading: false,
      currentId: null,
      tableData: [],
      page: {
          pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableOption: {
        // height: 'auto',
        // calcHeight: 30,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: false,
        editBtn: false,
        addBtn: false,
        delBtn: false,
        menu: false,
        size: 'small',
        // viewBtn: true,
        // delBtn: false,
        // menuWidth: 350,
        dialogClickModal: false,
        menuWidth: 300,
        column: [
          {
            label: 'API名称',
            prop: 'dataShareName',
            span: 24,
          },
          {
            label: '入参',
            prop: '_inputParams'
          },
          {
            label: '调用时间',
            prop: 'requestTime'
          },
          {
            label: '调用状态',
            prop: 'returnStatus',
            formatter(row) {
              if (row.returnStatus==200) {
                return '成功'
              } else if (row.returnStatus==500) {
                return '失败'
              } else {
                return '-'
              }
            }
          },
          {
            label: '调用系统',
            prop: 'appName'
          }
        ]
      }
    }
  },
  methods: {
    // 返回
    goBack () {
      this.$breadcrumb.pop();
      this.$router.back()
    },
    // 清空搜索
    emptHandle () {
      this.search.createTime=null
      this.search.appId=''
    },
    // 搜索
    searchChange () {
      this.onLoad()
    },
    onLoad(){
      this.tableLoading = true;
      this.tableData = [];
      datasharelogPage({
        appId: this.search.appId,
        dataShareId: this.$route.query.id,
        searchEndTime: this.validatenull(this.search.createTime)?"":this.search.createTime[1],
        searchStartTime: this.validatenull(this.search.createTime)?"":this.search.createTime[0],
        current: this.page.currentPage,
        size: this.page.pageSize
      }).then(
        res => {
          this.tableLoading = false;
          if(res.data){
            let tableData = [];
            (res.data.data.records || []).forEach(
              item => {
                tableData.push({
                  ...item,
                  _inputParams: item.inputParams ? JSON.parse(item.inputParams).map(v => v.key).join(',') : '',
                })
              }
            )
            this.tableData = tableData;
            this.page.total = res.data.data.total;
          }
        }
      )
    }
  },
  created(){
    if(this.$breadcrumb.breadcrumbs.length < 4){
      this.$breadcrumb.add({
          name: '调用日志'
      })
    }
    // 获取所有应用
    getDataAppList({current: 1,size: 100}).then((res) => {
      if(res.data.code==200) {
        this.appList=res.data.data.records
      } else {
        this.appList=[]
      }
    })
  },
  mounted(){

  }
}
</script>

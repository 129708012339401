<template>
  <div>
    <el-divider content-position="left">楼宇信息</el-divider>
    <el-form
      class="pad-10"
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      label-position="top"
      size="small"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="所属板块" prop="name">
            <el-select
              v-model="ruleForm.part_id"
              size="small"
              placeholder="请选择板块"
            >
              <template v-for="(item, index) in partList">
                <el-option
                  v-if="item.parent_id == 0"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </template>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="所属区域" prop="name">
            <el-select
              v-model="ruleForm.sub_part_id"
              size="small"
              placeholder="请选择区域"
            >
              <template v-for="(item, index) in partList">
                <el-option
                  v-if="item.parent_id == ruleForm.part_id"
                  :key="index"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </template>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="楼宇名称" prop="name">
            <el-input
              v-model="ruleForm.name"
              placeholder="请输入楼宇名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="楼宇编号" prop="code">
            <el-input
              placeholder="请输入楼宇编号"
              v-model="ruleForm.code"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6" v-if="showFloorNum">
          <el-form-item label="层数" prop="floor_num">
            <el-input
              placeholder="请输入层数"
              type="number"
              v-model.number="ruleForm.floor_num"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="物业费(m²/月)" prop="property_management_fee">
            <el-input
              placeholder="请输入物业费"
              v-model="ruleForm.property_management_fee"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item
            label="建筑面积(m²)"
            prop="floorage"
            :rules="[
              { required: true, message: '请输入建筑面积', trigger: 'blur' },
            ]"
          >
            <el-input
              placeholder="请输入建筑面积"
              v-model="ruleForm.floorage"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="在管面积(m²)" prop="manger_area">
            <el-input
              placeholder="请输入在管面积"
              v-model="ruleForm.manger_area"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item label="租赁方式">
            <el-radio-group v-model="ruleForm.lease_type">
              <el-radio label="单间"></el-radio>
              <el-radio label="整层"></el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="排序" prop="sort">
            <el-input
              placeholder="请输入排序"
              v-model="ruleForm.sort"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="楼宇地址" prop="form">
        <el-input type="textarea" v-model="ruleForm.address"></el-input>
        <!-- <avue-input-map
          :params="params"
          placeholder="请选择楼宇地址"
          v-model="adressForm"
        ></avue-input-map> -->
      </el-form-item>
      <el-form-item label="楼宇描述" prop="description">
        <el-input
          type="textarea"
          placeholder="请选择楼宇描述"
          v-model="ruleForm.description"
        ></el-input>
      </el-form-item>
    </el-form>
    <el-divider content-position="left">楼宇图片</el-divider>
    <avue-form :option="option" v-model="imgform"></avue-form>
    <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import { getMetaCommonList, getPicListByIds } from "@/api/busiMode/metaCommon";

export default {
  props: {
    ruleModel: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  props: {
    showFloorNum: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },

  data() {
    return {
      option: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,

            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      imgform: {
        imgUrl: [],
      },
      dialogImageUrl: "",
      dialogVisible: false,
      ruleForm: {},
      params: {
        zoom: 4,
        zoomEnable: false,
        dragEnable: true,
      },
      partList: [],
      model: "wisdom_park.wp_part",
      adressForm: [
        120.34657865613701,
        36.08180852446481,
        "山东省青岛市市北区登州路街道宁海路4号",
      ],
    };
  },
  computed: {},
  mounted() {
    this.getPart();
  },
  methods: {
    getPart() {
      let body = {
        current: 1,
        size: 10,
        orders: [{ field: "sort", sort: "ASC" }],
      };

      getMetaCommonList(this.model, body).then((res) => {
        const data = res.data.data;
        this.partList = data.records;
        this.ruleForm.part_id = this.partList[0]["id"];
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    submit() {
      return new Promise((resolve) => {
        this.$refs["ruleForm"].validate((valid) => {
          console.log(this.$refs["ruleForm"]);
          if (valid) {
            resolve({
              valid: true,
              form: this.ruleForm,
            });
          } else {
            let fields = this.$refs["ruleForm"].fields,
              firstError = {
                field: "",
                message: "",
                name: "",
              };
            for (let i = 0; i < fields.length; i++) {
              if (fields[i].validateState === "error") {
                firstError.message = fields[i].validateMessage;
                firstError.field = fields[i].prop;
                firstError.name = fields[i].label;
                break;
              }
            }
            resolve({
              valid: false,
              form: this.ruleForm,
              error: firstError,
            });
          }
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
  margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
  left: 0px;
}
/deep/ .el-divider__text {
  padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
  width: 68px;
  height: 68px;
  line-height: 72px;
  vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
}
/deep/ .el-select {
  width: 100%;
}
</style>

<template>
  <div>
    <el-col :span="24" class="mar-t-15">
      <el-card shadow="hover">
        <div slot="header" class="clearfix">
          <span>快捷操作</span>
        </div>
        <div class="mar-t-10f pad-b-10">
          <el-button size="mini" type="primary" @click="dialogVisible = true"
            >人员入驻</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="goOther('/visitor/visitorOrder?type=0')"
            >访客预约</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="goOther('/contract/manage')"
            >我的合同</el-button
          >
          <el-button
            size="mini"
            type="primary"
            @click="goOther('/employee/approvalEmp')"
            >人员审核</el-button
          >
          <el-button size="mini" type="primary" @click="checkout"
            >企业退出</el-button
          >
        </div>
      </el-card>
    </el-col>
    <el-col class="mar-t-10">
      <el-tabs v-model="activeName" @tab-click="handleClick" class="pad-l-5">
        <!-- <el-tab-pane label="工单跟踪" name="2"></el-tab-pane> -->
        <el-tab-pane label="账单" name="3"></el-tab-pane>
        <!-- <el-tab-pane label="能源消耗" name="4"></el-tab-pane> -->
      </el-tabs>
    </el-col>
    <el-col :span="24" class="pad-0" v-if="activeName == 3">
      <el-card shadow="hover">
        <!-- <div slot="header" class="clearfix">
          <span>账单</span>
        </div> -->
        <ReceiptTable :queryParams="{ dept_id: 1 }"></ReceiptTable>
      </el-card>
    </el-col>
    <el-col :span="24" class="pad-0" v-if="activeName == 2">
      <el-card shadow="hover">
        <!-- <div slot="header" class="clearfix">
          <span>账单</span>
        </div> -->
        <repair></repair>
      </el-card>
    </el-col>
    <el-dialog
      class="join-dialog"
      title="一键加入"
      :visible.sync="dialogVisible"
      width="600px"
    >
      <div ref="AssetTag" class="pad-5" style="margin: 0 auto">
        <Join v-if="dialogVisible"></Join>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <!-- <el-button size="small" type="primary" @click="printTag"
          >打 印</el-button
        > -->
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { getMetaCommonList } from "@/api/busiMode/metaCommon";

import ReceiptTable from "../../finance/receipt-table.vue";
import repair from "../../assets/repair.vue";
import { mapGetters, mapState } from "vuex";
import { checkout } from "@/api/contract/index";

import Join from "../join";
// import zjtable from "./zjtable";
export default {
  components: { ReceiptTable, Join, repair },

  data() {
    return {
      dialogVisible: false,
      activeName: "3",
      building: "",
      buildList: [],
      comObj: {},
      page: {
        pageSize: 1000,
        currentPage: 1,
        total: 0,
      },
    };
  },
  mounted() {
    this.getBuildList();
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  methods: {
    checkout(row) {
      this.$confirm("确认退出园区?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        checkout({ companyId: this.userInfo.companyId, approvalType: 1 }).then(
          (res) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
          }
        );
      });
    },
    goOther(path) {
      this.$router.push({
        path: path,
      });
    },
    getBuildList() {
      getMetaCommonList("wisdom_park.wp_building", this.page).then((res) => {
        const data = res.data.data;
        this.buildList = data.records;
      });
    },
    handleClick(tab, event) {
      this.activeName = tab.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.ftzClor {
  color: #999999;
}
/deep/ .el-card__body {
  padding: 15px;
}
/deep/.el-tabs__nav-wrap::after {
  position: static !important;
}
/deep/ .el-card__header {
  padding: 8px 15px;
  line-height: 34px;
  font-size: 14px;
  border: none;
}
.pdtitle {
  line-height: 25px;
  background: #eff6ff;
  width: 25px;
  height: 25px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
}
.join-dialog {
  /deep/ .el-dialog__body {
    padding-bottom: 0px;
  }
}
</style>

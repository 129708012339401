<template>
  <basic-container>
    <avue-crud
      ref="crud"
      v-model="modelForm"
      :data="tableDatas"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      @on-load="onLoad"
      @row-save="handleSave"
      @refresh-change="onLoad"
      @row-update="handleUpdate"
    >
      <template slot="resource" slot-scope="scope">
        {{scope.row.rule['resource']}}
      </template>
      <template slot="limitApp" slot-scope="scope">
        {{scope.row.rule['limitApp']}}
      </template>
      <template slot="strategy" slot-scope="scope">
        {{scope.row.rule['strategy'] === 0 ? '白名单':'黑名单'}}
      </template>
      <template slot-scope="{type,size,row}" slot="menu">
        <el-button @click.stop="handleEdit(row)" icon="el-icon-edit" :size="size" :type="type">编辑</el-button>
        <el-button @click.stop="handleDel(row)" icon="el-icon-delete" :size="size" :type="type">删除</el-button>

        <!--<el-button @click="copyFormAdd" icon="el-icon-document-copy" :size="size" :type="type">复制</el-button>-->
      </template>
    </avue-crud>
  </basic-container>
</template>
<script>
import defaultService from '@/config/defaultService';
import { getDataAuthList, saveAuth, delAuth, updateAuth } from '@/api/dataService/dataAuthority'
import {
  getServiceList
} from '@/api/dataService/dataIdentity'
  export default {
    data(){
      return {
        page:{
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        modelForm: {
          resource:'',
          limitApp:'',
          strategy:0
        },
        tableLoading: false,
        tableDatas: [],
        tableOption: {
          editBtn: false,
          delBtn: false,
          column:[
            {
              label:'资源名',
              prop:'resource',
              span:24,
              rules: [{
                required: true,
                message: "请输入资源名",
                trigger: "blur"
              }]
            },
            {
              label:'流控应用',
              prop:'limitApp',
              span:24,
              placeholder:'指调用方，多个调用方名称用半角英文逗号（,）分隔',
              rules: [{
                required: true,
                message: "请输入流控应用",
                trigger: "blur"
              }]
            },
            {
              label:'授权类型',
              prop:'strategy',
              type: "radio",
              value: 0,
              dicData: [{
                label: '白名单',
                value: 0
              }, {
                label: '黑名单',
                value: 1
              }],
              span:24,
              rules: [{
                required: true,
                message: "请选择授权类型",
                trigger: "blur"
              }]
            }
          ]
        }
      }
    },
    methods: {
      async onLoad() {
        await getServiceList().then( res => {
          let item = res.data.data[0].machines[0];
          defaultService['app'] = item['app'];
          defaultService['ip'] = item['ip'];
          defaultService['port'] = item['port'];
        });
        this.tableLoading = true;
        getDataAuthList(defaultService).then( res => {
          this.tableDatas = res.data.data;
          this.page.total = res.data.data.length;
          let start = (this.page.currentPage - 1) * this.page.pageSize;
          let end = start + this.page.pageSize;
          this.tableDatas = res.data.data.slice(start,end);
          this.tableLoading = false;
        }).catch( res => {
          this.tableLoading = false;
        });
      },
      handleSave(row,done,loading) {
        let rule = {
          limitApp: row['limitApp'],
          resource: row['resource'],
          strategy: row['strategy'],
        };
        let obj = {
          ...defaultService,
          rule
        };
        saveAuth(obj).then( res => {
          done();
          this.onLoad();
        });
      },
      handleEdit(row) {
        let _t = this;
        this.modelForm.limitApp = row.rule.limitApp;
        this.modelForm.resource = row.rule.resource;
        this.modelForm.strategy = row.rule.strategy;
        this.$nextTick( () => {
          this.$refs.crud.rowEdit(row);
        })
      },
      handleUpdate(row, index, done, loading) {
        let rule = {
          limitApp: row['limitApp'],
          resource: row['resource'],
          strategy: row['strategy'],
        };
        let obj = {
          id:row['id'],
          ...defaultService,
          rule
        };
        updateAuth(obj).then( res => {
          done();
          this.onLoad();
        });
      },
      handleDel(row) {
        let _t = this;
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            delAuth(row['id']).then(res => {
              _t.onLoad();
            })
          })
      }
    }
  }
</script>

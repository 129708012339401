<template>
  <div>
    <el-tabs v-model="search.ACTIVENAME" @tab-click="tabsHandleClick">
      <el-tab-pane v-for="item of tabs" :label="item.dictValue" :name="item.dictKey"></el-tab-pane>
    </el-tabs> 
    <avue-crud
      v-if="init"
      ref="table"
      :data="tableData"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      :search.sync="search"
      v-model="form"
      @search-change="searchChange"
      @on-load="onLoad"
      @refresh-change="onLoad"
      @row-save="saveTableForm"
      @row-update="(row, index, done, loading) => saveTableForm(row, done, loading)"
      @row-del="rowDel"
    >
      <template slot="menu" slot-scope="scope">
        <el-button
          type="text"
          size="small"
          icon="el-icon-link"
          @click="pickFn(scope.row)"
          v-if="scope.row.status === 1 || scope.row.status === 2"
          >指派取件人
        </el-button>
        <el-button
          type="text"
          size="small"
          icon="el-icon-link"
          @click="jckdFn(scope.row)"
          v-if="scope.row.status === 3"
          >快递寄出
        </el-button>
      </template>
      <template slot="express_company_idForm" slot-scope="{disabled,size}">
        <el-select :size="size" v-model="form.express_company_id" :disabled="disabled" @change="selectChange(dicData.express_company , 'express_company_id' , 'id' , [{target: 'express_company' , source: 'name'}])">
          <el-option v-for="item of dicData.express_company" :label="item.name" :value="item.id" :key="item.id"></el-option>
        </el-select>
      </template>
      <template slot="picker_idForm" slot-scope="{disabled,size}">
        <el-select :size="size" v-model="form.picker_id" :disabled="disabled" @change="selectChange(dicData.pick , 'picker_id' , 'userId' , [{target: 'picker' , source: 'deliveryName'} , {target: 'picker_tel' , source: 'deliveryPhone'}])">
          <el-option v-for="item of dicData.pick" :label="item.deliveryName" :value="item.userId" :key="item.userId"></el-option>
        </el-select>
      </template>
    </avue-crud>
    <el-dialog width="400px" title="指派取件人" :visible.sync="pickVisible">
      <el-form v-if="pickVisible" size="small" :model="dialogForm.form" label-width="80px">
        <el-form-item label="取件人" prop="picker_id">
          <model-search-select
            class="dis-b w-100p"
            model="wp_spcod_delivery_man"
            v-model="dialogForm.form.picker_id"
            labelKey="delivery_name"
            valueKey="user_id"
            :fillModel.sync="dialogForm.form"
            :fills="[
              { source: 'delivery_name', target: 'picker' },
              { source: 'delivery_phone', target: 'picker_tel'}
            ]"
          ></model-search-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button :loading="dialogForm.saving" size="small" @click="pickVisible = false">关 闭</el-button>
        <el-button :loading="dialogForm.saving" size="small" type="primary" @click="dialogFormSubmit">提 交</el-button>
      </div>
    </el-dialog>
    <el-dialog width="400px" title="快递寄出" :visible.sync="kdjcVisible">
      <el-form v-if="kdjcVisible" size="small" ref="kdjcForm" :rules="dialogForm.rules" :model="dialogForm.form" label-width="80px">
        <el-form-item label="快递单号" prop="express_number">
          <el-input v-model="dialogForm.form.express_number"></el-input>
        </el-form-item>
        <el-form-item label="快递公司" prop="express_company_id">
          <model-search-select
            class="dis-b w-100p"
            model="wp_xps_carrier"
            v-model="dialogForm.form.express_company_id"
            labelKey="name"
            valueKey="id"
            dataType="string"
            :params="{status: 1}"
            :fillModel.sync="dialogForm.form"
            :fills="[
              { source: 'name', target: 'express_company' }
            ]"
          ></model-search-select>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button :loading="dialogForm.saving" size="small" @click="kdjcVisible = false">关 闭</el-button>
        <el-button :loading="dialogForm.saving" size="small" type="primary" @click="dialogFormSubmit">提 交</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import {
  getMetaCommonList,
  delMetaCommon,
  getMetaCommonListExport,
  saveMetaCommon,
  updateMetaCommon,
} from "@/api/busiMode/metaCommon";
import * as dictService from "@/api/system/dictbiz";
import { getCarrierPage } from "@/api/spcod/xpscarrier";
import { getDeliveryMan } from '@/api/spcod/xpsexpressorder';
export default {
  data(){
    return {
      pickVisible: false,
      kdjcVisible: false,
      dialogForm: {
        saving: false,
        form: {},
        row: null,
        refName: '',
        rules: {}
      },
      form: {
        express_company: '',
        picker: ''
      },
      model: 'wisdom_park.wp_send_express_bill',
      tableLoading: false,
      tableData: [],
      search: {
        ACTIVENAME: 'all'
      },
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableOption: {
        height: 'auto',
        labelPosition: 'top',
        searchMenuPosition: 'right',
        searchLabelPosition: 'right',
        searchMenuSpan: 24,
        border: true,
        viewBtn: true,
        menuWidth: 280,
        column: [
          {
            label: "快递单号",
            prop: "express_number",
            span: 12,
            search: true,
            searchSpan: 6,
            width: 200,
          },
          {
            label: "快递公司",
            prop: "express_company_id",
            span: 12,
            search: true,
            searchSpan: 6,
            width: 200,
            type: 'select',
            dicData: [],
            props: {
              label: "name",
              value: "id",
            }
          },
          {
            label: "物品种类名称",
            prop: "mtl_type_name",
            span: 12,
            width: 200,
          },
          {
            label: "物品重量",
            prop: "mtl_weight",
            span: 12,
          },
          {
            label: "物品体积",
            prop: "mtl_volumn",
            span: 12,
          },
          {
            label: "物品件数",
            prop: "mtl_num",
            span: 12,
          },
          {
            label: "寄件人",
            prop: "sender",
            span: 12,
            search: true,
            searchLike: true
          },
          {
            label: "寄件人联系方式",
            prop: "sender_tel",
            span: 12,
            width: 200,
            search: true,
            searchLike: true
          },
          {
            label: "寄件地址",
            prop: "send_address",
            width: 200,
            span: 24,
            type: 'textarea'
          },
          {
            label: "收件人",
            prop: "receiver",
            span: 12,
          },
          {
            label: "收件人联系方式",
            prop: "receiver_tel",
            span: 12,
            width: 200,
          },
          {
            label: "收件地址",
            prop: "receiv_address",
            width: 200,
            span: 24,
            type: 'textarea'
          },
          {
            label: "取件人",
            prop: "picker",
            span: 12,
            disabled: true,
            // dicData: [],
            // props: {
            //   label: "deliveryName",
            //   value: "userId",
            // }
          },
          {
            label: "取件人联系方式",
            prop: "picker_tel",
            span: 12,
            width: 200,
          },
          {
            label: "期望取件时间",
            prop: "pick_time",
            span: 12,
            width: 200,
            type: 'datetime',
          },
          {
            label: "取件码",
            prop: "verification_code",
            span: 12,
            disabled: true,
          },
          {
            label: "状态",
            prop: "status",
            span: 12,
            type: "select",
            dicUrl: dictService.getUrl("SendExpressStatus"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: 'number'
          },
          {
            label: "备注",
            prop: "memo",
            width: 200,
            span: 24,
            type: 'textarea',
            hide: true
          },
        ]
      },
      init: false,
      tabs: [],
      dicData: {
        express_company: [],
        pick: []
      }
    }
  },
  mounted(){
    this.getTabs();
    this.getCarrierPage();
    // this.getDeliveryMan();
  },
  methods: {
    pickFn(row){
      this.dialogForm.form = {
        picker_id: row.picker_id,
        picker: row.picker,
        picker_tel: row.picker_tel,
        verification_code: row.verification_code || `${parseInt(Math.random() * 899999) + 100000}`,
        status: 2
      },
      this.dialogForm.refName = '';
      this.dialogForm.rules = {}
      this.dialogForm.row = row;
      this.pickVisible = true;
    },
    jckdFn(row){
      this.dialogForm.form = {
        status: 4,
        express_number: row.express_number,
        express_company_id: row.express_company_id,
        express_company: row.express_company
      },
      this.dialogForm.refName = 'kdjcForm';
      this.dialogForm.rules = {
        express_number: [
          { required: true, message: '请输入 快递单号', trigger: 'blur' }
        ],
        express_company_id: [
          { required: true, message: '请选择 快递公司', trigger: 'blur' }
        ]
      }
      this.dialogForm.row = row;
      this.kdjcVisible = true;
    },
    dialogFormSubmit(){
      let body = {
        ...this.dialogForm.form
      };

      Object.keys(body).forEach(
        key => {
          if(key[0] === '$'){
            delete body[key];
          }
        }
      );
      let submitData = () => {
        let msg = this.$message({
          type: 'info',
          duration: 0,
          message: `正在提交...`
        });
        this.dialogForm.saving = true;
        updateMetaCommon(this.model , {
          update: {...body},
          primaryKeyValue: this.dialogForm.row.id,
          primaryKey: "id",
        }).then(
          res => {
            this.kdjcVisible = false;
            this.pickVisible = false;
            this.dialogForm.saving = false;
            this.$message.success('保存成功');
            msg.close();
            this.onLoad()
          }
        )
      }
      if(this.dialogForm.refName){
        this.$refs[this.dialogForm.refName].validate(valid => {
          if(valid){
            submitData();
          }
        })
      }else{
        submitData();
      }
    },
    getTabs(){
      dictService.getDictionary({
        code: 'SendExpressStatus'
      }).then(
        res => {
          this.tabs = [
            {dictValue: '全部' , dictKey: 'all'},
          ].concat(res.data.data || []);
          this.$nextTick(() => {
            this.init = true;
          })
        }
      ).catch(e => {
        this.init = true;
      })
    },
    getCarrierPage(){
      getCarrierPage(1 , 10000 , {
        status: 1
      }).then(
        res => {
          const column = this.findObject(this.tableOption.column, "express_company_id");
          column.dicData = res.data.data.records;
          this.dicData.express_company = res.data.data.records;
        }
      )
    },
    getDeliveryMan(){
      getDeliveryMan(1 , 10000 , {

      }).then(
        res => {
          const column = this.findObject(this.tableOption.column, "picker_id");
          column.dicData = res.data.data.records;
          this.dicData.pick = res.data.data.records;
        }
      )
    },
    selectChange(data , prop , prop1 , params){
      data.forEach(
        item => {
          if(item[prop1] === this.form[prop]){
            params.forEach(
              itemParam => {
                this.form[itemParam.target] = item[itemParam.source]
              }
            )
          }
        }
      );
    },
    tabsHandleClick(){
      this.page.currentPage = 1;
      this.onLoad();
    },
    getSearch(){
      let form = this.search , wheres = [];
      Object.keys(form).forEach(
        keyName => {
          if(keyName !== 'ACTIVENAME' && keyName[0] !== '$' && (form[keyName] ?? '') !== ''){
            const column = this.findObject(this.tableOption.column, keyName);
            if(column['searchRange']){
              let startVal = form[keyName][0] , endVal = form[keyName][1];
              if(startVal){
                if(column['type'] === 'date'){
                  startVal = `${startVal} 00:00:00`
                }
                wheres.push({ field: keyName, value: startVal, op: "GE" });
              }
              if(endVal){
                if(column['type'] === 'date'){
                  endVal = `${endVal} 23:59:59`
                }
                wheres.push({ field: keyName, value: endVal, op: "LE" });
              }
            }else if(column['searchLike']){
              wheres.push({field: keyName , op: 'LIKE', value: form[keyName]})
            }else{
              wheres.push({field: keyName , value: form[keyName]})
            }
          }
        }
      );
      return wheres;
    },
    searchChange(form , done){
      done();
      this.onLoad()
    },
    onLoad(weres){
      let wheres = this.getSearch();
      this.tableLoading = true;
      if(this.search.ACTIVENAME !== 'all'){
        wheres.push({field: 'status' , value: this.search.ACTIVENAME})
      }
      this.tableData = [];
      getMetaCommonList(this.model , {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres,
        orders: this.orders
      }).then(
        res => {
          this.tableLoading = false;
          if(res.data && res.data.code === 200){
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
          }else{
            this.tableData = [];
            this.page.total = 0;
          }
        }
      ).catch(e => {
        this.tableLoading = false;
      })
    },
    saveTableForm(row, done, loading) {

      let body = {
        ...row
      };

      Object.keys(body).forEach(
        key => {
          if(key[0] === '$'){
            delete body[key];
          }
        }
      );
     
      if(row.id){
        body = {
          update: body,
          primaryKeyValue: row.id,
          primaryKey: "id",
        };
      }else{
        body = {
          values: body
        }
      };
      (row.id ? updateMetaCommon : saveMetaCommon)(this.model , body).then((res) => {
        this.responeHandle(res, done, loading);
      }).catch((e) => {
        loading();
      });
    },
    responeHandle(res, done, loading) {
      if (res.data && res.data.code === 200) {
        done && done();
        this.onLoad();
        this.$message.success(res.data.msg);
      } else {
        loading && loading();
        this.$message.error(res.data.msg);
      }
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        return delMetaCommon(this.model , {pk: 'id' , ids: [row.id]});
      }).then(() => {
        this.onLoad();
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    },
  }
}
</script>
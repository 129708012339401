<template>
  <div>
    <el-button
      style="position: absolute; right: 20px; z-index: 1; top: 15px"
      type="primary"
      size="mini"
      icon="el-icon-refresh"
      circle
      @click="refreshTable"
    ></el-button>
    <avue-tree
      :style="{
        height: viewPortHeight - 120 + 'px',
        overflow: 'auto',
        'margin-top': '50px',
      }"
      :permission="getPermission"
      :loading="loading"
      :option="option"
      :data="treeData"
      @node-click="nodeClick"
      @update="update"
      @save="save"
      @del="del"
      ref="tree"
      v-model="form"
    >
      <template slot-scope="scope" slot="menu">
        <div class="avue-tree__item" @click="addNode(scope.node)">
          <i class="el-icon-plus mar-r-10" @click="addNode(scope.node)"></i>新增
        </div>
        <div class="avue-tree__item" @click="del(scope.node, scope.done)">
          <i
            class="el-icon-delete mar-r-10"
            @click="del"
            style="color: #f56c6c"
          ></i
          >删除
        </div>
      </template>
    </avue-tree>

    <el-dialog
      title="新增节点"
      :visible.sync="dialogVisible"
      width="50%"
      size="small"
    >
      <avue-form ref="form" v-model="form" :option="formOption"> </avue-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small">取 消</el-button>
        <el-button type="primary" @click="save" size="small">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  getLazyList,
  remove,
  update,
  add,
  getDept,
  getDeptTree,
  getDeptLazyTree,
  getDeptLazyTreeByTenantId,
} from "@/api/system/dept";
import { mapGetters, mapState } from "vuex";
import { getMetaCommonList, delMetaCommon } from "@/api/busiMode/metaCommon";
export default {
  data() {
    return {
      form: {},
      loading: true,
      treeData: [],
      formOption: {},
      dialogVisible: false,
      company: {},
      model: "wisdom_park.wp_company",
      viewPortHeight:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight,
      filterText: "",
      option: {
        title: "",
        editBtn: false,
        delBtn: false,
        addBtn: false,
        lazy: true,
        dicData: [
          {
            title: "全部",
            leaf: true,
            id: "all",
          },
        ],
        treeLoad: function (node, resolve) {
          const parentId = node.level === 0 ? 0 : node.data.id;
          if (node.level != 0) {
            let templateId = "000002";
            if (this.identity == 2) {
              templateId = "000002";
            } else if (this.identity == 1) {
              templateId = "000001";
            } else if (this.identity == 0) {
              templateId = "000000";
            }
            getDeptLazyTreeByTenantId(parentId, templateId).then((res) => {
              resolve(
                res.data.data.map((item) => {
                  return {
                    ...item,
                    leaf: !item.hasChildren,
                  };
                })
              );
            });
          }
        },
        props: {
          labelText: "名称",
          label: "title",
          value: "value",
          children: "children",
        },
      },
    };
  },
  props: {
    identity: {
      type: Number,
      default() {
        return 999;
      },
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  mounted() {
    setTimeout(() => {
      this.loading = false;
      window.document
        .getElementsByClassName("el-tree-node")[0]
        .setAttribute("class", "el-tree-node is-current is-focusable");
    }, 2000);
    this.getTemId();
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  methods: {
    refreshTable() {
      this.getTemId();
    },
    getMetaCommonList(id) {
      if (id) {
        let body = {
          current: 1,
          size: 20,
          wheres: [{ field: "dept_id", value: id }],
        };
        getMetaCommonList(this.model, body).then((res) => {
          this.company = res.data.data.records;
        });
      }
    },
    getTemId() {
      let templateId = "";
      if (this.identity == 2) {
        templateId = "000002";
      } else if (this.identity == 1) {
        templateId = "000001";
      } else if (this.identity == 0) {
        templateId = "000000";
      }
      this.initTreeData(templateId);
    },
    initTreeData(id) {
      if (id == "") {
        getDeptTree().then((res) => {
          this.treeData = [
            {
              title: "全部",
              leaf: true,
              id: "all",
              companyIdentityType: this.identity,
            },
          ].concat(res.data.data);
          this.$emit("nodeClick", { id: "all" });
          this.formatOption(res.data.data);
        });
      } else {
        getDeptTree(id).then((r) => {
          if (r.data.data[0].id) {
            this.getMetaCommonList(r.data.data[0].id);
            this.$emit("nodeClick", {
              id: "all",
              companyIdentityType: this.identity,
            });
            if (id == "000000") {
              this.treeData = [
                {
                  title: "全部",
                  leaf: true,
                  id: "all",
                  companyIdentityType: this.identity,
                },
              ].concat(
                r.data.data.map((item) => {
                  return {
                    ...item,
                    leaf: !item.hasChildren,
                  };
                })
              );
              this.formatOption(r.data.data);
            } else {
              getDeptLazyTreeByTenantId(r.data.data[0].id, id).then((res) => {
                this.treeData = [
                  {
                    title: "全部",
                    leaf: true,
                    id: "all",
                    companyIdentityType: this.identity,
                  },
                ].concat(
                  res.data.data.map((item) => {
                    return {
                      ...item,
                      leaf: !item.hasChildren,
                    };
                  })
                );
                this.formatOption(res.data.data);
              });
            }
          }
        });
      }
    },
    formatOption(data) {
      this.option.formOption = {
        labelWidth: 100,
        column: [
          {
            label: "上级机构",
            prop: "parentId",
            dicData: data,
            type: "tree",
            hide: true,
            addDisabled: false,
            props: {
              label: "title",
            },
            rules: [
              {
                required: false,
                message: "请选择上级机构",
                trigger: "click",
              },
            ],
          },
          {
            label: "机构类型",
            type: "select",
            dicUrl: "/api/jida-system/dict/dictionary?code=org_category",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            width: 120,
            prop: "deptCategory",
            slot: true,
            rules: [
              {
                required: true,
                message: "请输入机构类型",
                trigger: "blur",
              },
            ],
          },
        ],
      };
      this.formOption = {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 100,
        column: [
          {
            label: "名称",
            prop: "title",
            type: "input",
            props: {
              label: "title",
            },
          },
          {
            label: "上级机构",
            prop: "parentId",
            dicData: data,
            type: "tree",
            hide: true,
            addDisabled: false,
            props: {
              label: "title",
            },
            rules: [
              {
                required: false,
                message: "请选择上级机构",
                trigger: "click",
              },
            ],
          },
          {
            label: "机构类型",
            type: "select",
            dicUrl: "/api/jida-system/dict/dictionary?code=org_category",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            width: 120,
            prop: "deptCategory",
            slot: true,
            rules: [
              {
                required: true,
                message: "请输入机构类型",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
    getPermission(key, data) {
      if (key == "addBtn" && data.value == 0) {
        return false;
      }
      return true;
    },
    del(data, done) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          console.log(data);
          return remove(data.id);
        })
        .then(() => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          // 数据回调进行刷新
          this.getTemId();
        });
    },
    update(node, data, done, loading) {
      this.$message.success("更新回调");
      done();
    },
    addNode(node) {
      this.form.parentId = node.id;
      this.form.value = "";
      this.dialogVisible = true;
      //this.save(node, data, done, loading);
    },
    save(node, data, done, loading) {
      let obj = {
        deptName: this.form.title,
        parentId: this.form.parentId,
        deptCategory: this.form.deptCategory,
      };
      add(obj).then(
        (res) => {
          // this.form.id = new Date().getTime();
          this.form.value = new Date().getTime();
          this.form.children = [];
          this.getTemId();
          this.dialogVisible = false;
          // done(res.data.data);
        },
        (error) => {
          window.console.log(error);
          loading();
        }
      );
    },
    nodeClick(data) {
      document
        .getElementsByClassName("el-tree-node")[0]
        .setAttribute("class", "el-tree-node is-focusable");
      console.log(data, "data");
      this.$emit("nodeClick", data);
      //this.$message.success(JSON.stringify(data));
    },
    filterNodeMethod(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .avue-tree__filter {
  position: fixed;
  width: 250px;
  top: 115px;
  padding-left: 10px;
}
</style>

<template>
  <basic-echarts :option="chartOption"></basic-echarts>
</template>

<script>

import WidgetMixins from '../widget-mixins';

export default {
  name: 'BuiltInWidgetByEcharts',
  mixins: [ WidgetMixins ],
  data(){
    return {
      chartOption: null
    }
  },
  mounted(){
    console.log(this.data , this.option);
    this.init();
  },
  methods: {
    init(){
      if(this.option){
        if(this[`${this.option.type}_chart`]){
          this[`${this.option.type}_chart`]();
        }
      }
    },
    dataChange(){
      console.log(this.data);
      this.init();
    },

    bar_chart(){
      
      this.chartOption = null;

      let series = {
        type: this.option.type,
        data: []
      } , data = [] , categoryData = [];

      let {category , value} = this.getDataOption();
      if(category && value){
        if(this.data && typeof this.data === 'object'){
          this.data.forEach(
            item => {
              categoryData.push(item[category]);
              data.push(item[value])
            }
          )
        }
        series.data = data;
      }

      let categoryAxis = {type: 'category' , data: categoryData} , valueAxis = {type: 'value'};
      
      this.chartOption = {
        xAxis: this.option.barType === 'horizontal' ? categoryAxis : valueAxis,
        yAxis: this.option.barType === 'vertical' ? categoryAxis : valueAxis,
        series
      }

      console.log(this.chartOption);
    }
  }
}
</script>
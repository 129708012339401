<template>
  <basic-bpmn
    ref="basicBpm"
    @back="back"
    @save="save"
    :value="lcdata.relation"
    :register="BpmnRegister"
  >
    <template v-slot:PropertiesPanel="data">
      <TaskForm :dataSoure="dataSoureObj" v-bind="data" ></TaskForm>
    </template>
  </basic-bpmn>
</template>
<script>
import { getDetail, add, update } from "@/api/tool/eventModel";

import BpmnRegister from './bpmn/index';
import request from '@/router/axios';
import TaskForm from './bpmnForm/BusinessTask.vue';

const xml2js = require("xml2js");
const builder = new xml2js.Builder();
const parser = new xml2js.Parser();
export default {
  name: "",
  components: { TaskForm },
  data() {
    return {
      BpmnRegister: {
        ...BpmnRegister,
        http: request,
      },
      showType: "business",
      currentBtnObj: {},
      serviceObj: {
        startupModel: "3",
        serviceName: "",
      },

      lcdata: {
        id: 1,
        name: "",
        desc: "",
        relation: ``,
      },
      dataSoureObj: {},
      qdfsList: [
        { name: "事件触发", value: "1" },
        { name: "消息触发", value: "2" },
        { name: "调用触发", value: "3" },
      ],
      sjcfList: [
        { name: "插入前", value: "crq" },
        { name: "插入后", value: "crh" },
        { name: "修改前", value: "xgq" },
        { name: "修改后", value: "xgh" },
        { name: "删除前", value: "scq" },
        { name: "删除后", value: "sch" },
      ],
    };
  },
  created() {
    this.currentBtnObj = this.currentBtn;
    this.dataSoureObj = this.dataSoure;
    this.$nextTick(() => {
      this.$refs.basicBpm.dataSoure = this.dataSoure;
    });

    this.getDetail();
  },
  props: {
    dataSoure: {
      type: Object,
      default() {
        return {};
      },
    },

    currentBtn: {
      type: Object,
      default() {
        return { label: "" };
      },
    },

    entityId: {
      type: String,
      default() {
        return "";
      },
    },
    onlyBpmn: {
      type: String,
      default() {
        return "false";
      },
    },
  },
  watch: {
    currentBtn: {
      handler(newName, oldName) {
        if (newName.serviceId) {
          this.currentBtnObj = this.currentBtn;
          this.getDetail();
        }
      },
      immediate: false,
      deep: true,
    },
    dataSoure: {
      handler(newName, oldName) {
        if (newName) {
          this.$refs.basicBpm.dataSoure = this.dataSoure;
        }
      },
      deep: true,
    },
  },
  mounted() {
  },
  methods: {
    changeJson(id) {
      this.currentBtnObj.serviceId = id;
      this.getDetail();
    },
    getDetail() {
      if (this.currentBtnObj.serviceId) {
        getDetail(this.currentBtnObj.serviceId).then((res) => {
          if (res.data.code == 200) {
            if (res.data.data.modelEditorJson) {
              this.lcdata.relation = this.jsonTOxml(
                JSON.parse(res.data.data.relation)
              );
              this.lcdata = JSON.parse(JSON.stringify(this.lcdata));
            }
          }
        });
      }
    },
    save(xml) {
      this.add(xml);
    },
    back(xml) {
      this.$emit("back");
    },
    jsonTOxml(data) {
      return builder.buildObject(data);
    },
    xmlToString(xml) {
      let xmlString = "";
      parser.parseString(xml, function (err, res) {
        if (err) throw err;

        xmlString = res;
      });
      console.log(xmlString);
      return xmlString;
    },
    getModelEditorJson(processJson){
      let data = {
        process: null,
        nodes: []
      }, nodes = {} , flows = {} , startNode = null;
      
      let process = processJson['bpmn2:definitions']['bpmn2:process'][0];
      let jsonParser = ['operate' , 'operationList'];
      data.process = process['$'];
      Object.keys(process).forEach(
        key => {
          if(key !== '$'){
            let type = key.split(':')[1];
            process[key].forEach(
              nodeItem => {
                if(type !== 'sequenceFlow'){
                  let obj = {};
                  Object.keys(nodeItem['$']).forEach(
                    valKey => {
                      if(jsonParser.indexOf(valKey) > -1){
                        obj[valKey] = JSON.parse(nodeItem['$'][valKey]);
                      }else{
                        obj[valKey] = nodeItem['$'][valKey];
                      }
                    }
                  )
                  nodes[obj.id] = {
                    id: obj.id,
                    obj: obj,
                    out: nodeItem['bpmn2:outgoing'],
                    inp: nodeItem['bpmn2:incoming']
                  };
                  if(!nodeItem['bpmn2:incoming']){
                    startNode = nodes[obj.id];
                  }
                }else{
                  let obj = nodeItem['$'];
                  flows[obj.id] = obj;
                }
              }
            )
          }
        }
      );
      let getNextNode = (node) => {
        if(node['out']){
          node['out'].forEach(
            flowId => {
              let targetId = flows[flowId]['targetRef'];
              let targetNode = nodes[targetId];
              data.nodes.push(targetNode.obj);
              getNextNode(targetNode);
            }
          )
        }
      }
      if(startNode && startNode['out']){
        data.nodes.push(startNode.obj);
        getNextNode(startNode);
      }
      return data;
    },
    add(xml) {
      let modelEditorJson = this.getModelEditorJson(this.xmlToString(xml));
      let o = {
        name: modelEditorJson.process.name,
        modelKey: this.entityId,
        description: "",
        version: "",
        createUser: "",
        relation: JSON.stringify(this.xmlToString(xml)),
        modelEditorJson: JSON.stringify(modelEditorJson),
        updateTime: "",
      };

      if (this.currentBtnObj.id) {
        o.id = this.currentBtnObj.id;
        this.modelEditorJson = update(o).then(
          (res) => {
            if (res.data.code == 200) {
              this.$emit("back", res.data.data.id);
            }
          },
          (error) => {}
        );
      } else {
        this.modelEditorJson = add(o).then(
          (res) => {
            if (res.data.code == 200) {
              this.$emit("back", res.data.data.id);
            }
          },
          (error) => {}
        );
      }
    },
  },
};
</script>
<style lang="scss">
@import url('./bpmn/node/customNode.scss');
</style>
<template>
  <el-row :gutter="15">
    <el-col :span="24" v-if="!isPre">
      <el-button
        @click="saveForm()"
        class="pull-right saveBtnForForm"
        type="primary"
        size="small"
        >保存</el-button
      >
    </el-col>
    <template>
      <i
        v-if="isPre"
        class="arrowIcon el-icon-d-arrow-left"
        @click="isPre = !isPre"
      ></i>
      <i
        v-if="!isPre"
        class="arrowIcon el-icon-d-arrow-right"
        @click="isPre = !isPre"
      ></i>
    </template>
    <el-col :span="isPre ? 24 : 16">
      <el-card class="mar-b-15">
        <!--搜索表单-->
        <avue-form
          ref="search1Form"
          :option="search1Option"
          v-model="search1Form"
          @submit="addSubmit"
        ></avue-form>
        <!--搜索表单-->

        <!--table-->
        <avue-crud
          ref="crud"
          :option="option_option1"
          :search.sync="search"
          :data="list"
          :page.sync="page"
          v-model="form"
          :header-cell-class-name="addCellClass"
          :cell-class-name="addCellClass"
          @row-save="rowSave"
          @row-del="rowDel"
          @row-update="rowUpdate"
        >
          <template slot="menuLeft">
            <template v-for="(btn, index) in menuLeftList">
              <el-button
                :key="index"
                type="primary"
                size="small"
                :icon="btn.icon"
                @click="operations(btn.value)"
                >{{ btn.label }}
              </el-button>
            </template>
          </template>
          <template slot="menu">
            <template v-for="(btn, index) in menuList">
              <el-button
                :key="index"
                type="text"
                size="small"
                :icon="btn.icon"
                @click="operations(btn.value, row, index, done)"
                >{{ btn.label }}
              </el-button>
            </template>
          </template>
        </avue-crud>
        <!--table-->
      </el-card>
    </el-col>
    <el-col :span="8" v-if="!isPre">
      <!-- <transition
        enter-active-class="animate__fadeInRight"
        leave-active-class="animate__bounceInLeft"
        :duration="{ enter: 200, leave: 400 }"
      > -->
      <el-card>
        <el-tabs v-model="type" stretch>
          <el-tab-pane label="表格属性" name="form" style="padding: 0 10px">
            <avue-form
              ref="tableForm"
              v-model="tableForm"
              :option="tableOption"
              :append-to-body="true"
            >
            </avue-form>

            <template v-if="tableForm.showPage">
              <el-divider></el-divider>
              <h3>分页配置</h3>
              <avue-form
                ref="pages"
                v-model="pageForm"
                :option="pageOption"
                :append-to-body="true"
              >
              </avue-form>
            </template>
          </el-tab-pane>
          <el-tab-pane label="列属性" name="row" style="padding: 0 10px">
            <el-dropdown size="mini" split-button type="primary">
              配置隐藏字段
              <el-dropdown-menu slot="dropdown">
                <el-row class="pad-15 fts-12">
                  <el-col
                    :span="24"
                    v-for="(col, index) in importCode['column']"
                    :key="index"
                  >
                    <el-switch
                      @change="changShowFiled(col)"
                      v-model="col.hide"
                      class="mar-r-10"
                    ></el-switch
                    >{{ col.label }}
                  </el-col>
                </el-row>
              </el-dropdown-menu>
            </el-dropdown>
            <el-divider></el-divider>
            <el-form>
              <el-form-item label-width="100px" label="列名称:">
                <el-select
                  v-model="chooseFile"
                  size="small"
                  @change="chooseFileData"
                >
                  <el-option
                    v-for="(col, index) in importCode['column']"
                    :key="index"
                    :label="col.label"
                    :value="col.prop"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <avue-form
              ref="rowForm"
              v-model="rowForm"
              :option="rowOption"
              append-to-body="true"
              @submit="submit"
            >
            </avue-form>
          </el-tab-pane>

          <el-tab-pane label="操作配置" name="menu" style="padding: 0 10px">
            <avue-form
              ref="menuForm"
              v-model="menuForm"
              :option="menuOption"
              append-to-body="true"
              @submit="submit"
            >
            </avue-form>
          </el-tab-pane>
          <el-tab-pane label="搜索配置" name="search" style="padding: 0 10px">
            <el-dropdown size="mini" split-button type="primary">
              配置查询字段
              <el-dropdown-menu slot="dropdown">
                <el-row class="pad-15 fts-12">
                  <el-col
                    :span="24"
                    v-for="(col, index) in search1Option['column']"
                    :key="index"
                  >
                    <el-switch
                      @change="changSearchShowFiled(col)"
                      v-model="col.display"
                      class="mar-r-10"
                    ></el-switch
                    >{{ col.label }}
                  </el-col>
                </el-row>
              </el-dropdown-menu>
            </el-dropdown>
            <el-divider></el-divider>
            <el-form>
              <el-form-item label-width="100px" label="搜索字段">
                <el-select
                  v-model="chooseSearchFile"
                  size="small"
                  @change="chooseSearchData"
                >
                  <el-option
                    v-for="(col, index) in search1Option['column']"
                    :key="index"
                    :label="col.label"
                    :value="col.prop"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-form>
            <avue-form
              ref="searchForm"
              v-model="searchForm"
              :option="searchOption"
              :append-to-body="true"
              @submit="submit"
            >
            </avue-form>
          </el-tab-pane>
        </el-tabs>
      </el-card>
      <!-- </transition> -->
    </el-col>
    <el-drawer
      :visible.sync="preViewbox"
      :size="preViewType == 'add' ? '30%' : '80%'"
      :direction="preViewType == 'add' ? 'rtl' : 'ttb'"
      :title="title"
      destroy-on-close
      append-to-body
    >
      <el-col :span="24" v-if="preViewType == 'add'">
        <el-button type="text" size="small" plain @click="preViewDia('design')"
          >自定义设计
        </el-button>
      </el-col>
      <avue-form
        v-if="preViewType == 'add'"
        ref="form"
        class="preview-form"
        :option="newOption"
        v-model="form"
        @submit="addSubmit"
      ></avue-form>
      <formPreview
        :options="newOption"
        @submit="handleSubmit"
        v-if="preViewType == 'design'"
      ></formPreview>
    </el-drawer>
  </el-row>
</template>
<script>
import {
  tableOption,
  menuOption,
  searchOption,
  search1Option,
  option_option,
  rowOption,
  pageOption,
  pageObj,
  newOption,
  menuLeftList,
  menuList,
} from "../../../components/dynamic/table/options";
import tablePreview from "../../../components/dynamic/table/preview";
import menuchoose from "../../system/menu";
import formPreview from "../form/design";

import {
  getCommList,
  // updateComm,
  // removeComm,
  // addComm,
} from "@/api/busiMode/commOps";
export default {
  name: "tableDesign",
  components: {
    menuchoose,
    tablePreview,
    formPreview,
  },

  props: {
    importCode: {
      type: Object,
      default: function () {
        return option_option;
      },
    },
    planCode: {
      type: Object,
      default: function () {
        return "";
      },
    },
    isPre: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      title: "",
      newOption: newOption,
      preViewType: "add",
      preViewbox: false,
      type: "form",
      form: {},
      chooseFile: "", //当前操作的列的数据
      chooseSearchFile: "", //当前操作的搜索
      menuLeftList: menuLeftList,
      menuList: menuList,
      option_option1: {
        column: [
          {
            label: "姓名123",
            prop: "name",
            renderHeader: (h, { column, $index }) => {
              return h("div", [
                h("el-tag", column.label),
                h("spam", "自定义表头"),
              ]);
            },
          },
        ],
      },
      tableForm: Object.assign(option_option, this.tableForm),
      rowOption: rowOption,
      rowForm: {},
      tableOption: tableOption,
      pageOption: pageOption,
      pageForm: {},
      searchOption: searchOption,
      search1Option: search1Option,
      searchForm: {},
      search1Form: {},
      menuOption: menuOption,
      menuForm: {},
      isImportJson: false,
      isSave: false,
      dialogForm: {},
      btnForm: {},
      show3: true,
      //  option: option_option,
      list: [
        { name: "我说名称", sex: "男", age: "12" },
        { name: "我说名称", sex: "男", age: "12" },
        { name: "我说名称", sex: "男", age: "12" },
      ],
      page: {},
    };
  },
  watch: {
    // importCode: {
    //   handler(newName, oldName) {
    //     // this.getCommList();
    //   },
    //   immediate: true,
    //   deep: true,
    // },
    pageForm: {
      //分页
      handler(newValue, oldName) {
        this.$refs.crud.$refs.tablePage.$el.style.textAlign =
          this.pageForm.showLeftRight || "right";
      },
      immediate: true,
      deep: true,
    },
    tableForm: {
      handler(newValue, oldName) {
        this.code();
        this.formatPageForm(newValue);
      },
      immediate: true,
      deep: true,
    },
    searchForm: {
      //搜索
      handler(newValue, oldValue) {
        this.search1Option.column.forEach((e) => {
          if (e.prop == this.chooseSearchFile) {
            e.type = newValue.type;
            e.readonly = newValue.readonly;
            e.placeholder = newValue.placeholder;
            e.span = newValue.span;
            e.labelWidth = newValue.labelWidth;
          }
        });
        this.search1Option = JSON.parse(JSON.stringify(this.search1Option));
      },
      immediate: true,
      deep: true,
    },
    rowForm: {
      handler(newName, oldName) {
        this.importCode.column.forEach((e) => {
          if (e.prop === this.chooseFile) {
            Object.assign(e, this.rowForm);
          }
        });
        this.importCode = JSON.parse(JSON.stringify(this.importCode));
      },
      immediate: true,
      deep: true,
    },
  },
  created() {},
  mounted() {
    this.formatOptions();
  },

  methods: {
    formatOptions() {
      this.initPageForm();
    },
    initPageForm() {
      this.pageForm.showLeftRight = "right";
      this.pageForm = JSON.parse(JSON.stringify(this.pageForm));
    },
    formatPageForm(newValue) {
      if (newValue.showPage) {
        this.page = pageObj;
        this.$refs.crud.$refs.tablePage.$el.style.display = "block";
        this.$refs.crud.$refs.tablePage.$el.style.textAlign =
          this.pageForm.showLeftRight || "right";
      } else {
        this.$refs.crud.$refs.tablePage.$el.style.display = "none";
        this.page = {};
      }
    },
    handleSubmit(data) {
      this.newOption = data;
      this.preViewbox = false;
    },
    operations(type, row, index, done) {
      switch (type) {
        case "add":
          this.preViewDia("add");
          break;
        case "edit":
          this.rowUpdate(row, index, done);
          break;
      }
    },
    changSearchShowFiled(col) {
      console.log(col);
      this.search1Option.column.forEach((e) => {
        if (e.label == col.label) {
          e = col;
        }
      });
    },
    //表格上显示哪些字段
    changShowFiled(col) {
      //Object.assign(this.importCode.column, _tableForm);
      this.importCode.column.forEach((e) => {
        if (e.label == col.label) {
          e = col;
        }
      });
    },
    saveForm() {
      history.go(-1);
    },
    addSubmit() {},
    getCommList() {
      getCommList(this.importCode.tableModel, {}).then((res) => {
        if (res.data.code == 200) {
          this.list = res.data.data.records;
        }
      });
    },
    chooseFileData() {
      this.$refs.crud.option.column.filter((item) => {
        if (item.prop === this.chooseFile) {
          this.rowForm = item;
        }
      });
    },
    chooseSearchData() {
      this.$refs.searchForm.option.column.filter((item) => {
        if (item.type === this.chooseSearchFile) {
          this.searchForm = item;
        }
      });
    },
    addCellClass({ row, column }) {
      if (!this.isPre) {
        if (column.property == this.chooseFile) {
          return "cell-active";
        }
      }
    },
    preViewDia(type) {
      this.title = "设计";
      this.preViewType = type;
      if (type == "add") {
        this.title = "新增";
        this.preViewbox = !this.preViewbox;
      } else {
        //设计的时候重新加载下
        this.preViewbox = false;
        setTimeout(() => {
          this.preViewbox = true;
        }, 500);
      }
    },
    submit(form) {
      this.box = false;
    },
    rowDel(row, index) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.list.splice(index, 1);
        })
        .catch(() => {});
    },
    rowUpdate(row, index, done) {
      this.list.splice(index, 1, row);
      done();
    },
    handleAdd() {
      this.$refs.crud.rowAdd();
    },
    rowSave(row, done) {
      //   this.$refs.crud.rowAdd();
      this.list.push(row);
    },
    code() {
      function vaild(option = {}, ele = "") {
        const result = option[ele] + "" || "";
        return !result || ele.includes("$") || ele.includes("hong");
      }
      let option = {};
      const _tableForm = JSON.parse(JSON.stringify(this.tableForm));
      // const _menuForm = JSON.parse(JSON.stringify(this.menuForm));
      const _rowForm = JSON.parse(JSON.stringify(this.rowForm));
      console.log(JSON.stringify(this.importCode));
      console.log(JSON.stringify(this.importCode));
      console.log(JSON.stringify(this.importCode));
      Object.assign(this.importCode, _tableForm, _rowForm);
      console.log(JSON.stringify(this.importCode));
      // Object.keys(option).forEach((ele) => {
      //   if (vaild(option, ele)) delete option[ele];
      // });
      // option.column = this.deepClone(this.importCode.column || []);
      this.importCode.column.forEach((ele) => {
        // if (_rowForm.prop == ele.prop) {
        //   Object.assign(ele, _rowForm);
        // }
        Object.keys(ele).forEach((key) => {
          if (vaild(ele, key)) delete ele[key];
        });
      });
      this.importCode = JSON.parse(JSON.stringify(this.importCode));
      // this.importCode.column = JSON.parse(JSON.stringify(option));
      // console.log(JSON.stringify(this.importCode, null, 4));
      //return JSON.stringify(option, null, 4);
    },
    doJson() {
      let json = {};
      try {
        json = JSON.parse(this.importCode);
      } catch (err) {
        return this.$message.error("JSON格式不正确");
      }
      const formArr = ["menu", "search", "btn", "table"];
      this.list = [];
      if (json.column) this.list = json.column;
      formArr.forEach((item) => {
        const arr = this[item + "Option"].column.map((item) => {
          return item.prop;
        });
        this.getAnyForm(item + "Form", arr, json);
      });
      // this.$message.success('应用成功')
      this.isImportJson = false;
    },
    getAnyForm(formName, arr, json) {
      function vaild(option = {}, ele = "") {
        const result = option[ele] + "" || "";
        return !result;
      }
      let _form = {};
      arr.forEach((item) => {
        if (vaild(json, item)) return;
        this.$set(_form, item, json[item]);
      });
      this.$set(this, formName, this.deepClone(Object.assign({}, _form)));
    },
    saveStorage() {
      this.isSave = !this.isSave;
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss">
.saveBtnForForm {
  position: fixed;
  right: 20px;
  top: 75px;
}
.el-button--medium {
  padding: 10px 10px;
  font-size: 14px;
  border-radius: 4px;
}
.menuhide {
  width: 10px;
  /* opacity: 0; */
  height: 10px;
  overflow: auto;
}
.box-sad {
  box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  &:hover {
    transform: scale(2);
    border: 1px solid #0c7dff;
  }
}
.scbg {
  margin-top: 5px;
  font-size: 13px;
  line-height: 3px;
}

.cell-active {
  /* transition: 0.2s;
  animation: 3s tdScale infinite; */
  background: #ddecff !important;
  /* box-shadow: 0 0 10px rgb(0 0 0 / 12%); */
  /* background-color: #ddecff; */
  /* -moz-box-shadow: 10px 10px 5px #888888;
  */
}
@keyframes tdScale {
  from {
    transform: scale(1) infinite;
  }
  to {
    transform: scale(1.1);
  }
}
.arrowIcon {
  position: absolute;
  right: -5px;
  top: 9px;
  font-size: 20px;
  color: #006dcf;
}
</style>

<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      :search.sync="search"
      :page.sync="page"
      :before-open="beforeOpen"
      v-model="form"
      ref="crud"
      @row-update="rowUpdate"
      @row-save="rowSave"
      @row-del="rowDel"
      @search-change="searchChange"
      @search-reset="searchReset"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <!-- 修改状态 -->
      <template slot-scope="scope" slot="menu">
        <template v-if="scope.row.status == 1">
          <el-button
            type="text"
            size="small"
            icon="el-icon-download"
            class="none-border"
            @click="tip(scope.row)"
            >关闭</el-button
          >
        </template>
        <template v-if="scope.row.status == 0">
          <el-button
            type="text"
            size="small"
            icon="el-icon-edit"
            class="none-border"
            @click="handleEdit(scope.row, scope.index)"
            >编辑</el-button
          >
          <el-button
            type="text"
            size="small"
            icon="el-icon-delete"
            class="none-border"
            @click="handleDel(scope.row, scope.index)"
            >删除</el-button
          >
          <el-button
            type="text"
            size="small"
            icon="el-icon-upload2"
            class="none-border"
            @click="tip(scope.row)"
            >开放</el-button
          >
        </template>
        <template v-if="scope.row.status == 0 && scope.row.pgroundId == -1">
          <el-button
            type="text"
            size="small"
            icon="el-icon-circle-plus"
            class="none-border"
            @click="addSubField(scope.row)"
            >加小场</el-button
          >
        </template>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import {
  getList,
  getDetail,
  add,
  update,
  remove,
  updateGroundStatus,
  saveOrUpdateMinGround,
  getPage,
} from "@/api/spcod/sportground";
import { mapGetters } from "vuex";
import textareaVue from "../../components/dynamic/form/config/textarea.vue";
import { parseNumbers } from "xml2js/lib/processors";

export default {
  data() {
    return {
      parentField: null,
      form: {},
      query: {},
      search: {},
      loading: true,
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      option: {
        height: "auto",
        calcHeight: 30,
        dialogCustomClass: "sportground-show",
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        viewBtn: true,
        delBtn: false,
        editBtn: false,
        dialogClickModal: false,
        column: [
          {
            label: "场地编码",
            prop: "code",
            rules: [
              {
                required: true,
                message: "请输入场地编码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "场地名称",
            prop: "name",
            search: true,
            searchSpan: 4,
            rules: [
              {
                required: true,
                message: "请输入场地名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "场地类型",
            prop: "typeId",
            type: "select",
            search: true,
            searchSpan: 4,
            dicUrl: "/api/park-spcod/sporttype/list?current=1&size=10000000",
            props: {
              label: "name",
              value: "id",
            },
            dicFormatter: (data) => {
              let datalist = data.data.records;
              /*datalist.forEach(ele => {
                  ele.id = parseInt(ele.id);
                });*/
              return datalist;
            },
            rules: [
              {
                required: true,
                message: "请输入场馆ID",
                trigger: "blur",
              },
            ],
          },
          {
            label: "场馆",
            prop: "stadiumId",
            type: "select",
            search: true,
            searchSpan: 5,
            dicUrl: "/api/park-spcod/sportstadium/list?current=1&size=10000000",
            props: {
              label: "name",
              value: "id",
            },
            dicFormatter: (data) => {
              return data.data.records;
            },
            rules: [
              {
                required: true,
                message: "请输入场地类型",
                trigger: "blur",
              },
            ],
          },
          {
            label: "容纳人数",
            prop: "maxPerson",
            rules: [
              {
                required: true,
                message: "请输入最多容纳人数，0不控制，1一次预约，n个人",
                trigger: "blur",
              },
            ],
          },
          {
            label: "计价方式",
            prop: "priceType",
            search: false,
            searchSpan: 4,
            addDisplay: true,
            editDisplay: true,
            type: "select",
            dicData: [
              {
                value: 1,
                label: "计时",
              },
              {
                value: 2,
                label: "计次",
              },
            ],
            rules: [
              {
                required: false,
                message: "状态",
                trigger: "blur",
              },
            ],
            change: ({ value, column }) => {
              let _this = this;
              console.log(value);
              if (value == undefined || value == "") return;
              if (value == 2) {
                let ci = 0;
                _this.option.column.forEach((c) => {
                  if (c.prop == "timeUnit") {
                    _this.option.column[ci].addDisplay = false;
                    _this.option.column[ci].editDisplay = false;
                  }
                  ci += 1;
                });
                _this.$forceUpdate();
              } else {
                let ci = 0;
                _this.option.column.forEach((c) => {
                  if (c.prop == "timeUnit") {
                    _this.option.column[ci].addDisplay = true;
                    _this.option.column[ci].editDisplay = true;
                  }
                  ci += 1;
                });
                _this.$forceUpdate();
              }
            },
          },
          {
            label: "计价单位",
            prop: "timeUnit",
            type: "select",
            addDisplay: false,
            editDisplay: false,
            dicData: [
              {
                label: "半小时",
                value: 30,
              },
              {
                label: "1小时",
                value: 60,
              },
            ],
            rules: [
              {
                required: true,
                message: "请输入计价单位分钟，1小时40元/半小时30",
                trigger: "blur",
              },
            ],
          },
          // {
          //   label: "场地价格因子",
          //   prop: "priceFactor",
          //   width: "100",
          //   hide: true,
          //   type: "number",
          //   rules: [
          //     {
          //       required: false,
          //       message: "请输入场地价格因子,本场地因子*场地类型对应的价格策略",
          //       trigger: "blur",
          //     },
          //   ],
          // },
          {
            label: "场地状态",
            prop: "status",
            search: true,
            searchSpan: 4,
            addDisplay: false,
            editDisplay: false,
            type: "select",
            dicData: [
              {
                value: 1,
                label: "开放",
              },
              {
                value: 0,
                label: "关闭",
              },
            ],
            rules: [
              {
                required: false,
                message: "状态",
                trigger: "blur",
              },
            ],
          },
          {
            label: "场馆状态",
            prop: "stmStatus",
            searchSpan: 4,
            addDisplay: false,
            editDisplay: false,
            type: "select",
            dicData: [
              {
                value: 1,
                label: "开放",
              },
              {
                value: 0,
                label: "关闭",
              },
            ],
            rules: [
              {
                required: false,
                message: "状态",
                trigger: "blur",
              },
            ],
          },
          // {
          //   label: "大场地名称",
          //   prop: "pgroundName",
          //   minRows: 4,
          //   type: "textarea",
          //   addDisplay: false,
          //   editDisplay: false,
          //   viewDisplay: true,
          //   rules: [
          //     {
          //       required: false,
          //       message: "请输入所属大场地名称",
          //       trigger: "blur",
          //     },
          //   ],
          // },
          {
            label: "场地备注",
            prop: "memo",
            hide: true,
            minRows: 4,
            type: "textarea",
            span: 24,
            rules: [
              {
                required: false,
                message: "请输入场地备注",
                trigger: "blur",
              },
            ],
          },
          {
            label: "上传图片",
            prop: "pics",
            listType: "picture-card",
            hide: true,
            propsHttp: {
              res: "data",
              url: "link",
              name: "originalName",
            },
            type: "upload",
            tip: "只能上传jpg/png场地图片，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file",
            span: 24,
            rules: [
              {
                required: true,
                message: "请上传图片",
                trigger: "change",
              },
            ],
          },
        ],
      },
      data: [],
      groupType: "big",
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.sportground_add, false),
        viewBtn: this.vaildData(this.permission.sportground_view, false),
        delBtn: this.vaildData(this.permission.sportground_delete, false),
        editBtn: this.vaildData(this.permission.sportground_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  methods: {
    addSubField(row) {
      let _this = this;
      _this.parentField = row;
      let ci = 0;
      let tagList = ["stadiumId", "timeUnit", "pics"];
      _this.option.column.forEach((c) => {
        if (tagList.indexOf(c.prop) > -1) {
          _this.option.column[ci].addDisplay = false;
          _this.option.column[ci].rules[0].required = false;
        }
        if (c.prop == "priceFactor") {
          _this.option.column[ci].addDisplay = true;
          _this.option.column[ci].rules[0].required = true;
        }
        ci += 1;
      });
      _this.$forceUpdate();
      this.groupType = "small";
      this.form = row;
      //this.saveOrUpdateMinGround(row);
      _this.$refs.crud.rowAdd();
    },
    saveOrUpdateMinGround(row, done) {
      console.log(this.form);
      let obj = this.form;
      obj.pgroundId = obj.id;
      delete obj.id;
      saveOrUpdateMinGround(obj).then((res) => {
        if (res.data.success) {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.onLoad(this.page);
          done();
        }
      });
      this.groupType = "big";
    },
    handleDel(row, index) {
      let _this = this;
      _this
        .$confirm("确定将选择场地数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          remove(row.id).then((res) => {
            _this.onLoad(this.page);
            _this.$message({
              type: "success",
              message: "操作成功!",
            });
            done();
          });
        });
    },
    handleEdit(row, index) {
      let _this = this;
      let ci = 0;
      let editFlag = true;
      if (row.pgroundId && row.pgroundId > -1) {
        editFlag = true;
      } else {
        editFlag = false;
      }
      _this.option.column.forEach((c) => {
        if (c.prop == "priceFactor") {
          // _this.option.column[ci].editDisplay = editFlag;
          _this.option.column[ci].rules[0].required = editFlag;
        }
        if (c.prop == "timeUnit") {
          _this.option.column[ci].editDisplay = !editFlag;
          _this.option.column[ci].rules[0].required = !editFlag;
        }
        ci += 1;
      });

      this.$refs.crud.rowEdit(row, index);
    },
    rowSave(row, done, loading) {
      if (this.groupType == "small") {
        this.saveOrUpdateMinGround(this.form, done);
      } else {
        let _this = this;
        _this.parentField = row;
        if (_this.parentField.stadiumId) {
          console.log(_this.parentField);
          row.stadiumId = _this.parentField.stadiumId;
          row.typeId = _this.parentField.typeId;
          row.timeUnit = _this.parentField.timeUnit;
          row.pgroundId = _this.parentField.id;
        }
        add(row).then(
          () => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            //重置
            _this.parentField = {};
            done();
          },
          (error) => {
            loading();
            window.console.log(error);
          }
        );
      }
    },
    rowUpdate(row, index, done, loading) {
      if (!row.memo) {
        row.memo = "";
      }
      update(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
        },
        (error) => {
          loading();
          console.log(error);
        }
      );
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据");
        return;
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(this.ids);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.$refs.crud.toggleSelection();
        });
    },
    handleStatus(row) {
      const status = row.status;
      const id = row.id;
      // 状态,1正常/2关闭/3维护中
      if (status === 1) {
        // 显示修改状态‘维修中’  确认
        this.$confirm('修改状态"维修中",  确认修改？', "", {
          cancelButtonText: "取消",
          confirmButtonText: "确认",
        }).then(() => {
          console.log(11111);
        });
      } else if (status === 2) {
        // 显示 修改状态‘正常’，确认
        this.$confirm('修改状态"正常",  确认修改？', "", {
          cancelButtonText: "取消",
          confirmButtonText: "确认",
        }).then(() => {
          console.log(2222);
        });
      } else if (status === 3) {
        this.$confirm("确认关闭？", "", {
          cancelButtonText: "取消",
          confirmButtonText: "关闭",
        }).then(() => {
          console.log(3333);
        });
      }
      // 页面更新
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        this.form.pics = [];
        getDetail(this.form.id).then((res) => {
          this.form = res.data.data;
          let pics = [];

          if (this.form.priceFactor == -1) {
            this.form.priceFactor = "";
          }
          if (this.form.stmStatus == -1) {
            this.form.stmStatus = "";
          }

          res.data.data.pics.forEach((i) => {
            let obj = {
              label: i.picName,
              value: i.picUrl,
            };
            pics.push(obj);
          });
          this.form.typeId = this.form.typeId + "";
          this.form.pics = pics;
        });
      }
      done();
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      getPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.data.forEach((e) => {
          e.typeId = e.typeId + "";
        });
        this.loading = false;
        this.selectionClear();
      });
    },
    tip(row) {
      this.$alert("确定修改状态吗？", "确定").then(() => {
        updateGroundStatus(row.id).then((res) => {
          if (res.data.success) {
            if (row.status === 1) {
              row.status = 0;
              this.$forceUpdate();
            } else {
              row.status = 1;
              this.$forceUpdate();
            }
            this.$message.success(res.data.msg);
          } else {
            this.$message.success(res.data.msg);
          }
        });
      });
    },
  },
};
</script>

<style lang="scss">
.sportground-show {
  .el-form-item__label {
    width: 115px !important;
  }
  .el-form-item__content {
    margin-left: 115px !important;
  }
}
</style>

import { getStyleName } from './const-style-name';

export function uuid(length, chars) {
  let result = "";
  let charsString = '';
  if(chars){
    if(typeof chars === 'string'){
      charsString = chars;
    }else if(typeof chars === 'object'){
      if(chars.hasNum){
        charsString += '0123456789';
      }
      if(chars.hasLower){
        charsString += 'abcdefghijklmnopqrstuvwxyz';
      }
      if(chars.hasUpper){
        charsString += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      }
    }
  }else{
    charsString = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  }
  length = length || 8;
  for (let i = length; i > 0; --i) {
    result += charsString[Math.floor(Math.random() * charsString.length)];
  }
  return result;
}

export function assign(target , source , deep){
  if(target){
    Object.keys(source).forEach(
      key => {
        if(target[key] !== undefined){
          if(typeof source[key] === 'object' && !Array.isArray(source[key]) && deep){
            assign(source[key] , source[key] , deep)
          }else{
            target[key] = source[key];
          }
        }else{
          target[key] = source[key];
        }
      }
    )
  }
  return target;
}

export const getStyle = (optionList , option , nameHash) => {
  let styles = {};
  if(optionList){
    (optionList || []).forEach(
      item => {
        let name = getStyleName(nameHash && nameHash[item.code] ? nameHash[item.code] : item.code);
        if(name){
          styles[name] = item.getValue(option ? option[item.code] : (item.defaultValue || item.value));
        }
      }
    )
  };
  return styles;
}

export const getStyleByObject = (option , nameHash) => {
  let styles = {};
  if(option){
    Object.keys(option).forEach(
      key => {
        let name = getStyleName(nameHash && nameHash[key] ? nameHash[key] : key);
        if(name){
          styles[name] = option[key] + (option['unit'] && option['unit'][key] ? option['unit'][key] : '');
        }
      }
    )
  };
  return styles;
}

export const OptionParseEach = (optionList , option , values) => {
  const getValue = (item , valueKey) => {
    valueKey = valueKey || 'value';
    if(values && values[item.code] !== undefined){
      return values[item.code];
    }else{
      if(item[valueKey] !== undefined){
        return item[valueKey];
      }else{
        return null;
      }
    }
  }
  option = option || {};
  optionList.forEach(
    item => {
      if(item['optionList']){
        if(item.code){
          option[item.code] = this.optionParseEach(item['optionList'] , null , values ? values[item.code] : null);
        }else{
          OptionParseEach(item['optionList'] , option , values ? values[item.code] : null);
        }
      }else{
        option[item.code] = getValue(item);
      }
      if(item.units){
        option['unit'] = option['unit'] !== undefined ? option['unit'] : {};
        option['unit'][item.code] = getValue(item , 'unitValue');
      }
    }
  )
  return option;
}

export const transformVar = (text , dash , widget , data) => {
  let vars = getVars(dash , widget , data);
  text = text.replace(/\$\{(.+?)\}/g , ($1 , $2) => {
    let str = $2.split('.').reduce((prev , current) => {
      return current && prev[current] && prev[current] !== undefined ? prev[current] : prev;
    } , vars);
    return str;
  })
  return text;
}

export const getVars = (dash , widget , data) => {
  let vars = {
    $data: data
  };
  if(dash && dash.variables){
    dash.variables.forEach(
      item => {
        vars[item.name] = item.value;
      }
    )
  }
  if(widget && widget.variables){
    widget.variables.forEach(
      item => {
        vars[item.name] = item.value;
      }
    )
  }
  return vars;
}
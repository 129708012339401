<template>
  <el-container class="h-100p" style="gap: 10px">
    <!-- 左侧表单 -->
    <el-aside width="350px">
      <basic-container isCard class="h-100p">
        <div style="display: flex; align-items: center">
          <el-input placeholder="输入关键字进行过滤" v-model="name" />
          <el-button size="small" style="margin-left: 15px" type="primary" @click="treeLoad">搜索</el-button>
        </div>

        <div style="margin-top: 15px">
          <el-tree :data="treeData" :props="defaultProps" @node-click="nodeClick"></el-tree>
        </div>
      </basic-container>
    </el-aside>
    <!-- 右侧表单 -->
    <el-main>
      <basic-container class="h-100p">
        <avue-crud
          :option="option"
          :search.sync="search"
          :table-loading="loading"
          :data="data"
          ref="crud"
          v-model="form"
          @row-del="rowDel"
          :page.sync="page"
          @search-change="searchChange"
          @refresh-change="refreshChange"
          @selection-change="selectionChange"
        >
          <template slot="menuLeft" slot-scope="scope">
            <el-button type="primary" size="small" icon="el-icon-plus" @click="openDislog('add')">新增人员 </el-button>
            <el-button type="primary" size="small" icon="el-icon-s-tools" @click="createUsers">批量开通账号 </el-button>
            <el-button type="primary" size="small" icon="el-icon-upload2" @click="handleImport">导入用户 </el-button>
            <el-button type="primary" size="small" icon="el-icon-s-tools" @click="excelFaceBoxDia">导入人脸图像 </el-button>
            <el-button class="mar-l-5" icon="el-icon-download" :loading="downLoading" @click="getMetaCommonListExport" size="small">导出用户</el-button>
          </template>
          <template slot-scope="scope" slot="menu">
            <template>
              <template v-if="scope.row.is_admin == 1">
                <el-button type="text" size="small" icon="el-icon-download" class="none-border" @click="setAdmin('0', scope.row)">取消管理员 </el-button>
              </template>
              <template v-if="scope.row.is_admin == 0">
                <el-button type="text" size="small" icon="el-icon-upload2" class="none-border" @click="setAdmin('1', scope.row)">设为管理员 </el-button>
              </template>
            </template>
            <template>
              <el-button type="text" size="small" class="none-border" icon="el-icon-view" @click="openDislog('detail', scope.row)">查看 </el-button>
              <el-button type="text" size="small" class="none-border" icon="el-icon-edit" @click="openDislog('update', scope.row)">编辑 </el-button>
              <el-button
                v-if="scope.row.account_status == 1 || scope.row.account_status == 2 || scope.row.account_status == 5"
                type="text"
                size="small"
                icon="el-icon-delete"
                class="none-border"
                @click="rowDel(scope.row)"
                >删除
              </el-button>

              <!-- <template v-if="scope.row.account_status == 1">
                <el-button type="text" size="small" icon="el-icon-finished" v-if="scope.row.face_ocr_status != 2" class="none-border" @click="onClickEndow(scope.row)">下发门禁权限 </el-button>
              </template>-->
            </template>
          </template>
        </avue-crud>

        <el-dialog title="用户数据导入" append-to-body :visible.sync="excelBox" width="555px">
          <avue-form :option="excelOption" v-model="excelForm" :upload-after="uploadAfter">
            <template slot="excelTemplate">
              <el-button type="primary" @click="handleTemplate"> 点击下载<i class="el-icon-download el-icon--right"></i> </el-button>
            </template>
          </avue-form>
        </el-dialog>
      </basic-container>
    </el-main>

    <!-- 新增人员模态框 -->
    <el-dialog :visible.sync="dialogVisible" destroy-on-close :with-header="false" :top="'8vh'" :body-style="{ padding: '0px' }" width="70%" class="buildDetail">
      <template v-if="dialogVisible">
        <div slot="title" v-if="dialogType != 'space'" class="header-title">
          <span class="fts-16">{{ title }}</span>
        </div>
        <div>
          <baseInfo :node="treeDepObj" :company="company" :companyNode="companyNode" :currentRow="currentRow" :doType="dialogType" :identity="identity" @close="dialogVisible = false"></baseInfo>
        </div>
      </template>
    </el-dialog>

    <!-- 用户信息导入模态框 -->
    <el-dialog title="用户数据导入" append-to-body :visible.sync="excelUserInfo" width="555px">
      <avue-form :option="excelOption" v-model="excelForm" :upload-after="uploadAfter">
        <template slot="excelTemplate">
          <el-button type="primary" @click="handleTemplate"> 点击下载<i class="el-icon-download el-icon--right"></i> </el-button>
        </template>
      </avue-form>
    </el-dialog>

    <!-- 用户人脸信息导入模态框 -->
    <el-dialog title="导入人脸图像" append-to-body :visible.sync="excelFaceBox" width="555px">
      <avue-form :option="excelFaceOption" v-model="excelFaceForm" :upload-after="uploadFaceAfter"> </avue-form>
    </el-dialog>
  </el-container>
</template>

<script>
import { getList, getUser, getUserPlatform, remove, update, updatePlatform, add, grant, resetPassword, addQGKHKUserAndAuth, exportUserData } from "@/api/system/user"
import { getList as getParamList } from "@/api/system/param.js"
import { getRoleTree } from "@/api/system/role"
import { mapGetters } from "vuex"
import website from "@/config/website"
import { downFileByResult } from "@/api/file"
import { getMetaCommonList, updateMetaCommon, getMetaCommonListExport } from "@/api/busiMode/metaCommon"
import baseInfo from "./../employee/baseInfo.vue"
import { fastCheckInComPanyProcess } from "@/api/visitor/visitor"

import { createUsers, delHKUserAndAuth } from "@/api/user"
export default {
  data() {
    return {
      /** 左侧树形结构关键字查询 */
      name: "",
      initPassword: "",
      form: {},
      search: {},
      excelBox: false,
      platformBox: false,
      initFlag: true,
      /** 右侧：批量选择 */
      selectionList: [],
      excelFaceBox: false,
      query: {},
      loading: false,
      excelFaceForm: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },

      props: {
        label: "title",
        value: "key",
      },
      treeDeptId: "",
      treeDepObj: {},
      treeData: [],

      defaultProps: {
        children: "children",
        label: "title",
      },
      option: {
        height: "auto",
        tip: false,
        searchShow: true,
        searchMenuSpan: 4,
        border: true,
        index: true,
        selection: true,
        viewBtn: true,
        viewBtn: false,
        dialogClickModal: false,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        cancelBtn: false,
        selection: true,
        menuWidth: 270,
        column: [
          {
            overHidden: true,
            label: "姓名",
            align: "center",
            prop: "name",
            hide: false,
            search: true,
          },
          {
            overHidden: true,
            label: "手机号",
            align: "center",
            prop: "contact_details",
            hide: false,
            search: true,
          },
          {
            width: 120,
            overHidden: true,
            label: "角色",
            align: "center",
            prop: "is_admin",
            hide: false,
            formatter: (row, col, val) => {
              return val == "1" ? "管理员" : "普通用户"
            },
          },
          {
            width: 150,
            label: "人脸开通状态",
            prop: "face_ocr_status",
            type: "select",
            align: "center",
            search: true,
            searchLabelWidth: 110,
            dicData: [
              {
                label: "待开通",
                value: 0,
              },
              {
                label: "已开通,人脸信息未上传",
                value: 1,
              },
              {
                label: "已禁用",
                value: 2,
              },
              {
                label: "开通失败",
                value: 3,
              },
              {
                label: "已开通",
                value: 4,
              },
            ],
            search: true,
          },
          {
            label: "状态",
            prop: "status",
            type: "select",
            align: "center",
            dicData: [
              {
                label: "在职",
                value: 1,
              },
              {
                label: "离职",
                value: 2,
              },
              {
                label: "退休",
                value: 3,
              },
              {
                label: "其它",
                value: 3,
              },
            ],
          },

          {
            width: 150,
            label: "账户开通状态",
            prop: "account_status",
            type: "select",
            align: "center",
            dicData: [
              {
                label: "待开通",
                value: 0,
              },
              {
                label: "已开通",
                value: 1,
              },
              {
                label: "已禁用",
                value: 2,
              },
              {
                label: "开通失败",
                value: 3,
              },
              {
                label: "待审核",
                value: 4,
              },
              {
                label: "审核不通过",
                value: 5,
              },
            ],
          },
        ],
      },
      data: [],
      platformQuery: {},
      platformSelectionList: [],
      platformData: [],
      platformForm: {},

      excelForm: {},

      hk_qgk_doorsDis: false,
      hk_qgk_doors: [],
      hk_qgk_doors_ids: [],
      /** 用户模态框状态 */
      dialogVisible: false,
      companyList: [],
      company: [] /** 没用，配合组件传参 */,

      /** 用户信息导入配置 */
      excelUserInfo: false,
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "模板上传",
            prop: "excelFile",
            type: "upload",
            drag: true,
            loadText: "模板上传中，请稍等",
            span: 24,
            propsHttp: {
              res: "data",
            },
            tip: "请上传 .xls,.xlsx 标准格式文件",
            action: "/api/park-company/company/importCompanyBak",
          },
          {
            label: "模板下载",
            prop: "excelTemplate",
            formslot: true,
            span: 24,
          },
        ],
      },
      downLoading: false,
      employee_export: "wisdom_park.v_wp_employee_export_stat",

      /** 人脸导入信息配置 */
      excelFaceOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "模板上传",
            prop: "excelFile",
            type: "upload",
            drag: true,
            loadText: "模板上传中，请稍等",
            span: 24,
            accept: "zip/*",
            propsHttp: {
              res: "data",
            },
            tip: "支持导入ZIP文件，建议文件中包含照片的数量在1000张以内；照片命名规则为姓名_手机号.jpg，需露出眉毛和眼睛；单张照片文件大小20 KB~10MB。",
            action: "/api/park-company/company/importEmployeesFace",
          },
        ],
      },
    }
  },
  watch: {
    "form.tenantId"() {
      if (this.form.tenantId !== "" && this.initFlag) {
        this.initData(this.form.tenantId)
      }
    },
    "excelForm.isCovered"() {
      if (this.excelForm.isCovered !== "") {
        const column = this.findObject(this.excelOption.column, "excelFile")
        column.action = `/api/jida-user/import-user?isCovered=${this.excelForm.isCovered}`
      }
    },
  },
  components: { baseInfo },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
    this.treeLoad()
  },
  methods: {
    /**加载左侧树形结构数据 */
    treeLoad() {
      let body = {
        current: 1,
        size: 1000,
        wheres: [
          { field: "sublessor_company_id", value: this.userInfo.companyId },
          { field: "renter_company_name", op: "LIKE", value: this.name },
        ],
      }
      getMetaCommonList("wisdom_park.v_wp_sublessor_renter_company", body).then((res) => {
        this.treeData = []
        this.companyList = res.data.data.records
        this.page.total = 0
        this.page.currentPage = 1
        this.data = []
        res.data.data.records.forEach((e) => {
          this.treeData.push({
            id: e.renter_company_id,
            title: e.renter_company_name,
            parentId: "0",
            deptId: e.renter_dept_id,
            hasChildren: false,
            key: e.renter_company_id,
            children: [],
            value: e.renter_company_id,
          })
        })
      })
    },

    /** 点击右侧树形结构数据 */
    nodeClick(data) {
      this.treeDeptId = data.id
      this.treeDepObj = data
      this.page.currentPage = 1
      this.onLoad(this.page)
    },

    /** 加载右侧表单（员工）数据 */
    onLoad(page, params = {}) {
      if (this.treeDeptId) {
        this.loading = true
        let body = {
          current: page.currentPage,
          size: page.pageSize,
          wheres: [{ field: "company_id", value: this.treeDeptId }],
        }
        Object.keys(params).forEach((key) => {
          body.wheres.push({ field: key, op: "LIKE", value: params[key] })
        })
        getMetaCommonList("wisdom_park.wp_employee", body).then(({ data }) => {
          this.loading = false
          if (data.success) {
            this.data = data.data.records
            this.page.total = data.data.total
          }
        })
      }
    },
    /** 打开 用户模态框 */
    openDislog(type, row) {
      this.currentRow = {}
      this.dialogType = type
      if (type == "detail") {
        this.currentRow = row
        this.dialogVisible = true
        this.title = "详情"
      } else if (type == "update") {
        this.currentRow = row
        this.title = "修改"
        this.dialogVisible = true
      } else {
        this.title = "新增"
        if (!this.treeDeptId) {
          this.$message.success("请选一个企业")
        } else {
          let com = this.companyList.find((item) => item.renter_company_id == this.treeDeptId)
          this.companyNode = {
            dept_id: this.treeDeptId,
            dept_name: com.renter_company_name,
          }
          console.log(this.companyNode)
          this.dialogVisible = true
        }
      }
    },

    /** 批量选择 */
    selectionChange(list) {
      this.selectionList = list
    },

    /** 批量开通账号 */
    createUsers() {
      if (!this.treeDeptId) {
        this.$message.success("请选中企业")
        return
      }
      if (this.selectionList.length == 0) {
        this.$message.success("请至少选择一个用户")
      } else {
        let obj = {
          companyId: this.selectionList[0]["company_id"],
          userIds: this.selectionList.map((item) => item.id).join(","),
        }
        createUsers(obj).then((res) => {
          this.$message.success("批量操作成功")
          this.onLoad(this.page)
        })
      }
    },

    /** 用户基本信息导入 */
    uploadAfter(res, done, loading, column) {
      this.excelBox = false
      this.onLoad(this.page)
      done()
    },

    /** 用户基本信息导出 */
    getMetaCommonListExport() {
      if (!this.treeDeptId) {
        this.$message("请先选择左侧企业")
        return
      }
      this.downLoading = true
      console.log(this.selectionList)
      /** 添加查询条件 */
      let query = {
        current: 1,
        size: 100,
        orders: [{ field: "update_time", sort: "DESC" }],
        wheres: [
          { field: "dept_id", value: this.treeDepObj.deptId },
          { field: "company_identity", value: 2 },
        ],
      }
      if (this.selectionList.length > 0) {
        let wheres = []
        this.selectionList.forEach((e) => {
          wheres.push(e.id)
        })
        query.wheres.push({ field: "id", op: "IN", value: wheres })
      }

      getMetaCommonListExport(this.employee_export, query)
        .then((res) => {
          this.downLoading = false
          let url = window.URL.createObjectURL(new Blob([res.data]))
          let link = document.createElement("a")
          link.style.display = "none"
          link.href = url
          link.download = this.treeDepObj.title + "人员信息" + ".xls"
          document.body.appendChild(link)
          link.click()
        })
        .catch((e) => {
          this.downLoading = false
        })
    },

    /*** 用户人脸信息导入 */
    excelFaceBoxDia() {
      this.treeDeptId ? (this.excelFaceBox = true) : this.$message.success("请先选择左侧企业")
    },

    uploadFaceAfter(res, done, loading, column) {
      this.excelFaceBox = false
      this.onLoad(this.page)
      done()
    },
    /** 管理员设置 */
    setAdmin(num, row) {
      let o = {
        update: { id: row.id, is_admin: Number(num) },
        primaryKeyValue: row["id"],
        primaryKey: "id",
      }
      updateMetaCommon("wisdom_park.wp_employee", o).then((res) => {
        if (res.data.code == 200) {
          let o = {
            isApproved: true,
            id: row.id,
          }
          fastCheckInComPanyProcess(o).then((ComPanyProcess) => {
            if (ComPanyProcess.data.code == 200) {
              if (row.account_status == 0) {
                let obj = {
                  companyId: row.company_id,
                  userIds: row.id,
                }
                createUsers(obj).then((res) => {
                  this.$message.success("操作成功")
                  this.onLoad(this.page)
                })
              } else {
                this.$message.success("操作成功")
                this.onLoad(this.page)
              }
            }
          })
        } else {
          this.$message({
            type: "error",
            message: "操作失败",
          })
        }
      })
    },
    /** 删除用户 */
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        delHKUserAndAuth(row.id).then((res) => {
          this.$message({
            type: "success",
            message: "操作成功!",
          })
          this.onLoad(this.page)
        })
      })
    },

    searchChange(params, done) {
      this.query = params
      this.page.currentPage = 1
      this.onLoad(this.page, params)
      done()
    },

    handleImport() {
      if (this.treeDeptId) {
        this.excelUserInfo = true
      } else {
        this.$message.warning("请先选择左侧企业")
      }
    },
    uploadAfter(res, done, loading, column) {
      window.console.log(column)
      this.excelBox = false
      this.refreshChange()
      done()
    },

    handleTemplate() {
      downFileByResult("/api/jida-user/export-template", "get", "用户导入模板.xls")
    },

    refreshChange() {
      this.onLoad(this.page, this.query)
    },
  },
}
</script>

<style>
.box {
  height: 800px;
}

.el-scrollbar {
  height: 100%;
}

.box .el-scrollbar__wrap {
  overflow: scroll;
}
</style>

<template>
    <el-container style="height: calc(100% - 50px)" v-loading="pageLoading">
        <el-main class="pad-20 ov-a">
            <el-form class="mar-t-20" :model="form" :rules="rules" ref="form" :size="formSize" label-width="100px">
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="入库单据号" prop="code">
                            <el-input v-model="form.code" disabled placeholder="系统自动生成"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="入库人" prop="storage_user">
                            <model-search-select class="dis-b w-100p" :disabled="disabled" v-model="form.storage_user" format="{name}_{deptName}_{postName}" :params="{tenantId: '000000'}"
                                                 :fillModel.sync="form" :fills="[
                {source: 'name' , target: 'storage_user_name'}
              ]">
                                <template slot-scope="{item}">
                                    <span style="float: left">{{ item.name }}{{item.postName ? `(${item.postName})` : ''}}</span>
                                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deptName }}</span>
                                </template>
                            </model-search-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="入库时间" prop="storage_date">
                            <el-date-picker :disabled="disabled" class="dis-b w-100p" v-model="form.storage_date" type="date" placeholder="选择日期" format="yyyy-MM-dd"
                                            value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="附件" prop="attachment_id">
                            <form-file :disabled="disabled" v-model="form.attachment_id"></form-file>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="入库说明" prop="remarks">
                            <el-input type="textarea" :rows="5" :disabled="disabled" placeholder="请输入内容" v-model="form.remarks">
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <div class="pad-tb-10">
                    <el-divider content-position="left">入库资产(共{{listDetailValues.length}}条)</el-divider>
                </div>
                <el-form-item label-width="0px">
                    <el-table :data="listDetailValues" :size="formSize" border>
                        <el-table-column v-if="!disabled" type="index" width="50">
                            <template slot="header">
                                <el-button size="mini" @click="addListDetailValue" type="primary" icon="el-icon-plus" circle></el-button>
                            </template>
                            <template slot-scope="scope">
                                <el-button size="mini" @click="listDetailValues.splice(scope.$index , 1);" type="danger" icon="el-icon-minus" circle></el-button>
                            </template>
                        </el-table-column>
                        <el-table-column v-show="!disabled" label="资产" width="210px">
                            <template slot-scope="scope">
                                <div style="display: flex;align-items: center;">
                                    <div style="flex: 1">
                                        <model-search-select :disabled="disabled" v-model="scope.row.asset_goods_id" model="wp_asset_goods" :fillModel.sync="scope.row" labelKey="name" valueKey="id"
                                                             :fills="[
                                                {source: 'name' , target: 'asset_name'},
                                                {source: 'finance_asset_code' , target: 'finance_asset_code'},
                                                {source: 'asset_brand' , target: 'asset_brand'},
                                                {source: 'asset_model' , target: 'asset_model'},
                                                {source: 'asset_norms' , target: 'asset_norms'},
                                              ]" :disabledList="listDetailValues.map(item => item.asset_goods_id)">
                                            <template slot-scope="{item}">
                                                <span style="float: left">{{ item.name }}</span>
                                                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.asset_model }}</span>
                                            </template>
                                        </model-search-select>
                                    </div>
                                    <div v-if="!disabled">
                                        <el-divider direction="vertical"></el-divider>
                                        <el-button size="mini" @click="editGoods(scope.row)">新增</el-button>
                                    </div>
                                </div>
                                <!-- <template v-if="!disabled">
                <el-divider direction="vertical"></el-divider>
                <el-button size="mini" @click="editGoods(scope.row)">新增</el-button>
              </template> -->
                            </template>
                        </el-table-column>
                        <el-table-column label="入库数量" :width="disabled ? '80px' : '170px'" prop="asset_quantity">
                            <template slot-scope="scope">
                                <el-input-number v-if="!disabled" :disabled="disabled" v-model="scope.row.asset_quantity" :min="1"></el-input-number>
                                <span v-else>{{scope.row.asset_quantity}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="入库位置" :width="!disabled ? '170px' : '350px'" prop="location_name_code">
                            <template slot-scope="scope">
                                <LocationCascader v-if="!disabled" :disabled="disabled" v-model="scope.row.location_name_code" @change="(data) => cascaderChange(data , scope.row)"></LocationCascader>
                                <span v-else>{{scope.row.location_name}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="资产名称" width="150px" prop="asset_name"></el-table-column>
                        <el-table-column label="财务资产编码" width="150px" prop="finance_asset_code"></el-table-column>
                        <el-table-column label="品牌" prop="asset_brand"></el-table-column>
                        <el-table-column label="型号" width="200px" prop="asset_model"></el-table-column>
                        <el-table-column label="规格" prop="asset_norms"></el-table-column>
                    </el-table>
                </el-form-item>
            </el-form>
        </el-main>
        <el-footer v-if="!isView" height="50px" class="lh-50">
            <div class="text-center">
                <el-button :size="formSize" :loading="btnLoading" icon="el-icon-close" @click="back">取 消</el-button>
                <el-button :size="formSize" :loading="btnLoading" icon="el-icon-check" type="primary" @click="saveForm(false)">保 存</el-button>
                <el-button :size="formSize" :loading="btnLoading" icon="el-icon-check" type="primary" @click="saveForm(true)">保存并提交审批</el-button>
            </div>
        </el-footer>
    </el-container>

</template>
<script>
import dayjs from 'dayjs';

import {
    saveMetaCommon,
    updateMetaCommon,
    getMetaCommonList,
    delMetaCommon,
    getMetaCommonListTree,
    getMetaCommonDetail,
} from '@/api/busiMode/metaCommon';

import { searchList } from '@/api/system/user';
import { uuid } from '@/util/util';

import { startFlow } from '@/api/flow/flow.js';

export default {
    data() {
        return {
            pageLoading: false,
            props: {
                lazy: true,
                label: 'name',
                value: 'id',
                lazyLoad: (node, resolve) => {
                    const { level } = node;
                    const modelCodes = ['wp_building', 'wp_floor', 'wp_room'];
                    let params = {
                        wheres: [],
                    };
                    if (level === 1) {
                        params = {
                            wheres: [{ field: 'building_id', value: node.path[level - 1] }],
                            orders: [{ field: 'code+1000', sort: 'DESC' }],
                        };
                    }
                    if (level === 2) {
                        params = {
                            wheres: [
                                { field: 'building_id', value: node.path[0] },
                                { field: 'floor_id', value: node.path[1] },
                            ],
                        };
                    }
                    getMetaCommonList(`wisdom_park.${modelCodes[level]}`, {
                        current: 1,
                        size: 500,
                        ...params,
                    }).then(res => {
                        con;
                        let data = res.data.data.records;
                        if (level === 1) {
                            data = data.map(item => {
                                return {
                                    ...item,
                                    name: item.code + '层',
                                };
                            });
                        }
                        if (level === 2) {
                            data = data.map(item => {
                                return {
                                    ...item,
                                    leaf: true,
                                };
                            });
                        }
                        resolve(data);
                    });
                },
            },
            btnLoading: false,
            form: {
                code: `RK-${dayjs().format('YYYYMMDD')}${uuid(6, '1234567890')}`,
                remarks: '',
                storage_user: null,
                storage_user_name: '',
                storage_date: dayjs().format('YYYY-MM-DD 00:00:00'),
                attachment_id: '',
            },
            listDetailValues: [],
            dictData: {
                storage_user: [],
                goods: [],
            },
            subFormOption: {
                labelPosition: 'top',
                column: [
                    {
                        label: '资产名称',
                        prop: 'name',
                        rules: [{ required: true, message: '请输入资产名称', trigger: 'blur' }],
                    },
                    {
                        label: '资产类型',
                        prop: 'asset_category_code',
                        type: 'tree',
                        props: {
                            label: 'asset_category_name',
                            value: 'id',
                        },
                        dicData: [],
                        rules: [{ required: true, message: '请选择资产类型', trigger: 'blur' }],
                    },
                    {
                        label: '财务资产编码',
                        prop: 'finance_asset_code',
                        rules: [{ required: true, message: '请输入财务资产编码', trigger: 'blur' }],
                    },
                    {
                        label: '品牌',
                        prop: 'asset_brand',
                        rules: [{ required: true, message: '请输入品牌', trigger: 'blur' }],
                    },
                    {
                        label: '型号',
                        prop: 'asset_model',
                        rules: [{ required: true, message: '请输入型号', trigger: 'blur' }],
                    },
                    {
                        label: '规格',
                        prop: 'asset_norms',
                        rules: [{ required: true, message: '请输入规格', trigger: 'blur' }],
                    },
                    {
                        label: '价值',
                        prop: 'asset_vlaue',
                        rules: [{ required: true, message: '请输入价值', trigger: 'blur' }],
                    },
                    {
                        label: '生产厂家',
                        prop: 'manufacturer_name',
                        rules: [{ required: true, message: '请输入生产厂家', trigger: 'blur' }],
                    },
                    {
                        label: '折旧率',
                        prop: 'depreciation_rate',
                        rules: [{ required: true, message: '请输入折旧率', trigger: 'blur' }],
                    },
                    {
                        label: '预计使用年限',
                        prop: 'expected_useful_life',
                        rules: [{ required: true, message: '请输入预计使用年限', trigger: 'blur' }],
                    },
                    {
                        label: '计量单位',
                        prop: 'unit_of_measure',
                        rules: [{ required: true, message: '请输入计量单位', trigger: 'blur' }],
                    },
                ],
            },
            catalogModel: 'wisdom_park.wp_asset_category',
            model: 'wisdom_park.wp_asset_storage',
            detailTable: 'wp_asset_storage_detail',
            masterIdColumnName: 'storage_id',
            listValue: {
                field: [
                    'asset_goods_id',
                    'asset_quantity',
                    'location_name',
                    { code: 'asset_name', isDel: true, type: 'string' },
                    { code: 'asset_brand', isDel: true, type: 'string' },
                    { code: 'asset_norms', isDel: true, type: 'string' },
                    { code: 'asset_model', isDel: true, type: 'string' },
                    { code: 'finance_asset_code', isDel: true, type: 'string' },
                    {
                        type: 'arrarObj',
                        code: 'location_name_code',
                        isDel: true,
                        field: ['location_build_id', 'location_floor_id', 'location_room_id'],
                    },
                ],
                relate: 'storage_id',
                master: ['asset_goods_id', 'location_name_code'],
            },
        };
    },
    computed: {
        disabled() {
            return this.isTodo || this.isView || false;
        },
        isView() {
            return this.isTodo ? this.isTodo : this.view;
        },
    },
    props: {
        id: {
            type: String,
            default: '',
        },
        formSize: {
            type: String,
            default: 'small',
        },
        isAdd: {
            type: Boolean,
            default: true,
        },
        view: {
            type: Boolean,
            default: false,
        },
        isTodo: {
            type: Boolean,
            default: false,
        },
        rules: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    mounted() {
        this.initData();
        if (this.isAdd) {
            this.addListDetailValue();
            // for(let i = 5; i > 0; i--){
            //   this.addListDetailValue()
            // }
        }
        if (this.id) {
            this.form.code = '';
            this.getMetaCommonDetail();
        }
    },
    methods: {
        cascaderChange(data, row) {
            row.location_name = data.label;
        },
        tableRowChange(row) {
            (row.assets.goods || []).forEach(item => {
                if (item.id === row.asset_goods_id) {
                    Object.assign(row.assets, item);
                }
            });
        },
        getMetaCommonDetail() {
            this.pageLoading = true;
            getMetaCommonDetail(this.model, {
                pk: 'id',
                id: this.id,
                detailTable: this.detailTable,
                masterIdColumnName: this.masterIdColumnName,
            })
                .then(res => {
                    let data = res.data.data;
                    let listDetailValues = data['listDetailValues'] || [];

                    delete data['listDetailValues'];

                    this.form = data;

                    listDetailValues.forEach(item => {
                        this.listValue.field.forEach(fieldItem => {
                            if (typeof fieldItem === 'string') {
                                fieldItem = {
                                    type: 'string',
                                    code: fieldItem,
                                    field: [],
                                };
                            }
                            if (fieldItem.type === 'arrar') {
                                item[fieldItem.code] = item[fieldItem.code].split(fieldItem.separate || '-');
                            } else if (fieldItem.type === 'arrarObj') {
                                item[fieldItem.code] = [];
                                if (fieldItem.field) {
                                    fieldItem.field.forEach(fieldName => {
                                        if (item[fieldName]) {
                                            item[fieldItem.code].push(item[fieldName]);
                                        }
                                    });
                                }
                            } else {
                                item[fieldItem.code] = item[fieldItem.code] || null;
                            }
                        });
                    });
                    this.listDetailValues = listDetailValues;
                    this.pageLoading = false;
                })
                .catch(e => {
                    this.pageLoading = false;
                });
        },
        back() {
            this.$emit('back');
        },
        initData(type) {
            getMetaCommonListTree(this.catalogModel, {
                labelField: 'asset_category_name',
                valueField: 'id',
                releaseField: 'parent_id',
                isLazy: false,
            }).then(res => {
                const column = this.findObject(this.subFormOption.column, 'asset_category_code');
                column.dicData = res.data.data;
            });
        },
        addListDetailValue() {
            let itemObj = {};
            this.listValue.field.forEach(item => {
                if (typeof item === 'string') {
                    item = {
                        type: 'string',
                        code: item,
                    };
                }
                switch (item.type) {
                    case 'string':
                        itemObj[item.code] = '';
                        break;
                    case 'number':
                        itemObj[item.code] = null;
                        break;
                    case 'arrar':
                        itemObj[item.code] = [];
                        break;
                    case 'arrarObj':
                        itemObj[item.code] = [];
                        break;
                }
            });
            this.listDetailValues.push(itemObj);
        },
        getListDetailValues() {
            let listDetailValues = [],
                isError = false;
            this.listDetailValues.forEach(item => {
                let master = [];
                if (typeof this.listValue.master === 'string') {
                    master = [this.listValue.master];
                } else {
                    master = this.listValue.master;
                }
                let add = true;
                master.forEach(name => {
                    if ((item[name] ?? '') === '' || (Array.isArray(item[name]) && item[name].length <= 0)) {
                        add = false;
                        isError = true;
                    }
                });
                if (add) {
                    let newItem = {};
                    this.listValue.field.forEach(fieldItem => {
                        if (typeof fieldItem === 'string') {
                            fieldItem = {
                                type: 'string',
                                code: fieldItem,
                                field: [],
                                isDel: false,
                            };
                        }
                        switch (fieldItem.type) {
                            case 'string':
                                if (fieldItem.isDel !== true) {
                                    newItem[fieldItem.code] = item[fieldItem.code];
                                }
                                break;
                            case 'number':
                                if (fieldItem.isDel !== true) {
                                    newItem[fieldItem.code] = Number(item[fieldItem.code]);
                                }
                                break;
                            case 'arrar':
                                if (fieldItem.isDel !== true) {
                                    newItem[fieldItem.code] = item[fieldItem.code].join(fieldItem.separate || '-');
                                }
                                break;
                            case 'arrarObj':
                                fieldItem.field.forEach((fieldName, index) => {
                                    if (item[fieldItem.code][index]) {
                                        newItem[fieldName] = item[fieldItem.code][index];
                                    }
                                });
                                break;
                        }
                    });
                    if (item.id) {
                        newItem['id'] = item.id;
                    }
                    if (item[this.listValue.relate]) {
                        newItem[this.listValue.relate] = item[this.listValue.relate];
                    }
                    listDetailValues.push(newItem);
                }
            });
            return isError ? null : listDetailValues;
        },
        editGoods(row) {
            this.$DialogForm.show({
                title: '新增资产',
                width: '50%',
                data: {},
                option: this.subFormOption,
                callback: formRes => {
                    let data = {};
                    this.subFormOption.column.forEach(item => {
                        data[item.prop] = formRes.data[item.prop];
                    });
                    saveMetaCommon('wisdom_park.wp_asset_goods', {
                        values: data,
                    }).then(res => {
                        formRes.done();
                        formRes.close();
                        let resData = res.data.data;
                        row.asset_goods_id = resData.id;
                    });
                },
            });
        },
        startFlow(row) {
            return startFlow(row.id, this.$PCode.BIZ.ASSET_STORAGE, this.$PCode.ACTIONS.SUBMIT);
        },
        saveForm(isStartFlow) {
            let listDetailValues = this.getListDetailValues();
            if (!listDetailValues) {
                this.$message({
                    type: 'error',
                    message: '请完善入库资产信息，资产和入库位置不能为空。',
                });
                return false;
            }
            if (!listDetailValues.length) {
                this.$message({
                    type: 'error',
                    message: '请完善入库资产',
                });
                return false;
            }

            this.$refs.form.validate(valid => {
                if (valid) {
                    let body = {
                        ...this.form,
                    };
                    if (this.form.id) {
                        delete body.id;
                        body = {
                            update: body,
                            primaryKeyValue: this.form.id,
                            primaryKey: 'id',
                            listDetailValues: listDetailValues,
                        };
                    } else {
                        body = {
                            values: body,
                            listDetailValues: listDetailValues,
                        };
                    }
                    this.btnLoading = true;
                    (this.form.id ? updateMetaCommon : saveMetaCommon)(this.model, {
                        ...body,
                        detailTable: this.detailTable,
                        masterIdColumnName: this.masterIdColumnName,
                    })
                        .then(res => {
                            if (isStartFlow) {
                                return this.startFlow(this.form.id ? this.form : res.data.data).then(res1 => res1);
                            } else {
                                return res;
                            }
                        })
                        .then(res => {
                            this.btnLoading = false;
                            this.$message.success(res.data.msg);
                            this.back();
                        })
                        .catch(e => {
                            this.btnLoading = false;
                        });
                } else {
                    return false;
                }
            });
        },
    },
};
</script>
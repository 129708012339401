<template>
  <basic-container>
    <avue-crud
      ref="table"
      :data="tableData"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      :before-open="beforeOpen"
      :search.sync="search"
      v-model="form"
      @search-change="searchChange"
      @on-load="onLoad"
      @refresh-change="onLoad"
      @row-save="saveTableForm"
      @row-update="
        (row, index, done, loading) => saveTableForm(row, done, loading)
      "
      @row-del="rowDel"
    >
    </avue-crud>
  </basic-container>
</template>
<script>
import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  delMetaCommon,
  getPicListByIds,
  getMetaCommonListTree,
} from "@/api/busiMode/metaCommon";
export default {
  data() {
    return {
      form: {},
      model: "wisdom_park.wp_asset_goods",
      tableLoading: false,
      tableData: [],
      search: {},
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      tableOption: {
        height: "auto",
        // labelPosition: 'top',
        labelWidth: 100,
        searchMenuPosition: "center",
        searchLabelPosition: "right",
        // searchMenuSpan: 24,
        border: true,
        column: [
          {
            label: "财务编码",
            prop: "finance_asset_code",
            width: 200,
            search: true,
            searchLike: true,
          },
          {
            label: "名称",
            prop: "name",
            width: 250,
            search: true,
            searchLike: true,
          },
          {
            label: "类型",
            prop: "asset_category_code",
            type: "tree",
            props: {
              label: "asset_category_name",
              value: "id",
            },
            dicData: [],
            dataType: "string",
            width: 150,
            search: true,
          },
          {
            label: "品牌",
            prop: "asset_brand",
            width: 250,
            search: true,
            searchLike: true,
          },
          {
            label: "型号",
            prop: "asset_model",
            width: 150,
            search: true,
            searchLike: true,
          },
          {
            label: "所属批次数量",
            prop: "import_nums",
            width: 150,
          },
          {
            label: "规格",
            prop: "asset_norms",
            width: 150,
          },
          {
            label: "价值",
            prop: "asset_vlaue",
            width: 150,
          },
          {
            label: "预计使用年限",
            prop: "expected_useful_life",
            width: 120,
          },
          {
            label: "生产厂家",
            prop: "manufacturer_name",
            width: 250,
          },
          {
            label: "折旧率",
            prop: "depreciation_rate",
            append: "%",
            width: 120,
          },
          {
            label: "计量单位",
            prop: "unit_of_measure",
            width: 120,
          },
        ],
      },
      files: {},
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData(type) {
      getMetaCommonListTree("wisdom_park.wp_asset_category", {
        labelField: "asset_category_name",
        valueField: "id",
        releaseField: "parent_id",
        isLazy: false,
      }).then((res) => {
        const column = this.findObject(
          this.tableOption.column,
          "asset_category_code"
        );
        column.dicData = res.data.data;
      });
    },
    onLoad(params) {
      let wheres = [];
      let searchParams = {
        ...this.search,
      };
      Object.keys(searchParams).forEach((key) => {
        if (!key.includes("$") && (searchParams[key] ?? "") !== "") {
          const column = this.findObject(this.tableOption.column, key);
          let itemWhere = { field: key, value: searchParams[key] };
          if (column) {
            if (column.searchLike) {
              itemWhere["op"] = "LIKE";
            }
          }
          wheres.push(itemWhere);
        }
      });
      this.tableLoading = true;
      return getMetaCommonList(this.model, {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres,
      })
        .then((res) => {
          this.tableLoading = false;
          if (res.data && res.data.code === 200) {
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
          } else {
            this.tableData = [];
            this.page.total = 0;
          }
        })
        .catch((e) => {
          this.tableLoading = false;
        });
    },
    searchChange(params, done) {
      this.page.currentPage = 1;
      this.onLoad(params)
        .then((res) => {
          done();
        })
        .catch((e) => {
          done();
        });
    },
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        this.files = {};
        let attachment_ids = this.form.attachment_id
          ? this.form.attachment_id.split(",")
          : [];
        if (attachment_ids.length) {
          getPicListByIds(attachment_ids).then((res) => {
            this.form.attachment_id = res.data.data.map((item) => {
              this.files[item.id] = item;
              return {
                label: item.originalName,
                value: item.id,
              };
            });
            done();
          });
        } else {
          done();
        }
      } else {
        done();
      }
    },
    saveTableForm(row, done, loading) {
      let body = {
        ...row,
      };
      // ['template_name' , 'template_type' , 'template_use' , 'rent_way' , 'rent_type' , 'note' , 'attachment_id'].forEach(
      //   key => {
      //     if(row[key] !== undefined){
      //       body[key] = row[key];
      //     }
      //   }
      // );
      // if(body['attachment_id'] && Array.isArray(body['attachment_id'])){
      //   let attachment_id = body['attachment_id'].map(item => item.value);
      //   body['attachment_id'] = attachment_id.join(',');
      // }
      if (row.id) {
        body = {
          update: body,
          primaryKeyValue: row.id,
          primaryKey: "id",
        };
      } else {
        body = {
          values: body,
        };
      }
      (row.id ? updateMetaCommon : saveMetaCommon)(this.model, body)
        .then((res) => {
          this.responeHandle(res, done, loading);
        })
        .catch((e) => {
          loading();
        });
    },
    responeHandle(res, done, loading) {
      if (res.data && res.data.code === 200) {
        done && done();
        this.onLoad();
        this.$message.success(res.data.msg);
      } else {
        loading && loading();
        this.$message.error(res.data.msg);
      }
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return delMetaCommon(this.model, { pk: "id", ids: [row.id] });
        })
        .then(() => {
          this.onLoad();
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
  },
};
</script>

import BuiltInWidgetByText from './text/index';
import BuiltInWidgetByEcharts from './echarts/index';
import BuiltInWidgetByNumCard from './num-card/index';
import BuiltInWidgetByTable from './table/index';

const BuiltInWidgets = [
  ...BuiltInWidgetByText,
  ...BuiltInWidgetByEcharts,
  ...BuiltInWidgetByNumCard,
  ...BuiltInWidgetByTable
];


export {
  BuiltInWidgets
};
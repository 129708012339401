<template>
  <div>
    <el-col class="w-300 pad-0 employeeTree" :span="5">
      <div class="box">
        <!-- <el-scrollbar> -->
        <!-- <basic-container isCard>
          <div> -->
        <tree
          @nodeClick="nodeClick"
          :identity="identity"
          @treeData="getTreeData"
        ></tree>
        <!-- </div>
        </basic-container> -->
        <!-- </el-scrollbar> -->
      </div>
    </el-col>
    <el-col class="w-calc-300" :span="5">
      <div :style="{ height: viewPortHeight - 115 + 'px' }" class="o-h">
        <employee
          :node="node"
          :identity="identity"
          :height="viewPortHeight"
        ></employee>
      </div>
    </el-col>
  </div>
</template>

<script>
import tree from "./tree";
import employee from "./employee";
export default {
  components: { tree, employee },

  data() {
    return {
      node: {},
      viewPortHeight:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight,
    };
  },

  props: {
    identity: {
      type: Number,
      default() {
        return 2;
      },
    },
  },
  watch: {},
  computed: {},
  mounted() {},
  created() {},
  methods: {
    nodeClick(d) {
      this.node = d;
    },
    getTreeData(d) {
      console.log(d);
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
/deep/ .el-scrollbar__wrap {
  margin-right: 0px !important;
}
.employeeTree {
  /deep/ .basic-container .el-card {
    width: 100%;
    //overflow-x: hidden !important;
  }
  /deep/ .el-card__body {
    padding: 10px 0px !important;
  }
  position: relative;

  background: #fff;
  padding-top: 10px;
}
.avue-tree__content {
  height: viewPortHeight;
}
</style>

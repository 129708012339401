<template>
  <basic-container>
    <avue-crud
      ref="table"
      :data="tableData"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      :search.sync="search"
      v-model="form"
      @search-change="onLoad"
      @on-load="onLoad"
      @refresh-change="onLoad"
      @row-save="saveTableForm"
      @row-update="
        (row, index, done, loading) => saveTableForm(row, done, loading)
      "
      @row-del="rowDel"
    >
      <!-- <template slot=""></template> -->
    </avue-crud>
  </basic-container>
</template>
<script>
import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  delMetaCommon,
} from "@/api/busiMode/metaCommon";
import * as dictService from "@/api/system/dictbiz";

import { searchList } from "@/api/system/user.js";

export default {
  data() {
    return {
      form: {},
      model: "wisdom_park.wp_asset_collection",
      tableLoading: false,
      tableData: [],
      search: {},
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      tableOption: {
        height: "auto",
        labelPosition: "top",
        searchMenuPosition: "right",
        searchLabelPosition: "right",
        searchMenuSpan: 24,
        border: true,
        column: [
          {
            label: "领用单号",
            prop: "code",
            width: 200,
            display: false,
          },
          {
            label: "领用部门",
            prop: "collection_dept_id",
            width: 200,
          },
          {
            label: "领用人",
            prop: "collection_user_id",
            width: 200,
          },
          {
            label: "领用时间",
            prop: "collection_date",
            width: 150,
            type: "date",
          },
          {
            label: "计划领用时长",
            prop: "plan_collection_cycle",
            width: 100,
          },
          {
            label: "审批状态",
            prop: "status",
          },
          {
            label: "领用事由",
            prop: "remarks",
          },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    onLoad() {
      let wheres = [];
      this.tableLoading = true;
      this.tableData = [];
      getMetaCommonList(this.model, {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres,
      })
        .then((res) => {
          this.tableLoading = false;
          if (res.data && res.data.code === 200) {
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
          } else {
            this.tableData = [];
            this.page.total = 0;
          }
        })
        .catch((e) => {
          this.tableLoading = false;
        });
    },
    saveTableForm(row, done, loading) {
      let body = {};
      [
        "template_name",
        "template_type",
        "template_use",
        "rent_way",
        "rent_type",
        "note",
        "supplementary_terms",
      ].forEach((key) => {
        if (row[key] !== undefined) {
          body[key] = row[key];
        }
      });
      if (row.id) {
        body = {
          update: body,
          primaryKeyValue: row.id,
          primaryKey: "id",
        };
      } else {
        body = {
          values: body,
        };
      }
      (row.id ? updateMetaCommon : saveMetaCommon)(this.model, body)
        .then((res) => {
          this.responeHandle(res, done, loading);
        })
        .catch((e) => {
          loading();
        });
    },
    responeHandle(res, done, loading) {
      if (res.data && res.data.code === 200) {
        done && done();
        this.onLoad();
        this.$message.success(res.data.msg);
      } else {
        loading && loading();
        this.$message.error(res.data.msg);
      }
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return delMetaCommon(this.model, { pk: "id", ids: [row.id] });
        })
        .then(() => {
          this.onLoad();
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
  },
};
</script>

import Component from './main.vue';

export const LineChartConfigs = [
  {
    widgetName: '折线图',
    type: '图表',
    code: 'BuiltInWidgetByEchartsLine',
    component: Component,
    icon: 'portal-iconfont portal-icon_fsux_zhexiantu',
    dataOption: [
      {name: '分类' , code: 'category' , type: 'field'},
      {
        name: '系列' , code: 'value' , type: 'dynamic' ,
        optionList: [],
        meta: [
          {name: '系列名' , code: 'name' , type: 'text'},
          {name: '系列值' , code: 'value' , type: 'field'}
        ]
      },
    ],
    optionList: [
      {
        code: 'chartType',
        value: 'line',
        hide: true,
        type: 'text',
        name: '图表类型',
      },
      {
        code: '',
        name: '主题配置',
        optionList: [
          {
            code: 'color',
            type: 'theme',
            value: {
              name: '',
              color: []
            },
            name: '主题'
          }
        ]
      },
      {
        code: 'tooltip',
        name: '提示',
        optionList: [
          {
            code: 'show',
            name: '显示',
            type: 'boolean',
            value: true
          }, 
          {
            code: 'trigger',
            name: '触发类型',
            type: 'select',
            value: 'item',
            selectList: [
              {name: '数据项' , value: 'item'},
              {name: '坐标轴' , value: 'axis'}
            ]
          }
        ]
      },
      {
        code: 'legend',
        name: '图例',
        optionList: [
          {
            code: 'show',
            name: '显示',
            type: 'boolean',
            value: true
          }
        ]
      },
      {
        code: 'xAxis',
        name: 'X轴配置',
        optionList: [
          {
            code: 'type',
            value: 'category',
            type: 'select',
            name: '类型',
            selectList: [
              {name: '类目轴' , value: 'category'},
              {name: '数值轴' , value: 'value'},
              {name: '时间轴' , value: 'time'},
              {name: '对数轴' , value: 'log'}
            ]
          }
        ]
      },
      {
        code: 'yAxis',
        name: 'Y轴配置',
        optionList: [
          {
            code: 'type',
            value: 'value',
            type: 'select',
            name: '类型',
            selectList: [
              {name: '类目轴' , value: 'category'},
              {name: '数值轴' , value: 'value'},
              {name: '时间轴' , value: 'time'},
              {name: '对数轴' , value: 'log'}
            ]
          }
        ]
      },
      {
        code: '_series',
        name: '其他配置',
        optionList: [
          {
            code: 'stack',
            name: '堆叠',
            type: 'boolean',
            value: false
          },
          {
            code: 'smooth',
            name: '平滑显示',
            type: 'boolean',
            value: false
          }
        ]
      }
    ]
  }
];

export const BarParse = () => {

}
export default [
  {
    'label':'HIGHEST',
    'value':'HIGHEST'
  },
  {
    'label':'HIGH',
    'value':'HIGH'
  },
  {
    'label':'MEDIUM',
    'value':'MEDIUM'
  },
  {
    'label':'LOW',
    'value':'LOW'
  },
  {
    'label':'LOWEST',
    'value':'LOWEST'
  }
]

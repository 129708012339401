import { ToolbarButtons } from './g6';

export default {
    methods: {
        _event_nodeClick_datasourceBox(){
            this.datasourceCombo = false;
            this.clearRender();
        },
        _event_nodeClick_datasourceComboCircle(){
            this.datasourceCombo = true;
            this.clearRender();
        },
        _event_nodeClick_default(ev){

        },
        _event_indicatorClick(data , ev){

        },
        // _event_nodeClick_dataHierarchy(ev){
        //     let model = ev.item.getModel();
        //     this._getData_getTables(this.getNodeId(model.id)).then(
        //         res => {
        //             this.level = 2;
        //             this.initGraph();
        //             this.clearRender();
        //         }
        //     );
        // },

        _event_toolbarClick_zoomIn(){
            let zoom = this.graph.getZoom() , center = {
                x: this.graph.getWidth() / 2 ,
                y: this.graph.getHeight() / 2
            };
            this.graph.zoomTo(zoom + 0.5 , center);
        },
        _event_toolbarClick_zoomOut(){
            let zoom = this.graph.getZoom() , center = {
                x: this.graph.getWidth() / 2 ,
                y: this.graph.getHeight() / 2
            };
            if(zoom <= 1){
                zoom -= 0.1;
            }else{
                zoom -= 0.5;
            }
            this.graph.zoomTo(zoom < 0.3 ? 0.3 : zoom , center);
        },
        _event_toolbarClick_realZoom(){
            let center = {
                x: this.graph.getWidth() / 2 ,
                y: this.graph.getHeight() / 2
            };
            this.graph.zoomTo(1 , center);
        },
        _event_toolbarClick_autoZoom(){
            this.graph.fitView();
        },
        _event_toolbarClick_back(){
            this.showType = 'chartShow';
            this.level = 1;
            this.$nextTick(() => {
                this.initGraph();
                this.clearRender();
            })
        },
        _event_toolbarClick_tableShow(){
            this.showType = 'tableShow';
            this.toolbarButton = ToolbarButtons.level_2_tableShow;
        },
        _event_toolbarClick_chartShow(){
            this.showType = 'chartShow';
            this.toolbarButton = ToolbarButtons.level_2;
        },
        _event_toolbarClick(code){
            if(this['_event_toolbarClick_' + code] && typeof this['_event_toolbarClick_' + code] === 'function'){
                this['_event_toolbarClick_' + code] ();
            }
        },
        _event_nodeClick(){
            this.graph.on('node:click' , (ev) => {
                let model = ev.item.getModel();
                // this.graph.focusItem(cfg.id , true);
                if(model){
                    if(this[`_event_nodeClick_${model.id}`]){
                        this[`_event_nodeClick_${model.id}`](ev)
                    }else if(this[`_event_nodeClick_${model.nodeType}`]){
                        this[`_event_nodeClick_${model.nodeType}`](ev);
                    }else{
                        this._event_nodeClick_default(ev);
                    }
                }
            })
        },
        _event_edgeClick(){
            this.graph.on('edge:click' , (ev) => {
                let model = ev.item ? ev.item.getModel() : null;
                if(model){
                    if(model.edgeType === 'task'){
                        let query = [];
                        if(model.query){
                            Object.keys(model.query).forEach(
                                key => {
                                    query.push(`${key}=${model.query[key]}`);
                                }
                            )
                        }
                        this.$router.push(`/projects/definition/list?${query.join('&')}`);
                    }
                }
            })
        },
        _event_comboDblclick(){
            this.graph.on('combo:dblclick', (evt) => {
                const { item } = evt;
                if(item._cfg.id === 'datasourceCombo'){
                    this.datasourceCombo = !this.datasourceCombo;
                    this.render(this.getRanderData());
                }
            });
        },
        bindEvents(){
            this._event_edgeClick();
            this._event_comboDblclick();
            this._event_nodeClick();
        },

        _event_page_tableClick(row){
            let data = row._item || {};
            if(data.guid){
                this.dataAssetsDetailProps = {
                    propGuid: data.guid,
                    propTableName: data.tableName,
                    propDatabaseId: data.datasourceId,
                    propDatabaseName: data.databaseName
                }
                this.showTableDetail = true;
            }
        }
    }
}

<template>
  <el-row v-loading="cardLoading">
    <el-col :span="24" style="min-height: 300px">
      <avue-form
        ref="addForm"
        class="tablePreview"
        v-model="addForm"
        :option="newOption"
        @submit="addObj"
        :upload-error="uploadError"
      >
        <template slot-scope="{ disabled, size }" :slot="mapqygh">
          <div>
            <el-input
              v-model="mapqyghValue"
              placeholder="选择区域"
              @focus="openMapDialog($event)"
            />
          </div>
        </template>
        <template slot-scope="scope" slot="menuForm">
          <el-button
            v-if="!formDisable"
            icon="el-icon-check"
            type="primary"
            @click="addFormSubmit"
            >保存
          </el-button>
          <el-button
            v-if="!formDisable"
            icon="el-icon-delete"
            @click="clearAddForm"
            >清空
          </el-button>
          <el-button v-if="target == 'target'" @click="goBack()"
            >返回</el-button
          >
        </template>
      </avue-form>
    </el-col>
    <el-dialog
      title="地图区域"
      :visible.sync="mapVisible"
      width="1144px"
      :before-close="handleClose"
    >
      <div id="container" style="height: 500px; width: 1104px"></div>
      <div class="input-card">
        <button
          class="btn"
          @click="polyEditorFun('open')"
          style="margin-bottom: 5px"
        >
          开始编辑
        </button>
        <button class="btn" @click="polyEditorFun('close')">结束编辑</button>
      </div>
    </el-dialog>
  </el-row>
</template>
<script>
import { mapGetters, mapState } from "vuex";
import axios from "@/router/axios";
import {
  getMetaCommonDetail,
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  getMetaCommonTree,
  delMetaCommon,
  getMetaCommonInfo,
  getMetaCommonListByDict,
} from "@/api/busiMode/metaCommon";
import { eventRun } from "@/api/tool/eventModel";

import { saveDynamicForm, getDetailByPlanId } from "@/api/busiMode/meta";
export default {
  name: "newPage",
  data() {
    return {
      polyEditor: {},
      modelId: "",
      mapqygh: "xqfw",
      mapqyghValue: "",
      mapVisible: false,
      addForm: {},
      cardLoading: false,
      currentUploadObj: {},
      formOption: {},
      mapData: [],
      formDisable: false,
      serviceId: "",
      filterList: [
        "create_time",
        "create_date",
        "create_user",
        "create_user_name",
        "update_user",
        "update_user_name",
        "tenant_id",
        "update_time",
        "is_deleted",
      ],

      newOptions: {
        column: [
          {
            label: "级联",
            prop: "xzqhdm",
            type: "map",
            props: {
              label: "title",
              value: "key",
            },
            lazy: true,
            lazyLoad(node, resolve) {
              let stop_level = 2;
              let level = node.level;
              let data = node.data || {};
              let code = data.code;
              let list = [];
              let callback = () => {
                resolve(
                  (list || []).map((ele) => {
                    return Object.assign(ele, {
                      leaf: level >= stop_level,
                    });
                  })
                );
              };
              if (level == 0) {
                axios
                  .get(`/api/jida-system/region/lazy-tree?parentCode=00`)
                  .then((res) => {
                    list = res.data.data;
                    callback();
                  });
              } else if (level == 1) {
                axios
                  .get(
                    `/api/jida-system/region/lazy-tree?parentCode=` +
                      node.data.value
                  )
                  .then((res) => {
                    list = res.data.data;
                    callback();
                  });
              } else if (level == 2) {
                axios
                  .get(
                    `/api/jida-system/region/lazy-tree?parentCode=` +
                      node.data.value
                  )
                  .then((res) => {
                    list = res.data.data;
                    callback();
                  });
              } else {
                callback();
              }
            },
          },
        ],
        labelPosition: "right",
        labelSuffix: " ",
        labelWidth: 80,
        gutter: 0,
        menuBtn: true,
        submitBtn: false,
        submitText: "保存",
        emptyBtn: false,
        emptyText: "清空",
        menuPosition: "center",
        url: "",
        queryType: "",
        disabled: true,
        cascaderList: [],
      },
    };
  },
  computed: {
    ...mapState({
      viewEdit: (state) => state.common.viewEdit,
    }),
  },
  props: {
    newOption: {
      type: Object,
      default: function () {
        return {};
      },
    },

    target: {
      type: String,
      default: function () {
        return "target";
      },
    },
    guid: {
      type: String,
      default: function () {
        return "";
      },
    },
    pk: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  watch: {
    viewEdit(val) {
      if (val) {
        this.$router.push({
          path: "/data/table/tableCommon/?modleId" + this.modelId,
        });
      }
    },
    newOption: {
      handler: function (newValue, oldName) {
        if (newValue.column) {
          this.formatOptions();
        }
      },
      immediate: true,
      deep: true,
    },
    addForm: {
      handler: function (newValue, oldName) {
        if (newValue) {
          this.formatOptionsMap();
        }
      },
      immediate: true,
      deep: true,
    },
    formDisable: {
      handler: function (newValue, oldName) {
        //this.newOption.disabled = newValue;
        // this.formatOptions();
      },
    },
  },
  created() {},
  destroyed() {
    this.$breadcrumb.pop();
  },
  mounted() {
    this.modelId = this.$route.fullPath.split(
      "/data/table/newPage/?modleId"
    )[1];
    //this.guid = this.$route.query.guid;
    if (this.modelId) {
      this.getDetailByPlanId();
    }
  },

  methods: {
    openMapDialog() {
      let t = this;
      t.mapVisible = true;
      setTimeout(function () {
        t.initMap();
      }, 100);
    },
    initMap() {
      var map = new AMap.Map("container", {
        center: [118.327667, 35.061631],
        zoom: 14,
      });
      this.addForm[this.mapqygh];
      var path = [
        [118.380756, 35.031046],
        [118.368728, 35.030055],
        [118.366122, 35.026807],
        [118.35429, 35.025834],
      ];
      if (this.mapqyghValue) {
        var point = this.mapqyghValue.split(":");
        path = [];
        for (var i in point) {
          path.push([
            parseFloat(point[i].split(",")[1]),
            parseFloat(point[i].split(",")[0]),
          ]);
        }
      }
      var polygon = new AMap.Polygon({
        path: path,
        strokeColor: "#FF33FF",
        strokeWeight: 6,
        strokeOpacity: 0.2,
        fillOpacity: 0.4,
        fillColor: "#1791fc",
        zIndex: 50,
      });

      map.add(polygon);
      // 缩放地图到合适的视野级别
      map.setFitView([polygon]);

      this.polyEditor = new AMap.PolyEditor(map, polygon);
      let t = this;
      this.polyEditor.on("end", function (event) {
        console.log(event.target.getPath());
        var arr = event.target.getPath();
        let points = "";
        for (var i in arr) {
          points =
            points +
            parseFloat(arr[i].lat) +
            "," +
            parseFloat(arr[i].lng) +
            ":";
        }
        t.mapqyghValue = points.substr(0, points.length - 1);
        t.mapVisible = false;
        t.addForm[t.mapqygh] = t.mapqyghValue;
      });
    },
    //polyEditor.close()
    polyEditorFun(type) {
      if (type == "open") {
        this.polyEditor.open();
      } else {
        this.polyEditor.close();
      }
    },
    formatOptionsMap() {
      try {
        let isChange = true;
        Object.keys(this.addForm).forEach((form) => {
          Object.keys(this.formOption).forEach((option) => {
            if (option == form && isChange == true) {
              if (this.formOption[option].type == "map") {
                if (this.formOption[option].coordinateType == "longitude") {
                  //经度
                  let jd = this.addForm[form];
                  if (
                    Array.isArray(this.addForm[form]) &&
                    jd.length > 0 &&
                    jd[0] != jd[2]
                  ) {
                    this.addForm[form][2] = this.addForm[form][0];
                    this.$set(this.addForm, form, this.addForm[form]);
                    console.log("经度修改");
                    isChange = false;
                    JSON.stringify({
                      dqwd: [
                        this.addForm[form][1],
                        this.addForm[form][0],
                        this.addForm[form][1],
                      ],
                    });
                    throw {
                      key: "dqwd",
                      value: [
                        this.addForm[form][0],
                        this.addForm[form][1],
                        this.addForm[form][1],
                      ],
                    };
                  }
                }
                if (this.formOption[option].coordinateType == "latitude") {
                  //纬度
                  console.log("修改");
                  let wd = this.addForm[form];
                  if (
                    Array.isArray(this.addForm[form]) &&
                    this.addForm[form].length > 0 &&
                    wd[1] != wd[2]
                  ) {
                    this.mapData = this.addForm[form];
                    this.addForm[form][2] = this.addForm[form][1];
                    this.$set(this.addForm, form, this.addForm[form]);
                    this.$set(this.addForm, "dqjd", [
                      this.addForm[form][0],
                      this.addForm[form][1],
                      this.addForm[form][1],
                    ]);
                  }
                }

                // if (
                //   this.formOption[option].coordinateType ==
                //   "latitude_and_longitude"
                // ) {
                //   //经纬度
                //   this.addForm[option] = this.addForm[option].toString();
                // }
              }
            }
          });
        });
      } catch (obj) {
        this.$set(this.addForm, obj["key"], obj["value"]);
      }
      // this.addForm = JSON.parse(JSON.stringify(this.addForm));
    },
    uploadBefore(file, done, loading) {
      // if (file.type == this.currentUploadObj.accept) {
      //   done();
      // } else {
      //   this.$message.warning("请选择指定格式的文件");
      //   loading();
      // }
    },
    uploadError(error, column) {
      this.$message.error("上传失败");
      console.log(error, column);
    },
    addFormSubmit() {
      this.$refs.addForm.submit();
    },
    clearAddForm() {
      this.$refs.addForm.resetForm();
      this.$refs.addForm.submit();
    },
    handleClose() {
      console.log(this.mapqyghValue);
      this.addForm[this.mapqygh] = this.mapqyghValue;
      this.mapVisible = false;
    },
    formatOptions() {
      this.formOption = {};
      this.newOption.column.forEach((e) => {
        if (e.type == "cascader") {
          console.log(e);
          e.checkStrictly = true;
          e.lazy = true;
          e.lazyLoad = (node, resolve) => {
            console.log("开始～～～");
            let stop_level = 2;
            let level = node.level;
            let data = node.data || {};
            let code = data.code;
            let list = [];
            let callback = () => {
              resolve(
                (list || []).map((ele) => {
                  return Object.assign(ele, {
                    leaf: level >= stop_level,
                  });
                })
              );
            };
            if (level == 0) {
              axios
                .get(`/api/jida-system/region/lazy-tree?parentCode=00`)
                .then((res) => {
                  list = res.data.data;
                  callback();
                });
            } else if (level == 1) {
              axios
                .get(
                  `/api/jida-system/region/lazy-tree?parentCode=` +
                    node.data.value
                )
                .then((res) => {
                  list = res.data.data;
                  callback();
                });
            } else if (level == 2) {
              axios
                .get(
                  `/api/jida-system/region/lazy-tree?parentCode=` +
                    node.data.value
                )
                .then((res) => {
                  list = res.data.data;
                  callback();
                });
            } else {
              callback();
            }
          };
        }
        if (e.type == "map") {
          if (e.mapType == "quhf") {
            this.mapqygh = e.prop;
            this.mapqyghValue = this.addForm[this.mapqygh];
            //判断画区域
            //console.log(this.formOption[option]);
            //判断画鱼去
            // e.type = "input";
            // e.focus = () => {
            //   // this.$message.success("查看控制台");
            //   console.log("获取焦点");
            // };
          } else {
            this.formOption[e.prop] = e;
          }
        }
        if (e.type == "upload") {
          this.currentUploadObj = e;
          // e.propsHttp = {
          //   res: "data",
          //   url: "link",
          //   name: "attachId",
          // };
          // delete e.rules;
        }
        if (e.valueDefault) {
          e.display = false;
        }
        e.readonly = this.formDisable;
        //console.log(e.type);
        // if (e.type == "input") {
        //   console.log(e);
        //   e.rules = [
        //     {
        //       required: e.isNotNull,
        //       message: "请输入" + e.description,
        //       trigger: "blur",
        //     },
        //     {
        //       max: e.columnLength,
        //       message: "长度不能超过" + e.columnLength + "个字符",
        //       trigger: "blur",
        //     },
        //   ];
        // }
      });
      // console.log("this.formDisable", this.formDisable);
      // this.newOption.disabled = this.formDisable;
      // this.newOption.readonly = this.formDisable;
      this.newOption.submitBtn = false;
      this.newOption.emptyBtn = false;
      this.newOption.labelSuffix = " ";
    },
    getDetailByPlanId() {
      this.cardLoading = true;
      getDetailByPlanId({ id: this.modelId }).then((res) => {
        if (res.data.code == 200) {
          let option = JSON.parse(res.data.data.content);
          this.newOption = option.newOption;
          this.guid = option.guid;
          this.pk = option.pk;
          let t = this;
          this.newOption.column.forEach((e) => {
            if (e.type == "cascader") {
              e.checkStrictly = true;
              e.lazyLoad = (node, resolve) => {
                let stop_level = 2;
                let level = node.level;
                let data = node.data || {};
                let code = data.code;
                let list = [];
                let callback = () => {
                  resolve(
                    (list || []).map((ele) => {
                      return Object.assign(ele, {
                        leaf: level >= stop_level,
                      });
                    })
                  );
                };
                if (level == 0) {
                  axios
                    .get(`/api/jida-system/region/lazy-tree?parentCode=00`)
                    .then((res) => {
                      list = res.data.data;
                      callback();
                    });
                } else if (level == 1) {
                  axios
                    .get(
                      `/api/jida-system/region/lazy-tree?parentCode=` +
                        node.data.value
                    )
                    .then((res) => {
                      list = res.data.data;
                      callback();
                    });
                } else if (level == 2) {
                  axios
                    .get(
                      `/api/jida-system/region/lazy-tree?parentCode=` +
                        node.data.value
                    )
                    .then((res) => {
                      list = res.data.data;
                      callback();
                    });
                } else {
                  callback();
                }
              };
            }

            if (e.type == "upload") {
              console.log("上传");
              this.currentUploadObj = e;
              delete e.rules;
            }
          });
          this.newOption.submitBtn = false;
          this.newOption.emptyBtn = false;
          this.cardLoading = false;
        }
      });
    },
    addObj() {
      let backfills = [];
      this.cascaderList = [];
      this.newOption.column.forEach((e) => {
        if (e.backfill) {
          backfills.push(e);
        }
        if (e.type == "upload") {
          let str = this.addForm[e.prop];

          if (
            typeof str == "object" &&
            str.constructor == Array &&
            this.addForm[e.prop].length > 0
          ) {
            let s = "";
            this.addForm[e.prop].forEach((e) => {
              s = s + e.label + ",";
            });
            this.addForm[e.prop] = s.substr(0, s.length - 1);
          } else {
            this.addForm[e.prop] = "";
          }
        }
        if (e.type == "map") {
          if (e.coordinateType == "longitude") {
            //经度
            this.addForm[e.prop] = this.addForm[e.prop][0];
          } else if (e.coordinateType == "latitude" && this.addForm[e.prop]) {
            //纬度
            this.addForm[e.prop] = this.addForm[e.prop][1];
          } else if (e.coordinateType == "latitude_and_longitude") {
            //经纬度
            this.addForm[e.prop] = this.addForm[e.prop].toString();
          }
        }
        if (e.type == "cascader") {
          this.cascaderList.push(e);
        }
      });

      let backfillNums = backfills.length;
      if (backfills.length > 0) {
        let num = [];
        new Promise((resolve, reject) => {
          backfills.forEach(async (element, i) => {
            if (element.backfill && this.addForm[element.fields] != "") {
              //判断冗余字段
              await getMetaCommonListByDict(
                element.dicUrl,
                element.dicQuery
              ).then((res) => {
                if (res.data.code == 200) {
                  res.data.data.forEach((e) => {
                    if (e[element.fields] == this.addForm[element.fields]) {
                      this.addForm[element.backfill] = e[element.backfill];
                    }
                  });
                  num.push(i);
                  console.log(num);
                }
              });
            } else {
              num.push(i);
              //this.saveObj();
            }
            if (num.length == backfills.length) {
              resolve(this.addForm);
            }
          });
        }).then((res) => {
          this.saveObj();
        });
      } else {
        this.saveObj();
      }
    },
    eventRun() {},
    saveObj() {
      if (this.target == "target") {
        console.log("我这是打开");
        //this.guid = this.modelId;
      }
      if (this.addForm[this.pk]) {
        let obj = {};
        Object.keys(this.addForm).forEach((ele) => {
          if (!ele.includes("$") && this.pk != ele) {
            if (!this.filterList.includes(ele)) {
              obj[ele] = this.addForm[ele];
              if (this.addForm[ele] == "") {
                obj[ele] = null;
              }
              this.cascaderList.forEach((cascader) => {
                if (ele == cascader.prop) {
                  if (this.addForm[ele] instanceof Array) {
                    console.log(obj[ele]);
                    if (obj[ele]) {
                      obj[ele] = obj[ele][obj[ele].length - 1];
                    }
                  } else {
                    let arr = obj[ele].split(",");
                    obj[ele] = arr[arr.length - 1];
                  }
                }
              });
            }
          }
        });

        let o = {
          update: obj,
          primaryKeyValue: this.addForm[this.pk],
          primaryKey: this.pk,
        };
        if (this.serviceId) {
          this.eventRun(this.serviceId, obj);
        } else {
          updateMetaCommon(this.guid, o)
            .then((res) => {
              if (res.data.code == 200) {
                this.addVisible = false;
                this.addId = "";
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                if (this.target == "target") {
                  this.goBack();
                }
                this.$emit("updatedCallBack", true);
                // this.$refs.crud.refreshChange();
                //this.tableList.splice(this.currIndex, 1, this.addForm);
              }
            })
            .catch((err) => {
              if (this.target == "target") {
                this.goBack();
              }
              this.$emit("updatedCallBack", false);
            });
        }
      } else {
        let obj = {};
        Object.keys(this.addForm).forEach((ele) => {
          if (!ele.includes("$") && this.addForm[ele]) {
            // if (ele == this.mapqygh) {
            //   obj[ele] = this.addForm[ele].join(",");
            // } else {
            obj[ele] = this.addForm[ele];
            // }

            this.cascaderList.forEach((cascader) => {
              if (ele == cascader.prop) {
                if (this.addForm[ele] instanceof Array) {
                  console.log(obj[ele]);
                  if (obj[ele]) {
                    obj[ele] = obj[ele][obj[ele].length - 1];
                  }
                } else {
                  let arr = obj[ele].split(",");
                  obj[ele] = arr[arr.length - 1];
                }
              }
            });

            if (JSON.stringify(obj[ele]) == "[]") {
              obj[ele] = "";
            }
          }
        });
        this.newOption.column.forEach((e) => {
          if (e.valueDefault) {
            obj[e.prop] = e.valueDefault;
          }
        });
        if (this.serviceId) {
          this.eventRun(this.serviceId, obj);
        } else {
          saveMetaCommon(this.guid, { values: obj })
            .then((res) => {
              if (res.data.code == 200) {
                this.addVisible = false;
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                if (this.target == "target") {
                  this.goBack();
                }
                this.$emit("updatedCallBack", true);
                //this.$refs.crud.refreshChange();
                //this.tableList.unshift(res.data.data);
              }
            })
            .catch((err) => {
              if (this.target == "target") {
                this.goBack();
              }
              this.$emit("updatedCallBack", false);
            });
        }
      }
    },
    rowUpdate(row, index, done) {
      //this.addForm = { xzqhdm: "14,1402,140213" };
      getMetaCommonInfo(this.guid, {
        pk: this.pk,
        id: row[this.pk],
      }).then((res) => {
        if (res.data.code == 200) {
          let resData = JSON.parse(JSON.stringify(res.data.data));
          this.newOption.column.forEach((e) => {
            //回填照片
            if (e.type == "upload" && res.data.data[e.prop] != "") {
              res.data.data[e.prop] = [
                {
                  label: "1",
                  value: res.data.data[e.prop],
                },
              ];
            } else if (e.type == "map") {
              if (e.coordinateType == "longitude") {
                //经度
                this.newOption.column.forEach((element) => {
                  if (
                    element.type == "map" &&
                    element.coordinateType == "latitude"
                  ) {
                    res.data.data[e.prop] = [
                      resData[e.prop],
                      resData[element.prop],
                      resData[e.prop],
                    ];
                  }
                });
              } else if (e.coordinateType == "latitude") {
                //纬度
                this.newOption.column.forEach((element) => {
                  if (
                    element.type == "map" &&
                    element.coordinateType == "longitude"
                  ) {
                    res.data.data[e.prop] = [
                      resData[element.prop],
                      resData[e.prop],
                      resData[e.prop],
                    ];
                  }
                });
              } else if (e.coordinateType == "latitude_and_longitude") {
                //经纬度
                res.data.data[e.prop] = res.data.data[e.prop].split(",");
              }
            } else if (e.type == "cascader") {
              let cascader = res.data.data[e.prop];
              let xzqh = "";
              if (cascader.length == 2) {
                xzqh = cascader;
              }
              if (cascader.length == 4) {
                xzqh = cascader[0] + cascader[1] + "," + cascader;
              }
              if (cascader.length == 6) {
                xzqh =
                  cascader[0] +
                  cascader[1] +
                  "," +
                  cascader[0] +
                  cascader[1] +
                  cascader[2] +
                  cascader[3] +
                  "," +
                  cascader;
              }

              res.data.data[e.prop] = "14,1402,140213";
            }
          });
          this.target = "dialog";
          this.addForm = res.data.data;
          this.addVisible = true;
        }
        //done();
      });
    },

    goBack() {
      this.$router.push({
        path: "/data/table/tableCommon/?modleId" + this.modelId,
      });
    },
  },
};
</script>
<style scoped></style>

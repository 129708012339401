const _StyleName = [
  'color' , 'backgroundColor' , 'lineHeight' , 'width' , 'height' , 'fontSize' , 'background'
];

['padding' , 'margin' , 'border'].forEach(
  item => {
    _StyleName.push(item);
    ['Left' , 'Top' , 'Bottom' , 'Right'].forEach(
      item1 => {
        _StyleName.push(item + item1);
        if(item === 'border'){
          ['style' , 'color' , 'width'].forEach(
            borItem => {
              _StyleName.push(`${item}${item1}${nameToLocaleUpperCase(borItem)}`)
            }
          )
        }
      }
    )
  }
);

['color' , 'image' , 'position' , 'size' , 'repeat' , 'origin' , 'clip' , 'attachment' , 'position-x' , 'position-y'].forEach(
  item => {
    let name = 'background' + nameToLocaleUpperCase(item);
    _StyleName.push(name);
  }
);

function nameToLocaleUpperCase(name , notTransFirst){
  let _name = name;
  if(name.indexOf('_') > -1){
    name.split('-').forEach(
      (item , index) => {
        if(index > 0){
          _name += item.substr(0,1).toLocaleUpperCase() + item.substr(1);
        }else{
          _name += item;
        }
      }
    )
  }
  if(notTransFirst){
    return _name;
  }else{
    return _name.substr(0,1).toLocaleUpperCase() + _name.substr(1);
  }
}

export const getStyleName = (name) => {
  let _name = nameToLocaleUpperCase(name , true);
  if(_StyleName.indexOf(_name) > -1){
    return _name;
  }else{
    return null;
  }
}


export const StyleName = _StyleName;
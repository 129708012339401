<template>
    <div>
        <el-form :model="ruleForm" ref="ruleForm" label-width="100px" label-position="top" size="small" :disabled="onlyRead">
            <el-row>
                <!-- <el-col :span="24" v-if="ruleForm.parent_id" class="pararoom">
          上级房间 {{ ruleForm.parent_name || ruleForm.parent_id }} 
        </el-col> -->
                <el-col :span="24">
                    <el-form-item label="房间名称" prop="name">
                        <el-input v-model="ruleForm.name" placeholder="请选择房间名称"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="所属楼宇" prop="building_id">
                        <!-- <el-input v-model="ruleForm.building_id"></el-input> -->
                        <el-select v-model="ruleForm.building_id" size="small" @change="getFloors" :disabled="!buildRead" placeholder="请选择">
                            <el-option v-for="(item, index) in buildList" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="所属楼层" prop="property_management_fee">
                        <!-- <el-input v-model="ruleForm.floor_id"></el-input> -->
                        <el-select v-model="ruleForm.floor_id" size="small" :disabled="!buildRead" @change="onChangeSetFloor" placeholder="请选择">
                            <el-option v-for="(item, index) in floorList" :key="index" :label="item.code" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>

                <el-col :span="6">
                    <el-form-item label="房间面积(m²)" prop="room_area">
                        <el-input @input.native="setMonthPrice" v-model="ruleForm.room_area" placeholder="请选择房间面积"></el-input>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="装修情况" prop="name">
                        <el-select v-model="ruleForm.renovation" size="small" placeholder="请选择装修情况">
                            <el-option v-for="(item, index) in decorationList" :key="index" :label="item.name" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6" class="roomForm">
                    <el-form-item label="付款方式" prop="frequency">
                        <el-input placeholder="请输入内容" type="number" v-model.number="ruleForm.frequency" class="input-with-select">
                            <template slot="append">
                                <el-select v-model="ruleForm.frequency_unit" placeholder="请选择">
                                    <el-option v-for="(item, index) of frequencyUnits" :key="index" :label="item.name" :value="item.value"></el-option>
                                </el-select>
                            </template>
                        </el-input>
                    </el-form-item>
                </el-col>
                <!-- <el-col :span="6">
          <el-form-item label="付款方式" prop="property_management_fee">
            <el-select
              v-model="ruleForm.payment_type"
              size="small"
              placeholder="请选择付款方式"
            >
              <el-option
                v-for="(item, index) in paymentTypeList"
                :key="index"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
                <el-col :span="6">
                    <el-form-item label="房源类型" prop="housing_resources_type">
                        <el-select v-model="ruleForm.housing_resources_type" size="small" multiple placeholder="请选择房源类型">
                            <el-option v-for="(item, index) in housingResourcesTypes" :key="index" :label="item.name" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="房屋状态">
                        <el-select v-model="ruleForm.status" size="small" @change="$forceUpdate()" placeholder="请选择房屋状态">
                            <el-option v-for="(item, index) in roomStatuss" :key="index" :label="item.name" :value="item.value">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="12" v-if="ruleForm.status == 2">
                    <el-form-item label="买方企业" prop="buy_company_id">
                        <el-select v-model="ruleForm.buy_company_id" filterable @change="changeBuyCompany" placeholder="请选择买方企业">
                            <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="是否拥有产权">
                        <el-radio-group v-model="ruleForm.has_ownership" @change="$forceUpdate()">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="是否虚拟房间">
                        <el-radio-group v-model="ruleForm.is_invented" @change="$forceUpdate()">
                            <el-radio :label="1">是</el-radio>
                            <el-radio :label="0">否</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="6">
                    <el-form-item label="出租单价/日" prop="rental_unit_price_day">
                        <el-input type="number" @input.native="setMonthPrice" v-model.number="ruleForm.rental_unit_price_day" placeholder="出租单价">
                            <template slot="append">元/m²/天</template></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="6">
                    <el-form-item label="出租单价/月" prop="rental_unit_price_month">
                        <el-input v-model="ruleForm.rental_unit_price_month">
                            <template slot="append">元/月</template>
                        </el-input>
                    </el-form-item>
                </el-col>
            </el-row>

            <el-row>
                <el-col :span="24" class="h-100" v-if="ruleForm.label">
                    <el-form-item label="房间标签" prop="label" v-if="!onlyRead">
                        <el-popover placement="top-start" title="添加标签" width="300" trigger="click">
                            <el-button slot="reference" icon="el-icon-plus"></el-button>
                            <div style="height: 74px" v-if="ruleForm.label">
                                <template v-for="(tag, i) in roomTags">
                                    <el-tag class="cur-p mar-r-5 mar-b-5" v-if="ruleForm.label.indexOf(tag.id) < 0" :key="i" :disable-transitions="false" @click="onClickAddRoomTags(tag)">
                                        {{ tag.name }}
                                    </el-tag>
                                </template>
                            </div>
                        </el-popover>
                        <template v-if="ruleForm.label">
                            <el-tag v-for="(tag, index) in ruleForm.label" :key="index" closable :disable-transitions="false" class="mar-l-5 mar-b-5" @close="ruleForm.label.splice(index, 1)">
                                {{ getTagName(tag) }}
                            </el-tag>
                        </template>
                    </el-form-item>
                    <el-form-item label="房间标签" prop="label" v-if="onlyRead">
                        <el-tag :key="index" v-for="(tag, index) in ruleForm.label" :disable-transitions="false" class="mar-l-5 mar-b-5" @close="ruleForm.label.splice(index, 1)">
                            {{ getTagName(tag) }}
                        </el-tag>
                        <div class="pad-10" v-if="ruleForm.label.length == 0">暂无标签</div>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-divider content-position="left">房间图片</el-divider>
            <avue-form :option="option" v-model="imgform"></avue-form>
            <template v-if="onlyRead">
                <div class="pad-10 pad-b-20" v-if="imgform.imgUrl.length == 0">
                    暂无照片
                </div>
            </template>
            <!-- <sonRoomList :pId="ruleForm.id"></sonRoomList> -->
            <el-divider v-if="contractList.length > 0" content-position="left">合同情况</el-divider>
            <el-table v-if="contractList.length > 0" :data="contractList" border class="mar-b-15" style="width: 100%">
                <el-table-column prop="contract_code" label="合同编码"></el-table-column>

                <el-table-column prop="renters_name" label="公司名称"></el-table-column>

                <el-table-column width="80" prop="execute_status" label="执行状态"></el-table-column>

                <el-table-column width="80" prop="contract_status" label="合同状态"></el-table-column>

                <el-table-column prop="start_date" label="合同开始时间"></el-table-column>

                <el-table-column prop="end_date" label="合同结束时间"></el-table-column>
            </el-table>
            <!-- <template v-if="ruleForm.parent_id">
        上级房间 {{ ruleForm.parent_name || ruleForm.parent_id }}
      </template> -->

            <el-divider content-position="left">户型图</el-divider>
            <template v-if="onlyRead">
                <div class="pad-10 pad-b-20" v-if="imgform1.imgUrl.length == 0">
                    暂无照片
                </div>
            </template>
            <avue-form :option="option1" v-model="imgform1"></avue-form>
        </el-form>
    </div>
</template>

<script>
import { getMetaCommonList, getPicListByIds, getMetaCommonInfo } from '@/api/busiMode/metaCommon';
import * as dictbizService from '@/api/system/dictbiz';
export default {
    components: {},
    data() {
        return {
            ruleForm: {
                label: [],
                status: 0,
                has_ownership: 1,
                is_invented: 0,
                floor_id: '',
            },
            roomTags: [],
            dialogImageUrl: '',
            page: {
                size: 1000,
                current: 1,
                total: 0,
            },
            buildList: [],
            decorationList: [],
            paymentTypeList: [],
            housingResourcesTypes: [],
            frequencyUnits: [],
            roomTypes: [],
            floorList: [],
            roomStatuss: [],
            ContractStatuss: [],
            executeStatusList: [],
            option1: {
                labelWidth: 0,
                submitBtn: false,
                emptyBtn: false,
                disabled: false,
                column: [
                    {
                        label: '',
                        prop: 'imgUrl',
                        type: 'upload',
                        listType: 'picture-card',
                        loadText: '附件上传中，请稍等',
                        span: 24,
                        // disabled: true,
                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '只能上传jpg/png文件，且不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },
                ],
            },
            option: {
                labelWidth: 0,
                submitBtn: false,
                emptyBtn: false,
                disabled: false,
                column: [
                    {
                        label: '',
                        prop: 'imgUrl',
                        type: 'upload',
                        listType: 'picture-card',
                        loadText: '附件上传中，请稍等',
                        span: 24,
                        // disabled: true,
                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '只能上传jpg/png文件，且不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },
                ],
            },
            imgform: {
                imgUrl: [],
            },
            imgform1: {
                imgUrl: [],
            },
            model: 'wisdom_park.wp_room',
            views: 'wisdom_park.v_wp_room_contract_stat',
            contractList: {},
            companyList: [],
            entModel: 'wisdom_park.wp_company',
        };
    },
    props: {
        floors: {
            type: Array,
            default: function () {
                return [];
            },
        },
        ruleFormObj: {
            type: Object,
            default: function () {
                return { label: '', has_ownership: 1, status: 0, is_invented: 0 };
            },
        },
        onlyRead: {
            type: Boolean,
            default: function () {
                return false;
            },
        },
        buildRead: {
            type: Boolean,
            default: function () {
                return false;
            },
        },
    },
    watch: {
        ruleFormObj: {
            handler: function (newValue, oldName) {
                if (newValue) {
                    if (this.ruleFormObj.room_id) {
                        this.getMetaCommonInfo(this.ruleFormObj.room_id);
                    } else {
                        if (newValue.label) {
                            if (
                                typeof newValue.label == 'string' &&
                                newValue.label.constructor == String &&
                                newValue.label != ''
                            ) {
                                newValue.label = newValue.label.split(',');
                            }
                        }
                        if (newValue.housing_resources_type) {
                            if (
                                typeof newValue.housing_resources_type == 'string' &&
                                newValue.housing_resources_type.constructor == String &&
                                newValue.housing_resources_type != ''
                            ) {
                                newValue.housing_resources_type = newValue.housing_resources_type.split(',');
                            }
                        }

                        newValue.status = newValue.status ? newValue.status : 0;
                        this.ruleForm = newValue;

                        if (this.ruleForm.attachment) {
                            this.getpicList(this.ruleForm.attachment, 'imgform');
                        }
                        if (this.ruleForm.layout_attachment) {
                            this.getpicList(this.ruleForm.layout_attachment, 'imgfor1');
                        }
                    }
                }
            },
            deep: true,
        },
        onlyRead: {
            handler: function (newValue, oldName) {
                if (newValue) {
                    this.option.disabled = true;
                    this.option1.disabled = true;
                } else {
                    this.option.disabled = false;
                    this.option1.disabled = false;
                }
                this.option = JSON.parse(JSON.stringify(this.option));
                this.option1 = JSON.parse(JSON.stringify(this.option1));
            },
            deep: true,
        },
        floors: {
            handler: function (newValue, oldName) {
                if (newValue) {
                    this.floorList = this.floors;
                }
            },
            deep: true,
        },
    },
    computed: {},
    mounted() {
        if (this.ruleFormObj) {
            if (this.ruleFormObj.room_id) {
                this.getMetaCommonInfo(this.ruleFormObj.room_id);
            } else {
                if (
                    typeof this.ruleFormObj.label == 'string' &&
                    this.ruleFormObj.label.constructor == String &&
                    this.ruleFormObj.label != ''
                ) {
                    this.ruleFormObj.label = this.ruleFormObj.label.split(',');
                }
                if (this.ruleFormObj.housing_resources_type) {
                    if (
                        typeof this.ruleFormObj.housing_resources_type == 'string' &&
                        this.ruleFormObj.housing_resources_type.constructor == String &&
                        this.ruleFormObj.housing_resources_type != ''
                    ) {
                        this.ruleFormObj.housing_resources_type = this.ruleFormObj.housing_resources_type.split(',');
                    }
                }
                this.ruleForm = this.ruleFormObj;
                if (this.ruleForm.attachment) {
                    this.getpicList(this.ruleForm.attachment, 'imgform');
                }
                if (this.ruleForm.layout_attachment) {
                    this.getpicList(this.ruleForm.layout_attachment, 'imgfor1');
                }
            }
        }
        this.floorList = this.floors;
        if (this.onlyRead) {
            this.option.disabled = true;
            this.option1.disabled = true;
        } else {
            this.option.disabled = false;
            this.option1.disabled = false;
        }
        this.option = JSON.parse(JSON.stringify(this.option));
        this.option1 = JSON.parse(JSON.stringify(this.option1));
    },

    created() {
        this.getBuildList();
        this.getDicList();
        this.getMetaCommonList();
    },
    methods: {
        changeRoom() {
            this.$forceUpdate();
        },
        changeBuyCompany() {
            let name = '';
            this.companyList.forEach(c => {
                if (c.id == this.ruleForm.buy_company_id) {
                    name = c.name;
                }
            });

            this.ruleForm.buy_company_name = name;
            console.log(JSON.stringify(this.ruleForm));
        },
        getMetaCommonList() {
            let body = {
                current: 1,
                size: 1000,
            };
            // 状态（0:无效；1.已入驻；2.入驻中；3.退出中；4.已退出）
            getMetaCommonList(this.entModel, body).then(res => {
                const data = res.data.data;
                this.companyList = data.records;
            });
        },
        onChangeSetFloor(val) {
            this.$forceUpdate();
        },
        onClickAddRoomTags(tag) {
            if (typeof this.ruleForm.label === 'string') {
                this.ruleForm.label = this.ruleForm.label.split(',');
            }
            this.ruleForm.label.push(tag.id);
        },
        forceUpdate(e) {
            console.log(e);
            this.$forceUpdate();
        },
        getFloors() {
            this.ruleForm.floor_id = '';
            let obj = {
                size: 1000,
                current: 1,
                orders: [{ field: 'code+1000', sort: 'DESC' }],
            };
            obj.wheres = [{ field: 'building_id', value: this.ruleForm.building_id }];
            getMetaCommonList('wisdom_park.wp_floor', obj).then(res => {
                const data = res.data.data;
                this.ruleForm.floor_id = data.records[0].id;
                this.floorList = data.records;
            });
        },
        getRoomContract(o) {
            let obj = {
                size: 1000,
                current: 1,
                wheres: [{ field: 'room_id', value: o.id }],
            };
            getMetaCommonList(this.views, obj).then(res => {
                if (res.data.code == 200) {
                    console.log('res.data.data');
                    console.log(res.data.data);
                    // ContractStatuss
                    res.data.data.records.forEach(r => {
                        this.ContractStatuss.forEach(c => {
                            if (r.contract_status == c.value) {
                                r.contract_status = c.name;
                            }
                        });
                        this.executeStatusList.forEach(c => {
                            if (r.execute_status == c.value) {
                                r.execute_status = c.name;
                            }
                        });
                    });

                    this.contractList = res.data.data.records;
                }
            });
        },
        getMetaCommonInfo(id) {
            let obj = {
                pk: 'id',
                id: id,
            };
            getMetaCommonInfo(this.model, obj).then(res => {
                if (res.data.code == 200) {
                    let newValue = res.data.data;
                    if (newValue.label) {
                        if (
                            typeof newValue.label == 'string' &&
                            newValue.label.constructor == String &&
                            newValue.label != ''
                        ) {
                            newValue.label = newValue.label.split(',');
                        }
                    }
                    if (newValue.housing_resources_type) {
                        if (
                            typeof newValue.housing_resources_type == 'string' &&
                            newValue.housing_resources_type.constructor == String &&
                            newValue.housing_resources_type != ''
                        ) {
                            newValue.housing_resources_type = newValue.housing_resources_type.split(',');
                        }
                    }
                    this.ruleForm = res.data.data;
                    if (this.ruleForm.attachment) {
                        this.getpicList(this.ruleForm.attachment, 'imgform');
                    }
                    if (this.ruleForm.layout_attachment) {
                        this.getpicList(this.ruleForm.layout_attachment, 'imgfor1');
                    }
                    this.ruleForm = JSON.parse(JSON.String(this.ruleForm));
                    console.log(this.ruleForm, 'this.ruleForm');
                    this.getRoomContract(res.data.data);
                }
            });
        },
        setMonthPrice() {
            if (this.ruleForm.room_area != '' && this.ruleForm.rental_unit_price_day != '') {
                let m = Number(this.ruleForm.room_area) * Number(this.ruleForm.rental_unit_price_day);
                this.ruleForm.rental_unit_price_month = m ? (m * 30).toFixed(2) : 0;
            } else {
                this.ruleForm.rental_unit_price_month = 0;
            }
            // this.set(this.ruleForm, "rental_unit_price_month", val);
            this.ruleForm = JSON.parse(JSON.stringify(this.ruleForm));
        },
        getpicList(ids, d) {
            getPicListByIds(ids).then(res => {
                if (res.data.code == 200) {
                    let arr = [];
                    res.data.data.forEach(pic => {
                        arr.push({
                            label: pic.id,
                            value: pic.link,
                        });
                    });
                    if (d == 'imgform') {
                        this.imgform.imgUrl = arr;
                    } else {
                        this.imgform1.imgUrl = arr;
                    }
                }
            });
        },
        getTagName(tag) {
            let tagName = '';
            this.roomTags.forEach(item => {
                if (item.id === tag) {
                    tagName = item['name'];
                }
            });
            return tagName;
        },
        getDicList() {
            dictbizService
                .getDictionary({
                    code: 'RoomStatus',
                })
                .then(res => {
                    this.roomStatuss = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: Number(item['dictKey']),
                            id: item['id'],
                        };
                    });
                });
            dictbizService
                .getDictionary({
                    code: 'ContractStatus',
                })
                .then(res => {
                    this.ContractStatuss = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                            id: item['id'],
                        };
                    });
                });
            dictbizService
                .getDictionary({
                    code: 'ContractExecuteStatus',
                })
                .then(res => {
                    this.executeStatusList = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                            id: item['id'],
                        };
                    });
                });

            dictbizService
                .getDictionary({
                    code: 'RoomBrightenedDot',
                })
                .then(res => {
                    this.roomTags = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                            id: item['id'],
                        };
                    });
                });
            dictbizService
                .getDictionary({
                    code: 'roomType',
                })
                .then(res => {
                    this.roomTypes = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                            id: item['id'],
                        };
                    });
                });

            dictbizService
                .getDictionary({
                    code: 'decoration',
                })
                .then(res => {
                    this.decorationList = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
            dictbizService
                .getDictionary({
                    code: 'paymentType',
                })
                .then(res => {
                    this.paymentTypeList = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });

            dictbizService
                .getDictionary({
                    code: 'housingResourcesType',
                })
                .then(res => {
                    this.housingResourcesTypes = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });

            dictbizService
                .getDictionary({
                    code: 'FREQUENCY_UNIT',
                })
                .then(res => {
                    this.frequencyUnits = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
        },

        handleRemove(file, fileList) {
            console.log(file, fileList);
        },

        getBuildList() {
            getMetaCommonList('wisdom_park.wp_building', this.page).then(res => {
                const data = res.data.data;
                this.buildList = data.records;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.pararoom {
    font-weight: 600;
    color: #252b50;
    /* font-size: 18px; */
    margin-bottom: 10px;
    margin-top: -10px;
}
/deep/ .el-divider--horizontal {
    margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
    left: 0px;
}
/deep/ .el-divider__text {
    padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
    width: 68px;
    height: 68px;
    line-height: 72px;
    vertical-align: top;
}
/deep/ .el-select {
    width: 100%;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
    width: 68px;
    height: 68px;
}
.roomForm {
    .input-with-select {
        /deep/.el-input {
            width: 80px;
        }
    }
}
</style>

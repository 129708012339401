<template>
    <el-row>
        <el-col class="w-175 pad-0 newTree" :span="5">
            <div class="box ov-a">
                <el-scrollbar>
                    <basic-container isCard clsss="pad-0" :style="{ height: viewPortHeight - 120 + 'px' }">
                        <div class="tabsCom pad-t-10">
                            <span class="pad-l-15">场馆类型</span>
                            <avue-tabs :option="option" @change="handleChange"></avue-tabs>
                        </div>
                    </basic-container>
                </el-scrollbar>
            </div>
        </el-col>
        <el-col class="w-calc-175" :span="5">
            <div class="dateCom mar-b-8 mar-l-10">
                <span>预约时间：</span>
                <el-date-picker v-model="queryDate" type="datetime" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="handelChangeDate()" size="small" :start-placeholder="startDate"
                                :end-placeholder="endDate" placeholder="选择日期">
                </el-date-picker>
            </div>
            <div class="ov-a" :style="{ height: viewPortHeight - 160 + 'px' }">
                <el-row class="contentCom h-100p ov-a pad-0" style="margin-left: 0; margin-right: 0">
                    <el-col :span="showCheckedDetail ? 18 : 24" class="navItemClass pad-r-0">
                        <div v-for="(i, key) in navList" :key="key">
                            <el-card class="box-card" :class="{ 'mar-t-15': key > 0 }">
                                <div slot="header" class="clearfix">
                                    <span>{{ i.name }}</span>
                                </div>
                                <div v-if="tabsType.floorName != '-1'">
                                    <!-- <div class="titleItem">预约时段</div> -->
                                    <div v-for="(item, index) in i.timeDetail" :key="index" class="timeCom">
                                        <span class="timeName">{{ item.name }}</span>
                                        <div>
                                            <span class="timeItem" v-for="itemCh in item.detail" :key="itemCh.id" :class="{
                          disTime: itemCh.status === 3,
                          noDisTime: itemCh.status === 0,
                          timeItemChecked: itemCh.checked,
                          weiTime: itemCh.status === 7,
                          xinTime: itemCh.status === 1,
                            }" @click="
                              itemCh.status >= 1
                                ? checkAppted(itemCh)
                                : checkUnAppted(itemCh,i)
                            ">
                                                <span class="time">{{ itemCh.time }}</span>
                                                <span class="status" v-if="itemCh.status === 1">待支付</span>
                                                <span class="status" v-if="itemCh.status === 3">已预约</span>
                                                <span class="status" v-if="itemCh.status === 0">可预约</span>
                                                <span class="status" v-if="itemCh.status === 7">已预留</span>
                                            </span>
                                        </div>
                                    </div>
                                </div>

                                <div class="apptbtnhx" v-if="tabsType.floorName == '-1'">
                                    <button class="atbtn" @click="gotoMakeAppt(i, 'hx')">
                                        核销
                                    </button>
                                </div>
                                <div class="apptbtn" v-else>
                                    <button class="atbtn-bor pull-right" @click="gotoPreoAppt(i)">
                                        预留
                                    </button>
                                    <button class="atbtn pull-right" @click="gotoMakeAppt(i)">
                                        预约
                                    </button>
                                </div>
                            </el-card>
                        </div>
                    </el-col>

                    <el-col :span="6" class="itemDetailClass" v-show="showCheckedDetail">
                        <basic-container class="cards pad-15" :style="{ height: viewPortHeight - 110 + 'px' }">
                            <div class="titleri mar-b-15" v-if="permission.sportapptnew_cancelAppt">
                                <div class="titleItem">预约信息</div>

                                <button v-if="apptInfo != null && (apptInfo.status == 3|| apptInfo.status == 1)" class="atbtn-bor-pos" @click="cancelAppt(apptInfo)">
                                    取消预约
                                </button>
                                <div class="btn" v-if="apptInfo != null && (apptInfo.status == 7|| apptInfo.status == 1)" @click="cancelPreoAppt(apptInfo)">
                                    取消预留
                                </div>
                            </div>
                            <div class="con">
                                <span class="label">场馆</span>
                                <span v-if="apptInfo != null">{{ apptInfo.stadiumName }}</span>
                            </div>
                            <div class="con">
                                <span class="label">场地</span>
                                <span v-if="apptInfo != null">{{ apptInfo.groundName }}</span>
                            </div>
                            <div class="con">
                                <span class="label">开始时段</span>
                                <span v-if="apptInfo != null">{{
                  apptInfo.beginTime.substring(0, 16)
                }}</span>
                            </div>
                            <div class="con">
                                <span class="label">结束时段</span>
                                <span v-if="apptInfo != null">{{
                  apptInfo.endTime.substring(0, 16)
                }}</span>
                            </div>
                            <div class="con">
                                <span class="label">下单时间</span>
                                <span v-if="apptInfo != null">{{
                  apptInfo.apptTime.substring(0, 16)
                }}</span>
                            </div>

                            <div class="con">
                                <span class="label">价格</span>
                                <span v-if="apptInfo != null">
                                    <span v-if="apptInfo.totalPrice != '-1'">{{
                    apptInfo.totalPrice
                  }}</span>
                                </span>
                            </div>
                            <div class="con">
                                <span class="label">状态</span>
                                <span v-if="apptInfo != null && apptInfo.status == 1">新建</span>
                                <span v-if="apptInfo != null && apptInfo.status == 3">已支付</span>
                                <span v-if="apptInfo != null && apptInfo.status == 7">场地预留</span>
                            </div>
                            <div class="con">
                                <span class="label">预约人</span>
                                <span v-if="apptInfo != null">{{ apptInfo.phoneName }}</span>
                            </div>
                            <div class="con">
                                <span class="label">预约电话</span>
                                <span v-if="apptInfo != null">{{ apptInfo.contactPhone }}</span>
                            </div>
                            <div class="con">
                                <span class="label">备注</span>
                                <span class="hh" v-if="apptInfo != null">{{
                  apptInfo.extraMemo
                }}</span>
                            </div>
                        </basic-container>
                    </el-col>
                </el-row>
            </div>
        </el-col>
        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" v-if="dialogVisible" width="70%" append-to-body @close="dialogCancel">
            <div class="mar-b-15">
                <div class="w-85 text-right" style="float: left">预约时间:</div>
                <div class="mar-l-95">
                    {{ queryDate }}
                    <span v-if="unApptedList.length > 0">{{
            unApptedList.toString()
          }}</span>
                </div>
            </div>

            <avue-form :option="formPriceOption" :defaults.sync="defaults" @submit="confirmSubmit" v-model="formPrice">
                <template slot-scope="{ size }" slot="menuForm">
                    <el-button icon="el-icon-close" :size="size" @click="shut">关闭</el-button>
                    <!--<el-button type="primary" icon="el-icon-search" :size="size" @click="searchPhone">搜索</el-button>-->
                </template>
            </avue-form>
        </el-dialog>
        <el-dialog title="场地预留" :visible.sync="tjdialogVisible" width="30%">
            <el-form :inline="true" label-width="50px" class="wid-100">
                <el-form-item label="备注">
                    <el-input class="w-300" type="textarea" v-model="ylDesc" rows="3"></el-input>
                </el-form-item>
            </el-form>
            <div class="btn-footer pad-t-0">
                <el-button size="small" class="pull-right" @click="closeTjdialogVisible()">取 消</el-button>
                <el-button type="primary" size="small" class="pull-right mar-r-10" @click="sure()">确 定</el-button>
            </div>
        </el-dialog>
    </el-row>
</template>

<script>
import * as dictbizService from '@/api/system/dictbiz';
import { allStatisTypes } from '@/api/spcod/sporttype';
import { listGround } from '@/api/spcod/sportground';
import { getAccDetail, getUser, getCardType } from '@/api/spcod/spcodaccount';
import dayjs from 'dayjs';
import { mapGetters, mapState } from 'vuex';

import {
    getDetail,
    submitPreAppoint,
    cancelPreAppoint,
    addAppoint,
    mobAccCardPay,
    mobAccCardCancel,
    listGroundAppoint,
    calAppointPrice,
    paymentDiscount,
    getRandomId,
    reverseDiscount,
    queryOrderDetail,
} from '@/api/spcod/sportappoint';
import { getPage, getResCardInfo } from '@/api/spcod/spcodaccountcard';
const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
export default {
    data() {
        // 正则表达式 验证手机号
        const validateMobile = (rule, value, callback) => {
            if (!/^[1]([3-9])[0-9]{9}$/.test(value)) {
                callback(new Error('请输入正确的手机号格式'));
            } else {
                callback();
            }
        };
        return {
            viewPortHeight: viewPortHeight,
            TIME_TYPE_DATA: [
                {
                    label: '普通卡',
                    value: 0,
                },
                {
                    label: '年卡',
                    value: 1,
                },
                {
                    label: '半年卡',
                    value: 2,
                },
                {
                    label: '季卡',
                    value: 3,
                },
            ],
            defaults: {},
            apptTimeUnit: 30, //30分钟
            apptCancelMin: 30, //默认30分钟
            preApptAHeadInDay: 14, //默认14天
            dialogTitle: '预约',
            dialogVisible: false,
            tjdialogVisible: false,
            cardList: [],
            changeReserveType: '会员',
            vipDisperseDisplay: true, // 会员表单项
            disperseDisplay: false, // 散户表单项
            formPrice: {
                reserveType: true,
            },
            ylDesc: '',
            formPriceOption: {
                emptyBtn: false,
                column: [
                    {
                        label: '场地',
                        prop: 'groundNmae',
                        disabled: true,
                    },
                    {
                        label: '价格(元)',
                        disabled: true,
                        prop: 'totalPrice',
                        rules: [
                            {
                                required: true,
                                message: '价格',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '实付价格',
                        prop: 'realTotalPrice',
                        rules: [
                            {
                                required: true,
                                message: '价格',
                                trigger: 'blur',
                            },
                        ],
                        change: ({ value, column, item, dic }) => {
                            if (column.appType != '散客') {
                                this.calAppointPriceByPrice(value);
                            }
                        },
                    },
                    // {
                    //   label: "次数",
                    //   prop: "totalPrice",
                    //   disabled: true,
                    //   display: false,
                    //   rules: [
                    //     {
                    //       required: true,
                    //       message: "次数",
                    //       trigger: "blur",
                    //     },
                    //   ],
                    // },
                    {
                        label: '预定类型',
                        prop: 'appType',
                        type: 'select',
                        dicData: [
                            {
                                label: '会员',
                                value: '会员',
                            },
                            {
                                label: '散客',
                                value: '散客',
                            },
                        ],
                        // value: true,
                        change: ({ value, column, item, dic }) => {
                            //this.calAppointPrice(value);
                            this.onClickChange(value);
                        },
                        rules: [
                            {
                                required: true,
                                message: '必须选择预定类型',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '会员电话',
                        display: true,
                        prop: 'contactPhone',
                        disabled: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入会员手机号',
                                trigger: 'blur',
                            },
                            {
                                trigger: 'blur',
                                validator: validateMobile,
                            },
                        ],
                        blur: ({ value, column }) => {
                            let _this = this;
                            if (value == undefined || value == '') return;
                            if (value.length < 11) {
                                this.$message.error('电话号码太短!');
                                return;
                            }
                            _this.searchPhone();
                        },
                    },
                    {
                        label: '会员卡',
                        display: true,
                        prop: 'accards',
                        type: 'select',
                        dicData: [],
                        rules: [
                            {
                                required: true,
                                message: '必须选择会员卡',
                                trigger: 'blur',
                            },
                        ],
                        change: ({ value, column, item, dic }) => {
                            this.calAppointPrice(value);
                        },
                    },

                    {
                        label: '会员称呼',
                        display: true,
                        prop: 'phoneName',
                        disabled: true,
                    },
                    {
                        label: '预约人姓名',
                        prop: 'testName',
                        display: false,
                    },
                    {
                        label: '预约人电话',
                        prop: 'testPhone',
                        display: false,
                    },
                    {
                        label: '备注',
                        prop: 'extraMemo',
                        span: 24,
                        minRows: 2,
                        type: 'textarea',
                        rules: [
                            {
                                required: false,
                                message: '请输入订购注意事项',
                                trigger: 'blur',
                            },
                        ],
                    },
                ],
            },
            currentGround: {},
            currentNav: 0,
            queryDate: null,
            endDate: null,
            tabsType: {},
            apptInfo: null,
            tempTimeSection: '2023年6月1日 9:00～10:00',
            option: {
                position: 'left',
                column: [
                    {
                        label: '',
                        prop: '',
                    },
                ],
            },
            unAppted: [],
            unApptedList: [],
            navList: [],
            timeDetail: [],
            showCheckedDetail: false,
            checkedItemDetail: {},
            isYYG: false,
            currentSporId: '',
            oldPrice: '',
        };
    },
    watch: {
        changeReserveType: {
            handler(val, oldName) {
                if (JSON.stringify(this.defaults) != '{}') {
                    if (val === '会员') {
                        this.defaults.contactPhone.display = true;
                        this.defaults.accards.display = true;
                        this.defaults.phoneName.display = true;

                        this.defaults.testName.display = false;
                        this.defaults.testPhone.display = false;
                    } else {
                        this.defaults.testName.display = true;
                        this.defaults.testPhone.display = true;
                        this.defaults.contactPhone.display = false;
                        this.defaults.accards.display = false;
                        this.defaults.phoneName.display = false;
                        this.formPrice.accards = '';
                    }
                }
            },
            immediate: true,
            deep: true,
        },
    },
    created() {
        let _this = this;
        this.initTabsData();
        let date = new Date();
        var Y = date.getFullYear();
        var M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
        var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        this.queryDate = Y + '-' + M + '-' + D;
        this.startDate = Y + '-' + M + '-' + D;

        date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 13);
        Y = date.getFullYear();
        M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
        D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        this.endDate = Y + '-' + M + '-' + D;
        // 获取时间配置
        dictbizService
            .getDictionary({
                code: 'ApptCancelAheadTimeInMin',
            })
            .then(res => {
                let dlist = res.data.data;
                dlist.forEach(d => {
                    if (d.dictValue == 'SportAppt') {
                        _this.apptCancelMin = parseInt(d.dictKey);
                    } else if (d.dictValue == 'PreApptAHeadInDay') {
                        _this.preApptAHeadInDay = parseInt(d.dictKey);
                    }
                });
            });
        this.getCardType();
    },
    computed: {
        ...mapGetters(['permission']),
    },
    methods: {
        getCardType() {
            getCardType(1).then(res => {
                res.data.data.forEach(e => {
                    if (e.key) {
                        e.key = Number(e.key);
                    }
                });
                this.TIME_TYPE_DATA = res.data.data;
            });
        },
        onClickChange(val) {
            this.changeReserveType = val;
            this.formPrice.accards = '';
            console.log(this.oldPrice, val);
            if (val == '散客') {
                console.log(this.oldPrice);
                this.formPrice.realTotalPrice = this.oldPrice;
                this.formPrice.totalPrice = this.oldPrice;
            }
        },
        cancelAppt(apptInfo) {
            let _this = this;
            let bt = new Date(_this.apptInfo.beginTime).getTime() / 1000 / 60 - _this.apptCancelMin;
            let ct = new Date().getTime() / 1000 / 60;
            _this
                .$confirm('确定取消场地预约?', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                .then(() => {
                    mobAccCardCancel(apptInfo.id, 'SPT').then(res2 => {
                        this.$message({
                            type: 'success',
                            message: res2.data.msg,
                        });
                        //刷新
                        this.initNavList();
                    });
                });
        },
        cancelPreoAppt(apptInfo) {
            let _this = this;
            _this
                .$confirm('确定取消场地预留?', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                .then(() => {
                    cancelPreAppoint(apptInfo.id).then(res2 => {
                        this.$message({
                            type: 'success',
                            message: res2.data.msg,
                        });
                        //刷新
                        this.initNavList();
                    });
                });
        },
        //判断一串数字是否是连续的
        isContinuityNum(num) {
            let array = [];
            if (num instanceof Array) {
                array = [...num];
            } else {
                array = Array.from(num.toString()); //转换为数组
            }

            var i = array[0];
            var isContinuation = true;
            for (var e in array) {
                if (array[e] != i) {
                    isContinuation = false;
                    break;
                }
                i++;
            }
            return isContinuation;
        },
        confirmSubmit(form, done) {
            let ids = [];
            this.unAppted.forEach(t => {
                ids.push(t.id);
            });
            //通过获取数组变量和原来的数组做对比
            var max = ids[0]; //对比结果是最大值
            var min = ids[0]; //对比结果是最小值
            for (var i = 0; i < ids.length; i++) {
                if (max < ids[i]) {
                    max = ids[i];
                }
                if (min > ids[i]) {
                    min = ids[i];
                }
            }
            var minIndex;
            var maxIndex;
            this.unAppted.forEach((t, index) => {
                if (t.id == min) {
                    minIndex = index;
                }
                if (t.id == max) {
                    maxIndex = index;
                }
            });
            if (this.unAppted.length) {
                this.$confirm(`点击确定，正式提交预订操作，点击取消，取消预订，重新进行预订`)
                    .then(_ => {
                        console.log(12312312);

                        this.priceSubmit(done);
                        this.handleChange(this.tabsType);
                        // });
                    })
                    .catch(_ => {
                        done();
                    });
            } else {
                this.priceSubmit(done);
                this.handleChange(this.tabsType);
            }
            this.unApptedList = [];
        },
        priceSubmit(done) {
            let _this = this;
            if (
                (_this.formPrice.accards == undefined || _this.formPrice.accards == '') &&
                this.changeReserveType === '会员'
            ) {
                _this.$message({
                    type: 'success',
                    message: '必须选定会员卡！',
                });
                return;
            }
            _this.cardList.forEach(r => {
                if (r.id === _this.formPrice.accards) {
                    _this.formPrice.cardId = r.id;
                    _this.formPrice.userId = r.userId;
                }
            });
            if (_this.unAppted.length > 0) {
                _this.formPrice.beginTime = _this.queryDate + ' ' + _this.unAppted[0].btstr + ':00';
                _this.formPrice.endTime =
                    _this.queryDate + ' ' + _this.unAppted[_this.unAppted.length - 1].etstr + ':00:00';
            }

            //_this.formPrice.extraMemo = '后台管理员预约';
            //_this.formPrice.totalPrice = 200.0;
            _this.formPrice.orderCount = 0;
            _this.formPrice.groundId = _this.currentGround.id;
            _this.formPrice.appType = _this.changeReserveType;
            //如果是游泳卡，那么开始时间是当天的时间
            if (this.tabsType.floorName == '-1') {
                console.log('this.tabsType.floorName');
                _this.formPrice.apptTime = dayjs().format('YYYY-MM-DD') + ' 00:00:00';
                _this.formPrice.endTime = dayjs().format('YYYY-MM-DD') + ' 23:59:59';
                let req = {
                    apptTime: _this.formPrice.apptTime,
                    endTime: _this.formPrice.endTime,
                    groundId: _this.formPrice.groundId,
                };

                calAppointPrice(req)
                    .then(res => {
                        _this.formPrice.totalPrice = res.data.data;
                        this.formPrice.realTotalPrice = res.data.data;
                        this.oldPrice = res.data.data;

                        this.addAppoint(done);
                    })
                    .catch(error => {
                        done();
                    });
            } else {
                this.addAppoint();
            }
        },
        addAppoint(done) {
            let timeList = [];
            this.unAppted.forEach(e => {
                timeList.push({
                    begin: e.btstr,
                    end: e.etstr,
                });
            });
            if (timeList.length > 0) {
                this.formPrice.timeList = timeList;
            }
            let o = JSON.parse(JSON.stringify(this.formPrice));
            if (this.formPrice.apptTime) {
                o.beginTime = o.apptTime;
                delete o.apptTime;
            }
            getRandomId().then(r => {
                if (r.data.data) {
                    o.randomId = r.data.data;
                }
                addAppoint(o)
                    .then(res => {
                        if (res.data.code == 200) {
                            this.$message.success(res.data.msg);
                            if (this.changeReserveType === '会员') {
                                // 当前预约类型为会员卡时
                                mobAccCardPay(res.data.data.orderSn, 'SPT').then(res2 => {
                                    this.dialogVisible = false;
                                    //刷新
                                    this.initNavList();
                                });
                            }
                            this.dialogVisible = false;
                        }
                    })
                    .catch(error => {
                        done();
                    });
            });
        },
        shut() {
            this.dialogVisible = false;
            this.formPrice = {
                reserveType: true,
            };
            this.unApptedList = [];
        },
        isOverdue(t) {
            if (t) {
                const date2 = dayjs();
                const date1 = dayjs(t + '23:59:59');
                let diff = date1.diff(date2, 'minute');
                if (diff <= 0) {
                    return true;
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        searchPhone() {
            let _this = this;
            let cardData = [];
            let phoneName = '';
            let cards;
            let ttArr = _this.TIME_TYPE_DATA;
            getResCardInfo(_this.formPrice.contactPhone, _this.tabsType.typeId, 'SPT').then(res => {
                cards = res.data.data;
                let typeNames = '';
                if (cards.length > 0) {
                    cards.forEach(r => {
                        ttArr.forEach(e => {
                            if (e.key == r.timeType) {
                                typeNames = e.value;
                            }
                        });
                        if (!this.isOverdue(r.endDay)) {
                            console.log('r.endDay');
                            cardData.push({
                                label: r.cardName + ',卡类型:' + typeNames + ',余额:' + r.balanceValue,
                                value: r.id,
                            });
                        }
                        phoneName = r.vipName;
                    });
                    _this.cardList = cards;
                    _this.formPrice.phoneName = phoneName;
                    _this.formPriceOption.column[5].dicData = cardData;
                }
            });
        },
        gotoMakeAppt(item, type) {
            console.log(item);
            if (this.tabsType.floorName == '-1') {
                let ci = 0;
                this.formPriceOption.column.forEach(c => {
                    if (c.prop == 'totalPrice') {
                        console.log(this.timeUnitList);
                        this.formPriceOption.column[ci].label = '次数(次)';
                    }
                    ci += 1;
                });
                this.$forceUpdate();
            }
            this.currentGround = item;
            let ids = [];
            this.unAppted.forEach(t => {
                ids.push(t.id);
            });

            let _this = this;
            this.formPrice = {};
            _this.currentGround.id = item.id;
            let req = {};
            if (type != 'hx') {
                if (_this.unAppted.length == 0) {
                    return;
                }
                req.apptTime = _this.queryDate + ' ' + _this.unAppted[0].btstr + ':00';
                req.endTime = _this.queryDate + ' ' + _this.unAppted[_this.unAppted.length - 1].etstr + ':00';
            } else {
                req.apptTime = _this.queryDate + ' 00:00:00';
            }
            req.groundId = _this.currentGround.id;

            let timeList = [];
            this.unAppted.forEach(e => {
                console.log(e, item);
                if (e.groundId == item.id) {
                    timeList.push({
                        begin: e.btstr,
                        end: e.etstr,
                    });
                }
            });
            if (timeList.length > 0) {
                req.timeList = timeList;
            }
            // if (type != 'hx') {
            calAppointPrice(req).then(res => {
                _this.formPrice.totalPrice = res.data.data;
                this.formPrice.realTotalPrice = res.data.data;
                this.oldPrice = res.data.data;
            });
            // }

            _this.formPrice.groundNmae = _this.currentGround.name;
            _this.formPrice.contactPhone = '';
            _this.formPrice.phoneName = '';
            _this.formPriceOption.column[5].dicData = [];
            _this.formPrice.accards = '';

            this.unAppted.forEach(e => {
                if (e.groundId == item.id) {
                    this.unApptedList.push(e.btstr + '-' + e.etstr);
                }
            });
            _this.dialogVisible = true;
        },
        calAppointPrice(value) {
            let req = {};
            if (value) {
                req.cardId = value;
            }
            req.totalPrice = this.formPrice.totalPrice;
            paymentDiscount(req).then(res => {
                console.log(res.data.data);
                if (res.data.data) {
                    this.formPrice.realTotalPrice = res.data.data;
                }

                // this.formPriceOption.column[3].display = true;
                this.$forceUpdate();
            });
        },
        calAppointPriceByPrice(value) {
            console.log(value, this.formPrice);
            if (value && this.formPrice.accards) {
                let req = {};
                if (value) {
                    req.totalPrice = value;
                }
                req.cardId = this.formPrice.accards;
                reverseDiscount(req).then(res => {
                    this.formPrice.totalPrice = res.data.data;
                    this.$forceUpdate();
                });
            } else {
                this.formPrice.totalPrice = value;
            }
        },
        getDiffDay(date_1) {
            // 计算两个日期之间的差值
            let totalDays, diffDate;
            let myDate_1 = Date.parse(date_1);
            // 将两个日期都转换为毫秒格式，然后做差
            diffDate = Math.abs(myDate_1 - new Date()); // 取相差毫秒数的绝对值
            totalDays = Math.ceil(diffDate / (1000 * 3600 * 24)); // 向下取整
            return totalDays; // 相差的天数
        },
        gotoPreoAppt(item) {
            let _this = this;
            _this.currentGround.id = item.id;
            if (_this.unAppted.length == 0) {
                return;
            }
            if (_this.getDiffDay(_this.queryDate) > _this.preApptAHeadInDay) {
                this.$message.error('只允许预留' + _this.preApptAHeadInDay + '天内的场地!');
                return;
            }
            let btime = _this.unAppted[0].btstr + ':00';
            let etime = _this.unAppted[_this.unAppted.length - 1].etstr + ':00';
            let params = {};
            params.beginTime = _this.queryDate + ' ' + btime;
            params.endTime = _this.queryDate + ' ' + etime;
            params.extraMemo = '场地预留';
            //params.totalPrice = 0.0;
            params.orderCount = 0;
            params.groundId = _this.currentGround.id;

            //判断时间是否超过当前时间
            let d = new Date(params.beginTime);
            if (d.getTime() < new Date().getTime()) {
                _this.$message({
                    type: 'error',
                    message: '预约时间已经超过了当前时间，无法预留!',
                });
                return;
            }
            this.tjdialogVisible = true;
        },
        sure() {
            let btime = this.unAppted[0].btstr + ':00';
            let etime = this.unAppted[this.unAppted.length - 1].etstr + ':00';
            let params = {};
            params.beginTime = this.queryDate + ' ' + btime;
            params.endTime = this.queryDate + ' ' + etime;
            params.extraMemo = '场地预留';
            //params.totalPrice = 0.0;
            params.orderCount = 0;
            params.groundId = this.currentGround.id;
            params.extraMemo = this.ylDesc;
            this.$confirm('确定提交场地预留?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                let timeList = [];
                this.unAppted.forEach(e => {
                    timeList.push({
                        begin: e.btstr,
                        end: e.etstr,
                    });
                });
                if (timeList.length > 0) {
                    params.timeList = timeList;
                }
                submitPreAppoint(params).then(res2 => {
                    this.$message({
                        type: 'success',
                        message: res2.data.msg,
                    });
                    this.closeTjdialogVisible();
                    //刷新
                    this.initNavList();
                });
            });
        },
        closeTjdialogVisible() {
            this.ylDesc = '';
            this.tjdialogVisible = false;
        },
        handelChangeDate() {
            let _this = this;
            this.navList = [];
            _this.initNavList();
        },
        // tabs数据
        initTabsData() {
            let _this = this;
            let data = [];
            allStatisTypes().then(res => {
                res.data.data.forEach(i => {
                    if (i.openGroundNum > 0) {
                        let obj = {
                            label: `${i.name}(${i.openGroundNum}/${i.totalGroundNum})`,
                            typeId: i.id,
                            name: i.name,
                            floorName: i.floorName,
                        };
                        data.push(obj);
                    }
                    _this.option.column = data;
                    _this.tabsType = data[0];
                });
                _this.initNavList();
            });
        },
        // 左侧 数据
        initNavList() {
            let _this = this;
            _this.navList = [];
            listGround(1, 1000, { typeId: _this.tabsType.typeId }).then(res => {
                let groundMap = {};
                res.data.data.records.forEach(r => {
                    groundMap[r.id] = r.pics;
                });
                res.data.data.records.forEach((r, index) => {
                    let pics2 = r.pics;
                    if (pics2 == null || pics2.length == 0) {
                        if (groundMap[r.pgroundId]) pics2 = groundMap[r.pgroundId];
                    }

                    let obj = {
                        id: r.id,
                        name: r.name,
                        iconUrl: pics2[0].value,
                        status: 1,
                        timeUnit: r.timeUnit,
                        openFrom: r.openFrom,
                        openEnd: r.openEnd,
                        openFlag: r.openFlag,
                    };
                    _this.navList.push(obj);
                });

                _this.navList.forEach((e, i) => {
                    this.checkNavHandle(i, e);
                });
                this.navList = JSON.parse(JSON.stringify(this.navList));
                this.$forceUpdate();
            });
        },
        // tabs change事件
        handleChange(column) {
            this.navList = [];
            this.tabsType = column;
            this.initNavList();
        },
        // 左侧 数据的点击事件
        checkNavHandle(index, item) {
            let _this = this;
            //清除以前选择的
            _this.unAppted = [];
            this.unApptedList = [];

            _this.showCheckedDetail = false;
            _this.timeDetail = [];
            _this.currentNav = index;
            _this.apptTimeUnit = item.timeUnit;
            let appts = [];
            let timeDetail = [];
            let t = 0;
            var btime = parseInt(item.openFrom.split(':')[0]);
            var etime = parseInt(item.openEnd.split(':')[0]);
            timeDetail = [
                { name: '上午', tag: 'M', detail: [] },
                { name: '下午', tag: 'A', detail: [] },
                { name: '晚上', tag: 'W', detail: [] },
            ];
            let tc2 = (etime * 60) / _this.apptTimeUnit;
            let tc1 = (btime * 60) / _this.apptTimeUnit;
            let tstr1 = '';
            let btstr = '';
            let tstr2 = '';
            let etstr = '';
            let t1 = 0;
            let t2 = 0;
            let tdi = 0;
            for (var c = tc1; c < tc2; c++) {
                tstr1 = c * _this.apptTimeUnit;
                tstr2 = (c + 1) * _this.apptTimeUnit;
                t1 = Math.floor((c * _this.apptTimeUnit) / 60);
                t2 = Math.floor(((c + 1) * _this.apptTimeUnit) / 60);
                if (t1 < 10) {
                    t1 = '0' + t1;
                }

                btstr = t1 + ':' + (tstr1 - 60 * t1);
                if (tstr1 - 60 * t1 == 0) btstr = t1 + ':' + (tstr1 - 60 * t1) + '0';
                if (t2 < 10) {
                    t2 = '0' + t2;
                }
                if (tstr2 - 60 * t2 == 0) etstr = t2 + ':' + (tstr2 - 60 * t2) + '0';
                else etstr = t2 + ':' + (tstr2 - 60 * t2);
                if (t1 <= 12) tdi = 0;
                else if (t1 <= 17) tdi = 1;
                else tdi = 2;
                let beginTime = btstr.split(':')[0];
                timeDetail[tdi].detail.push({
                    time: btstr + '~' + etstr,
                    status: 0,
                    btime: beginTime,
                    etime: beginTime + 1,
                    btstr: btstr,
                    etstr: etstr,
                    id: c,
                });
            }
            //如果晚上没有则删除
            if (timeDetail[2].detail.length == 0) {
                timeDetail.pop();
            }

            //groundId=2&apptDay=2022-12-5&statusList=1,3,7
            _this.currentGround = item;
            listGroundAppoint(1, 10000, {
                groundId: item.id,
                apptDay: _this.queryDate,
                statusList: '1,3,7',
            }).then(res => {
                if (res.data.data.records.length > 0) {
                    res.data.data.records.forEach(r => {
                        if (r.groundId == item.id) {
                            if (r.floorName == '-1') {
                                timeDetail = [];
                                //游泳馆
                            } else {
                                if (r.timeSlot) {
                                    let timeSlot = r.timeSlot.split(',');
                                    timeSlot.forEach(e => {
                                        let btstr = e.split('-')[0];
                                        let etstr = e.split('-')[1];
                                        for (var l = 0; l < timeDetail.length; l++) {
                                            for (var i = 0; i < timeDetail[l].detail.length; i++) {
                                                if (
                                                    timeDetail[l].detail[i].btstr == btstr &&
                                                    timeDetail[l].detail[i].etstr == etstr
                                                ) {
                                                    timeDetail[l].detail[i].status = r.status;
                                                    timeDetail[l].detail[i].id = r.id;
                                                }
                                            }
                                        }
                                    });
                                } else {
                                    let btstr = r.beginTime.substring(11, 16).split(':');
                                    let etstr = r.endTime.substring(11, 16).split(':');
                                    let c1 = (parseInt(btstr[0]) * 60) / _this.apptTimeUnit;
                                    if (parseInt(btstr[1]) != 0) {
                                        c1 += _this.apptTimeUnit / parseInt(btstr[1]);
                                    }
                                    let c2 = (parseInt(etstr[0]) * 60) / _this.apptTimeUnit;
                                    if (parseInt(etstr[1]) != 0) {
                                        c2 += _this.apptTimeUnit / parseInt(etstr[1]);
                                    }
                                    for (var c = c1; c <= c2; c++) {
                                        for (var l = 0; l < timeDetail.length; l++) {
                                            for (var i = 0; i < timeDetail[l].detail.length; i++) {
                                                let timeDetailTime = timeDetail[l].detail[i].time
                                                    .split('~')[0]
                                                    .split(':');
                                                if (
                                                    timeDetailTime[0] == parseInt(btstr[0]) &&
                                                    timeDetailTime[1] == parseInt(btstr[1])
                                                ) {
                                                    timeDetail[l].detail[i].status = r.status;
                                                    timeDetail[l].detail[i].id = r.id;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        this.navList[index].timeDetail = timeDetail;
                        this.navList = JSON.parse(JSON.stringify(this.navList));
                        this.$forceUpdate();
                        // this.$set(this.navList[index], "timeDetail", timeDetail);
                    });
                } else {
                    this.navList[index].timeDetail = timeDetail;
                    this.navList = JSON.parse(JSON.stringify(this.navList));
                    this.$forceUpdate();
                }
            });
        },
        // 已预约项的点击事件
        checkAppted(item) {
            let _this = this;
            _this.showCheckedDetail = true;
            getDetail(item.id).then(res => {
                _this.apptInfo = res.data.data;
                this.apptInfo.beginTime = this.apptInfo.beginTime.split(' ')[0] + ' ' + item.btstr;
                this.apptInfo.endTime = this.apptInfo.endTime.split(' ')[0] + ' ' + item.etstr;
                this.apptInfo.apptTime = this.apptInfo.apptTime;
            });
        },
        // 选择预约项
        checkUnAppted(item, index) {
            console.log(item, index);
            if (this.currentSporId == '') {
                this.currentSporId = index.id;
            }

            this.showCheckedDetail = false;
            //初始为空
            if (this.unAppted.length == 0) {
                item.checked = true;
                item.groundId = index.id;
                this.unAppted.push(item);
                this.$forceUpdate();
                return;
            }
            let checkIndex = -1;
            if (item.checked) {
                item.checked = !item.checked;
                checkIndex = this.unAppted.indexOf(item);
                this.unAppted.splice(checkIndex, 1);
                this.$forceUpdate();
                return;
            }
            item.groundId = index.id;
            item.checked = true;
            this.unAppted.push(item);
            this.$forceUpdate();
        },
        dialogCancel() {
            this.dialogVisible = false;
            this.formPrice = {
                reserveType: true,
            };
            this.unApptedList = [];
        },
    },
};
</script>

<style lang="scss" scoped>
.newTree {
    /deep/ .el-card__body {
        padding: 0px !important;
    }
}

/deep/.el-dialog__body {
    padding: 15px 20px;
}
/deep/.el-tabs__nav {
    padding: 15px;
}
/deep/ .el-tabs__item.is-active {
    background: #0090ff;
    color: #fff;
}
/deep/ .el-tabs--left .el-tabs__item.is-left {
    text-align: left !important;
    width: 140px;
}
/deep/ .el-tabs__item {
    padding: 0px 5px !important;
}
/deep/ .el-tabs__active-bar {
    display: none;
}
/deep/.el-scrollbar__bar.is-vertical {
    display: none;
}

.cards {
    background: #fff;
    // padding: 15px 10px 0px 10px !important;
    margin-bottom: 15px;
}
.header-con {
    display: flex;
    width: 100%;
    background: #fff;
    position: relative;
    padding-right: 20px;
    height: 50px;
    .tabsCom {
        flex: 1;
        width: calc(100% - 300px);
        > div {
            width: 100%;
        }

        /deep/ .el-tabs__nav-wrap::after {
            display: none;
        }
    }

    .dateCom {
        height: 40px;
        //width: 300px;
        padding-top: 5px;
        text-align: right;
        .el-date-editor {
            width: 180px;
        }
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 10px;
        width: 100%;
        height: 1px;
        background-color: #e4e7ed;
        z-index: 1;
    }
}
.el-row {
    padding: 0px 10px;
}
.contentCom {
    .navListClass {
        // max-height: 70vh;
        // overflow-y: scroll;
        img {
            width: 100%;
            height: 215px;
        }
        .navItem {
            border: 1px solid #c1c1c1;
            margin-bottom: 20px;
            // height: 335px;
            .titleNav {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding-left: 14px;
                line-height: 32px;
                .name {
                    color: #878787;
                    font-weight: bold;
                }
                .isOK {
                    color: #1fbfbe;
                }
                .isNO {
                    color: #f39a37;
                }
            }
            //边框
            &.activeNav {
                //background-color: #67D9D8;
                border: 1px solid #00bfbf;
            }
        }
    }
    /**右边*/
    .itemDetailClass {
        // border: 1px solid #c1c1c1;
        font-size: 15px;
        //font-weight: bold;
        > div {
            // margin-bottom: 15px;
            padding: 8px;
        }
        .con {
            margin-bottom: 10px;
        }
        .titleri {
            display: flex;
            justify-content: space-between;
        }
        .titleItem {
            color: #878787;
            font-weight: bold;
            margin-bottom: 10px;
        }
        .btn {
            color: #0090ff;
            //width: 70px;
            //height: 20px;
            //text-align: center;
            //line-height: 20px;
            //background: #0090FF;
            //color: #fff;
        }
        .label {
            display: inline-block;
            width: 35%;
            text-align: right;
            padding-right: 10px;
            color: #878787;
        }
    }
    /**中间 */

    .navItemClass {
        // height: 335px;
        font-size: 15px;
        position: inherit;

        // > div {
        //   padding: 8px;
        //   height: 335px;
        //   border: 1px solid #c1c1c1;
        //   margin-bottom: 20px;
        //   position: inherit;
        // }
        .titleItem {
            font-size: 15px;
            color: #878787;
            margin-bottom: 10px;
        }
        .timeName {
            padding-left: 0px;
            text-align: right;
            line-height: 30px;
        }

        .timeCom {
            font-size: 15px;
            font-weight: bold;
            display: flex;
            flex-wrap: wrap;
            > div {
                flex: 1;
                display: flex;
                flex-wrap: wrap;
            }
        }
        .timeItem {
            width: 80px;
            display: flex;
            font-size: 12px;
            flex-direction: column;
            justify-content: center;
            margin-left: 10px;
            cursor: pointer;
            padding: 0 2px;
            margin-bottom: 10px;
            .time,
            .status {
                text-align: center;
            }
        }
        .disTime {
            border: 1px solid #868687;
            background: #f2f2f2;
            .time,
            .status {
                color: #868687;
            }
        }
        .noDisTime {
            border: 1px solid #1fbfbe;
            background: #fff;
            font-size: 12px;
            .time,
            .status {
                color: #1fbfbe;
            }
        }
        .timeItemChecked {
            background: #1fbfbe;
            .time,
            .status {
                color: #fff;
            }
        }
        .weiTime {
            border: 1px solid red;
            background: #fff;
            .time,
            .status {
                color: red;
            }
        }
        .xinTime {
            border: #fcc608 1px solid;
            background: #fff;
            .time,
            .status {
                color: #fcc608;
            }
        }
        .apptbtn {
            // display: flex;
            // //justify-content: flex-end;
            // padding-left: 30px;
            // margin-top: 10px;
            // position: absolute;
            // bottom: 10px;
            float: right;
            margin-left: 30px;
            padding-bottom: 15px;
        }
        .apptbtnhx {
            display: flex;
            padding-right: 10px;
            /* margin-top: 100px; */
            /* position: absolute; */
            bottom: 20px;
            right: 20px;
            text-align: center;
            padding-top: 100px;
            float: right;
            padding-bottom: 15px;
        }
        .atbtn {
            width: 70px;
            height: 30px;
            font-size: 14px;
            background: #0090ff;
            margin-left: 10px;
            border: 1px solid #0090ff;
            color: #fff;
        }
        .atbtn-bor {
            width: 70px;
            height: 30px;
            font-size: 14px;
            margin-left: 10px;
            background: #fff;
            border: 1px solid #0090ff;
            color: #0090ff;
        }
    }
    .atbtn-bor-pos {
        width: 73px;
        height: 30px;
        font-size: 14px;
        margin-left: 10px;
        background: #fff;
        border: 1px solid #0090ff;
        color: #0090ff;
        position: absolute;
        right: 15px;
        top: 10px;
    }
}
.btn-footer {
    display: flow-root;
}
.hh {
    word-wrap: break-word;
}
</style>

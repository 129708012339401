<template>
  <basic-container>
    <avue-crud
      :data="tableDatas"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      @on-load="onLoad"
      @refresh-change="onLoad"
    >
      <template slot-scope="{type,size,row}" slot="menu">
        <el-button @click.stop="handleFlow(row)" icon="el-icon-reading" :size="size" :type="type">流控</el-button>
        <el-button @click.stop="handleAuth(row)" icon="el-icon-collection" :size="size" :type="type">授权</el-button>
      </template>
    </avue-crud>
    <el-dialog class="avue-dialog avue-crud__dialog" custom-class="dialog-width" @close="closeAuthForm"  append-to-body :title="authTitle" :visible.sync="addAuthVisible">
      <avue-form ref="authForm" v-model="authForm" :option="authOption">
        <template slot-scope="scope" slot="menuForm">
          <basic-form-button @submit="saveAuth" @cancel="cancelAddAuth"></basic-form-button>
        </template>
      </avue-form>
    </el-dialog>
    <el-dialog class="avue-dialog avue-crud__dialog" custom-class="dialog-width" @close="closeFlowForm"  append-to-body :title="flowTitle" :visible.sync="addFlowVisible">
      <avue-form ref="flowForm" v-model="flowForm" :option="flowOption">
        <template slot-scope="scope" slot="menuForm">
          <basic-form-button @submit="saveFlow" @cancel="cancelAddFlow"></basic-form-button>
        </template>
      </avue-form>
    </el-dialog>
  </basic-container>
</template>
<script>
  import defaultService from '@/config/defaultService';
  import {
    getMachineResourceList,
    getServiceList
  } from '@/api/dataService/dataIdentity'
  import { saveAuth } from '@/api/dataService/dataAuthority'
  import {
    saveFlowRule
  } from '@/api/dataService/dataFlow'
  export default {
    data(){
      return {
        authTitle: '授权',
        addAuthVisible: false,
        authForm:{
          limitApp:''
        },
        authOption:{
          submitBtn:false,
          emptyBtn:false,
          column:[
            {
              label:'资源名',
              prop:'resource',
              span:24,
              rules: [{
                required: true,
                message: "请输入资源名",
                trigger: "blur"
              }]
            },
            {
              label:'流控应用',
              prop:'limitApp',
              span:24,
              placeholder:'调用来源，"default"表示所有应用',
              rules: [{
                required: true,
                message: "请输入流控应用",
                trigger: "blur"
              }]
            },
            {
              label:'授权类型',
              prop:'strategy',
              type: "radio",
              value: 0,
              dicData: [{
                label: '白名单',
                value: 0
              }, {
                label: '黑名单',
                value: 1
              }],
              span:24,
              rules: [{
                required: true,
                message: "请选择授权类型",
                trigger: "blur"
              }]
            }
          ]
        },
        flowTitle: '流控',
        addFlowVisible: false,
        flowForm:{},
        flowOption:{
          submitBtn:false,
          emptyBtn:false,
          column: [
            {
              label: '资源名',
              prop: 'resource',
              span: 24,
              rules: [{
                required: true,
                message: "请输入名称",
                trigger: "blur"
              }]
            },
            {
              label: '来源应用',
              prop: 'limitApp',
              span: 24,
              placeholder:'指调用方，多个调用方名称用半角英文逗号（,）分隔',
              rules: [{
                required: true,
                message: "请输入名称",
                trigger: "blur"
              }]
            },
            {
              label: '流控模式',
              prop: 'strategy',
              type: "radio",
              dicData: [{
                label: '直接',
                value: 0
              }, {
                label: '关联',
                value: 1
              }, {
                label: '链路',
                value: 2
              }],
              display: false
            },
            {
              label: '阈值类型',
              prop: 'grade',
              span: 24,
              type: "radio",
              value: 1,
              dicData: [{
                label: 'QPS',
                value: 1
              }, {
                label: '并发线程数',
                value: 0
              }],
              rules: [{
                required: true,
                message: "请选择阈值类型",
                trigger: "blur"
              }]
            },
            {
              label: '阈值',
              prop: 'count',
              type: 'number',
              span: 24,
              rules: [{
                required: true,
                message: "请输入阈值",
                trigger: "blur"
              }]
            },
            {
              label: '阈值模式',
              prop: 'modetype',
              display: false
            },
            {
              label: '流控效果',
              prop: 'controlBehavior',
              type: "radio",
              dicData: [{
                label: '快速失败',
                value: 0
              }, {
                label: 'Warm Up',
                value: 1
              }, {
                label: '排队等待',
                value: 2
              }, {
                label: '预热排队',
                value: 3
              }],
              display: false
            }
          ]
        },
        page: {
          pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        tableLoading: false,
        tableDatas: [],
        tableOption: {
          editBtn: false,
          delBtn: false,
          addBtn: false,
          column:[
            {
              label:'资源名',
              prop:'resource',
              span: 24
            },
            {
              label:'通过QPS',
              prop:'passQps',
              span: 24
            },
            {
              label:'拒绝QPS',
              prop:'blockQps',
            },
            {
              label:'线程数',
              prop:'threadNum',
            },
            {
              label:'平均RT',
              prop:'averageRt',
            },
            {
              label:'分钟通过',
              prop:'oneMinutePass',
            },
            {
              label:'分钟拒绝',
              prop:'oneMinuteBlock',
            }
          ]
        }
      }
    },
     mounted() {

    },
    methods: {

      async onLoad() {
        await getServiceList().then( res => {
          let item = res.data.data[0].machines[0];
          defaultService['app'] = item['app'];
          defaultService['ip'] = item['ip'];
          defaultService['port'] = item['port'];
        });
        this.tableLoading = true;
        let obj = {
          ...defaultService,
          type: 'cluster'
        };
        getMachineResourceList(obj).then( res => {
          this.tableLoading = false;
          this.page.total = res.data.data.length;
          let start = (this.page.currentPage - 1) * this.page.pageSize;
          let end = start + this.page.pageSize;
          this.tableDatas = res.data.data.slice(start,end);
        }).catch( e => {
          this.tableLoading = false;
        });
      },
      handleAuth(row) {
        this.authForm['resource'] = row['resource'];
        this.addAuthVisible = true;
      },
      closeAuthForm() {
        this.addAuthVisible = false;
      },
      saveAuth() {
        this.$refs.authForm.validate((vaild)=>{
          if(vaild){
            let rule = {
              limitApp: this.authForm['limitApp'],
              resource: this.authForm['resource'],
              strategy: this.authForm['strategy'],
            };
            let obj = {
              ...defaultService,
              rule
            };
            saveAuth(obj).then( res => {
              this.addAuthVisible = false;
              this.resetAuthForm();
              this.$router.push('/dataService/dataAuthority');
            });
          }
        })

      },
      resetAuthForm() {
        this.authForm['limitApp'] = '';
        this.authForm['resource'] = '';
        this.authForm['strategy'] = 0;
      },
      cancelAddAuth() {
        this.addAuthVisible = false;
      },
      handleFlow(row) {
        this.flowForm['resource'] = row['resource'];
        this.addFlowVisible = true;
      },
      closeFlowForm() {

      },
      saveFlow() {
        this.$refs.flowForm.validate((vaild)=> {
          if (vaild) {
            let obj = {
              "grade": this.flowForm['grade'],
              "strategy": 0,
              "controlBehavior": 0,
              "limitApp": this.flowForm['limitApp'],
              "clusterMode": false,
              "clusterConfig": {"thresholdType": 0},
              "resource": this.flowForm['resource'],
              "count": this.flowForm['count'],
              ...defaultService
            };
            saveFlowRule(obj).then(res => {
              this.addFlowVisible = false;
              this.resetFlowForm();
              this.$router.push('/dataService/dataFlow');
            }).catch(e => {

            });
          }
        })

      },
      resetFlowForm() {
        this.flowForm['grade'] = 1;
        this.flowForm['limitApp'] = '';
        this.flowForm['resource'] = '';
        this.flowForm['count'] = '';
      },
      cancelAddFlow() {
        this.addFlowVisible = false;
      }
    }
  }
</script>

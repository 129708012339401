<template>
  <div>
    <el-row>
      <el-col :span="2" class="w-80">
        <div
          @click="scrollTop"
          class="cur-p jtdiv"
          style="top: -10px"
          v-if="spaces.length > 0"
        >
          <i
            class="el-icon-arrow-up jt"
            :class="{ hidden: scrollHeight == 0 }"
          ></i>
        </div>
        <div
          :style="{ height: viewPortHeight - 255 + 'px' }"
          class="o-a"
          ref="viewBox"
          @scroll="getScroll"
        >
          <div
            class="text-center floorClazz cur-p animate__animated animate__zoomIn"
            v-for="(space, i) in spaces"
            :class="{
              dx: space.above_under == 'under',
              active: floor.id == space.id,
            }"
            :key="i"
            @click="changeFloor(space)"
          >
            <div>
              <span v-if="space.above_under == 'under'">B</span
              >{{ Math.abs(space.code) }}
            </div>
          </div>
        </div>

        <div
          :style="{ top: viewPortHeight - 275 + 'px' }"
          v-if="spaces.length > 0"
          class="cur-p jtdiv"
          @click="scrollBotton"
        >
          <i class="el-icon-arrow-down jt" :class="{ hidden: bottomDis }"></i>
        </div>
      </el-col>
      <el-col :span="22" class="w-calc-80" v-if="spaces.length > 0">
        <floorDetail :floor="floor"></floorDetail>
        <room :floor="floor" :buildId="buildId"></room>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as dictbizService from "@/api/system/dictbiz";
import room from "./room";
import { getMetaCommonList } from "@/api/busiMode/metaCommon";
import floorDetail from "./floorDetail";

export default {
  components: { room, floorDetail },
  data() {
    return {
      viewPortHeight:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight,
      page: {
        size: 100,
        currentPage: 1,
        total: 0,
      },
      currentId: 1,
      floorModel: "wisdom_park.wp_floor",
      spaces: [],
      floors: [],
      floor: {},
      scrollHeight: 0,
      bottomDis: false,
    };
  },

  props: {
    buildId: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  watch: {
    buildId: {
      immediate: true,
      handler(val) {},
    },
  },
  computed: {},
  mounted() {
    this.onLoad();
    this.getDicList();
  },
  destroyed() {
    window.removeEventListener("scroll", this.getScroll);
  },
  methods: {
    back() {
      history.go(-1);
    },
    getDicList() {
      dictbizService
        .getDictionary({
          code: "decoration",
        })
        .then((res) => {
          this.decorationList = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
    },
    scrollTop() {
      this.scrollHeight = this.$refs.viewBox.scrollTop;
      if (this.scrollHeight > 50) {
        this.$refs.viewBox.scrollTop = this.scrollHeight - 50;
        this.scrollHeight = this.scrollHeight - 50;
      } else {
        this.scrollHeight = 0;
        this.$refs.viewBox.scrollTop = 0;
      }
    },
    scrollBotton() {
      this.scrollHeight = this.$refs.viewBox.scrollTop;
      if (
        this.viewPortHeight - 350 + this.scrollHeight <=
        this.spaces.length * 50
      ) {
        this.$refs.viewBox.scrollTop = this.scrollHeight + 50;
        this.scrollHeight = this.scrollHeight + 50;
      } else {
        this.scrollHeight = 0;
        this.$refs.viewBox.scrollTop = 0;
      }
    },
    getScroll(e) {
      let scrollTop = e.srcElement.scrollTop;
      let i = Math.floor(scrollTop / 50);
      if (i + 1 <= this.spaces.length) {
        this.floor = this.spaces[i + 1];
      } else {
        this.floor = this.spaces[i];
      }
      this.scrollHeight = this.$refs.viewBox.scrollTop;
      let clientHeight = this.$refs.viewBox.clientHeight;
      let scrollHeight = this.$refs.viewBox.scrollHeight;

      if (clientHeight + scrollTop === scrollHeight) {
        this.bottomDis = true;
      } else {
        this.bottomDis = false;
      }
    },
    onLoad() {
      let obj = this.page;
      obj.wheres = [{ field: "building_id", value: this.buildId }];
      obj.orders = [{ field: "code+1000", sort: "DESC" }];

      this.getMetaCommonList(obj);
    },
    getMetaCommonList(obj) {
      getMetaCommonList(this.floorModel, obj).then((res) => {
        const data = res.data.data;
        this.spaces = data.records;
        data.records.forEach((element) => {
          if (element.code == 1 && element.above_under == "above") {
            this.floor = element;
          }
        });
        if (!this.floor) {
          this.floor = this.spaces[0];
        }
      });
    },
    changeFloor(f) {
      this.floor = f;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-card__body {
  padding: 0px;
}
.b-b {
  border-bottom: 1px solid #dcdfe6;
}
.floorClazz {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 15px 0px;
  font-size: 16px;
  background: #fff;
  &:hover {
    background: rgb(102, 177, 255);
    border-color: #66b1ff;
    color: #fff;
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-delay: 0.3s;
    animation-fill-mode: forwards;
  }
}
.dx {
  background: rgba(0, 0, 0, 0.05);
}
.active {
  background: #1976d3 !important;
  color: #fff;
  animation: fadeIn; /* referring directly to the animation's @keyframe declaration */
  animation-duration: 2s; /* don't forget to set a duration! */
}

.o-a {
  overflow: auto;
}
::-webkit-scrollbar {
  width: 0px !important;
  height: 0px !important;
  background-color: rgba(171, 171, 171, 0) !important;
  background-color: rgba(171, 171, 171, 0) !important;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(171, 171, 171, 0) !important;
  background-color: rgba(171, 171, 171, 0) !important;
}

::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 6px rgba(171, 171, 171, 0) !important;
  background-color: rgba(171, 171, 171, 0) !important;
}
* {
  scrollbar-color: rgba(171, 171, 171, 0) !important;
  scrollbar-track-color: rgba(171, 171, 171, 0) !important;
}
.jt {
  font-size: 28px;
  width: 64px;
  text-align: center;
  cursor: pointer;
  opacity: 0.8;
  color: #000;
}
.jtdiv {
  position: absolute;
  z-index: 100;
}
.disScroll {
  opacity: 0.5;
  cursor: no-drop;
}
.animate__animated.animate__zoomIn {
  --animate-duration: 0.1s;
}
</style>

import { render, staticRenderFns } from "./spcodaccountPutOff.vue?vue&type=template&id=68bb7218&scoped=true"
import script from "./spcodaccountPutOff.vue?vue&type=script&lang=js"
export * from "./spcodaccountPutOff.vue?vue&type=script&lang=js"
import style0 from "./spcodaccountPutOff.vue?vue&type=style&index=0&id=68bb7218&prod&lang=scss&rel=stylesheet%2Fscss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68bb7218",
  null
  
)

export default component.exports
<template>
    <div>

        <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" :page.sync="page" @refresh-change="refreshChange" @on-load="onLoad" @row-update="rowUpdate" @row-save="rowSave"
                   @row-del="rowDel" @search-change="searchChange" @search-reset="searchReset" @current-change="currentChange" @size-change="sizeChange">
            <!-- <template slot="menuLeft" slot-scope="scope">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="addRoom()">新增房间
                </el-button>
            </template> -->
            <!-- <template slot-scope="scope" slot="menu">
                <el-button type="text" size="small" class="none-border" icon="el-icon-view" @click="getDetail(scope.row, true)">查看
                </el-button>
                <el-button type="text" size="small" class="none-border" icon="el-icon-edit" @click="getDetail(scope.row, false)">修改
                </el-button>
                <el-button type="text" size="small" icon="el-icon-delete" class="none-border" @click="rowDel(scope.row)">删除
                </el-button>
            </template> -->
        </avue-crud>
        <el-dialog :title="roomTitle" :visible.sync="dialogVisible" :close-on-click-modal="false" destroy-on-close :top="'8vh'" width="40%" append-to-body>
            <roomInfo v-if="dialogVisible" ref="roomInfo" :buildRead="buildRead" :onlyRead="onlyRead" :floors="floors" :ruleFormObj="ruleForm" @roomSucess="closeDialogVisible"></roomInfo>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary" v-if="!onlyRead" @click="saveMetaCommon">确 定</el-button>
                <el-button size="small" type="primary" v-if="onlyRead" @click="editRoom">编 辑</el-button>
                <el-button size="small" @click="closeDias">取 消</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
import roomInfo from './roomInfo';
import * as dictbizService from '@/api/system/dictbiz';

import {
    getMetaCommonList,
    delMetaCommon,
    saveMetaCommon,
    updateMetaCommon,
    getMetaCommonInfo,
    getMetaCommonListExport,
} from '@/api/busiMode/metaCommon';

export default {
    components: { roomInfo },
    data() {
        return {
            formInline: {},
            detailDia: false,
            dialogVisible: false,
            buildId: '',
            buildName: '',
            title: '',
            btnLoding: false,
            downLoading: false,
            page: {
                size: 10,
                current: 1,
                total: 0,
            },
            tableLoading: false,
            model: 'wisdom_park.wp_room',
            view_model: 'wisdom_park.v_wp_room_stat',
            floorModel: 'wisdom_park.wp_floor',
            room_export: 'wisdom_park.v_wp_room_export_stat',

            spaces: [],
            floors: [],
            ruleForm: { label: [] },
            currentIndex: '',
            onlyRead: false,
            roomTitle: '新增房间',
            decorationList: [],
            sumCheckinArea: '',
            loading: false,
            searchText: '',
            data: [],
            buildRead: false,
            roomStatuss: [],
            option: {
                align: 'center',
                calcHeight: 30,
                dialogWidth: 900,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: true,
                // selection: true,
                labelWidth: 120,
                menuWidth: 200,
                viewBtn: true,
                dialogClickModal: false,
                delBtn: true,
                editBtn: true,
                addBtn: true,
                cancelBtn: false,
                column: [
                    {
                        label: '合同编号',
                        prop: 'contract_no',
                        width: 250,
                        search: true,
                        searchSpan: 6,
                        span: 24,
                    },
                    {
                        label: '租客名称',
                        prop: 'renters_name',
                        width: 120,
                        span: 24,
                    },
                    {
                        label: '租客签订人',
                        prop: 'renters_signed',
                        width: 120,
                        span: 24,
                    },
                    {
                        label: '签订联系方式',
                        prop: 'renters_signed_contact',
                        width: 120,
                        span: 24,
                    },

                    {
                        label: '法人',
                        prop: 'corporation_personality',
                        width: 120,
                    },
                    {
                        label: '法人联系方式',
                        prop: 'corporation_personality_contact',
                        width: 120,
                    },
                    {
                        label: '租赁总金额(元)',
                        prop: 'amount',
                        width: 120,
                    },
                    {
                        label: '租赁面积(㎡)',
                        prop: 'rent_area',
                        width: 120,
                    },
                    {
                        label: '地址',
                        prop: 'renters_address',
                        width: 120,
                        type: 'textarea',
                        span: 24,
                    },
                ],
            },
            buildList: [],
            floorList: [],
            floorLists: [],
        };
    },
    props: {
        currentRow: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    computed: {},
    mounted() {
        this.buildId = this.$router.currentRoute.query.buildId;
        this.buildName = this.$router.currentRoute.query.buildName;
        this.onLoad();
        this.getDicList();
        this.getBuildList();
    },
    methods: {
        rowSave(row, done, loading) {
            saveMetaCommon('wisdom_park.wp_sublessor_contract', {
                values: row,
            }).then(res => {
                if (res.data.code == 200) {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                }
            });
        },
        rowUpdate(row, index, done, loading) {
            let o = {
                update: JSON.parse(JSON.stringify(row)),
                primaryKeyValue: row.id,
                primaryKey: 'id',
            };
            updateMetaCommon('wisdom_park.wp_sublessor_contract', o).then(res => {
                if (res.data.code == 200) {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                }
            });
        },
        getBuildList() {
            this.loading = true;
            getMetaCommonList('wisdom_park.wp_building', {
                size: 100,
                current: 1,
            }).then(res => {
                this.loading = false;
                const data = res.data.data;
                this.buildList = data.records;
            });
        },
        getFloors() {
            let obj = {
                size: 1000,
                current: 1,
                orders: [{ field: 'code+1000', sort: 'DESC' }],
            };
            obj.wheres = [{ field: 'building_id', value: this.formInline.building_id }];
            getMetaCommonList('wisdom_park.wp_floor', obj).then(res => {
                const data = res.data.data;
                this.floorLists = data.records;
            });
        },
        refreshChange() {
            console.log('herer');
            this.onLoad(this.page);
        },
        closeDialogVisible() {
            this.dialogVisible = false;
            this.onLoad();
        },
        close() {
            this.detailDia = false;
            this.onLoad();
        },
        back() {
            history.go(-1);
        },
        getDicList() {
            dictbizService
                .getDictionary({
                    code: 'decoration',
                })
                .then(res => {
                    this.decorationList = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
            dictbizService
                .getDictionary({
                    code: 'RoomStatus',
                })
                .then(res => {
                    this.roomStatuss = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: Number(item['dictKey']),
                            id: item['id'],
                        };
                    });
                });
        },

        getRenovation(r) {
            let str = '';
            this.decorationList.forEach(d => {
                if (d.value == r) {
                    str = d.name;
                }
            });
            return str;
        },
        editRoom() {
            this.onlyRead = !this.onlyRead;
            if (!this.onlyRead) {
                this.roomTitle = '房间编辑';
            } else {
                this.roomTitle = '房间详情';
            }
        },
        addRoom() {
            this.ruleForm = {};

            this.onlyRead = false;
            this.buildRead = true;
            this.roomTitle = '新增房间';
            this.dialogVisible = true;
        },
        btbDO(type, index) {
            if (type == 'rowDel') {
                this.rowDel(index);
            }
            if (type == 'splitRoom') {
                this.splitRoom(index);
            }
        },

        rowDel(row) {
            this.$confirm('是否删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let pk = 'id';
                    delMetaCommon('wisdom_park.wp_sublessor_contract', { pk: pk, ids: [row.id] }).then(res => {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        this.onLoad(this.page);
                    });
                })
                .catch(() => {});
        },
        //
        onFloorLoad(id) {
            let params = {
                wheres: [
                    {
                        field: 'floor_id',
                        value: id,
                    },
                ],
            };
            getMetaCommonList('wisdom_park.v_wp_floor_stat', {
                current: 1,
                size: 500,
                ...params,
            }).then(res => {
                const data = res.data.data;
                this.sumCheckinArea = data.records[0].sum_free_area;
            });
        },
        saveMetaCommon() {
            let room = JSON.parse(JSON.stringify(this.$refs.roomInfo.saveRooms(this.currentRow)));

            if (!room.id) {
                saveMetaCommon('wp_sublessor_contract', {
                    values: this.ruleForm,
                }).then(res => {
                    if (res.data.code == 200) {
                        this.$message.success('新增成功');
                        this.closeDialogVisible();
                    }
                });
            } else {
                let obj = {};
                Object.keys(room).forEach(ele => {
                    if (!ele.includes('$')) {
                        obj[ele] = room[ele];
                    }
                });
                let o = {
                    update: JSON.parse(JSON.stringify(obj)),
                    primaryKeyValue: room['id'],
                    primaryKey: 'id',
                };

                updateMetaCommon('wisdom_park.wp_sublessor_room', o).then(res => {
                    if (res.data.code == 200) {
                        this.dialogVisible = false;
                        this.onLoad();
                    }
                });
            }
        },
        getDetail(room, onlyRead) {
            this.ruleForm = {};

            this.ruleForm = JSON.parse(JSON.stringify(room));
            // if (this.ruleForm.label == "") {
            //   this.ruleForm.label = [];
            // }
            if (typeof this.ruleForm.label === 'string') {
                this.ruleForm.label = this.ruleForm.label.split(',');
            }
            this.roomTitle = '房间详情';
            if (!onlyRead) {
                this.buildRead = true;
                this.roomTitle = '房间编辑';
            }
            //  JSON.stringify

            this.onlyRead = onlyRead;
            this.onFloorLoad(room.floor_id);
            this.findFloors(room.building_id);
            this.dialogVisible = true;
        },

        closeDias() {
            this.dialogVisible = false;
            this.buildRead = false;
            this.ruleForm = {};
        },
        findFloors(bid) {
            let obj = {
                current: 1,
                size: 1000,
            };
            obj.wheres = [{ field: 'building_id', value: bid }];
            obj.orders = [{ field: 'code+1000', sort: 'DESC' }];
            getMetaCommonList(this.floorModel, obj).then(res => {
                const data = res.data.data;
                console.log(this.floors);
                this.floors = data.records;
            });
        },
        onLoad() {
            let obj = {
                current: this.page.currentPage,
                size: this.page.pageSize,
            };

            let wheres = [{ field: 'company_id', value: this.currentRow.company_id }];

            obj.wheres = wheres;
            console.log(wheres);
            this.getMetaCommonList(obj);
        },
        search() {
            let obj = this.getSearch();
            console.log(obj);
            this.getMetaCommonList(obj);
        },
        getMetaCommonList(obj) {
            this.btnLoding = true;
            getMetaCommonList('wisdom_park.wp_sublessor_contract', obj).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                data.records.forEach(e => {
                    if (e.status) {
                        e.status = e.status.toString();
                    }
                });
                this.data = data.records;
                this.btnLoding = false;
            });
        },
        getSearch() {
            let wheres = [{ field: 'is_cancel', value: 0 }];
            if (this.buildId) {
                wheres.push({ field: 'building_id', value: this.buildId });
            }
            for (let key in this.formInline) {
                if (key == 'rental_unit_price_day') {
                    wheres.push({ field: key, value: this.formInline[key], op: 'GE' });
                } else if (key == 'rental_unit_price_day1') {
                    wheres.push({
                        field: 'rental_unit_price_day',
                        value: this.formInline[key],
                        op: 'LE',
                    });
                } else if (key == 'room_area') {
                    wheres.push({ field: key, value: this.formInline[key], op: 'GE' });
                } else if (key == 'room_area1') {
                    wheres.push({
                        field: 'room_area',
                        value: this.formInline[key],
                        op: 'LE',
                    });
                } else if (key == 'code') {
                    wheres.push({
                        field: 'room_name',
                        value: this.formInline[key],
                        op: 'LIKE',
                    });
                } else {
                    wheres.push({
                        field: key,
                        value: this.formInline[key],
                    });
                }
            }
            return {
                current: this.page.currentPage,
                size: this.page.pageSize,
                wheres: wheres,
            };
        },

        getMetaCommonListExport() {
            this.downLoading = true;
            let obj = this.getSearch();
            getMetaCommonListExport(this.view_model, obj)
                .then(res => {
                    this.downLoading = false;
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.download = '房屋详情' + '.xls';
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(e => {
                    this.downLoading = false;
                });
        },
        async getroomCommonList(obj) {
            getMetaCommonList(this.view_model, obj).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.titleclazz {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.configInput {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 80px;
    margin: 0px 5px;
}
.card {
    padding: 0px 10px 10px 0px;
}
ul,
li {
    list-style: none;
    margin-bottom: 5px;
}
.spaceCard {
    display: inline-block;
    min-width: 100px;
    height: 105px;
}
.cardBg {
    background: #f5f7fa;
}
.spaceCard {
    &:hover {
        .addbtn {
            display: block;
        }
    }
}
.addbtn {
    border: 1px dotted #1976d3;
    padding: 5px 10px;
    color: #1976d3;
    margin-bottom: 10px;
    border-radius: 0px;
}
.roomClazz {
    white-space: nowrap;
    font-size: 0px;
    overflow-x: auto;
    width: 100%;
}
/deep/ .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 20px;
}
.b-b {
    border-bottom: 1px solid #dcdfe6;
}

.dropclazz {
    position: absolute;
    right: 5px;
    top: 5px;
    padding: 3px 5px 3px 0px;
    transform: rotate(-90deg);
}
.p-r {
    position: relative;
}
/deep/ .el-drawer__header {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 20px;
    margin-bottom: 0px;
}
/deep/ .el-drawer__body {
    padding: 0px;
}
/deep/.el-dialog__body {
    height: calc(80vh - 110px);
    overflow: auto;
    padding: 15px;
}

.cz-pos {
    position: absolute;
    top: 10px;
    right: 20px;
    opacity: 0.3;
}
</style>

<template>
  <div class="h-100p">
    <basic-container
      v-loading="loading"
      class="h-100p"
      :body-style="{ height: '100%', padding: '0', position: 'relative' }"
    >
      <!-- <div class="bor" ref="dataAssetsMapMinimap" style="position: absolute; bottom: 5px; right: 5px;background: #fff;"></div> -->
      <div v-if="!isError">
        <div class="lh-50 pad-lr-15 bor-b">
          <template>
            <el-button
              @click="goBack"
              icon="el-icon-back"
              type="text"
              size="small"
              >返回</el-button
            >
          </template>
          <template v-for="(item, index) of toolbarButton">
            <div
              :key="index"
              v-if="item.type === 'divider'"
              class="el-divider el-divider--vertical"
            ></div>
            <el-tooltip
              :key="index"
              v-if="item.type === 'button'"
              class="item"
              effect="dark"
              :content="item.desc || item.name || ''"
              placement="top"
            >
              <el-button
                :disabled="item.disabled"
                @click="toolbarClick(item)"
                size="small"
                class="el-button operBtn el-button--text"
                ><i v-if="item.icon" :class="item.icon"></i>
                {{ item.name || "" }}</el-button
              >
            </el-tooltip>
            <span :key="index" v-if="item.type === 'search'" class="push-right">
              <el-select
                v-model="searchTableId"
                clearable
                filterable
                remote
                :placeholder="item.desc || item.name || ''"
                :remote-method="remoteMethod"
                @change="searchTableNameChange"
              >
                <el-option
                  v-for="item in searchTables"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </span>
          </template>
        </div>
      </div>
      <no-data class="pad-t-40" v-if="isError">
        <div class="text-center">
          <el-button size="small" type="primary" @click="loadInitData"
            >重新加载</el-button
          >
        </div>
      </no-data>
      <div v-if="!isError" style="height: calc(100% - 52px)" class="w-100p">
        <div
          class="w-100p h-100p"
          ref="dataAssetsMap"
        ></div>
      </div>
    </basic-container>
  </div>
</template>

<script>
import { G6, GraphOptions, LayoutConfigs, ToolbarButtons } from "./map/g6";
import CreateRanderData from "./map/map.js";
import MapEvents from "./map/map-event";
import RequestData from "./map/request-data";
// import DataAssetsDetail from './assets-detail';

import { panoramaForSingleApi } from "@/api/dataService/serviceOverview.js";
export default {
  components: {
    // DataAssetsDetail
  },
  mixins: [CreateRanderData, MapEvents, RequestData],
  data() {
    return {
      keyword: "",
      dataAssetsDetailProps: {
        propGuid: null,
        propTableName: "",
        propDatabaseId: null,
        propDatabaseName: "",
      },
      graph: null,
      dataHierarchys: [],
      datasources: [],
      applications: [],
      tables: [],
      tasks: [],
      level: 1,
      currentLayou: null,
      loading: false,
      isError: false,
      datasourceCombo: false,
      showDHIndicators: ["tableNum", "allRecordNum", "allStorage", "columnNum"],
      showTableIndicators: [
        "allRecordNum",
        "allStorage",
        "columnNum",
        "dayRecordNum",
      ],
      toolbarButton: [],
      searchTableId: "",
      searchTables: [],
      showType: "chartShow",
      tableData: [],
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      tablesOption: {
        addBtn: false,
        editBtn: false,
        delBtn: false,
        menu: false,
        refreshBtn: false,
        columnBtn: false,
        column: [
          {
            label: "表名称",
            prop: "tableName",
            width: 200,
          },
          {
            label: "字段数",
            prop: "columnNum",
          },
          {
            label: "数据量（条）",
            prop: "dataRows",
          },
          {
            label: "日增数据量（条）",
            prop: "dataRowsIncreaseByDay",
          },
          {
            label: "占用存储空间",
            prop: "dataSize",
          },
          {
            label: "日增占用存储空间",
            prop: "dataSizeIncreaseByDay",
          },
        ],
      },
    };
  },
  created() {
    if (this.$breadcrumb.breadcrumbs.length < 3) {
      this.$breadcrumb.add({
        name: "API应用全景图",
      });
    }
  },
  mounted() {
    this.loadInitData();
  },
  methods: {
     // 返回
    goBack() {
      this.$breadcrumb.pop();
      this.$router.back();
    },
    closeOpen(name) {
      this.$nextTick(() => {
        this[name] = false;
      });
    },
    loadInitData() {
      this.isError = false;
      this.loading = true;
      Promise.all(
        [
          this._getData_datasourcesList(),
          this._getData_taskCount()
        ]).then((res) => {
        if(this.datasources.length > 10){
          this.datasourceCombo = true;
        }
        if (this.datasources.length>0) {
          this.$nextTick(() => {
            this.render(this.getRanderData());
            this.loading = false;
          })
        } else {
          this.loading = false;
          this.isError=true
        }
      })
    },
    initGraph() {
      if (this.graph) {
        this.graph.destroy();
        this.graph = null;
      }
      this.toolbarButton = this.getToolbarButton();
      this.graph = new G6.Graph({
        container: this.$refs.dataAssetsMap,
        ...this.getGraphOption(),
        // plugins: [ this.toolbar ]
      });
      this.bindEvents();
    },
    getToolbarButton() {
      return ToolbarButtons["level_" + this.level] || {};
    },
    getGraphOption() {
      return GraphOptions["level_" + this.level] || [];
    },
    setLayout() {
      if (this.currentLayou !== this.level) {
        this.currentLayou = this.level;
        let layoutConfig = LayoutConfigs["level_" + this.level];
        if (layoutConfig) {
          this.graph.updateLayout(layoutConfig);
        }
      }
    },
    render(data) {
      if (!this.graph) {
        this.initGraph();
      }
      this.setLayout();
      this.graph.data(data);
      this.graph.render();
    },
    clearRender(data) {
      this.graph.clear();
      this.render(data || this.getRanderData());
    },
    toolbarClick(item) {
      this._event_toolbarClick(item.code);
    },
    searchTableNameChange() {
      if (this.searchTableId) {
        this.graph.getNodes().forEach((node) => {
          let model = node.getModel();
          node.setState("active", false);
          if (model.id === this.searchTableId) {
            this.graph.focusItem(model.id, true);
            node.setState("active", true);
          }
        });
      }
    },
    remoteMethod(query) {
      this.searchTables = [];
      let searchTables = [];
      this.tables.forEach((item) => {
        if (item.label.indexOf(query) > -1) {
          searchTables.push(item);
        }
      });
      this.searchTables = searchTables;
    },
    getToolbarShow(item) {
      if (item.show === undefined) {
        return true;
      }
      if (typeof item.show === "function") {
        return item.show(this);
      }
      return item.show;
    },
    searchTable() {
      let tables = [];
      if (this.keyword) {
        this.tables.forEach((item) => {
          if (item.tableName.indexOf(this.keyword) > -1) {
            tables.push(item);
          }
        });
      } else {
        tables = this.tables;
      }
      this._setTableData(tables);
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner {
  line-height: 32px;
  height: 32px;
}
.el-input-group__append .el-select,
.el-input-group__append .el-button,
.el-input-group__prepend .el-select,
.el-input-group__prepend .el-button {
  display: inline-block;
  margin: -10px -28px;
}
/deep/ .el-button--text.is-disabled {
  color: #ccc !important;
}
</style>

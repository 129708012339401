<template>
  <basic-container isCard>
    <avue-crud
      :option="option"
      ref="crud"
      :data="data"
      :page.sync="page"
      :search.sync="search"
      @refresh-change="refreshChange"
      @search-change="searchChange"
      @search-reset="searchReset"
      @selection-change="selectionChange"
      @on-load="onLoad"
    >
      <template slot-scope="scope" slot="menu">
        <el-button
          type="text"
          size="small"
          icon="el-icon-view"
          class="none-border"
          @click="view(scope.row)"
          >查看
        </el-button>
      </template>
    </avue-crud>

    <el-drawer :visible.sync="drawerDia" size="60%" :withHeader="false">
      <el-header class="bor-b" height="50px">
        <el-page-header
          class="lh-50"
          @back="close"
          :content="title"
        ></el-page-header>
      </el-header>
      <checkoutDetail :id="currentRow.id"></checkoutDetail>
    </el-drawer>
  </basic-container>
</template>
<script>
import {
  getMetaCommonList,
  delMetaCommon,
  updateMetaCommon,
  getMetaCommonInfo,
} from "@/api/busiMode/metaCommon";
import { mapGetters } from "vuex";
import addEnterprise from "./addEnterprise";
import * as dictbizService from "@/api/system/dictbiz";
import * as dictService from "@/api/system/dictbiz";
import bankInfo from "./addEnterprise/bankInfo.vue";
import archives from "./archives.vue";
import { checkout } from "@/api/contract/index";
import checkoutDetail from "./checkoutDetail";

import { managementOption } from "./option";
import { optionEnter } from "./optionEnter";
const viewPortHeight =
  window.innerHeight ||
  document.documentElement.clientHeight ||
  document.body.clientHeight;
export default {
  components: { addEnterprise, bankInfo, archives, checkoutDetail },

  data() {
    return {
      data: [],
      loading: false,
      detailDia: false,
      drawerDia: false,
      viewPortHeight:
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight,
      form: {
        name: "",
        uniform_social_credit_code: "",
      },
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      model: "wisdom_park.wp_company",
      option: managementOption,
      currentRow: {},
      title: "",
      enterpriseTypes: [],
      firmSizeList: [],
      companyTechnicalTypes: [],
      companyIsPublics: [],
      qualifications: [],
      doType: "",
      search: {
        industry: "",
        name: "",
        uniform_social_credit_code: "",
      },
    };
  },
  props: {
    enter: {
      type: String,
      default() {
        return "";
      },
    },
    choose: {
      type: Boolean,
      default() {
        return false;
      },
    },
    blacklist: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  watch: {
    enter: {
      immediate: true,
      handler(val) {
        if (val == "enter") {
          console.log("111");
          this.option = JSON.parse(JSON.stringify(optionEnter));
        }
      },
    },
    choose: {
      immediate: true,
      handler(val) {
        // if (val == "enter") {
        this.option.selection = val;
        this.option.refreshBtn = !val;
        this.option.searchBtn = !val;
        this.option.searchShow = !val;
        this.option.searchShowBtn = !val;
        this.option.menu = !val;
        this.option.height = viewPortHeight * 0.6;
        this.option = JSON.parse(JSON.stringify(this.option));
        // }
      },
    },
    blacklist: {
      immediate: true,
      handler(val) {
        if (val) {
          this.option.menuWidth = 100;
        } else {
          this.option.menuWidth = 200;
        }
        this.option = JSON.parse(JSON.stringify(this.option));
      },
    },
  },
  mounted() {},
  created() {
    this.getDicList();
    this.option.menuWidth = 100;
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.code_add, false),
        viewBtn: this.vaildData(this.permission.code_view, false),
        delBtn: this.vaildData(this.permission.code_delete, false),
        editBtn: this.vaildData(this.permission.code_edit, false),
      };
    },
  },
  methods: {
    checkout(row) {
      checkout({ companyId: row.id }).then((res) => {
        this.onLoad();
      });
    },
    selectionChange(selection) {
      if (selection.length) {
        if (selection.length > 1) {
          const nowVal = selection.shift();
          this.$refs.crud.toggleRowSelection(nowVal, false);
        } else if (selection.length === 1) {
          this.$emit("selectionChange", selection);
        } else {
          this.$message.warning("只能选择一条数据进行操作!");
        }
      } else {
        this.$message.warning("请选择一条数据进行操作!");
      }
    },
    getDicList() {
      dictbizService
        .getDictionary({
          code: "firmSize",
        })
        .then((res) => {
          this.firmSizeList = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
      dictbizService
        .getDictionary({
          code: "companyIsPublic",
        })
        .then((res) => {
          this.companyIsPublics = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: Number(item["dictKey"]),
            };
          });
        });
      dictbizService
        .getDictionary({
          code: "companyTechnicalType",
        })
        .then((res) => {
          this.companyTechnicalTypes = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: Number(item["dictKey"]),
            };
          });
        });
      dictbizService
        .getDictionary({
          code: "enterpriseType",
        })
        .then((res) => {
          this.enterpriseTypes = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
      dictbizService
        .getDictionary({
          code: "qualification",
        })
        .then((res) => {
          this.qualifications = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
    },
    view(row) {
      this.drawerDia = true;
      this.$nextTick(() => (this.currentRow = row));
    },

    refreshChange() {
      this.onLoad(this.page);
    },
    close() {
      this.drawerDia = false;
      this.detailDia = false;
      this.currentRow = {};
      this.refreshChange();
    },
    searchChange(params, done) {
      let wheres = [];
      for (let key in params) {
        if (params[key]) {
          if (key == "name") {
            wheres.push({ field: key, op: "LIKE", value: params[key] });
          } else {
            wheres.push({ field: key, value: params[key] });
          }
        }
      }
      let body = {
        current: 1,
        size: this.page.pageSize,
        wheres: wheres,
      };
      this.getMetaCommonList(body);
      done();
    },
    searchReset() {
      this.onLoad();
    },
    onLoad() {
      this.loading = true;
      let body = {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: [
          {
            field: "status",
            value: 4,
          },
        ],
        orders: [{ field: "create_time", sort: "DESC" }],
      };
      console.log(this.form);
      if (this.form.name) {
        body["wheres"].push({
          field: "name",
          op: "LIKE",
          value: this.form.name,
        });
        console.log(this.form);
      }
      if (this.blacklist) {
        body["wheres"].push({
          field: "black_list_status",
          value: 1,
        });
      } else {
      }
      this.getMetaCommonList(body);
    },
    getMetaCommonList(body) {
      getMetaCommonList(this.model, body).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        data.records.forEach((e) => {
          if (e.type) {
            this.enterpriseTypes.forEach((type) => {
              if (e.type == type.value) {
                e.type = type.name;
              }
            });
          }
          if (e.qualification) {
            this.qualifications.forEach((q) => {
              if (e.qualification == q.value) {
                e.qualification = q.name;
              }
            });
          }
          if (e.firm_size) {
            this.firmSizeList.forEach((q) => {
              if (e.firm_size == q.value) {
                e.firm_size = q.name;
              }
            });
          }
          if (e.technical_type) {
            this.companyTechnicalTypes.forEach((q) => {
              if (e.technical_type == q.value) {
                e.technical_type = q.name;
              }
            });
          }
          if (e.is_public) {
            this.companyIsPublics.forEach((q) => {
              if (e.is_public == q.value) {
                e.is_public = q.name;
              }
            });
          }
        });
        this.data = data.records;
        this.loading = false;
      });
    },
    removeBlackList(row) {
      this.$confirm("确认将当前企业从黑名单中移除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let r = {
            pk: "id",
            id: row.id,
          };
          getMetaCommonInfo(this.model, r).then((res) => {
            if (res.data.code == 200) {
              let form = res.data.data;
              form.black_list_status = 0;
              let o = {
                update: form,
                primaryKeyValue: form.id,
                primaryKey: "id",
              };
              updateMetaCommon(this.model, o).then((res) => {
                this.$message({
                  type: "success",
                  message: "操作成功!",
                });
                this.onLoad(this.page);
              });
            }
          });
        })
        .catch(() => {});
    },
    jrhmd(row) {
      this.$confirm("确认将当前企业加入黑名单?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let r = {
          pk: "id",
          id: row.id,
        };
        getMetaCommonInfo(this.model, r).then((res) => {
          if (res.data.code == 200) {
            let form = res.data.data;
            form.black_list_status = 1;
            let o = {
              update: form,
              primaryKeyValue: form.id,
              primaryKey: "id",
            };
            updateMetaCommon(this.model, o).then((res) => {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.onLoad(this.page);
            });
          }
        });
      });
    },
    rowDel(row, index) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let pk = "id";
          delMetaCommon(this.model, { pk: pk, ids: [row[pk]] }).then((res) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.onLoad(this.page);
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-dialog__body {
  padding-top: 0px;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
}
.enterDetail /deep/ .el-dialog__body {
  padding: 0px;
}
/deep/ .el-drawer__body {
  padding-top: 0px;
  overflow-x: hidden !important;
}
/deep/ .el-drawer__header {
  border-bottom: 1px solid #ebebeb;
  margin-bottom: 0px;
  padding-bottom: 15px;
}
// /deep/ .avue-crud__menu {
//   display: none;
// }
</style>

<template>
    <basic-container>
        <div class="header-con">
            <div class="tabsCom">
                <avue-tabs :option="option" @change="handleChange"></avue-tabs>
            </div>
        </div>
        <el-row class="contentCom" style="margin-left: 0; margin-right: 0">
            <div class="mar-b-10">
                <span>预约时间：</span>
                <el-date-picker v-model="queryDate" type="datetime" format="yyyy-MM-dd" value-format="yyyy-MM-dd" @change="handelChangeDate()" size="small" :start-placeholder="startDate"
                                :end-placeholder="endDate" placeholder="选择日期">
                </el-date-picker>
            </div>
            <el-col :span="18" class="navListClass" style="padding-left: 0; padding-right: 0">
                <div v-for="(i, key) in navList" :key="key" class="navItem" :class="{ activeNav: currentNav === i }">
                    <!-- <img :src="i.iconUrl" alt="" /> -->

                    <el-card class="box-card" :class="{ 'mar-t-15': key > 0 }">
                        <div slot="header" class="clearfix">
                            <span class="name">{{ i.name }}</span>
                            <span v-show="i.openFlag == 'true'" class="isOK">当天可预约</span>
                            <span v-show="i.openFlag == 'false'" class="isNO">当天已约满</span>
                        </div>
                        <div v-for="(item, index) in i.timeDetail" :key="index">
                            <!-- <el-col :span="24" class="navItemClass"> -->
                            <div class="navItemClass">
                                <el-col class="w-200" :key="index">
                                    <div>
                                        <span class="timeName">{{ item.name }}</span>
                                        <span class="timeItem" :class="{
                        disTime: item.detail.status === 3,
                        noDisTime: item.detail.status === 0,
                        timeItemChecked: item.detail.checked,
                        weiTime: item.detail.status === 7,
                        xinTime: item.detail.status === 1,
                      }" @click="
                        item.detail.status > 0
                          ? checkAppted(item.detail)
                          : checkUnAppted(item.detail)
                      ">
                                            <span class="status" v-if="item.detail.status === 1">待支付</span>
                                            <span class="status" v-if="item.detail.status === 3">已预订</span>
                                            <span class="status" v-if="item.detail.status === 0">可预订</span>
                                            <span class="status" v-if="item.detail.status === 7">已预留</span>
                                        </span>
                                    </div>
                                </el-col>
                            </div>
                            <!-- </el-col> -->
                        </div>
                        <el-col :span="24" class="mar-b-15">
                            <div class="apptbtn">
                                <button class="atbtn-bor pull-right" @click="gotoPreoAppt(i)">
                                    预留
                                </button>
                                <button class="atbtn pull-right" @click="gotoMakeAppt(i)">
                                    预订
                                </button>
                            </div>
                        </el-col>
                    </el-card>
                </div>
            </el-col>
            <el-col :span="6" class="itemDetailClass" v-if="showCheckedDetail">
                <basic-container class="cards pad-15" :style="{ height: viewPortHeight - 110 + 'px' }">
                    <div class="titleri mar-b-15">
                        <div class="titleItem">预约信息</div>
                        <div class="btn" @click="cancelAppt(apptInfo)">
                            取消预订
                        </div>
                        <div class="btn" v-if="apptInfo != null && apptInfo.status == 7" @click="cancelPreoAppt(apptInfo)">
                            取消预留
                        </div>
                    </div>
                    <el-descriptions title="" :column="1" :colon="false" :labelStyle="labelStyle">
                        <el-descriptions-item label="楼栋" v-if="apptInfo != null">{{ apptInfo.buildingName }}</el-descriptions-item>
                        <el-descriptions-item label="会议室" v-if="apptInfo != null">{{ apptInfo.roomName }}</el-descriptions-item>
                        <el-descriptions-item label="时间段" v-if="apptInfo && apptInfo.unitHour"> <span v-if="apptInfo.unitHour == 1">上午</span>
                            <span v-if="apptInfo.unitHour == 2">下午</span>
                            <span v-if="apptInfo.unitHour == 0">全天</span></el-descriptions-item>
                        <el-descriptions-item label="价格" v-if="apptInfo != null">{{ apptInfo.totalPrice }}
                        </el-descriptions-item>
                        <el-descriptions-item label="状态"> <span v-if="apptInfo != null && apptInfo.status == 1">新建</span>
                            <span v-if="apptInfo != null && apptInfo.status == 3">已支付</span>
                            <span v-if="apptInfo != null && apptInfo.status == 7">会议室预留</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="预约人" v-if="apptInfo != null"><span class="yyr">{{ apptInfo.contactName }}</span>
                        </el-descriptions-item>
                        <el-descriptions-item label="预约电话" v-if="apptInfo != null">{{ apptInfo.contactPhone }}
                        </el-descriptions-item>
                        <el-descriptions-item label="备注" v-if="apptInfo != null">{{ apptInfo.extraMemo }}</el-descriptions-item>
                    </el-descriptions>

                    <!-- <div class="con">
                        <span class="label">预约人</span>
                        <span v-if="apptInfo != null">{{ apptInfo.contactName }}</span>
                    </div>
                    <div class="con">
                        <span class="label">预约电话</span>
                        <span v-if="apptInfo != null">{{ apptInfo.contactPhone }}</span>
                    </div>
                    <div class="con">
                        <span class="label">备注</span>
                        <span v-if="apptInfo != null">{{ apptInfo.extraMemo }}</span>
                    </div> -->
                </basic-container>
            </el-col>
        </el-row>

        <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" v-if="dialogVisible" width="70%" append-to-body>
            <avue-form :option="formPriceOption" @submit="priceSubmit" :defaults.sync="defaults" v-model="formPrice">
                <template slot-scope="{ size }" slot="menuForm">
                    <el-button icon="el-icon-close" :size="size" @click="shut">关闭</el-button>
                    <!--<el-button type="primary" icon="el-icon-search" :size="size" @click="searchPhone">搜索</el-button>-->
                </template>
            </avue-form>
        </el-dialog>
    </basic-container>
</template>

<script>
import * as dictbizService from '@/api/system/dictbiz';
import { mobAccCardPay, mobAccCardCancel } from '@/api/spcod/sportappoint';
import { getPage, getResCardInfo } from '@/api/spcod/spcodaccountcard';
import {
    listBuilding,
    listRoom,
    resAppoint,
    addAppoint,
    getApptDetail,
    submitPreAppoint,
    cancelPreAppoint,
    updateMeetingId,
    resOpenRoomCount,
    resPrice,
} from '@/api/spcod/codappointnew';
import { mapGetters } from 'vuex';
import {
    createMeeting,
    updateMeeting,
    statusMeeting,
    addMeetDoorAuth,
    deleteMeetDoorAuth,
} from '@/api/spcod/hkmeeting';
const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
export default {
    watch: {
        changeReserveType: {
            handler(val, oldName) {
                console.log(this.defaults);
                if (JSON.stringify(this.defaults) != '{}') {
                    if (this.defaults) {
                        if (val === '会员') {
                            this.defaults.contactPhone.display = true;
                            this.defaults.accards.display = true;
                            this.defaults.phoneName.display = true;

                            this.defaults.testName.display = false;
                            this.defaults.testPhone.display = false;
                        } else {
                            this.defaults.testName.display = true;
                            this.defaults.testPhone.display = true;

                            this.defaults.contactPhone.display = false;
                            this.defaults.accards.display = false;
                            this.defaults.phoneName.display = false;
                        }
                    }
                }
            },
            immediate: true,
            deep: true,
        },
    },
    data() {
        // 正则表达式 验证手机号
        const validateMobile = (rule, value, callback) => {
            if (!/^[1]([3-9])[0-9]{9}$/.test(value)) {
                callback(new Error('请输入正确的手机号格式'));
            } else {
                callback();
            }
        };
        return {
            apptCancelMin: 30, //默认30分钟
            dialogTitle: '预约',
            viewPortHeight: viewPortHeight,
            changeReserveType: '会员',
            dialogVisible: false,
            cardList: [],
            defaults: {},
            formPrice: {},
            labelStyle: {
                color: '#999',
                width: '80px',
            },
            formPriceOption: {
                emptyBtn: false,
                column: [
                    {
                        label: '会议室',
                        prop: 'groundNmae',
                        disabled: true,
                    },
                    {
                        label: '价格',
                        prop: 'totalPrice',
                        disabled: true,
                    },
                    {
                        label: '预定类型',
                        prop: 'appType',
                        type: 'select',
                        dicData: [
                            {
                                label: '会员',
                                value: '会员',
                            },
                            {
                                label: '散客',
                                value: '散客',
                            },
                        ],
                        // value: true,
                        change: ({ value, column, item, dic }) => {
                            this.onClickChange(value);
                        },
                        rules: [
                            {
                                required: true,
                                message: '必须选择预定类型',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '会员电话',
                        prop: 'contactPhone',
                        disabled: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入会员手机号',
                                trigger: 'blur',
                            },
                            {
                                trigger: 'blur',
                                validator: validateMobile,
                            },
                        ],
                        blur: ({ value, column }) => {
                            let _this = this;
                            if (value == undefined || value == '') return;
                            if (value.length < 11) {
                                this.$message.error('电话号码太短!');
                                return;
                            }
                            _this.searchPhone();
                        },
                    },
                    {
                        label: '会员卡',
                        prop: 'accards',
                        type: 'select',
                        dicData: [],
                        rules: [
                            {
                                required: true,
                                message: '必须选择会员卡',
                                trigger: 'blur',
                            },
                        ],
                        change: ({ value, column, item, dic }) => {
                            // this.calAppointPrice(value);
                        },
                    },
                    {
                        label: '会员称呼',
                        prop: 'phoneName',
                        disabled: true,
                    },
                    {
                        label: '预约人姓名',
                        prop: 'testName',
                        display: false,
                        rules: [
                            {
                                required: true,
                                message: '必须选择预约人姓名',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '预约电话',
                        prop: 'testPhone',
                        display: false,
                        rules: [
                            {
                                required: true,
                                message: '必须选择预约人电话',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '备注',
                        prop: 'extraMemo',
                        span: 24,
                        minRows: 2,
                        type: 'textarea',
                        rules: [
                            {
                                required: false,
                                message: '请输入订购注意事项',
                                trigger: 'blur',
                            },
                        ],
                    },
                ],
            },
            roomList: [],
            currentRoom: {},
            currentNav: 0,
            queryDate: null,
            endDate: null,
            tabsType: {},
            apptInfo: null,
            option: {
                column: [
                    {
                        label: '',
                        prop: '',
                    },
                ],
            },
            unAppted: [],
            unApptedIds: [],
            navList: [],
            timeDetail: [],
            showCheckedDetail: false,
            checkedItemDetail: {},
        };
    },
    created() {
        let _this = this;
        this.initTabsData();
        let date = new Date();
        var Y = date.getFullYear();
        var M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
        var D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        this.queryDate = Y + '-' + M + '-' + D;
        this.startDate = Y + '-' + M + '-' + D;

        date = new Date(new Date().getTime() + 24 * 60 * 60 * 1000 * 13);
        Y = date.getFullYear();
        M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
        D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        this.endDate = Y + '-' + M + '-' + D;
        // 获取时间配置
        dictbizService
            .getDictionary({
                code: 'ApptCancelAheadTimeInMin',
            })
            .then(res => {
                let dlist = res.data.data;
                dlist.forEach(d => {
                    if (d.dictValue == 'CodAppt') {
                        _this.apptCancelMin = parseInt(d.dictKey);
                    }
                });
            });
    },
    methods: {
        cancelAppt(apptInfo) {
            let _this = this;
            let bt = new Date(_this.apptInfo.beginTime).getTime() / 1000 / 60 - _this.apptCancelMin;
            let ct = new Date().getTime() / 1000 / 60;
            //if(bt<=ct){
            //_this.$message({
            //  type: "error",
            //    message: "无法在前xx分钟之前取消"
            //  });
            //return;
            //}

            _this
                .$confirm('确定取消会议室预订?', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                .then(() => {
                    mobAccCardCancel(apptInfo.id, 'COD').then(res2 => {
                        this.$message({
                            type: 'success',
                            message: res2.data.msg,
                        });
                        this.initNavList();
                        //刷新
                    });
                });
        },
        onClickChange(val) {
            console.log(val);
            this.changeReserveType = val;
        },
        cancelPreoAppt(apptInfo) {
            let _this = this;
            _this
                .$confirm('确定取消会议室预留?', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                .then(() => {
                    cancelPreAppoint(apptInfo.id).then(res2 => {
                        this.$message({
                            type: 'success',
                            message: res2.data.msg,
                        });
                        this.initNavList();
                        //刷新
                    });
                });
        },
        priceSubmit() {
            let _this = this;
            if (
                _this.formPrice.accards == undefined ||
                (_this.formPrice.accards == '' && this.changeReserveType === '会员')
            ) {
                _this.$message({
                    type: 'success',
                    message: '必须选定会员卡！',
                });
                return;
            }
            let bookObj = {};
            _this.cardList.forEach(r => {
                if (r.id === _this.formPrice.accards) {
                    _this.formPrice.cardId = r.id;
                    _this.formPrice.userId = r.userId;
                }
            });

            if (this.unAppted.length > 0) {
                if (this.unAppted.length > 1) {
                    this.formPrice.unitHour = '0';
                } else {
                    this.formPrice.unitHour = this.unAppted[0].code;
                }
            }

            _this.formPrice.orderCount = 0;
            _this.formPrice.contactName = _this.formPrice.phoneName;
            _this.formPrice.roomId = _this.currentRoom.id;
            _this.formPrice.appType = _this.changeReserveType;
            _this.formPrice.apptTime = _this.queryDate;

            addAppoint(_this.formPrice).then(res => {
                if (res.data.data.orderSn) {
                    if (this.formPrice.appType == '会员') {
                        mobAccCardPay(res.data.data.orderSn, 'COD').then(res2 => {
                            this.dialogVisible = false;
                            this.initNavList();
                            //刷新
                        });
                    }
                } else {
                    this.dialogVisible = false;
                    this.formPrice = {};
                }
                this.dialogVisible = false;
                this.initNavList(); //刷新
            });
        },
        shut() {
            this.dialogVisible = false;
            this.formPrice = {};
        },
        searchPhone() {
            let _this = this;
            let cardData = [];
            let phoneName = '';
            let cards;
            getResCardInfo(_this.formPrice.contactPhone, 0, 'COD').then(res => {
                cards = res.data.data;
                if (cards.length == 0) {
                    _this.formPrice.accards = '';
                    this.$message.error('无对应的会员卡!');
                }
                cards.forEach(r => {
                    cardData.push({
                        label: r.cardName + ',余额:' + r.balanceValue,
                        value: r.id,
                    });
                    phoneName = r.vipName;
                });
                _this.cardList = cards;
                _this.formPrice.phoneName = phoneName;
                _this.formPriceOption.column[4].dicData = cardData;
            });
        },
        calAppointPrice() {
            if (this.unAppted.length > 0) {
                let unitHour = '1';
                if (this.unAppted.length > 1) {
                    unitHour = '0';
                } else {
                    unitHour = this.unAppted[0].code;
                }
                resPrice(this.currentRoom.id, unitHour, this.queryDate).then(res => {
                    this.formPrice.priceId = res.data.data.id;
                    this.formPrice.totalPrice = res.data.data.unitPrice;
                    this.formPrice.beginTime = res.data.data.beginTime;
                    this.formPrice.endTime = res.data.data.endTime;
                    this.formPrice.groundNmae = _this.currentRoom.name;
                    _this.formPrice.contactPhone = '';
                    _this.formPrice.phoneName = '';
                    _this.formPriceOption.column[3].dicData = [];
                    _this.formPrice.accards = '';

                    _this.dialogVisible = true;
                });
                //判断时间是否超过当前时间
                // let d = new Date(req.beginTime);
                // _this.formPrice.totalPrice = _this.currentRoom.price;
            }
        },
        gotoMakeAppt(item) {
            let _this = this;
            this.currentRoom = item;
            if (this.unAppted.length > 0) {
                let unitHour = '1';
                if (this.unAppted.length > 1) {
                    unitHour = '0';
                } else {
                    unitHour = this.unAppted[0].code;
                }
                resPrice(this.currentRoom.id, unitHour, this.queryDate).then(res => {
                    console.log('res', res);

                    _this.formPrice.priceId = res.data.data.id;
                    _this.formPrice.totalPrice = res.data.data.unitPrice;
                    _this.formPrice.beginTime = res.data.data.beginTime;
                    _this.formPrice.endTime = res.data.data.endTime;
                    _this.formPrice.groundNmae = _this.currentRoom.name;
                    _this.formPrice.contactPhone = '';
                    _this.formPrice.phoneName = '';
                    _this.formPriceOption.column[3].dicData = [];
                    _this.formPrice.accards = '';

                    _this.dialogVisible = true;
                });
                //判断时间是否超过当前时间
                // let d = new Date(req.beginTime);
                // _this.formPrice.totalPrice = _this.currentRoom.price;
            }
        },
        gotoPreoAppt(item) {
            let _this = this;
            _this.currentRoom.id = item.id;

            let params = {};
            params.extraMemo = '会议室预留';
            //params.totalPrice = 0.0;
            params.orderCount = 0;
            params.roomId = _this.currentRoom.id;
            if (this.unAppted.length > 0) {
                if (this.unAppted.length > 1) {
                    params.unitHour = '0';
                } else {
                    params.unitHour = this.unAppted[0].code;
                }
            }
            _this
                .$confirm('确定提交会议室预留?', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                })
                .then(() => {
                    params.apptTime = _this.queryDate;
                    submitPreAppoint(params).then(res2 => {
                        //测试hk接口
                        // _this.createMeeting(res2.data.data.id);
                        this.$message({
                            type: 'success',
                            message: res2.data.msg,
                        });
                        this.initNavList();
                        //刷新
                    });
                });
        },
        handelChangeDate() {
            this.navList = [];
            this.initNavList();
        },
        // tabs数据
        initTabsData() {
            let _this = this;
            let data = [];
            resOpenRoomCount().then(res => {
                console.log(res);
                res.data.data.forEach(i => {
                    // if (i.roomNum > 0) {
                    let obj = {
                        label: `${i.name}(${i.totalNum}间)`,
                        typeId: i.id,
                        name: i.name,
                    };
                    data.push(obj);
                    _this.option.column = data;
                    _this.tabsType = data[0];
                    //}
                });
                //初始化
                _this.initNavList();
            });
        },
        // 左侧 数据
        initNavList() {
            let _this = this;
            _this.navList = [];
            let params = {
                status: 1,
                buildId: _this.tabsType.typeId,
            };
            listRoom(1, 100, params)
                .then(res => {
                    let rooms = res.data.data;
                    _this.roomList = rooms;

                    rooms.forEach(r => {
                        let obj = {
                            id: r.room.id,
                            name: r.room.name,
                            //iconUrl:r.pics[0].value,
                            status: 1,
                            openFrom: r.room.openBegin,
                            openEnd: r.room.openEnd,
                            price: r.codPriceDto.unitPrice,
                            openFlag: r.openFlag,
                            timeType: r.codPriceDto.timeType,
                        };
                        if (r.pics.length > 0) {
                            obj.iconUrl = r.pics[0].picUrl;
                        } else {
                            obj.iconUrl = '';
                        }
                        _this.navList.push(obj);
                    });
                    _this.navList.forEach((e, i) => {
                        this.checkNavHandle(i, e);
                    });
                })
                .catch(error => {
                    _this.roomList = [];
                });
        },
        // tabs change事件
        handleChange(column) {
            this.navList = [];
            this.tabsType = column;
            this.initNavList();
        },
        // 左侧 数据的点击事件
        checkNavHandle(index, item) {
            let _this = this;
            //清除以前选择的
            _this.unAppted = [];
            _this.showCheckedDetail = false;
            _this.timeDetail = [];
            _this.currentNav = index;
            //console.log(item);
            let appts = [];
            let timeDetail = [];
            let t = 0;
            var btime = parseInt(item.openFrom.split(':')[0]);
            var etime = parseInt(item.openEnd.split(':')[0]);
            timeDetail = [
                { name: '上午', tag: 'M', detail: { code: '1', status: 0 } },
                { name: '下午', tag: 'A', detail: { code: '2', status: 0 } },
            ];
            _this.currentRoom = item;
            console.log(item.id, this.queryDate);
            resAppoint(item.id, this.queryDate).then(res => {
                if (res.data.data.length > 0) {
                    res.data.data.forEach(r => {
                        console.log(item.id, r.roomId);
                        if (item.id == r.roomId) {
                            for (var l = 0; l < timeDetail.length; l++) {
                                if (r.unitHour == 0) {
                                    //全天
                                    timeDetail[l].detail.status = r.status;
                                    timeDetail[l].detail.id = r.id;
                                } else {
                                    if (timeDetail[l].detail.code == r.unitHour) {
                                        console.log(r.status);
                                        timeDetail[l].detail.status = r.status;
                                        timeDetail[l].detail.id = r.id;
                                    }
                                }

                                this.navList[index].timeDetail = timeDetail;
                                this.navList = JSON.parse(JSON.stringify(this.navList));
                                this.$forceUpdate();
                            }
                        }
                    });
                } else {
                    this.navList[index].timeDetail = timeDetail;
                    this.navList = JSON.parse(JSON.stringify(this.navList));
                    this.$forceUpdate();
                }

                // _this.timeDetail = timeDetail;
            });
        },
        // 已预约项的点击事件
        checkAppted(item) {
            console.log(item);
            let _this = this;
            _this.showCheckedDetail = true;
            getApptDetail(item.id).then(res => {
                _this.apptInfo = res.data.data;
                this.apptInfo.beginTime = this.apptInfo.beginTime.split(' ')[0] + ' ' + item.btstr;
                this.apptInfo.endTime = this.apptInfo.endTime.split(' ')[0] + ' ' + item.etstr;
            });
        },
        // 选择预约项
        checkUnAppted(item) {
            console.log('111');
            let _this = this;
            _this.showCheckedDetail = false;
            //初始为空
            if (_this.unAppted.length == 0) {
                item.checked = true;
                this.unAppted.push(item);
            } else {
                this.unApptedIds.forEach((e, index) => {
                    if (this.unApptedIds.indexOf(item.code) > -1) {
                        item.checked = false;
                        if (e == item.code) {
                            this.unAppted.splice(index, 1);
                        }
                    } else {
                        item.checked = true;
                        this.unAppted.push(item);
                    }
                });
            }

            let ids = [];
            this.unAppted.forEach(e => {
                ids.push(e.code);
            });
            this.unApptedIds = ids;
            this.$forceUpdate();
        },
        createMeeting(codApptId) {
            let _this = this;
            //console.log('createMeeting：',_this.roomList[_this.currentNav]);
            let hkRoomId = _this.roomList[_this.currentNav].room.hkRoomId;
            if (hkRoomId == undefined || hkRoomId == '') {
                this.$message.error('为对应HK会议室!');
                return;
            }
            getApptDetail(codApptId).then(res => {
                let appt = res.data.data;
                //console.log(appt);
                let req = {};
                req.roomId = hkRoomId;
                req.meetingId = appt.id + '';
                req.name = _this.roomList[_this.currentNav].room.fullName;
                //req.name = '第一会议室';
                req.title = appt.extraMemo;
                req.moderator = '管理员';
                req.members = '001';
                req.startTime = appt.beginTime.substring(0, 10) + 'T' + appt.beginTime.substring(11, 19) + '+08:00';
                req.endTime = appt.endTime.substring(0, 10) + 'T' + appt.endTime.substring(11, 19) + '+08:00';
                //req.startTime = appt.beginTime;
                //req.endTime = appt.endTime;
                req.signType = '2';
                req.startSignTime = '30';
                req.laterSignTime = '30';
                //console.log(req);
                createMeeting(req).then(res2 => {
                    if (res2.data.data.length > 0) {
                        let meetingId = res2.data.data[0].meetingId;
                        updateMeetingId(codApptId, meetingId).then(resMeeting => {
                            console.log(resMeeting);
                        });
                    }
                });

                //加入人脸
                let hkObj = {};
                hkObj.roomId = appt.roomId;
                hkObj.contactPhone = '13888889911'; //模拟jxlong
                hkObj.beginTime = appt.beginTime;
                hkObj.endTime = appt.endTime;
                addMeetDoorAuth(hkObj).then(hkRes => {
                    console.log(hkRes);
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.header-con {
    display: flex;
    width: 100%;
    position: relative;
    padding-right: 20px;
    height: 50px;
    .tabsCom {
        flex: 1;
        width: calc(100% - 300px);
        > div {
            width: 100%;
        }

        /deep/ .el-tabs__nav-wrap::after {
            display: none;
        }
    }
    .dateCom {
        //width: 300px;
        height: 40px;
        padding-top: 5px;
        text-align: right;
        .el-date-editor {
            width: 180px;
        }
    }
    &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 10px;
        width: 100%;
        height: 1px;
        background-color: #e4e7ed;
        z-index: 1;
    }
}

.con {
    margin-bottom: 10px;
}
.contentCom {
    .navListClass {
        max-height: 70vh;
        overflow-y: scroll;
        img {
            width: 100%;
        }
        .navItem {
            // border: 1px solid #c1c1c1;
            .titleNav {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 14px 8px;
                .name {
                    color: #878787;
                }
                .isOK {
                    color: #1fbfbe;
                }
                .isNO {
                    color: #f39a37;
                }
            }
            &.activeNav {
                //background-color: #67D9D8;
                border: 1px solid #00bfbf;
            }
        }
    }
    /**右边*/
    .itemDetailClass {
        // border: 1px solid #c1c1c1;
        font-size: 15px;
        > div {
            margin-bottom: 15px;
            padding: 8px;
        }
        .titleri {
            display: flex;
            justify-content: space-between;
        }
        .titleItem {
            color: #878787;
        }
        .label {
            display: inline-block;
            width: 35%;
            text-align: right;
            padding-right: 20px;
        }
        .btn {
            //color: #0090ff;
            //width: 70px;
            //height: 20px;
            //font-size: 10px;
            //font-weight: bold;
            //background: #0090FF;
            //margin-left: 70%;
            color: #0090ff;
            border: 1px solid #0090ff;
            padding: 5px;
            font-size: 12px;
        }
    }
    /**中间 */
    .navItemClass {
        font-size: 15px;
        > div {
            //  border: 1px solid #c1c1c1;
            padding: 8px;
        }
        .titleItem {
            color: #878787;
            font-weight: bold;
        }
        .timeName {
            padding-left: 20px;
            text-align: right;
        }

        .timeCom {
            font-size: 15px;
            font-weight: bold;
            display: flex;
            flex-wrap: wrap;
            margin-top: 10px;
            > div {
                flex: 1;
                display: flex;
                flex-wrap: wrap;
            }
        }
        .timeItem {
            width: 120px;
            height: 35px;
            // display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 10px;
            cursor: pointer;
            padding: 10px 35px;
            margin-bottom: 10px;
            .time,
            .status {
                text-align: center;
            }
        }
        .disTime {
            border: 1px solid #868687;
            background: #f2f2f2;
            .time,
            .status {
                color: #868687;
            }
        }
        .noDisTime {
            border: 1px solid #1fbfbe;
            background: #fff;
            .time,
            .status {
                color: #1fbfbe;
            }
        }
        .timeItemChecked {
            background: #1fbfbe;
            .time,
            .status {
                color: #fff;
            }
        }
        .weiTime {
            border: 1px solid red;
            background: #fff;
            .time,
            .status {
                color: red;
            }
        }
        .xinTime {
            border: #fcc608 1px solid;
            background: #fff;
            .time,
            .status {
                color: #fcc608;
            }
        }
        .apptbtn {
            display: flex;
            padding-left: 50px;
            margin-top: 30px;
        }
    }
}
.atbtn {
    width: 70px;
    height: 30px;
    font-size: 14px;
    background: #0090ff;
    margin-left: 10px;
    border: 1px solid #0090ff;
    color: #fff;
}
.atbtn-bor {
    width: 70px;
    height: 30px;
    font-size: 14px;
    margin-left: 10px;
    background: #fff;
    border: 1px solid #0090ff;
    color: #0090ff;
}
.cards {
    background: #fff;
    margin-bottom: 15px;
}
.yyr {
    word-wrap: break-word;
}
/deep/ .el-descriptions-item__content {
    width: calc(100% - 80px);
}
</style>

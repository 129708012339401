<template>
  <div>
    <el-dialog
      append-to-body
      title="上传"
      :visible.sync="dialogViewVisible"
      v-if="dialogViewVisible"
      custom-class="dis-b"
    >
      <avue-form
        ref="form"
        v-model="obj"
        :option="formOption"
        @submit="submitHandle"
        @reset-change='emptyHandle'
      >
        <template slot="tableName">
          <!-- :remote-method="remoteMethod"
            @focus='focusHandel'
            remote -->
          <el-select
            v-model="obj.tableName"
            filterable
            reserve-keyword
            clearable
            placeholder="请输入关键词"
            :loading="loading">
            <el-option
              v-for="item in tableArr"
              :key="item"
              :label="item"
              :value="item">
            </el-option>
          </el-select>
        </template>
        <template slot="file">
          <el-upload
            class="upload-demo"
            accept=".xls,.xlsx,.csv,.txt"
            action="#"
            :auto-upload="false"
            :on-change="changeFile"
            :on-remove="handleRemove"
            ref='upload'
          >
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">
              请上传excel、csv和txt格式的数据文件
            </div>
          </el-upload>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>
<script>
import {
  queryDataBaseByDataHierarchyId,
  metadataGetTables,
  uploadFileToDb
} from "@/api/dws/datalayer";
export default {
  props: ["datahierarchyId"],
  data() {
    return {
      dialogViewVisible: false,
      obj: {
        tableName: ''
      },
      formOption: {
        column: [
          {
            label: "数据库",
            prop: "resourceId",
            span: 24,
            type: "select",
            dicData: [],
            props: {
              label: "name",
              value: "dataBaseId",
            },
            rules: [
              {
                required: true,
                trigger: "change",
                message: "请选择数据库",
              },
            ],
          },
          {
            label: "数据表",
            prop: "tableName",
            span: 24,
            type: "select",
            formslot: true,
            rules: [
              {
                required: true,
                trigger: "change",
                message: "请选择数据表",
              },
            ],
          },
          {
            label: "导入方式",
            prop: "uploadType",
            type: "select",
            dicData: [],
            span: 24,
            rules: [
              {
                required: true,
                message: "请选择导入方式",
              },
            ],
          },
          {
            label: "忽略标题",
            span: 24,
            prop: "ignoreFlag",
            type: "switch",
            value: true,
            dicData: [
              {
                label: "否",
                value: false,
              },
              {
                label: "是",
                value: true,
              },
            ],
          },
          {
            label: "列分隔符",
            span: 24,
            prop: "fileTerminated",
            rules: [
              {
                required: true,
                message: "请输入列分隔符",
              },
            ],
          },
          {
            label: "分区字段",
            span: 24,
            prop: "partitionVColmun",
            display: false,
          },
          {
            label: "分区值",
            span: 24,
            prop: "partitionValue",
            display: false,
          },
          {
            label: "文件",
            prop: "file",
            span: 24,
            formslot: true,
            rules: [
              {
                required: true,
                message: "请上传文件",
              },
            ],
          },
        ],
      },
      tableArr: [], //数据表 列表数据
      resourceArr: [], //数据库 列表数据
      loading: false,
      tableName: '', //数据表
      resourceObj: {},
      queryName: '',// 数据表的搜索字段
      fileList: []
    };
  },
  watch: {
    dialogViewVisible(newVal, oldVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.updateDicHandle();
        });
      }
      if (oldVal) {
        this.obj = {
          resourceId: "",
          tableName: "",
          file: "",
        };
      }
    },
    "obj.resourceId": function (newVal, oldVal) {
      this.resourceObj = this.resourceArr.filter((i) => {
        return i.dataBaseId == newVal;
      });
      var form = this.$refs.form;
      this.obj.tableName = "";
      let uploadTypeDic =[]
      this.obj.partitionVColmun=''
      this.obj.partitionValue=''
      this.obj.uploadType=''
      this.queryName=''
      this.findObject(this.formOption.column, "partitionVColmun").display = false
      this.findObject(this.formOption.column, "partitionValue").display = false
      this.$nextTick(() => {
        form.updateDic("uploadType", []);
      })
      if (Array.isArray(this.resourceObj) && this.resourceObj.length > 0) {
        this.dataBaseHandle(this.resourceObj[0]);
          if (this.resourceObj[0].type == "HIVE") {
            uploadTypeDic = [
              {
                value: "OVERWRITE",
                label: "覆盖",
              },
              {
                value: "APPEND",
                label: "追加",
              },
            ];
            this.findObject(this.formOption.column, "partitionVColmun").display = true
            this.findObject(this.formOption.column, "partitionValue").display = true
            this.$nextTick(() => {
              form.updateDic("uploadType", uploadTypeDic);
              form.updateDic("resourceId", this.resourceArr);
            })
          } else if (this.resourceObj[0].type == "MYSQL") {
            uploadTypeDic = [
              {
                value: "REPLACE",
                label: "重复替换",
              },
              {
                value: "IGNORE",
                label: "重复忽略",
              },
            ];
            this.findObject(this.formOption.column, "partitionVColmun").display = false
            this.findObject(this.formOption.column, "partitionValue").display = false
            this.$nextTick(() => {
              form.updateDic("uploadType", uploadTypeDic);
              form.updateDic("resourceId", this.resourceArr);
            })
          } else {
            this.findObject(this.formOption.column, "partitionVColmun").display = false
            this.findObject(this.formOption.column, "partitionValue").display = false
            this.$nextTick(() => {
              form.updateDic("resourceId", this.resourceArr);
            })
          }
      } else {
          this.findObject(this.formOption.column, "partitionVColmun").display = false
          this.findObject(this.formOption.column, "partitionValue").display = false
          this.$nextTick(() => {
            form.updateDic("resourceId", this.resourceArr);
          })
      }
    },
    'obj.tableName': function (newVal, oldVal) {
      console.log(newVal)
      console.log('this.queryName',this.queryName)
      if ((Array.isArray(this.resourceObj) && this.resourceObj.length > 0)&&newVal=='') {
        let query=''
        this.remoteMethod (query)
      }
    },
  },
  mounted() {},
  methods: {
    // 提交
    submitHandle(form,done) {
      let params = {
        file: this.obj.file.raw,
        resourceId: this.obj.resourceId,
        tableName: this.obj.tableName,
        uploadType: this.obj.uploadType,
        fileTerminated: this.obj.fileTerminated,
        ignoreFlag: this.obj.ignoreFlag,
        partitionVColmun: this.obj.partitionVColmun,
        partitionValue: this.obj.partitionValue,
      }
      uploadFileToDb(params).then((res) => {
        console.log("res",res)
        if (res.data.code==0) {
          this.$message.success(res.data.msg)
          done()
          this.dialogViewVisible=false
        } else {
          this.$message.warning(res.data.msg)
          done()
        }
      }).catch((error) => {
        done()
      })
    },
    // 清空事件
    emptyHandle () {
      this.obj.file=''
      this.obj.ignoreFlag=true
      this.$refs.upload.clearFiles();
    },
    // 文件上传
    changeFile(file, fileList) {
      this.obj.file = file;
      this.fileList=fileList
      if (this.fileList.length > 1) {
          this.fileList.splice(0, 1);
      }
    },
    // 文件删除
    handleRemove () {
      this.obj.file=''
    },
    // 获取 该层下的数据库
    updateDicHandle() {
      var form = this.$refs.form;
      queryDataBaseByDataHierarchyId({
        id: this.datahierarchyId,
      }).then((res) => {
        this.resourceArr = res.data.data || [];
        form.updateDic("resourceId", this.resourceArr);
      }).catch((error) => {
        form.updateDic("resourceId", []);
      });
    },
    // 数据表的远程搜索
    remoteMethod (query) {
      this.queryName=query
      this.loading = true;
      this.dataBaseHandle(this.resourceObj[0])
    },
    focusHandel () {
      if (this.tableArr.length==0) {
        this.queryName=''
        this.dataBaseHandle(this.resourceObj[0])
      }
    },
    // 获取 数据层下数据库的数据表
    dataBaseHandle(row) {
      let obj = {
        datasourceId: row["dataBaseId"]
      };
      metadataGetTables(obj)
        .then((res) => {
          var form = this.$refs.form;
          this.tableArr = res["data"]["data"] || [];
          this.loading = false
          // form.updateDic("tableName", this.tableArr);
        })
        .catch((e) => {
          this.tableArr = []
        });
    },
  },
};
</script>
<style lang="scss" scoped>
</style>

<template>
  <el-container style="height: calc(100% - 50px)" v-loading="pageLoading">
    <el-main class="pad-20 ov-a">
      <el-form class="mar-t-20" :model="form" :rules="rules" ref="form" :size="formSize" label-width="100px">
        <el-row>
          <el-col :span="8">
            <el-form-item label="领用单据号" prop="code">
              <el-input v-model="form.code" disabled placeholder="系统自动生成"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="领用时间" prop="collection_date">
              <el-date-picker :disabled="disabled" class="dis-b w-100p" v-model="form.collection_date" type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="计划领用时长" prop="plan_collection_cycle">
              <el-input type="number" v-model.number="form.plan_collection_cycle" :disabled="disabled" placeholder="请输入计划领用时长">
                <template slot="append">天</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item label="领用部门" prop="collection_dept_id">
              <model-cascader
                :option="{
                  emitPath: false
                }"
                :show-all-levels="false"
                :disabled="disabled"
                :getData="collectionDeptIdData"
                v-model="form.collection_dept_id"
                labelKey="title"
                @change="(ev) => {form['collection_dept_name'] = ev.label;form.collection_user_id = '';}"
              >
              </model-cascader>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="领用人" prop="collection_user_id">
              <model-search-select
                class="dis-b w-100p"
                :disabled="disabled"
                v-model="form.collection_user_id"
                format="{name}_{deptName}_{postName}"
                :params="{tenantId: '000000' , deptId: form.collection_dept_id}"
                :fillModel.sync="form"
                :fills="[
                  {source: 'name' , target: 'collection_user_name'},
                ]"
              >
                <template slot-scope="{item}">
                  <span style="float: left">{{ item.name }}{{item.postName ? `(${item.postName})` : ''}}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px">{{ item.deptName }}</span>
                </template>
              </model-search-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="领用说明" prop="remarks">
              <el-input
                type="textarea"
                :rows="5"
                :disabled="disabled"
                placeholder="请输入内容"
                v-model="form.remarks">
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="pad-tb-10">
          <el-divider content-position="left">领用资产(共{{listDetailValues.length}}条)</el-divider>
        </div>
        <el-form-item label-width="0px">
          <el-table
            :data="listDetailValues"
            :size="formSize"
            border
          >
            <el-table-column v-if="!disabled" type="index" width="100">
              <template slot="header">
                <el-button size="mini" @click="addListDetailValue()" type="primary" icon="el-icon-plus" circle></el-button>
              </template>
              <template slot-scope="scope">
                <el-tooltip class="item" effect="dark" content="以此行新增" placement="top-start">
                  <el-button size="mini" @click="addListDetailValueInRow(scope.row)" type="primary" icon="el-icon-plus" circle></el-button>
                </el-tooltip>
                <el-button size="mini" @click="listDetailValues.splice(scope.$index , 1);" type="danger" icon="el-icon-minus" circle></el-button>
              </template>
            </el-table-column>
            <el-table-column v-if="!disabled" label="资产" width="210px">
              <template slot-scope="scope">
                <model-search-select
                  class="dis-b w-100p"
                  v-model="scope.row.asset_id"
                  model="wp_asset_detail"
                  :fillModel.sync="scope.row"
                  labelKey="asset_name"
                  valueKey="id"
                  :fills="[
                    {source: 'asset_name' , target: 'asset_name'},
                    {source: 'system_asset_code' , target: 'system_asset_code'}
                  ]"
                  :disabledList="listDetailValues.map(item => item.asset_id)"
                >
                  <template slot-scope="{item}">
                    <span style="float: left">{{ item.asset_name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.system_asset_code }}</span>
                  </template>
                </model-search-select>
              </template>
            </el-table-column>
            <el-table-column label="领用说明" prop="remarks">
              <template slot-scope="scope">
                <el-input v-if="!disabled" :disabled="disabled" v-model="scope.row.remarks"></el-input>
                <span v-else>{{scope.row.remarks}}</span>
              </template>
            </el-table-column>
            <el-table-column label="资产位置信息" prop="location_name">
              <template slot-scope="scope">
                <LocationCascader v-if="!disabled" :disabled="disabled" v-model="scope.row.location_name_code" @change="(data) => cascaderChange(data , scope.row)"></LocationCascader>
                <span v-else>{{scope.row.location_name}}</span>
              </template>
            </el-table-column>
            <el-table-column label="资产名称" width="150px" prop="asset_name"></el-table-column>
            <el-table-column label="资产编码" width="150px" prop="system_asset_code"></el-table-column>
          </el-table>
        </el-form-item>
      </el-form>
    </el-main>
    <el-footer v-if="!isView" height="50px" class="lh-50">
      <div class="text-center">
        <el-button :size="formSize" :loading="btnLoading" icon="el-icon-close" @click="back">取 消</el-button>
        <el-button :size="formSize" :loading="btnLoading" icon="el-icon-check" type="primary" @click="saveForm(false)">保 存</el-button>
        <el-button :size="formSize" :loading="btnLoading" icon="el-icon-check" type="primary" @click="saveForm(true)">保存并提交审批</el-button>
      </div>
    </el-footer>
  </el-container>
</template>
<script>

import dayjs from 'dayjs';
import * as deptService from "@/api/system/dept";
import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  delMetaCommon,
  getMetaCommonListTree,
  getMetaCommonDetail
} from "@/api/busiMode/metaCommon";

import { searchList } from '@/api/system/user'
import { uuid } from '@/util/util';

import { startFlow } from '@/api/flow/flow.js';

export default {
  data(){
    return {
      pageLoading: false,
      btnLoading: false,
      loading: {
        userLoading: false
      },
      form: {
        code: `TH-${dayjs().format('YYYYMMDD')}${uuid(6 , '1234567890')}`,
        remarks: '',
        collection_date: dayjs().format('YYYY-MM-DD 00:00:00'),
        plan_collection_cycle: null,
        collection_user_id: null,
        collection_user_name: '',
        collection_dept_id: '',
        collection_dept_name: ''
      },
      listDetailValues: [],
      dictData: {
        userList: []
      },
      catalogModel: 'wisdom_park.wp_asset_category',
      model: 'wisdom_park.wp_asset_collection',
      detailTable: 'wp_asset_collection_detail',
      masterIdColumnName: 'collection_id',
      listValue: {
        field: ['asset_id' , 'system_asset_code' , 'asset_name' , 'remarks' , {
          type: 'arrarObj' , code: 'location_name_code' , isDel: true , field: ['location_build_id' , 'location_floor_id' , 'location_room_id']
          } , 'location_name'],
        relate: 'collection_id',
        master: ['asset_id' , 'location_name_code']
      }
    }
  },
  computed: {
    disabled(){
      return this.isTodo || this.isView || false
    },
    isView(){
      return this.isTodo ? this.isTodo : this.view
    },
  },
  props: {
    id: {
      type: String,
      default: ''
    },
    formSize: {
      type: String,
      default: 'small'
    },
    view: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: true
    },
    isTodo: {
      type: Boolean,
      default: false
    },
    rules: {
      type: Object,
      default(){
        return {}
      }
    }
  },
  mounted(){
    if(this.isAdd){
      this.addListDetailValue()
      // for(let i = 5; i > 0; i--){
      //   this.addListDetailValue()
      // }
    }
    if(this.id){
      this.form.code = '';
      this.getMetaCommonDetail();
    }
  },
  methods: {
    collectionDeptIdData(ev , done){
      deptService.getDeptTree().then(res => {
        done(res.data.data || []);
      })
    },
    cascaderChange(data , row){
      row.location_name = data.label;
    },
    getMetaCommonDetail(){
      this.pageLoading = true;
      getMetaCommonDetail(this.model , {
        pk: 'id',
        id: this.id,
        detailTable: this.detailTable,
        masterIdColumnName: this.masterIdColumnName,
      }).then(
        res => {

          let data = res.data.data;
          let listDetailValues = data['listDetailValues'] || [];

          delete data['listDetailValues'];
          
          this.form = data;

          listDetailValues.forEach(
            item => {
              this.listValue.field.forEach(
                fieldItem => {
                  if(typeof fieldItem === 'string'){
                    fieldItem = {
                      type: 'string',
                      code: fieldItem,
                      field: []
                    }
                  }
                  if(fieldItem.type === 'arrar'){
                    item[fieldItem.code] = item[fieldItem.code].split(fieldItem.separate || '-');
                  }
                  if(fieldItem.type === 'arrarObj'){
                    item[fieldItem.code] = [];
                    if(fieldItem.field){
                      fieldItem.field.forEach(
                        fieldName => {
                          if(item[fieldName]){
                            item[fieldItem.code].push(item[fieldName]);
                          }
                        }
                      )
                    }
                  }
                }
              )
            }
          )
          this.listDetailValues = listDetailValues;
          this.pageLoading = false;
        }
      ).catch(e => {
        this.pageLoading = false;
      })
    },
    addListDetailValueInRow(row){
      this.addListDetailValue({
        location_name: row.location_name,
        location_name_code: this.deepClone(row.location_name_code),
        remarks: row.remarks
      })
    },
    addListDetailValue(data){
      let itemObj = {};
      data = data || {};
      this.listValue.field.forEach(
        item => {
          if(typeof item === 'string'){
            item = {
              type: 'string',
              code: item
            }
          }
          switch(item.type){
            case 'string':
              itemObj[item.code] = data[item.code] !== undefined ? data[item.code] : '';
              break;
            case 'number':
              itemObj[item.code] = data[item.code] !== undefined ? data[item.code] : null;
              break;
            case 'arrar':
              itemObj[item.code] = data[item.code] !== undefined ? data[item.code] : [];
              break;
            case 'arrarObj':
              itemObj[item.code] = data[item.code] !== undefined ? data[item.code] : [];
              break;
          }
        }
      )
      this.listDetailValues.push(itemObj)
    },
    getListDetailValues(){
      let listDetailValues = [];
      this.listDetailValues.forEach(
        item => {
          let master = [];
          if(typeof this.listValue.master === 'string'){
            master = [this.listValue.master]
          }else{
            master = this.listValue.master;
          }
          let add = true;
          master.forEach(
            name => {
              if(item[name] === '' || item[name] === null || item[name] === undefined){
                add = false;
              }
            }
          )
          if(add){
            let newItem = {};
            this.listValue.field.forEach(
              fieldItem => {
                if(typeof fieldItem === 'string'){
                  fieldItem = {
                    type: 'string',
                    code: fieldItem,
                    field: []
                  }
                }
                switch(fieldItem.type){
                  case 'string':
                    newItem[fieldItem.code] = item[fieldItem.code];
                    break;
                  case 'number':
                    newItem[fieldItem.code] = Number(item[fieldItem.code]);
                    break;
                  case 'arrar':
                    newItem[fieldItem.code] = item[fieldItem.code].join(fieldItem.separate || '-');
                    break;
                  case 'arrarObj':
                    fieldItem.field.forEach(
                      (fieldName , index) => {
                        if(item[fieldItem.code][index]){
                          newItem[fieldName] = item[fieldItem.code][index];
                        }
                      }
                    )
                    break;
                }
              }
            )
            if(item.id){
              newItem['id'] = item.id;
            }
            if(item[this.listValue.relate]){
              newItem[this.listValue.relate] = item[this.listValue.relate];
            }
            listDetailValues.push(newItem)
          }
        }
      )
      return listDetailValues;
    },
    startFlow(row){
      return startFlow(row.id , this.$PCode.BIZ.ASSET_COLLECTION , this.$PCode.ACTIONS.SUBMIT);
    },
    back(){
      this.$emit('back');
    },
    saveForm(isStartFlow){
      let listDetailValues = this.getListDetailValues();
      if(!listDetailValues.length){
        this.$message({
          type: 'error',
          message: '请完善领用资产'
        })
        return false;
      }

      this.$refs.form.validate(valid => {
        if(valid){
          let body = {
            ...this.form
          };
          if(this.form.id){
            delete body.id;
            body = {
              update: body,
              primaryKeyValue: this.form.id,
              primaryKey: "id",
              listDetailValues: listDetailValues
            };
          }else{
            body = {
              values: body,
              listDetailValues: listDetailValues
            }
          }
          this.btnLoading = true;
          (this.form.id ? updateMetaCommon : saveMetaCommon)(this.model , {
            ...body,
            detailTable: this.detailTable,
            masterIdColumnName: this.masterIdColumnName,
          }).then((res) => {
            if(isStartFlow){
              return this.startFlow(this.form.id ? this.form : res.data.data).then(res1 => res1);
            }else{
              return res;
            }
          }).then(res => {
            this.btnLoading = false;
            this.$message.success(res.data.msg);
            this.back();
          }).catch((e) => {
            this.btnLoading = false;
          });
        }else{
          return false;
        }
      })

    }
  }
}
</script>
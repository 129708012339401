<template>
  <div class="asset-details h-100p">
    <el-row class="h-100p" :gutter="$store.state.common.gutter">
      <el-col :span="24" class="h-100p">
        <div class="h-100p f-l w-300" style="margin-bottom: 0;">
          <el-card
            v-loading="treeLoading"
            class="h-100p ov-a"
            shadow="never"
            :body-style="{ padding: '15px' }"
          >
            <el-tree
              ref="tree"
              node-key="id"
              :props="{ children: 'children', label: 'name', isLeaf: 'isLeaf' }"
              lazy
              :default-expanded-keys="defaultExpandedId"
              :load="loadNode"
              @node-click="handleNodeClick"
            >
            </el-tree>
          </el-card>
        </div>
        <div class="h-100p  mar-l-308 ov-a" style="margin-bottom: 0;">
          <el-card class="mar-b-8" v-if="currentDatabase" :body-style="{'padding': '8px'}">
            <el-row type="flex">
              <el-col style="width: 50px;" class="mar-b-0 pad-lr-5">
                <div style="width: 40px;height: 40px;border-radius: 50%;top: 3px;position: relative;" class="pr bor text-center lh-45">
                  <i class="el-icon-chat-line-round fts-26 text-blue"></i>
                </div>
              </el-col>
              <el-col class="mar-l-8 mar-b-0">
                <span class="text-blue">{{currentDatabase.dhName}}</span> 的 <span class="text-blue">{{currentDatabase.name}}</span> 库累计占用存储空间 <span class="text-blue fts-16">{{gsData.dataSize}}</span> ，
                累计 <span class="text-blue fts-16">{{gsData.dataRows | numConvertToStr(2 , true)}}</span> {{gsData.dataRows | numConvertToUnit}} 条数据，分布在 <span class="text-blue fts-16">{{gsData.tableNum | numConvertToStr(2 , true)}}</span> 张表、<span class="text-blue fts-16"> {{gsData.columnNum | numConvertToStr(2 , true)}}</span> {{gsData.columnNum | numConvertToUnit}}个字段中，
                其中存在敏感数据的表共 <span class="text-blue fts-16">{{gsData.involvedTableNum | numConvertToStr(2 , true)}}</span> 张、<span class="text-blue fts-16">{{gsData.sensitiveColumnNum | numConvertToStr(2 , true)}}</span> {{gsData.sensitiveColumnNum | numConvertToUnit}}个字段，占字段总数<span class="text-blue fts-16"> {{getSensitiveColumnNumZb()}}% </span>，
                较上一统计周期<span :class="{'text-green': getSensitiveColumnNumBj().value > 0 , 'text-red': getSensitiveColumnNumBj().value < 0}">{{getSensitiveColumnNumBj().value < 0 ? '减少' : '增加'}} </span>
                <span class="text-blue fts-16">{{getSensitiveColumnNumBj().absValue | numConvertToStr(2 , true)}}</span> {{getSensitiveColumnNumBj().absValue | numConvertToUnit}}个字段。
              </el-col>
            </el-row>
          </el-card>
          <el-card
            class="ov-a"
            shadow="never"
            :body-style="{ padding: '15px' }"
          >
            <avue-crud
              ref="table"
              :data="tables"
              :page.sync="page"
              :option="tablesOption"
              :table-loading="contentLoading"
              @on-load="showTables"
              @refresh-change="showTables"
            >
              <template slot="menuLeft">
                <el-input
                  v-model="searchVal"
                  @keyup.native.enter="showTables"
                  size="small"
                  :placeholder="$t('Please enter keyword')"
                  type="text"
                  style="width:180px;"
                >
                  <el-button
                    class="mar-0 pad-0"
                    size="small"
                    @click="showTables"
                    slot="append"
                    icon="el-icon-search"
                  ></el-button>
                </el-input>
              </template>
              <template slot="tableName" slot-scope="scope">
                <a class="cur-p" @click="view(scope.row)">{{
                  scope.row.tableName
                }}</a>
              </template>
              <template slot="menu" slot-scope="scope">
                <el-button type='text' size='mini' icon='el-icon-s-unfold'>导出</el-button>
              </template>
              <template slot="columnNum" slot-scope="{row}">
                {{row.columnNum | numConvert}}
              </template>
              <template slot="dataSize" slot-scope="{row}">
                {{row.dataSize | numConvert}}
              </template>
              <!-- <template slot="menu" slot-scope="{ row, size }">
              <el-button
                type="text"
                icon="el-icon-view"
                @click="view(row)"
                :size="size"
                >详情</el-button
              >
            </template> -->
            </avue-crud>
          </el-card>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import {
  getDataLayerList,
  queryTablesByDataBaseIdAndDhId,
  queryDataBaseByDataHierarchyId,
  queryStatisticByDataBaseIdAndDhId
} from "@/api/dws/datalayer";

export default {
  data() {
    return {
      gsData: {
        tableNum: 0,
        sensitiveColumnNum: 0,
        sensitiveColumnHistoryNum: 0,
        columnNum: 0,
        dataSize: 0,
        dataRows: 0,
        involvedTableNum: 0
      },
      dhId: this.$router.currentRoute.query.dhId || null,
      treeLoading: false,
      contentLoading: false,
      treeData: [],
      currentDatabase: null,
      defaultExpandedId: [],
      defaultCheckedId: [],
      tables: [],
      tablesOption: {
        addBtn: false,
        editBtn: false,
        delBtn: false,
        menu: false,
        menuWidth: 80,
        column: [
          {
            label: "表名称",
            prop: "tableName",
            width: 200
          },
          {
            label: "表类型",
            prop: "typeName"
          },
          {
            label: "字段数",
            prop: "columnNum"
          },
          {
            label: "敏感字段数",
            prop: "sensitiveColumnNum"
          },
          {
            label: "数据量（条）",
            prop: "dataRows",
            width: 100
          },
          {
            label: "占用存储空间",
            prop: "dataSize",
            width: 100
          },
          {
            label: "最近一次更新时间",
            prop: "updateTime",
            width:150
          }
        ]
      },
      searchVal: "",
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      }
    };
  },
  created() {},
  mounted() {},
  methods: {
    getDataLayerList() {
      this.treeLoading = true;
      let obj = {
        dhName: "",
        pageNo: 1,
        pageSize: 10000
      };
      return getDataLayerList(obj).then(res => {
        let treeData = [];
        (res.data.data.lists || []).forEach(item => {
          treeData.push({
            ...item,
            name: item["dhName"],
            _isRoot: true
          });
        });
        this.treeLoading = false;
        return treeData;
      });
    },
    getSensitiveColumnNumBj(){
      return {
        absValue: Math.abs(this.gsData.sensitiveColumnNum - this.gsData.sensitiveColumnHistoryNum),
        value: this.gsData.sensitiveColumnNum - this.gsData.sensitiveColumnHistoryNum
      }
    },
    getSensitiveColumnNumZb(){
      if(this.gsData.sensitiveColumnNum === 0){
        return 0;
      }else{
        return Number((this.gsData.sensitiveColumnNum / this.gsData.columnNum * 100).toFixed(2));
      }
    },
    queryDataBaseByDataHierarchyId(id) {
      let obj = {
        id: id
      };
      return queryDataBaseByDataHierarchyId(obj).then(res => {
        let data = res.data.data || [];
        data.forEach(item => {
          item.id = "database_" + item.dataBaseId;
          item.isLeaf = true;
        });
        return data;
      });
    },
    handleNodeClick(data) {
      if (data.isLeaf) {
        this.currentDatabase = data;
        this.showTables();
        this.queryStatisticByDataBaseIdAndDhId();
        this.page = {
          total: 0,
          pageSize: 10,
          currentPage: 1
        };
      }
    },
    queryStatisticByDataBaseIdAndDhId(){
      if(this.currentDatabase){
        queryStatisticByDataBaseIdAndDhId({
          guid: this.currentDatabase.guid,
          databaseId: this.currentDatabase.dataBaseId
        }).then(
          res => {
            if(res.data){
              this.gsData = res.data;
            }
          }
        )
      }
    },
    view(row) {
      //this.$router.push("/data-assets/assets-detail/" + row.guid);
      this.$router.push({
        path: "/data-assets/assets-detail/" + row.guid,
        query: {
          databaseId: this.currentDatabase.dataBaseId,
          tableName: row.tableName,
          databaseName: this.currentDatabase.database
        }
      });
    },
    loadNode(node, resolve) {
      if (node.level === 0) {
        return this.getDataLayerList().then(data => {
          resolve(data);
          if (data.length) {
            if(this.dhId){
              data.forEach(
                item => {
                  if(item.id == this.dhId){
                    this.defaultExpandedId = [item.id];
                  }
                }
              )
            }else{
              this.defaultExpandedId = [data[0].id];
            }
          }
        });
      } else if (node.level === 1) {
        this.queryDataBaseByDataHierarchyId(node.data.id)
          .then(data => {
            resolve(data);
            if (data.length && !this.currentDatabase) {
              this.$refs.tree.setCurrentNode(data[0]);
              this.handleNodeClick(data[0]);
            }
          })
          .catch(e => {
            resolve([]);
          });
      } else {
        resolve([]);
      }
    },
    showTables() {
      let row = this.currentDatabase;
      this.tables = [];
      if (!row || !row["guid"]) {
        //this.$message.error('未绑定数据');
        return;
      }
      this.contentLoading = true;
      let obj = {
        id: row["dhId"],
        guid: row["guid"],
        databaseId: row["dataBaseId"],
        pageSize: this.page.pageSize,
        pageNo: this.page.currentPage,
        searchVal: this.searchVal
      };
      queryTablesByDataBaseIdAndDhId(obj)
        .then(res => {
          this.tables = res["data"]["data"] || [];
          this.page.total = res["data"]["total"];
          this.contentLoading = false;
        })
        .catch(e => {
          this.contentLoading = false;
          this.tables = [];
        });
    }
  }
};
</script>
<style lang="scss">
.f-l {
  float: left;
}
.cur-a {
  cursor: pointer;
}

</style>

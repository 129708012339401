<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" :page.sync="page" :before-open="beforeOpen" v-model="form" ref="crud" @row-update="rowUpdate" @row-save="rowSave"
                   @row-del="rowDel" @search-change="searchChange" @search-reset="searchReset" @current-change="currentChange" @size-change="sizeChange" @refresh-change="refreshChange"
                   @on-load="onLoad">
            <template slot="menuLeft">
                <el-button type="danger" size="small" icon="el-icon-delete" plain v-if="permission.epmallstore_delete" @click="handleDelete">删 除
                </el-button>
            </template>
            <template slot-scope="scope" slot="menu">
                <template v-if="scope.row.state == 1">
                    <el-button type="text" size="small" icon="el-icon-close" class="none-border" @click="updateStoreState(scope.row)">关闭</el-button>
                </template>
                <template v-if="scope.row.state == 0">
                    <el-button type="text" size="small" icon="el-icon-open" class="none-border" @click="updateStoreState(scope.row)">营业</el-button>
                </template>
            </template>
        </avue-crud>
    </basic-container>
</template>

<script>
import { getPage, getDetail, add, update, remove, updateStoreStates } from '@/api/epmall/store';
import { mapGetters } from 'vuex';
import { parser } from 'mathjs';
import { getMetaCommonList, saveMetaCommon, updateMetaCommon } from '@/api/busiMode/metaCommon';
import dayjs from 'dayjs';
export default {
    data() {
        return {
            form: {},
            query: {},
            loading: true,
            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            selectionList: [],
            buildList: [],
            option: {
                height: 'auto',
                calcHeight: 30,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: true,
                viewBtn: true,
                delBtn: false,
                dialogClickModal: false,
                // labelWidth: 50,
                column: [
                    {
                        label: '店铺名称',
                        prop: 'name',
                        searchSpan: 4,
                        search: true,
                        rules: [
                            {
                                required: true,
                                message: '请输入店铺名称',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '店铺图标',
                        prop: 'files',
                        type: 'upload',
                        listType: 'picture-card',
                        hide: true,
                        span: 24,
                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '只能上传jpg/png用户头像，且不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },
                    {
                        label: '客服电话',
                        prop: 'service_phone',
                        rules: [
                            {
                                required: true,
                                message: '请输入客服电话',
                                trigger: 'blur',
                            },
                        ],
                    },
                    // {
                    //   label: "省",
                    //   prop: "province",
                    //   rules: [{
                    //     required: true,
                    //     message: "请输入省",
                    //     trigger: "blur"
                    //   }]
                    // },
                    // {
                    //   label: "市",
                    //   prop: "city",
                    //   rules: [{
                    //     required: true,
                    //     message: "请输入市",
                    //     trigger: "blur"
                    //   }]
                    // },
                    // {
                    //   label: "区",
                    //   prop: "district",
                    //   rules: [{
                    //     required: true,
                    //     message: "请输入区",
                    //     trigger: "blur"
                    //   }]
                    // },
                    {
                        label: '楼宇',
                        prop: 'building_id',
                        hide: true,
                        search: true,
                        searchSpan: 4,
                        addDisplay: true,
                        editDisplay: true,
                        viewDisplay: true,
                        type: 'select',
                        cascader: ['wpRoomId'],
                        dicUrl: '/api/park-spcod/mobcodappoint/listbuilding?current=1&size=10000000&buildId={{key}}',
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                        dicFormatter: res => {
                            this.buildList = res.data.records;
                            return res.data.records;
                        },
                        rules: [
                            {
                                required: false,
                                message: '请输入楼宇Id',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '楼宇',
                        prop: 'building_name',
                        addDisplay: false,
                        editDisplay: false,
                        rules: [
                            {
                                required: false,
                                message: '请输入楼宇',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '菜品分类',
                        type: 'select',
                        prop: 'catg_id',
                        addDisplay: true,
                        editDisplay: false,
                        search: false,
                        searchSpan: 4,
                        dicData: [],
                        // dicUrl: '/api/park-epmall/itemcat/list?current=1&size=10000000',
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                        // dicFormatter: res => {
                        //     let l = res.data.records;
                        //     l.forEach(c => {
                        //         c.id = parseInt(c.id);
                        //     });
                        //     console.log(l);
                        //     return l;
                        // },
                        rules: [
                            {
                                required: true,
                                message: '请输入分类',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '地址',
                        prop: 'addr',
                        cell: true,
                        span: 24,
                        props: {
                            label: 'name',
                            value: 'code',
                        },
                        rules: [
                            {
                                required: true,
                                message: '请输入地址',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '店铺介绍',
                        prop: 'store_desc',
                        span: 24,
                        minRows: 4,
                        type: 'textarea',
                        rules: [
                            {
                                required: false,
                                message: '请输入店铺介绍',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '营业时间',
                        prop: 'open_time',
                        searchShow: true,
                        type: 'select',
                        dicUrl: '/api/park-spcod/sportstadium/getimeseg?startHour=8&endHour=23&timeSeg=60',
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                        dicFormatter: data => {
                            let datalist = [];
                            data.data.forEach(ele => {
                                datalist.push({
                                    name: ele,
                                    id: ele,
                                });
                            });
                            return datalist;
                        },
                        rules: [
                            {
                                required: true,
                                message: '请输入开放开始，09:00',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '结业时间',
                        prop: 'open_end',
                        searchShow: true,
                        type: 'select',
                        dicUrl: '/api/park-spcod/sportstadium/getimeseg?startHour=8&endHour=23&timeSeg=60',
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                        dicFormatter: data => {
                            let datalist = [];
                            data.data.forEach(ele => {
                                datalist.push({
                                    name: ele,
                                    id: ele,
                                });
                            });
                            return datalist;
                        },
                        rules: [
                            {
                                required: true,
                                message: '请输入开放结束,20:00',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '创建时间',
                        prop: 'created',
                        addDisplay: false,
                        editDisplay: false,
                        hide: false,
                        type: 'datetime',
                        format: 'yyyy-MM-dd HH:mm:ss',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        rules: [
                            {
                                required: true,
                                message: '请输入创建时间',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '更新时间',
                        prop: 'updated',
                        addDisplay: false,
                        editDisplay: false,
                        hide: true,
                        type: 'datetime',
                        format: 'yyyy-MM-dd HH:mm:ss',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        rules: [
                            {
                                required: true,
                                message: '请输入更新时间',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '状态',
                        prop: 'state',
                        searchSpan: 4,
                        search: true,
                        addDisplay: false,
                        editDisplay: false,
                        viewDisplay: false,
                        hide: false,
                        type: 'select',
                        dicData: [
                            {
                                label: '营业',
                                value: 1,
                            },
                            {
                                label: '关闭',
                                value: 0,
                            },
                        ],
                        rules: [
                            {
                                required: true,
                                message: '请输入状态,1=正常,0=关闭',
                                trigger: 'blur',
                            },
                        ],
                    },
                ],
            },
            data: [],
        };
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.epmallstore_add, false),
                viewBtn: this.vaildData(this.permission.epmallstore_view, false),
                delBtn: this.vaildData(this.permission.epmallstore_delete, false),
                editBtn: this.vaildData(this.permission.epmallstore_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    mounted() {
        this.getItemCat();
    },
    methods: {
        getItemCat() {
            let body = {
                current: 1,
                size: 1000,
                wheres: [{ field: 'parent_id', value: 1 }],
            };

            getMetaCommonList('wisdom_park.wp_epmall_item_cat', body).then(res => {
                const data = res.data.data;
                let ci = 0;
                this.option.column.forEach(c => {
                    if (c.prop == 'catg_id') {
                        this.option.column[ci].dicData = data.records;
                    }
                    ci += 1;
                });
                this.$forceUpdate();
            });
        },
        rowSave(row, done, loading) {
            let op = parseInt(row.openTime);
            let en = parseInt(row.openEnd);
            if (en <= op) {
                this.$message({
                    type: 'error',
                    message: '结业时间不能大于开业时间',
                });
                return;
            }
            if (row.files && row.files.length > 0) {
                row.logo_url = row.files[0].value;
            }
            row.state = 1;
            row.created = dayjs().format('YYYY-MM-DD HH:mm:00');
            row.updated = dayjs().format('YYYY-MM-DD HH:mm:00');
            this.buildList.forEach(element => {
                if (element.id == row.building_id) {
                    row.building_name = element.name;
                }
            });
            saveMetaCommon('wisdom_park.wp_epmall_store', {
                values: row,
            }).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    window.console.log(error);
                },
            );
        },
        rowUpdate(row, index, done, loading) {
            let op = parseInt(row.openTime);
            let en = parseInt(row.openEnd);
            if (en <= op) {
                this.$message({
                    type: 'error',
                    message: '结业时间不能大于开业时间',
                });
                return;
            }

            if (row.files && row.files.length > 0) {
                row.logoUrl = row.files[0].value;
            }
            update(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    console.log(error);
                },
            );
        },
        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(row.id);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning('请选择至少一条数据');
                return;
            }
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(this.ids);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    this.$refs.crud.toggleSelection();
                });
        },
        beforeOpen(done, type) {
            let _this = this;
            if (['edit', 'view'].includes(type)) {
                _this.form.files = [];
                getDetail(this.form.id).then(res => {
                    this.form = res.data.data;
                    _this.form = res.data.data;
                    let files = [];
                    files.push({ label: 'noValue', value: this.form.logoUrl });
                    _this.form.files = files;
                });
            }
            done();
        },
        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },
        searchChange(params, done) {
            this.query = params;
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
            done();
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        currentChange(currentPage) {
            this.page.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        onLoad(page, params = {}) {
            this.loading = true;
            let body = {
                current: page.currentPage,
                size: page.pageSize,
                wheres: [],
            };
            let o = Object.assign(params, this.query);
            if (o.name) {
                body.wheres.push({ field: 'name', value: o['name'] });
            }
            if (o.state) {
                body.wheres.push({ field: 'state', value: o['state'] });
            }
            if (o.building_id) {
                body.gwheres.push({ field: 'building_id', value: o['building_id'] });
            }

            getMetaCommonList('wisdom_park.wp_epmall_store', body).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                this.loading = false;
                this.selectionClear();
            });
            // getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
            //   const data = res.data.data;
            //   this.page.total = data.total;
            //   this.data = data.records;
            //   this.loading = false;
            //   this.selectionClear();
            //});
        },
        updateStoreState(row) {
            this.$alert('确定修改状态吗？', '确定').then(() => {
                updateStoreStates(row.id).then(res => {
                    if (res.data.success) {
                        if (row.state === 1) {
                            row.state = 0;
                            this.$forceUpdate();
                        } else {
                            row.state = 1;
                            this.$forceUpdate();
                        }
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.success(res.data.msg);
                    }
                });
            });
        },
    },
};
</script>

<style>
</style>

import { numConvert } from '@/filters/numConvert.js'

export const line1Option = () => {
  return{
    grid: {
      top: 35,
      left: 15,
      right: 5,
      bottom: '11%',
      containLabel: true
    },
    color: ['#40c68b','#1695ec','#5e32fa','#2365d5','#fa9a02','#fe5a60'],
    tooltip: {
      trigger: "axis"
    },
    xAxis: [
      {
        type: "category",
        data: [],
        axisPointer: {
          type: "shadow"
        }
      }
    ],
    yAxis: [
      {
        type: "value",
        name: "总量(条)",
        axisLabel: {
          formatter: function(value) {
            return getDataCollectCountStr(value, " ");
          }
        }

      },
      {
        name: "增长量(条)",
        type: "value",
        axisLabel: {
          formatter: function(value) {
            return getDataCollectCountStr(value, " ");
          }
        }
       
      }
    ],
    legend: {
      data: ['总量' , '增长量'],
      top: 'bottom'
    },
    series: [
      {
        name: "总量",
        type: "line",
        data: []
      },
      {
        name: "增长量",
        type: "line",
        yAxisIndex: 1,
        data: []
      }
    ]
  };
}

export const lineOption = (series , xAxis) => {
  return{
    grid: {
      containLabel: true,
      left: 5,
      right: 5,
      top: 35,
      bottom: '11%'
    },
    color: ['#40c68b','#1695ec','#5e32fa','#2365d5','#fa9a02','#fe5a60'],
    tooltip: {
      trigger: "axis"
    },
    xAxis: [
      {
        type: "category",
        data: xAxis || [],
        axisPointer: {
          type: "shadow"
        }
      }
    ],
    yAxis: [
      {
        type: "value",
        name: "总量",
        min: 0,
        axisLabel: {
          formatter: function(value) {
            return getDataCollectCountStr(value, " ");
          }
        }
      }
    ],
    series: series || []
  };
}

export const barOption = (option) => {
  option = option || {};
  let unit = option.unit || '条',
      color = option.color || ['#40c68b','#1695ec','#5e32fa','#2365d5','#fa9a02','#fe5a60'];
  return {
    grid: {
      containLabel: true,
      left: 5,
      right: 5,
      top: 35,
      bottom: 5
    },
    color: color,
    tooltip: {
      formatter: `{b}: {c}${unit}`,
    },
    yAxis: {
      type: "category",
      data: [],
      axisLabel: {
        inside: true
      }
    },
    xAxis: {
      type: 'value',
      name: "数据量(" + unit + ")",
      min: 0,
    },
    series: [
      {
        data: [],
        type: 'bar',
        barWidth: 40
      }
    ]
  }
}

export const pieOption = (data , formatter , opt) => {
  return {
      ...opt,
      tooltip: {
        trigger: 'item',
        formatter: function(ev){
          let data = ev.data , percent = ev.percent;
          let name = data.name , value = data.value;
          return `${name}  ${getDataCollectCountStr(value , '条')}(${percent}%)`;
        },
        backgroundColor: "rgba(255,255,255,0.75)",
        textStyle: {
          color: '#000'
        }
      },
      color: ['#40c68b','#1695ec','#5e32fa','#2365d5','#fa9a02','#fe5a60'],
      legend: {
        orient: 'vertical',
        left: 0,
        top: 'center',
        data: data.legend,
        icon: 'circle',
        formatter: formatter || function(name){
          return `${name}  {value|${data.legendDatas[name]['value']}, ${data.legendDatas[name]['pre']}%}`;
        },
        textStyle: {
          rich: {
            value: {
              color: '#999'
            }
          }
        }
      },
      series: data.series ? data.series : [
        {
          type: 'pie',
          radius: ['40%' , '60%'],
          center: ['75%' , '50%'],
          label: {
            show: false
          },
          labelLine: {
            show: false
          },
          data: data.data
        }
      ]
  }
}

export const getDataSize = (_bytes) => {
  let isNegative = _bytes < 0 ? '-' : '' , bytes = Math.abs(_bytes);
  if (bytes === 0){
    return {
      value: bytes,
      unit: 'B'
    }
  }
  let k = 1024 ,
      sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
      i = Math.floor(Math.log(bytes) / Math.log(k));
      
  return {
    value: isNegative + (bytes / Math.pow(k, i)).toPrecision(3),
    unit: sizes[i]
  }
}

export const getDataCollectCountStr = (_num , unit) => {
  let isNegative = _num < 0 ? '-' : '' , num = Math.abs(_num);
  unit = typeof unit === 'string' ? unit : '条';
  let range = [
    {
      min: 9999,
      max: 100000000,
      value: 10000,
      unit: '万'
    },
    {
      min: 99999999,
      value: 100000000,
      unit: '亿'
    }
  ]
  let resNum = Math.abs(num);
  range.forEach(
    item => {
      if(item.value){
        item.max = item.max !== undefined ? item.max : (num + 1);
        if(item.min !== undefined && item.max !== undefined){
          if(num > item.min && num < item.max){
            resNum = Number((num / item.value).toFixed(2)) + item.unit;
          }
        }
      }
    }
  )
  return isNegative + resNum + unit;
  // if(num > 99999999){
  //   return (num / 100000000).toFixed(2) + '亿' + unit;
  // }else if(num < 100000000 && num > 9999){
  //   return (num / 10000).toFixed(2) + '万' + unit;
  // }else{
  //   return num + unit;
  // }
}

export const getPre = (count , values) => {
  Object.keys(values).forEach(
    key => {
      let value = values[key].c;
      let pre = (value / count * 100);
      values[key]['pre'] = Number(pre.toString().match(/^\d+(?:\.\d{0,2})?/));
    }
  )
  return values;
}


export const formartPieData =(res) =>{
  let count = 0;
  let data = {
    legend: [],
    data: [],
    legendDatas: {}
  }; 
  let valueName = "value";
  let labelName = "name";
  res.data.forEach(item => {
    count += item[valueName];
    data.legend.push(item[labelName]);
    data.legendDatas[item[labelName]] = {
      pre: "",
      value: getDataCollectCountStr(item[valueName], ""),
      c: item[valueName]
    };
    data.data.push({
      ...item,
      name: item[labelName],
      value: item[valueName]
    });
  });
  data.legendDatas = getPre(count, data.legendDatas);
  return data
 
}
<template>
  <basic-container>
    <avue-crud
      :data="tableDatas"
      :option="tableOption"
      :table-loading="tableLoading"
      @on-load="onLoad"
      @refresh-change="onLoad"
    >
      <template slot="menuLeft">
        <el-button @click="$router.back()" icon="el-icon-back" type="text" size="small">返回</el-button>
      </template>
      <template slot="tableName" slot-scope="scope">
        <el-link v-if="scope.row.guid" type="primary" :href="'#/dws/datamodel/table/info/' + scope.row.guid">{{scope.row.tableName}}</el-link>
        <el-link v-else>{{scope.row.tableName}}</el-link>
      </template>
      <template slot="menu" slot-scope="{row , size}">
        <el-button type="text" icon="el-icon-view" @click.prevent="view(row)" :size="size">预览</el-button>
      </template>
    </avue-crud>
    <el-dialog
      append-to-body
      title="数据预览"
      :visible.sync="dialogVisible"
      width="80%"
      custom-class="dis-b"
    >
      <div class="w-100p ov-a h-300" v-loading="dialogLoading">
        <el-table v-if="dialogVisible && !dialogLoading && viewColumns.length" :data="viewData" style="width: 100%">
          <el-table-column
            v-for="(item , index) of viewColumns"
            :prop="item.prop"
            :label="item.label"
            :key="index"
            >
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
  </basic-container>
</template>
<script>
import { 
  queryTablesByDataBaseId,
  queryDataByDataBaseIdAndTableName
} from "@/api/dws/datamodel";

import { firstToUpper } from '@/filters/index';

export default {
  data(){
    return {
      databaseId: this.$router.currentRoute.params.id,
      guid: this.$router.currentRoute.query.guid,
      tableDatas: [],
      tableLoading: false,
      dialogVisible: false,
      dialogLoading: false,
      tableOption: {
        addBtn:false,
        editBtn:false,
        delBtn:false,
        column:[
          {
            label:'表名称',
            prop:'tableName'
          },
          {
            label:'表类型',
            prop:'typeName'
          },
          {
            label:'模型名称',
            prop:'modeltype'
          },
          {
            label:'数据源',
            prop:'name'
          }
        ]
      },
      viewData: [],
      viewColumns: []
    }
  },
  methods: {
    onLoad(){
      let obj = {
        databaseId: this.databaseId,
        guid: this.guid
      };
      this.tableLoading = true;
      queryTablesByDataBaseId(obj).then(res => {
        this.tableDatas = res['data']['data'];
        this.tableLoading = false;
      }).catch(() => {
        this.tableLoading = false;
        this.tableDatas = [];
      });
    },
    resetViewData(columnNameList , data){
      let columnNameListTemp = [] , dataTemp = [];
      columnNameList.forEach(
        item => {
          let itemArr = item.split('.');
          columnNameListTemp.push({
            label: itemArr.join('.'),
            prop: itemArr.join('')
          });
        }
      )
      this.viewColumns = columnNameListTemp;
      data.forEach(
        item => {
          let itemTemp = {};
          Object.keys(item).forEach(
            name => {
              itemTemp[name.split('.').join('')] = item[name];
            }
          )
          dataTemp.push(itemTemp);
        }
      )
      this.viewData = dataTemp;
    },
    view(row){
      this.dialogVisible = true;
      this.dialogLoading = true;
      this.viewColumns = [];
      this.viewData = [];
      queryDataByDataBaseIdAndTableName({
        databaseId: this.databaseId,
        tableName: row.tableName,
        rowSize: 10
      }).then(
        res => {
          this.resetViewData(res.data['columnNameList'] || [] , res.data['data'] || []);
          this.dialogLoading = false;
        }
      )
    }
  }
}
</script>
<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" :page.sync="page" :before-open="beforeOpen" v-model="form" ref="crud" @row-update="rowUpdate" @row-save="rowSave"
                   @row-del="rowDel" @row-click="rowClick" @search-change="searchChange" @search-reset="searchReset" @selection-change="selectionChange" @current-change="currentChange"
                   @size-change="sizeChange" @refresh-change="refreshChange" @on-load="onLoad" @sort-change="sortChange">
            <template slot-scope="scope" slot="menu">
                <template v-if="scope.row.status == 1">
                    <el-button type="text" size="small" icon="el-icon-edit" v-if="permission.code_edit" class="none-border" @click="handleEdit(scope.row, scope.index)">编辑
                    </el-button>
                </template>
                <template v-if="scope.row.status == 1">
                    <el-button type="text" size="small" icon="el-icon-delete" class="none-border" @click="delAppt(scope.row.id)">删除
                    </el-button>
                </template>
                <template v-if="scope.row.status == 1">
                    <el-button type="text" size="small" icon="el-icon-download" class="none-border" @click="cancelAppt(scope.row.id)">取消预订
                    </el-button>
                </template>
                <template v-if="scope.row.status == 7">
                    <el-button type="text" size="small" icon="el-icon-close" class="none-border" @click="cancelPreAppt(scope.row.id)">取消预留
                    </el-button>
                </template>
                <template>
                    <el-button type="text" size="small" icon="el-icon-edit" class="none-border" @click="changeDate(scope.row)">改期
                    </el-button>
                </template>
            </template>
        </avue-crud>
    </basic-container>
</template>

<script>
import { getPage, getDetail, add, update, remove, updateApptStatus, deleteAppoint } from '@/api/spcod/codappoint';
import { getTimeUnit } from '@/api/spcod/codprice';
import { mapGetters } from 'vuex';
import { cancelPreAppoint } from '@/api/spcod/codappointnew';
import { saveMessage } from '@/api/desk/notice';
import { updateMetaCommon } from '@/api/busiMode/metaCommon';
import dayjs from 'dayjs';

export default {
    data() {
        // 正则表达式 验证手机号
        const validateMobile = (rule, value, callback) => {
            if (!/^[1]([3-9])[0-9]{9}$/.test(value)) {
                callback(new Error('请输入正确的手机号格式'));
            } else {
                callback();
            }
        };
        return {
            form: {},
            query: {},
            // loading: true,
            loading: false,
            name: 'codelogin',
            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            selectionList: [],
            option: {
                height: 'auto',
                calcHeight: 30,
                tip: false,
                searchShow: true,
                searchMenuSpan: 24,
                searchMenuPosition: 'center',
                searchLabelWidth: 80,
                border: true,
                index: true,
                delBtn: false,
                viewBtn: true, //行内查看按钮
                editBtn: false, // 行内编辑按钮
                addBtn: false,
                dialogClickModal: false,
                defaultSort: {
                    prop: 'apptTime',
                    order: 'descending',
                },
                column: [
                    {
                        label: '会议室',
                        prop: 'roomId',
                        search: false,
                        hide: true,
                        searchSpan: 6,
                        type: 'select',
                        editDisplay: false,
                        viewDisplay: false,
                        addDisplay: true,
                        dicUrl: '/api/park-spcod/codroom/page?current=1&size=10000000&status=1',
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                        dicFormatter: res => {
                            return res.data.records;
                        },
                        rules: [
                            {
                                required: true,
                                message: '请输入会议室ID',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '订单号',
                        prop: 'orderSn',
                        minWidth: 120,
                        search: true,
                        searchSpan: 6,
                    },
                    {
                        label: '会议室',
                        prop: 'roomName',
                        minWidth: 150,
                        addDisplay: false,
                        editDisplay: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入外键到房间',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '楼宇',
                        prop: 'buildingId',
                        hide: true,
                        search: true,
                        searchSpan: 6,
                        addDisplay: false,
                        viewDisplay: false,
                        editDisplay: false,
                        type: 'select',
                        dicUrl: '/api/park-spcod/mobcodappoint/listbuilding?current=1&size=10000000',
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                        dicFormatter: res => {
                            return res.data.records;
                        },
                        rules: [
                            {
                                required: false,
                                message: '请输入楼宇Id',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '楼宇',
                        prop: 'buildingName',
                        minWidth: 150,
                        addDisplay: false,
                        editDisplay: true,
                        rules: [
                            {
                                required: false,
                                message: '请输入楼宇',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        align: 'left',
                        label: '下单时间',
                        format: 'yyyy-MM-dd',
                        prop: 'createTime',
                        width: 150,
                        sortable: 'custom',
                    },
                    {
                        align: 'left',
                        label: '预订时间',
                        format: 'yyyy-MM-dd',
                        valueFormat: 'yyyy-MM-dd 00:00:00',
                        prop: 'apptTime',
                        sortable: 'custom',
                        width: 120,
                        type: 'date',
                        rules: [
                            {
                                required: true,
                                message: '请选择日期',
                                trigger: 'blur',
                            },
                        ],
                    },

                    {
                        label: '预订时段',
                        prop: 'unitHour',
                        type: 'select',
                        dicData: [],
                        props: {
                            label: 'value',
                            value: 'key',
                        },
                        rules: [
                            {
                                required: true,
                                message: '请输入预订时段',
                                trigger: 'blur',
                            },
                        ],
                    },

                    {
                        label: '类型',
                        prop: 'timeType',
                        search: false,
                        searchSpan: 4,
                        type: 'select',
                        dicData: [
                            {
                                label: '工作日',
                                value: 0,
                            },
                            {
                                label: '节假日',
                                value: 1,
                            },
                        ],
                        rules: [
                            {
                                required: true,
                                message: '请输入类型',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '费用(元)',
                        prop: 'totalPrice',
                        addDisplay: false,
                        viewDisplay: true,
                        editDisplay: true,
                        rules: [
                            {
                                required: false,
                                message: '请输入预订费用',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '预订人',

                        prop: 'contactName',
                        minWidth: 120,
                        rules: [
                            {
                                required: false,
                                message: '请输入预订电话',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '预订电话',
                        width: 150,
                        prop: 'contactPhone',
                        search: true,
                        searchSpan: 6,
                        width: 120,
                        rules: [
                            {
                                required: true,
                                message: '请输入预订电话',
                                trigger: 'blur',
                            },
                            {
                                trigger: 'blur',
                                validator: validateMobile,
                            },
                        ],
                    },
                    // {
                    //   label: "预约时间",
                    //   prop: "apptTime",
                    //   hide: true,
                    //   addDisplay: false,
                    //   editDisplay: false,
                    //   rules: [
                    //     {
                    //       required: true,
                    //       message: "请输入预约时间",
                    //       trigger: "blur",
                    //     },
                    //   ],
                    // },
                    // {
                    //   label: "预订时间",
                    //   prop: "apptDay",
                    //   hide: true,
                    //   search: true,
                    //   searchSpan: 5,
                    //   addDisplay: false,
                    //   editDisplay: false,
                    //   viewDisplay: false,
                    //   type: "datetime",
                    //   format: "yyyy-MM-dd",
                    //   valueFormat: "yyyy-MM-dd",
                    //   rules: [
                    //     {
                    //       required: false,
                    //       message: "请输入预订时间",
                    //       trigger: "blur",
                    //     },
                    //   ],
                    // },
                    {
                        label: '状态',
                        prop: 'status',
                        search: true,
                        searchSpan: 6,
                        addDisplay: false,
                        editDisplay: false,
                        viewDisplay: false,
                        type: 'select',
                        dicData: [
                            {
                                label: '新建',
                                value: 1,
                            },
                            {
                                label: '取消',
                                value: 2,
                            },
                            {
                                label: '已支付',
                                value: 3,
                            },
                            {
                                label: '退单',
                                value: 5,
                            },
                            {
                                label: '支付超时',
                                value: 6,
                            },
                            {
                                label: '预留',
                                value: 7,
                            },
                        ],
                        rules: [
                            {
                                required: false,
                                message: '请输入状态',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '备注',
                        prop: 'extraMemo',
                        span: 24,
                        minRows: 6,
                        type: 'textarea',
                        rules: [
                            {
                                required: false,
                                message: '请输入备注',
                                trigger: 'blur',
                            },
                        ],
                    },
                ],
            },
            data: [],
            timeUnitList: [],
            sortObj: {
                sortField: 'appt_time',
                sortType: 'DESC',
            },
        };
    },
    computed: {
        ...mapGetters(['permission', 'userInfo']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.codappoint_add, false),
                viewBtn: this.vaildData(this.permission.codappoint_view, false),
                delBtn: this.vaildData(this.permission.codappoint_delete, false),
                editBtn: this.vaildData(this.permission.codappoint_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    created() {
        this.getTimeUnit();
    },
    methods: {
        changeDate(row) {
            row.changeDate = '2023-11-28,0';
            let changeDay = row.changeDate.split(',')[0];
            let changeTime = this.getUnitText(row.changeDate.split(',')[1]);
            this.$confirm(
                `确定将会议室预定时间从${dayjs(row.apptTime).format('YYYY-MM-DD')}${this.getUnitText(
                    row.unitHour,
                )}改为${changeDay}${changeTime}?`,
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                },
            )
                .then(() => {
                    if (row.changeDate) {
                        let notice = {
                            title: '会议室时间改期',
                            content: `会议室预定时间从${dayjs(row.apptTime).format('YYYY-MM-DD')}${this.getUnitText(
                                row.unitHour,
                            )}改为${changeDay}${changeTime}`,
                            bizType: '会议室时间延期',
                            recipient: this.userInfo.user_id,
                            sendFlag: 1,
                        };

                        let updateBody = {
                            id: row.id,
                            appt_time: row.changeDate.split(',')[0],
                            unit_hour: row.changeDate.split(',')[1],
                        };
                        let body = {
                            update: updateBody,
                            primaryKeyValue: row.id,
                            primaryKey: 'id',
                        };
                        updateMetaCommon('wisdom_park.wp_cod_appoint', body).then(res => {
                            saveMessage(notice).then(res => {
                                this.onLoad();
                                this.$message({
                                    type: 'success',
                                    message: '操作成功!',
                                });
                            });
                        });
                    }
                })
                .then(() => {});
        },
        getUnitText(unitHour) {
            let unitHourText = '';
            if (unitHour == 1) {
                unitHourText = '上午';
            } else if (unitHour == 2) {
                unitHourText = '下午';
            } else if (unitHour == 0) {
                unitHourText = '全天';
            }
            return unitHourText;
        },
        sortChange({ col, prop, order }) {
            let orders = {
                ascending: 'ASC',
                descending: 'DESC',
            };
            if (prop) {
                this.sortObj.sortField = order
                    ? prop.replace(/([A-Z])/g, $1 => {
                          return '_' + $1.toLocaleLowerCase();
                      })
                    : '';
                this.sortObj.sortType = order ? orders[order] : '';
            }
            this.onLoad();
        },
        getTimeUnit() {
            getTimeUnit().then(res => {
                let data = [];
                res.data.data.forEach(e => {
                    data.push({
                        key: e.key,
                        value: e.value,
                    });
                });
                this.timeUnitList = data;
                let ci = 0;
                this.option.column.forEach(c => {
                    if (c.prop == 'unitHour') {
                        this.option.column[ci].dicData = this.timeUnitList;
                    }
                    ci += 1;
                });
                this.$forceUpdate();
            });
        },
        rowSave(row, done, loading) {
            if (row.beginTime) {
                let t = row.beginTime;
                row.beginTime = t + ' 00:00:00';
                row.endTime = t + ' 23:59:59';
            }
            add(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    window.console.log(error);
                },
            );
        },
        rowUpdate(row, index, done, loading) {
            update(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                },
            );
        },
        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(row.id);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
        delAppt(apptId) {
            this.$confirm('确定将选择删除预订?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return deleteAppoint(apptId);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
        cancelPreAppt(apptId) {
            let _this = this;
            this.$confirm('确定将选择取消预留?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                cancelPreAppoint(apptId).then(res => {
                    _this.onLoad(this.page);
                    _this.$message({
                        type: 'success',
                        message: '取消预留操作成功!',
                    });
                });
            });
        },
        cancelAppt(apptId) {
            this.$confirm('确定将选择取消预约?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return updateApptStatus(apptId, 2);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
        // 通过点击事件保存行数据
        rowClick(row) {
            this.rowData = row;
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning('请选择至少一条数据');
                return;
            }
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(this.ids);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    this.$refs.crud.toggleSelection();
                });
        },
        beforeOpen(done, type) {
            if (['edit', 'view'].includes(type)) {
                getDetail(this.form.id).then(res => {
                    this.form = res.data.data;
                });
            }
            done();
        },
        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },
        searchChange(params, done) {
            this.query = params;
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
            done();
        },
        selectionChange(list) {
            this.selectionList = list;
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        currentChange(currentPage) {
            this.page.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        onLoad(page, params = {}) {
            this.loading = true;
            getPage(this.page.currentPage, this.page.pageSize, {
                ...Object.assign(params, this.query),
                ...this.sortObj,
            }).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                this.loading = false;
                this.selectionClear();
            });
        },
        changeRequire(e) {
            if (e == '-2') {
                this.$refs.crud.formRules.rejectReason[0].required = true;
            } else {
                this.$refs.crud.formRules.rejectReason[0].required = false;
            }
        },
        handleEdit(row, index) {
            this.$refs.crud.rowEdit(row, index);
        },
    },
};
</script>

<style></style>

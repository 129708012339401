<template>
  <div>
    <div class="diaTitle">
      <span
        class="fts-14 title titleavtive mar-l-20"
        :class="{ titleactive: active == 1 }"
        >1 选择房源</span
      >
      <span class="fts-14 title mar-l-15" :class="{ titleactive: active == 2 }"
        >2 完善信息</span
      >
    </div>

    <div class="mar-t-10f" v-if="active == 1">
      <el-form>
        <el-form-item label="房源类型" prop="resource">
          <el-radio-group v-model="houseResourType">
            <el-radio label="build">楼宇</el-radio>
            <el-radio label="room">房间</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <buildList
        v-if="houseResourType == 'build'"
        @oneBuild="oneBuild"
      ></buildList>
      <rooms
        v-if="houseResourType == 'room'"
        @oneBuild="oneBuild"
        :currentHouse="currentHouse"
      ></rooms>
    </div>
    <div style="height: 67vh; overflow-x: hidden !important" v-if="active == 2">
      <houseInfo
        ref="houseInfo"
        :currentHouse="currentHouse"
        :currentObj="currentObj"
        :houseResourType="houseResourType"
        @close="close"
      ></houseInfo>
    </div>
    <div class="pad-t-10 btnClass">
      <el-button size="small" type="" @click="close" v-if="active == 1"
        >取消</el-button
      >
      <el-button
        size="small"
        type="primary"
        v-if="active == 1"
        class="pull-right"
        :disabled="JSON.stringify(currentObj) == '{}'"
        @click="active = 2"
        >下一步</el-button
      >

      <el-button size="small" @click="active = 1" v-if="active == 2"
        >上一步</el-button
      >
      <el-button
        size="small"
        class="pull-right"
        type="primary"
        v-if="active == 2"
        @click="saveHouse"
        >保存</el-button
      >
    </div>
  </div>
</template>

<script>
import { saveMetaCommon } from "@/api/busiMode/metaCommon";
import rooms from "./room";
import buildList from "./buildList";
import houseInfo from "./houseInfo";

export default {
  name: "addHousingResources",
  components: { rooms, buildList, houseInfo },

  props: {
    ruleModel: {
      type: Object,
      default() {
        return {};
      },
    },
    currentHouse: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      houseResourType: "room",
      active: 1,
      currentObj: {},
    };
  },
  watch: {
    currentHouse: {
      handler: function (newValue, oldName) {
        if (JSON.stringify(newValue) == "{}") {
          this.active = 1;
        } else {
          this.active = 2;
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (this.currentHouse.subject_type == 0) {
      this.houseResourType = "room";
    } else {
      this.houseResourType = "build";
    }
  },
  computed: {},
  methods: {
    oneBuild(e) {
      this.currentObj = e;
    },
    close() {
      this.$emit("close");
    },
    saveHouse() {
      console.log(this.$refs.houseInfo);
      this.$refs.houseInfo.save();
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-card__body {
  padding-top: 0px;
}
.titleactive {
  color: #475570 !important;
}
.title {
  color: #8d9ab2;
}
/deep/ .el-divider__text {
  color: rgba(48, 49, 51, 0.5);
}
.diaTitle {
  position: absolute;
  top: 23px;
  left: 80px;
}
.btnClass {
  display: flow-root;
  margin-top: 5px;
}
</style>

<template>
  <basic-container>
    <avue-crud
      :data='tableData'
      :table-loading='monitorLoading'
      :page.sync='page'
      :option='option'
      @on-load='getMonitorList'
      @refresh-change='getMonitorList'
    >
      <template slot-scope='{ type, size, row }' slot='menu'>
        <el-button
          @click='_refreshLog(row)'
          icon='el-icon-video-play'
          :size='size'
          :type='type'
          >查看日志</el-button
        >
      </template>
      <!-- 搜索 -->
      <template slot='menuLeft'>
        <el-row :gutter='$store.state.common.gutter'>
          <el-col :span='4'>
            <el-input
              size='small'
              clearable
              v-model='search.name'
              placeholder='请输入任务名称'
            ></el-input>
          </el-col>
          <el-col :span='4'>
            <el-select
              v-model='search.state'
              size='small'
              placeholder='请选择状态'
              clearable
            >
              <el-option
                v-for='item in stateTypes'
                :key='item.code'
                :value='item.code'
                :label='item.label'
              ></el-option>
            </el-select>
          </el-col>
          <el-col :span='10'>
            <el-date-picker
              size='small'
              v-model='search.submitTime'
              format='yyyy-MM-dd HH:mm:ss'
              valueFormat='yyyy-MM-dd HH:mm:ss'
              type='datetimerange'
              range-separator='至'
              start-placeholder='请选择调度开始日期'
              end-placeholder='请选择调度结束日期'
            >
            </el-date-picker>
          </el-col>
          <el-col :span='6'>
            <div class='searchBtn'>
              <el-button size='small' icon='el-icon-search' type='primary' @click='searchChange'>搜索</el-button>
              <el-button size='small' icon='el-icon-delete' @click='emptHandle'>清空</el-button>
            </div>
          </el-col>
        </el-row>
      </template>
    </avue-crud>
    <el-dialog
      append-to-body
      :show-close='false'
      :visible.sync='logDialog'
      width='auto'
    >
      <m-log :key='logId' :item='item' :source='source' :logId='logId' @ok='ok' @close='close'></m-log>
    </el-dialog>
  </basic-container>
</template>

<script>
import { mapActions } from 'vuex';
import { stateType } from '@/view-module/scheduler/pages/projects/pages/_source/conditions/instance/common';
import mLog from './log';
// import { getMonitorList } from '@/api/metadata/monitor';
export default {
  components: { mLog },
  data() {
    return {
      stateTypes: [],
      monitorLoading: false,
      search: {},
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      searchParams: {
        // Search keywords
        searchVal: '',
        // Number of pages
        pageSize: 10,
        // Current page
        pageNo: 1,
        // host
        host: '',
        // State
        stateType: '',
        // Start Time
        startDate: '',
        // End Time
        endDate: '',
        // Exectuor Name
        executorName: '',
      },
      tableData: [],
      logDialog: false,
      item: {},
      source: '',
      logId: null,
      option: {
        addBtn: false,
        editBtn: false,
        delBtn: false,
        menuWidth: 100,
        // searchMenuSpan:5,
        column: [
          {
            label: '任务名称',
            prop: 'name',
            // search:true,
            // searchSpan:5,
            display: false,
          },
          {
            label: '实例ID',
            prop: 'processInstanceName',
            display: false,
          },
          {
            label: '状态',
            prop: 'state',
            props: {
              label: 'label',
              value: 'code',
            },
            type: 'select',
            width: 70,
            dicData: stateType,
          },
          {
            label: '调度时间',
            prop: 'submitTime',
            type: 'date',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            display: false,
          },
          {
            label: '开始时间',
            prop: 'startTime',
            type: 'date',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            display: false,
          },
          {
            label: '结束时间',
            prop: 'endTime',
            type: 'date',
            format: 'yyyy-MM-dd HH:mm:ss',
            valueFormat: 'yyyy-MM-dd HH:mm:ss',
            display: false,
          },
          {
            label: '运行时长',
            prop: 'duration',
            width: 70,
            display: false,
          },
          {
            label: '运行次数',
            prop: 'retryTimes',
            width: 70,
            display: false,
          },
          {
            label: '执行用户',
            prop: 'executorName',
            display: false,
          },
          {
            label: 'host',
            prop: 'host',
            display: false,
          },
        ],
      },
    };
  },
  mounted() {
    // this.getMonitorList();
    if (this.taskName) {
      this.option.column[0].search = false;
    }
  },
  props: {
    type: {
      type: Number,
      default: 3,
    },
    taskName: {
      type: String,
      default: '',
    },
  },
  watch: {
    taskName() {
      this._getProcessInstanceListP();
    },
  },
  created() {
    this.stateTypes = stateType;
  },
  methods: {
    ...mapActions('dag', ['getProcessInstance', 'getTaskInstanceList']),
    viewDetail() {},
    _getProcessInstanceListP() {
      this.monitorLoading = true;
      if (this.taskName) {
        this.searchParams.searchVal = this.taskName;
      }
      this.getTaskInstanceList({
        ...this.searchParams,
        type: this.type,
      })
        .then((res) => {
          if (this.searchParams.pageNo > 1 && res.totalList.length === 0) {
            this.searchParams.pageNo = this.searchParams.pageNo - 1;
          } else {
            this.tableData = [];
            this.tableData = res.totalList;
            this.page.total = res['total'];
          }
          this.monitorLoading = false;
        })
        .catch((e) => {});
    },
    _refreshLog(item) {
      this.item = item;
      this.source = 'list';
      this.logId = item.id;
      this.logDialog = true;
    },
    ok() {},
    close() {
      this.logDialog = false;
    },
    // 清空
    emptHandle() {
      this.search.name = '';
      this.search.state = '';
      this.search.submitTime = null;
    },
    // 搜索功能
    searchChange() {
      this.searchParams.searchVal = this.search['name'] || '';
      this.searchParams.stateType = this.search['state'] || '';
      if (this.search['submitTime']) {
        this.searchParams.startDate = this.search['submitTime'][0];
        this.searchParams.endDate = this.search['submitTime'][1];
      } else {
        this.searchParams.startDate = '';
        this.searchParams.endDate = '';
      }
      this.getMonitorList();
    },
    getMonitorList(page) {
      if (page) {
        this.searchParams.pageNo = page.currentPage;
      } else {
        this.searchParams.pageNo = 1;
        this.page.currentPage = this.searchParams.pageNo;
      }
      this._getProcessInstanceListP();
    },
  },
};
</script>

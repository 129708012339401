import BaseInfo from './baseinfo';
import Header from './header';
import Data from './data';
import Option from './option'

export default [
  BaseInfo,
  Header,
  Data,
  Option
]
<template>
    <div>
        <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" v-model="form" :page.sync="page" @refresh-change="refreshChange" @row-save="rowSave" @row-del="rowDel"
                   @on-load="onLoad" @search-change="searchChange">
            <template slot="menuLeft" slot-scope="scope">
                <el-button type="primary" size="small" icon="el-icon-plus" @click="add()">新增
                </el-button>
            </template>
            <template slot-scope="scope" slot="menu" v-if="scope.row.status != 2">
                <el-button type="text" size="small" class="none-border" icon="el-icon-edit" @click="rowEdit(scope.row)">编辑
                </el-button>
                <el-button type="text" size="small" class="none-border" icon="el-icon-delete" @click="rowDel(scope.row)">删除
                </el-button>
                <el-button type="text" size="small" class="none-border" icon="el-icon-s-promotion" @click="sendMessage(scope.row)">发送
                </el-button>
            </template>
            <template slot-scope="{ row }" slot="recipients">
                <div v-if="row.recipientName">人员（{{row.recipientName}})</div>
                <div v-if="row.receivingRoleName">角色（{{row.receivingRoleName}}）</div>
                <div v-if="row.receivingDeptName">部门（{{row.receivingDeptName}}）</div>
            </template>
        </avue-crud>
        <el-dialog :title="title" :visible.sync="detailDia" width="60%" class="enterDetail" destroy-on-close append-to-body>
            <messageForm @close="close" :currentEditRow="currentEditRow"></messageForm>
        </el-dialog>
    </div>
</template>

<script>
import { getMessageList, removeMessage, sendMessage } from '@/api/desk/notice';
import { mapGetters, mapState } from 'vuex';
import { getDeptTree } from '@/api/system/dept';
import { getRoleTree } from '@/api/system/role';
import messageForm from './messageForm';

export default {
    components: { messageForm },
    data() {
        return {
            searchText: '',
            regionName: '',
            form: {},
            selectionList: [],
            loading: true,
            dialogVisible: false,
            detailDia: false,
            query: {},
            dialogType: '',
            type: '',
            size: '60%',
            currentRow: {},
            currentEditRow: {},
            title: '新增消息',
            page: {
                size: 10,
                current: 1,
                total: 0,
            },
            option: {
                dateBtn: false,
                border: true,
                height: 'auto',
                tip: true,
                searchShow: true,
                searchMenuSpan: 12,
                addBtn: false,
                refreshBtn: true,
                addRowBtn: false,
                columnBtn: true,
                delBtn: false,
                editBtn: false,
                viewBtn: true,
                cancelBtn: true,
                rowKey: '',
                showPage: true,
                index: true,
                indexLabel: '序号',
                selection: false,
                showHeader: true,
                expand: false,
                highlightCurrentRow: true,
                stripe: false,
                menuWidth: 150,
                submitText: '保存并发表',
                reserveSelection: true,
                formslot: true,
                column: [
                    {
                        overHidden: true,
                        align: 'center',
                        label: '标题',
                        prop: 'title',
                        hide: false,
                        show: true,
                        display: true,
                        span: 24,
                    },
                    {
                        overHidden: true,
                        label: '内容',
                        prop: 'content',
                        width: 250,
                        hide: false,
                        show: true,
                        search: true,
                        overHidden: true,
                        type: 'textarea',
                        display: true,
                        span: 24,
                    },
                    {
                        overHidden: true,
                        align: 'center',
                        label: '业务类型',
                        prop: 'bizType',
                        hide: false,
                        display: true,
                    },
                    {
                        overHidden: true,
                        align: 'center',
                        label: '状态',
                        prop: 'status',
                        hide: false,
                        type: 'select',
                        search: true,
                        dicData: [
                            {
                                label: '未发送',
                                value: 1,
                            },
                            {
                                label: '已发送',
                                value: 2,
                            },
                            {
                                label: '发送失败',
                                value: 3,
                            },
                        ],
                        display: true,
                    },
                    {
                        align: 'left',
                        label: '接收方',
                        prop: 'recipients',
                        viewDisplay: false,
                        slot: true,
                    },
                    {
                        label: '接收人',
                        hide: true,
                        prop: 'recipientName',
                        multiple: true,
                    },
                    {
                        label: '接收角色',
                        hide: true,
                        prop: 'receivingRoleName',
                        multiple: true,
                    },
                    {
                        label: '接收部门',
                        prop: 'receivingDeptName',
                        checkStrictly: true,
                        hide: true,
                    },
                ],
            },
            data: [],
            ruleModel: {},
        };
    },

    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.code_add, false),
                viewBtn: this.vaildData(this.permission.code_view, false),
                delBtn: this.vaildData(this.permission.code_delete, false),
                editBtn: this.vaildData(this.permission.code_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    mounted() {},
    props: {},
    watch: {},
    methods: {
        close() {
            this.detailDia = false;
            this.onLoad();
        },
        initData() {
            getRoleTree(this.userInfo.tenant_id).then(res => {
                console.log(res.data.data);
                const column = this.findObject(this.option.column, 'receivingRole');
                column.dicData = res.data.data;
            });
            getDeptTree(this.userInfo.tenant_id).then(res => {
                const column = this.findObject(this.option.column, 'receivingDept');
                column.dicData = res.data.data;
            });
        },
        add() {
            this.currentEditRow = {};
            this.title = '新增消息';
            this.detailDia = true;
        },
        rowEdit(row) {
            this.title = '修改消息';
            this.currentEditRow = row;
            this.detailDia = true;
        },

        sendMessage(row) {
            this.$confirm('确定发送该信息?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                sendMessage(row.id).then(res => {
                    if (res.data.code == 200) {
                        this.onLoad();
                        this.$message({
                            type: 'success',
                            message: '发送成功!',
                        });
                    } else {
                        this.$message({
                            type: 'error',
                            message: '发送成功!',
                        });
                    }
                });
            });
        },
        rowSave(row, done, loading) {
            let o = row;
            if (o.receivingDept) {
                o.receivingDept = o.receivingDept.toString();
            }
            if (o.receivingRole) {
                o.receivingRole = o.receivingRole.toString();
            }
            if (o.id) {
                updateMessageTemp(o).then(
                    res => {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        // 数据回调进行刷新
                        done(row);
                    },
                    error => {
                        window.console.log(error);
                        loading();
                    },
                );
            } else {
                saveMessageTemp(o).then(
                    res => {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        // 数据回调进行刷新
                        done(row);
                    },
                    error => {
                        window.console.log(error);
                        loading();
                    },
                );
            }
        },
        searchChange(params, done) {
            console.log(params);
            this.query = params;
            this.page.currentPage = 1;
            this.onLoad(params, done);
        },
        refreshChange() {
            this.onLoad(this.page);
        },
        closeDia() {
            console.log('关闭');
            this.detailDia = false;
            this.currentRow = {};
            this.refreshChange();
        },
        onLoad(params, done) {
            this.loading = true;
            getMessageList(this.page.currentPage, this.page.pageSize, this.query).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                this.loading = false;
                done && done();
            });
        },
        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    console.log(row.id);
                    return removeMessage(row.id);
                })
                .then(() => {
                    this.onLoad();
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.deleteIcon {
    font-size: 18px;
    position: absolute;
    left: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 1;
}
/deep/ .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 15px;
}
/deep/ .el-drawer__header {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 20px;
    margin-bottom: 0px;
}
/deep/ .el-drawer__body {
    padding: 0px;
}
/deep/ .el-dialog__body {
    padding: 0px;
}
.buildDetail /deep/ .el-dialog__body {
    padding: 0px;
}
</style>

<template>
  <div>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      ref="crud"
      v-model="form"
      :page.sync="page"
      @selection-change="selectionChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot="menuLeft" slot-scope="scope">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="add()"
          >新增门禁
        </el-button>
        <el-input
          v-model="searchText"
          @keyup.native.enter="refreshChange"
          size="small"
          placeholder="请输入门禁"
          type="text"
          style="width: 250px"
        >
          <el-button
            class="mar-0 pad-0"
            size="small"
            @click="refreshChange"
            slot="append"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button
          type="text"
          size="small"
          class="none-border"
          icon="el-icon-view"
          @click="openDetailDia('detail', scope.row)"
          >查看
        </el-button>
        <el-button
          type="text"
          size="small"
          icon="el-icon-edit"
          class="none-border"
          @click="openDetailDia('update', scope.row)"
          >编辑
        </el-button>
        <el-button
          type="text"
          size="small"
          icon="el-icon-delete"
          class="none-border"
          @click="rowDel(scope.row)"
          >删除
        </el-button>
        <el-button
          type="text"
          size="small"
          class="none-border"
          icon="el-icon-s-tools"
          @click="doorevents(scope.row)"
          >通行记录
        </el-button>
      </template>
    </avue-crud>
    <el-dialog
      :visible.sync="detailDia"
      width="40%"
      class="enterDetail"
      destroy-on-close
      append-to-body
    >
      <div slot="title" class="header-title">
        <span class="fts-16" v-if="type == 'detail'">门禁详情</span>
        <span class="fts-16" v-if="type == 'update'">修改门禁</span>
        <span class="fts-16" v-if="type == 'add'">新增门禁</span>
      </div>

      <addDoor
        @close="closeDia"
        :currentRow="currentRow"
        :type="type"
      ></addDoor>
    </el-dialog>
  </div>
</template>

<script>
import { getMetaCommonList, delMetaCommon } from "@/api/busiMode/metaCommon";
import standardService from "@/api/metadata/standard";
import { mapGetters } from "vuex";
import addDoor from "./addDoor";
import { doorevents } from "@/api/visitor/visitor";

export default {
  components: { addDoor },
  data() {
    return {
      searchText: "",
      form: {},
      selectionList: [],
      loading: true,
      dialogVisible: false,
      detailDia: false,
      query: {},
      dialogType: "",
      type: "",
      size: "60%",
      currentRow: {},
      title: "",
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      option: {
        dateBtn: false,
        border: true,
        height: "auto",
        tip: true,
        searchShow: true,
        searchMenuSpan: 12,
        addBtn: false,
        refreshBtn: true,
        addRowBtn: false,
        columnBtn: true,
        delBtn: false,
        editBtn: false,
        cancelBtn: false,
        rowKey: "",
        showPage: true,
        index: true,
        indexLabel: "序号",
        selection: false,
        showHeader: true,
        expand: false,
        highlightCurrentRow: true,
        stripe: false,
        menuWidth: 250,
        reserveSelection: true,
        column: [
          {
            width: 100,
            overHidden: true,
            align: "left",
            label: "主键",
            prop: "id",
            hide: true,
            span: 4,
            key: "id",
          },
          {
            overHidden: true,
            align: "left",
            label: "门禁名称",
            prop: "door_name",
            hide: false,
            show: true,
            span: 4,
            key: "door_name",
          },
          {
            overHidden: true,
            align: "left",
            label: "门禁所在楼宇",
            prop: "building_name",
            hide: false,
            show: false,
            span: 4,
            key: "building_name",
          },
          {
            width: 100,
            overHidden: true,
            align: "left",
            label: "资产id",
            prop: "asset_id",
            hide: true,
            span: 4,
            key: "asset_id",
          },

          {
            overHidden: true,
            align: "left",
            label: "门禁标识",
            prop: "door_index_codes",
            hide: true,
            span: 4,
            key: "door_index_codes",
          },
          {
            width: 100,
            overHidden: true,
            align: "left",
            label: "门禁设备编码",
            prop: "door_ip",
            hide: true,
            span: 4,
            key: "door_ip",
          },
          {
            overHidden: true,
            align: "left",
            label: "门禁设备状态",
            prop: "door_status",

            span: 4,
            key: "door_status",
            dicData: [
              { label: "在线", value: 1 },
              { label: "离线", value: 0 },
              { label: "未检测", value: -1 },
            ],
          },
          {
            overHidden: true,
            align: "left",
            label: "门禁读卡器编码",
            prop: "card_reader_index_codes",
            hide: true,
            span: 4,
            key: "card_reader_index_codes",
          },
          {
            overHidden: true,
            align: "left",
            label: "门禁读卡器状态",
            prop: "card_reader_status",
            span: 4,
            key: "card_reader_status",
            dicData: [
              { label: "在线", value: 1 },
              { label: "离线", value: 0 },
              { label: "未检测", value: -1 },
            ],
          },
          {
            width: 100,
            overHidden: true,
            align: "left",
            label: "创建人id",
            prop: "create_user_id",
            hide: true,

            span: 4,
            key: "create_user_id",
          },
          {
            width: 100,
            overHidden: true,
            align: "left",
            label: "修改人id",
            prop: "update_user_id",
            hide: true,
            span: 4,
            key: "update_user_id",
          },
          {
            width: 200,
            overHidden: true,
            align: "left",
            label: "门禁点所在区域编码",
            prop: "door_region_index_code",
            hide: true,
            show: false,
            span: 4,
            key: "door_region_index_code",
          },
        ],
      },
      model: "wisdom_park.wp_door",
      data: [],

      ruleModel: {},
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.code_add, false),
        viewBtn: this.vaildData(this.permission.code_view, false),
        delBtn: this.vaildData(this.permission.code_delete, false),
        editBtn: this.vaildData(this.permission.code_edit, false),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  mounted() {
    this.queryByModelCode();
  },
  props: {},
  watch: {},
  methods: {
    add() {
      this.type = "add";
      this.detailDia = true;
    },
    openDetailDia(type, row) {
      this.type = type;
      this.detailDia = true;
      setTimeout(() => {
        this.currentRow = row;
      }, 0);
    },
    back() {
      this.detailDia = false;
      this.searchReset();
    },
    queryByModelCode() {
      standardService.getModelRule(this.model).then((res) => {
        this.ruleModel = res;
      });
    },
    changName(row) {
      this.title = row.name;
    },

    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },

    selectionChange(list) {
      this.selectionList = list;
    },
    selectionClear() {
      this.selectionList = [];
      this.$refs.crud.toggleSelection();
    },

    refreshChange() {
      this.onLoad(this.page);
    },
    closeDia() {
      this.detailDia = false;
      this.refreshChange();
    },
    onLoad() {
      this.loading = true;
      let body = {
        current: this.page.currentPage,
        size: this.page.pageSize,
      };
      if (this.searchText) {
        body["wheres"] = [
          { field: "visitor_name", op: "LIKE", value: this.searchText },
        ];
      }

      getMetaCommonList(this.model, body).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
        this.selectionClear();
      });
    },
    doorevents(row) {
      this.$confirm("确定打开该门禁?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          doorevents(row).then((res) => {
            if (res.data.code == 200) {
              this.$message.success("当前门禁已打开");
            }
          });
        })
        .catch(() => {});
    },
    rowDel(row, index) {
      console.log(row);
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let pk = "id";
          delMetaCommon(this.model, { pk: pk, ids: [row[pk]] }).then((res) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.onLoad(this.page);
          });
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.deleteIcon {
  font-size: 18px;
  position: absolute;
  left: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 1;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
}
/deep/ .el-drawer__header {
  border-bottom: 1px solid #ebebeb;
  padding: 10px 20px;
  margin-bottom: 0px;
}
/deep/ .el-drawer__body {
  padding: 0px;
}
/deep/ .el-dialog__body {
  padding: 0px;
}
.buildDetail /deep/ .el-dialog__body {
  padding: 0px;
}
</style>

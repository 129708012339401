<template>
  <div>
    <el-button type="primary" @click="toggle">全屏展示</el-button>
    <fullscreen :fullscreen.sync="isFullscreen">
      <div class="cockpit-container" ref="container">
        <div class="closeBtn" @click="toggle">
          <img
            class="w-40 h-40 mar-t-8"
            src="../../../public/assets/images/icon/outFull.png"
            alt=""
          />
        </div>
        <!-- 头部  -->
        <div class="header">
          <img
            src="../../../public/assets/assets/img/header.svg"
            style="width: 100%"
          />
        </div>
        <div class="cockpit-data">
          <!-- 左侧部分 -->
          <div class="cockpit-left">
            <yyfx></yyfx>
            <div class="count">
              <lyfx></lyfx>
            </div>
          </div>
          <!-- 右侧部分 -->
          <div class="cockpit-right">
            <bbfx></bbfx>
          </div>
        </div>
      </div>
    </fullscreen>
  </div>
</template>

<script>
import yyfx from "./yyfx";
import lyfx from "./lyfx";
import bbfx from "./bbfx";

export default {
  components: { yyfx, lyfx, bbfx },
  data() {
    return {
      isFullscreen: false,
    };
  },
  mounted() {},
  created() {
    this.$fullscreen.request();
  },
  methods: {
    toggle() {
      this.isFullscreen = !this.isFullscreen;
    },
  },
};
</script>

<style scoped>
.closeBtn {
  background-color: rgba(0, 0, 0, 0.6);
  width: 60px;
  height: 60px;
  border-radius: 4px;
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
  z-index: 10000;
}
.cockpit-container {
  padding: 0;
  margin: 0;
  font-family: Microsoft YaHei;
  font-weight: 400;
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  background-color: #070731;
  display: flex;
  flex-direction: column;
  color: #fff;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  cursor: pointer;
}

.header {
  width: 100%;
  overflow: hidden;
  flex-shrink: 0;
}

.cockpit-data {
  flex-shrink: 0;
  height: 88.89vh;
  display: flex;
  display: -webkit-flex;
  flex-wrap: nowrap;
  overflow: auto;
  padding: 0 10px;
}

.cockpit-container .cockpit-left,
.cockpit-container .cockpit-right {
  height: 960px;
}

.cockpit-container .cockpit-left {
  width: 979px;
}

.cockpit-container .cockpit-right {
  width: 916px;
  padding: 0 5px;
}

.cockpit-container .cockpit-left {
  background: url(../../../public/assets/assets/img/bg001.svg) no-repeat 100%/100%;
}

/** 标题渐变色样式 **/
.cockpit-title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  color: #fff;
  font-weight: bold;
  background: linear-gradient(
    90deg,
    rgba(0, 238, 255, 0.7) 0%,
    rgba(60, 165, 255, 0) 100%
  );
}

.cockpit-title img {
  width: 40px;
}

.mt {
  margin-top: 5px;
}

.cockpit-mid-title {
  padding: 15px;
}

.flex {
  display: flex;
  display: -webkit-flex;
  align-items: center;
}

/** 左侧 小框内容 **/
.data-content {
  display: flex;
  display: -webkit-flex;
  margin-top: 15px;
}

.data-item {
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 110px;
  height: 60px;
  margin-right: 8px;
}

.data-item > p.num {
  font-size: 18px;
  font-weight: bold;
}

.data-item > p {
  margin: 0px;
  margin-top: 5px;
  font-size: 11px;
}

/** 楼宇租赁分析表格样式 **/
.simulate_thead {
  display: flex;
  display: -webkit-flex;
  flex-wrap: nowrap;
  margin-top: 15px;
}

.simulate_thead p {
  flex-shrink: 0;
  font-size: 13px;
  line-height: 36px;
  text-align: right;
  background: rgba(0, 238, 255, 0.3);
}

.tr-div {
  display: flex;
  display: -webkit-flex;
  flex-wrap: nowrap;
  position: relative;
}

.tr-div > p {
  flex-shrink: 0;
  line-height: 40px;
  font-size: 13px;
  text-align: right;
}

.tr-div::after {
  position: absolute;
  content: "";
  width: 100%;
  left: 0;
  bottom: 0;
  height: 1px;

  background: linear-gradient(
    90deg,
    rgba(0, 238, 255, 0.1) 0%,
    rgba(0, 238, 255, 0.7) 51%,
    rgba(0, 238, 255, 0.01) 100%
  );
}

.simulate_thead > p:nth-child(2),
.tr-div > p:nth-child(2) {
  text-align: left;
}

.simulate_thead > p:first-child,
.tr-div > p:first-child {
  text-align: center;
}

.tr-div > p:last-child {
  padding-right: 10px;
}
</style>

<template>
  <basic-container>
    <el-card class="mar-b-20">
      <el-form ref="form" :model="query" label-width="80px" size="small">
        <el-col :span="5">
          <el-form-item label="人员类型">
            <el-select v-model="query.type" size="small" placeholder="请选择人员类型">
              <el-option label="普通用户" value="1"></el-option>
              <el-option label="访客" value="2"></el-option>
              <el-option label="会员" value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="联系电话">
            <el-input v-model="query.phone" size="small"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-button type="primary" @click="searchChange" size="small">查询</el-button>
          <el-button @click="searchReset" size="small">清空</el-button>
        </el-col>
      </el-form>
    </el-card>
    <el-card class="mar-b-20" v-if="showForm">
      <div slot="header" class="clearfix">
        <span>用户信息</span>
      </div>
      <div class="recy-form">
        <avue-form :option="userOption" v-model="ruleForm"></avue-form>
      </div>
    </el-card>
    <el-card>
      <div slot="header" class="clearfix">
        <span>门禁权限</span>
      </div>
      <div class="mar-15f mar-t-20f">
        <avue-crud class="pad-0" :option="option" :table-loading="loading" :data="data" ref="crud" v-model="form" :permission="permissionList" @search-reset="searchReset">
          <template slot-scope="scope" slot="menu">
            <el-button
              type="text"
              size="small"
              icon="el-icon-setting
"
              @click.stop="hsqx(scope.row, scope.index)"
              >回收权限
            </el-button>
          </template>
          <template slot-scope="{ row }" slot="deptCategory">
            <el-tag>{{ row.deptCategoryName }}</el-tag>
          </template>
        </avue-crud>
        <div v-if="data.length > 0" class="pad-r-20 mar-b-10 pull-right">
          共<span class="fts-14 mar-l-3 mar-r-3">{{ data.length }}</span
          >条
        </div>
      </div>
    </el-card>
  </basic-container>
</template>

<script>
import { authLogNew, deleteAuthConfig } from "@/api/system/recycle"
import * as dictService from "@/api/system/dictbiz"
import website from "@/config/website"

import { mapGetters } from "vuex"
export default {
  data() {
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"))
      } else {
        callback()
      }
    }
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"))
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"))
      } else {
        callback()
      }
    }
    return {
      form: {},

      showForm: false,
      selectionList: [],
      query: {},
      loading: false,
      parentId: 0,
      ruleForm: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      option: {
        tip: false,
        simplePage: true,
        searchShow: true,
        searchMenuSpan: 6,
        tree: true,
        border: true,
        index: false,
        selection: false,
        viewBtn: false,
        addBtn: false,
        editBtn: false,
        delBtn: false,
        menuWidth: 150,
        dialogClickModal: false,
        column: [
          {
            label: "门禁名称",
            prop: "name",
            rules: [
              {
                required: true,
                message: "请输入门禁名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "所属区域",
            prop: "locationBuildName",
            rules: [
              {
                required: true,
                message: "请输入所属区域",
                trigger: "blur",
              },
            ],
          },
          {
            label: "类型",
            prop: "doorType",
            search: false,
            searchSpan: 6,
            type: "select",
            dicData: [
              {
                label: "人员通道门禁",
                value: 0,
              },
              {
                label: "会议室门禁",
                value: 1,
              },
              {
                label: "场地门禁",
                value: 2,
              },
            ],
            rules: [
              {
                required: true,
                message: "请输入类型",
                trigger: "blur",
              },
            ],
          },
          {
            label: "电话",
            prop: "phone",
            span: 6,
            search: false,
            hide: true,
            rules: [
              {
                required: true,
                message: "请输入电话",
                trigger: "blur",
              },
            ],
          },
          {
            label: "开始时间",
            prop: "startTime",
            type: "date",
            format: "yyyy-MM-dd",
            rules: [
              {
                required: true,
                message: "请输入所属区域",
                trigger: "blur",
              },
            ],
          },
          {
            label: "结束时间",
            prop: "endTime",
            type: "date",
            format: "yyyy-MM-dd",
            rules: [
              {
                required: true,
                message: "请输入所属区域",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      userOption: {
        height: "auto",
        readonly: true,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        disabled: true,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "登录账号",
            prop: "account",
            search: true,
            display: false,
          },
          {
            label: "所属租户",
            prop: "tenantName",
            slot: true,
            display: false,
          },
          {
            label: "用户姓名",
            prop: "realName",
            search: true,
            display: false,
          },
          {
            label: "所属角色",
            prop: "roleName",
            slot: true,
            display: false,
          },
          {
            label: "所属部门",
            prop: "deptName",
            slot: true,
            display: false,
          },
          {
            label: "用户平台",
            prop: "userTypeName",
            slot: true,
            display: false,
          },
          // {
          //     width: 100,
          //     label: '人脸识别开通状态',
          //     prop: 'faceOcrStatus',
          //     viewDisplay: false,
          //     addDisplay: false,
          //     editDisplay: false,
          //     type: 'select',
          //     dicUrl: dictService.getUrl('faceOcrStatus'),
          //     props: {
          //         label: 'dictValue',
          //         value: 'dictKey',
          //     },
          // },
          {
            label: "用户平台",
            type: "select",
            dicUrl: "/api/jida-system/dict/dictionary?code=user_type",
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: "number",
            search: true,
            hide: true,
            display: false,
            prop: "userType",
          },
        ],
        group: [
          {
            label: "基础信息",
            prop: "baseInfo",
            icon: "el-icon-user-solid",
            column: [
              {
                label: "所属租户",
                prop: "tenantId",
                readOnly: true,
                type: "tree",
                dicUrl: "/api/jida-system/tenant/select",
                props: {
                  label: "tenantName",
                  value: "tenantId",
                },
                hide: !website.tenantMode,
                addDisplay: website.tenantMode,
                editDisplay: website.tenantMode,
                viewDisplay: website.tenantMode,
                placeholder: " ",
                span: 6,
              },
              {
                label: "登录账号",
                prop: "account",
                readOnly: true,
                span: 6,
                placeholder: " ",
              },
              {
                label: "用户平台",
                readonly: true,
                type: "select",
                dicUrl: "/api/jida-system/dict/dictionary?code=user_type",
                props: {
                  label: "dictValue",
                  value: "dictKey",
                },
                dataType: "number",
                slot: true,
                prop: "userType",
                span: 6,
                placeholder: " ",
              },
            ],
          },
          {
            label: "详细信息",
            prop: "detailInfo",
            icon: "el-icon-s-order",
            column: [
              {
                label: "用户昵称",
                prop: "name",
                hide: true,
                readonly: true,
                span: 6,
                placeholder: " ",
              },
              {
                label: "用户姓名",
                prop: "realName",
                readonly: true,
                span: 6,
                placeholder: " ",
              },
              {
                label: "手机号码",
                prop: "phone",
                readonly: true,
                overHidden: true,
                span: 6,
                placeholder: " ",
              },
              {
                label: "电子邮箱",
                prop: "email",
                readonly: true,
                hide: true,
                overHidden: true,
                span: 6,
                placeholder: " ",
              },
              {
                label: "用户性别",
                prop: "sex",
                type: "select",
                readonly: true,
                dicData: [
                  {
                    label: "男",
                    value: 1,
                  },
                  {
                    label: "女",
                    value: 2,
                  },
                  {
                    label: "未知",
                    value: 3,
                  },
                ],
                span: 6,
                placeholder: " ",
                hide: true,
              },
              {
                label: "用户生日",
                readonly: true,
                type: "date",
                prop: "birthday",
                format: "yyyy-MM-dd hh:mm:ss",
                valueFormat: "yyyy-MM-dd hh:mm:ss",
                hide: true,
                span: 6,
                placeholder: " ",
              },
              {
                label: "头像",
                prop: "avatar",
                readonly: true,
                span: 24,
                placeholder: " ",
                component: "form-file",
                params: {
                  max: 1,
                  accept: "image/*",
                  multiple: false,
                },

                tip: "",
                // action: "/api/jida-resource/oss/endpoint/put-file-attach",
              },
              {
                label: "账号状态",
                readonly: true,
                prop: "statusName",
                hide: true,
                display: false,
              },
            ],
          },
          {
            label: "职责信息",
            prop: "dutyInfo",
            icon: "el-icon-s-custom",
            column: [
              {
                label: "用户编号",
                prop: "code",
                placeholder: " ",
                span: 6,
              },
              {
                label: "所属角色",
                prop: "roleId",
                multiple: true,
                type: "tree",
                dicData: [],
                props: {
                  label: "title",
                },
                checkStrictly: true,
                slot: true,
                placeholder: " ",
                span: 6,
              },
              {
                label: "所属部门",
                prop: "deptId",
                type: "tree",
                multiple: true,
                dicData: [],
                placeholder: " ",
                props: {
                  label: "title",
                },
                checkStrictly: true,
                slot: true,
                span: 6,
              },
              {
                label: "所属岗位",
                prop: "postId",
                type: "tree",
                multiple: true,
                dicData: [],
                props: {
                  label: "postName",
                  value: "id",
                },
                span: 6,
                placeholder: " ",
              },
            ],
          },
        ],
      },
      data: [],
    }
  },
  computed: {
    ...mapGetters(["userInfo", "permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.dept_add, false),
        viewBtn: this.vaildData(this.permission.dept_view, false),
        delBtn: this.vaildData(this.permission.dept_delete, false),
        editBtn: this.vaildData(this.permission.dept_edit, false),
      }
    },
  },
  methods: {
    hsqx(row) {
      console.log(row)
      //   return
      let obj = {
        personDatas: [
          {
            indexCodes: [row.personDataId], //[对应删除数据行里的auth.personDataId]
            personDataType: "person", //写死
          },
        ],
        resourceInfos: [
          {
            // resourceIndexCode: row.resourceIndexCode,
            resourceIndexCode: row.channelIndexCode,
            resourceType: row.resourceType, //对应删除数据行里的auth.resourceType
            channelNos: [row.channelNo], //[对应删除数据行里的auth.channelNo]
          },
        ],
      }

      deleteAuthConfig(obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "操作成功!",
          })
        } else {
          this.$message({
            type: "error",
            message: "操作成功!",
          })
        }
      })
    },
    searchReset() {
      this.query = {}
      this.data = []
      this.showForm = false
      this.ruleForm = {}
    },
    searchChange() {
      this.onLoad()
    },
    onLoad() {
      this.loading = true
      authLogNew(this.query)
        .then((res) => {
          this.data = res.data.data.auth
          this.ruleForm = res.data.data.user
          this.showForm = true
          this.loading = false
        })
        .catch((e) => {
          this.loading = false
        })
    },
  },
}
</script>
<style lang="scss" scoped>
/deep/ .recy-form .el-select .el-input.is-disabled .el-input__inner {
  cursor: not-allowed;
  background: #fff !important;
  border: 0px !important;
  color: #606266;
}
/deep/ .recy-form .el-input__suffix {
  display: none;
}
/deep/ .recy-form .el-input.is-disabled .el-input__inner {
  background-color: #fff !important;
  border: 0px !important;
  color: #c0c4cc;
  cursor: not-allowed;
  color: #606266;
}
</style>

<template>
    <div>
        <el-form class="login-form" status-icon :rules="loginRules" ref="loginForm" :model="loginForm" label-width="0">
            <!-- <el-form-item v-if="tenantMode" prop="tenantId">
      <el-input
        size="small"
        @keyup.enter.native="handleLogin"
        v-model="loginForm.tenantId"
        auto-complete="off"
        :placeholder="$t('login.tenantId')"
      >
        <i slot="prefix" class="icon-quanxian" />
      </el-input>
    </el-form-item> -->
            <el-form-item prop="username">
                <el-input size="small" @keyup.enter.native="handleLogin" v-model="loginForm.username" auto-complete="off" :placeholder="$t('login.username')">
                    <i slot="prefix" class="icon-yonghu" />
                </el-input>
            </el-form-item>
            <el-form-item prop="password">
                <el-input size="small" @keyup.enter.native="handleLogin" :type="passwordType" v-model="loginForm.password" auto-complete="off" :placeholder="$t('login.password')">
                    <i class="el-icon-view el-input__icon" slot="suffix" @click="showPassword" />
                    <i slot="prefix" class="icon-mima" />
                </el-input>
            </el-form-item>
            <el-form-item v-if="captchaMode" prop="code">
                <el-row :span="24">
                    <el-col :span="16" class="pad-0">
                        <el-input size="small" @keyup.enter.native="handleLogin" v-model="loginForm.code" auto-complete="off" :placeholder="$t('login.code')">
                            <i slot="prefix" class="icon-yanzhengma" />
                        </el-input>
                    </el-col>
                    <el-col :span="8">
                        <div class="login-code">
                            <img :src="loginForm.image" class="login-code-img" @click="refreshCode" />
                        </div>
                    </el-col>
                </el-row>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" size="small" @click.native.prevent="handleLogin" class="login-submit btnlogin">{{ $t("login.submit") }}
                </el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import { info } from '@/api/system/tenant';
import { getCaptcha, getCaptchaMode } from '@/api/user';
import { getTopUrl } from '@/util/util';

import website from '@/config/website';
function isURL(s) {
    if (s.includes('html')) return true;
    return /^http[s]?:\/\/.*/.test(s);
}

const tagWel = website.fistPage;
export default {
    name: 'userlogin',

    data() {
        return {
            tenantMode: this.website.tenantMode,
            captchaMode: this.website.captchaMode,
            loginForm: {
                //租户ID
                tenantId: '000000',
                //用户名
                username: '',
                //密码
                password: '',
                //账号类型
                type: 'account',
                //验证码的值
                code: '',
                //验证码的索引
                key: '',
                //预加载白色背景
                image: 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7',
            },
            loginRules: {
                tenantId: [{ required: false, message: '请输入租户ID', trigger: 'blur' }],
                username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { min: 1, message: '密码长度最少为6位', trigger: 'blur' },
                ],
            },
            passwordType: 'password',
        };
    },
    created() {
        this.refreshCode();
        // this.getCaptchaMode();
        this.getTenant();
    },
    mounted() {},
    computed: {
        ...mapGetters(['tagWel']),
    },
    props: [],
    methods: {
        getCaptchaMode() {
            getCaptchaMode()
                .then(res => {
                    const data = res.data;
                    if (data.data === 'false' || data.data === false) {
                        this.captchaMode = false;
                    } else {
                        this.captchaMode = true;
                    }
                })
                .catch(() => {
                    // this.getCaptchaMode();
                });
        },
        refreshCode() {
            getCaptcha().then(res => {
                const data = res.data;
                this.loginForm.key = data.key;
                this.loginForm.image = data.image;
            });
        },
        showPassword() {
            this.passwordType === '' ? (this.passwordType = 'password') : (this.passwordType = '');
        },
        handleLogin() {
            this.$refs.loginForm.validate(valid => {
                if (valid) {
                    const loading = this.$loading({
                        lock: true,
                        text: '登录中,请稍后。。。',
                        spinner: 'el-icon-loading',
                    });
                    this.$store.commit('SET_MENU_LOAD', false);

                    this.$store
                        .dispatch('LoginByUsername', this.loginForm)
                        .then(() => {
                            this.$store
                                .dispatch('GetMenu')
                                .then(res => {
                                    if (res.length > 0) {
                                        if (tagWel && tagWel.value) {
                                            this.$router.push({
                                                path: tagWel.value,
                                            });
                                            loading.close();
                                        } else {
                                            for (let i = 0; i < res.length; i++) {
                                                if (!isURL(res[i].path)) {
                                                    this.$router.push({
                                                        path: res[i].path,
                                                    });
                                                    break;
                                                }
                                            }
                                        }
                                        loading.close();
                                    } else {
                                        this.$message.error('抱歉,暂无登录系统权限,请联系管理员。');
                                        loading.close();
                                        this.refreshCode();
                                        this.$store
                                            .dispatch('LogOut')
                                            .then(() => {
                                                commit('SET_USER_PASS', '');
                                                commit('SET_TOKEN', '');
                                                commit('SET_REFRESH_TOKEN', '');
                                                commit('SET_TENANT_ID', '');
                                                commit('SET_USER_INFO', '');
                                                commit('DEL_ALL_TAG', '');
                                                commit('CLEAR_LOCK', '');
                                            })
                                            .catch(() => {
                                                localStorage.clear();
                                                window.location.reload();
                                            });
                                    }
                                })
                                .catch(() => {
                                    loading.close();
                                    this.refreshCode();
                                });
                            // }
                        })
                        .catch(() => {
                            loading.close();
                            // this.$message.error("登录失败,请联系管理员。");
                            this.refreshCode();
                        });
                }
            });
        },
        addTag(label, value, close) {
            this.$store.commit('ADD_TAG', {
                label: label,
                value: value,
                params: {},
                query: {},
                group: [],
                close: close,
            });
        },
        getTenant() {
            let domain = getTopUrl();
            info(domain).then(res => {
                const data = res.data;
                if (data.success && data.data.tenantId) {
                    this.tenantMode = false;
                    this.loginForm.tenantId = data.data.tenantId;
                    this.$parent.$refs.login.style.backgroundImage = `url(${data.data.backgroundUrl})`;
                }
            });
        },
    },
};
</script>

<style>
.btnlogin {
    background: #4495ff;
    color: #fff;
}
</style>

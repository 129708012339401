<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" :page.sync="page" :before-open="beforeOpen" v-model="form" ref="crud" @row-save="rowSave" @search-change="searchChange"
                   @search-reset="searchReset" @refresh-change="refreshChange" @on-load="onLoad">
            <template slot="menuLeft" slot-scope="scope">
                <el-button class="mar-l-5" icon="el-icon-download" @click="getMetaCommonListExport" size="small">导出</el-button>
            </template>
            <template slot-scope="scope" slot="menu">
                <template v-if="scope.row.status == 0">
                    <el-button type="text" size="small" icon="el-icon-download" class="none-border" @click="tip(scope.row)">处理</el-button>
                </template>
                <el-button type="text" size="small" icon="el-icon-delete" class="none-border" @click="rowDel(scope.row)">删除
                </el-button>

            </template>
        </avue-crud>
    </basic-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getUser } from '@/api/system/user';
import { getPicListByIds } from '@/api/busiMode/metaCommon';

import {
    getMetaCommonList,
    saveMetaCommon,
    updateMetaCommon,
    delMetaCommon,
    getMetaCommonListExport,
} from '@/api/busiMode/metaCommon';
import * as dictService from '@/api/system/dictbiz';

export default {
    data() {
        return {
            form: {},
            query: {},
            loading: true,
            status: '',
            floorLists: [],
            queryParam: {},
            statusOptions: [
                {
                    label: '正常',
                    value: '1',
                },
                {
                    label: '关闭',
                    value: '2',
                },
            ],
            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            selectionList: [],
            option: {
                height: 'auto',
                calcHeight: 30,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: true,
                viewBtn: true,
                editBtn: false,
                delBtn: false,
                dialogClickModal: false,
                column: [
                    {
                        width: 100,
                        label: '问题类型',
                        prop: 'problem_type',
                        type: 'select',
                        dicUrl: dictService.getUrl('problem_type'),
                        props: {
                            label: 'dictValue',
                            value: 'dictKey',
                        },
                        dicFormatter: data => {
                            let datalist = data.data;
                            datalist.forEach(ele => {
                                ele.dictKey = Number(ele.dictKey);
                            });
                            return datalist;
                        },
                    },
                    {
                        label: '反馈人',
                        prop: 'applicant_name',
                        searchSpan: 4,
                        rules: [
                            {
                                required: true,
                                message: '请输入反馈人',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '手机号',
                        prop: 'applicant_phone',
                        rules: [
                            {
                                required: true,
                                message: '请输入反馈人手机号',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '状态',
                        search: true,
                        prop: 'status',
                        type: 'select',
                        dicData: [
                            { label: '未处理', value: 0 },
                            { label: '已处理', value: 1 },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                    },

                    {
                        label: '创建时间',
                        addDisabled: true,
                        prop: 'create_time',
                        type: 'date',
                        minRows: 2,
                        format: 'yyyy年MM月dd日',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        rules: [
                            {
                                required: false,
                                message: '请输入场馆描述',
                                trigger: 'blur',
                            },
                        ],
                        row: true
                    },
                    {
                        label: '照片',
                        prop: 'problem_pictures',
                        type: 'upload',
                        listType: 'picture-card',
                        span: 24,
                        hide: true,
                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'attachId',
                        },
                        tip: '只能上传jpg/png用户头像，且不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },

                    {
                        label: '描述',
                        prop: 'problem_desc',
                        type: 'textarea',
                        span: 24,
                    },
                ],
            },
            data: [],
        };
    },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    methods: {
        rowSave(row, done, loading) {
            if (row.problem_pictures && row.problem_pictures.length > 0) {
                let iconId = [];
                row.problem_pictures.forEach(e => {
                    iconId.push(e.label);
                });
                row.problem_pictures = iconId.toString();
            }
            saveMetaCommon('wisdom_park.wp_problem', {
                values: row,
            }).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    window.console.log(error);
                },
            );
        },

        beforeOpen(done, type) {
            if (['edit', 'view'].includes(type)) {
                if (this.form.problem_pictures) {
                    getPicListByIds(this.form.problem_pictures).then(res => {
                        if (res.data.code == 200) {
                            this.form.problem_pictures = [];
                            let headFiles = [];
                            this.form.problem_pictures = headFiles;
                            res.data.data.forEach(pic => {
                                headFiles.push({
                                    label: pic.id,
                                    value: pic.link,
                                    url: pic.url,
                                });
                            });
                            this.form.problem_pictures = headFiles;
                        }
                    });
                }
            }
            if (['add'].includes(type)) {
                this.form.applicant_name = this.userInfo.user_name;
                console.log(this.userInfo);
                if (this.userInfo.user_id) {
                    this.getUserPhone(this.userInfo.user_id);
                }
            }
            done();
        },
        getMetaCommonInfo(id) {
            let obj = {
                pk: 'id',
                id: id,
            };
        },
        getUserPhone(id) {
            getUser(id).then(res => {
                this.form.applicant_phone = res.data.data.phone;
            });
        },
        searchReset() {
            this.query = {};
            this.queryParam = {};
            this.onLoad(this.page);
        },
        rowDel(row, index) {
            console.log(row);
            this.$confirm('是否删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let pk = 'id';
                    delMetaCommon('wisdom_park.wp_problem', { pk: pk, ids: [row[pk]] }).then(res => {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        this.onLoad(this.page);
                    });
                })
                .catch(() => {});
        },
        searchChange(params, done) {
            this.query = params;
            this.page.currentPage = 1;
            this.onLoad(this.page, params);

            done();
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },

        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        onLoad(page, params) {
            this.queryParam = params || this.queryParam;
            this.loading = true;
            let obj = {
                current: this.page.currentPage,
                size: this.page.pageSize,
                wheres: [],
                orders: [{ field: 'create_time', sort: 'DESC' }],
            };
            if (this.queryParam.status == 0 || this.queryParam.status == 1) {
                obj.wheres.push({
                    field: 'status',
                    value: this.queryParam.status,
                });
            }
            getMetaCommonList('wisdom_park.wp_problem', obj).then(res => {
                const data = res.data.data;
                this.data = data.records;
                this.page.total = data.total;
                this.loading = false;
                this.selectionClear();
            });
        },
        getMetaCommonListExport() {
            let obj = {
                current: this.page.currentPage,
                size: this.page.pageSize,
                wheres: [],
                orders: [{ field: 'create_time', sort: 'DESC' }],
            };
            if (this.queryParam.status == 0 || this.queryParam.status == 1) {
                obj.wheres.push({
                    field: 'status',
                    value: this.queryParam.status,
                });
            }
            getMetaCommonListExport('wisdom_park.v_wp_problem', obj)
                .then(res => {
                    this.downLoading = false;
                    let url = window.URL.createObjectURL(new Blob([res.data]));
                    let link = document.createElement('a');
                    link.style.display = 'none';
                    link.href = url;
                    link.download = '园区问题反馈' + '.xls';
                    document.body.appendChild(link);
                    link.click();
                })
                .catch(e => {
                    this.downLoading = false;
                });
        },
        tip(row) {
            //row.status = 1;
            let obj = {};
            Object.keys(row).forEach(ele => {
                if (!ele.includes('$') && row[ele] != '') {
                    obj[ele] = row[ele];
                }
            });
            console.log(row);
            this.$alert('确定处理该反馈问题吗？', '确定').then(() => {
                let o = {
                    update: obj,
                    primaryKeyValue: row['id'],
                    primaryKey: 'id',
                };
                updateMetaCommon('wisdom_park.wp_problem', o).then(res => {
                    if (res.data.success) {
                        if (row.status === 1) {
                            row.status = 0;
                            this.$forceUpdate();
                        } else {
                            row.status = 1;
                            this.$forceUpdate();
                        }
                        this.$message.success(res.data.msg);
                    } else {
                        this.$message.success(res.data.msg);
                    }
                });
            });
        },
    },
};
</script>

<style></style>

<template>
  <div>
    <el-form
      :model="ruleForm"
      ref="ruleForm"
      label-width="100px"
      label-position="top"
      size="small"
      :disabled="onlyRead"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="房间名称" prop="name">
            <el-input
              v-model="ruleForm.name"
              placeholder="请选择房间名称"
            ></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="房间面积(m²)" prop="room_area">
            <el-input
              v-model="ruleForm.room_area"
              placeholder="请选择房间面积"
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="付款方式" prop="property_management_fee">
            <el-select
              v-model="ruleForm.payment_type"
              size="small"
              placeholder="请选择付款方式"
            >
              <el-option
                v-for="item in paymentTypeList"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="出租单价/日" prop="rental_unit_price_day">
            <el-input
              type="number"
              v-model.number="ruleForm.rental_unit_price_day"
              placeholder="出租单价"
            >
              <template slot="append">元/m²/天</template></el-input
            >
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import { getMetaCommonList, getPicListByIds } from "@/api/busiMode/metaCommon";
import * as dictbizService from "@/api/system/dictbiz";

export default {
  data() {
    return {
      ruleForm: {
        label: [],
      },
      roomTags: [],
      dialogImageUrl: "",
      page: {
        pageSize: 1000,
        currentPage: 1,
        total: 0,
      },
      buildList: [],
      decorationList: [],
      paymentTypeList: [],
      onlyRead: false,
      housingResourcesTypes: [],
      option1: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,
        disabled: false,
        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,
            // disabled: true,
            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      option: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,
        disabled: false,
        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,
            // disabled: true,
            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      imgform: {
        imgUrl: [],
      },
      imgform1: {
        imgUrl: [],
      },
    };
  },
  props: {
    floors: {
      type: Array,
      default: function () {
        return [];
      },
    },
    roomObj: {
      type: String,
      default: function () {
        return "";
      },
    },
    index: {
      type: Number,
      default: function () {
        return 0;
      },
    },
  },
  watch: {
    roomObj: {
      handler: function (newValue, oldName) {
        if (newValue) {
          this.ruleForm = JSON.parse(newValue);
        }
      },
      immediate: true,
      deep: true,
    },
    onlyRead: {
      handler: function (newValue, oldName) {
        if (newValue) {
          this.option.disabled = true;
          this.option1.disabled = true;
        } else {
          this.option.disabled = false;
          this.option1.disabled = false;
        }
        this.option = JSON.parse(JSON.stringify(this.option));
        this.option1 = JSON.parse(JSON.stringify(this.option1));
      },
      deep: true,
    },
    "ruleForm.room_area": {
      handler: function (newValue, oldName) {
        this.setMonthPrice();
      },
      deep: true,
    },
    "ruleForm.rental_unit_price_day": {
      handler: function (newValue, oldName) {
        this.setMonthPrice();
      },
      deep: true,
    },
  },
  computed: {},
  mounted() {
    if (this.ruleFormObj) {
      if (
        typeof this.ruleFormObj.label == "string" &&
        this.ruleFormObj.label.constructor == String &&
        this.ruleFormObj.label != ""
      ) {
        this.ruleFormObj.label = this.ruleFormObj.label.split(",");
      }
      this.ruleForm = this.ruleFormObj;
      if (this.ruleForm.attachment) {
        this.getpicList(this.ruleForm.attachment, "imgform");
      }
      if (this.ruleForm.layout_attachment) {
        this.getpicList(this.ruleForm.layout_attachment, "imgfor1");
      }
    }

    if (this.onlyRead) {
      this.option.disabled = true;
      this.option1.disabled = true;
    } else {
      this.option.disabled = false;
      this.option1.disabled = false;
    }
    this.option = JSON.parse(JSON.stringify(this.option));
    this.option1 = JSON.parse(JSON.stringify(this.option1));
  },

  created() {
    this.getBuildList();
    this.getDicList();
  },
  methods: {
    setMonthPrice() {
      if (
        this.ruleForm.room_area != "" &&
        this.ruleForm.rental_unit_price_day != ""
      ) {
        let m =
          Number(this.ruleForm.room_area) *
          Number(this.ruleForm.rental_unit_price_day);
        this.ruleForm.rental_unit_price_month = m ? m * 30 : 0;
      } else {
        this.ruleForm.rental_unit_price_month = 0;
      }
    },
    getpicList(ids, d) {
      getPicListByIds(ids).then((res) => {
        if (res.data.code == 200) {
          let arr = [];
          res.data.data.forEach((pic) => {
            arr.push({
              label: pic.id,
              value: pic.link,
            });
          });
          if (d == "imgform") {
            this.imgform.imgUrl = arr;
          } else {
            this.imgform1.imgUrl = arr;
          }
        }
      });
    },
    getTagName(tag) {
      let tagName = "";
      this.roomTags.forEach((item) => {
        if (item.id === tag) {
          tagName = item["name"];
        }
      });
      return tagName;
    },
    getDicList() {
      dictbizService
        .getDictionary({
          code: "RoomBrightenedDot",
        })
        .then((res) => {
          this.roomTags = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
              id: item["id"],
            };
          });
        });
      dictbizService
        .getDictionary({
          code: "decoration",
        })
        .then((res) => {
          this.decorationList = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
      dictbizService
        .getDictionary({
          code: "paymentType",
        })
        .then((res) => {
          this.paymentTypeList = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });

      dictbizService
        .getDictionary({
          code: "housingResourcesType",
        })
        .then((res) => {
          this.housingResourcesTypes = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
    },
    getBuildList() {
      getMetaCommonList("wisdom_park.wp_building", this.page).then((res) => {
        const data = res.data.data;
        this.buildList = data.records;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
  margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
  left: 0px;
}
/deep/ .el-divider__text {
  padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
  width: 68px;
  height: 68px;
  line-height: 72px;
  vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
}
</style>

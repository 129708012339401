<template>
  <basic-container
    isCard
    :body-style="{ padding: '0', height: '100%' }"
    class="h-100p"
  >
    <el-container class="h-100p">
      <el-header height="50px" class="lh-50 bor-b" v-if="!isTodo">
        <basic-page-header
          @back="back"
          class="lh-50"
          :title="'发布详情'"
        ></basic-page-header>
      </el-header>
      <el-container style="height: calc(100% - 50px)" class="pad-20">
        <el-main class="ov-a">
          <el-descriptions
            title="发布详情"
            :column="4"
            :colon="false"
            direction="vertical"
            :labelStyle="labelStyle"
          >
            <el-descriptions-item label="节目名称">{{
              ruleForm.name
            }}</el-descriptions-item>
            <el-descriptions-item label="发布周期">
              <span v-if="ruleForm.mode == 'daily'">天</span>
              <span v-if="ruleForm.mode == 'weekly'">周</span>
              <span v-if="ruleForm.mode == 'loop'">轮播</span>
              <span v-if="ruleForm.mode == 'selfDefine'">自定义</span>
            </el-descriptions-item>
            <el-descriptions-item label="展示有效期" :span="2">
              {{ ruleForm.startTime }} - {{ ruleForm.endTime }}
            </el-descriptions-item>

            <el-descriptions-item label="设备" :span="4">
              {{ ruleForm.publishDeviceNames }}</el-descriptions-item
            >

            <el-descriptions-item label="节目">
              <template v-if="imgform.imgUrl.length > 0">
                <avue-form
                  class="mar-t-20"
                  :option="option1"
                  v-model="imgform"
                ></avue-form>
              </template>
            </el-descriptions-item>
          </el-descriptions>
        </el-main>
      </el-container>
    </el-container>
  </basic-container>
</template>

<script>
import standardService from "@/api/metadata/standard";
import { submit } from "@/api/visitor/visitor";
import { mapGetters, mapState } from "vuex";
import * as dictbizService from "@/api/system/dictbiz";

import {
  getMetaCommonList,
  getMetaCommonInfo,
  getPicListByIds,
} from "@/api/busiMode/metaCommon";

export default {
  data() {
    return {
      ruleModel: {},
      labelStyle: {
        color: "#999",
        fontSize: "12px",
      },
      cardTypeName: "",
      option1: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,
        disabled: true,
        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,

            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      option: {
        labelWidth: 0,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "",
            prop: "imgUrl",
            type: "upload",
            listType: "picture-card",
            loadText: "附件上传中，请稍等",
            span: 24,

            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png文件，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
        ],
      },
      imgform: {
        imgUrl: [],
      },
      ruleForm: {},
      params: {
        zoom: 4,
        zoomEnable: false,
        dragEnable: true,
      },
      ruleModel: {},
      companyList: [],
      model: "wisdom_park.wp_publish_schedule",
      programModel: "wisdom_park.wp_publish_program",
      cardTypeList: [],
    };
  },
  computed: {},
  mounted() {
    //this.getMetaCommonList();
    // this.getDicList();
    if (this.$router.currentRoute.query.publishId) {
      this.ruleForm.id = this.$router.currentRoute.query.publishId;
      this.id = this.$router.currentRoute.query.publishId;
      this.getMetaCommonInfo();
    }
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
  props: {
    id: {
      type: String,
      default: "",
    },
    isTodo: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(val) {
        if (val) {
          this.getMetaCommonInfo();
        }
      },
    },
  },
  methods: {
    getDicList() {
      dictbizService
        .getDictionary({
          code: "cardType",
        })
        .then((res) => {
          this.cardTypeList = (res.data.data || []).map((item) => {
            return {
              name: item["dictValue"],
              value: item["dictKey"],
            };
          });
        });
    },
    getCardType(val) {
      cardTypeList.forEach((card) => {
        if (val == card.value) {
          this.cardTypeName = card.name;
        }
      });
    },
    changeCom() {
      let name = "";
      this.companyList.forEach((e) => {
        if (e.id == this.ruleForm.companyId) {
          name = e.name;
        }
      });
      this.ruleForm.companyName = name;
    },
    toHump(name) {
      return name.replace(/\_(\w)/g, function (all, letter) {
        return letter.toUpperCase();
      });
    },
    //toHump('hello_js_go') //helloJsGo

    getMetaCommonInfo() {
      let obj = {
        pk: "id",
        id: this.id,
      };
      getMetaCommonInfo(this.model, obj).then((res) => {
        if (res.data.code == 200) {
          let obj = {};
          Object.keys(res.data.data).forEach((ele) => {
            obj[this.toHump(ele)] = res.data.data[ele];
          });

          this.ruleForm = obj;
          console.log(this.ruleForm);
          if (this.ruleForm.publishProgramIds) {
            //this.getCompanyId(this.ruleForm.publishProgramIds);
            this.getMetaCommonList(this.ruleForm.publishProgramIds);
          }
        }
      });
    },
    getCompanyId(val) {
      getPicListByIds(val).then((res) => {
        if (res.data.code == 200) {
          let arr = [];
          res.data.data.forEach((pic) => {
            arr.push({
              label: pic.id,
              value: pic.link,
            });
          });
          this.imgform.imgUrl = arr;
        }
      });
    },
    queryByModelCode() {
      standardService.getModelRule(this.entModel).then((res) => {
        this.ruleModel = res;
      });
    },
    getMetaCommonList(val) {
      let body = {
        size: 100,
        current: 1,
        total: 0,
      };
      body.wheres = [{ field: "id", op: "IN", value: val.split(",") }];
      getMetaCommonList(this.programModel, body).then((res) => {
        const data = res.data.data;
        let arr = [];
        data.records.forEach((e) => {
          if (e.file_url) {
            arr.push(e.file_url);
          }
        });
        if (arr.length > 0) {
          this.getCompanyId(arr);
        }
      });
    },
    submit() {
      return new Promise((resolve) => {
        this.$refs["ruleForm"].validate((valid) => {
          if (valid) {
            resolve({
              valid: true,
              form: this.ruleForm,
            });
          } else {
            let fields = this.$refs["ruleForm"].fields,
              firstError = {
                field: "",
                message: "",
                name: "",
              };
            for (let i = 0; i < fields.length; i++) {
              if (fields[i].validateState === "error") {
                firstError.message = fields[i].validateMessage;
                firstError.field = fields[i].prop;
                firstError.name = fields[i].label;
                break;
              }
            }
            resolve({
              valid: false,
              form: this.ruleForm,
              error: firstError,
            });
          }
        });
      });
    },
    back() {
      this.$router.push({
        path: "/information/publish",
      });
    },
    save() {
      Promise.all([this.submit()]).then((forms) => {
        //照片
        if (this.imgform) {
          let imgList = [];
          this.imgform.imgUrl.forEach((e) => {
            if (e.label) {
              imgList.push(e.label);
            }
          });
          this.ruleForm.attachment_id = imgList.toString();
        }
        if (this.ruleForm.id) {
          let o = {
            update: this.ruleForm,
            primaryKeyValue: this.ruleForm["id"],
            primaryKey: "id",
          };
          submit(this.ruleForm).then((res) => {
            if (res.data.code == 200) {
              this.$router.push({
                path: "/visitor/index",
              });
            }
          });
        } else {
          submit(this.ruleForm).then((res) => {
            if (res.data.code == 200) {
              this.$router.push({
                path: "/visitor/index",
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .el-divider--horizontal {
  margin: 20px 0 !important;
}
/deep/ .el-divider__text.is-left {
  left: 0px;
}
/deep/ .el-divider__text {
  padding: 0px 10px 0px 0px;
}
/deep/ .el-upload--picture-card {
  width: 68px;
  height: 68px;
  line-height: 72px;
  vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
}
.btnClass {
  display: flow-root;
  height: 50px;
  line-height: 50px;
}
/deep/ .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}
/deep/ .el-select {
  width: 100%;
}
</style>

<template>
  <basic-container>
    <basic-page-header @back="goBack"></basic-page-header>
    <div class="display-flex" v-if="entityAttr.name != ''">
      <h3 class="metadata-title">
        {{ entityAttr.name }}{{ "(" + entity.typeName + ")" }}
      </h3>
      <el-button size="mini" icon="el-icon-download" @click="outputFile"
        >导出</el-button
      >
    </div>
    <el-tabs v-loading="showMeta" v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="技术属性" name="property" style="line-height: 20px">
        <!--<h4>技术属性</h4>-->
        <div
          style="position: absolute; right: 10px; z-index: 1"
          v-if="showOperation"
        >
          <button
            @click="editMeta"
            type="text"
            class="el-button el-button--text el-button--small"
            icon="el-icon-edit"
            size="small"
          >
            <i class="el-icon-edit"></i><span>编辑</span>
          </button>
          <button
            @click="deleteMeta"
            type="text"
            class="el-button el-button--text el-button--small"
            icon="el-icon-delete"
            size="small"
          >
            <i class="el-icon-delete"></i><span>删除</span>
          </button>
        </div>
        <div style="height: calc(100vh - 284px); overflow: auto">
          <el-row v-for="(item, index) in dyAttrs" :key="index">
            <el-col :span="4">
              {{ $t("dataAssetsDetail." + item.label) }}
            </el-col>
            <el-col :span="16">
              <span v-if="isTimeField.indexOf(item.label) > -1">
                {{ item.value | formatDate }}
              </span>
              <span v-else>
                {{ item.value }}
              </span>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4"> 标签 </el-col>
            <el-col :span="16" class="property-tags">
              <el-tag
                size="mini"
                v-for="(tag, index) in tags"
                :key="tag.name"
                @close="deleteTag(tag, index)"
                :type="tag.type"
              >
                {{ tag.name }}
              </el-tag>
              <!--<i class="el-icon-edit operation mar-l-10" @click="addTag"></i>-->
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4"> 分类 </el-col>
            <el-col :span="16" class="property-tags">
              <el-tag
                size="mini"
                v-for="(tag, index) in classes"
                :key="tag.name"
                @close="deleteTag(tag, index)"
                :type="tag.type"
              >
                {{ tag.name }}
              </el-tag>
              <!--<i class="el-icon-edit operation mar-l-10" @click="addClasses"></i>-->
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4"> 分级 </el-col>
            <el-col :span="16" class="property-tags">
              <el-tag
                size="mini"
                v-for="(tag, index) in levels"
                :key="tag.name"
                @close="deleteTag(tag, index)"
                :type="tag.type"
              >
                {{ tag.name }}
              </el-tag>
              <!--<i class="el-icon-edit operation mar-l-10" @click="addLevel"></i>-->
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="4"> 敏感 </el-col>
            <el-col :span="16" class="property-tags">
              {{ isSensitived() }}
            </el-col>
          </el-row>
        </div>
      </el-tab-pane>
      <el-tab-pane label="关系" name="relation">
        <el-row
          v-for="(item, index) in relations"
          :key="index"
          style="line-height: 20px"
        >
          <el-col :span="4">
            {{ $t("dataAssetsDetail." + item.label) }}
          </el-col>
          <el-col :span="16">{{ item.value }}</el-col>
        </el-row>
      </el-tab-pane>
      <el-tab-pane v-if="false" label="分类" name="classfication">
        <el-table :data="classifications" style="width: 100%">
          <el-table-column label="分类" prop="typeName"> </el-table-column>
          <el-table-column label="属性">
            <template slot-scope="scope">
              <div class="w-100p" v-if="scope.row.attr.length > 0">
                <div>
                  <div class="col">名称</div>
                  <div class="col">值</div>
                </div>
                <div v-for="(item, index) in scope.row.attr">
                  <div class="col">{{ item.label }}</div>
                  <div class="col">{{ item.value }}</div>
                </div>
              </div>
              <span v-if="scope.row.attr.length === 0"> NA </span>
            </template>
          </el-table-column>
          <!--<el-table-column label="操作">-->
          <!--<template slot-scope="scope">-->
          <!--<el-tooltip :content="$t('Edit')" placement="top" :enterable="false">-->
          <!--<span><el-button type="primary" size="mini" icon="el-icon-edit-outline" @click="handleEdit(scope.row)" circle></el-button></span>-->
          <!--</el-tooltip>-->
          <!--<el-tooltip :content="$t('Delete')" placement="top" :enterable="false">-->
          <!--<el-popconfirm-->
          <!--:confirmButtonText="$t('Confirm')"-->
          <!--:cancelButtonText="$t('Cancel')"-->
          <!--icon="el-icon-info"-->
          <!--iconColor="red"-->
          <!--:metadata-title="$t('Delete?')"-->
          <!--@confirm="handleDelete(scope.row,scope.row.id)"-->
          <!--&gt;-->
          <!--<el-button class="mar-l-10" type="danger" size="mini" icon="el-icon-delete" circle slot="reference"></el-button>-->
          <!--</el-popconfirm>-->
          <!--</el-tooltip>-->
          <!--</template>-->
          <!--</el-table-column>-->
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="审计" name="audit">
        <el-table :data="audits" style="width: 100%">
          <el-table-column prop="user" label="用户" width="180">
          </el-table-column>
          <el-table-column label="创建时间" width="180">
            <template slot-scope="scope">
              {{ transformDate(scope.row.timestamp) }}
            </template>
          </el-table-column>
          <el-table-column prop="action" label="操作"> </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane label="血缘关系" name="graph">
        <div style="height: calc(100vh - 280px)">
          <mGraph :guid="guid" v-if="showGraph"></mGraph>
        </div>
      </el-tab-pane>

      <el-tab-pane label="历史版本" name="version">
        <avue-crud
          ref="version"
          :data="versionList"
          :option="versionOption"
          @refresh-change="getVersions"
        >
        </avue-crud>
      </el-tab-pane>

      <el-tab-pane
        v-if="isTable(entity.typeName) && tableName && databaseId"
        label="数据预览"
        name="dataPreview"
        style="height: calc(100vh - 280px)"
      >
        <el-table v-if="viewColumns.length" :data="resetViewData" height="100%">
          <el-table-column
            v-for="item of viewColumns"
            :prop="item.prop"
            :label="item.label"
            :key="item.prop"
          >
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane
        v-if="showFields && isTable(entity.typeName)"
        label="字段"
        name="field"
      >
        <el-table :data="fields" style="width: 100%">
          <el-table-column label="字段名" width="180">
            <template slot-scope="scope">
              <!-- <span>{{ scope.row.name }}</span> -->
              <a @click="toColumn(scope.row)" class="cur-p">{{
                scope.row.name
              }}</a>
            </template>
          </el-table-column>
          <el-table-column prop="comment" label="描述" width="180">
          </el-table-column>
          <el-table-column label="创建时间">
            <template slot-scope="scope">
              {{ transformDate(scope.row.time) }}
            </template>
          </el-table-column>
          <el-table-column label="是否敏感">
            <template slot-scope="scope">
              {{ scope.row.isSensitived === "1" ? "是" : "否" }}
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>

      <el-tab-pane
        v-if="isTable(entity.typeName)"
        label="关联任务"
        name="tasks"
      >
        <avue-crud
          :option="taskTableOption"
          :table-loading="taskTableLoading"
          :data="taskTableData"
          :page="taskTablePage"
          @on-load="_getProcessListP"
          @refresh-change="_getProcessListP"
        >
          <!-- <template slot="menuLeft" slot-scope="{size}">
          <span>时间:</span>
          <div class="dis-ib mar-l-5">
            <el-date-picker
              size="small"
              value-format="yyyy-MM-dd HH:mm:ss"
              v-model="taskTableSearch.time"
              @change="_getProcessInstance"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始时间"
              end-placeholder="结束时间">
            </el-date-picker>
          </div>
        </template> -->
          <template slot="releaseState" slot-scope="scope">
            {{ _rtPublishStatus(scope.row.releaseState) }}
          </template>
          <template slot="name" slot-scope="scope">
            <el-link
              :underline="false"
              :href="getHref(scope.row)"
              type="primary"
              >{{ scope.row.name }}</el-link
            >
          </template>
        </avue-crud>
      </el-tab-pane>
    </el-tabs>
  </basic-container>
</template>
<script>
import * as _ from "lodash";
import { mapActions } from "vuex";
import { getVersionList } from "@/api/metadata/model";
import { getList } from "@/api/metadata/grade";
import { getLabelTree } from "@/api/metadata/label";
import { listLabeltype } from "@/api/metadata/labeltype";
import { getMetadataDetail, getAudits } from "@/api/metadata/metadata";
import mGraph from "../metadata/graph";
// import { publishStatus } from '@/view-module/scheduler/pages/dag/_source/config'
import { queryDataByDataBaseIdAndTableName } from "@/api/dws/datamodel";

export default {
  name: "assets-info",
  components: { mGraph },
  data() {
    return {
      isTimeField: ["lastAccessTime", "createTime", "retention"],
      tableTypes: ["hive_table", "Table"],
      taskTableSearch: {
        time: [],
      },
      taskTableOption: {
        addBtn: false,
        menu: false,
        column: [
          {
            label: "任务类型",
            prop: "type",
            dicData: [
              {
                label: "采集任务",
                value: "1",
              },
              {
                label: "治理任务",
                value: "9",
              },
              {
                label: "宽表任务",
                value: "8",
              },
            ],
          },
          {
            label: "任务名称",
            prop: "name",
          },
          {
            label: "任务状态",
            prop: "releaseState",
          },
          {
            label: "创建时间",
            prop: "createTime",
            type: "datetime",
            format: "YYYY-MM-DD HH:mm:ss",
          },
          {
            label: "创建人",
            prop: "userName",
          },
        ],
      },
      taskTableLoading: false,
      taskTableData: [],
      taskTablePage: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },

      typeNameByColumn: ["hive_column", "Column"],
      databaseId: "",
      databaseName: "",
      tableName: "",
      guid: "",
      showMeta: false,
      showGraph: false,
      activeName: "property",
      entityAttr: {
        name: "",
        owner: "",
        description: "",
      },
      dyAttrs: [],
      entity: {
        createTime: "",
        updateTime: "",
        typeName: "",
      },
      fields: [],
      tags: [],
      levels: [],
      classes: [],
      classifications: [],
      audits: [],
      relations: [],
      showFields: true,
      tagFormVisible: false,
      classesFormVisible: false,
      levelFormVisible: false,
      tagForm: {
        tag: "",
      },
      tagOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "标签",
            prop: "typeName",
            type: "select",
            dicData: [],
            rules: [
              {
                required: true,
                message: "标签",
                trigger: "change",
              },
            ],
          },
        ],
      },
      levelForm: {},
      levelOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "分级",
            prop: "typeName",
            type: "select",
            dicData: [],
            rules: [
              {
                required: true,
                message: "请选择分级",
                trigger: "change",
              },
            ],
          },
        ],
      },
      classesForm: {},
      classesOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: "分类",
            prop: "typeName",
            type: "select",
            dicData: [],
            rules: [
              {
                required: true,
                message: "请选择分类",
                trigger: "change",
              },
            ],
          },
        ],
      },
      versionList: [],
      versionOption: {
        header: false,
        menu: false,
        column: [
          {
            label: "版本",
            prop: "version",
            display: false,
          },
          {
            label: "发布时间",
            prop: "createTime",
            display: false,
          },
          {
            label: "发布人",
            prop: "publisher",
            display: false,
          },
        ],
      },
      resetViewData: [],
      viewColumns: [],
      grades: [],
      labels: [],
      metaclasses: [],
      columnNameLists: [],
      resetViewDataArr: [],
    };
  },
  props: {
    propGuid: String,
    propTableName: String,
    propDatabaseId: [String, Number],
    propDatabaseName: String,
    isRoute: {
      type: Boolean,
      default: true,
    },
    showOperation: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    guid() {
      this.getMetadataDetail(this.guid);
      this.getMetaAudits(this.guid);
    },
  },
  mounted() {
    if (this.isRoute) {
      this.databaseId = this.$router.currentRoute.query.databaseId || "";
      this.tableName = this.$router.currentRoute.query.tableName || "";
      this.guid = this.$router.currentRoute.params.guid || "";
      this.databaseName = this.$router.currentRoute.query.databaseName || "";
    } else {
      this.databaseId = this.propDatabaseId;
      this.tableName = this.propTableName;
      this.guid = this.propGuid;
      this.databaseName = this.propDatabaseName;
    }
    this.getMetadataDetail(this.guid);
    this.getMetaAudits(this.guid);
    this.$nextTick(() => {
      setTimeout(() => {
        if (
          this.isTable(this.entity.typeName) &&
          this.tableName &&
          this.databaseId
        ) {
          this.getResetViewData(this.guid);
        }
      }, 1000);
    });
  },
  methods: {
    // 导出
    outputFile() {
      // 技术属性
      let dyAttrs = [];
      let dyobj = {};
      this.dyAttrs.map((item) => {
        return (dyobj[this.$t("dataAssetsDetail." + item["label"])] =
          this.isTimeField.indexOf(item["label"]) > -1
            ? this.transformDate(item["value"])
            : typeof item["value"] == "object"
            ? JSON.stringify(item["value"])
            : item["value"]);
      });
      dyobj["标签"] = this.tags
        .map((i) => {
          return i.name;
        })
        .toString();
      dyobj["分类"] = this.classes
        .map((i) => {
          return i.name;
        })
        .toString();
      dyobj["分级"] = this.levels
        .map((i) => {
          return i.name;
        })
        .toString();
      dyobj["敏感"] = this.isSensitived();
      dyAttrs.push(dyobj);
      // 关系
      console.log("this.relations", this.relations);
      let relations = [];
      let relationsobj = {};
      this.relations.map((item) => {
        return (relationsobj[this.$t("dataAssetsDetail." + item["label"])] =
          item["value"]);
      });
      relations.push(relationsobj);
      // 审计
      let audits = [];
      if (this.audits.length == 0) {
        audits = [
          {
            用户: "",
            创建时间: "",
            操作: "",
          },
        ];
      } else {
        audits = this.audits.map((v, i) => {
          return {
            用户: v.user,
            创建时间: this.transformDate(v.timestamp),
            操作: v.action,
          };
        });
      }
      // 历史版本
      let versionList = [];
      if (this.versionList.length == 0) {
        versionList = [
          {
            版本: "",
            发布时间: "",
            发布人: "",
          },
        ];
      } else {
        versionList = this.versionList.map((v, i) => {
          return {
            版本: v.version,
            发布时间: v.createTime,
            发布人: v.publisher,
          };
        });
      }
      // 数据预览
      let resetViewData = [];
      this.resetViewDataArr.forEach((i) => {
        let obj = {};
        this.columnNameLists.map((w) => {
          let objKey = w;
          obj[objKey] = i[objKey] == null ? "" : i[objKey] + "";
        });
        resetViewData.push(obj);
      });
      // return
      // 字段
      let fields = [];
      if (this.showFields && this.isTable(this.entity.typeName)) {
        if (this.fields.length == 0) {
          fields = [
            {
              字段名: "",
              描述: "",
              创建时间: "",
            },
          ];
        } else {
          fields = this.fields.map((v) => {
            return {
              字段名: v.name,
              描述: v.comment == null ? "" : v.comment,
              创建时间: this.transformDate(v.time),
            };
          });
        }
      }
      // return
      // 关联任务
      let taskTableData = [];
      if (this.taskTableData.length == 0) {
        taskTableData = [
          {
            任务类型: "",
            任务名称: "",
            任务状态: "",
            创建时间: "",
            创建人: "",
          },
        ];
      } else {
        this.taskTableData.map((v) => {
          let obj = {};
          let type = "";
          if (v.type == "1") {
            obj["任务类型"] = "采集任务";
          } else if (v.type == "9") {
            obj["任务类型"] = "治理任务";
          } else if (v.type == "8") {
            obj["任务类型"] = "宽表任务";
          }
          obj["任务名称"] = v.name + "";
          obj["任务状态"] = v.releaseState;
          obj["创建时间"] = v.createTime;
          obj["创建人"] = v.userName;
          taskTableData.push(obj);
        });
      }

      const dyAttrsSheet = XLSX.utils.json_to_sheet(dyAttrs);
      const relationsSheet = XLSX.utils.json_to_sheet(relations);
      const auditsSheet = XLSX.utils.json_to_sheet(audits);
      const versionListSheet = XLSX.utils.json_to_sheet(versionList);
      const resetViewDataSheet = XLSX.utils.json_to_sheet(resetViewData);
      const fieldsSheet = XLSX.utils.json_to_sheet(fields);
      const taskTableDataSheet = XLSX.utils.json_to_sheet(taskTableData);

      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, dyAttrsSheet, "技术属性");
      XLSX.utils.book_append_sheet(wb, relationsSheet, "关系");
      XLSX.utils.book_append_sheet(wb, auditsSheet, "审计");
      XLSX.utils.book_append_sheet(wb, versionListSheet, "历史版本");
      if (
        this.isTable(this.entity.typeName) &&
        this.tableName &&
        this.databaseId
      ) {
        XLSX.utils.book_append_sheet(wb, resetViewDataSheet, "数据预览");
      }
      if (this.showFields && this.isTable(this.entity.typeName)) {
        XLSX.utils.book_append_sheet(wb, fieldsSheet, "字段");
      }
      if (this.isTable(this.entity.typeName)) {
        XLSX.utils.book_append_sheet(wb, taskTableDataSheet, "关联任务");
      }

      var wopts = {
        // 要生成的文件类型
        bookType: "xlsx",
        // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        bookSST: false,
        type: "binary",
      };
      function s2ab(s) {
        let buf = new ArrayBuffer(s.length);
        let view = new Uint8Array(buf);
        for (let i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      var wbout = XLSX.write(wb, wopts);
      var blob = new Blob([s2ab(wbout)], { type: "" });
      saveAs(
        blob,
        `${this.entityAttr.name}(${this.entity.typeName})元数据.xlsx`
      );
    },
    workbook2blob(workbook) {
      // 生成excel的配置项
      var wopts = {
        // 要生成的文件类型
        bookType: "xlsx",
        // 是否生成Shared String Table，官方解释是，如果开启生成速度会下降，但在低版本IOS设备上有更好的兼容性
        bookSST: false,
        type: "binary",
      };
      var wbout = XLSX.write(workbook, wopts);
      // 将字符串转ArrayBuffer
      function s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      }
      let buf = s2ab(wbout);
      var blob = new Blob([buf], {
        type: "application/octet-stream",
      });
      return blob;
    },

    ...mapActions("dag", ["getProcessListP"]),
    isTable(type) {
      return this.tableTypes.includes(type);
    },
    isSensitived() {
      let isSensitivedState = {
        1: "是",
        0: "否",
      };
      if (this.entity && this.entity.customAttributes) {
        if (this.entity.customAttributes.isSensitived === undefined) {
          return "";
        } else {
          return (
            isSensitivedState[this.entity.customAttributes.isSensitived] || ""
          );
        }
      } else {
        return "";
      }
    },
    getHref(item) {
      return `/#/projects/instance/list?scene=${item.type}&searchVal=${item.name}&back=true`;
    },
    _rtPublishStatus(code) {
      // return _.filter(publishStatus, (v) => v.code === code)[0].desc;
    },
    _getProcessListP() {
      // console.log(this.taskTableSearch)
      // let startDate = null , endDate = null;
      // if(this.taskTableSearch.time && this.taskTableSearch.time.length === 2){
      //   startDate = this.taskTableSearch.time[0];
      //   endDate = this.taskTableSearch.time[1];
      // }
      this.taskTableLoading = true;
      this.getProcessListP({
        searchVal: "",
        pageSize: this.taskTablePage.pageSize,
        pageNo: this.taskTablePage.currentPage,
        dName: this.databaseName,
        tName: this.tableName,
        // startDate: startDate,
        // endDate: endDate
      })
        .then((res) => {
          this.taskTableLoading = false;
          this.taskTableData = res.totalList;
          this.taskTablePage.total = res.total;
        })
        .catch((e) => {
          this.taskTableLoading = false;
        });
    },
    goBack() {
      if (this.isRoute) {
        this.$router.back();
      } else {
        this.$emit("back");
      }
    },
    editMeta() {
      this.$emit("edit-meta", this.entity);
    },
    deleteMeta() {
      this.$emit("delete-meta", this.entity);
    },
    getGradeList() {
      getList().then((res) => {
        if (res["data"]["code"] === 200) {
          this.grades = res["data"]["data"]["records"];
          if (!!this.entity.customAttributes) {
            let gradeId = this.entity.customAttributes.grade;
            this.levels = this.grades.filter((item) => {
              return item.id === gradeId;
            });
          }
        }
      });
    },
    getLabelList() {
      getLabelTree().then((res) => {
        this.labels = [];
        this.translateTreeData(res["data"]["children"] || []);
        let labels = this.entity["labels"];
        let temp = [];
        for (let i = 0, len = this.labels.length; i < len; i++) {
          let id = this.labels[i]["id"];
          if (labels.indexOf(id) > -1) {
            temp.push(this.labels[i]);
          }
        }
        this.tags = temp;
      });
    },
    translateTreeData(treeData) {
      let newTreeData = [];
      treeData.forEach((item) => {
        let el = {
          ...item.label,
        };
        this.labels.push(el);
        if (item.children && item.children.length) {
          el["children"] = this.translateTreeData(item["children"]);
        }
        newTreeData.push(el);
      });
      return newTreeData;
    },
    getClassfication() {
      listLabeltype().then((res) => {
        if (res["data"]["code"] === 200) {
          this.metaclasses = res["data"]["data"]["records"];
          if (!!this.entity.customAttributes) {
            let classesId = this.entity.customAttributes.classification;
            this.classes = this.metaclasses.filter((item) => {
              return item.id === classesId;
            });
          }
        }
      });
    },
    transformDate(time) {
      if (!time) return "-";
      let date = new Date(time);
      let year = date.getFullYear();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let day = date.getDay() < 10 ? "0" + date.getDay() : date.getDay();
      let hours =
        date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
      let minutes =
        date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
      let seconds =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    },
    getMetadataDetail(id) {
      this.showMeta = true;
      getMetadataDetail(id).then((res) => {
        if (res["status"] === 200 && res["data"] != "") {
          this.entity = res["data"]["entity"];
          this.entityAttr = res["data"]["entity"]["attributes"];
          this.classifications = res["data"]["entity"]["classifications"] || [];
          this.classifications.forEach((item) => {
            item["attr"] = [];
            if (item["attributes"]) {
              let labels = Object.keys(item["attributes"]);
              labels.forEach((label) => {
                item["attr"].push({
                  label: label,
                  value: item["attributes"][label] || "-",
                });
              });
            }
          });
          let attrs = Object.keys(this.entityAttr);
          let tempAttrs = [];
          for (let i = 0, len = attrs.length; i < len; i++) {
            if (!!this.entityAttr[attrs[i]]) {
              tempAttrs.push({
                label: attrs[i],
                value: this.entityAttr[attrs[i]],
              });
            }
          }
          this.dyAttrs = tempAttrs.concat([]);
          let relation = res["data"]["entity"]["relationshipAttributes"];
          let relationAttr = Object.keys(relation);
          let temp = [];
          relationAttr.forEach((item) => {
            if (relation[item]) {
              if (
                Object.prototype.toString.call(relation[item]) ===
                "[object Array]"
              ) {
                let column = [];
                relation[item].forEach((et) => {
                  column.push(et["displayText"]);
                });
                if (column.length > 0) {
                  temp.push({
                    label: item,
                    value: column.join(","),
                  });
                }
              } else {
                temp.push({
                  label: item,
                  value: relation[item]["displayText"],
                });
              }
            }
          });
          this.relations = temp.concat([]);
          let referredEntities = res["data"]["referredEntities"];
          let referKeys = Object.keys(referredEntities);
          let typeNameByColumn = this.typeNameByColumn;
          let tempFields = [];
          referKeys.forEach((item) => {
            if (
              typeNameByColumn.includes(referredEntities[item]["typeName"]) &&
              referredEntities[item]["status"] !== "DELETED"
            ) {
              tempFields.push({
                guid: referredEntities[item]["guid"],
                name: referredEntities[item]["attributes"]["name"],
                comment: referredEntities[item]["attributes"]["comment"],
                time: referredEntities[item]["createTime"],
                isSensitived:
                  referredEntities[item]["customAttributes"] &&
                  referredEntities[item]["customAttributes"]["isSensitived"] !==
                    undefined
                    ? referredEntities[item]["customAttributes"]["isSensitived"]
                    : "0",
              });
            }
          });
          this.fields = tempFields;
          this.getGradeList();
          this.getClassfication();
          this.getLabelList();
        }
        this.showMeta = false;
      });
    },
    getMetaAudits(id) {
      getAudits(id).then((res) => {
        if (res["status"] === 200) {
          this.audits = res["data"];
        }
      });
    },
    handleClick(tab) {
      if (tab["name"] === "graph") {
        this.showGraph = true;
      }
      if (tab["name"] === "dataPreview") {
        this.getResetViewData(this.guid);
      }
    },
    addTag() {
      this.tagFormVisible = true;
    },
    confirmAddTag() {
      this.tagFormVisible = false;
      this.tagForm.tag = "";
    },
    tagCancel() {
      this.tagFormVisible = false;
      this.tagForm.tag = "";
    },
    deleteTag(tag, index) {
      this.tags.splice(index, 1);
    },
    toColumn(item) {
      this.showFields = false;
      let guid = item["guid"];
      this.$router.push(`/data-assets/assets-detail/${guid}`);
      // this.getMetadataDetail(guid);
      // this.getMetaAudits(guid);
      // this.activeName = "property";
    },
    getVersions(id) {
      console.log(id, "id");
      let obj = {
        metadataId: id,
      };
      getVersionList(obj).then((res) => {
        if (res["data"]["code"] === 200) {
          this.versionList = res["data"]["data"]["records"];
        }
      });
    },
    //获取数据预览的信息
    getResetViewData(id) {
      queryDataByDataBaseIdAndTableName({
        databaseId: this.databaseId,
        tableName: this.tableName,
        rowSize: 10,
      }).then((res) => {
        this.resetViewDatas(
          res.data["columnNameList"] || [],
          res.data["data"] || []
        );
      });
    },
    resetViewDatas(columnNameList, data) {
      let columnNameListTemp = [],
        dataTemp = [];
      this.columnNameLists = columnNameList;
      this.resetViewDataArr = data;
      columnNameList.forEach((item) => {
        let itemArr = item.split(".");
        columnNameListTemp.push({
          label: itemArr.join("."),
          prop: itemArr.join(""),
        });
      });
      this.viewColumns = columnNameListTemp;
      data.forEach((item) => {
        let itemTemp = {};
        Object.keys(item).forEach((name) => {
          itemTemp[name.split(".").join("")] = item[name];
        });
        dataTemp.push(itemTemp);
      });
      this.resetViewData = dataTemp;
    },
    addClasses() {
      this.classesFormVisible = true;
    },
    addLevel() {
      this.levelFormVisible = true;
    },
  },
};
</script>
<style scoped>
.property-tags .el-tag + .el-tag {
  margin-left: 10px;
}
.lctdiv {
  border: 1px solid #666;
  height: 40px;
  border-radius: 4px;
  line-height: 38px;
  text-align: center;
  color: #666;
  width: 120px;
}
.link {
  position: relative;
  display: block;
  padding: 1em 3em 1em 1em;
  font-size: 10px;
  /* font-weight: bold; */
  text-decoration: none;
  color: #000;
  margin-top: 10px;
}
.link:after,
.link:before {
  content: "";
  display: block;
  position: absolute;
  -webkit-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}
.link:before {
  top: 50%;
  right: 1em;
  width: 1em;
  height: 1em;
  -webkit-transform-origin: 50% 50%;
  transform-origin: 50% 50%;
  -webkit-transform: translate(0, -0.5em) rotate(45deg);
  transform: translate(0, -0.5em) rotate(45deg);
  -webkit-box-shadow: inset -0.1em 0.1em 0 0 #666;
  box-shadow: inset -0.1em 0.1em 0 0 #666;
  border-radius: 0 0.15em 0 0;
}
.link:after {
  top: 50%;
  right: 1em;
  width: 80px;
  height: 0.1em;
  -webkit-transform: translate(0, -0.1em);
  transform: translate(0, -0.1em);
  background-color: #666;
}
.link:hover:before {
  -webkit-transform: translate(0.5em, -0.5em) rotate(45deg);
  transform: translate(0.5em, -0.5em) rotate(45deg);
}
.link:hover:after {
  -webkit-transform: translate(0.5em, -0.1em);
  transform: translate(0.5em, -0.1em);
}
.lctx {
  width: 100px;
}
.lctActive {
  color: #1976d3;
  font-weight: bold;
  animation: scaleLct 2s infinite;
}
@keyframes scaleLct {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.metadata-title {
  margin-right: 10px !important;
}
</style>

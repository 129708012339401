<template>
    <div>
        <basic-container class="cards">
            <el-form :inline="true" :model="formInline" size="small" class="demo-form-inline">
                <el-form-item label="所属区域" prop="name">
                    <el-select v-model="formInline.sub_part_id" size="small" filterable clearable @change="getBuildList" placeholder="请选择区域">
                        <template v-for="(item, index) in partList">
                            <el-option v-if="item.parent_id != 0" :key="index" :label="item.name" :value="item.id">
                            </el-option>
                        </template>
                    </el-select>
                </el-form-item>
                <el-form-item label="楼宇">
                    <el-select class="w-190" filterable v-model="formInline.build_id" clearable size="small" @change="getCompanyList" placeholder="请选择所属楼宇">
                        <el-option v-for="(item, index) in buildList" :key="index" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="企业">
                    <el-select class="w-190" v-model="formInline.company_id" clearable filterable size="small" @change="getContractList" placeholder="请选择企业" @clear="clearCom">
                        <el-option v-for="(item, index) in companyList" :key="index" :label="item.name" :value="item.company_id">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="合同">
                    <el-select class="w-210" v-model="formInline.contract_code" clearable filterable size="small" @change.native="selectBlur" @blur.native="selectBlur" placeholder="请选择合同">
                        <el-option v-for="(item, index) in contractList" :key="index" :label="item.contract_code" :value="item.contract_code">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="getMetaCommonList">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button class="pull-right" type="primary" plain @click="exportTable" icon="el-icon-download" size="small">导出</el-button>
                </el-form-item>
            </el-form>
        </basic-container>
        <basic-container isCard id="excelTable">
            <el-table :data="tableData" :height="height" style="width: 100%" border>
                <el-table-column :label="`${partName}房屋租赁费用收取明细表`" header-align="center" class="bg-white">
                    <el-table-column class="bg-white" fixed>
                        <template slot="header" slot-scope="{ row }">
                            <el-descriptions border :colon="false" :labelStyle="labelStyle">
                                <el-descriptions-item label="企业名称" :span="2">{{ tableForm.renters_name }}
                                </el-descriptions-item>
                                <el-descriptions-item label="合同编号" :span="1">{{ tableForm.code }}
                                </el-descriptions-item>

                                <el-descriptions-item label="承租位置" :span="1">{{
                  tableForm.room_names || "-"
                }}</el-descriptions-item>

                                <el-descriptions-item label="付款方式" :span="1">{{
                  getPaymentTypeByList(tableForm.payment_type)
                }}</el-descriptions-item>
                                <el-descriptions-item label="租赁期限" :span="1">{{ formatDate(tableForm.start_date) }}至
                                    {{ formatDate(tableForm.end_date) }}</el-descriptions-item>
                                <el-descriptions-item label="承租面积(m²)" :span="1">{{
                  tableForm.rent_area
                }}</el-descriptions-item>
                                <el-descriptions-item label="租赁单价(元/m²)" :span="1">{{
                  tableForm.rental_unit_price_day
                }}</el-descriptions-item>

                                <el-descriptions-item label="租赁费总额(元)" :span="1">{{
                  toqfw(tableForm["amount"])
                }}</el-descriptions-item>
                            </el-descriptions>
                        </template>

                        <el-table-column type="index" fixed header-align="center" width="50">
                        </el-table-column>
                        <el-table-column label="应收款日期" align="center" fixed prop="receivable_date" header-align="center" width="220">
                        </el-table-column>
                        <!-- <el-table-column label="主合同编号" align="center" prop="contract_code" header-align="center" fixed width="220">
                        </el-table-column>
                        <el-table-column label="补充协议编号" align="center" fixed prop="supplementary_code" header-align="center" width="220">
                        </el-table-column> -->
                        <!-- <el-descriptions-item label="应收款日期" :span="1">{{ tableForm.receivable_date }} -->
                        <!-- </el-descriptions-item> -->
                        <el-table-column prop="period_start_time" align="center" label="费用期限" fixed width="200">
                            <template slot-scope="{ row }">
                                <span v-if="row.receivable_date">
                                    {{ formatDate(row.period_start_time) }}
                                    <span v-if="row.period_end_time"> 至
                                        {{ formatDate(row.period_end_time) }}</span>
                                </span>
                                <span v-else class="ft500">共计</span>
                            </template>
                        </el-table-column>

                        <el-table-column label="应交租金" value1 header-align="center" align="center" width="180">
                            <el-table-column prop="zj_amount_receivable" label="房屋租金(元)" header-align="center" align="right" width="120">
                                <template slot-scope="{ row }">
                                    {{ toqfw(row.zj_amount_receivable) }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="fw_amount_receivable" label="综合服务费(元)" header-align="center" align="right" width="120">
                                <template slot-scope="{ row }">
                                    {{ toqfw(row.fw_amount_receivable) }}
                                </template>
                            </el-table-column>
                            <el-table-column header-align="center" align="right" prop="amount_receivable" label="合计(元)" width="120">
                                <template slot-scope="{ row }">
                                    {{ toqfw(row.amount_receivable) }}
                                </template>
                            </el-table-column>
                        </el-table-column>

                        <el-table-column label="实收租金" value1 align="center" width="180">
                            <el-table-column prop="zj_amount_received" label="房屋租金(元)" header-align="center" align="right" width="120">
                                <template slot-scope="{ row }">
                                    {{ toqfw(row.zj_amount_received) }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="fw_amount_received" label="综合服务费(元)" header-align="center" align="right" width="120">
                                <template slot-scope="{ row }">
                                    {{ toqfw(row.fw_amount_received) }}
                                </template>
                            </el-table-column>
                            <el-table-column prop="amount_received" header-align="center" align="right" label="合计(元)" width="120">
                                <template slot-scope="{ row }">
                                    {{ toqfw(row.amount_received) }}
                                </template>
                            </el-table-column>
                        </el-table-column>

                        <el-table-column label="欠交租赁费" value1 header-align="center" width="180">
                            <el-table-column header-align="center" align="right" prop="amount_owed" label="本期(元)" width="120">
                                <template slot-scope="{ row }">
                                    {{ toqfw(row.amount_owed) }}
                                </template>
                            </el-table-column>

                            <el-table-column header-align="center" align="right" prop="qy_lj_qf" label="累计(元)" width="120">
                                <template slot-scope="{ row }"> {{ toqfw(row.qy_lj_qf) }} </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column align="center" prop="received_time" width="150" label="实际收款日期">
                            <template slot-scope="{ row }">
                                {{ formatDate(row.received_time) }}
                            </template>
                        </el-table-column>
                        <el-table-column align="center" prop="enjoyploi" width="150" label="备注">
                        </el-table-column>
                    </el-table-column>
                </el-table-column>
            </el-table>
        </basic-container>
    </div>
</template>

<script>
import { getMetaCommonList } from '@/api/busiMode/metaCommon';
import XLSXS from 'xlsx-style';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import Vue from 'vue';
import * as dictbizService from '@/api/system/dictbiz';

const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
export default {
    data() {
        return {
            labelStyle: {
                color: '#999',
                fontSize: '12px',
            },
            height: viewPortHeight - 240,
            formInline: {},
            value1: '2021',
            tableForm: {
                renters_name: '-',
                contract_code: '-',
                room_name: '-',
                payment_type: '-',
                period_start_date: '-',
                period_end_date: '-',
                room_area: '-',
                rental_unit_price_day: '-',
                amount: '-',
            },
            tableData: [],

            token: '',
            detail_count: 'wisdom_park.v_rpt_contract_receipt_stat_sum',
            expense_detail: 'wisdom_park.v_rpt_contract_receipt_detail',
            contract_detail: 'wisdom_park.v_rpt_contract_subject',
            partName: '',
            buildList: [],
            companyList: [],
            partList: [],
            contractList: [],
            paymentTypeList: [],
        };
    },
    watch: {
        value1: {
            handler: function (newValue, oldName) {
                if (newValue) {
                    this.value1 = dayjs(newValue).format('YYYY');
                }
            },
            immediate: true,
            deep: true,
        },
    },
    mounted() {
        this.getPart();
        this.getDictList();
    },

    methods: {
        getDictList() {
            dictbizService
                .getDictionary({
                    code: 'paymentType',
                })
                .then(res => {
                    this.paymentTypeList = (res.data.data || []).map(item => {
                        return {
                            name: item['dictValue'],
                            value: item['dictKey'],
                        };
                    });
                });
        },
        getPaymentTypeByList(type) {
            let name = '';
            console.log(type);
            this.paymentTypeList.forEach(e => {
                if (e.value == type) {
                    name = e.name;
                }
            });

            return name;
        },
        selectBlur(e) {
            console.log(e);
            Vue.set(this.formInline, 'contract_code', e.target.value);
        },
        toqfw(num) {
            if (Number(num)) {
                let n = num;
                n = this.addDou(Number(num).toFixed(2));
                return n;
            } else {
                return num;
            }
        },
        addDou(value) {
            var isNegative = value < 0;
            value = Math.abs(value).toString();
            var decimalIndex = value.indexOf('.');
            if (decimalIndex === -1) {
                decimalIndex = value.length;
            }
            for (var i = decimalIndex - 3; i > 0; i -= 3) {
                value = value.slice(0, i) + ',' + value.slice(i);
            }
            if (isNegative) {
                value = '-' + value;
            }
            return value;
        },
        clearCom() {
            delete this.formInline.company_id;
            delete this.formInline.contract_code;
            this.contractList = [];
            this.$forceUpdate();
        },
        getMetaCommonList() {
            // console.log(this.formInline.company_id);
            // if (!this.formInline.company_id) {
            //   this.$message({
            //     type: "error",
            //     message: "请选企业",
            //   });
            //   return false;
            // }
            if (!this.formInline.contract_code) {
                this.$message({
                    type: 'error',
                    message: '请选合同',
                });
                return false;
            }
            let obj = [];
            for (let key in this.formInline) {
                if (key == 'contract_code') {
                    if (key == 'contract_code') {
                        obj.push({ field: key, op: 'LIKE', value: this.formInline[key] });
                    } else {
                        obj.push({ field: key, value: this.formInline[key] });
                    }
                }
            }
            let body = {
                current: 1,
                size: 10000,
                wheres: obj,
                orders: [{ field: 'receivable_date', sort: 'ASC' }],
            };

            getMetaCommonList(this.expense_detail, body).then(res => {
                const data = res.data.data;
                if (data.records.length > 0) {
                    let objcon = {
                        current: 1,
                        size: 10000,
                        wheres: [{ field: 'id', value: data.records[0].contract_id }],
                    };
                    getMetaCommonList(this.contract_detail, objcon).then(r => {
                        this.tableForm = JSON.parse(JSON.stringify(r.data.data.records[0]));
                    });
                    let b = body;
                    delete b.orders;
                    this.tableData = data.records;
                    getMetaCommonList(this.detail_count, body).then(res => {
                        const data1 = res.data.data;
                        let o = {
                            receivable_date: '',
                            period_start_time: '共计',
                            amount_receivable: data1.records[0]['amount_receivable'],
                            amount_received: data1.records[0]['amount_received'],
                            // amount_owed: data1.records[0]['amount owed'],
                        };
                        this.tableData = this.tableData.concat([o]);
                    });
                } else {
                    this.tableData = [];
                    this.tableForm = {
                        renters_name: '-',
                        contract_code: '-',
                        room_name: '-',
                        payment_type: '-',
                        period_start_date: '-',
                        period_end_date: '-',
                        room_area: '-',
                        rental_unit_price_day: '-',
                        amount: '-',
                    };
                }
            });
        },
        getPart() {
            let body = {
                current: 1,
                size: 100,
                orders: [{ field: 'sort', sort: 'ASC' }],
            };
            getMetaCommonList('wisdom_park.wp_part', body).then(res => {
                const data = res.data.data;
                this.partList = data.records;
                //this.formInline.sub_part_id = this.partList[0]["id"];
            });
        },
        getBuildList(e) {
            this.partList.forEach(e => {
                if (e.id == this.formInline.sub_part_id) {
                    this.partName = e.name;
                }
            });
            getMetaCommonList('wisdom_park.wp_building', {
                size: 100,
                current: 1,
                wheres: [{ field: 'sub_part_id', value: this.formInline.sub_part_id }],
            }).then(res => {
                const data = res.data.data;
                this.buildList = data.records;
                this.formInline.build_id = '';
                this.formInline.company_id = '';
                delete this.formInline.contract_code;
                this.$forceUpdate();
            });
        },
        formatDate(createTime) {
            let obj = '';
            if (createTime) {
                if (createTime) {
                    obj = dayjs(createTime).format('YYYY-MM-DD');
                }
            }
            return obj;
        },
        getCompanyList() {
            getMetaCommonList('wisdom_park.v_building_company_releation', {
                size: 100,
                current: 1,
                wheres: [{ field: 'building_id', value: this.formInline.build_id }],
            }).then(res => {
                const data = res.data.data;
                this.companyList = data.records;
                this.formInline.company_id = '';
                delete this.formInline.contract_code;
                this.$forceUpdate();
            });
        },
        getContractList() {
            if (this.formInline.company_id) {
                getMetaCommonList('wisdom_park.v_company_contract_releation', {
                    size: 100,
                    current: 1,
                    wheres: [{ field: 'company_id', value: this.formInline.company_id }],
                }).then(res => {
                    const data = res.data.data;
                    this.contractList = data.records;
                    delete this.formInline.contract_code;
                    this.$forceUpdate;
                });
            }
        },
        flitterData4() {
            let spanOneArr = [];
            let concatOne = 0;

            this.tableData.forEach((item, index) => {
                //循环后端查询出来的数据(orderdata)
                if (index === 0) {
                    spanOneArr.push(1);
                } else {
                    if (item.qymc === this.tableData[index - 1].qymc) {
                        //第一列需合并相同内容的字段
                        spanOneArr[concatOne] += 1;
                        spanOneArr.push(0);
                    } else {
                        spanOneArr.push(1);
                        concatOne = index;
                    }
                }
            });
            return {
                one: spanOneArr,
            };
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (columnIndex === 1) {
                // this.tableData 修改
                const _row = this.flitterData4(this.tableData).one[rowIndex];
                const _col = _row > 0 ? 1 : 0;
                return {
                    rowspan: _row,
                    colspan: _col,
                };
            }
        },
        // 点击事件触发的方法
        exportTable() {
            // 首先加载  <table id='excelTable'> 标签 为 workbook对象
            let workbook = this.$XLSX.utils.table_to_book(document.getElementById('excelTable'), { raw: true });
            // 先定义列宽 ， 我这里文件一共有7 列 ，所以设置7列宽度相等都为 20 ，如果你有很多列建议直接 map()
            let wscols = [
                { wch: 5 },
                { wch: 20 },
                { wch: 20 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
                { wch: 15 },
            ];

            // 获取 需要设置样式的 sheet ，我这里只有 一个 sheet 所以索引默认加载了第一个
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            // 设置列宽
            sheet['!cols'] = wscols;
            console.log(sheet);
            // 定义框线样式
            const borderAll = {
                color: { auto: 1 },
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            };

            // 这里的意思为 先默认代表表格的 7 个列  的 列号
            // 比如 A2 意思是 第一列 第2行
            const _letterList = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
            // 定义一个 箭头函数，接受三个参数，分别为 当前Sheet , 行列号（例如：‘A2’）, 是否是新增样式
            const _mapCellStyle = (_sheet, _key, _type) => {
                const _cellStyle = {
                    border: borderAll,
                    alignment: {
                        wrapText: true,
                        horizontal: 'center',
                        vertical: 'center',
                    },
                    font: {
                        name: '微软雅黑',
                        sz: 10,
                        height: 100,
                    },
                    height: 150,
                    bold: true,
                    numFmt: '0',
                };
                const _title = {
                    // border: borderAll,
                    alignment: {
                        wrapText: true,
                        horizontal: 'center',
                        vertical: 'center',
                    },
                    font: {
                        name: '微软雅黑',
                        sz: 16,
                    },
                    bold: false,
                    numFmt: '0',
                };
                let fiflterArr = [
                    `A1`,
                    `B1`,
                    `C1`,
                    `D1`,
                    `E1`,
                    `F1`,
                    `G1`,
                    `A2`,
                    `B2`,
                    `C2`,
                    `D2`,
                    `E2`,
                    `F2`,
                    `G2`,
                    `A3`,
                    `B3`,
                    `C3`,
                    `D3`,
                    `E3`,
                    `F3`,
                    `G3`,
                    `A4`,
                    `B4`,
                    `C4`,
                    `D4`,
                    `E4`,
                    `F4`,
                    `G4`,
                    `A5`,
                    `B5`,
                    `C5`,
                    `D5`,
                    `E5`,
                    `F5`,
                    `G5`,
                ];
                if (fiflterArr.indexOf(_key) > -1) {
                    if (_key == 'A1') {
                        if (_type === 'append') {
                            // 需要新增样式，说明当前加载的sheet中并不存在该单元格，可能当前单元格被前边合并，这种被合并的单元格也得设置样式，
                            // 否则就会出现 合并单元格只有第一格带框线，后边没框线的情况出现，所以这里需要将后边的边框样式也加上。
                            _sheet[_key] = {
                                s: _title,
                            };
                        } else {
                            // 若不是新增样式 则代表sheet中已存在该表格直接修改其 s 属性即可使属性生效
                            if (typeof _sheet[_key] === 'object') {
                                _sheet[_key].s = _title;
                            }
                        }
                    }
                } else {
                    if (_type === 'append') {
                        // 需要新增样式，说明当前加载的sheet中并不存在该单元格，可能当前单元格被前边合并，这种被合并的单元格也得设置样式，
                        // 否则就会出现 合并单元格只有第一格带框线，后边没框线的情况出现，所以这里需要将后边的边框样式也加上。
                        _sheet[_key] = {
                            s: _cellStyle,
                        };
                    } else {
                        // 若不是新增样式 则代表sheet中已存在该表格直接修改其 s 属性即可使属性生效
                        if (typeof _sheet[_key] === 'object') {
                            _sheet[_key].s = _cellStyle;
                        }
                    }
                }
            };

            // sheet 不懂得可以单独打印一下，它其实就是一个对象，键代表行列号（‘A2’），值为该单元格的值，样式等，
            // 我们需要做的就是修改其值中的样式
            Object.keys(sheet).forEach((i, _) => {
                // 无用属性直接过滤

                if (i !== '!ref' || i !== '!cols' || i !== '!merges' || i !== 'Am') {
                    // 首先设置遍历到的 当前 key
                    let _nowKey = i;
                    // 然后调用 _mapCellStyle  渲染当前单元格样式

                    _mapCellStyle(sheet, _nowKey);
                    // 我们这里先获取下一个行列号  例如当前_nowKey 是 A1  这里就是获取到 B1 ,及 当前行的 下一列数据
                    let _nextKey = _letterList[_letterList.indexOf(_nowKey[0]) + 1] + i.slice(1);

                    // 判断 B1 是否在 Sheet的key中,如果不存在，只可能是因为B1所在单元格已经被A1所合并，所以我们需要将B1也调用一下  _mapCellStyle
                    // 渲染 B1 的样式，不然就会出现 A1 B1 这两格是合并的状态，只有A1有框线，而B1 没有框线的情况。
                    // 这里用 while 就是 要把需要合并的单元格全部 渲染上样式，直到可能 B4 在 Sheet中 停止
                    while (_nowKey[0] !== 'G' && !Object.keys(sheet).includes(_nextKey)) {
                        _mapCellStyle(sheet, _nextKey, 'append');
                        // 这里就是简单的交换索引
                        _nowKey = _nextKey;
                        _nextKey = _letterList[_letterList.indexOf(_nowKey[0]) + 1] + _nowKey.slice(1);
                    }
                }
                if (i == 'A2') {
                    sheet[i].v = '';
                }
            });

            let wopts = {
                bookType: 'xlsx',
                bookSST: false,
                type: 'buffer',
            };
            let _blob = XLSXS.write(workbook, wopts);

            FileSaver.saveAs(
                new Blob([_blob], {
                    type: 'application/octet-stream',
                }),
                this.partName + '房屋租赁费用收取明细表' + new Date().getTime() + '.xlsx',
            );
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
/deep/ .el-form-item {
    margin-bottom: 0px !important;
}
/deep/ .el-table thead.is-group th {
    background: #fff !important;
}
.cards {
    background: #fff;
    padding: 10px !important;
    padding-right: 0px !important;
    margin-bottom: 15px;
}
/deep/.el-table__header-wrapper tbody td,
.el-table__footer-wrapper tbody td {
    background-color: #fff !important;
    color: #606266;
}
.bg-white {
    background-color: #fff !important;
}
.ft500 {
    font-weight: 700;
    font-size: 15px;
}
</style>

import { render, staticRenderFns } from "./orderFormFile.vue?vue&type=template&id=472ee242&scoped=true"
import script from "./orderFormFile.vue?vue&type=script&lang=js"
export * from "./orderFormFile.vue?vue&type=script&lang=js"
import style0 from "./orderFormFile.vue?vue&type=style&index=0&id=472ee242&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "472ee242",
  null
  
)

export default component.exports
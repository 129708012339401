<template>
    <el-row>
        <el-col :span="6" class="mar-b-10">
            <el-select size="small" v-model='areaId' @change='changeDatabase' clearable placeholder="请选择停车区域">
                <el-option v-for='item in areaList' :key='item.parkId' :value='item.parkId' :label='item.parkName'></el-option>
            </el-select>
        </el-col>

        <el-col :span="24" class="pad-0">
            <el-col :span="8">
                <el-card shadow="hover">
                    <div class="czmj mar-b-10">车位数</div>
                    <el-row>
                        <el-col :span="6" class="czmjNum">{{totle.totalSpace||"-"}} </el-col>
                        <el-col :span="6" class="czmjNum"> {{totle.freeSpace||"-"}}</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6" class="czmj">总车位数 </el-col>
                        <el-col :span="6" class="czmj"> 空余车位数</el-col>
                    </el-row>

                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card shadow="hover">

                    <div class="czmj mar-b-10">长租车位数</div>
                    <el-row>
                        <el-col :span="6" class="czmjNum">{{totle.longSpace||"-"}} </el-col>
                        <el-col :span="8" class="czmjNum"> {{totle.longFreeSpace||"-"}}</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6" class="czmj">长租总车位数 </el-col>
                        <el-col :span="8" class="czmj"> 长租空余车位数</el-col>
                    </el-row>

                </el-card>
            </el-col>
            <el-col :span="8">
                <el-card shadow="hover">

                    <div class="czmj mar-b-10">临租车位数</div>
                    <el-row>
                        <el-col :span="6" class="czmjNum">{{totle.tmpSpace}} </el-col>
                        <el-col :span="8" class="czmjNum"> {{totle.tmpFreeSpace}}</el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6" class="czmj">临租总车位数 </el-col>
                        <el-col :span="8" class="czmj"> 临租车位空余数</el-col>
                    </el-row>

                </el-card>
            </el-col>
        </el-col>
        <el-col :span="24" class="mar-t-10 pad-0">
            <el-col :span="12">
                <el-card shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>长租车收费排行TOP10</span>
                    </div>
                    <div style="height: 250px">
                        <basic-echarts :option="chartOption1"></basic-echarts>
                    </div>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card shadow="hover">
                    <div slot="header" class="clearfix">
                        <span>临租车收费排行TOP10</span>
                    </div>
                    <div style="height: 250px">
                        <basic-echarts :option="chartOption2"></basic-echarts>
                    </div>
                </el-card>
            </el-col>
        </el-col>
    </el-row>
</template>

<script>
import { getMetaCommonList, getMetaCommonInfo } from '@/api/busiMode/metaCommon';
export default {
    data() {
        return {
            model: 'wisdom_park.wp_third_qqb_space',
            areaModel: 'wisdom_park.wp_third_qqb_park',
            longModel: 'wisdom_park.wp_third_qqb_long_rent_records',
            temModel: 'wisdom_park.wp_third_qqb_tmp_rent_records',
            totle: {},
            areaList: [],
            areaId: '',
            chartOption1: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                grid: {
                    left: '2%',
                    right: '2%',
                    bottom: '3%',
                    top: '4%',
                    containLabel: true,
                },

                xAxis: [
                    {
                        type: 'category',
                        data: [],
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '',
                        interval: 1000,
                        axisLabel: {
                            formatter: '{value}',
                        },
                    },
                ],
                series: [
                    {
                        name: '',
                        type: 'bar',
                        barWidth: 30, //固定柱子宽度
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '元';
                            },
                        },
                        data: [],
                    },
                ],
            },
            chartOption2: {
                tooltip: {
                    trigger: 'axis',
                    axisPointer: {
                        type: 'cross',
                        crossStyle: {
                            color: '#999',
                        },
                    },
                },
                grid: {
                    left: '2%',
                    right: '2%',
                    bottom: '3%',
                    top: '4%',
                    containLabel: true,
                },
                legend: {
                    data: ['1', '2', '3'],
                },
                xAxis: [
                    {
                        type: 'category',
                        data: [],
                        axisLabel: {
                            color: '#333',

                            formatter: function (value) {
                                return value;
                            },
                        },
                    },
                ],
                yAxis: [
                    {
                        type: 'value',
                        name: '',
                        interval: 1000,
                    },
                ],
                series: [
                    {
                        name: '',
                        type: 'bar',
                        barWidth: 30, //固定柱子宽度
                        tooltip: {
                            valueFormatter: function (value) {
                                return value + '元';
                            },
                        },
                        data: [],
                    },
                ],
            },
        };
    },
    computed: {},
    created() {
        this.onAreaLoad();
    },
    mounted() {},

    methods: {
        onAreaLoad() {
            let body = {
                pageNo: 1,
                pageSize: 500,
            };
            getMetaCommonList(this.areaModel, body).then(res => {
                const data = res.data.data;
                this.areaList = data.records;
                this.areaId = this.areaList[2]['parkId'];
                this.onLoad();
                this.onLongLoad();
                this.onTemLoad();
            });
        },
        onLoad() {
            let body = {
                pageNo: 1,
                pageSize: 500,
                wheres: [{ field: 'parkId', value: this.areaId }],
            };
            getMetaCommonList(this.model, body).then(res => {
                const data = res.data.data;
                this.totle = data.records[0];
            });
        },
        onLongLoad() {
            let body = {
                pageNo: 1,
                pageSize: 10,
                wheres: [{ field: 'parkId', value: this.areaId }],
                orders: [{ field: 'realFee', sort: 'DESC' }],
            };
            getMetaCommonList(this.longModel, body).then(res => {
                const data = res.data.data.records;
                let xAxis = [];
                let yAxis = [];
                data.forEach(e => {
                    xAxis.push(e.plateNumber);
                    yAxis.push(e.realFee);
                });
                this.chartOption1['xAxis'][0]['data'] = xAxis;
                this.chartOption1['series'][0]['data'] = yAxis;
                console.log(this.chartOption1);
            });
        },
        onTemLoad() {
            let body = {
                pageNo: 1,
                pageSize: 10,
                wheres: [{ field: 'parkId', value: this.areaId }],
                orders: [{ field: 'realFee', sort: 'DESC' }],
            };
            getMetaCommonList(this.temModel, body).then(res => {
                const data = res.data.data.records;
                let xAxis = [];
                let yAxis = [];
                data.forEach(e => {
                    xAxis.push(e.plateNumber);
                    yAxis.push(e.realFee);
                });
                this.chartOption2['xAxis'][0]['data'] = xAxis;
                this.chartOption2['series'][0]['data'] = yAxis;
            });
        },
        changeDatabase(value) {
            this.areaId = value;
            this.onLoad();
            this.onLongLoad();
            this.onTemLoad();
        },
    },
};
</script>

<style lang="scss" scoped>
/deep/ .el-card__body {
    padding: 15px;
}
/deep/ .el-select {
    width: 100%;
}
.czmj {
    color: rgb(153, 153, 153);
    font-size: 12px;
}
.czmjNum {
    font-size: 20px;
    margin-top: 5px;
    padding-bottom: 8px;
}
.czl {
    font-size: 12px;
    margin-top: 10px;
}
.ftzClor {
    color: rgb(153, 153, 153);
}
.down {
    color: #67c23a;
}
.top {
    color: #f56c6c;
}
</style>

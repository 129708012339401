<template>
    <div>
        <basic-container class="cards">
            <el-form :inline="true" :model="formInline" size="small" class="demo-form-inline">
                <!-- <el-form-item label="充值时间">
          <el-date-picker
            class="w-150"
            v-model="formInline['充值时间']"
            placeholder="充值时间"
          >
          </el-date-picker> 
        </el-form-item>-->
                <el-form-item label="充值时间">
                    <el-date-picker class="w-150" :picker-options="startTime" v-model="formInline['startTime']" placeholder="开始时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="-">
                    <el-date-picker class="w-150" :picker-options="endtTime" v-model="formInline['endTime']" placeholder="结束时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="会员编号" prop="name">
                    <el-input v-model="formInline['会员编号']" size="small" clearable filterable @clear="clearPart('会员编号')" placeholder="请填写会员编号">
                    </el-input>
                </el-form-item>
                <el-form-item label="会员姓名" prop="name">
                    <el-input v-model="formInline['会员姓名']" size="small" clearable filterable @clear="clearPart('会员姓名')" placeholder="请填写会员姓名">
                    </el-input>
                </el-form-item>

                <el-form-item>
                    <el-button type="primary" @click="search">查询</el-button>
                </el-form-item>
                <el-form-item>
                    <el-button class="pull-right" type="primary" plain @click="exportTable" icon="el-icon-download" size="small">导出</el-button>
                </el-form-item>
            </el-form>
        </basic-container>
        <basic-container isCard>
            <el-table :data="tableData" style="width: 100%" id="exportTable" v-loading="isLoading" element-loading-text="加载中，请稍后..." index border :height="height">
                <el-table-column type="index" fixed header-align="center" width="50">
                </el-table-column>
                <el-table-column prop="会员编号" fixed align="center" label="会员编号" width="150">
                </el-table-column>

                <el-table-column prop="会员姓名" fixed align="center" label="会员姓名" width="150">
                </el-table-column>
                <el-table-column prop="会员级别" fixed align="center" label="会员级别" width="100">
                </el-table-column>

                <el-table-column prop="充值金额" align="right" header-align="center" label="充值金额(元)" width="150">
                    <template slot-scope="{ row }">
                        {{ toqfw(row["充值金额"]) || "-" }}
                    </template>
                </el-table-column>
                <el-table-column prop="实收金额" align="right" header-align="center" label="实收金额(元)" width="150">
                    <template slot-scope="{ row }">
                        {{ toqfw(row["实收金额"]) || "-" }}
                    </template>
                </el-table-column>

                <el-table-column prop="优惠金额" align="right" header-align="center" label="优惠金额(元)" width="150">
                    <template slot-scope="{ row }">
                        {{ toqfw(row["优惠金额"]) || "-" }}
                    </template>
                </el-table-column>
                <el-table-column prop="付款方式" align="center" label="付款方式" width="150">
                </el-table-column>
                <el-table-column prop="会籍顾问" align="center" label="会籍顾问" width="150">
                </el-table-column>
                <el-table-column prop="充值时间" align="center" label="充值时间" width="200">
                    <template slot-scope="{ row }">
                        {{ formatDate(row.充值时间) }}
                    </template>
                </el-table-column>

                <el-table-column label="收款人" header-align="center" prop="收款人" width="150">
                </el-table-column>
                <el-table-column label="充值分店" align="left" width="200" header-align="center" prop="充值分店">
                </el-table-column>
                <el-table-column label="所属分店" width="200" header-align="center" prop="所属分店">
                </el-table-column>

                <el-table-column prop="备注" header-align="center" width="250" label="备注">
                </el-table-column>
            </el-table>
        </basic-container>
    </div>
</template>

<script>
import { getMetaCommonList } from '@/api/busiMode/metaCommon';
import XLSXS from 'xlsx-style';
import FileSaver from 'file-saver';
import dayjs from 'dayjs';
import Vue from 'vue';
import { getAppToken, queryAreaBySpecialDate } from '@/api/reportForms/reportForms';
import numCard from '../../data-view/widgets/num-card';
const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
export default {
    data() {
        return {
            formInline: {
                endTime: dayjs().format('YYYY-MM-DD'),
                startTime: dayjs().subtract(30, 'day').format('YYYY-MM-DD'),
            },
            height: viewPortHeight - 320,
            value1: '',
            partList: [],
            value2: '',
            tableData: [],
            buildList: [],
            companyList: [],
            contractList: [],
            tdList: [],
            partName: '',
            token: '',
            isLoading: false,
            startTime: {
                disabledDate: time => {
                    if (this.formInline.endTime) {
                        return time.getTime() > dayjs(this.formInline.endTime);
                    }
                },
            },
            endtTime: {
                disabledDate: time => {
                    if (this.formInline.startTime) {
                        //return dayjs(this.formInline.startDate).format('YYYY-MM-DD');
                        return time.getTime() < dayjs(this.formInline.startTime);
                    }
                },
            },
        };
    },
    mounted() {
        // this.formInline["充值时间"] = dayjs().format("YYYY-MM-DD");
        this.search();
    },
    methods: {
        // handelChangeTime(val) {
        //   console.log(val);
        //   this.formInline["充值时间"] = dayjs(val).format("YYYY-MM-DD");
        //   this.$forceUpdate();
        // },
        toqfw(num) {
            if (Number(num)) {
                let n = num;
                n = this.addDou(Number(num).toFixed(2));
                return n;
            } else {
                return num;
            }
        },

        addDou(value) {
            var isNegative = value < 0;
            value = Math.abs(value).toString();
            var decimalIndex = value.indexOf('.');
            if (decimalIndex === -1) {
                decimalIndex = value.length;
            }
            for (var i = decimalIndex - 3; i > 0; i -= 3) {
                value = value.slice(0, i) + ',' + value.slice(i);
            }
            if (isNegative) {
                value = '-' + value;
            }
            return value;
        },
        formatDate(createTime) {
            let obj = '';
            if (createTime) {
                obj = dayjs(createTime).format('YYYY-MM-DD HH:mm:ss');
            }
            return obj;
        },

        search() {
            this.tablData();
        },

        tablData() {
            let obj = {
                size: 100,
                current: 1,
                wheres: [],
            };
            const date1 = dayjs(this.formInline['endTime']);
            let d1 = date1.diff(this.formInline['startTime'], 'day');
            if (d1 > 30) {
                this.$message.error('时间不能超过30天');
            }
            if (this.formInline['startTime']) {
                obj.wheres.push({
                    field: '充值时间',
                    op: 'GT',
                    value: dayjs(this.formInline['startTime']).format('YYYY-MM-DD'),
                });
            }
            if (this.formInline['endTime']) {
                obj.wheres.push({
                    field: '充值时间',
                    op: 'LE',
                    value: dayjs(this.formInline['endTime']).format('YYYY-MM-DD'),
                });
            }

            if (this.formInline['会员编号']) {
                obj.wheres.push({
                    field: '会员编号',
                    value: this.formInline['会员编号'],
                });
            }
            if (this.formInline['会员姓名']) {
                obj.wheres.push({
                    field: '会员姓名',
                    value: this.formInline['会员姓名'],
                });
            }
            getMetaCommonList('wisdom_park.v_wp_spcod_account_card_stat', obj).then(res => {
                const data = res.data.data;
                this.tableData = data.records;
            });
        },

        clearPart(key) {
            delete this.formInline[key];
        },

        // 点击事件触发的方法
        exportTable() {
            // 首先加载  <table id='excelTable'> 标签 为 workbook对象excelTableDiv
            //let workbook = this.$XLSX.utils.table_to_book(document.getElementById('exportTable'), { raw: true });

            // var fix = document.querySelector('.el-table__fixed');
            // var workbook;
            // // 判断要导出的节点中是否有fixed的表格，如果有，转换excel时先将该dom移除，然后append回去
            // if (fix) {
            //     /* 从表生成工作簿对象 */
            //     workbook = this.$XLSX.utils.table_to_book(document.querySelector('#exportTable').removeChild(fix));
            //     document.querySelector('#exportTable').appendChild(fix);
            // } else {
            //     workbook = this.$XLSX.utils.table_to_book(document.querySelector('#exportTable'));
            // }

            var workbook;
            var fix = document.querySelector('.el-table__fixed');
            var dt = document.querySelector('#exportTable');
            var xlsxParam = { raw: true }; //转换成excel时，使用原始的格式,解决工单号过长导致数字自动转换为科学计数法导致精度缺失
            if (fix) {
                workbook = this.$XLSX.utils.table_to_book(dt.removeChild(fix), xlsxParam);
                dt.appendChild(fix);
            } else {
                workbook = this.$xlsx.utils.table_to_book(dt, xlsxParam);
            }
            // 先定义列宽 ， 我这里文件一共有7 列 ，所以设置7列宽度相等都为 20 ，如果你有很多列建议直接 map()
            let wscols = [
                { wch: 5 },
                { wch: 22 },
                { wch: 22 },
                { wch: 22 },
                { wch: 20 },
                { wch: 15 },
                { wch: 15 },
                { wch: 20 },
            ];

            // 获取 需要设置样式的 sheet ，我这里只有 一个 sheet 所以索引默认加载了第一个
            const sheet = workbook.Sheets[workbook.SheetNames[0]];
            // 设置列宽
            sheet['!cols'] = wscols;
            // 定义框线样式
            const borderAll = {
                color: { auto: 1 },
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
            };

            // 这里的意思为 先默认代表表格的 7 个列  的 列号
            // 比如 A2 意思是 第一列 第2行
            const _letterList = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];

            // 定义一个 箭头函数，接受三个参数，分别为 当前Sheet , 行列号（例如：‘A2’）, 是否是新增样式
            const _mapCellStyle = (_sheet, _key, _type) => {
                const _cellStyle = {
                    border: borderAll,
                    alignment: {
                        wrapText: true,
                        horizontal: 'center',
                        vertical: 'center',
                    },
                    font: {
                        name: '微软雅黑',
                        sz: 10,
                        height: 50,
                    },
                    height: 50,
                    bold: true,
                    numFmt: '0',
                };
                const _title = {
                    // border: borderAll,
                    alignment: {
                        wrapText: true,
                        horizontal: 'center',
                        vertical: 'center',
                    },
                    font: {
                        name: '微软雅黑',
                        sz: 16,
                    },
                    bold: false,
                    numFmt: '0',
                };
                if (_key == 'A1') {
                    if (_type === 'append') {
                        // 需要新增样式，说明当前加载的sheet中并不存在该单元格，可能当前单元格被前边合并，这种被合并的单元格也得设置样式，
                        // 否则就会出现 合并单元格只有第一格带框线，后边没框线的情况出现，所以这里需要将后边的边框样式也加上。
                        _sheet[_key] = {
                            s: _title,
                        };
                    } else {
                        // 若不是新增样式 则代表sheet中已存在该表格直接修改其 s 属性即可使属性生效
                        if (typeof _sheet[_key] === 'object') {
                            _sheet[_key].s = _title;
                        }
                    }
                } else {
                    if (_type === 'append') {
                        // 需要新增样式，说明当前加载的sheet中并不存在该单元格，可能当前单元格被前边合并，这种被合并的单元格也得设置样式，
                        // 否则就会出现 合并单元格只有第一格带框线，后边没框线的情况出现，所以这里需要将后边的边框样式也加上。
                        _sheet[_key] = {
                            s: _cellStyle,
                            v: _sheet['v'] + '1',
                        };
                    } else {
                        // 若不是新增样式 则代表sheet中已存在该表格直接修改其 s 属性即可使属性生效
                        if (typeof _sheet[_key] === 'object') {
                            _sheet[_key].s = _cellStyle;
                        }
                    }
                }
            };

            // sheet 不懂得可以单独打印一下，它其实就是一个对象，键代表行列号（‘A2’），值为该单元格的值，样式等，
            // 我们需要做的就是修改其值中的样式
            Object.keys(sheet).forEach((i, _) => {
                // 无用属性直接过滤
                if (i !== '!ref' || i !== '!cols' || i !== '!merges' || i !== 'Am') {
                    // 首先设置遍历到的 当前 key
                    let _nowKey = i;
                    // 然后调用 _mapCellStyle  渲染当前单元格样式
                    _mapCellStyle(sheet, _nowKey);
                    // 我们这里先获取下一个行列号  例如当前_nowKey 是 A1  这里就是获取到 B1 ,及 当前行的 下一列数据
                    let _nextKey = _letterList[_letterList.indexOf(_nowKey[0]) + 1] + i.slice(1);

                    // 判断 B1 是否在 Sheet的key中,如果不存在，只可能是因为B1所在单元格已经被A1所合并，所以我们需要将B1也调用一下  _mapCellStyle
                    // 渲染 B1 的样式，不然就会出现 A1 B1 这两格是合并的状态，只有A1有框线，而B1 没有框线的情况。
                    // 这里用 while 就是 要把需要合并的单元格全部 渲染上样式，直到可能 B4 在 Sheet中 停止
                    while (_nowKey[0] !== 'G' && !Object.keys(sheet).includes(_nextKey)) {
                        _mapCellStyle(sheet, _nextKey, 'append');
                        // 这里就是简单的交换索引
                        _nowKey = _nextKey;
                        _nextKey = _letterList[_letterList.indexOf(_nowKey[0]) + 1] + _nowKey.slice(1);
                    }
                }
            });

            let wopts = {
                bookType: 'xlsx',
                bookSST: false,
                type: 'buffer',
            };
            let _blob = XLSXS.write(workbook, wopts);

            FileSaver.saveAs(
                new Blob([_blob], {
                    type: 'application/octet-stream',
                }),
                this.partName + '充值记录' + new Date().getTime() + '.xlsx',
            );
        },
        //传入 YYYY-MM , YYYY-MM  (2020-09)  (2020-12)  返回 YYYY-MM 数组
        getYearAndMonth(start, end) {
            let result = [];
            let starts = start.split('-');
            let ends = end.split('-');
            let staYear = parseInt(starts[0]);
            let staMon = parseInt(starts[1]) - 1;
            let endYear = parseInt(ends[0]);
            let endMon = parseInt(ends[1]);
            while (staYear <= endYear) {
                if (staYear === endYear) {
                    while (staMon < endMon) {
                        staMon++;
                        var str = staYear + '-' + (staMon >= 10 ? staMon : '0' + staMon);
                        result.push(str);
                    }
                    staYear++;
                } else {
                    staMon++;
                    if (staMon > 12) {
                        staMon = 1;
                        staYear++;
                    }
                    let str = staYear + '-' + (staMon >= 10 ? staMon : '0' + staMon);
                    result.push(str);
                }
            }
            return result;
        },
    },
};
</script>
<style scoped lang="scss" rel="stylesheet/scss">
.ft500 {
    font-weight: 700;
}
/deep/ .el-table thead.is-group th {
    background: #fafafa !important;
}
.bg-white {
    background-color: #fff !important;
}
.cards {
    background: #fff;
    padding: 15px 10px 0px 10px !important;
    padding-right: 0px !important;
    margin-bottom: 15px;
}
</style>

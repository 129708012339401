<template>
  <el-container class="h-100p">
    <el-aside width="250px">
      <basic-container isCard class="h-100p ov-a">
        <el-input
          class="mar-b-15"
          size="small"
          placeholder="输入关键字进行过滤"
          clearable
          v-model="treeFilterText"
        ></el-input>
        <el-tree
          ref="tree"
          node-key="id"
          :data="catalogData"
          :props="treeProps"
          :expand-on-click-node="false"
          @node-click="treeNodeClick"
          :filter-node-method="filterNode"
        >
        </el-tree>
      </basic-container>
    </el-aside>
    <el-main class="pad-l-15">
      <AssetsTable :queryParams="tableQueryParams"></AssetsTable>
    </el-main>
  </el-container>
</template>
<script>
import {
  getMetaCommonListTree,
} from "@/api/busiMode/metaCommon";

import AssetsTable from "./table.vue";

export default {
  components: { AssetsTable },
  data() {
    return {
      catalogModel: "wisdom_park.wp_asset_category",
      treeFilterText: "",
      catalogData: [],
      treeProps: {
        label: "asset_category_name",
      },
      tableQueryParams: {},
      seachForm: {},
    };
  },
  watch: {
    treeFilterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  mounted() {
    this.getMetaCommonListTree();
  },
  methods: {
    getMetaCommonListTree() {
      getMetaCommonListTree(this.catalogModel, {
        labelField: "asset_category_name",
        valueField: "id",
        releaseField: "parent_id",
        isLazy: false,
      }).then((res) => {
        this.catalogData = [
          {
            asset_category_name: "全部",
            id: "all",
          },
        ].concat(res.data.data);
        this.$nextTick(() => {
          if (this.$refs.tree) {
            this.$refs.tree.setCurrentNode(this.catalogData[0]);
          }
        });
      });
    },
    treeNodeClick(ev) {
      if (ev) {
        let tableQueryParams = {
          ...this.getSearchForm(),
        };
        if (ev.id !== "all") {
          tableQueryParams = {
            ...tableQueryParams,
            asset_category_code: ev["id"],
          };
        }
        this.tableQueryParams = tableQueryParams;
      }
    },
    getSearchForm() {
      let seachForm = {};
      Object.keys(this.seachForm).forEach((key) => {
        if (this.seachForm[key]) {
          seachForm[key] = this.seachForm[key];
        }
      });
      return seachForm;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.asset_category_name.indexOf(value) !== -1;
    },
  },
};
</script>

<template>
  <basic-container>
    <avue-crud
      ref="table"
      :data="tableData"
      :page.sync="page"
      :option="tableOption"
      :table-loading="tableLoading"
      :search.sync="search"
      v-model="form"
      @search-change="searchChange"
      @on-load="onLoad"
      @refresh-change="onLoad"
      @row-save="saveTableForm"
      @row-update="(row, index, done, loading) => saveTableForm(row, done, loading)"
      @row-del="rowDel"
    >
      <template slot="address_label_idForm" slot-scope="{disabled,size}">
        <model-search-select
          :disabled="disabled"
          class="dis-b w-100p"
          model="wp_address_label"
          v-model="form.address_label_id"
          labelKey="name"
          :fillModel.sync="form"
          :fills="[{ source: 'name', target: 'address_label' }]"
          @change="setName"
        ></model-search-select>
      </template>
      <template slot="delivery_idForm" slot-scope="{disabled,size}">
        <model-search-select
          :disabled="disabled"
          class="dis-b w-100p"
          model="wp_spcod_delivery_man"
          v-model="form.delivery_id"
          labelKey="delivery_name"
          valueKey="user_id"
          :params="{
            status: 1
          }"
          :fillModel.sync="form"
          :fills="[
            { source: 'delivery_name', target: 'delivery' },
            { source: 'delivery_phone', target: 'delivery_tel'}
          ]"
          @change="setName"
        ></model-search-select>
      </template>
      <template slot="address_label_idSearch" slot-scope="{disabled,size}">
        <model-search-select
          :disabled="disabled"
          class="dis-b w-100p"
          model="wp_address_label"
          v-model="search.address_label_id"
          labelKey="name"
        ></model-search-select>
      </template>
      <template slot="delivery_idSearch" slot-scope="{disabled,size}">
        <model-search-select
          :disabled="disabled"
          class="dis-b w-100p"
          model="wp_spcod_delivery_man"
          v-model="search.delivery_id"
          labelKey="delivery_name"
          valueKey="user_id"
        ></model-search-select>
      </template>
    </avue-crud>
  </basic-container>
</template>
<script>
import {
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  delMetaCommon,
  getPicListByIds
} from "@/api/busiMode/metaCommon";
import * as dictService from "@/api/system/dictbiz";
import dayjs from 'dayjs';
export default {
  data(){
    return {
      form: {

      },
      model: 'wisdom_park.wp_delivery_bill',
      tableLoading: false,
      tableData: [],
      search: {},
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableOption: {
        height: 'auto',
        labelPosition: 'top',
        searchMenuPosition: 'right',
        searchLabelPosition: 'right',
        searchMenuSpan: 24,
        border: true,
        column: [
          {
            label: "配送单名称",
            prop: "name",
            span: 12,
            width: 300,
            search: true
          },
          {
            label: "地址标签",
            prop: "address_label",
            span: 12,
            display: false
          },
          {
            label: "地址标签",
            prop: "address_label_id",
            span: 12,
            hide: true,
            showColumn: false,
            search: true
          },
          {
            label: "配送员",
            prop: "delivery",
            span: 12,
            display: false,
            width: 150
          },
          {
            label: "配送员",
            prop: "delivery_id",
            span: 12,
            hide: true,
            showColumn: false,
            search: true,
          },
          {
            label: "配送员电话",
            prop: "delivery_tel",
            span: 12,
            width: 120
          },
          {
            label: "状态",
            prop: "status",
            type: 'select',
            dicUrl: dictService.getUrl("DeliveryOrderStatus"),
            props: {
              label: "dictValue",
              value: "dictKey",
            },
            dataType: 'number',
            search: true
          },
          {
            label: "创建时间",
            prop: "create_time",
            span: 12,
            display: false,
            width: 140
          },
          {
            label: "更新时间",
            prop: "update_time",
            span: 12,
            display: false,
            width: 140
          },
        ]
      }
    }
  },
  mounted(){
    
  },
  methods: {
    setName(){
      this.$nextTick(() => {
        let d = dayjs('2023-10-11 14:51:22');
        // let hour = d.hour();
        // let hourType = '';
        // if(hour >= 5 && hour <= 8){
        //   hourType = '早上'
        // }
        // if(hour > 8 && hour <= 11){
        //   hourType = '上午'
        // }
        // if(hour > 11 && hour <= 14){
        //   hourType = '中午'
        // }
        // if(hour > 14 && hour <= 18){
        //   hourType = '下午'
        // }
        // if(hour > 18 && hour <= 21){
        //   hourType = '晚上'
        // }
        // console.log(d.hour());
        this.form.name = `${this.form.address_label}_${this.form.delivery}_${dayjs().format('YY-MM-DD HH:mm:ss')}`
      })
    },
    onLoad(){
      let wheres = [];
      Object.keys(this.search).forEach(
        name => {
          if((this.search[name] ?? '') !== '' && name[0] !== '$'){
            if(name === 'name'){
              wheres.push({field: name , op: 'LIKE' , value: this.search[name]})
            }else{
              wheres.push({field: name , value: this.search[name]})
            }
          }
        }
      )
      
      this.tableData = [];
      this.tableLoading = true;
      return getMetaCommonList(this.model , {
        current: this.page.currentPage,
        size: this.page.pageSize,
        wheres: wheres
      }).then(
        res => {
          this.tableLoading = false;
          if(res.data && res.data.code === 200){
            this.tableData = res.data.data.records;
            this.page.total = res.data.data.total;
          }else{
            this.tableData = [];
            this.page.total = 0;
          }
        }
      ).catch(e => {
        this.tableLoading = false;
      })
    },
    searchChange(params, done) {
      this.page.currentPage = 1;
      this.onLoad(params)
        .then((res) => {
          done();
        })
        .catch((e) => {
          done();
        });
    },
    saveTableForm(row, done, loading) {
      // console.log(row);
      let body = {
        ...row
      };

      Object.keys(body).forEach(
        key => {
          if(key[0] === '$'){
            delete body[key];
          }
        }
      );
     
      if(row.id){
        body = {
          update: {
            ...body,
            update_time: dayjs().format('YYYY-MM-DD HH:mm:ss')
          },
          primaryKeyValue: row.id,
          primaryKey: "id",
        };
      }else{
        body = {
          values: {
            ...body,
            create_time: dayjs().format('YYYY-MM-DD HH:mm:ss')
          }
        }
      };
      (row.id ? updateMetaCommon : saveMetaCommon)(this.model , body).then((res) => {
        this.responeHandle(res, done, loading);
      }).catch((e) => {
        loading();
      });
    },
    responeHandle(res, done, loading) {
      if (res.data && res.data.code === 200) {
        done && done();
        this.onLoad();
        this.$message.success(res.data.msg);
      } else {
        loading && loading();
        this.$message.error(res.data.msg);
      }
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        return delMetaCommon(this.model , {pk: 'id' , ids: [row.id]});
      }).then(() => {
        this.onLoad();
        this.$message({
          type: "success",
          message: "操作成功!",
        });
      });
    },
  }
}
</script>
<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      :page.sync="page"
      :before-open="beforeOpen"
      :permission="permissionList"
      v-model="form"
      ref="crud"
      @row-update="rowUpdate"
      @row-save="rowSave"
      @row-del="rowDel"
      @search-change="searchChange"
      @search-reset="searchReset"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
      @change="formChange"
    >
      <template slot-scope="scope" slot="menu">
        <template v-if="website.permission">
          <template v-if="scope.row.status == 1">
            <el-button type="text" size="small" icon="el-icon-download" class="none-border" v-if="permission.sportstadium_close" @click="tip(scope.row)">关闭</el-button>
          </template>
          <template v-if="scope.row.status == 0">
            <el-button type="text" size="small" icon="el-icon-edit" class="none-border" v-if="permission.sportstadium_edit" @click="handleEdit(scope.row, scope.index)">编辑</el-button>
            <el-button type="text" size="small" icon="el-icon-delete" class="none-border" v-if="permission.sportstadium_del" @click="handleDel(scope.row, scope.index)">删除</el-button>
            <el-button type="text" size="small" icon="el-icon-upload2" class="none-border" v-if="permission.sportstadium_open" @click="tip(scope.row)">开放</el-button>
          </template>
        </template>
        <template v-else>
          <template v-if="scope.row.status == 1">
            <el-button type="text" size="small" icon="el-icon-download" class="none-border" @click="tip(scope.row)">关闭</el-button>
          </template>
          <template v-if="scope.row.status == 0">
            <el-button type="text" size="small" icon="el-icon-edit" class="none-border" @click="handleEdit(scope.row, scope.index)">编辑</el-button>
            <el-button type="text" size="small" icon="el-icon-delete" class="none-border" @click="handleDel(scope.row, scope.index)">删除</el-button>
            <el-button type="text" size="small" icon="el-icon-upload2" class="none-border" @click="tip(scope.row)">开放</el-button>
          </template>
        </template>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
import { getList, getPage, getDetail, add, update, remove, updateStadiumStatu } from "@/api/spcod/sportstadium"
import { mapGetters } from "vuex"
import website from "@/config/website"
import { getMetaCommonList } from "@/api/busiMode/metaCommon"

export default {
  data() {
    return {
      form: {},
      query: {},
      loading: true,
      status: "",
      floorLists: [],

      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      option: {
        height: "auto",
        calcHeight: 30,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        viewBtn: true,
        editBtn: false,
        delBtn: false,
        labelWidth: 110,
        dialogClickModal: false,
        column: [
          {
            label: "场馆编号",
            prop: "code",
            search: true,
            searchSpan: 4,
            addDisplay: true,
            editDisplay: false,
            rules: [
              {
                required: true,
                message: "请输入场馆编号",
                trigger: "blur",
              },
            ],
          },
          {
            label: "场馆名称",
            prop: "name",
            search: true,
            searchSpan: 4,
            rules: [
              {
                required: true,
                message: "请输入场馆名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "场馆全称",
            prop: "fullName",
            rules: [
              {
                required: true,
                message: "请输入场馆全称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "场馆类型",
            prop: "fullType",
            type: "select",
            dicData: [
              { label: "室内", value: 0 },
              { label: "室外", value: 1 },
            ],
            props: {
              label: "label",
              value: "value",
            },
            rules: [
              {
                required: true,
                message: "请输入场馆类型",
                trigger: "blur",
              },
            ],
          },

          {
            label: "楼宇",
            prop: "buildingId",
            search: true,
            addDisplay: true,
            editDisplay: true,
            type: "select",
            dicUrl: "/api/park-spcod/mobcodappoint/listbuilding?current=1&size=10000000",
            props: {
              label: "name",
              value: "id",
            },
            dicFormatter: (res) => {
              return res.data.records
            },
            rules: [
              {
                required: false,
                message: "请选择楼宇",
                trigger: "blur",
              },
            ],
          },

          {
            label: "楼层",
            hide: true,
            prop: "floorId",
            search: false,
            type: "select",
            dicData: this.floorLists,
            props: {
              label: "code",
              value: "id",
            },
            rules: [
              {
                required: false,
                message: "请输入外键到楼宇ID",
                trigger: "blur",
              },
            ],
          },
          {
            label: "楼层",
            prop: "floorName",
            search: false,
            display: false,
          },
          {
            label: "场馆位置",
            prop: "addr",
            addDisplay: true,
            editDisplay: true,
            viewDisplay: true,
            rules: [
              {
                required: false,
                message: "请输入场馆位置",
                trigger: "blur",
              },
            ],
          },
          {
            label: "位置坐标",
            prop: "gpsPos",
            hide: true,
            addDisplay: false,
            editDisplay: false,
            viewDisplay: false,
            rules: [
              {
                required: true,
                message: "请输入场馆位置坐标",
                trigger: "blur",
              },
            ],
          },
          {
            label: "开放开始",
            prop: "openFrom",
            type: "select",
            dicUrl: "/api/park-spcod/sportstadium/getimeseg?startHour=8&endHour=23&timeSeg=60",
            props: {
              label: "name",
              value: "id",
            },
            dicFormatter: (data) => {
              let datalist = []
              data.data.forEach((ele) => {
                datalist.push({
                  name: ele,
                  id: ele,
                })
              })
              return datalist
            },
            rules: [
              {
                required: true,
                message: "请输入开放开始，09:00",
                trigger: "blur",
              },
            ],
          },
          {
            label: "开放结束",
            prop: "openEnd",
            type: "select",
            dicUrl: "/api/park-spcod/sportstadium/getimeseg?startHour=8&endHour=23&timeSeg=60",
            props: {
              label: "name",
              value: "id",
            },
            dicFormatter: (data) => {
              let datalist = []
              data.data.forEach((ele) => {
                datalist.push({
                  name: ele,
                  id: ele,
                })
              })
              return datalist
            },
            rules: [
              {
                required: true,
                message: "请输入开放结束,20:00",
                trigger: "blur",
              },
            ],
          },
          {
            label: "场馆主图",
            prop: "files",
            type: "upload",
            listType: "picture-card",
            span: 24,
            hide: true,
            propsHttp: {
              res: "data",
              url: "link",
              name: "attachId",
            },
            tip: "只能上传jpg/png用户头像，且不超过500kb",
            action: "/api/jida-resource/oss/endpoint/put-file-attach",
          },
          {
            label: "状态",
            prop: "status",
            search: true,
            searchSpan: 4,
            addDisplay: false,
            editDisplay: false,
            type: "select",
            dicData: [
              {
                label: "开放",
                value: 1,
              },
              {
                label: "关闭",
                value: 0,
              },
            ],
            rules: [
              {
                required: false,
                message: "请输入状态",
                trigger: "blur",
              },
            ],
          },

          {
            label: "场馆管理人",
            prop: "stadiumManager",
            span: 12,
            width: 150,
            minWidth: "150",
            className: "test",
          },
          {
            label: "场馆描述",
            prop: "memo",
            hide: true,
            span: 24,
            minRows: 2,
            type: "textarea",
            rules: [
              {
                required: false,
                message: "请输入场馆描述",
                trigger: "blur",
              },
            ],
          },
          {
            label: "预约描述",
            prop: "apptMemo",
            hide: true,
            span: 24,
            minRows: 4,
            type: "textarea",
            rules: [
              {
                required: false,
                message: "请输入场馆描述",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      data: [],
    }
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.sportstadium_add, !website.permission),
        viewBtn: this.vaildData(this.permission.sportstadium_view, !website.permission),
        delBtn: this.vaildData(this.permission.sportstadium_del, !website.permission),
        editBtn: this.vaildData(this.permission.sportstadium_edit, !website.permission),
      }
    },
    ids() {
      let ids = []
      this.selectionList.forEach((ele) => {
        ids.push(ele.id)
      })
      return ids.join(",")
    },
  },
  methods: {
    handleDel(row, index) {
      let _this = this
      _this
        .$confirm("确定将选择场地数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          remove(row.id).then((res) => {
            _this.onLoad(this.page)
            _this.$message({
              type: "success",
              message: "操作成功!",
            })
            done()
          })
        })
    },
    handleEdit(row, index) {
      this.$refs.crud.rowEdit(row, index)
    },
    rowSave(row, done, loading) {
      let bt = parseInt(row.openFrom.split(":"))
      let et = parseInt(row.openEnd.split(":"))
      if (bt >= et) {
        this.$message({
          type: "error",
          message: "开始不能超过结束时间!",
        })
        done()
        return
      }
      if (row.files && row.files.length > 0) {
        row.iconFile = row.files[0].value
      }
      if (row.floorId) {
        this.floorLists.forEach((f) => {
          if (row.floorId == f.id) {
            row.floorName = f.code
          }
        })
      }
      console.log(row)
      add(row).then(
        () => {
          this.onLoad(this.page)
          this.$message({
            type: "success",
            message: "操作成功!",
          })
          done()
        },
        (error) => {
          loading()
          window.console.log(error)
        }
      )
    },
    rowUpdate(row, index, done, loading) {
      let bt = parseInt(row.openFrom.split(":"))
      let et = parseInt(row.openEnd.split(":"))
      if (bt >= et) {
        this.$message({
          type: "error",
          message: "开始不能超过结束时间!",
        })
        done()
        return
      }

      if (row.files && row.files.length > 0) {
        row.iconFile = row.files[0].value
      }
      if (row.floorId) {
        this.floorLists.forEach((f) => {
          if (row.floorId == f.id) {
            row.floorName = f.code
          }
        })
      }
      update(row).then(
        () => {
          this.onLoad(this.page)
          this.$message({
            type: "success",
            message: "操作成功!",
          })
          done()
        },
        (error) => {
          loading()
          console.log(error)
        }
      )
    },
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: "success",
            message: "操作成功!",
          })
        })
    },
    handleDelete() {
      if (this.selectionList.length === 0) {
        this.$message.warning("请选择至少一条数据")
        return
      }
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(this.ids)
        })
        .then(() => {
          this.onLoad(this.page)
          this.$message({
            type: "success",
            message: "操作成功!",
          })
          this.$refs.crud.toggleSelection()
        })
    },
    beforeOpen(done, type) {
      let _this = this
      if (["edit", "view"].includes(type)) {
        getDetail(this.form.id).then((res) => {
          _this.form = res.data.data
          _this.form.files = []
          if (parseInt(_this.form.buildingId) == -1) _this.form.buildingId = ""
          let files = []
          if (res.data.data.iconFile != "") {
            files.push({ label: "noValue", value: res.data.data.iconFile })
          }
          _this.form.files = files
        })
      }
      done()
    },
    searchReset() {
      this.query = {}
      this.onLoad(this.page)
    },
    searchChange(params, done) {
      this.query = params
      this.page.currentPage = 1
      this.onLoad(this.page, params)
      done()
    },
    selectionClear() {
      this.selectionList = []
      this.$refs.crud.toggleSelection()
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize
    },
    refreshChange() {
      this.onLoad(this.page, this.query)
    },
    onLoad(page, params = {}) {
      this.loading = true
      getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then((res) => {
        let data = res.data.data
        let lists = []
        data.records.forEach((i) => {
          i.id = String(i.id)
          lists.push(i)
          if (i.buildingId < 0) i.buildingId = ""
        })

        this.page.total = data.total
        this.data = lists
        this.loading = false
        this.selectionClear()
      })
    },
    tip(row) {
      this.$alert("确定修改状态吗？", "确定").then(() => {
        updateStadiumStatu(row.id).then((res) => {
          if (res.data.success) {
            if (row.status === 1) {
              row.status = 0
              this.$forceUpdate()
            } else {
              row.status = 1
              this.$forceUpdate()
            }
            this.$message.success(res.data.msg)
          } else {
            this.$message.success(res.data.msg)
          }
        })
      })
    },
    formChange(eve) {
      if (eve.buildingId) {
        this.getFloors(eve.buildingId)
      }
      console.log(eve)
    },
    getFloors(buildingId) {
      let obj = {
        size: 1000,
        current: 1,
        orders: [{ field: "code+1000", sort: "DESC" }],
      }
      obj.wheres = [{ field: "building_id", value: buildingId }]
      getMetaCommonList("wisdom_park.wp_floor", obj).then((res) => {
        const data = res.data.data
        this.floorLists = data.records
        this.$set(this.option["column"][5], "dicData", this.floorLists)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
// .el-collapse-item__content {
//   /deep/.el-form-item__label {
//     width: 110px !important ;
//     background-color: red;
//   }
// }

// /deep/ .test .el-form-item__label {
//   width: 110px !important ;
//   background-color: red;
// }
</style>

<template>
  <el-form ref="form" size="small" label-width="80px">
    <el-form-item class="mar-t-8" label="数据来源">
      <el-radio v-model="datasourceConf.dataModel" @change="dataModelChange" label="staticData">静态数据</el-radio>
      <el-radio v-model="datasourceConf.dataModel" @change="dataModelChange" label="datasource">数据源</el-radio>
      <el-radio v-model="datasourceConf.dataModel" @change="dataModelChange" label="apiInterface">API接口</el-radio>
    </el-form-item>
    <!-- <el-form-item label="数据类型">
      <el-radio v-model="datasourceConf.dataType" label="json">JSON</el-radio>
      <el-radio v-model="datasourceConf.dataType" label="text">文本</el-radio>
    </el-form-item> -->
    <template v-if="datasourceConf.dataModel === 'staticData'">
      <el-form-item label="数据">
        <!-- <div class="h-300 bor ov-h"> -->
          <!-- <code-editor :language="datasourceConf.dataType === 'json' ? 'javascript' : 'text'" class="h-100p w-100p" v-model="datasourceConf.staticValue"></code-editor> -->
          <el-input type="textarea" rows="20" v-model="datasourceConf.staticValue" @change="staticValueChange"></el-input>
        <!-- </div> -->
      </el-form-item>
    </template>

    <template v-if="datasourceConf.dataModel === 'apiInterface'">
      <el-form-item label="接口地址">
        <el-input v-model="datasourceConf.option.url" placeholder="请输入接口地址"></el-input>
      </el-form-item>
      <el-form-item label="数据解析">
        <el-select class="dis-b" v-model="datasourceConf.option.parse">
          <el-option v-for="item of dataParses" :label="item.name" :value="item.code"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary">请求数据</el-button>
      </el-form-item>
    </template>

    <template v-if="datasourceConf.dataOption">
      <el-form-item v-for="item of datasourceConf.dataOption" class="mar-t-8" :label="item.name">
        <el-input v-if="item.type === 'text'" v-model="item.value" type="text"></el-input>
        <el-select class="dis-b" v-if="item.type === 'field'" v-model="item.value">
          <el-option label="请选择字段" value=""></el-option>
          <el-option v-for="field of fields" :label="field.name" :value="field.code"></el-option>
        </el-select>
      </el-form-item>
    </template>

  </el-form>
</template>
<script>
export default {
  name: 'WidgetConfigByData',
  tabName: '数据源',
  inject: [ 'dataParseList' ],
  data(){
    return {
      datasourceConf: {},
      dataParses: [{name: '标准解析组件' , desc: '标准解析组件' , code: 'default'}].concat(this.dataParseList || []),
      fields: []
    }
  },
  props: {
    widget: {
      type: Object,
      default: () => {
        return {}
      }
    },
    option: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted(){
    this.datasourceConf = this.widget.datasourceConf;
    this.staticValueChange();
    console.log(this.datasourceConf);
  },
  methods: {
    staticValueChange(){
      if(this.datasourceConf.dataModel === 'staticData' && this.datasourceConf.dataType === 'json'){
        let fields = [];
        let data = null;
        try {
          data = JSON.parse(this.datasourceConf.staticValue)
        } catch (error) {
          data = null;
        }
        // console.log(data , this.datasourceConf.staticValueChange);
        if(data){
          let dataItem = null;
          if(data instanceof Array){
            if(data.length){
              dataItem = data[0];
            }
          }else if(data instanceof Object){
            dataItem = data;
          }
          console.log(dataItem);
          dataItem && Object.keys(dataItem).forEach(
            key => {
              if(!fields.find(field => field.name === key)){
                fields.push({
                  name: key,
                  code: key,
                  desc: key
                })
              }
            }
          )
        }
        this.fields = fields;
      }
    },
    initValue(){
      
    },
    valueChange(){
      
    },
    dataModelChange(){
      if(this.datasourceConf.dataModel === 'staticData'){
        this.datasourceConf.option = {};
      }else if(this.datasourceConf.dataModel === 'apiInterface'){
        this.datasourceConf.option = {
          url: '',
          parse: ''
        };
      }else if(this.datasourceConf.dataModel === 'datasource'){
        this.datasourceConf.option = {};
      }
    }
  }
}
</script>
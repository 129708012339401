<template>
  <div class="panel-tab__content">
    <el-form
      size="small"
      label-position="left"
      label-width="70px"
    >
      <el-col :span="20">
        <el-form-item label="能力名称" class="pad-l-20">
          <el-input
            v-model="serviceObj.name"
            placeholder="请输入能力名称"
            class="w-240"
            size="small"
            @change="taskInfoChange('name')"
          ></el-input>
        </el-form-item>
      </el-col>
      <el-col :span="20">
        <el-form-item label="启动方式" class="pad-l-20">
          <el-select
            label="启动方式"
            v-model="serviceObj.startupModel"
            size="small"
            class="w-240"
            filterable
            placeholder="启动方式"
            @change="taskInfoChange('startupModel')"
          >
            <el-option
              v-for="item in qdfsList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="20" v-if="serviceObj.startupModel == '1'">
        <el-form-item label="事件触发" class="pad-l-20">
          <el-select
            label="事件触发"
            class="w-240"
            v-model="serviceObj.sjcfType"
            size="small"
            filterable
            placeholder="事件触发"
            @change="taskInfoChange('sjcfType')"
          >
            <el-option
              v-for="item in sjcfList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :span="20" v-if="serviceObj.startupModel == '2'">
        <el-form-item label="设置标识" class="pad-l-20">
          <el-input
            class="w-240"
            v-model="serviceObj.szbs"
            placeholder="设置标识"
            size="small"
            @change="taskInfoChange('szbs')"
          ></el-input>
        </el-form-item>
      </el-col>
    </el-form>
  </div>
</template>
<script>

export default {
  name: "baseInfo",
  data() {
    return {
      serviceObj: {
        startupModel: '',
        name: '',
        sjcfType: '',
        szbs: ''
      },
      qdfsList: [
        { name: "事件触发", value: "1" },
        { name: "消息触发", value: "2" },
        { name: "调用触发", value: "3" },
      ],
      sjcfList: [
        { name: "插入前", value: "crq" },
        { name: "插入后", value: "crh" },
        { name: "修改前", value: "xgq" },
        { name: "修改后", value: "xgh" },
        { name: "删除前", value: "scq" },
        { name: "删除后", value: "sch" },
      ],
    };
  },
  props: {
    id: String,
    businessObject: Object,
    bpmnElement: {
      type: Object,
      default(){
        return null;
      }
    },
  },
  watch: {
    id: {
      immediate: true,
      handler(){
        this.resetBaseInfo();
      }
    }
  },
  mounted() {
    
  },

  created() {},

  methods: {
    resetBaseInfo(){
      if(this.businessObject){
        this.serviceObj.name = this.businessObject.name || '';
        this.serviceObj.startupModel = this.businessObject.startupModel || '3';
        this.serviceObj.sjcfType = this.businessObject.sjcfType || '';
        this.serviceObj.szbs = this.businessObject.szbs || '';
      }
    },
    taskInfoChange(key){
      let attrObj = {
        [key]: this.serviceObj[key]
      }
      console.log(attrObj)
      if(this.bpmnElement){
        console.log(attrObj)
        window.bpmnInstances.modeling.updateProperties(this.bpmnElement, attrObj);
      }
    }
  },
  destroyed() {},
};
</script>
<style lang="scss" scoped></style>

<template>
  <el-col :span="24">
    <el-form size="small" label-position="left" label-width="50px">
      <el-form-item label="名称" class="h-40">
        <el-input
          class="mar-b-10"
          v-model="operation.operName"
          placeholder="请输入操作名称"
          size="small"
        ></el-input>
      </el-form-item>
      <el-form-item label="图标" class="h-40">
        <el-popover
          ref="popover"
          placement="bottom"
          width="450"
          trigger="click"
        >
          <el-tabs v-model="operation.activeName">
            <el-tab-pane
              v-for="(item, index) of iconList"
              :key="index"
              :label="item.label"
              :name="'' + index"
            >
              <el-row class="h-300 ov-a">
                <el-col
                  :span="4"
                  v-for="(icon, index) of item.list"
                  :key="index"
                >
                  <div
                    @click="iconClick(icon)"
                    title="点击切换图标(className)"
                    class="w-100p h-60 icon-item cur-p"
                  >
                    <i class="fts-30" :class="icon"></i>
                  </div>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
          <div slot="reference">
            <el-button
              style="width: 48px; height: 38px; border-style: dashed"
              size="small"
              :icon="operation.icon"
            ></el-button>
          </div>
        </el-popover>
        <i class="el-icon-delete" @click="deleteIcon"></i>
      </el-form-item>
    </el-form>
    <el-col :span="24" class="pad-10">
      <el-radio-group v-model="operationType">
        <el-col :span="24" class="pad-10">
          <el-radio label="business">业务</el-radio>
        </el-col>
        <el-col :span="24" class="pad-10">
          <el-radio label="staticFun">JS静态方法</el-radio>
        </el-col>
        <el-col :span="24" class="pad-10">
          <el-radio label="newPage">通用</el-radio>
        </el-col>
      </el-radio-group>
    </el-col>
    <!-- <el-col :span="24" class="pad-10 text-center">
      <el-button type="primary" size="small" @click="saveoperation()"
        >确 定</el-button
      >
      <el-button size="small">取 消</el-button>
    </el-col> -->
  </el-col>
</template>
<script>
import iconList from "@/config/iconList";

export default {
  name: "",
  components: {},
  data() {
    return {
      iconList: iconList,
      operationType: "business",
      operation: {
        icon: "",
        type: "",
        operName: "",
      },
    };
  },
  mounted() {},
  props: {},
  watch: {
    operationType: {
      handler: function (newValue, oldName) {
        if (newValue) {
          this.saveoperation();
        }
      },
      deep: true,
    },
  },
  created() {},
  methods: {
    saveoperation() {
      this.operation.type = this.operationType;
      this.$emit("saveoperation", this.operation);
    },
    deleteIcon() {
      this.operation["icon"] = "";
    },
    iconClick(icon) {
      this.$refs.popover.showPopper = false;
      this.operation["icon"] = icon;
      this.operation = JSON.parse(JSON.stringify(this.operation));
    },
  },
  mounted() {
    //this.operationType = this.operation.type;
  },
};
</script>
<style scoped>
.el-icon-delete {
  position: absolute;
  top: 15px;
  left: 60px;
  cursor: pointer;
  color: #f56c6c;
}
.mar-b-10 {
  margin-bottom: 10px;
}
.pad-10 {
  padding: 10px;
}
</style>

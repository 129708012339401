<template>
    <div class="manageDetail">
        <el-tabs v-model="activeName" @tab-click="tabsHandleClick">
            <el-tab-pane v-for="item of tabs" :label="item.dictValue" :name="item.dictKey"></el-tab-pane>
        </el-tabs>
        <avue-crud v-if="init" ref="table" :data="tableData" :page.sync="page" :option="tableOption" :table-loading="tableLoading" :search.sync="search" v-model="form" @search-change="onLoad"
                   @on-load="onLoad" @refresh-change="onLoad" :permission="permissionList">
            <template slot-scope="{ size, row }" slot="menu">
                <el-button icon="el-icon-view" :size="size || 'small'" type="text" @click="gotoForm(row, 'view')">查看</el-button>
                <el-button v-if="row.status === 3 && permissionList.returnVisit" icon="el-icon-phone" :size="size || 'small'" type="text" @click="visit(row)">回访</el-button>
            </template>
        </avue-crud>
        <el-drawer :visible.sync="drawer" :size="400" :withHeader="false">
            <el-container v-if="drawer" class="h-100p">
                <el-header class="bor-b" height="50px">
                    <el-page-header class="lh-50" @back="drawer = false" content="报修详情"></el-page-header>
                </el-header>
                <el-main class="pad-15">
                    <RepairDetail :id="currentRow.id"></RepairDetail>
                </el-main>
                <el-footer class="bor-t lh-50" height="50px">
                    <el-button v-if="currentRow.status < 2" size="small" @click="openAssign">指派维修人员</el-button>
                    <!-- <el-button size="small" @click="openAssign">完成维修</el-button> -->
                </el-footer>
            </el-container>
        </el-drawer>
        <el-dialog title="指派维修人员" :visible.sync="openAssignDialogVisible" width="500px">
            <el-form :model="assignForm" ref="assignForm" size="small" label-width="100px">
                <el-form-item label="维修人员" :rules="[
            { required: true, message: '请选择维修人员', trigger: 'blur' },
          ]" prop="repair_user_id">
                    <model-search-select class="dis-b w-100p" v-model="assignForm.repair_user_id" format="{name}_{deptName}_{postName}" :params="{ tenantId: '000000' }" :fillModel.sync="assignForm"
                                         :fills="[{ source: 'name', target: 'repair_user_name' }]">
                        <template slot-scope="{ item }">
                            <span style="float: left">{{ item.name
                }}{{ item.postName ? `(${item.postName})` : "" }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.deptName
              }}</span>
                        </template>
                    </model-search-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button :loading="saveBtn" size="small" @click="openAssignDialogVisible = false">取 消</el-button>
                <el-button :loading="saveBtn" size="small" type="primary" @click="saveForm(assignForm, 'assignForm', 'openAssignDialogVisible')">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="回访" :visible.sync="openVisitDialogVisible" width="500px">
            <el-form v-if="openVisitDialogVisible" :model="visitForm" :rules="visitRules" ref="visitForm" size="small" label-width="100px">
                <el-form-item label="回访人" prop="repair_user_id">
                    <model-search-select class="dis-b w-100p" v-model="visitForm.return_visit_user_id" format="{name}_{deptName}_{postName}" :fillModel.sync="visitForm"
                                         :fills="[{ source: 'name', target: 'return_visit_user_name' }]">
                        <template slot-scope="{ item }">
                            <span style="float: left">{{ item.name
                }}{{ item.postName ? `(${item.postName})` : "" }}</span>
                            <span style="float: right; color: #8492a6; font-size: 13px">{{
                item.deptName
              }}</span>
                        </template>
                    </model-search-select>
                </el-form-item>
                <el-form-item label="回访人电话" prop="return_visit_user_phone">
                    <el-input v-model="visitForm.return_visit_user_phone" placeholder="输入回访人电话"></el-input>
                </el-form-item>
                <el-form-item label="回访反馈" prop="return_visit_content">
                    <el-input rows="5" type="textarea" placeholder="输入回访反馈" v-model="visitForm.return_visit_content"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button :loading="saveBtn" size="small" @click="openVisitDialogVisible = false">取 消</el-button>
                <el-button :loading="saveBtn" size="small" type="primary" @click="saveVisitForm()">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { saveMetaCommon, updateMetaCommon, getMetaCommonList, delMetaCommon, sendMsg } from '@/api/busiMode/metaCommon';
import * as dictService from '@/api/system/dictbiz';
import RepairDetail from './repair-detail.vue';
export default {
    components: { RepairDetail },
    data() {
        return {
            openAssignDialogVisible: false,
            openVisitDialogVisible: false,
            form: {},
            visitForm: {
                return_visit_content: '',
                repair_id: '',
                return_visit_user_name: '',
                return_visit_user_id: '',
                return_visit_user_phone: '',
            },
            visitRules: {
                return_visit_user_id: [{ required: true, message: '请选择回访人', trigger: 'blur' }],
                return_visit_user_phone: [{ required: true, message: '请输入回访人电话', trigger: 'blur' }],
                return_visit_content: [{ required: true, message: '请输入回访反馈', trigger: 'blur' }],
            },
            assignForm: {
                repair_user_id: '',
                repair_user_name: '',
                status: 1,
            },
            saveBtn: false,
            drawer: false,
            currentRow: null,
            tabs: [],
            init: false,
            activeName: 'all',
            model: 'wisdom_park.wp_repair',
            detailModel: 'wisdom_park.wp_repair_operation_log',
            tableLoading: false,
            tableData: [],
            search: {},
            page: {
                pageSizes: this.$store.state.common.pageSizes,
                pagerCount: this.$store.state.common.pagerCount,
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            tableOption: {
                height: 'auto',
                labelPosition: 'top',
                searchMenuPosition: 'right',
                searchLabelPosition: 'right',
                searchMenuSpan: 24,
                border: true,
                addBtn: false,
                editBtn: false,
                viewBtn: false,
                delBtn: false,
                menuWidth: 160,
                column: [
                    {
                        label: '报修类型',
                        prop: 'repair_type',
                        type: 'select',
                        dicUrl: dictService.getUrl('RepairServerType'),
                        props: {
                            label: 'dictValue',
                            value: 'dictKey',
                        },
                        dataType: 'string',
                    },
                    {
                        label: '报修位置',
                        prop: 'location_name',
                    },
                    {
                        label: '申请人',
                        prop: 'applicant_name',
                    },
                    {
                        label: '联系方式',
                        prop: 'applicant_phone',
                    },
                    {
                        label: '预约时间',
                        prop: 'applicant_time',
                        width: 150,
                    },
                    {
                        label: '描述',
                        prop: 'repair_desc',
                    },
                    {
                        label: '维修人员',
                        prop: 'repair_user_name',
                    },
                    {
                        label: '状态',
                        prop: 'status',
                        type: 'select',
                        dicUrl: dictService.getUrl('RepairServiceStatus'),
                        props: {
                            label: 'dictValue',
                            value: 'dictKey',
                        },
                        dataType: 'string',
                    },
                ],
            },
        };
    },
    mounted() {
        dictService
            .getDictionary({
                code: 'RepairServiceStatus',
            })
            .then(res => {
                this.tabs = [{ dictValue: '全部', dictKey: 'all' }].concat(res.data.data || []);
                this.$nextTick(() => {
                    this.init = true;
                });
            })
            .catch(e => {
                this.init = true;
            });
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                returnVisit: this.vaildData(this.permission.returnVisit, false),
            };
        },
    },
    methods: {
        gotoForm(row, type) {
            this.currentRow = row;
            this.drawer = true;
        },
        visit(row) {
            this.visitForm.return_visit_user_id = row.applicant_id;
            this.visitForm.return_visit_user_name = row.applicant_name;
            this.visitForm.return_visit_user_phone = row.applicant_phone;
            this.visitForm.repair_id = row.id;
            this.visitForm.return_visit_content = '';
            this.openVisitDialogVisible = true;
        },
        tabsHandleClick() {
            this.page.currentPage = 1;
            this.onLoad();
        },
        onLoad() {
            let wheres = [];
            if (this.activeName !== 'all') {
                wheres.push({ field: 'status', value: this.activeName });
            }
            this.tableData = [];
            this.tableLoading = true;
            getMetaCommonList(this.model, {
                current: this.page.currentPage,
                size: this.page.pageSize,
                wheres: wheres,
                orders: [{ field: 'applicant_time', sort: 'DESC' }],
            })
                .then(res => {
                    this.tableLoading = false;
                    if (res.data && res.data.code === 200) {
                        this.tableData = res.data.data.records;
                        this.page.total = res.data.data.total;
                    } else {
                        this.tableData = [];
                        this.page.total = 0;
                    }
                })
                .catch(e => {
                    this.tableLoading = false;
                });
        },
        openAssign() {
            this.assignForm.repair_user_id = this.currentRow.repair_user_id;
            this.assignForm.repair_user_name = this.currentRow.repair_user_name;
            this.assignForm.status = 1;
            this.openAssignDialogVisible = true;
        },
        saveVisitForm() {
            // wp_repair_return_visit
            this.saveBtn = true;
            this.$refs['visitForm'].validate(valid => {
                if (valid) {
                    saveMetaCommon('wisdom_park.wp_repair_return_visit', {
                        values: {
                            ...this.visitForm,
                        },
                    }).then(res => {
                        updateMetaCommon(this.model, {
                            update: {
                                status: 4,
                            },
                            primaryKeyValue: this.visitForm.repair_id,
                            primaryKey: 'id',
                        }).then(res => {
                            this.$message.success('操作成功');
                            this.openVisitDialogVisible = false;
                            this.saveBtn = false;
                            this.onLoad();
                        });
                    });
                }
            });
        },
        saveForm(form, refName, modelName) {
            this.$refs[refName].validate(valid => {
                if (valid) {
                    this.saveBtn = true;
                    updateMetaCommon(this.model, {
                        update: form,
                        primaryKeyValue: this.currentRow.id,
                        primaryKey: 'id',
                    }).then(res => {
                        saveMetaCommon(this.detailModel, {
                            values: {
                                operation_type: 2,
                                operation_remark: `已指派维修人员：${form.repair_user_name}`,
                                create_user_name: form.repair_user_name,
                                create_user: form.repair_user_id,
                                repair_id: this.currentRow.id,
                            },
                        }).then(res => {
                            sendMsg({
                                type: 3,
                                bizId: res.data.data.id,
                            }).then(res => {
                                this[modelName] = false;
                                this.$message.success('操作成功');
                                this.saveBtn = false;
                                this.onLoad();
                            });
                        });
                    });
                }
            });
        },
    },
};
</script>
<style lang="scss" scoped>
.manageDetail {
    /deep/.el-drawer__header {
        margin-bottom: 0;
    }
    /deep/.el-drawer__body {
        padding-top: 0;
    }
}
</style>

<template>
  <el-row>
    <el-col :span="24">
      <basic-container>
        <avue-crud
          ref="crud"
          :option="option"
          :search.sync="search"
          :data="list"
          :page="page"
          @row-save="rowSave"
          @row-del="rowDel"
          @row-update="rowUpdate"
        >
          <!-- <template slot-scope="scope" slot="menuLeft">
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="small"
              plain
              @click.stop="chooseModelbox = true"
              >创建表格</el-button
            >
          </template> -->
          <!-- <template slot-scope="{ type, size, row }" slot="menu">
            <el-button
              @click.stop="handleEdit(row)"
              icon="el-icon-edit"
              :size="size"
              :type="type"
              >编辑</el-button
            >
            <el-button
              @click.stop="rowDel(row)"
              icon="el-icon-delete"
              :size="size"
              :type="type"
              >删除</el-button
            >
          </template> -->
        </avue-crud>
      </basic-container>
    </el-col>
  </el-row>
</template>
<script>
import {
  getPlanList,
  updatePlan,
  removePlan,
  addPlan,
} from "@/api/busiMode/meta";
export default {
  name: "tableList",
  components: {},
  data() {
    return {
      chooseModelbox: false,
      option: {
        refreshBtn: true,
        sortable: true,
        menu: true, //操作菜单栏
        columnBtn: false, //列显隐
        column: [
          {
            label: "方案名称",
            prop: "name",
          },
          {
            label: "方案编码",
            prop: "code",
          },

          {
            label: "方案模版",
            prop: "isDefault",
          },
          {
            label: "备注",
            prop: "note",
          },
        ],
      },
      list: [],
      page: {
        pageSizes: [10, 20, 30, 40], //默认
        currentPage: 1,
        total: 10,
        pageSize: 10,
      },
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      getPlanList().then((res) => {
        this.list = res.data.data.records;
      });
    },
    nodeClick(data) {
      console.log(data);
    },
    rowDel(row, index) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          removePlan(row.id).then(() => {
            this.list.splice(index, 1, row);
          });
        })
        .catch(() => {});
    },
    rowUpdate(row, index, done) {
      updatePlan(row).then(() => {
        this.list.splice(index, 1, row);
        done();
      });
    },
    rowSave(row, done) {
      addPlan(row).then(() => {
        this.$message.success("新增成功");
        this.initData;
        done();
      });
    },
  },
};
</script>
<style scoped></style>

<template>
    <basic-container isCard>
        <el-row>
            <el-col :span="3" class="title pad-t-5 pad-l-0 pad-r-0">
                <div class="fts-18">{{ gradenObj.name }}</div>
                <div class="mar-t-5">{{ gradenObj.gardenAdress }}</div>
            </el-col>
            <el-col :span="9" class="text-center title o-a">
                <div class="lygk">

                    <div class="bnd">楼宇概况</div>

                    <div class="w-80">
                        <div class="thsj-tj">{{ graden.num }}</div>
                        <span class="text-title">楼栋数</span>
                    </div>
                    <div class="w-80">
                        <div class="thsj-tj">{{ graden.floorage }}</div>
                        <span class="text-title">建筑面积(m²)</span>
                    </div>

                    <div class="w-120">
                        <div class="thsj-tj">{{ graden.sum_room_area_yz }}</div>
                        <span class="text-title">出租面积(m²)</span>
                    </div>
                    <div class="w-120">
                        <div class="thsj-tj">{{ graden.sum_room_area_wz }}</div>
                        <span class="text-title">空置面积(m²)</span>
                    </div>
                    <div class="w-120">
                        <div class="thsj-tj">{{ graden.sum_room_area_ys }}</div>
                        <span class="text-title">已售面积(m²)</span>
                    </div>
                    <div class="w-120">
                        <div class="thsj-tj">{{ graden.sum_room_area_zy }}</div>
                        <span class="text-title">自用面积(m²)</span>
                    </div>
                    <div class="w-80">
                        <div class="thsj-tj">{{ graden.sum_checkin_num }}</div>
                        <span class="text-title">入驻企业数</span>
                    </div>
                    <div class="w-80">
                        <div class="thsj-tj">{{getRZL(graden)}}</div>
                        <span class="text-title">企业入驻率</span>
                    </div>
                </div>
            </el-col>
            <el-col :span="7" class="title text-center thsj pad-10 pad-t-15">
                <div class="bnd">本年累计</div>
                <el-col :span="8">
                    <div class="thsj-tj">{{ amountSumObj.current_amount || "-"
          }}</div>
                    <span class="text-title">总收入(万元)</span>
                </el-col>
                <el-col :span="8" class="pad-l-0 pad-r-0">
                    <div class="thsj-tj">{{ amountSumObj.current_rent_amount || "-" }}</div>
                    <span class="text-title">租金费收入(万元)</span>
                </el-col>
                <el-col :span="8">
                    <div class="thsj-tj">{{ amountSumObj.current_other_amount  || "-"
          }}</div>
                    <span class="text-title">其他收入(万元)</span>
                </el-col>
            </el-col>
            <el-col :span="5" class="text-center thsj pad-10">
                <div class="bnd">能源使用</div>
                <el-col :span="8">
                    <div class="thsj-tj">{{ d3.electric_year || "0" }}</div>
                    <span class="text-title">本年度用电量(万度)</span>
                </el-col>
                <el-col :span="8">
                    <div class="thsj-tj">{{ d3.water_year || "0" }}</div>
                    <span class="text-title">本年度用水量(吨)</span>
                </el-col>
                <el-col :span="8">
                    <div class="thsj-tj">{{ d3.heating_year || "0" }}</div>
                    <span class="text-title">本年度用暖量(吉焦)</span>
                </el-col>
            </el-col>
        </el-row>
    </basic-container>
</template>

<script>
import { getMetaCommonList, delMetaCommon } from '@/api/busiMode/metaCommon';
import website from '@/config/website';

export default {
    components: {},
    data() {
        return {
            gradenObj: {
                name: website.gardenName,
                gardenAdress: website.gardenAdress,
            },
            graden: {
                area: 98000,
                ds: 9,
                czmj: 56900,
                rooms: 1908,
                ruzys: 80,
                ydl: 2000,
                ysl: 12900,
                jnjp: 100,
            },
            amountSumObj: {
                zzb: '0',
                renZzb: '0',
                otheZzb: '0',
            },
            d3: {},
            model: 'wisdom_park.v_wp_park_stat',
            amount_sum: 'wisdom_park.v_rpt_curyear_amount_sum',
        };
    },
    props: {},
    watch: {},
    computed: {},
    mounted() {
        this.onLoad();
    },
    methods: {
        getRZL(graden) {
            if (graden.sum_room_area_yz && graden.floorage) {
                return ((graden.sum_room_area_yz / graden.floorage) * 100).toFixed(2) + '%';
            } else {
                return '-';
            }
        },
        onLoad() {
            getMetaCommonList(this.model, {
                current: 1,
                size: 500,
            }).then(res => {
                const data = res.data.data;
                this.graden = data.records[0];
                if (this.graden.sum_free_area) {
                    this.graden.sum_free_area = this.graden.sum_free_area.toFixed(2);
                }
                if (this.graden.sum_checkin_area) {
                    this.graden.sum_checkin_area = this.graden.sum_checkin_area.toFixed(2);
                }
                if (this.graden.sum_floor_area) {
                    this.graden.sum_floor_area = this.graden.sum_floor_area.toFixed(2);
                }
            });
            getMetaCommonList(this.amount_sum, {
                current: 1,
                size: 500,
            }).then(res => {
                this.amountSumObj = res.data.data.records[0];
                this.amountSumObj.zzb = (
                    (this.amountSumObj.current_amount * 100) /
                    (this.amountSumObj.previous_amount * 100)
                ).toFixed(2);
                this.amountSumObj.renZzb = (
                    (this.amountSumObj.current_rent_amount * 100) /
                    (this.amountSumObj.previous_rent_amount * 100)
                ).toFixed(2);
                this.amountSumObj.otheZzb = (
                    (this.amountSumObj.current_other_amount * 100) /
                    (this.amountSumObj.previous_other_amount * 100)
                ).toFixed(2);
            });
            getMetaCommonList('wisdom_park.v_jsc_wp_energy_consumption_stat', {
                current: 1,
                size: 500,
            }).then(res => {
                console.log(res);
                this.d3 = res.data.data.records[0];
            });
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.thsj-tj {
    font-size: 18px;
}
.text-title {
    margin-top: 6px;
    color: rgba(0, 0, 0, 0.5);
    display: block;
}
.title {
    border-right: 1px solid rgba(0, 0, 0, 0.1);
    padding: 15px 10px 0px 10px;
}
.bnd {
    position: absolute;
    top: -9px;
    color: rgba(0, 0, 0, 0.5);
}
.w-120 {
    width: 120px;
    float: left;
}
.w-80 {
    float: left;
}
.lygk {
    width: 100%;
    width: 800px;
    overflow: auto;
    display: flex;
}
.o-a {
    overflow: auto;
}
</style>

<template>
  <div class="avue-contail" :class="{ 'avue--collapse': isCollapse }">
    <div
      class="avue-header"
      :class="{ 'avue-left-slide-hide': showMenus === false }"
    >
      <!-- 顶部导航栏 -->
      <top ref="top" :showMenus="showMenus" />
    </div>
    <div class="avue-layout">
      <div class="avue-left" v-if="showMenus && menuMode !== 'top'">
        <!-- 左侧导航栏 -->
        <sidebar />
      </div>
      <div
        class="avue-main"
        :class="{
          'max-avue-main':
            menuMode === 'top' || (menuMode !== 'top' && showMenus === false),
        }"
      >
        <!-- 顶部标签卡 -->
        <tags v-if="showTag" />
        <el-row
          v-if="showBreadcrumb"
          :class="'mar-b-' + breadcrumbMarginBottom"
        >
          <el-col :span="24 - breadcrumbRightCol" class="mar-b-0">
            <el-breadcrumb separator="/">
              <template v-for="(item, index) in breadcrumbs">
                <el-breadcrumb-item :key="index">
                  <span v-if="!item.path">{{ item.name }}</span>
                  <a
                    v-if="item.path"
                    href="javascript:void(0)"
                    @click="breadcrumbClick(item, index)"
                    >{{ item.name }}</a
                  >
                </el-breadcrumb-item>
              </template>
            </el-breadcrumb>
          </el-col>
          <el-col
            v-if="breadcrumbRightComponent"
            :span="breadcrumbRightCol"
            class="mar-b-0"
          >
            <component :is="breadcrumbRightComponent"></component>
          </el-col>
        </el-row>
        <!-- <transition name="fade-scale">
          <search class="avue-view" v-show="isSearch"></search>
        </transition> -->
        <!-- 主体视图层 -->
        <div class="ov-x-h ov-y-a mar-t-15 h-view" id="avue-view">
          <keep-alive>
            <router-view
              class="avue-view"
              :key="routerKey"
              v-if="$route.meta.keepAlive"
            />
          </keep-alive>
          <router-view
            class="avue-view"
            :key="routerKey"
            v-if="!$route.meta.keepAlive"
          />
        </div>
      </div>
    </div>
    <div class="avue-shade" @click="showCollapse"></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import tags from "./tags";
import search from "./search";
import top from "./top/";
import sidebar from "./sidebar/";
import admin from "@/util/admin";
import { validatenull } from "@/util/validate";
import { calcDate } from "@/util/date.js";
import { getStore } from "@/util/store.js";
// import SchedulerNav from '@/view-module/scheduler/common/components/nav/nav';
export default {
  components: {
    top,
    tags,
    search,
    sidebar,
  },
  name: "pageIndex",
  provide() {
    return {
      indexMain: this,
    };
  },
  data() {
    return {
      back: !!this.$route.query.back,
      //搜索控制
      isSearch: false,
      //刷新token锁
      refreshLock: false,
      //刷新token的时间
      refreshTime: "",
      breadcrumbs: this.$breadcrumb.breadcrumbs,
      breadcrumbRightComponent: null,
      breadcrumbRightCol: 0,
      breadcrumbMarginBottom: 0,
      showMenus: true,
      routerKey: this.$router.currentRoute.fullPath,
      //menuMode: this.menu.menuType,
    };
  },
  created() {
    if (this.menuMode === "top") {
      this.openMenu({});
    }
    //实时检测刷新token
    this.refreshToken();
  },
  watch: {
    $route() {
      this.routerKey = this.$router.currentRoute.fullPath;
      this.back = !!this.$route.query.back;
    },
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters([
      "menuMode",
      "isMenu",
      "isLock",
      "isCollapse",
      "website",
      "showTag",
      "menu",
      "showBreadcrumb",
      "sidebarMenu",
    ]),
    // breadcrumbs(){
    //   return this.$breadcrumb.breadcrumbs;
    // }
  },
  props: [],
  methods: {
    goBack() {
      this.$router.back();
    },
    showCollapse() {
      this.$store.commit("SET_COLLAPSE");
    },
    // 初始化
    init() {
      this.$store.commit("SET_SCREEN", admin.getScreen());
      window.onresize = () => {
        setTimeout(() => {
          this.$store.commit("SET_SCREEN", admin.getScreen());
        }, 0);
      };
      this.$store.dispatch("FlowRoutes").then(() => {});
      this.$root.$on("SetBreadcrumbComponent", (data) => {
        if (data) {
          this.breadcrumbRightComponent = data.component || null;
          this.breadcrumbRightCol = data.col !== undefined ? data.col : 8;
          this.breadcrumbMarginBottom =
            data.breadcrumbMarginBottom !== undefined
              ? data.breadcrumbMarginBottom
              : 0;
        } else {
          this.breadcrumbRightComponent = null;
          this.breadcrumbRightCol = 0;
          this.breadcrumbMarginBottom = 0;
        }
      });
    },
    breadcrumbClick(item, index) {
      if (item.path) {
        if (index < this.breadcrumbs.length - 1) {
          this.$router.push(item.path);
        }
        this.$breadcrumb.remove(index + 1, true);
      }
    },
    //打开菜单
    openMenu(item = {}) {
      this.$store.dispatch("GetMenu", item.id).then((data) => {
        if (data.length !== 0) {
          this.$router.$avueRouter.formatRoutes(data, true);
          this.$store.commit("SET_MENU_LOAD", true);
        }
        //当点击顶部菜单后默认打开第一个菜单
        if (!this.validatenull(item)) {
          let itemActive = {},
            childItemActive = 0;
          if (item.path) {
            itemActive = item;
          } else {
            if (this.menu[childItemActive].length === 0) {
              itemActive = this.menu[childItemActive];
            } else {
              itemActive = this.menu[childItemActive].children[childItemActive];
            }
          }
          this.$store.commit("SET_MENU_ID", item);
          this.$router.push({
            path: this.$router.$avueRouter.getPath(
              {
                name: itemActive.label || itemActive.name,
                src: itemActive.path,
              },
              itemActive.meta
            ),
          });
        }
      });
    },
    // 定时检测token
    refreshToken() {
      this.refreshTime = setInterval(() => {
        const token =
          getStore({
            name: "token",
            debug: true,
          }) || {};
        const date = calcDate(token.datetime, new Date().getTime());
        if (validatenull(date)) return;
        if (date.seconds >= this.website.tokenTime && !this.refreshLock) {
          this.refreshLock = true;
          this.$store
            .dispatch("refreshToken")
            .then(() => {
              this.refreshLock = false;
            })
            .catch(() => {
              this.refreshLock = false;
            });
        }
      }, this.website.refreshToken);
    },
  },
};
</script>
<style lang="scss" scoped>
.el-breadcrumb {
  padding: 20px 5px;
  // background: #fff;
  background: transparent;
}
.h-view {
  height: calc(100vh - 115px);
}
.max-avue-main {
  width: 100% !important;
  left: 0;
}
</style>

// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./imgs/update.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./imgs/api.png");
var ___CSS_LOADER_URL_IMPORT_2___ = require("./imgs/java.png");
var ___CSS_LOADER_URL_IMPORT_3___ = require("./imgs/kafka.png");
var ___CSS_LOADER_URL_IMPORT_4___ = require("./imgs/process.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
// Module
exports.push([module.id, ".bpmn-icon-update{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")}.bpmn-icon-api{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")}.bpmn-icon-java{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}.bpmn-icon-kafka{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")}.bpmn-icon-process{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")}.icon-custom{display:block;background-size:55%;background-repeat:no-repeat;background-position:50%;width:100%}", ""]);
// Exports
module.exports = exports;

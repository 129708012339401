<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" :page.sync="page" :before-open="beforeOpen" :upload-after="uploadAfter" v-model="form" ref="crud" @row-update="rowUpdate"
                   @row-save="rowSave" @row-del="rowDel" @search-change="searchChange" @search-reset="searchReset" @current-change="currentChange" @size-change="sizeChange"
                   @refresh-change="refreshChange" @on-load="onLoad">
            <template slot="menuLeft">
                <el-button type="danger" size="small" icon="el-icon-delete" plain v-if="permission.epmallitem_delete" @click="handleDelete">删 除
                </el-button>
            </template>
            <!-- 修改状态 -->
            <template slot-scope="scope" slot="menu">
                <template v-if="scope.row.status == 1">
                    <el-button icon="el-icon-download" size="small" type="text" class="none-border" @click="updateState(scope.row)">下架</el-button>
                </template>

                <template v-if="scope.row.status == 0">
                    <el-button type="text" size="small" icon="el-icon-edit" class="none-border" @click="handleEdit(scope.row, scope.index)">编辑
                    </el-button>
                    <el-button type="text" size="small" icon="el-icon-delete-solid" class="none-border" @click="doCheckRemove(scope.row.id)">删除
                    </el-button>
                    <el-button icon="el-icon-upload2" size="small" type="text" class="none-border" @click="updateState(scope.row)">上架</el-button>
                </template>
            </template>
        </avue-crud>
    </basic-container>
</template>

<script>
import { getList, getDetail, add, update, remove, getPage, toogleStatus } from '@/api/epmall/item';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            form: {},
            query: {},
            loading: true,
            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            selectionList: [],
            option: {
                height: 'auto',
                calcHeight: 30,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: true,
                viewBtn: true,
                dialogClickModal: false,
                editBtn: false,
                delBtn: false,
                column: [
                    {
                        label: 'SKU',
                        prop: 'sku',
                        hide: true,
                        addDisplay: false,
                        editDisplay: false,
                        viewDisplay: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入SKU',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '菜品',
                        prop: 'title',
                        with: 120,
                        span: 12,
                        search: true,
                        searchSpan: 5,
                        rules: [
                            {
                                required: true,
                                message: '请输入菜品',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '店铺',
                        prop: 'storeId',
                        type: 'select',
                        search: true,
                        searchSpan: 4,
                        editDisplay: false,
                        with: 40,
                        dicUrl: '/api/park-epmall/store/list?current=1&size=10000000',
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                        dicFormatter: res => {
                            let ds = res.data.records;
                            // ds.forEach(e=>{
                            //   e.id = Number(e.id);
                            // });
                            return ds;
                        },
                        rules: [
                            {
                                required: true,
                                message: '请输入店铺Id',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '菜品卖点',
                        prop: 'sellPoint',
                        with: 240,
                        span: 24,
                        rules: [
                            {
                                required: true,
                                message: '请输入菜品卖点',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '菜品价格',
                        prop: 'price',
                        with: 40,
                        rules: [
                            {
                                required: true,
                                message: '请输入菜品价格',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '菜品原价',
                        prop: 'orgiPrice',
                        with: 40,
                        rules: [
                            {
                                required: true,
                                message: '请输入菜品原价',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '库存数量',
                        prop: 'num',
                        hide: true,
                        addDisplay: false,
                        editDisplay: false,
                        viewDisplay: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入库存数量',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '销售限制',
                        prop: 'limitNum',
                        hide: true,
                        addDisplay: false,
                        editDisplay: false,
                        viewDisplay: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入销售限制',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '菜品描述',
                        prop: 'itemDesc',
                        hide: true,
                        component: 'RichText',
                        span: 24,
                        minRows: 6,
                        rules: [
                            {
                                required: true,
                                message: '请输入内容，图文混编',
                                trigger: 'blur',
                            },
                        ],
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                    },
                    {
                        label: '菜品图标',
                        prop: 'pics',
                        listType: 'picture-card',
                        hide: true,
                        propsHttp: {
                            res: 'data',
                            url: 'link',
                            name: 'originalName',
                        },
                        type: 'upload',
                        tip: '只能上传jpg/png的图片，且不超过500kb',
                        action: '/api/jida-resource/oss/endpoint/put-file-attach',
                        span: 24,
                        rules: [
                            {
                                required: true,
                                message: '请上传图片',
                                trigger: 'change',
                            },
                        ],
                    },
                    {
                        label: '创建时间',
                        prop: 'created',
                        hide: true,
                        addDisplay: false,
                        editDisplay: false,
                        viewDisplay: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入创建时间',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '更新时间',
                        prop: 'updated',
                        hide: true,
                        addDisplay: false,
                        editDisplay: false,
                        viewDisplay: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入更新时间',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '状态',
                        prop: 'status',
                        search: true,
                        searchSpan: 4,
                        addDisplay: false,
                        editDisplay: false,
                        viewDisplay: false,
                        hide: false,
                        type: 'select',
                        dicData: [
                            {
                                label: '上架',
                                value: '1',
                            },
                            {
                                label: '下架',
                                value: '0',
                            },
                        ],
                        rules: [
                            {
                                required: true,
                                message: '请输入状态,1=正常,0=下线',
                                trigger: 'blur',
                            },
                        ],
                    },
                ],
            },
            data: [],
        };
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.epmallitem_add, false),
                viewBtn: this.vaildData(this.permission.epmallitem_view, false),
                delBtn: this.vaildData(this.permission.epmallitem_delete, false),
                editBtn: this.vaildData(this.permission.epmallitem_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    methods: {
        operEdit(id) {
            this.rowUpdate();
        },
        handleEdit(row, index) {
            this.$refs.crud.rowEdit(row, index);
        },
        doCheckRemove(id) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(id);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    this.$refs.crud.toggleSelection();
                });
        },
        updateState(row) {
            this.$confirm('确定修改状态吗?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return toogleStatus(row.id);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
        rowSave(row, done, loading) {
            let imgList = [];
            row.pics.forEach(p => {
                imgList.push(p.value);
            });
            row.image = imgList.join(';');
            add(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    window.console.log(error);
                },
            );
        },
        rowUpdate(row, index, done, loading) {
            let imgList = [];
            row.pics.forEach(p => {
                imgList.push(p.value);
            });
            row.image = imgList.join(';');
            update(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    console.log(error);
                },
            );
        },
        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(row.id);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning('请选择至少一条数据');
                return;
            }
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(this.ids);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    this.$refs.crud.toggleSelection();
                });
        },
        beforeOpen(done, type) {
            if (['edit', 'view'].includes(type)) {
                getDetail(this.form.id).then(res => {
                    this.form = res.data.data;
                    this.form.storeId = this.form.storeId + '';
                    let pics = [];
                    let imgList = res.data.data.image.split(';');
                    imgList.forEach(i => {
                        let obj = {
                            label: 'NoName',
                            value: i,
                        };
                        pics.push(obj);
                    });
                    this.form.pics = pics;
                });
            }
            done();
        },
        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },
        searchChange(params, done) {
            this.query = params;
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
            done();
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        currentChange(currentPage) {
            this.page.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        onLoad(page, params = {}) {
            this.loading = true;
            getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                data.records.forEach(r => {
                    r.storeId = r.storeId + '';
                });
                this.data = data.records;
                this.loading = false;
                this.selectionClear();
                //console.log(this.data.length);
            });
        },
        uploadAfter(res, done, loading, column) {
            done();
            this.$message.success('上传成功');
        },
    },
};
</script>

<style src="@wangeditor/editor/dist/css/style.css"></style>

<style>
/* 上方引入富文本编辑器样式 */
</style>

<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      ref="crud"
      :page.sync="page"
      @row-del="rowDel"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot="menuLeft" slot-scope="scope">
        <el-button
          type="primary"
          size="small"
          icon="el-icon-plus"
          @click="goDetail('add')"
          >制作节目
        </el-button>

        <!-- <el-button
          size="small"
          icon="el-icon-upload2"
          @click="importantMater"
          type="primary"
          >从素材库导入</el-button
        > -->
        <el-input
          v-model="searchText"
          @keyup.native.enter="refreshChange"
          size="small"
          placeholder="请输入节目名称"
          type="text"
          style="width: 250px"
        >
          <el-button
            class="mar-0 pad-0"
            size="small"
            @click="refreshChange"
            slot="append"
            icon="el-icon-search"
          ></el-button>
        </el-input>
      </template>
      <template slot-scope="scope" slot="menu">
        <el-button
          type="text"
          size="small"
          class="none-border"
          icon="el-icon-view"
          @click="goDetail('view', scope.row)"
          >查看
        </el-button>
        <el-button
          type="text"
          size="small"
          class="none-border"
          icon="el-icon-edit"
          @click="goDetail('update', scope.row)"
          >修改
        </el-button>
        <el-button
          type="text"
          size="small"
          icon="el-icon-delete"
          class="none-border"
          @click="rowDel(scope.row)"
          >删除
        </el-button>
      </template>
    </avue-crud>
    <el-dialog
      size="60%"
      :visible.sync="dialogVisible"
      destroy-on-close
      :with-header="false"
      :top="'8vh'"
      :body-style="{ padding: '0px' }"
      width="70%"
      class="buildDetail"
    >
      <template v-if="dialogVisible">
        <div slot="title">
          <span class="fts-16">素材导入</span>
        </div>
        <div>
          <material :isSelected="`selected`" @selection="selection"></material>
        </div>
        <div class="btnClass bor-t">
          <el-button
            size="small"
            type=""
            class="pull-right mar-t-10"
            @click="dialogVisible = false"
            >取消</el-button
          >
          <el-button
            size="small"
            class="pull-right mar-t-10 mar-r-10"
            type="primary"
            @click="saveMaterial"
            >确定</el-button
          >
        </div>
      </template>
    </el-dialog>
  </basic-container>
</template>

<script>
import {
  getMetaCommonList,
  delMetaCommon,
  saveMetaCommon,
} from "@/api/busiMode/metaCommon";
import { mapGetters } from "vuex";
import material from "./material";

export default {
  components: { material },

  data() {
    return {
      searchText: "",
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      loading: false,
      dialogVisible: false,
      selectionList: [],
      option: {
        height: "auto",
        calcHeight: 30,
        dialogWidth: 900,
        tip: false,
        searchShow: true,
        searchMenuSpan: 6,
        border: true,
        index: true,
        // selection: true,
        labelWidth: 120,
        menuWidth: 0,
        viewBtn: false,
        dialogClickModal: false,
        delBtn: false,
        editBtn: false,
        addBtn: false,
        cancelBtn: false,
        column: [
          {
            width: 150,
            overHidden: true,
            align: "left",
            label: "节目名称",
            prop: "name",
            hide: false,
          },
          {
            width: 120,
            overHidden: true,
            align: "left",
            label: "别名",
            prop: "alias",
            hide: false,
          },

          {
            width: 120,
            overHidden: true,
            align: "left",
            label: "节目类型",
            prop: "program_type",

            dicData: [
              {
                value: "picture",
                label: "图片",
              },
              {
                value: "flash",
                label: "Flash",
              },
              {
                value: "audio",
                label: "音频",
              },
              {
                value: "video",
                label: "视频",
              },
              {
                value: "document",
                label: "文本",
              },
              {
                value: "pdf",
                label: "PDF",
              },
              {
                value: "web",
                label: "网页",
              },
              {
                value: "doc-Word",
                label: "文档",
              },
              {
                value: "ppt",
                label: "PPT",
              },
              {
                value: "web",
                label: "网页",
              },
              {
                value: "realStream",
                label: "视频直播",
              },
              {
                value: "dataSource",
                label: "通用节目",
              },
              {
                value: "doc-Word",
                label: "文档",
              },
            ],
          },
          // {
          //   width: 220,
          //   overHidden: true,
          //   align: "left",
          //   label: "节目地址",
          //   prop: "file_url",
          //   hide: false,
          // },
          {
            width: 160,
            overHidden: true,
            align: "left",
            label: "信息类别",
            prop: "category_name",
            hide: false,
          },
          {
            width: 120,
            overHidden: true,
            label: "创建人",
            prop: "create_user",
          },
          {
            width: 150,
            overHidden: true,
            label: "创建时间",
            prop: "create_time",
            hide: false,
          },
        ],
      },
      model: "wisdom_park.wp_publish_program",
      materialModel: "wisdom_park.wp_publish_material",
      materials: [],
      data: [],

      ruleModel: {},
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.code_add, false),
        viewBtn: this.vaildData(this.permission.code_view, false),
        delBtn: this.vaildData(this.permission.code_delete, false),
        editBtn: this.vaildData(this.permission.code_edit, false),
      };
    },
  },
  mounted() {},
  methods: {
    rowDel(row, index) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let pk = "id";
          delMetaCommon(this.model, { pk: pk, ids: [row[pk]] }).then((res) => {
            this.$message({
              type: "success",
              message: "操作成功!",
            });
            this.onLoad(this.page);
          });
        })
        .catch(() => {});
    },

    saveMaterial() {
      let obj = {};
      Object.keys(this.selectionList[0]).forEach((ele) => {
        if (!ele.includes("$")) {
          obj[ele] = this.selectionList[0][ele];
        }
      });

      delete obj.id;
      obj.program_type = obj.material_type;
      delete obj.material_type;

      saveMetaCommon(this.model, {
        values: obj,
      }).then((res) => {
        if (res.data.code == 200) {
          this.dialogVisible = false;
          this.onLoad();
        }
      });
    },
    goDetail(type, row) {
      if (row) {
        this.$router.push({
          path: "/information/programForm",
          query: { equipment: row.id, type: type },
        });
      } else {
        this.$router.push({
          path: "/information/programForm",
        });
      }
    },
    selection(val) {
      this.selectionList = val;
    },
    refreshChange() {
      this.onLoad(this.page);
    },
    importantMater() {
      this.dialogVisible = true;
      this.getMetaCommonListBymaterialModel();
    },
    getMetaCommonListBymaterialModel() {
      let body = {
        current: 1,
        size: 500,
      };
      getMetaCommonList(this.materialModel, body).then((res) => {
        if (res.data.code == 200) {
          const data = res.data.data;
          let ids = [];
          //   data.records.forEach((e) => {
          //     ids.push()
          //   });
          this.materials = data.records;
        }
      });
    },
    getpicList(ids) {
      getPicListByIds(ids).then((res) => {
        if (res.data.code == 200) {
          let arr = [];
          res.data.data.forEach((pic) => {
            arr.push({
              label: pic.id,
              value: pic.link,
            });
          });
          this.imgform.imgUrl = arr;
        }
      });
    },
    onLoad() {
      this.loading = true;
      let body = {
        current: this.page.current,
        size: this.page.size,
      };
      if (this.searchText) {
        body["wheres"] = [
          { field: "name", op: "LIKE", value: this.searchText },
        ];
      }
      getMetaCommonList(this.model, body).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;

        this.loading = false;
        // this.selectionClear();
      });
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.deleteIcon {
  font-size: 18px;
  position: absolute;
  right: 15px;
  top: 15px;
  cursor: pointer;
  z-index: 1;
}
/deep/ .el-dialog__header {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 15px;
}
/deep/ .el-drawer__header {
  border-bottom: 1px solid #ebebeb;
  padding: 10px 20px;
  margin-bottom: 0px;
}
/deep/ .el-drawer__body {
  padding: 0px;
}
.buildDetail /deep/ .el-dialog__body {
  padding: 0px;
}
.btnClass {
  display: flow-root;
  height: 50px;
  line-height: 50px;
  padding: 0px 15px;
}
</style>

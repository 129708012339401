import {
  TextInputType,
  TextareaInputType,
  ColorPickerInputType
} from './option-type';


import ConstType from './const-type';

import { WidgetType } from './widget';

const chartBarOption = () => {
  return [

  ]
}


const Widgets = [
  new WidgetType({
    component: 'BuiltInWidgetByText',
    optionList: [
      ConstType.TextColor(),
      ConstType.LineHeight(),
      ConstType.FontSize()
    ],
    type: '基础部件',
    name: '普通文本',
    datasourceConf: {
      dataType: 'text'
    },
    testData: '文本组件',
    public: {
      simple: true
    }
  }),
  new WidgetType({
    component: 'BuiltInWidgetByEcharts',
    optionList: [
      new TextInputType({
        code: 'type',
        name: '类型',
        show: false,
        defaultValue: 'bar'
      }),
      new TextInputType({
        code: 'barType',
        name: '类型',
        show: false,
        defaultValue: 'horizontal'
      }),
      ...chartBarOption()
    ],
    type: '柱状图',
    name: '纵向柱状图',
    datasourceConf: {
      dataType: 'json',
      dataOption: [
        {name: '分类' , code: 'category' , type: 'field' , value: '' , testValue: 'name'},
        {name: '值' , code: 'value' , type: 'field' , value: '' , testValue: 'value'}
      ]
    },
    testOption: {},
    testData: [{name: 'Mon' , value: 120} , {name: 'Tue' , value: 200} , {name: 'Wed' , value: 150}],
    position: { width: 25 , height: 15}
  }),
  new WidgetType({
    component: 'BuiltInWidgetByEcharts',
    optionList: [
      new TextInputType({
        code: 'type',
        name: '类型',
        show: false,
        defaultValue: 'bar'
      }),
      new TextInputType({
        code: 'barType',
        name: '类型',
        show: false,
        defaultValue: 'vertical'
      }),
      ...chartBarOption()
    ],
    type: '柱状图',
    name: '横向柱状图',
    datasourceConf: {
      dataType: 'json',
      dataOption: [
        {name: '分类' , code: 'category' , type: 'field' , value: '' , testValue: 'name'},
        {name: '值' , code: 'value' , type: 'field' , value: '' , testValue: 'value'}
      ]
    },
    testOption: {},
    testData: [{name: 'Mon' , value: 120} , {name: 'Tue' , value: 200} , {name: 'Wed' , value: 150}],
    position: { width: 25 , height: 15}
  })
]

export default Widgets;

<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" :page.sync="page" :before-open="beforeOpen" v-model="form" ref="crud" @row-update="rowUpdate" @row-save="rowSave"
                   @row-del="rowDel" @search-change="searchChange" @search-reset="searchReset" @current-change="currentChange" @size-change="sizeChange" @refresh-change="refreshChange"
                   @on-load="onLoad">
            <template slot="menuLeft">
                <el-button type="danger" size="small" icon="el-icon-delete" plain v-if="permission.codprice_delete" @click="handleDelete">删 除
                </el-button>
            </template>
        </avue-crud>
    </basic-container>
</template>

<script>
import { getList, getPage, getDetail, add, update, remove, getTimeUnit, getTimeType } from '@/api/spcod/codprice';
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            form: {},
            query: {},
            loading: true,
            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            selectionList: [],
            option: {
                height: 'auto',
                calcHeight: 30,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: true,
                delBtn: true,
                viewBtn: true,
                dialogClickModal: false,
                column: [
                    {
                        label: '房间',
                        prop: 'roomId',
                        hide: true,
                        type: 'select',
                        addDisplay: true,
                        editDisplay: false,
                        dicUrl: '/api/park-spcod/codroom/list?current=1&size=10000000',
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                        dicFormatter: res => {
                            return res.data.records;
                        },
                        rules: [
                            {
                                required: true,
                                message: '请输入外键到房间Id',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '房间',
                        prop: 'roomName',
                        addDisplay: false,
                        disabled: true,
                        rules: [
                            {
                                required: true,
                                message: '请输入外键到房间',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '楼宇',
                        prop: 'buildingId',
                        search: true,
                        hide: true,
                        addDisplay: false,
                        editDisplay: false,
                        type: 'select',
                        dicUrl: '/api/park-spcod/mobcodappoint/listbuilding?current=1&size=10000000',
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                        dicFormatter: res => {
                            return res.data.records;
                        },
                        rules: [
                            {
                                required: true,
                                message: '请输入外键到楼宇ID',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '楼宇',
                        prop: 'buildingName',
                        editDisplay: false,
                        addDisplay: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入外键到楼宇ID',
                                trigger: 'blur',
                            },
                        ],
                    },
                    // {
                    //   overHidden: true,
                    //   align: "left",
                    //   label: "日期",
                    //   prop: "beginTime",
                    //   type: "date",
                    //   format: "yyyy-MM-dd",
                    //   valueFormat: "yyyy-MM-dd",
                    //   rules: [
                    //     {
                    //       required: true,
                    //       message: "请选择日期",
                    //       trigger: "blur",
                    //     },
                    //   ],
                    // },
                    {
                        label: '时间单位',
                        prop: 'unitHour',
                        type: 'select',
                        dicData: [],
                        props: {
                            label: 'value',
                            value: 'key',
                        },
                        rules: [
                            {
                                required: true,
                                message: '请输入时间单位',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '时间类型',
                        prop: 'timeType',
                        type: 'select',
                        dicData: [
                            {
                                label: '工作日',
                                value: 0,
                            },
                            {
                                label: '节假日',
                                value: 1,
                            },
                        ],
                        props: {
                            label: 'label',
                            value: 'value',
                        },
                        rules: [
                            {
                                required: true,
                                message: '请输入时间类型',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '开始时间',
                        prop: 'beginTime',
                        // type: 'time',
                        // format: 'HH:mm',
                        //  disabled: true,
                    },
                    {
                        label: '结束时间',
                        prop: 'endTime',
                        // type: 'time',
                        // format: 'HH:mm',
                        //  disabled: true,
                    },
                    {
                        label: '单位价格',
                        prop: 'unitPrice',
                        type: 'number',
                        min: 0,
                        rules: [
                            {
                                required: true,
                                message: '请输入单位价格',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '状态',
                        prop: 'status',
                        addDisplay: false,
                        search: true,
                        searchSpan: 5,
                        type: 'select',
                        editDisplay: false,
                        dicData: [
                            {
                                label: '有效',
                                value: 1,
                            },
                            {
                                label: '无效',
                                value: 0,
                            },
                        ],
                        rules: [
                            {
                                required: false,
                                message: '请输入状态',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '备注',
                        prop: 'memo',
                        span: 24,
                        minRows: 6,
                        type: 'textarea',
                        rules: [
                            {
                                required: false,
                                message: '请输入订购注意事项',
                                trigger: 'blur',
                            },
                        ],
                    },
                ],
            },
            data: [],
            timeUnitList: [],
        };
    },
    created() {
        this.getTimeUnit();
        // this.getTimeType();
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.codprice_add, false),
                viewBtn: this.vaildData(this.permission.codprice_view, false),
                delBtn: this.vaildData(this.permission.codprice_delete, false),
                editBtn: this.vaildData(this.permission.codprice_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    methods: {
        rowSave(row, done, loading) {
            // if (row.beginTime) {
            //   let t = row.beginTime;
            //   row.beginTime = t + " 00:00:00";
            //   row.endTime = t + " 23:59:59";
            // }
            add(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    window.console.log(error);
                },
            );
        },
        rowUpdate(row, index, done, loading) {
            // if (row.beginTime) {
            //   let t = row.beginTime;
            //   row.beginTime = t + " 00:00:00";
            //   row.endTime = t + " 23:59:59";
            // }
            update(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    console.log(error);
                },
            );
        },
        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(row.id);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning('请选择至少一条数据');
                return;
            }
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(this.ids);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    this.$refs.crud.toggleSelection();
                });
        },
        beforeOpen(done, type) {
            if (['edit', 'view'].includes(type)) {
                getDetail(this.form.id).then(res => {
                    this.form = res.data.data;
                });
            }
            done();
        },
        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },
        searchChange(params, done) {
            this.query = params;
            this.page.currentPage = 1;
            this.onLoad(this.page, params);
            done();
        },
        selectionClear() {
            this.selectionList = [];
            this.$refs.crud.toggleSelection();
        },
        currentChange(currentPage) {
            this.page.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        onLoad(page, params = {}) {
            this.loading = true;
            getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                this.data = data.records;
                this.loading = false;
                this.selectionClear();
            });
        },
        getTimeUnit() {
            getTimeUnit().then(res => {
                let data = [];
                res.data.data.forEach(e => {
                    data.push({
                        key: e.key,
                        value: e.value,
                    });
                });
                // this.timeUnitList = data;
                // const data = res.data.data;
                this.timeUnitList = data;
                let ci = 0;
                this.option.column.forEach(c => {
                    if (c.prop == 'unitHour') {
                        this.option.column[ci].dicData = this.timeUnitList;
                    }
                    ci += 1;
                });
                this.$forceUpdate();
            });
        },
        getTimeType() {
            getTimeType().then(res => {
                const data = res.data.data;
                let ci = 0;
                this.option.column.forEach(c => {
                    if (c.prop == 'timeType') {
                        this.option.column[ci].dicData = data;
                    }
                    ci += 1;
                });
                this.$forceUpdate();
            });
        },
    },
};
</script>

<style></style>

<template>
  <div>
    <el-form ref="dataConfig" :model="widget.public" :size="config.size" :label-width="'70px'">
      <el-form-item label="数据源">
        <el-select class="dis-b" v-model="widget.public.dataConfig.datasource.id" @change="datasourceChange()">
          <el-option v-for="item of datasources" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
      <!-- <el-form-item v-if="widget.public.dataConfig.datasource.type === 'staticData'" label="静态数据">
        <el-input type="textarea" :rows="5" v-model="widget.public.dataConfig.option.staticData" ></el-input>
      </el-form-item> -->
      <!-- <el-form-item v-if="widget.public.dataConfig.datasource.type !== 'staticData'" label="表达式">
        <el-input type="textarea" :rows="5" v-model="widget.public.dataConfig.option.queryString" ></el-input>
      </el-form-item> -->
      <template v-if="currentDatasource && currentDatasource.optionList">
        <OptionGroup  v-for="item of currentDatasource.optionList" :optionItem="item" :option="widget.public.dataConfig.option"></OptionGroup>
      </template>
      <el-form-item>
        <el-button @click="loadData">保存</el-button>
      </el-form-item>
    </el-form>
    <div v-if="dash && widget">
        <h4 class="bor-b h-40 lh-40 pad-0 mar-0 mar-b-10">
          <b>变量配置</b><el-tooltip class="item" effect="dark" placement="top">
                <pre slot="content">可以在输入框内使用${变量编码}使用变量值，如:${name}</pre>
                <i class="mar-l-5 el-icon-info"></i>
              </el-tooltip>
          <span class="pull-right">
            <el-button :size="config.size" type="text" icon="el-icon-plus" @click="widget.variables.push({name: '' , value: '' , type: 'widget'})">添加变量</el-button>
          </span>
        </h4>
        <!-- <div class="mar-b-10">
          
        </div> -->
         <el-table
          v-if="dash.variables && dash.variables.length"
          :data="dash.variables"
          border
          class="mar-b-15"
          style="width: 100%"
        >
          <el-table-column prop="name" label="变量编码"></el-table-column>
          <el-table-column prop="value" label="变量值"></el-table-column>
        </el-table>
        <el-table
          :data="widget.variables"
          border
          style="width: 100%"
        >
          <el-table-column
            prop="name"
            label="变量编码"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.type === 'dash'">{{scope.row.name}}</span>
              <el-input v-if="scope.row.type === 'widget'" :size="config.size" type="text" v-model="scope.row.name"></el-input>
            </template>
          </el-table-column>
          <el-table-column
            prop="value"
            label="变量值"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.type === 'dash'">{{scope.row.value}}</span>
              <el-input v-if="scope.row.type === 'widget'" :size="config.size" type="text" v-model="scope.row.value"></el-input>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button :size="config.size" v-if="scope.row.type === 'widget'" icon="el-icon-delete" type="text" @click="handleDeleteVar(scope.row)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
  </div>
</template>
<script>

import { DataViewConnector } from '../../index.js';
import { OptionParseEach } from '../../util/index';
import OptionGroup from './option-group.vue';

export default {
  inject: [ 'config' ],
  components: { OptionGroup },
  data(){
    return {
      datasources: DataViewConnector.Datasource.getDatasources(),
      currentDatasource: null
    }
  },
  props: {
    widget: {
      type: Object,
      default(){
        return null;
      }
    },
    dash: {
      type: Object,
      default(){
        return null;
      }
    }
  },
  mounted(){
    console.log(this.widget);
    this.datasourceChange(true);
  },
  methods: {
    handleDeleteVar(row){
      this.widget.variables.splice(row.$index , 1);
    },
    datasourceChange(init){
      if(!init){
        this.widget.public.dataConfig.option = {};
      }
      let oldOption = this.widget.public.dataConfig.option || {};
      this.datasources.forEach(
        item => {
          if(item.id === this.widget.public.dataConfig.datasource.id){
            this.widget.public.dataConfig.datasource.type = item.type;
            if(item.optionList){
              this.widget.public.dataConfig.option = OptionParseEach(item.optionList , init ? oldOption : null)
            }
            this.currentDatasource = item;
          }
        }
      )
    },
    loadData(){
      this.$emit('loadData')
    }

  }
}
</script>
<!-- 服务总览-->
<template>
  <div>
    <!-- 统计数据 -->
    <el-row type="flex"  :gutter="$store.state.common.gutter">
       <el-col :span='24'>
      <el-card header="数据总览">
        <el-row type="flex"  class="h-100p" :gutter="$store.state.common.gutter"  style="align-items:center;justify-content:center;">
          <el-col :span='5' class='numberCon'>
            <div>
              <span class='num'>{{(validatenull(form.uploadedNum)||form.uploadedNum=='-1')?0:form.uploadedNum}}</span>
              <small>个</small>
            </div>
            <el-divider class="dis-ib w-100p zoom-d5"></el-divider><br>
            <div>已上架API总数</div>
          </el-col>
          <el-col :span='5' class='numberCon'>
            <div>
              <span class='num'>{{(validatenull(form.apiLicensedCount)||form.apiLicensedCount=='-1')?0:form.apiLicensedCount}}</span>
              <small>个</small>
            </div>
            <el-divider class="dis-ib w-100p zoom-d5"></el-divider><br>
            <div>授权API个数</div>
          </el-col>
          <el-col :span='5' class='numberCon'>
            <div>
              <span class='num'>{{(validatenull(form.usedApiDistinctNum)||form.usedApiDistinctNum=='-1')?0:form.usedApiDistinctNum}}</span>
              <small>个</small>
            </div>
            <el-divider class="dis-ib w-100p zoom-d5"></el-divider><br>
            <div>调用API个数</div>
          </el-col>
          <el-col :span='5' class='numberCon'>
            <div>
              <span class='num'>{{(validatenull(form.usedApiNum)||form.usedApiNum=='-1')?0:form.usedApiNum}}</span>
              <small>次</small>
            </div>
            <el-divider class="dis-ib w-100p zoom-d5"></el-divider><br>
            <div>调用API次数</div>
          </el-col>
          <el-col :span='5' class='numberCon'>
            <div>
              <span class='num'>{{(validatenull(form.usedApiNumWithSuccess)||form.usedApiNumWithSuccess=='-1')?0:form.usedApiNumWithSuccess}}</span>
              <small>次</small>
            </div>
            <el-divider class="dis-ib w-100p zoom-d5"></el-divider><br>
            <div>调用API成功次数</div>
          </el-col>
          <el-col :span='5' class='numberCon'>
            <div>
              <span class='num'>{{(validatenull(form.appCount)||form.appCount=='-1')?0:form.appCount}}</span>
              <small>个</small>
            </div>
            <el-divider class="dis-ib w-100p zoom-d5"></el-divider><br>
            <div>应用总数</div>
          </el-col>
        </el-row>
      </el-card>
       </el-col>
    </el-row>
    <el-row type="flex" :gutter="$store.state.common.gutter">
      <!-- 热门API调用 -->
      <el-col :span="12">
        <el-card header="热门API调用(TOP5)">
          <div class="w-100p h-300 ov-a">
            <el-table :data="hotLists">
              <el-table-column type="index" label="排名" width="100">
                <template slot-scope="scope">
                  <div class="text-left mar-r-15">
                    <i
                      v-if="scope.$index < 3"
                      class="portal-icon_huangguan"
                      :class="{
                        'text-sort-1': scope.$index == 0,
                        'text-sort-2': scope.$index == 1,
                        'text-sort-3': scope.$index == 2
                      }"
                    ></i>
                    <span style="margin-left: 10px">{{
                      scope.$index + 1
                    }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="服务名"></el-table-column>
              <el-table-column prop="callCount" label="调用次数">
                <template slot-scope="{row}">
                  <span>{{(validatenull(row.callCount)||row.callCount=='-1')?0:row.callCount}}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
      <!-- API调用失败率 -->
      <el-col :span="12">
        <el-card header="API调用失败率(TOP5)">
          <div class="w-100p h-300 ov-a">
            <el-table :data="errorLists">
              <el-table-column type="index" label="排名" width="100">
                <template slot-scope="scope">
                  <div class="text-left mar-r-15">
                    <i
                      v-if="scope.$index < 3"
                      class="portal-icon_huangguan"
                      :class="{
                        'text-sort-1': scope.$index == 0,
                        'text-sort-2': scope.$index == 1,
                        'text-sort-3': scope.$index == 2
                      }"
                    ></i>
                    <span style="margin-left: 10px">{{
                      scope.$index + 1
                    }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="name" label="服务名"></el-table-column>
              <el-table-column prop="failureRate" label="失败率(%)">
                <template slot-scope="{row}">
                  <span>{{(validatenull(row.failureRate)||row.failureRate=='-1')?0:row.failureRate}}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row type="flex" :gutter="$store.state.common.gutter">
      <el-col :span="12">
        <el-card header="API调用情况占比分析">
          <div class="w-100p h-257">
            <basic-echarts v-if="pieOption1.series.length>0" :option="pieOption1"></basic-echarts>
            <no-data v-else></no-data>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card header="近一月API调用情况统计">
          <div class="w-100p h-257">
              <basic-echarts :option="lineOption"></basic-echarts>
            </div>
        </el-card>
        </el-col>
    </el-row>
    <!-- 调用API列表 -->
    <el-row type="flex" :gutter="$store.state.common.gutter" class="mar-b-0">
      <el-col :span='24' class="mar-b-0">
        <div class='el-card is-always-shadow'>
          <div class='el-card__header'>
            <!-- 搜索 -->
            <el-row  type="flex" :gutter="$store.state.common.gutter" class="mar-b-0">
              <el-col :span='24' class="mar-b-0">
                <span>调用API列表</span>
                <span class="push-right w-560">
                  <el-row  type="flex" :gutter="$store.state.common.gutter" class="mar-b-0">
                    <el-col :span='8' class="w-198 mar-b-0">
                      <el-select size='mini' clearable v-model='search.apiCollectionId' placeholder='请选择API集合'>
                        <el-option v-for='item in APICollectionList' :key='item.id' :value="item.id" :label='item.name'></el-option>
                      </el-select>
                    </el-col>
                    <el-col :span='8' class="w-198 mar-b-0">
                      <el-input size='mini' clearable placeholder="请输入API名称" v-model="search.apiName" class="input-with-select">
                      </el-input>
                    </el-col> 
                    <el-col :span="8" class="w-164 mar-b-0">
                      <el-button size="mini" icon="el-icon-search" type="primary" @click="searchChange">搜索</el-button>
                      <el-button size="mini" icon="el-icon-delete" @click="emptHandle">清空</el-button>
                    </el-col>
                  </el-row>
                </span>
              </el-col>
            </el-row>
          </div>
          <div class="el-card__body w-100p h-300 ov-a">
            <el-table :data="callDataList">
              <el-table-column prop="name" label="API名称"></el-table-column>
              <el-table-column prop="shareUrl"  width='250' label="请求路径"></el-table-column>
              <el-table-column prop="apiCollectionName" label="API集合"></el-table-column>
              <el-table-column prop="status" label="状态">
                <template slot-scope="scope">
                  <span v-if='scope.row.status==1'>已上架</span>
                  <span v-else-if='scope.row.status==0'>未上架</span>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column prop="licensedNum" label="授权应用数"></el-table-column>
              <el-table-column prop="callCount"  label="累计调用次数">
                <template slot-scope="scope">
                  <span style='color:#006dcf;cursor:pointer' @click='openPanorama(scope.row)'>{{scope.row.callCount}}</span>
                </template>
              </el-table-column>
              <el-table-column prop="createUserName" label="创建人"></el-table-column>
              <el-table-column width='150' prop="createTime" label="创建时间"></el-table-column>
            </el-table>
          </div>
          <el-pagination
          style='text-align:right;margin-top:20px'
            background
            layout="total, sizes, prev, pager, next, jumper"
            :total="page.total">
            </el-pagination>
        </div>
      </el-col>
    </el-row>

    <!-- <el-dialog
      title="API应用全景图"
      append-to-body
      v-if="createUserDialog"
      :visible.sync="createUserDialog"
      style='height:400px'
      height='400px'
    >
      <mapDiv></mapDiv>
    </el-dialog> -->
  </div>
</template>
<script>

import dayjs from 'dayjs'
import { pieOption,line1Option,formartPieData} from './chart-option'
import { generalOverview,panoramaForSingleApi,datasharePage,hotList,errorList,findStatisticsByDay } from '@/api/dataService/serviceOverview'
import { getDataAPICollectionSelect } from '@/api/dataService/dataAPICollection';
import mapDiv from './map.vue'
import {userLists} from '@/api/dataService/dataApp'
// /Users/wufeiya/SSTA/converge_flex_frontend/src/api/dataService/dataApp.js
export default {
  components: {mapDiv},
  name: 'serviceOverview',
  data () {
    return {
      createUserDialog: false,
      callDataList: [],
      apiName: "",
      collection: "",
      user: "",
      callData: [],
      pieOption1:  pieOption([], [], []),
      lineOption: line1Option(),
      form: {
        apiLicensedCount: 0,
        appCount: 0,
        uploadedNum: 0,
        usedApiDistinctNum: 0,
        usedApiNum: 0,
        usedApiNumWithSuccess: 0
      },
      search: {
        apiCollectionId: '',
        apiName: ""
      },
      page: {
        pageSizes: this.$store.state.common.pageSizes,
        pagerCount: this.$store.state.common.pagerCount,
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      APICollectionList: [],
      userLists: [],//人员下拉框数据
      hotLists: [],//热门top5
      errorLists: [],//API调用失败率
      LatelyNDayData: {},
    }
  },
  created () {
    this.generalOverviewHandle()
    this.datasharePageHandle()
    this.getDataAPICollectionSelectHandel()
    this.userListsHandle()
    this.hotListHandle()
    this.errorListHandle()
    this.findStatisticsByDayHandle()
  },
  mounted () {
  },
  methods: {
      // 清空搜索
    emptHandle () {
      this.search.apiName=''
      this.search.apiCollectionId=''
    },
    // 搜索
    searchChange () {
      this.onLoad()
    },
    getBeforeDate() {
      let d = new Date();
      let year = d.getFullYear();
      let mon = d.getMonth() + 1;
      let day = d.getDate();
      let currentDate=`${year}-${mon < 10 ? "0" + mon : mon}-${day < 10 ? "0" + day : day}`
      let year1 = d.getFullYear();
      let mon1 = d.getMonth()
      let day1 = d.getDate();
      let beforeTime =`${year1}-${mon1 < 10 ? "0" + mon1 : mon1}-${day1 < 10 ? "0" + day1 : day1}`
      return [currentDate,beforeTime];
    },
    // 每日调用折线率
    findStatisticsByDayHandle () {
      let time=this.getBeforeDate()
      let params= {
        endDate: dayjs().format('YYYY-MM-DD'),
        startDate: dayjs().subtract(1 , 'month').format('YYYY-MM-DD'),
      }
      findStatisticsByDay(params).then((res) => {
        if (res.data.code==200) {
          let dss=[]
          let dss1=[]
          let date=[]
          res.data.data.forEach((i)=>{
            dss.push(i.usedApiDistinctNum) //使用api的个数
            dss1.push(i.usedApiDistinctNum) //调用次数
            date.push(i.statisticDate)
          })

          this.lineOption.xAxis[0].data =date
          this.lineOption.series[0].data = dss
          this.lineOption.series[1].data = dss1;
          this.lineOption.yAxis[0].name = "API个数";
          this.lineOption.yAxis[1].name = "API调用次数";
        }
      })
    },
    // API调用失败率
    errorListHandle() {
      errorList({topNum: 5}).then((res) => {
        if (res.data.code==200) {
          this.errorLists=res.data.data
        }
      })

    },
    // 热门top5
    hotListHandle () {
      hotList({topNum: 5}).then((res) => {
        if (res.data.code==200) {
          this.hotLists=res.data.data
        }
      })
    },
    // 获取人员
    userListsHandle () {
      userLists().then((res) => {
        if (res.data.code==200) {
          this.userLists=res.data.data
        } else {
          this.userLists=[]
        }
      }).catch((error) => {
        this.userLists=[]
      })
    },
    // 获取集合下拉框数据
    getDataAPICollectionSelectHandel () {
      getDataAPICollectionSelect().then((res) => {
        if (res.data.code==200) {
          this.APICollectionList=res.data.data
        } else {
          this.APICollectionList=[]
        }
      }).catch((error) => {
        this.APICollectionList=[]
      })
    },
    // 获取调用API列表
    datasharePageHandle() {
      let params= {
        current: this.page.currentPage,
        size: this.page.pageSize,
        apiCollectionId: this.search.apiCollectionId,
        name:  this.search.apiName,
        hiveLicensedNum: 1
      }
      datasharePage(params).then((res) => {
        if (res.data.code==200) {
          this.callDataList=res.data.data.records
          this.page.total=res.data.data.total
        } else {
          this.callDataList=[]
        }
      })
    },
    // 打开API全景图
    openPanorama (row) {
      this.$router.push({
        path: '/dataService/map',
        query: {
          id: row.id
        }
      })
      // let params= {
      //   dataShareId: row.id
      // }
      // panoramaForSingleApi(params).then((res) => {

      // })
      // this.createUserDialog=true
    },
    //
    // 获取统计数据
    generalOverviewHandle () {
      generalOverview().then((res) => {
        if (res.data.code==200) {
          this.form=res.data.data
          this.$nextTick(() => {
            this.initPieEcharts()
          })
        } else {
          this.form= {
            apiLicensedCount: 0,
            appCount: 0,
            uploadedNum: 0,
            usedApiDistinctNum: 0,
            usedApiNum: 0,
            usedApiNumWithSuccess: 0
          }
        }
      }).catch((error) => {
        this.form= {
            apiLicensedCount: 0,
            appCount: 0,
            uploadedNum: 0,
            usedApiDistinctNum: 0,
            usedApiNum: 0,
            usedApiNumWithSuccess: 0
          }
      })
    },
    initPieEcharts () {
      let dataArr={
        data: [
          { value: this.form.usedApiNumWithSuccess, name: '调用成功' },
          { value: (this.form.usedApiNum-this.form.usedApiNumWithSuccess), name: '调用失败' },
        ]
      }
      let data = formartPieData(dataArr);
      this.pieOption1.series[0].data = data.data;
      this.pieOption1.legend.data = data.legend;
      this.pieOption1.legend.formatter = function(name) {
        return `${name}  {value|${data.legendDatas[name]["value"]}条 ,${data.legendDatas[name]["pre"]}%}`;
      };
    },
    searchChange () {
      this.datasharePageHandle()
    }
  }
}
</script>
<style lang="scss" scoped>
.numberCon {
  text-align: center;
  padding: 0 20px!important;
  .num {
    color: #006dcf;
    font-size: 28px;
    padding-right: 5px;
  }
}

</style>

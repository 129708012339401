<template>
<div class="h-100p grid-container">
  <smart-widget-grid @resize="resize" v-bind="option" :layout="data">
    <template v-if="isDesign">
      <el-popover
        trigger="hover"
        placement="top-end"
        popper-class="el-popper-min"
        :visible-arrow="false"
        v-for="(item , index) of data" :slot="item.i" :key="item.i"
      >
        <smart-widget :style="getStyle(item)" v-bind="item.public" :title="item.title" :subTitle="item.subTitle" slot="reference" class="h-100p">
          <WidgetContainer :ref="item.i" :item="item"></WidgetContainer>
        </smart-widget>
        <div>
          <el-tooltip class="item" effect="dark" content="设置" placement="top-start">
            <el-link :underline="false" @click="settingWidget(item)"><i class="el-icon-setting"></i></el-link>
          </el-tooltip>
          <el-divider class="mar-lr-10" direction="vertical"></el-divider>
          <el-tooltip class="item" effect="dark" content="删除" placement="top-start">
            <el-link type="danger" :underline="false" @click="data.splice(index , 1)"><i class="el-icon-delete"></i></el-link>
          </el-tooltip>
        </div>
      </el-popover>
    </template>
    <template v-else>
      <smart-widget  @resize="resize(item)" :style="getStyle(item)" v-bind="item.public" :title="item.title" :subTitle="item.subTitle" v-for="item of data" :slot="item.i" :key="item.i">
        <WidgetContainer :ref="item.i" :item="item"></WidgetContainer>
      </smart-widget>
    </template>
  </smart-widget-grid>
  <el-drawer
    title="部件设置"
    :visible.sync="widgetDrawer"
    :with-header="false"
    append-to-body
    >
    <h4 class="lh-50 bor-b mar-0 pad-0 pad-lr-8">部件设置</h4>
    <div class="pad-8">
      <template v-if="widgetDrawer">
        <template v-if="widgetConfigComponents.length > 1">
          <el-tabs v-model="activeName" @tab-click="tabChange">
            <el-tab-pane v-for="item of widgetConfigComponents" :label="item.tabName" :key="item.name" :name="item.name">
            </el-tab-pane>
          </el-tabs>
          <component :is="currentTabComponent" @resize="resize" :key="activeName" :option="option" :widget="currentWidget" @dataChange="dataChange"></component>
        </template>
        <component v-else :is="widgetConfigComponents[0]" @resize="resize" :option="option" :widget="currentWidget" @dataChange="dataChange"></component>
      </template>
    </div>
  </el-drawer>
</div>
</template>
<script>

import LayoutConfig from '../config/layout.config';
import WidgetContainer from './widget-container.vue';

import widgetConfigComponents from './widget-config';

export default {
  components: { WidgetContainer },
  props: {
    config: {
      type: Object,
      default: null
    },
    data: {
      type: Array,
      default(){
        return [];
      }
    },
    widgets: {
      type: Object,
      default(){
        return {}
      }
    },
    isDesign: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    config: {
      deep: true,
      immediate: true,
      handler(val){
        this.option = {
          ...LayoutConfig,
          ...val
        }
      }
    }
  },
  data(){
    return {
      activeName: '',
      option: LayoutConfig,
      widgetDrawer: false,
      widgetConfigComponents: widgetConfigComponents,
      currentWidget: null,
      currentTabComponent: widgetConfigComponents.length ? widgetConfigComponents[0] : null
    }
  },
  created(){
    
  },
  mounted(){
    // console.log(this.rowHeight , this.$refs.layout.$el.offsetHeight);
    console.log(this.data); 
  },
  methods: {
    tabChange(){
      this.widgetConfigComponents.forEach(
        item => {
          if(item.name === this.activeName){
            this.currentTabComponent = item;
          }
        }
      )
    },
    getWidget(widget){
      let showWidget = null;
      if(typeof widget === 'string'){
        Object.values(this.widgets).forEach(
          itemWidget => {
            if(itemWidget.name === widget){
              showWidget = itemWidget;
            }
          }
        )
      }else if(typeof widget === 'object'){
        showWidget = widget;
      }
      return showWidget;
    },
    widgetReady(vm){

    },
    settingWidget(widget){
      this.currentWidget = widget;
      this.widgetDrawer = true;
      if(!this.activeName){
        if(this.widgetConfigComponents && this.widgetConfigComponents.length){
          this.activeName = this.widgetConfigComponents[0]['name'];
        }
      }
    },
    getW(num){
      num = Number(num);
      return num / 2;
    },
    getStyle(widget){
      let _style = widget.style || {};
      return _style;
    },
    dataChange(){

    },
    resize(item){
      if(this.$refs[item['i']].length){
        this.$refs[item['i']][0].resize();
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.grid-container{
  position: relative;
}
.grid-line{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: red;
}
</style>
<style lang="scss">
.el-popper-min{
  min-width: auto;
}
</style>
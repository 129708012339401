<template>
    <basic-container class="h-100p">
        <ActionTable ref="table" v-if="type && options[type]" v-bind="options[type]" :actionType="type">
            <template slot="tableMenu" slot-scope="{size , row}">
                <template v-if="type === 'repair'">
                    <el-button v-if="row.repair_status == 1" icon="el-icon-check" :size="size || 'small'" type="text" @click="openRepair(row)">维保完成</el-button>
                </template>
                <template v-if="type === 'inventory'">
                    <el-button v-if="permissionCheck('inventoryStart') && row.inventory_status == 1" icon="el-icon-check" :size="size || 'small'" type="text"
                               @click="openStartInventory(row , 1)">开始盘点</el-button>
                    <!-- <el-button v-if="(row.inventory_status == 3 && row.approval_status == 1) || row.approval_status == 4" icon="el-icon-circle-check" :size="size || 'small'" type="text" @click="startInventoryFlow(row)">提交审批</el-button> -->
                    <el-button v-if="permissionCheck('inventoryEnd') && row.inventory_status == 2" icon="el-icon-check" :size="size || 'small'" type="text"
                               @click="openStartInventory(row , 2)">完成盘点</el-button>
                </template>
                <template v-if="type === 'scrap'">
                    <el-button v-if="row.scrap_status == 1" icon="el-icon-check" :size="size || 'small'" type="text" @click="openScrapApprovalFinsh(row)">归档</el-button>
                    <!-- <el-button icon="el-icon-download" :size="size || 'small'" type="text" @click="exportScrapDetail(row)">导出报损明细</el-button> -->
                </template>
                <template v-if="options[type].sealBtn">
                    <el-button :size="size || 'small'" v-if="permissionCheck('seal') && options[type].sealBtnDisplay && options[type].sealBtnDisplay(row)" @click="sealData(row)" type="text"
                               icon="el-icon-document">归档</el-button>
                </template>
            </template>
        </ActionTable>
        <el-dialog width="800px" :title="inventoryDialog.title" :visible.sync="inventoryTableVisible">
            <el-form v-loading="inventoryDialog.loading" size="small" :model="inventoryDialog.form">
                <el-form-item required :label="inventoryDialog.type === 1 ? '开始时间': '完成时间'" prop="timeValue">
                    <el-date-picker v-model="inventoryDialog.form.timeValue" type="datetime" placeholder="选择日期时间" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
                </el-form-item>
                <template v-if="inventoryDialog.tableData.length">
                    <el-tabs value="1">
                        <el-tab-pane label="未盘点资产" name="1"></el-tab-pane>
                    </el-tabs>
                    <avue-crud class="mar-t-60f mar-l-15f mar-r-15f" ref="inventoryDialogTable" :data="inventoryDialog.tableData.filter((item , index) => {
              if(index >= (inventoryDialog.page.currentPage - 1) * inventoryDialog.page.pageSize && index < (inventoryDialog.page.pageSize * inventoryDialog.page.currentPage)){
                return item;
              }
            })" :page.sync="inventoryDialog.page" :option="inventoryDialog.tableOption">
                        <template slot-scope="{row}" slot="inventory_user_name">
                            <span v-if="row.inventory_use_person !== 1">{{row.inventory_user_name || '-'}}</span>
                            <span v-else>默认资产使用人盘点</span>
                        </template>
                    </avue-crud>
                    <!-- <el-table border :data="inventoryDialog.tableData">
            <el-table-column label="资产编码" width="160px" prop="system_asset_code"></el-table-column>
            <el-table-column label="资产名称" prop="asset_name"></el-table-column>
            <el-table-column width="160px" label="盘点人员" prop="inventory_user_name">
              <template slot-scope="{row}" slot="inventory_user_name">
                <span v-if="row.inventory_use_person !== 1">{{row.inventory_user_name || '-'}}</span>
                <span v-else>默认资产使用人盘点</span>
              </template>
            </el-table-column>
          </el-table> -->
                </template>
            </el-form>
            <div slot="footer">
                <el-button :loading="inventoryDialog.saving" size="small" @click="inventoryTableVisible = false">关 闭</el-button>
                <el-button :loading="inventoryDialog.saving" size="small" type="primary" @click="inventoryTableDialogSubmit">提 交</el-button>
            </div>
        </el-dialog>
    </basic-container>
</template>
<script>
import { mapGetters } from 'vuex';

import dayjs from 'dayjs';

import ActionTable from './action/table.vue';

import * as dictService from '@/api/system/dictbiz';

import { repairFinsh, scrapApprovalFinsh } from '@/api/asset/index.js';
import cacheSearch from '@/mixins/cacheSearch';

import { updateMetaCommon, getMetaCommonDetail } from '@/api/busiMode/metaCommon';

export default {
    components: { ActionTable },
    mixins: [cacheSearch],
    data() {
        return {
            type: this.$router.currentRoute.params['type'],
            options: {
                storage: {
                    column: [
                        {
                            label: '入库单据号',
                            prop: 'code',
                            width: 220,
                            search: true,
                            searchSpan: 8,
                            searchLike: true,
                        },
                        {
                            label: '入库审批状态',
                            prop: 'approval_status',
                            dicUrl: dictService.getUrl('ApprovalStatus'),
                            props: {
                                label: 'dictValue',
                                value: 'dictKey',
                            },
                            dataType: 'number',
                            width: 100,
                        },
                        {
                            label: '入库人',
                            prop: 'storage_user_name',
                        },
                        {
                            label: '入库时间',
                            prop: 'storage_date',
                            type: 'date',
                            format: 'yyyy-MM-dd',
                            search: true,
                            searchRange: true,
                            searchSpan: 10,
                            valueFormat: 'yyyy-MM-dd',
                            sortable: 'custom',
                        },
                        {
                            label: '入库说明',
                            prop: 'remarks',
                        },
                    ],
                    model: 'wisdom_park.wp_asset_storage',
                    detailTable: 'wp_asset_storage_detail',
                    masterIdColumnName: 'storage_id',
                    title: '入库单',
                    bizCode: this.$PCode.BIZ.ASSET_STORAGE,
                    actionCode: this.$PCode.ACTIONS.SUBMIT,
                    isTab: true,
                    tabDictCode: 'ApprovalStatus',
                    tabQueryProp: 'approval_status',
                },
                return: {
                    column: [
                        {
                            label: '退回单据号',
                            prop: 'code',
                            width: 220,
                            search: true,
                            searchLike: true,
                        },
                        {
                            label: '退回审批状态',
                            prop: 'approval_status',
                            dicUrl: dictService.getUrl('ApprovalStatus'),
                            props: {
                                label: 'dictValue',
                                value: 'dictKey',
                            },
                            dataType: 'number',
                            width: 100,
                        },
                        {
                            label: '退回部门',
                            prop: 'return_dept_name',
                        },
                        {
                            label: '退回人',
                            prop: 'return_user_name',
                        },
                        {
                            label: '退回人',
                            prop: 'return_user_id',
                            width: 150,
                            hide: true,
                            showColumn: false,
                            search: true,
                            type: 'select',
                            component: 'basic-user-select',
                        },
                        {
                            label: '退回日期',
                            prop: 'return_date',
                            type: 'date',
                            format: 'yyyy-MM-dd',
                        },
                        {
                            label: '退回说明',
                            prop: 'remarks',
                        },
                    ],
                    model: 'wisdom_park.wp_asset_collection_return',
                    detailTable: 'wp_asset_collection_return_detail',
                    masterIdColumnName: 'return_id',
                    title: '退回单',
                    bizCode: this.$PCode.BIZ.ASSET_RETURN,
                    actionCode: this.$PCode.ACTIONS.SUBMIT,
                    isTab: true,
                    tabDictCode: 'ApprovalStatus',
                    tabQueryProp: 'approval_status',
                    tableOption: {
                        searchSpan: 8,
                    },
                },
                collection: {
                    column: [
                        {
                            label: '领用单据号',
                            prop: 'code',
                            width: 220,
                            search: true,
                            searchLike: true,
                        },
                        {
                            label: '领用审批状态',
                            prop: 'approval_status',
                            dicUrl: dictService.getUrl('ApprovalStatus'),
                            props: {
                                label: 'dictValue',
                                value: 'dictKey',
                            },
                            dataType: 'number',
                            width: 100,
                        },
                        {
                            label: '领用部门',
                            prop: 'collection_dept_name',
                            width: 150,
                        },
                        {
                            label: '领用人',
                            prop: 'collection_user_name',
                            width: 150,
                        },
                        {
                            label: '领用人',
                            prop: 'collection_user_id',
                            width: 150,
                            hide: true,
                            showColumn: false,
                            search: true,
                            type: 'select',
                            component: 'basic-user-select',
                        },
                        {
                            label: '领用日期',
                            prop: 'collection_date',
                            type: 'date',
                            format: 'yyyy-MM-dd',
                            width: 90,
                        },
                        {
                            label: '计划领用时长(天)',
                            prop: 'plan_collection_cycle',
                            width: 110,
                        },
                        {
                            label: '领用事由',
                            prop: 'remarks',
                        },
                    ],
                    model: 'wisdom_park.wp_asset_collection',
                    detailTable: 'wp_asset_collection_detail',
                    masterIdColumnName: 'collection_id',
                    title: '领用单',
                    bizCode: this.$PCode.BIZ.ASSET_COLLECTION,
                    actionCode: this.$PCode.ACTIONS.SUBMIT,
                    isTab: true,
                    tabDictCode: 'ApprovalStatus',
                    tabQueryProp: 'approval_status',
                    tableOption: {
                        searchSpan: 8,
                    },
                },
                repair: {
                    column: [
                        {
                            label: '维保单据号',
                            prop: 'code',
                            width: 225,
                            search: true,
                            searchLike: true,
                            searchSpan: 6,
                        },
                        {
                            label: '审批状态',
                            prop: 'approval_status',
                            dicUrl: dictService.getUrl('ApprovalStatus'),
                            props: {
                                label: 'dictValue',
                                value: 'dictKey',
                            },
                            dataType: 'number',
                            width: 100,
                        },
                        {
                            label: '维保状态',
                            prop: 'repair_status',
                            width: 100,
                            dicUrl: dictService.getUrl('RepairStatus'),
                            props: {
                                label: 'dictValue',
                                value: 'dictKey',
                            },
                            dataType: 'number',
                            width: 100,
                        },
                        {
                            label: '维保类型',
                            prop: 'repair_type',
                            width: 100,
                            dicUrl: dictService.getUrl('RepairType'),
                            props: {
                                label: 'dictValue',
                                value: 'dictKey',
                            },
                            dataType: 'number',
                            width: 100,
                        },
                        // {
                        //   label: "维修费用",
                        //   prop: "repair_cost",
                        //   width: 100
                        // },
                        {
                            label: '资产名称',
                            prop: 'asset_name',
                            width: 100,
                        },
                        {
                            label: '资产编码',
                            prop: 'system_asset_code',
                            width: 100,
                            search: true,
                            searchLike: true,
                            searchSpan: 6,
                        },
                        {
                            label: '计划维修日期',
                            prop: 'plan_finish_date',
                            type: 'date',
                            format: 'yyyy-MM-dd',
                            width: 100,
                            search: true,
                            searchRange: true,
                            searchSpan: 12,
                            valueFormat: 'yyyy-MM-dd',
                        },
                        {
                            label: '计划维修时长(天)',
                            prop: 'plan_repair_duration',
                            width: 110,
                        },
                        {
                            label: '申请人',
                            prop: 'report_user_name',
                            width: 100,
                        },
                        {
                            label: '申请人',
                            prop: 'report_user',
                            width: 150,
                            hide: true,
                            showColumn: false,
                            search: true,
                            searchSpan: 12,
                            type: 'select',
                            searchModel: 'user',
                            component: 'basic-user-select',
                        },
                        {
                            label: '申请说明',
                            prop: 'remarks',
                        },
                    ],
                    tableOption: {
                        searchMenuSpan: 12,
                    },
                    model: 'wisdom_park.wp_asset_repair',
                    title: '维保单',
                    bizCode: this.$PCode.BIZ.ASSET_REPARIR,
                    actionCode: this.$PCode.ACTIONS.SUBMIT,
                    isTab: true,
                    tabDictCode: 'ApprovalStatus',
                    tabQueryProp: 'approval_status',
                },
                scrap: {
                    column: [
                        {
                            label: '报废单据号',
                            prop: 'code',
                            width: 220,
                            search: true,
                            searchLike: true,
                        },
                        {
                            label: '报废状态',
                            prop: 'scrap_status',
                            dicUrl: dictService.getUrl('ScrapStatus'),
                            props: {
                                label: 'dictValue',
                                value: 'dictKey',
                            },
                            dataType: 'number',
                            width: 100,
                        },
                        {
                            label: '报废部门',
                            prop: 'scrap_dept_name',
                        },
                        {
                            label: '报废人',
                            prop: 'scrap_user_name',
                        },
                        {
                            label: '报废人',
                            prop: 'scrap_user',
                            width: 150,
                            hide: true,
                            showColumn: false,
                            search: true,
                            type: 'select',
                            searchModel: 'user',
                            component: 'basic-user-select',
                        },
                        {
                            label: '报废日期',
                            prop: 'scrap_date',
                            type: 'date',
                            format: 'yyyy-MM-dd',
                        },
                        {
                            label: '报废说明',
                            prop: 'remarks',
                        },
                    ],
                    model: 'wisdom_park.wp_asset_scrap',
                    detailTable: 'wp_asset_scrap_detail',
                    exportView: 'wisdom_park.v_wp_asset_scrap_detail_export_stat',
                    masterIdColumnName: 'scrap_id',
                    title: '报废单',
                    bizCode: this.$PCode.BIZ.ASSET_SCRAP,
                    actionCode: this.$PCode.ACTIONS.SUBMIT,
                    isTab: true,
                    tabDictCode: 'ScrapStatus',
                    tabQueryProp: 'scrap_status',
                    flowStartBtn: false,
                    tableOption: {
                        menuWidth: 300,
                        searchSpan: 8,
                    },
                    permissionList: {
                        flowViewBtn: 'scrap_flow_view',
                    },
                    btnShow: {
                        edit: row => {
                            return Number(row.scrap_status) < 2 ? true : false;
                        },
                        del: row => {
                            return Number(row.scrap_status) < 2 ? true : false;
                        },
                    },
                },
                inventory: {
                    column: [
                        {
                            label: '盘点单据号',
                            prop: 'code',
                            width: 220,
                        },
                        {
                            label: '盘点名称',
                            prop: 'name',
                            width: 220,
                            search: true,
                            searchSpan: 8,
                            searchLike: true,
                        },
                        {
                            label: '盘点状态',
                            prop: 'inventory_status',
                            dicUrl: dictService.getUrl('InventoryStatus'),
                            props: {
                                label: 'dictValue',
                                value: 'dictKey',
                            },
                            dataType: 'number',
                        },
                        // {
                        //   label: "盘点审批状态",
                        //   prop: "approval_status",
                        //   dicUrl: dictService.getUrl("ApprovalStatus"),
                        //   props: {
                        //     label: "dictValue",
                        //     value: "dictKey",
                        //   },
                        //   dataType: 'number',
                        //   width: 100
                        // },
                        {
                            label: '盘点负责人',
                            prop: 'owner_code_name',
                        },
                        {
                            label: '计划开始时间',
                            prop: 'plan_start_time',
                            type: 'date',
                            width: 120,
                            format: 'yyyy-MM-dd',
                            sortable: 'custom',
                        },
                        {
                            label: '计划结束时间',
                            prop: 'plan_finish_time',
                            type: 'date',
                            width: 120,
                            format: 'yyyy-MM-dd',
                            // sortable: 'custom'
                        },
                        {
                            label: '实际开始时间',
                            prop: 'start_time',
                            width: 150,
                        },
                        {
                            label: '实际完成时间',
                            prop: 'finish_time',
                            width: 150,
                        },
                    ],
                    tableOption: {
                        defaultSort: {
                            prop: 'plan_start_time',
                            order: 'descending',
                        },
                    },
                    model: 'wisdom_park.wp_asset_inventory',
                    detailTable: 'wp_asset_inventory_detail',
                    masterIdColumnName: 'inventory_id',
                    title: '盘点单',
                    actionCode: this.$PCode.ACTIONS.SUBMIT,
                    isTab: true,
                    tabDictCode: 'InventoryStatus',
                    tabQueryProp: 'inventory_status',
                    flowOption: {
                        key: 'asset_category',
                        dictCode: 'AssetCategory',
                        businessTypeKey: 'remark',
                    },
                    flowStartBtn: false,
                    sealBtn: true,
                    btnShow: {
                        del: row => {
                            return Number(row.inventory_status) === 6 ? false : true;
                        },
                    },
                    sealBtnDisplay: row => {
                        if (row.inventory_status == 3) {
                            return true;
                        }
                        return false;
                    },
                    getSealData: row => {
                        return {
                            inventory_status: 6,
                        };
                    },
                    isSeal: row => {
                        return row.inventory_status == 6 ? true : false;
                    },
                    permissionList: {
                        addBtn: 'inventory_add',
                        editBtn: 'inventory_edit',
                        delBtn: 'inventory_del',
                        flowStartBtn: 'inventory_flow_start',
                        flowEndBtn: 'inventory_flow_end',
                        flowViewBtn: 'inventory_flow_view',
                        inventoryStartBtn: 'inventory_start',
                        inventoryEndBtn: 'inventory_end',
                        sealBtn: 'inventory_seal',
                    },
                },
            },
            currentRow: null,
            inventoryTableVisible: false,
            inventoryDialog: {
                title: '',
                type: null,
                loading: false,
                saving: false,
                tableData: [],
                form: {
                    timeValue: '',
                },
                page: {
                    pageSizes: this.$store.state.common.pageSizes,
                    pagerCount: this.$store.state.common.pagerCount,
                    pageSize: 10,
                    currentPage: 1,
                    total: 0,
                },
                tableOption: {
                    height: 300,
                    searchMenuPosition: 'right',
                    searchLabelPosition: 'right',
                    searchMenuSpan: 6,
                    searchLabelWidth: 100,
                    border: true,
                    addBtn: false,
                    editBtn: false,
                    delBtn: false,
                    refreshBtn: false,
                    menu: false,
                    column: [
                        {
                            label: '序号',
                            prop: 'index',
                            width: 60,
                        },
                        {
                            label: '资产编码',
                            prop: 'system_asset_code',
                            width: 160,
                        },
                        {
                            label: '资产名称',
                            prop: 'asset_name',
                        },
                        {
                            label: '盘点人员',
                            prop: 'inventory_user_name',
                            width: 160,
                        },
                    ],
                },
            },
        };
    },
    computed: {
        ...mapGetters(['permission']),
    },
    mounted() {},
    methods: {
        permissionCheck(type) {
            if (this.options[this.type].permissionList) {
                let typeCode = `${type}Btn`;
                let permissionList = this.options[this.type].permissionList;
                if (permissionList[typeCode]) {
                    let permissionCode = permissionList[typeCode];
                    return this.vaildData(this.permission[permissionCode], false);
                }
                return true;
            } else {
                return true;
            }
        },
        openScrapApprovalFinsh(row) {
            this.$confirm('确认归档后该条记录将无法修改, 是否确定归档?', '报损归档', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        instance.confirmButtonLoading = true;
                        scrapApprovalFinsh({
                            id: row.id,
                        })
                            .then(res => {
                                instance.confirmButtonLoading = false;
                                this.$refs.table.onLoad();
                                this.$message.success('操作成功');
                                done();
                            })
                            .catch(e => {
                                instance.confirmButtonLoading = false;
                                done();
                            });
                    } else {
                        done();
                    }
                },
            });
        },
        sealData(row) {
            this.$confirm('确认归档后该条记录将无法修改, 是否确定归档?', '确认要归档？', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                beforeClose: (action, instance, done) => {
                    if (action === 'confirm') {
                        let body = {
                            update: this.options[this.type].getSealData ? this.options[this.type].getSealData(row) : {},
                            primaryKeyValue: row.id,
                            primaryKey: 'id',
                        };
                        instance.confirmButtonLoading = true;
                        updateMetaCommon(this.options[this.type].model, body)
                            .then(res => {
                                instance.confirmButtonLoading = false;
                                this.$refs.table.onLoad();
                                this.$message.success('归档成功');
                                done();
                            })
                            .catch(e => {});
                    } else {
                        done();
                    }
                },
            });
        },
        openRepair(row) {
            let formOption = {
                column: [
                    {
                        label: '维保金额',
                        prop: 'repairCost',
                        type: 'number',
                        span: 24,
                        rules: [{ required: true, message: '请输入维保金额', trigger: 'blur' }],
                    },
                    {
                        label: '完成时间',
                        prop: 'actualFinishDate',
                        type: 'datetime',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                        formar: 'yyyy-MM-dd HH:mm',
                        value: dayjs().format('YYYY-MM-DD HH:mm:00'),
                        span: 24,
                        rules: [{ required: true, message: '请选择完成时间', trigger: 'blur' }],
                    },
                    {
                        label: '维保情况',
                        prop: 'remarks',
                        type: 'textarea',
                        rows: 5,
                        span: 24,
                    },
                ],
            };
            this.$DialogForm.show({
                title: '维保完成',
                width: '500px',
                option: formOption,
                // repairFinsh
                callback: formRes => {
                    let data = {
                        id: row.id,
                    };
                    formOption.column.forEach(item => {
                        data[item.prop] = formRes.data[item.prop];
                    });

                    repairFinsh(data).then(res => {
                        formRes.done();
                        formRes.close();
                        this.$message.success(res.data.msg);
                    });
                },
            });
        },
        startInventoryFlow(row) {
            this.$refs.table.startFlow(row);
        },
        openStartInventory(row, type) {
            this.inventoryDialog.title = type === 1 ? '开始盘点' : '完成盘点';
            this.inventoryDialog.type = type;
            this.currentRow = row;
            this.inventoryTableVisible = true;
            this.inventoryDialog.form.timeValue = dayjs().format('YYYY-MM-DD HH:mm:00');
            this.inventoryDialog.tableData = [];
            if (type === 2) {
                this.inventoryDialog.loading = true;
                getMetaCommonDetail(`wisdom_park.wp_asset_inventory`, {
                    pk: 'id',
                    id: row.id,
                    detailTable: 'wp_asset_inventory_detail',
                    masterIdColumnName: 'inventory_id',
                }).then(res => {
                    let index = 1;
                    (res.data.data.listDetailValues || []).forEach(item => {
                        if (item.inventory_status === 1) {
                            this.inventoryDialog.tableData.push({
                                ...item,
                                index: index,
                            });
                            index++;
                        }
                    });
                    this.inventoryDialog.page.total = this.inventoryDialog.tableData.length;
                    this.inventoryDialog.loading = false;
                });
            }
        },
        inventoryTableDialogSubmit() {
            if (!this.inventoryDialog.form.timeValue) {
                this.$message.warning('请选择日期时间');
            } else {
                let data = {
                    inventory_status: this.inventoryDialog.type === 1 ? 2 : 3,
                };
                if (this.inventoryDialog.type === 1) {
                    data['start_time'] = this.inventoryDialog.form.timeValue;
                } else {
                    data['finish_time'] = this.inventoryDialog.form.timeValue;
                }
                let body = {
                    update: data,
                    primaryKeyValue: this.currentRow.id,
                    primaryKey: 'id',
                };
                this.inventoryDialog.saving = true;
                updateMetaCommon('wisdom_park.wp_asset_inventory', body)
                    .then(res => {
                        this.inventoryDialog.saving = false;
                        this.inventoryTableVisible = false;
                        this.$refs.table.onLoad();
                        this.$message.success(res.data.msg);
                    })
                    .catch(e => {
                        this.inventoryDialog.saving = false;
                    });
            }
        },
    },
};
</script>
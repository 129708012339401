<template>
  <el-row v-loading="cardLoading">
    <el-row style="padding-left: 40px; padding-bottom: 10px">{{
      dashName
    }}</el-row>
    <el-col v-if="option.showTree" :span="4" class="w-300">
      <el-card class="h-preview">
        <previewTree
          :guid="option.guid"
          :treeOption="option.treeOption"
          @treeNodeClick="treeNodeClick"
        ></previewTree>
      </el-card>
    </el-col>
    <el-col
      :span="option.showTree ? 20 : 24"
      :class="{ 'w-calc-300': option.showTree }"
    >
      <el-card class="h-preview">
        <!--搜索表单-->
        <el-col :span="24" v-if="option.seachOption.column.length > 0">
          <avue-form
            :style="{ height: searchHeight }"
            ref="form"
            class="tablePreview"
            v-model="searchForm"
            :option="option.seachOption"
            @submit="handleSearchForm"
          >
            <template slot-scope="scope" slot="menuForm">
              <el-button
                icon="el-icon-search"
                type="primary"
                @click="formSubmit"
                >搜索
              </el-button>
              <el-button icon="el-icon-delete" @click="clearForm"
                >清空
              </el-button>
            </template>
          </avue-form>
        </el-col>
        <!--搜索表单-->
        <!--table-->
        <el-col :span="24" v-if="option.tableOption.column.length > 0">
          <avue-crud
            ref="crud"
            :option="option.tableOption"
            :data="tableList"
            :page.sync="page"
            v-model="tableObj"
            :table-loading="contentLoading"
            @row-save="rowSave"
            @row-del="rowDel"
            @row-update="rowUpdate"
            @on-load="getMetaCommonList"
            @refresh-change="refreshChange"
            @cell-click="cellClick"
          >
            <template slot="menuLeft">
              <!-- <el-button
                type="danger"
                size="small"
                plain
                icon="el-icon-delete"
                v-if="isPermission('view')"
                @click="handleDelete"
                >删 除
              </el-button> -->
              <template v-for="(btn, index) in option.menuList">
                <!-- <template v-if="isPermission(btn.value)"> -->
                <el-button
                  v-if="btn.show && btn.showPosotion == 'left'"
                  :key="index"
                  :type="btn.type"
                  size="small"
                  :icon="btn.icon + ' fts-12'"
                  class="menuBtnList"
                  @click="operations(btn)"
                >
                  {{ btn.label }}
                </el-button>
                <!-- </template> -->
              </template>
            </template>
            <template slot="menu" slot-scope="scope">
              <template v-for="(btn, index) in option.menuList">
                <!-- <template v-if="isPermission(btn.value)"> -->
                <el-button
                  v-if="btn.show && btn.showPosotion == 'table'"
                  :key="index"
                  :type="btn.type"
                  size="small"
                  :icon="btn.icon + ' fts-12'"
                  class="menuBtnList"
                  @click="operations(btn, scope.row, scope.index)"
                >
                  {{ btn.label }}
                </el-button>
                <!-- </template> -->
              </template>
            </template>
          </avue-crud>
        </el-col>
        <!--table-->
      </el-card>
    </el-col>
    <div v-if="addVisible">
      <el-drawer
        size="80%"
        :title="newDesignTitle"
        append-to-body
        destroy-on-close
        :visible.sync="addVisible"
      >
        <newPage
          ref="newPage"
          :guid="option.guid"
          :pk="pk"
          :newOption="optionNewOption"
          :target="option.newOpenType"
          @updatedCallBack="updatedCallBack"
          :operaType="operaType"
        ></newPage>
      </el-drawer>
    </div>
    <el-dialog
      append-to-body
      :visible.sync="tableDialogVisible"
      width="30%"
      custom-class="dis-b"
    >
      <!-- <tableClickCunstom
        :tableClickCunstomOptions="tableClickCunstomOptions"
      ></tableClickCunstom> -->
    </el-dialog>
  </el-row>
</template>
<script>
import {
  getMetaCommonDetail,
  saveMetaCommon,
  updateMetaCommon,
  getMetaCommonList,
  getMetaCommonTree,
  delMetaCommon,
  getMetaCommonInfo,
  getMetaCommonListByDict,
  getPicListByIds,
} from "@/api/busiMode/metaCommon";
import { saveDynamicForm, getDetailByPlanId } from "@/api/busiMode/meta";
import { allOptions, operBtns } from "../../../util/option";
import { mapGetters, mapState } from "vuex";
import newPage from "../design/newPage";
import previewTree from "./previewTree";
import axios from "@/router/axios";
import { eventRun } from "@/api/tool/eventModel";

export default {
  name: "tableDesign",

  components: { newPage, previewTree },
  data() {
    return {
      searchForm: {},
      treeSearch: {},
      oldSearchForm: {},
      tableObj: {},
      addForm: {},
      addId: "",
      option: allOptions,
      tableList: [],
      entity: "",
      searchHeight: "43px",
      cardLoading: false,
      contentLoading: false,
      page: {
        currentPage: 1,
        total: 10,
        pageSize: 10,
      },
      addVisible: false,
      operaType: "",
      currIndex: 999,
      pk: "",
      selects: [],
      newDesignTitle: "",
      permissionList: [],
      tableDialogVisible: false,
      optionNewOption: {},
      tableClickCunstomOptions: {},
      modelId: "",
    };
  },
  props: {
    modelId: {
      type: String,
      default: function () {
        return "";
      },
    },
    dashName: {
      type: String,
      default: function () {
        return "";
      },
    },
  },
  created() {},
  computed: {
    ...mapState({
      viewEdit: (state) => state.common.viewEdit,
    }),
    ...mapGetters(["permission"]),
  },
  mounted() {
    if (this.modelId == "") {
      this.modelId = this.$route.fullPath.split(
        "/data/table/tableCommon/?modleId"
      )[1];
    }

    if (this.modelId) {
      this.getDetailByPlanId();
    }
    Object.keys(this.permission).forEach((perm) => {
      this.permissionList.push(perm);
    });
  },
  watch: {
    $route() {
      this.option = allOptions;
      this.cardLoading = false;
      this.contentLoading = false;
      this.treeOptionRefresh = false;
      this.modelId = this.$route.fullPath.split(
        "/data/table/tableCommon/?modleId"
      )[1];
      if (this.modelId) {
        this.getDetailByPlanId();
      }
    },
    modelId: {
      handler: function (newValue, oldName) {
        if (newValue) {
          this.getDetailByPlanId();
        }
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    cellClick(row, column, cell, event) {
      event.preventDefault();
      let tableURL = Array.from(
        this.option.tableOperList,
        ({ field }) => field
      );
      if (tableURL.includes(column.property)) {
        this.option.tableOperList.forEach((t) => {
          operBtns.forEach((e) => {
            if (e.key == t.key && t.field == column.property) {
              if (e.operType == "dict") {
                this.tableDialogVisible = true;
                this.tableClickCunstomOptions = e;
              }
              if (e.operType == "js") {
                let tip = e.tip == "是否继续该操作" ? "" : e.tip;
                this.$confirm(tip, "提示", {
                  confirmButtonText: "确定",
                  cancelButtonText: "取消",
                  type: "warning",
                })
                  .then(() => {
                    let click = e.click.replace(
                      "#query#",
                      row[column.property]
                    );
                    eval(click);
                  })
                  .catch(() => {
                    this.$message({
                      type: "info",
                      message: "已取消当前操作",
                    });
                  });
              }
            }
          });
        });
      }
    },
    updatedCallBack(call) {
      if (call) {
        this.$refs.crud.refreshChange();
      }
      this.addVisible = false;
    },
    formSubmit() {
      this.$refs.form.submit();
    },
    isPermission(type) {
      let show = false;
      if (this.permissionList.includes(this.modelId + "_" + type)) {
        show = true;
      }
      return show;
    },
    clearAddForm() {
      this.$refs.addForm.resetForm();
      this.$refs.addForm.submit();
    },
    treeNodeClick(obj) {
      this.treeSearch = obj;
      this.refreshChange();
    },
    clearForm() {
      this.$refs.form.resetForm();
      this.$refs.form.submit();
    },
    formatSearchOption() {
      this.option.seachOption.column.forEach((e) => {
        if (e.type == "cascader") {
          e.checkStrictly = true;
          e.lazyLoad = (node, resolve) => {
            let stop_level = 2;
            let level = node.level;
            let data = node.data || {};
            let code = data.code;
            let list = [];
            let callback = () => {
              resolve(
                (list || []).map((ele) => {
                  return Object.assign(ele, {
                    leaf: level >= stop_level,
                  });
                })
              );
            };
            if (level == 0) {
              axios
                .get(`/api/jida-system/region/lazy-tree?parentCode=00`)
                .then((res) => {
                  list = res.data.data;
                  callback();
                });
            } else if (level == 1) {
              axios
                .get(
                  `/api/jida-system/region/lazy-tree?parentCode=` +
                    node.data.value
                )
                .then((res) => {
                  list = res.data.data;
                  callback();
                });
            } else if (level == 2) {
              axios
                .get(
                  `/api/jida-system/region/lazy-tree?parentCode=` +
                    node.data.value
                )
                .then((res) => {
                  list = res.data.data;
                  callback();
                });
            } else {
              callback();
            }
          };
        }
        if (e.type == "map") {
          if (e.mapType == "quhf") {
            this.mapqygh = e.prop;
            this.mapqyghValue = this.addForm[this.mapqygh];
          } else {
            this.formOption[e.prop] = e;
          }
        }
        if (e.type == "upload") {
          this.currentUploadObj = e;
        }
        if (e.valueDefault) {
          e.display = false;
        }
      });

      this.option.seachOption.submitBtn = false;
      this.option.seachOption.emptyBtn = false;
      this.option.tableOption.border = true;
      this.option.seachOption.labelSuffix = " ";
    },
    formatNewOption() {
      this.option.newOption.column.forEach((n) => {
        if (n.type == "upload") {
          n.propsHttp = {
            res: "data",
            url: "link",
            name: "attachId",
          };
          delete n.rules;
        }
      });
      this.optionNewOption = this.option.newOption;
    },

    getDetailByPlanId() {
      this.cardLoading = true;
      getDetailByPlanId({ id: this.modelId }).then((res) => {
        if (res.data.code == 200) {
          this.option = JSON.parse(res.data.data.content);
          this.formatSearchOption();
          this.formatNewOption();
          this.entity = this.option.guid;
          this.selects = [];
          let tableURL = [];
          if (this.option.tableOperList) {
            tableURL = Array.from(
              this.option.tableOperList,
              ({ field }) => field
            );
          }

          this.option.tableOption.column.forEach((element) => {
            if (element.showFiledBackfill) {
              element.prop = element.showFiledBackfill.split("@")[0];
              if (!element.hide) {
                this.selects.push(element.showFiledBackfill.split("@")[1]);
              }
              //this.selects.push(element.showFiledBackfill);
            } else {
              if (!element.hide) {
                this.selects.push(element.prop);
              }
            }
            if (tableURL.includes(element.prop)) {
              element.type = "url";
            }
          });
          this.pk = this.option.PK;
          this.selects.push(this.pk);
          this.cardLoading = false;
          if (this.entity) {
            this.handleSearchForm();
          }
        }
      });
    },
    getMetaCommonList() {
      this.contentLoading = true;
      if (this.entity) {
        let o = {
          current: this.page.currentPage,
          size: this.page.pageSize,
        };
        if (this.treeSearch) {
          o["wheres"] = [this.treeSearch];
        }
        if (this.selects.length) {
          o["selects"] = this.selects;
        }
        if (this.option.tableQuery && o) {
          o["orders"] = this.option.tableQuery.orders;

          o["wheres"] = o["wheres"].concat(this.option.tableQuery.wheres);
        }
        getMetaCommonList(this.entity, o)
          .then((res) => {
            this.tableList = [];
            if (res.data.code == 200) {
              this.tableList = res.data.data.records;
              this.page.total = res.data.data.total;
              if (this.treeData.length > 0) {
                res.data.data.records.forEach((element) => {
                  this.treeData.push({
                    label: element[model.labelField],
                    value: element[model.valueField],
                    children: [],
                  });
                });
              }
            }
            this.contentLoading = false;
          })
          .catch(() => {
            this.contentLoading = false;
          });
      }
    },
    refreshChange() {
      let search = {
        current: this.page.currentPage,
        size: this.page.pageSize,
      };
      let objWhere = [];
      if (JSON.stringify(this.treeSearch) != "{}") {
        objWhere.push(this.treeSearch);
      }
      Object.keys(this.searchForm).forEach((ele) => {
        if (!ele.includes("$") && this.searchForm[ele] != "") {
          let o = {
            field: ele,
            value: this.searchForm[ele],
          };
          this.option.seachOption.column.forEach((element) => {
            if (ele == element.prop && element.type == "input") {
              o.op = "LIKE";
            }
          });
          objWhere.push(o);
        }
      });
      if (objWhere.length > 0) {
        search.wheres = objWhere;
      }
      if (this.selects.length > 0) {
        search["selects"] = this.selects;
      }

      if (this.option.tableQuery && search) {
        search["orders"] = this.option.tableQuery.orders;
        let wheres = search["wheres"] ? search["wheres"] : [];
        search["wheres"] = wheres.concat(this.option.tableQuery.wheres);
      }
      if (this.entity) {
        getMetaCommonList(this.entity, search)
          .then((res) => {
            this.tableList = [];
            if (res.data.code == 200) {
              this.tableList = res.data.data.records;
              this.page.total = res.data.data.total;
              this.oldSearchForm = JSON.parse(JSON.stringify(search));
              //done();
            }
          })
          .catch(() => {
            // done();
            this.$refs.form.resetForm();
          });
      }
    },
    handleSearchForm(form, done) {
      let search = {
        current: this.page.currentPage,
        size: this.page.pageSize,
      };
      let objWhere = [];
      if (JSON.stringify(this.treeSearch) != "{}") {
        objWhere.push(this.treeSearch);
      }
      Object.keys(this.searchForm).forEach((ele) => {
        if (!ele.includes("$") && this.searchForm[ele] != "") {
          let o = {
            field: ele,
            value: this.searchForm[ele],
          };
          let time = [];
          this.option.seachOption.column.forEach((element) => {
            console.log(element.type);
            if (ele == element.prop && element.type == "input") {
              o.op = "LIKE";
            }
            if (ele == element.prop && element.type == "datetimerange") {
              time = [
                { field: ele, value: this.searchForm[ele][0], op: "GT" },
                { field: ele, value: this.searchForm[ele][1], op: "LE" },
              ];
            }
          });
          if (time.length > 0) {
            objWhere = objWhere.concat(time);
          } else {
            objWhere.push(o);
          }
        }
      });

      if (objWhere.length > 0) {
        search.wheres = objWhere;
      }
      if (this.selects.length > 0) {
        search["selects"] = this.selects;
      }
      if (this.option.tableQuery && search) {
        search["orders"] = this.option.tableQuery.orders;
        let wheres = search["wheres"] ? search["wheres"] : [];
        search["wheres"] = wheres.concat(this.option.tableQuery.wheres);
      }
      if (
        JSON.stringify(this.oldSearchForm) != JSON.stringify(search) &&
        this.entity &&
        JSON.stringify(search) != ""
      ) {
        getMetaCommonList(this.entity, search)
          .then((res) => {
            this.tableList = [];
            if (res.data.code == 200) {
              this.tableList = res.data.data.records;
              this.page.total = res.data.data.total;
              this.oldSearchForm = JSON.parse(JSON.stringify(search));
              this.contentLoading = false;
              if (done) {
                done();
              }
            }
          })
          .catch(() => {
            this.$refs.form.resetForm();
            if (done) {
              done();
            }
          });
      } else {
        if (done) {
          done();
        }
      }
    },
    operations(type, row, index) {
      console.log(row);
      this.currIndex = index;
      this.operaType = type;
      if (type.value == "service") {
        this.eventRun(type.serviceId, row);
      } else {
        switch (type.value) {
          case "add":
            if (this.option.newOpenType == "target") {
              this.$store.commit("SET_GUID", this.entity);
              this.$store.commit("SET_SHOW_NEW", true);
              if (this.viewEdit) {
                this.$router.push({
                  path: "/data/table/tableCommon/?modleId" + this.modelId,
                });
              } else {
                this.$breadcrumb.add({
                  name: "新增",
                });
                this.$router.push({
                  path: "/data/table/newPage/?modleId" + this.modelId,
                });
              }
            } else {
              this.addVisible = true;
              this.addForm = {};
              if (this.treeSearch.value) {
                this.addForm[this.option.treeOption.dataOption.label] =
                  this.treeSearch.value;
              }
              let t = this;
              setTimeout(function () {
                t.$refs.newPage.addForm = t.addForm;
                t.optionNewOption.disabled = false;
                t.$refs.newPage.serviceId = type.serviceId;

                //t.$refs.newPage.formDisable = false;
              }, 100);
            }
            this.newDesignTitle = "新增";
            break;
          case "edit":
            if (type.serviceId) {
              this.eventRun(type.serviceId, row);
            } else {
              this.rowUpdate(row, index);
            }
            this.newDesignTitle = "编辑";
            break;
          case "view":
            this.rowUpdate(row, index);
            this.newDesignTitle = "查看";
            break;
          case "del":
            if (type.serviceId) {
              this.eventRun(type.serviceId, row);
            } else {
              this.rowDel(row, index);
            }

            break;
        }
      }
    },
    eventRun(serviceId, row) {
      eventRun(serviceId, row)
        .then((res) => {
          if (res.data.code == 200) {
            console.log("运行成功");
          }
        })
        .catch(() => {});
    },
    async getPic() {
      let res = await axios
        .get(`/api/jida-system/region/lazy-tree?parentCode=00`)
        .then((r) => {});
      return res;
    },
    rowUpdate(row, index, done) {
      getMetaCommonInfo(this.option.guid, {
        pk: this.pk,
        id: row[this.pk],
      }).then((res) => {
        if (res.data.code == 200) {
          let resData = JSON.parse(JSON.stringify(res.data.data));
          let num = [];
          new Promise((resolve, reject) => {
            this.option.newOption.column.forEach(async (e, i) => {
              //回填照片
              if (e.type == "upload" && res.data.data[e.prop] != "") {
                let arr = [];
                if (res.data.data[e.prop]) {
                  await getPicListByIds(res.data.data[e.prop]).then((res) => {
                    if (res.data.code == 200) {
                      res.data.data.forEach((pic) => {
                        arr.push({
                          label: pic.id,
                          value: pic.link,
                        });
                      });
                      // arr = res.data.data;
                    }
                  });
                  res.data.data[e.prop] = arr;
                }
              } else if (e.type == "map") {
                if (e.mapType == "quhf") {
                  e.formslot = true;
                  e.rules = [e.rules[0]];
                  res.data.data[e.prop] = res.data.data[e.prop].split(",");
                } else {
                  if (e.coordinateType == "longitude") {
                    //经度
                    this.option.newOption.column.forEach((element) => {
                      if (
                        element.type == "map" &&
                        element.coordinateType == "latitude"
                      ) {
                        res.data.data[e.prop] = [
                          resData[e.prop],
                          resData[element.prop],
                          resData[e.prop],
                        ];
                      }
                    });
                  } else if (e.coordinateType == "latitude") {
                    //纬度
                    this.option.newOption.column.forEach((element) => {
                      if (
                        element.type == "map" &&
                        element.coordinateType == "longitude"
                      ) {
                        res.data.data[e.prop] = [
                          resData[element.prop],
                          resData[e.prop],
                          resData[e.prop],
                        ];
                      }
                    });
                  } else if (e.coordinateType == "latitude_and_longitude") {
                    //经纬度
                    res.data.data[e.prop] = res.data.data[e.prop].split(",");
                  }
                }
              } else if (e.type == "cascader") {
                let cascader = res.data.data[e.prop];
                if (cascader) {
                  let xzqh = "";
                  if (cascader.length == 2) {
                    xzqh = cascader;
                  }
                  if (cascader.length == 4) {
                    xzqh = cascader[0] + cascader[1] + "," + cascader;
                  }
                  if (cascader.length == 6) {
                    xzqh =
                      cascader[0] +
                      cascader[1] +
                      "," +
                      cascader[0] +
                      cascader[1] +
                      cascader[2] +
                      cascader[3] +
                      "," +
                      cascader;
                  }

                  res.data.data[e.prop] = xzqh;
                }
              }
              num.push(i);
              if (num.length == this.option.newOption.column.length) {
                resolve(res.data.data);
              }
            });
          }).then((res) => {
            this.target = "dialog";
            this.addForm = res;
            this.addVisible = true;

            let t = this;
            setTimeout(function () {
              t.$refs.newPage.addForm = t.addForm;
              t.$refs.newPage.formDisable = true;
              if (t.operaType == "view") {
                t.$refs.newPage.formDisable = true;
              } else {
                t.$refs.newPage.formDisable = false;
              }
            }, 100);
          });
        }
      });
    },
    handleAdd(row, index, done) {},
    rowSave(row, done) {
      saveMetaCommon(this.addObj).then((res) => {
        this.$refs.crud.rowAdd();
        this.tableList.push(row);
        done();
      });
    },
    rowDel(row, index) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let pk = this.pk ? this.pk : "id";
          delMetaCommon(this.entity, { pk: this.pk, ids: [row[pk]] }).then(
            (res) => {
              this.$message({
                type: "success",
                message: "操作成功!",
              });
              this.tableList.splice(index, 1);
              //done();
            }
          );
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss">
.h-preview {
  height: calc(100vh - 134px);
}
.h-previewtree {
  height: calc(100vh - 215px);
  .el-tree {
    & > .el-tree-node:first-child {
      .el-tree-node__content {
        padding-left: 22px !important;
        .el-tree-node__expand-icon {
          display: none;
        }
      }
    }
  }
}
</style>

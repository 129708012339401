<template>
    <div>
        <avue-crud :option="option" :table-loading="loading" :data="data" ref="crud" v-model="form" :page.sync="page" @refresh-change="refreshChange" @on-load="onLoad">
            <template slot="menuLeft" slot-scope="scope">
                <el-input v-model="searchText" @keyup.native.enter="refreshChange" size="small" placeholder="请输入人员名称" type="text" style="width: 250px">
                    <el-button class="mar-0 pad-0" size="small" @click="refreshChange" slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </template>
            <template slot-scope="scope" slot="menu">
                <template>
                    <el-button type="text" size="small" icon="el-icon-delete" class="none-border" @click="handleDelMetadata(scope.row)">删除
                    </el-button>
                    <el-button type="text" size="small" icon="el-icon-setting" class="none-border" @click="setAdmin('0', scope.row)">处理
                    </el-button>
                </template>
            </template>
        </avue-crud>
    </div>
</template>

<script>
import { getMetaCommonList, delMetaCommon, saveMetaCommon, updateMetaCommon } from '@/api/busiMode/metaCommon';
import { fastCheckInComPanyProcess } from '@/api/visitor/visitor';
import * as dictService from '@/api/system/dictbiz';

const viewPortHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
// import addBuild from "./addBuild";
import { mapGetters, mapState } from 'vuex';
import baseInfo from './baseInfo';

export default {
    components: { baseInfo },
    computed: {
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    data() {
        return {
            searchText: '',
            form: {},
            loading: true,
            page: {
                size: 10,
                current: 1,
                total: 0,
            },
            model: 'wisdom_park.wp_employee',
            option: {
                height: 'auto',
                dialogWidth: 900,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: false,
                labelWidth: 120,
                menu: true,
                menuWidth: 150,
                viewBtn: false,
                dialogClickModal: false,
                delBtn: false,
                editBtn: false,
                addBtn: false,
                cancelBtn: false,
                selection: true,
                column: [
                    {
                        width: 100,
                        overHidden: true,
                        align: 'right',
                        label: '主键',
                        prop: 'id',
                        hide: true,
                    },
                    {
                        width: 200,
                        overHidden: true,
                        align: 'left',
                        label: '公司名称',
                        prop: 'company_name',
                        hide: false,
                    },
                    {
                        width: 100,
                        overHidden: true,
                        align: 'left',
                        label: '员工姓名',
                        prop: 'name',
                        hide: false,
                    },
                    {
                        width: 150,
                        label: '管理员状态',
                        prop: 'is_admin',
                        type: 'select',
                        dicData: [
                            {
                                label: '管理员',
                                value: 1,
                            },
                            {
                                label: '非管理员',
                                value: 0,
                            },
                        ],
                    },
                    {
                        width: 100,
                        overHidden: true,
                        align: 'left',
                        label: '工号',
                        prop: 'job_num',
                        hide: true,
                    },
                    {
                        label: '性别',
                        prop: 'sex',
                        type: 'select',
                        dicData: [
                            {
                                label: '男',
                                value: 0,
                            },
                            {
                                label: '女',
                                value: 1,
                            },
                            {
                                label: '未知',
                                value: -1,
                            },
                        ],
                    },
                    {
                        width: 200,
                        overHidden: true,
                        align: 'left',
                        label: '联系方式',
                        prop: 'contact_details',
                        hide: false,
                    },

                    {
                        width: 100,
                        overHidden: true,
                        align: 'right',
                        label: '所属企业id',
                        prop: 'company_id',

                        hide: true,
                    },
                    // {
                    //   width: 200,
                    //   overHidden: true,
                    //   align: "left",
                    //   label: "所属企业名称",
                    //   prop: "company_name",
                    //   hide: true,
                    // },

                    {
                        width: 150,
                        overHidden: true,
                        align: 'left',
                        label: '附件集合id',
                        prop: 'attachment_id',
                        hide: true,
                    },
                    {
                        width: 150,
                        overHidden: true,
                        align: 'left',
                        label: '身份证号码',
                        prop: 'identity_card',
                        hide: true,
                    },
                    {
                        width: 100,
                        overHidden: true,
                        align: 'left',
                        label: '年龄',
                        prop: 'age',
                        hide: true,
                    },
                    {
                        width: 200,
                        overHidden: true,
                        align: 'left',
                        label: '居住地址',
                        prop: 'address',
                        hide: true,
                    },
                    {
                        width: 150,
                        overHidden: true,
                        align: 'left',
                        label: '最高学历',
                        prop: 'highest_education',
                        hide: true,
                    },
                    {
                        width: 150,
                        overHidden: true,
                        align: 'left',
                        label: '政治面貌',
                        prop: 'political_status',
                        hide: true,
                    },
                    {
                        width: 150,
                        overHidden: true,
                        align: 'left',
                        type: 'date',
                        hide: true,
                        label: '入职时间',
                        prop: 'employment_date',
                        format: 'yyyy年MM月dd日',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                    },
                    {
                        label: '状态',
                        prop: 'status',
                        type: 'select',
                        dicData: [
                            {
                                label: '在职',
                                value: 1,
                            },
                            {
                                label: '离职',
                                value: 2,
                            },
                            {
                                label: '退休',
                                value: 3,
                            },
                            {
                                label: '其它',
                                value: 3,
                            },
                        ],
                    },

                    {
                        width: 200,
                        label: '账户开通状态',
                        prop: 'account_status',
                        type: 'select',
                        dicData: [
                            {
                                label: '待开通',
                                value: 0,
                            },
                            {
                                label: '已开通',
                                value: 1,
                            },
                            {
                                label: '已禁用',
                                value: 2,
                            },
                            {
                                label: '开通失败',
                                value: 3,
                            },
                        ],
                    },

                    {
                        width: 200,
                        label: '人脸识别开通状态',
                        prop: 'face_ocr_status',
                        type: 'select',
                        dicUrl: dictService.getUrl('faceOcrStatus'),
                        props: {
                            label: 'dictValue',
                            value: 'dictKey',
                        },
                    },
                ],
            },
            data: [],
        };
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.code_add, false),
                viewBtn: this.vaildData(this.permission.code_view, false),
                delBtn: this.vaildData(this.permission.code_delete, false),
                editBtn: this.vaildData(this.permission.code_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
        ...mapState({
            userInfo: state => state.user.userInfo,
        }),
    },
    mounted() {},
    methods: {
        handleDelMetadata(row) {
            this.$confirm('是否删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    let pk = 'id';
                    delMetaCommon(this.model, { pk: pk, ids: [row[pk]] }).then(res => {
                        this.$message({
                            type: 'success',
                            message: '操作成功!',
                        });
                        this.onLoad();
                    });
                })
                .catch(() => {});
        },
        setAdmin(num, row) {
            this.$confirm('确定同意【' + row.name + '】加入【' + row.company_name + '】?', {
                distinguishCancelAndClose: true,
                confirmButtonText: '同意',
                cancelButtonText: '拒绝',
                type: 'warning',
            })
                .then(() => {
                    let o = {
                        isApproved: true,
                        id: row.id,
                    };
                    fastCheckInComPanyProcess(o).then(res => {
                        if (res.data.code == 200) {
                            this.$message.success('审核通过');
                            this.onLoad();
                        }
                    });
                })
                .catch(action => {
                    console.log(action);
                    if (action == 'cancel') {
                        let o = {
                            isApproved: false,
                            id: row.id,
                        };
                        fastCheckInComPanyProcess(o).then(res => {
                            if (res.data.code == 200) {
                                this.$message.success('已拒绝');
                                this.onLoad();
                            }
                        });
                    }
                });
        },
        refreshChange() {
            this.onLoad(this.page);
        },
        onLoad() {
            let body = {
                current: this.page.currentPage,
                size: this.page.pageSize,
                wheres: [
                    {
                        field: 'account_status',
                        value: 0,
                    },
                ],
                orders: [{ field: 'update_time', sort: 'DESC' }],
            };
            if (this.userInfo.companyId && this.userInfo.tenant_id == '000002') {
                body['wheres'].push({
                    field: 'company_Id',
                    value: this.userInfo.companyId,
                });
            }

            if (this.searchText) {
                body['wheres'].push({
                    field: 'name',
                    op: 'LIKE',
                    value: this.searchText,
                });
            }

            this.loading = true;
            getMetaCommonList(this.model, body).then(res => {
                const data = res.data.data;
                this.page.total = data.total;
                data.records.forEach(e => {
                    if (e.face_ocr_status) {
                        e.face_ocr_status = e.face_ocr_status + '';
                    }
                    if (e.face_ocr_status == 0) {
                        e.face_ocr_status = '0';
                    }
                });
                this.data = data.records;
                this.loading = false;
            });
        },
    },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.deleteIcon {
    font-size: 18px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    z-index: 1;
}
/deep/ .el-dialog__header {
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 15px;
}
/deep/ .el-drawer__header {
    border-bottom: 1px solid #ebebeb;
    padding: 10px 20px;
    margin-bottom: 0px;
}
/deep/ .el-drawer__body {
    padding: 0px;
}
.buildDetail /deep/ .el-dialog__body {
    padding: 0px;
}
</style>

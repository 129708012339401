<template>
  <div>
    <el-form ref="form" label-width="80px">
      <el-form-item label="名称">
        <el-input v-model="btnEditObj.label"></el-input>
      </el-form-item>
      <el-form-item label="图标" class="h-40">
        <el-popover
          ref="popover"
          placement="bottom"
          width="450"
          trigger="click"
        >
          <el-tabs v-model="activeName">
            <el-tab-pane
              v-for="(item, index) of iconList"
              :key="index"
              :label="item.label"
              :name="'' + index"
            >
              <el-row class="h-300 ov-a">
                <el-col
                  :span="4"
                  v-for="(icon, index) of item.list"
                  :key="index"
                >
                  <div
                    @click="iconClick(icon)"
                    title="点击切换图标(className)"
                    class="w-100p h-60 icon-item cur-p"
                  >
                    <i class="fts-30" :class="icon"></i>
                  </div>
                </el-col>
              </el-row>
            </el-tab-pane>
          </el-tabs>
          <div slot="reference">
            <el-button
              style="width: 48px; height: 38px; border-style: dashed"
              size="small"
              :icon="btnEditObj.icon"
            ></el-button>
          </div>
        </el-popover>
        <i class="el-icon-delete" @click="deleteIcon"></i>
      </el-form-item>
      <el-form-item label="默认创建">
        <el-radio-group v-model="btnEditObj.openType">
          <el-radio label="form">表格</el-radio>
          <el-radio label="table">表单</el-radio>
          <el-radio label="layout">看板</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item>
        <el-button size="small" type="primary" @click="onSubmit"
          >确定</el-button
        >
        <el-button size="small">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import iconList from "@/config/iconList";
import { getLazyList, remove, update, add, getMenu } from "@/api/system/menu";

export default {
  data() {
    return {
      btnEditObj: {},
      iconList: iconList,
      activeName: 0,
      value: [],
      options: [
        {
          value: "zhinan",
          label: "指南",
          children: [
            {
              value: "shejiyuanze",
              label: "设计原则",
              children: [
                {
                  value: "yizhi",
                  label: "一致",
                },
              ],
            },
          ],
        },
      ],
    };
  },
  created() {
    console.log(this.$store.state.user.menu);
  },
  methods: {
    deleteIcon() {
      this.btnEditObj["icon"] = "";
    },
    iconClick(icon) {
      this.$refs.popover.showPopper = false;
      this.btnEditObj["icon"] = icon;
      this.btnEditObj = JSON.parse(JSON.stringify(this.btnEditObj));
    },
    onSubmit() {
      let obj = {
        alias: this.btnEditObj["label"],
        category: 1,
        code: "tableCommon" + this.btnEditObj["label"],
        isOpen: 1,
        name: this.btnEditObj["label"],
        parentId: "",
        path: "/data/pageComm/" + this.btnEditObj["label"],
        remark: "",
        sort: this.$store.state.user.menu.length + 1,
        source: this.btnEditObj["icon"],
      };
      add(obj).then(
        (res) => {
          // 获取新增数据的相关字段
          const data = res.data.data;
          this.$emit("saveNewPage", [obj, this.btnEditObj.openType]);
        },
        (error) => {}
      );
    },
    handleChange() {},
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/default-vars.scss";
@import "@/styles/vars.scss";
.el-icon-delete {
  // display: none;
  position: absolute;
  top: 15px;
  left: 60px;
  cursor: pointer;
  color: #f56c6c;
}
.icon-item {
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: $--color-primary;
    background: $--background-color-base;
  }
}
</style>

import * as dictService from "@/api/system/dictbiz";

export const option = {
  height: "auto",
  dialogWidth: 900,
  tip: false,
  searchShow: true,
  searchMenuSpan: 4,
  border: true,
  index: true,
  // selection: true,
  labelWidth: 120,
  menuWidth: 300,
  menuAlign: "left",
  viewBtn: false,
  dialogClickModal: false,
  delBtn: false,
  editBtn: false,
  emptyBtn: true,
  addBtn: false,
  cancelBtn: false,
  column: [
    {
      width: 150,
      overHidden: true,
      label: "所属楼宇",
      align: "center",
      prop: "building_name",
      hide: false,
    },
    {
      width: 100,
      overHidden: true,
      label: "房源楼层",
      align: "center",
      prop: "floor_name",
      hide: false,
    },
    {
      width: 150,
      overHidden: true,
      label: "房源名称",
      align: "center",
      prop: "name",
      hide: false,

      searchSpan: 5,
      search: true,
    },
    {
      overHidden: true,
      label: "房源类型",
      align: "center",
      prop: "subject_type",
      hide: false,
    },

    {
      width: 100,
      overHidden: true,
      align: "center",
      label: "房源面积(m²)",
      prop: "area",
    },

    {
      overHidden: true,
      label: "建成年代",
      align: "center",
      align: "text",
      prop: "building_year",
      type: "date",
      format: "yyyy",
      valueFormat: "yyyy",
    },
    {
      overHidden: true,
      label: "租售方式",
      align: "center",
      prop: "rental_sales_type",
    },

    {
      width: 150,
      overHidden: true,
      label: "可入驻时间",
      prop: "immigration_time",
      type: "datetime",
      format: "yyyy-MM-dd",
      align: "center",
      valueFormat: "yyyy-MM-dd",
      hide: false,
    },

    {
      width: 120,
      overHidden: true,
      label: "出租单价(元/月)",
      align: "center",
      prop: "rental_unit_price_month",
      hide: false,
      align: "right",
    },
    {
      width: 120,
      overHidden: true,
      label: "物业费",
      align: "center",
      prop: "property_management_fee",
      hide: false,
      align: "right",
    },
    // {
    //   width: 120,
    //   overHidden: true,
    //   label: "付款方式",
    //   prop: "payment_type",
    //   hide: false,
    //   align: "right",
    // },
    {
      width: 120,
      overHidden: true,
      label: "联系人",
      align: "center",
      prop: "contact_person", // publish_user
      hide: false,
      align: "right",
    },
    {
      width: 120,
      label: "联系电话",
      align: "center",
      prop: "contact_phone",
      align: "right",
    },
    {
      overHidden: true,
      label: "房源用途",
      prop: "housing_resources_type",
      search: true,
      align: "center",
      hide: false,
      type: "select",
      searchSpan: 5,
      dicData: [
        {
          label: "办公",
          value: 1,
        },
        {
          label: "商铺",
          value: 2,
        },
        {
          label: "公寓",
          value: 3,
        },
        {
          label: "其它",
          value: 4,
        },
      ],
    },
    {
      overHidden: true,
      label: "发布状态",
      prop: "online_status",
      search: true,
      align: "center",
      hide: false,
      type: "select",
      searchSpan: 5,
      dicData: [
        {
          label: "上线",
          value: 1,
        },
        {
          label: "下线",
          value: 2,
        },
      ],
    },
    {
      label: "出租状态",
      prop: "status",
      search: true,
      type: "select",
      align: "center",
      searchSpan: 5,
      dicData: [
        {
          value: 0,
          label: "未出租",
        },
        {
          value: 1,
          label: "已出租",
        },
        {
          value: 2,
          label: "已售",
        },
        {
          value: 3,
          label: "自用",
        },
      ],
    },
    // {
    //   width:200,
    //   label: "合同编号",
    //   prop: "contract_code",

    // },
  ],
};

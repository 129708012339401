  <template>
  <div>
    <el-card>
      <!-- 年和月 -->
      <el-row type="flex" justify="end">
        <el-col :span="12">
          <el-select
            v-model="stadium"
            placeholder="场馆"
            size="small"
            style="width: 120px; margin-left: 10px"
            @change="dataChange"
          >
            <el-option
              v-for="item in 12"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
          <el-select
            v-model="type"
            placeholder="场地类型"
            size="small"
            style="width: 120px; margin-left: 10px"
            @change="dataChange"
          >
            <el-option
              v-for="item in 12"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-col>
        <el-col :span="12">
          <!-- 年 用组件给定一个日期，日期获取年，年取前五年 + 后五年 -->
          <el-select
            v-model="currentYear"
            size="small"
            style="width: 120px"
            @change="dataChange"
          >
            <el-option
              v-for="item in yearList"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
          <!-- 月份 -->
          <el-select
            v-model="currentMonth"
            size="small"
            style="width: 120px; margin-left: 10px"
            @change="dataChange"
          >
            <!-- 循环 -->
            <el-option
              v-for="item in 12"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-col>
      </el-row>
      <!-- 放置一个日历组件 -->
      <el-calendar :rang="rangCal">
        <template slot="dateCell" slot-scope="{ data }">
          <!-- date是当前单元格的日期 data是对象 对象里有要显示的day -->
          <!-- <template v-slot:dateCell="{ date, data }"> -->
          <div @click="checkDay(data.day)" class="date-content">
            <span class="text"> {{ data.day | getDay }}</span>
            <!-- <span>{{data.day}}</span> -->
            <span v-for="item in dayDatas" :key="item.id">
              <span
                class="work"
                v-if="item.day === data.day && item.appointNum > 0"
                >{{ item.appointNum }}</span
              >
            </span>
            <!-- 预约详情 -->
            <span v-for="item in dayDetails" :key="item.id">

            </span>
          </div>
        </template>
      </el-calendar>
      <!-- <el-dialog :visible:sync="dialogVisible" v-if="dialogVisible" :append-to-body="true"> -->
    </el-card>
  </div>
</template>

<script>
import { appointStatis } from "@/api/spcod/sportappoint";
export default {
  filters: {
    getDay(value) {
      const day = value.split("-")[2];
      return day.startsWith("0") ? day.substr(1) : day;
    },
  },
  data() {
    return {
      yearList: [], // 要遍历的年的数组
      currentYear: null, // 当前年份
      currentMonth: null, // 当前月份
      currentDate: null, // 当前时间
      rangCal: [],
      dayDatas: [],
      stadium: "",
      type: "",
      dayDetails: []   //预约详情
    };
  },
  created() {
    // 获取当前的年份
    this.currentYear = new Date().getFullYear(); // 得到当前的年份
    this.currentMonth = new Date().getMonth() + 1; // 当前月份
    //  快速生成数组的方法
    this.yearList = Array.from(Array(11), (v, i) => this.currentYear + i - 5);
    // 钩子函数执行完成之后
    this.dataChange(); // 主动调用一下方法
  },
  methods: {
    dataChange() {
      //this.currentDate = new Date(`${this.currentYear}-${this.currentMonth}-01`) // 以1号为开始
      console.log("curDate", this.currentYear, this.currentMonth);

      // 生成新的日期
      let params = {
        yearMonth: "2022-10",
        sportTypeId: 1,
      };
      appointStatis(this.currentYear + "-" + this.currentMonth).then((res) => {
        const data = res.data.data;
        let day = data.map((i) => {
          return i.day;
        });
        this.rangCal = day;
        this.dayDatas = data;
      });
    },
    // 判断当前是否是周末
    isWeek(date) {
      return date.getDay() === 6 || date.getDay() === 0;
    },
    // 判断当前是否工作日
    isWork(date) {
      return date.getDay() !== 6 && date.getDay() !== 0;
    },
    checkDay(day) {
      // console.log(day)
      // console.log(this.dayDatas)
      this.dayDatas.forEach((i) => {
        if (i.day === day) {
          // 判断日期和预约是否有,然后判断是否进去
        // if (i.day === day && i.appointNum > 0) {
          this.$router.push({
            path: "/spcod/sportappointcalDetail",
            query: {
              day: i.day,
            },
          });
        }
      });
    },
  },
};
</script>


  <style lang="scss" scoped>
div /deep/ .el-calendar-day {
  height: auto;
}
div /deep/ .el-calendar-table__row td,
div /deep/ .el-calendar-table tr td:first-child,
div /deep/ .el-calendar-table__row td.prev {
  border: none;
}
.date-content {
  // height: 40px;
  height: 80px;
  text-align: center;
  // line-height: 40px;
  font-size: 14px;
}
.date-content .rest {
  color: #fff;
  border-radius: 50%;
  background: rgb(250, 124, 77);
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  font-size: 12px;
  margin-left: 10px;
}
.date-content .work {
  color: #fff;
  border-radius: 50%;
  background: rgb(91, 77, 250);
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
  font-size: 12px;
  margin-left: 10px;
}
.date-content .text {
  width: 20px;
  height: 20px;
  line-height: 20px;
  display: inline-block;
}
div /deep/ .el-calendar-table td.is-selected .text {
  background: #409eff;
  color: #fff;
  border-radius: 50%;
}
div /deep/ .el-calendar__header {
  display: none;
}
.dialogUl {
  li {
    list-style: none;
    display: flex;
    align-items: flex-start;
    .time {
      display: inline-block;
      width: 4rem;
      margin-top: -5px;
    }
    .contentTime {
      display: flex;
      flex: 1;
      height: 30px;
      border-top: 1px solid #cfcfcf;
      border-left: 1px solid #cfcfcf;
      border-right: 1px solid #cfcfcf;
      .contentTimeItem {
        flex: 1;
      }
    }
    &:last-child {
      .contentTime {
        border-bottom: 1px solid #cfcfcf;
      }
    }
  }
}
</style>

<template>
  <div class="interactive h-100p">
    <el-row type="flex" class="h-100p">
      <el-drawer
        size="100%"
        :visible.sync="previewBox"
        :before-close="beforeClose"
        modalAppendToBody
        appendToBody
        :with-header="false"
      >
        <el-row class="mar-t-10">
          <i class="el-icon-close closeIcon" @click="previewBox = false"></i>
          <template v-if="newType == 'form'">
            <formDesign></formDesign>
          </template>
          <template v-if="newType == 'table'">
            <tablePreview
              v-if="preType == 'pre'"
              :dashName="currentRow.name"
              :modelId="currentRow.id"
            ></tablePreview>
            <tableDesign
              v-if="preType == 'des' || preType == 'add'"
              :entityId="dataSource.code + '.' + model.tableName"
              :modelId="currentRow.id"
              :menuDo="preType"
              @goTableList="goTableList"
            ></tableDesign>
          </template>
        </el-row>
      </el-drawer>
      <el-dialog
        aria-setsize="small"
        width="30%"
        append-to-body
        destroy-on-close
        :visible.sync="menuBox"
      >
        <el-row
          ><createMenu
            :model="model"
            @saveNewPage="saveNewPage"
            :currentRow="currentRow"
          ></createMenu
        ></el-row>
      </el-dialog>
      <el-col :span="24">
        <el-card>
          <avue-crud
            ref="crud"
            :option="option"
            :data="tableDatas"
            :page.sync="page"
            v-model="form"
            :table-loading="tableLoading"
            @row-save="rowSave"
            @row-del="rowDel"
            @row-update="rowUpdate"
            @on-load="onLoad"
            @refresh-change="onLoad"
          >
            <template slot-scope="{ row }" slot="menuLeft">
              <el-button type="primary" size="small" @click="newDesi('table')" icon="el-icon-plus">新增表格</el-button>
              <el-button size="small" @click="newDesi('form')" icon="el-icon-plus">新增表单</el-button>
              <!-- <el-dropdown>
                <span class="el-dropdown-link">
                  <el-button type="primary" icon="el-icon-plus" size="small">
                    新 增
                  </el-button>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="form">
                    <span @click="newDesi('form')">表单</span></el-dropdown-item
                  >
                  <el-dropdown-item command="table"
                    ><span @click="newDesi('table')"
                      >表格</span
                    ></el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown> -->
            </template>
            <template slot="menuLeft">
              <el-button type="primary" size="small" icon="el-icon-plus"
                >新增
              </el-button>
            </template>
            <template slot="menu" slot-scope="scope">
              <el-button
                type="text"
                size="small"
                icon="el-icon-edit"
                @click="previewTable(scope.row, 'des')"
                >设计
              </el-button>
              <el-button
                type="text"
                size="small"
                icon="el-icon-view"
                @click="previewTable(scope.row, 'pre')"
                >预览
              </el-button>
              <el-button
                type="text"
                size="small"
                icon="el-icon-paperclip"
                @click="
                  menuBox = true;
                  setMenu(scope.row);
                "
                >设为菜单
              </el-button>
              <el-button
                type="text"
                size="small"
                icon="el-icon-delete"
                plain
                @click="rowDel"
                >删 除
              </el-button>
            </template>
          </avue-crud>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import tablePreview from "../data/table/table/preview/preview";
import tableDesign from "../data/table/table/design/design";
import createMenu from "../data/table/createMenu";
import formDesign from "../data/form/design";

import { dateFormat } from "@/util/date";
import {
  getAppModelDetail,
  saveAppMode,
  updateAppMode,
  delAppMode,
  getTree,
} from "@/api/dataService/appModel";

import {
  getDataAppList,
  saveApp,
  updateApp,
  delApp,
} from "@/api/dataService/dataApp";
import { getDynamicFormList } from "@/api/busiMode/meta";
let id = 1000;
export default {
  name: "tableList",
  components: { tableDesign, createMenu, tablePreview, formDesign },
  data() {
    return {
      treeProps: {
        label: "name",
        children: "children",
      },
      appOption: {},
      appVisible: false,
      filterText: "",
      chooseModelbox: false,
      previewBox: false,
      menuBox: false,
      tableLoading: false,
      form: {},
      appObj: {},
      page: {},
      node: {},
      newType: "",
      checkedNode: {},
      title: {},
      tableDatas: [],
      treeData: [],
      option: {
        addBtn: false,
        editBtn: false,
        delBtn: false,
        height: "430",
        column: [
          {
            label: "页面名称",
            prop: "name",
          },
          {
            label: "页面类型",
            prop: "type",
          },
          {
            label: "创建时间",
            prop: "createTime",
          },
          {
            label: "创建用户",
            prop: "createUser",
          },
        ],
      },
      preType: "",
      currentRow: {},
    };
  },
  created() {
    this.getDynamicFormList();
  },
  props: {
    dataSource: {
      type: Object,
      default: function () {
        return {};
      },
    },
    model: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    setMenu(row) {
      this.currentRow = row;
    },
    goTableList() {
      this.newType = "";
      this.previewBox = false;
      this.$store.commit("SET_VIEWEDIT", false);
      this.getDynamicFormList();
    },
    saveNewPage() {
      this.menuBox = !this.menuBox;
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    newDesi(type) {
      if (type == "table") {
        this.createNew("table");
      } else if (type == "form") {
        this.createNew("form");
      }
    },
    getDynamicFormList(id, type) {
      let obj = {
        current: this.page.currentPage,
        size: this.page.pageSize,
        code: this.dataSource.code + "." + this.model.tableName,
      };
      if (id) {
        obj[type] = id;
      }
      getDynamicFormList(obj)
        .then((res) => {
          this.tableLoading = false;
          res.data.data.records.forEach((element) => {
            if (element.type == "T") {
              element.type = "表格";
            } else {
              element.type = "表单";
            }
          });
          this.tableDatas = res.data.data.records;
          this.page.total = res.data.data.total;
        })
        .catch((e) => {
          this.tableLoading = false;
        });
    },
    onLoad() {
      this.tableLoading = true;
      this.getDynamicFormList();
    },
    createNew(type) {
      this.$store.commit("SET_VIEWEDIT", true);
      this.previewBox = true;
      this.preType = "add";
      this.newType = type;
    },
    beforeClose() {
      this.$store.commit("SET_VIEWEDIT", false);
    },
    previewTable(row, type) {
      this.newType = "table";
      this.previewBox = true;
      this.currentRow = row;
      this.preType = type;
      if (type == "pre") {
        this.$store.commit("SET_VIEWEDIT", false);
      }
      if (type == "des") {
        this.$store.commit("SET_VIEWEDIT", true);
      }
      this.$store.commit("SET_GUID", row.guid);
    },
    rowUpdate(row, index, done) {
      this.tableDatas.splice(index, 1, row);
      done();
    },
    handleAdd() {
      this.$refs.crud.rowAdd();
    },
    rowSave(row, done) {
      this.tableDatas.push(row);
    },
    rowDel(row, index) {
      this.$confirm("是否删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.tableDatas.splice(index, 1);
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
/deep/ .btnSaveClass {
  position: absolute;
  right: 20px;
  top: 50px;
}
.add-main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.add-main .add-item {
  width: 230px;
  height: 136px;
  background: #eff9ff;
  cursor: pointer;
  padding-left: 20px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 15px;
}
.add-main .add-item .add-icon[data-v-3e875892] {
  width: 56px;
  height: 56px;
  margin-right: 10px;
  background: #ceeaff;
  border-radius: 10px;
  color: #46adfe;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  font-size: 30px;
  line-height: 56px;
  text-align: center;
}
.add-main .add-item.add-item-list[data-v-3e875892] {
  background: #fef3e6;
}
.add-main .add-item.add-item-form[data-v-3e875892] {
  background: #f1f5ff;
}
/deep/ .el-drawer__header {
  margin-bottom: 0px !important;
  span {
    font-size: 16px;
  }
}
.closeIcon {
  position: absolute;
  right: 0px;
  text-align: right;
  padding: 0px 20px 10px 10px;
  font-size: 20px;
  z-index: 1000;
  cursor: pointer;
}
</style>

<template>
  <basic-container>
    <avue-form
      ref="form"
      v-model="obj"
      :option="option"
      @reset-change="emptytChange"
      @submit="submit"
    >
    </avue-form>
  </basic-container>
</template>
<script>
export default {
  data() {
    return {
      obj: {},
      parentId: 0,
      sizeValue: "",
    };
  },
  computed: {
    option() {
      return {
        menuBtn: false,
        column: [
          {
            label: "功能名称",
            prop: "name",
            search: true,
            rules: [
              {
                required: true,
                message: "请输入功能名称",
                trigger: "blur",
              },
            ],
          },
          {
            label: "功能编码",
            prop: "path",
            rules: [
              {
                required: true,
                message: "请输入功能编码",
                trigger: "blur",
              },
            ],
          },
          {
            label: "所属应用",
            prop: "parentId",
            type: "tree",
            hide: true,
            dicUrl: "/api/jida-system/menu/tree", // 动态字典 接口
            props: {
              label: "title",
            },
            addDisabled: false,
            rules: [
              {
                required: false,
                message: "请选择所属应用",
                trigger: "click",
              },
            ],
          },
          {
            label: "排序",
            prop: "sort",
            type: "number",
            row: true,
            span: 12,
            rules: [
              {
                required: true,
                message: "请输入排序",
                trigger: "blur",
              },
            ],
          },
          {
            label: "功能说明",
            prop: "remark",
            type: "textarea",
            span: 24,
            minRows: 2,
          },
          {
            label: "数据源",
            prop: "parentId",
            type: "tree",
            hide: true,
            dicUrl: "/api/jida-system/menu/tree", // 动态字典 接口
            props: {
              label: "title",
            },
            addDisabled: false,
            rules: [
              {
                required: false,
                message: "请选择所属应用",
                trigger: "click",
              },
            ],
          },
          {
            label: "源数据",
            prop: "parentId",
            type: "tree",
            hide: true,
            dicUrl: "/api/jida-system/menu/tree", // 动态字典 接口
            props: {
              label: "title",
            },
            addDisabled: false,
            rules: [
              {
                required: false,
                message: "请选择所属应用",
                trigger: "click",
              },
            ],
          },
        ],
      };
    },
  },

  watch: {
    option: {
      handler(newName, oldName) {
        //this.getCommList();
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.obj.username = "smallwei";
    this.obj.switch = 0;
    this.obj.phone = "17547400800";
  },
  methods: {
    emptytChange() {
      this.$message.success("清空方法回调");
    },
    submit() {
      console.log(JSON.stringify(this.obj));
      this.$message.success("创建成功～");
    },
  },
};
</script>

<template>
  <div class="h-100p">
    <div class="h-40 lh-40">
      <el-button class="el-icon-plus" size="small" type="primary" @click="addWidgetDrawer = true"> 添加部件</el-button>
    </div>
    <div style="height: calc(100% - 40px)">
      <Layout :data="data"></Layout>
    </div>
    <el-drawer
      :with-header="false"
      :visible.sync="addWidgetDrawer"
      direction="ltr"
      append-to-body
      >
      <h4 class="lh-50 bor-b mar-0 pad-0 pad-lr-8">添加部件</h4>
      <div class="pad-8">
        <el-tabs v-model="currentTabType" tab-position="top">
          <el-tab-pane v-for="itemType of widgetTypes" :name="itemType" :label="itemType" :key="itemType">
          </el-tab-pane>
        </el-tabs>
        <template v-for="item of widgetList">
            <div class="bor widget-list pad-5 mar-b-10" v-if="item.type === currentTabType" :key="item.name" shadow="hover">
              <component :is="item.component" :option="item.getTestOption && item.getTestOption()" :data="item.testData" :widget="item" :isTest="true"></component>
              <div class="widget-list-title">{{item.name}} <span class="pull-right"><el-button @click="addWidget(item)" icon="el-icon-plus" type="text" size="small">添加</el-button></span></div>
            </div>
        </template>
        <!-- <div class="bor widget-list pad-5" v-if="item.type === itemType" :key="item.name" shadow="hover">
          <component :is="item.component" :option="item.testOption" :data="item.testData" :widget="item" :isTest="true"></component>
          <div class="widget-list-title">{{item.name}} <span class="pull-right"><el-button @click="addWidget(item)" icon="el-icon-plus" type="text" size="small">添加</el-button></span></div>
        </div> -->
      </div>
    </el-drawer>
  </div>
</template>
<script>

import Layout from './core/layout.vue';
import BuiltInWidgetComponents from './widget/index';
import BuiltInWidgets from './widget/widgets';
import { initWidget } from './util/util';

export default {
  components: {
    Layout,
    ...BuiltInWidgetComponents
  },
  data(){
    return {
      addWidgetDrawer: false,
      data: [],
      widgetTypes: [],
      currentTabType: ''
    }
  },
  provide(){
    return {
      dataParseList: this.dataParseList,
      widgets: this.widgets
    }
  },
  props: {
    widgets: {
      type: Array,
      default(){
        return [];
      }
    },
    dataParseList: {
      type: Array,
      default(){
        return [];
      }
    }
  },
  computed: {
    widgetList(){
      return [].concat(this.widgets , BuiltInWidgets)
    }
  },
  mounted(){
    this.widgetList.forEach(
      item => {
        if(!this.widgetTypes.includes(item.type)){
          this.widgetTypes.push(item.type)
        }
      }
    )
    this.currentTabType = this.widgetTypes[0] || '';
  },
  methods: {
    addWidget(widget){
      this.data.push(initWidget(widget))
      this.addWidgetDrawer = false;
    },
  }
}
</script>
<style lang="scss" scoped>
.widget-list{
  height: 150px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
  >.widget-list-title{
    line-height: 40px;
    height: 40px;
    background: rgba(0,0,0,.25);
    color: #fff;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 15px;
  }
}
</style>
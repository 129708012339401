<template>
  <basic-container>
    <avue-crud :option="option"
               :table-loading="loading"
               :data="data"
               :page.sync="page"
               :before-open="beforeOpen"
               v-model="form"
               ref="crud"
               @row-update="rowUpdate"
               @row-save="rowSave"
               @row-del="rowDel"
               @search-change="searchChange"
               @search-reset="searchReset"
               @current-change="currentChange"
               @size-change="sizeChange"
               @refresh-change="refreshChange"
               @on-load="onLoad">
      <template slot="menuLeft">
        <el-button type="danger"
                   size="small"
                   icon="el-icon-delete"
                   plain
                   v-if="permission.epmalladdress_delete"
                   @click="handleDelete">删 除
        </el-button>
      </template>
    </avue-crud>
  </basic-container>
</template>

<script>
  import {getList, getDetail, add, update, remove,getPage} from "@/api/epmall/address";
  import {mapGetters} from "vuex";

  export default {
    data() {
        // 正则表达式 验证手机号
        const validateMobile = (rule, value, callback) => {
          if (!/^[1]([3-9])[0-9]{9}$/.test(value)) {
            callback(new Error("请输入正确的手机号格式"));
          } else {
            callback();
          }
        };
      return {
        form: {},
        query: {},
        loading: true,
        page: {
          pageSize: 10,
          currentPage: 1,
          total: 0
        },
        selectionList: [],
        option: {
          height:'auto',
          calcHeight: 30,
          tip: false,
          searchShow: true,
          searchMenuSpan: 6,
          border: true,
          index: true,
          viewBtn: true,
          dialogClickModal: false,
          column: [
            {
              label: "用户ID",
              prop: "userId",
              hide:true,
              addDisplay:false,
              editDisplay:false,
              viewDisplay:false,
              rules: [{
                required: true,
                message: "请输入",
                trigger: "blur"
              }]
            },
            {
              label: "用户名",
              prop: "userName",
              rules: [{
                required: true,
                message: "请输入",
                trigger: "blur"
              }]
            },
            {
              label: "联系人",
              prop: "contact",
              rules: [{
                required: true,
                message: "请输入联系人",
                trigger: "blur"
              }]
            },
            {
              label: "电话",
              prop: "tel",
              search:true,
              rules: [{
                required: true,
                message: "请输入电话",
                trigger: "blur"
              },
              {
                trigger: "blur",
                validator: validateMobile
              }]
            },
             {
            label: "楼宇",
            prop: "buildingId",
            hide: true,
            search: true,
            searchSpan: 4,
            addDisplay: true,
            editDisplay: true,
            viewDisplay: false,
            type: "select",
            cascader: ['wpRoomId'],
            dicUrl:
              "/api/park-spcod/mobcodappoint/listbuilding?current=1&size=10000000&buildId={{key}}",
            props: {
              label: "name",
              value: "id",
            },
            dicFormatter: (res) => {
              return res.data.records;
            },
            rules: [
              {
                required: false,
                message: "请输入楼宇Id",
                trigger: "blur",
              },
            ],
          },
          {
            label: "楼宇",
            prop: "buildingName",
            addDisplay: false,
            editDisplay: false,
            rules: [
              {
                required: false,
                message: "请输入楼宇",
                trigger: "blur",
              },
            ],
          },
            {
              label: "街道地址",
              prop: "streetName",
              rules: [{
                required: true,
                message: "请输入街道地址",
                trigger: "blur"
              }]
            },
            {
              label: "省份",
              prop: "prvn",
              addDisplay:false,
              editDisplay:false,
              viewDisplay:false,
              hide: true,
              type: "select",
              props: {
                label: "name",
                value: "code",
              },
              cascaderItem: ["city", "district"],
              cascader: ['city'],
              dicUrl: "/api/jida-system/region/select",
              // span: 24,
            },
            {
              label: "地市",
              addDisplay:false,
              editDisplay:false,
              viewDisplay:false,
              prop: "city",
              type: "select",
              hide: true,
              cell: true,
              props: {
                label: "name",
                value: "code",
              },
              // dicFlag: false,
              cascader: ['district'],
              dicUrl: "/api/jida-system/region/select?code={{key}}",
              // span: 24,
            },
            {
              label: "区县",
              prop: "district",
              addDisplay:false,
              editDisplay:false,
              viewDisplay:false,
              type: "select",
              hide: true,
              cell: true,
              props: {
                label: "name",
                value: "code",
              },
              // dicFlag: false,
              dicUrl: "/api/jida-system/region/select?code={{key}}",
              // span: 24,
            },
            {
              label: "是否默认",
              prop: "isDefault",
              type: "select",
              dicData:[{
                label: "是",
                value: true
              },{
                label: "否",
                value: false
              }],
              rules: [{
                required: true,
                message: "请输入是否默认",
                trigger: "blur"
              }]
            },
          ]
        },
        data: []
      };
    },
    computed: {
      ...mapGetters(["permission"]),
      permissionList() {
        return {
          addBtn: this.vaildData(this.permission.epmalladdress_add, false),
          viewBtn: this.vaildData(this.permission.epmalladdress_view, false),
          delBtn: this.vaildData(this.permission.epmalladdress_delete, false),
          editBtn: this.vaildData(this.permission.epmalladdress_edit, false)
        };
      },
      ids() {
        let ids = [];
        this.selectionList.forEach(ele => {
          ids.push(ele.id);
        });
        return ids.join(",");
      }
    },
    mounted(){
      //放在数据加载完后执行
      this.$refs.crud.dicInit('cascader');
    },
    methods: {
      rowSave(row, done, loading) {
        add(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          window.console.log(error);
        });
      },
      rowUpdate(row, index, done, loading) {
        update(row).then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!"
          });
          done();
        }, error => {
          loading();
          console.log(error);
        });
      },
      rowDel(row) {
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(row.id);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
          });
      },
      handleDelete() {
        if (this.selectionList.length === 0) {
          this.$message.warning("请选择至少一条数据");
          return;
        }
        this.$confirm("确定将选择数据删除?", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
          .then(() => {
            return remove(this.ids);
          })
          .then(() => {
            this.onLoad(this.page);
            this.$message({
              type: "success",
              message: "操作成功!"
            });
            this.$refs.crud.toggleSelection();
          });
      },
      beforeOpen(done, type) {
        if (["edit", "view"].includes(type)) {
          getDetail(this.form.id).then(res => {
            this.form = res.data.data;
          });
        }
        done();
      },
      searchReset() {
        this.query = {};
        this.onLoad(this.page);
      },
      searchChange(params, done) {
        this.query = params;
        this.page.currentPage = 1;
        this.onLoad(this.page, params);
        done();
      },
      selectionClear() {
        this.selectionList = [];
        this.$refs.crud.toggleSelection();
      },
      currentChange(currentPage){
        this.page.currentPage = currentPage;
      },
      sizeChange(pageSize){
        this.page.pageSize = pageSize;
      },
      refreshChange() {
        this.onLoad(this.page, this.query);
      },
      onLoad(page, params = {}) {
        this.loading = true;
        getPage(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
          const data = res.data.data;
          this.page.total = data.total;
          this.data = data.records;
          this.loading = false;
          this.selectionClear();
        });
      }
    }
  };
</script>

<style>
</style>

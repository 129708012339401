<template>
  <data-write-list></data-write-list>
</template>
<script>
import dataWriteList from './dataWrite/data-write-list';
export default {
  components: {
    dataWriteList
  }
}
</script>

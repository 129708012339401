import { numConvert } from '@/filters/numConvert.js'

export default {
    methods: {
        _getLabelTask(index){
            let indexTypes = {
                0: {
                    type: '9',
                    name: '治理任务'
                },
                1: {
                    type: '8',
                    name: '宽表任务'
                }
            }
            let str = '' , type = null;
            this.tasks.forEach(
                item => {
                    if(item.type === indexTypes[index].type){
                        str = indexTypes[index].name + ':' + numConvert(item.c);
                        type = item.type;
                    }
                }
            )
            return {
                label: str,
                type: type
            };
        },

        _addDatasources(nodes , edges , combos){

            let firstDH = [];
                let tasks = 0;
                this.datasources.forEach(
                    item => {
                        tasks += item.cjNum;
                    }
                )
                this.datasources.forEach((i) => {
                  if (i.isLast) {
                    edges.push({
                      source: 'datasourceBox',
                      target: i.jidaAppId,
                      // type: 'line'
                    })
                    nodes.push({
                      id: i.jidaAppId,
                      title: i.appName,
                      type: 'dh-node1',
                      size: [220 , 60],
                      nodeType: 'box',
                      indicators: [
                          {name: '调用次数' , value: i.callCount=='-1'?0: i.callCount , code: 'datasource'},
                          {name: '调用成功次数' , value: i.successNum =='-1'?0: i.successNum,  code: 'allCjTask'},
                          {name: '创建时间' , value: this.validatenull(i.createTime)?"-":i.createTime.split(" ")[0] , code: 'createTime'},
                          {name: '最近调用时间' , value: this.validatenull(i.lastUsedTime)?"-":i.lastUsedTime.split(" ")[0], code: 'updateTime'},
                      ],
                    })
                  } else {
                      nodes.push({
                        id: 'datasourceBox',
                        title: i.name,
                        type: 'dh-node1',
                        size: [220 , 60],
                        nodeType: 'box',
                        indicators: [
                            {name: '累计调用次数' , value: i.callCount=='-1'?0: i.callCount, code: 'datasource'},
                            {name: '累计调用成功次数' , value: i.successCount=='-1'?0: i.successCount ,  code: 'allCjTask'},
                            {name: '创建时间' , value: this.validatenull(i.createTime)?"-":i.createTime.split(" ")[0], code: 'createTime'},
                            {name: '上架时间' , value: this.validatenull(i.launchTime)?"-":i.launchTime.split(" ")[0] , code: 'updateTime'},
                        ],
                    })
                  }
                })
        },
        _addDataHierarchys(nodes , edges , combos){
            this.dataHierarchys.forEach(
                (item , index) => {
                    nodes.push({
                        id: item.id,
                        title: item.label,
                        type: 'dh-node1',
                        nodeType: 'dataHierarchy',
                        indicators: item.indicators || [],
                        size: [180 , 60],
                        indicatorClick: (indicator , ev) => {
                            if(indicator){
                                let id = this.getNodeId(indicator.nodeId);
                                if(id){
                                    this.$router.push(`/data-assets/assets?dhId=${id}&back=true`);
                                }
                            }
                        },
                        onClick: (ev , cfg) => {

                            // let model = ev.item.getModel();
                            this._getData_getTables(this.getNodeId(cfg.id)).then(
                                res => {
                                    this.showType = 'chartShow';
                                    this.level = 2;
                                    this.initGraph();
                                    this.clearRender();
                                }
                            );
                        }
                    })
                    let nextDH = null;
                    if(index < this.dataHierarchys.length - 1){
                        nextDH = this.dataHierarchys[index + 1];
                    }
                    if(nextDH){
                        let labelObj = this._getLabelTask(index);
                        edges.push({
                            source: item.id,
                            target: nextDH.id,
                            type: 'line',
                            label: labelObj.label,
                            edgeType: 'task',
                            query: {
                                scene: labelObj.type,
                                back: true
                            }
                        })
                    }
                }
            )
        },
        _addTables(nodes , edges , combos){
            this.tables.forEach((item) => {
                nodes.push({
                    id: item.id,
                    type: 'dh-node1',
                    title: item.label,
                    data: item,
                    nodeType: 'tableNode',
                    indicators: item.indicators || [],
                    indicatorClick: (indicator , ev) => {
                        // if(indicator){

                        // }
                        // if(this._event_indicatorClick && typeof this._event_indicatorClick === 'function'){
                        //     // this._event_indicatorClick(indicator , ev)
                        // }
                    },
                    onClick: (ev , cfg) => {
                        let data = cfg ? cfg.data : {};
                        if(data.guid){
                            this.dataAssetsDetailProps = {
                                propGuid: data.guid,
                                propTableName: data.tableName,
                                propDatabaseId: data.datasourceId,
                                propDatabaseName: data.databaseName
                            }
                            this.showTableDetail = true;
                        }
                    }
                })
            });
        },
        _setTableData(_tables){
            let tables = _tables || this.tables;
            this.page.total = tables.length;
            this.tableData = [];
            let startIndex = (this.page.currentPage - 1) * this.page.pageSize ,
                endIndex = this.page.currentPage * this.page.pageSize;
            for(let i = startIndex; i <= endIndex; i++){
                if(tables[i]){
                    let item = tables[i];
                    this.tableData.push({
                        id: item.id,
                        dataRows: item.dataRows,
                        dataSize: item.dataSize,
                        tableName: item.tableName,
                        dataSizeIncreaseByDay: item.dataSizeIncreaseByDay,
                        dataRowsIncreaseByDay: item.dataRowsIncreaseByDay,
                        columnNum: item.columnNum,
                        _item: item
                    });
                }
            }

        },

        getRanderData(){
            let nodes = [] , edges = [] , combos = [];
            if(this.level === 1){
                this._addDatasources(nodes , edges , combos);
                // this._addDataHierarchys(nodes , edges , combos);
            }else if(this.level === 2){
                this._addTables(nodes , edges , combos)
            }
            return {
                nodes,
                edges,
                combos
            }
        },
    }
}

<template>
  <el-form ref="form" size="small" label-width="80px">
    <template v-for="item of optionList">
      <el-form-item v-if="item.show" class="mar-t-8" :label="item.name">
        <el-input v-bind="item.propData"  v-if="item.type === 'text'" type="text" v-model="item.value" @change="valueChange"></el-input>
        <el-input v-bind="item.propData"  v-if="item.type === 'textarea'" type="textarea" v-model="item.value" @change="valueChange"></el-input>
        <el-input-number v-bind="item.propData" v-if="item.type === 'number'" v-model="item.value" @change="valueChange"></el-input-number>
        <el-color-picker v-bind="item.propData" v-if="item.type === 'colorpicker'" v-model="item.value" @change="valueChange" show-alpha></el-color-picker>
      </el-form-item>
    </template>
  </el-form>
</template>
<script>
export default {
  name: 'WidgetConfigByOption',
  tabName: '配置项',
  data(){
    return {
      optionList: []
    }
  },
  props: {
    widget: {
      type: Object,
      default: () => {
        return {}
      }
    },
    option: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  mounted(){
    console.log(this.widget);
    this.optionList = this.widget.optionList || [];
    this.initValue();
  },
  methods: {
    initValue(){
      this.optionList.forEach(
        item => {
          if(this.widget.option && this.widget.option[item.code] !== undefined){
            item.value = this.widget.option[item.code];
          }else{
            item.value = item.defaultValue || null;
          }
        }
      )
    },
    valueChange(){
      let option = {};
      this.optionList.forEach(
        item => {
          option[item.code] = item.value;
        }
      )
      this.widget.option = option;
    }
  }
}
</script>
import { render, staticRenderFns } from "./messageForm.vue?vue&type=template&id=a7b1d2fe&scoped=true"
import script from "./messageForm.vue?vue&type=script&lang=js"
export * from "./messageForm.vue?vue&type=script&lang=js"
import style0 from "./messageForm.vue?vue&type=style&index=0&id=a7b1d2fe&prod&lang=scss&rel=stylesheet%2Fscss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7b1d2fe",
  null
  
)

export default component.exports
<template>
    <basic-container>
        <avue-crud :option="option" :table-loading="loading" :data="data" :page.sync="page" v-model="form" ref="crud" @row-update="rowUpdate" @row-save="rowSave" @row-del="rowDel"
                   @search-change="searchChange" @search-reset="searchReset" @current-change="currentChange" @size-change="sizeChange" @refresh-change="refreshChange" @on-load="onLoad">
            <template slot="menuLeft">
                <el-button type="danger" size="small" icon="el-icon-delete" plain v-if="permission.sportappoint_delete" @click="handleDelete">删 除
                </el-button>
            </template>
            <template slot-scope="scope" slot="menu">
                <template v-if="scope.row.status == 7">
                    <el-button type="text" size="small" icon="el-icon-scissors" class="none-border" @click="cancelPreAppt(scope.row)">取消预留
                    </el-button>
                </template>
                <template v-if="scope.row.status == 3">
                    <el-button type="text" size="small" icon="el-icon-delete" @click="handleEdit(scope.row, scope.index)"> 修改预约时段
                    </el-button>
                </template>
            </template>
        </avue-crud>
        <el-dialog title="修改预约时段" :visible.sync="timeSlotDialogVisible" v-if="timeSlotDialogVisible" width="40%" append-to-body @close="handleEdit">
            <el-form ref="form" label-width="80px">
                <el-form-item label="预约时段">
                    <el-col :span="11">
                        <el-date-picker type="datetime" placeholder="选择时间" v-model="timeSlotForm.startTime" style="width: 100%;" :picker-options="pickerOptions" @change="getStart()"></el-date-picker>
                    </el-col>
                    <el-col class="line" :span="2">-</el-col>
                    <el-col :span="11">
                        <el-date-picker type="datetime" placeholder="选择时间" v-model="timeSlotForm.endTime" style="width: 100%;" :picker-options="pickerOptions1"></el-date-picker>
                    </el-col>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button size="small" @click="timeSlotDialogVisible = false">取 消</el-button>
                <el-button size="small" type="primary" @click="updateTimeSlot()">确 定</el-button>
            </span>
        </el-dialog>
    </basic-container>
</template>

<script>
import {
    getList,
    getPage,
    getDetail,
    add,
    update,
    listGroundAppoint,
    cancelPreAppoint,
} from '@/api/spcod/sportappoint';
import { mapGetters } from 'vuex';
import dayjs from 'dayjs';

export default {
    data() {
        // 正则表达式 验证手机号
        const validateMobile = (rule, value, callback) => {
            if (!/^[1]([3-9])[0-9]{9}$/.test(value)) {
                callback(new Error('请输入正确的手机号格式'));
            } else {
                callback();
            }
        };
        return {
            APPT_STATUS: {},
            form: {},
            query: {},
            loading: true,
            groundList: [],
            currentObj: {},
            timeSlotForm: {},
            timeSlotDialogVisible: false,
            updateable: false,
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() < Date.now() - 8.64e7;
                },
            },
            // pickerOptions1: {
            //     disabledDate: time => {
            //         if (this.timeSlotForm.startTime) {
            //             return time.getTime() < dayjs(this.timeSlotForm.startTime) - 8.64e7;
            //         }
            //     },
            // },

            page: {
                pageSize: 10,
                currentPage: 1,
                total: 0,
            },
            selectionList: [],
            option: {
                height: 'auto',
                calcHeight: 30,
                tip: false,
                searchShow: true,
                searchMenuSpan: 6,
                border: true,
                index: true,
                addBtn: false,
                editBtn: false,
                delBtn: false,
                viewBtn: true,
                dialogClickModal: false,
                column: [
                    // {
                    //   label: "编码",
                    //   prop: "groundCode",
                    //   width: 80,
                    //   addDisplay: false,
                    //   rules: [
                    //     {
                    //       required: true,
                    //       message: "请输入场地ID",
                    //       trigger: "blur",
                    //     },
                    //   ],
                    // },
                    {
                        label: '场馆',
                        prop: 'stadiumName',
                        addDisplay: false,
                        width: 150,
                        rules: [
                            {
                                required: true,
                                message: '请输入场馆',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '场地',
                        width: 150,
                        prop: 'groundId',
                        hide: true,
                        search: true,
                        searchSpan: 5,
                        type: 'select',
                        editDisplay: false,
                        addDisplay: false,
                        viewDisplay: false,
                        dicUrl: '/api/park-spcod/sportground/list?current=1&size=10000000',
                        props: {
                            label: 'name',
                            value: 'id',
                        },
                        dicFormatter: res => {
                            this.groundList = res.data.records;
                            return res.data.records;
                        },
                        rules: [
                            {
                                required: true,
                                message: '请输入场地ID',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '场地',
                        width: 150,
                        prop: 'groundName',
                        addDisplay: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入场地',
                                trigger: 'blur',
                            },
                        ],
                    },

                    {
                        label: '下单时间',
                        prop: 'apptTime',
                        width: 220,
                        search: true,
                        editDisplay: false,
                        addDisplay: false,
                        viewDisplay: true,
                        type: 'date',
                        format: 'yyyy年MM月dd日',
                        valueFormat: 'yyyy-MM-dd HH:mm:ss',
                    },
                    {
                        label: '预约时间',
                        prop: 'timeSlot',
                        width: 220,
                        // type: 'date',
                        // format: 'yyyy年MM月dd日 HH:mm:ss',
                        // valueFormat: 'yyyy-MM-dd HH:mm:ss',
                    },

                    {
                        label: '预订人',
                        prop: 'phoneName',
                        hide: true,
                        addDisplay: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入预订人',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '类型',
                        prop: 'appType',
                        rules: [
                            {
                                required: true,
                                message: '请输入预订人类型',
                                trigger: 'blur',
                            },
                        ],
                    },

                    {
                        label: '订单号',
                        prop: 'orderSn',
                        hide: true,
                        addDisplay: false,
                        editDisplay: false,
                        rules: [
                            {
                                required: true,
                                message: '请输入订单号',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '预订电话',
                        prop: 'contactPhone',
                        search: true,
                        width: 150,
                        searchSpan: 4,
                        rules: [
                            {
                                required: true,
                                message: '请输入预订电话',
                                trigger: 'blur',
                            },
                            {
                                trigger: 'blur',
                                validator: validateMobile,
                            },
                        ],
                    },
                    {
                        label: '联系人',
                        width: 150,
                        prop: 'phoneName',
                        rules: [
                            {
                                required: true,
                                message: '请输入联系人',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '支付金额',
                        width: 150,
                        prop: 'totalPrice',
                        rules: [
                            {
                                required: true,
                                message: '请输入支付金额',
                                trigger: 'blur',
                            },
                        ],
                    },

                    {
                        label: '状态',
                        prop: 'status',
                        search: true,
                        searchSpan: 4,
                        addDisplay: false,
                        type: 'select',
                        //状态,1新建/2取消/3已支付/4消费确认/5退单/6支付超时
                        dicData: [
                            {
                                label: '新建',
                                value: 1,
                            },
                            {
                                label: '取消',
                                value: 2,
                            },
                            {
                                label: '已支付',
                                value: 3,
                            },
                            {
                                label: '退单',
                                value: 5,
                            },
                            {
                                label: '支付超时',
                                value: 6,
                            },
                            {
                                label: '预留',
                                value: 7,
                            },
                        ],
                        rules: [
                            {
                                required: true,
                                message: '请输入状态',
                                trigger: 'blur',
                            },
                        ],
                    },
                    {
                        label: '备注',
                        prop: 'extraMemo',
                        span: 24,
                        width: 200,
                        minRows: 6,
                        overHidden: true,
                        type: 'textarea',
                        rules: [
                            {
                                required: false,
                                message: '请输入预订要求',
                                trigger: 'blur',
                            },
                        ],
                    },
                ],
            },
            data: [],
        };
    },
    computed: {
        ...mapGetters(['permission']),
        permissionList() {
            return {
                addBtn: this.vaildData(this.permission.sportappoint_add, false),
                viewBtn: this.vaildData(this.permission.sportappoint_view, false),
                delBtn: this.vaildData(this.permission.sportappoint_delete, false),
                editBtn: this.vaildData(this.permission.sportappoint_edit, false),
            };
        },
        ids() {
            let ids = [];
            this.selectionList.forEach(ele => {
                ids.push(ele.id);
            });
            return ids.join(',');
        },
    },
    methods: {
        getStart(time) {
            console.log('this.timeSlotForm.startTime', this.timeSlotForm);
            if (this.timeSlotForm.startTime) {
                if (dayjs(this.timeSlotForm.startTime) < Date.now()) {
                    this.timeSlotForm.startTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
                }
            }
        },
        handleEdit(row, index) {
            this.currentObj = row;
            this.timeSlotForm = {
                startTime: row.beginTime,
                endTime: row.endTime,
            };
            this.timeSlotDialogVisible = true;
        },
        updateTimeSlot() {
            console.log(dayjs(this.timeSlotForm.startTime).valueOf());
            console.log(dayjs(this.timeSlotForm.endTime).valueOf());
            if (
                dayjs(this.timeSlotForm.startTime).format('YYYY-MM-DD') !=
                dayjs(this.timeSlotForm.endTime).format('YYYY-MM-DD')
            ) {
                this.$message({
                    type: 'error',
                    message: '请选择同一天的时间段!',
                });
                return false;
            }
            if (dayjs(this.timeSlotForm.startTime).valueOf() > dayjs(this.timeSlotForm.endTime).valueOf()) {
                this.$message({
                    type: 'error',
                    message: '结束时间不能大于开始时间!',
                });
                return false;
            }

            this.currentObj.beginTime = dayjs(this.timeSlotForm.startTime).format('YYYY-MM-DD HH:mm:ss');
            this.currentObj.endTime = dayjs(this.timeSlotForm.endTime).format('YYYY-MM-DD HH:mm:ss');
            //this.currentObj.apptTime = dayjs(this.timeSlotForm.startTime).format('YYYY-MM-DD HH:mm:ss');
            this.currentObj.timeSlot =
                dayjs(this.timeSlotForm.startTime).format('HH:mm:ss') +
                '-' +
                dayjs(this.timeSlotForm.endTime).format('HH:mm:ss');
            console.log(dayjs(this.timeSlotForm.endTime).format('HH:mm:ss'));
            update(this.currentObj).then(
                () => {
                    this.onLoad(this.page);
                    this.timeSlotDialogVisible = false;
                    this.timeSlotForm = {};
                },
                error => {
                    loading();
                    console.log(error);
                },
            );
        },
        cancelPreAppt(row) {
            let _this = this;
            this.$confirm('确定取消预留?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                cancelPreAppoint(row.id).then(res2 => {
                    _this.$message({
                        type: 'success',
                        message: res2.data.msg,
                    });
                    _this.onLoad(_this.page);
                });
            });
        },
        rowSave(row, done, loading) {
            add(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    window.console.log(error);
                },
            );
        },
        rowUpdate(row, index, done, loading) {
            update(row).then(
                () => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    done();
                },
                error => {
                    loading();
                    console.log(error);
                },
            );
        },
        rowDel(row) {
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(row.id);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                });
        },
        handleDelete() {
            if (this.selectionList.length === 0) {
                this.$message.warning('请选择至少一条数据');
                return;
            }
            this.$confirm('确定将选择数据删除?', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            })
                .then(() => {
                    return remove(this.ids);
                })
                .then(() => {
                    this.onLoad(this.page);
                    this.$message({
                        type: 'success',
                        message: '操作成功!',
                    });
                    this.$refs.crud.toggleSelection();
                });
        },
        searchReset() {
            this.query = {};
            this.onLoad(this.page);
        },
        searchChange(params, done) {
            this.query = params;
            this.page.currentPage = 1;

            this.onLoad(this.page, params);
            done();
        },
        selectionClear() {
            this.selectionList = [];
            // this.$refs.crud.toggleSelection();
        },
        currentChange(currentPage) {
            this.page.currentPage = currentPage;
        },
        sizeChange(pageSize) {
            this.page.pageSize = pageSize;
        },
        refreshChange() {
            this.onLoad(this.page, this.query);
        },
        onLoad(page, params = {}) {
            let o = Object.assign(params, this.query);
            console.log(o.apptTime);
            if (o.apptTime) {
                o.apptTime = dayjs(o.apptTime).format('YYYY-MM-DD') + ' 00:00:00';
                console.log(o.apptTime);
            }
            if (o.groundId) {
                this.groundList.forEach(g => {
                    if (g.id == o.groundId) {
                        o.groundName = g.name;
                    }
                });
            }

            this.loading = true;
            listGroundAppoint(page.currentPage, page.pageSize, o).then(res => {
                const data = res.data.data;
                this.page.total = data.total;

                data.records.forEach(e => {
                    let apptime = '';
                    if (e.timeSlot) {
                        if (e.apptTime) {
                            apptime = dayjs(e.beginTime).format('YYYY-MM-DD');
                        }
                        e.timeSlot = apptime + '   ' + e.timeSlot;
                    } else {
                        if (e.apptTime) {
                            apptime = dayjs(e.beginTime).format('YYYY-MM-DD');
                        }
                        e.timeSlot =
                            apptime +
                            '   ' +
                            dayjs(e.beginTime).format('HH:mm') +
                            '-' +
                            dayjs(e.endTime).format('HH:mm');
                    }
                });

                this.data = data.records;
                this.loading = false;
                this.selectionClear();
            });
        },
    },
};
</script>

<style></style>

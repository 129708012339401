<template>
  <basic-container isCard v-loading="loading" :body-style="{ padding: '0', height: '100%' }" class="h-100p">
    <el-container class="h-100p">
      <el-header height="50px" class="lh-50 bor-b">
        <basic-page-header @back="back" class="lh-50" :title="info.taskName || '待办详情'"></basic-page-header>
      </el-header>
      <el-container style="height: calc(100% - 50px)">
        <el-main class="ov-a bor-r">
          <component v-if="component" :is="component" :id="info.businessId" :isTodo="true" :processInstanceId="info.processInstanceId"></component>
        </el-main>
        <el-aside width="300px" class="ov-h">
          <el-container class="h-100p">
            <el-main class="ov-a pad-15">
              <TodoTask :id="info.processInstanceId"></TodoTask>
            </el-main>
            <el-footer height="50px" class="lh-50 bor-t text-center">
              <template v-if="showBtn">
                <el-button size="small" icon="el-icon-close" @click="action(2)">拒绝</el-button>
                <el-button size="small" type="primary" icon="el-icon-check" @click="action(1)">同意</el-button>
              </template>
            </el-footer>
          </el-container>
        </el-aside>
      </el-container>
    </el-container>
  </basic-container>
</template>
<script>
import { completeTask, getTaskInfo } from "@/api/flow/flow.js"
import TodoTask from "./todo-task.vue"

export default {
  components: { TodoTask },
  data() {
    return {
      info: this.$store.state.user.currentTodoInfo || {},
      component: null,
      showBtn: false,
      loading: false,
    }
  },
  computed: {
    id() {
      return this.$router.currentRoute.query["id"] || ""
    },
  },
  mounted() {
    if (this.id) {
      this.loading = true
      getTaskInfo({
        taskId: this.id,
      })
        .then((res) => {
          this.loading = false
          if (res && res.data && res.data.data) {
            this.info = res.data.data
            this.showBtn = true
            this.requestComponent()
          }
        })
        .catch((e) => {
          this.loading = false
          this.showBtn = false
          this.requestComponent()
        })
    }
  },
  methods: {
    back() {
      this.$router.back()
    },
    requestComponent() {
      if (this.info && this.info.route) {
        let component = this.info.route
        require([`@/views/${component}.vue`], (componentModule) => {
          if (componentModule && componentModule.default) {
            this.component = componentModule.default
          }
        })
      }
    },
    action(type) {
      let name = type === 1 ? "同意" : "拒绝"

      this.$prompt(`请输入${name}原因`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputValidator: (val) => {
          if (!val && type === 2) {
            return "请填写拒绝原因"
          }
          return true
        },
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true
            let value = instance.inputValue
            console.log({
              taskId: this.id,
              comment: value || "",
              flag: type,
              processInstanceId: this.info.processInstanceId,
            })

            completeTask({
              taskId: this.id,
              comment: value || "",
              flag: type,
              processInstanceId: this.info.processInstanceId,
            })
              .then((res) => {
                instance.confirmButtonLoading = false
                this.$message({
                  message: `操作成功`,
                  type: "success",
                })
                done()
                this.back()
              })
              .catch((e) => {
                instance.confirmButtonLoading = false
              })
          } else {
            done()
          }
        },
      })
    },
  },
}
</script>

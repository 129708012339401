<template>
  <div>
    <template v-for="(tag, key) in buildsList">
      <basic-container :key="key" class="mar-b-10">
        <el-row>
          <el-col :span="3" class="fts-18 title text-center cur-p"
            ><div @click="getOne(tag)">{{ tag.name }}</div>
          </el-col>
          <el-col :span="21" class="text-center thsj pad-l-0 pad-r-0">
            <el-col :span="3">
              <span class="text-title">总面积(m²)</span>
              <div>{{ tag.area }}</div></el-col
            >
            <el-col :span="3">
              <span class="text-title">层数</span>
              <div>{{ tag.ds }}</div></el-col
            >
            <el-col :span="3">
              <span class="text-title">出租面积(m²)</span>
              <div>{{ tag.czmj }}</div></el-col
            >
            <el-col :span="3">
              <span class="text-title">总房间数</span>
              <div>{{ tag.rooms }}</div></el-col
            >
            <el-col :span="3">
              <span class="text-title">入驻企业率</span>
              <div>{{ tag.ruzys }}%</div></el-col
            >
            <el-col :span="3">
              <span class="text-title">用电量(kW·h)</span>
              <div>{{ tag.ydl }}</div></el-col
            >
            <el-col :span="3">
              <span class="text-title">用水量(吨)</span>
              <div>{{ tag.ysl }}</div></el-col
            >
            <el-col :span="3">
              <span class="text-title">节能减排</span>
              <div>{{ tag.jnjp }}</div></el-col
            >
          </el-col>
        </el-row>
      </basic-container>
    </template>
  </div>
</template>

<script>
import { getMetaCommonList, delMetaCommon } from "@/api/busiMode/metaCommon";

export default {
  components: {},
  data() {
    return {
      buildsList: [
        {
          name: "A栋",
          area: 1000,
          ds: 10,
          czmj: 3456900,
          rooms: 1908,
          ruzys: 80,
          ydl: 2000,
          ysl: 12900,
          jnjp: 100,
        },
        {
          name: "B栋",
          area: 1000,
          ds: 10,
          czmj: 3456900,
          rooms: 1908,
          ruzys: 80,
          ydl: 2000,
          ysl: 12900,
          jnjp: 100,
        },
        {
          name: "C栋",
          area: 1000,
          ds: 10,
          czmj: 3456900,
          rooms: 1908,
          ruzys: 80,
          ydl: 2000,
          ysl: 12900,
          jnjp: 100,
        },
        {
          name: "D栋",
          area: 1000,
          ds: 10,
          czmj: 3456900,
          rooms: 1908,
          ruzys: 80,
          ydl: 2000,
          ysl: 12900,
          jnjp: 100,
        },
        {
          name: "E栋",
          area: 1000,
          ds: 10,
          czmj: 3456900,
          rooms: 1908,
          ruzys: 80,
          ydl: 2000,
          ysl: 12900,
          jnjp: 100,
        },
      ],
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getOne(build) {
      this.buildsList = [build];
      this.$emit("oneBuild", build);
    },
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
.thsj .el-col-3 div {
  font-size: 18px;
  margin-top: 10px;
}
.text-title {
  color: rgba($color: #000000, $alpha: 0.5);
}
.title {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  padding: 10px;
}
</style>

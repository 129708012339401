<template>
  <basic-container>
    <avue-crud
      :option="option"
      :table-loading="loading"
      :data="data"
      :page.sync="page"
      :permission="permissionList"
      :before-open="beforeOpen"
      v-model="form"
      ref="crud"
      @row-update="rowUpdate"
      @row-save="rowSave"
      @row-del="rowDel"
      @search-change="searchChange"
      @search-reset="searchReset"
      @current-change="currentChange"
      @size-change="sizeChange"
      @refresh-change="refreshChange"
      @on-load="onLoad"
    >
      <template slot-scope="scope" slot="menu">
        <template v-if="website.permission">
          <template v-if="scope.row.status == 1">
            <el-button
              type="text"
              size="small"
              icon="el-icon-close"
              v-if="permission.codroom_close"
              class="none-border"
              @click="tip(scope.row)"
              >关闭</el-button
            >
          </template>
          <template v-if="scope.row.status == 0">
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              v-if="permission.codroom_edit"
              class="none-border"
              @click="handleEdit(scope.row, scope.index)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              icon="el-icon-close"
              v-if="permission.codroom_delete"
              class="none-border"
              @click="doCheckRemove(scope.row.id)"
              >删除</el-button
            >
            <!-- <el-button
              type="text"
              size="small"
              icon="el-icon-medal"
              v-if="permission.codroom_price"
              class="none-border"
              @click="openPriceForm(scope.row)"
              >价格</el-button
            > -->
            <el-button
              type="text"
              size="small"
              icon="el-icon-upload2"
              v-if="permission.codroom_open"
              class="none-border"
              @click="tip(scope.row)"
              >开放</el-button
            >
          </template>
        </template>
        <template v-else>
          <template v-if="scope.row.status == 1">
            <el-button
              type="text"
              size="small"
              icon="el-icon-close"
              class="none-border"
              @click="tip(scope.row)"
              >关闭</el-button
            >
          </template>
          <template v-if="scope.row.status == 0">
            <el-button
              type="text"
              size="small"
              icon="el-icon-edit"
              class="none-border"
              @click="handleEdit(scope.row, scope.index)"
              >编辑</el-button
            >
            <el-button
              type="text"
              size="small"
              icon="el-icon-close"
              class="none-border"
              @click="doCheckRemove(scope.row.id)"
              >删除</el-button
            >
            <!-- <el-button
              type="text"
              size="small"
              icon="el-icon-medal"
              class="none-border"
              @click="openPriceForm(scope.row)"
              >价格</el-button
            > -->
            <el-button
              type="text"
              size="small"
              icon="el-icon-upload2"
              class="none-border"
              @click="tip(scope.row)"
              >开放</el-button
            >
          </template>
        </template>
      </template>
    </avue-crud>

    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      v-if="dialogVisible"
      width="70%"
      append-to-body
      @close="dialogCancel"
    >
      <avue-form
        :option="formPriceOption"
        @submit="priceSubmit"
        v-model="formPrice"
      >
        <template slot-scope="{ size }" slot="menuForm">
          <el-button icon="el-icon-close" :size="size" @click="shut"
            >关闭</el-button
          >
        </template>
      </avue-form>
    </el-dialog>
  </basic-container>
</template>

<script>
import {
  getList,
  getPage,
  getDetail,
  add,
  update,
  remove,
  updateRoomStatus,
  doCheckRemove,
} from "@/api/spcod/codroom";
import { addPrice } from "@/api/spcod/codprice";
import { syncMeetRooms } from "@/api/spcod/hkmeeting";
import * as dictService from "@/api/system/dictbiz";
import website from "@/config/website";
import { mapGetters } from "vuex";
import { done } from "nprogress";

export default {
  data() {
    return {
      form: {},
      query: {},
      loading: true,
      dialogTitle: "会议价格",
      dialogVisible: false,
      formPrice: {},
      curRoomId: 0,
      formPriceOption: {
        emptyBtn: false,
        column: [
          {
            label: "时间单位",
            prop: "unitHour",
            type: "select",
            dicData: [
              {
                label: "60分钟",
                value: 60.0,
              },
            ],
            rules: [
              {
                required: true,
                message: "请输入最小预定时间",
                trigger: "blur",
              },
            ],
          },
          {
            label: "单位价格",
            prop: "unitPrice",
            type: "number",
            precision: 2,
            rules: [
              {
                required: true,
                message: "请输入单位价格",
                trigger: "blur",
              },
            ],
          },
          {
            label: "备注",
            prop: "memo",
            span: 24,
            minRows: 2,
            type: "textarea",
            rules: [
              {
                required: false,
                message: "请输入订购注意事项",
                trigger: "blur",
              },
            ],
          },
        ],
      },
      selOptions: [
        {
          label: "花费1000元设备免费提供",
          value: "1",
        },
        {
          label: "花费2000元设备免费提供",
          value: "2",
        },
        {
          label: "花费3000元设备免费提供",
          value: "3",
        },
        {
          label: "花费4000元设备免费提供",
          value: "4",
        },
      ],
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0,
      },
      selectionList: [],
      option: {
        height: "auto",
        calcHeight: 30,
        tip: false,
        searchShow: true,
        searchMenuSpan: 24,
        searchMenuPosition: 'center',
        border: true,
        index: true,
        viewBtn: true,
        delBtn: false,
        editBtn: false,
        dialogClickModal: false,
        column: [
          {
            label: "编码",
            prop: "code",
            search: true,
            searchSpan: 6,
            maxlength: 20,
            showWordLimit: false,
            minWidth: 200,
            rules: [
              {
                required: true,
                message: "请输入编码",
                trigger: "blur",
              },
              { max: 20 , message: `只能输入20个字符`, trigger: 'blur' }
            ],
          },
          {
            label: "名称",
            prop: "name",
            search: true,
            searchSpan: 6,
            maxlength: 40,
            showWordLimit: false,
            minWidth: 200,
            rules: [
              {
                required: true,
                message: "请输入名称",
                trigger: "blur",
              },
              { max: 40 , message: `只能输入40个字符`, trigger: 'blur' }
            ],
          },
          {
            label: "全称",
            prop: "fullName",
            maxlength: 120,
            showWordLimit: false,
            minWidth: 200,
            addDisplay: true,
            editDisplay: true,
            rules: [
              {
                required: true,
                message: "请输入全称",
                trigger: "blur",
              },
              { max: 120 , message: `只能输入120个字符`, trigger: 'blur' }
            ],
          },
          {
            label: "楼宇",
            prop: "buildingId",
            hide: true,
            search: true,
            searchSpan: 6,
            addDisplay: true,
            editDisplay: true,
            viewDisplay: false,
            type: "select",
            cascader: ["wpRoomId"],
            dicUrl:
              "/api/park-spcod/mobcodappoint/listbuilding?current=1&size=10000000",
            props: {
              label: "name",
              value: "id",
            },
            dicFormatter: (res) => {
              return res.data.records;
            },
            rules: [
              {
                required: false,
                message: "请输入楼宇Id",
                trigger: "blur",
              },
            ],
          },
          {
            label: "楼宇",
            prop: "buildingName",
            minWidth: 200,
            addDisplay: false,
            editDisplay: false,
            rules: [
              {
                required: false,
                message: "请输入楼宇",
                trigger: "blur",
              },
            ],
          },
          {
            label: "入驻房间",
            prop: "roomName",
            minWidth: 200,
            addDisplay: false,
            viewDisplay: true,
            editDisplay: false,
            rules: [
              {
                required: true,
                message: "请输入入驻房间",
                trigger: "blur",
              },
            ],
          },
          {
            label: "入驻房间",
            prop: "wpRoomId",
            type: "select",
            hide: true,
            addDisplay: true,
            viewDisplay: false,
            editDisplay: true,
            dicUrl:
              "/api/park-spcod/mobcodappoint/listbuildingroom?current=1&size=10000000&buildingId={{key}}",
            props: {
              label: "name",
              value: "id",
            },
            dicFormatter: (res) => {
              console.log(res);
              return res.data.records;
            },
            // filterable:true, //筛选选择项
            rules: [
              {
                required: true,
                message: "请输入关联房间wp_room中的ID",
                trigger: "blur",
              },
            ],
          },
          {
            label: "面积(m²)",
            prop: "roomArea",
            type: 'number',
            rules: [
              {
                required: true,
                message: "请输入面积",
                trigger: "blur",
              },
            ],
          },
          {
            label: "容纳人数",
            prop: "maxPerson",
            type: 'number',
            rules: [
              {
                required: true,
                message: "请输入容纳人数",
                trigger: "blur",
              },
            ],
          },
          // {
          //   label: "时间单位",
          //   prop: "unitHour",
          //   addDisplay: false,
          //   editDisplay: false,
          //   formatter(row, value, label, column) {
          //     if (row.unitHour && row.unitHour > 0)
          //       return row.unitHour + "分钟";
          //     else return "";
          //   },
          // },
          // {
          //   label: "价格",
          //   prop: "unitPrice",
          //   addDisplay: false,
          //   editDisplay: false,
          //   formatter(row, value, label, column) {
          //     if (row.unitPrice && row.unitPrice > 0)
          //       return row.unitPrice + "元";
          //     else return "";
          //   },
          // },
          {
            label: "开放开始",
            prop: "openBegin",
            hide: true,
            type: "select",
            dicUrl:
              "/api/park-spcod/sportstadium/getimeseg?startHour=7&endHour=23&timeSeg=60",
            props: {
              label: "name",
              value: "id",
            },
            dicFormatter: (data) => {
              let datalist = [];
              data.data.forEach((ele) => {
                datalist.push({
                  name: ele,
                  id: ele,
                });
              });
              return datalist;
            },
            rules: [
              {
                required: true,
                message: "请输入开放开始",
                trigger: "blur",
              },
            ],
          },
          {
            label: "开放结束",
            prop: "openEnd",
            hide: true,
            type: "select",
            row: true,
            dicUrl:
              "/api/park-spcod/sportstadium/getimeseg?startHour=7&endHour=23&timeSeg=60",
            props: {
              label: "name",
              value: "id",
            },
            dicFormatter: (data) => {
              let datalist = [];
              data.data.forEach((ele) => {
                datalist.push({
                  name: ele,
                  id: ele,
                });
              });
              return datalist;
            },
            rules: [
              {
                required: true,
                message: "请输入开放结束",
                trigger: "blur",
              },
            ],
          },
          {
            label: "上传图片",
            prop: "pics",
            span: 24,
            row: true,
            component: 'form-file',
            params: {
              isList: true,
              maxSize: 500,
              max: 3,
              placeholder: '只能上传jpg/png格式图片',
              accept: 'image/jpg,image/png'
            }
            // type: "upload",
            // listType: "picture-card",
            // hide: true,
            // propsHttp: {
            //   res: "data",
            //   url: "link",
            //   name: "originalName",
            // },
            // tip: "只能上传jpg/png图像，且不超过500kb",
            // action: "/api/jida-resource/oss/endpoint/put-file-attach",
            // rules: [
            //   {
            //     required: false,
            //     message: "请上传图片",
            //     trigger: "change",
            //   },
            // ],
          },
          {
            label: "会议设施",
            prop: "roomDesc",
            type: "select",
            hide: true,
            span: 24,
            multiple: true,
            dicUrl: dictService.getUrl("MeetingInstallation"),
            props: {
              label: "dictValue",
              value: "dictValue",
            },
            rules: [
              {
                required: true,
                message: "请输入会议室设施",
                trigger: "blur",
              },
            ],
          },
          {
            label: "状态",
            prop: "status",
            addDisplay: false,
            editDisplay: false,
            search: true,
            searchSpan: 6,
            type: "select",
            dicData: [
              {
                label: "开放",
                value: 1,
              },
              {
                label: "关闭",
                value: 0,
              },
            ],
            rules: [
              {
                required: false,
                message: "请输入状态",
                trigger: "blur",
              },
            ],
          },
          {
            label: "备注",
            prop: "memo",
            hide: true,
            type: "textarea",
            span: 24,
            minRow: 2,
            maxlength: 255,
            showWordLimit: false,
            rules: [
              { max: 255 , message: `只能输入255个字符`, trigger: 'blur' }
            ]
          },
          {
            label: "预订须知",
            prop: "apptMemo",
            hide: true,
            span: 24,
            minRows: 2,
            type: "textarea",
            maxlength: 1024,
            showWordLimit: false,
            rules: [
              {
                required: false,
                message:
                  "请输入预订备注，多行对应多条，单行结构：事项名称###事项内容",
                trigger: "blur",
              },
              { max: 1024 , message: `只能输入1024个字符`, trigger: 'blur' }
            ],
          },
        ],
      },
      data: [],
    };
  },
  computed: {
    ...mapGetters(["permission"]),
    permissionList() {
      return {
        addBtn: this.vaildData(
          this.permission.codroom_add,
          !website.permission
        ),
        viewBtn: this.vaildData(
          this.permission.codroom_view,
          !website.permission
        ),
        delBtn: this.vaildData(
          this.permission.codroom_delete,
          !website.permission
        ),
        editBtn: this.vaildData(
          this.permission.codroom_edit,
          !website.permission
        ),
      };
    },
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
  },
  methods: {
    handleEdit(row, index) {
      this.$refs.crud.rowEdit(row, index);
    },
    doCheckRemove(id) {
      let _this = this;
      this.$confirm("确定删除吗？", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //修改状态
        doCheckRemove(id).then(() => {
          _this.$forceUpdate();
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
      });
    },
    rowSave(row, done, loading) {
      // 设备描述
      let selOps = [];
      let _this = this;
      //console.log(row);
      //console.log(this.form);
      /*row.roomDesc.forEach((i) => {
        selOps.push(i);
      });*/
      row.roomDesc = this.form.roomDesc.join(",");
      add(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
          //同步海康
          syncMeetRooms().then((res) => {
            console.log(res);
          });
        },
        (error) => {
          loading();
          window.console.log(error);
        }
      );
    },
    rowUpdate(row, index, done, loading) {
      if (!row.memo) {
        row.memo = "";
      }
      update(row).then(
        () => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          done();
          syncMeetRooms().then((res) => {
            console.log(res);
          });
        },
        (error) => {
          loading();
          console.log(error);
        }
      );
    },
    // 原删除方法
    rowDel(row) {
      this.$confirm("确定将选择数据删除?", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return remove(row.id);
        })
        .then(() => {
          this.onLoad(this.page);
          this.$message({
            type: "success",
            message: "操作成功!",
          });
        });
    },
    // 现
    del() {},
    beforeOpen(done, type) {
      if (["edit", "view"].includes(type)) {
        this.form.pics = [];
        getDetail(this.form.id).then((res) => {
          this.form = res.data.data;
          let pics = [];
          res.data.data.pics.forEach((i) => {
            let obj = {
              label: i.picName,
              value: i.picUrl,
            };
            pics.push(obj);
          });
          this.form.pics = pics;
          done();
        });
      }else{
        done();
      }
    },
    searchReset() {
      this.query = {};
      this.onLoad(this.page);
    },
    searchChange(params, done) {
      this.query = params;
      this.page.currentPage = 1;
      this.onLoad(this.page, params);
      done();
    },
    selectionChange(list) {
      this.selectionList = list;
    },
    currentChange(currentPage) {
      this.page.currentPage = currentPage;
    },
    sizeChange(pageSize) {
      this.page.pageSize = pageSize;
    },
    refreshChange() {
      this.onLoad(this.page, this.query);
    },
    onLoad(page, params = {}) {
      this.loading = true;
      getPage(
        page.currentPage,
        page.pageSize,
        Object.assign(params, this.query)
      ).then((res) => {
        const data = res.data.data;
        this.page.total = data.total;
        this.data = data.records;
        this.loading = false;
      });
    },
    priceSubmit() {
      let _this = this;
      this.formPrice.roomId = this.curRoomId;
      if (_this.formPrice.unitPrice <= 0) {
        _this.$message({
          type: "success",
          message: "价格不能小于0!",
        });
        //_this.loading();
        //_this.$forceUpdate();
        done();
        return;
      }

      addPrice(this.formPrice).then((res) => {
        _this.$message({
          type: "success",
          message: "添加价格成功!",
        });
        _this.dialogVisible = false;
        //_this.$forceUpdate();
        //_this.done();
        _this.onLoad(_this.page);
      });
    },
    dialogCancel() {
      this.dialogVisible = false;
    },
    openPriceForm(row) {
      if (row.unitHour && row.unitHour > 0)
        this.formPrice.unitHour = row.unitHour;
      if (row.unitPrice && row.unitPrice > 0)
        this.formPrice.unitPrice = row.unitPrice;
      this.curRoomId = row.id;
      this.dialogVisible = true;
    },

    tip(row) {
      this.$alert("确定修改状态吗？", "确定").then(() => {
        updateRoomStatus(row.id).then((res) => {
          if (res.data.success) {
            if (row.status === 1) {
              row.status = 0;
              this.$forceUpdate();
            } else {
              row.status = 1;
              this.$forceUpdate();
            }
            this.$message.success(res.data.msg);
          } else {
            this.$message.success(res.data.msg);
          }
        });
      });
    },
    shut() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-upload--picture-card {
  width: 68px;
  height: 68px;
  line-height: 72px;
  vertical-align: top;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 68px;
  height: 68px;
}
.btnClass {
  display: flow-root;
  height: 50px;
  line-height: 50px;
  padding: 0px 15px;
}
/deep/ .el-select {
  width: 100%;
}
</style>

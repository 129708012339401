<template>
  <div class="pad-lr-8" v-if="widget && dash">
    <el-collapse v-model="activeName" accordion>
      <el-collapse-item title="基础配置" name="baseInfo">
        <div class="pad-15">
          <el-form ref="basicInfoform" :model="widget.public" :size="config.size" :label-width="config.labelWidth">
            <OptionGroup title="标题" :columns="columns">
              <el-form-item label="显示标题">
                <el-switch v-model="widget.public.title.show"></el-switch>
              </el-form-item>
              <el-form-item label="沉浸式">
                <el-switch v-model="widget.public.title.immersive"></el-switch>
              </el-form-item>
              <el-form-item label="标题文本">
                <el-input v-model="widget.public.title.text"></el-input>
              </el-form-item>
              <!-- <el-form-item label="副标题">
                <el-input v-model="widget.public.title.subtext"></el-input>
              </el-form-item> -->
              <el-form-item label="字体颜色">
                <el-color-picker v-model="widget.public.title.style.color" show-alpha></el-color-picker>
              </el-form-item>
              <el-form-item label="背景颜色">
                <el-color-picker v-model="widget.public.title.style.background" show-alpha></el-color-picker>
              </el-form-item>
              <el-form-item label="左边留白">
                <el-input type="number" :min="0" v-model="widget.public.title.style.paddingLeft">
                  <el-select class="dv-el-select-input" v-if="widget.public.title.style.unit && widget.public.title.style.unit.paddingLeft" v-model="widget.public.title.style.unit.paddingLeft" slot="append" placeholder="请选择">
                    <el-option v-for="item of sizeUnits" :value="item.value" :label="item.name" :key="item.value">
                      <span style="float: left">{{ item.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item label="右边留白">
                <el-input type="number" :min="0" v-model="widget.public.title.style.paddingRight">
                  <el-select class="dv-el-select-input" v-if="widget.public.title.style.unit && widget.public.title.style.unit.paddingRight" v-model="widget.public.title.style.unit.paddingRight" slot="append" placeholder="请选择">
                    <el-option v-for="item of sizeUnits" :value="item.value" :label="item.name" :key="item.value">
                      <span style="float: left">{{ item.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </OptionGroup>
            <OptionGroup title="位置和大小" :columns="columns">
              <el-form-item label="X">
                <el-input-number v-model="widget.x" controls-position="right" :min="0" :max="dash.props.colNum"></el-input-number>
              </el-form-item>
              <el-form-item label="Y">
                <el-input-number v-model="widget.y" controls-position="right" :min="0"></el-input-number>
              </el-form-item>
              <el-form-item label="宽度">
                <el-input-number v-model="widget.w" controls-position="right" :min="1"  :max="dash.props.colNum"></el-input-number>
              </el-form-item>
              <el-form-item label="高度">
                <el-input-number v-model="widget.h" controls-position="right" :min="1"></el-input-number>
              </el-form-item>
            </OptionGroup>
            <OptionGroup title="边距" :columns="columns">
              <el-form-item label="上边距">
                <el-input type="number" :min="0" v-model="widget.public.style.paddingTop">
                  <el-select class="dv-el-select-input" v-if="widget.public.style.unit && widget.public.style.unit.paddingTop" v-model="widget.public.style.unit.paddingTop" slot="append" placeholder="请选择">
                    <el-option v-for="item of sizeUnits" :value="item.value" :label="item.name" :key="item.value">
                      <span style="float: left">{{ item.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item label="右边距">
                <el-input type="number" :min="0" v-model="widget.public.style.paddingRight">
                  <el-select class="dv-el-select-input" v-if="widget.public.style.unit && widget.public.style.unit.paddingRight" v-model="widget.public.style.unit.paddingRight" slot="append" placeholder="请选择">
                    <el-option v-for="item of sizeUnits" :value="item.value" :label="item.name" :key="item.value">
                      <span style="float: left">{{ item.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item label="下边距">
                <el-input type="number" :min="0" v-model="widget.public.style.paddingBottom">
                  <el-select class="dv-el-select-input" v-if="widget.public.style.unit && widget.public.style.unit.paddingBottom" v-model="widget.public.style.unit.paddingBottom" slot="append" placeholder="请选择">
                    <el-option v-for="item of sizeUnits" :value="item.value" :label="item.name" :key="item.value">
                      <span style="float: left">{{ item.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
              <el-form-item label="左边距">
                <el-input type="number" :min="0" v-model="widget.public.style.paddingLeft">
                  <el-select class="dv-el-select-input" v-if="widget.public.style.unit && widget.public.style.unit.paddingLeft" v-model="widget.public.style.unit.paddingLeft" slot="append" placeholder="请选择">
                    <el-option v-for="item of sizeUnits" :value="item.value" :label="item.name" :key="item.value">
                      <span style="float: left">{{ item.name }}</span>
                      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.desc }}</span>
                    </el-option>
                  </el-select>
                </el-input>
              </el-form-item>
            </OptionGroup>
            <OptionGroup title="其他配置" :columns="columns">
              <el-form-item label="溢出">
                <el-radio v-model="widget.public.overflow" label="auto">滚动</el-radio>
                <el-radio v-model="widget.public.overflow" label="hidden">剪裁</el-radio>
              </el-form-item>
            </OptionGroup>
          </el-form>
        </div>
      </el-collapse-item>
      <el-collapse-item v-if="currentWidgetType && currentWidgetType.optionList" title="更多配置" name="moreInfo">
        <div class="pad-15">
          <el-form :size="config.size" :label-width="config.labelWidth">
            <OptionGroup v-for="optionItem of currentWidgetType.optionList" :columns="columns" :optionItem="optionItem" :option="widget.private"></OptionGroup>
          </el-form>
          <!-- <template v-for="optionItem of currentWidgetType.optionList">
            <OptionGroup v-if="optionItem.optionList" :optionList="optionList" :option="option"></OptionGroup>
          </template> -->
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>
<script>

import OptionGroup from './option-group.vue';

export default {
  components: { OptionGroup },
  inject: ['config'],
  data(){
    return {
      activeName: 'moreInfo',
      sizeUnits: [
        {name: 'PX' , value: 'px' , desc: '像素'},
        {name: '%' , value: '%' , desc: '百分比'}
      ]
    }
  },
  props: {
    columns: {
      type: Array,
      default(){
        return [];
      }
    },
    widget: {
      type: Object,
      default(){
        return null;
      }
    },
    dash: {
      type: Object,
      default(){
        return null;
      }
    },
    currentWidgetType: {
      type: Object,
      default(){
        return null;
      }
    }
  }
}
</script>
<style lang="scss">
.dv-el-select-input{
  width: 60px;
  font-size: 12px;
  .el-input__inner{
    padding-left: 5px;
  }
}
</style>
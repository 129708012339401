<template>
  <div>
    <avue-form
    v-loading="loading"
      :option="option"
        ref="crud"
        v-model="form"
        >
        <!-- 入参 -->
        <template slot='inParams'>
          <avue-crud :option='inOption' :data='inputParams'>
          </avue-crud>
        </template>
        <!-- 返回参数 -->
        <template slot='backParams'>
          <avue-crud :option='outOption' :data='outputParams'>
          </avue-crud>
        </template>
        <!-- 授权应用 -->
        <template slot='app'>
          <avue-crud :option='licenseOption' :data='licensedList'>
          </avue-crud>
        </template>
    </avue-form>

  </div>
</template>
<script>
import api from '@/api/dataShare/index';
export default {
  data(){
    return {
      loading: false,
      inOption: {
        addBtn: false,
        viewBtn: false,
        delBtn: false,
        editBtn: false,
        refreshBtn: false,
        columnBtn:false,
        simplePage: false,
        header: false,
        menu: false,
        border: true,
        column:[
          {
            label:'字段',
            prop:'key',
            span: 24,
          },
          {
            label:'参数类型',
            prop:'dataType',
          },
        ]
      },
      outOption: {
        addBtn: false,
        viewBtn: false,
        delBtn: false,
        editBtn: false,
        refreshBtn: false,
        columnBtn:false,
        simplePage: false,
        header: false,
        menu: false,
        border: true,
        column:[
          {
            label:'字段',
            prop:'key',
            span: 24,
          },
          {
            label:'参数类型',
            prop:'dataType',
          },
        ]
      },
      licenseOption: {
        addBtn: false,
        viewBtn: false,
        delBtn: false,
        editBtn: false,
        refreshBtn: false,
        columnBtn:false,
        simplePage: false,
        header: false,
        menu: false,
        border: true,
        column:[
          {
            label:'应用名称',
            prop:'appName',
            span: 24,
          },
          {
            label:'授权时间',
            prop:'createTime',
          },
          {
            label:'有效时间',
            prop:'validTime',
          },
        ]
      },
      form: {
        dataSourceType: '',
        dataSourceDb: '',
        dataSourceTable: '',
        name: '',
        token: '',
        description: ''
      },
      inputParams: [],
      outputParams: [],
      licensedList: [],
      form: {},
      option: {
          labelWidth: 120,
          emptyBtn: false,
          submitBtn: false,
          detail:true,
          group: [
            {
              label: '基本信息',
              prop: 'base',
              icon: 'el-icon-edit-outline',
              column: [
                {
                  label: '接口名称',
                  prop: 'name',
                },
                {
                  label:'请求地址',
                  prop:'shareUrl',
                  span: 24
                },
                {
                  label:'用途',
                  prop:'description',
                  span: 24,
                },
                {
                  label: '创建方式',
                  prop: 'typeName',
                },
              ]
            }, {
              label: '入参',
              prop: 'inParams',
              icon: 'el-icon-s-order',
              column: [
                {
                  label: '',
                  prop: 'inParams',
                  span: 24,
                  labelWidth: 0,
                  formslot:true,
                }
              ]
            }, {
              label: '返回参数',
              prop: 'backParams',
              icon: 'el-icon-s-order',
              column: [
                {
                  label: '',
                  prop: 'backParams',
                  span: 24,
                  labelWidth: 0,
                  formslot:true,
                }
              ]
            }, {
              label: '授权应用',
              prop: 'app',
              icon: 'el-icon-s-order',
              column: [
                {
                  label: '',
                  prop: 'app',
                  span: 24,
                  labelWidth: 0,
                  formslot:true,
                }
              ]
            }
          ]
        },
    }
  },
  mounted(){
    if(this.id){
      // this.id = this.$router.currentRoute.params.id;
      this.get();
    }
  },
  props: {
    id: String,
  },
  methods: {
    get(){
      this.loading = true;
      return api.get({
        id: this.id
      }).then(
        res => {
          this.loading = false;
          if(res.data){
            this.form = res.data.data;
            if (res.data.data.type==0) {
              this.form.typeName= '本地生成'
            } else if (res.data.data.type==1){
              this.form.typeName= '第三方注册'
            } else {
              this.form.typeName= ''
            }
            this.licensedList=[]
            this.inputParams = JSON.parse(this.form.inputParams) || [];
            this.outputParams = JSON.parse(this.form.outputParams) || [];
            this.form.licensedList.forEach((i) => {
              i.validTime=(i.startDate==''||i.startDate==null)?"":`${i.startDate}~${i.endDate}`
              this.licensedList.push(i)
            })
          }
        }
      )
    },
    goBack(){
      this.$emit('close' , null)
    }
  }
}
</script>
<style lang="scss" scoped>
::v-deep .avue-crud__pagination {
  display: none!important;
}
</style>
